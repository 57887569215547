import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
import { map } from 'rxjs/operators'
import { PopUpModal } from '../../components';
import { csvHeader } from './viewModel';
import CommonHelper from '../../services/common';
import FormFeedbacksList from './list';
import PreviewForm from '../settings/workflow/feedbackForms/preview';
import Translate from '../../constants/translate';
import { firestoreDB } from '../../services/helper';


const FeedBackForms = ({ dealersettings, clientUsers }) => {
	const [activeTab, setActiveTab] = useState('all');
	const [loader, setLoader] = useState(true)
	const [formFeedbacks, setFormFeedbacks] = useState([]);
	const [hasMore, setHasMoreData] = useState([])
	const [triggers, setTriggers] = useState([])
	const [selectedTrigger, setSelectedTrigger] = useState('')
	const [yesNoFilter, setYesNoFilter] = useState('')
	const [csvData, setCSVData] = useState([])
	const pageLimit = 100;
	const [previewForm, setPreviewForm] = useState({
		show: false,
		title: '',
		formFields: null,
		data: null
	})
	useEffect(() => {
		if (_.isEmpty(selectedTrigger)) {
			return
		}
		const fetchFeedbacks = async () => {
			setLoader(true)
			let _formFeedbacks = [];
			const ref = firestoreDB(dealersettings)
				.firestore()
				.collection('triggersLog')
				.where('clientID', '==', dealersettings.client.id)
				.where('isDeleted', '==', false)
				.where('triggerID', '==', selectedTrigger)
				// .orderBy('sentDate', 'desc')
				.limit(pageLimit)

			const querySnapshots = await ref.get()

			if (querySnapshots.size > 0) {
				if (querySnapshots.docs.length > 0 && querySnapshots.docs.length === pageLimit) {
					setHasMoreData([querySnapshots.docs[querySnapshots.docs.length - 1]])
				}
				else {
					setHasMoreData([]);
				}
				querySnapshots.forEach(rec => {
					_formFeedbacks.push({ ...rec.data(), documentID: rec.id });
				});
				_formFeedbacks = _formFeedbacks.map(a => convertCampaignVM(a));
			}
			setFormFeedbacks(_formFeedbacks);
			// setCSVData(CommonHelper.generateExcelData(_formFeedbacks, csvHeader))
			setLoader(false);
		}
		fetchFeedbacks()
	}, [dealersettings.client.id, selectedTrigger]);

	useEffect(() => {

		let refOemTriggers = '';
		let refclientTriggers = '';
		let refTriggersAddedby = '';
		if (localStorage.defaultModule === 'oem') {
			refOemTriggers = window.firebase.firestore().collection('triggers')
				.where('isDeleted', '==', false)
				.where('triggerFeedback', '==', true)
			if (dealersettings.level === 'oem' && dealersettings.oemID) {
				refOemTriggers = refOemTriggers
					.where('oemID', '==', dealersettings.oemID)
			}
			else if (dealersettings.level === 'region' && dealersettings.regionID) {
				refOemTriggers = refOemTriggers
					.where('regionIDs', 'array-contains', dealersettings.regionID)
			}
			else if (dealersettings.level === 'group' && dealersettings.groupID) {
				refOemTriggers = refOemTriggers
					.where('groupIDs', 'array-contains', dealersettings.groupID)
			}
			else {
				refOemTriggers = refOemTriggers
					.where('clientID', '==', dealersettings.client.id);
				refclientTriggers = window.firebase.firestore().collection('triggers')
					.where('isDeleted', '==', false)
					.where('triggerFeedback', '==', true)
					.where('clientIDs', 'array-contains', dealersettings.client.id)
			}
		}
		else {
			refOemTriggers = window.firebase.firestore().collection('triggers')
				.where('isDeleted', '==', false)
				.where('triggerFeedback', '==', true)
				.where('clientID', '==', dealersettings.client.id);
			refclientTriggers = window.firebase.firestore().collection('triggers')
				.where('isDeleted', '==', false)
				.where('triggerFeedback', '==', true)
				.where('clientIDs', 'array-contains', dealersettings.client.id)
		}
		// refTriggersAddedby = window.firebase.firestore().collection('triggers')
		//     .where('isDeleted', '==', false)
		//     .where('settingsID', '==', !_.isEmpty(dealersettings.oemID) ? dealersettings.oemID : dealersettings.client.settingsID)
		//     .where('addedBy', '==', localStorage.uid)

		const unSubTriggerCollectionzz = combineLatest(
			refOemTriggers ? collection(refOemTriggers) : of([]),
			refclientTriggers ? collection(refclientTriggers) : of([]),
			refTriggersAddedby ? collection(refTriggersAddedby) : of([])
		).pipe(
			map(([oemTriggers, clientTriggers, ownedTriggers]) => {
				return [
					_.map(oemTriggers, function (obj) { return { ...obj.data(), documentID: obj.id } }),
					_.map(clientTriggers, function (obj) { return { ...obj.data(), documentID: obj.id } }),
					_.map(ownedTriggers, function (obj) { return { ...obj.data(), documentID: obj.id } }),
				]
			}))
			.subscribe(([oemTriggers, clientTriggers, ownedTriggers]) => {
				let _triggers = [];
				oemTriggers.forEach((rec) => {
					if (!_triggers.some(a => a.documentID === rec.documentID))
						_triggers.push(rec);
				})
				clientTriggers.forEach((rec) => {
					if (!_triggers.some(a => a.documentID === rec.documentID))
						_triggers.push(rec);
				})
				ownedTriggers.forEach((rec) => {
					if (!_triggers.some(a => a.documentID === rec.documentID))
						_triggers.push(rec);
				})
				_triggers = _triggers.filter(a => a.isActive === true)
				setTriggers(_triggers)
				if (!_.isEmpty(_triggers)) {
					setSelectedTrigger(_triggers[0].documentID)
				}
				setLoader(false)
			});

		return () => {
			unSubTriggerCollectionzz && unSubTriggerCollectionzz.unsubscribe()
		}

	}, [])



	const convertCampaignVM = doc => {
		let objToReturn = { ...doc };

		objToReturn._type = doc.triggerType === 'sms' ? 'SMS' : 'Email';

		objToReturn._participant = CommonHelper.displayContactName([], doc.contact, ' ');
		objToReturn.enqdisplayID = !_.isEmpty(doc.enquiry) ? `${doc.enquiry.displayID}` : '';
		objToReturn._sentOn = doc?.email ?
			(
				<div className='user-list-items-info'>
					<h4>{doc.isDone === true && doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--'}</h4>
				</div>
			) : (
				<div className='user-list-items-info'>
					<h4>{doc?.messagemedia?.scheduled ? moment(doc.messagemedia.scheduled).format('DD/MM/YYYY hh:mm A') : '--'}</h4>
				</div>
			);

		objToReturn._sentOnCSV = doc?.email ?
			doc.isDone === true && doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--'
			: doc?.messagemedia?.scheduled ? moment(doc.messagemedia.scheduled).format('DD/MM/YYYY hh:mm A') : '--';

		if (!_.isEmpty(doc.replies) && doc.replies[0].date) {
			objToReturn._responseOn = doc.replies[0].date ? moment(doc.replies[0].date.toDate()).format('DD/MM/YYYY hh:mm A') : '--'
		}
		else if (doc.formFeedbackIDs && doc.formFeedbackIDs.length > 0 && doc.formFeedbackResponse) {
			objToReturn._responseOn = moment.unix(doc.formFeedbackResponse.seconds).format('DD/MM/YYYY hh:mm A');
		}
		else {
			objToReturn._responseOn = '';
		}

		objToReturn._phone = !_.isEmpty(doc.messagemedia) && !_.isEmpty(doc.messagemedia.destination_number) ? doc.messagemedia.destination_number : !_.isEmpty(doc.contact) && doc.contact.phone ? CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, dealersettings) : '--';
		objToReturn._email = !_.isEmpty(doc.email) && !_.isEmpty(doc.email.to) ? doc.email.to : !_.isEmpty(doc.contact) && doc.contact.email ? `${doc.contact.email}` : '--';
		objToReturn.contactPhone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, dealersettings);
		objToReturn.csvPhone = objToReturn.contactPhone ? `${objToReturn.contactPhone}` : ''

		if (!_.isEmpty(doc.replies) && doc.replies[0].text) {
			let text = doc.replies[0].text
			text = text.trim()
			text = text.replaceAll('\n', '')
			text = text.length > 50 ? text.substring(0, 50) + '...' : text;
			objToReturn.response = text;
			objToReturn.responseCSV = text
		}
		else if (doc.formFeedbackIDs && doc.formFeedbackIDs.length > 0) {
			objToReturn.response = (<button
				type='button'
				className={`btn btn-green float-left mr-2 ${doc.formFeedbackResponse ? '' : 'btn-disable'}`}
				style={{ padding: `3px 8px 4px`, fontSize: `11px` }}
				onClick={async (e) => {
					e.preventDefault();
					const formFeedback = await firestoreDB(dealersettings).firestore().doc('formFeedback/' + doc.formFeedbackIDs[0]).get();
					setPreviewForm({
						show: true,
						title: objToReturn._participant,
						formFields: formFeedback.exists ? formFeedback.data().formFields : [],
						customerReview: formFeedback.exists ? formFeedback.data().response : null,
						data: formFeedback.data()
					});
				}}
			>
				{' '}
				<i
					className='fa fa-sms mr-1'
					aria-hidden='true'
				></i>{' '}
				View{' '}
			</button>)
		} else {
			objToReturn.response = '--'
		}

		objToReturn._responseCombined = (
			<div className='user-list-items-info'>
				<span>{objToReturn.response}</span>
				{
					objToReturn._responseOn ? (<h5 style={{ width: '100%', display: 'inline-block' }}>{objToReturn._responseOn}</h5>)
						: (<></>)
				}

			</div>
		)
		return objToReturn;
	};

	// const handleClick = id => {
	// 	window.open(
	// 		`${window.location.origin}/customer/form/${id}?preview=true`,
	// 		'_blank',
	// 	);
	// };

	const fetchMoreData = async () => {
		let feedBacksToAdd = []
		let feedBacksRef = firestoreDB(dealersettings)
			.firestore()
			.collection('triggersLog')
			.where('clientID', '==', dealersettings.client.id)
			.where('triggerID', '==', selectedTrigger)
			.where('isDeleted', '==', false)
			// .orderBy('sentDate', 'desc')
			.startAfter(hasMore[0])
			.limit(pageLimit)

		const feedBacksSnapshots = await feedBacksRef.get()
		if (!feedBacksSnapshots.empty) {
			feedBacksSnapshots.forEach((doc) => {
				feedBacksToAdd.push(convertCampaignVM(doc.data()))
			});
			setFormFeedbacks([...formFeedbacks, ...feedBacksToAdd]);
			// setCSVData(CommonHelper.generateExcelData([...formFeedbacks, ...feedBacksToAdd], csvHeader))
			if (feedBacksSnapshots.docs.length > 0 && feedBacksSnapshots.docs.length <= pageLimit) {
				setHasMoreData([feedBacksSnapshots.docs[feedBacksSnapshots.docs.length - 1]])
			}
		} else {
			setHasMoreData([])
		}
	}

	return (<>
		<div className='middle-wrapper'>
			<div className='filter-panel'>
				<div className='float-left campaign-head'>
					<h3><Translate text={'feedback'} /></h3>
				</div>
			</div>
			<div className='bulksms-panel'>
				<div className='bulksms-main-container'>
					<div className='bulksms-wrapper'>
						<Tab.Container
							defaultActiveKey={activeTab}
							mountOnEnter={true}
							onSelect={k => {
								setActiveTab(k)
								// setSelectedTrigger(null)
								// setYesNoFilter(null)
							}}
						>
							<div className='bulksms-left-panel'>
								<div className='bulksms-left-menu'>
									<h4><Translate text={'XXX'} /></h4>
									<div className='bulksms-submenu'>
										<Nav as='ul' bsPrefix='ul-nav'>
											<Nav.Item as='li' bsPrefix='li-nav'>
												<Nav.Link eventKey='all' bsPrefix='li-a-nav'>
													<i className='ico icon-campaign-all'> </i>{' '}
													<span><Translate text={'all'} /></span>{' '}
												</Nav.Link>
											</Nav.Item>
											<Nav.Item as='li' bsPrefix='li-nav'>
												<Nav.Link eventKey='sms' bsPrefix='li-a-nav'>
													<i className='ico icon-sms-id-icon'> </i>{' '}
													<span><Translate text={'SMS'} /></span>{' '}
												</Nav.Link>
											</Nav.Item>
											<Nav.Item as='li' bsPrefix='li-nav'>
												<Nav.Link eventKey='email' bsPrefix='li-a-nav'>
													<i className='ico icon-sms-template-icon'> </i>{' '}
													<span><Translate text={'email'} /></span>{' '}
												</Nav.Link>
											</Nav.Item>
										</Nav>
									</div>
								</div>
							</div>
							<FormFeedbacksList
								activeTab={activeTab}
								feedbacks={formFeedbacks}
								setFormFeedbacks={setFormFeedbacks}
								loader={loader}
								dealersettings={dealersettings}
								clientUsers={clientUsers}
								hasMore={hasMore}
								fetchMoreData={fetchMoreData}
								triggers={triggers}
								setSelectedTrigger={setSelectedTrigger}
								selectedTrigger={selectedTrigger}
								setYesNoFilter={setYesNoFilter}
								yesNoFilter={yesNoFilter}
								setCSVData={setCSVData}
								csvData={csvData}
							/>
						</Tab.Container>
					</div>
				</div>
			</div>
		</div>
		<PopUpModal show={previewForm.show}>
			<PreviewForm
				{...previewForm}
				dealersettings={dealersettings}
				handleClose={() => { setPreviewForm({ show: false, title: '', formFields: null, data: null }) }}
			/>
		</PopUpModal>
	</>
	);
};

export default FeedBackForms;
