import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import _ from 'lodash'
import ReactExport from 'react-export-excel-xlsx-fix';
import { activityListVM, allactivityFields } from '../calendar/viewModel'
import CommonHelper from '../../services/common';
import { ContentOverlay, TableView, PopUpModal } from '../../components'
import toast from 'toasted-notes'
import { StageProvider, StageConsumer } from '../pipeline/provider'
import QuickView from "../pipeline/quickview";
import _images from '../../images'
import AddActivity from '../activity'
import { objActivitylog, objWalkIn } from '../pipeline/viewModel';
import Swal from 'sweetalert2'
import ReAuthenticate from '../common/reAuthenticate';
import Filters from '../calendar/filters'
import ActivityStatus from '../activity/actvityStatusModal';
import { enqUnActionStatus, enquiryStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';
import Translate from '../../constants/translate';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const ActivityList = (props) => {
    const csvBtn = useRef();
    const csvDirectBtn = useRef();
    const [activitylogs, setActivities] = useState([])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [hasMore, setHasMore] = useState([1])
    const [activityFields, setActivityFields] = useState(props.dealersettings.activityFields)
    const [showActivityModel, setShowActivityModel] = useState(false)
    const [activityHeader, setActivityHeader] = useState('Add New Activity')
    const [newActivitylog, setNewActivitylog] = useState(null);
    const [enquiryObj, setEnquiryObj] = useState(null);
    const [contactObj, setContactObj] = useState(null);
    const [stockObj, setStockObj] = useState(null);
    const [searchText, setSearchText] = useState('')
    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const [showScheduleActivityModel, setShowScheduleActivityModel] = useState({
        show: false,
        activityLog: null,
        contact: null,
        enquiry: null
    })
    const [pageNum, setPageNum] = useState(0);
    const [filterpageNum, setfilterPageNum] = useState(0);

    const [loader, setLoader] = useState(false);
    const [showReAuthentication, setReAuthentication] = useState(false);

    const [activityFilter, setactivityFilter] = useState({})
    const [searchKeywords, setSearchKeywords] = useState('')
    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [showOutcomeModal, setShowOutcomeModal] = useState({
        show: false,
        clsActive: '',
        isMandatory: false,
        data: {}
    });
    const [sortConfig, setSortConfig] = useState();
    const [tableLoader, setTableLoader] = useState(false)

    const enableCustShowNoShow = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

    const fetchAct = async (type = 'generic', _pageNum, localFilter, _sortConfig) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled && type === 'excel') {
            handleReAuthOpen();
            return;
        }

        let num = _pageNum
        let pageLimit = props.counts ? props.counts > 49 ? 50 : props.counts : 50
        let filters = props.filter
        // on export fetch success csvBtn.current && csvBtn.current.link.click();
        let _filter = {
            type: 'activity'
        }
        if (props?.filter?.subType) {
            _filter.subType = props?.filter?.subType
        }
        if (props?.filter?.isDone) {
            _filter.isDone = props?.filter?.isDone
        }
        if (props.title.includes('Overdue')) {
            _filter.searchType = 'overdue'
        } else if (props.title.includes('Scheduled')) {
            _filter.searchType = ''
        } else {
            _filter.isDone = true
        }
        if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        }
        // else if (!_.isEmpty(props.pipeline)) {
        //     _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket').map(item => item.value).join(',')
        // }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        } else if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        // if (props.dateRange && props.dateRange.startDate && props.dateRange.endDate) {
        //     _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
        //     _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        // }
        if (filters && filters.startDate) {
            _filter.startDate = moment(filters.startDate.toDate()).format('YYYY-MM-DD')
        }
        if (filters && filters.endDate) {
            _filter.endDate = moment(filters.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (filters && filters.isDone) {
            _filter.isDone = filters.isDone
        }
        if (filters && filters.dateType) {
            _filter.dateType = filters.dateType
        }
        if (filters && filters.type) {
            _filter.type = filters.type
        }
        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.enqSaletype = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.enqSaletype = props.dealersettings.accessSalesType.join(',');
        }
        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.enqMake = props.dashboardFilter.makes.join(',')
        }
        if (filters && filters.documentIDs) {
            _filter = {
                documentIDs: filters.documentIDs.join(',')
            }
        }
        if (filters && filters.roleIDs) {
            _filter.roleIDs = filters.roleIDs
        }
        if (!_.isEmpty(localFilter)) {
            _filter = { ..._filter, ...localFilter }
            // num = 0
            pageLimit = 50
        }
        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
        
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()

        // New Filters Added for Porsche - SaMeeR - 05-09-24
        if (props?.dashboardFilter?.models?.length > 0) _filter.model = props.dashboardFilter.models.join(',')
        if (props?.dashboardFilter?.campaigns?.length > 0) _filter.campaign = props.dashboardFilter.campaigns.join(',')
        if (props?.dashboardFilter?.origins?.length > 0) _filter.origin = props.dashboardFilter.origins.join(',')
        if (props?.dashboardFilter?.enquiryTypes?.length > 0) _filter.enquiryType = props.dashboardFilter.enquiryTypes.join(',')
        if (props?.dashboardFilter?.leadSource?.length > 0) _filter.leadSource = props.dashboardFilter.leadSource.join(',')

        let _searchObject = {
            "type": "searchActivities",
            "filters": JSON.stringify(_filter),
            "sortOrder": !_.isEmpty(_sortConfig) ? `${_sortConfig.key} ${_sortConfig.sort}` : `activity."modifiedDate" desc`,
            "pageNum": num,
            pageLimit
        }
        if (type === 'excel') {
            _searchObject.pageLimit = (!props.counts || props.counts > 999) ? 10000 : props.counts
            _searchObject.pageNum = 0
            toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
                {
                    position: 'top',
                    duration: null
                },
            )
        }
        _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings, null, null, true);
        if (!_.isEmpty(filters.clientID)) {
            _searchObject.clientID = filters.clientID;
        }
        if (type === 'generic' && _.isEmpty(_sortConfig)) {
            setLoader(true)
        }
        const searchActivities = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchActivities(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _pageNum = num + 1;
            let activities_ = resp.data.data.map(a => CommonHelper.convertActivityLogVM(a, { ...props }))
            if (!_.isEmpty(localFilter)) {
                setfilterPageNum(_pageNum)
            } else {
                setPageNum(_pageNum)
            }
            if (type === 'excel') {
                setActivities(activities_)
                setCSVData(CommonHelper.generateExcelData(activities_, csvHeader, props))
                setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                })
                toast.closeAll();

                let _objLogData = {
                    notes: 'Excel export from dashboard - activities',
                    type: 'excelExport',
                    subType: 'activities',
                    params: JSON.stringify(_filter),
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                CommonHelper.saveAuditLog(_objLogData);

            } else {
                if (type === 'paging') {
                    setActivities(_.uniqBy([...activitylogs, ...activities_], 'documentID'))
                } else {
                    setActivities(activities_)
                }
                // setActivities(_.uniqBy([...activitylogs, ...activities_], 'documentID'))
            }
            if (type === 'generic') {
                setLoader(false)
            }
        }
        else {
            console.log('error: ', resp.data.message)
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
            if (type === 'generic') {
                setLoader(false)
            }
        }
        setTableLoader(false)
    }

    const fetchDirectExcel = () => {
        // console.log('fetchDirectExcel');
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        setTimeout(() => { csvDirectBtn.current && csvDirectBtn.current.handleDownload && csvDirectBtn.current.handleDownload(); }, 1000)
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                if (props.isFromDashboard)
                    if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
                        fetchAct('excel', filterpageNum, activityFilter.value, sortConfig)
                    } else {
                        fetchAct('excel', pageNum, null, sortConfig)
                    }
                else
                    fetchDirectExcel();
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    useEffect(() => {
        if (props.isFromDashboard) {
            fetchAct('generic', pageNum)
        }
    }, [])

    useEffect(() => {
        let headerFields = activityFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allactivityFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allactivityFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allactivityFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        setHeader(_headers);

    }, [activityFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.filter) || _.isEmpty(csvHeader) || props.isFromDashboard) {
            return;
        }
        let _activities = []
        _activities = props.dataList.filter(a =>
            (!_.isEmpty(props.filter.activityIDs) ? props.filter.activityIDs.some(e => e === a.documentID) : true)
            && (_.isEmpty(props.filter.activityIDs) ? a.isDone === props.filter.isDone : true)
            && (props.filter.dateType ? !_.isEmpty(a[props.filter.dateType]) : true)
            && (props.filter.startDate ? a[props.filter.dateType].seconds >= props.filter.startDate.seconds : true)
            && (props.filter.endDate ? a[props.filter.dateType].seconds <= props.filter.endDate.seconds : true)
            && (!_.isEmpty(props.selectedUser) ? _.filter(props.selectedUser.value.split(','), (v) => _.indexOf(a.searchUsers, v) >= 0).length > 0 : true)
            && (!_.isEmpty(props.selectedPipeline) ? (!_.isEmpty(a.enquiry) ? a.enquiry.pipeline === props.selectedPipeline.value : false) : true)
            && (!_.isEmpty(props.selectedMake) ? (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement) && (a.enquiry.requirement.make === props.selectedMake.value || (!_.isEmpty(a.enquiry.requirement.stock) && a.enquiry.requirement.stock.make === props.selectedMake.value))) : true)
            && (!_.isEmpty(props.selectedModel) ? (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement) && (a.enquiry.requirement.model === props.selectedModel.value || (!_.isEmpty(a.enquiry.requirement.stock) && a.enquiry.requirement.stock.model === props.selectedModel.value))) : true)
            && (!_.isEmpty(searchText) ?
                (!_.isEmpty(a.title) ? a.title.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.contactName) ? a.contactName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.contactEmail) ? a.contactEmail.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.phone) ? a.phone.includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.licenseNo) ? a.licenseNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.address) ? a.address.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.companyName) ? a.companyName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.activityOwner) ? a.activityOwner.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.notes) ? a.notes.toLowerCase().includes(searchText.toLowerCase()) : false)
                : true)
        );
        setCSVData(CommonHelper.generateExcelData(_activities, csvHeader, props))
        setActivities(_activities)

    }, [props.dataList, searchText, csvHeader])


    const handleSavesettings = (fields, showToast) => {
        if (!showToast)
            setLoader(true)
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ activityFields: fields }, { merge: true })
            .then(() => {
                setActivityFields(fields)
                setSettingsLoader(false)
                setLoader(false)
                props.updateDealerSettings('activityFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                setLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            handleEditActivity(id)
        }}>
            <i className="ico icon-edit"></i>
        </div>)
    }

    const activityDetailclick = (e, id) => {
        e.preventDefault();
        handleEditActivity(id)
    }

    const handleCompleteActivityContinue = (id, isDone, activityOutcome) => {
        let objLog
        let previousIsDone = false
        if (props.isFromDashboard) {
            objLog = activitylogs.find(item => item.documentID === id);
            previousIsDone = objLog.isDone
        } else {
            objLog = props.dataList.find(item => item.documentID === id);
        }
        if (!_.isEmpty(objLog)) {
            objLog.isDone = isDone
            // dispatch({
            //     type: "UPDATE_DONE_LIST",
            //     data: objLog
            // });
            if (activityOutcome) {
                objLog.activityOutcome = activityOutcome
            }
            if (previousIsDone === true && isDone === false) {
                objLog.activityOutcome = null
            }
            delete objLog.searchUsers;
            firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`)
                .set({
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    isDone: isDone,
                    modifiedFrom: 'web',
                    activityOutcome: objLog.activityOutcome ? objLog.activityOutcome : null
                }, { merge: true })
                .then(snapshot => {
                    // dispatch({
                    //     type: "REMOVE_LIST",
                    //     data: objLog
                    // });
                    try {
                        if (document.getElementById(`done-${objLog.documentID}`)) {
                            document.getElementById(`done-${objLog.documentID}`).checked = isDone
                        }
                    } catch { }

                    toast.notify('Log updated successfully', {
                        duration: 2000
                    })

                    if (previousIsDone !== isDone && props.isFromDashboard) {
                        props.reloadData && props.reloadData()
                        let otherActivities = []
                        if (!previousIsDone && isDone) {
                            otherActivities = activitylogs.filter(act => act.documentID !== objLog.documentID)
                        } else if (previousIsDone && !isDone) {
                            otherActivities = activitylogs.filter(act => act.documentID !== objLog.documentID)
                        }
                        setActivities(otherActivities)
                    }
                    //update enquiry modified date if activity is mark as done
                    if (!_.isEmpty(objLog.enquiryID) && isDone === true) {
                        let objUpdate = {
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                            unactionedNotify: enqUnActionStatus.COMPLETED,
                        }
                        // if (objLog.inbound === true) {
                        //     objUpdate = {
                        //         ...objUpdate,
                        //         bdcStatus: 'show'
                        //     }
                        // }
                        firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                            .set(objUpdate, { merge: true }).then(() => {
                            })
                    }
                    if (isDone === true) {
                        if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                            Swal.fire({
                                title: CommonHelper.showLocale(props, 'Please select'),
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                                cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                                    .set({
                                        bdcStatus: result.value ? 'show' : 'noshow'
                                    }, { merge: true }).then(() => {
                                        if (props.isFromDashboard) {
                                            props.reloadData && props.reloadData()
                                        }
                                    })
                                shedulenextactivity(objLog);
                                if (result.value)
                                    logWalkin(objLog.enquiryID)

                                CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), 'log', 'note', null, props.dealersettings);
                            });
                        }
                        else {
                            shedulenextactivity(objLog);
                        }
                    }

                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
    }

    const handleCompleteActivity = (id, isDone) => {
        let objLog = activitylogs.find(item => item.documentID === id);
        const { dealersettings } = props
        try {
            if (document.getElementById(`done-${id}`)) {
                document.getElementById(`done-${id}`).checked = isDone
            }
        } catch { }
        if (
            dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings &&
            !_.isEmpty(dealersettings.client.settings.activityOutcome) &&
            dealersettings.client.settings.activityOutcome.filter(item => item.active === true).length &&
            !objLog.isDone && isDone &&
            objLog.subType && objLog.subType === 'call'
        ) {
            const isMandatory = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.mandActivityOutcome) ? true : false);
            const activtyOutcomes = [];
            _.orderBy(
                dealersettings.client.settings.activityOutcome.filter(
                    item => item.active === true,
                ),
                ['name'],
                ['asc'],
            ).forEach((data, index) => {
                activtyOutcomes.push({
                    value: data.value,
                    name: data.name,
                    label: data.name,
                    price: data.price,
                });
            });
            // console.log('activtyOutcomes', activtyOutcomes)
            // console.log('isMandatory', isMandatory)
            setShowOutcomeModal({
                show: true,
                clsActive: 'overlay-modal active',
                isMandatory,
                activtyOutcomes,
                data: { id, isDone }
            });
        } else {
            handleCompleteActivityContinue(id, isDone);
        }
    }

    const handleCloseOutcomeModal = (isMandatory, selectedStatus, data) => {
        //console.log('handleCloseOutcomeModal', isMandatory, selectedStatus, data)
        if (isMandatory) {
            if (selectedStatus) {
                handleCompleteActivityContinue(data.id, data.isDone, selectedStatus)
            } else {
                setShowOutcomeModal({
                    show: false,
                    clsActive: '',
                    isMandatory: false,
                    activtyOutcomes: null,
                    data: {}
                });
                return
            }
        } else {
            if (selectedStatus) {
                handleCompleteActivityContinue(data.id, data.isDone, selectedStatus)
            } else {
                handleCompleteActivityContinue(data.id, data.isDone,)
            }
        }
        setShowOutcomeModal({
            show: false,
            clsActive: '',
            isMandatory: false,
            activtyOutcomes: null
        });
    }


    const logWalkin = (enquiryID) => {
        const objData = Object.assign({}, objWalkIn);
        objData.documentID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
        objData.addedBy = localStorage.uid;
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.clientID = props.dealersettings.client.id;
        objData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
        objData.enquiryID = enquiryID;
        objData.walkinDate = window.firebase.firestore.Timestamp.now();

        const refOnjData = firestoreDB(props.dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
        refOnjData.set(objData);
        CommonHelper.logFirstWalkin(enquiryID, objData.walkinDate, props.dealersettings);
    }

    const shedulenextactivity = async (objLog) => {
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.clientSettings && props.dealersettings.client.clientSettings.isScheduleNextActivity) {
            let _isAlreadyScheduled = false;
            if (objLog.enquiryID || objLog?.enquiry?.documentID) {
                const responseMKA = await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', (objLog.enquiryID ? objLog.enquiryID : objLog?.enquiry?.documentID))
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'activity')
                    .where('isDone', '==', false)
                    .limit(1)
                    .get();
                if (responseMKA.docs.length > 0) _isAlreadyScheduled = true;
            }
            if (_isAlreadyScheduled) return;

            const mandScheduleNextActivity = CommonHelper.mandScheduleNextActivity(props.dealersettings, objLog?.enquiry);

            Swal.fire({
                title: CommonHelper.showLocale(props, 'Schedule next activity?'),
                text: CommonHelper.showLocale(props, 'Do you want to schedule next activity?'),
                icon: 'info',
                showCancelButton: mandScheduleNextActivity ? false : true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    setShowScheduleActivityModel({
                        activityLog: {
                            ...objLog,
                            inbound: null
                        },
                        show: true,
                        contact: objLog.contact ? objLog.contact : null,
                        enquiry: objLog.enquiry ? objLog.enquiry : null,
                    })
                }
            })
        }
    }
    const handleEditActivity = async (id) => {
        let editLog
        if (props.isFromDashboard) {
            editLog = activitylogs.find(item => item.documentID === id);
        } else {
            editLog = props.dataList.find(item => item.documentID === id);
        }
        const logVM = Object.assign({}, objActivitylog);
        for (let [key, value] of Object.entries(editLog)) {
            if (logVM[key] !== undefined)
                logVM[key] = value;
        }
        setNewActivitylog(logVM)
        if (logVM.stockID) {
            if (!_.isEmpty(logVM.stock)) {
                setStockObj(logVM.stock)
            }
            else {
                const stockDoc = await window.firebase.firestore().doc('stock/' + logVM.stockID).get();
                if (stockDoc.exists) {
                    const stockData = stockDoc.data()
                    stockData.documentID = stockDoc.id;
                    setStockObj(stockData)
                }
            }
        }
        let _contactID = logVM.contactID ? logVM.contactID : logVM?.contact?.documentID;
        if (_contactID) {
            //if (!_.isEmpty(logVM.contact)) {
            //    setContactObj(logVM.contact);
            //} else {
            const contactDoc = await firestoreDB(props.dealersettings)
                .firestore()
                .doc('contactDetails/' + _contactID)
                .get();
            if (contactDoc.exists) {
                const contactData = contactDoc.data();
                contactData.documentID = contactDoc.id;
                setContactObj(contactData);
            }
            //}
        }
        if (logVM.enquiryID) {
            // if (!_.isEmpty(logVM.enquiry)) {
            //     setEnquiryObj(logVM.enquiry);
            // } else {
            const enquiryDoc = await firestoreDB(props.dealersettings).firestore().doc('enquiries/' + logVM.enquiryID)
                .get();
            if (enquiryDoc.exists) {
                const enquiryData = enquiryDoc.data();
                enquiryData.documentID = enquiryDoc.id;
                setEnquiryObj(enquiryData);
            }
            //}
        }

        setActivityHeader('Edit Activity')
        setShowActivityModel(true)
    }

    const handleActivityModelDelete = (objLog) => {
        if (!_.isEmpty(objLog) && props.isFromDashboard) {
            const updatedActivities = activitylogs.filter(act => act.documentID !== objLog.documentID)
            setActivities(updatedActivities)
            //fetchAct('generic', pageNum)
            props.reloadData && props.reloadData()
        }
        setEnquiryObj(null)
        setContactObj(null)
        setNewActivitylog(null);
        setShowActivityModel(false)
    }

    const handleActivityModelClose = (objLog, isActivtyAlreadyDone) => {
        // check if its Done is change
        if (!_.isEmpty(objLog) && props.isFromDashboard) {
            if (objLog.isDone !== newActivitylog.isDone) {
                let otherActivities = []
                // console.log('objLog.isDone', objLog.isDone)
                // console.log('newActivitylog.isDone', newActivitylog.isDone)
                if (objLog.isDone && !newActivitylog.isDone) {
                    otherActivities = activitylogs.filter(act => act.documentID !== objLog.documentID)
                } else if (!objLog.isDone && newActivitylog.isDone) {
                    otherActivities = activitylogs.filter(act => act.documentID !== objLog.documentID)
                }
                setActivities(otherActivities)
            } else {
                const otherActivities = activitylogs.map(act => {
                    if (act.documentID === objLog.documentID) {
                        return { ...CommonHelper.convertActivityLogVM(objLog, { ...props }) }
                    } else { return act }
                })
                setActivities(otherActivities)
                fetchAct('generic', 0)
            }
            
            setTimeout(() => {
                props.reloadData && props.reloadData();
            }, 2000);
        }
        if (!_.isEmpty(objLog) && objLog.isDone === true && !isActivtyAlreadyDone) {
            if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                Swal.fire({
                    title: CommonHelper.showLocale(props, 'Please select'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                    cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                        .set({
                            bdcStatus: result.value ? 'show' : 'noshow'
                        }, { merge: true })
                    shedulenextactivity(objLog);
                    if (result.value)
                        logWalkin(objLog.enquiryID)

                    CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), CommonHelper.getActivityType('log'), 'note', null, props.dealersettings);
                });
            }
            else {
                shedulenextactivity(objLog);
            }
        }
        setEnquiryObj(null)
        setContactObj(null)
        setStockObj(null)
        setNewActivitylog(null);
        setShowActivityModel(false)
    }

    const handleScheduledActivityModelClose = () => {
        setShowScheduleActivityModel({
            show: false,
            activityLog: null,
            contact: null,
            enquiry: null,
        })
        setTimeout(() => {
            props.reloadData && props.reloadData();
        }, 2000);
    }

    const handlePagination = () => {
        if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
            fetchAct('paging', filterpageNum, activityFilter.value)
        } else {
            if (activitylogs.length >= props.counts) {
                return
            }
            fetchAct('paging', pageNum)
        }
        // fetchAct('paging')
    }

    const filterSearch = (_activitylogs) => {
        return _activitylogs.filter(a =>
        (!_.isEmpty(searchText) ?
            (!_.isEmpty(a.title) ? a.title.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.contactName) ? a.contactName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.contactEmail) ? a.contactEmail.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.phone) ? a.phone.includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.licenseNo) ? a.licenseNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.address) ? a.address.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.companyName) ? a.companyName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.activityOwner) ? a.activityOwner.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.notes) ? a.notes.toLowerCase().includes(searchText.toLowerCase()) : false)
            : true)
        )
    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            fetchAct('generic', 0, _filter.value)
            setactivityFilter(_filter)
            setFilterApplied(true);
            setPageNum(0);
            setfilterPageNum(0)
            if (_filter.value.hasOwnProperty('keywords')) {
                setSearchText(_filter.value.keywords);
            } else {
                setSearchText('');
            }
            // localStorage.enquirySearchText = '';
            // setDataload(true);
            // setPaging(false);
            // setHasMoreData([])
            setSortConfig(null)
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(activityFilter)) {
            setactivityFilter({})
            fetchAct('generic', pageNum)

            // localStorage.removeItem('activityFilter');
            // localStorage.enquirySearchText = '';
            setFilterApplied(false);
            // setDataload(true);
            // setPaging(false);
            // setHasMoreData([])
            setPageNum(0);
            setfilterPageNum(0)
            setSearchText('');
            setSortConfig(null)
        }
    }

    const handlesearchText = (text) => {
        if (text) {
            fetchAct('generic', 0, { keywords: text })
            setactivityFilter({
                name: 'Adv Filter',
                type: 'filter',
                value: { keywords: text }
            })
            setPageNum(0);
            setfilterPageNum(0)
        } else {
            setPageNum(0);
            setfilterPageNum(0)
            setactivityFilter({})
            fetchAct('generic', pageNum)
        }
    }
    const handleDynamicSort = (sortConfig) => {
        setSortConfig(sortConfig);
        setTableLoader(true);
        setPageNum(0);
        if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
            fetchAct('generic', 0, activityFilter.value, sortConfig)
        } else {
            fetchAct('generic', 0, null, sortConfig)
        }
    }
    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportData) ? true : false);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">
                                    {!props.isFromDashboard ? (
                                        <div className="filter-search search-icon">
                                            <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                        </div>
                                    ) : (
                                        <div className={`filter-search`}>
                                            <div className="input-group ">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="isearch"
                                                    placeholder="quick search..."
                                                    value={searchText ? searchText : ''}
                                                    onChange={(e) => {
                                                        let _val = e.target.value
                                                        setSearchText(_val);
                                                        // localStorage.contactSearchText = _val;

                                                        if (_val && _val.length > 2) {
                                                            handlesearchText(_val)
                                                        }

                                                        if (!_val) {
                                                            handlesearchText('')
                                                        }

                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <div className="input-group-append input-search-clear">
                                                    {
                                                        searchText && searchText.length > 0
                                                            ?
                                                            <a href="#"
                                                                className="input-search-clear-icon"
                                                                title="Clear"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSearchText('');
                                                                    // localStorage.contactSearchText = '';
                                                                    handlesearchText('')
                                                                }}
                                                            >
                                                                <i className="ico icon-remove"></i>
                                                            </a>
                                                            :
                                                            <>
                                                            </>
                                                    }

                                                    <button
                                                        className="btn btn-default"
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }}>
                                                        <i className="ico icon-search-fill"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </div>
                                <div className="float-right">

                                    {
                                        (exportData)
                                            ?
                                            <>
                                                <div className="filter-item-btn ">
                                                    {props.isFromDashboard ? (
                                                        <>
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
                                                                    fetchAct('excel', filterpageNum, activityFilter.value, sortConfig)
                                                                } else {
                                                                    fetchAct('excel', pageNum, null, sortConfig)
                                                                }
                                                            }}>
                                                                <i className="ico icon-excel-download"></i>
                                                            </a>
                                                            <ExcelFile ref={csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                                <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                                            </ExcelFile>
                                                            {/* <CSVLink
                                                                ref={csvBtn}
                                                                uFEFF={false}
                                                                data={activitylogs}
                                                                headers={csvHeader}
                                                                filename={"ams-pro-download.csv"}
                                                                style={{ display: 'none' }}
                                                            >
                                                                <i className="ico icon-excel-download"></i>
                                                            </CSVLink> */}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                fetchDirectExcel();
                                                            }}>
                                                                <i className="ico icon-excel-download"></i>
                                                            </a>
                                                            <ExcelFile ref={csvDirectBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                                <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                                            </ExcelFile>
                                                            {/* <CSVLink ref={csvDirectBtn} uFEFF={false} data={activitylogs} headers={csvHeader} style={{ display: 'none' }} filename={"ams-pro-download.csv"}>
                                                                <i className="ico icon-excel-download"></i>
                                                            </CSVLink> */}
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }


                                </div>
                                {/* Activities Filter */}
                                {props.isFromDashboard && (
                                    <Filters
                                        isFromDashboard={true}
                                        searchKeywords={searchKeywords}
                                        activityFilter={activityFilter}
                                        customFilters={customFilters}
                                        dealersettings={props.dealersettings}
                                        handleApplyFilter={handleApplyFilter}
                                        handleClearFilter={handleClearFilter}
                                        clientUsers={props.clientUsers}
                                        groupUsers={props.groupUsers}
                                        showDateFilter={props.showMore}
                                        noSaveAccess={true}
                                    >
                                    </Filters>
                                    // <Filters
                                    //     isFromDashboard={true}
                                    //     searchKeywords={searchKeywords}
                                    //     activityFilter={activityFilter}
                                    //     customFilters={customFilters}
                                    //     dealersettings={props.dealersettings}
                                    //     handleApplyFilter={handleApplyFilter}
                                    //     handleClearFilter={handleClearFilter}
                                    //     clientUsers={props.clientUsers}
                                    //     groupUsers={props.groupUsers}
                                    //     isFilterApplied={isFilterApplied}
                                    //     noSaveAccess={true}
                                    // >
                                    // </Filters>
                                )}
                            </div>

                            {props.isFromDashboard ? loader ? (
                                <>
                                    <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                        <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                                    </div>
                                </>
                            ) : (
                                <div className="common-table">
                                    {
                                        tableLoader
                                            ?
                                            <>
                                                <div className="dashboard-widget-loader h-100">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    <TableView
                                        dealersettings={props.dealersettings}
                                        isReorderable={true}
                                        datalist={filterSearch(activitylogs)}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allactivityFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(activityFields && activityFields.length > 0) ? activityFields.filter(item => item !== 'checkbox' && (props.isReadOnlyView === true ? item !== 'documentID' : true)) : allactivityFields.filter(e => e.default === true && e.value !== 'checkbox' && (props.isReadOnlyView === true ? e.subText !== 'isDone' : true)).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={activityDetailclick}
                                        isSettings={true}
                                        handleCompleteActivity={handleCompleteActivity}
                                        showSNo={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        isSorting={true}
                                        dynamicSort={true}
                                        handleDynamicSort={handleDynamicSort}
                                        sortConfig={sortConfig}
                                        displayByValue={true}
                                    />
                                </div>
                            ) : (
                                <div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={activitylogs}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allactivityFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(activityFields && activityFields.length > 0) ? activityFields.filter(item => item !== 'checkbox' && (props.isReadOnlyView === true ? item !== 'documentID' : true)) : allactivityFields.filter(e => e.default === true && e.value !== 'checkbox' && (props.isReadOnlyView === true ? e.subText !== 'isDone' : true)).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={activityDetailclick}
                                        isSettings={true}
                                        handleCompleteActivity={handleCompleteActivity}
                                        showSNo={true}
                                        displayByValue={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={showActivityModel}>
                <AddActivity
                    show={showActivityModel}
                    handleClose={handleActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={activityHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={newActivitylog}
                    enquiry={enquiryObj}
                    contact={contactObj}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={false}
                    isReadOnlyView={props.isReadOnlyView}
                >
                </AddActivity>
            </PopUpModal>
            <PopUpModal show={showScheduleActivityModel.show}>
                <AddActivity
                    show={showScheduleActivityModel.show}
                    handleClose={handleScheduledActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={'Add New Activity'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={showScheduleActivityModel.activityLog}
                    enquiry={enquiryObj ? enquiryObj : showScheduleActivityModel.enquiry ? showScheduleActivityModel.enquiry : null}
                    contact={contactObj ? contactObj : showScheduleActivityModel.contact ? showScheduleActivityModel.contact : null}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={true}
                    isScheduleActivity={true}
                    isReadOnlyView={props.isReadOnlyView}
                // activeTab={activeTab}
                >
                </AddActivity>
            </PopUpModal>

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>
            <PopUpModal show={showOutcomeModal.show}>
                <ActivityStatus
                    show={showOutcomeModal.show}
                    isMandatory={showOutcomeModal.isMandatory}
                    activtyOutcomes={showOutcomeModal.activtyOutcomes}
                    data={showOutcomeModal.data}
                    handleClose={handleCloseOutcomeModal}
                    title={'Select Activity Outcome'}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>
        </>
    );
}

export default ActivityList;