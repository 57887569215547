import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';

import { StageProvider, StageConsumer } from '../pipeline/provider'
import { LayoutConsumer } from '../layout/provider';
import Translate from '../../constants/translate';
import { ContentOverlay, SidePanel, TableView, PopUpModal } from '../../components'
import QuickView from "../pipeline/quickview";
import FilterPanel from './filterPanel'
import CommonHelper from '../../services/common';

import images from '../../images'
import ReAuthenticate from '../common/reAuthenticate';
import TransferOwner from '../common/transfer'
import { objEnquiryListVM, allenquiryFields } from '../pipeline/viewModel'
import { firestoreDB } from '../../services/helper';

const EnquiryListReducer = (state, action) => {
    function updateEnquiry() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_ENQUIRY_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_ENQUIRY_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "REMOVE_ENQUIRY": {
            return state.filter(item => item.documentID !== action.documentID)
        }
        case "UPDATE_ENQUIRY_LIST": {
            return updateEnquiry()
        }
        default:
            return state;
    }
};

const _pipelines = [{
    active: true,
    default: true,
    modules: {
        cafe: true,
        contact: true,
        files: true,
        requirement: true,
        finance: false,
        testDrive: false,
        tradeIn: false
    },
    value: 'AfterSales',
    name: 'After Sales',
    stages: [{
        name: 'Inbound Lead',
        value: 'inbound-lead'
    }, {
        name: 'New Enquiry',
        value: 'new-enquiry'
    },
    {
        name: 'In Progress',
        value: 'in-progress'
    },
    {
        name: 'Qualified',
        value: 'qualified'
    },
    {
        name: 'Appoitment Scheduled',
        value: 'appoitment-scheduled'
    },
    {
        name: 'Won',
        value: 'won'
    },
    {
        name: 'Lost',
        value: 'lost'
    }]
}];

const PipelineListView = (props) => {
    const [enquiries, dispatch] = useReducer(EnquiryListReducer, [])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [enquiryFields, setEnquiryFields] = useState(props.dealersettings.enquiryFields)
    const pageLimit = 50
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.enquirySearchText ? localStorage.enquirySearchText : '')
    const [enquiryLoader, setEnquiryLoader] = useState(true)
    const [defaultPipeline, setDefaultPipeline] = useState('AfterSales')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [customFilters, setCustomFilters] = useState([])

    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})
    const [modalTransferShow, setModalTransferShow] = useState(false)

    const [pipelineCount, setPipelineCount] = useState(0)

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    //const [enquiryTypes, setEnquiryTypes] = useState([])
    const [enquiryOptions, setEnquiryOptions] = useState([])
    //const [campaigns, setCampaigns] = useState([])
    //const [origins, setOrigins] = useState([])
    const [financeStatuses, setFinanceStatuses] = useState([])
    //const [lostReasons, setLostReasons] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    //const [countries, setCountries] = useState([])
    //const [states, setStates] = useState([])
    const [enquiryAllStatus, setAllStatus] = useState([]);

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])

    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const _moduleSettings = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings)) ? props.dealersettings.client.moduleSettings : null);

    const financeEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.finance) &&
        _moduleSettings.finance.enabled) ? true : false);

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers



    //#region LOAD_SETTINGS
    useEffect(() => {
        let headerFields = enquiryFields;
        let allHeaderFields = allenquiryFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        setHeader(_headers);

    }, [enquiryFields])

    useEffect(() => {
        if (_.isEmpty(enquiryOptions))
            return
        enquiryOptions.forEach(rec => {
            if (!allenquiryFields.some(e => e.value === rec.value))
                allenquiryFields.push({
                    name: rec.label,
                    value: rec.value,
                    flex: 1,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        enquiryFields.forEach(rec => {
            if (!allenquiryFields.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setEnquiryFields(_.differenceBy(enquiryFields, deleteids))
    }, [enquiryOptions])


    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });


            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                _origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                _enquiryTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _enquiryOptions = [];
            setttings.enquiryOptionsDF && setttings.enquiryOptionsDF.forEach(doc => {
                _enquiryOptions.push({
                    ...doc,
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _financeStatuses = [];
            setttings.financeStatus && setttings.financeStatus.forEach(doc => {
                _financeStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                _campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _pipelines = [];
            setttings.pipelines && setttings.pipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _countries = [];
            props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
                _countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _states = [];
            props.dealersettings.states && props.dealersettings.states.forEach((doc) => {
                _states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _lostReasons = [];
            const _lostSubReasons = [];
            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });

                !_.isEmpty(doc.subList) && doc.subList.forEach(docsub => {
                    _lostSubReasons.push({
                        value: docsub.value,
                        label: docsub.name
                    });
                });
            });

            let _enquiryAllStatus = [];
            setttings.enquiryStatus && setttings.enquiryStatus.forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });

            setAllStatus(_enquiryAllStatus);
            setTitles(_titles);
            //setEnquiryTypes(_enquiryTypes);
            setEnquiryOptions(_enquiryOptions.filter(e => e.active === true));
            //setCampaigns(_campaigns);
            //setOrigins(_origins);
            setFinanceStatuses(_financeStatuses)
            setLanguages(_languages);
            setNationalities(_nationalities)
            //setLostReasons(_lostReasons);
            setLostSubReasons(_lostSubReasons);
            //setCountries(_countries);
            //setStates(_states);
            setDataload(true);
        }
    }, [])

    useEffect(() => {
        if (isFilterApplied || _pipelines.length === 0)
            return
        const { dealersettings } = props;
        const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);
        const dpl = 'AfterSales'
        var querySet = `clients/${dealersettings.client.id}`
        // if (!viewOtherEnquiry) {
        //     querySet = `users/${localStorage.uid}/userClients/${props.dealersettings.client.id}`
        // }
        if (dealersettings && localStorage.defaultModule === 'oem') {
            if (dealersettings.level === 'oem' && dealersettings.oemID)
                querySet = `clientSettings/${dealersettings.oemID}/counts/${dealersettings.oemID}`
            else if (dealersettings.level === 'group' && dealersettings.groupID)
                querySet = `clientSettings/${dealersettings.oemID}/groups/${dealersettings.groupID}/counts/${dealersettings.groupID}`
            else if (dealersettings.level === 'region' && dealersettings.regionID)
                querySet = `clientSettings/${dealersettings.oemID}/regions/${dealersettings.regionID}/counts/${dealersettings.regionID}`
        }

        if (!viewOtherEnquiry) {
            querySet = `users/${localStorage.uid}/userClients/${dealersettings.client.id}`
            if (dealersettings && localStorage.defaultModule === 'oem') {
                if (dealersettings.level === 'oem' && dealersettings.oemID)
                    querySet = `users/${localStorage.uid}/counts/oem`
                else if (dealersettings.level === 'group' && dealersettings.groupID)
                    querySet = `users/${localStorage.uid}/counts/group`
                else if (dealersettings.level === 'region' && dealersettings.regionID)
                    querySet = `users/${localStorage.uid}/counts/region`
            }
        }



        window.unSubPipelineLstCount = window.firebase.firestore().doc(querySet)
            .onSnapshot(docSnapshot => {
                if (docSnapshot.exists && docSnapshot.data().counts && docSnapshot.data().counts.pipeline && docSnapshot.data().counts.pipeline[dpl] && docSnapshot.data().counts.pipeline[dpl].stages) {
                    var stageCount = {};
                    _pipelines.find(item => item.value === 'AfterSales').stages.forEach(stage => {
                        stageCount = dpl === 'AfterSales' && (stage.value === 'Converted' || stage.value === 'InboundLead') ? {
                            ...stageCount, [stage.value]: { ['total']: docSnapshot.data().counts.pipeline["AfterSales"].stages[stage.value] }
                        }
                            : {
                                ...stageCount,
                                [stage.value]: {
                                    ...docSnapshot.data().counts.pipeline[dpl].stages[stage.value],
                                    ['total']: sumObj(docSnapshot.data().counts.pipeline[dpl].stages[stage.value])
                                }
                            }
                    })
                    setPipelineCount(stageCount)
                }

            })
    }, [props.pipeline, isFilterApplied])

    const sumObj = (obj) => {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum;
    }

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'enquiry')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubinboundlist && window.unsubinboundlist();
            window.unsubenquirylist && window.unsubenquirylist();
            window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.pipelineFilter', isFilterApplied, localStorage.pipelineFilter)
        if (localStorage.pipelineFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.pipelineFilter])
    //#endregion

    //#region PAGE_LOAD


    useEffect(() => {
        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        if (_pipelines.length === 0) {
            return
        }
        if (!checkDataLoad && defaultPipeline === 'AfterSales') {
            return;
        }
        if (defaultPipeline !== 'AfterSales') {
            setEnquiryLoader(true)
        }

        if (isFilterApplied && pageNum === 0)
            setEnquiryLoader(true)


        const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        if ((isFilterApplied || localStorage.pipelineFilter) && isValidFilter()) {
            let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
            let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            _filter.pipeline = 'AfterSales';

            if (!viewOtherEnquiry)
                _filter.owner = dealersettings.id;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                delete _filter['date'];
            }

            /* MAKE & MODEL FILTER */
            if (!_.isEmpty(_filter.make))
                _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

            if (_filter.rottenDays &&
                !_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.settings) &&
                !_.isEmpty(dealersettings.client.settings.pipelines)) {
                _filter.pipelineSettings = JSON.stringify(dealersettings.client.settings.pipelines)
            }

            _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings, null, null, true);
            _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
            let _searchObject = {
                "type": "searchEnquiries",
                "filters": JSON.stringify(_filter),
                "sortOrder": "stageDate desc",
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }

            _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, null, null, true);

            //console.log('filter-searchEnquiries', _searchObject, clientID, pageNum, _filter);
            const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
            searchEnquiries(_searchObject).then((response) => {
                //console.log('generic-searchData', _rec, response);
                if (response.data.success && localStorage.pipelineFilter && isValidFilter()) {
                    onCollectionEnquiry(response.data);
                    setPipelineCount({
                        advance: {
                            total: response.data.total
                        }
                    })
                }
                else {
                    setEnquiryLoader(false)
                    setDataload(false)
                    setPaging(false)
                }
            });

            window.unsubenquirylist && window.unsubenquirylist();
            window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        }
        else {

            let searchKeywords = searchText;
            let searchOwners = [];

            if (localStorage.pipelineFilter) {
                let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);

                searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

                setSearchText(searchKeywords);
                localStorage.enquirySearchText = searchKeywords;
                //console.log('search---Keywords', searchKeywords, searchOwners, searchText)
            }

            let refEnquiryData = firestoreDB(dealersettings).firestore().collection('enquiries')
                .where('clientID', '==', props.dealersettings.client.id)
                .where('pipeline', '==', 'AfterSales')
                .where('isDeleted', '==', false)



            if (searchKeywords) {
                let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                if (formattedSearchText.trim())
                    refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
            }

            if (!viewOtherEnquiry)
                refEnquiryData = refEnquiryData.where('owner', 'in', [localStorage.uid])
            else if (!_.isEmpty(searchOwners))
                refEnquiryData = refEnquiryData.where('owner', 'in', searchOwners)

            refEnquiryData = refEnquiryData
                .orderBy('stageDate', 'desc')

            if (hasMore.length > 0 && defaultPipeline === 'AfterSales') {
                refEnquiryData = refEnquiryData
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                refEnquiryData = refEnquiryData
                    .limit(pageLimit)
            }

            window.unsubenquirylist = refEnquiryData
                .onSnapshot(onCollectionUpdate);
        }


    }, [checkDataLoad, props.pipeline, props.dealersettings])



    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = CommonHelper.convertEnquiryVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                }, props);
                if (change.type === 'added') {
                    actionType = "ADD_ENQUIRY_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_ENQUIRY_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_ENQUIRY_LIST"
                }
            }
        })
        const _enquiries = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach(function (doc) {
                let logVM = doc.data();
                if ((props.dealersettings.level === 'oem' && !Boolean(logVM.isConvertedOEM)) ||
                    (props.dealersettings.level === 'region' && !Boolean(logVM.isConvertedRegion)) ||
                    (props.dealersettings.level === 'group' && !Boolean(logVM.isConvertedGroup))
                ) {
                    _enquiries.push(CommonHelper.convertEnquiryVM({
                        ...logVM,
                        documentID: doc.id,
                        stageDate: logVM.stageDate ? logVM.stageDate : logVM.addedDate,
                    }, props));
                }
                else if (localStorage.defaultModule !== 'oem') {
                    _enquiries.push(CommonHelper.convertEnquiryVM({
                        ...logVM,
                        documentID: doc.id,
                        stageDate: logVM.stageDate ? logVM.stageDate : logVM.addedDate,
                    }, props));
                }
            });
        }
        dispatch({
            type: actionType ? actionType : isPaging ? "APPEND_LIST" : "SUCCESS",
            data: actionType ? snapshotDoc : _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        setDefaultPipeline('AfterSales')
    }

    const onCollectionEnquiry = (querySnapshot) => {

        const _enquiries = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            //console.log('doc', doc)
            var logVM = CommonHelper.convertEnquiryVM(doc, props);
            logVM.stageDate = logVM.stageDate ? logVM.stageDate : logVM.addedDate;
            _enquiries.push(logVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS",
            data: _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        setDefaultPipeline('AfterSales')
    }

    /*const convertEnquiryVM = (doc) => {

        doc.stageDate = doc.stageDate && doc.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stageDate._seconds)._d) : doc.stageDate;
        doc.convertedDate = doc.convertedDate && doc.convertedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDate._seconds)._d) : doc.convertedDate;
        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
        doc.wonDate = doc.wonDate && doc.wonDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.wonDate._seconds)._d) : doc.wonDate;
        doc.lostDate = doc.lostDate && doc.lostDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lostDate._seconds)._d) : doc.lostDate;
        doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;

        doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;
        doc.confirmedDate = doc.confirmedDate && doc.confirmedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.confirmedDate._seconds)._d) : doc.confirmedDate;
        doc.verifiedDate = doc.verifiedDate && doc.verifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.verifiedDate._seconds)._d) : doc.verifiedDate;
        doc.receivedDate = doc.receivedDate && doc.receivedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.receivedDate._seconds)._d) : doc.receivedDate;

        doc.stageHistory = [..._.map(doc.stageHistory, function (obj) {
            return obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]


        const objenquiryData = Object.assign({}, doc);
        const listVM = Object.assign({}, objEnquiryListVM);
        for (let [key, value] of Object.entries(objenquiryData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }
        listVM.objDoc = Object.assign({}, objenquiryData);
        listVM.displayID = listVM.displayID ? listVM.displayID : '--';
        listVM.originValue = doc.origin;
        listVM.enquiryTypeValue = doc.enquiryType;
        listVM.leadSourceValue = doc.leadSource;
        listVM.campaignValue = doc.campaign;
        listVM.statusValue = doc.stage === 'InboundLead' ? 'pending' : doc.status;
        listVM.labelValue = doc.label;
        listVM.pipelineValue = doc.pipeline;
        listVM.stageValue = doc.stage;
        listVM.ownerValue = doc.owner;
        listVM.statusName = doc.stage === 'InboundLead' ? 'pending' : CommonHelper.getNameByValue(enquiryAllStatus, doc.status);
        listVM.financeStatusName = doc.financeStatus;
        listVM.labelName = doc.label;

        let dealersettings = props.dealersettings;

        listVM.pipeline = CommonHelper.getNameByValue(props.dealersettings.allPipelines, doc.pipeline, '');
        if (_.find(props.dealersettings.allPipelines, { value: doc.pipeline }) && doc.stage !== 'InboundLead') {
            listVM.stage = CommonHelper.getNameByValue(_.find(props.dealersettings.allPipelines, { value: doc.pipeline }).stages, doc.stage, '');
        }
        listVM.appointmentDateOn = doc.appointmentDate ? moment.unix(doc.appointmentDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.bdcStatusName = doc.bdcStatus === 'show' ? 'Show' : doc.bdcStatus === 'noshow' ? 'No Show' : doc.bdcStatus === 'allocated' ? 'Allocated' : '';
        listVM.strResponseTime = doc.responseTime > 0 ? CommonHelper.timeformatfromSeconds(doc.responseTime, props.dealersettings.client) : '';
        listVM.strAllocatedTime = doc.allocatedTime > 0 ? CommonHelper.timeformatfromSeconds(doc.allocatedTime, props.dealersettings.client) : '';
        listVM.strContactedTime = doc.contactedTime > 0 ? CommonHelper.timeformatfromSeconds(doc.contactedTime, props.dealersettings.client) : '';  listVM.convertedOn = doc.convertedDate ? moment.unix(doc.convertedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.wonOn = doc.wonDate && doc.status && (doc.status.toLowerCase() === 'won' || doc.status.toLowerCase() === 'delivered') ? moment.unix(doc.wonDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.lostOn = doc.lostDate && doc.status && (doc.status.toLowerCase() === 'lost' || doc.status.toLowerCase() === 'prospectLost') ? moment.unix(doc.lostDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.deliveredOn = doc.deliveredDate && doc.status && doc.status.toLowerCase() === 'delivered' ? moment.unix(doc.deliveredDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.warrantyExpiry = doc.warrantyExpiry ? moment(doc.warrantyExpiry, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        listVM.warrantyStartDate = doc.warrantyStartDate ? moment(doc.warrantyStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        listVM.deliveryDate = doc.deliveryDate ? moment.unix(doc.deliveryDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.confirmedBy = doc.confirmedBy ? CommonHelper.getUserNamebyId(allUsers, doc.confirmedBy) : '';
        listVM.confirmedDate = doc.confirmedDate ? moment.unix(doc.confirmedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.verifiedBy = doc.verifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.verifiedBy) : '';
        listVM.verifiedDate = doc.verifiedDate ? moment.unix(doc.verifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.receivedDate = doc.receivedDate ? moment.unix(doc.receivedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOwner = doc.convertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.convertedBy) : '';

        listVM.convertedOnOEM = doc.convertedDateOEM ? moment.unix(doc.convertedDateOEM.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOnRegion = doc.convertedDateRegion ? moment.unix(doc.convertedDateRegion.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOnGroup = doc.convertedDateGroup ? moment.unix(doc.convertedDateGroup.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOwnerOEM = doc.oemConvertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
        listVM.convertedOwnerRegion = doc.oemConvertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
        listVM.convertedOwnerGroup = doc.oemConvertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';


        let _setttings = doc.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

        if (_setttings) {
            listVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, doc.campaign, '');
            listVM.origin = CommonHelper.getNameByValue(_setttings.origins, doc.origin, '');

            listVM.enquiryType = CommonHelper.getNameByValue(_setttings.enquiryTypes, doc.enquiryType, '');
            listVM.leadSource = CommonHelper.getNameByValue(_setttings.leadSource, doc.leadSource, '');
            listVM.lostReason = CommonHelper.getNameByValue(_setttings.lostReasons, doc.lostReason, '');
            listVM.labelName = CommonHelper.getNameByValue(_setttings.enquiryLabels, doc.label);
            listVM.label = CommonHelper.bindEnquiryLabel(_setttings.enquiryLabels, doc.label);
        }

        //listVM.lostReason = CommonHelper.getLabelByValue(lostReasons, doc.lostReason, '');
        listVM.lostSubReason = CommonHelper.getLabelByValue(lostSubReasons, doc.lostSubReason, '');
        //listVM.origin = CommonHelper.getLabelByValue(origins, doc.origin, '');
        //listVM.enquiryType = CommonHelper.getLabelByValue(enquiryTypes, doc.enquiryType, '');
        //listVM.campaign = CommonHelper.getLabelByValue(campaigns, doc.campaign, '');
        

        if (financeEnabled)
            listVM.financeStatus = doc.financeStatus ? (<div className={`badge badge-pill badge-${doc.financeStatus.toLowerCase()} ml-1`}>{doc.financeStatus.toUpperCase()}</div>) : '';
        else
            listVM.financeStatus = CommonHelper.getLabelByValue(financeStatuses, doc.financeStatus, '');

            let _objCurrentStatus = doc.status && enquiryAllStatus.filter(e => e.value === doc.status)[0];
        if (doc.stage === 'InboundLead')
            listVM.status = <span className={`status-pending`}>{'Pending'}</span>;
        else
        listVM.status = doc.status ? (
            _objCurrentStatus
                ?
                <>
                    <span
                        className={`status-${doc.status === 'open' ? 'open' : 'empty'}`}
                        style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                    >
                        {_objCurrentStatus.name}
                    </span>
                </>
                :
                <>
                    
                </>
        ) : '';

        listVM.stageDate = doc.stageDate ? moment.unix(doc.stageDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.owner = CommonHelper.getUserNamebyId(allUsers, doc.owner);
        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.addedBy = CommonHelper.getUserNamebyId(allUsers, doc.addedBy);
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.modifiedBy = doc.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.modifiedBy) : '';
        listVM.overdueActivity = 0;
        listVM.todayActivity = 0;
        listVM.upcomingActivity = 0;
        listVM.doneActivity = 0;
        listVM.totalActivity = 0;
        listVM.depositAmount = doc.depositAmount ? currencySymbol + doc.depositAmount : '';
        listVM.grossAmount = doc.grossAmount ? currencySymbol + doc.grossAmount : '';
        listVM.csvQuoteAmount = !_.isEmpty(doc.quotation) && doc.quotation.amount ? currencySymbol + doc.quotation.amount : '';

        if (doc.clientID && (doc.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            listVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, doc.clientID);
        }
        else
            listVM.dealerName = '';

        listVM.quoteAmount = !_.isEmpty(doc.quotation) && doc.quotation.amount ? <NumberFormat
            thousandSeparator={true}
            prefix={currencySymbol}
            displayType={'text'}
            value={doc.quotation.amount}
        /> : '';

        listVM.enquiryAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '');
        _pipelines.filter(e => e.default)[0].stages.forEach(col => {
            if (listVM.stage === col.value) {
                if (col.rottenDays) {
                    var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                    listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
                }
            }
        })

        if (!_.isEmpty(doc.requirement) && (!_.isEmpty(doc.requirement.make) || !_.isEmpty(doc.requirement.stock))) {
            listVM.vehicleModel = (!_.isEmpty(doc.requirement.stock) && doc.requirement.stock.make) ? doc.requirement.stock.make + ' ' + doc.requirement.stock.model : (doc.requirement.make ? doc.requirement.make + ' ' + doc.requirement.model : '')
            listVM.vehicleYear = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.year : doc.requirement.year;

            listVM.vehicleSaletype = CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, doc.requirement.saleType, '');
            listVM.vehicleType = listVM.vehicleModel ? <>
                <>{listVM.vehicleSaletype ? <>{listVM.vehicleSaletype}</> : <></>}</>
                <>{listVM.soldCount > 1 ? <div className="badge badge-pill badge-available badge-mini ml-1">{listVM.soldCount} WON</div> : <></>}</>
            </> : <></>;
            listVM.vehicleBodytype = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
        }
        if (!_.isEmpty(doc.contact)) {
            listVM.contactName = CommonHelper.displayFullContact(titles, doc.contact);
            listVM.contactNumber = doc.contact.displayID;
            listVM.phone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone);
            listVM.displayPhone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings, true);
            listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''
            listVM.email = doc.contact.email;
            listVM.licenseNo = doc.contact.licenseNo;
            listVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
            listVM.contactType = doc.contact.contactType;
            listVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
            listVM.gender = doc.contact.gender;
            listVM.maritalStatus = doc.contact.maritalStatus;
            listVM.contactMethod = doc.contact.contactMethod;
            listVM.licenseType = doc.contact.licenseType;
            listVM.language = CommonHelper.getLabelByValue(languages, doc.contact.language, '');
            listVM.nationality = CommonHelper.getLabelByValue(nationalities, doc.contact.nationality, '');
            // listVM.campaign = CommonHelper.getLabelByValue(campaigns, doc.contact.campaign, '');
            // listVM.origin = CommonHelper.getLabelByValue(origins, doc.contact.origin, '');
            let _setttings = doc.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings;
            if (_.isEmpty(_setttings))
                _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

            if (_setttings) {
                listVM.gender = CommonHelper.getNameByValue(_setttings.genders, doc.contact.gender, '');
                listVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
                listVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
                listVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
                listVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');

                listVM.contactcampaign = CommonHelper.getNameByValue(_setttings.campaigns, doc.contact.campaign, '');
                listVM.contactorigin = CommonHelper.getNameByValue(_setttings.origins, doc.contact.origin, '');
            }

            // if (doc.contact.clientID && (doc.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            //     listVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, doc.contact.clientID);
            // }
            // else
            //     listVM.dealerName = '';

            listVM.address = doc.contact.address;
            listVM.companyName = doc.contact.companyName;
            listVM.designation = doc.contact.designation;

            listVM.licenseState = doc.contact.licenseState;
            listVM.secondaryName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
            listVM.secondaryPhone = CommonHelper.phoneFormat(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone);
            listVM.secondaryEmail = doc.contact.secondaryEmail;
            listVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
            listVM.optinPhone = doc.contact.optinPhone === false ? 'Yes' : '';
            listVM.optinEmail = doc.contact.optinEmail === false ? 'Yes' : '';
            listVM.optinPost = doc.contact.optinPost === false ? 'Yes' : '';
        }
        else if (!_.isEmpty(doc.engageNewContact)) {
            let _contact = Object.assign({}, doc.engageNewContact);
            listVM.contactName = CommonHelper.displayFullContact([], _contact, '', true);
            listVM.phone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone);
            listVM.contactPhone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
            listVM.phoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
            listVM.email = _contact.email ? _contact.email : '';
            listVM.csvPhone = listVM.contactPhone ? `${listVM.contactPhone}` : ''
        }

        if (!_.isEmpty(doc.dynamicFields)) {

            enquiryOptions.forEach(rec => {
                if (rec.type === 'toggle') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                else if (rec.type === 'price') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? currencySymbol + doc.dynamicFields[rec.value] : ''
                }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    listVM[`${rec.value}Value`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                        {
                            doc.dynamicFields[rec.value].map((rec, index) => {
                                return <div key={index}><span>{rec}</span></div>
                            })
                        }
                    </div>) : (<></>)
                }
                else {
                    listVM[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        if (!_.isEmpty(doc.td)) {
            listVM.testdriveModel = !_.isEmpty(doc.td) && !_.isEmpty(doc.td.make) ? doc.td.make + ' ' + doc.td.model : ''
            listVM.testdriveSaletype = CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, doc.td.saleType, '');
        }

        if (!_.isEmpty(doc.tags) && _.isArray(doc.tags)) {
            listVM.domleadSource = (<div className="Vehicle-availability">
                {
                    doc.tags.map((rec, index) => {
                        return <div key={index}><span>{CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)}</span></div>
                    })
                }
            </div>);
            listVM.strleadSource = doc.tags.map((rec, index) => {
                return CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)
            }).join(',');
        }

        return listVM
    }*/
    //#endregion

    //#region CHANGE_EVENTS
    const isValidFilter = () => {
        if (localStorage.pipelineFilter) {
            let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ enquiryFields: fields }, { merge: true })
            .then(() => {
                setEnquiryFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('enquiryFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, obj) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            sidepanelOpen(id)
        }}>
            <i className="ico icon-more"></i>
        </div>)
    }

    const enquiryDetailclick = (e, id, obj) => {
        sidepanelOpen(id)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const handleFavorite = (id, val) => {
        const enq = enquiries.find(item => item.documentID === id);
        if (!_.isEmpty(enq)) {
            enq.isFav = val
            dispatch({
                type: "UPDATE_ENQUIRY_LIST",
                data: enq
            });


            firestoreDB(props.dealersettings).firestore().doc(`enquiries/${id}`).set({
                isFav: Boolean(val)
            }, { merge: true })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });


        }
    }

    const updateListEnquiry = (objEnquiry) => {
        if (!_.isEmpty(objEnquiry)) {
            let enq = CommonHelper.convertEnquiryVM(objEnquiry, props);

            if (!_.isEmpty(enq)) {
                dispatch({
                    type: "UPDATE_ENQUIRY_LIST",
                    data: enq
                });
            }
        }

    }

    const handleStatusFilter = (_status) => {
        if (_status) {
            if (!_.isEmpty(localStorage.pipelineFilter)) {
                let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
                if (!_.isEmpty(_pipelineFilter.value)) {
                    // update with the existing keyword
                    _pipelineFilter.value['status'] = _status
                }
                else {
                    // update with the new keyword
                    _pipelineFilter.value = { 'status': _status }
                }
                handleApplyFilter(_pipelineFilter);
            }
            else {
                // create new filter with keyword
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'status': _status }, type: 'filter' })
                handleApplyFilter(_pipelineFilter);
            }
        }
        else if (!_.isEmpty(localStorage.pipelineFilter)) {
            let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);
            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('status'))
                handleClearFilter();
            else {
                delete _pipelineFilter.value['status'];
                handleApplyFilter(_pipelineFilter);
            }
        }
    }

    const handleSearchText = (val) => {
        setSearchText(val);
        if (!_.isEmpty(_.trim(val))) {
            if (localStorage.pipelineFilter) {
                let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);
                _filter['keywords'] = _.trim(val);
                _pipelineFilter.value = _filter;
                handleApplyFilter(_pipelineFilter);
            }
            else {
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': val }, type: 'filter' })
                handleApplyFilter(_pipelineFilter);
            }
        }
        else {
            handleClearFilter();
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('pipelineFilter', JSON.stringify(_filter));
            //localStorage.enquirySearchText = '';
            setFilterApplied(true);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            //setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.pipelineFilter)) {
            localStorage.removeItem('pipelineFilter');
            localStorage.enquirySearchText = '';
            setFilterApplied(false);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
        }
    }

    //#endregion

    //#region EXCEL_DOWNLOAD
    const excelDownload = async (total) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
        let _pipelineFilter = localStorage.pipelineFilter ? JSON.parse(localStorage.pipelineFilter) : {};
        let _filter = Object.assign({}, _pipelineFilter.value);
        _filter.pipeline = 'AfterSales';
        const viewOtherEnquiry = ((!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        if (!viewOtherEnquiry)
            _filter.owner = props.dealersettings.id;

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        /* MAKE & MODEL FILTER */
        if (!_.isEmpty(_filter.make))
            _filter = CommonHelper.handleMakeModelFilter(_filter, props.dealersettings);

        if (_filter.rottenDays &&
            !_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.client) &&
            !_.isEmpty(props.dealersettings.client.settings) &&
            !_.isEmpty(props.dealersettings.client.settings.pipelines)) {
            _filter.pipelineSettings = JSON.stringify(props.dealersettings.client.settings.pipelines)
        }
        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> downloading...</div>),
            {
                position: 'top',
                duration: null
            },
        )
        _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, props.dealersettings, null, null, true);
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()

        let _searchObject = {
            "type": "searchEnquiries",
            "filters": JSON.stringify(_filter),
            "sortOrder": "stageDate desc",
            "pageNum": 0,
            "pageLimit": total > 999 ? 5000 : (total ? total : 5000)
        }
        _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings, null, null, true);

        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = CommonHelper.convertEnquiryVM(doc, props);
                _enquiries.push(logVM)
            });

            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props));
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from pipeline',
                type: 'excelExport',
                subType: 'enquiries',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }
    }
    //#endregion

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = [];
                for (var i in pipelineCount) {
                    if (i !== 'Converted')
                        dataArray.push(pipelineCount[i]);
                }
                let count = _.sumBy(dataArray, "total")
                excelDownload(count);
            }, 1000);
        }
        setReAuthentication(false);

    }

    //#endregion


    //#region SELECT & TRANSFER
    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries) && enquiries.map(file => {
                let record = Object.assign({}, enquiries.filter(e => e.documentID === file.documentID)[0]);
                if (!_.isEmpty(record))
                    _selectedIds[file.documentID] = record.objDoc;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleShowTransferEnquiries = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select one or more lead(s) to transfer.'));
            return;
        }
        else {
            setModalTransferShow(true)
        }
    }


    const handleCloseTransferEnquiries = async (owner) => {
        if (owner) {
            let ObjData = {
                owner: owner,
                modifiedBy: props.dealersettings ? props.dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                bulkDt: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
            }

            const batch = firestoreDB(props.dealersettings).firestore().batch();

            let _selectedIds = Object.keys(Object.assign({}, selectedIds)).slice(0, 50).reduce((result, key) => {
                result[key] = selectedIds[key];
                return result;
            }, {});

            for (let [key, value] of Object.entries(_selectedIds)) {
                let _selectedEnq = Object.assign({}, value);
                await firestoreDB(props.dealersettings).firestore().collection('enquiries')
                    .doc(key)
                    .update(ObjData).then((docRef) => {
                        for (let [key, value] of Object.entries(ObjData)) {
                            _selectedEnq[key] = value;
                        }
                        updateListEnquiry(_selectedEnq);
                    }).catch((error) => {
                        console.error("Error updating enquiries: ", error);
                    });

                await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', key)
                    .where('isDeleted', '==', false)
                    .where('isDone', '==', false)
                    .get().then(response => {
                        response.docs.forEach((doc) => {
                            batch.update(doc.ref, ObjData);
                        });
                    });
            }

            batch.commit()
                .then(() => {
                    setSelectedIds({});
                    setModalTransferShow(false)
                    if (localStorage.pipelineFilter) {
                        let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
                        handleApplyFilter(_pipelineFilter);
                    }
                    let _leadsSize = Object.keys(_selectedIds).length;
                    toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} Leads transferred successfully`, {
                        duration: 2000
                    })
                })
                .catch((error) => {
                    console.log(error);
                    setModalTransferShow(false)
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else {
            setModalTransferShow(false);
        }

    }


    return (
        <LayoutConsumer>
            {({ pipeline, handlePipelineChange, dealersettings, clientUsers, groupUsers }) => dealersettings ? (


                <div className={`middle-wrapper ${pipeline.length === 0 ? 'h-100' : ''}`}>

                    {
                        pipeline.length === 0 ? (<></>) : (<FilterPanel
                            pipeline={_pipelines}
                            customFilters={customFilters}
                            handlePipelineChange={(val) => {
                                handlePipelineChange(val);
                                setPageNum(0);
                                if (!_.isEmpty(localStorage.pipelineFilter))
                                    localStorage.removeItem('pipelineFilter');
                            }}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            handleApplyFilter={handleApplyFilter}
                            handleClearFilter={handleClearFilter}
                            handleStatusFilter={handleStatusFilter}
                            handleTransfer={handleShowTransferEnquiries}
                            showMore={true}
                            stageCounts={pipelineCount}
                            csvHeader={csvHeader}
                            csvData={csvData}
                            datalist={enquiries}
                            view="list"
                            isFilterApplied={isFilterApplied}
                            searchText={searchText}
                            handlesearchText={(val) => {
                                handleSearchText(val);

                            }}
                            selectedIds={selectedIds}
                            excelDownload={excelDownload}
                            csvBtn={csvBtn}
                        />)
                    }
                    <div className={`${_pipelines.length === 0 ? 'h-100' : 'mt-2'}`}>
                        {
                            _pipelines.length === 0 ? (
                                <div className="spinner-loader h-100">
                                    <div className="no-data-flex-wrap h-100 justify-content-center align-items-center text-primary">
                                        <div className="no-data-img-wrap"><img src={images.nopipeline} width="350" alt="" /></div>
                                        <div className="no-data-txt-wrap">
                                            <div className="no-data-content-head"><Translate text={'Access denied.'} /></div>
                                            <div className="no-data-content"><Translate text={'You dont have any pipeline access. Please contact administrator.'} /></div>
                                        </div>
                                    </div>
                                </div>
                            ) : enquiryLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (<div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={enquiries}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allenquiryFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(enquiryFields && enquiryFields.length > 0) ? [...enquiryFields.filter(item => item !== 'checkbox'), 'checkbox'] : allenquiryFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={enquiryDetailclick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        handleFavorite={handleFavorite}
                                        selectedIds={selectedIds}
                                        handleAllChecked={handleAllChecked}
                                        handleCheckChange={handleCheckChange}
                                        displayByValue={true}
                                    />
                                </div>
                                )
                        }


                    </div>



                    <PopUpModal show={modalTransferShow}>
                        <TransferOwner
                            show={modalTransferShow}
                            handleClose={handleCloseTransferEnquiries}
                            title={'Transfer Leads'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        >
                        </TransferOwner>
                    </PopUpModal>

                    <PopUpModal show={showReAuthentication}>
                        <ReAuthenticate
                            show={showReAuthentication}
                            handleClose={handleReAuthOpenClose}
                            dealersettings={props.dealersettings}
                            privacy='file'
                        />
                    </PopUpModal>

                    <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers}>
                        <StageConsumer>
                            {() => (

                                <SidePanel
                                    clsActive={showpanel.clsActive}
                                    clsName={showpanel.clsName}
                                    sidepanelClose={sidepanelClose}
                                    title=''
                                >
                                    <QuickView {...props}
                                        enquiryid={showpanel.enquiryid}
                                        isFilterApplied={isFilterApplied}
                                        updateListEnquiry={updateListEnquiry}
                                        sidepanelClose={sidepanelClose}
                                    />
                                </SidePanel>
                            )}
                        </StageConsumer>
                    </StageProvider>
                </div>

            ) : (<></>)}
        </LayoutConsumer>
    )
}

export default PipelineListView;