/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import toast from 'toasted-notes'
import Translate from '../../../../constants/translate';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { alerts } from './viewmodel';
import AlertTabs from './alertTab';
import { ContentOverlay } from '../../../../components';
import CommonHelper from '../../../../services/common';


const CustomAlerts = (props) => {
    const [state, setState] = useState({});
    const [tabLoader, setTabLoader] = useState(true);
    const [loader, setLoader] = useState();
    const [key, setKey] = useState(alerts[0].value)
    const [email, setEmail] = useState('')
    const [allocationTabKey, setAllocationTabKey] = useState()
    const [rulesLoader, setRulesLoader] = useState(false)

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/alerts/${key}`)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.exists) {
                    setState(querySnapshot.data());
                    setEmail(querySnapshot.data().emailTemplate);
                }
                else {
                    setState({})
                }
                setTabLoader(false)
            });
    }, [key])

    useEffect(() => {
        if (!_.isEmpty(email) && !_.isEmpty(state)) {
            setState({
                ...state,
                emailTemplate: email
            })
        }
    }, [email])

    const handleChange = obj => {
        setEmail(obj)
    };

    const onMentionChange = notesText => {
        setState({
            ...state,
            templateJSON: '',
            smsTemplate: notesText ? notesText : '',
        });
    };

    const onMentionSelect = (option, ownerID) => {
        console.log('select', option);
    };

    const saveTemplate = () => {
        setLoader(true);
        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/alerts/${key}`).set({
            ...state,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedBy: localStorage.uid
        }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify('Template updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                setLoader(false);
                Swal.fire('Something went wrong.', '', 'error')
            });
    }
    const handleOnChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        var str = value;

        if (str && str.length >= 1) {
            var firstChar = str.charAt(0);
            var remainingStr = str.slice(1);
            str = firstChar.toUpperCase() + remainingStr;
        }
        setState({
            ...state,
            [name]: str,
        });
    };

    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            const num = number !== '' ? parseInt(number) : number
            setState({
                ...state,
                [e.target.name]: num
            })

        }
    };

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setState({
            ...state,
            [name]: value
        });
    }

    const handleCheckedChange = (e, name) => {
        if (name) {
            setState({
                ...state,
                [name]: state[name] ? {
                    ...state[name],
                    [e.target.name]: e.target.checked
                } : {
                    [name]: {
                        [e.target.name]: e.target.value
                    }
                }
            });
        }
        else {
            setState({
                ...state,
                [e.target.name]: e.target.checked
            })
        }
    }

    const handleRulesChange = (rules, data) => {
        const _updateState = {
            ...state,
            ...data,
            ['rules']: rules || []
        }
        setState(_updateState);
        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/alerts/${key}`).set({
            ..._updateState,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedBy: localStorage.uid
        }, { merge: true })
            .then(snapshot => {
                setRulesLoader(false);
                toast.notify('Allocation rules updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                setRulesLoader(false);
                Swal.fire('Something went wrong.', '', 'error')
            });
    }

    return (
        <>
            <h2><Translate text={'Custom Alerts'} /></h2>
            <div className="settings-tab-wrap tabs">
                <div className="tabs-wraper">
                    <nav>
                        <Tabs defaultActiveKey={key} transition={false} className="nav-fill" onSelect={(val) => {
                            setTabLoader(true)
                            setEmail('');
                            setState({});
                            setKey(val);
                        }} unmountOnExit={true} mountOnEnter={true}>
                            {
                                alerts.map((rec, index) => {
                                    return <Tab key={index} eventKey={rec.value} title={CommonHelper.showLocale(props, rec.name)}>
                                        {
                                            tabLoader ? (<div className="mt-5" style={{ height: '200px' }}><ContentOverlay active={true} /></div>) :
                                                <AlertTabs
                                                    {...props}
                                                    activeTab={rec.value}
                                                    data={state}
                                                    email={email}
                                                    handleChange={handleChange}
                                                    handleOnChange={handleOnChange}
                                                    onMentionChange={onMentionChange}
                                                    onMentionSelect={onMentionSelect}
                                                    handleNumberChange={handleNumberChange}
                                                    handleReactMultiSelectChange={handleReactMultiSelectChange}
                                                    handleCheckedChange={handleCheckedChange}
                                                    handleRulesChange={handleRulesChange}
                                                    setAllocationTabKey={(val) => setAllocationTabKey(val)}
                                                    rulesLoader={rulesLoader}
                                                    setRulesLoader={val => setRulesLoader(val)}
                                                />
                                        }
                                    </Tab>
                                })
                            }

                        </Tabs>
                    </nav>
                    {
                        _.isEmpty(allocationTabKey) || allocationTabKey !== 'allocationRules' ? (
                            <div className="settings-footer mt-3">
                                <button type="button" className="btn btn-primary float-right" onClick={() => saveTemplate()}>
                                    {
                                        loader ?
                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                            : (<></>)
                                    }
                                    <Translate text={'save'} />
                                </button>
                            </div>
                        ) : (<></>)
                    }

                </div>
            </div>

        </>
    );
}

export default CustomAlerts;