/** LIBRARIES */
import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
/** COMPONENTS */
// import PasswordSecurity from './general-settings/passwordsecurity';
import DeviceLog from './sections/deviceLog';
import Rules from './sections/rules';
import GDPRSettings from './sections/gdpr';
import Translate from '../../../constants/translate';
// import EmailSettings from './general-settings/emailsettings';

const SecurityManagement = props => {
	const getQueryparam = () => {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let key = params.get('key');
		if (!key) {
			key = 'personel';
		}
		return key;
	};

	return (
		<>
			<Tab.Container defaultActiveKey={getQueryparam()} mountOnEnter={true}>
				<div className='settings-submenu'>
					<h4><Translate text={'Security Management'} /></h4>
					<Nav as='ul' bsPrefix='ul-nav'>
						<Nav.Item as='li' bsPrefix='li-nav'>
							<Nav.Link eventKey='personel' bsPrefix='li-a-nav'>
							<Translate text={'Device Log'} />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item as='li' bsPrefix='li-nav'>
							<Nav.Link eventKey='security' bsPrefix='li-a-nav'>
							<Translate text={'Security Rules'} />
							</Nav.Link>
						</Nav.Item>
						{/* <Nav.Item as='li' bsPrefix='li-nav'>
							<Nav.Link eventKey='gdpr' bsPrefix='li-a-nav'>
								GDPR Settings
							</Nav.Link>
						</Nav.Item> */}
					</Nav>
				</div>
				<div className={`settings-container`}>
					<div className='settings-container-sub'>
						<Tab.Pane eventKey='personel'>
							<DeviceLog {...props} />
						</Tab.Pane>
						<Tab.Pane eventKey='security'>
							<Rules {...props} />
						</Tab.Pane>
						{/* <Tab.Pane eventKey='gdpr'>
							<GDPRSettings {...props} />
						</Tab.Pane> */}
					</div>
				</div>
			</Tab.Container>
		</>
	);
};

export default SecurityManagement;
