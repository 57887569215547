/** LIBRARIES */
import React, { useState, useEffect, useReducer, Suspense } from 'react';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import _ from 'lodash'
import moment from 'moment';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
/** PROVIDERS */
/** VIEW-MODELS */
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { PopUpModal, PopoverListLoader } from '../../components';
import { default as _images } from '../../images';
import { serviceStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';

const SlotView = (props) => {
    const [searchText, setSearchText] = useState('')
    const [selectedSlot, setSlot] = useState(props.slot ? props.slot : {})
    const [selectedServices, setServices] = useState(props.slot && props.slot.services ? props.slot.services : [])

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    const divStyle = {
        height: '500px',
        overflow: 'auto',
        position: "relative",
        width: '100%'
    };
    useEffect(() => {
        setServices(props.slot && props.slot.services ? props.slot.services : []);
    }, [props.slot, props.slot.services])


    const checkInJob = async (_service) => {
        const { dealersettings } = props;
        let state = Object.assign({});
        const setttings = dealersettings.client.services;

        const _allPipelines = dealersettings?.client?.services?.pipelines;
        const selectOptions = !_.isEmpty(_allPipelines) && _allPipelines.length > 1 ? _allPipelines.reduce((options, { name, value }) => {
            options[value] = name;
            return options;
        }, {}) : null;

        let selectedPipeline;
        if (!_.isEmpty(selectOptions)) {
            const { value: _selectedPipeline } = await Swal.fire({
                title: 'Select Pipeline to Continue',
                input: 'select',
                inputOptions: selectOptions,
                inputPlaceholder: 'select pipeline',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                inputValidator: (value) => {
                    if (!value) {
                        return 'You need to select an pipeline';
                    }
                }
            });
            selectedPipeline = _selectedPipeline
        }


        state.status = serviceStatus.CHECKIN;
        state.checkInBy = dealersettings ? dealersettings.id : '';
        state.checkInDate = window.firebase.firestore.Timestamp.now();
        state.modifiedBy = props.dealersettings ? props.dealersettings.id : '';
        state.modifiedDate = window.firebase.firestore.Timestamp.now();
        state.modifiedFrom = 'web';

        if (!_.isEmpty(setttings)) {

            let _selPipeline = Object.assign({}, setttings.allPipelines.filter(i => i.default === true)[0]);
            if (_.isEmpty(_selPipeline))
                _selPipeline = Object.assign({}, setttings.allPipelines[0]);

            if (selectedPipeline) _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.value === selectedPipeline)[0]);

            let statusSettings = _selPipeline && _selPipeline.statusSettings;

            let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === state.status)[0];
            if (!_.isEmpty(data) && data.pipeline && data.stage) {
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }
            else {
                let _stages = Array.prototype.map.call(_selPipeline.stages, function (item) { return item.value; }).join(",").split(',');
                state.pipeline = _selPipeline.value;
                state.stage = _stages[0];
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }
            let _stageHistory = Object.assign([], _service && _service.stageHistory);
            let _stageData = CommonHelper.handleStageHistory(_stageHistory, state.pipeline, state.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }

            let _statusHistory = Object.assign([], _service && _service.statusHistory);
            _statusHistory.push({
                date: window.firebase.firestore.Timestamp.now(),
                status: state.status,
                user: localStorage.uid
            });
            state.statusHistory = Object.assign([], _statusHistory);
        }

        const updateRef = firestoreDB(props.dealersettings).firestore().collection('serviceJobs').doc(_service.documentID);
        updateRef.update(state).then((docRef) => {
            toast.notify(`Service status updated sucessfully.`, { duration: 2000 });

            const _recommendations = Object.assign([], selectedServices);

            _recommendations.filter(function (obj) {
                //Update old data
                if (obj.documentID === _service.documentID) {
                    for (let [key, value] of Object.entries(state)) {
                        if (obj.hasOwnProperty(key))
                            obj[key] = value;
                    }
                }
            });

            setServices(_recommendations)
        }).catch((error) => {
            console.error("Error updating enquiries: ", error);
        });

    }

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.servicePermissions)) ? props.dealersettings.rolePermissions.servicePermissions : null);
    const scheduleAccess = ((!_.isEmpty(_permissions) && _permissions.scheduleAccess) ? true : false);
    const checkInAccess = ((!_.isEmpty(_permissions) && _permissions.checkInAccess) ? true : false);
    const advisorRequestedAccess = ((!_.isEmpty(_permissions) && _permissions.serviceAdvisor) ? true : false);

    let _selectedServices = !_.isEmpty(selectedServices) && selectedServices.filter(a => (searchText ? (a.keywords && a.keywords.some(b => b.toLowerCase() === searchText.toLowerCase())) : true));
    return (
        <>

            <Modal
                id="modal-view-slots"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-view-slots">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" >{selectedSlot.name}
                            {props.date ? ' - ( ' + moment(props.date).format('DD MMM YYYY') + ' )' : ''}
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="package-item-head pr-0">
                                <input
                                    type="text"
                                    aria-label="Search"
                                    placeholder="Search..."
                                    value={searchText ? searchText : ''}
                                    autoComplete="off"
                                    onChange={e => {
                                        setSearchText(e.target.value);
                                    }}
                                />
                            </div>
                            {/*             
                                        <!-- <div className="alert alert-info w-100" role="alert">
                              This is a info alert—check it out!
                            </div>--> */}
                            <div className="package-item-sub-head">
                                <div className="float-left package-item-sub-head-count">
                                    <Translate text={'slots'} /> : &nbsp;
                                    <span className="cal-slotcount-occupied">{!_.isEmpty(selectedServices) ? selectedServices.length : 0} / {!_.isEmpty(selectedSlot) ? selectedSlot.total : 0}</span>
                                </div>

                                {
                                    scheduleAccess && ((!_.isEmpty(selectedServices) ? selectedServices.length : 0) < (!_.isEmpty(selectedSlot) ? selectedSlot.total : 0))
                                        ?
                                        <>
                                            <div className=" float-right view-slots-addbtn">
                                                <button type="button" className="btn btn-primary float-right" onClick={(e) => {
                                                    e.preventDefault();

                                                    props.loadNewService(props.date, selectedSlot);
                                                }}>
                                                    <i className="ico icon-add"></i> <Translate text={'appointment'} />
                                                </button>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }

                            </div>

                            <div style={divStyle} className="position-relative w-100 d-inline-block pr-2 mt-2">

                                {
                                    _selectedServices
                                        ?
                                        <>
                                            {
                                                _selectedServices.map((_service, index) => {
                                                    return <div key={index} className="slotview-loop-item cursor-pointer" onClick={(e) => {
                                                        e.preventDefault();

                                                        props.history.push("/service/details/" + _service.documentID + '?back-url=/appointment');
                                                    }}>
                                                        {/* <div className="pipeline-control">
                                                            <div className="pipeline-favourite">
                                                                <a href="#" ><i className="ico icon-star"></i>
                                                                </a>
                                                            </div>
                                                        </div> */}

                                                        {
                                                            (!_.isEmpty(_service.contact))
                                                                ?
                                                                <>
                                                                    <div className="pipeline-item-head">
                                                                        {CommonHelper.displayContactIcon(_service.contact, 'mr-1')}
                                                                        {CommonHelper.displayContact(null, _service.contact, '')}
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        (!_.isEmpty(_service) && !_.isEmpty(_service.engageNewContact))
                                                                            ?
                                                                            <>
                                                                                <div className="pipeline-item-head">
                                                                                    {CommonHelper.displayContactIcon(_service.contact, 'mr-1')}
                                                                                    {CommonHelper.displayContact(null, _service.engageNewContact, '')}
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                            </>
                                                                    }
                                                                </>
                                                        }

                                                        {
                                                            (!_.isEmpty(_service) && !_.isEmpty(_service.contact) && _service.contact.businessContact && !_.isEmpty(_service.contact.company))
                                                                ?
                                                                <div className="testdrive-contact-license">
                                                                    <i className={`ico icon-Contacts mr-1`}></i>
                                                                    {CommonHelper.displayContactName([], _service.contact, '')}
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        {_service.displayID ? <div className="pipeline-item-source"><i className="ico icon-enquiry"></i> {_service.displayID}</div> : <></>}
                                                        {
                                                            (!_.isEmpty(_service.contact) && (_service.contact.phone || _service.contact.email)) ? (
                                                                <div className="pipeline-item-phone">
                                                                    {_service.contact.phone ?
                                                                        CommonHelper.phoneFormat(_service.contact.phoneCode, _service.contact.phone, props.dealersettings)
                                                                        : _service.contact.email}
                                                                </div>
                                                            ) : <>
                                                                {
                                                                    (!_.isEmpty(_service.contact) &&
                                                                        _service.contact.businessContact &&
                                                                        !_.isEmpty(_service.contact.company) &&
                                                                        (_service.contact.company.phone || _service.contact.company.email))
                                                                        ?
                                                                        <div className="pipeline-item-phone">
                                                                            {_service.contact.company.phone ?
                                                                                CommonHelper.phoneFormat(_service.contact.company.phoneCode, _service.contact.company.phone, props.dealersettings)
                                                                                : _service.contact.company.email}
                                                                        </div>
                                                                        :
                                                                        <div className="no-data-red"><Translate text={'contactDetailsMissing'} /></div>
                                                                }
                                                            </>
                                                        }

                                                        {
                                                            (_service.isVehicle && !_.isEmpty(_service.vehicle)) ? (
                                                                <div className="pipeline-item-vehicle">
                                                                    <strong>{(_service.vehicle.make ? _service.vehicle.make : '') + ' ' + (_service.vehicle.model ? _service.vehicle.model : '')}</strong>
                                                                </div>
                                                            ) : (<div className="no-data-red"><Translate text={CommonHelper.showVehicleText(props.dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>)
                                                        }
                                                        {_service.displayID ? <div className="pipeline-item-source"><i className="ico icon-enquiry"></i> {_service.displayID}</div> : <></>}
                                                        {
                                                            _service.appointmentDate
                                                                ?
                                                                <div className="pipeline-item-div w-100">
                                                                    <i className="ico icon-timer"></i>
                                                                    {moment(_service.appointmentDate).format('DD MMM YYYY h:mm A')}
                                                                </div>
                                                                :
                                                                <></>
                                                        }

                                                        <div className="pipeline-item-div w-100 mt-2">
                                                            {
                                                                _service.owner ? (<OverlayTrigger
                                                                    placement='bottom'
                                                                    overlay={
                                                                        <Tooltip>{CommonHelper.getUserNamebyId(allUsers, _service.owner)}</Tooltip>
                                                                    }
                                                                >
                                                                    <div className="float-right">
                                                                        <div className="pipeline-users pl-0">
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                <img src={CommonHelper.getUserImagebyId(allUsers, _service.owner)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </OverlayTrigger>) : (<></>)
                                                            }
                                                            {(() => {
                                                                let _jobStatus = _service.status && _service.status === serviceStatus.OWNERRESPONDED ? _service.ownerResponse : _service.status;
                                                                const serviceAllStatus = Object.assign([], props.dealersettings?.client?.services?.serviceStatus)
                                                                let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0]
                                                                return <div className="float-left">
                                                                    {
                                                                        (_jobStatus)
                                                                            ?
                                                                            <div className='pipeline-button'>
                                                                                {
                                                                                    !_.isEmpty(_objCurrentStatus)
                                                                                        ?
                                                                                        <>
                                                                                            <span
                                                                                                className={`status-empty border-bottom-0`} style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                                                                <Translate
                                                                                                    text={_objCurrentStatus.name}
                                                                                                    upperCase={true}
                                                                                                />

                                                                                            </span>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <span className={`status-${_jobStatus.toLowerCase()} border-bottom-0`}>
                                                                                                {_jobStatus.split(/(?=[A-Z])/).join(' ').toUpperCase()}
                                                                                            </span>
                                                                                        </>
                                                                                }

                                                                            </div>
                                                                            :
                                                                            <></>

                                                                    }
                                                                </div>
                                                            })()}

                                                        </div>
                                                        <div className="pipeline-item-div w-100">
                                                            <div className="slotview-status">
                                                                <div className="float-left">

                                                                    {
                                                                        !_.isEmpty(_service) && !_.isEmpty(_service.slot)
                                                                            ?
                                                                            <>
                                                                                <OverlayTrigger
                                                                                    placement='bottom'
                                                                                    overlay={<Tooltip>{_service.slot.from}{'-'}{_service.slot.to}</Tooltip>}
                                                                                >
                                                                                    <div className="badge-slot"
                                                                                        style={{
                                                                                            color: `${_service.slot.color ? _service.slot.color : '#f47815 '}`,
                                                                                            backgroundColor: `${_service.slot.color ? _service.slot.color + '1f' : '#f478151f'}`,
                                                                                            boxShadow: `inset 0 0 0 1px ${_service.slot.color ? _service.slot.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
                                                                                        }}>
                                                                                        <i className="ico icon-service-timer"></i> {_service.slot.name}
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                            </>

                                                                            :
                                                                            <></>
                                                                    }

                                                                </div>

                                                                {
                                                                    _service.documentID && (_service.status === serviceStatus.SCHEDULED) && (checkInAccess || advisorRequestedAccess)
                                                                        ?
                                                                        <div className="float-right slotview-checkinbtn">
                                                                            <button type="submit" className="btn btn-sm btn-primary float-right"
                                                                                onClick={e => { e.stopPropagation(); checkInJob(_service) }}>
                                                                                <i className="ico icon-close"></i> <Translate text={'checkIn'} /></button>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="spinner-loader h-100 p-5">
                                                <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-cafe-img">
                                                        <i className="ico icon-activity"></i> </div>
                                                    <div className="no-data-txt mt-2"> <Translate text={'No appointment available'} /></div>
                                                </div>

                                            </div>
                                        </>
                                }





                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal >



        </>
    )
}

export default SlotView