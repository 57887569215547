import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import _images from '../../images'
import Translate from '../../constants/translate';
import { ContentOverlay, ReactSelect } from '../../components';
import moment from 'moment';
import CommonHelper from '../../services/common';

const DynamicFieldsWidget = (props) => {
    const enquiryOptionsDF = props?.dealersettings?.client?.settings?.enquiryOptionsDF?.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect")) || [];
    const [selectedField, setSelectedField] = useState(enquiryOptionsDF?.[0]?.value)
    const [refreshData, setRefreshData] = useState(false)
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const [dealersCount, setDealersCount] = useState([])
    const [show, setShow] = useState(false)
    const [oldDaterange, setOldDaterange] = useState({})
    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-dynamicFields`);
    const node = useRef();

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setShow(false);
    };


    useEffect(() => {
        return () => {
            window.unsubdynamicfieldswidhetSnap && window.unsubdynamicfieldswidhetSnap();
        }
    }, []);

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchData();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return
        setLoader(true)
        window.unsubenquiryTypesSnap = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setLoader(false);
                    setInitialLoad(false)
                    setDealersCount(snap.data().counts);
                    setData(setChartData(snap.data().counts));
                    if (snap.data().selectedField) {
                        setSelectedField(snap.data().selectedField)
                    }
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true, snap.data()?.selectedField);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    const fetchData = async (hideLoader, value) => {
        // console.log('lead by campaigns')
        let _filter = {
            clientID: CommonHelper.getAllDealers(props.dealersettings),
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
            fieldValue: value || selectedField
        }
        if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        } else if (!_.isEmpty(props.pipeline)) {
            if (inclInboundLeadsinRpt)
                _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
            else
                _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
        }

        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.saleType = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.make = props.dashboardFilter.makes.join(',')
        }

        // New Filters Added for Porsche - SaMeeR - 05-09-24
        if (props?.dashboardFilter?.models?.length > 0) _filter.model = props.dashboardFilter.models.join(',')
        if (props?.dashboardFilter?.campaigns?.length > 0) _filter.campaign = props.dashboardFilter.campaigns.join(',')
        if (props?.dashboardFilter?.origins?.length > 0) _filter.origin = props.dashboardFilter.origins.join(',')
        if (props?.dashboardFilter?.enquiryTypes?.length > 0) _filter.enquiryType = props.dashboardFilter.enquiryTypes.join(',')
        if (props?.dashboardFilter?.leadSource?.length > 0) _filter.leadSource = props.dashboardFilter.leadSource.join(',')

        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getDynamicFieldsCountV2" : "getDynamicFieldsCount",
            params: JSON.stringify(_filter),
        }
        if (!hideLoader)
            setLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp', resp)

            setDealersCount(resp.data.data);
            const _data = setChartData(resp.data.data);
            setData(_data)
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({
                    counts: resp.data.data,
                    logDate: window.firebase.firestore.Timestamp.now(),
                    selectedField: value || selectedField
                }, { merge: true })
            }
            setLoader(false)
            setFirstTimeLoaded(true)
        } else {
            // Swal.fire('Something went wrong.');
            setLoader(false)
            setFirstTimeLoaded(true)
        }
    }

    const setChartData = (_data) => {
        const _summaryData = _data.map(r => {
            return {
                ...r,
                wonCount: _.sumBy(JSON.parse(r.won), 'soldCount'),
                wonLeads: _.uniqBy(JSON.parse(r.won)?.map(b => b.documentID)),
                deliveredCount: _.sumBy(JSON.parse(r.delivered), 'soldCount'),
                deliveredLeads: _.uniqBy(JSON.parse(r.delivered)?.map(b => b.documentID)),
                lostCount: _.sumBy(JSON.parse(r.lost), 'soldCount'),
                lostLeads: _.uniqBy(JSON.parse(r.lost)?.map(b => b.documentID))
            }
        })
        let enquiries_ = _.chain(_summaryData)
            .groupBy("name")
            .map((value, key) => ({
                name: key,
                wonCount: _.sumBy(value, 'wonCount'),
                wonLeads: _.uniq(value.map(r => { return r?.wonLeads?.join(',') })),
                deliveredCount: _.sumBy(value, 'deliveredCount'),
                deliveredLeads: _.uniq(value.map(r => { return r?.deliveredLeads?.join(',') })),
                lostCount: _.sumBy(value, 'lostCount'),
                lostLeads: _.uniq(value.map(r => { return r?.lostLeads?.join(',') })),
            }))
            .value()

        return enquiries_;
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props.dashboardFilter)) {
            if (props.fromOEM) {
                if (JSON.stringify(oldDaterange) !== JSON.stringify(props?.dashboardFilter?.daterange)) {
                    setFirstTimeLoaded(false);
                    fetchData()
                    setOldDaterange(props?.dashboardFilter?.daterange)
                }
                else
                    setData(setChartData(dealersCount.filter(a => props?.dashboardFilter?.clients?.length > 0 ? props.dashboardFilter.clients.includes(a.clientID) : true)));
            } else {
                window.unsubenquiryTypesSnap && window.unsubenquiryTypesSnap();
                setFirstTimeLoaded(false);
                fetchData();
            }
        }
        else {
            setInitialLoad(true)
        }

    }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchData();
    }, [props.refreshData])

    return (
        <>
            <div className="dashboard-box">
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setFirstTimeLoaded(false); fetchData(); }}> <i className="ico icon-refresh"></i></a>
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }
                        {/* <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a> */}
                    </div>
                    {
                        show ? (
                            <div ref={node} className="form-group col-md-4 pl-0" style={{ position: 'absolute', top: '12px' }}>
                                <ReactSelect
                                    options={enquiryOptionsDF?.map(rec => {
                                        return {
                                            label: rec.name,
                                            value: rec.value
                                        }
                                    }) || []}
                                    name={`checkout-teams`}
                                    placeholder={'select field'}
                                    onChange={(e, data) => {
                                        setSelectedField(e?.value)
                                        fetchData(false, e?.value)
                                        setShow(false)
                                    }}
                                    value={selectedField}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={true}
                                    defaultMenuIsOpen={true}
                                >
                                </ReactSelect>
                            </div>
                        ) : (
                            <h3>{_.find(enquiryOptionsDF, { value: selectedField })?.name || 'Select Field'} <a href="#" onClick={(e) => {
                                e.preventDefault();
                                setShow(true)
                            }} className="edit-widget-mini"> <i className="ico icon-edit"></i></a> </h3>
                        )
                    }
                </div>
                {
                    (loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                        <div className="dashboard-box-body" style={{ minHeight: `${props.dealersettings.isMclaren === true ? '800px' : '390px'}` }}>
                            <div className="h-100" style={{ maxHeight: `390px`, minHeight: `390px`, overflow: `auto` }}>
                                {

                                    data?.length ? (<table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td className="head-light" width="30%">Name</td>
                                                <td className="head-light text-center" width="10%">Won</td>
                                                <td className="head-light text-center" width="10%">Delivered</td>
                                                <td className="head-light text-center" width="10%">Lost</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.map((rec, index) => {
                                                    return <tr key={index}>
                                                        <td><div className="dealership-tbl float-left" >{rec.name}</div></td>
                                                        <td className="text-center cursor-pointer" onClick={(e) => {
                                                            e.preventDefault();
                                                            if (rec.wonCount > 0) {
                                                                props.handleShowEnquiryList({
                                                                    documentIDs: rec.wonLeads
                                                                })
                                                            }
                                                        }}>{rec.wonCount || 0}</td>
                                                        <td className="text-center cursor-pointer" onClick={(e) => {
                                                            e.preventDefault();
                                                            if (rec.deliveredCount > 0) {
                                                                props.handleShowEnquiryList({
                                                                    documentIDs: rec.deliveredLeads
                                                                })
                                                            }
                                                        }}>{rec.deliveredCount || 0}</td>
                                                        <td className="text-center cursor-pointer" onClick={(e) => {
                                                            e.preventDefault();
                                                            if (rec.lostCount > 0) {
                                                                props.handleShowEnquiryList({
                                                                    documentIDs: rec.lostLeads
                                                                })
                                                            }
                                                        }}>{rec.lostCount || 0}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>) : (<div className="spinner-loader h-100 p-5">
                                        <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                            <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                            <div className="no-data-txt mt-2"> <Translate text={'No Data Found'} /></div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>)
                }

            </div>
        </>
    );
}

export default DynamicFieldsWidget;