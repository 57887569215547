/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import "../../../styles/tradein_new.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { ContentOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { objTradeIn, objMark } from '../viewModel'


const TradeInImages = (props) => {
    const [tradeIn, setTradeIn] = useState(objTradeIn)

    const {
        make, model, year, modelDescription,
        notes, regNo, regDate, chassisNo, engineNo, transmission, doors,
        mileage, exteriorColor, interiorColor, trim, spareTyre,
        massageVentilated, camera, contrastStiching, woodSteering, sunroof,
        owners, outstandingPayments, warranty, financeCo,
        serviceContFrom, serviceContTo, reconditioning, financed, tyreAge, lastService,
        type, engineType, engineSize,
        frontLeftWheel, frontRightWheel, rearLeftWheel, rearRightWheel,
        vehicleCondition, buildDate, complianceDate, spareKey, logBooks, serviceHistory,
        cargoBlind, headRests, sdCard, sent, warrantyExpiry,
        images, marks, status, addedBy, inspectionSnapshot, sidePhotos,
        enquiry, client, users, service
    } = tradeIn;

    const fetchLookups = async () => {
        let _tradeinplusURL
        const remoteConfig = window.firebase.remoteConfig();
        try {
            await remoteConfig.fetchAndActivate()
                .then(() => {
                    _tradeinplusURL = remoteConfig.getString('tradeinplusURL');
                    //console.log('_tradeinplusURL', _tradeinplusURL);
                })
                .catch((err) => {
                    console.error(err);
                });

        } catch (error) {
            console.log('lookups', error);
        }
        return _tradeinplusURL;
    }

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`tradeins/${props.tradeinid}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        //console.log('TradeInDetails', props.tradeinid);
        if (props.tradeinid) {
            loadTradeIn();
        }
        return () => {
            window.refCollectionDatai && window.refCollectionDatai.unsubscribe()
        }
    }, [firestoreDB])

    const loadTradeIn = async () => {


        window.refCollectionDatai = docData(firestoreDB.firestore().doc(`tradeins/${props.tradeinid}`))
            .pipe(
                switchMap(tradeIns => {
                    //console.log(tradeIns);
                    const enqIds = tradeIns.enquiryID ? [tradeIns.enquiryID] : [];
                    const serviceIds = tradeIns.serviceID ? [tradeIns.serviceID] : [];
                    const clientIds = [tradeIns.clientID];
                    return combineLatest(
                        of(tradeIns),
                        combineLatest(
                            enqIds.length > 0 ? enqIds.map(enquiryID =>
                                collectionData(firestoreDB.firestore().collection('enquiries').where('documentID', '==', enquiryID)).pipe(
                                    map(enquirySnapshot => enquirySnapshot[0] ? enquirySnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            serviceIds.length > 0 ? serviceIds.map(serviceID =>
                                collectionData(firestoreDB.firestore().collection('serviceJobs').where('documentID', '==', serviceID)).pipe(
                                    map(serviceSnapshot => serviceSnapshot[0] ? serviceSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([tradeIns, enquirySnapshot, serviceSnapshot, clientSnapshot]) => {

                    return {
                        ...tradeIns,
                        enquiry: tradeIns.enquiryID ? enquirySnapshot.find(a => a.documentID === tradeIns.enquiryID) : {},
                        service: tradeIns.serviceID ? serviceSnapshot.find(a => a.documentID === tradeIns.serviceID) : null,
                        client: clientSnapshot.find(a => a.documentID === tradeIns.clientID)
                    }

                })
            )
            .subscribe(_tradeIns => {

                if (!_.isEmpty(_tradeIns)) {
                    setTradeIn(_tradeIns);

                    const tradeinPlusEnabled = ((!_.isEmpty(_tradeIns.client) &&
                        !_.isEmpty(_tradeIns.client.moduleSettings) &&
                        !_.isEmpty(_tradeIns.client.moduleSettings.tradeinPlus) &&
                        _tradeIns.client.moduleSettings.tradeinPlus.enabled) ? true : false);

                    //console.log('TradeInDetails', _tradeIns, '==', _tradeIns.sent, '--', tradeinPlusEnabled);

                    if (_tradeIns.sent && _tradeIns.evalexpertID) {
                        getDetailsFromEvalExpert(props.tradeinid, _tradeIns);
                    }
                    else if (_tradeIns.sent && tradeinPlusEnabled) {
                        getDetailsFromTradeInPlus(props.tradeinid, _tradeIns);
                    }


                }


            });

    }

    const getDetailsFromTradeInPlus = async (docID, _fields) => {
        try {

            let tradeinplusURL = await fetchLookups();
            //console.log('tradeinplusURL', tradeinplusURL);
            if (tradeinplusURL) {
                const ObjectToSend = {
                    Name: docID
                };

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(ObjectToSend),
                };

                const response = await fetch(
                    tradeinplusURL + 'api/TradeInAMS/Action/GetTradeInByAMSProId',
                    requestOptions,
                );

                const respjson = await response.json();

                if (respjson.success) {
                    //console.log(respjson);
                    //convertMappingVM(respjson.data, _fields);
                    var tradein = CommonHelper.convertTradeInMappingVM(respjson.data, _fields);
                    setTradeIn(tradein);
                } else {
                    //console.log('getDetailsFromTradeInPlus', respjson);
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    const getDetailsFromEvalExpert = async (docID, _fields) => {
        try {

            const evalexpertGet = window.firebase.functions().httpsCallable('evalexpert-get');
            evalexpertGet(_fields).then((_response) => {

                if (_response && _response.data && _response.data.success && _response.data.data) {
                    //console.log('evalexpert-get--', _response.data.data[0]);
                    var tradein = CommonHelper.convertEvalMappingVM(_response.data.data[0], _fields);
                    setTradeIn(tradein);
                }

            }).catch((error) => {
                //Swal.fire('Something went wrong. Please contact support.', '', 'error')
            });
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>

            {
                !_.isEmpty(tradeIn) && !_.isEmpty(client)
                    ?
                    <>
                        <div className="A4">
                            <div className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="50%">

                                                    <div className="header-left-align">

                                                        {
                                                            client && client.logoURL
                                                                ?
                                                                <div className="logo-head"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="50" /></div>
                                                                :
                                                                <>
                                                                    <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                                </>
                                                        }

                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div className="header-right-align align-right-text">
                                                        <div className="header-company"> {client.name} <br />
                                                            <span>{client.address ? client.address : ''}

                                                                {client.phone ? (
                                                                    <>
                                                                        <br />
                                                                        {`Phone: ${client.phone}`}
                                                                    </>
                                                                ) : <></>}
                                                                {client.email || client.website ? (
                                                                    <>
                                                                        <br />
                                                                        {client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
                                                                    </>
                                                                ) : <></>} </span>
                                                        </div>
                                                    </div>






                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div className="middle-section-wraper">

                                    <div className="report-ui-mainhead">
                                        <Translate text={'Vehicle Evaluation Request'} upperCase={true} />

                                    </div>

                                    <div className="report-ui-subcontainer-main">

                                        <h2>
                                            {modelDescription ?
                                                <>{modelDescription}</> :
                                                <>{year ? year : ''}&nbsp;{make ? make : ''}&nbsp;{model ? model : ''}</>
                                            }
                                        </h2>
                                        <h3>{exteriorColor ? exteriorColor : '--'}</h3>
                                        <div className="report-veh-info">
                                            <div className="block">
                                                <img src={_images.ti_odometer} />
                                                <p>
                                                    {' Odometer'}
                                                    <span>{mileage ? <NumberFormat style={{ fontSize: 12 }} value={mileage} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : '--'}</span>
                                                </p>
                                            </div>
                                            <div className="block">
                                                <img src={_images.ti_bodytype} />
                                                <p>
                                                    {' Body Type'}
                                                    <span>{type ? type : '--'}</span>
                                                </p>
                                            </div>
                                            <div className="block">
                                                <img src={_images.ti_transmission} />
                                                <p>
                                                    {' Transmission'}
                                                    <span>{transmission ? transmission : '--'}</span>
                                                </p>
                                            </div>
                                            <div className="block">
                                                <img src={_images.ti_engine} />
                                                <p>
                                                    {' Engine'}
                                                    <span>{engineType ? engineType : '--'}</span>
                                                </p>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="report-ui-subhead">

                                        <div className="report-ui-cst">Customer Details
                                            {(enquiry?.contact && enquiry?.contact?.displayID) ? <span className="badge-custom">{enquiry.contact.displayID}</span> :
                                                <>{(service?.contact && service?.contact?.displayID) ? <span className="badge-custom">{service.contact.displayID}</span> : <></>}</>}

                                        </div>

                                        {tradeIn.EvaluationNumber ?
                                            <div className="report-ui-enq"> Valuation No.: <span> {tradeIn.EvaluationNumber} </span> </div> :
                                            <>
                                                {
                                                    tradeIn.evalexpertID ?
                                                        <div className="report-ui-enq"> Eval Expert No.: <span> {tradeIn.evalexpertID} </span> </div> :
                                                        <>
                                                            {
                                                                enquiry.displayID ?
                                                                    <div className="report-ui-enq"> Enquiry No.: <span> {enquiry.displayID} </span> </div> :
                                                                    <>
                                                                        {
                                                                            service?.displayID ?
                                                                                <div className="report-ui-enq"> Service No.: <span> {service.displayID} </span> </div> :
                                                                                <>
                                                                                </>
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </>
                                        }

                                    </div>

                                    <div className="report-ui-subcontainer padding-15">
                                        <div className="table-details">
                                            <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                <tbody>
                                                    {
                                                        !_.isEmpty(service)
                                                            ?
                                                            <tr>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span>Customer</span>{CommonHelper.displayFullContact(null, service?.contact, '--')}</div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span><Translate text={'phone'} /></span>{service.contact.phone ?
                                                                        CommonHelper.phoneFormat(service?.contact?.phoneCode, service.contact.phone, { client: client })
                                                                        : '--'} </div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span><Translate text={'email'} /></span> {service?.contact?.email ? service.contact.email : '--'}</div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span><Translate text={'Address'} /></span>{service?.contact?.address ? service.contact.address : '--'}</div>
                                                                </td>

                                                            </tr>
                                                            :
                                                            <tr>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span>Customer</span>{CommonHelper.displayFullContact(null, enquiry?.contact, '--')}</div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span><Translate text={'phone'} /></span>{enquiry.contact.phone ?
                                                                        CommonHelper.phoneFormat(enquiry?.contact?.phoneCode, enquiry.contact.phone, { client: client })
                                                                        : '--'} </div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span><Translate text={'email'} /></span> {enquiry?.contact?.email ? enquiry.contact.email : '--'}</div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span><Translate text={'Address'} /></span>{enquiry?.contact?.address ? enquiry.contact.address : '--'}</div>
                                                                </td>

                                                            </tr>
                                                    }
                                                    <tr>
                                                        <td width="25%">
                                                            <div className="grid-one"> <span>Dealer Name</span> {!_.isEmpty(client) ? client.name : '--'}</div>
                                                        </td>
                                                        <td width="25%">
                                                            <div className="grid-one"> <span>Created By</span> {(!_.isEmpty(users) && addedBy) ? CommonHelper.getUserNamebyDocumentId(users, addedBy) : '--'} </div>
                                                        </td>
                                                        <td width="25%"></td>
                                                        <td width="25%"></td>

                                                    </tr>






                                                </tbody>

                                            </table>
                                        </div>

                                    </div>

                                    <div className="report-ui-subhead"> VEHICLE IMAGES</div>
                                    <div className="report-ui-subcontainer">

                                        <div className="report-ui-thumb-wraper">

                                            <>
                                                {
                                                    (!_.isEmpty(images) || !_.isEmpty(sidePhotos) || (!_.isEmpty(marks) && marks.find(item => !_.isEmpty(item.images))))
                                                        ?
                                                        <>

                                                            {
                                                                !_.isEmpty(images) && images.map((data, index) => (
                                                                    <div className="report-ui-thumb-item-image" key={index}>
                                                                        <a data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                            <img id={`tradein-before-img-${index}`} src={data} width="80" height="80" alt="" className="img-object-fit" />
                                                                        </a>
                                                                    </div>
                                                                ))
                                                            }

                                                            {!_.isEmpty(sidePhotos) && Object.keys(sidePhotos).map((key, index) => {
                                                                if (sidePhotos[key]) {
                                                                    return (<div className="report-ui-thumb-item-image" key={index}>
                                                                        <a data-fancybox={`tradein_img`} href={sidePhotos[key]} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                            <img id={`tradein-side-img-${index}`} src={sidePhotos[key]} width="80" height="80" alt="" className="img-object-fit" />
                                                                        </a>
                                                                    </div>)
                                                                }

                                                            })}

                                                            {
                                                                !_.isEmpty(marks) && marks.map((mark, index) => {
                                                                    return <div key={index}>
                                                                        {!_.isEmpty(mark.images) && mark.images.map((data, index) => (
                                                                            <div className="report-ui-thumb-item-image" key={index}>
                                                                                <a data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                                    <img id={`tradein-mark-img-${index}`} src={data} width="80" height="80" alt="" className="img-object-fit" />
                                                                                </a>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                })
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                            <div className="report-ui-thumb-item-image">
                                                                <a href="#" onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                    <img src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} width="80" height="80" alt="" className="img-object-fit" />
                                                                </a>
                                                            </div>
                                                        </>
                                                }

                                            </>


                                        </div>

                                    </div>




                                    <div className="report-ui-disclaimer">This appraisal remains valid for a week. The evaluation is contingent on the
                                        successful transfer of clear title and approval from PPSR checks. The given price estimate
                                        relies on known vehicle data and/or a visual inspection (if conducted). A more detailed technical inspection
                                        may be required in the future, and the proposed price could
                                        be adjusted based on its findings. Vehicle information was collected electronically using specific vehicle
                                        identifiers. Despite our best efforts to guarantee accuracy, we
                                        advise not to rely solely on this data. Any mistakes or oversights are excepted.</div>


                                    <div className="footer">
                                        {/* <div className="footer-icons">                                            
                                            <div className="icons">
                                                <div className="badge-ins major-info"> i</div>
                                                <Translate text={'info'} upperCase={true} /></div>

                                            <div className="icons">
                                                <div className="badge-ins major-scratch"> S</div>
                                                <Translate text={'Major Scratch'} upperCase={true} /></div>
                                            <div className="icons">
                                                <div className="badge-ins minor-scratch"> S</div>
                                                <Translate text={'Minor Scratch'} upperCase={true} /></div>

                                            <div className="icons">
                                                <div className="badge-ins major-rust"> R</div>
                                                <Translate text={'Major Rust'} upperCase={true} /></div>
                                            <div className="icons">
                                                <div className="badge-ins minor-rust"> R</div>
                                                <Translate text={'Minor Rust'} upperCase={true} /></div>

                                            <div className="icons">
                                                <div className="badge-ins major-dent"> D</div>
                                                <Translate text={'Major Dent'} upperCase={true} /></div>
                                            <div className="icons">
                                                <div className="badge-ins minor-dent"> D</div>
                                                <Translate text={'Minor Dent'} upperCase={true} /></div>

                                            <div className="icons">
                                                <div className="badge-ins major-crack"> C</div>
                                                <Translate text={'Major Crack'} upperCase={true} /></div>
                                            <div className="icons">
                                                <div className="badge-ins minor-crack"> C</div>
                                                <Translate text={'Minor Crack'} upperCase={true} /></div>

                                            <div className="clear"></div>
                                        </div> */}
                                        <div className="footer-cpy">
                                            <div className="footer-left">Powered by <a target="_blank" href="http://fusionsd.com.au/">Fusion SD</a></div>
                                            <div className="footer-right">Created On - {!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment().format('DD/MM/YYYY hh:mm A')}</div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>

            }
        </>



    )
};

export default TradeInImages;
