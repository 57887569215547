import React, { useState, useRef } from 'react';
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import { AntDateRangePicker, ReactMultiSelect, ReactSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import moment from 'moment';
import ReactExport from 'react-export-excel-xlsx-fix';
import CommonHelper from '../../../../services/common';
import { roleVM, userexportFields } from './viewModel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExportUser = (props) => {
    const [state, setState] = useState({
        clients: props?.objFilter?.clients || props?.dealersettings?.clients?.map(r => { return r.id }),
        region: props?.objFilter?.region || [],
        subregion: props?.objFilter?.subregion || [],
        dealer: props?.objFilter?.dealer || [],
    })
    const [loader, setLoader] = useState(false)
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const handleDateRangeChange = (val, name) => {
        setState({
            ...state,
            [name]: val ? {
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            } : {}
        })
    }

    const handleSelectChange = (e, data) => {
        setState({
            ...state,
            [data.name]: e?.value || null
        })
    }

    const handleDownload = async () => {
        setLoader(true)
        const exportJson = window.firebase.functions().httpsCallable('amsadmin-getUsers');
        exportJson({
            "settingsID": props.dealersettings.oemID,
        }).then((data) => {
            setLoader(false)
            let edata = data?.data?.filter(a => !Boolean(a.isDisabled)).map(r => {
                return {
                    ...r,
                    department: _.find(props?.dealersettings?.client?.settings?.departments, { value: r.department })?.name || r.department,
                    dealer: _.find(props?.dealersettings?.clients, { id: r?.defaultClientID || r?.arrayClientID?.[0] })?.name || '',
                    createdOn: r?.metadata?.creationTime || '',
                    lastlogin: r?.metadata?.lastSignInTime || '',
                    actionedOn: r?.metadata?.lastRefreshTime || '',
                    createdOnUnix: r?.metadata?.creationTime ? moment(r?.metadata?.creationTime).unix() : 0,
                    lastloginUnix: r?.metadata?.lastSignInTime ? moment(r?.metadata?.lastSignInTime).unix() : 0,
                    actionedOnUnix: r?.metadata?.lastRefreshTime ? moment(r?.metadata?.lastRefreshTime).unix() : 0,
                }
            })
            edata = edata.filter(r =>
                (state?.dateRange && state?.dateType ? r[`${state.dateType}Unix`] >= state.dateRange.startDate.seconds && r[`${state.dateType}Unix`] <= state.dateRange.endDate.seconds : true)
                && (state.clients ? r.arrayClientID?.some(ai => state.clients.includes(ai)) : false));
            setCSVData(CommonHelper.generateExcelData(edata, userexportFields, props));
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
        });
    }

    const getRegions = () => {
        let regionsToReturn;
        let filteredRegions;

        filteredRegions = props.dealersettings.regions.filter(region => {
            if (props.dealersettings.clients.some(client => client.region === region.code)) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredRegions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getSubregions = () => {
        let regionsToReturn;
        let filteredSubregions;

        filteredSubregions = props.dealersettings.subregions.filter(subregion => {
            if (props.dealersettings.clients.some(client => (client.subregion === subregion.code) &&
                (!_.isEmpty(state['region']) ? state['region'].includes(client.region) : true))) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredSubregions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getDealers = () => {
        let clientsToReturn = props.dealersettings.clients;

        if (!_.isEmpty(state['region'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                state['region'].includes(client.region)
            );
        }

        if (!_.isEmpty(state['subregion'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                state['subregion'].includes(client.subregion)
            );
        }

        if (!_.isEmpty(state['country'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                state['country'].includes(client.country)
            );
        }

        clientsToReturn = clientsToReturn.map(client => {
            return {
                value: client.documentID,
                doc: client,
                label: client.name,
                active: _.isBoolean(client.active) ? client.active : true,
            };
        })

        return clientsToReturn
    }

    const setMultibjFilter = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let _state = state;
        if (name === 'region') {
            _state = {
                ..._state,
                [name]: value,
                ['subregion']: [],
                ['dealer']: [],
            };
        } else if (name === 'subregion') {
            _state = {
                ..._state,
                [name]: value,
                ['dealer']: [],
            };
        } else {
            _state = {
                ..._state,
                [name]: value,
            };
        }
        _state.clients = props?.dealersettings?.clients ? props.dealersettings.clients.filter(client =>
            (_state?.region?.length > 0 ? _state.region.includes(client.region) : true) &&
            (_state?.subregion?.length > 0 ? _state.subregion.includes(client.subregion) : true) &&
            (_state?.dealer?.length > 0 ? _state.dealer.includes(client.documentID) : true)
        ).map(r => { return r.id }) : []
        setState(_state);
    }

    return (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-transfer-leads modal-merge-data"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title> <Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid" >
                <div className="row">
                    <div className="form-style w-100 pipeline-form-style">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label >   <Translate text={'dateType'} /></label>
                                <ReactSelect
                                    options={userexportFields.filter(r => r.date).map(r => {
                                        return {
                                            ...r,
                                            value: r.key
                                        }
                                    })}
                                    id={"date-type"}
                                    name={"dateType"}
                                    placeholder={'select date option'}
                                    onChange={handleSelectChange}
                                    value={state.dateType}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={false}
                                >
                                </ReactSelect>
                            </div>
                            <div className="form-group col-md-8">
                                <label >   <Translate text={'dateRange'} /></label>
                                <AntDateRangePicker
                                    value={!_.isEmpty(state?.dateRange) ? [moment.unix(state?.dateRange.startDate.seconds), moment.unix(state?.dateRange.endDate.seconds)] : null}
                                    id={"dashboard-date-range"}
                                    name={'dateRange'}
                                    format='DD/MM/YYYY'
                                    onChange={(e) => { handleDateRangeChange(e, 'dateRange') }}
                                    placeholder='DD/MM/YYYY'
                                    className={'ant-picker-billing'}
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label >   <Translate text={'Region'} /></label>
                                <ReactMultiSelect
                                    options={getRegions()}
                                    name={'region'}
                                    placeholder={`select region`}
                                    onChange={(e) => {
                                        setMultibjFilter(e, 'region')
                                    }}
                                    value={state['region']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                            <div className="form-group col-md-12">
                                <label >   <Translate text={'subregion'} /></label>
                                <ReactMultiSelect
                                    options={getSubregions()}
                                    name={'subregion'}
                                    placeholder={`select subregion`}
                                    onChange={(e) => {
                                        setMultibjFilter(e, 'subregion')
                                    }}
                                    value={state['subregion']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                            <div className="form-group col-md-12">
                                <label >   <Translate text={'dealer'} /></label>
                                <ReactMultiSelect
                                    options={getDealers()}
                                    name={'dealer'}
                                    placeholder={`select dealer`}
                                    onChange={(e) => {
                                        setMultibjFilter(e, 'dealer')
                                    }}
                                    value={state['dealer']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <ExcelFile ref={csvBtn} element={<a href="#" className='hide'><i className="ico icon-excel-download"></i> Export Excel</a>} filename={`active-users`}>
                <ExcelSheet dataSet={csvData} name={!_.isEmpty(state?.dateRange) ? `${moment.unix(state.dateRange.startDate.seconds).format('DD MMM YYYY')} - ${moment.unix(state.dateRange.endDate.seconds).format('DD MMM YYYY')}` : 'Active Users'} />
            </ExcelFile>
            <button type="button" className="btn btn-primary float-right ml-2" onClick={handleDownload}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                Download

            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
        </Modal.Footer>
    </Modal>
    );
}

export default ExportUser;