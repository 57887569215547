import React, { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import Swal from 'sweetalert2';
import CommonHelper from '../../../../services/common';
import _ from 'lodash';
import { deviceshistoryfieldsVM } from '../viewModel';
import { TableView, ContentOverlay, ReactSelect } from '../../../../components';
import moment from 'moment'

const AllActiveDevices = props => {
	const [devices, setDevices] = useState(null);
	const [searchText, setSearchText] = useState('');
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 485,
		windowHeight: window.innerHeight - 343,
	});
	const [loader, setLoader] = useState(true)
	const [selectedClient, setSelectedClient] = useState([])
	const [clients, setClients] = useState([])

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 485,
				windowHeight: window.innerHeight - 343,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

	useEffect(() => {
		const devicesRef = window.firebase
			.firestore()
			.collectionGroup(`devices`)
			.where('oemID', '==', props.dealersettings.client.settingsID);


		window.userSnapshot = devicesRef.onSnapshot(async userSnap => {
			let data = [];
			let users = []
			let usersData = []
			if (userSnap.empty) {
				return;
			}
			// console.log('userSnap', userSnap);
			userSnap.forEach(doc => {
				let docData = {
					...doc.data(),
					documentID: doc.id,
				};

				if (!docData.userID || !docData.clientID || !docData.loginDate) {
					return;
				}

				if (docData.userID) {
					users.push(docData.userID)
				}

				if (docData.isLogin === false && !_.isEmpty(docData.logoutDate) && props.dealersettings.clients.some(client => client.documentID === docData.clientID)) {
					data.push(docData);
				}
			});

			users = _.uniq(users)
			// console.log('users', users);

			const promises = []
			users.forEach(el => {
				promises.push(window.firebase.firestore().doc(`users/${el}`).get())
			});
			const snapshots = await Promise.all(promises)
			snapshots.forEach(snap => {
				if (snap.exists) {
					usersData.push(snap.data())
				}
			})
			// console.log('usersData', usersData)
			data = data.map(_data => convertVM(_data, usersData))
			data = data.filter(_data => (_data.userName && !_data.userName.toLowerCase() === 'admin') || !Boolean(_data.superAdmin))

			// console.log('data', data);

			data = _.orderBy(data, ['logoutDate'], ['desc']);

			// let currentDevice = [...data];
			// let otherDevice = [...data];
			// currentDevice = currentDevice.filter(
			// 	a => a.deviceID === localStorage.browserKey,
			// );
			// otherDevice = data.filter(a => a.deviceID !== localStorage.browserKey);
			// console.log(
			// 	'all onece',
			// 	uniqBy(data, 'deviceID'),
			// );
			setDevices(uniqBy(data, 'deviceID'));
			setLoader(false)
		});

		return () => {
			window.userSnapshot && window.userSnapshot();
		};
	}, [props.dealersettings.clients, props.dealersettings.client.settingsID]);

	useEffect(() => {
		if (_.isEmpty(props.dealersettings) || localStorage.defaultModule === 'oem')
			return

		// const clientSnapshot = window.firebase.firestore().collection(`clients`)
		//     .where('documentID', 'in', props.dealersettings.clients.map(v => _.pick(v, ['id']).id))
		//     .onSnapshot(querySnapshot => {
		let _clients = [];
		props.dealersettings.clients.forEach(rec => {
			_clients.push({
				label: rec.name,
				value: rec.id,
				data: rec,
				active: true,
			})
		})
		setSelectedClient(_clients.filter(a => a.value === props.dealersettings.client.id)[0].data)
		setClients(_clients)
		// setLoader(false)
		//     })
		// return () => {
		//     clientSnapshot && clientSnapshot()
		// }

	}, [props])

	const convertVM = (doc, usersData) => {
		const objLog = { ...doc }
		const currentUser = usersData.filter(a => a.documentID === objLog.userID)[0]

		objLog.email = currentUser && currentUser.email ? currentUser.email : '';
		objLog.modifiedDate = objLog.modifiedDate ? objLog.modifiedDate.toDate() : '';
		objLog.loginDate = objLog.loginDate ? objLog.loginDate.toDate() : '';
		objLog.logoutDate = objLog.logoutDate ? objLog.logoutDate.toDate() : '';
		objLog.location =
			objLog.geoLocation &&
				objLog.geoLocation.location &&
				objLog.geoLocation.city &&
				objLog.geoLocation.country_name &&
				objLog.geoLocation.region_name
				? `${objLog.geoLocation.city} - ${objLog.geoLocation.country_name} - ${objLog.geoLocation.region_name}`
				: 'No Location Data';
		objLog._ip =
			objLog.geoLocation && objLog.geoLocation.ip
				? objLog.geoLocation.ip
				: objLog.deviceInfo && objLog.deviceInfo.query
					? objLog.deviceInfo.query
					: '';
		objLog.userName = currentUser && currentUser.name ? currentUser.name : CommonHelper.getUserNamebyId(
			props.clientUsers,
			objLog.userID,
		);

		objLog._user = (
			<div className='security-userthumb'>
				<div className='security-userthumbimage'>
					{' '}
					<img
						src={currentUser && currentUser.profileImage ? currentUser.profileImage : CommonHelper.getUserImagebyId(
							props.clientUsers,
							objLog.userID,
						)}
						alt=''
						className='rounded-circle img-object-fit'
					/>{' '}
				</div>
				<div className='security-userthumb-info'>
					{' '}
					{objLog.userName}
				</div>
			</div>
		)
		objLog.struser = objLog.userName;
		objLog.strdevice = `${objLog.name}, ${objLog.platform}`;
		objLog.strlocation = `${objLog.location}`;
		objLog.strloginDate = `${moment(objLog.loginDate).format('DD/MM/YYYY hh:mm A')}`;
		objLog.strlogoutDate = `${moment(objLog.logoutDate).format('DD/MM/YYYY hh:mm A')}`;

		objLog._device = (
			<div className='security-devicethumb'>
				<div className='security-devicethumbimage'>
					{objLog.platform.toLowerCase() === 'ios' ? (
						<i className='ico icon-ios security-device-icon'></i>
					) : objLog.platform.toLowerCase() === 'android' ? (
						<i className='ico icon-android security-device-icon'></i>
					) : (
						// <i className={`ico icon-monitor fill-icon`}></i>
						<i className='ico icon-monitor security-device-icon'></i>
					)}
				</div>
				<div className='security-devicethumb-info'>
					{' '}
					{`${objLog.name}, ${objLog.platform}`}{' '}
				</div>
			</div>
		)

		objLog._location = (
			<div className='securitylist-items-info '>
				<h4>{objLog.location ? objLog.location : ''} </h4>
				<h5>{objLog._ip ? objLog._ip : ''}</h5>
			</div>
		)

		objLog._loginDate = (
			<div className='securitylist-items-info '>
				<h4>
					{`${moment(objLog.loginDate).format('DD/MM/YYYY hh:mm A')}`}{' '}
				</h4>
			</div>
		)

		objLog._logoutDate = (
			<div className='securitylist-items-info '>
				<h4>
					{`${moment(objLog.logoutDate).format('DD/MM/YYYY hh:mm A')}`}{' '}
				</h4>
			</div>
		)
		return objLog
	}

	const filterBySearchText = device => {
		let returnVal = false;

		if (!searchText.length) {
			return true;
		}

		if (device.userName && device.userName.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		if (device.name && device.name.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		if (device.platform && device.platform.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		if (device.user && device.user.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		return returnVal;
	};

	const handleRowSingleClick = () => { }

	return (
		<>
			<p className='mb-3'>
			{CommonHelper.showLocale(props, 'Below are all the devices that are logged out.')}
			</p>
			<div className='settings-head'>
				<div className='float-left'>
					<div className='filter-search search-icon'>
						<div className='input-group template-search'>
							<input type='text' className='form-control' placeholder='Search' value={searchText} onChange={e => setSearchText(e.target.value)} style={{ width: '100%' }} />
							<div className='input-group-append input-search-clear'>
								{searchText.length > 0 && (
									<a href='#' className='input-search-clear-icon' style={{ top: '9px' }} onClick={() => setSearchText('')}>
										<i className='ico icon-remove'></i>
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="float-right" style={{ width: '180px' }}>
					<ReactSelect
						options={clients}
						name={"client"}
						placeholder={'select client'}
						onChange={(e) => {
							setSelectedClient(e.data)
						}}
						value={!_.isEmpty(selectedClient) ? selectedClient.documentID : ''}
						classNamePrefix={`basic-select`}
						removeClearable={true}
					>
					</ReactSelect>
				</div>
				{/* <div className="float-right">
        <div className="enquiry-view-more float-right mt-0 dropdown"><a href="#" className="common-button dropdown-toggle"><i className="ico icon-more"></i></a></div>
        <div className="advanced-filter-btn float-right " id="dropdown03" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <a href="#"> <span className="badge badge-pill badge-advanced-filter">2</span> <i className="ico icon-filter"></i> Filter </a> <a href="#" className="clear-btn"> <i className="ico icon-remove"></i> </a> </div>
        </div> */}
			</div>
			<div className='security-inner-section'>
				{loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
					<div className='common-table'>
						<TableView
							isReorderable={false}
							datalist={devices ? devices.filter(device => device.clientID === selectedClient.documentID).filter(device => filterBySearchText(device)) : []}
							height={windowSize.windowHeight}
							width={windowSize.windowWidth}
							columns={deviceshistoryfieldsVM}
							dynamicFields={deviceshistoryfieldsVM
								.filter(e => e.default === true)
								.map(v => _.pick(v, ['value']).value)}
							settingsLoader={false}
							// handleActionClick={handleActionClick}
							handleRowSingleClick={handleRowSingleClick}
							isSettings={false}
							hasMore={false}
							isPaging={false}
							isSorting={true}
							isTranslate={true}
							dealersettings={props.dealersettings}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default AllActiveDevices;
