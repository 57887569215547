
import React from 'react'
import Translate from '../constants/translate';


export const stockStatus = {
    AVAILABLE: 'available',
    SOLD: 'sold',
    DELIVERED: 'delivered',
    ONLOAN: 'onLoan',
    ONTESTDRIVE: 'onTestdrive',
    RESERVED: 'reserved',
    INPRODUCTION: 'inProduction',
    UNAVAILABLE: 'unavailable',
    MCRETAILED: 'mclarenRetailed'
}

export const testDriveStatus = {
    COMPLETE: 'complete',
    PENDING: 'pending'
}

export const fleetStatus = {
    COMPLETE: 'complete',
    PENDING: 'pending'
}

export const fleetAssignTypes = {
    RetailerEvent: 'retailerEvent',
    ExternalInternalEvent: 'extIntEvent',
    LoanVehicle: 'loanVehicle'
}

export const fleetProCategory = {
    MARKETING: 'marketing',
    PR: 'publicRelation',
    MASOASSeT: 'msoAsset'
}

export const enquiryStatus = {
    OPEN: 'open',
    WON: 'won',
    LOST: 'lost',
    DELIEVERD: 'delivered',
    PENDINGLOST: 'pendingLost',
    PROSPECTLOST: 'prospectLost',
    PENDING: 'pending',
    CLOSED: 'closeWon',
}

export const enqUnActionStatus = {
    COMPLETED: 'completed',
    PENDING: 'pending'
}

export const cafeStatus = {
    PENDING: 'pending',
    PREPARING: 'preparing',
    READY: 'ready',
    DELIVERED: 'delivered',
    CANCELLED: 'cancelled'
}

export const tipStatus = {
    NEW: 'New',
    WON: 'Won',
    LOST: 'Lost',
    PENDING: 'Pending',
    INPROGRESS: 'InProgress',
    COMPLETED: 'Completed',
}

export const eventStatus = {
    DRAFT: 'draft',
    PUBLISHED: 'published',
    LIVE: 'live',
    CLOSED: 'closed'
}

export const fleetProStatus = {
    ALLOCATED: 'allocated',
    FOK: 'fok',
    ONFLEETING: 'onfleeting',
    OPERATIONAL: 'operational',
    INPREP: 'inprep',
    DEFLEETED: 'defleeted',
}

export const fleetAssignStatus = {
    REQUESTED: 'requested',
    PENDINGAPPROVAL: 'pendingApproval',
    CANCELLED: 'cancelled',
    DECLINED: 'declined',
    APPROVED: 'approved',
    RECEIVED: 'received',
    RELEASED: 'released',
}


export const tipOfferStatus = {
    COMPLETED: 'completed',
    PENDING: 'pending',
    WON: 'won',
    LOST: 'lost'
}

export const stockAvail = {
    SALE: 'sale',
    TESTDRIVE: 'testdrive',
    LOAN: 'loan'
}

export const inboundLeadStatus = {
    0: 'Pending',
    1: 'Allocated',
    2: 'Cancelled'
}

export const engageStatus = {
    PENDING: 'pending',
    REJECT: 'reject',
    ACCEPT: 'accept'
}

export const callLogStatus = {
    PENDING: 'pending',
    LOGGED: 'logged',
    REJECTED: 'rejected',
    ACCEPTED: 'accepted'
}

export const contactResponseCode = {
    Undefined: 'Undefined',
    Exception: 'Exception',
    Success: 'Success',
    EmailAlreadyExists: 'EmailAlreadyExists',
    PhoneAlreadyExists: 'PhoneAlreadyExists',
    LicenseAlreadyExists: 'LicenseAlreadyExists',
    PMDSAlreadyExists: 'PMDSAlreadyExists',
    KeyloopAlreadyExists: 'KeyloopAlreadyExists',
    CAPAlreadyExists: 'CAPAlreadyExists'
}

export const tradeInStatus = {
    PENDING: 'Pending',
    WON: 'Won',
    LOST: 'Lost'
}

export const eventsProStatus = {
    PUBLISHED: 'Published',
    LIVE: 'Live',
}

export const eventAllocationStatus = {
    PENDING: 'pending',
    NEW: 'new',
    ACCEPTED: 'accepted'
}

export const eventAttendeeStatus = {
    Accepted: 'accepted',
    Tentative: 'tentative',
    Declined: 'declined',
    Rejected: 'rejected',
    EOIConfirmed: 'eoi-confirmed',
    EOIResponse: 'eoi-response',
    EOIUnqualified: 'eoi-unqualified',
}

export const testDriveBookingStatus = {
    REQUESTED: 'requested',
    BOOKED: 'booked'
}

export const statusColors = [
    { value: '#545A6C', searchlabel: 'CHARCOAL GREY', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#545A6C` }}></div>CHARCOAL GREY</div>) },
    { value: '#000000', searchlabel: 'BLACK', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#000000` }}></div>BLACK</div>) },
    { value: '#607D8B', searchlabel: 'SLATE GRAY', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#607D8B` }}></div>SLATE GRAY</div>) },
    { value: '#B0BABE', searchlabel: 'LIGHT GRAY', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#B0BABE` }}></div>LIGHT GRAY</div>) },
    { value: '#981230', searchlabel: 'MAROON', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#981230` }}></div>MAROON</div>) },
    { value: '#EF4136', searchlabel: 'RED', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#EF4136` }}></div>RED</div>) },
    { value: '#EA1E64', searchlabel: 'PINK', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#EA1E64` }}></div>PINK</div>) },
    { value: '#9C28B0', searchlabel: 'VIOLET', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#9C28B0` }}></div>VIOLET</div>) },
    //{ value: '#4466F2', searchlabel: 'ROYAL BLUE', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#4466F2` }}></div>ROYAL BLUE</div>) },
    { value: '#0070BC', searchlabel: 'NAVY BLUE', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#0070BC` }}></div>NAVY BLUE</div>) },
    { value: '#28AAE2', searchlabel: 'SKY BLUE', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#28AAE2` }}></div>SKY BLUE</div>) },
    { value: '#00BCD4', searchlabel: 'FLUORESCENT BLU', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#00BCD4` }}></div>FLUORESCENT BLUE</div>) },
    { value: '#049B8C', searchlabel: 'PERSIAN GREEN', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#049B8C` }}></div>PERSIAN GREEN</div>) },
    { value: '#00A75F', searchlabel: 'GREEN', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#00A75F` }}></div>GREEN</div>) },
    { value: '#A5AA3E', searchlabel: 'MOSS GREEN', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#A5AA3E` }}></div>MOSS GREEN</div>) },
    { value: '#F68412', searchlabel: 'ORANGE', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#F68412` }}></div>ORANGE</div>) },
    { value: '#F05A24', searchlabel: 'DARK ORANGE', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#F05A24` }}></div>DARK ORANGE</div>) },
    { value: '#785648', searchlabel: 'BROWN', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#785648` }}></div>BROWN</div>) },
    { value: '#C87646', searchlabel: 'SANDY BROWN', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#C87646` }}></div>SANDY BROWN</div>) },
    { value: '#CC9C87', searchlabel: 'TAN BROWN', label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: `#CC9C87` }}></div>TAN BROWN</div>) },
]

export const callTypes = [
    { value: 'inbound', label: (<><i className="ico icon-call-inbound"></i> Inbound</>), name: 'Inbound' },
    { value: 'outbound', label: (<><i className="ico icon-call-outbound"></i> Outbound</>), name: 'Outbound' }
]

export const logTypes = [
    { value: 'call', name: 'call', label: 'Call' },
    { value: 'internet', name: 'internet', label: 'Internet' },
    { value: 'walkin', name: 'walkin', label: 'Walk In' }
]

export const serviceStatus = {
    CANCELLED: 'cancelled',
    NEWSERVICE: 'newService',
    SCHEDULED: 'scheduled',
    CHECKIN: 'checkIn',
    TECHNICIANREQUESTED: 'technicianRequested',
    PARTSREQUESTED: 'partsRequested',
    WORKSHOPREQUESTED: 'workshopRequested',
    ADVISORREQUESTED: 'advisorRequested',
    OWNERREQUESTED: 'ownerRequested',
    OWNERRESPONDED: 'ownerResponded',
    OWNERAPPROVED: 'ownerApproved',
    OWNERDECLINED: 'ownerDeclined',
    WORKINPROGRESS: 'workInProgress',
    COMPLETED: 'completed',
    PENDING: 'pending',
}

export const service_Types = [
    { active: true, value: 'external', label: "External", name: "External" },
    { active: true, value: 'internal', label: 'Internal', name: 'Internal' },
]


export const defaultFileCategories = [
    { active: true, default: true, level: 'oem', module: 'sales', value: 'quotation', name: 'Quotation', label: 'Quotation' },
    { active: true, default: true, level: 'oem', module: 'sales', value: 'invoice', name: 'Invoice', label: 'Invoice' },
    { active: true, default: true, level: 'oem', module: 'sales', value: 'contract', name: 'Contract', label: 'Contract' },
    { active: true, default: true, level: 'oem', module: 'sales', value: 'others', name: 'Others', label: 'Others' }
]

export const serviceStatuses = [
    { active: true, value: 'scheduled', name: 'Scheduled', label: <div className={`badge badge-pill status-scheduled`}><Translate text={'scheduled'} upperCase={true} /></div> },
    { active: true, value: 'checkIn', name: 'Check In', label: <div className={`badge badge-pill status-checkin`}><Translate text={'checkIn'} upperCase={true} /></div> },
    { active: true, value: 'technicianRequested', name: 'Technician Requested', label: <div className={`badge badge-pill status-technicianrequested`}><Translate text={'technicianRequested'} upperCase={true} /></div> },
    { active: true, value: 'partsRequested', name: 'Parts Requested', label: <div className={`badge badge-pill status-partsrequested`}><Translate text={'partsRequested'} upperCase={true} /></div> },
    { active: true, value: 'workshopRequested', name: 'Workshop Requested', label: <div className={`badge badge-pill status-workshoprequested`}><Translate text={'workshopRequested'} upperCase={true} /></div> },
    { active: true, value: 'advisorRequested', name: 'Advisor Requested', label: <div className={`badge badge-pill status-advisorrequested`}><Translate text={'advisorRequested'} upperCase={true} /></div> },
    { active: true, value: 'ownerRequested', name: 'Owner Requested', label: <div className={`badge badge-pill status-ownerrequested`}><Translate text={'ownerRequested'} upperCase={true} /></div> },
    { active: true, value: 'ownerApproved', name: 'Owner Approved', label: <div className={`badge badge-pill status-ownerapproved`}><Translate text={'ownerApproved'} upperCase={true} /></div> },
    { active: true, value: 'ownerDeclined', name: 'Owner Declined', label: <div className={`badge badge-pill status-ownerdeclined`}><Translate text={'ownerDeclined'} upperCase={true} /></div> },
    { active: true, value: 'workInProgress', name: 'Work In Progress', label: <div className={`badge badge-pill status-workinprogress`}><Translate text={'workInProgress'} upperCase={true} /></div> },
    { active: true, value: 'completed', name: 'Completed', label: <div className={`badge badge-pill status-completed`}><Translate text={'completed'} upperCase={true} /></div> },

]

export const tipStatuses = [
    { active: true, value: 'New', name: 'New', label: <div className={`badge badge-pill status-new`}><Translate text={'new'} upperCase={true} /></div> },
    { active: true, value: 'Won', name: 'Won', label: <div className={`badge badge-pill status-won`}><Translate text={'won'} upperCase={true} /></div> },
    { active: true, value: 'Lost', name: 'Lost', label: <div className={`badge badge-pill status-lost`}><Translate text={'lost'} upperCase={true} /></div> },
    { active: true, value: 'Pending', name: 'Pending', label: <div className={`badge badge-pill status-pending`}><Translate text={'pending'} upperCase={true} /></div> },
    { active: true, value: 'InProgress', name: 'InProgress', label: <div className={`badge badge-pill status-inprogress`}><Translate text={'inprogress'} upperCase={true} /></div> },
    { active: true, value: 'Completed', name: 'Completed', label: <div className={`badge badge-pill status-completed`}><Translate text={'completed'} upperCase={true} /></div> },

]

export const tipOfferStatuses = [
    { active: true, value: 'won', name: 'Won', label: <div className={`badge badge-pill status-won`}><Translate text={'won'} upperCase={true} /></div> },
    { active: true, value: 'lost', name: 'Lost', label: <div className={`badge badge-pill status-lost`}><Translate text={'lost'} upperCase={true} /></div> },
    { active: true, value: 'pending', name: 'Pending', label: <div className={`badge badge-pill status-pending`}><Translate text={'pending'} upperCase={true} /></div> },
    { active: true, value: 'completed', name: 'Completed', label: <div className={`badge badge-pill status-completed`}><Translate text={'completed'} upperCase={true} /></div> },

]

export const recommStatuses = [
    { active: true, color: '#ff9e3e', value: 'inProgress', name: 'In Progress', label: <div className={`badge badge-pill status-pending`}><Translate text={'inprogress'} upperCase={true} /></div> },
    { active: true, color: '#4CAF50', value: 'completed', name: 'Completed', label: <div className={`badge badge-pill status-completed`}><Translate text={'completed'} upperCase={true} /></div> },

]

export const stockScoreGrades = [
    { active: true, color: '#22b573', value: 'A', name: 'A', id: '25-30', min: 25, max: 30 },
    { active: true, color: '#29abe2', value: 'B', name: 'B', id: '20-24', min: 20, max: 24 },
    { active: true, color: '#f7931e', value: 'C', name: 'C', id: '16-19', min: 16, max: 19 },
    { active: true, color: '#d2555e', value: 'D', name: 'D', id: '11-15', min: 11, max: 15 },
    { active: true, color: '#d2555e', value: 'E', name: 'E', id: '7-10', min: 7, max: 10 },
    { active: true, color: '#d2555e', value: 'F', name: 'F', id: '0-6', min: 0, max: 6 }
]

// export const stockPriceScore = [
//     { active: true, value: 10, name: '10', id: '0-30', min: 0, max: 30 },
//     { active: true, value: 8, name: '8', id: '31-60', min: 31, max: 60 },
//     { active: true, value: 6, name: '6', id: '61-75', min: 61, max: 75 },
//     { active: true, value: 4, name: '4', id: '76-90', min: 76, max: 90 },
//     { active: true, value: 2, name: '2', id: '91-105', min: 91, max: 105 },
//     { active: true, value: 1, name: '1', id: '106-9999', min: 106, max: 9999 }
// ]

export const stockPriceScore = [
    { active: true, value: 10, name: '10', id: '0-10', min: 0, max: 10 },
    { active: true, value: 0, name: '0', id: '11-9999', min: 11, max: 9999 }
]

export const stockCommentsScore = [
    { active: true, value: 10, name: '10', id: '0-30', min: 0, max: 30 },
    { active: true, value: 8, name: '8', id: '31-45', min: 31, max: 45 },
    { active: true, value: 4, name: '4', id: '46-60', min: 46, max: 60 },
    { active: true, value: 3, name: '3', id: '61-75', min: 61, max: 75 },
    { active: true, value: 0, name: '0', id: '76-9999', min: 76, max: 9999 }
]

export const stockPriceAR = [
    { active: true, value: 10, name: '10', id: '0-10', min: 0, max: 10 },
    { active: true, value: 8, name: '8', id: '11-15', min: 11, max: 15 },
    { active: true, value: 6, name: '6', id: '16-20', min: 16, max: 20 },
    { active: true, value: 5, name: '5', id: '21-25', min: 21, max: 25 },
    { active: true, value: 3, name: '3', id: '26-30', min: 26, max: 30 },
    { active: true, value: 0, name: '0', id: '31-9999', min: 31, max: 9999 }
]

// export const stockPlateChangeScore = [
//     { active: true, value: 10, name: '10', id: '0-45', min: 0, max: 45 },
//     { active: true, value: 8, name: '8', id: '46-50 ', min: 46, max: 50 },
//     { active: true, value: 6, name: '6', id: '51-54', min: 51, max: 54 },
//     { active: true, value: 3, name: '3', id: '55-59', min: 55, max: 59 },
//     { active: true, value: 0, name: '0', id: '60-9999', min: 60, max: 9999 }
// ]

export const stockPlateChangeScore = [
    { active: true, value: 10, name: '10', id: '0-40', min: 0, max: 40 },
    { active: true, value: 0, name: '0', id: '41-9999', min: 41, max: 9999 }
]

export const stockAgeScore = [
    { active: true, value: 10, name: '10', id: '0-45', min: 0, max: 45 },
    { active: true, value: 8, name: '8', id: '46-50 ', min: 46, max: 50 },
    { active: true, value: 6, name: '6', id: '51-55', min: 51, max: 55 },
    { active: true, value: 4, name: '4', id: '56-60', min: 56, max: 60 },
    { active: true, value: 2, name: '2', id: '61-70', min: 61, max: 70 },
    { active: true, value: 0, name: '0', id: '71-9999', min: 71, max: 9999 }
]

export const stockManagementScore = [
    { active: true, color: '#22b573', value: 'A', name: 'A', label: '> 69', id: '69-999', min: 69, max: 999 },
    { active: true, color: '#29abe2', value: 'B', name: 'B', label: '68-60', id: '60-68', min: 60, max: 68 },
    { active: true, color: '#f7931e', value: 'C', name: 'C', label: '59-51', id: '51-59', min: 51, max: 59 },
    { active: true, color: '#d2555e', value: 'D', name: 'D', label: '50-44', id: '44-50', min: 44, max: 50 },
    { active: true, color: '#d2555e', value: 'E', name: 'E', label: '43-36', id: '36-43', min: 36, max: 43 },
    { active: true, color: '#d2555e', value: 'F', name: 'F', label: '< 35', id: '0-35', min: 0, max: 35 }
]

export const serviceRole = {
    technician: 'technician',
    partsInterpretor: 'partsInterpretor',
    workshopManager: 'workshopManager',
    serviceAdvisor: 'serviceAdvisor',
}

export const recommendationChoice = {
    Required: 'Required',
    Pass: 'Pass',
    NotAvailable: 'N/A',
}

export const recommendationOption = {
    customer: 'customer',
    warranty: 'warranty',
    internal: 'internal',
}
export const recommPartQuotes = {
    customer: 'customer',
    warranty: 'warranty',
    includedInPackage: 'includedInPackage',
}

export const discount_type = {
    percentage: 'percentage',
    amount: 'amount'
}

export const service_type = {
    internal: 'internal',
    external: 'external'
}

export const defaultenquiryStatus = [
    { active: true, default: true, dynamic: true, color: '#ffffff', level: 'oem', value: 'open', name: 'Open' },
    { active: true, default: true, color: '#2bb673', level: 'oem', value: 'won', name: 'Won' },
    { active: true, default: true, color: '#049b8c', level: 'oem', value: 'delivered', name: 'Delivered' },
    { active: true, default: true, color: '#ef4136', level: 'oem', value: 'lost', name: 'Lost' },
    { active: true, default: true, color: '#ef4136', level: 'oem', value: 'prospectLost', name: 'Prospect Lost' }
]

export const defaulttipStatuses = [
    { active: true, default: true, dynamic: true, color: '#ffffff', level: 'oem', value: 'New', name: 'New' },
    { active: true, default: true, color: '#2bb673', level: 'oem', value: 'Won', name: 'Won' },
    { active: true, default: true, color: '#ef4136', level: 'oem', value: 'Lost', name: 'Lost' },
    { active: true, default: true, color: '#ff9e3e', level: 'oem', value: 'Pending', name: 'Pending' },
    { active: true, default: true, color: '#29abe2', level: 'oem', value: 'InProgress', name: 'InProgress' },
    { active: true, default: true, color: '#4CAF50', level: 'oem', value: 'Completed', name: 'Completed' }
]

export const defaultfleetStatuses = [
    { active: true, default: true, color: '#616b84', level: 'oem', value: 'allocated', name: 'Allocated' },
    { active: true, default: true, color: '#ff7363', level: 'oem', value: 'fok', name: 'FOK' },
    { active: true, default: true, color: '#f15a29', level: 'oem', value: 'inprep', name: 'In Prep', booking: true },
    { active: true, default: true, color: '#ff9d00', level: 'oem', value: 'onfleeting', name: 'On-Fleeting', scheduler: true },
    { active: true, default: true, color: '#27aae1', level: 'oem', value: 'operational', name: 'Operational', scheduler: true },
    { active: true, default: true, color: '#2bb673', level: 'oem', value: 'assigned', name: 'Assigned' },
    { active: true, default: true, color: '#ed1c24', level: 'oem', value: 'defleeted', name: 'Defleeted' },
    { active: true, default: true, color: '#ed1c24', level: 'oem', value: 'offroard', name: 'Off-Road', booking: true },
]

export const defaultfleetAssignStatuses = [
    { active: true, default: true, color: '#ffe8cc', level: 'oem', value: 'pendingApproval', name: 'Pending Approval' },
    { active: true, default: true, color: '#f9c7c7', level: 'oem', value: 'cancelled', name: 'Cancelled' },
    { active: true, default: true, color: '#ef4136', level: 'oem', value: 'declined', name: 'Declined' },
    { active: true, default: true, color: '#2bb673', level: 'oem', value: 'approved', name: 'Approved' },
    { active: true, default: true, color: '#2bb673', level: 'oem', value: 'received', name: 'Received' },
    { active: true, default: true, color: '#2bb673', level: 'oem', value: 'released', name: 'released' }
]

export const defaultfleetCategory = [
    { active: true, default: true, level: "oem", name: "Marketing", value: "marketing" },
    { active: true, default: true, level: "oem", name: "PR", value: "publicRelation" },
    { active: true, default: true, level: "oem", name: "MSO Asset", value: "msoAsset" }
]

export const defaultfleetAssignTypes = [
    { active: true, default: true, level: "oem", name: "Retailer Event", value: "retailerEvent" },
    { active: true, default: true, level: "oem", name: "External/Internal Event", value: "extIntEvent" },
    { active: true, default: true, level: "oem", name: "Loan Vehicle", value: "loanVehicle" }
]

export const defaulteventStatuses = [
    { active: true, default: true, dynamic: true, color: '#607D8D', level: 'oem', value: 'draft', name: 'Draft' },
    { active: true, default: true, color: '#2bb673', level: 'oem', value: 'published', name: 'Published' },
    { active: true, default: true, color: '#4CAF50', level: 'oem', value: 'live', name: 'Live' },
    { active: true, default: true, color: '#ef4136', level: 'oem', value: 'closed', name: 'Closed' },
]

export const defaultserviceStatuses = [
    { active: true, default: true, color: '#875A5A', level: 'oem', value: 'scheduled', name: 'Scheduled' },
    { active: true, default: true, color: '#607D8D', level: 'oem', value: 'checkIn', name: 'Check In' },
    { active: true, default: true, color: '#d99d8c', level: 'oem', value: 'technicianRequested', name: 'Technician Requested' },
    { active: true, default: true, color: '#16a89a', level: 'oem', value: 'partsRequested', name: 'Parts Requested' },
    { active: true, default: true, color: '#9b5082', level: 'oem', value: 'workshopRequested', name: 'Workshop Requested' },
    { active: true, default: true, color: '#6f62a5', level: 'oem', value: 'advisorRequested', name: 'Advisor Requested' },
    { active: true, default: true, color: '#359cd3', level: 'oem', value: 'ownerRequested', name: 'Owner Requested' },
    { active: true, default: true, color: '#64a000', level: 'oem', value: 'ownerApproved', name: 'Owner Approved' },
    { active: true, default: true, color: '#e25b3d', level: 'oem', value: 'ownerDeclined', name: 'Owner Declined' },
    { active: true, default: true, color: '#ff9d00', level: 'oem', value: 'workInProgress', name: 'Work In Progress' },
    { active: true, default: true, color: '#4CAF50', level: 'oem', value: 'completed', name: 'Completed' },
    { active: true, default: true, color: '#DE252A', level: 'oem', value: 'cancelled', name: 'Cancelled' }
]

export const defaultTechnicianStatuses = [
    { active: true, default: true, color: '#4967cc', level: 'oem', value: 'pending', name: 'Pending' },
    { active: true, default: true, color: '#ff9d00', level: 'oem', value: 'workInProgress', name: 'Work In Progress' },
    { active: true, default: true, color: '#4CAF50', level: 'oem', value: 'completed', name: 'Completed' }
]

export const defaultenquiryLabels = [
    { active: true, default: true, color: '#ed1c24', level: 'oem', value: 'hot', name: 'Hot' },
    { active: true, default: true, color: '#f7931e', level: 'oem', value: 'warm', name: 'Warm' },
    { active: true, default: true, color: '#29abe2', level: 'oem', value: 'cold', name: 'Cold' }
]

export const defaultcontactTypes = [
    { active: true, default: true, level: 'oem', value: 'Potential Customer', name: 'Potential Customer' },
    { active: true, default: true, level: 'oem', value: 'Current Customer', name: 'Current Customer' }
]

export const defaultaftermarketStatus = [
    { active: true, color: '#f05a24', level: 'oem', value: 'InProgress', name: 'InProgress' },
    { active: true, color: '#f68412', level: 'oem', value: 'Pending', name: 'Pending' },
    { active: true, color: '#2bb673', level: 'oem', value: 'won', name: 'Won' },
    { active: true, color: '#ef4136', level: 'oem', value: 'lost', name: 'Lost' },
]

export const defaultStockStatus = [
    { active: true, level: 'oem', color: '#2bb673', badge: 'badge-available-fill', default: true, value: 'available', name: 'Available' },
    { active: true, level: 'oem', color: '#708090', badge: 'badge-sold-fill', default: true, value: 'sold', name: 'Sold' },
    { active: true, level: 'oem', color: '#049b8c', badge: 'badge-status-delivered', default: true, value: 'delivered', name: 'Delivered' },
    { active: true, level: 'oem', color: '#545a6d', badge: 'badge-onloan-fill', default: false, value: 'onLoan', name: 'on Loan' },
    { active: true, level: 'oem', color: '#29abe2', badge: 'badge-ontestdrive-fill', default: false, value: 'onTestdrive', name: 'on Testdrive' },
    { active: true, level: 'oem', color: '#f7931e', badge: 'badge-onloan-fill', default: false, value: 'reserved', name: 'Reserved' },
    { active: true, level: 'oem', color: '#f68412', badge: 'badge-inproduction-fill', default: true, value: 'inProduction', name: 'In Production' },
    { active: true, level: 'oem', color: '#ef4136', badge: 'badge-unavailable-fill', default: true, value: 'unavailable', name: 'Unavailable' },
    { active: true, level: 'oem', color: '#4466f2', badge: 'badge-onservicefleet-fill', default: true, value: 'onServiceFleet', name: 'On Service Fleeet' }
]

export const financestatus = [
    { active: true, value: 'InProgress', name: 'In Progress', label: <div className={`badge badge-pill badge-status-inprogress`}><Translate text={'InProgress'} upperCase={true} /></div> },
    { active: true, value: 'Pending', name: 'Pending', label: <div className={`badge badge-pill badge-status-pending`}><Translate text={'Pending'} upperCase={true} /></div> },
    { active: true, value: 'Won', name: 'Won', label: <div className={`badge badge-pill badge-status-won`}><Translate text={'won'} upperCase={true} /></div> },
    { active: true, value: 'Lost', name: 'Lost', label: <div className={`badge badge-pill badge-status-lost`}><Translate text={'lost'} upperCase={true} /></div> }
]

export const defaultFinanceTerms = [
    { active: true, value: 0.5, label: '6 months' },
    { active: true, value: 1, label: '1 year' },
    { active: true, value: 1.5, label: '1.5 years' },
    { active: true, value: 2, label: '2 years' },
    { active: true, value: 2.5, label: '2.5 years' },
    { active: true, value: 3, label: '3 years' },
    { active: true, value: 3.5, label: '3.5 years' },
    { active: true, value: 4, label: '4 years' },
    { active: true, value: 4.5, label: '4.5 years' },
    { active: true, value: 5, label: '5 years' }
];

export const defaultCreditScore = [{
    title: "Very Poor",
    color: "#f00c01",
    lowScore: 0,
    highScore: 299
}, {
    title: "Poor",
    color: "#fd6420",
    lowScore: 300,
    highScore: 499
}, {
    title: "Fair",
    color: "#ffa400",
    lowScore: 500,
    highScore: 699
}, {
    title: "Good",
    color: "#95ae03",
    lowScore: 700,
    highScore: 799
}, {
    title: "Excellent",
    color: "#57b10e",
    lowScore: 800,
    highScore: 1200
}];


export const defaultActivityTypes = [
    {
        "default": true,
        "active": true,
        "icon": "activity-appointment",
        "name": "Appointment",
        "level": "oem",
        "value": "appointment"
    },
    {
        "value": "testdrive",
        "active": true,
        "level": "oem",
        "default": true,
        "name": "Test Drive",
        "icoVehicle": true,
        "icon": "activity-testdrive"
    },
    {
        "value": "call",
        "level": "oem",
        "icon": "call",
        "active": true,
        "default": true,
        "name": "Call"
    },
    {
        "level": "oem",
        "value": "email",
        "active": true,
        "default": true,
        "name": "Mail",
        "icon": "Mail"
    },
    {
        "default": true,
        "active": true,
        "name": "Delivery",
        "icoVehicle": true,
        "icon": "activity-delivery",
        "value": "delivery",
        "level": "oem"
    },
    {
        "active": true,
        "default": true,
        "name": "Task",
        "value": "task",
        "level": "oem",
        "icon": "activity"
    }
]

export const reminderOptions = [
    // { value: "-1", label: 'NONE' },
    { value: 0, label: 'At the time of event' },
    { value: 1, label: '1 min before' },
    { value: 5, label: '5 mins before' },
    { value: 10, label: '10 mins before' },
    { value: 15, label: '15 mins before' },
    { value: 30, label: '30 mins before' },
    { value: 60, label: '1 hour before' },
    { value: 120, label: '2 hours before' }
];


export const calendarEventColor = [
    { name: 'Red', value: '#ffd3cc' },
    { name: 'Blue', value: '#d5e5fe' },
    { name: 'Green', value: '#c9f2d1' },
    { name: 'Yellow', value: '#fff0cb' },
    { name: 'Orange', value: '#ffe5cb' },
    { name: 'Pink', value: '#f1d7df' },
    { name: 'Purple', value: '#eae1ff' },
    { name: 'Brown', value: '#dba9a9' },
    { name: 'ivory', value: '#f3f3f2' }
]

export const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]


export const categories = [
    { value: 'Automotive', label: 'Automotive' },
    { value: 'Boat', label: 'Boat' },
    { value: 'Motorcycle', label: 'Motorcycle' },
    { value: 'Portables', label: 'Portables' }
]

export const userLevel = {
    OEM: 'oem',
    REGION: 'region',
    SUBREGION: 'subregion',
    GROUP: 'group',
    INDIVIDUAL: 'individual'
}

// export const vehicleCategories = {
//     Automotive: 'Automotive',
//     Motorcycle: 'Motorcycle'
// }