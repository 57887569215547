/** LIBRARIES */
import React, { useState, useMemo, useEffect, useReducer } from 'react';
import moment from 'moment'
import _ from 'lodash'
import toast from 'toasted-notes'

import { collection } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'

/** PROVIDERS */
import { StageProvider } from '../../pipeline/provider';
/** COMPONENTS */
import { ContentOverlay, PopUpModal, TableView, SidePanel } from '../../../components'
import Translate from '../../../constants/translate';

import StockHistoryReducer from '../history/stockHistoryReducer'
import AddFleet from '../../fleet/add'
import AddTestDrive from '../../testdrive/add'
import AddActivity from '../../activity'
import CommonHelper from '../../../services/common';
import QuickView from '../../pipeline/quickview';
import AddNotes from '../../service/details/addNotes'
import Activity from '../activity';
import Files from '../../files';
/** VIEW-MODELS */
import { testDriveStatus } from '../../../services/enum'
import { objFleetHistoryVM, allFleetStockHistoryFields } from '../../fleet/viewModel'
import { objStock, allStockNotesHistoryFields } from '../../stock/viewModel'
import { objTestDriveHistoryVM, allTestDriveHistoryFieldsVM } from '../../testdrive/viewModel'
import { objEnquiry, objActivitylog, allEnquiryStockHistoryFields, allBookingStockHistoryFields } from '../../pipeline/viewModel';
import { firestoreDB } from '../../../services/helper';

const StockHistoryListView = (props) => {
    const [activeBookingTab, setActiveBookingTab] = useState('Previous');
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - (window.innerWidth * (38 / 100))), windowHeight: (window.innerHeight - 170) })
    const [stockHistory, dispatch] = useReducer(StockHistoryReducer, { Loan: [], TestDrive: [], Enquiries: [], Bookings: [], Notes: [], ChangeLogs: [], Specifications: [] })
    const [checkDataLoad, setDataload] = useState({ "Loan": true, "TestDrive": true, "Enquiries": true, "Bookings": true, "Notes": true, "ChangeLogs": true, "Specifications": true })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelFleetShow, setModelFleetShow] = useState(false)
    const [modelTestDriveShow, setModelTestDriveShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [modelStock, setModelStock] = useState({})
    const [stockHistoryLoader, setLoader] = useState({ "Loan": true, "TestDrive": true, "Enquiries": true, "Bookings": true, "Notes": true, "ChangeLogs": true, "Specifications": true })
    const [activeTab, setActiveTab] = useState(localStorage.defaultModule === 'service' ? 'Loan' : 'Enquiries')

    const [showActivityModel, setShowActivityModel] = useState(false)
    const [activityHeader, setActivityHeader] = useState('Add New Activity')
    const [newActivitylog, setNewActivitylog] = useState(null);
    const [enquiryObj, setEnquiryObj] = useState(null);
    const [contactObj, setContactObj] = useState(null);
    const [stockObj, setStockObj] = useState(null);
    const [isNewActivity, setNewActivity] = useState(false)
    const [showpanel, setShowPanel] = useState({
        clsActive: '',
        clsName: 'sidebar-quickview',
        enquiryid: 0
    })

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [enquiryTypes, setEnquiryTypes] = useState([])
    const [enquiryOptions, setEnquiryOptions] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])

    const [showNotes, setShowNotes] = useState(false)

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - (window.innerWidth * (38 / 100))),
                windowHeight: (window.innerHeight - 170)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])
    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })
            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });


            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                _origins.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            let _enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                _enquiryTypes.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            let _enquiryOptions = [];
            setttings.enquiryOptions && setttings.enquiryOptions.forEach(doc => {
                _enquiryOptions.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                _campaigns.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            let _pipelines = [];
            setttings.pipelines && setttings.pipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            setTitles(_titles);
            setEnquiryTypes(_enquiryTypes);
            setEnquiryOptions(_enquiryOptions);
            setCampaigns(_campaigns);
            setOrigins(_origins);
            setLanguages(_languages);
            setNationalities(_nationalities)
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
        }
    }, [])


    useEffect(() => {
        return () => {
            window.unsubscribeFleet && window.unsubscribeFleet();
            window.unsubscribeTestDrives && window.unsubscribeTestDrives();
            window.unsubscribeActivity && window.unsubscribeActivity();
            window.refEnquiries && window.refEnquiries.unsubscribe();
            window.unsubscribeStackNotes && window.unsubscribeStackNotes();
            window.unsubscribeEnqRequirement && window.unsubscribeEnqRequirement();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }


        if (activeTab === 'Loan') {
            window.unsubscribeFleet = firestoreDB(props.dealersettings).firestore().collection('fleet')
                .where('stockID', '==', props.stockID)
                .where('isDeleted', '==', false)
                //.orderBy('startDate', 'desc')
                //.orderBy('documentID', 'desc')
                .onSnapshot(onFleetCollectionUpdate);
        }
        else if (activeTab === 'TestDrive') {
            window.unsubscribeTestDrives = firestoreDB(props.dealersettings).firestore().collection('testdrives')
                .where('stockID', '==', props.stockID)
                .where('isDeleted', '==', false)
                //.orderBy('startDate', 'desc')
                //.orderBy('documentID', 'desc')
                .onSnapshot(onTestDrivesCollectionUpdate);
        }
        else if (activeTab === 'Bookings') {
            window.unsubscribeActivity = firestoreDB(props.dealersettings).firestore().collection('activities')
                .where('stockID', '==', props.stockID)
                .where('type', '==', 'activity')
                //.where('subType', '==', 'testdrive')
                .where('isDeleted', '==', false)
                //.orderBy('startDate', 'asc')
                .onSnapshot(onActivityCollectionUpdate);
        }
        else if (activeTab === 'Notes') {
            window.unsubscribeStackNotes = window.firebase.firestore().collection(`stock/${props.stockID}/notes`)
                .onSnapshot(onNotesCollectionUpdate);
        }
        else if (activeTab !== 'ChangeLogs' && activeTab !== 'Specifications') {

            window.unsubscribeEnqRequirement = firestoreDB(props.dealersettings).firestore().collectionGroup('requirements')
                .where('stock.documentID', '==', props.stockID)
                .where('isDeleted', '==', false)
                .onSnapshot(onEnquiryCollectionUpdate);
            // let _enquiryID = props.stock && props.stock.enquiryID ? props.stock.enquiryID : null;           

            // window.refEnquiries = combineLatest(
            //     collection(window.firebase.firestore().collection('enquiries').where('requirement.stock.documentID', '==', props.stockID).where('isDeleted', '==', false)),
            //     _enquiryID ? collection(window.firebase.firestore().collection('enquiries').where('documentID', '==', _enquiryID).where('isDeleted', '==', false)) : of([])
            // ).pipe(
            //     map(([enqASnapshot, enqBSnapshot]) => {
            //         return [
            //             _.map(enqASnapshot, function (obj) { return { ...obj.data(), documentID: obj.id } }),
            //             _.map(enqBSnapshot, function (obj) { return { ...obj.data(), documentID: obj.id } }),
            //         ]
            //     }))
            //     .subscribe(async ([enqASnapshot, enqBSnapshot]) => {
            //         const enquiries = [];
            //         enqASnapshot && enqASnapshot.forEach((_enquiry) => {
            //             enquiries.push(convertEnquiryVM(_enquiry));
            //         });
            //         enqBSnapshot && enqBSnapshot.forEach((_enquiry) => {
            //             if (!enquiries.some(i => i.documentID === _enquiry.documentID))
            //                 enquiries.push(convertEnquiryVM(_enquiry));
            //         });

            //         Promise.all(enquiries).then(_enquiries => {

            //             dispatch({
            //                 type: "SUCCESS_LIST",
            //                 data: _enquiries,
            //                 activetab: activeTab
            //             });
            //             setDataload({
            //                 ...checkDataLoad,
            //                 [activeTab]: false
            //             })
            //             setLoader({
            //                 ...stockHistoryLoader,
            //                 [activeTab]: false
            //             })
            //         })

            //     });

        }

    }, [checkDataLoad, activeTab])

    const onNotesCollectionUpdate = (querySnapshot) => {
        const activities = [];
        querySnapshot.forEach((_activity) => {
            const activityVM = Object.assign({}, _activity.data());

            activityVM.documentID = _activity.id;
            activityVM.createdOn = activityVM.addedDate ? moment.unix(activityVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
            activityVM.createdBy = activityVM.addedBy ? CommonHelper.getUserNamebyId(allUsers, activityVM.addedBy) : '--';

            activities.push(activityVM)
        });

        dispatch({
            type: "SUCCESS_LIST",
            data: _.orderBy(activities, ["addedDate"], ["desc"]),
            activetab: activeTab
        });
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoader({
            ...stockHistoryLoader,
            [activeTab]: false
        })

    }

    const onEnquiryCollectionUpdate = async (querySnapshot) => {
        const refEnquiryPromises = [];
        querySnapshot.forEach((_activity) => {
            const activityVM = Object.assign({}, _activity.data());
            if (activityVM.enquiryID) {
                refEnquiryPromises.push(firestoreDB(props.dealersettings).firestore().collection('enquiries').doc(activityVM.enquiryID).get());
            }
        });
        const enquiries = [];
        const snapshots1 = await Promise.all(refEnquiryPromises)
        snapshots1.forEach((doc, index) => {
            if (doc.exists) {
                enquiries.push(convertEnquiryVM(doc.data()));
            }
        })

        dispatch({
            type: "SUCCESS_LIST",
            data: _.orderBy(enquiries, ["addedDate"], ["asc"]),
            activetab: activeTab
        });
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoader({
            ...stockHistoryLoader,
            [activeTab]: false
        })

    }


    const onActivityCollectionUpdate = (querySnapshot) => {
        const activities = [];
        querySnapshot.forEach((_activity) => {
            const activityVM = Object.assign({}, objActivitylog);
            const objActivityData = Object.assign({}, _activity.data());
            for (let [key, value] of Object.entries(objActivityData)) {
                activityVM[key] = value;
            }
            activityVM.documentID = _activity.id;

            activityVM.createdOn = activityVM.addedDate ? moment.unix(activityVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
            activityVM.updatedOn = activityVM.modifiedDate ? moment.unix(activityVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
            activityVM.createdBy = activityVM.addedBy ? CommonHelper.getUserNamebyId(allUsers, activityVM.addedBy) : '--';
            activityVM.updatedBy = activityVM.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, activityVM.modifiedBy) : '--';
            activityVM.activityOwner = activityVM.owner ? CommonHelper.getUserNamebyId(allUsers, activityVM.owner) : '--';

            activityVM.startDate = activityVM.startDate ? activityVM.startDate : activityVM.addedDate;

            // activityVM.startDate = activityVM.startDate ? moment.unix(activityVM.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            // activityVM.endDate = activityVM.endDate ? moment.unix(activityVM.endDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

            // if (activityVM.contact && typeof activityVM.contact === 'object' && Object.keys(activityVM.contact).length > 0) {
            //     activityVM.contactName = <div onClick={(e) => {
            //         e.preventDefault();
            //         const url = `/contacts/details/${activityVM.contact.documentID}`;
            //         window.open(url, '_blank');
            //     }}>{activityVM.contact.firstName + ' ' + activityVM.contact.lastName}</div>;
            //     activityVM.contactEmail = activityVM.contact.email ? activityVM.contact.email : '';
            //     activityVM.contactPhone = activityVM.contact.phone ? activityVM.contact.phone : '';
            //     activityVM.contactLicenseNo = activityVM.contact.licenseNo ? activityVM.contact.licenseNo : '';
            // }
            activityVM.dueDate = activityVM.startDate ? moment.unix(activityVM.startDate.seconds).format('DD/MM/YYYY') : '--';
            activityVM.dueTime = moment.unix(activityVM.startDate.seconds).format('hh:mm A') + ' - ' + moment.unix(activityVM.endDate.seconds).format('hh:mm A');

            activityVM.activityCompletedDate = activityVM.completedDate ? moment.unix(activityVM.completedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            activityVM.activityCompletedBy = CommonHelper.getUserNamebyId(allUsers, activityVM.completedBy);

            if (!_.isEmpty(activityVM.enquiry)) {
                activityVM.enquiryStatus = activityVM.enquiry.status;
            }
            if (!_.isEmpty(activityVM.stock)) {
                activityVM.testdriveModel = activityVM.stock.make + ' ' + activityVM.stock.model;
            }
            if (!_.isEmpty(activityVM.contact)) {
                activityVM.contactName = CommonHelper.displayFullContact(titles, activityVM.contact)
                // <div onClick={(e) => {
                //     e.preventDefault();
                //     const url = `/contacts/details/${activityVM.contact.documentID}`;
                //     window.open(url, '_blank');
                // }}>{CommonHelper.displayFullContact(titles, activityVM.contact)}</div>;
                activityVM.phone = activityVM.contact.phone ? (activityVM.contact.phoneCode ? activityVM.contact.phoneCode : '') + activityVM.contact.phone : '';
                activityVM.contactPhone = CommonHelper.phoneFormat(activityVM.contact.phoneCode, activityVM.contact.phone, props.dealersettings);
                activityVM.contactEmail = activityVM.contact.email;
                activityVM.licenseNo = activityVM.contact.licenseNo;
                activityVM.licenseExpiry = activityVM.contact.licenseExpiry ? moment(activityVM.contact.licenseExpiry).format('DD/MM/YYYY') : '';
                activityVM.contactType = activityVM.contact.contactType;
                activityVM.dob = activityVM.contact.dob ? moment(activityVM.contact.dob).format('DD/MM/YYYY') : '';
                activityVM.gender = activityVM.contact.gender;
                activityVM.maritalStatus = activityVM.contact.maritalStatus;
                activityVM.contactMethod = activityVM.contact.contactMethod;
                activityVM.licenseType = activityVM.contact.licenseType;
                activityVM.isDLScan = activityVM.contact.isDLScan ? 'Yes' : 'No';
                activityVM.language = CommonHelper.getLabelByValue(languages, activityVM.contact.language, '');
                activityVM.nationality = CommonHelper.getLabelByValue(nationalities, activityVM.contact.nationality, '');


                let dealersettings = props.dealersettings;
                let _setttings = activityVM.contact.clientID && dealersettings && dealersettings.group &&
                    dealersettings.group.clients && dealersettings.group.clients[activityVM.contact.clientID] && dealersettings.group.clients[activityVM.contact.clientID].settings;
                if (_.isEmpty(_setttings))
                    _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
                if (_setttings) {
                    // activityVM.contactName = <div onClick={(e) => {
                    //     e.preventDefault();
                    //     const url = `/contacts/details/${activityVM.contact.documentID}`;
                    //     window.open(url, '_blank');
                    // }}>{CommonHelper.displayFullContact(_setttings.titles, activityVM.contact, '', true)}</div>;

                    activityVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, activityVM.contact.campaign, '');
                    activityVM.origin = CommonHelper.getNameByValue(_setttings.origins, activityVM.contact.origin, '');
                    activityVM.gender = CommonHelper.getNameByValue(_setttings.genders, activityVM.contact.gender, '');
                    activityVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, activityVM.contact.maritalStatus, '');
                    activityVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, activityVM.contact.contactMethod, '');
                    activityVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, activityVM.contact.interests, '', true);
                    activityVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, activityVM.contact.licenseType, '');
                }

                if (dealersettings.group && activityVM.contact.clientID && (activityVM.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
                    activityVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, activityVM.contact.clientID);
                }
                else
                    activityVM.dealerName = '';

                activityVM.address = activityVM.contact.address;
                activityVM.companyName = activityVM.contact.companyName;
                activityVM.designation = activityVM.contact.designation;

                activityVM.licenseState = activityVM.contact.licenseState;
                activityVM.secondaryContactName = activityVM.contact.secondaryFirstName ? (activityVM.contact.secondaryFirstName + ' ' + activityVM.contact.secondaryLastName) : '';
                activityVM.secondaryPhone = activityVM.contact.secondaryPhone ? ((activityVM.contact.secondaryPhoneCode ? activityVM.contact.secondaryPhoneCode : '') + activityVM.contact.secondaryPhone) : '';
                activityVM.secondaryContactPhone = CommonHelper.phoneFormat(activityVM.contact.secondaryPhoneCode, activityVM.contact.secondaryPhone, props.dealersettings);
                activityVM.secondaryContactEmail = activityVM.contact.secondaryEmail;
                activityVM.contactCreatedBy = activityVM.contact.addedBy ? CommonHelper.getUserNamebyId(allUsers, activityVM.contact.addedBy) : '';
                activityVM.contactModifiedby = activityVM.contact.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, activityVM.contact.modifiedBy) : '';
                activityVM.contactCreatedOn = activityVM.contact.addedDate ? moment.unix(activityVM.contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
                activityVM.contactModifiedOn = activityVM.contact.modifiedDate ? moment.unix(activityVM.contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            }

            activities.push(activityVM)
        });

        dispatch({
            type: "SUCCESS_LIST",
            data: _.orderBy(activities, ["startDate"], ["asc"]),
            activetab: activeTab
        });
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoader({
            ...stockHistoryLoader,
            [activeTab]: false
        })

    }

    const convertEnquiryVM = (_enquiry) => {

        const enquiryVM = Object.assign({}, objEnquiry);
        const _objData = Object.assign({}, _enquiry);
        for (let [key, value] of Object.entries(_objData)) {
            enquiryVM[key] = value;
        }

        enquiryVM.createdOn = enquiryVM.addedDate ? moment.unix(enquiryVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        enquiryVM.updatedOn = enquiryVM.modifiedDate ? moment.unix(enquiryVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        enquiryVM.addedBy = enquiryVM.addedBy ? CommonHelper.getUserNamebyId(allUsers, enquiryVM.addedBy) : '--';
        enquiryVM.modifiedBy = enquiryVM.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, enquiryVM.modifiedBy) : '--';
        enquiryVM.pipelineValue = _enquiry.pipeline;
        enquiryVM.pipeline = CommonHelper.getNameByValue(props.dealersettings.allPipelines, enquiryVM.pipeline, '');
        if (_.find(props.dealersettings.allPipelines, { value: enquiryVM.pipelineValue })) {
            enquiryVM.stage = CommonHelper.getNameByValue(_.find(props.dealersettings.allPipelines, { value: enquiryVM.pipelineValue }).stages, enquiryVM.stage, '');
        }

        if (!_.isEmpty(enquiryVM.contact)) {
            let _contact = Object.assign({}, enquiryVM.contact);
            enquiryVM.contactName = CommonHelper.displayFullContact(titles, _contact)
            // <div onClick={(e) => {
            //     e.preventDefault();
            //     const url = `/contacts/details/${_contact.documentID}`;
            //     window.open(url, '_blank');
            // }}>{CommonHelper.displayFullContact(titles, _contact)}</div>;
            enquiryVM.phone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
            enquiryVM.contactPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
            enquiryVM.contactEmail = _contact.email;
            enquiryVM.licenseNo = _contact.licenseNo;
            enquiryVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
            enquiryVM.contactType = _contact.contactType;
            enquiryVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
            enquiryVM.gender = _contact.gender;
            enquiryVM.maritalStatus = _contact.maritalStatus;
            enquiryVM.contactMethod = _contact.contactMethod;
            enquiryVM.licenseType = _contact.licenseType;
            enquiryVM.isDLScan = _contact.isDLScan ? 'Yes' : 'No';
            enquiryVM.language = CommonHelper.getLabelByValue(languages, _contact.language, '');
            enquiryVM.nationality = CommonHelper.getLabelByValue(nationalities, _contact.nationality, '');

            let dealersettings = props.dealersettings;
            let _setttings = _contact.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[_contact.clientID] && dealersettings.group.clients[_contact.clientID].settings;
            if (_.isEmpty(_setttings))
                _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
            if (_setttings) {

                enquiryVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, _contact.campaign, '');
                enquiryVM.origin = CommonHelper.getNameByValue(_setttings.origins, _contact.origin, '');
                enquiryVM.gender = CommonHelper.getNameByValue(_setttings.genders, _contact.gender, '');
                enquiryVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, _contact.maritalStatus, '');
                enquiryVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, _contact.contactMethod, '');
                enquiryVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, _contact.interests, '', true);
                enquiryVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, _contact.licenseType, '');
            }

            if (dealersettings.group && _contact.clientID && (_contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
                enquiryVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, _contact.clientID);
            }
            else
                enquiryVM.dealerName = '';

            enquiryVM.address = _contact.address;
            enquiryVM.companyName = _contact.companyName;
            enquiryVM.designation = _contact.designation;

            enquiryVM.licenseState = _contact.licenseState;
            enquiryVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
            enquiryVM.secondaryPhone = _contact.secondaryPhone ? ((_contact.secondaryPhoneCode ? _contact.secondaryPhoneCode : '') + _contact.secondaryPhone) : '';
            enquiryVM.secondaryContactPhone = CommonHelper.phoneFormat(_contact.secondaryPhoneCode, _contact.secondaryPhone, props.dealersettings);
            enquiryVM.secondaryContactEmail = _contact.secondaryEmail;
            enquiryVM.contactCreatedBy = _contact.addedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.addedBy) : '--';
            enquiryVM.contactModifiedby = _contact.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.modifiedBy) : '--';
            enquiryVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            enquiryVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        }

        enquiryVM.origin = CommonHelper.getLabelByValue(origins, enquiryVM.origin, '');
        enquiryVM.enquiryType = CommonHelper.getLabelByValue(enquiryTypes, enquiryVM.enquiryType, '');
        enquiryVM.leadSource = CommonHelper.getNameByValue(props.dealersettings.client.settings.leadSource, enquiryVM.leadSource, '');
        enquiryVM.label = CommonHelper.bindEnquiryLabel(props.dealersettings.client.settings.enquiryLabels, enquiryVM.label, '');
        enquiryVM.campaign = CommonHelper.getLabelByValue(campaigns, enquiryVM.campaign, '');

        const enquiryAllStatus = (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.enquiryStatus : []);
        let _objCurrentStatus = enquiryVM.status && enquiryAllStatus.filter(e => e.value === enquiryVM.status)[0];
        enquiryVM.status = !_.isEmpty(enquiryVM.status) ? (
            _objCurrentStatus
                ?
                <>
                    <span key={enquiryVM.status}
                        className={`status-${enquiryVM.status === 'open' ? 'open' : 'empty'}`}
                        style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                    >
                        {_objCurrentStatus.name}
                    </span>
                </>
                :
                <></>
        ) : '';

        return enquiryVM;
    }


    const onTestDrivesCollectionUpdate = (querySnapshot) => {
        const testdrives = [];
        querySnapshot.forEach((_testdrive) => {


            const testDriveVM = Object.assign({}, objTestDriveHistoryVM);
            const objTestDriveData = Object.assign({}, _testdrive.data());
            for (let [key, value] of Object.entries(objTestDriveData)) {
                testDriveVM[key] = value;
            }
            testDriveVM.documentID = _testdrive.id;
            testDriveVM.createdOn = testDriveVM.addedDate ? moment.unix(testDriveVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
            testDriveVM.updatedOn = testDriveVM.modifiedDate ? moment.unix(testDriveVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
            testDriveVM.addedBy = testDriveVM.addedBy ? CommonHelper.getUserNamebyId(allUsers, testDriveVM.addedBy) : '--';
            testDriveVM.modifiedBy = testDriveVM.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, testDriveVM.modifiedBy) : '--';

            if (!_.isEmpty(testDriveVM.contact)) {
                let _contact = Object.assign({}, testDriveVM.contact);
                testDriveVM.contactName = CommonHelper.displayFullContact(titles, _contact)
                // <div onClick={(e) => {
                //     e.preventDefault();
                //     const url = `/contacts/details/${_contact.documentID}`;
                //     window.open(url, '_blank');
                // }}>{CommonHelper.displayFullContact(titles, _contact)}</div>;
                testDriveVM.phone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
                testDriveVM.contactPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
                testDriveVM.contactEmail = _contact.email;
                testDriveVM.licenseNo = _contact.licenseNo;
                testDriveVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
                testDriveVM.contactType = _contact.contactType;
                testDriveVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
                testDriveVM.gender = _contact.gender;
                testDriveVM.maritalStatus = _contact.maritalStatus;
                testDriveVM.contactMethod = _contact.contactMethod;
                testDriveVM.licenseType = _contact.licenseType;
                testDriveVM.isDLScan = _contact.isDLScan ? 'Yes' : 'No';
                testDriveVM.language = CommonHelper.getLabelByValue(languages, _contact.language, '');
                testDriveVM.nationality = CommonHelper.getLabelByValue(nationalities, _contact.nationality, '');

                let dealersettings = props.dealersettings;
                let _setttings = _contact.clientID && dealersettings && dealersettings.group &&
                    dealersettings.group.clients && dealersettings.group.clients[_contact.clientID] && dealersettings.group.clients[_contact.clientID].settings;
                if (_.isEmpty(_setttings))
                    _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
                if (_setttings) {
                    // testDriveVM.contactName = <div onClick={(e) => {
                    //     e.preventDefault();
                    //     const url = `/contacts/details/${_contact.documentID}`;
                    //     window.open(url, '_blank');
                    // }}>{CommonHelper.displayFullContact(_setttings.titles, _contact, '', true)}</div>;

                    testDriveVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, _contact.campaign, '');
                    testDriveVM.origin = CommonHelper.getNameByValue(_setttings.origins, _contact.origin, '');
                    testDriveVM.gender = CommonHelper.getNameByValue(_setttings.genders, _contact.gender, '');
                    testDriveVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, _contact.maritalStatus, '');
                    testDriveVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, _contact.contactMethod, '');
                    testDriveVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, _contact.interests, '', true);
                    testDriveVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, _contact.licenseType, '');
                }

                if (_contact.clientID && (_contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                    testDriveVM.contactdealerName = CommonHelper.getOtherDealerName(dealersettings, _contact.clientID);
                }
                else
                    testDriveVM.contactdealerName = '';

                testDriveVM.address = _contact.address;
                testDriveVM.companyName = _contact.companyName;
                testDriveVM.designation = _contact.designation;

                testDriveVM.licenseState = _contact.licenseState;
                testDriveVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
                testDriveVM.secondaryPhone = _contact.secondaryPhone ? ((_contact.secondaryPhoneCode ? _contact.secondaryPhoneCode : '') + _contact.secondaryPhone) : '';
                testDriveVM.secondaryContactPhone = CommonHelper.phoneFormat(_contact.secondaryPhoneCode, _contact.secondaryPhone, props.dealersettings);
                testDriveVM.secondaryContactEmail = _contact.secondaryEmail;
                testDriveVM.contactCreatedBy = _contact.addedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.addedBy) : '--';
                testDriveVM.contactModifiedby = _contact.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.modifiedBy) : '--';
                testDriveVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
                testDriveVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            }

            testDriveVM.startDateTime = testDriveVM.startDate ? testDriveVM.startDate : testDriveVM.addedDate;
            testDriveVM.startDate = testDriveVM.startDate ? moment.unix(testDriveVM.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            testDriveVM.expectedReturn = testDriveVM.expectedReturn ? moment.unix(testDriveVM.expectedReturn.seconds).format('DD/MM/YYYY hh:mm A') : '';
            testDriveVM.endDate = testDriveVM.endDate ? moment.unix(testDriveVM.endDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            testDriveVM.status = (testDriveVM.status
                ?
                <div className={`badge badge-pill badge-${testDriveVM.status === testDriveStatus.PENDING ? 'pending' : 'active-fill'}`}>
                    {testDriveVM.status}
                </div>
                :
                <></>);

            if (!_.isEmpty(testDriveVM.stock)) {
                testDriveVM.stockNo = testDriveVM.stock.stockNo;
                testDriveVM.make = testDriveVM.stock.make;
                testDriveVM.model = testDriveVM.stock.model;
                testDriveVM.modelDescription = testDriveVM.stock.modelDescription;
                testDriveVM.saleType = CommonHelper.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
                    testDriveVM.stock.saleType, '');
                testDriveVM.type = testDriveVM.stock.type;
                testDriveVM.year = testDriveVM.stock.year;
                testDriveVM.regNo = testDriveVM.stock.regNo;
                testDriveVM.vinNo = testDriveVM.stock.vinNo;
                testDriveVM.extColor = testDriveVM.stock.extColor;
                testDriveVM.intColor = testDriveVM.stock.intColor;
                testDriveVM.price = testDriveVM.stock.price;
                testDriveVM.purchasePrice = testDriveVM.stock.purchasePrice;
                testDriveVM.location = testDriveVM.stock.location;
                testDriveVM.stockStatus = CommonHelper.bindStockLabel(props.dealersettings?.client?.settings?.stockStatus, testDriveVM.stock.status, '');
                testDriveVM.statusUpdatedBy = testDriveVM.stock.statusModifiedBy ? CommonHelper.getUserNamebyId(allUsers, testDriveVM.stock.statusModifiedBy) : '';
                if (testDriveVM.stock.clientID && (testDriveVM.stock.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                    testDriveVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, testDriveVM.stock.clientID);
                }
                else
                    testDriveVM.dealerName = '';
                // testDriveVM.stockStatus = (testDriveVM.stock.status
                //     ?
                //     <div className={`badge badge-pill badge-${testDriveVM.stock.status.trim().toLowerCase()}-fill `}>
                //         {testDriveVM.stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                //     </div>
                //     :
                //     <></>);
            }
            testdrives.push(testDriveVM);

            // if (testDriveVM.stockID) {
            //     const refTDStockData = window.firebase.firestore().collection('stock').doc(testDriveVM.stockID);

            //     testdrives.push(
            //         refTDStockData.get()
            //             .then((_testStock) => {
            //                 if (_testStock.exists) {
            //                     const _stock = Object.assign({}, objStock);
            //                     const objTDStockData = Object.assign({}, _testStock.data());
            //                     for (let [key, value] of Object.entries(objTDStockData)) {
            //                         _stock[key] = value;
            //                     }
            //                     _stock.documentID = testDriveVM.stockID;
            //                     testDriveVM.stock = _stock;

            //                     if (!_.isEmpty(_stock)) {
            //                         testDriveVM.stockNo = _stock.stockNo;
            //                         testDriveVM.make = _stock.make;
            //                         testDriveVM.model = _stock.model;
            //                         testDriveVM.modelDescription = _stock.modelDescription;
            //                         testDriveVM.saleType = _stock.saleType;
            //                         testDriveVM.type = _stock.type;
            //                         testDriveVM.year = _stock.year;
            //                         testDriveVM.regNo = _stock.regNo;
            //                         testDriveVM.vinNo = _stock.vinNo;
            //                         testDriveVM.extColor = _stock.extColor;
            //                         testDriveVM.intColor = _stock.intColor;
            //                         testDriveVM.price = _stock.price;
            //                         testDriveVM.purchasePrice = _stock.purchasePrice;
            //                         testDriveVM.location = _stock.location;
            //                         testDriveVM.stockStatus = (_stock.status
            //                             ?
            //                             <div className={`badge badge-pill badge-${_stock.status.trim().toLowerCase()}-fill `}>
            //                                 {_stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
            //                             </div>
            //                             :
            //                             <></>);
            //                     }
            //                     return testDriveVM
            //                 }
            //                 else {
            //                     //console.log("No such document!");
            //                 }
            //             })
            //     )
            // }
            // else {
            //     testdrives.push(testDriveVM);
            // }
        });

        Promise.all(testdrives).then(_testdrives => {

            dispatch({
                type: "SUCCESS_LIST",
                data: _.orderBy(_testdrives, ["startDateTime"], ["asc"]),
                activetab: activeTab
            });
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })
            setLoader({
                ...stockHistoryLoader,
                [activeTab]: false
            })
        })

    }

    const onFleetCollectionUpdate = querySnapshot => {
        var loans = [];
        querySnapshot.forEach(_fleet => {

            const fleetVM = Object.assign({}, objFleetHistoryVM);
            const objTestDriveData = Object.assign({}, _fleet.data());
            for (let [key, value] of Object.entries(objTestDriveData)) {
                fleetVM[key] = value;
            }
            fleetVM.documentID = _fleet.id;
            fleetVM.createdOn = fleetVM.addedDate ? moment.unix(fleetVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            fleetVM.updatedOn = fleetVM.modifiedDate ? moment.unix(fleetVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            fleetVM.addedBy = fleetVM.addedBy ? CommonHelper.getUserNamebyId(allUsers, fleetVM.addedBy) : '';
            fleetVM.modifiedBy = fleetVM.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, fleetVM.modifiedBy) : '';

            if (!_.isEmpty(fleetVM.contact)) {
                fleetVM.contactName = CommonHelper.displayContact([], fleetVM.contact, '--')
                // <div onClick={(e) => {
                //     e.preventDefault();
                //     const url = `/contacts/details/${fleetVM.contact.documentID}`;
                //     window.open(url, '_blank');
                // }}>{CommonHelper.displayContact([], fleetVM.contact, '--')}</div>;
                fleetVM.phone = fleetVM.contact.phone ? (fleetVM.contact.phoneCode ? fleetVM.contact.phoneCode : '') + fleetVM.contact.phone : '';
                fleetVM.contactEmail = fleetVM.contact.email ? fleetVM.contact.email : '';
                fleetVM.contactPhone = CommonHelper.phoneFormat(fleetVM.contact.phoneCode, fleetVM.contact.phone, props.dealersettings);
                fleetVM.contactLicenseNo = fleetVM.contact.licenseNo ? fleetVM.contact.licenseNo : '';
            }
            else if (!_.isEmpty(fleetVM.staff)) {
                fleetVM.contactName = fleetVM.staff.name ? fleetVM.staff.name : '';
            }

            fleetVM.startDateTime = fleetVM.startDate ? fleetVM.startDate : fleetVM.addedDate;
            fleetVM.startDate = fleetVM.startDate ? moment.unix(fleetVM.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            fleetVM.expectedReturn = fleetVM.expectedReturn ? moment.unix(fleetVM.expectedReturn.seconds).format('DD/MM/YYYY hh:mm A') : '';
            fleetVM.endDate = fleetVM.endDate ? moment.unix(fleetVM.endDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

            fleetVM.status = (fleetVM.status
                ?
                <div className={`badge badge-pill badge-${fleetVM.status === testDriveStatus.PENDING ? 'pending' : 'active-fill'}`}>
                    {fleetVM.status}
                </div>
                :
                <></>);


            if (!_.isEmpty(fleetVM.stock)) {
                fleetVM.stockNo = fleetVM.stock.stockNo;
                fleetVM.make = fleetVM.stock.make;
                fleetVM.model = fleetVM.stock.model;
                fleetVM.modelDescription = fleetVM.stock.modelDescription;
                fleetVM.saleType = CommonHelper.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
                    fleetVM.stock.saleType, '');
                fleetVM.type = fleetVM.stock.type;
                fleetVM.year = fleetVM.stock.year;
                fleetVM.regNo = fleetVM.stock.regNo;
                fleetVM.vinNo = fleetVM.stock.vinNo;
                fleetVM.extColor = fleetVM.stock.extColor;
                fleetVM.intColor = fleetVM.stock.intColor;
                fleetVM.price = fleetVM.stock.price;
                fleetVM.purchasePrice = fleetVM.stock.purchasePrice;
                fleetVM.location = fleetVM.stock.location;
                fleetVM.stockStatus = CommonHelper.bindStockLabel(props.dealersettings?.client?.settings?.stockStatus, fleetVM.stock.status, '');
                // fleetVM.stockStatus = (fleetVM.stock.status
                //     ?
                //     <div className={`badge badge-pill badge-${fleetVM.stock.status.trim().toLowerCase()}-fill `}>
                //         {fleetVM.stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                //     </div>
                //     :
                //     <></>);
            }
            loans.push(fleetVM)
            // if (fleetVM.stockID) {
            //     var refTDStockData = window.firebase.firestore().collection('stock').doc(fleetVM.stockID)
            //     loans.push(
            //         refTDStockData.get()
            //             .then(_testStock => {
            //                 const _stock = Object.assign({}, objStock);
            //                 const objTDStockData = Object.assign({}, _testStock.data());
            //                 for (let [key, value] of Object.entries(objTDStockData)) {
            //                     _stock[key] = value;
            //                 }
            //                 _stock.documentID = fleetVM.stockID;
            //                 fleetVM.stock = _stock;

            //                 if (!_.isEmpty(_stock)) {
            //                     fleetVM.stockNo = _stock.stockNo;
            //                     fleetVM.make = _stock.make;
            //                     fleetVM.model = _stock.model;
            //                     fleetVM.modelDescription = _stock.modelDescription;
            //                     fleetVM.saleType = _stock.saleType;
            //                     fleetVM.type = _stock.type;
            //                     fleetVM.year = _stock.year;
            //                     fleetVM.regNo = _stock.regNo;
            //                     fleetVM.vinNo = _stock.vinNo;
            //                     fleetVM.extColor = _stock.extColor;
            //                     fleetVM.intColor = _stock.intColor;
            //                     fleetVM.price = _stock.price;
            //                     fleetVM.purchasePrice = _stock.purchasePrice;
            //                     fleetVM.location = _stock.location;
            //                     fleetVM.stockStatus = (_stock.status
            //                         ?
            //                         <div className={`badge badge-pill badge-${_stock.status.trim().toLowerCase()}-fill `}>
            //                             {_stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
            //                         </div>
            //                         :
            //                         <></>);
            //                 }

            //                 return fleetVM
            //             })
            //     )
            // }
            // else {
            //     loans.push(fleetVM)
            // }
        })

        Promise.all(loans).then(_loans => {

            dispatch({
                type: "SUCCESS_LIST",
                data: _.orderBy(_loans, ["startDateTime"], ["asc"]),
                activetab: activeTab
            });
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })
            setLoader({
                ...stockHistoryLoader,
                [activeTab]: false
            })
        })
    }


    const handleAddActivity = () => {
        setNewActivitylog(null)
        setEnquiryObj(null)
        setContactObj(null)
        setStockObj(props.stock)
        setNewActivity(true)
        setActivityHeader('Add New Activity')
        setShowActivityModel(true)
    }

    const handleAddNote = () => {
        setShowNotes(true)
    }

    const handleNotesClose = (noteTxt, _mentions) => {
        if (noteTxt) {


            const _userID = props.dealersettings ? props.dealersettings.id : '';
            const _currentDate = window.firebase.firestore.Timestamp.now();

            const objData = Object.assign({});
            objData.modifiedBy = _userID;
            objData.modifiedDate = _currentDate;
            objData.lastNoteDate = _currentDate;
            objData.lastNote = noteTxt;

            let convertedIDs = []
            if (!_.isEmpty(_mentions)) {
                let rawIDs = _mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })
            }

            let _objNote =
            {
                documentID: window.firebase.firestore().collection('stock').doc().id,
                notes: noteTxt,
                mentions: Object.assign([], convertedIDs),
                addedBy: _userID,
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedFrom: 'web'
            }

            const updateRef = window.firebase.firestore().doc(`stock/${props.stockID}/notes/${_objNote.documentID}`);
            updateRef.set(_objNote, { merge: true }).then((docRef) => {

                toast.notify('notes added successfully', {
                    duration: 2000
                })

                const refStockData = window.firebase.firestore().collection('stock').doc(props.stockID);
                refStockData.set(objData, { merge: true }).then((docRef) => {

                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }

        setShowNotes(false);
    }

    const handleEditActivity = (id) => {
        const editLog = stockHistory[activeTab].find(item => item.documentID === id);
        const logVM = Object.assign({}, objActivitylog);
        for (let [key, value] of Object.entries(editLog)) {
            if (logVM[key] !== undefined)
                logVM[key] = value;
        }
        setNewActivitylog(logVM)
        setEnquiryObj(editLog.enquiry)
        setContactObj(editLog.contact)
        setStockObj(editLog.stock)
        setNewActivity(false)
        setActivityHeader('Edit Activity')
        setShowActivityModel(true)
    }

    const handleActivityModelDelete = (objLog) => {
        setEnquiryObj(null)
        setContactObj(null)
        setNewActivitylog(null);
        setShowActivityModel(false)
        if (!_.isEmpty(objLog)) {
            dispatch({
                type: "REMOVE_LIST",
                data: objLog,
                activetab: activeTab
            });
        }
    }

    const handleActivityModelClose = () => {
        setEnquiryObj(null)
        setContactObj(null)
        setStockObj(null)
        setNewActivitylog(null);
        setShowActivityModel(false);
    }



    const handleEditFleet = (id, obj) => {

        if (activeTab === 'Loan' || activeTab === 'TestDrive') {
            let _stock = Object.assign({}, objStock);
            for (let [key, value] of Object.entries(obj.stock)) {
                if (_stock.hasOwnProperty(key))
                    _stock[key] = value;
            }

            if (activeTab === 'Loan' && !readOnlyEnquiryView) {
                setEditDocument(id);
                setModelFleetShow(true);
                setModelStock(_stock);
            }
            else if (activeTab === 'TestDrive' && !readOnlyEnquiryView) {
                setEditDocument(id);
                setModelTestDriveShow(true);
                setModelStock(_stock);
            }

        }
        else if (activeTab === 'Bookings') {
            handleEditActivity(id);
        }
        else if (activeTab === 'Enquiries') {
            // const url = `/enquiry/details/${id}`;
            // window.open(url, '_blank');
            sidepanelOpen(id);
        }
    }

    //#region  QUICK VIEW
    const sidepanelOpen = id => {
        setShowPanel({
            clsActive: 'active',
            clsName: 'sidebar-quickview',
            enquiryid: id,
        });
    };

    const sidepanelClose = () => {
        setShowPanel({
            clsActive: '',
            clsName: 'sidebar-quickview',
            enquiryid: 0,
        });
    };
    //#endregion

    const handleClose = (id) => {

        setModelFleetShow(false);
        setModelTestDriveShow(false);
        setModelStock({});
        setEditDocument(null);

    }


    const handleActionClick = (id, obj) => {

        return (
            <div
                style={{ cursor: 'pointer' }}
                className="mini-button"
                onClick={(e) => {
                    e.preventDefault();
                    handleEditFleet(id, obj);
                }}>
                {
                    ((activeTab === 'Loan' || activeTab === 'TestDrive') && readOnlyEnquiryView)
                        ?
                        <i className="ico icon-lock"></i>
                        :
                        <i className="ico icon-more"></i>
                }
            </div>
        )


    }

    const handleDetailsClick = (e, id, obj) => {
        e.preventDefault();
        handleEditFleet(id, obj);
    }

    const stockHistoryList = useMemo(() => stockHistory[activeTab]
        .filter(
            ({ startDate }) => (activeBookingTab === 'Upcoming' ? (startDate > window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)) :
                activeBookingTab === 'Previous' ? (startDate < window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)) : true)
        ), [activeTab, activeBookingTab, stockHistory])

    const stockHistoryFiltered = stockHistoryLoader[activeTab]
        ? []
        : (activeTab === 'Bookings' ? stockHistoryList : stockHistory[activeTab]);


    const divStyle = {
        height: '400px',
        overflow: 'auto',
        position: "relative"
    };
    return (
        <>
            <div className="vehicle-history-right tabs">
                <div className="tabs-wraper cursor-pointer">

                    <div className="nav nav-tabs nav-fill">

                        {
                            localStorage.defaultModule === 'service'
                                ?
                                <>
                                    <a href="#" className={`nav-item nav-link ${activeTab === 'Loan' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('Loan');
                                        }}><Translate text={'loanVehicle'} /> </a>
                                    <a href="#" className={`nav-item nav-link ${activeTab === 'Notes' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('Notes');
                                        }}><Translate text={'notes'} /> </a>
                                    <a href="#" className={`nav-item nav-link ${activeTab === 'Specifications' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('Specifications');
                                        }}><Translate text={'Specifications'} /> </a>
                                    <a href="#" className={`nav-item nav-link ${activeTab === 'ChangeLogs' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('ChangeLogs');
                                        }}><Translate text={'Change Logs'} /> </a>
                                </>
                                :
                                <>
                                    <a href="#" className={`nav-item nav-link ${activeTab === 'Enquiries' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('Enquiries');
                                        }}><Translate text={'enquiries'} /> </a>

                                    <a href="#" className={`nav-item nav-link ${activeTab === 'Loan' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('Loan');
                                        }}><Translate text={'loanVehicle'} /> </a>

                                    <a href="#" className={`nav-item nav-link ${activeTab === 'TestDrive' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('TestDrive');
                                        }}><Translate text={'testDrive'} /> </a>

                                    <a href="#" className={`nav-item nav-link ${activeTab === 'Bookings' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('Bookings');
                                        }}><Translate text={'bookings'} /> </a>

                                    <a href="#" className={`nav-item nav-link ${activeTab === 'Notes' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('Notes');
                                        }}><Translate text={'notes'} /> </a>

                                    <a href="#" className={`nav-item nav-link ${activeTab === 'Specifications' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('Specifications');
                                        }}><Translate text={'Specifications'} /> </a>

                                    <a href="#" className={`nav-item nav-link ${activeTab === 'ChangeLogs' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab('ChangeLogs');
                                        }}><Translate text={'Change Logs'} /> </a>
                                </>
                        }


                    </div>

                    {
                        activeTab === 'Bookings'
                            ?
                            <div className="filter-panel">
                                <div className="float-left">
                                    <div className="tabber-nav">
                                        <ul>
                                            <li><a href="#" className={`${activeBookingTab === 'Previous' ? 'active' : ''}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setActiveBookingTab('Previous')
                                                }}
                                            > <Translate text={'previous'} /> </a></li>
                                            <li><a href="#" className={`${activeBookingTab === 'Upcoming' ? 'active' : ''}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setActiveBookingTab('Upcoming');
                                                }}><Translate text={'upcoming'} /> </a></li>
                                        </ul>
                                    </div>
                                </div>
                                {
                                    localStorage.defaultModule !== 'oem' && (<div className="float-right">
                                        <div className="filter-add-button">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleAddActivity();
                                                }}
                                            > <i className="ico icon-add"></i> <Translate text={'addNewActivity'} /></button>
                                        </div>
                                    </div>)
                                }

                            </div>
                            :
                            <></>
                    }

                    {
                        activeTab === 'Notes'
                            ?
                            <div className="filter-panel">
                                <div className="float-right">
                                    <div className="filter-add-button">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleAddNote();
                                            }}
                                        > <i className="ico icon-add"></i> <Translate text={'addNote'} /></button>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }


                    <div className={`${activeTab === 'Bookings' ? '' : 'mt-2'}`}>
                        {
                            activeTab === 'ChangeLogs'
                                ?
                                <>
                                    <Activity
                                        stockID={props.stockID}
                                        clientUsers={props.clientUsers}
                                        groupUsers={props.groupUsers}
                                        dealersettings={props.dealersettings}
                                        history={props.history}
                                    >
                                    </Activity>
                                </>
                                :
                                <>
                                    {
                                        activeTab === 'Specifications'
                                            ?
                                            <>
                                                <div className="enquiry-flow mt-0 mb-3 position-relative" style={divStyle}>
                                                    <Files
                                                        stockID={props.stockID}
                                                        clientUsers={props.clientUsers}
                                                        groupUsers={props.groupUsers}
                                                        dealersettings={props.dealersettings}
                                                        history={props.history}
                                                        isDeivered={false}
                                                        filePath={`stock/${props.stockID}/files`}
                                                        isListView={true}
                                                    >
                                                    </Files>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {
                                                    stockHistoryLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                                        stockHistoryFiltered.length > 0 ? (
                                                            <div className="common-table">
                                                                <TableView
                                                                    isReorderable={false}
                                                                    datalist={stockHistoryFiltered}
                                                                    //height={activeTab === 'Bookings' ? 300 : 350}//{windowSize.windowHeight}
                                                                    //width={820}//{windowSize.windowWidth}
                                                                    height={400}
                                                                    width={windowSize.windowWidth}
                                                                    columns={
                                                                        (activeTab === 'Loan')
                                                                            ?
                                                                            allFleetStockHistoryFields
                                                                            :
                                                                            (
                                                                                (activeTab === 'TestDrive')
                                                                                    ?
                                                                                    allTestDriveHistoryFieldsVM
                                                                                    :
                                                                                    (
                                                                                        (activeTab === 'Enquiries')
                                                                                            ?
                                                                                            allEnquiryStockHistoryFields
                                                                                            :
                                                                                            (
                                                                                                (activeTab === 'Notes')
                                                                                                    ?
                                                                                                    allStockNotesHistoryFields
                                                                                                    :
                                                                                                    allBookingStockHistoryFields
                                                                                            )
                                                                                    )
                                                                            )
                                                                    }
                                                                    //handleSavesettings={handleSavesettings}
                                                                    dynamicFields={
                                                                        (activeTab === 'Loan')
                                                                            ?
                                                                            allFleetStockHistoryFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                                                            :
                                                                            (
                                                                                (activeTab === 'TestDrive')
                                                                                    ?
                                                                                    allTestDriveHistoryFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                                                                    :
                                                                                    (
                                                                                        (activeTab === 'Enquiries')
                                                                                            ?
                                                                                            allEnquiryStockHistoryFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                                                                            :
                                                                                            (
                                                                                                (activeTab === 'Notes')
                                                                                                    ?
                                                                                                    allStockNotesHistoryFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                                                                                    :
                                                                                                    allBookingStockHistoryFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                                                                            )
                                                                                    )
                                                                            )
                                                                    }
                                                                    settingsLoader={settingsLoader}
                                                                    handleActionClick={handleActionClick}
                                                                    handleRowSingleClick={handleDetailsClick}
                                                                    isSettings={false}
                                                                    //hasMore={hasMore.find(e => e.type === activeTab)}
                                                                    //handlePagination={handlePagination}
                                                                    isPaging={false}
                                                                    //handleCompleteActivity={handleCompleteActivity}
                                                                    activityTab={activeTab}
                                                                    displayByValue={true}
                                                                />

                                                            </div>
                                                        ) : (
                                                            <div className="common-table">
                                                                <div className="text-center p-5">
                                                                    <p><Translate text={'No record found'} /></p>

                                                                </div>
                                                            </div>
                                                        )
                                                }
                                            </>
                                    }
                                </>
                        }



                    </div>

                    <PopUpModal show={modelFleetShow}>
                        <AddFleet
                            docID={editDocumentID}
                            show={modelFleetShow}
                            clsActive='overlay-modal active'
                            stock={modelStock}
                            handleClose={handleClose}
                            title={'fleet'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        >
                        </AddFleet>
                    </PopUpModal>

                    <PopUpModal show={modelTestDriveShow}>
                        <AddTestDrive
                            docID={editDocumentID}
                            show={modelTestDriveShow}
                            clsActive='overlay-modal active'
                            handleClose={handleClose}
                            title={'testDrive'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                            isDeivered={false}
                            contact={null}
                        >
                        </AddTestDrive>
                    </PopUpModal>

                    <PopUpModal show={showActivityModel}>
                        <AddActivity
                            show={showActivityModel}
                            handleClose={handleActivityModelClose}
                            handleDelete={handleActivityModelDelete}
                            title={activityHeader}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                            activityLog={newActivitylog}
                            enquiry={enquiryObj}
                            contact={contactObj}
                            stock={stockObj}
                            clsActive='overlay-modal active'
                            activeTab={isNewActivity ? 'testdrive' : ''}
                            isNewActivity={isNewActivity}
                        >
                        </AddActivity>
                    </PopUpModal>

                    <PopUpModal show={showNotes}>
                        <AddNotes
                            show={showNotes}
                            handleClose={handleNotesClose}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        />
                    </PopUpModal>



                </div>
            </div>
            <StageProvider
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                history={props.history}
                path={'contacts'}
            >
                <>
                    <PopUpModal show={showpanel.clsActive ? true : false}>
                        <SidePanel
                            clsActive={showpanel.clsActive}
                            clsName={showpanel.clsName}
                            sidepanelClose={sidepanelClose}
                            title=''
                        >
                            <QuickView
                                enquiryid={showpanel.enquiryid}
                                history={props.history}
                                sidepanelClose={sidepanelClose}
                                isFromLayout={props.isFromLayout}
                                parentClose={props.handleClose}
                            />
                        </SidePanel>
                    </PopUpModal>

                </>
            </StageProvider>
        </>
    )
}

export default StockHistoryListView;