import React, { useState, useEffect } from 'react'
import "../../../styles/dailyActivity.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';

import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { ContentOverlay } from '../../../components';
import Translate from '../../../constants/translate';


const TriggerActivityReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const [counts, setCounts] = useState()
    const [allActivities, setActivities] = useState([]);
    const [client, setClient] = useState({});
    const [users, setUsers] = useState()
    const [firestoreDB, setFirestoreDB] = useState()

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    _date = _date ? _date : moment().format('YYYY-MM-DD');

    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('month')._d)
    })
    //moment().day("sunday").day()

    const [tempData, setTempData] = useState({
        activities: []
    })

    useEffect(() => {
        if (_.isEmpty(props.clientid))
            return

        window.firebase.firestore().doc(`clients/${props.clientid}`)
            .get()
            .then(doc => {
                if (doc.exists) {
                    if (doc.data().projectId === window.secondaryProjectId) {
                        setFirestoreDB(window.firebase2)
                    }
                    else {
                        setFirestoreDB(window.firebase)
                    }
                    setDateRange({
                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(doc.data().timezone).startOf('day')._d),
                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(doc.data().timezone).endOf('day')._d)
                    });
                    setClient(doc.data());
                }
            })

    }, [])

    useEffect(() => {
        if (!_.isEmpty(client)) {

            setLoader(true)
            //MTD DONE ACTIVITIES
            let refCompletedActivityData = firestoreDB.firestore().collection('activities')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('isDone', '==', true)
                .where('type', '==', 'activity')
                .where('modifiedDate', '>=', dateRange.startDate)
                .where('modifiedDate', '<=', dateRange.endDate)

            //MTD OVERDUE ACTIVITIES
            let refScheduleActivityData = firestoreDB.firestore().collection('activities')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('isDone', '==', false)
                .where('type', '==', 'activity')
                .where('startDate', '>=', dateRange.startDate)
                .where('startDate', '<=', dateRange.endDate)
                .orderBy('startDate', 'desc')

            let refOverdueActivityData = firestoreDB.firestore().collection('activities')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('isDone', '==', false)
                .where('type', '==', 'activity')
                .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                .orderBy('startDate', 'desc')
                .limit(1000)

            let refUsersActivityData = window.firebase.firestore().collection('users')
                .where('arrayClientID', 'array-contains', props.clientid)


            var allActivities = combineLatest(
                refCompletedActivityData ? collection(refCompletedActivityData) : of([]),
                refScheduleActivityData ? collection(refScheduleActivityData) : of([]),
                refOverdueActivityData ? collection(refOverdueActivityData) : of([]),
                refUsersActivityData ? collection(refUsersActivityData) : of([])
            ).pipe(
                map(([doneActivity, scheduleActivity, overdue, users]) => {
                    return [
                        _.map(doneActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(scheduleActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(overdue, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(users, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    ]
                }))
                .subscribe(allDocs => {
                    var _activities = []
                    for (let i = 0; i < 3; i++) {
                        allDocs[i].forEach(el => {
                            if (el.triggerID && !_activities.some(e => e.documentID === el.documentID)) {
                                _activities.push(convertActivityVM(el, allDocs[3]))
                            }
                        });
                    }
                    setUsers(allDocs[3])
                    setActivities(_activities)
                    setLoader(false)
                });
            return () => {
                allActivities && allActivities.unsubscribe()
            }
        }

    }, [client])

    useEffect(() => {
        if (!_.isArray(allActivities))
            return
        let _counts = [];
        const userActivities = _.chain(allActivities)
            .groupBy("owner")
            .map((value, key) => ({ owner: key, activities: value }))
            .value();
        userActivities.forEach(rec => {
            _counts.push({
                userName: _.find(users, { documentID: rec.owner }) ? _.find(users, { documentID: rec.owner }).name : 'unkown',
                profileImage: _.find(users, { documentID: rec.owner }) ? _.find(users, { documentID: rec.owner }).profileImage : null,
                todayOverdue: rec.activities.filter(a => (a.isDone === false) && a.startDate.seconds >= dateRange.startDate.seconds && a.startDate.seconds <= dateRange.endDate.seconds).length,
                overdue: rec.activities.filter(a => (a.isDone === false) && a.startDate.seconds <= dateRange.endDate.seconds).length,
                completed: rec.activities.filter(a => a.isDone === true && a.startDate.seconds >= dateRange.startDate.seconds && a.startDate.seconds <= dateRange.endDate.seconds).length,
                scheduled: rec.activities.filter(a => a.startDate.seconds >= dateRange.startDate.seconds && a.startDate.seconds <= dateRange.endDate.seconds).length,
            });
        })
        setCounts(_.orderBy(_counts, ['username'], ['asc']))
    }, [allActivities])


    const convertActivityVM = (doc, _users) => {
        let listVM = doc;

        if (doc.contact) {
            listVM.contactName = CommonHelper.displayContactName([], doc.contact)
            listVM.contactPhone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, { client: client }, true);
        }
        if (doc.enquiry) {
            listVM.status = doc.enquiry.status ? (<span className={`mrg-fixstatus badge-${doc.enquiry.status === 'prospectLost' ? 'lost' : doc.enquiry.status.toLowerCase()}`}>{doc.enquiry.status === 'prospectLost' ? 'PROSPECT LOST' : doc.enquiry.status}</span>) : '';
            listVM.displayID = `${doc.enquiry.displayID}`
        }
        if (doc.enquiry && doc.enquiry.requirement) {
            listVM.vehicleMake = !_.isEmpty(doc.enquiry.requirement.stock) ? doc.enquiry.requirement.stock.make : doc.enquiry.requirement.make
            listVM.vehicleModel = !_.isEmpty(doc.enquiry.requirement.stock) ? doc.enquiry.requirement.stock.model : doc.enquiry.requirement.model
            listVM.vehicleYear = !_.isEmpty(doc.enquiry.requirement.stock) ? doc.enquiry.requirement.stock.year : doc.enquiry.requirement.year;
            listVM.vehicleSaletype = doc.enquiry.requirement.saleType;
        }
        else {
            listVM.vehicleMake = '--';
            listVM.vehicleModel = '';
            listVM.vehicleYear = '';
            listVM.vehicleSaletype = '';
        }
        listVM.eventDate = doc.startDate ? moment.unix(doc.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.userName = _.find(_users, { documentID: doc.owner }) ? _.find(_users, { documentID: doc.owner }).name : 'unkown';

        return listVM
    }

    return (
        <>
            {
                !_.isEmpty(client)
                    ?
                    <>
                        <div className="A4 landscape">
                            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="60%"><div className="header-left">
                                                    {
                                                        client && client.logoURL
                                                            ?
                                                            <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }


                                                </div></td>

                                                <td width="40%"><div className="header-right"> {'DAILY ACTIVITY REPORT'} <br></br>
                                                    <span> {moment(_date).format('DD MMMM YYYY')}</span> </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-wraper">
                                    <div className="table-head">Today's Trigger Activities</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="40%">USER</th>
                                                <th width="15%">SCHEDULED</th>
                                                <th width="15%">COMPLETED</th>
                                                <th width="15%">TODAYS OVERDUE	</th>
                                                <th width="15%">OVERDUE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                counts && counts.map((user, index) => {
                                                    return <tr key={index}>
                                                        <td><div className="table-user-list">
                                                            <a href="#">
                                                                <div className="table-user-avatar">
                                                                    <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(user.profileImage, user.userName)} /></div>
                                                                {user.userName}
                                                            </a>
                                                        </div></td>
                                                        <td className="count-large">{user.scheduled}</td>
                                                        <td className="count-large">{user.completed}</td>
                                                        <td className="count-large">{user.todayOverdue}</td>
                                                        <td className="count-large">{user.overdue}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td className="td-head table-sub-total">Totals</td>
                                                <td className="count-large text-left table-sub-total">{allActivities.filter(a => a.startDate.seconds >= dateRange.startDate.seconds && a.startDate.seconds <= dateRange.endDate.seconds).length}</td>
                                                <td className="count-large text-left table-sub-total">{allActivities.filter(a => a.isDone === true && a.startDate.seconds >= dateRange.startDate.seconds && a.startDate.seconds <= dateRange.endDate.seconds).length}</td>
                                                <td className="count-large text-left table-sub-total">{allActivities.filter(a => (a.isDone === false) && a.startDate.seconds >= dateRange.startDate.seconds && a.startDate.seconds <= dateRange.endDate.seconds).length}</td>
                                                <td className="count-large text-left table-sub-total">{allActivities.filter(a => (a.isDone === false) && a.startDate.seconds <= dateRange.endDate.seconds).length}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div className="table-wraper">
                                    <div className="table-head">Today's Scheduled Activities</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="20%">TITLE</th>
                                                <th width="25%">CONTACT</th>
                                                <th width="25%">VEHICLE MODEL</th>
                                                <th width="15%">EVENT DATE	</th>
                                                <th width="15%">STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allActivities && allActivities.filter(a => a.startDate.seconds >= dateRange.startDate.seconds && a.startDate.seconds <= dateRange.endDate.seconds)
                                                    .map((activity, index) => {
                                                        return <tr key={index}>
                                                            <td>{activity.title}</td>
                                                            <td>{activity.contactName ? activity.contactName : '--'}{activity.contactPhone ? <span className="sub-txt">{activity.contactPhone}</span> : <></>}</td>
                                                            <td>{activity.vehicleMake} {activity.vehicleModel} {activity.vehicleSaletype ? <span className="sub-txt">{activity.vehicleSaletype}</span> : <></>}</td>
                                                            <td>{activity.eventDate}
                                                                {<span className="sub-txt">{activity.userName}</span>}
                                                            </td>
                                                            <td className="align-center">
                                                                {activity.status ? activity.status : '--'}
                                                                {activity.displayID ? <span className="sub-txt">{activity.displayID}</span> : <></>}
                                                            </td>
                                                        </tr>
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="footer">
                                    <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="footer-cpy">
                                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                                        <div className="footer-right"></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </section>

                        </div>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>
            }
        </>
    )
}
export default TriggerActivityReport;