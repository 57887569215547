/** LIBRARIES */
import React, { useState, useEffect, useRef, useMemo } from 'react'
import _ from 'lodash'
import { Table, Column, Cell } from "fixed-data-table-2";
import "fixed-data-table-2/dist/fixed-data-table.min.css";
import { Dropdown } from 'react-bootstrap'
import { CustomTableFields, CustomToggle } from './customdropdown';
import Translate from '../constants/translate';
import CommonHelper from '../services/common'
import { ReactSelect } from '../components';

const TableView = (props) => {

    const [dynamicCols, setDynamicCols] = useState(props.dynamicFields);
    const [dynamicFields, setDynamicFields] = useState(props.dynamicFields);
    const [columnFields, setColumnFields] = useState(props.columns)
    const [searchField, setSearchField] = useState('')
    const [show, setShow] = useState(false)
    const node = useRef();
    const [sortConfig, setSortConfig] = useState(props.sortConfig ? props.sortConfig : null);


    useEffect(() => {

        if (document.getElementById('table-dynamic-settings-ico')) {
            if (document.getElementsByClassName('public_fixedDataTable_scrollbarSpacer').length > 0) {
                document.getElementById('table-dynamic-settings-ico').classList.remove('table-field-settings-fix')
            }
            else {
                document.getElementById('table-dynamic-settings-ico').classList.add('table-field-settings-fix')
            }
        }

        if (document.getElementById('hide-drag-icon')) {
            var parentNode = document.getElementById('hide-drag-icon').parentElement;
            parentNode.classList.remove('public_fixedDataTableCell_hasReorderHandle')
            if (parentNode.childNodes.length === 2) {
                parentNode.removeChild(parentNode.childNodes[0])
            }
        }
    }, [props.datalist, show, props.height, dynamicCols])

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        setDynamicCols(props.dynamicFields)
        setDynamicFields(props.dynamicFields)
        setColumnFields(props.columns)
    }, [props.dynamicFields ? JSON.stringify(props.dynamicFields) : false, props.columns ? props.columns.length : false])

    useEffect(() => {
        if (!props.settingsLoader)
            setShow(props.settingsLoader)
    }, [props.settingsLoader])

    const handleCancel = () => {
        setShow(false)
    }

    const handleSavesettings = () => {
        props.handleSavesettings(dynamicCols, true)
    }

    const handleColsChecked = (e) => {
        setDynamicCols(e.target.checked ?
            [...dynamicCols.filter(v => v !== e.target.value), e.target.value] :
            [...dynamicCols.filter(v => v !== e.target.value)]
        )
        e.stopPropagation();
    }

    const handleTextchange = (e) => {
        setSearchField(e.target.value);
        if (e.target.value) {
            setColumnFields([...props.columns.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()))])
        }
        else {
            setColumnFields(props.columns)
        }

    }

    const onColumnReorderEndCallback = (event) => {

        var fixedColumns = props.columns.filter(e => e.fixed === true || e.fixedRight === true).map(v => _.pick(v, ['value']).value);
        var columnOrder = dynamicCols.filter((columnKey) => {
            return columnKey !== event.reorderColumn;
        });

        if (event.columnAfter) {
            var index = columnOrder.indexOf(event.columnAfter);
            columnOrder.splice(index, 0, event.reorderColumn);
        } else {
            if (fixedColumns.indexOf(event.reorderColumn) !== -1) {
                columnOrder.splice(fixedColumns.length - 1, 0, event.reorderColumn)
            } else {
                columnOrder.push(event.reorderColumn);
            }
        }
        setDynamicCols(columnOrder)
        setDynamicFields(columnOrder)
        props.handleSavesettings(columnOrder, false)

    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        const _sortConfig = { key, direction }
        setSortConfig(_sortConfig);
        return _sortConfig;
    };

    const sortedItems = useMemo(() => {
        let sortableItems = [...props.datalist];
        if (!_.isEmpty(sortConfig) && !Boolean(props.dynamicSort)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [props.datalist, sortConfig]);

    // const handleAllChecked = (event) => {

    //     let selectedIds = [];
    //     if (event.target.checked) {
    //         !_.isEmpty(datalist) && datalist.map(file => {
    //             selectedIds.push(file.documentID)
    //         })
    //     }

    //     setCheckFile(selectedIds)
    // }

    // const handleCheckChange = (e) => {
    //     const { name, checked } = e.target;
    //     //console.log('handleCheckChange', name, checked)

    //     let selectedIds = checkFile;
    //     if (checked) {
    //         selectedIds.push(name);
    //     }
    //     else {
    //         let stindex = selectedIds.indexOf(name)
    //         selectedIds.splice(stindex, 1);
    //     }

    //     setCheckFile(selectedIds)
    // }

    const unCheckFields = _.chain(_.filter(columnFields, (v) => _.indexOf(dynamicFields, v.value) < 0 && !v.fixed && !v.fixedRight)).groupBy("group").map(function (v, i) {
        return {
            group: i === "undefined" ? 'hidden' : i,
            data: _.map(v)
        }
    }).value();

    //console.log('result', unCheckFields);

    const displayName = (_value, _name) => {
        const excludedValue = ['wonOn', 'deliveredOn', 'lostOn', 'displayID', 'documentID', 'userName']
        const { displayOptions, displayByValue, isTranslate, dealersettings } = props
        let _displayName = displayByValue && !_.isEmpty(displayOptions) && _.find(displayOptions, { value: _value }) ? _.find(displayOptions, { value: _value })?.name : null;

        return displayByValue && isUUID(_value) === false ?
            <Translate text={(_value && excludedValue.indexOf(_value) < 0) ? (_displayName || _value) : _name} /> :
            (isTranslate && isUUID(_value) === false && !_.isEmpty(dealersettings) ?
                CommonHelper.showLocale(props, (_value && excludedValue.indexOf(_value) < 0 ? _value : _name), _name) :
                (isTranslate && isUUID(_value) === false ? < Translate text={_name} /> : <>{_name}</>));
    }

    const isUUID = (uuid) => {
        if (uuid.match(/\d/) && uuid.match(/-/) && uuid.length > 25) {
            return true;
        } else {
            return false;
        }
    }


    const { dealersettings, datalist, height, width, settingsLoader, columns, handleActionClick, handleRowSingleClick,
        isSettings, handlePagination, hasMore, isPaging, handleCompleteActivity, activityTab, handleFavorite, handleAllChecked,
        handleCheckChange, selectedIds, handleSwitchChange, isSorting, dynamicSort, handleDropdownChange, dropdownOptions, hideSerialNo } = props;



    return dynamicFields?.length !== props.dynamicFields?.length ? (<></>) : (
        <>
            {
                isSettings ? (
                    <div id="table-dynamic-settings-ico" className="table-field-settings">
                        <Dropdown ref={node} drop='left' show={show} >
                            <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                setShow(!show)
                            }}>
                                <i className="ico icon-settings"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomTableFields} id="table-dynamic-cols-settings" className="dropdown-menu dropdown-menu-right" ChildClass="table-field-listing" xplacement="bottom-end"
                                handleCancel={handleCancel}
                                handleSave={handleSavesettings}
                                settingsLoader={settingsLoader}
                                handleTextchange={handleTextchange}
                                searchField={searchField}
                            >
                                <Dropdown.Header className="table-field-subheader"><Translate text={'show'} /></Dropdown.Header>
                                {
                                    _.filter(columnFields, (v) => _.indexOf(dynamicFields, v.value) >= 0 && !v.fixed && !v.fixedRight).map((col, index) => {
                                        return <div
                                            className="check-list"
                                            key={index}
                                            htmlFor={`field-checkbox-${col.value}`}>
                                            <div className="checkbox icheck-success w-100">
                                                <input type="checkbox" id={`field-checkbox-${col.value}`}
                                                    checked={dynamicCols.some(e => e === col.value) ? true : false}
                                                    value={col.value}
                                                    onChange={(e) => {
                                                        handleColsChecked(e);
                                                    }}
                                                />
                                                <label htmlFor={`field-checkbox-${col.value}`}> {displayName(col.value, col.name)}
                                                </label>
                                            </div>
                                        </div>
                                    })
                                }
                                {
                                    unCheckFields && unCheckFields.length > 0
                                        ?
                                        <>
                                            {
                                                unCheckFields && unCheckFields.map((fields, index) => {


                                                    return <div key={index}>
                                                        <Dropdown.Header className="table-field-subheader">{fields.group}</Dropdown.Header>
                                                        {
                                                            fields.data && fields.data.map((col, i) => {
                                                                return <div
                                                                    className="check-list"
                                                                    key={i}>
                                                                    <div className="checkbox icheck-success w-100">
                                                                        <input type="checkbox" id={`field-checkbox-${col.value}`}
                                                                            value={col.value}
                                                                            checked={dynamicCols.some(e => e === col.value) ? true : false}
                                                                            onChange={(e) => {
                                                                                handleColsChecked(e);
                                                                            }}
                                                                        />
                                                                        <label htmlFor={`field-checkbox-${col.value}`}>{displayName(col.value, col.name)}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }

                                                        {/* <Dropdown.Header className="table-field-subheader">{fields.group}</Dropdown.Header>

                                                        {
                                                            fields.data && fields.data.map((col, i) => {
                                                                return <div
                                                                    className="check-list"
                                                                    key={i}>
                                                                    <div className="checkbox icheck-success w-100">
                                                                        <input type="checkbox" id={`field-checkbox-${col.value}`}
                                                                            value={col.value}
                                                                            checked={dynamicCols.some(e => e === col.value) ? true : false}
                                                                            onChange={(e) => {
                                                                                handleColsChecked(e);
                                                                            }}
                                                                        />
                                                                        <label htmlFor={`field-checkbox-${col.value}`}>{col.name}</label>
                                                                    </div>
                                                                </div>
                                                            })
                                                        } */}
                                                    </div>;

                                                })
                                            }

                                        </>
                                        :
                                        <>
                                        </>

                                }

                                {/* <Dropdown.Header className="table-field-subheader">hidden</Dropdown.Header>
                                {
                                    _.filter(columnFields, (v) => _.indexOf(dynamicFields, v.value) < 0 && !v.fixed && !v.fixedRight).map((col, index) => {
                                        return <div
                                            className="check-list"
                                            key={index}>
                                            <div className="checkbox icheck-success w-100">
                                                <input type="checkbox" id={`field-checkbox-${col.value}`}
                                                    value={col.value}
                                                    checked={dynamicCols.some(e => e === col.value) ? true : false}
                                                    onChange={(e) => {
                                                        handleColsChecked(e);
                                                    }}
                                                />
                                                <label htmlFor={`field-checkbox-${col.value}`}>{col.name}</label>
                                            </div>
                                        </div>
                                    })
                                } */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                ) : (<></>)
            }

            <Table
                rowsCount={sortedItems.length}
                rowHeight={props.rowHeight ? props.rowHeight : 60}
                headerHeight={props.headerHeight ? props.headerHeight : 40}
                height={height}
                width={width}
                className="tbl-main-wrapper"
                onColumnReorderEndCallback={onColumnReorderEndCallback}
                isColumnReordering={false}
                touchScrollEnabled={true}
                onScrollEnd={(xScroll, yScroll, colIndex, rowIndex) => {
                    if (isPaging && !_.isEmpty(hasMore) && rowIndex === sortedItems.length) {
                        handlePagination()
                    }
                }}
                scrollToRow={0}
            >
                {/* {console.log('dynamicFields', props.activityTab, props.dynamicFields, dynamicFields)} */}
                <Column
                    header={<Cell>#</Cell>}
                    cell={({ rowIndex }) => (<Cell
                        width={hideSerialNo ? 0 : 50}
                        height={hideSerialNo ? 0 : 60}
                    >{(rowIndex + 1)}</Cell>)}
                    fixed={true}
                    width={hideSerialNo ? 0 : 50}
                    height={props.rowHeight ? props.rowHeight : 60}
                    align={'left'}
                    flexGrow={0}
                />
                {
                    dynamicFields.map((field, index) => {
                        let rec = columns.filter(e => e.value === field)[0];
                        return rec ? (<Column key={index}
                            align={rec.align}
                            header={
                                rec.value === 'checkbox' ?
                                    (<Cell className="single-row-cell" id="hide-drag-icon">
                                        {
                                            props.hideAllCheck
                                                ?
                                                <></>
                                                :
                                                <div className="checkbox icheck-success">
                                                    <input
                                                        id={'col-checkbox-header'}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            if (handleAllChecked)
                                                                handleAllChecked(e)
                                                        }}
                                                        value={'col-checkbox-header'}
                                                        checked={(!_.isEmpty(selectedIds) && Object.keys(selectedIds).length === sortedItems.length) ? true : false}
                                                    />
                                                    <label htmlFor="col-checkbox-header"></label>
                                                </div>
                                        }
                                    </Cell>) :
                                    rec.value === 'settings' ? (
                                        <Cell className="col-header">{''}</Cell>
                                    ) : (<Cell onClick={(e) => {
                                        e.preventDefault()
                                        if (isSorting === true && rec.sortValue) {
                                            const _sortConfig = requestSort(rec.sortValue)
                                            if (dynamicSort)
                                                props.handleDynamicSort({
                                                    ..._sortConfig,
                                                    sort: _sortConfig && _sortConfig.direction === 'ascending' ? 'asc' : 'desc'
                                                })
                                        }
                                    }} className={`col-header ${isSorting === true && rec.sortValue ? (sortConfig && sortConfig.key === rec.sortValue ? (sortConfig.direction === 'ascending' ? 'sorting sorting_asc' : 'sorting sorting_desc') : 'sorting') : ''}`}>

                                        {displayName(rec.value, rec.name)}
                                    </Cell>)
                            }
                            cell={props =>
                                rec.value === 'checkbox' ? (
                                    <Cell className="single-row-cell" {...props}
                                        rowIndex={props.rowIndex}
                                        width={props.width}
                                        height={props.height}>
                                        <div className="checkbox icheck-success">
                                            <input
                                                type="checkbox"
                                                id={`cel-${sortedItems[props.rowIndex]['documentID']}`}
                                                name={sortedItems[props.rowIndex]['documentID']}
                                                disabled={sortedItems[props.rowIndex]['checkDisabled'] ? true : false}
                                                checked={(!_.isEmpty(selectedIds) && selectedIds.hasOwnProperty(sortedItems[props.rowIndex]['documentID'])) ? true : false}
                                                onChange={(e) => {
                                                    if (handleCheckChange)
                                                        handleCheckChange(e)
                                                }} />
                                            <label htmlFor={`cel-${sortedItems[props.rowIndex]['documentID']}`}></label>
                                        </div>
                                    </Cell>
                                ) :
                                    rec.value === 'settings' ? (
                                        <Cell className="single-row-cell" {...props}
                                            rowIndex={props.rowIndex}
                                            width={props.width}
                                            height={props.height}>
                                            {handleActionClick(sortedItems[props.rowIndex]['documentID'], sortedItems[props.rowIndex])}
                                        </Cell>
                                    ) : rec.type === 'switch' ? (
                                        <Cell className="single-row-cell" {...props}>
                                            <span className={`switch switch-sm ${(sortedItems[props.rowIndex]['disableDelete'] && Boolean(sortedItems[props.rowIndex]['disableDelete'])) ? 'btn-disable' : ''}`}>
                                                <input type="checkbox" className="switch" checked={sortedItems[props.rowIndex][rec.value] ? sortedItems[props.rowIndex][rec.value] : false}
                                                    id={`switch-${sortedItems[props.rowIndex]['documentID']}`}
                                                    onChange={(e) => {
                                                        handleSwitchChange(sortedItems[props.rowIndex]['documentID'], e.target.checked, sortedItems[props.rowIndex])
                                                    }} />
                                                <label htmlFor={`switch-${sortedItems[props.rowIndex]['documentID']}`}></label>
                                            </span>
                                        </Cell>
                                    ) : rec.type === 'dropdown' ? (
                                        <Cell className="single-row-cell" {...props}>
                                            {/* <select defaultValue={sortedItems[props.rowIndex][rec.value]} id={`dd-${sortedItems[props.rowIndex]['documentID']}`} onChange={(e) => handleDropdownChange(sortedItems[props.rowIndex]['documentID'], e ? e.target.value : null, sortedItems[props.rowIndex])}>
                                                <option value={''}></option>
                                                {
                                                    dropdownOptions.map((r, i) => {
                                                        return <option key={i} value={r.value} >{r.label}</option>
                                                    })
                                                }
                                            </select> */}
                                            <ReactSelect
                                                options={dropdownOptions}
                                                name={`dd-${sortedItems[props.rowIndex]['documentID']}`}
                                                placeholder={'select'}
                                                onChange={(e, data) => {
                                                    handleDropdownChange(sortedItems[props.rowIndex]['documentID'], e ? e.value : null, sortedItems[props.rowIndex])
                                                }}
                                                value={null}
                                                classNamePrefix={`cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </Cell>
                                    ) :
                                        rec.type === 'checkbox' ? (
                                            <Cell className="single-row-cell" {...props}>
                                                <div className="checkbox icheck-success">
                                                    <input type="checkbox" id={`done-${sortedItems[props.rowIndex]['documentID']}`}
                                                        onChange={(e) => {
                                                            handleCompleteActivity(sortedItems[props.rowIndex]['documentID'], e.target.checked)
                                                        }}
                                                        checked={sortedItems[props.rowIndex][rec.subText]}
                                                    />
                                                    <label htmlFor={`done-${sortedItems[props.rowIndex]['documentID']}`}></label>
                                                </div>
                                            </Cell>
                                        ) : rec.type === 'favorite' ? (
                                            <Cell className="single-row-cell" {...props}>
                                                <div className="pipeline-favourite">
                                                    <a href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            handleFavorite(sortedItems[props.rowIndex]['documentID'], !sortedItems[props.rowIndex][rec.subText], sortedItems[props.rowIndex])
                                                        }}
                                                    ><i className={`ico icon-star${sortedItems[props.rowIndex][rec.subText] === true ? '-active' : ''}`}></i></a>
                                                </div>
                                            </Cell>
                                        ) : (
                                            <Cell className={`single-row-cell ${rec.value === 'notes' || rec.displayType === 'notes' ? 'form-style-notes' : ''} ${sortedItems[props.rowIndex]['borderClass']}`} {...props}
                                                rowIndex={props.rowIndex}
                                                width={props.width}
                                                height={props.height}
                                                onClick={(e) => {
                                                    handleRowSingleClick && handleRowSingleClick(e, sortedItems[props.rowIndex]['documentID'], sortedItems[props.rowIndex], props)
                                                }}>
                                                {
                                                    rec.subText ?
                                                        (<div className={`user-list-items-info pr-0 ${rec.value === 'dueDate' ? (activityTab === 'Overdue' ? 'text-red' : activityTab === 'Today' ? 'text-green' : '') : ''}`}>
                                                            <h4 title={_.isString(sortedItems[props.rowIndex][rec.value]) ? sortedItems[props.rowIndex][rec.value] : ''}>{
                                                                sortedItems[props.rowIndex][rec.value] && sortedItems[props.rowIndex][rec.value].length > 25 ? sortedItems[props.rowIndex][rec.value].substring(0, 25) + '...' : sortedItems[props.rowIndex][rec.value]
                                                            }</h4>
                                                            <h5 className='mt-1'>{sortedItems[props.rowIndex][rec.subText] ? sortedItems[props.rowIndex][rec.subText] : ''}</h5>
                                                            {
                                                                rec.nestedText && sortedItems[props.rowIndex][rec.nestedText] ?
                                                                    (<span className="tbl-sub-dealer">{sortedItems[props.rowIndex][rec.nestedText]}</span>) : (<></>)
                                                            }
                                                        </div>) :
                                                        (<>
                                                            {
                                                                rec.icon ? (<div className="calendar-activity-icon"><i className={`ico ${CommonHelper.activityIconByValue(dealersettings, sortedItems[props.rowIndex]['subType'])}`}></i></div>) : (<></>)
                                                            }
                                                            {
                                                                rec.value === 'notes' || rec.displayType === 'notes'
                                                                    ?
                                                                    <>{sortedItems[props.rowIndex][rec.value] ? <span placement="bottom" title={sortedItems[props.rowIndex][rec.value]} className="text-area-space">
                                                                        {/* <textarea
                                                                            className={'notes cafe-popover-notewrap'}
                                                                            name={`${props.rowIndex}${rec.value}`}
                                                                            onChange={(e) => { e.preventDefault(); }}
                                                                            value={sortedItems[props.rowIndex][rec.value] ? sortedItems[props.rowIndex][rec.value] : ''}
                                                                            readOnly={true}
                                                                        >
                                                                        </textarea> */}
                                                                        {rec.limit > 0 && sortedItems[props.rowIndex][rec.value] && sortedItems[props.rowIndex][rec.value].length > rec.limit ? sortedItems[props.rowIndex][rec.value].substring(0, rec.limit) + '...' : sortedItems[props.rowIndex][rec.value]}
                                                                    </span> : <></>}</>
                                                                    :
                                                                    <>{rec.limit > 0 && sortedItems[props.rowIndex][rec.value] && sortedItems[props.rowIndex][rec.value].length > rec.limit ? sortedItems[props.rowIndex][rec.value].substring(0, rec.limit) + '...' : sortedItems[props.rowIndex][rec.value]}</>
                                                            }

                                                            {
                                                                rec.nestedText && sortedItems[props.rowIndex][rec.nestedText] ?
                                                                    (<span className="tbl-sub-dealer">{sortedItems[props.rowIndex][rec.nestedText]}</span>) : (<></>)
                                                            }
                                                        </>)
                                                }

                                            </Cell>
                                        )
                            }
                            width={rec.width ? rec.width : 200}
                            height={props.rowHeight ? props.rowHeight : 60}
                            flexGrow={rec.flex}
                            fixed={rec.fixed === true ? true : false}
                            fixedRight={rec.fixedRight === true ? true : false}
                            scrollbarYWidth={15}
                            allowCellsRecycling={true}
                            isReorderable={props.isReorderable}
                            columnKey={rec.value}
                        />) : (<></>)
                    })
                }
            </Table >
        </>
    );

}

export default TableView;