import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { InputText, ReactSelect, ReactMultiSelect, PopUpModal } from '../../../../../components'
import Translate from '../../../../../constants/translate';
import _images from '../../../../../images'
import CommonHelper from '../../../../../services/common';
import { ClientContext } from '../../clientContext'
import UserExists from './userExistsAlert'

const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]

const TargetKPIs = (props) => {

    const [activeMonth, setActiveMonth] = useState(moment().format("MMM"))
    const [errorFields, setErrorFields] = useState({});
    const [textEditor, setTexteditor] = useState({})
    const [loader, setLoader] = useState(false)
    const [copyloader, setCopyLoader] = useState(false)
    const [years, setYears] = useState([])
    const [selectedYear, setSelctedYear] = useState(moment().format("YYYY"))
    const [selectedTarget, setSelectedTarget] = useState(props.target)
    const { roles } = useContext(ClientContext);
    const newTarget = !_.isEmpty(props.target) ? false : true
    const [existsUsers, setUsers] = useState();
    const [showModel, setShowModel] = useState(false)
    const [yearloader, setYearLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.target)) {
            const newtargetVM = Object.assign({});
            newtargetVM.documentID = window.firebase.firestore().collection(`targets`).doc().id;
            newtargetVM.oemID = props.dealersettings.oemID;
            newtargetVM.addedDate = window.firebase.firestore.Timestamp.now();
            newtargetVM.addedBy = localStorage.uid;
            newtargetVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newtargetVM.modifiedBy = localStorage.uid;
            newtargetVM.isDeleted = false;
            newtargetVM.type = 'kpi';
            setSelectedTarget(newtargetVM)
        }
    }, [])


    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSelectedTarget({
                ...selectedTarget,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSelectedTarget({
                ...selectedTarget,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    useEffect(() => {
        let _years = []
        _years.push({
            label: moment().add(-1, 'year').format('YYYY'),
            value: moment().add(-1, 'year').format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().format('YYYY'),
            value: moment().format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().add(1, 'year').format('YYYY'),
            value: moment().add(1, 'year').format('YYYY'),
            active: true,
        })
        setYears(_years)
    }, [])


    const handleNumberChange = e => {
        const { name, value } = e.target;
        var regexp = /^[0-9+ \b]+$/;
        let number = value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setSelectedTarget({
                ...selectedTarget,
                [selectedYear]: {
                    ...selectedTarget[selectedYear],
                    [activeMonth]: selectedTarget[selectedYear] ? (selectedTarget[selectedYear][activeMonth] ? { ...selectedTarget[selectedYear][activeMonth], [name]: number !== '' ? Number(number) : number } : { [name]: number !== '' ? Number(number) : number }) : { [name]: number !== '' ? Number(number) : number }
                }
            })
        }
    };

    const handleRoleSelectChange = (e, data) => {
        setSelectedTarget({
            ...selectedTarget,
            [data.name]: e ? e.value : '',
            ['userIDs']: e ? props.clientUsers.filter(item => item.roleID === e.value).map(v => _.pick(v, ['documentID']).documentID) : []
        })
    }
    const handleyearChange = (e, data) => {
        setSelctedYear(e.value)

        if (e.value === moment().format('YYYY')) {
            setActiveMonth(moment().format('MMM'))
        }
        else {
            setActiveMonth(moment().startOf('year').format('MMM'))
        }
    }


    const handleReactMultiSelectChange = (selectedOptions) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setSelectedTarget({
            ...selectedTarget,
            ['userIDs']: value
        });
    }

    const handleCopytoAll = () => {
        if (_.isEmpty(selectedTarget) || _.isEmpty(selectedTarget[selectedYear]) || _.isEmpty(selectedTarget[selectedYear][activeMonth])) {
            Swal.fire(CommonHelper.showLocale(props, 'Please define target in current month'), '', 'error')
            return
        }
        setCopyLoader(true)
        var _currentTarget = selectedTarget;
        const _targetMonth = Number(moment().format("M")) < Number(moment().month(activeMonth).format("M")) ? moment().format("MMM") : activeMonth;
        for (let i = Number(moment().month(_targetMonth).format("M")); i <= 12; i++) {
            _currentTarget = {
                ..._currentTarget,
                [selectedYear]: {
                    ..._currentTarget[selectedYear],
                    [moment(i.toString(), 'M').format('MMM')]: {
                        ...selectedTarget[selectedYear][activeMonth]
                    }
                }
            }
        }
        setSelectedTarget(_currentTarget)

        setTimeout(function () {
            toast.notify('Targets copied successfully.', {
                duration: 1000
            })
            setCopyLoader(false)
        }, 2000)


    }

    const handleCopyfromlastYear = async () => {
        if (_.isEmpty(selectedTarget) || _.isEmpty(selectedTarget[(parseInt(selectedYear) - 1)])) {
            Swal.fire(`${CommonHelper.showLocale(props, 'No targets defined in')} ${parseInt(selectedYear) - 1}`, '', 'error')
            return
        }
        const result = await Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: `${CommonHelper.showLocale(props, 'Do you want to copy targets from')} ${(parseInt(selectedYear) - 1)}?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        })

        if (!result.value) {
            return
        }
        setYearLoader(true)
        var _currentTarget = selectedTarget;
        for (let i = (Number(moment().month(activeMonth).format("M")) + 1); i <= 12; i++) {
            _currentTarget = {
                ..._currentTarget,
                [selectedYear]: {
                    ..._currentTarget[(parseInt(selectedYear) - 1)]
                }
            }
        }
        setSelectedTarget(_currentTarget)

        setTimeout(function () {
            toast.notify('Targets copied successfully.', {
                duration: 1000
            })
            setYearLoader(false)
        }, 2000)
    }

    const handleSave = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(selectedTarget['title'])) {
            formIsValid = false;
            errors['title'] = errorClass;
        }
        if (_.isEmpty(selectedTarget['roleID']) && _.isEmpty(selectedTarget['userIDs'])) {
            formIsValid = false;
            errors['userIDs'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        if (!_.isEmpty(selectedTarget['roleID']) && props.clientUsers.filter(item => item.roleID === selectedTarget.roleID).length === 0) {
            Swal.fire(CommonHelper.showLocale(props, 'No users in selected role. Please select other role'), '', 'error')
            return
        }
        if (_.isEmpty(selectedTarget) || _.isEmpty(selectedTarget[selectedYear]) || _.isEmpty(selectedTarget[selectedYear][activeMonth])) {
            Swal.fire(CommonHelper.showLocale(props, 'Please define target in current month'), '', 'error')
            return
        }
        if (props.allTargets.filter(item => item.documentID !== selectedTarget.documentID).some(item => _.some(item.userIDs, (v) => _.indexOf(selectedTarget.userIDs, v) >= 0))) {
            let _users = []
            props.allTargets.filter(item => _.some(item.userIDs, (v) => _.indexOf(selectedTarget.userIDs, v) >= 0)).forEach(rec => {
                _.filter(rec.userIDs, (v) => _.indexOf(selectedTarget.userIDs, v) >= 0).forEach(userid => {
                    if (_.find(props.clientUsers, { 'documentID': userid }))
                        _users.push(_.find(props.clientUsers, { 'documentID': userid }).name)
                })
            })
            setUsers(_.uniq(_users))
            setShowModel(true)
            return
        }
        setLoader(true)
        window.firebase.firestore().doc(`targets/${selectedTarget.documentID}`).set({ ...selectedTarget }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newTarget === true ? 'User Target added successfully' : 'User Target updated successfully'), {
                    duration: 2000
                })
                props.handleClose();
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const handleModelClose = () => {
        setUsers(null)
        setShowModel(false)
    }

    return _.isEmpty(selectedTarget) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-kpitarget-popup"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid pl-0 pr-0">
                        <div className="settings-head form-style">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label ><Translate text={'title'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'title'}
                                        className={`form-control ${errorFields["title"]}`}
                                        name="title"
                                        onChange={handleOnChange}
                                        value={selectedTarget.title}
                                        id={`target-title`}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label ><Translate text={'role'} /></label>
                                    <ReactSelect
                                        options={_.map(roles, function (c) {
                                            return {
                                                value: c.documentID,
                                                doc: c,
                                                label: <>{c.name}<span className="user-role-span">{_.find(levelOptions, { value: c.level }) ? _.find(levelOptions, { value: c.level }).label : c.level}</span></>,
                                                active: _.isBoolean(c.active) ? c.active : true,
                                            };
                                        })}
                                        name={"roleID"}
                                        placeholder={'select role'}
                                        onChange={handleRoleSelectChange}
                                        value={selectedTarget.roleID}
                                        classNamePrefix={`basic-select`}
                                        removeClearable={true}
                                        id={`target-roleID`}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'users'} /></label>
                                    <ReactMultiSelect
                                        options={_.map(props.clientUsers.filter(item => item.isDisabled !== true && (!_.isEmpty(selectedTarget.roleID) ? item.roleID === selectedTarget.roleID : true)), function (rec) {
                                            return {
                                                label: (
                                                    <div className='drop-image'>
                                                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                        {rec.name}
                                                    </div>
                                                ),
                                                value: rec.documentID,
                                                data: rec,
                                                //active: _.isBoolean(rec.active) ? rec.active : true,
                                                searchlabel: rec.name
                                            };
                                        })}
                                        name={"userIDs"}
                                        placeholder={'select user'}
                                        onChange={handleReactMultiSelectChange}
                                        value={selectedTarget.userIDs}
                                        classNamePrefix={`${errorFields["userIDs"]} basic-select`}
                                        isMulti={true}
                                        id="target-userIDs"
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="settings-head form-style border-top pt-3">
                        <div className="form-row">
                            <div className="form-group col-md-2">
                                <ReactSelect
                                    options={years}
                                    name={"year"}
                                    placeholder={'select year'}
                                    onChange={handleyearChange}
                                    value={selectedYear}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                    id={`target-year`}
                                >
                                </ReactSelect>
                            </div>
                            <div className="form-group col-md-6">
                                <div className="yeargrid">
                                    <ul>
                                        {
                                            moment.monthsShort().map((rec, index) => {
                                                return <li key={index}><a
                                                    id={rec}
                                                    href="#"
                                                    className={rec === activeMonth ? 'selected' : ''}
                                                    onClick={(event) => {
                                                        if (event.target.id) {
                                                            setActiveMonth(event.target.id)
                                                        }
                                                        event.preventDefault();
                                                    }}
                                                ><Translate text={rec} /></a></li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="form-group col-md-4">
                                <div className="float-right ml-2">
                                    <button type="button" className="btn btn-primary float-right" onClick={() => {
                                        handleCopyfromlastYear()
                                    }} >
                                        {
                                            yearloader ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }<Translate text={'Copy from last year'} /></button>
                                </div>
                                <div className="float-right">
                                    <button type="button" className="btn btn-primary float-right" onClick={() => {
                                        handleCopytoAll()
                                    }} >
                                        {
                                            copyloader ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }<Translate text={'Copy to all months'} /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='settings-tab-fields-wrap'>
                        <div className='form-row'>
                            <div className='form-group col-md-6'>
                                <label ><Translate text={'Sales Target'} /></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'sales target'}
                                    className={`form-control ${errorFields["salesTarget"]}`}
                                    name="salesTarget"
                                    onChange={handleNumberChange}
                                    value={selectedTarget?.[selectedYear]?.[activeMonth]?.salesTarget}
                                    id="client-salesTarget"
                                />
                            </div>
                            <div className='form-group col-md-6'>
                                <label ><Translate text={'Conv Target (%)'} /></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'conv target'}
                                    className={`form-control ${errorFields["convTarget"]}`}
                                    name="convTarget"
                                    onChange={handleNumberChange}
                                    value={selectedTarget?.[selectedYear]?.[activeMonth]?.convTarget}
                                    id="client-convTarget"
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group col-md-6'>
                                <label ><Translate text={'Close Target (%)'} /></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'close target'}
                                    className={`form-control ${errorFields["closeTarget"]}`}
                                    name="closeTarget"
                                    onChange={handleNumberChange}
                                    value={selectedTarget?.[selectedYear]?.[activeMonth]?.closeTarget}
                                    id="client-closeTarget"
                                />
                            </div>
                            <div className='form-group col-md-6'>
                                <label ><Translate text={'Test Drive Target (%)'} /></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'test drive target'}
                                    className={`form-control ${errorFields["testdriveTarget"]}`}
                                    name="testdriveTarget"
                                    onChange={handleNumberChange}
                                    value={selectedTarget?.[selectedYear]?.[activeMonth]?.testdriveTarget}
                                    id="client-testdriveTarget"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className={`btn btn-primary float-right ml-2 ${!_.isEmpty(textEditor) ? 'btn-disable' : ''}`} onClick={() => { handleSave() }}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'save'} />
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
                </Modal.Footer>
            </Modal>
            <PopUpModal show={showModel}>
                <UserExists
                    {...props}
                    show={showModel}
                    handleClose={handleModelClose}
                    users={existsUsers}
                />
            </PopUpModal>
        </>
    );
}

export default TargetKPIs;