import React, { useState, useEffect, useReducer, useMemo, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { combineLatest, of } from 'rxjs';
import { collection, docData } from 'rxfire/firestore';


import { ContentOverlay, DealerSelect, PopUpModal, TableView } from '../../components'
import Translate from '../../constants/translate';
import FilterPanel from './filterPanel'
import EnquiryListReducer from '../fleet/fleetReducer'
import CommonHelper from '../../services/common';
import { firestoreDB } from '../../services/helper';
import { allUnallocatedFields, allAllocatedFields } from './viewModel'
import { objEnquiryListVM } from '../pipeline/viewModel'
import { tipStatus } from '../../services/enum';
import InBoundLeadConvert from '../tradeinplus/inBoundLeadConvert'


const FleetListView = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [enquiries, dispatch] = useReducer(EnquiryListReducer, { unallocated: [], allocated: [] })
    const [checkDataLoad, setDataload] = useState({ "unallocated": true, "allocated": false })
    const [settingsLoader, setSettingsLoader] = useState(false);
    const [unallocatedFields, setUnallocatedFields] = useState([])
    const [allocatedFields, setAllocatedFields] = useState([])
    const pageLimit = 50
    const [hasMore, setHasMoreData] = useState([])

    const [activeTab, setActiveTab] = useState('unallocated')

    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.inBoundTIPSearchText ? localStorage.inBoundTIPSearchText : null)
    const [enquiryLoader, setEnquiryLoader] = useState({ "unallocated": true, "allocated": false })



    const [customFilters, setCustomFilters] = useState([])

    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})
    const [pipelineCount, setPipelineCount] = useState(0)
    const [inboundCount, setInboundCount] = useState({ unallocated: 0, allocated: 0 })


    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [states, setStates] = useState([])

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showDealerSelect, setDealerSelect] = useState(false);
    const [inboundModal, setInboundModal] = useState({ show: false, docID: null })

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    const respMins = 15



    useEffect(() => {
        let headerFields = (activeTab === 'unallocated') ? unallocatedFields : allocatedFields;
        let allHeaderFields = (activeTab === 'unallocated') ? allUnallocatedFields : allAllocatedFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(_headers);

    }, [unallocatedFields, activeTab])



    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let defaultCountry = props.dealersettings?.client?.country ? props.dealersettings.client.country.toUpperCase() : 'AU';
            const _states = [];
            defaultCountry && props.dealersettings.states && props.dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                _states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            setLanguages(_languages);
            setNationalities(_nationalities)
            setStates(_states);

            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
        }
    }, [])





    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubinboundAllocList && window.unsubinboundAllocList();
            window.unsubenquiryAllocList && window.unsubenquiryAllocList();
            window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        }
    }, [])

    useEffect(() => {
        if (localStorage.inBoundTIPFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.inBoundTIPFilter])



    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }

        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        // if (props.pipeline.length === 0) {
        //     return
        // }

        if (isFilterApplied && pageNum === 0) {
            setEnquiryLoader({
                ...enquiryLoader,
                [activeTab]: true
            })
        }


        const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        // if ((isFilterApplied || localStorage.inBoundTIPFilter) && isValidFilter()) {
        //     let _pipelineFilter = JSON.parse(localStorage.inBoundTIPFilter);
        //     let _filter = Object.assign({}, _pipelineFilter.value);

        //     //_filter.pipeline = activeTab === 'unallocated' ? 'LeadsBucket' : null;
        //     _filter.inbound = true;
        //     _filter.inboundType = activeTab

        //     if (!viewOtherEnquiry)
        //         _filter.owner = dealersettings.id;

        //     /* DATE RANGE FILTER */
        //     if (!_.isEmpty(_filter.date)) {
        //         _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
        //         delete _filter['date'];
        //     }

        //     /* MAKE & MODEL FILTER */
        //     if (!_.isEmpty(_filter.make))
        //         _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

        //     if (_filter.rottenDays &&
        //         !_.isEmpty(dealersettings) &&
        //         !_.isEmpty(dealersettings.client) &&
        //         !_.isEmpty(dealersettings.client.settings) &&
        //         !_.isEmpty(dealersettings.client.settings.pipelines)) {
        //         _filter.pipelineSettings = JSON.stringify(dealersettings.client.settings.pipelines)
        //     }

        //     _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings, 'INBOUND', null, true);
        //     _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
        //     let _searchObject = {
        //         "type": "searchEnquiries",
        //         "filters": JSON.stringify(_filter),
        //         "sortOrder": activeTab === 'unallocated' ? "addedDate asc" : "stageDate desc",
        //         "pageNum": pageNum,
        //         "pageLimit": pageLimit
        //     }

        //     _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, 'INBOUND', null, true);

        //     console.log('filter-searchEnquiries', _searchObject, pageNum, _filter);
        //     const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        //     searchEnquiries(_searchObject).then((response) => {
        //         //console.log('generic-searchData', _rec, response);
        //         if (response.data.success && localStorage.inBoundTIPFilter && isValidFilter()) {
        //             onCollectionEnquiry(response.data);
        //             setInboundCount({
        //                 [activeTab]: response.data.total
        //             })
        //             setPipelineCount({
        //                 advance: {
        //                     total: response.data.total
        //                 }
        //             })
        //         }
        //         else {
        //             setEnquiryLoader({
        //                 ...enquiryLoader,
        //                 [activeTab]: false
        //             })
        //             setDataload({
        //                 ...checkDataLoad,
        //                 [activeTab]: false
        //             })
        //             setPaging(false)
        //         }
        //     });

        //     window.unsubenquiryAllocList && window.unsubenquiryAllocList();
        //     window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        // }
        // else {

        let searchKeywords = searchText;
        var collectionPromise = [];

        if (localStorage.inBoundTIPFilter) {
            let _pipelineFilter = JSON.parse(localStorage.inBoundTIPFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
            //searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

            setSearchText(searchKeywords);
            localStorage.inBoundTIPSearchText = searchKeywords;
        }

        let allTIProDealers = CommonHelper.getTradeinProDealers(dealersettings, true, true);
        for (let k = 0; k < Math.ceil(allTIProDealers.length / 10); k++) {
            let refEnquiryData = firestoreDB(dealersettings).firestore().collection('tradeinProInbound')
                .where('clientID', 'in', CommonHelper.arrPaginate(allTIProDealers, 10, k + 1))
                .where('isDeleted', '==', false)

            if (searchKeywords) {
                let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                if (formattedSearchText.trim())
                    refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
            }

            if (activeTab === 'allocated') {
                refEnquiryData = refEnquiryData
                    .where('status', '==', 1)
            }
            else {
                refEnquiryData = refEnquiryData
                    .where('status', '==', 0)
                    .orderBy('addedDate', 'desc');

            }

            if (hasMore[k]) {
                refEnquiryData = refEnquiryData
                    .startAfter(hasMore[k])
                    .limit(pageLimit)
            }
            else {
                if (isPaging) {
                    refEnquiryData = '';
                } else {
                    refEnquiryData = refEnquiryData
                        .limit(pageLimit)
                }
            }
            collectionPromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));
        }

        if (collectionPromise.length > 0) {
            window.unsubTIPlist = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    let i = 0;
                    let _hasMore = {};
                    allDocs.forEach(doc => {
                        if (doc.length > 0 && doc.length === pageLimit) {
                            _hasMore = {
                                ..._hasMore,
                                [i]: doc[doc.length - 1]
                            };
                        }
                        else {
                            _hasMore = {
                                ..._hasMore,
                                [i]: null
                            };
                        }
                        querySnapshot.push(...doc);
                        i++;
                    });
                    setHasMoreData(_hasMore);
                    onCollectionUpdate(querySnapshot);
                });
        }
        else {
            setEnquiryLoader({
                ...enquiryLoader,
                [activeTab]: false
            })
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })
            setPaging(false)
        }

        // let refEnquiryData = firestoreDB(props.dealersettings).firestore().collection('tradeinProInbound')
        //     .where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
        //     .where('isDeleted', '==', false)

        // if (searchKeywords) {
        //     let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
        //     if (formattedSearchText.trim())
        //         refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
        // }

        // if (activeTab === 'allocated') {
        //     refEnquiryData = refEnquiryData
        //         .where('status', '==', 1)
        //     //.orderBy('stageDate', 'desc');
        // }
        // else {
        //     refEnquiryData = refEnquiryData
        //         .where('status', '==', 0)
        //         .orderBy('addedDate', 'desc');

        // }

        // let lastRecord = hasMore.filter(e => e.type === activeTab);
        // if (lastRecord.length > 0) {
        //     refEnquiryData = refEnquiryData
        //         .startAfter(lastRecord[0].lastDoc)
        //         .limit(pageLimit)
        // }
        // else {
        //     refEnquiryData = refEnquiryData
        //         .limit(pageLimit)
        // }

        // window.unsubenquiryAllocList = refEnquiryData
        //     .onSnapshot(onCollectionUpdate);
        // }


    }, [checkDataLoad, activeTab])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         snapshotDoc = convertEnquiryVM({
        //             ...change.doc.data(),
        //             documentID: change.doc.id
        //         });
        //         if (change.type === 'added') {
        //             actionType = "ADDNEW_LIST";
        //         }
        //         else if (change.type === 'modified') {
        //             actionType = "UPDATE_LIST"
        //         }
        //         else if (change.type === 'removed') {
        //             actionType = "REMOVE_LIST"
        //         }
        //     }
        // })

        let _enquiries = [];
        if (!actionType) {
            querySnapshot.forEach((doc) => {
                _enquiries.push(convertEnquiryVM({
                    ...doc.data(),
                    documentID: doc.id,
                    stageDate: doc.data().stageDate ? doc.data().stageDate : doc.data().addedDate,
                }));
            });
        }


        // if (activeTab === 'unallocated')
        //     _enquiries = _enquiries.filter(m => m.stage !== 'Archived')

        if (isPaging) {
            dispatch({
                type: "APPEND_LIST",
                data: _enquiries,
                activetab: activeTab
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS_LIST",//"SUCCESS_LIST",//"APPEND_LIST",
                data: actionType ? snapshotDoc : _enquiries,
                activetab: activeTab
            });
        }


        setEnquiryLoader({
            ...enquiryLoader,
            [activeTab]: false
        })
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setPaging(false)
    }



    const convertEnquiryVM = (doc) => {


        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;



        const objenquiryData = Object.assign({}, doc);
        const listVM = Object.assign({}, objEnquiryListVM);
        for (let [key, value] of Object.entries(objenquiryData)) {
            //if (listVM.hasOwnProperty(key))
            listVM[key] = value;
        }
        listVM.objDoc = Object.assign({}, objenquiryData);
        listVM.sortDate = doc.modifiedDate;

        if (!_.isEmpty(doc.systemType)) {
            listVM.inboundType = (doc.systemType === 'oem' || doc.systemType === 'region' || doc.systemType === 'group') ? ['LeadsBucket'] : [doc.systemType]
            doc.tags = [{ type: doc.systemType }];
        }

        let dealersettings = props.dealersettings;

        if (doc.clientID && !_.isEmpty(dealersettings.tradeinProClients))
            listVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, doc.clientID);
        else
            listVM.dealerName = '';


        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.addedBy = CommonHelper.getUserNamebyId(allUsers, doc.addedBy);
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.modifiedBy = doc.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.modifiedBy) : '';
        listVM.origin = CommonHelper.getNameByValue(dealersettings.client.tradeinPro.origins, doc.origin, '');

        if (!_.isEmpty(doc.tradein)) {
            listVM.tradeinModel = (!_.isEmpty(doc.tradein.stock) && doc.tradein.stock.make) ? doc.tradein.stock.make + ' ' + doc.tradein.stock.model : (doc.tradein.make ? doc.tradein.make + ' ' + doc.tradein.model : '')
            listVM.vehicleYear = !_.isEmpty(doc.tradein.stock) ? doc.tradein.stock.year : doc.tradein.year;

            listVM.vehicleSaletype = CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, doc.tradein.saleType, '');

            listVM.vehicleBodytype = !_.isEmpty(doc.tradein.stock) ? doc.tradein.stock.bodyType : doc.tradein.bodyType;
            listVM.regNo = !_.isEmpty(doc.tradein.stock) ? doc.tradein.stock.regNo : doc.tradein.regNo;
        }
        if (!_.isEmpty(doc.contact)) {
            listVM.contactName = CommonHelper.displayFullContact(null, doc.contact);
            listVM.contactNumber = doc.contact.displayID;
            listVM.phone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings);
            listVM.displayPhone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings, true);
            listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''
            listVM.email = doc.contact.email;
            listVM.licenseNo = doc.contact.licenseNo;
            listVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
            listVM.contactType = doc.contact.contactType;
            listVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
            listVM.gender = doc.contact.gender;
            listVM.maritalStatus = doc.contact.maritalStatus;
            listVM.contactMethod = doc.contact.contactMethod;
            listVM.licenseType = doc.contact.licenseType;
            listVM.language = CommonHelper.getLabelByValue(languages, doc.contact.language, '');
            listVM.nationality = CommonHelper.getLabelByValue(nationalities, doc.contact.nationality, '');
            listVM.state = CommonHelper.getLabelByValue(states, doc.contact.state, '');
            let _setttings = doc.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings;
            if (_.isEmpty(_setttings))
                _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

            if (_setttings) {
                listVM.gender = CommonHelper.getNameByValue(_setttings.genders, doc.contact.gender, '');
                listVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
                listVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
                listVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
                listVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');

                listVM.contactcampaign = CommonHelper.getNameByValue(_setttings.campaigns, doc.contact.campaign, '');
                listVM.contactorigin = CommonHelper.getNameByValue(_setttings.origins, doc.contact.origin, '');
            }

            if (doc.contact.clientID && (doc.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                listVM.contactdealerName = CommonHelper.getOtherDealerName(dealersettings, doc.contact.clientID);
            }
            else if (doc.clientName)
                listVM.contactdealerName = doc.clientName;
            else
                listVM.contactdealerName = '';

            listVM.address = doc.contact.address;
            listVM.companyName = doc.contact.companyName;
            listVM.designation = doc.contact.designation;

            listVM.licenseState = doc.contact.licenseState;
            listVM.secondaryName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
            listVM.secondaryPhone = CommonHelper.phoneFormat(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone, props.dealersettings);
            listVM.secondaryEmail = doc.contact.secondaryEmail;
            listVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
            listVM.optinPhone = doc.contact.optinPhone === null ? '' : (doc.contact.optinPhone ? 'Yes' : 'No');
            listVM.optinEmail = doc.contact.optinEmail === null ? '' : (doc.contact.optinEmail ? 'Yes' : 'No');
            listVM.optinPost = doc.contact.optinPost === null ? '' : (doc.contact.optinPost ? 'Yes' : 'No');
            listVM.optinSMS = doc.contact.optinSMS === null ? '' : (doc.contact.optinSMS ? 'Yes' : 'No');
            listVM.marketingOptinEmail = doc.contact.marketingOptinEmail === null ? '' : (doc.contact.marketingOptinEmail ? 'Yes' : 'No');
            listVM.marketingOptinSMS = doc.contact.marketingOptinSMS === null ? '' : (doc.contact.marketingOptinSMS ? 'Yes' : 'No');
        }
        else if (!_.isEmpty(doc.engageNewContact)) {
            let _contact = Object.assign({}, doc.engageNewContact);
            listVM.contactName = CommonHelper.displayFullContact([], _contact, '', true);
            listVM.phone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
            listVM.contactPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings, true);
            listVM.phoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
            listVM.email = _contact.email ? _contact.email : '';
            listVM.csvPhone = listVM.contactPhone ? `${listVM.contactPhone}` : ''
        }

        if (!_.isEmpty(doc.tags) && _.isArray(doc.tags)) {
            listVM.domleadSource = (<div className="Vehicle-availability">
                {
                    doc.tags.map((rec, index) => {
                        return CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type) ?
                            (<div key={index}><span className="text-capitalize">{CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)}</span></div>)
                            : (<></>)
                    })
                }
            </div>);
            listVM.strleadSource = doc.tags.map((rec, index) => {
                return CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)
            }).join(',');
        }

        return listVM
    }

    const handleFavorite = (id, val) => {

    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchText && searchText.length > 2) {
                setDataload({
                    ...checkDataLoad,
                    [activeTab]: true
                })
            }

            if (searchText === '')
                setDataload({
                    ...checkDataLoad,
                    [activeTab]: true
                })
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [searchText]);

    const handleSearchText = (val) => {
        setSearchText(val);
        // setDataload({
        //     ...checkDataLoad,
        //     [activeTab]: true
        // })
        setEnquiryLoader({
            ...enquiryLoader,
            [activeTab]: true
        });
        setPaging(false);
        setHasMoreData([]);
        setPageNum(0);
        // if (!_.isEmpty(val)) {
        //     if (localStorage.inBoundTIPFilter) {
        //         let _pipelineFilter = JSON.parse(localStorage.inBoundTIPFilter);
        //         let _filter = Object.assign({}, _pipelineFilter.value);
        //         _filter['keywords'] = val;
        //         handleApplyFilter(_pipelineFilter);
        //     }
        //     else {
        //         let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': val }, type: 'filter' })
        //         handleApplyFilter(_pipelineFilter);
        //     }
        // }
        // else {
        //     handleClearFilter();
        // }

    }

    const handleSetActiveTab = (val) => {
        if (activeTab !== val) {
            setActiveTab(val);
            setDataload({
                ...checkDataLoad,
                [val]: true
            })
            setHasMoreData([]);
            setPageNum(0);
            setEnquiryLoader({
                ...enquiryLoader,
                [val]: true
            });
            setSelectedIds({});
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('inBoundTIPFilter', JSON.stringify(_filter));
            //localStorage.inBoundTIPSearchText = '';
            setFilterApplied(true);
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([]);
            setPageNum(0);
            //setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.inBoundTIPFilter)) {
            localStorage.removeItem('inBoundTIPFilter');
            localStorage.inBoundTIPSearchText = '';
            setFilterApplied(false);
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([]);
            setPageNum(0);
            setSearchText('');
        }
    }



    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries[activeTab]) && enquiries[activeTab].map(file => {
                let record = Object.assign({}, enquiries[activeTab].filter(e => e.documentID === file.documentID)[0]);
                if (!_.isEmpty(record))
                    _selectedIds[file.documentID] = record.objDoc;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries[activeTab].filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleAllocateData = (name, dealerSelect) => {

        let _selectedIds = Object.assign({}, selectedIds);

        let record = Object.assign({}, enquiries[activeTab].filter(e => e.documentID === name)[0]);
        if (!_.isEmpty(record))
            _selectedIds[name] = record.objDoc ? record.objDoc : record;

        setSelectedIds(_selectedIds);
        if (dealerSelect) setDealerSelect(true)
    }

    const handleActionClick = (id, obj) => {

        return (<div className="fleet-btn-loan width-fix">
            <button
                style={{ cursor: 'pointer' }}
                className="btn btn-sm btn-primary"
                onClick={(e) => {
                    e.preventDefault();
                    //handleAllocateData(id, true);
                    handleShowInboundLead(id);
                }}
            >
                <Translate text={'allocate'} />
            </button>
        </div>)

    }

    const handleDetailsClick = (e, id, obj) => {
        e.preventDefault();
        //handleAllocateData(id, true);
        handleShowInboundLead(id);

    }

    const handleDealerSelectClose = async (_data, _origin) => {

        if (!_.isEmpty(_data)) {

            let ObjData = {
                addedBy: localStorage.uid,
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedFrom: 'web',
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
            }

            ObjData.clientID = _data.dealer ? _data.dealer : props.dealersettings?.client?.id;
            ObjData.owner = _data.owner ? _data.owner : localStorage.uid;
            ObjData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

            const batch = firestoreDB(props.dealersettings).firestore().batch();

            let _selectedIds = Object.keys(Object.assign({}, selectedIds)).slice(0, 50).reduce((result, key) => {
                result[key] = selectedIds[key];
                return result;
            }, {});

            for (let [key, value] of Object.entries(_selectedIds)) {
                let _selectedEnq = Object.assign({}, value);
                await createTIPLead(batch, _selectedEnq, ObjData);
            }

            batch.commit()
                .then(() => {

                    let _leadsSize = Object.keys(_selectedIds).length;
                    toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} Leads allocated successfully`, {
                        duration: 2000
                    })
                    setSelectedIds({});
                    setDealerSelect(false);

                })
                .catch((error) => {
                    console.log(error);
                    setDealerSelect(false);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else {
            setSelectedIds({});
            setDealerSelect(false);
        }

    }

    const handleOpenAllocateModal = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select one or more lead(s) to allocate.'));
            return;
        }
        else {
            // const _leadIds = Object.keys(_selectedIds).slice(0, 50).reduce((result, key) => {
            //     result[key] = _selectedIds[key];
            //     return result;
            // }, {});
            setDealerSelect(true);
        }
    }

    const createTIPLead = async (batch, objInbound, fields) => {
        const { dealersettings } = props;

        let state = Object.assign({}, fields);
        state.documentID = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc().id;
        state.createdDate = window.firebase.firestore.Timestamp.now();
        state.isDeleted = false;
        state.isNewTradein = true;

        const setttings = dealersettings.client.tradeinPro;
        let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.default === true)[0]);
        if (_.isEmpty(_selPipeline))
            _selPipeline = Object.assign({}, setttings.pipelines[0]);

        let defaultstages = _selPipeline.stages;
        let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

        state.pipeline = _selPipeline.value;
        state.stage = stages[0];
        state.status = tipStatus.NEW;
        state.stageDate = window.firebase.firestore.Timestamp.now();
        state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
        state.tags = [
            {
                type: (objInbound.systemType ? objInbound.systemType : ''),
                refID: (objInbound.refID ? objInbound.refID : ''),
                subType: (objInbound.subType ? objInbound.subType : '')
            }
        ]

        state.isConverted = true;
        state.convertedBy = localStorage.uid;
        state.convertedDate = window.firebase.firestore.Timestamp.now();
        state.inboundLeadID = objInbound.documentID || null

        if (!_.isEmpty(objInbound.contact)) {
            let objContactData = Object.assign({}, objInbound.contact);
            //Remove empty value from object
            for (var propName in objContactData) {
                if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                    delete objContactData[propName];
                }
            }

            //save license image
            if (objInbound.request && objInbound.request.LicenseImageURL && objInbound.request.LicenseImageURL.includes('http')) {
                objContactData.licenseFrontURL = objInbound.request.LicenseImageURL;
            }
            if (objInbound.request && objInbound.request.LicenseImageBackURL && objInbound.request.LicenseImageBackURL.includes('http')) {
                objContactData.licenseBackURL = objInbound.request.LicenseImageBackURL;
            }
            if (objInbound.request && objInbound.request.ImageURL && objInbound.request.ImageURL.includes('http')) {
                objContactData.userImageURL = objInbound.request.ImageURL;
            }
            if (objInbound.request && objInbound.request.LicenseExpiry) {
                objContactData.licenseExpiry = moment(objInbound.request.LicenseExpiry).format('YYYY-MM-DD');
            }
            if (objInbound.request && objInbound.request.LicenseNo) {
                objContactData.licenseNo = objInbound.request.LicenseNo;
            }
            //end

            const _systemType = Object.assign({}, !_.isEmpty(dealersettings?.client?.integrations) ?
                dealersettings.client.integrations.filter(e => e.type === objInbound.systemType)[0] : {});
            if (!_.isEmpty(_systemType) && _systemType?.settings?.customerConsent)
                objContactData.terms = {
                    addedBy: localStorage.uid,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    verbalConsent: false,
                    signature: '',
                    termsAndConditions: !_.isEmpty(dealersettings?.client?.digitalConsent) ? dealersettings.client.digitalConsent : (!_.isEmpty(dealersettings?.client?.customerConsent) ? dealersettings.client.customerConsent : ''),
                    consentType: 'Digital'
                };

            state.engageNewContact = objContactData;
            state.contact = null;
        }

        //requirement
        if (!_.isEmpty(objInbound.tradein) && objInbound.tradein?.make) {
            let objTradeinData = Object.assign({}, objInbound.tradein);

            objTradeinData.documentID = firestoreDB(dealersettings).firestore().collection('tradeins').doc().id;
            objTradeinData.tradeinProID = state.documentID;
            objTradeinData.status = tipStatus.NEW;
            objTradeinData.addedBy = localStorage.uid;
            objTradeinData.addedDate = window.firebase.firestore.Timestamp.now();
            objTradeinData.addedFrom = 'web';
            objTradeinData.modifiedBy = localStorage.uid;
            objTradeinData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objTradeinData.modifiedFrom = 'web';
            objTradeinData.isDeleted = false;
            objTradeinData.clientID = state.clientID ? state.clientID : null;
            objTradeinData.projectId = state.projectId ? state.projectId : null;

            //Remove empty value from object
            for (var propName in objTradeinData) {
                if (objTradeinData[propName] === null || objTradeinData[propName] === undefined || objTradeinData[propName] === '' || (_.isObject(objTradeinData[propName]) && _.isEmpty(objTradeinData[propName]))) {
                    delete objTradeinData[propName];
                }
            }

            state.isTradein = true;
            state.tradein = objTradeinData;
            state.tradeinID = objTradeinData.documentID;
        }
        //end

        const objData = Object.assign({}, state);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        //console.log('objData', objData)
        batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${objData.documentID}`), objData, { merge: true });
        let documentID = objData.documentID;

        if (objData.isTradein && !_.isEmpty(documentID) && !_.isEmpty(objData.tradein) && !_.isEmpty(objData.tradeinID))
            batch.set(firestoreDB(dealersettings).firestore().doc(`tradeins/${objData.tradeinID}`), objData.tradein, { merge: true });

        if (!_.isEmpty(documentID) && !_.isEmpty(objInbound.documentID)) {
            let _objReq = {
                status: 1,
                tradeinProID: documentID,
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }

            batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinProInbound/${objInbound.documentID}`), _objReq, { merge: true });
        }

        if (!_.isEmpty(documentID) && !_.isEmpty(objInbound.notes)) {
            let newlogVM = Object.assign({});
            newlogVM.type = 'tradeinProlog';
            newlogVM.subType = 'note';
            newlogVM.isDone = true;
            newlogVM.isDeleted = false;
            newlogVM.addedBy = localStorage.uid;
            newlogVM.modifiedBy = localStorage.uid;
            newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
            newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newlogVM.tradeinProID = objData.documentID ? objData.documentID : null;
            newlogVM.clientID = objData.clientID ? objData.clientID : (dealersettings ? dealersettings.client.id : null);
            newlogVM.projectId = objData.projectId ? objData.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
            newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
            newlogVM.startDate = window.firebase.firestore.Timestamp.now();
            newlogVM.notes = objInbound.notes;
            newlogVM.tags = objInbound.systemType ? [objInbound.systemType] : [];

            batch.set(firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
        }

        CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, 'Lead converted', 'tradeinPro', 'updated');
    }

    //#region SHOW INBOUND LEAD CONVERT MODAL
    const handleShowInboundLead = (id) => {
        //console.log(id);
        setInboundModal({ show: true, docID: id });
    }

    const handleCloseInboundLead = () => {
        setInboundModal({ show: false, docID: null })
    }
    //#endregion

    return (
        <div className="middle-wrapper">

            <FilterPanel
                pipeline={props.pipeline}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                stageCounts={pipelineCount}
                inboundCount={inboundCount}
                csvHeader={csvHeader}
                csvData={csvData}
                //datalist={enquiries}
                view="list"
                isFilterApplied={isFilterApplied}
                searchText={searchText}
                handlesearchText={(val) => {
                    handleSearchText(val);
                }}
                //excelDownload={excelDownload}
                csvBtn={csvBtn}
                pipelineView={null}
                activeTab={activeTab}
                handleSetActiveTab={handleSetActiveTab}
                selectedIds={selectedIds}
                handleOpenAllocateModal={handleOpenAllocateModal}
            />

            <div className="calendar-panel">
                {
                    enquiryLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        enquiries[activeTab].length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    isReorderable={false}
                                    datalist={_.orderBy(enquiries[activeTab], ['sortDate.seconds'], ['desc'])}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={
                                        (activeTab === 'unallocated')
                                            ?
                                            allUnallocatedFields
                                            :
                                            allAllocatedFields
                                    }
                                    //handleSavesettings={handleSavesettings}
                                    dynamicFields={
                                        (activeTab === 'unallocated')
                                            ? (unallocatedFields && unallocatedFields.length > 0) ? [...unallocatedFields.filter(item => item !== 'checkbox'), 'checkbox'] : allUnallocatedFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                            : (allocatedFields && allocatedFields.length > 0) ? [...allocatedFields.filter(item => item !== 'checkbox')] : allAllocatedFields.filter(e => e.default === true && e.type !== 'checkbox').map(v => _.pick(v, ['value']).value)
                                    }
                                    settingsLoader={settingsLoader}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleDetailsClick}
                                    handleFavorite={handleFavorite}
                                    isSettings={false}
                                    hasMore={hasMore}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    activityTab={activeTab}
                                    selectedIds={selectedIds}
                                    handleAllChecked={handleAllChecked}
                                    handleCheckChange={handleCheckChange}
                                    isDisplayByValue={true}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No record found'} /></p>

                                </div>
                            </div>
                        )
                }


            </div>
            <PopUpModal show={showDealerSelect}>
                <DealerSelect
                    show={showDealerSelect}
                    origin={'tradeinPro'}
                    title={'Allocate Leads'}
                    handleClose={handleDealerSelectClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    showOwner={true}

                />
            </PopUpModal>

            <PopUpModal show={inboundModal.show}>
                <InBoundLeadConvert
                    show={inboundModal.show}
                    docID={inboundModal.docID}
                    handleModalClose={handleCloseInboundLead}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    loader={false}
                />
            </PopUpModal>

        </div>
    )
}

export default FleetListView;