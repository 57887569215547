import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { leadBucketCountVM } from './viewModel'
import Translate from '../../constants/translate';
import CommonHelper from "../../services/common";
const ActivitiesWidget = (props) => {
    const [counts, setCounts] = useState(leadBucketCountVM)
    const [loader, setLoader] = useState(true);
    const [todayStart, setTodayStart] = useState(window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
    const [todayEnd, setTodayEnd] = useState(window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
    const [roleIDs, setRoleIDs] = useState();
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false
    const showInboundActivities = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.separateInbdUserAct) ? true : false);

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);

    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-${props.docID}`);

    useEffect(() => {
        return () => {
            window.unsubdashboardactivityWidget && window.unsubdashboardactivityWidget();
        }
    }, [])

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, props.refreshTimer);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchData();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return
        window.unsubdashboardactivityWidget = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setInitialLoad(false)
                    setCounts(snap.data().counts);
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        setTimeout(() => {
                            fetchData(true);
                        }, props.delayTimer);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchData();
    }, [props.refreshData])

    const fetchData = async (hideLoader) => {
        let _filter = {
            clientID: props.dealersettings.client.id,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        // if (props?.dashboardFilter?.salesType?.length > 0) {
        //     _filter.saleType = props.dashboardFilter.salesType.join(',')
        // }
        if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }

        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.make = props.dashboardFilter.makes.join(',')
        }

        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.saleType = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }
        if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        } else if (!_.isEmpty(props.pipeline)) {
            if (inclInboundLeadsinRpt)
                _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
            else
                _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

        //if (accessToFinanceActivity)
        _filter.type = props?.activityType || "activity";
        _filter.leadsWithoutSales = true;

        if (!props?.activityType && showInboundActivities && !ownerDashboard && props.dealersettings.client.roles.some(e => e.permissions && e.permissions.separateInbdUserAct === true)) {
            const _roleIDs = props.dealersettings.client.roles
                .filter(e => e.permissions && !Boolean(e.permissions.separateInbdUserAct))
                .map(r => { return r.documentID })
                .join(',');
            _filter.roleIDs = _roleIDs;
            setRoleIDs(_roleIDs)
        }

        // New Filters Added for Porsche - SaMeeR - 05-09-24
        if (props?.dashboardFilter?.models?.length > 0) _filter.model = props.dashboardFilter.models.join(',')
        if (props?.dashboardFilter?.campaigns?.length > 0) _filter.campaign = props.dashboardFilter.campaigns.join(',')
        if (props?.dashboardFilter?.origins?.length > 0) _filter.origin = props.dashboardFilter.origins.join(',')
        if (props?.dashboardFilter?.enquiryTypes?.length > 0) _filter.enquiryType = props.dashboardFilter.enquiryTypes.join(',')
        if (props?.dashboardFilter?.leadSource?.length > 0) _filter.leadSource = props.dashboardFilter.leadSource.join(',')

        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getActivitesCountV2" : "getActivitesCount",
            params: JSON.stringify(_filter),
        }
        if (!hideLoader)
            setLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp leadsBucket', resp)
            // console.log('getActivitesCount',resp)
            const data = resp.data.data[0]
            const _counts = {
                today: data.todayactivity,
                month: data.monthactivity,
                overdue: data.overdue,
                noactivity: data.noactivity,
            }
            setCounts(_counts)
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({ counts: _counts, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            setLoader(false)
        } else {
            // toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
        }
    }


    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props.dashboardFilter)) {
            window.unsubdashboardactivityCount && window.unsubdashboardactivityCount();
            setFirstTimeLoaded(false);
            fetchData();
        }
        else {
            setInitialLoad(true)
        }

    }, [props.dashboardFilter])

    // useEffect(() => {
    //     if (!_.isArray(props.pipelineEnquiries))
    //         return

    //     var _pipelines = _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
    //     if (!_.isEmpty(props.selectedPipeline)) {
    //         _pipelines = [props.selectedPipeline.value]
    //     }

    //     setCounts({
    //         todayConverted: props.pipelineEnquiries.filter(a =>
    //             _pipelines.some(e => e === a.pipelineValue)
    //             && !_.isEmpty(a.convertedDate)
    //             && a.isConverted === true
    //             && a.convertedDate.seconds >= todayStart.seconds
    //             && a.convertedDate.seconds <= todayEnd.seconds
    //             && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true)),
    //         monthConverted: props.pipelineEnquiries.filter(a =>
    //             _pipelines.some(e => e === a.pipelineValue)
    //             && !_.isEmpty(a.convertedDate)
    //             && a.isConverted === true
    //             && a.convertedDate.seconds >= monthStart.seconds
    //             && a.convertedDate.seconds <= monthEnd.seconds
    //             && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true)),
    //         pendingInboundleads: props.pipelineEnquiries.filter(a =>
    //             a.pipelineValue === 'LeadsBucket'
    //             && (a.stageValue !== 'Archived')
    //             && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true)),
    //         lostApprovalList: props.pipelineEnquiries.filter(a =>
    //             a.statusValue === 'pendingLost'
    //             && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true))
    //     })

    // }, [props.pipelineEnquiries, props.selectedUser, props.selectedPipeline])


    return (<>
        <div className="dashboard-box draggable-section">
            <div className="row h-100" style={{ minHeight: `165px` }}>
                <div className="col-md-6">
                    <div className="dashboard-box-inner d-flex align-items-center justify-content-center h-100">
                        <div className="dashboard-lost-wrap cursor-pointer" onClick={() => {
                            if (counts.overdue) {
                                props.handleShowActivityList({
                                    isDone: false,
                                    endDate: todayStart,
                                    dateType: 'startDate',
                                    roleIDs: roleIDs,
                                    type: props?.activityType || "activity"
                                }, `${props?.titlePrefix || ''} Overdue Activities`, counts.overdue)
                            }
                        }}>
                            <Translate text={`${props?.titlePrefix || ''} Overdue Activities`} />
                            <h2>{counts.overdue ? counts.overdue : '0'}</h2>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 border-left pl-0">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="dashboard-allocated-wrap " style={{ height: '110px' }}>
                                <h3><Translate text={`${props?.titlePrefix || ''} Completed Activities`} /></h3>
                                <div className="dashboard-module-count-wraper dashboard-cell-bg" style={{ marginTop: '12.5px', borderBottomRightRadius: '11px' }}>
                                    <div className="row">
                                        {
                                            props.applydateRange === false ? (
                                                <div className="col-lg-6" style={{ cursor: 'pointer' }} onClick={() => {
                                                    if (counts.today) {
                                                        props.handleShowActivityList({
                                                            startDate: todayStart,
                                                            endDate: todayEnd,
                                                            isDone: true,
                                                            dateType: 'modifiedDate',
                                                            roleIDs: roleIDs,
                                                            type: props?.activityType || "activity"
                                                        }, 'Today Activities', counts.today)
                                                    }
                                                }}>
                                                    <div className="dashboard-count-title"> <Translate text={'today'} /></div>
                                                    <div className="dashboard-count-value"> <a href="#" className="blue-link">{counts.today ? counts.today : '0'}</a></div>
                                                </div>) : (<></>)
                                        }

                                        <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} style={{ cursor: 'pointer' }} onClick={() => {
                                            if (counts.month) {
                                                if (counts.month) {
                                                    props.handleShowActivityList({
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        isDone: true,
                                                        dateType: 'modifiedDate',
                                                        roleIDs: roleIDs,
                                                        type: props?.activityType || "activity"
                                                    }, (props.applydateRange === false ? `MTD Activities` : 'Activities'), counts.month)
                                                }
                                            }
                                        }}>
                                            {
                                                props.applydateRange === false ?
                                                    (
                                                        <div className="dashboard-count-title">MTD</div>
                                                    ) : (<></>)
                                            }
                                            <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link">{counts.month ? counts.month : '0'}</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    props.hideLeadswithoutscheduledactivity === true ? (<></>) : (
                        <div className="col-md-12" style={{ cursor: 'pointer' }} onClick={() => {
                            if (counts.noactivity) {
                                props.handleShowEnquiryList({
                                    activityOption: 'noactivity',
                                    activityOptionType: props.activityType,
                                }, (props.title || 'Leads without scheduled activity'), counts.noactivity < 1999 ? 1999 : 9999)
                            }
                        }}>
                            <div className="dashboard-Pending-wrap"><span className="dashboard-Pending-txt"><Translate text={props.title || 'Leads without scheduled activity'} /></span> <span className="float-right"><h2>{counts.noactivity > 99 ? '99+' : counts.noactivity}</h2></span></div>
                        </div>
                    )
                }
            </div>
        </div >
    </>);
}

export default ActivitiesWidget;