/** LIBRARIES */
import React, { useState, useEffect, useReducer, Suspense } from 'react';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import _ from 'lodash'
import moment from 'moment';
/** PROVIDERS */
/** VIEW-MODELS */
import { objContact } from '../viewModel';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { PopUpModal, PopoverListLoader } from '../../../components';

import { default as _images } from '../../../images';
import AddContact from '../add'
import EnquiryList from '../enquiryList'
import CapInfo from './porsche'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'


import ContactListReducer from '../contactlistReducer'
import { firestoreDB } from '../../../services/helper';

const Contacts = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [contacts, dispatch] = useReducer(ContactListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [hasMore, setHasMoreData] = useState([])
    const [myContact, setMyContact] = useState(localStorage.myContactsOnly ? JSON.parse(localStorage.myContactsOnly) : false)
    const [dmsContact, setDMSContact] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [contactLoader, setContactLoader] = useState(true)
    const [showContactEnqList, setShowContactEnqList] = useState({ show: false, id: '' })
    const [contactCount, setContactCount] = useState(0)

    const [showCapInfo, setShowCapInfo] = useState({ show: false, contactID: '', contact: null })
    const pageLimit = 20

    const [titles, setTitles] = useState([])

    const enableSharedContact = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedContact) ? true : false);

    const _dmsSettings = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "keyloop" || e.type === "cap")[0]);
    const _dmsEnabled = _dmsSettings && _dmsSettings.active && _dmsSettings.enabled && _dmsSettings.settings && _dmsSettings.settings.searchContact ?
        ((props.clientID && props.clientID !== props.dealersettings.client.id) ? false : true) :
        false;
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers
    useEffect(() => {
        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            //setDataload(false)
            if (localStorage.defaultModule == 'tradeinPro')
                return;

            try {

                let _searchObject = {};
                if (props.clientID)
                    _searchObject['clientID'] = props.clientID
                else
                    _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings);

                const addContact = window.firebase.functions().httpsCallable('contact-getContactCount');
                addContact(_searchObject).then((_data) => {
                    //console.log('contact-getContactCount', _data);
                    if (!_.isEmpty(_data) && !_.isEmpty(_data.data) && _data.data.length > 0) {
                        setContactCount(_data.data[0].contact_count);
                    }

                });
            }
            catch (error) {
                console.error(error)
            }


            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    label: doc.name
                });
            });

            setTitles(_titles);
            setDataload(true)
        }
    }, [props.dealersettings])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.unSubContactList && window.unSubContactList();
            //console.log('pageUNLoad', myContact, props.dealersettings.client.id, searchText, hasMore)
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }

        const { dealersettings } = props;
        let clientID = props.clientID ? props.clientID : (dealersettings.client ? dealersettings.client.id : '');
        //console.log('pageLoad', myContact, props.dealersettings.client.id, searchText, hasMore, isPaging)
        if (dmsContact) {
            try {
                const dmsContacts = window.firebase.functions().httpsCallable('contact-searchDMS');
                dmsContacts({
                    clientID: clientID,
                    dms: _dmsSettings.type,
                    keywords: searchText
                }).then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: "SUCCESS",
                            data: response.data.data && response.data.data.map(rec => {
                                return _dmsSettings.type === 'cap' ? {
                                    ...rec,
                                    'type': _dmsSettings.type
                                } : {
                                    ...rec,
                                    firstName: rec.name,
                                    documentID: uuid()
                                }
                            }),
                            sortName: 'name',
                            sortOrder: 'asc'
                        });
                        setDataload(false)
                        setContactLoader(false)
                        setPaging(false)
                    }
                    else {
                        dispatch({
                            type: "SUCCESS",
                            data: [],
                            sortName: 'name',
                            sortOrder: 'asc'
                        });
                        setDataload(false)
                        setContactLoader(false)
                        Swal.fire('Something went wrong', '', 'error')
                    }
                });
            }
            catch (err) {
                dispatch({
                    type: "SUCCESS",
                    data: [],
                    sortName: 'name',
                    sortOrder: 'asc'
                });
                setDataload(false)
                setContactLoader(false)
                console.log(err);
                Swal.fire('Something went wrong', '', 'error')
            }

        }
        else {
            let contactlstIdxDataRef = firestoreDB(props.dealersettings).firestore().collection('contactDetails')

            if (localStorage.defaultModule === 'tradeinPro')
                contactlstIdxDataRef = CommonHelper.whereClientOrGroupTradeinPROContactClause(contactlstIdxDataRef, dealersettings, props.clientID);
            else if (localStorage.defaultModule !== 'tradeinPro' && props.clientID) // if client id there , you need to check only clientid
                contactlstIdxDataRef = contactlstIdxDataRef.where('clientID', '==', props.clientID)
            else
                contactlstIdxDataRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(contactlstIdxDataRef, dealersettings);

            // if (enableSharedContact && dealersettings.client.group) {
            //     contactlstIdxDataRef = contactlstIdxDataRef.where('groupID', '==', dealersettings.client.group)
            //     //contactlstIdxDataRef = contactlstIdxDataRef.where('clientID', '==', dealersettings.client.id)
            // }
            // else {
            //     contactlstIdxDataRef = contactlstIdxDataRef.where('clientID', '==', dealersettings.client.id)
            // }

            contactlstIdxDataRef = contactlstIdxDataRef.where('isDeleted', '==', false)

            if (_.trim(searchText) && _.trim(searchText).length > 2) {
                let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
                if (formattedSearchText.trim())
                    contactlstIdxDataRef = contactlstIdxDataRef
                        .where('keywords', 'array-contains', formattedSearchText.trim().toLowerCase())
            }

            if (myContact === true) {
                let userID = props.dealersettings ? props.dealersettings.id : '';
                contactlstIdxDataRef = contactlstIdxDataRef
                    .where('owner', '==', userID)
            }

            contactlstIdxDataRef = contactlstIdxDataRef
                .orderBy('firstName', 'asc')

            if (hasMore.length > 0) {
                contactlstIdxDataRef = contactlstIdxDataRef
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                contactlstIdxDataRef = contactlstIdxDataRef
                    .limit(pageLimit)
            }

            contactlstIdxDataRef
                //.onSnapshot(onCollectionUpdate);
                .get().then(querySnapshot => {
                    const _contacts = [];

                    if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                        setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
                    }
                    else {
                        setHasMoreData([]);
                    }
                    querySnapshot.docs.forEach((doc) => {
                        _contacts.push(convertContactVM({
                            ...doc.data(),
                            documentID: doc.id
                        }));
                    });

                    if (isPaging) {
                        dispatch({
                            type: "APPENDCONTACT",
                            data: _contacts,
                            sortName: 'firstName',
                            sortOrder: 'asc'
                        });
                    }
                    else {
                        dispatch({
                            type: "SUCCESS",
                            data: _contacts,
                            sortName: 'firstName',
                            sortOrder: 'asc'
                        });
                    }

                    setDataload(false)
                    setContactLoader(false)
                    setPaging(false)

                });
        }
        //  return () => {
        //      window.unSubContactList();
        //  }

    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertContactVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDCONTACT";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATECONTACT"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVECONTACT"
                }
            }
        })
        const _contacts = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                _contacts.push(convertContactVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }
        if (isPaging) {
            dispatch({
                type: "APPENDCONTACT",
                data: _contacts,
                sortName: 'firstName',
                sortOrder: 'asc'
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS",
                data: actionType ? snapshotDoc : _contacts,
                sortName: 'firstName',
                sortOrder: 'asc'
            });
        }

        //console.log('hasMore', hasMore)
        setDataload(false)
        setContactLoader(false)
        setPaging(false)
    }

    const convertContactVM = (doc) => {
        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objcontactData)) {
            contact[key] = value;
        }

        let dealersettings = props.dealersettings;
        let _setttings = contact.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[contact.clientID] && dealersettings.group.clients[contact.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
        if (_setttings) {
            contact.fullName = CommonHelper.getFullName(_setttings.titles, contact, '', true);
            //contact.campaign = CommonHelper.getNameByValue(_setttings.campaigns, contact.campaign, '');
            //contact.origin = CommonHelper.getNameByValue(_setttings.origins, contact.origin, '');
            //contact.gender = CommonHelper.getNameByValue(_setttings.genders, contact.gender, '');
            //contact.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, contact.maritalStatus, '');
            //contact.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, contact.contactMethod, '');
            //contact.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, contact.interests, '', true);
            //contact.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, contact.licenseType, '');
        }

        if (contact.regionID && dealersettings.regionID && contact.regionID !== dealersettings.regionID)
            contact.regionName = CommonHelper.getNameByCode(dealersettings.regions, contact.regionID, '');

        // if (contact.regionID && dealersettings.regionID && contact.regionID !== dealersettings.regionID)
        //     contact.regionName = CommonHelper.getNameByCode(dealersettings.regions, contact.regionID, '');

        if (contact.clientID && (props.clientID || contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            contact.dealerName = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);
            console.log(contact.dealerName, contact.clientID, props.clientID, dealersettings)
        }

        return contact
    }



    const handleActionClick = (id) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            setEditDocument(id);
            setModelShow(true);
        }}>
            <i className="ico icon-edit"></i>
        </div>)
    }

    const handleContactdetailsClick = (e, id) => {
        e.preventDefault();
        props.history.push("/contacts/details/" + id);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }
    const handleClose = (id) => {
        setModelShow(false);
        setEditDocument(null);
    }

    const listenScrollEvent = (e) => {
        if (hasMore.length === 0) return;
        //console.log('listenScrollEvent', e.target.scrollHeight - e.target.scrollTop, e.target.offsetHeight)
        if (e.target.scrollHeight - e.target.scrollTop === e.target.offsetHeight) {
            //console.log('listenScrollEvent')
            //handlePagination();
        }
        return;
    }

    const handleContactClose = (_contactID, _contact) => {
        const { isNewEnquiry } = props;
        // if (isNewEnquiry) // SaMeeR (muzammal 06-09-22)
        //     setShowContactEnqList({ show: true, id: _contactID })
        // else

        if (_contact?.gdprDeleted) {
            Swal.fire(CommonHelper.showLocale(props, "Can't select GDPR contact"));
            return;
        }

        if (_contact.type === 'cap') {
            if (!_.isEmpty(_contact?.Vehicles)) _contact.contactType = 'Current Customer'
            setShowCapInfo({ show: true, contact: { ..._contact, type: null } })

        }
        else {
            props.handleContactClose(_contactID, _contact);
        }


    }

    const handleCloseCAPInfo = (_contact) => {
        if (!_.isEmpty(_contact)) {
            props.handleContactClose(_contact.documentID, _contact);
        }

        setShowCapInfo({ show: false, contact: null })
    }

    const handleCloseEnquiry = (_enquiryid) => {
        if (_enquiryid) {
            if (_enquiryid === 'new') {
                props.handleContactClose(showContactEnqList.id);
            }
            else {
                props.handleContactClose(_enquiryid, 'enquiry');
            }
            setShowContactEnqList({ show: false, id: '' })
        }
        else
            setShowContactEnqList({ show: false, id: '' })
    }
    const divStyle = {
        height: '500px',
        overflow: 'auto',
        position: "relative"
    };

    return (
        <>
            {/* <FilterPanel
                sortOrder={sortOrder}
                //sortName={sortName}
                searchText={searchText}
                handlesortOrder={(val) => {
                    setSortOrder(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
                handlesearchText={(val) => {
                    setSearchText(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
                handleSortNamechange={(val) => {
                    setSortName(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
            /> */}

            <Modal
                id="contact-List"
                show={props.show}
                onHide={props.handleContactClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-terms">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'contacts'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className={`vehicle-popover-head ${props.showAdd ? '' : 'pr-0'}`}>
                            {
                                props.showAdd
                                    ?
                                    <div className="vehicle-popover-add">
                                        <a href="#" className="mini-button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setEditDocument(null);
                                                setModelShow(true);
                                                // this.setState({
                                                //     contactModal:
                                                //     {
                                                //         showContact: true,
                                                //         showContactSearch: false,
                                                //         contactTitle: 'addContact',
                                                //         clsContactActive: 'overlay-modal active'
                                                //     }
                                                // });
                                            }}> <i className="ico icon-add"></i>
                                        </a>
                                    </div>
                                    :
                                    <></>

                            }

                            <input
                                type="text"
                                aria-label="Search"
                                placeholder="Search..."
                                value={searchText ? searchText : ''}
                                autoComplete="off"
                                onChange={e => {
                                    let _value = e.target.value;
                                    if (!_value)
                                        setSearchText('');
                                    else
                                        setSearchText(_value);

                                    if (_.trim(_value) && _.trim(_value).length > 2)
                                        setContactLoader(true);

                                    setTimeout(() => {
                                        setHasMoreData([]);
                                        setDataload(true);
                                    }, 1000);


                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setSearchText(searchText)
                                        setHasMoreData([]);
                                        setDataload(true)
                                        setContactLoader(true)
                                    }
                                }}
                            />

                        </div>


                        <div className="popover-sub-head">
                            {
                                (contactCount > 0 && !myContact && !searchText)
                                    ?
                                    <div className="float-left popover-sub-head-count"><strong>{contactCount}</strong> <Translate text={'contacts'} /></div>
                                    :
                                    <></>
                            }

                            <div className="float-right">

                                <div className="checkbox icheck-success">
                                    <input
                                        type="checkbox"
                                        id="myContact"
                                        name="myContact"
                                        className="uncheck-activity"
                                        checked={myContact}
                                        onChange={(e) => {
                                            setMyContact(e.target.checked);
                                            setPaging(false)
                                            setHasMoreData([]);
                                            setDataload(true)
                                            setContactLoader(true)
                                        }} />
                                    <label htmlFor="myContact"><Translate text={'myContacts'} /></label>
                                </div>
                            </div>
                            {
                                _dmsEnabled ? (<div className="float-right mr-2">
                                    <div className="checkbox icheck-success">
                                        <input
                                            type="checkbox"
                                            id="dmsContact"
                                            name="dmsContact"
                                            className="uncheck-activity"
                                            checked={dmsContact}
                                            onChange={(e) => {
                                                setDMSContact(e.target.checked);
                                                if (searchText.length > 0) {
                                                    setPaging(false)
                                                    setHasMoreData([]);
                                                    setDataload(true)
                                                    setContactLoader(true)
                                                }
                                            }} />
                                        <label htmlFor="dmsContact"><Translate text={`Search in ${_dmsSettings.name}`} /></label>
                                    </div>
                                </div>) : (<></>)
                            }

                        </div>

                        <Suspense>
                            <div style={divStyle} onScroll={listenScrollEvent}>
                                {
                                    contactLoader ? (<PopoverListLoader />) :
                                        (contacts && contacts.length) > 0
                                            ?
                                            <div className='mr-2'>
                                                {contacts.map(contact =>
                                                    <div key={contact.documentID} id={contact.documentID} className="vehicle-popover-loop" onClick={(e) => {
                                                        e.preventDefault();
                                                        if (dmsContact && _dmsSettings?.type !== 'cap') {
                                                            const getDMSContact = window.firebase.functions().httpsCallable('contact-getDMSContact');
                                                            getDMSContact(contact).then((response) => {
                                                                if (response.data.success) {
                                                                    handleContactClose(response.data.data.documentID, {
                                                                        ...response.data.data,
                                                                        owner: localStorage.uid
                                                                    })
                                                                }
                                                                else {
                                                                    Swal.fire(response.data.message, '', 'error')
                                                                }
                                                            });
                                                        }
                                                        else {
                                                            handleContactClose(contact.documentID, contact)
                                                        }
                                                    }}>
                                                        <div className="divTable">
                                                            <div className="divTableBody">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell width100">
                                                                        <div className="customer-content">
                                                                            {/* <div className="customer-avatar-image">
                                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}> <img src={CommonHelper.showUserAvatar(contact.userImageURL, contact.fullName)} className="img-object-fit" alt="" width="70" height="70" /> </a>
                                                                            </div> */}
                                                                            <div className="customer-avatar-title">
                                                                                {CommonHelper.displayContactIcon(contact, 'mr-1')}
                                                                                {CommonHelper.displayContact(titles, contact, '--')}
                                                                                <span className='activity-details-preferred'>{CommonHelper.displayPreferredName(contact, '')}</span>



                                                                            </div>

                                                                            {
                                                                                (contact.businessContact && !_.isEmpty(contact.company))
                                                                                    ?
                                                                                    <div className="customer-avatar-titlesub">
                                                                                        {CommonHelper.getFullName(titles, contact, '--')}
                                                                                    </div>
                                                                                    :
                                                                                    <> </>

                                                                            }

                                                                            {/* <div className="calllogs-contact-sub">
                                                                                <ul className="calllogs-contact-details">
                                                                                    {contact.displayID ? <li> <i className="ico icon-enquiry"></i>{contact.displayID}</li> : <></>}
                                                                                    {contact.dealerName ? <li> <div className="customer-avatar-titlesub blue-color" title='Dealer'><i className="ico icon-group"></i>{contact.dealerName}</div></li> : <></>}
                                                                                    {contact.regionName ? <li> <div className="customer-avatar-titlesub blue-color" title='Region'><i className="ico icon-group"></i>{contact.regionName}</div></li> : <></>}
                                                                                </ul>
                                                                            </div> */}
                                                                            {contact.displayID ?
                                                                                <>
                                                                                    <div className="customer-avatar-titlesub">
                                                                                        <i className="ico icon-enquiry"></i> {contact.displayID}
                                                                                    </div>
                                                                                </> : <></>}


                                                                            {contact.dealerName ? <div className="customer-avatar-titlesub blue-color"><i className="ico icon-group"></i><strong>{contact.dealerName}</strong></div> : <></>}
                                                                            {contact.regionName ? <div className="customer-avatar-titlesub blue-color"><strong>{contact.regionName}</strong></div> : <></>}
                                                                            {
                                                                                contact.phone
                                                                                    ?
                                                                                    <>
                                                                                        <div className="customer-avatar-titlesub">
                                                                                            <strong><Translate text={'mobile'} />: </strong> {CommonHelper.phoneFormat(contact.phoneCode, contact.phone, props.dealersettings)}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            {
                                                                                contact.email
                                                                                    ?
                                                                                    <>
                                                                                        <div className="customer-avatar-titlesub">
                                                                                            <strong><Translate text={'email'} />: </strong>  {contact.email}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            {/* {(contact.phone || contact.email)
                                                                                ?
                                                                                <div className="calllogs-contact-sub">
                                                                                    <ul className="calllogs-contact-details">
                                                                                        {contact.phone ? <li> <i className="ico icon-call"></i>{CommonHelper.phoneFormat(contact.phoneCode, contact.phone, props.dealersettings)}</li> : <></>}
                                                                                        {contact.email ? <li> <i className="ico icon-email"></i>{contact.email}</li> : <></>}
                                                                                    </ul>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                            } */}
                                                                            {
                                                                                contact.licenseNo
                                                                                    ?
                                                                                    <>
                                                                                        <div className="customer-avatar-titlesub">
                                                                                            <strong><Translate text={'licenseNo'} />: </strong> {contact.licenseNo}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            {
                                                                                contact.licenseExpiry
                                                                                    ?
                                                                                    <>
                                                                                        <div className="customer-avatar-titlesub">
                                                                                            <strong><Translate text={'licenseExpiry'} />: </strong> {moment(contact.licenseExpiry).format('DD MMM YYYY')}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            {
                                                                                ((contact.owner && !_.isEmpty(allUsers)) || contact.gdprDeleted)
                                                                                    ?
                                                                                    <div className="float-right owner-avatar-right">
                                                                                        {
                                                                                            contact.gdprDeleted ?
                                                                                                <div className="badge badge-pill badge-warm float-left mr-2" style={{ height: '21px', paddingTop: '2px' }}>
                                                                                                    <i className="ico icon-delete mr-1"> </i><Translate text={'gdprDeleted'} upperCase={true} />
                                                                                                </div> : <></>
                                                                                        }
                                                                                        {
                                                                                            contact.owner && !_.isEmpty(allUsers) ? (<OverlayTrigger
                                                                                                placement='bottom'
                                                                                                overlay={
                                                                                                    <Tooltip>{CommonHelper.getUserNamebyId(allUsers, contact.owner)}</Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <div className="pipeline-users pl-0">
                                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                        <img src={CommonHelper.getUserImagebyId(allUsers, contact.owner)} width="200" height="200" alt="" className="rounded-circle img-object-fit" />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </OverlayTrigger>) : (<></>)
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    <></>
                                                                            }

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className="ico icon-Contacts"></i> </div>
                                                        <div className="no-data-txt mt-2"> <Translate text={'noContactFound'} /></div>
                                                    </div>

                                                </div>
                                            </>
                                }

                            </div>
                        </Suspense>

                    </>
                </Modal.Body>
                {
                    hasMore && hasMore.length > 0 ? (
                        <Modal.Footer >

                            {/* <div className="loadmore-modal-middle-btn">
                                <button
                                    className={`btn btn-sm btn-loadmore  ${checkDataLoad ? 'form-disable' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handlePagination();
                                    }}><Translate text={'Load More'} />
                                    {
                                        !_.isEmpty(checkDataLoad) ? (
                                            <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                        ) : (<i className="fas fa-chevron-down"></i>)
                                    }
                                </button>
                            </div> */}

                            <div className="load-more-button pb-2"> <a href="#" className="mt-1" onClick={(e) => {
                                e.preventDefault();
                                handlePagination();
                            }}><Translate text={'Load More'} />
                                {
                                    !_.isEmpty(checkDataLoad) ? (
                                        <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                    ) : (<i className="fa fa-angle-down" aria-hidden="true"></i>)
                                }

                            </a></div>
                        </Modal.Footer>

                    ) : (<></>)
                }
            </Modal>

            <PopUpModal show={modelShow}>
                <AddContact
                    show={modelShow}
                    handleClose={handleClose}
                    docID={null}
                    title={'addContact'}
                    showSearch={false}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    selectedClientID={props.clientID}
                >
                </AddContact>
            </PopUpModal>

            {/* <PopUpModal show={showContactEnqList.show}>
                <EnquiryList
                    show={showContactEnqList.show}
                    title={'Existing enquiries for the selected contact'}
                    handleClose={handleCloseEnquiry}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    contactID={showContactEnqList.id}
                />
            </PopUpModal> */}

            <PopUpModal show={showCapInfo.show}>
                <CapInfo
                    show={showCapInfo.show}
                    contact={showCapInfo.contact}
                    handleClose={handleCloseCAPInfo}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    enquiryID={props.enquiryID}
                    enquiry={props.enquiry}

                />
            </PopUpModal>
        </>
    )
}

export default Contacts