import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import _ from 'lodash';
import CommonHelper from '../../../../services/common';
import { frontDeskTabs, NoDataDiv, LoadingDiv } from '../constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap' 
import { firestoreDB } from '../../../../services/helper';

const FrontDeskSearch = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	history,
	enableSharedContact,
	getDealerName,
}) => {
	const [activeTab, setActivetab] = useState('contacts');
	const [contactDetails, setContactDetails] = useState([]);
	const [loader, setLoader] = useState([]);

	useEffect(() => {
		if (searchText.length > 0) {
			setLoader(true);
			let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
			let refContactDetails;
			if (enableSharedContact && dealersettings.client.group) {
				refContactDetails = firestoreDB(dealersettings)
					.firestore()
					.collection('contactDetails')
					.where('groupID', '==', dealersettings.client.group)
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.limit(10);
			} else {
				refContactDetails = firestoreDB(dealersettings)
					.firestore()
					.collection('contactDetails')
					.where('clientID', '==', dealersettings.client.documentID)
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.orderBy('firstName', 'asc')
					.limit(10);
			}

			var dashboardPipelines = combineLatest(collection(refContactDetails))
				.pipe(
					map(([contactDetails]) => {
						return [
							_.map(contactDetails, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'contactDetail',
									sortDate: obj.data().addedDate,
								};
							}),
						];
					}),
				)
				.subscribe(allDocs => {
					setContactDetails(allDocs[0]);
					setLoader(false);
					dashboardPipelines && dashboardPipelines.unsubscribe();
				});
		}
		return () => {
			dashboardPipelines && dashboardPipelines.unsubscribe();
		};
	}, [searchText, dealersettings.client.documentID]);

	return (
		<div className='dropdown' style={{ float: 'left', position: 'inherit' }}>
			<div
				className='dropdown-menu dropdown-menu-left'
				aria-labelledby='dropdownMenuButton'
				style={{ display: showSearchPanel ? 'block' : '', left: '120px' }}
			>
				<div className='form-style globalsearch-wrap'>
					<div className='globalsearch-nav-wraper'>
						<div className='globalsearch-nav pl-0'>
							<Tab.Container defaultActiveKey={activeTab}>
								<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
									{frontDeskTabs.map((rec, index) => {
										return (
											<OverlayTrigger
													placement='bottom'
													overlay={
															<Tooltip><span>{rec.title}</span></Tooltip>
													}
												>
											<Nav.Item
												as='li'
												bsPrefix='li-nav'
												key={index}
											>
												<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
														<i className={rec.ico}></i>
														{rec.name}
												</Nav.Link>
											</Nav.Item>
										</OverlayTrigger>
										);
									})}
								</Nav>
							</Tab.Container>
						</div>
					</div>
					<div className='globalsearch-body'>
						{loader ? (
							<LoadingDiv />
						) : (
							<div className='global-item-wraper'>
								{activeTab === 'contacts' ? (
									<>
										{contactDetails.length > 0 ? (
											<>
												{contactDetails.map(obj => (
													<a
														className='global-item d-flex'
														onClick={e => {
															e.preventDefault()
															history.push(`/`);
															history.push(
																`contacts/details/${obj.documentID}`,
															);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className='ico icon-Contacts'></i>
															</div>
														</div>
														<div>
															{(obj.firstName || obj.lastName) && (
																	<span className='global-header'>{`${CommonHelper.displayContactName([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
															)}
															{obj.phone ? (
																<span className='global-info'>{CommonHelper.phoneFormat(obj.phoneCode, obj.phone, dealersettings)}</span>
															) : obj.email ? (
																<span className='global-info'>{`${obj.email}`}</span>
															) : (
																<></>
															)}
															{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ? 
																	(<>
																	<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																	</>) : <></>}
														</div>
													</a>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : (
									<></>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FrontDeskSearch;
