/** LIBRARIES */
import React, { Component } from 'react';
import { Accordion, Tabs, Tab } from 'react-bootstrap';
import { OverlayTrigger, Tooltip, Card, Dropdown, Button } from 'react-bootstrap';
import moment from 'moment';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import Reasons from '../customer/reason'
import { collection } from 'rxfire/firestore';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'

import {
    PopUpModal,
    ReactTimePicker,
    ReactSelect,
    ReactMultiSelect,
    LoaderOverlay,
    InputAutoText,
    InputAutoNumber,
    AntDatePicker,
    PopoverPanel,
    ImageCropHook,
    InputRadio, EvalScan,
    ImageCanvas, DropDownMenu
} from '../../../components';

import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import PageNotFound from '../../../components/pagenotFound';

import AddContact from '../../contacts/add';
import ContactInfo from '../../contacts/info'
import Files from '../../files';
import AddFile from '../../files/add';
import CommonHelper from '../../../services/common';

import Activity from '../../pipeline/details/activity'
import AddVehicle from '../requirement/add';
import VehicleInfo from '../requirement/info';
import AddOns from './addOns';
import AddOnsInfo from './addOnsInfo';
import AddNotes from './addNotes';
import AddCheckList from '../checklists/addChecklist'
import AddBBTCheckList from '../checklists/addBBTChecklist'
import AddRecommendation from './addRecommendation';
import AddPart from './addPart';
import AddLoanAppointment from './addLoanAppointment';
import CustomerApproval from './customerApproval'
import ConfrimQuotation from './confrimQuotation'
import ServiceDetails from './serviceDetails'
import PipelineSwitch from '../pipelineSwitch';
import ShowCosts from '../showCosts'
import SendMail from '../../pipeline/details/sendMail';
import ServiceHistory from '../serviceInfo';
import AddDiscount from '../discounts/service'
import AddDiscountRecomm from '../discounts/recommendation'
import AddDiscountPack from '../discounts/packages'
import AddVideo from '../videos'
import ReRequestStatus from '../reRequestStatus'
import AddFleet from '../../fleet/add'
import AddTestDrive from '../../testdrive/add'
import StockLoanList from './loanList'
import TestDriveInfo from '../../fleet/fleetInfo';
import ChatSidePanel from '../../chat/chatsidepanel';
import QuotationInfo from '../../serviceInvoice/info'

import AddTradeIn from "../../tradeIn/add";
import AutoGrab from "../../tradeIn/autograb";
import TradeInInfo from "../../tradeIn/tradeinInfo";
import SendTradeInLink from "../../tradeIn/sendLink";
import TradeinLinkAppraisal from "../../tradeinplus/list/linkAppraisals";

import WorkLog from '../workLog'
/** VIEW-MODELS */
import { serviceStatus, recommStatuses, stockStatus, recommendationChoice, recommPartQuotes, service_type, service_Types } from '../../../services/enum';
import {
    objContact,
    contactBasicDetailsVM,
    objOwnedVehicle,
} from '../../contacts/viewModel';
import { stockBasicVM } from '../../stock/viewModel';
import {
    objService,
    objServicePackage,
    objSlot, partQuotes, objServiceBasicVM
} from '../viewModel';
import { firestoreDB, storageBucket } from '../../../services/helper';
import { objTradeIn } from "../../tradeIn/viewModel";

//#region  ASSIGN VARIABLES
let titles = [];
let servicePackageTypes = [];
let appointmentTypes = [];
let servicePackagesData = [];
let partStatuses = [];
let serviceAllStatus = [];
let technicianAllStatus = [];
let reasons = [];
const fieldsData = {
    contact: '',
    businessContact: '',
    service: '',
    company: ''
}

//#endregion

class ServiceInfo extends Component {
    // _isMounted = false;
    constructor(props) {
        super(props);
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs');
        this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.unsubscribeService = null;
        this.unsubscribeContact = null;
        this.unsubscribeSerSlots = null;
        this.unsubscribeRecomm = null;
        this.unsubscribePack = null;
        this.unsubscribeNote = null;
        this.unsubscribeServiceHistory = null;
        this.unsubscribeTradeIn = null;
        this.mandatoryFields = [];
        this.mandatoryRecommFields = ['labourCost', 'allocationJobTime'];
        this.mandatoryPartsFields = [];
        this.mandatoryVehicleFields = [];
        this.mandatoryFieldsForTestDrive = [];
        this.state = {
            loading: false,
            activeLeftKey: '0',
            activeCntKey: '0',
            activeVehKey: '1',
            activeRightKey: '',
            fields: Object.assign({}, objService),
            contact: Object.assign({}, objContact),
            recommendations: Object.assign([]),
            servicePackages: Object.assign([]),
            serviceNotes: Object.assign([]),
            currentSlots: Object.assign([]),
            serviceHistory: Object.assign([]),
            serviceVideos: Object.assign([]),
            serviceLoanStocks: Object.assign([]),
            serviceLoanApps: Object.assign([]),
            serviceInvoices: Object.assign([]),
            testdrives: Object.assign([]),
            tradeIns: Object.assign([]),
            serviceID: null,
            _clientID: null,
            errors: {},
            errorsRecomm: {},
            pipelines: [],
            defaulStages: [],
            allUsers: [],
            allTeamUsers: [],
            allAdvisorUsers: [],
            allTechnicianUsers: [],
            allPartsUsers: [],
            allWorkshopUsers: [],
            slotTimes: [],
            rottenDays: 0,
            fullServiceCost: {
                total: 0,
                labourCost: 0,
                partsCost: 0,
                packagesCost: 0,
                taxCost: 0,
                subTotal: 0,
            },
            serviceInfo:
            {
                new: false,
                lead: false
            },
            contactModal:
            {
                show: false,
                showSearch: false,
                title: '',
                clsActive: '',
                mandatoryFields: null
            },
            vehicleModal:
            {
                show: false,
                title: '',
                clsActive: '',
                vehicle: null,
                mandatoryFields: null
            },
            fileModal:
            {
                show: false
            },
            recommendationModal:
            {
                show: false,
                docID: '',
                title: '',
                data: null
            },
            partModal:
            {
                show: false,
                docID: '',
                recommID: '',
                recommendation: null,
                title: '',
                part: null,
                isPackage: false
            },
            showSharePanel: {
                chatClsActive: '',
                chatClsName: 'sidebar',
            },
            videoModal:
            {
                show: false,
                recordID: '',
            },
            pipelinePopOver: { showpopover: false, targetpopover: null },
            costPopOver: { showpopover: false, targetpopover: null },
            statusSettings: [],
            activeStageIndex: 0,
            isEmptyPipeline: false,
            showReAuthentication: false,
            showCanvas: false,
            isLoadingInspection: false,
            imageURL: null,
            imageURLs: null,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            showAddOns: false,
            showAddOnsInfo:
            {
                show: false,
                data: null
            },
            showNotes: false,
            showCheckLists: false,
            showBBTCheckLists: false,
            isChangeRecomm: false,
            showCustomerApproval: false,
            showConfrimQuotation: false,
            showServiceDetails: false,
            originFromServiceDetails: null,
            showDiscountService: {
                show: false,
                isview: null,
            },
            showDiscountRecomm: {
                show: false,
                data: null,
                isview: null,
            },
            showDiscountPack: {
                show: false,
                data: null,
                isview: null,
            },
            showStatusRequestModal: false,
            showLoanAppointmentModal: false,
            testDriveModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null,
                contact: null
            },
            fleetModal:
            {
                show: false,
                ID: '',
                serviceID: '',
                title: '',
                clsActive: '',
                stock: null,
                contact: null
            },
            showStocks: false,
            pagenotFound: false,
            tradeInModal: {
                show: false,
                tradeinID: "",
                title: "",
                clsActive: "",
            },
            autoGrabModal: {
                show: false,
                tradeinID: "",
                selectedVehicle: {},
                title: "",
                clsActive: "",
            },
            showEvalScan: false,
            sendLinkModal: {
                show: false,
                tradeinID: null,
            },
            linkAppraisalModal: false,
            showReasons: {
                show: false,
                id: '',
                type: ''
            },
            expanded: null

        }
        const { dealersettings } = this.props;
        this.currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
    }

    //#region FUNCTIONS

    //#region LOAD PAGE

    async loadService() {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        const { serviceid, dealersettings, clientUsers, groupUsers } = this.props;
        if (serviceid && dealersettings) {
            // if (dealersettings.client.settings.pipelines.length === 0) {
            //     Swal.fire('Access denied. Please contact administrator.', '', 'info')
            //     this.props.history.push('/service');
            //     return
            // }

            const refData = await firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(serviceid).get();
            var _stage = '';

            if (refData.exists) {
                const _objService = Object.assign({}, refData.data());
                if (localStorage.defaultModule !== 'oem' && _objService.clientID && !Object.keys(dealersettings.group.clients).some(e => e === _objService.clientID)) {
                    if (localStorage.defaultModule === 'service') {
                        Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.You dont have dealer access. Please contact administrator.'), '', 'info')
                        this.props.history.push('/service');
                    }
                    return
                }

                const service = Object.assign({}, objService);
                let _isEmptyPipeline = false;
                for (let [key, value] of Object.entries(_objService)) {
                    service[key] = value;
                }
                service.documentID = refData.id;

                if (_.isEmpty(service.pipeline)) {
                    //service.pipeline = 'LeadsBucket';
                    _isEmptyPipeline = true;
                }

                if (!_.isEmpty(service.parts)) {
                    service.parts.forEach((doc) => {
                        for (let [key, value] of Object.entries(doc)) {
                            doc[key] = _.isNaN(value) ? 0 : value;
                        }
                    });
                }

                const _client = service.clientID && !_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.group) &&
                    !_.isEmpty(dealersettings.group.clients) &&
                    dealersettings.group.clients[service.clientID] ? dealersettings.group.clients[service.clientID] : dealersettings.client;

                //console.log('E              X             I                S                  T             S', _isEmptyPipeline)
                if (_client && _client.services && service.pipeline && !_client.services.pipelines.some(item => item.value === service.pipeline)) {
                    if (localStorage.defaultModule === 'service') {
                        Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.You dont have pipleine access. Please contact administrator.'), '', 'info')
                        this.props.history.push('/service');
                    }
                    return
                }



                const contact = Object.assign({}, objContact);
                const pipelines = [];
                let _slotTimes = [];
                let defaultstages;
                let statusSettings;
                let slotsData = [];
                let stages = null;

                _stage = service['stage'];


                // //add service into routes
                this.props.handleRouteChange({
                    name: (service.isContact && !_.isEmpty(service.contact) && (!_.isEmpty(service.contact.firstName) || !_.isEmpty(service.contact.lastName)))
                        ? CommonHelper.displayContact([], service.contact, '--')
                        : (!_.isEmpty(service.engageNewContact) ? CommonHelper.displayContact([], service.engageNewContact, '--') : (service.displayID ? service.displayID : refData.id)),
                    id: refData.id,
                    displayID: service.displayID ? service.displayID : refData.id,
                    status: service.status && service.status === serviceStatus.OWNERRESPONDED ? service.ownerResponse : service.status,
                    active: true,
                    clientid: service.clientID,
                    isNew: null,
                    params: '',
                    module: 'service'
                }, 'service');



                if (!_.isEmpty(service.slot)) {
                    _slotTimes = this.getSlotTimes(service.slot.from, service.slot.to, service.slot.halfanHrSlot)
                }

                if ((!_.isEmpty(service['contact']) && service['contact'].documentID) || service.contactID) {

                    let _contactid = service.contactID ? service.contactID : service['contact'].documentID;

                    const refContactData2 = await this.refContact.doc(_contactid).get();
                    if (refContactData2.exists) {
                        const objcontactData = Object.assign({}, refContactData2.data());
                        for (let [key, value] of Object.entries(objcontactData)) {
                            contact[key] = value;
                        }
                        if (_.isNil(objcontactData?.optinSMS)) contact.optinSMS = null;
                        if (_.isNil(objcontactData?.marketingOptinEmail)) contact.marketingOptinEmail = null;
                        if (_.isNil(objcontactData?.marketingOptinSMS)) contact.marketingOptinSMS = null;
                        contact.documentID = _contactid;
                        service.contactID = _contactid;
                        //console.log('refContactData', objcontactData, contact);
                    }

                    this.unsubscribeContact = this.refContact
                        .doc(_contactid)
                        .onSnapshot(this.onContactCollectionUpdate);

                    this.unsubscribeServiceHistory = this.ref
                        .where('contact.documentID', '==', _contactid)
                        .where('isDeleted', '==', false)
                        .onSnapshot(this.onServiceJobCollectionUpdate);

                }

                if (!_.isEmpty(_client) && !_.isEmpty(_client.settings)) {
                    const setttings = _client.settings;
                    titles = [];
                    setttings.titles && setttings.titles.forEach((doc) => {
                        titles.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });
                }

                if (!_.isEmpty(_client) && !_.isEmpty(_client.settings)) {
                    const setttings = _client.settings;
                    if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.testdriveContact))
                        this.mandatoryFieldsForTestDrive = setttings.mandatoryFields.testdriveContact.split(',');
                }

                if (!_.isEmpty(_client) && !_.isEmpty(_client.services)) {
                    const setttings = _client.services;

                    if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.service))
                        this.mandatoryFields = setttings.mandatoryFields.service.split(',');

                    if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.recommendation)) {
                        this.mandatoryRecommFields = _.union(setttings.mandatoryFields.recommendation.split(','), ['labourCost', 'allocationJobTime'])
                    }

                    if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.part))
                        this.mandatoryPartsFields = setttings.mandatoryFields.part.split(',');

                    if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.vehicle))
                        this.mandatoryVehicleFields = setttings.mandatoryFields.vehicle.split(',');

                    appointmentTypes = [];
                    setttings.appointmentTypes && setttings.appointmentTypes.forEach((doc) => {
                        appointmentTypes.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });

                    reasons = [];
                    setttings.reasons && setttings.reasons.forEach((doc) => {
                        reasons.push({
                            value: doc.name,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });

                    partStatuses = [];
                    setttings.partStatus && setttings.partStatus.forEach((doc) => {
                        partStatuses.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });

                    serviceAllStatus = [];
                    setttings.serviceStatus && setttings.serviceStatus.forEach((doc) => {
                        serviceAllStatus.push({
                            ...doc,
                            label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                        });
                    });

                    technicianAllStatus = [];
                    setttings.technicianStatus && setttings.technicianStatus.forEach((doc) => {
                        technicianAllStatus.push({
                            ...doc,
                            label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                        });
                    });

                    if (!_.isEmpty(setttings.slots))
                        slotsData = Object.assign([], setttings.slots);

                    if (!_.isEmpty(setttings.servicePackages)) {
                        servicePackagesData = [];
                        setttings.servicePackages && setttings.servicePackages.forEach((_pack) => {
                            const _package = Object.assign({}, objServicePackage);
                            for (let [key, value] of Object.entries(_pack)) {
                                _package[key] = value;
                            }
                            servicePackagesData.push(_package);
                        });
                    }

                    //console.log('servicePackagesData', servicePackagesData)
                    //let _selPipeline = dealersettings.allPipelines[0];
                    let allPipelines = setttings.allPipelines ? setttings.allPipelines : setttings.pipelines;
                    if (service.pipeline) {
                        let _selPipeline = allPipelines.filter(e => e.value === service.pipeline)[0];
                        defaultstages = _selPipeline && _selPipeline.stages;
                        statusSettings = _selPipeline && _selPipeline.statusSettings;

                        if (!_.isEmpty(defaultstages))
                            stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')
                    }

                    allPipelines && allPipelines.forEach((doc) => {
                        pipelines.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });


                }



                // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
                let options = [];
                let technicianOptions = [];
                let advisorOptions = [];
                let partsOptions = [];
                let workshopOptions = [];
                if (!_.isEmpty(dealersettings.client.roles) && service.clientID) {

                    let roleIds = [..._.map(dealersettings.client.roles.filter(e => service.pipeline && e.servicePipeline && e.servicePipeline.some(m => m === service.pipeline)), function (obj) {
                        return obj.documentID;
                    })];

                    let roleTechIds = [..._.map(dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.technician === true), function (obj) {
                        return obj.documentID;
                    })];

                    let roleAdvIds = [..._.map(dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.serviceAdvisor === true), function (obj) {
                        return obj.documentID;
                    })];

                    let rolePartIds = [..._.map(dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.partsInterpretor === true), function (obj) {
                        return obj.documentID;
                    })];

                    let roleWorkIds = [..._.map(dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.workshopManager === true), function (obj) {
                        return obj.documentID;
                    })];

                    let _selectedUsers = [];
                    let _selectedTechUsers = [];
                    let _selectedAdvUsers = [];
                    let _selectedPartUsers = [];
                    let _selectedWorkUsers = [];

                    if (!_.isEmpty(roleTechIds))
                        _selectedTechUsers = _.filter(clientUsers, (v) => _.includes(roleTechIds, v.roleID));

                    if (!_.isEmpty(roleAdvIds))
                        _selectedAdvUsers = _.filter(clientUsers, (v) => _.includes(roleAdvIds, v.roleID));

                    if (!_.isEmpty(rolePartIds))
                        _selectedPartUsers = _.filter(clientUsers, (v) => _.includes(rolePartIds, v.roleID));

                    if (!_.isEmpty(roleWorkIds))
                        _selectedWorkUsers = _.filter(clientUsers, (v) => _.includes(roleWorkIds, v.roleID));

                    if (!_.isEmpty(roleIds))
                        _selectedUsers = _.filter(clientUsers, (v) => _.includes(roleIds, v.roleID));

                    _selectedWorkUsers && _selectedWorkUsers.map(rec => {
                        return workshopOptions.push({
                            value: rec.id,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            searchlabel: rec.name,
                            label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        })
                    })

                    _selectedPartUsers && _selectedPartUsers.map(rec => {
                        return partsOptions.push({
                            value: rec.id,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            searchlabel: rec.name,
                            label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        })
                    })

                    _selectedTechUsers && _selectedTechUsers.map(rec => {
                        return technicianOptions.push({
                            value: rec.id,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            searchlabel: rec.name,
                            label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        })
                    })

                    _selectedAdvUsers && _selectedAdvUsers.map(rec => {
                        return advisorOptions.push({
                            value: rec.id,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            searchlabel: rec.name,
                            label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        })
                    })

                    if (service.pipeline) {
                        _selectedUsers && _selectedUsers.map(rec => {
                            return options.push({
                                value: rec.id,
                                active: _.isBoolean(rec.active) ? rec.active : true,
                                searchlabel: rec.name,
                                label: <div className="drop-image">
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            })
                        })
                    }
                    else {
                        clientUsers.forEach(rec => {
                            return options.push({
                                value: rec.id,
                                active: _.isBoolean(rec.active) ? rec.active : true,
                                searchlabel: rec.name,
                                label: <div className="drop-image">
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            })
                        })
                    }
                }


                //}

                const _teams = [];
                !_.isEmpty(dealersettings.client.teams) && dealersettings.client.teams.forEach(doc => {
                    _teams.push({
                        value: doc.id,
                        type: 'team',
                        searchlabel: doc.name,
                        user_ids: doc.user_ids ? doc.user_ids : [],
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar('', doc.name)} alt="" width="50" className="img-object-fit" />
                                {doc.name}
                            </div>
                        )
                    });
                });

                this.setState({
                    //serviceID: refData.id,
                    _clientID: service.clientID ? service.clientID : dealersettings.client.id,
                    fields: service,
                    currentSlots: slotsData,
                    contact: contact,
                    pipelines: pipelines,
                    allUsers: options,
                    allTechnicianUsers: technicianOptions,
                    allAdvisorUsers: advisorOptions,
                    allPartsUsers: partsOptions,
                    allWorkshopUsers: workshopOptions,
                    defaulStages: defaultstages,
                    activeStageIndex: stages && stages.indexOf(_stage),
                    isEmptyPipeline: _isEmptyPipeline,
                    statusSettings: statusSettings,
                    allTeamUsers: [
                        {
                            label: 'Teams',
                            options: _teams
                        },
                        {
                            label: 'Users',
                            options: options
                        }
                    ],
                    slotTimes: _slotTimes
                }, () => {

                    if (service.appointmentDate) {
                        this.handleLoadsSlotByDate(moment(service.appointmentDate).format('YYYY-MM-DD'));
                    }

                    this.unsubscribeService = this.ref
                        .doc(serviceid)
                        .onSnapshot(this.onServiceCollectionUpdate);

                    this.loadServieData(service.documentID);

                    this.unsubscribeTradeIn = firestoreDB(dealersettings).firestore().collection('tradeins')
                        .where('serviceID', '==', service.documentID)
                        .where('isDeleted', '==', false)
                        .onSnapshot(this.onTradeInsCollectionUpdate);

                });


            }
            else {
                this.setState({
                    pagenotFound: true,
                    serviceID: null
                })
            }
        }
    }

    // loadServieDataOLD = (serviceid) => {

    //     window.refCollectionJobDetailData = docData(window.firebase.firestore().doc(`serviceJobs/${serviceid}`))
    //         .pipe(
    //             switchMap(tradeIns => {
    //                 const serviceIds = [serviceid];
    //                 return combineLatest(
    //                     of(tradeIns),
    //                     combineLatest(
    //                         serviceIds.length > 0 ? serviceIds.map(serviceID =>
    //                             collectionData(window.firebase.firestore().collection(`serviceJobs/${serviceID}/recommendations`)).pipe(
    //                                 map(recommendationSnapshot => recommendationSnapshot)
    //                             )
    //                         ) : of([])
    //                     ),
    //                     combineLatest(
    //                         serviceIds.length > 0 ? serviceIds.map(serviceID =>
    //                             collectionData(window.firebase.firestore().collection(`serviceJobs/${serviceID}/servicePackages`)).pipe(
    //                                 map(servicePackagesSnapshot => servicePackagesSnapshot)
    //                             )
    //                         ) : of([])
    //                     ),
    //                     combineLatest(
    //                         serviceIds.length > 0 ? serviceIds.map(serviceID =>
    //                             collectionData(window.firebase.firestore().collection(`serviceJobs/${serviceID}/notes`)).pipe(
    //                                 map(notesSnapshot => notesSnapshot)
    //                             )
    //                         ) : of([])
    //                     ),
    //                     combineLatest(
    //                         serviceIds.length > 0 ? serviceIds.map(serviceID =>
    //                             collectionData(window.firebase.firestore().collection(`serviceJobs/${serviceID}/videos`).where('isDeleted', '==', false)).pipe(
    //                                 map(videosSnapshot => videosSnapshot)
    //                             )
    //                         ) : of([])
    //                     ),
    //                 )
    //             }),
    //             map(([jobIns, recommendationSnapshot, servicePackagesSnapshot, notesSnapshot, videosSnapshot]) => {
    //                 return {
    //                     service: { ...jobIns },
    //                     recommendations: recommendationSnapshot[0],
    //                     servicePackages: servicePackagesSnapshot[0],
    //                     notes: notesSnapshot[0],
    //                     videos: videosSnapshot[0]
    //                 }

    //             })
    //         )
    //         .subscribe(_jobIns => {
    //             if (!_.isEmpty(_jobIns)) {

    //                 const recommendations = [];
    //                 _jobIns.recommendations && _jobIns.recommendations.forEach((_owned) => {
    //                     const owned = Object.assign({}, objRecommendation);
    //                     const objOwnedData = Object.assign({}, _owned);
    //                     for (let [key, value] of Object.entries(objOwnedData)) {
    //                         owned[key] = value;
    //                     }
    //                     //owned.documentID = _owned.id;
    //                     recommendations.push(owned);
    //                 });

    //                 const servicePackages = [];
    //                 _jobIns.servicePackages && _jobIns.servicePackages.forEach((_owned) => {
    //                     const owned = Object.assign({}, objServicePackage);
    //                     const objOwnedData = Object.assign({}, _owned);
    //                     for (let [key, value] of Object.entries(objOwnedData)) {
    //                         owned[key] = value;
    //                     }
    //                     //owned.documentID = _owned.id;
    //                     servicePackages.push(owned);
    //                 });

    //                 const notes = [];
    //                 _jobIns.notes && _jobIns.notes.forEach((_owned) => {
    //                     const owned = Object.assign({});
    //                     const objOwnedData = Object.assign({}, _owned);
    //                     for (let [key, value] of Object.entries(objOwnedData)) {
    //                         owned[key] = value;
    //                     }
    //                     //owned.documentID = _owned.id;
    //                     notes.push(owned);
    //                 });

    //                 const videos = [];
    //                 _jobIns.videos && _jobIns.videos.forEach((_owned) => {
    //                     const owned = Object.assign({});
    //                     const objOwnedData = Object.assign({}, _owned);
    //                     for (let [key, value] of Object.entries(objOwnedData)) {
    //                         owned[key] = value;
    //                     }
    //                     //owned.documentID = _owned.id;
    //                     videos.push(owned);
    //                 });

    //                 this.setState({
    //                     serviceNotes: notes,
    //                     serviceVideos: videos,
    //                     servicePackages: servicePackages,
    //                     recommendations: recommendations
    //                 }, () => { this.saveTotalServiceCost(); });

    //             }
    //         });
    // }

    loadServieData = (_serviceID) => {

        const { serviceid, dealersettings } = this.props;

        const enableSharedStock = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            dealersettings.group.enableSharedStock) ? true : false);

        var refServiceLoanStocks = window.firebase.firestore().collection('stock')
            .where('isDeleted', '==', false)
            .where('isLoan', '==', true)
            .where('isServiceLoan', '==', true)
            .where("status", "in", [stockStatus.ONLOAN, stockStatus.ONTESTDRIVE, stockStatus.AVAILABLE])
        if (enableSharedStock && dealersettings.client.group) {
            refServiceLoanStocks = refServiceLoanStocks
                .where('groupID', '==', dealersettings.client.group)
        }
        else {
            refServiceLoanStocks = refServiceLoanStocks
                .where('clientID', '==', dealersettings.client.id)
        }

        var refServiceLoanApps = firestoreDB(dealersettings).firestore().collection('loanAppointments')
            .where('clientID', '==', dealersettings.client.id)

        var refServiceFleets = firestoreDB(dealersettings).firestore().collection('fleet')
            .where('serviceID', '==', _serviceID)


        window.refCollectionJobDetailData = combineLatest(
            collection(firestoreDB(dealersettings).firestore().collection(`serviceJobs/${_serviceID}/recommendations`)),
            collection(firestoreDB(dealersettings).firestore().collection(`serviceJobs/${_serviceID}/servicePackages`)),
            //collection(window.firebase.firestore().collection(`serviceJobs/${_serviceID}/notes`)),
            collection(firestoreDB(dealersettings).firestore().collection(`serviceJobs/${_serviceID}/videos`)),
            collection(firestoreDB(dealersettings).firestore().collection(`serviceJobs/${_serviceID}/invoices`).where('isDeleted', '==', false)),
            collection(refServiceLoanStocks),
            collection(refServiceLoanApps),
            collection(refServiceFleets),
        ).pipe(
            map(([recommendationSnapshot, servicePackagesSnapshot, videosSnapshot, invoiceSnapshot, stocksSnapshot, appointmentsSnapshot, fleetSnapshot]) => {
                return [
                    _.map(recommendationSnapshot, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    _.map(servicePackagesSnapshot, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    //_.map(notesSnapshot, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    _.map(videosSnapshot, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    _.map(invoiceSnapshot, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    _.map(stocksSnapshot, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    _.map(appointmentsSnapshot, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    _.map(fleetSnapshot, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                ]
            }))
            .subscribe(async ([recommendationSnapshot, servicePackagesSnapshot, videosSnapshot, invoiceSnapshot, stocksSnapshot, appointmentsSnapshot, fleetSnapshot]) => {
                // BIND APPOINTMENT IN STOCK OBJECT
                stocksSnapshot && stocksSnapshot.forEach((_stock) => {
                    _stock.loanAppointments = Object.assign([], appointmentsSnapshot.filter(a => a.stockID === _stock.documentID));
                });

                //console.log('stocks', stocksSnapshot)
                //console.log('appointments', appointmentsSnapshot)
                // console.log('recommendationSnapshot', recommendationSnapshot)
                // console.log('servicePackagesSnapshot', servicePackagesSnapshot)
                // console.log('notesSnapshot', notesSnapshot)
                // console.log('videosSnapshot', videosSnapshot)

                this.setState({
                    serviceID: _serviceID,
                    //serviceNotes: notesSnapshot,
                    serviceVideos: videosSnapshot,
                    servicePackages: servicePackagesSnapshot,
                    recommendations: recommendationSnapshot,
                    serviceLoanApps: appointmentsSnapshot,
                    serviceLoanStocks: stocksSnapshot,
                    testdrives: fleetSnapshot,
                    serviceInvoices: invoiceSnapshot,
                }, () => { this.saveTotalServiceCost(); });

            });

    }

    onServiceCollectionUpdate = (docSnapshot) => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');

        if (!docSnapshot.exists) {
            return;
        }

        const { dealersettings } = this.props;
        const pipelines = [];
        let _slotTimes = [];
        let rottendays = 0;
        let stages = null;
        let defaultstages;
        let statusSettings;

        const service = Object.assign({}, objService);
        const _objService = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(_objService)) {
            service[key] = value;
        }

        service.documentID = docSnapshot.id;
        // if (_.isEmpty(service.pipeline))
        //     service.pipeline = 'LeadsBucket';

        if (!_.isEmpty(service.parts)) {
            service.parts.forEach((doc) => {
                for (let [key, value] of Object.entries(doc)) {
                    doc[key] = _.isNaN(value) ? 0 : value;
                }
            });
        }

        if (!_.isEmpty(service.slot)) {
            _slotTimes = this.getSlotTimes(service.slot.from, service.slot.to, service.slot.halfanHrSlot)
        }

        if (!service.counts || service.counts.todayDate !== moment().format('YYYY-MM-DD')) {
            let objcounts = service.counts ? service.counts : {};
            objcounts.todayDate = moment().format('YYYY-MM-DD');

            firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(docSnapshot.id).set({ counts: objcounts }, { merge: true });
        }

        let _client = service.clientID && !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            !_.isEmpty(dealersettings.group.clients) &&
            dealersettings.group.clients[service.clientID] ? dealersettings.group.clients[service.clientID] : dealersettings.client;

        if (!_.isEmpty(_client) && !_.isEmpty(_client.settings)) {
            const setttings = _client.settings;
            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.testdriveContact))
                this.mandatoryFieldsForTestDrive = setttings.mandatoryFields.testdriveContact.split(',');
        }

        if (!_.isEmpty(_client) && !_.isEmpty(_client.settings) && service.pipeline) {
            const setttings = _client.services;

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.service))
                this.mandatoryFields = setttings.mandatoryFields.service.split(',');

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.recommendation)) {
                this.mandatoryRecommFields = _.union(setttings.mandatoryFields.recommendation.split(','), ['labourCost', 'allocationJobTime'])
            }

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.part))
                this.mandatoryPartsFields = setttings.mandatoryFields.part.split(',');

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.vehicle))
                this.mandatoryVehicleFields = setttings.mandatoryFields.vehicle.split(',');

            let allPipelines = setttings.allPipelines ? setttings.allPipelines : setttings.pipelines;
            let _selPipeline = allPipelines.filter(e => e.value === service.pipeline)[0];
            defaultstages = _selPipeline && _selPipeline.stages;
            statusSettings = _selPipeline && _selPipeline.statusSettings;

            if (!_.isEmpty(defaultstages))
                stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

            allPipelines.forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    label: doc.name
                });
            });

            allPipelines
                .filter(e => e.value === service.pipeline)[0]
                .stages.forEach(col => {
                    if (service.stage === col.value) {
                        if (col.rottenDays) {
                            var enqage = service.modifiedDate ? moment().diff(moment.unix(service.modifiedDate.seconds), 'days') : 0;
                            rottendays = enqage - col.rottenDays;
                        }
                    }
                });

        }

        const { displayID, status, isContact, contact, ownerResponse } = service;
        if (service.status !== this.state.fields.status) {
            this.props.updatenavbar({
                id: this.state.serviceID,
                displayID: service.displayID,
                status: status && status === serviceStatus.OWNERRESPONDED ? ownerResponse : status,
                name: (isContact && !_.isEmpty(contact)) ? CommonHelper.displayContact([], contact, '--') : (service.displayID ? service.displayID : docSnapshot.id),
                module: 'service'
            }, 'service');
        }

        this.setState({
            fields: service,
            pipelines: pipelines,
            statusSettings: statusSettings,
            defaulStages: defaultstages,
            activeStageIndex: stages && stages.indexOf(service['stage']),
            slotTimes: _slotTimes
        });
    };

    onContactCollectionUpdate = (docSnapshot) => {
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        const contact = Object.assign({}, objContact);
        const objcontactData = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(objcontactData)) {
            if (contact.hasOwnProperty(key))
                contact[key] = value;
        }
        contact.documentID = docSnapshot.id;
        if (_.isNil(objcontactData?.optinSMS)) contact.optinSMS = null;
        if (_.isNil(objcontactData?.marketingOptinEmail)) contact.marketingOptinEmail = null;
        if (_.isNil(objcontactData?.marketingOptinSMS)) contact.marketingOptinSMS = null;
        this.setState({
            contact: contact
        });
    }

    onServiceJobCollectionUpdate = querySnapshot => {
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        const services = [];
        let _fields = Object.assign({});
        const { serviceid } = this.props;

        querySnapshot.forEach(_enquiry => {
            const enquiry = Object.assign({});
            const objEnquiryData = Object.assign({}, _enquiry.data());
            for (let [key, value] of Object.entries(objEnquiryData)) {
                enquiry[key] = value;
            }
            enquiry.documentID = _enquiry.id;

            let dealersettings = this.props.dealersettings;
            if (dealersettings && enquiry.clientID && (enquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
                enquiry.dealerName = CommonHelper.getOtherDealerName(dealersettings, enquiry.clientID);
            }

            if (!_.isEmpty(enquiry.pipeline) && enquiry.documentID !== serviceid) {
                _fields = enquiry;
                services.push(enquiry);
            }

        });

        this.setState({
            serviceHistory: _.orderBy(services, ["modifiedDate"], ["desc"])
        });
    };

    onTradeInsCollectionUpdate = querySnapshot => {
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        let tradeIns = [];
        querySnapshot.forEach(_tradein => {
            const tradeIn = Object.assign({}, objTradeIn);
            const objTradeInData = Object.assign({}, _tradein.data());
            for (let [key, value] of Object.entries(objTradeInData)) {
                tradeIn[key] = value;
            }
            tradeIn.documentID = _tradein.id;
            tradeIn.addedDate = objTradeInData.addedDate ? objTradeInData.addedDate : window.firebase.firestore.Timestamp.now();
            tradeIns.push(tradeIn);
        });

        if (!_.isEmpty(tradeIns)) {
            tradeIns = tradeIns.sort(function (x, y) {
                return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1);
            })
        }

        this.setState({
            tradeIns: tradeIns
        });
    }

    handleSpaceScrollLock = (e) => {
        if (e.keyCode == 32 && e.target == document.body) {
            e.preventDefault();
        }
    }

    componentDidMount() {
        localStorage.removeItem("objService");
        this._isMounted = true;
        if (this._isMounted) {
            this.loadService();
            setTimeout(() => this.scrollIntoComments(), 4000);
        }

        window.addEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });

    }

    componentDidUpdate() {
        let classList = document.getElementsByTagName('body')[0].classList.value;
        if (classList.indexOf('enquiry-bg') >= 0 && classList.indexOf('inner-bg') >= 0) {
            //console.log('componentDidUpdate-classList', classList.indexOf('enquiry-bg'), classList.indexOf('inner-bg'));
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        }
        if (this.state.loading) {
            this.clearAllSubscribe();
            this.loadService();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.serviceid !== state.serviceID && state.serviceID) {
            return {
                loading: true,
                serviceID: null,
            };
        } else {
            return {
                loading: false,
            };
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        window.removeEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });
        this.clearAllSubscribe();
        if (window.location.pathname.indexOf('service/details') < 0) {
            this.props.resetnavbar();
        }
        this._isMounted = false;
    }

    clearAllSubscribe = () => {
        this.unsubscribeService && this.unsubscribeService();
        this.unsubscribeContact && this.unsubscribeContact();
        this.unsubscribeSerSlots && this.unsubscribeSerSlots();
        this.unsubscribeRecomm && this.unsubscribeRecomm();
        this.unsubscribePack && this.unsubscribePack();
        this.unsubscribeNote && this.unsubscribeNote();
        this.unsubscribeServiceHistory && this.unsubscribeServiceHistory();
        this.unsubscribeTradeIn && this.unsubscribeTradeIn();
        window?.refCollectionJobDetailData?.unsubscribe && window.refCollectionJobDetailData.unsubscribe()
    }

    scrollIntoComments = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let __comments = params.get('comments');
        let __tradeinID = params.get('tid');

        if (__comments && document.getElementById('enquiry-item-' + __comments)) {
            document.getElementById('enquiry-item-' + __comments).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }

        if (__tradeinID) this.handleEditTradeIn(__tradeinID)
    }

    //#endregion

    //#region RECOMMENDATION CHANGES
    handleRecommendationClose = (_data) => {
        // if (!_.isEmpty(_data)) {
        //     this.saveServiceField('vehicle', _data);
        // }
        this.setState({
            recommendationModal: {
                show: false,
                title: '',
                docID: '',
                data: null
            },
        });
    }

    handleAddRecommendation = () => {
        this.setState({
            recommendationModal: {
                show: true,
                title: 'Add Recommendation',
                docID: '',
                data: null
            },
        });
    }

    handleEditRecommendation = (req) => {
        this.setState({
            recommendationModal: {
                show: true,
                title: 'Edit Recommendation',
                docID: req ? req.documentID : '',
                data: req
            },
        });
    }
    //#endregion

    //#region PART CHANGES
    handlePartClose = (_data) => {
        if (!_.isEmpty(_data)) {
            this.saveTotalServiceCost();
        }
        this.setState({
            partModal: {
                show: false,
                title: '',
                docID: '',
                recommID: '',
                recommendation: null,
                part: null,
                isPackage: false
            },
        });
    }

    handleAddPart = (recommID, recommendation) => {
        this.setState({
            partModal: {
                show: true,
                title: 'Add Part',
                docID: '',
                recommID: recommID,
                recommendation: recommendation,
                part: null,
                isPackage: false
            },
        });
    }

    handleEditPart = (recommID, recommendation, req) => {
        this.setState({
            partModal: {
                show: true,
                title: 'Edit Part',
                docID: req ? req.documentID : '',
                recommID: recommID,
                recommendation: recommendation,
                part: req,
                isPackage: false
            },
        });
    }

    handleDeletePart = (recommID, recommendation, _ID) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to delete this part.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {
                if (recommID && recommendation) {

                    const _recommendation = Object.assign({}, recommendation);
                    let recommendationDetail = Object.assign({}, _recommendation.detail);
                    let recommendationParts = Object.assign([], recommendationDetail.parts);

                    recommendationDetail.parts = Object.assign([], recommendationParts.filter(e => e.documentID !== _ID));
                    _recommendation.detail = recommendationDetail;

                    const { dealersettings } = this.props;
                    if (!_recommendation.projectId) _recommendation.projectId = (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);

                    const updateRef = firestoreDB(this.props.dealersettings).firestore().doc(`serviceJobs/${this.state.serviceID}/recommendations/${recommID}`);
                    updateRef.set(_recommendation, { merge: true }).then((docRef) => {
                        //console.log(docRef);
                        this.isLoading = false;
                        toast.notify('Part deleted successfully', {
                            duration: 2000
                        })
                        this.handleStatusReScheduled();
                    }).catch((error) => {
                        console.error("Error updating contact_details: ", error);
                    });
                }

            }

        });

    }
    //#endregion

    //#region SERVICE PART CHANGES

    handleAddServicePart = () => {
        this.setState({
            partModal: {
                show: true,
                title: 'Add Part',
                docID: '',
                part: null,
                isPackage: true
            },
        });
    }

    handleEditServicePart = (req) => {
        this.setState({
            partModal: {
                show: true,
                title: 'Edit Part',
                docID: req ? req.documentID : '',
                part: req,
                isPackage: true
            },
        });
    }

    handleDeleteServicePart = (_ID) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to delete this part.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {
                if (_ID) {
                    const _objData = Object.assign({});
                    let recommendationParts = Object.assign([], this.state.fields.parts);
                    _objData.parts = Object.assign([], recommendationParts.filter(e => e.documentID !== _ID));
                    _objData.modifiedBy = localStorage.uid;
                    _objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                    _objData.modifiedFrom = 'web';
                    const updateRef = firestoreDB(this.props.dealersettings).firestore().doc(`serviceJobs/${this.state.serviceID}`);
                    updateRef.set(_objData, { merge: true }).then((docRef) => {
                        //console.log(docRef);
                        this.isLoading = false;
                        toast.notify('Part deleted successfully', {
                            duration: 2000
                        });
                        this.saveTotalServiceCost();
                        this.handleStatusReScheduled();
                    }).catch((error) => {
                        console.error("Error updating contact_details: ", error);
                    });
                }

            }

        });

    }
    //#endregion

    //#region Vehicle CHANGES
    handleVehicleClose = (_data) => {
        if (!_.isEmpty(_data)) {
            this.saveServiceField('vehicle', _data);
        }
        this.setState({
            vehicleModal: {
                show: false,
                title: 'vehicle',
                clsActive: 'overlay-modal active',
                vehicle: null,
                mandatoryFields: null
            },
        });
    }

    handleMandatoryVehicleOpen = (req, _mandatory) => {
        let _mandatoryFields = Object.assign([], this.getMandatoryFieldsByType('vehicle'));

        if (!_.isEmpty(this.mandatoryVehicleFields))
            _mandatoryFields = _.union(_mandatoryFields, this.mandatoryVehicleFields);

        if (!_.isEmpty(_mandatory))
            _mandatoryFields = _.union(_mandatoryFields, _mandatory);

        this.setState({
            vehicleModal: {
                show: true,
                title: 'vehicle',
                clsActive: 'overlay-modal active',
                vehicle: !_.isEmpty(req) ? req : null,
                mandatoryFields: _mandatoryFields,
            },
        });
    }
    //#endregion

    //#region CONTACT CHANGES

    handleMandatoryContactOpen = (title) => {
        let _manFields = this.getMandatoryFieldsByType('contact');
        //console.log('handleMandatoryContactOpen', _manFields)
        this.setState({
            contactModal: {
                show: true,
                showSearch: true,
                title: title,
                clsActive: 'overlay-modal active',
                mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
            },
        });
    }

    handleContactOpen = (title, _manFields) => {
        //console.log('handleContactOpen', _manFields)
        setTimeout(() => {
            this.setState({
                contactModal: {
                    show: true,
                    showSearch: true,
                    title: title,
                    clsActive: 'overlay-modal active',
                    mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
                },
            });
        }, 200);

    }

    handleContactClose = (ID) => {
        if (ID) {
            const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {
                    const objcontactData = Object.assign({}, doc.data());
                    const contact = Object.assign({}, objContact);
                    const _contact = Object.assign({}, contactBasicDetailsVM);
                    for (let [key, value] of Object.entries(objcontactData)) {
                        contact[key] = value;
                        if (_contact.hasOwnProperty(key))
                            _contact[key] = value;
                    }
                    contact.documentID = doc.id;
                    if (_.isNil(objcontactData?.optinSMS)) contact.optinSMS = null;
                    if (_.isNil(objcontactData?.marketingOptinEmail)) contact.marketingOptinEmail = null;
                    if (_.isNil(objcontactData?.marketingOptinSMS)) contact.marketingOptinSMS = null;
                    let state = Object.assign({}, this.state.fields);
                    state['isContact'] = true;
                    state['contact'] = _contact;
                    state.contactID = doc.id;

                    state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                    state.modifiedDate = window.firebase.firestore.Timestamp.now();
                    state.modifiedFrom = 'web';
                    const objData = Object.assign({}, state);
                    //Remove empty value from object
                    for (var propName in objData) {
                        if (
                            objData[propName] === null ||
                            objData[propName] === undefined ||
                            objData[propName] === '' ||
                            objData[propName] === [] ||
                            (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))
                        ) {
                            delete objData[propName];
                        }
                    }
                    this.setState({ contact: contact, fields: state });
                    const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);

                    updateRef.update({
                        isContact: state.isContact,
                        contact: state.contact,
                        modifiedBy: state.modifiedBy,
                        modifiedDate: state.modifiedDate,
                        modifiedFrom: 'web'
                    }).then((docRef) => {
                        const { isContact, status, displayID, documentID, ownerResponse, contactID } = state;
                        this.props.updatenavbar({
                            id: this.state.serviceID,
                            status: status && status === serviceStatus.OWNERRESPONDED ? ownerResponse : status,
                            name: (isContact && !_.isEmpty(contact)) ? CommonHelper.displayContact([], contact, '--') : (displayID ? displayID : documentID),
                            isNew: null,
                            params: null,
                            module: 'service'
                        }, 'service');

                        this.setState({ serviceInfo: { new: false, lead: false } })

                        this.unsubscribeContact = this.refContact
                            .doc(contactID)
                            .onSnapshot(this.onContactCollectionUpdate);

                        this.unsubscribeServiceHistory = this.ref
                            .where('contact.documentID', '==', contactID)
                            .where('isDeleted', '==', false)
                            .onSnapshot(this.onServiceJobCollectionUpdate);

                    }).catch((error) => {
                        console.error("Error updating enquiries: ", error);
                    });

                } else {
                    console.error("No such document!");
                }
            });
        }

        this.setState({
            contactModal:
            {
                show: false,
                showSearch: false,
                title: '',
                clsActive: ''
            }
        });
    }
    //#endregion

    //#region CHANGE EVENT SERVICE

    handleOnChange = (e) => {
        const { name, value } = e.target;
        this.saveServiceField(name, value);
    }

    handleAppointmentDateChange = (value, name) => {
        let _selectedDate = null;
        const { timeOfDay, loanAppointmentID, slot, isLoan } = this.state.fields

        let _time = timeOfDay ? timeOfDay + ':00' : moment().format('HH:mm:ss');
        //if (value) _selectedDate = window.firebase.firestore.Timestamp.fromDate(moment(moment(value).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        if (value) _selectedDate = moment(value).format('YYYY-MM-DD') + ' ' + _time;

        let _msg;
        if (!_.isEmpty(loanAppointmentID) && !isLoan)
            _msg = 'Doing so will also remove time slot and loan vehicle appointment.'
        else if (!_.isEmpty(slot))
            _msg = 'Doing so will also remove time slot'


        if (!_.isEmpty(_msg)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, _msg),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.value) {
                    this.saveServiceField(name, _selectedDate);
                    this.handleLoadsSlotByDate(value);
                    this.deleteAppointment();
                }
            });
        }
        else {
            this.saveServiceField(name, _selectedDate);
            this.handleLoadsSlotByDate(value);
        }

        setTimeout(() => this.errorChange(name), 500);
    };

    handleTimeSlotChange = (date, name) => {
        this.saveServiceField(name, date);
        this.deleteAppointment();
        setTimeout(() => this.errorChange(name), 500);
    }

    handleLoadsSlotByDate = (value) => {
        if (value) {
            const { currentSlots } = this.state;
            const selectedDate = moment(value).format('YYYY-MM-DD');
            const startDate = moment(selectedDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
            const endDate = moment(selectedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
            //console.log('selectedDate', selectedDate, startDate, endDate)
            this.unsubscribeSerSlots && this.unsubscribeSerSlots();
            this.unsubscribeSerSlots = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs')
                .where('clientID', '==', this.props.dealersettings.client.id)
                .where('isDeleted', '==', false)
                .where('appointmentDate', '>=', startDate)
                .where('appointmentDate', '<=', endDate)
                .onSnapshot(querySnapshot => {
                    let _services = [];
                    querySnapshot.forEach((rec) => {
                        _services.push({ ...rec.data(), 'id': rec.id })
                    })
                    let _slots = [];
                    currentSlots && currentSlots.forEach((_slot) => {
                        let _selectedData = !_.isEmpty(_services) && _services.filter(m => !_.isEmpty(m.slot) && m.slot.value === _slot.value);
                        _slot.count = !_.isEmpty(_selectedData) ? _selectedData.length : 0;
                        _slots.push(_slot);
                    })
                    this.setState({ currentSlots: _slots });
                    //console.log('_services', selectedDate, _services, _slots)
                })
        }
        else {
            this.setState({ currentSlots: [] });
            this.unsubscribeSerSlots && this.unsubscribeSerSlots();
        }
    }

    availableStockCount = (_timeOfDay) => {
        const { appointmentDate } = this.state.fields
        const { serviceLoanStocks } = this.state;
        let _selectedStart = window.firebase.firestore.Timestamp.fromDate(moment(moment(appointmentDate).format('YYYY-MM-DD') + ' ' + _timeOfDay + ':00')._d);
        let _selectedEnd = window.firebase.firestore.Timestamp.fromDate(moment(moment.unix(_selectedStart.seconds).add('hour', 1).add('seconds', -1))._d);

        let _filter = serviceLoanStocks && serviceLoanStocks.filter(m => !m.loanAppointments.some(a => {

            if (_.isEmpty(a.startDate) || _.isEmpty(a.expectedReturn))
                return true;

            let A = a.startDate.seconds
            let B = a.expectedReturn.seconds
            let C = _selectedStart.seconds;
            let D = _selectedEnd.seconds;

            var isOverlapping = ((A == null || A <= D) &&
                (B == null || C <= B) &&
                (A == null || B == null || A <= B) &&
                (C <= D));

            return isOverlapping;
        }
        ))

        //console.log('availableStockCount', _timeOfDay, _selectedStart, _filter)

        return !_.isEmpty(_filter) ? _filter.length : 0;

    }

    getSlotTimes = (_from, _to, _halfanHrSlot) => {
        //console.log('getSlotTimes', _from, '==>', _to, '==>', _halfanHrSlot)

        if (_from && _to) {
            let startTime = parseFloat(_from.replace(':', '.'));
            let endTime = parseFloat(_to.replace(':', '.'));
            if (_halfanHrSlot)
                return _.orderBy(CommonHelper.getSlotTimeValues().filter(a => a.code >= startTime && a.code <= endTime), ['code'], ['asc'])
            else {
                if (parseInt(_from.split(':')[1]) > 0)
                    return _.orderBy(CommonHelper.getSlotTimeValues().filter(a => a.code >= startTime && a.code <= endTime && a.half), ['code'], ['asc'])
                else
                    return _.orderBy(CommonHelper.getSlotTimeValues().filter(a => a.code >= startTime && a.code <= endTime && a.full), ['code'], ['asc'])

            }
        }
        // else {
        //     return CommonHelper.getSlotTimeValues().filter(a => a.code >= 9 && a.code < 15)
        // }
    }

    handleSelectSlot = (_slot) => {
        const { slot } = this.state.fields;

        if (!_.isEmpty(slot)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, `You want to select this slot.`),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.value) {
                    this.handleSaveSlot(_slot);
                }
            });
        }
        else {
            this.handleSaveSlot(_slot);
        }
    }

    handleSaveSlot = (_slot) => {
        this.saveServiceField('slot', _slot);
        const { appointmentDate } = this.state.fields;

        if (appointmentDate)
            this.handleLoadsSlotByDate(moment(appointmentDate).format('YYYY-MM-DD'));

        let _slotTimes = this.getSlotTimes(_slot.from, _slot.to, _slot.halfanHrSlot);
        this.setState({ slotTimes: _slotTimes });
        this.deleteAppointment();
        setTimeout(() => this.errorChange('slot'), 500);
    }

    handleOnDateChange = (value, name) => {
        let _selectedDate = null;
        if (value) _selectedDate = window.firebase.firestore.Timestamp.fromDate(moment(moment(value).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        this.saveServiceField(name, _selectedDate);

        setTimeout(() => this.errorChange(name), 500);
    };


    handleTimeChange = (date, name) => {

        this.saveServiceField(name, window.firebase.firestore.Timestamp.fromDate(moment(date)._d));
        setTimeout(() => this.errorChange(name), 500);
    }

    handleOnRONumChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.toUpperCase();
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    saveServiceROField = async (name, val) => {
        if (val) {
            const { dealersettings } = this.props;
            const { serviceID } = this.state;
            if (await CommonHelper.checkServiceROExist(dealersettings, dealersettings.client.id, serviceID, val) === true) {
                Swal.fire(CommonHelper.showLocale(this.props, 'RO Number already exists'), '', 'info');

                let errors = Object.assign({}, this.state.errors);
                errors['roNumber'] = 'input_error';
                this.setState({ errors: errors });

                const _input = document.getElementById('roNumber')
                if (_input) _input.focus();

                return false;
            }
            else {
                this.saveServiceField(name, val);
            }
        }
        else {
            this.saveServiceField(name, val);
        }

    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                if (key === 'status') {
                    if (!this.state.serviceInfo.lead)
                        errors[key] = errorClass;
                }
                else
                    errors[key] = errorClass;
            }
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    };

    handleTemmateChange = selectedOptions => {
        let _usersList = [];

        !_.isEmpty(selectedOptions) && selectedOptions.forEach((data, index) => {
            if (data.type === 'team') {
                _usersList = _.union(_usersList, Object.assign([], data.user_ids));
            }
            else
                _usersList = _.union(_usersList, [data.value]);
        });

        this.saveServiceField('teammates', _usersList);
    };

    handlePackagesChange = selectedOptions => {
        const { dealersettings } = this.props;
        const { servicePackages, serviceID } = this.state;
        let _usersList = [];
        const batch = firestoreDB(dealersettings).firestore().batch();

        !_.isEmpty(selectedOptions) && selectedOptions.forEach((info, index) => {
            if (_.isEmpty(servicePackages) || !_.find(servicePackages, { value: info.value })) {
                let objData = servicePackagesData ? servicePackagesData.filter(i => i.value === info.value)[0] : null;
                if (objData) {
                    objData.oKToDo = true;
                    objData.documentID = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc().id;
                    objData.addedBy = dealersettings ? dealersettings.id : '';
                    objData.addedDate = window.firebase.firestore.Timestamp.now();
                    objData.addedFrom = 'web';
                    objData.serviceID = serviceID;

                    objData.clientID = dealersettings?.client?.id ? dealersettings?.client?.id : null;
                    objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

                    _usersList.push(objData);
                }
            }
        });

        !_.isEmpty(_usersList) && _usersList.forEach(data => {
            batch.set(firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/servicePackages/${data.documentID}`), data, { merge: true });
        })

        const selectedValues = selectedOptions ? Array.prototype.map.call(selectedOptions, function (item) { return item.value; }) : [];
        _.differenceWith(servicePackages.map(v => _.pick(v, ['value']).value), selectedValues, _.isEqual).forEach(_value => {
            let documentID = _.find(servicePackages, { 'value': _value }) ? _.find(servicePackages, { 'value': _value }).documentID : ''
            if (documentID)
                batch.delete(firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/servicePackages/${documentID}`))

        })
        //  if (_.isEmpty(selectedOptions) && !_.isEmpty(servicePackages)) {
        //      servicePackages && servicePackages.forEach(data => {
        //          batch.delete(window.firebase.firestore().doc(`serviceJobs/${serviceID}/servicePackages/${data.documentID}`))
        //      })

        //  }

        batch.commit().then(snapshot => {
            this.handleStatusReScheduled();
        }).catch((error) => {
            console.error("Error updating document: ", error);
        });


    };

    // handleAddLoanApp = () => {
    //     const { dealersettings } = this.props;
    //     let objData = {};
    //     objData.documentID = window.firebase.firestore().collection('loanAppointments').doc().id;
    //     objData.addedBy = dealersettings ? dealersettings.id : '';
    //     objData.clientID = dealersettings ? dealersettings.client.id : '';
    //     objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
    //     objData.stockID = 'vAz9Xudgc1dy33QaMQYj';
    //     objData.addedDate = window.firebase.firestore.Timestamp.now();
    //     objData.startDate = window.firebase.firestore.Timestamp.now();
    //     objData.expectedReturn = window.firebase.firestore.Timestamp.fromDate(moment(moment().add('hour', 1))._d);

    //     firestoreDB(dealersettings).firestore().doc(`loanAppointments/${objData.documentID}`).set(objData, { merge: true })
    // }

    handleStatusReScheduled = () => {
        const { status, ownerResponse, documentID, technicianStatus } = this.state.fields;
        const { dealersettings } = this.props;
        const _jobStatus = status && status === serviceStatus.OWNERRESPONDED ? ownerResponse : status;
        const isOwnerResponsed = (_jobStatus === serviceStatus.OWNERAPPROVED || _jobStatus === serviceStatus.OWNERDECLINED || _jobStatus === serviceStatus.WORKINPROGRESS || _jobStatus === serviceStatus.COMPLETED) ? true : false
        const isStatusSold = CommonHelper.isServiceStatusComplete(serviceAllStatus, _jobStatus)
        const isStatusOnProgress = CommonHelper.isServiceStatusOnProgress(serviceAllStatus, _jobStatus)
        if (isOwnerResponsed || isStatusSold || isStatusOnProgress) {
            let _objData = {
                status: serviceStatus.ADVISORREQUESTED,
                ownerResponse: null,
                responseDate: null,
                startDate: null,
                completeDate: null,
                jobAllocated: false,
                jobConfirmed: false,
                partsConfirmed: false,
                workshopConfirmed: false,
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }

            if (technicianStatus !== serviceStatus.WORKINPROGRESS)
                _objData.technicianStatus = serviceStatus.PENDING;

            _objData = this.handleMoveStageForStatusChange(_objData.status, _objData);

            const updateJobRef = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc(documentID);
            updateJobRef.update(_objData).then((docRef) => {

            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }

    }

    handleSelectSave = (e, data) => {
        this.saveServiceField(data.name, (e ? e.value : null));
        setTimeout(() => this.errorChange(data.name), 500);
    };

    handleMultiSelectChange = (selectedOptions, data) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        this.saveServiceField(data.name, value);
    }

    handleCheckChange = (e) => {

        const { name, checked } = e.target;

        if (checked && this.isValidCheckConfirmed(name) === false)
            return;

        this.saveServiceField(name, checked);
        setTimeout(() => this.errorChange(name), 500);
    }

    isValidCheckConfirmed = (name) => {
        const { technician, partsInterpretor, workshopManager } = this.state.fields;
        let errors = {}; let errorClass = 'input_error';
        if (name === 'jobAllocated') {
            if (technician) return true; else { errors['technician'] = errorClass; this.setState({ errors: errors, showServiceDetails: true, originFromServiceDetails: 'jobAllocated' }); return false; }
        }
        else if (name === 'partsConfirmed') {
            if (partsInterpretor) return true; else { errors['partsInterpretor'] = errorClass; this.setState({ errors: errors, showServiceDetails: true, originFromServiceDetails: 'partsConfirmed' }); return false; }
        }
        else if (name === 'workshopConfirmed') {
            if (workshopManager) return true; else { errors['workshopManager'] = errorClass; this.setState({ errors: errors, showServiceDetails: true, originFromServiceDetails: 'workshopConfirmed' }); return false; }
        }
        else {
            return false;
        }
    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    cancelServiceField = (name, val) => {
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleLeftActiveKey = (index) => {
        this.setState({ activeLeftKey: index });
    }
    handleCntActiveKey = (index) => {
        this.setState({ activeCntKey: index });
    }
    handleVehActiveKey = (index) => {
        this.setState({ activeVehKey: index });
    }

    isValidateAppointment = () => {

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let _mandtry = ['appointmentDate'];
        let errorClass = 'input_error';

        _mandtry.forEach((key) => {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        })

        this.setState({ errors: errors });

        return formIsValid;
    }

    checkIsJobHasOkToDo = () => {

        const { recommendations } = this.state;

        let _recommRequiredLists = Object.assign([], recommendations.filter(item => item.choice === recommendationChoice.Required))
        let isOkToDo = false;

        _recommRequiredLists && _recommRequiredLists.forEach(_recommData => {
            const _recommendation = Object.assign({}, _recommData);
            let recommendationDetail = Object.assign({}, _recommendation.detail);

            if (recommendationDetail.oKToDo === true) {
                isOkToDo = true;
                return;
            }
        });

        return isOkToDo;
    }

    setTotalServiceCost = () => {
        const { recommendations, fields, servicePackages } = this.state;
        let _fullCost = CommonHelper.getTotalServiceCost(fields, recommendations, servicePackages);
        this.setState({ fullServiceCost: _fullCost });

    }

    showTotalServiceCost = () => {
        const { recommendations, fields, servicePackages } = this.state;
        let _fullCost = CommonHelper.getTotalServiceCost(fields, recommendations, servicePackages);
        return _fullCost ? _fullCost.total : 0;

    }

    showTotalDeclinedCost = () => {
        const { recommendations, fields, servicePackages } = this.state;
        let _fullCost = CommonHelper.getTotalDeclinedCost(fields, recommendations);
        return _fullCost ? _fullCost.total : 0;

    }

    showFullServiceCost = () => {
        const { recommendations, fields, servicePackages } = this.state;
        return CommonHelper.getTotalServiceCost(fields, recommendations, servicePackages);
    }

    //#endregion

    //#region  SAVE Service INFO

    saveServiceField = (name, val, noNotify) => {

        if (!val && this.mandatoryFields && this.mandatoryFields.indexOf(name) >= 0) {
            this.errorChange(name);
        }
        else {
            let objData = {};
            let state = Object.assign({}, this.state.fields);
            if (name !== 'isFav') {
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();
            }

            if (name === 'appointmentDate') {
                state['slot'] = null;
                objData['slot'] = null;
            }

            if (name === 'timeOfDay') {
                let _selectedDate = moment(state.appointmentDate).format('YYYY-MM-DD') + ' ' + val + ':00';
                state['appointmentDate'] = _selectedDate ? _selectedDate : state.appointmentDate;
                objData['appointmentDate'] = state.appointmentDate;
            }

            if (name === 'slot' || name === 'appointmentDate') {
                state['timeOfDay'] = null;
                objData['timeOfDay'] = null;
            }

            if (name === 'vehicle') {
                state['isVehicle'] = true;
                objData['isVehicle'] = true;
            }

            if (name === 'marks' && val) {
                state['isInspection'] = true;
                objData['isInspection'] = true;
            }

            if (name === 'slot' && val) {
                let slot = Object.assign({}, objSlot)
                for (let [key, value] of Object.entries(val)) {
                    slot[key] = value;
                }
                val = slot;
            }

            if (name === 'status') {
                objData = this.handleMoveStageForStatusChange(val, objData);
            }

            state[name] = val;
            objData[name] = val;
            objData.modifiedBy = state.modifiedBy;
            objData.modifiedDate = state.modifiedDate;
            objData.modifiedFrom = 'web';

            this.setState({ fields: state });

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
            updateRef.set(objData, { merge: true }).then((docRef) => {
                if (!noNotify)
                    toast.notify('Service updated successfully.', {
                        duration: 2000,
                    });

                if (name === 'serviceType') {
                    this.saveTotalServiceCost();
                }


            }).catch(error => {
                console.error('Error updating enquiries: ', error);
            });
        }
    };

    handleDeleteService = (e, _type) => {
        e.preventDefault();
        const { clientID, dailyroID } = this.state.fields;

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to ${_type ? _type : 'delete'} the service.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                state.isDeleted = true;
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
                updateRef.update({
                    isDeleted: state.isDeleted,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    modifiedFrom: 'web'
                }).then((docRef) => {
                    var objRecord = {
                        id: this.state.serviceID,
                        clientid: clientID
                    }

                    toast.notify(`Service ${_type ? 'cancelled' : 'deleted'} successfully.`, {
                        duration: 2000,
                    });
                    this.setState({ fields: state });

                    const { dealersettings, updateDealerSettings } = this.props;
                    CommonHelper.stopAllWorkLogTimers(dealersettings, this.state.serviceID);
                    if (updateDealerSettings && dealersettings.workingServiceID === this.state.serviceID)
                        updateDealerSettings('workingServiceID', null);

                    if (_.isEmpty(_type)) {
                        let logNotes = 'Service deleted';
                        if (!_.isEmpty(state) && state.displayID)
                            logNotes = 'Service: ' + state.displayID + ' deleted';

                        let _objLogData = {
                            notes: logNotes,
                            type: 'serviceJobs',
                            subType: 'delete',
                            recordId: this.state.serviceID,
                        }
                        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, this.props.dealersettings);
                        CommonHelper.saveAuditLog(_objLogData)
                    }

                    if (dailyroID)
                        firestoreDB(dealersettings).firestore().doc(`serviceDailyRO/${dailyroID}`).set({ status: 0, serviceID: null }, { merge: true });

                    this.props.handleRouteRemove(objRecord, 'service');

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });

            }

        });

    }

    saveTotalServiceCost = () => {
        const { fields } = this.state;
        let _totalRepairCost = this.showTotalServiceCost();
        let _totalDeclinedCost = this.showTotalDeclinedCost();
        let _totalCost = this.showFullServiceCost();
        if (fields && fields.documentID && (fields.totalServiceCost !== _totalRepairCost || fields.totalDeclinedCost !== _totalDeclinedCost)) {

            let _objReq = {
                totalServiceCost: _totalRepairCost,
                totalDeclinedCost: _totalDeclinedCost,
                totalCost: _totalCost,
                modifiedFrom: 'web'
            }
            firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(fields.documentID).set(_objReq, { merge: true })
        }
    }

    scheduleJob = async (_status) => {
        if (this.isValidForStatusUpdate(_status ? _status : serviceStatus.SCHEDULED)) {
            const { dealersettings } = this.props;
            let state = Object.assign({});

            const _allPipelines = dealersettings?.client?.services?.pipelines;
            const selectOptions = !_.isEmpty(_allPipelines) && _allPipelines.length > 1 ? _allPipelines.reduce((options, { name, value }) => {
                options[value] = name;
                return options;
            }, {}) : null;

            let selectedPipeline;
            if (!_.isEmpty(selectOptions) && _status === serviceStatus.SCHEDULED) {
                const { value: _selectedPipeline } = await Swal.fire({
                    title: 'Select Pipeline to Continue',
                    input: 'select',
                    inputOptions: selectOptions,
                    inputPlaceholder: 'select pipeline',
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    inputValidator: (value) => {
                        if (!value) {
                            return 'You need to select an pipeline';
                        }
                    }
                });
                selectedPipeline = _selectedPipeline
            }

            //console.log('selectedPipeline', selectedPipeline)

            state.status = _status ? _status : serviceStatus.SCHEDULED;
            state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
            state.modifiedFrom = 'web';

            if (dealersettings?.client?.services) {
                const setttings = dealersettings.client.services;
                let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.default === true)[0]);
                if (_.isEmpty(_selPipeline))
                    _selPipeline = Object.assign({}, setttings.pipelines[0]);

                if (selectedPipeline) _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.value === selectedPipeline)[0]);
                else if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultservicePipeline)) {
                    _selPipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultservicePipeline);
                }
                let statusSettings = _selPipeline && _selPipeline.statusSettings;

                let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === state.status)[0];
                if (!_.isEmpty(data) && data.pipeline && data.stage) {
                    state.pipeline = data.pipeline;
                    state.stage = data.stage;
                }
                else {
                    let _stages = Array.prototype.map.call(_selPipeline.stages, function (item) { return item.value; }).join(",").split(',');
                    state.pipeline = _selPipeline.value;
                    state.stage = _stages[0];
                }

                state.stageDate = window.firebase.firestore.Timestamp.now();
                state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
            }

            let _statusHistory = Object.assign([], this.state.fields.statusHistory);
            _statusHistory.push({
                date: window.firebase.firestore.Timestamp.now(),
                status: state.status,
                user: localStorage.uid
            });
            state.statusHistory = Object.assign([], _statusHistory);



            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, 'You want to schedule the appointment.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.value) {

                    const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
                    updateRef.update(state).then((docRef) => {
                        toast.notify(`Service status updated sucessfully.`, { duration: 2000 });

                    }).catch((error) => {
                        console.error("Error updating enquiries: ", error);
                    });
                }
            });
        }
    }

    checkInJob = () => {
        if (this.isValidForStatusUpdate(serviceStatus.CHECKIN)) {
            const { dealersettings } = this.props;
            const { pipeline } = this.state.fields;
            let state = Object.assign({});

            state.status = serviceStatus.CHECKIN;
            //state.technicianStatus = serviceStatus.PENDING;
            state.checkInBy = dealersettings ? dealersettings.id : '';
            state.checkInDate = window.firebase.firestore.Timestamp.now();
            state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
            state.modifiedFrom = 'web';

            if (dealersettings && dealersettings.client && dealersettings.client.services) {
                const setttings = dealersettings.client.services;

                let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.default === true)[0]);
                if (_.isEmpty(_selPipeline))
                    _selPipeline = Object.assign({}, setttings.pipelines[0]);
                if (pipeline) _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.value === pipeline)[0]);
                else if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultservicePipeline)) {
                    _selPipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultservicePipeline);
                }
                let statusSettings = _selPipeline && _selPipeline.statusSettings;

                let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === state.status)[0];
                if (!_.isEmpty(data) && data.pipeline && data.stage) {
                    state.pipeline = data.pipeline;
                    state.stage = data.stage;
                    state.stageDate = window.firebase.firestore.Timestamp.now();
                }
                else {
                    let _stages = Array.prototype.map.call(_selPipeline.stages, function (item) { return item.value; }).join(",").split(',');
                    state.pipeline = _selPipeline.value;
                    state.stage = _stages[0];
                    state.stageDate = window.firebase.firestore.Timestamp.now();
                }

                let _stageHistory = Object.assign([], this.state.fields.stageHistory);
                let _stageData = CommonHelper.handleStageHistory(_stageHistory, state.pipeline, state.stage);
                if (!_.isEmpty(_stageData)) {
                    _stageHistory.push(_stageData);
                    state.stageHistory = Object.assign([], _stageHistory);
                    state.stageDate = window.firebase.firestore.Timestamp.now();
                }

                let _statusHistory = Object.assign([], this.state.fields.statusHistory);
                _statusHistory.push({
                    date: window.firebase.firestore.Timestamp.now(),
                    status: state.status,
                    user: localStorage.uid
                });
                state.statusHistory = Object.assign([], _statusHistory);
            }




            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
            updateRef.update(state).then((docRef) => {
                toast.notify(`service status updated sucessfully.`, { duration: 2000 });

            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }
    }

    cancelJob = () => {
        const { dealersettings } = this.props;
        let state = Object.assign({});

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'You want to cancel the appointment.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {

                state.status = serviceStatus.CANCELLED;
                state.modifiedBy = dealersettings ? dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();
                state.modifiedFrom = 'web';

                state.pipeline = null;
                state.stage = null;
                state.slot = null;

                let _statusHistory = Object.assign([], this.state.fields.statusHistory);
                _statusHistory.push({
                    date: window.firebase.firestore.Timestamp.now(),
                    status: state.status,
                    user: localStorage.uid
                });
                state.statusHistory = Object.assign([], _statusHistory);

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
                updateRef.update(state).then((docRef) => {
                    toast.notify(`service status updated sucessfully.`, { duration: 2000 });

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
            }
        });

    }

    startJob = () => {
        const { dealersettings } = this.props;
        let state = Object.assign({});

        state.status = serviceStatus.WORKINPROGRESS;
        state.startDate = window.firebase.firestore.Timestamp.now();
        state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        state.modifiedDate = window.firebase.firestore.Timestamp.now();
        state.modifiedFrom = 'web';

        if (this.isValidForStatusUpdate(state.status)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, 'You want to start the job.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.value) {
                    state = this.handleMoveStageForStatusChange(state.status, state);

                    const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
                    updateRef.update(state).then((docRef) => {
                        toast.notify(`service status updated sucessfully.`, { duration: 2000 });

                    }).catch((error) => {
                        console.error("Error updating enquiries: ", error);
                    });
                }

            });


        }
    }

    completeJob = () => {
        const { dealersettings, updateDealerSettings } = this.props;
        let state = Object.assign({});

        state.status = serviceStatus.COMPLETED;
        state.technicianStatus = serviceStatus.COMPLETED;
        state.completeDate = window.firebase.firestore.Timestamp.now();
        state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        state.modifiedDate = window.firebase.firestore.Timestamp.now();
        state.modifiedFrom = 'web';

        if (this.isValidForStatusUpdate(state.status)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, 'You want to complete the job.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.value) {
                    state = this.handleMoveStageForStatusChange(state.status, state);

                    const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
                    updateRef.update(state).then((docRef) => {
                        CommonHelper.saveLastServiceOnCompleted(this.state.fields, this.props);
                        CommonHelper.stopAllWorkLogTimers(dealersettings, this.state.serviceID);
                        CommonHelper.updateRecommendationStatus(dealersettings, this.state.recommendations, this.state.serviceID)
                        if (updateDealerSettings && dealersettings.workingServiceID === this.state.serviceID)
                            updateDealerSettings('workingServiceID', null);

                        this.handleSaveOwnedVehicle();

                        toast.notify(`service status updated sucessfully.`, { duration: 2000 });

                    }).catch((error) => {
                        console.error("Error updating enquiries: ", error);
                    });
                }

            });

        }
    }

    sendJobRequest = async (_status) => {
        const { isChangeRecomm, serviceID } = this.state;

        // if (isChangeRecomm) {
        //     Swal.fire('Please save the changes..', '', 'info')

        //     let _key = 'recomm_' + isChangeRecomm;
        //     if (_key && document.getElementById(_key)) {
        //         document.getElementById(_key).scrollIntoView({
        //             behavior: "smooth",
        //             block: "center"
        //         })
        //     }
        //     return false;
        // }

        let state = Object.assign({}, this.state.fields);

        let _objData = {};
        let _confirmMsg = ''
        _objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        _objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        _objData.modifiedFrom = 'web';
        if (_status) {
            _objData.status = _status;
            if (state.status === serviceStatus.CHECKIN)
                _objData.technicianStatus = serviceStatus.PENDING;

            if (_status === serviceStatus.TECHNICIANREQUESTED) {
                _confirmMsg = 'You want to send to technician.'
            }
            else if (_status === serviceStatus.PARTSREQUESTED) {
                _confirmMsg = 'You want to send to parts.'
            }
            else if (_status === serviceStatus.WORKSHOPREQUESTED) {
                _confirmMsg = 'You want to send to workshop.'
            }
            else if (_status === serviceStatus.ADVISORREQUESTED) {
                _confirmMsg = 'You want to send to advisor.'
            }
        }
        else {
            if (state.status === serviceStatus.CHECKIN) {
                _objData.technicianStatus = serviceStatus.PENDING;
                _objData.status = serviceStatus.TECHNICIANREQUESTED;
                _confirmMsg = 'You want to send to technician.'
            }
            else if (state.status === serviceStatus.TECHNICIANREQUESTED) {
                _objData.status = serviceStatus.PARTSREQUESTED;
                _confirmMsg = 'You want to send to parts.'
            }
            else if (state.status === serviceStatus.PARTSREQUESTED) {
                _objData.status = serviceStatus.WORKSHOPREQUESTED;
                _confirmMsg = 'You want to send to workshop.'
            }
            else if (state.status === serviceStatus.WORKSHOPREQUESTED) {
                _objData.status = serviceStatus.ADVISORREQUESTED;
                _confirmMsg = 'You want to send to advisor.'
            }
        }


        if (this.isValidForStatusUpdate(_objData.status)) {

            // Swal.fire({
            //     title: 'Are you sure?',
            //     text: _confirmMsg,
            //     icon: 'info',
            //     showCancelButton: true,
            //     confirmButtonText: 'Yes',
            //     cancelButtonText: 'No',
            //     //allowOutsideClick: false,
            //     //allowEscapeKey: false
            // }).then(result => {
            //     if (result.value) {
            _objData = this.handleMoveStageForStatusChange(_objData.status, _objData);

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(serviceID);
            updateRef.update(_objData).then((docRef) => {
                this.forceUpdate();
                toast.notify(`Service status has been updated.`, { duration: 2000 });

            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
            //     }

            // });

        }

    }

    handleSendToCustomerApproval = () => {
        if (this.handleValidRecommFields(true) === false) {
            //Swal.fire('Please fill all the required recommendation fields.', '', 'info')
            return false;
        }

        this.setState({ showCustomerApproval: true });
    }

    sendToCustomerApproval = (objData) => {
        //console.log('sendToCustomerApproval', objData);
        if (!_.isEmpty(objData)) {
            let _objData = {
                status: serviceStatus.OWNERREQUESTED,
                modifiedBy: this.props.dealersettings ? this.props.dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                quoteSentDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }
            _objData = this.handleMoveStageForStatusChange(_objData.status, _objData);
            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
            updateRef.update(_objData).then((docRef) => {
                toast.notify(`Service has been sent to owner for approval.`, { duration: 2000 });

                let _postObject = {
                    "service": this.state.fields,
                    "sendSMS": objData.sendSMS ? true : false,
                    "phone": objData.phone ? objData.phone : null
                }

                if (!_.isEmpty(objData.emails)) {
                    let _data = Array.prototype.map.call(objData.emails.filter(item => !_.isEmpty(item.value)), function (item) { return item.value ? item.value : ''; }).join(",")
                    _postObject.ccEmails = _data;
                }

                //console.log('_postObject', _postObject);
                const sendToCustomerFunction = window.firebase.functions().httpsCallable('serviceapp-sendToCustomer');
                sendToCustomerFunction(_postObject).then((response) => {
                    //console.log('serviceapp-sendToCustomerFunction', response);
                });

            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });

        }


        this.setState({ showCustomerApproval: false });
    }

    handleConfirmQuotation = () => {
        if (this.handleValidRecommFields(true) === false) {
            //Swal.fire('Please fill all the required recommendation fields.', '', 'info')
            return false;
        }
        this.setState({ showConfrimQuotation: true });
    }

    quotationApproved = (objData) => {

        if (!_.isEmpty(objData)) {
            let _objData = {
                status: serviceStatus.OWNERRESPONDED,
                responseDate: window.firebase.firestore.Timestamp.now(),
                modifiedBy: this.props.dealersettings ? this.props.dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }

            const { servicePackages } = this.state;
            if (this.checkIsJobHasOkToDo() === true || !_.isEmpty(servicePackages)) {
                _objData.ownerResponse = serviceStatus.OWNERAPPROVED;
                _objData.jobConfirmed = true;
            }
            else {
                _objData.ownerResponse = serviceStatus.OWNERDECLINED;
            }

            _objData = this.handleMoveStageForStatusChange(_objData.ownerResponse, _objData);

            if (objData.spokeTo) _objData.spokeTo = objData.spokeTo;

            if (objData.clientContactDate) _objData.clientContactDate = objData.clientContactDate;

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
            updateRef.update(_objData).then((docRef) => {

                //Send Approval Mail To Customer
                if (objData.sendMail) {

                }

                if (_objData.jobConfirmed) {
                    toast.notify(`Job has been confirmed.`, { duration: 2000 });
                }
                else {
                    toast.notify(`Job has been declined.`, { duration: 2000 });
                }

                let _postObject = {
                    "service": this.state.fields,
                    "sendEmail": objData.sendMail ? true : false,
                    "confirmed": true
                }

                if (!_.isEmpty(objData.emails)) {
                    let _data = Array.prototype.map.call(objData.emails.filter(item => !_.isEmpty(item.value)), function (item) { return item.value ? item.value : ''; }).join(",")
                    _postObject.ccEmails = _data;
                }

                //console.log('_postObject', _postObject);
                const sendToCustomerFunction = window.firebase.functions().httpsCallable('serviceapp-sendToCustomer');
                sendToCustomerFunction(_postObject).then((response) => {
                    //console.log('serviceapp-sendToCustomerFunction', response);
                });


            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });

            if (!_.isEmpty(objData.emails)) {
                let _data = Array.prototype.map.call(objData.emails.filter(item => !_.isEmpty(item.value)), function (item) { return item.value ? item.value : ''; }).join(",")
                //console.log('quotationApproved', _data)
            }

        }

        this.setState({ showConfrimQuotation: false });
    }

    handlePipelineClose = async (data, origin) => {
        if (!_.isEmpty(data)) {

            if (!_.isEmpty(origin) || this.isValidForStageUpdate(data.pipeline, data.stage, 'PipelineClose')) {
                let state = Object.assign({}, this.state.fields);

                const { dealersettings } = this.props;

                let setttings = dealersettings.client.services;

                if (state.clientID && dealersettings && dealersettings.group &&
                    dealersettings.group.clients && dealersettings.group.clients[state.clientID] && dealersettings.group.clients[state.clientID].services)
                    setttings = dealersettings.group.clients[state.clientID].services;


                let allPipelines = setttings.allPipelines ? setttings.allPipelines : setttings.pipelines;
                let defaultstages = allPipelines.filter(e => e.value === data.pipeline)[0].stages;
                let statusSettings = allPipelines.filter(e => e.value === data.pipeline)[0].statusSettings;

                let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

                if (state.stage !== data.stage || state.pipeline !== data.pipeline) {
                    let stageHistory = Object.assign([], state.stageHistory);
                    let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
                    if (!_.isEmpty(_stageData)) {
                        stageHistory.push(_stageData);
                        state.stageHistory = Object.assign([], stageHistory);
                        state.pipeline = data.pipeline;
                        state.stage = data.stage;
                        state.stageDate = window.firebase.firestore.Timestamp.now();
                    }
                }
                state.owner = data.owner ? data.owner : state.owner;
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
                let updateObj = {
                    pipeline: state.pipeline,
                    stage: state.stage,
                    owner: state.owner,
                    stageDate: state.stageDate,
                    stageHistory: state.stageHistory,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    modifiedFrom: 'web'
                }

                await updateRef.update(updateObj).then((docRef) => {
                    this.setState({
                        fields: state,
                        defaulStages: defaultstages,
                        statusSettings: statusSettings,
                        activeStageIndex: stages.indexOf(data.stage)
                    }, () => { this.errorChange(data.name); });

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
            }
        }
        this.setState({
            pipelinePopOver: { showpopover: false, targetpopover: '' }
        });
    }

    handleStageChange = (id, name) => {
        //e.preventDefault();

        //const { id, name } = e.target;
        const { pipeline, status } = this.state.fields;

        if (this.isValidForStageUpdate(pipeline, name)) {
            this.setState({
                activeStageIndex: id
            })
            let state = Object.assign({}, this.state.fields);

            let stageHistory = Object.assign([], state.stageHistory);

            let _stageData = CommonHelper.handleStageHistory(stageHistory, state.pipeline, name);
            if (!_.isEmpty(_stageData)) {
                stageHistory.push(_stageData)
                state.stageHistory = Object.assign([], stageHistory);
                state.stage = name;
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();
                state.stageDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
                updateRef.update({
                    stage: state.stage,
                    stageDate: state.stageDate,
                    stageHistory: state.stageHistory,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    modifiedFrom: 'web'
                }).then((docRef) => {
                    this.setState({ fields: state });
                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
            }
        }

    }

    handleStageComplete = _stage => {
        const { stageHistory, pipeline } = this.state.fields;
        let response = 'active-disable';
        if (!_.isEmpty(stageHistory) &&
            stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0]) {
            let _stageDays = stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0];
            if (!_.isEmpty(_stageDays)) {
                response = 'active';
            }
        }
        return response;
    };

    //#endregion

    //#region CHANGE EVENT RECOMMENDATIONS

    cancelRecommField = (_field, val) => {
        let name = _field.split('_')[0];
        let recommID = _field.split('_')[1];
        //console.log('cancelRecommField', name, recommID, val)
        const _recommendations = Object.assign([], this.state.recommendations);

        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === recommID) {
                let recommendationDetail = Object.assign({}, obj.detail);
                recommendationDetail[name] = val ? val : 0;

                let _total = parseFloat(recommendationDetail.labourCost * recommendationDetail.allocationJobTime);
                recommendationDetail.totalLabourCost = _total ? _total : 0;
                obj.detail = recommendationDetail;
            }
        });
        this.setState({ recommendations: _recommendations, isChangeRecomm: false });
    }

    saveRecommField = (_field, val) => {
        let name = _field.split('_')[0];
        let recommID = _field.split('_')[1];
        //console.log('saveRecommField', name, recommID, val)

        const _recommendations = Object.assign([], this.state.recommendations);
        let _recommData;
        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === recommID) {
                let recommendationDetail = Object.assign({}, obj.detail);
                recommendationDetail[name] = val ? val : 0;

                let _total = parseFloat(recommendationDetail.labourCost * recommendationDetail.allocationJobTime);
                recommendationDetail.totalLabourCost = _total ? _total : 0;
                obj.detail = recommendationDetail;
                _recommData = Object.assign({}, obj);
            }
        });
        this.setState({ recommendations: _recommendations, isChangeRecomm: false });

        if (!_.isEmpty(_recommData))
            this.handleSaveRecommendation(_recommData);
    }

    onCostChange = (name, recommID, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        const _recommendations = Object.assign([], this.state.recommendations);

        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === recommID) {
                let recommendationDetail = Object.assign({}, obj.detail);
                recommendationDetail[name] = _.isNumber(e.floatValue) ? e.floatValue : null;

                //let _total = parseFloat(recommendationDetail.labourCost * recommendationDetail.allocationJobTime);
                //recommendationDetail.totalLabourCost = _total ? _total : 0;
                obj.detail = recommendationDetail;
            }
        });
        this.setState({ recommendations: _recommendations, isChangeRecomm: recommID });
    }

    handleOkToDoChange = (e) => {
        const { name, value } = e.target;

        if (value === 'No' && !_.isEmpty(reasons.filter(m => m.active))) {
            this.setState({ showReasons: { show: true, id: name, type: 'recommendations' } })
            return;
        }

        const _recommendations = Object.assign([], this.state.recommendations);
        let _recommData;
        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === name) {
                let recommendationDetail = Object.assign({}, obj.detail);
                recommendationDetail['oKToDo'] = value === 'Yes' ? true : false;
                recommendationDetail['reason'] = null;
                recommendationDetail['recomStatus'] = null;
                obj.detail = recommendationDetail;
                _recommData = Object.assign({}, obj);
            }
        });
        this.setState({ recommendations: _recommendations, isChangeRecomm: name });

        if (!_.isEmpty(_recommData))
            this.handleSaveRecommendation(_recommData);
    }

    handleRecomStatusChange = (status, documentID) => {

        const _recommendations = Object.assign([], this.state.recommendations);
        let _recommData;
        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === documentID) {
                let recommendationDetail = Object.assign({}, obj.detail);
                recommendationDetail['recomStatus'] = status
                obj.detail = recommendationDetail;
                _recommData = Object.assign({}, obj);
            }
        });
        this.setState({ recommendations: _recommendations });

        if (!_.isEmpty(_recommData))
            this.handleSaveRecommendation(_recommData);

    }

    handleReasonsClose = (objData) => {
        //console.log('handleReasonsClose', objData)
        if (!_.isEmpty(objData) && !_.isEmpty(objData.reason)) {
            if (objData.type === 'recommendations') {

                const _recommendations = Object.assign([], this.state.recommendations);
                let _recommData;
                _recommendations.filter(function (obj) {
                    //Update old data
                    if (obj.documentID === objData.docID) {
                        let recommendationDetail = Object.assign({}, obj.detail);
                        recommendationDetail['oKToDo'] = false;
                        recommendationDetail['reason'] = objData.reason;
                        recommendationDetail['recomStatus'] = null;
                        obj.detail = recommendationDetail;
                        _recommData = Object.assign({}, obj);
                    }
                });

                this.setState({ recommendations: _recommendations, isChangeRecomm: 'oKToDo' });

                if (!_.isEmpty(_recommData))
                    this.handleSaveRecommendation(_recommData);

            }
            else if (objData.type === 'servicePackages') {
                // const _servicePackages = Object.assign([], this.state.servicePackages);

                // _servicePackages.filter(function (obj) {
                //     //Update old data
                //     if (obj.value === objData.docID) {
                //         obj['reason'] = objData.reason;
                //     }
                // });

                // this.setState({ servicePackages: _servicePackages });

            }
        }
        this.setState({ showReasons: { show: false, id: null, type: null } })
    }

    handleSaveRecommendation = (_recommData) => {

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, _recommData);
        let fieldsDetail = Object.assign({}, fields.detail);
        let errorClass = 'input_error';

        // this.mandatoryRecommFields && this.mandatoryRecommFields.forEach((key) => {
        //     if ((_.isObject(fieldsDetail[key]) && _.isEmpty(fieldsDetail[key])) || (!_.isObject(fieldsDetail[key]) && !_.isBoolean(fieldsDetail[key]) && !fieldsDetail[key])) {
        //         formIsValid = false;
        //         errors[key + '_' + fields.documentID] = errorClass;
        //     }
        // });

        // this.setState({ errorsRecomm: errors });

        if (this.state.serviceID) {

            const { dealersettings } = this.props;
            if (!_recommData.projectId) _recommData.projectId = (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);

            const updateRef = firestoreDB(this.props.dealersettings).firestore().doc(`serviceJobs/${this.state.serviceID}/recommendations/${_recommData.documentID}`);
            updateRef.set(_recommData, { merge: true }).then((docRef) => {
                this.setState({ isChangeRecomm: false });
                toast.notify('Recommendation saved successfully', {
                    duration: 2000
                })

                this.handleStatusReScheduled();

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        }

    }

    handleValidRecommFields = (isScroll) => {

        let errors = {};
        let formIsValid = true;
        let _key;
        let errorClass = 'input_error';
        const { recommendations } = this.state;
        let _recommRequiredLists = Object.assign([], recommendations.filter(item => item.choice === recommendationChoice.Required))

        _recommRequiredLists && _recommRequiredLists.forEach(_recommData => {
            const _recommendation = Object.assign({}, _recommData);
            let fieldsDetail = Object.assign({}, _recommendation.detail);
            let _isIdAdded = false
            this.mandatoryRecommFields && this.mandatoryRecommFields.forEach((key) => {
                //if ((fieldsDetail.oKToDo === true) && ((_.isObject(fieldsDetail[key]) && _.isEmpty(fieldsDetail[key])) || (!_.isObject(fieldsDetail[key]) && !_.isBoolean(fieldsDetail[key]) && !fieldsDetail[key]))) {
                if ((fieldsDetail.oKToDo === true && key !== 'name') && ((_.isObject(fieldsDetail[key]) && _.isEmpty(fieldsDetail[key])) || (!_.isObject(fieldsDetail[key]) && !_.isBoolean(fieldsDetail[key]) && !_.isNumber(fieldsDetail[key]) && !fieldsDetail[key]))) {
                    formIsValid = false;
                    //console.log(key + '_' + _recommendation.documentID, fieldsDetail[key])
                    errors[key + '_' + _recommendation.documentID] = errorClass;
                    if (!_isIdAdded) {
                        if (!_key) _key = 'recomm_' + _recommendation.documentID;
                        errors['recomm_' + _recommendation.documentID] = errorClass;
                        _isIdAdded = true;
                    }
                }
            });

        });

        this.setState({ errorsRecomm: errors });

        if (isScroll && _key && document.getElementById(_key) && !_.isEmpty(errors[_key])) {
            document.getElementById(_key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }


        return formIsValid;
    }

    handleDeleteRecommendation = (_recommData) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to delete the recommendation.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {
                const updateRef = firestoreDB(this.props.dealersettings).firestore().doc(`serviceJobs/${this.state.serviceID}/recommendations/${_recommData.documentID}`);

                if (_recommData.type === 'manual') {
                    updateRef.delete()
                        .then(() => {
                            toast.notify('Recommendation deleted successfully', { duration: 2000 })

                            this.handleStatusReScheduled();
                        })
                        .catch(error => {
                            console.error("Error updating Recommendation: ", error);
                            toast.notify('Something went wrong', { duration: 2000 })
                        });
                }
                else {
                    let objData = { modifiedFrom: 'web' };
                    if (_recommData.type === 'checklist')
                        objData.choice = 'N/A';
                    else
                        objData.choice = null;

                    updateRef.update(objData).then((docRef) => {
                        toast.notify('Recommendation deleted successfully', { duration: 2000 })
                    }).catch((error) => {
                        console.error("Error updating Recommendation: ", error);
                    });
                }
            }

        });

    }

    handleRightActiveKey = (index) => {

        const { isChangeRecomm } = this.state;

        // if (isChangeRecomm) {
        //     Swal.fire('Please save the changes.', '', 'info')
        //     return false;
        // }
        // else {
        let _state = { activeRightKey: index }
        _state.expanded = null
        this.setState(_state);
        // }

    }

    errorRecommChange = (key, _recommID) => {

    }


    onDummyChange = (name, recommID, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}
    }


    //#endregion

    //#region MANDATORY CHECK

    getMandatoryFieldsByType = (type) => {
        const { dealersettings } = this.props;
        const { status } = this.state.fields;
        let _mandEnquiryStatus;
        let _response = [];
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.services) &&
            !_.isEmpty(dealersettings.client.services.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.services.mandatoryFields.status))
            _mandEnquiryStatus = dealersettings.client.services.mandatoryFields.status;

        if (!_.isEmpty(_mandEnquiryStatus) && status) {
            let _newStatusMandatoryFields = _mandEnquiryStatus.filter(e => e.name === status)[0];

            _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields));

            if (!_.isEmpty(_newStatusMandatoryFields) && _newStatusMandatoryFields[type]) {
                _response = _.uniq(_newStatusMandatoryFields[type].split(','));
            }
        }
        else {
            let _newStatusMandatoryFields = [];
            _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields));

            if (!_.isEmpty(_newStatusMandatoryFields) && _newStatusMandatoryFields[type])
                _response = _.uniq(_newStatusMandatoryFields[type].split(','))

        }
        return _response;
    }

    isValidForStatusUpdate = (_newStatus, origin, _previousStatus) => {
        const { dealersettings } = this.props;

        let _mandEnquiryStatus;
        let _isValid = true;
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.services) &&
            !_.isEmpty(dealersettings.client.services.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.services.mandatoryFields.status))
            _mandEnquiryStatus = dealersettings.client.services.mandatoryFields.status;

        if (!_.isEmpty(_mandEnquiryStatus) && _newStatus) {
            let _newStatusMandatoryFields = _mandEnquiryStatus.filter(e => e.name === _newStatus)[0];

            if (_previousStatus)
                _newStatusMandatoryFields = this.mergeNewAndPreviousStatus(_mandEnquiryStatus, Object.assign({}, _newStatusMandatoryFields), _previousStatus);

            _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields), _newStatus);
            if (!_.isEmpty(_newStatusMandatoryFields)) {
                _isValid = this.handleMandatoryPermission(_newStatusMandatoryFields, (origin ? origin : _newStatus))
            }
        }
        else {
            let _newStatusMandatoryFields = [];

            _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields), _newStatus);

            _isValid = this.handleMandatoryPermission(_newStatusMandatoryFields, (origin ? origin : _newStatus))

        }

        // if (_newStatus === enquiryStatus.OPEN && !_isValid)
        // 	this.setState({ isAccessToReOpen: true });

        return _isValid;
    }

    isValidForStageUpdate = (newPipeline, newStage, origin) => {
        const { pipeline, stage } = this.state.fields;
        const _newStage = newPipeline + '-' + newStage;
        const _oldStage = pipeline + '-' + stage;
        const { dealersettings } = this.props;

        let _mandPiplines;
        let _isValid = true;
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.services) &&
            !_.isEmpty(dealersettings.client.services.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.services.mandatoryFields.pipeline))
            _mandPiplines = dealersettings.client.services.mandatoryFields.pipeline;

        if (!_.isEmpty(_mandPiplines)) {

            let __newPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];
            let __oldPipeline = _mandPiplines.filter(e => e.name === _oldStage)[0];

            if (!_.isEmpty(__newPipeline)) {

                if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
                    for (let [key] of Object.entries(fieldsData)) {
                        __newPipeline[key] = ((__newPipeline[key] ? __newPipeline[key] : "") + (__oldPipeline[key] ? (__newPipeline[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
                    }
                }
                //	console.log('isValidFor StageUpdate', '==>', _newStage, '==>', _oldStage, '==>', __newPipeline, '==>', __oldPipeline);
                _isValid = this.handleMandatoryPermission(__newPipeline, (origin ? origin : newStage))
                //	console.log('isValidFor StageUpdate-Response', _isValid);
            }
            else if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
                for (let [key] of Object.entries(fieldsData)) {
                    __oldPipeline[key] = (__oldPipeline[key] ? __oldPipeline[key] : "");
                }

                //	console.log('isValidForStageUpdate', '==>', _oldStage, '==>', __oldPipeline);
                _isValid = this.handleMandatoryPermission(__oldPipeline, (origin ? origin : newStage))
                //	console.log('isValidFor StageUpdate-Response', _isValid);
            }
        }

        return _isValid;
    }
    mergeNewAndPreviousStatus = (_mandEnquiryStatus, _newMandatoryStatus, _prevStatus) => {
        if (!_.isEmpty(_mandEnquiryStatus)) {
            let __oldPipeline = _mandEnquiryStatus.filter(e => e.name === _prevStatus)[0];

            if (!_.isEmpty(__oldPipeline)) {
                for (let [key] of Object.entries(fieldsData)) {
                    _newMandatoryStatus[key] = ((_newMandatoryStatus[key] ? _newMandatoryStatus[key] : "") + (__oldPipeline[key] ? (_newMandatoryStatus[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
                }
            }
        }
        return _newMandatoryStatus;
    }

    mergeStatusAndStage = (_mandEnquiryStatus, _newStatus) => {

        const { pipeline, stage } = this.state.fields;
        const _newStage = pipeline + '-' + stage;
        const { dealersettings } = this.props;

        let _mandPiplines;
        let _isValid = true;
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.services) &&
            !_.isEmpty(dealersettings.client.services.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.services.mandatoryFields.pipeline))
            _mandPiplines = dealersettings.client.services.mandatoryFields.pipeline;

        if (!_.isEmpty(_mandPiplines)) {
            let __oldPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];

            if (!_.isEmpty(__oldPipeline)) {
                for (let [key] of Object.entries(fieldsData)) {
                    _mandEnquiryStatus[key] = ((_mandEnquiryStatus[key] ? _mandEnquiryStatus[key] : "") + (__oldPipeline[key] ? (_mandEnquiryStatus[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
                }
            }

            if (_newStatus) {
                const { statusSettings } = this.state;
                let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _newStatus)[0];
                const _newStatusPipelineName = !_.isEmpty(_statusMndry) ? _statusMndry.pipeline + '-' + _statusMndry.stage : null;
                let __newStatusPipeline = !_.isEmpty(_newStatusPipelineName) && _mandPiplines.filter(e => e.name === _newStatusPipelineName)[0];
                if (!_.isEmpty(__newStatusPipeline)) {
                    for (let [key] of Object.entries(fieldsData)) {
                        _mandEnquiryStatus[key] = ((_mandEnquiryStatus[key] ? _mandEnquiryStatus[key] : "") + (__newStatusPipeline[key] ? (_mandEnquiryStatus[key] ? ',' + __newStatusPipeline[key] : __newStatusPipeline[key]) : ""));
                    }
                }
            }

        }

        return _mandEnquiryStatus;
    }

    handleMoveStageForStatusChange = (_status, state) => {
        const { statusSettings } = this.state;
        const { stageHistory, statusHistory } = this.state.fields;

        let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            let _stageHistory = Object.assign([], stageHistory);

            let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }

            let _statusHistory = Object.assign([], statusHistory);
            _statusHistory.push({
                date: window.firebase.firestore.Timestamp.now(),
                status: _status,
                user: localStorage.uid
            });
            state.statusHistory = Object.assign([], _statusHistory);

            return state;
        }
        else {
            let _statusHistory = Object.assign([], statusHistory);
            _statusHistory.push({
                date: window.firebase.firestore.Timestamp.now(),
                status: _status,
                user: localStorage.uid
            });
            state.statusHistory = Object.assign([], _statusHistory);
            return state;
        }

    }

    //#endregion

    //#region MANDATORY PERMISSION
    handleMandatoryPermission = (objFields, _newStatus) => {
        let isBreak = false;


        if (!_.isEmpty(objFields)) {
            const _fieldsData = Object.assign({}, fieldsData);
            for (let [key, value] of Object.entries(objFields)) {
                _fieldsData[key] = value;
            }
            const { contact } = this.state;
            for (let [key, value] of Object.entries(_fieldsData)) {

                if (key === 'contact' && !contact?.businessContact && !_.isEmpty(value)) {
                    if (!this.checkMandatoryContact(value)) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'businessContact' && contact?.businessContact && !_.isEmpty(value)) {
                    if (!this.checkMandatoryContact(value)) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'vehicle' && !_.isEmpty(value)) {
                    if (!this.checkMandatoryVehicle(value)) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'service' && !_.isEmpty(value)) {
                    if (!this.checkMandatoryService(value, _newStatus)) {
                        isBreak = true;
                        break;
                    }
                }

            }
        }
        //console.log('handleMandatoryPermission', !isBreak, objFields, '==>', _newStatus)
        if (isBreak)
            return false;
        else
            return true;
    }

    checkMandatoryContact = value => {
        const { isContact } = this.state.fields;

        let _mandatoryFields = value ? _.uniq(value.split(',')) : [];

        //console.log('checkMandatoryContact', isContact, _mandatoryFields)
        if (!isContact) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleContactOpen('addContact', _mandatoryFields);
                }
            });
            // <SweetAlert title="Please add contact" onConfirm={this.handleContactOpen('addContact', _mandatoryFields)}  />
            return false;

        }
        else if (!_.isEmpty(_mandatoryFields)) {
            const ObjCustomer = Object.assign({}, this.state.contact);
            let msg;
            _mandatoryFields.some((el, index) => {
                if (el.trim() === 'licenseFrontURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license front image';
                        return true;
                    }
                } else if (el.trim() === 'licenseBackURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license back image';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemail') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
                        msg = 'Please enter Phone or Email';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemailORlicenseNo') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
                        msg = 'Please enter Phone or Email or License No.';
                        return true;
                    }
                } else if (!ObjCustomer[el]) {
                    msg = 'Please enter ' + el;
                    return true;
                }
            });
            if (msg) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in contact'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        this.handleContactOpen('addContact', _mandatoryFields);
                    }
                });
                return false;
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    checkMandatoryVehicle = value => {
        const { isVehicle, vehicle } = this.state.fields;

        let _mandatoryFields = value ? _.uniq(value.split(',')) : [];

        //console.log('checkMandatoryContact', isContact, _mandatoryFields)
        if (!isVehicle) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add vehicle'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleMandatoryVehicleOpen(vehicle, _mandatoryFields);
                }
            });
            // <SweetAlert title="Please add contact" onConfirm={this.handleContactOpen('addContact', _mandatoryFields)}  />
            return false;

        }
        else if (!_.isEmpty(_mandatoryFields)) {
            const ObjVehicle = Object.assign({}, this.state.fields.vehicle);
            let msg;
            _mandatoryFields.some((el, index) => {
                if (!ObjVehicle[el]) {
                    msg = 'Please enter ' + el;
                    return true;
                }
            });
            if (msg) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in vehicle'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        this.handleMandatoryVehicleOpen(ObjVehicle, _mandatoryFields);
                    }
                });
                return false;
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    checkMandatoryService = (value, _newStatus) => {
        let _mandatoryFields = !_.isEmpty(value) ? _.uniq(value.split(',')) : [];

        if (!_.isEmpty(this.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.mandatoryFields);

        if (!_.isEmpty(_mandatoryFields)) {
            const objService = Object.assign({}, this.state.fields);
            let msg;


            let _isbreak = false;

            let _specialKey = ['isContact', 'isVehicle', 'isInspection', 'isServicePackage', 'isFiles', 'recommendations', 'notes']

            let _requiredObject = _.filter(_mandatoryFields, (v) => (_specialKey.indexOf(v) >= 0));

            let _requiredFields = _.filter(_mandatoryFields, (v) => (_specialKey.indexOf(v) < 0));

            let _recommRequiredList = this.state.recommendations.filter(item => item.choice === recommendationChoice.Required)
            //console.log('checkMandatory-Service', _newStatus, value, _mandatoryFields, this.mandatoryFields, _requiredObject, _requiredFields)
            !_.isEmpty(_requiredObject) && _requiredObject.some((el, index) => {
                if (el.trim() === 'isContact') {
                    if (!objService[el] && _.isEmpty(objService['contact'])) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add contact'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleContactOpen('addContact');
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isVehicle') {
                    if (!objService[el] && _.isEmpty(objService['vehicle'])) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add vehicle'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                setTimeout(() => {
                                    this.handleMandatoryVehicleOpen();
                                }, 200);

                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isInspection') {
                    if (!objService[el]) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add inspection'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                setTimeout(() => {
                                    this.setState({ showCanvas: true })
                                }, 200);

                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isServicePackage') {
                    if (_.isEmpty(this.state.servicePackages)) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add service packages'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                setTimeout(() => {
                                    this.setState({ showAddOns: true })
                                }, 200);

                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'recommendations') {
                    if (_.isEmpty(_recommRequiredList)) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add recommendations'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleAddRecommendation();
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'notes') {
                    // if (_.isEmpty(objService[el])) {
                    //     Swal.fire({
                    //         title: 'Please add notes',
                    //         icon: 'info',
                    //         showCancelButton: true,
                    //         confirmButtonText: 'Add Now',
                    //         cancelButtonText: 'Later',
                    //     }).then(result => {
                    //         if (result.value) {
                    //             setTimeout(() => {
                    //                 this.setState({ showNotes: true })
                    //             }, 200);

                    //         }
                    //     });
                    //     _isbreak = true;
                    //     return true;
                    // }
                } else if (el.trim() === 'isFiles') {
                    if (!objService[el]) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add file'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                setTimeout(() => {
                                    this.isValidForFile();
                                }, 200);

                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                }
            });

            if (_isbreak)
                return false;

            !_.isEmpty(_requiredFields) && _requiredFields.some((el, index) => {
                if (!objService[el]) {
                    if (objService.hasOwnProperty(el)) {
                        msg = 'Please enter ' + el;
                        return true;
                    }
                    else {
                        return true;
                    }
                }
            });

            if (msg) {

                let errors = {};
                let formIsValid = true;
                let errorClass = 'input_error';

                _requiredFields.forEach((key, index) => {
                    if (!objService[key] && _requiredFields.indexOf(key) >= 0) {
                        if (objService.hasOwnProperty(key)) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                });

                if (formIsValid === true) {
                    return true;
                } else {
                    //console.log('checkMandatory-Service-errors', errors)
                    this.setState({ errors: errors });
                    const _jobStatus = objService.status && objService.status === serviceStatus.OWNERRESPONDED ? objService.ownerResponse : objService.status;

                    const isCheckInJobDone = (_jobStatus === serviceStatus.NEWSERVICE || _jobStatus === serviceStatus.CANCELLED || _jobStatus === serviceStatus.SCHEDULED || _jobStatus === serviceStatus.CHECKIN) ? false : true


                    if (isCheckInJobDone) {
                        this.handleServiceDetailOpen();
                    }
                    else {
                        let _key = errors && Object.keys(errors)[0];
                        if (_key && document.getElementById(_key) && !_.isEmpty(errors[_key])) {
                            document.getElementById(_key).scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            })
                        }
                    }

                    return formIsValid;
                }

            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }
    //#endregion 

    //#region RE-AUTHENTICATION
    handleReAuthOpen = () => {
        this.setState({ showReAuthentication: true });
    }

    handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            this.forceUpdate();
        }
        this.setState({ showReAuthentication: false });
    }

    handleSaveAuditLog = (imagePath) => {
        const { documentID } = this.state.contact;
        const { dealersettings } = this.props;
        let _name = CommonHelper.getFullNameWithID(null, this.state.contact, '');
        let _objLogData = {
            notes: 'License image viewed for ' + _name,
            type: 'licenseImage',
            subType: 'contactDetails',
            recordId: documentID ? documentID : null,
            contactID: documentID ? documentID : null,
            imageURL: imagePath,
        }

        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
        CommonHelper.saveAuditLog(_objLogData);
    }
    //#endregion    

    //#region CHANGE EVENT ADD_ONS  
    handleAddOnsOpen = () => {
        this.setState({ showAddOns: true });
    }

    handleAddOnsClose = () => {
        const { isServicePackage } = this.state.fields;
        const { servicePackages } = this.state;
        if (!isServicePackage && !_.isEmpty(servicePackages)) {
            this.saveServiceField('isServicePackage', true);
        }
        this.setState({ showAddOns: false });
    }

    handleAddOnsInfoOpen = (data) => {
        this.setState({ showAddOnsInfo: { show: true, data: data } })
    }

    handleAddOnsInfoClose = () => {
        this.setState({ showAddOnsInfo: { show: false, data: null } })
    }

    handleSaveAddOns = (_recommData) => {
        const { serviceID } = this.state;
        //let fields = Object.assign({}, _recommData);
        _recommData.serviceID = serviceID;
        if (serviceID) {

            const { dealersettings } = this.props;
            if (!_recommData.projectId) _recommData.projectId = (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);

            const updateRef = firestoreDB(this.props.dealersettings).firestore().doc(`serviceJobs/${serviceID}/servicePackages/${_recommData.documentID}`);
            updateRef.set(_recommData, { merge: true }).then((docRef) => {
                this.setState({ isChangeRecomm: false });
                toast.notify('Packages saved successfully', {
                    duration: 2000
                });

                this.handleStatusReScheduled();

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        }

    }

    handleRemoveAddOns = (_ID) => {

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to delete the package.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {
                const { serviceID } = this.state;
                if (serviceID && _ID) {
                    firestoreDB(this.props.dealersettings).firestore().doc(`serviceJobs/${serviceID}/servicePackages/${_ID}`).delete().then((docRef) => {
                        this.setState({ isChangeRecomm: false });
                        toast.notify('Packages removed successfully', {
                            duration: 2000
                        });

                        this.handleStatusReScheduled();

                    }).catch((error) => {
                        console.error("Error updating contact_details: ", error);
                    });
                }
            }

        });

    }

    //#endregion

    //#region CHANGE EVENT Notes  
    handleNotesOpen = () => {
        this.setState({ showNotes: true });
    }

    handleNotesClose = (noteTxt, _mentions) => {

        if (noteTxt) {
            const { serviceID } = this.state;

            let convertedIDs = []
            if (!_.isEmpty(_mentions)) {
                let rawIDs = _mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })
            }
            const { dealersettings } = this.props;
            let _objNote =
            {
                documentID: firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc().id,
                projectId: dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null,
                note: noteTxt,
                mentions: Object.assign([], convertedIDs),
                addedBy: this.props.dealersettings ? this.props.dealersettings.id : '',
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedFrom: 'web'
            }

            const updateRef = firestoreDB(this.props.dealersettings).firestore().doc(`serviceJobs/${serviceID}/notes/${_objNote.documentID}`);
            updateRef.set(_objNote, { merge: true }).then((docRef) => {

                toast.notify('notes added successfully', {
                    duration: 2000
                })



                if (!_.isEmpty(convertedIDs)) {
                    let mentions = Object.assign([], this.state.fields.mentions);
                    if (mentions && mentions.length > 0) {
                        mentions = _.uniq([...mentions, ...convertedIDs])
                    } else {
                        mentions = _.uniq(convertedIDs)
                    }
                    this.saveServiceField('mentions', Object.assign([], mentions));
                }

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }

        this.setState({ showNotes: false });
    }
    //#endregion 

    //#region CHANGE EVENT CHECK_LISTS  
    handleCheckListOpen = () => {
        this.setState({ showCheckLists: true });
    }

    handleCheckListClose = (isChecklist) => {

        let state = Object.assign({}, this.state.fields);
        if (!state.isChecklist && isChecklist) {
            state.isChecklist = true;
            state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
            updateRef.update({
                isChecklist: state.isChecklist,
                modifiedBy: state.modifiedBy,
                modifiedDate: state.modifiedDate,
                modifiedFrom: 'web'
            }).then((docRef) => {
                this.setState({ fields: state });
            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }
        this.setState({ showCheckLists: false });
    }

    handleBBTCheckListOpen = () => {
        this.setState({ showBBTCheckLists: true });
    }

    handleBBTCheckListClose = (isBbtChecklist) => {
        let state = Object.assign({}, this.state.fields);
        if (!state.isBbtChecklist && isBbtChecklist) {
            state.isBbtChecklist = true;
            state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
            updateRef.update({
                isBbtChecklist: state.isBbtChecklist,
                modifiedBy: state.modifiedBy,
                modifiedDate: state.modifiedDate,
                modifiedFrom: 'web'
            }).then((docRef) => {
                this.setState({ fields: state });
            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }

        this.setState({ showBBTCheckLists: false });
    }

    handleServiceDetailOpen = () => {
        this.setState({ showServiceDetails: true });
    }

    handleServiceDetailClose = (response) => {
        const { originFromServiceDetails } = this.state;

        if (response === 'saved' && (originFromServiceDetails === 'jobAllocated' || originFromServiceDetails === 'partsConfirmed' || originFromServiceDetails === 'workshopConfirmed')) {
            this.saveServiceField(originFromServiceDetails, true);
            this.setState({ showServiceDetails: false, originFromServiceDetails: null, errors: {} });
            setTimeout(() => this.errorChange(originFromServiceDetails), 500);
        }
        else {
            this.setState({ showServiceDetails: false, originFromServiceDetails: null, errors: {} });
        }
    }
    //#endregion 

    //#region INSPECTION
    handleCanvasClose = (marks) => {
        this.setState({ showCanvas: false })
        let state = Object.assign({}, this.state.fields);
        if (!_.isEmpty(marks) || (!_.isEmpty(state.marks) && _.isEmpty(marks))) {
            this.saveServiceField('marks', marks);
        }
    }

    getInspectionSnapshot = async (_marks) => {
        const canvas = document.createElement("canvas");
        canvas.width = 395;
        canvas.height = 600;
        var context = canvas.getContext('2d');

        var img = new Image();
        const { dealersettings } = this.props;
        if (dealersettings?.client?.category === 'Motorcycle')
            img.src = require("../../../images/inspection-motorcycle.jpg");
        else
            img.src = require("../../../images/inspection.jpg");
        //img.src = require(`../../../images/${CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'inspection')}.jpg`);
        img.onload = async (e) => {
            context.drawImage(img, 0, 0, 395, 600);

            if (!_.isEmpty(_marks)) {
                await this.asyncForEach(_marks, async (mark) => {
                    let imgURL = require(`../../../images/${mark.markType}.png`);
                    await this.loadImage(imgURL).then(img2 => {
                        context.drawImage(img2, mark.dx, mark.dy, 25, 25);
                    });
                });
            }

            var dataURL = canvas.toDataURL();
            if (dataURL)
                this.saveInspectionToStorage(dataURL);

            return dataURL;
        }
    }

    asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // It's better to use async image loading.
    loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };

    saveInspectionToStorage = (imageBase64) => {
        this.setState({ isLoadingInspection: true })
        const { serviceid, dealersettings } = this.props;
        let clientID = dealersettings ? dealersettings.client.id : '';

        var storageRef = storageBucket(dealersettings).ref(`${clientID}/serviceJobs/${serviceid}/inspections/snapshot.png`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(dealersettings).ref(`${clientID}/serviceJobs/${serviceid}/inspections`).child('snapshot.png').getDownloadURL()
                    .then(dataURL => {
                        //console.log(dataURL);
                        this.setState({ isLoadingInspection: false })
                        this.saveServiceField('inspectionSnapshot', dataURL);
                    })
            })
    };
    //#endregion

    //#region IMAGE UPLOAD

    saveImageToStorage = (imageBase64, id, ext) => {

        let uid = moment()._d.getTime().toString() + '.' + ext;
        const { serviceid, dealersettings } = this.props;
        let clientID = dealersettings ? dealersettings.client.id : '';

        var storageRef = storageBucket(dealersettings).ref(`${clientID}/serviceJobs/${serviceid}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(dealersettings).ref(`${clientID}/serviceJobs/${serviceid}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        //console.log('saveImageToStorage-2', uid);
                        let state = Object.assign({}, this.state.fields);
                        let _data = Object.assign([], state[id]);
                        _data.push(dataURL);
                        this.setState({ imageURL: null })
                        this.saveServiceField(id, Object.assign([], _data));
                        if (id && document.getElementById(id))
                            document.getElementById(id).value = "";
                    })
            })
    };

    saveImagesToStorage = async (filez, id) => {
        let promises = [];
        this.setState({ imageURLs: Object.assign([], filez) });
        let files = [];
        for (let [key, file] of Object.entries(filez)) {
            if (file.name.toLowerCase().includes('.heic')) {
                let _file = await CommonHelper.heic2Image(file);
                files.push(_file)
            } else
                files.push(file);
        }


        const { serviceid, dealersettings } = this.props;
        let clientID = dealersettings ? dealersettings.client.id : '';

        files.forEach((file, index) => {
            let uid = moment()._d.getTime().toString() + index + '.' + file.name.substr((file.name.lastIndexOf('.') + 1));
            let storageRef = storageBucket(dealersettings).ref(`${clientID}/serviceJobs/${serviceid}/${uid}`);
            promises.push(
                storageRef.put(file).then((snapshot) => {
                    return snapshot.ref.getDownloadURL()
                })
            );
        });
        const snapshots = await Promise.all(promises)
        let state = Object.assign({}, this.state.fields);
        let fileURLS = Object.assign([], state[id]);
        snapshots.forEach(url => {
            fileURLS.push(url);
        })

        if (!_.isEmpty(fileURLS)) {
            this.setState({ imageURLs: null })
            this.saveServiceField(id, fileURLS);
            if (id && document.getElementById(id))
                document.getElementById(id).value = "";
        }
    }


    handleImageDel = (index) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                let _data = Object.assign([], state.images);
                _data.splice(index, 1);
                this.saveServiceField('images', Object.assign([], _data));
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }


    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            imageURL: null,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {

        const { src, id, type, ext } = cropShow;

        this.saveImageToStorage(src, id, ext);

        this.setState({
            imageURL: src,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = async (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 1) {
            const files = e.target.files;
            for (let [key, file] of Object.entries(files)) {
                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 10) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Maximum size of each file is 10MB'), '', 'info')
                    return;
                }
            }

            this.saveImagesToStorage(files, id)

        } else if (e.target.files && e.target.files.length === 1) {

            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];
            if (file.name.toLowerCase().includes('.heic')) {
                this.setState({ imageURL: _images.novehicle });
                file = await CommonHelper.heic2Image(file);
            }
            reader.onloadend = () => {
                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }
    //#endregion

    //#region SHARE CHANGES
    handleSharePanelOpen = (e) => {
        e.preventDefault();
        localStorage.removeItem('showChat');
        this.setState({
            showSharePanel: {
                chatClsActive: 'active',
                chatClsName: 'sidebar',
            },
        });
    };

    handleSharePanelClose = () => {
        this.setState({
            showSharePanel: {
                chatClsActive: '',
                chatClsName: 'sidebar',
            },
        });
    };
    //#endregion

    //#region FILE CHANGES

    handleFileClose = (ID) => {
        if (ID) {
            let state = Object.assign({}, this.state.fields);
            if (!state.isFiles) {
                state.isFiles = true;
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
                updateRef.update({
                    isFiles: state.isFiles,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    modifiedFrom: 'web'
                }).then((docRef) => {
                    this.setState({ fields: state });

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
            }
        }

        this.setState({
            fileModal: {
                show: false,
            },
        });
    }

    handleFileOpen = () => {
        this.setState({
            fileModal: {
                show: true,
            },
        });
    }

    isValidForFile = () => {
        if (this.state.fields.isContact) {
            this.handleFileOpen();
            return true;
        }
        else {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then((result) => {
                if (result.value) {
                    this.handleContactOpen('addContact');

                }
            });
            return false;
        }
    }
    //#endregion

    //#region  QUICK VIEW SERVICE
    sidepanelServiceOpen = id => {
        const { serviceID } = this.state;

        if (serviceID !== id)
            this.props.history.push('/service/details/' + id);

        // this.setState({
        //     showServicepanel: {
        //         clsActive: 'active',
        //         clsName: 'sidebar-quickview',
        //         serviceid: id,
        //     },
        // });


    };

    sidepanelServiceClose = () => {

        // this.setState({
        //     showServicepanel: {
        //         clsActive: '',
        //         clsName: 'sidebar-quickview',
        //         serviceid: 0,
        //     },
        // });
    };
    //#endregion

    //#region DISCOUNTS 

    handleOpenDiscount = (isview) => {
        this.setState({ showDiscountService: { show: true, isview: isview } })
    }

    handleCloseDiscount = (_data) => {
        if (!_.isEmpty(_data)) {
            const { serviceID } = this.state;
            const { dealersettings } = this.props;

            let _objData = {
                discountType: _data.discountType ? _data.discountType : null,
                discount: _data.discount ? _data.discount : null,
                modifiedBy: dealersettings ? dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }

            const updateJobRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(serviceID);
            updateJobRef.update(_objData).then((docRef) => {
                toast.notify(`Discount updated sucessfully.`, { duration: 2000 });
                this.saveTotalServiceCost();
            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }
        this.setState({ showDiscountService: { show: false, isview: null } })
    }

    handleOpenRecommDiscount = (_data, isview) => {
        this.setState({ showDiscountRecomm: { show: true, data: _data, isview: isview } })
    }

    handleCloseRecommDiscount = (_recommData) => {
        if (!_.isEmpty(_recommData)) {
            this.handleSaveRecommendation(_recommData);
        }
        this.setState({ showDiscountRecomm: { show: false, data: null, isview: null } })
    }

    handleOpenPackDiscount = (_data, isview) => {
        this.setState({ showDiscountPack: { show: true, data: _data, isview: isview } })
    }

    handleClosePackDiscount = (_recommData) => {
        if (!_.isEmpty(_recommData)) {
            this.handleSaveAddOns(_recommData);
        }
        this.setState({ showDiscountPack: { show: false, data: null, isview: null } })
    }

    //#endregion

    //#region VIDEO CHANGES

    handleVideoClose = (ID) => {
        // if (ID) {
        //     let state = Object.assign({}, this.state.fields);
        //     if (!state.isVideo) {
        //         state.isVideo = true;
        //         state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        //         state.modifiedDate = window.firebase.firestore.Timestamp.now();

        //         const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.enquiryID);
        //         updateRef.update({
        //             isVideo: state.isVideo,
        //             modifiedBy: state.modifiedBy,
        //             modifiedDate: state.modifiedDate,
        //             modifiedFrom: 'web'
        //         }).then((docRef) => {
        //             this.setState({ fields: state });

        //         }).catch((error) => {
        //             console.error("Error updating enquiries: ", error);
        //         });
        //     }
        // }

        this.setState({
            videoModal: {
                show: false,
                recordID: null
            }
        });
    }

    handleVideoOpen = (ID) => {
        this.setState({
            videoModal: {
                show: true,
                recordID: ID
            }
        });
    }

    deleteVideo = (fileID) => {

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this video.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                const { dealersettings } = this.props;
                let modifiedBy = dealersettings ? dealersettings.id : '';
                let modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(dealersettings).firestore().collection(`serviceJobs/${this.state.serviceID}/videos`).doc(fileID)
                updateRef.
                    set({
                        isDeleted: true,
                        modifiedBy,
                        modifiedDate
                    }, { merge: true })
                    .then((docRef) => {
                        toast.notify('Video successfully deleted.', {
                            duration: 2000
                        });
                    }).catch((error) => {
                        console.error("Error deleting Video: ", error);
                    });

            }
        })

    }
    //#endregion

    //#region STATUS RE-REQUEST CHANGES

    handleRequestStatusClose = (_status) => {
        this.setState({ showStatusRequestModal: false });
    }

    handleRequestStatusOpen = () => {
        this.setState({ showStatusRequestModal: true });
    }
    //#endregion

    //#region LOAN APPOINTMENT CHANGES

    handleCloseLoanAppointment = (_status) => {
        this.setState({ showLoanAppointmentModal: false });
    }

    handleOpenLoanAppointment = () => {
        const { isLoan, contact } = this.state.fields;

        if (contact.banLoanVehicle) {
            Swal.fire(`${CommonHelper.displayContact(null, contact, 'Contact')} ${CommonHelper.showLocale(this.props, 'has been restricted from loan vehicle')}`, '', 'info')
            return;
        }

        if (!isLoan)
            this.setState({ showLoanAppointmentModal: true });
    }

    handleDeleteAppointment = () => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to delete the appointment.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {
                this.deleteAppointment();
            }
        });

    }

    deleteAppointment = () => {
        const { loanAppointmentID, isLoan } = this.state.fields;
        if (loanAppointmentID && !isLoan) {
            firestoreDB(this.props.dealersettings).firestore().doc(`loanAppointments/${loanAppointmentID}`).delete();

            let state = Object.assign({}, this.state.fields);
            state.loanAppointmentID = null;
            state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc(this.state.serviceID);
            updateRef.update({
                loanAppointmentID: null,
                modifiedBy: state.modifiedBy,
                modifiedDate: state.modifiedDate,
                modifiedFrom: 'web'
            }).then((docRef) => {
                toast.notify('Appointment deleted successfully', {
                    duration: 2000
                })

            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }
    }

    isValidForLoan = () => {
        if (this.state.fields.isContact) {
            const ObjCustomer = Object.assign({}, this.state.contact);
            if (!_.isEmpty(this.mandatoryFieldsForTestDrive)) {
                var msg = '';
                this.mandatoryFieldsForTestDrive.some((el, index) => {
                    if (el.trim() === 'licenseFrontURL') {
                        if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                            msg = 'Please upload license front image';
                            return true;
                        }
                    } else if (el.trim() === 'licenseBackURL') {
                        if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                            msg = 'Please upload license back image';
                            return true;
                        }
                    } else if (el.trim() === 'phoneORemail') {
                        if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
                            msg = 'Please enter Phone or Email';
                            return true;
                        }
                    } else if (el.trim() === 'phoneORemailORlicenseNo') {
                        if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
                            msg = 'Please enter Phone or Email or License No.';
                            return true;
                        }
                    } else if (!ObjCustomer[el] && !_.isBoolean(ObjCustomer[el])) {
                        msg = `${CommonHelper.showLocale(this.props, 'Please enter')} ${CommonHelper.showLocale(this.props, el)}`;
                        return true;
                    }
                });

                if (msg) {
                    Swal.fire({
                        title: CommonHelper.showLocale(this.props, msg),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                        cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                    }).then(result => {
                        if (result.value) {
                            this.handleContactOpen('editContact', this.mandatoryFieldsForTestDrive);
                        }
                    });

                    return false;
                } else {
                    this.handleOpenTestDriveModal(ObjCustomer);
                }
            } else {
                this.handleOpenTestDriveModal(ObjCustomer);
            }
            return true;
        } else {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleContactOpen('addContact', this.mandatoryFieldsForTestDrive);
                }
            });
            return false;
        }
    };

    handleOpenTestDriveModal = (ObjCustomer) => {
        if (ObjCustomer['licenseExpiry'] && (moment()._d > moment(ObjCustomer['licenseExpiry'])._d)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'License is expired.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Update Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleContactOpen('editContact', this.mandatoryFieldsForTestDrive);
                }
            });

            return false;
        }
        else {
            this.handleAddLoanVehicle();
        }
    }

    handleAddLoanVehicle = () => {
        const { loanAppointmentID, contact } = this.state.fields
        const { serviceLoanStocks, serviceLoanApps } = this.state;

        if (contact.banLoanVehicle) {
            Swal.fire(`${CommonHelper.displayContact(null, contact, 'Contact')} ${CommonHelper.showLocale(this.props, 'has been restricted from loan vehicle')}`, '', 'info')
            return;
        }

        if (!_.isEmpty(loanAppointmentID)) {
            let _stock = loanAppointmentID && !_.isEmpty(serviceLoanStocks) && serviceLoanStocks.filter(item => item.loanAppointments.some(a => a.documentID === loanAppointmentID))[0]
            if (!_.isEmpty(_stock)) {
                if (_stock.status === stockStatus.ONTESTDRIVE && !_.isEmpty(_stock.testDriveDetail)) {
                    this.handleTestDriveStatusChange(_stock.testDriveDetail, _stock);
                }
                else if (_stock.status === stockStatus.ONLOAN && !_.isEmpty(_stock.fleetDetail)) {
                    this.handleLoanStatusChange(_stock.fleetDetail, _stock);
                }
                else {
                    this.setState({
                        fleetModal:
                        {
                            show: true,
                            ID: null,
                            serviceID: this.state.serviceID,
                            title: 'loanVehicle',
                            clsActive: 'overlay-modal active',
                            stock: Object.assign({}, _stock),
                            contact: Object.assign({}, this.state.contact)
                        }
                    });

                }
            }
        }
        else {
            this.handleOpenLoanStocks();
        }

    }

    handleTestDriveStatusChange = (objDetail, _stock) => {
        let _vehicleModel = !_.isEmpty(_stock) ? (_stock.make ? _stock.make : '') + ' ' + (_stock.model ? _stock.model : '') + (_stock.year ? ', ' + _stock.year : '') : ''
        Swal.fire({
            title: `The ${_vehicleModel} is on testdrive`,
            text: CommonHelper.showLocale(this.props, 'doYouWantToReturnThisVehicleAnyway'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'yesReturn'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
        }).then((result) => {
            if (result.value) {
                this.setState({
                    testDriveModal:
                    {
                        show: true,
                        ID: objDetail.testdriveID,
                        title: 'testDrive',
                        clsActive: 'overlay-modal active',
                        stock: Object.assign({}, _stock),
                        contact: (!_.isEmpty(objDetail.contact)) ? objDetail.contact : null
                    }
                });
            }
        })
    }

    handleLoanStatusChange = (objDetail, _stock) => {
        let _vehicleModel = !_.isEmpty(_stock) ? (_stock.make ? _stock.make : '') + ' ' + (_stock.model ? _stock.model : '') + (_stock.year ? ', ' + _stock.year : '') : ''
        Swal.fire({
            title: `The ${_vehicleModel} is already on loan`,
            text: CommonHelper.showLocale(this.props, 'doYouWantToReturnThisVehicleAnyway'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'yesReturn'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
        }).then((result) => {
            if (result.value) {
                this.setState({
                    fleetModal:
                    {
                        show: true,
                        ID: objDetail.fleetID,
                        serviceID: '',
                        title: 'loanVehicle',
                        clsActive: 'overlay-modal active',
                        stock: Object.assign({}, _stock),
                        contact: null
                    }
                });

            }
        })
    }

    handleEditTestDrive = (testDriveID, status) => {
        this.setState({
            fleetModal:
            {
                show: true,
                ID: testDriveID,
                serviceID: '',
                title: 'loanVehicle',
                clsActive: 'overlay-modal active'
            }
        });
    };

    handleCloseFleetAndTDModal = (ID) => {
        this.setState({
            testDriveModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null,
                contact: null
            },
            fleetModal:
            {
                show: false,
                ID: '',
                serviceID: '',
                title: '',
                clsActive: '',
                stock: null,
                contact: null
            }
        }, () => { this.handleCloseLoanStocks(ID) });
    }

    handleCloseLoanStocks = (ID, objData) => {
        const { serviceLoanStocks } = this.props;

        if (ID && !_.isEmpty(objData)) {

            const fields = Object.assign({}, this.state.fields);

            const stock = Object.assign({}, stockBasicVM);
            for (let [key, value] of Object.entries(objData)) {
                if (stock.hasOwnProperty(key))
                    stock[key] = value;
            }
            this.setState({
                fleetModal:
                {
                    show: true,
                    ID: null,
                    serviceID: this.state.serviceID,
                    title: 'loanVehicle',
                    clsActive: 'overlay-modal active',
                    stock: Object.assign({}, stock),
                    contact: Object.assign({}, this.state.contact)
                }
            });

        }
        else if (ID) {
            const refData = window.firebase.firestore().collection('stock').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {

                    const objStockData = Object.assign({}, doc.data());
                    const stock = Object.assign({}, stockBasicVM);
                    for (let [key, value] of Object.entries(objStockData)) {
                        if (stock.hasOwnProperty(key))
                            stock[key] = value;
                    }
                    stock.documentID = doc.id;

                    this.setState({
                        fleetModal:
                        {
                            show: true,
                            ID: null,
                            serviceID: this.state.serviceID,
                            title: 'loanVehicle',
                            clsActive: 'overlay-modal active',
                            stock: Object.assign({}, stock),
                            contact: Object.assign({}, this.state.contact)
                        }
                    });

                } else {
                    console.error("No such document!");
                }
            });
        }

        this.setState({
            showStocks: false
        });



    }

    handleOpenLoanStocks = () => {
        this.setState({ showStocks: true });
    }

    //#endregion

    getvehiclePackages = (_packages, _make, _model) => {
        let _all = _packages.filter(e => !e.make && !e.model).map(_data => {
            _data.category = `General Packages`
            _data.sortOrder = 1
            return _data;
        })
        let _makes = _packages.filter(e => e.make === _make && !e.model).map(_data => {
            _data.category = `${_make} Packages`
            _data.sortOrder = 2
            return _data;
        })
        let _makeModels = _packages.filter(e => e.make === _make && e.model === _model).map(_data => {
            _data.category = `${_model} Packages`
            _data.sortOrder = 3
            return _data;
        })
        return _.uniq([..._all, ..._makes, ..._makeModels])
    }

    handleDynamicStatus = (_status) => {
        const { jobConfirmed, ownerResponse } = this.state.fields;
        let _objCurrentStatus = _status && serviceAllStatus.filter(e => e.value === _status)[0];

        if (_status === serviceStatus.SCHEDULED)
            this.scheduleJob(serviceStatus.SCHEDULED);
        else if (_status === serviceStatus.CHECKIN) {
            if (this.isValidateAppointment()) this.checkInJob();
        }
        else if (_status === serviceStatus.TECHNICIANREQUESTED) {
            if (this.isValidateAppointment()) this.sendJobRequest(_status);
        }
        else if (_status === serviceStatus.ADVISORREQUESTED) {
            if (this.handleValidRecommFields(true) === false) {
                //Swal.fire('Please fill all the required recommendation fields.', '', 'info')
                return false;
            }
            else this.sendJobRequest(_status);
        }
        else if (_status === serviceStatus.PARTSREQUESTED || _status === serviceStatus.WORKSHOPREQUESTED)
            this.sendJobRequest(_status);
        else if (_status === serviceStatus.WORKINPROGRESS) {
            const { servicePackages, recommendations } = this.state;

            if (jobConfirmed ||
                (!_.isEmpty(servicePackages) && ownerResponse === serviceStatus.OWNERDECLINED) ||
                (!_.isEmpty(servicePackages) && _.isEmpty(recommendations.filter(item => item.choice === recommendationChoice.Required)))) {
                this.startJob();
            }
            else {
                Swal.fire('Please confirm the quotation.', '', 'info');
                return false;
            }

        }
        else if (_status === serviceStatus.COMPLETED) {
            const { servicePackages, recommendations } = this.state;

            if (jobConfirmed ||
                (!_.isEmpty(servicePackages) && ownerResponse === serviceStatus.OWNERDECLINED) ||
                (!_.isEmpty(servicePackages) && _.isEmpty(recommendations.filter(item => item.choice === recommendationChoice.Required))) ||
                (_.isEmpty(servicePackages) && _.isEmpty(recommendations.filter(item => item.choice === recommendationChoice.Required)))) {
                this.completeJob();
            }
            else {
                Swal.fire(CommonHelper.showLocale(this.props, 'Please confirm the quotation.'), '', 'info');
                return false;
            }
        }
        else if (!_.isEmpty(_objCurrentStatus) && this.isValidForStatusUpdate(_status)) {
            this.saveServiceField('status', _status);
        }

    }

    handleStatusChange = (status) => {

        const { dealersettings, updateDealerSettings } = this.props;
        const { serviceID, fields } = this.state;

        this.saveServiceField('technicianStatus', status);

        if (status === serviceStatus.COMPLETED) {
            CommonHelper.stopAllWorkLogTimers(dealersettings, serviceID);
            if (updateDealerSettings && dealersettings.workingServiceID === serviceID)
                updateDealerSettings('workingServiceID', null);
        }
        else if (status === serviceStatus.WORKINPROGRESS && _.isEmpty(dealersettings.workingServiceID)) {
            CommonHelper.addTechnicianWorkLog(dealersettings, fields, updateDealerSettings);
        }


    };

    handleSaveOwnedVehicle = async () => {

        const { vehicle, documentID, contactID, contact } = this.state.fields;
        const { dealersettings } = this.props;
        let _contactID = contactID ? contactID : (contact?.documentID ? contact.documentID : null);

        if (_contactID && !_.isEmpty(vehicle)) {
            let ownedVehicles = []
            const snapshots = await firestoreDB(dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`).get()
            if (snapshots.size > 0) {
                snapshots.docs.forEach((doc) => {
                    ownedVehicles.push(doc.data())
                });
            }

            let isExists = ownedVehicles && ownedVehicles.filter(m =>
                (vehicle.vinNo ? m.vinNo === vehicle.vinNo : false) ||
                (vehicle.rego ? m.regNo === vehicle.rego : false))[0];

            if (_.isEmpty(isExists))
                isExists = ownedVehicles && ownedVehicles.filter(m => (documentID ? m.serviceID === documentID : false))[0];

            if (_.isEmpty(isExists)) {

                const objData = Object.assign({}, objOwnedVehicle);
                for (let [key, value] of Object.entries(vehicle)) {
                    if (objData.hasOwnProperty(key))
                        objData[key] = value;
                }
                objData.addedBy = localStorage.uid;
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
                objData.modifiedBy = localStorage.uid;
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = 'web';
                objData.documentID = firestoreDB(dealersettings).firestore().collection('contactDetails').doc().id;
                objData.currentlyOwned = true;
                objData.exteriorColor = vehicle.extColor ? vehicle.extColor : '';
                objData.regNo = vehicle.rego ? vehicle.rego : '';
                objData.serviceID = documentID;
                objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                //Remove empty value from object
                for (var propName in objData) {
                    if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                        delete objData[propName];
                    }
                }

                const refStockData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`).doc(objData.documentID);
                refStockData.set(objData, { merge: true }).then((docRef) => {
                    //console.log(docRef);				
                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });
            }
        }
    }

    isValidForInvoice = () => {
        let state = Object.assign({}, this.state.fields);
        let _isbreak = false;

        ["isContact", "isVehicle"].some(item => {
            if (item === 'isContact') {
                if (!state[item]) {
                    Swal.fire({
                        title: CommonHelper.showLocale(this.props, 'Please add contact'),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                        cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                    }).then(result => {
                        if (result.value) {
                            this.handleMandatoryContactOpen('addContact');
                            _isbreak = true;
                            return true;
                        }
                    });
                    _isbreak = true;
                    return true;
                }
            } else if (item === 'isVehicle') {
                if (!state['vehicle']) {
                    Swal.fire({
                        title: CommonHelper.showLocale(this.props, 'Please add vehicle'),
                        icon: 'info',
                        showCancelButton: true,
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                this.handleMandatoryVehicleOpen();
                            }, 200);

                            _isbreak = true;
                            return true;
                        }
                    });
                    _isbreak = true;
                    return true;
                }

            }

        });

        if (_isbreak)
            return false;
        else {
            return true;
        }


    };

    //#region TRADE-IN CHANGES
    isValidForTradeIn = (isLink, isLinkAppraisal) => {
        if (this.state.fields.isContact) {
            const { pipeline, stage, isTradeIn } = this.state.fields;
            if (isTradeIn ? this.isValidForStageUpdate(pipeline, stage, isLink ? "tradeInLink" :
                isLinkAppraisal ? "tradeInLinkAppraisal" : "tradeIn") : this.isValidForStatusUpdate("isTradeIn")) {
                if (isLink) {
                    this.setState({
                        sendLinkModal: {
                            show: true,
                            tradeinID: null,
                        },
                    });
                } else if (isLinkAppraisal) {
                    this.setState({ linkAppraisalModal: true })
                } else {
                    this.setState({
                        tradeInModal: {
                            show: true,
                            tradeinID: "",
                            title: "addTradeIn",
                            clsActive: "overlay-modal active",
                        },
                    });
                }
                return true;
            } else return false;
        } else {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, "Please add contact"),
                icon: "info",
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
                cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
            }).then((result) => {
                if (result.value) {
                    this.handleContactOpen("addContact");
                }
            });
            return false;
        }
    };

    handleReSendLink = (tradeinID) => {
        this.setState({
            sendLinkModal: {
                show: true,
                tradeinID: tradeinID,
            },
        });
    };

    handleCloseSendLink = (data, tradeinID) => {
        if (!_.isEmpty(data)) {
            toast.notify((<div style={{ fontSize: `14px` }}>
                <span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span>
                {tradeinID ? 're-sending...' : 'sending...'}
            </div>),
                {
                    position: 'top',
                    duration: null
                },
            )

            const objData = Object.assign({});
            const { fields, contact } = this.state;
            const { dealersettings } = this.props;

            if (!tradeinID) {
                objData.addedBy = this.props.dealersettings
                    ? this.props.dealersettings.id
                    : "";
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = "web";
                objData.documentID = firestoreDB(this.props.dealersettings).firestore().collection("tradeins").doc().id;

                if (!_.isEmpty(contact)) {
                    let _contact = Object.assign({}, contactBasicDetailsVM);
                    for (let [key, value] of Object.entries(contact)) {
                        if (_contact.hasOwnProperty(key)) _contact[key] = value;
                    }
                    objData.contact = _contact;
                    objData.contactID = _contact.documentID;
                }

                if (!_.isEmpty(fields)) {
                    let _service = Object.assign({}, objServiceBasicVM);
                    for (let [key, value] of Object.entries(fields)) {
                        if (_service.hasOwnProperty(key)) _service[key] = value;
                    }
                    objData.service = _service;
                    objData.serviceID = _service.documentID;
                }
            } else {
                objData.documentID = tradeinID;
            }

            objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : "";
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = "web";
            objData.isDeleted = false;
            objData.clientID = fields.clientID ? fields.clientID : null;
            objData.projectId = fields.projectId ? fields.projectId : dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            objData.linkURL = "tradein/customer/" + objData.documentID;

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("tradeins").doc(objData.documentID);
            updateRef.set(objData, { merge: true }).then((docRef) => {
                // toast.notify('Trade-In saved successfully', {
                // 	duration: 2000
                // })

                let _postObject = {
                    documentID: objData.documentID,
                    sendSMS: data.sendSMS ? true : false,
                    phone: data.phone ? data.phone : null,
                    phoneCode: data.phoneCode ? data.phoneCode : null,
                    sendEmail: data.sendEmail ? true : false,
                    email: data.email ? data.email : null,
                    projectId: objData.projectId ? objData.projectId : null,
                };

                if (!_.isEmpty(data.emails)) {
                    let _data = Array.prototype.map.call(data.emails.filter(item => !_.isEmpty(item.value)), function (item) { return item.value ? item.value : ''; }).join(",")
                    _postObject.ccEmails = _data;
                }

                //console.log("_postObject", _postObject);

                let _logNote = `Trade-In link ${tradeinID ? 're-sent' : 'sent'}. ${data.phone ? '\nPhone: ' + data.phone : ''} ${(data.email ? ('\nEmail: ' + data.email) : '')} ${(data.ccEmails ? ('\nCC Emails: ' + data.ccEmails) : '')}`;
                const tradeinSendLink = window.firebase.functions().httpsCallable('tradein-sendLink');
                tradeinSendLink(_postObject).then((data) => {
                    toast.closeAll();
                    if (data.data.success === true) {
                        toast.notify(`Trade-In link ${tradeinID ? 're-send' : 'send'} successfully.`, {
                            duration: 2000
                        })
                        if (objData.serviceID)
                            CommonHelper.saveChangeLog(objData.clientID, null, objData.serviceID, _logNote, "tradein", "service");

                    } else {
                        Swal.fire(data.data.message, "", "error");
                    }
                });
            })
                .catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
        }
        this.setState({
            sendLinkModal: {
                show: false,
                tradeinID: null,
            },
        });
    };

    handleTradeInClose = (tradeinID) => {
        let state = Object.assign({}, this.state.fields);
        if (tradeinID && !state.isTradeIn) {
            let objData = {};
            objData.isTradeIn = true;
            objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : "";
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = "web";
            objData = this.handleMoveStageForStatusChange("isTradeIn", objData);

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("serviceJobs").doc(this.state.serviceID);
            updateRef.set(objData, { merge: true })
                .then((docRef) => { })
                .catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
        }

        this.setState({
            showEvalScan: false,
            tradeInModal: {
                show: false,
                tradeinID: "",
                title: "",
                clsActive: "",
            },
        });
    };

    handleAutograbClose = () => {

        this.setState({
            autoGrabModal: {
                show: false,
                tradeinID: "",
                title: "",
                clsActive: "",
            },
        });
    };

    handleEditTradeIn = (tradeinId, sent) => {
        this.setState({
            tradeInModal: {
                show: true,
                tradeinID: tradeinId,
                title: "tradeIn",
                clsActive: "overlay-modal active",
            },
        });
    };

    handleAutoGrap = (tradeinId, vehicle) => {
        this.setState({
            autoGrabModal: {
                show: true,
                tradeinID: tradeinId,
                selectedVehicle: vehicle,
                title: "Market Overlay",
                clsActive: "overlay-modal active",
            },
        });
    };

    handleCloseLinkAppraisal = (tradeinProID, tradeinProData, isRelink) => {
        const { fields, serviceID, contact, dealersettings } = this.state;
        let tradeinID = tradeinProData?.tradeinID ? tradeinProData?.tradeinID : tradeinProData?.tradein?.documentID
        let _oldEnqID = tradeinProData?.tradein?.serviceID ? tradeinProData.tradein.serviceID : tradeinProData?.tradein?.service?.documentID
        let _oldEnq = tradeinProData?.tradein?.service
        if (tradeinProID && tradeinID && tradeinProData) {

            // update tip
            let state = Object.assign({});
            state.ownerService = fields.owner ? fields.owner : null;
            state.modifiedBy = dealersettings ? dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
            state.modifiedFrom = 'web';

            let tradein = tradeinProData.tradein
            let _enqbasicVM = Object.assign({}, objServiceBasicVM);
            for (let [key, value] of Object.entries(fields)) {
                if (_enqbasicVM.hasOwnProperty(key)) _enqbasicVM[key] = value;
            }
            Object.keys(_enqbasicVM).forEach(key => _enqbasicVM[key] === undefined ? delete _enqbasicVM[key] : {});

            if (!_.isEmpty(tradein)) {
                let _vehicle = Object.assign({}, tradein);
                _vehicle.serviceID = serviceID;
                _vehicle.service = _enqbasicVM;
                _vehicle.linkedAppraisalService = true;
                Object.keys(_vehicle).forEach(key => _vehicle[key] === undefined ? delete _vehicle[key] : {});
                state['tradein'] = _vehicle;
            }

            if (!_.isEmpty(tradeinProData?.tags)) {
                let tags = Object.assign([], tradeinProData?.tags);
                if (isRelink) tags = tags.filter(item => item.type !== 'service');
                tags.push({ type: 'service', refID: serviceID, subType: 'linked' })
                state.tags = tags;
            }
            else
                state.tags = [{ type: 'service', refID: serviceID, subType: 'linked' }];


            const batch = firestoreDB(dealersettings).firestore().batch();
            batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinProID}`), state, { merge: true });

            // update tradein
            let tradein_state = Object.assign({
                serviceID: serviceID,
                service: _enqbasicVM,
                status: tradeinProData?.status,
                linkedAppraisalService: true,
                sent: true,
                modifiedBy: state.modifiedBy,
                modifiedFrom: 'web',
                modifiedDate: state.modifiedDate
            });
            Object.keys(tradein_state).forEach(key => tradein_state[key] === undefined ? delete tradein_state[key] : {});

            batch.set(firestoreDB(dealersettings).firestore().doc(`tradeins/${tradeinID}`), tradein_state, { merge: true });

            // update service
            if (tradeinID && !fields.isTradeIn) {
                let objData = {};
                objData.isTradeIn = true;
                objData.modifiedBy = state.modifiedBy;
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = "web";
                objData = this.handleMoveStageForStatusChange("isTradeIn", objData);
                batch.set(firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}`), objData, { merge: true });
            }


            batch.commit().then(snapshot => {

                toast.notify('Appraisal linked successfully', {
                    duration: 2000
                })

                const batch_primary = window.firebase.firestore().batch();
                //update change logs    
                let _makeModel = (tradein.make ? tradein.make + ' ' : '') + (tradein.model ? tradein.model + ' ' : '') + (tradein.year ? tradein.year : '') + (tradein.regNo ? ', ' + tradein.regNo : '');
                //1 - service logs
                CommonHelper.saveChangeLog(fields.clientID, fields.groupID, serviceID, `Appraisal has been linked with this service. \n(Valuation no. ${tradeinProData.displayID?.toUpperCase()})` + (_makeModel ? ' (' + _makeModel + ')' : ''), 'tradein', 'service', batch_primary);
                //2 - tip logs
                CommonHelper.saveChangeLog(fields.clientID, fields.groupID, tradeinProID, `Appraisal has been linked with the service. \n(Service no. ${fields?.displayID?.toUpperCase()})`, 'tradein', 'tradeinPro', batch_primary);

                if (isRelink) { //if relink , update with change log
                    //1 - service logs
                    CommonHelper.saveChangeLog(tradein.clientID, tradein.groupID, _oldEnqID, `Appraisal has been unlinked from this service. \n(Valuation no. ${tradeinProData.displayID?.toUpperCase()})` + (_makeModel ? ' (' + _makeModel + ')' : ''), 'tradein', 'service', batch_primary);
                    //2 - tip logs
                    CommonHelper.saveChangeLog(fields.clientID, fields.groupID, tradeinProID, `Appraisal has been unlinked from the service. \n(Service no. ${_oldEnq?.displayID?.toUpperCase()})`, 'tradein', 'tradeinPro', batch_primary);

                }
                batch_primary.commit();
            }).catch((error) => {
                console.error("Error updating document: ", error);
            });

        }

        this.setState({ linkAppraisalModal: false })

    }

    //#endregion

    //#endregion
    render() {

        //console.log('R E - R E N D E R - E N Q U I R Y - E D I T ');
        let { documentID, clientID, displayID, contactID, loanAppointmentID,
            pipeline, stage, roNumber, status, owner,
            serviceAdvisor, technician, partsInterpretor, workshopManager,
            checkInBy, vehicle, isFav, slot, appointmentType,
            serviceType, internalTaxExemption, nextService,
            checkInDate, completionDate, appointmentDate, stageDate, timeOfDay,
            isServicePackage, isContact, isVehicle, isTradeIn, isChecklist, isBbtChecklist, isFiles,
            isLoan, technicians, technicianStatus,
            stageHistory, images, videos, marks, parts,
            teammates, mentions, ownerResponse,
            totalServiceCost, totalDeclinedCost,
            addedBy, addedDate, isDeleted, modifiedBy, modifiedDate, modifiedFrom, addedFrom,
            spokeTo, jobAllocated, jobConfirmed, partsConfirmed, workshopConfirmed,
            taxPercentage, taxType,
            clientContactDate, startDate, completeDate, quoteSentDate, responseDate,
            discount, discountType, engageNewContact
        } = this.state.fields;

        const _isDiscountApplied = discount && discountType ? true : false;
        const _isTaxIncluded = (serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType ? true : false;
        const _dispalyTax = _isTaxIncluded ? `(${taxType} Incl.)` : `(${taxType ? taxType : 'Tax'} Excl.)`

        const _slotValue = !_.isEmpty(slot) ? slot.value : null

        const { dob, businessContact, gdprDeleted,
            gender, address, isVIP, contactType, marketingOptinEmail, marketingOptinSMS,
            company, licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL,
            phone, phoneCode, email, optinPhone, optinSMS, optinEmail, optinPost, banLoanVehicle
        } = this.state.contact;

        const {
            servicePackages, serviceNotes, serviceHistory, serviceInvoices,
            serviceLoanStocks, serviceLoanApps, testdrives,
            activeCntKey, activeVehKey, videoModal,
            activeLeftKey, activeRightKey, fullServiceCost,
            contactModal, vehicleModal, recommendationModal, partModal,
            ownedModal, showpanel, fileModal, showSharePanel,
            contact, slotTimes, showServiceDetails,
            allUsers, allTeamUsers, allAdvisorUsers, allTechnicianUsers,
            allPartsUsers, allWorkshopUsers,
            currentSlots, cropShow, showCanvas, showAddOns, showNotes,
            showCheckLists, showAddOnsInfo, showBBTCheckLists,
            showCustomerApproval, showConfrimQuotation,
            imageURL, imageURLs, pipelinePopOver, costPopOver,
            showDiscountService, showDiscountRecomm, showDiscountPack,
            serviceVideos, showStatusRequestModal, showLoanAppointmentModal,
            testDriveModal, fleetModal, showStocks, tradeInModal, autoGrabModal, showEvalScan,
            sendLinkModal, linkAppraisalModal, showReasons, expanded
        } = this.state;

        const _serviceVideos = serviceVideos.filter(item => item.recordID === this.state.serviceID)

        let _recommRequiredList = this.state.recommendations.filter(item => item.choice === recommendationChoice.Required)

        const { dealersettings, groupUsers, clientUsers } = this.props;

        let _client = clientID && !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            !_.isEmpty(dealersettings.group.clients) &&
            dealersettings.group.clients[clientID] ? dealersettings.group.clients[clientID] : dealersettings.client;

        const _clientService = (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.clientSettings) &&
            !_.isEmpty(dealersettings.client.clientSettings.service)) ? dealersettings.client.clientSettings.service : null;

        const _settings = _client && _client.services;

        const _hasNoPipelineAccess = pipeline && _settings && !_settings.pipelines.some(item => item.value === pipeline) ? true : false;

        const _servicePermissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.servicePermissions)) ? dealersettings.rolePermissions.servicePermissions : null);

        const currencySymbol = ((!_.isEmpty(_client) &&
            !_.isEmpty(_client.currency)) ? _client.currency.symbol + ' ' : '$ ');

        const setttings = _client && _client.settings;

        let dealerNameContact = '';
        if (contact && contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem'))
            dealerNameContact = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);

        const _jobStatus = status && status === serviceStatus.OWNERRESPONDED ? ownerResponse : status;

        const isCheckInJobDone = (_jobStatus === serviceStatus.NEWSERVICE || _jobStatus === serviceStatus.CANCELLED || _jobStatus === serviceStatus.SCHEDULED || _jobStatus === serviceStatus.CHECKIN) ? false : true

        const isScheduledJobDone = (_jobStatus === serviceStatus.NEWSERVICE || _jobStatus === serviceStatus.CANCELLED || _jobStatus === serviceStatus.SCHEDULED) ? false : true

        const _servicePackagesList = this.getvehiclePackages(Object.assign([], servicePackagesData), vehicle?.make, vehicle?.model)
        const servicePackageTypes = [];
        _servicePackagesList && _servicePackagesList.forEach((doc) => {
            servicePackageTypes.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        const servicePackageType = servicePackages ? Array.prototype.map.call(servicePackages, function (item) { return item.value; }) : [];

        const isPrivacyEnabled = CommonHelper.isPrivacyEnabled(dealersettings);

        const scheduledAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.scheduleAccess) ? true : false);
        const checkInAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.checkInAccess) ? true : false);
        const deleteServiceAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.deleteService) ? true : false);
        const previewServiceAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.previewService) ? true : false);
        const movePipeline = ((!_.isEmpty(_servicePermissions) && _servicePermissions.movePipeline) ? true : false);

        const confirmQuotationAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.confirmQuotationAccess) ? true : false);
        const sendToCustomerAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.sendToCustomerAccess) ? true : false);
        const workInProgressAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.workInProgressAccess) ? true : false);
        const completedAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.completedAccess) ? true : false);

        const technicianAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.technician) ? true : false);
        const partsInterpretorAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.partsInterpretor) ? true : false);
        const workshopManagerAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.workshopManager) ? true : false);
        const serviceAdvisorAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.serviceAdvisor) ? true : false);

        const technicianOnlyAccess = (technicianAccess && !partsInterpretorAccess && !workshopManagerAccess && !serviceAdvisorAccess) ? true : false;

        const viewOnlyAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.viewOnlyAccess) ? true : false);
        const modifyServiceAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.modifyService) ? true : false);


        let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0];
        const isStatusComplete = CommonHelper.isServiceStatusComplete(serviceAllStatus, status)
        const isStatusOnProgress = CommonHelper.isServiceStatusOnProgress(serviceAllStatus, _jobStatus);
        const isStatusApproved = CommonHelper.isServiceStatusApproved(serviceAllStatus, _jobStatus);
        const _isCompleted = (status === serviceStatus.COMPLETED || isStatusComplete) ? true : false;
        const canModifyService = (_isCompleted || _hasNoPipelineAccess || isDeleted || status === serviceStatus.CANCELLED || viewOnlyAccess || gdprDeleted) ? false : true;
        let _dynamicStatusMenu = CommonHelper.bindServiceStatusMenu(serviceAllStatus, _jobStatus, _servicePermissions, this.state.fields, _recommRequiredList, servicePackages, (canModifyService || (!canModifyService && _isCompleted)));
        let _dynamicTechStatusMenu = CommonHelper.bindTechStatusMenu(technicianAllStatus, technicianStatus, _servicePermissions, this.state.fields, canModifyService);

        const isAfterOwnerApproved = (isStatusComplete || isStatusOnProgress || _jobStatus === serviceStatus.OWNERAPPROVED || _jobStatus === serviceStatus.WORKINPROGRESS || _jobStatus === serviceStatus.COMPLETED) ? true : false

        const isOwnerResponsed = (isStatusComplete || isStatusOnProgress || _jobStatus === serviceStatus.OWNERAPPROVED || _jobStatus === serviceStatus.OWNERDECLINED || _jobStatus === serviceStatus.WORKINPROGRESS || _jobStatus === serviceStatus.COMPLETED) ? true : false

        const checklistAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.checklistAccess) ? true : false);
        const checklistBBTAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.checklistBBTAccess) ? true : false);
        const packagesAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.packagesAccess) ? true : false);
        const inspectionAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.inspectionAccess) ? true : false);
        const recommendationAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.recommendationAccess) ? true : false);
        const partsAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.partsAccess) ? true : false);
        const statusReRequestAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.statusReRequestAccess) ? true : false);

        const partsConfirmedAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.partsConfirmedAccess) ? true : false);
        const workshopConfirmedAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.workshopConfirmedAccess) ? true : false);
        const jobAllocatedAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.jobAllocatedAccess) ? true : false);

        const hasTechToModify = (_isCompleted === false && recommendationAccess && canModifyService) ? (technicianOnlyAccess && _jobStatus !== serviceStatus.TECHNICIANREQUESTED ? false : true) : false;

        const canAddNewRecommendations = (_isCompleted === false && recommendationAccess && canModifyService) ? (technicianOnlyAccess && _jobStatus !== serviceStatus.TECHNICIANREQUESTED ? false : true) : false;
        const canAddNewChecklistItems = (_isCompleted === false && checklistAccess && canModifyService) ? (technicianOnlyAccess && _jobStatus !== serviceStatus.TECHNICIANREQUESTED ? false : true) : false;
        const canAddNewBBTChecklistItems = (_isCompleted === false && checklistBBTAccess && canModifyService) ? (technicianOnlyAccess && _jobStatus !== serviceStatus.TECHNICIANREQUESTED ? false : true) : false;

        const _fullServiceCosts = this.showFullServiceCost();

        const discountAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.discountAccess) ? true : false);
        //const internalTaxExemption = true;//(!_.isEmpty(_clientService) && _clientService.internalTaxExemption) ? true : false;
        const discountEnabled = (!_.isEmpty(_clientService) && _clientService.discountEnabled) ? true : false;

        let _loanAppointmentStock = loanAppointmentID && !_.isEmpty(serviceLoanStocks) && serviceLoanStocks.filter(item => item.loanAppointments.some(a => a.documentID === loanAppointmentID))[0]
        let _loanAppointmentData = loanAppointmentID && !_.isEmpty(serviceLoanApps) && serviceLoanApps.filter(item => item.documentID === loanAppointmentID)[0]

        const addInvoice = ((!_.isEmpty(_servicePermissions) && _servicePermissions.addInvoice) ? true : false);
        const deleteInvoice = ((!_.isEmpty(_servicePermissions) && _servicePermissions.deleteInvoice) ? true : false);

        const _moduleSettings = (!_.isEmpty(dealersettings?.client?.moduleSettings) ? dealersettings.client.moduleSettings : null);

        const serviceInvoiceEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.serviceInvoice) &&
            _moduleSettings.serviceInvoice.enabled) ? true : false);

        const tradeinPlusEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.tradeinPlus) &&
            _moduleSettings.tradeinPlus.enabled) ? true : false);

        const tradeinProEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.tradeinPro) &&
            _moduleSettings.tradeinPro.enabled) ? true : false);

        const disableTradeInLink = (dealersettings?.client?.clientSettings?.disableTradeInLink) ? true : false;

        const aircallEnabled = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
            dealersettings.client.integrations.filter(e => e.type === "aircall")[0] &&
            dealersettings.client.integrations.filter(e => e.type === "aircall")[0].active === true &&
            dealersettings.client.integrations.filter(e => e.type === "aircall")[0].enabled === true
        ) ? true : false);

        const evalexpertEnabled = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
            dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0] &&
            dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0].active === true &&
            dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0].enabled === true
        ) ? true : false);

        return this.state.serviceID ? (
            <>
                <div className='middle-wrapper'>
                    {
                        status === serviceStatus.NEWSERVICE
                            ?
                            <>
                                <div className="new-enq-header">
                                    <div className="new-enq-button">
                                        {
                                            owner === localStorage.uid
                                                ?
                                                <>
                                                    <button type="button"
                                                        className="btn btn-common-fullscreen btn-danger float-right ml-2"
                                                        onClick={(e) => { this.handleDeleteService(e, 'cancel'); }}>
                                                        <Translate text={'cancel'} />
                                                    </button>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        <button type="button"
                                            className="btn btn-common-fullscreen button-blue float-right"
                                            onClick={(e) => { e.preventDefault(); this.scheduleJob(serviceStatus.SCHEDULED); }}>
                                            <Translate text={'confirm'} />
                                        </button>
                                    </div>
                                    <h4 className="modal-title" > <Translate text={'Add New Appointment'} /></h4>
                                </div>
                            </>
                            :
                            <>
                            </>
                    }

                    <div className="service-section-detailswrap">
                        <div className="service-section-detail-head">
                            <div className="float-left  enquiry-details-title-wrap">
                                {
                                    (isContact)
                                        ?
                                        <>
                                            <div className='enquiry-details-contact'>
                                                {businessContact ? (
                                                    <i className={`ico icon-company ${contactType && contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''} mr-1`}></i>
                                                ) : (
                                                    <i className={`ico icon-Contacts ${contactType && contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''} mr-1`}></i>
                                                )}

                                                <a
                                                    href='#'
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.props.history.push(
                                                            '/contacts/details/' +
                                                            this.state.contact.documentID +
                                                            '?back-url=/service/details/' +
                                                            this.state.serviceID,
                                                        );
                                                    }}
                                                >
                                                    {CommonHelper.displayContact(titles, this.state.contact, '--')}
                                                </a>
                                            </div>

                                            <>{CommonHelper.displayPreferredContact(this.state.contact)}</>

                                            {
                                                isVIP ?
                                                    (<span className='enquiry-details-preferred'> ({'VIP'})</span>) :
                                                    (<></>)
                                            }

                                            <span className='enquiry-details-favourite-ico'>
                                                {
                                                    (isFav)
                                                        ?
                                                        <>
                                                            <a href='#' onClick={e => { e.preventDefault(); }}>
                                                                <i
                                                                    className='ico icon-star-active'
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.saveServiceField('isFav', false);
                                                                    }}
                                                                ></i>
                                                            </a>

                                                        </>
                                                        :
                                                        <>
                                                            <a href='#' onClick={e => { e.preventDefault(); }}>
                                                                <i
                                                                    className='ico icon-star'
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.saveServiceField('isFav', true);
                                                                    }}
                                                                ></i>
                                                            </a>
                                                        </>
                                                }
                                            </span>

                                            <div className="enquiry-details-optin">
                                                {
                                                    optinPhone === false
                                                        ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={<Tooltip><Translate text={'optoutPhone'} /></Tooltip>}
                                                            >
                                                                <i className={`ico icon-no-call`}></i>
                                                            </OverlayTrigger>
                                                        </span>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    optinSMS === false
                                                        ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={<Tooltip><Translate text={'optoutSMS'} /></Tooltip>}
                                                            >
                                                                <i className={`ico icon-no-sms`}></i>
                                                            </OverlayTrigger>
                                                        </span>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    optinEmail === false
                                                        ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={<Tooltip><Translate text={'optoutEmail'} /></Tooltip>}
                                                            >
                                                                <i className={`ico icon-no-email`}></i>
                                                            </OverlayTrigger>
                                                        </span>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    optinPost === false
                                                        ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={<Tooltip><Translate text={'optoutPost'} /></Tooltip>}
                                                            >
                                                                <i className={`ico icon-no-post`}></i>
                                                            </OverlayTrigger>
                                                        </span>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    marketingOptinEmail === false
                                                        ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={<Tooltip><Translate text={'marketingoptoutEmail'} /></Tooltip>}
                                                            >
                                                                <i className={`ico icon-no-email`}></i>
                                                            </OverlayTrigger>
                                                        </span>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    marketingOptinSMS === false
                                                        ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={<Tooltip><Translate text={'marketingoptoutSMS'} /></Tooltip>}
                                                            >
                                                                <i className={`ico icon-no-sms`}></i>
                                                            </OverlayTrigger>
                                                        </span>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    banLoanVehicle ? <span><OverlayTrigger
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip><Translate text={'banLoanVehicle'} /></Tooltip>
                                                        }
                                                    >
                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, `ico icon-ban-loan`)}></i>
                                                    </OverlayTrigger></span> : <></>
                                                }
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                                <div className="enquiry-subtitle">
                                    {
                                        (!_.isEmpty(vehicle))
                                            ?
                                            <>
                                                <span>

                                                    {(vehicle.make ? vehicle.make : '') + ' ' + (vehicle.model ? vehicle.model : '') + ' ' + (vehicle.year ? vehicle.year : '')}

                                                </span>
                                            </>
                                            :
                                            <>
                                            </>
                                    }

                                </div>
                                {
                                    (isContact === false && isVehicle === false)
                                        ?
                                        <div className="addnew-enquiry-title">{displayID ? displayID : (`VSR-` + this.state.serviceID)}</div>
                                        :
                                        <></>
                                }
                            </div>
                            <div className="float-right">
                                {
                                    (_jobStatus)
                                        ?
                                        <div className='current-status mt-3'>

                                            {
                                                _objCurrentStatus
                                                    ?
                                                    <>
                                                        <Translate text={'currentStatus'} />
                                                        <div className={`badge badge-pill badge-empty ml-2`}
                                                            style={{ background: (_objCurrentStatus && _objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                            {
                                                                !_.isEmpty(_dynamicStatusMenu)
                                                                    ?
                                                                    <DropDownMenu
                                                                        text={_objCurrentStatus ? _objCurrentStatus.name : status}
                                                                        keyVal="165898"
                                                                        tIndex="-13"
                                                                        menu={_dynamicStatusMenu}
                                                                        handleChange={this.handleDynamicStatus}
                                                                    >
                                                                    </DropDownMenu>
                                                                    :
                                                                    <Translate
                                                                        text={_objCurrentStatus ? _objCurrentStatus.name : _jobStatus.toLowerCase()}
                                                                        upperCase={true}
                                                                    />
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <Translate text={'currentStatus'} />
                                                        <div className={`badge badge-pill badge-service-${_jobStatus.toLowerCase()} ml-2`} >
                                                            <Translate
                                                                text={_jobStatus.split(/(?=[A-Z])/).join(' ').toLowerCase()}
                                                                upperCase={true}
                                                            />
                                                        </div>

                                                    </>
                                            }

                                        </div>
                                        :
                                        <></>

                                }

                                {
                                    (isCheckInJobDone)
                                        ?
                                        <div className='current-status mt-3 ml-3'>

                                            {
                                                !_.isEmpty(_dynamicTechStatusMenu)
                                                    ?
                                                    <>
                                                        <Translate text={'technicianStatus'} />
                                                        <div className={`badge badge-pill badge-empty ml-2`}
                                                            style={{ background: CommonHelper.getFieldByValue(technicianAllStatus, technicianStatus, 'color', '#b0babe') }}>
                                                            <DropDownMenu
                                                                text={technicianStatus ? CommonHelper.getNameByValue(technicianAllStatus, technicianStatus) : 'Select status'}
                                                                keyVal="178341"
                                                                tIndex="-16"
                                                                menu={_dynamicTechStatusMenu}
                                                                handleChange={this.handleStatusChange}
                                                            >
                                                            </DropDownMenu>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            technicianStatus
                                                                ?
                                                                <>
                                                                    <Translate text={'technicianStatus'} />
                                                                    <div
                                                                        className={`badge badge-pill badge-service-${technicianStatus.toLowerCase()} ml-2`}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    >
                                                                        <Translate
                                                                            text={CommonHelper.getNameByValue(technicianAllStatus, technicianStatus)}
                                                                            upperCase={true}
                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }


                                                    </>
                                            }

                                        </div>
                                        :
                                        <></>

                                }
                                <div className="status-btn-wrap">

                                    {
                                        canModifyService
                                            ?
                                            <>
                                                {
                                                    ((status === serviceStatus.ADVISORREQUESTED ||
                                                        status === serviceStatus.OWNERREQUESTED ||
                                                        status === serviceStatus.OWNERRESPONDED || isStatusApproved) &&
                                                        isContact && (!_.isEmpty(_recommRequiredList) || !_.isEmpty(servicePackages)))
                                                        ?
                                                        <>
                                                            {
                                                                confirmQuotationAccess
                                                                    ?
                                                                    <a href="#"
                                                                        className={`btn btn-common button-${jobConfirmed ? 'confirm' : 'blue'}`}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleConfirmQuotation();

                                                                        }}>
                                                                        {jobConfirmed ? <i className="ico icon-dynamic-checkbox mr-2"></i> : <></>}
                                                                        <Translate text={jobConfirmed ? 'Re-Confirm Quotation' : 'Confirm Quotation'} upperCase={true} />
                                                                    </a>
                                                                    : <></>
                                                            }
                                                            {
                                                                sendToCustomerAccess
                                                                    ?
                                                                    <a href="#"
                                                                        className={`btn btn-common button-${quoteSentDate ? 'delivered' : 'grey'}`}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleSendToCustomerApproval();

                                                                        }}>
                                                                        {quoteSentDate ? <i className="ico icon-dynamic-checkbox mr-2"></i> : <></>}
                                                                        <Translate text={quoteSentDate ? 'Resend To Customer' : 'Send To Customer'} upperCase={true} />
                                                                    </a>
                                                                    : <></>
                                                            }

                                                        </>
                                                        :
                                                        <></>
                                                }

                                                {/* {
                                                    (_jobStatus === serviceStatus.OWNERAPPROVED && workInProgressAccess)
                                                        ?
                                                        <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                            e.preventDefault();
                                                            if (jobConfirmed) {
                                                                this.startJob();
                                                            }
                                                            else {
                                                                Swal.fire('Please confirm the quotation.', '', 'info');
                                                                return false;
                                                            }


                                                        }}> <Translate text={'Start Job'} upperCase={true} /></a>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    (_jobStatus === serviceStatus.WORKINPROGRESS && completedAccess)
                                                        ?
                                                        <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                            e.preventDefault();
                                                            this.completeJob();

                                                        }}><Translate text={'Complete Job'} upperCase={true} /> </a>
                                                        :
                                                        <></>
                                                }

                                                {(() => {

                                                    if (status === serviceStatus.CHECKIN && (checkInAccess || serviceAdvisorAccess)) {
                                                        return (
                                                            <>
                                                                <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (this.isValidateAppointment()) {
                                                                        this.sendJobRequest();
                                                                    }

                                                                }}><Translate text={'Request To Technician'} /></a>
                                                            </>
                                                        )
                                                    }
                                                    else if (status === serviceStatus.TECHNICIANREQUESTED && (technicianAccess || serviceAdvisorAccess)) {
                                                        return (
                                                            <>
                                                                <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.sendJobRequest();
                                                                }}> <Translate text={'Request To Parts'} /> </a>
                                                            </>
                                                        )
                                                    }
                                                    else if (status === serviceStatus.PARTSREQUESTED && (partsInterpretorAccess || serviceAdvisorAccess)) {
                                                        return (
                                                            <>
                                                                <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.sendJobRequest();
                                                                }}><Translate text={'Request To  WorkShop'} /> </a>
                                                            </>
                                                        )
                                                    }
                                                    else if (status === serviceStatus.WORKSHOPREQUESTED && (workshopManagerAccess || serviceAdvisorAccess)) {
                                                        return (
                                                            <>
                                                                <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (this.handleValidRecommFields(true) === false) {
                                                                        //Swal.fire('Please fill all the required recommendation fields.', '', 'info')
                                                                        return false;
                                                                    }
                                                                    else {
                                                                        this.sendJobRequest();
                                                                    }
                                                                }}> <Translate text={'Request To Advisor'} /> </a>
                                                            </>
                                                        )
                                                    }

                                                })()}

                                                {
                                                    (status === serviceStatus.SCHEDULED) && checkInAccess
                                                        ?
                                                        <>
                                                            <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                                e.preventDefault();
                                                                if (this.isValidateAppointment()) {
                                                                    this.checkInJob();
                                                                }
                                                            }}> <Translate text={'Check In'} /> </a>
                                                        </>
                                                        :
                                                        <></>
                                                } */}

                                                {/* {
                                                    statusReRequestAccess && (status === serviceStatus.OWNERREQUESTED ||
                                                        status === serviceStatus.ADVISORREQUESTED ||
                                                        status === serviceStatus.WORKSHOPREQUESTED ||
                                                        status === serviceStatus.PARTSREQUESTED)
                                                        ?
                                                        <>
                                                            <a href="#" className="btn btn-common button-reopen" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.handleRequestStatusOpen();
                                                            }}> <Translate text={'Status Update'} upperCase={true} /> </a>
                                                        </>
                                                        :
                                                        <></>
                                                } */}



                                            </>
                                            :
                                            <></>
                                    }


                                    {
                                        (status === serviceStatus.SCHEDULED) && scheduledAccess
                                            ?
                                            <>
                                                <a href="#" className="btn btn-common button-red" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.cancelJob();
                                                }}> <Translate text={'Cancel Appointment'} upperCase={true} /></a>
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        (status === serviceStatus.CANCELLED) && scheduledAccess
                                            ?
                                            <>
                                                <a href="#" className="btn btn-common button-grey" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (this.isValidateAppointment()) {
                                                        this.scheduleJob(serviceStatus.NEWSERVICE);
                                                    }
                                                }}> <Translate text={'re-schedule'} upperCase={true} /> </a>
                                            </>
                                            :
                                            <></>
                                    }

                                </div>

                                {
                                    (previewServiceAccess || deleteServiceAccess)
                                        ?
                                        <>
                                            <Dropdown className='enquiry-view-more'>
                                                <Dropdown.Toggle as={CustomToggle} className='common-button'>
                                                    <i className='ico icon-more'></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    as={CustomMenu}
                                                    ChildClass="more-dropdown dropsub-fix"
                                                    xplacement="bottom-end"
                                                >
                                                    {
                                                        previewServiceAccess
                                                            ?
                                                            <>
                                                                <Dropdown.Item eventKey="1" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    const url = `/service/preview/${this.state.serviceID}?isView=y`;
                                                                    window.open(url, '_blank');
                                                                }}><i className="ico icon-waiver"></i><Translate text={'preview'} /></Dropdown.Item>

                                                                <Dropdown.Item eventKey="4" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (!vehicle?.vinNo) {
                                                                        Swal.fire({
                                                                            title: CommonHelper.showLocale(this.props, 'Please enter VIN.'),
                                                                            icon: 'info',
                                                                            showCancelButton: true,
                                                                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                                                                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                                                                        }).then(result => {
                                                                            if (result.value) {
                                                                                this.handleMandatoryVehicleOpen(vehicle);
                                                                            }
                                                                        });

                                                                    } else {
                                                                        const url = `/service/history/${this.state.serviceID}?isView=y`;
                                                                        window.open(url, '_blank');
                                                                    }


                                                                }}><i className="ico icon-history"></i><Translate text={'serviceHistory'} /></Dropdown.Item>
                                                            </>
                                                            :
                                                            <></>
                                                    }

                                                    <Dropdown.Item eventKey="3" onClick={(e) => { this.handleSharePanelOpen(e) }}><i className="ico icon-share"></i> <Translate text={'Share'} /> </Dropdown.Item>

                                                    {
                                                        deleteServiceAccess
                                                            ?
                                                            <Dropdown.Item eventKey="2" onClick={(e) => {
                                                                this.handleDeleteService(e);
                                                            }}><i className="ico icon-delete"></i>
                                                                <Translate text={'delete'} />
                                                            </Dropdown.Item>
                                                            :
                                                            <></>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </>
                                        :
                                        <></>
                                }

                            </div>
                        </div>

                        {(() => {
                            if (isContact) {
                                return (
                                    <div className="enquiry-detail-bottom">
                                        <div className="float-left mt-2">
                                            <ul className="enquiry-details">
                                                <li> <i className="ico icon-service-settings"
                                                    onDoubleClick={(e) => {
                                                        e.preventDefault();
                                                        console.log('props.dealersettings:-', dealersettings)
                                                        console.log('clientUsers:-', clientUsers)
                                                        console.log('groupUsers:-', groupUsers)
                                                    }}></i> {displayID ? displayID : (`VSR-` + this.state.serviceID)}</li>
                                                {
                                                    aircallEnabled && phone ? (
                                                        <li> <i className="ico icon-call"></i><a onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.dialNumber(CommonHelper.validPhone(phone, phoneCode), null, this?.state?.contact?.documentID, null, this.state.serviceID)
                                                        }} href='#' className='hyperlink-blue'>{phone ? CommonHelper.phoneFormat(phoneCode, phone, dealersettings) : '--'}</a></li>
                                                    ) : (
                                                        <li> <i className="ico icon-call"></i> {phone ? CommonHelper.phoneFormat(phoneCode, phone, dealersettings) : '--'}</li>
                                                    )
                                                }                                               
                                                <li> <i className="ico icon-Mail"></i>
                                                    {email ?
                                                        <SendMail
                                                            email={email}
                                                            service={this.state.fields}
                                                            dealersettings={this.props.dealersettings}
                                                            history={this.props.history}
                                                        >
                                                        </SendMail> : <>{'--'}</>}
                                                </li>
                                                {
                                                    (businessContact)
                                                        ?
                                                        <li>
                                                            <i className={`ico icon-Contacts ${contactType && contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''}`}></i>
                                                            {CommonHelper.getFullName(titles, this.state.contact, '--')}
                                                        </li>
                                                        :
                                                        <li> <i className="ico icon-company"></i> {company && company.name ? company.name : '--'}</li>

                                                }

                                                <li><i className="ico icon-date"></i>  <Translate text={'created'} /> - {addedDate ? moment.unix(addedDate.seconds).format('DD MMM YYYY') : '--'}</li>


                                            </ul>
                                        </div>
                                        <div className="float-right">
                                            <div className={`filter-item-btn filter-owner-btn ml-2 ${canModifyService ? '' : 'form-disable'}`}>
                                                <ReactSelect
                                                    options={_.orderBy(allUsers, ["searchlabel"], ["asc"])}
                                                    name={"owner"}
                                                    placeholder={'select ownership'}
                                                    onChange={this.handleSelectSave}
                                                    value={owner ? owner : addedBy}
                                                    classNamePrefix={`cursor-pointer ${this.state.errors["owner"]} basic-select`}
                                                    removeClearable={true}
                                                    isSearchable={true}
                                                    isDisabled={canModifyService ? false : true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                    </div>

                    <div className="enquiry-stagewrap">
                        <div className="enquiry-stagewrap-head">
                            <div className="float-left">
                                {
                                    pipeline
                                        ?
                                        <>
                                            <div
                                                className={`pipeline-info-drop`}
                                                title={CommonHelper.showPSAge(stageHistory, pipeline, null, 'pipeline')}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (movePipeline && canModifyService) {
                                                        this.setState({
                                                            pipelinePopOver: { showpopover: true, targetpopover: e.target }
                                                        });
                                                    }

                                                }}>
                                                <Translate text={'pipeline'} />
                                                <span className="pipline-arrow">
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                </span>

                                                {this.state.pipelines.find(
                                                    e => e.value === pipeline
                                                )
                                                    ? this.state.pipelines.find(
                                                        e => e.value === pipeline
                                                    ).label
                                                    : ''}
                                            </div>
                                        </>
                                        :
                                        <></>
                                }

                                {
                                    !canModifyService ? (
                                        <div className="pipeline-enquiry-status ml-2">
                                            <div className="badge badge-pill badge-readonly">
                                                <i className="ico icon-lock mr-1"> </i> <Translate text={'readonly'} upperCase={true} />
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }

                                {
                                    isDeleted ? (
                                        <div className="pipeline-enquiry-status ml-2">
                                            <div className="badge badge-pill badge-readonly">
                                                <i className="ico icon-delete mr-1"> </i> <Translate text={'deleted'} upperCase={true} />
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }

                                {
                                    gdprDeleted ? (
                                        <div className="pipeline-enquiry-status ml-2">
                                            <div className="badge badge-pill badge-merged">
                                                <i className="ico icon-delete mr-1"> </i> <Translate text={'gdprDeleted'} upperCase={true} />
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }

                            </div>
                            {!_.isEmpty(stageHistory) && (<div className="float-right mt-1">
                                <ul className="enquiry-overview">
                                    <li className="overview-blue">
                                        <Translate text={"pipelineAge"} />:&nbsp;<span>{CommonHelper.showPSAge(stageHistory, pipeline, stage, 'pipeline')}</span>
                                    </li>
                                    <li className="overview-orange">
                                        <Translate text={"stageAge"} />:&nbsp;<span>{CommonHelper.showPSAge(stageHistory, pipeline, stage)}</span>
                                    </li>
                                </ul>
                            </div>)}


                            {
                                isCheckInJobDone
                                    ?
                                    <>
                                        <div className="float-right mt-1">
                                            <ul className="enquiry-overview">
                                                <li className="overview-blue"><Translate text={'appointmentDate'} />: &nbsp;{appointmentDate ? moment(appointmentDate).format('DD MMM YYYY h:mm A') : '--'}</li>
                                                <li className="overview-orange"><Translate text={'slot'} />: &nbsp;{!_.isEmpty(slot) ? <>
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={<Tooltip>{slot.from}{'-'}{slot.to}</Tooltip>}
                                                    >
                                                        <span style={{ color: `${slot.color ? slot.color : '#f47815 '}` }}>
                                                            <i className="ico icon-service-timer"></i> {slot.name}
                                                            {
                                                                slot.isExpress ? <span className="express-inline-tag"><div className="badge red-ribbon ml-2 express-fix"><Translate text={'express'} upperCase={true} /></div></span> : <></>
                                                            }

                                                        </span>


                                                    </OverlayTrigger>
                                                </> : '--'}</li>
                                                <li className="overview-green"><Translate text={'Anticipated Completion Date'} />: &nbsp;{completionDate ? moment.unix(completionDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                                <li className="overview-red"><Translate text={'Next Service Due Date'} />: &nbsp;{nextService ? moment.unix(nextService.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </ul>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }



                        </div>
                        {
                            !_.isEmpty(this.state.defaulStages)
                                ?
                                <div className="enquiry-stagewrap-stages">
                                    <ul className="pipeline-step">
                                        {
                                            this.state.defaulStages.map((_stage, index) => {
                                                let _sittingDays = this.state.activeStageIndex >= index ? CommonHelper.handleSittingDays(stageHistory, pipeline, _stage) : '';
                                                return (
                                                    <OverlayTrigger
                                                        key={index}
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip><>{_stage.name} {_sittingDays ? <><br />{_sittingDays}</> : ''}</></Tooltip>
                                                        }
                                                    >
                                                        <li
                                                            key={index}
                                                            className={this.state.activeStageIndex >= index ? (this.handleStageComplete(_stage.value)) : ''}>
                                                            <a
                                                                href="#"
                                                                id={index}
                                                                className={(canModifyService || (!canModifyService && _isCompleted)) ? '' : 'form-disable'}
                                                                name={_stage.value}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    if (canModifyService || (!canModifyService && _isCompleted))
                                                                        this.handleStageChange(index, _stage.value)
                                                                }}
                                                            >
                                                                &nbsp;{_stage.name}
                                                            </a>
                                                        </li>
                                                    </OverlayTrigger>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                :
                                <></>

                        }

                    </div>

                    <div className="clearfix"></div>

                    <div className="enquirydetail-grid flex-column mt-4">
                        {/** LEFT TAB */}
                        <aside className="small-column mr-3 ">
                            <div className="enquiry-sidebar">
                                <Accordion defaultActiveKey="0" >
                                    {/** CONTACT INFO */}

                                    <Card>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            eventKey="0"
                                            onClick={(e) => {
                                                e.preventDefault();

                                                if (activeCntKey === '0')
                                                    this.handleCntActiveKey(null);
                                                else
                                                    this.handleCntActiveKey('0');
                                            }}>
                                            <div className="mb-0">
                                                {isContact ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                <div className="cardlink">
                                                    <a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeCntKey === '0' ? '' : 'collapsed'}`}>
                                                        <i className="ico icon-Contacts"></i> <Translate text={'contactInfo'} /> <i className="arrow-collapse"></i>
                                                    </a></div>
                                            </div>
                                        </Accordion.Toggle>
                                        {(() => {
                                            if (isContact) {
                                                return (
                                                    <>
                                                        {
                                                            canModifyService
                                                                ?
                                                                <>
                                                                    <div className='edit-button right-second-icon'>
                                                                        <a
                                                                            href='#'
                                                                            className='mini-button'
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                this.handleMandatoryContactOpen('editContact');
                                                                            }}
                                                                        >
                                                                            <i className='ico icon-edit'></i>
                                                                        </a>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }

                                                        <div className='secondary-button '>
                                                            <a
                                                                href='#'
                                                                className='mini-button'
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.history.push(
                                                                        '/contacts/details/' +
                                                                        this.state.contact.documentID +
                                                                        '?back-url=/service/details/' +
                                                                        this.state.serviceID,
                                                                    );
                                                                }}
                                                            >
                                                                <i className='ico icon-expand'></i>
                                                            </a>
                                                        </div>
                                                    </>
                                                );
                                            }
                                        })()}
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                {(() => {
                                                    if (isContact) {
                                                        return (
                                                            <>
                                                                <ContactInfo
                                                                    contact={contact}
                                                                    dealersettings={dealersettings}
                                                                ></ContactInfo>
                                                            </>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            canModifyService
                                                                ?
                                                                <>
                                                                    {
                                                                        (!_.isEmpty(engageNewContact))
                                                                            ?
                                                                            <>
                                                                                <div className="alert-table-box p-2 ">
                                                                                    <div className="alert alert-danger mb-2" role="alert">
                                                                                        <i className="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i>
                                                                                        <Translate text={'Contact details is not saved!'} />
                                                                                    </div>
                                                                                    <table className="table-fill">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="text-left label-text" width="30%"><Translate text={'name'} /></td>
                                                                                                <td className="text-left" width="70%">{CommonHelper.displayContact(titles, engageNewContact, '--')}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="text-left label-text"><Translate text={'phone'} /></td>
                                                                                                <td className="text-left">{engageNewContact && engageNewContact.phone ? CommonHelper.phoneFormat(engageNewContact.phoneCode, engageNewContact.phone, dealersettings) : '--'}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="text-left label-text"><Translate text={'email'} /></td>
                                                                                                <td className="text-left">{engageNewContact && engageNewContact.email ? engageNewContact.email : '--'}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <div className="text-center pt-3 pb-2">
                                                                                        <button
                                                                                            className="btn btn-sm btn-default"
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                                this.handleMandatoryContactOpen('addContact');
                                                                                            }}
                                                                                        ><i className="ico icon-add mr-1"></i> <Translate text={'add'} />{' '} </button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className='text-center p-4'>
                                                                                    <p><Translate text={'nocontactata'} /></p>
                                                                                    <button
                                                                                        className='btn btn-sm btn-default'
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            this.handleMandatoryContactOpen('addContact');
                                                                                        }}
                                                                                    >
                                                                                        <i className='ico icon-add mr-1'></i>{' '}
                                                                                        <Translate text={'add'} />{' '}
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <div className='text-center p-4'>
                                                                        <p><Translate text={'nocontactata'} /></p>
                                                                    </div>
                                                                </>
                                                        );
                                                    }
                                                })()}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>

                                <Accordion defaultActiveKey="1" >
                                    {/** VEHICLE INFO */}

                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={(e) => {
                                            e.preventDefault()

                                            if (activeVehKey === '1')
                                                this.handleVehActiveKey(null);
                                            else
                                                this.handleVehActiveKey('1');
                                        }}
                                        >
                                            <div className="mb-0">
                                                {isVehicle ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                <div className="cardlink">
                                                    <a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeVehKey === '1' ? '' : 'collapsed'}`}>
                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i><Translate text={'vehicle'} />

                                                        <i className="arrow-collapse"></i>
                                                    </a></div>
                                            </div>
                                        </Accordion.Toggle>
                                        {
                                            (_isCompleted === false && canModifyService)
                                                ?
                                                <div className="add-button"><a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleMandatoryVehicleOpen(vehicle);
                                                }} className="mini-button">
                                                    <i className={`ico icon-${!_.isEmpty(vehicle) ? 'edit' : 'add'}`}></i>
                                                </a>
                                                </div>
                                                :
                                                <>
                                                </>
                                        }

                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>

                                                {(() => {
                                                    if (!_.isEmpty(vehicle)) {
                                                        return (
                                                            <>
                                                                {
                                                                    !isVehicle
                                                                        ?
                                                                        <div className="alert alert-danger mb-2" role="alert">
                                                                            <i className="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i>
                                                                            <Translate text={'Vehicle details is not saved!'} />
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }


                                                                <VehicleInfo
                                                                    requirement={vehicle}
                                                                    handleEdit={this.handleMandatoryVehicleOpen}
                                                                    serviceID={this.state.serviceID}
                                                                    clientID={clientID}
                                                                    isDeivered={canModifyService ? _isCompleted : true}
                                                                    dealersettings={dealersettings}
                                                                    clientUsers={clientUsers}
                                                                    groupUsers={groupUsers}
                                                                >
                                                                </VehicleInfo>
                                                            </>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            (_isCompleted === false && canModifyService)
                                                                ?
                                                                <>
                                                                    <div className='text-center p-3'>
                                                                        <p><Translate text={'nodata'} /></p>
                                                                        <button
                                                                            className='btn btn-sm btn-default'
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                this.handleMandatoryVehicleOpen(vehicle);
                                                                            }}
                                                                        >
                                                                            <i className='ico icon-add mr-1'></i>{' '}
                                                                            <Translate text={'add'} />
                                                                        </button>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className='text-center p-3'>
                                                                        <p><Translate text={'nodata'} /></p>
                                                                    </div>
                                                                </>

                                                        );

                                                    }

                                                })()}

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>

                                <Accordion defaultActiveKey="2" >
                                    {/** LOAN VEHICLE INFO */}

                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={(e) => {
                                            e.preventDefault()

                                            if (activeVehKey === '2')
                                                this.handleVehActiveKey(null);
                                            else
                                                this.handleVehActiveKey('2');
                                        }}
                                        >
                                            <div className="mb-0">
                                                {isLoan ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                <div className="cardlink">
                                                    <a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeVehKey === '2' ? '' : 'collapsed'}`}>
                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, "ico icon-module-loan")}></i><Translate text={'Loan Vehicle'} />

                                                        <i className="arrow-collapse"></i>
                                                    </a></div>
                                            </div>
                                        </Accordion.Toggle>
                                        {
                                            (_isCompleted === false && canModifyService && _.isEmpty(testdrives))
                                                ?
                                                <div className="add-button"><a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.isValidForLoan();

                                                }} className="mini-button">
                                                    <i className={`ico icon-${!_.isEmpty(isLoan) ? 'edit' : 'add'}`}></i>
                                                </a>
                                                </div>
                                                :
                                                <>
                                                </>
                                        }

                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>

                                                {(() => {
                                                    if (!_.isEmpty(testdrives)) {
                                                        return (
                                                            <>
                                                                {
                                                                    testdrives.map((_testdrive, index) => (

                                                                        <TestDriveInfo
                                                                            key={index}
                                                                            testdrive={_testdrive}
                                                                            stockData={_testdrive.stock}
                                                                            handleEditTestDrive={this.handleEditTestDrive}
                                                                            isDeivered={canModifyService ? _isCompleted : true}
                                                                            dealersettings={this.props.dealersettings}
                                                                        >
                                                                        </TestDriveInfo>

                                                                    ))
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            (_isCompleted === false && canModifyService)
                                                                ?
                                                                <>
                                                                    <div className='text-center p-3'>
                                                                        <p><Translate text={'nodata'} /></p>
                                                                        <button
                                                                            className='btn btn-sm btn-default'
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                this.isValidForLoan();
                                                                            }}
                                                                        >
                                                                            <i className='ico icon-add mr-1'></i>{' '}
                                                                            <Translate text={'add'} />
                                                                        </button>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className='text-center p-3'>
                                                                        <p><Translate text={'nodata'} /></p>
                                                                    </div>
                                                                </>

                                                        );

                                                    }

                                                })()}

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>


                                <Accordion defaultActiveKey="00" >

                                    {/** TRADE IN INFO */}
                                    <Card>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            eventKey="04"
                                            onClick={(e) => {
                                                e.preventDefault();

                                                if (activeLeftKey === "04")
                                                    this.handleLeftActiveKey(null);
                                                else this.handleLeftActiveKey("04");
                                            }}
                                        >
                                            <div className="mb-0">
                                                {isTradeIn ? (
                                                    <div className="ribbon-wrapper-featured">
                                                        <div className="featured"></div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                <div className="cardlink">
                                                    <a
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                        className={`btn btn-link ${activeLeftKey === "04" ? "" : "collapsed"
                                                            }`}
                                                    >
                                                        <i
                                                            className={CommonHelper.getVehicleIcons(
                                                                dealersettings?.client?.category,
                                                                "ico icon-tradein"
                                                            )}
                                                        ></i>
                                                        <Translate text={"tradeIn"} />
                                                        {isTradeIn && this.state.tradeIns.length > 0 ? (
                                                            <span className="badge badge-pill badge-cafe ml-1">
                                                                {this.state.tradeIns.length}
                                                            </span>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <i className="arrow-collapse"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                        {_isCompleted === false && canModifyService ? (
                                            <>
                                                {evalexpertEnabled ? (
                                                    <div className={`evalsearch-logo-button ${disableTradeInLink ? "evalsearch-logo-right" : ""}`}>
                                                        <a
                                                            href="#"
                                                            className="btn btn-secondary"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ showEvalScan: true });
                                                            }}
                                                        >
                                                            <img src={_images.evalexpertLogo} />
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}

                                                {tradeinProEnabled && (
                                                    <div className={`edit-button right-${disableTradeInLink ? 'second' : 'third'}-icon`}>
                                                        <a
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.isValidForTradeIn(false, true);
                                                            }}
                                                            className="mini-button btn-mini-secondary"
                                                        >
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip>
                                                                        <Translate text={"Link Appraisal"} />
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i className="fa fa-link custom-icon-preview"> </i>
                                                            </OverlayTrigger>
                                                        </a>
                                                    </div>
                                                )}

                                                {disableTradeInLink ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        <div className="edit-button right-second-icon">
                                                            <a
                                                                href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.isValidForTradeIn(true);
                                                                }}
                                                                className="mini-button btn-mini-primary"
                                                            >
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            <Translate text={"Send Link"} />
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="ico icon-email"> </i>
                                                                </OverlayTrigger>
                                                            </a>
                                                        </div>
                                                    </>
                                                )}



                                                <div className="secondary-button">
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.isValidForTradeIn();
                                                        }}
                                                        className="mini-button"
                                                    >
                                                        <i className="ico icon-add"></i>
                                                    </a>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        <Accordion.Collapse eventKey="04">
                                            <Card.Body>
                                                {(() => {
                                                    if (!_.isEmpty(this.state.tradeIns)) {
                                                        return (
                                                            <>
                                                                {this.state.tradeIns.map(
                                                                    (_tradeIn, index) => (
                                                                        <TradeInInfo
                                                                            key={index}
                                                                            tradein={_tradeIn}
                                                                            handleEditTradeIn={this.handleEditTradeIn}
                                                                            handleAutoGrap={this.handleAutoGrap}
                                                                            isDeivered={canModifyService ? _isCompleted : true}
                                                                            tradeinPlusEnabled={tradeinPlusEnabled}
                                                                            contact={this.state.contact}
                                                                            isContact={isContact}
                                                                            dealersettings={dealersettings}
                                                                            clientUsers={clientUsers}
                                                                            groupUsers={groupUsers}
                                                                            handleContactOpen={this.handleContactOpen}
                                                                            handleReSendLink={this.handleReSendLink}
                                                                            showTag={true}
                                                                        ></TradeInInfo>
                                                                    )
                                                                )}
                                                            </>
                                                        );
                                                    } else {
                                                        return _isCompleted === false &&
                                                            canModifyService ? (
                                                            <>
                                                                <div className="text-center p-4">
                                                                    <p>
                                                                        <Translate text={"notradeindata"} />
                                                                    </p>
                                                                    <button
                                                                        className="btn btn-sm btn-default"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.isValidForTradeIn();
                                                                        }}
                                                                    >
                                                                        <i className="ico icon-add mr-1"></i>{" "}
                                                                        <Translate text={"add"} />
                                                                    </button>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="text-center p-4">
                                                                    <p>
                                                                        <Translate text={"notradeindata"} />
                                                                    </p>
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                })()}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    {/** INVOICE INFO */}
                                    {
                                        serviceInvoiceEnabled && _isCompleted
                                            ?
                                            <>
                                                <Card>
                                                    <Accordion.Toggle
                                                        as={Card.Header}
                                                        eventKey='03'
                                                        onClick={e => {
                                                            e.preventDefault();

                                                            if (activeLeftKey === '03')
                                                                this.handleLeftActiveKey(null);
                                                            else this.handleLeftActiveKey('03');
                                                        }}
                                                        id='quotation-toggle'
                                                    >
                                                        <div className='mb-0'>
                                                            {serviceInvoices.length ? (
                                                                <div className='ribbon-wrapper-featured'>
                                                                    <div className='featured'></div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <div className='cardlink'>
                                                                <a
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                    }}
                                                                    className={`btn btn-link ${activeLeftKey === '03' ? '' : 'collapsed'
                                                                        }`}
                                                                >
                                                                    {' '}
                                                                    <i className='ico icon-contract-fill'></i> <Translate text={'Invoices'} />{' '}
                                                                    {!_.isEmpty(serviceInvoices) ? (
                                                                        <span className='badge badge-pill badge-cafe ml-1'>
                                                                            {serviceInvoices.length}
                                                                        </span>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    <i className='arrow-collapse'></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </Accordion.Toggle>
                                                    {
                                                        (addInvoice)
                                                            ?
                                                            <>
                                                                <div className='edit-button'>
                                                                    <a
                                                                        className='mini-button'
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            if (this.isValidForInvoice() === true)
                                                                                window.open(`/service/invoice/${this.state.serviceID}?type=new`, '_blank');
                                                                        }}
                                                                    >
                                                                        <i className={`ico icon-add`}></i>
                                                                    </a>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                    <Accordion.Collapse eventKey='03' id='finance-collapse'>
                                                        <Card.Body>
                                                            {!_.isEmpty(serviceInvoices) ? (
                                                                serviceInvoices.map(quotation => (
                                                                    <QuotationInfo
                                                                        key={quotation.documentID}
                                                                        quotation={quotation}
                                                                        gdprDeleted={gdprDeleted}
                                                                        isDeivered={false}
                                                                        currencySymbol={currencySymbol}
                                                                        addInvoice={addInvoice}
                                                                        deleteInvoice={deleteInvoice}
                                                                        clientUsers={clientUsers}
                                                                        dealersettings={dealersettings}
                                                                    />
                                                                ),
                                                                )
                                                            ) : (
                                                                <>
                                                                    <div className='text-center p-3'>
                                                                        <p>
                                                                            <Translate text={'No invoices found'} />
                                                                        </p>
                                                                        {
                                                                            (addInvoice)
                                                                                ?
                                                                                <>
                                                                                    <button
                                                                                        className='btn btn-sm btn-default'
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            if (this.isValidForInvoice() === true)
                                                                                                window.open(`/service/invoice/${this.state.serviceID}?type=new`, '_blank');
                                                                                        }}
                                                                                    >
                                                                                        <i className={`ico icon-${'add'} mr-1`}></i>{' '}
                                                                                        <Translate text={'add'} />
                                                                                    </button>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </>
                                            :
                                            <></>
                                    }

                                    {/** SERVICE INFO */}
                                    <Card>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            eventKey='01'
                                            onClick={e => {
                                                e.preventDefault();

                                                if (activeLeftKey === '01')
                                                    this.handleLeftActiveKey(null);
                                                else
                                                    this.handleLeftActiveKey('01');

                                            }}
                                        >
                                            <div className='mb-0'>
                                                <div className='cardlink'>
                                                    <a
                                                        onClick={e => {
                                                            e.preventDefault();
                                                        }}
                                                        className={`btn btn-link ${activeLeftKey === '01' ? '' : 'collapsed'
                                                            }`}
                                                    >
                                                        <i className={`ico icon-history`}></i>
                                                        <Translate text={'serviceHistory'} />
                                                        {serviceHistory.length > 0 ? (
                                                            <span className='badge badge-pill badge-cafe ml-1'>
                                                                {serviceHistory.length}
                                                            </span>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <i className='arrow-collapse'></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey='01'>
                                            <Card.Body>
                                                {serviceHistory &&
                                                    serviceHistory.length > 0 ? (
                                                    <>
                                                        {serviceHistory.map((_enquiry, index) => (
                                                            <ServiceHistory
                                                                key={index}
                                                                service={_enquiry}
                                                                clientUsers={this.props.clientUsers}
                                                                groupUsers={this.props.groupUsers}
                                                                sidepanelOpen={this.sidepanelServiceOpen}
                                                                history={this.props.history}
                                                                showMore={false}
                                                                dealersettings={this.props.dealersettings}
                                                            ></ServiceHistory>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className='text-center p-3'>
                                                            <p><Translate text={'noServiceFound'} /></p>
                                                        </div>
                                                    </>
                                                )}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    {/** FILES INFO */}

                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="02" onClick={(e) => {
                                            e.preventDefault()

                                            if (activeLeftKey === '02')
                                                this.handleLeftActiveKey(null);
                                            else
                                                this.handleLeftActiveKey('02');
                                        }}>
                                            <div className="mb-0">
                                                {isFiles ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                <div className="cardlink"><a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeLeftKey === '02' ? '' : 'collapsed'}`}>
                                                    <i className="ico icon-files"></i> <Translate text={'Files'} /> <i className="arrow-collapse"></i></a></div>
                                            </div>
                                        </Accordion.Toggle>

                                        <div className='add-button'>
                                            <a
                                                href='#'
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.isValidForFile();
                                                }}
                                                className='mini-button'
                                            >
                                                <i className='ico icon-add'></i>
                                            </a>
                                        </div>
                                        <Accordion.Collapse eventKey="02">
                                            <Card.Body>
                                                <Files
                                                    serviceID={this.state.serviceID}
                                                    contactID={this.state.contact?.documentID}
                                                    contact={this.state.contact}
                                                    dealersettings={dealersettings}
                                                    clientUsers={clientUsers}
                                                    groupUsers={groupUsers}
                                                    isDeivered={canModifyService ? _isCompleted : true}
                                                    filePath={`serviceJobs/${this.state.serviceID}/files`}
                                                >
                                                </Files>
                                                {/* <div className="text-center p-3">
                                                        <p></p>
                                                        <button className="btn btn-sm btn-default"><i className="ico icon-add mr-1"></i> Add </button>
                                                    </div> */}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                </Accordion>
                            </div>

                            <div className="enquiry-detailed-info">

                                <ul>
                                    <li> <span><Translate text={'modifiedBy'} />:</span> {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), modifiedBy)}</li>
                                    <li> <span><Translate text={'modifiedDate'} />:</span> {modifiedDate ? moment.unix(modifiedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                    {
                                        !_.isEmpty(modifiedFrom)
                                            ?
                                            <>
                                                <li> <span><Translate text={'modifiedFrom'} />:</span> {modifiedFrom}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        !_.isEmpty(completeDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={'Job completed on'} />:</span> {completeDate ? moment.unix(completeDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        !_.isEmpty(startDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={'Job started on'} />:</span> {startDate ? moment.unix(startDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        !_.isEmpty(responseDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={'Client responded on'} />:</span> {responseDate ? moment.unix(responseDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        !_.isEmpty(quoteSentDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={'quoteSentDate'} />:</span> {quoteSentDate ? moment.unix(quoteSentDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        !_.isEmpty(clientContactDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={'clientContactDate'} />:</span> {clientContactDate ? moment.unix(clientContactDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        checkInBy && !_.isEmpty(checkInDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={'checkInBy'} />:</span> {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), checkInBy)}</li>
                                                <li> <span><Translate text={'checkInDate'} />:</span> {checkInDate ? moment.unix(checkInDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }


                                    <li> <span><Translate text={'addedBy'} />:</span> {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), addedBy)}</li>
                                    <li> <span><Translate text={'addedDate'} />:</span> {addedDate ? moment.unix(addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                    {
                                        !_.isEmpty(addedFrom)
                                            ?
                                            <>
                                                <li> <span><Translate text={'addedFrom'} />:</span> {addedFrom}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                </ul>
                            </div>

                        </aside>
                        {/** RIGHT TAB */}
                        <div className="mid-column pb-5">

                            {
                                isCheckInJobDone
                                    ?
                                    <>
                                        {/** SERVICE DETAILS **/}
                                        <div className="service-section-boxloop">
                                            <div className="service-section-boxloop-header border-bottom-0">
                                                <div className="float-left">
                                                    <h3 className="service-section-page-title">
                                                        <span className="service-section-page-title-icon bg-service-blue text-white mr-3">
                                                            <i className="ico icon-service-settings"></i> </span>
                                                        <div className="service-section-ro-title"> {displayID}
                                                            <div className="service-section-ro-subtitle"><Translate text={'Job Number'} /></div>
                                                        </div>
                                                    </h3>
                                                </div>
                                                <div className="float-right">
                                                    <div className="service-section-totalcost">
                                                        <div className="service-section-totalcost-info"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setTotalServiceCost();
                                                                this.setState({
                                                                    costPopOver: { showpopover: true, targetpopover: e.target }
                                                                });
                                                            }}>
                                                            <i className="ico icon-info-fill icon-grey"></i>
                                                        </div>
                                                        <span><Translate text={'Total'} />:&nbsp;</span>  <NumberFormat value={totalServiceCost} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                                                        <div className="gst">{_dispalyTax}</div>
                                                    </div>
                                                    {
                                                        _isDiscountApplied
                                                            ?
                                                            <>
                                                                <div className="total-discount-service">
                                                                    <div className="labourcost-discount-list mr-3">
                                                                        {
                                                                            discountAccess && canModifyService
                                                                                ?
                                                                                <div className="labourcost-discount-edit">
                                                                                    <a href="#" onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.handleOpenDiscount();
                                                                                    }}> <i className="fas fa-pen-square" aria-hidden="true"></i>
                                                                                    </a>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div className="labourcost-discount-edit">
                                                                                        <a href="#" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleOpenDiscount(true);
                                                                                        }}> <i className="far fa-pen-square" aria-hidden="true"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                        <div className="labourcost-discount-title"><Translate text={'discount'} />:&nbsp;
                                                                            <NumberFormat value={_fullServiceCosts.actualDiscount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    _fullServiceCosts.total && discountEnabled && discountAccess && canModifyService
                                                                        ?
                                                                        <>
                                                                            <div className="service-section-discount-btn">
                                                                                <button className="btn btn-sm btn-default" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleOpenDiscount();
                                                                                }}><i className="ico icon-add"></i> <Translate text={'Add Discount'} /> </button>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </>
                                                    }


                                                </div>
                                            </div>


                                            <div className="service-section-page-content pt-0 form-style">

                                                {
                                                    isAfterOwnerApproved
                                                        ?
                                                        <>
                                                            <div className="service-statusinfobar">

                                                                <div className="service-statusinfobar-confirm">
                                                                    <div className="badge badge-pill badge-service-confirm ml-2 pl-4">
                                                                        <img src={jobConfirmed ? _images.radioTick : _images.radioUnTick} alt="" width="16" className="confirm-ico-status-inner" />
                                                                        <Translate text={'Confirmed'} />
                                                                    </div>
                                                                </div>

                                                                <div className={`service-statusinfobar-checkbox ${(_isCompleted === false && canModifyService) ? '' : 'form-disable'}`}>
                                                                    <div className="checkbox icheck-success float-left mr-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="partsConfirmed"
                                                                            name="partsConfirmed"
                                                                            className="uncheck-activity"
                                                                            checked={partsConfirmed ? true : false}
                                                                            disabled={partsConfirmedAccess ? false : true}
                                                                            onChange={(e) => {
                                                                                if (_isCompleted === false && canModifyService)
                                                                                    this.handleCheckChange(e, 'partsConfirmed')
                                                                            }} />
                                                                        <label htmlFor="partsConfirmed"><Translate text={'partsConfirmed'} /> </label>
                                                                    </div>
                                                                    <div className="checkbox icheck-success float-left mr-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="workshopConfirmed"
                                                                            name="workshopConfirmed"
                                                                            className="uncheck-activity"
                                                                            checked={workshopConfirmed ? true : false}
                                                                            disabled={workshopConfirmedAccess ? false : true}
                                                                            onChange={(e) => {
                                                                                if (_isCompleted === false && canModifyService)
                                                                                    this.handleCheckChange(e, 'workshopConfirmed')
                                                                            }} />
                                                                        <label htmlFor="workshopConfirmed"><Translate text={'workshopConfirmed'} /> </label>
                                                                    </div>
                                                                    <div className="checkbox icheck-success float-left ">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="jobAllocated"
                                                                            name="jobAllocated"
                                                                            className="uncheck-activity"
                                                                            checked={jobAllocated ? true : false}
                                                                            disabled={jobAllocatedAccess ? false : true}
                                                                            onChange={(e) => {
                                                                                if (_isCompleted === false && canModifyService)
                                                                                    this.handleCheckChange(e, 'jobAllocated')
                                                                            }} />
                                                                        <label htmlFor="jobAllocated"><Translate text={'jobAllocated'} /></label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }




                                                <div className="service-section-info-middle">
                                                    <div className="float-left">
                                                        <div className="service-section-owner-info float-left pr-3">
                                                            <h4><Translate text={'roNumber'} /></h4>
                                                            <h2 >{roNumber ? roNumber : '--'} </h2>
                                                        </div>
                                                        <div className="service-section-owner-info float-left pl-3 pr-3 border-left">
                                                            <h4><Translate text={'serviceType'} /></h4>
                                                            <h2 >{CommonHelper.getLabelByValue(service_Types, serviceType, '--')} </h2>
                                                        </div>
                                                        <div className="service-section-owner-info float-left pl-3 pr-3 border-left">
                                                            <h4><Translate text={'appointmentType'} /></h4>
                                                            <h2 >{CommonHelper.getLabelByValue(appointmentTypes, appointmentType, '--')}</h2>
                                                        </div>

                                                        {
                                                            loanAppointmentID && !_.isEmpty(_loanAppointmentStock) && !_.isEmpty(_loanAppointmentData)
                                                                ?
                                                                <>
                                                                    <div className="service-section-owner-info float-left pl-3 pr-3 border-left">
                                                                        <h4><Translate text={'Loan Booking'} /></h4>
                                                                        <h2>
                                                                            <div className="vehicle-item-title">{_loanAppointmentStock.make} {_loanAppointmentStock.model}</div>
                                                                        </h2>
                                                                    </div>
                                                                    <div className="service-section-owner-info float-left pl-3 border-left">
                                                                        <h4><Translate text={'Booking Time'} /></h4>
                                                                        <h2>
                                                                            <div className="vehicle-item-title">
                                                                                {(!_.isEmpty(_loanAppointmentData.startDate)) ? moment.unix(_loanAppointmentData.startDate.seconds).format('DD MMM YYYY h:mm A') : '--'}
                                                                                {'  -  '}
                                                                                {(!_.isEmpty(_loanAppointmentData.expectedReturn)) ? moment.unix(_loanAppointmentData.expectedReturn.seconds).format('DD MMM YYYY h:mm A') : '--'}
                                                                            </div>
                                                                        </h2>
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }

                                                    </div>

                                                    <div className="float-right service-section-actionbtn pt-1">
                                                        <WorkLog
                                                            serviceID={this.state.serviceID}
                                                            service={this.state.fields}
                                                            dealersettings={dealersettings}
                                                            clientUsers={clientUsers}
                                                            groupUsers={groupUsers}
                                                            canModify={canModifyService}
                                                            updateDealerSettings={this.props.updateDealerSettings}
                                                            allTechnicianUsers={allTechnicianUsers}
                                                        ></WorkLog>
                                                    </div>

                                                    {/* <div className="float-right service-section-actionbtn pt-1">

                                                        <button type="button" className="btn btn-primary mt-1"
                                                            onClick={(e) => { e.preventDefault(); if (this.isValidateAppointment()) { this.handleCheckListOpen(); } }}
                                                        >
                                                            {
                                                                (isChecklist || !checklistAccess)
                                                                    ?
                                                                    <><i className="ico icon-service-checklist mr-1"></i> {'View Check List'}</>
                                                                    :
                                                                    <><i className="ico icon-add"></i> {'Add Check List'}</>
                                                            }
                                                        </button>

                                                        <button type="button" className="btn btn-secondary mt-1 ml-2"
                                                            onClick={(e) => { e.preventDefault(); if (this.isValidateAppointment()) { this.handleBBTCheckListOpen(); } }}
                                                        >
                                                            {
                                                                (isBbtChecklist || !checklistBBTAccess)
                                                                    ?
                                                                    <><i className="ico icon-service-checklist"></i> {'View BBT Check List'}</>
                                                                    :
                                                                    <><i className="ico icon-add"></i> {'Add BBT Check List'}</>
                                                            }
                                                        </button>

                                                    </div> */}
                                                </div>
                                                <div className="service-section-info-footer">
                                                    {
                                                        _isCompleted === false && canModifyService && modifyServiceAccess
                                                            ?
                                                            <>
                                                                <div className="service-section-edit-button">
                                                                    <a href="#" className="mini-button"
                                                                        onClick={(e) => { e.preventDefault(); this.handleServiceDetailOpen(); }}>
                                                                        <i className="ico icon-edit"></i>
                                                                    </a>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }

                                                    <div className="row no-gutters">
                                                        <div className="col-md-3 border-right">
                                                            <div className="d-flex align-items-center">
                                                                <div className="service-section-owner-info">
                                                                    <h4><Translate text={'serviceAdvisor'} /></h4>
                                                                    <h2 >{CommonHelper.getLabelByValue(allAdvisorUsers, serviceAdvisor, '--')} </h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 border-right pl-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="service-section-owner-info">
                                                                    <h4><Translate text={'workshopManager'} /></h4>
                                                                    <h2 >{CommonHelper.getLabelByValue(allWorkshopUsers, workshopManager, '--')} </h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 border-right pl-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="service-section-owner-info">
                                                                    <h4><Translate text={'partsInterpretor'} /></h4>
                                                                    <h2 >{CommonHelper.getLabelByValue(allPartsUsers, partsInterpretor, '--')} </h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 pl-3 ">
                                                            <div className="d-flex align-items-center">
                                                                <div className="service-section-owner-info">
                                                                    <h4><Translate text={'technician'} /></h4>
                                                                    <h2 >{CommonHelper.getLabelByValue(allTechnicianUsers, technician, '--')} </h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            !_.isEmpty(technicians)
                                                                ?
                                                                <>
                                                                    <div className={`col-md-6 mt-3`}>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="service-section-owner-info">
                                                                                <h4><label ><Translate text={'otherTechnicians'} /> </label></h4>
                                                                                <h2 className="other-tech">
                                                                                    {technicians.map((_tech, idx) => (
                                                                                        CommonHelper.getLabelByValue(allTechnicianUsers, _tech, '')
                                                                                    ))}
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                        {/* {
                                                            !_.isEmpty(teammates)
                                                                ?
                                                                <>
                                                                    <div className={`col-md-6 mt-3`}>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="service-section-owner-info">
                                                                                <h4><label ><Translate text={'teammates'} /> </label></h4>
                                                                                <h2 className="other-tech">
                                                                                    {teammates.map((_tech, idx) => (
                                                                                        CommonHelper.getLabelByValue(allUsers, _tech, '')
                                                                                    ))}
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        } */}

                                                        {/* {
                                                            !_.isEmpty(technicians)
                                                                ?
                                                                <>
                                                                    <div className={`col-md-3 mt-3`}>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="service-section-owner-info">
                                                                                <h4><label ><Translate text={'otherTechnician'} /> </label></h4>
                                                                                <h2 >{CommonHelper.getLabelByMultipleValues(allTechnicianUsers, technicians, '--')} </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        } */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                    :
                                    <>
                                        {/** APPOINTMENT DATE & SLOTS **/}
                                        <div className="service-section-boxloop">
                                            <div className="service-section-boxloop-header">
                                                <div className="float-left">
                                                    <h3 className="service-section-page-title"> <span className="service-section-page-title-icon bg-service-green text-white mr-3">
                                                        <i className="ico icon-service-calendar"></i> </span> <Translate text={'Select Date & Time for Appointment'} /> </h3>
                                                </div>

                                                <div className="float-right">
                                                    <div className="service-section-totalcost">
                                                        <div className="service-section-totalcost-info"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setTotalServiceCost();
                                                                this.setState({
                                                                    costPopOver: { showpopover: true, targetpopover: e.target }
                                                                });
                                                            }}>
                                                            <i className="ico icon-info-fill icon-grey"></i>
                                                        </div>
                                                        <span><Translate text={'Total'} />:&nbsp;</span>
                                                        <NumberFormat value={totalServiceCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} />
                                                        <div className="gst">{_dispalyTax}</div>
                                                    </div>
                                                    {
                                                        _isDiscountApplied
                                                            ?
                                                            <>
                                                                <div className="total-discount-service">
                                                                    <div className="labourcost-discount-list mr-3">
                                                                        {
                                                                            discountAccess && canModifyService
                                                                                ?
                                                                                <div className="labourcost-discount-edit">
                                                                                    <a href="#" onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.handleOpenDiscount();
                                                                                    }}> <i className="fas fa-pen-square" aria-hidden="true"></i>
                                                                                    </a>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div className="labourcost-discount-edit">
                                                                                        <a href="#" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleOpenDiscount(true);
                                                                                        }}> <i className="far fa-pen-square" aria-hidden="true"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                        <div className="labourcost-discount-title"><Translate text={'discount'} />:&nbsp;
                                                                            <NumberFormat value={_fullServiceCosts.actualDiscount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    _fullServiceCosts.total && discountEnabled && discountAccess && canModifyService
                                                                        ?
                                                                        <>
                                                                            <div className="service-section-discount-btn">
                                                                                <button className="btn btn-sm btn-default" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleOpenDiscount();
                                                                                }}><i className="ico icon-add"></i> <Translate text={'Add Discount'} /> </button>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </>
                                                    }

                                                </div>

                                            </div>
                                            <div className={`service-section-page-content form-style ${(_isCompleted === false && canModifyService) ? '' : 'form-disable'}`}>
                                                <div className="form-row">
                                                    <div className="form-group col-md-3">
                                                        <label ><Translate text={'appointmentDate'} /> </label>
                                                        <div className="service-appointment-input">
                                                            <div className="inner-addon right-addon"> <i className="bg-icons ico icon-date"></i>
                                                                <AntDatePicker
                                                                    value={appointmentDate ? moment(appointmentDate).format('YYYY-MM-DD') : null}
                                                                    name={'appointmentDate'}
                                                                    onChange={(e) => { this.handleAppointmentDateChange(e, 'appointmentDate') }}
                                                                    format='DD/MM/YYYY'
                                                                    placeholder='DD/MM/YYYY'
                                                                    className={`form-control ${this.state.errors["appointmentDate"]}`}
                                                                    disableContainer={true}
                                                                    allowClear={false}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    !_.isEmpty(currentSlots)
                                                        ?
                                                        <>

                                                            {timeOfDay && _.isEmpty(_slotValue) ?
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <label><Translate text={'Specified Appointment Time'} />:&nbsp;
                                                                            <span className="service-date-blue">{moment(appointmentDate).format('h:mm A')} </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                : <></>}

                                                            <div className="form-row">
                                                                <div className="form-group col-md-12">
                                                                    {appointmentDate ? <label><Translate text={'Available timeslots on'} /> <span className="service-date-blue">{moment(appointmentDate).format('dddd, Do MMMM YYYY')} </span> </label> : <></>}

                                                                    {/** SLOTS **/}
                                                                    <div className={`service-slot-list ${this.state.errors["slot"] ? 'border-error' : ''}`}>
                                                                        <div className="slot-loop-flex ">
                                                                            {
                                                                                currentSlots.map((_slotData, index) => {
                                                                                    return (
                                                                                        <div key={index}
                                                                                            className={`slot-loop ${_slotValue === _slotData.value ? 'slot-active' : ''} ${(_slotData.total > 0 && _slotData.count >= _slotData.total) ? 'form-disable' : ''}`}
                                                                                            style={{
                                                                                                boxShadow: `inset 0 0 0 1px ${_slotData.color ? _slotData.color : '#000'},0 1px 2px rgba(38,41,44,.08)`,
                                                                                                //backgroundColor: `${_slotData.color ? (_slotValue === _slotData.value ? '' : _slotData.color + '1f') : '#f478151f'}`,
                                                                                            }}
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                                this.handleSelectSlot(_slotData);
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                _slotData.isExpress ? <div className="red-corder-ribbon"><div className="badge red-ribbon ml-2"><Translate text={'express'} upperCase={true} /></div></div> : <></>
                                                                                            }

                                                                                            <div className="slot-content">
                                                                                                <div className="slot-title text-ellipsis"
                                                                                                    style={{ color: `${_slotData.color ? _slotData.color : '#000'}` }}>{_slotData.name} </div>
                                                                                                <div className="slot-text "> {_slotData.from}{'-'}{_slotData.to}</div>
                                                                                                <div className="slot-text ">{_slotData.count ? _slotData.count : 0}/{_slotData.total} <Translate text={'Slots'} /></div>

                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                                {/* slotTimes */}

                                                {
                                                    !_.isEmpty(slotTimes)
                                                        ?
                                                        <>
                                                            <div className="form-row">
                                                                <div className='form-group col-md-12'>
                                                                    <label><Translate text={'Appointment Time'} /> </label>

                                                                    <div className={`service-booked-listing ${this.state.errors["timeOfDay"] ? 'border-error' : ''}`}>
                                                                        {
                                                                            slotTimes.map((_time, index) => {
                                                                                return <div
                                                                                    key={index}
                                                                                    className={`service-booked-slot ${timeOfDay === _time.value ? 'active' : ''}`}
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        this.handleTimeSlotChange(_time.value, 'timeOfDay');
                                                                                    }}
                                                                                >
                                                                                    {_time.label}

                                                                                    <div className="service-loan-vehicle-slot">
                                                                                        <div className="slot-loan-count">
                                                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
                                                                                            {this.availableStockCount(_time.value)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                        }

                                                                    </div>

                                                                    <div className="service-loan-listing-wrap mt-3">
                                                                        {
                                                                            timeOfDay && _.isEmpty(loanAppointmentID) && !isLoan
                                                                                ?
                                                                                <div>
                                                                                    <button type="button" className="btn btn-secondary mt-1"
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            this.handleOpenLoanAppointment();
                                                                                        }}
                                                                                    > <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i> <Translate text={'Loan Vehicle Appointment'} /></button>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            loanAppointmentID && !_.isEmpty(_loanAppointmentStock) && !_.isEmpty(_loanAppointmentData)
                                                                                ?
                                                                                <>
                                                                                    <div className="vehicle-item service-loancell-fix mb-0"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleOpenLoanAppointment();
                                                                                        }}>
                                                                                        <div className="service-loan-listing-head"><i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i> <Translate text={'Loan Vehicle Appointment'} /></div>
                                                                                        {
                                                                                            isLoan
                                                                                                ?
                                                                                                <></>
                                                                                                :
                                                                                                <>
                                                                                                    <a href="#" className="service-loan-vehicle-clear"
                                                                                                        onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                            this.handleDeleteAppointment();
                                                                                                        }}><i className="ico icon-remove"></i></a>
                                                                                                </>
                                                                                        }

                                                                                        <div className="testdrive-listing-tab flex-vehicle">
                                                                                            <div className="service-loan-vehicle-item-image">
                                                                                                {
                                                                                                    (!_.isEmpty(_loanAppointmentStock) && !_.isEmpty(_loanAppointmentStock.images))
                                                                                                        ?
                                                                                                        <>
                                                                                                            {
                                                                                                                _loanAppointmentStock.images.map((data, index) => (
                                                                                                                    <a key={index} data-fancybox={`testgl_info_img_slider${loanAppointmentID}`} className={index === 0 ? '' : 'hide'} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                                                        {index === 0 ?
                                                                                                                            <>
                                                                                                                                <div className="zoom-ico"><i className="ico icon-zoom"></i></div>
                                                                                                                                <img src={data} width="40" height="40" alt="" className="img-object-fit" />
                                                                                                                            </>
                                                                                                                            : <></>}
                                                                                                                    </a>
                                                                                                                ))
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                            <img src={CommonHelper.showBrandLogo(dealersettings, _loanAppointmentStock.make)} className="img-object-fit-contain" width="40" height="40" alt="" />
                                                                                                        </a>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="vehicle-data">
                                                                                                <div className="vehicle-item-title">{_loanAppointmentStock.make} {_loanAppointmentStock.model}</div>
                                                                                                <div className="vehicle-item-info">{_loanAppointmentStock.exteriorColor ? `${_loanAppointmentStock.exteriorColor},` : ''} {_loanAppointmentStock.year ? _loanAppointmentStock.year : ''}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="testdrive-duration">
                                                                                            <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td width="50%"><Translate text={'startDate'} /></td>
                                                                                                        <td width="50%"><Translate text={'expectedReturn'} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="label-duration">{(!_.isEmpty(_loanAppointmentData.startDate)) ? moment.unix(_loanAppointmentData.startDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                                                                                                        <td className="label-duration">{(!_.isEmpty(_loanAppointmentData.expectedReturn)) ? moment.unix(_loanAppointmentData.expectedReturn.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }

                                                                    </div>

                                                                </div>
                                                            </div>


                                                        </>
                                                        :
                                                        <></>

                                                }



                                            </div>
                                        </div>

                                        {/** SERVICE DETAILS **/}
                                        <div className="service-section-boxloop mt-3">
                                            <div className="service-section-boxloop-header">
                                                <div className="float-left">
                                                    <h3 className="service-section-page-title">
                                                        <span className="service-section-page-title-icon bg-service-blue text-white mr-3">
                                                            <i className="ico icon-service-settings"></i> </span>
                                                        <div className="service-section-ro-title">

                                                            {displayID}
                                                            <div className="service-section-ro-subtitle"><Translate text={'Job Number'} /></div>

                                                        </div>

                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="service-section-page-content  form-style">
                                                <div className="form-row">

                                                    <div className={`form-group col-md-4 ${(_isCompleted === false && canModifyService) ? '' : 'form-disable'}`}>
                                                        <label ><Translate text={'roNumber'} /></label>
                                                        <InputAutoText
                                                            type='text'
                                                            autoComplete='off'
                                                            placeholder={'ro number'}
                                                            className={`form-control cursor-pointer ${this.state.errors['roNumber']}`}
                                                            name='roNumber'
                                                            onChange={this.handleOnRONumChange}
                                                            value={roNumber ? roNumber : ''}
                                                            onSaveField={this.saveServiceROField}
                                                            onCancelField={this.cancelServiceField}
                                                            disableOutsideClick={true}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label ><Translate text={'serviceType'} /></label>
                                                        <ReactSelect
                                                            options={service_Types}
                                                            name={"serviceType"}
                                                            placeholder={'select service type'}
                                                            onChange={this.handleSelectSave}
                                                            value={serviceType ? serviceType : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["serviceType"]} basic-select`}
                                                            isSearchable={true}
                                                            isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label ><Translate text={'appointmentType'} /></label>
                                                        <ReactSelect
                                                            options={appointmentTypes}
                                                            name={"appointmentType"}
                                                            placeholder={'select appointment types'}
                                                            onChange={this.handleSelectSave}
                                                            value={appointmentType ? appointmentType : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["appointmentType"]} basic-select`}
                                                            isSearchable={true}
                                                            isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label ><Translate text={'serviceAdvisor'} /> </label>
                                                        <ReactSelect
                                                            options={allAdvisorUsers}
                                                            name={"serviceAdvisor"}
                                                            placeholder={'select service advisor'}
                                                            onChange={this.handleSelectSave}
                                                            value={serviceAdvisor ? serviceAdvisor : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["serviceAdvisor"]} basic-select`}
                                                            isSearchable={true}
                                                            isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>

                                                    <div className="form-group col-md-4">
                                                        <label ><Translate text={'workshopManager'} /> </label>
                                                        <ReactSelect
                                                            options={allWorkshopUsers}
                                                            name={"workshopManager"}
                                                            placeholder={'select workshop manager'}
                                                            onChange={this.handleSelectSave}
                                                            value={workshopManager ? workshopManager : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["workshopManager"]} basic-select`}
                                                            isSearchable={true}
                                                            isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>

                                                    <div className="form-group col-md-4">
                                                        <label ><Translate text={'partsInterpretor'} /></label>
                                                        <ReactSelect
                                                            options={allPartsUsers}
                                                            name={"partsInterpretor"}
                                                            placeholder={'select parts interpretor'}
                                                            onChange={this.handleSelectSave}
                                                            value={partsInterpretor ? partsInterpretor : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["partsInterpretor"]} basic-select`}
                                                            isSearchable={true}
                                                            isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>

                                                    <div className="form-group col-md-4">
                                                        <label ><Translate text={'technician'} /> </label>
                                                        <ReactSelect
                                                            options={allTechnicianUsers}
                                                            name={"technician"}
                                                            placeholder={'select technician'}
                                                            onChange={this.handleSelectSave}
                                                            value={technician ? technician : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["technician"]} basic-select`}
                                                            isSearchable={true}
                                                            isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label ><Translate text={'otherTechnicians'} /> </label>
                                                        <ReactMultiSelect
                                                            options={allTechnicianUsers}
                                                            name={"technicians"}
                                                            placeholder={'select technicians'}
                                                            onChange={this.handleMultiSelectChange}
                                                            value={technicians}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                            menuPlacement='bottom'
                                                            isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>


                                                    <div className="form-group col-md-4">
                                                        <label ><Translate text={'Service Packages'} /></label>
                                                        <ReactMultiSelect
                                                            options={servicePackageTypes}
                                                            name={'servicePackageType'}
                                                            placeholder={'select service package'}
                                                            onChange={this.handlePackagesChange}
                                                            value={servicePackageType}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                            menuPlacement='bottom'
                                                            isDisabled={(canModifyService && packagesAccess) ? false : true}
                                                        ></ReactMultiSelect>
                                                    </div>





                                                    <div className={`form-group col-md-2 ${(_isCompleted === false && canModifyService) ? '' : 'form-disable'}`}>
                                                        <label ><Translate text={'completionDate'} /></label>
                                                        <AntDatePicker
                                                            value={completionDate ? moment.unix(completionDate.seconds).format('YYYY-MM-DD') : null}
                                                            name={'completionDate'}
                                                            onChange={(e) => { this.handleOnDateChange(e, 'completionDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["completionDate"]}`}
                                                            disableContainer={true}
                                                        />
                                                    </div>
                                                    <div className={`form-group col-md-2 ${(_isCompleted === false && canModifyService) ? '' : 'form-disable'}`}>
                                                        <label ><Translate text={'time'} /></label>
                                                        <ReactTimePicker
                                                            value={completionDate ? moment.unix(completionDate.seconds)._d : null}
                                                            name={'completionDate'}
                                                            onChange={this.handleTimeChange}
                                                            timeIntervals={15}
                                                            placeholder='HH:MM'
                                                            className={`form-control ${this.state.errors["completionDate"]}`}
                                                        />
                                                    </div>


                                                    <div className="form-group col-md-8">
                                                        <label ><Translate text={'teammates'} /></label>
                                                        <ReactMultiSelect
                                                            options={allTeamUsers}
                                                            name={'teammates'}
                                                            placeholder={'select teammate'}
                                                            onChange={this.handleTemmateChange}
                                                            value={teammates}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                            menuPlacement='bottom'
                                                            isGroup={true}
                                                            isDisabled={canModifyService ? false : true}
                                                        ></ReactMultiSelect>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </>
                            }

                            {/** Required Services **/}
                            <div className="service-section-boxloop mt-3">
                                <div className="service-section-boxloop-header">
                                    <div className="float-left">
                                        <h3 className="service-section-page-title">
                                            <span className="service-section-page-title-icon bg-service-pink text-white mr-3">
                                                <i className="ico icon-service-package"></i> </span> <Translate text={'Required Services'} /> </h3>
                                    </div>
                                    <div className="float-right">
                                        {
                                            (_isCompleted === false && packagesAccess && canModifyService)
                                                ?
                                                <>
                                                    <div className="service-add-button">
                                                        <a href="#" className="mini-button"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                if (this.isValidateAppointment()) {
                                                                    this.handleAddOnsOpen();
                                                                }
                                                            }}
                                                        >
                                                            <i className="ico icon-add"></i></a>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>

                                <div className="service-section-page-content form-style">
                                    {
                                        (!_.isEmpty(servicePackages))
                                            ?
                                            <>
                                                {
                                                    servicePackages.map((_serviceData, index) => {
                                                        return <div key={index} className="service-section-packages-box" onClick={(e) => { e.preventDefault(); this.handleAddOnsInfoOpen(_serviceData); }}>
                                                            {
                                                                (_isCompleted === false && packagesAccess && canModifyService)
                                                                    ?
                                                                    <>
                                                                        <div className="input-readonly-close">
                                                                            <a href="#" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                this.handleRemoveAddOns(_serviceData.documentID);
                                                                            }}><i className="ico icon-remove"></i> </a>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                            {/* <div className="package-list-oktodo">
                                                                <img src={_serviceData.oKToDo ? _images.todoyes : (_serviceData.oKToDo === false ? _images.todono : _images.todoempty)} alt="" width="20" />
                                                            </div> */}
                                                            <>
                                                                {(() => {

                                                                    let _packCosts = CommonHelper.getTotalPackageCost(this.state.fields, _serviceData);
                                                                    const _isDiscountAppliedPack = _serviceData.discount && _serviceData.discountType ? true : false;
                                                                    return <>

                                                                        <div className="package-discount-inner" onClick={(e) => {
                                                                            e.stopPropagation();
                                                                        }}>
                                                                            {
                                                                                _isDiscountAppliedPack
                                                                                    ?
                                                                                    <>
                                                                                        <div className="labourcost-discount-list">
                                                                                            {
                                                                                                discountAccess && canModifyService
                                                                                                    ?
                                                                                                    <div className="labourcost-discount-edit">
                                                                                                        <a href="#" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.handleOpenPackDiscount(_serviceData);
                                                                                                        }}>
                                                                                                            <i className="fas fa-pen-square" aria-hidden="true"></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div className="labourcost-discount-edit">
                                                                                                            <a href="#" onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                this.handleOpenPackDiscount(_serviceData, true);
                                                                                                            }}>
                                                                                                                <i className="far fa-pen-square" aria-hidden="true"></i>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </>
                                                                                            }
                                                                                            <div className="labourcost-discount-title"><Translate text={'discount'} />:&nbsp;
                                                                                                <NumberFormat value={_packCosts.actualDiscount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            _packCosts.total && discountEnabled && discountAccess && canModifyService
                                                                                                ?
                                                                                                <>
                                                                                                    <div className="labourcost-discount-btn">
                                                                                                        <button className="btn btn-sm btn-default" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.handleOpenPackDiscount(_serviceData);
                                                                                                        }}><i className="ico icon-add"></i> <Translate text={'Add Discount'} /> </button>
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </div>

                                                                        <div className="service-section-flex-package">
                                                                            <div className="service-section-package-item-icon"> <i className="ico icon-service-package"> </i> </div>
                                                                            <div className="service-section-package-data">
                                                                                <div className="service-section-package-item-title">{_serviceData.name}</div>
                                                                                {/* <div className="service-section-package-item-info"> {_serviceData.price ?
                                                                                    <NumberFormat value={_serviceData.price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                                                    : ''}
                                                                                </div> */}
                                                                                <div className="service-section-package-item-info"> {_packCosts.subtotalAfterDiscount ?
                                                                                    <NumberFormat value={_packCosts.subtotalAfterDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                                                    : ''}
                                                                                </div>
                                                                                {_serviceData.description ? <div className="service-section-package-item-length"><Translate text={'durationHRS'} />:&nbsp;{_serviceData.description}</div> : <></>}

                                                                                {
                                                                                    _serviceData.oKToDo === false && _serviceData.reason
                                                                                        ?
                                                                                        <div className="reason-text-panel "><span><Translate text={'Reason'} />: </span>
                                                                                            <div className="text-area-space "> {_serviceData.reason} </div>

                                                                                        </div>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                })()}
                                                            </>

                                                        </div>
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                <div className="spinner-loader">
                                                    <div className="no-cafe-flex justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className="ico icon-service-package"></i> </div>
                                                    </div>
                                                </div>
                                            </>
                                    }

                                    {
                                        (!_.isEmpty(servicePackages) || !_.isEmpty(parts))
                                            ?
                                            <>
                                                <div className="w-100 d-inline-block mt-3 service-details-wrap-inner parts-boder-top">
                                                    <div className="float-left">

                                                        <h2>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="parts-svg-icon"><path d="M22.7,19L13.6,9.9C14.5,7.6 14,4.9 12.1,3C10.1,1 7.1,0.6 4.7,1.7L9,6L6,9L1.6,4.7C0.4,7.1 0.9,10.1 2.9,12.1C4.8,14 7.5,14.5 9.8,13.6L18.9,22.7C19.3,23.1 19.9,23.1 20.3,22.7L22.6,20.4C23.1,20 23.1,19.3 22.7,19Z" /></svg>
                                                            <Translate text={'Service Parts'} /></h2></div>
                                                    <div className="float-right">
                                                        {
                                                            (_isCompleted === false && !_.isEmpty(servicePackages) && partsAccess && canModifyService)
                                                                ?
                                                                <>
                                                                    <button type="button" className="btn btn-primary float-right ml-1" onClick={e => {
                                                                        e.preventDefault();
                                                                        this.handleAddServicePart();
                                                                    }}><i className="ico icon-add mr-2"></i> <Translate text={'Add Parts'} /></button>
                                                                </>
                                                                :
                                                                <></>
                                                        }


                                                    </div>
                                                </div>

                                                <div className="common-table">
                                                    <table className="table table-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="head-light" width="10%"><Translate text={'partNumber'} /></th>
                                                                <th scope="col" className="head-light" width="30%"><Translate text={'partName'} /></th>
                                                                <th scope="col" className="head-light" width="8%"><Translate text={'quote'} /></th>
                                                                <th scope="col" className="head-light" width="8%"><Translate text={'partStatus'} /></th>
                                                                <th scope="col" className="head-light" width="5%"><Translate text={'quantity'} /></th>
                                                                <th scope="col" className="head-light" width="10%"><Translate text={'Part Cost'} /> </th>
                                                                <th scope="col" className="head-light" width="8%"><Translate text={'Markdown %'} /> </th>
                                                                <th scope="col" className="head-light" width="10%"><Translate text={'Total Cost'} /> </th>
                                                                <th scope="col" className="head-light border-right-0" width="5%"><Translate text={'Actions'} /> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                !_.isEmpty(parts)
                                                                    ?
                                                                    <>
                                                                        {parts.map((_part, index1) => {
                                                                            return <tr key={index1} className={[recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote) ? 'font-italic' : ''}>
                                                                                <td>{_part.partNumber ? _part.partNumber : '--'}</td>
                                                                                <td>{_part.partName ? _part.partName : '--'}</td>
                                                                                <td>{_part.quote ? CommonHelper.getLabelByValue(partQuotes, _part.quote, '--') : '--'}</td>
                                                                                <td>
                                                                                    {
                                                                                        _part.partStatus
                                                                                            ?
                                                                                            <>{CommonHelper.getLabelByValue(partStatuses, _part.partStatus, '--')} </>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                </td>
                                                                                <td>{_part.quantity ? _part.quantity : '--'}</td>
                                                                                <td>{_part.partCost ? <NumberFormat value={_part.partCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : '--'} </td>
                                                                                <td>{_part.priceLevelCode ? _part.priceLevelCode : '--'}</td>
                                                                                <td>{_part.totalCost ? <strong><NumberFormat value={([recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote)) ? 0 : _part.totalCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></strong> : '--'} </td>

                                                                                <td className="border-right-0"><div className="action-fix">
                                                                                    <div className="table-edit float-left mr-2">
                                                                                        {
                                                                                            (_isCompleted === false && partsAccess && canModifyService)
                                                                                                ?
                                                                                                <>
                                                                                                    <a href="#" className="mini-button"
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            this.handleEditServicePart(_part);
                                                                                                        }}>
                                                                                                        <i className="ico icon-edit"></i>
                                                                                                    </a>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <a href="#" className="mini-button"
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                        }}>
                                                                                                        <i className="ico icon-lock"></i>
                                                                                                    </a>
                                                                                                </>
                                                                                        }

                                                                                    </div>
                                                                                    <div className="table-edit float-left mr-2">
                                                                                        {
                                                                                            (_isCompleted === false && partsAccess && canModifyService)
                                                                                                ?
                                                                                                <>
                                                                                                    <a href="#" className="mini-button" onClick={e => {
                                                                                                        e.preventDefault();
                                                                                                        this.handleDeleteServicePart(_part.documentID);
                                                                                                    }}>
                                                                                                        <i className="ico icon-delete"></i>
                                                                                                    </a>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <a href="#" className="mini-button"
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                        }}>
                                                                                                        <i className="ico icon-lock"></i>
                                                                                                    </a>
                                                                                                </>
                                                                                        }

                                                                                    </div>
                                                                                </div></td>
                                                                            </tr>
                                                                        })
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={9}>
                                                                                <div className="spinner-loader">
                                                                                    <div className="no-data-flex justify-content-center align-items-center ">
                                                                                        <div className="no-data-txt mt-2"> No data</div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </>


                                                            }

                                                        </tbody>

                                                    </table>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }


                                </div>


                            </div>

                            {/** VEHICLE IMAGES **/}
                            {
                                isScheduledJobDone
                                    ?
                                    <>

                                        <div className="service-section-boxloop mt-3">
                                            <div className="service-section-boxloop-header">
                                                <div className="float-left">
                                                    <h3 className="service-section-page-title">
                                                        <span className="service-section-page-title-icon bg-service-grey text-white mr-3">
                                                            <i className="ico icon-service-visuals"></i> </span> <Translate text={'Vehicle Visuals'} /> </h3>
                                                </div>

                                                <div className="float-right">

                                                    <button
                                                        type="button"
                                                        className="btn btn-inspection-service mt-1"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({ showCanvas: true })
                                                        }}
                                                    >
                                                        {this.state.isLoadingInspection ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-inspection')}></i>}

                                                        <Translate text={'vehicleInspection'} />
                                                        {
                                                            !_.isEmpty(marks) && marks.length > 0
                                                                ?
                                                                <>
                                                                    <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                                    <div className="float-right"><span className="badge badge-inspection ml-1">{marks.length}</span></div>
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        _isCompleted === false && inspectionAccess && canModifyService
                                                                            ?
                                                                            <div className="float-right ml-2"><i className="ico icon-add" aria-hidden="true"></i></div>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </>
                                                        }

                                                    </button>
                                                    {/* <div className="service-add-button"><a href="#" className="mini-button"><i className="ico icon-add"></i></a></div> */}

                                                </div>
                                            </div>

                                            <div className="service-section-page-content pt-0 form-style">
                                                <div className="tabs recom-popup-tabs">
                                                    <div className="tabs-wraper">
                                                        <nav>
                                                            <Tabs
                                                                defaultActiveKey='images'
                                                                className='nav-fill'
                                                                mountOnEnter={true}
                                                            >
                                                                <Tab eventKey='images' title='Images'>
                                                                    <div className="service-section-thumb-wraper ">
                                                                        {
                                                                            _isCompleted || !canModifyService
                                                                                ?
                                                                                <></>
                                                                                :
                                                                                <div className={`service-section-item-image upload ${this.state.errors["images"] ? 'border-error' : ''}`}>
                                                                                    <label htmlFor={`images_service_${this.state.serviceID}`}>
                                                                                        <img src={_images.addvehicle} alt="" htmlFor={`images_service_${this.state.serviceID}`} />
                                                                                        <input className="fileInput"
                                                                                            type="file"
                                                                                            name={`images_service_${this.state.serviceID}`}
                                                                                            id={`images_service_${this.state.serviceID}`}
                                                                                            accept="image/*"
                                                                                            style={{ display: 'none' }}
                                                                                            multiple={true}
                                                                                            onChange={(e) => this.onSelectFile(e, 'serviceJobs', 'images', 'Vehicle Image')} />
                                                                                    </label>
                                                                                </div>
                                                                        }
                                                                        {
                                                                            !_.isEmpty(images)
                                                                                ?
                                                                                <>
                                                                                    {
                                                                                        images.map((data, index) => {
                                                                                            return <div className="service-section-item-image" key={index}>
                                                                                                {_isCompleted ? <></> :
                                                                                                    <>
                                                                                                        <div className="image-delete img-delete-button" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.handleImageDel(index);
                                                                                                        }}>
                                                                                                            <i className="ico icon-delete" ></i>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }

                                                                                                <a data-fancybox={`services_img`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                                    <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                                                                </a>
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                </>
                                                                        }
                                                                        {
                                                                            (imageURL) ?
                                                                                (
                                                                                    <div className="service-section-item-image">
                                                                                        <div className="img-loader">
                                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                                                    <span className="sr-only"><Translate text={'Loading...'} /></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                            <img src={imageURL} width="70" height="70" alt="" />
                                                                                        </a>
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            (imageURLs) ?
                                                                                <>
                                                                                    {
                                                                                        imageURLs.map((data, index) => {
                                                                                            return <div key={index} className="service-section-item-image">
                                                                                                <div className="img-loader">
                                                                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                                                                            <span className="sr-only"><Translate text={'Loading...'} /></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                    <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" />
                                                                                                </a>
                                                                                            </div>
                                                                                        })
                                                                                    }


                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }



                                                                    </div>

                                                                </Tab>
                                                                <Tab eventKey='videos' title='Videos'>

                                                                    <div className="service-section-thumb-wraper ">
                                                                        {
                                                                            _isCompleted || !canModifyService
                                                                                ?
                                                                                <></>
                                                                                :
                                                                                <div className={`service-section-item-image upload cursor-pointer ${this.state.errors["videos"] ? 'border-error' : ''}`}
                                                                                    onClick={(e) => { e.preventDefault(); this.handleVideoOpen(this.state.serviceID); }}>
                                                                                    <img src={_images.addVideo} alt="" />
                                                                                </div>
                                                                        }
                                                                        {
                                                                            !_.isEmpty(_serviceVideos)
                                                                                ?
                                                                                <>
                                                                                    {
                                                                                        _.orderBy(_serviceVideos, ['addedDate'], ['desc']).map((data, index) => {
                                                                                            return <div className="service-section-item-image" key={index}>
                                                                                                {_isCompleted ? <></> :
                                                                                                    <>
                                                                                                        <div className="image-delete img-delete-button" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.deleteVideo(data.documentID);
                                                                                                        }}>
                                                                                                            <i className="ico icon-delete" ></i>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }

                                                                                                <a data-fancybox={`services_videos`} href={data.url} onClick={(e) => { e.preventDefault(); }}>
                                                                                                    <img src={_images.previewVideo} width="70" height="70" alt="" className="img-object-fit" />
                                                                                                </a>
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <></>

                                                                        }

                                                                    </div>

                                                                </Tab>
                                                            </Tabs>
                                                        </nav>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }

                            {/** RECOMMENDATION **/}
                            {
                                isScheduledJobDone
                                    ?
                                    <>
                                        <div className="service-section-boxloop mt-3">
                                            <div className="service-section-boxloop-header">
                                                <div className="float-left">
                                                    <h3 className="service-section-page-title">
                                                        <span className="service-section-page-title-icon bg-service-orange text-white mr-3">
                                                            <i className="ico icon-service-recommendation"></i> </span> <Translate text={'Recommendation'} /> </h3>
                                                </div>

                                                <div className="float-right">

                                                    <div className={`float-right ${canAddNewRecommendations ? 'mr-5' : ''}`}>
                                                        {!isChecklist && _isCompleted
                                                            ?
                                                            <></>
                                                            :
                                                            <><button type="button" className="btn btn-primary mt-1"
                                                                onClick={(e) => { e.preventDefault(); if (this.isValidateAppointment()) { this.handleCheckListOpen(); } }}
                                                            >
                                                                {
                                                                    (isChecklist || !checklistAccess)
                                                                        ?
                                                                        <><i className="ico icon-service-checklist mr-1"></i> <Translate text={'View Check List'} /></>
                                                                        :
                                                                        <><i className="ico icon-add"></i> <Translate text={'Add Check List'} /></>
                                                                }
                                                            </button></>
                                                        }

                                                        {!isBbtChecklist && _isCompleted
                                                            ?
                                                            <></>
                                                            :
                                                            <><button type="button" className="btn btn-secondary mt-1 ml-2"
                                                                onClick={(e) => { e.preventDefault(); if (this.isValidateAppointment()) { this.handleBBTCheckListOpen(); } }}
                                                            >
                                                                {
                                                                    (isBbtChecklist || !checklistBBTAccess)
                                                                        ?
                                                                        <><i className="ico icon-service-checklist"></i> <Translate text={'View BBT Check List'} /></>
                                                                        :
                                                                        <><i className="ico icon-add"></i> <Translate text={'Add BBT Check List'} /></>
                                                                }
                                                            </button></>
                                                        }


                                                    </div>
                                                    {
                                                        (_isCompleted === false && recommendationAccess && canModifyService)
                                                            ?
                                                            <>
                                                                {
                                                                    (technicianOnlyAccess && _jobStatus !== serviceStatus.TECHNICIANREQUESTED)
                                                                        ?
                                                                        <></>
                                                                        :
                                                                        <>
                                                                            <div className="service-add-button">
                                                                                <a href="#" className="mini-button"
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        this.handleAddRecommendation();
                                                                                    }}
                                                                                >
                                                                                    <i className="ico icon-add"></i></a>
                                                                            </div>
                                                                        </>
                                                                }

                                                            </>
                                                            :
                                                            <></>
                                                    }

                                                </div>
                                            </div>

                                            <div className="service-section-page-content  form-style">
                                                {
                                                    (!_.isEmpty(_recommRequiredList))
                                                        ?
                                                        <>
                                                            <Button className='mb-3' onClick={(e) => { e.preventDefault(); this.setState({ expanded: !expanded, activeRightKey: '' }) }}>
                                                                {expanded ? 'Collapse All' : 'Expand All'}
                                                            </Button>
                                                            <div className="recom-list-accordion">
                                                                <Accordion className="quickview-related">
                                                                    {
                                                                        _.orderBy(_recommRequiredList, ["addedDate"], ["asc"]).map((_recommData, index) => {

                                                                            return <Card key={index} className={`${this.state.errorsRecomm[`recomm_${_recommData.documentID}`] ? 'border-error' : ''}`}>
                                                                                <>
                                                                                    {(() => {

                                                                                        let _recommCosts = CommonHelper.getTotalRecommCost(this.state.fields, _recommData);

                                                                                        const _isDiscountAppliedRecomm = _recommData.detail && _recommData.detail.discount && _recommData.detail.discountType ? true : false;

                                                                                        return <>
                                                                                            <Accordion.Toggle as={Card.Header} eventKey={`0${index}`} className={`${expanded ? 'form-lite' : ''}`} onClick={(e) => {
                                                                                                e.preventDefault();

                                                                                                if (activeRightKey === `0${index}`)
                                                                                                    this.handleRightActiveKey(null);
                                                                                                else
                                                                                                    this.handleRightActiveKey(`0${index}`);
                                                                                            }}>
                                                                                                <div className="mb-0" id={`recomm_${_recommData.documentID}`}>
                                                                                                    <div className="cardlink">
                                                                                                        <a
                                                                                                            onClick={(e) => { e.preventDefault(); }}
                                                                                                            className={`btn btn-link ${activeRightKey === `0${index}` ? '' : 'collapsed'}`}
                                                                                                        >
                                                                                                            <div className="recom-list-head">{_recommData.name}
                                                                                                                <span className="badge badge-pill badge-cafe ml-1">
                                                                                                                    {
                                                                                                                        !_.isEmpty(_recommData.detail) && _recommData.detail.parts
                                                                                                                            ?
                                                                                                                            _recommData.detail.parts.length
                                                                                                                            :
                                                                                                                            0
                                                                                                                    }
                                                                                                                </span>


                                                                                                            </div>

                                                                                                            <div className="recom-list-oktodo">
                                                                                                                {
                                                                                                                    !_.isEmpty(_recommData.detail)
                                                                                                                        ?
                                                                                                                        <img src={_recommData.detail.oKToDo ? (_recommData.detail.totalLabourCost ? _images.tickcircular : _images.todoyes) : (_recommData.detail.oKToDo === false ? _images.todono : _images.todoempty)} alt="" width="20" />
                                                                                                                        :
                                                                                                                        <img src={_images.todoempty} alt="" width="20" />
                                                                                                                }
                                                                                                            </div>
                                                                                                            {
                                                                                                                (!_.isEmpty(_recommData.detail) && _.isBoolean(_recommData.detail.oKToDo))
                                                                                                                    ?
                                                                                                                    <><div className="recom-list-cost">
                                                                                                                        <NumberFormat value={_recommCosts.subtotalAfterDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} />
                                                                                                                    </div>
                                                                                                                        {
                                                                                                                            _recommData.detail.oKToDo && _recommData.detail.recomStatus && (<div className="recom-list-current-status">
                                                                                                                                <div title="Recommendation Status" className={`badge badge-pill badge-service-${_recommData.detail.recomStatus.toLowerCase()} ml-2`}>
                                                                                                                                    <Translate
                                                                                                                                        text={_recommData.detail.recomStatus.split(/(?=[A-Z])/).join(' ').toLowerCase()}
                                                                                                                                        upperCase={true}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>)
                                                                                                                        }
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <></>
                                                                                                            }
                                                                                                            {
                                                                                                                _isDiscountAppliedRecomm
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <div className="recom-list-discountcost">
                                                                                                                            <div className="recom-list-discountcost-inner">
                                                                                                                                <div className="recom-list-discountcost-title"><Translate text={'discount'} />:&nbsp;
                                                                                                                                    <NumberFormat value={_recommCosts.actualDiscount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <></>
                                                                                                            }

                                                                                                            <i className="arrow-collapse"></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Accordion.Toggle>


                                                                                            <Accordion.Collapse style={expanded ? { display: 'block' } : {}} eventKey={`0${index}`}>
                                                                                                <Card.Body>
                                                                                                    <div className="recom-details-wrap-inner">

                                                                                                        <div className="recom-header-info">
                                                                                                            <div className={`option`}>
                                                                                                                <h6 className={this.state.errorsRecomm[`option_${_recommData.documentID}`] ? 'border-error' : ''}><Translate text={'option'} /> </h6>
                                                                                                                <button className="btn btn-recom-lightblue"> {_recommData.detail && _recommData.detail.option ? _recommData.detail.option.toUpperCase() : '--'} </button>
                                                                                                            </div>

                                                                                                            <div className={`vehicle-status`}>
                                                                                                                <h6 className={this.state.errorsRecomm[`status_${_recommData.documentID}`] ? 'border-error' : ''}><Translate text={'status'} /></h6>
                                                                                                                {_recommData.detail && _recommData.detail.status ? <button className={`btn btn-recom-${_recommData.detail.status.toLowerCase()} active`}> {_recommData.detail.status.toUpperCase()} </button> : '--'}

                                                                                                            </div>
                                                                                                            <div className={`vehicle-oil`}>
                                                                                                                <h6 className={this.state.errorsRecomm[`oilMiscSub_${_recommData.documentID}`] ? 'border-error' : ''}><Translate text={'oilMiscSub'} /> </h6>
                                                                                                                <div className="nil">{_recommData.detail && _recommData.detail.oilMiscSub ? _recommData.detail.oilMiscSub : '--'}</div>
                                                                                                            </div>
                                                                                                            {
                                                                                                                (_isCompleted === false && recommendationAccess && canModifyService)
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <div className="recom-partsinfo-edit">
                                                                                                                            <a href="#" className="mini-button float-right"
                                                                                                                                onClick={e => {
                                                                                                                                    e.preventDefault();
                                                                                                                                    this.handleEditRecommendation(_recommData);
                                                                                                                                }}>
                                                                                                                                <i className="ico icon-edit"></i>
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <></>
                                                                                                            }

                                                                                                            <div className={`damage-pic`}>
                                                                                                                <h6 className={this.state.errorsRecomm[`images_${_recommData.documentID}`] ? 'border-error' : ''}><Translate text={'Photos'} /></h6>
                                                                                                                <ul>
                                                                                                                    {
                                                                                                                        _recommData.detail && _recommData.detail.images && _recommData.detail.images.map((img, i2) => {
                                                                                                                            return <li key={i2}>
                                                                                                                                <a data-fancybox={`_recommData_detail_images_${_recommData.documentID}`} href={img} onClick={(e) => { e.preventDefault(); }}>
                                                                                                                                    <img className="img-object-fit" src={img} alt="" width="50" height="50" />
                                                                                                                                </a>
                                                                                                                            </li>
                                                                                                                        })
                                                                                                                    }
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                            <div className={`damage-pic`}>
                                                                                                                <h6 className={this.state.errorsRecomm[`videos_${_recommData.documentID}`] ? 'border-error' : ''}><Translate text={'Videos'} /></h6>
                                                                                                                <ul>
                                                                                                                    {
                                                                                                                        serviceVideos.filter(item => item.recordID === _recommData.documentID).map((vid, i2) => {
                                                                                                                            return <li key={i2}>
                                                                                                                                <a data-fancybox={`_recommData_detail_videos_${_recommData.documentID}`} href={vid.url} onClick={(e) => { e.preventDefault(); }}>
                                                                                                                                    <img className="img-object-fit" src={_images.previewVideo} alt="" width="50" height="50" />
                                                                                                                                </a>
                                                                                                                            </li>
                                                                                                                        })
                                                                                                                    }
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                            <div className="clear"></div>
                                                                                                        </div>
                                                                                                        <div className="recom-parts-note">
                                                                                                            <div className="damage-report">
                                                                                                                <h6><Translate text={'faultReports'} /></h6>
                                                                                                                <div className={`notes-item text-area-space ${this.state.errorsRecomm[`faultReports_${_recommData.documentID}`] ? 'border-error' : ''}`}>{_recommData.detail && _recommData.detail.faultReports ? _recommData.detail.faultReports : '--'} </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="w-100 d-inline-block">
                                                                                                            <div className="float-left"> <h2><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="parts-svg-icon"><path d="M22.7,19L13.6,9.9C14.5,7.6 14,4.9 12.1,3C10.1,1 7.1,0.6 4.7,1.7L9,6L6,9L1.6,4.7C0.4,7.1 0.9,10.1 2.9,12.1C4.8,14 7.5,14.5 9.8,13.6L18.9,22.7C19.3,23.1 19.9,23.1 20.3,22.7L22.6,20.4C23.1,20 23.1,19.3 22.7,19Z" /></svg><Translate text={'Parts Requirement'} /></h2></div>
                                                                                                            <div className="float-right">
                                                                                                                {
                                                                                                                    (_isCompleted === false && partsAccess && canModifyService)
                                                                                                                        ?
                                                                                                                        <>
                                                                                                                            <button type="button" className="btn btn-primary float-right ml-1" onClick={e => {
                                                                                                                                e.preventDefault();
                                                                                                                                this.handleAddPart(_recommData.documentID, _recommData);
                                                                                                                            }}><i className="ico icon-add mr-2"></i> <Translate text={'Add Parts'} /></button>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <></>
                                                                                                                }


                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="common-table">
                                                                                                            <table className="table table-bordered mb-0">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th scope="col" className="head-light" width="10%"><Translate text={'partNumber'} /></th>
                                                                                                                        <th scope="col" className="head-light" width="30%"><Translate text={'partName'} /></th>
                                                                                                                        <th scope="col" className="head-light" width="8%"><Translate text={'quote'} /></th>
                                                                                                                        <th scope="col" className="head-light" width="8%"><Translate text={'partStatus'} /></th>
                                                                                                                        <th scope="col" className="head-light" width="5%"><Translate text={'quantity'} /></th>
                                                                                                                        <th scope="col" className="head-light" width="10%"><Translate text={'Part Cost'} /> </th>
                                                                                                                        <th scope="col" className="head-light" width="8%"><Translate text={'Markdown %'} /> </th>
                                                                                                                        <th scope="col" className="head-light" width="10%"><Translate text={'Total Cost'} /> </th>
                                                                                                                        <th scope="col" className="head-light border-right-0" width="5%"><Translate text={'Actions'} /> </th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        _recommData.detail && !_.isEmpty(_recommData.detail.parts)
                                                                                                                            ?
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    _recommData.detail.parts.map((_part, index1) => {
                                                                                                                                        return <tr key={index1} className={[recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote) ? 'font-italic' : ''}>
                                                                                                                                            <td>{_part.partNumber ? _part.partNumber : '--'}</td>
                                                                                                                                            <td>{_part.partName ? _part.partName : '--'}</td>
                                                                                                                                            <td>{_part.quote ? CommonHelper.getLabelByValue(partQuotes, _part.quote, '--') : '--'}</td>
                                                                                                                                            <td>
                                                                                                                                                {
                                                                                                                                                    _part.partStatus
                                                                                                                                                        ?
                                                                                                                                                        <>{CommonHelper.getLabelByValue(partStatuses, _part.partStatus, '--')} </>
                                                                                                                                                        :
                                                                                                                                                        <></>
                                                                                                                                                }
                                                                                                                                            </td>
                                                                                                                                            <td>{_part.quantity ? _part.quantity : '--'}</td>
                                                                                                                                            <td>{_part.partCost ? <NumberFormat value={_part.partCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : '--'} </td>
                                                                                                                                            <td>{_part.priceLevelCode ? _part.priceLevelCode : '--'}</td>
                                                                                                                                            <td>{_part.totalCost ? <strong><NumberFormat value={([recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote)) ? 0 : _part.totalCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></strong> : '--'} </td>

                                                                                                                                            <td className="border-right-0"><div className="action-fix">
                                                                                                                                                <div className="table-edit float-left mr-2">
                                                                                                                                                    {
                                                                                                                                                        (_isCompleted === false && partsAccess && canModifyService)
                                                                                                                                                            ?
                                                                                                                                                            <>
                                                                                                                                                                <a href="#" className="mini-button"
                                                                                                                                                                    onClick={e => {
                                                                                                                                                                        e.preventDefault();
                                                                                                                                                                        this.handleEditPart(_recommData.documentID, _recommData, _part);
                                                                                                                                                                    }}>
                                                                                                                                                                    <i className="ico icon-edit"></i>
                                                                                                                                                                </a>
                                                                                                                                                            </>
                                                                                                                                                            :
                                                                                                                                                            <>
                                                                                                                                                                <a href="#" className="mini-button"
                                                                                                                                                                    onClick={e => {
                                                                                                                                                                        e.preventDefault();
                                                                                                                                                                    }}>
                                                                                                                                                                    <i className="ico icon-lock"></i>
                                                                                                                                                                </a>
                                                                                                                                                            </>
                                                                                                                                                    }

                                                                                                                                                </div>
                                                                                                                                                <div className="table-edit float-left mr-2">
                                                                                                                                                    {
                                                                                                                                                        (_isCompleted === false && partsAccess && canModifyService)
                                                                                                                                                            ?
                                                                                                                                                            <>
                                                                                                                                                                <a href="#" className="mini-button" onClick={e => {
                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                    this.handleDeletePart(_recommData.documentID, _recommData, _part.documentID);
                                                                                                                                                                }}>
                                                                                                                                                                    <i className="ico icon-delete"></i>
                                                                                                                                                                </a>
                                                                                                                                                            </>
                                                                                                                                                            :
                                                                                                                                                            <>
                                                                                                                                                                <a href="#" className="mini-button"
                                                                                                                                                                    onClick={e => {
                                                                                                                                                                        e.preventDefault();
                                                                                                                                                                    }}>
                                                                                                                                                                    <i className="ico icon-lock"></i>
                                                                                                                                                                </a>
                                                                                                                                                            </>
                                                                                                                                                    }

                                                                                                                                                </div>
                                                                                                                                            </div></td>
                                                                                                                                        </tr>
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td colSpan={9}>
                                                                                                                                        <div className="spinner-loader">
                                                                                                                                            <div className="no-data-flex justify-content-center align-items-center ">
                                                                                                                                                <div className="no-data-txt mt-2"> No data</div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </>
                                                                                                                    }

                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                        {
                                                                                                            partsAccess || serviceAdvisorAccess || workshopManagerAccess
                                                                                                                ?
                                                                                                                <>
                                                                                                                    <div className="service-parts-labourwrap">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-md-12">
                                                                                                                                <div className="labourcost-wrapper">
                                                                                                                                    {
                                                                                                                                        (_isCompleted === false && (recommendationAccess || workshopManagerAccess || serviceAdvisorAccess) && canModifyService)
                                                                                                                                            ?
                                                                                                                                            <>
                                                                                                                                                <div className="gridin">
                                                                                                                                                    <div className="gridin-title"><Translate text={'Allocation Job Time'} /> <span><Translate text={'hour(s)'} /></span></div>
                                                                                                                                                    <div className="dtl-list">
                                                                                                                                                        <InputAutoNumber
                                                                                                                                                            id={`allocationJobTime_${_recommData.documentID}`}
                                                                                                                                                            name={`allocationJobTime_${_recommData.documentID}`}
                                                                                                                                                            allowNegative={false}
                                                                                                                                                            thousandSeparator={true}
                                                                                                                                                            allowLeadingZeros={true}
                                                                                                                                                            className={`form-control cursor-pointer ${this.state.errorsRecomm[`allocationJobTime_${_recommData.documentID}`]}`}
                                                                                                                                                            onValueChange={this.onCostChange.bind(this, 'allocationJobTime', _recommData.documentID)}
                                                                                                                                                            value={_recommData.detail && _.isNumber(_recommData.detail.allocationJobTime) ? _recommData.detail.allocationJobTime : null}
                                                                                                                                                            onSaveField={this.saveRecommField}
                                                                                                                                                            onCancelField={this.cancelRecommField}
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <div className="gridin">
                                                                                                                                                    <div className="gridin-title"><Translate text={'Labour Cost'} /> <span><Translate text={'(Per Hour)'} /></span></div>
                                                                                                                                                    <div className="dtl-list">
                                                                                                                                                        <InputAutoNumber
                                                                                                                                                            id={`labourCost_${_recommData.documentID}`}
                                                                                                                                                            name={`labourCost_${_recommData.documentID}`}
                                                                                                                                                            allowNegative={false}
                                                                                                                                                            thousandSeparator={true}
                                                                                                                                                            allowLeadingZeros={true}
                                                                                                                                                            prefix={currencySymbol}
                                                                                                                                                            placeholder={currencySymbol}
                                                                                                                                                            className={`form-control cursor-pointer ${this.state.errorsRecomm[`labourCost_${_recommData.documentID}`]}`}
                                                                                                                                                            onValueChange={this.onCostChange.bind(this, 'labourCost', _recommData.documentID)}
                                                                                                                                                            value={_recommData.detail && _.isNumber(_recommData.detail.labourCost) ? _recommData.detail.labourCost : null}
                                                                                                                                                            onSaveField={this.saveRecommField}
                                                                                                                                                            onCancelField={this.cancelRecommField}
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <div className="gridin form-disable">
                                                                                                                                                    <div className="gridin-title"><Translate text={'Total Labour Cost'} /></div>
                                                                                                                                                    <div className="dtl-list form-disable">
                                                                                                                                                        <NumberFormat
                                                                                                                                                            id={`totalLabourCost_${_recommData.documentID}`}
                                                                                                                                                            thousandSeparator={true}
                                                                                                                                                            decimalScale={2}
                                                                                                                                                            allowNegative={false}
                                                                                                                                                            prefix={currencySymbol}
                                                                                                                                                            placeholder={currencySymbol}
                                                                                                                                                            className={`form-control ${this.state.errorsRecomm[`totalLabourCost_${_recommData.documentID}`]}`}
                                                                                                                                                            onValueChange={this.onDummyChange.bind(this, 'totalLabourCost', _recommData.documentID)}
                                                                                                                                                            value={_recommData.detail && _.isNumber(_recommData.detail.totalLabourCost) ? _recommData.detail.totalLabourCost : null}
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                            </>
                                                                                                                                            :
                                                                                                                                            <>
                                                                                                                                                {
                                                                                                                                                    (recommendationAccess || workshopManagerAccess)
                                                                                                                                                        ?
                                                                                                                                                        <>
                                                                                                                                                            <div className="gridin">
                                                                                                                                                                <div className="gridin-title"><Translate text={'Allocation Job Time'} /> <span><Translate text={'hour(s)'} />:</span></div>
                                                                                                                                                                <div className="dtl-list mt-1">
                                                                                                                                                                    <strong>
                                                                                                                                                                        <NumberFormat
                                                                                                                                                                            decimalScale={2}
                                                                                                                                                                            displayType={'text'}
                                                                                                                                                                            thousandSeparator={true}
                                                                                                                                                                            value={_recommData.detail && _recommData.detail.allocationJobTime ? _recommData.detail.allocationJobTime : null}
                                                                                                                                                                        />
                                                                                                                                                                    </strong>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                            <div className="gridin">
                                                                                                                                                                <div className="gridin-title"><Translate text={'Labour Cost'} /> <span><Translate text={'(Per Hour)'} />:</span></div>
                                                                                                                                                                <div className="dtl-list mt-1">
                                                                                                                                                                    <strong>
                                                                                                                                                                        <NumberFormat
                                                                                                                                                                            decimalScale={2}
                                                                                                                                                                            displayType={'text'}
                                                                                                                                                                            thousandSeparator={true}
                                                                                                                                                                            prefix={currencySymbol}
                                                                                                                                                                            value={_recommData.detail && _recommData.detail.labourCost ? _recommData.detail.labourCost : null}
                                                                                                                                                                        />
                                                                                                                                                                    </strong>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                            <div className="gridin">
                                                                                                                                                                <div className="gridin-title"><Translate text={'Total Labour Cost'} />:</div>
                                                                                                                                                                <div className="dtl-list mt-1">
                                                                                                                                                                    <strong>
                                                                                                                                                                        <NumberFormat
                                                                                                                                                                            decimalScale={2}
                                                                                                                                                                            displayType={'text'}
                                                                                                                                                                            thousandSeparator={true}
                                                                                                                                                                            prefix={currencySymbol}
                                                                                                                                                                            value={_recommData.detail && _recommData.detail.totalLabourCost ? _recommData.detail.totalLabourCost : null}
                                                                                                                                                                        />
                                                                                                                                                                    </strong>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </>
                                                                                                                                                        :
                                                                                                                                                        <></>
                                                                                                                                                }

                                                                                                                                            </>
                                                                                                                                    }


                                                                                                                                </div>

                                                                                                                                {
                                                                                                                                    _isDiscountAppliedRecomm
                                                                                                                                        ?
                                                                                                                                        <>
                                                                                                                                            <div className="labourcost-discount-list mr-3">
                                                                                                                                                {
                                                                                                                                                    discountAccess && canModifyService
                                                                                                                                                        ?
                                                                                                                                                        <div className="labourcost-discount-edit">
                                                                                                                                                            <a href="#" onClick={(e) => {
                                                                                                                                                                e.preventDefault();
                                                                                                                                                                this.handleOpenRecommDiscount(_recommData);
                                                                                                                                                            }}> <i className="fas fa-pen-square" aria-hidden="true"></i>
                                                                                                                                                            </a>
                                                                                                                                                        </div>
                                                                                                                                                        :
                                                                                                                                                        <>
                                                                                                                                                            <div className="labourcost-discount-edit">
                                                                                                                                                                <a href="#" onClick={(e) => {
                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                    this.handleOpenRecommDiscount(_recommData, true);
                                                                                                                                                                }}> <i className="far fa-pen-square" aria-hidden="true"></i>
                                                                                                                                                                </a>
                                                                                                                                                            </div>
                                                                                                                                                        </>
                                                                                                                                                }

                                                                                                                                                <div className="labourcost-discount-title"><Translate text={'discount'} />:&nbsp;
                                                                                                                                                    <NumberFormat value={_recommCosts.actualDiscount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </>
                                                                                                                                        :
                                                                                                                                        <>
                                                                                                                                            {
                                                                                                                                                _recommCosts.total && discountEnabled && discountAccess && canModifyService
                                                                                                                                                    ?
                                                                                                                                                    <>
                                                                                                                                                        <div className="labourcost-discount-btn">
                                                                                                                                                            <button className="btn btn-sm btn-default" onClick={(e) => {
                                                                                                                                                                e.preventDefault();
                                                                                                                                                                this.handleOpenRecommDiscount(_recommData);
                                                                                                                                                            }}><i className="ico icon-add"></i><Translate text={'Add Discount'} /></button>
                                                                                                                                                        </div>
                                                                                                                                                    </>
                                                                                                                                                    :
                                                                                                                                                    <></>
                                                                                                                                            }

                                                                                                                                        </>
                                                                                                                                }

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }
                                                                                                        {
                                                                                                            (_isCompleted === false && recommendationAccess && canModifyService)
                                                                                                                ?
                                                                                                                <>
                                                                                                                    <div className="recom-details-parts-footer">
                                                                                                                        <div className={`oktodo-wrap ${this.state.errorsRecomm[`oKToDo_${_recommData.documentID}`] ? 'border-error' : ''}`}>
                                                                                                                            <div className="oktodo-title"><Translate text={'oKToDo'} /></div>

                                                                                                                            <div className="radio icheck-success float-left mr-2">

                                                                                                                                <InputRadio
                                                                                                                                    id={`Yes_${_recommData.documentID}`}
                                                                                                                                    type="radio"
                                                                                                                                    value="Yes"
                                                                                                                                    name={_recommData.documentID}
                                                                                                                                    checked={_recommData.detail && _recommData.detail.oKToDo === true ? true : false}
                                                                                                                                    onChange={this.handleOkToDoChange}
                                                                                                                                />
                                                                                                                                <label htmlFor={`Yes_${_recommData.documentID}`}><Translate text={'yes'} /></label>
                                                                                                                            </div>
                                                                                                                            <div className="radio icheck-success float-left mr-2 todo-red">
                                                                                                                                <InputRadio
                                                                                                                                    id={`No_${_recommData.documentID}`}
                                                                                                                                    type="radio"
                                                                                                                                    value="No"
                                                                                                                                    name={_recommData.documentID}
                                                                                                                                    checked={_recommData.detail && _recommData.detail.oKToDo === false ? true : false}
                                                                                                                                    onChange={this.handleOkToDoChange}
                                                                                                                                />
                                                                                                                                <label htmlFor={`No_${_recommData.documentID}`}><Translate text={'no'} /></label>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            _recommData?.detail?.oKToDo && (<div className="service-req-current-status">
                                                                                                                                <strong>Recommendation Status</strong>
                                                                                                                                <div className={`badge badge-pill badge-empty ml-2`}
                                                                                                                                    style={{ background: CommonHelper.getFieldByValue(recommStatuses, _recommData?.detail?.recomStatus, 'color', '#b0babe') }}>
                                                                                                                                    <DropDownMenu
                                                                                                                                        text={_recommData?.detail?.recomStatus ? CommonHelper.getNameByValue(recommStatuses, _recommData.detail.recomStatus) : 'Select status'}
                                                                                                                                        keyVal={`stat_${_recommData.documentID}`}
                                                                                                                                        tIndex={`00${index}`}
                                                                                                                                        menu={recommStatuses.filter(e => e.value !== _recommData?.detail?.recomStatus)}
                                                                                                                                        handleChange={(status) => { this.handleRecomStatusChange(status, _recommData.documentID) }}
                                                                                                                                    >
                                                                                                                                    </DropDownMenu>
                                                                                                                                </div>
                                                                                                                            </div>)
                                                                                                                        }

                                                                                                                        <div className="float-right">
                                                                                                                            <button type="button" className="btn btn-red float-right ml-2"
                                                                                                                                onClick={e => {
                                                                                                                                    e.preventDefault(); this.handleDeleteRecommendation(_recommData);
                                                                                                                                }}><Translate text={'delete'} /></button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }

                                                                                                        {
                                                                                                            _recommData.detail && _recommData.detail.oKToDo === false && _recommData.detail.reason
                                                                                                                ?
                                                                                                                <div className="reason-text-panel "><span><Translate text={'Reason'} />: </span>
                                                                                                                    <div className="text-area-space "> {_recommData.detail.reason} </div>

                                                                                                                </div>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }

                                                                                                    </div>

                                                                                                </Card.Body>
                                                                                            </Accordion.Collapse>
                                                                                        </>
                                                                                    })()}
                                                                                </>


                                                                            </Card>
                                                                        })
                                                                    }
                                                                </Accordion>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="spinner-loader">
                                                                <div className="no-cafe-flex justify-content-center align-items-center ">
                                                                    <div className="no-cafe-img">
                                                                        <i className="ico icon-service-recommendation"></i> </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </div>

                                        </div>

                                    </>
                                    :
                                    <>
                                    </>
                            }



                            {/** NOTES **/}
                            {/* {
                                !_.isEmpty(serviceNotes)
                                    ?
                                    <>
                                        <div className="service-section-boxloop mt-3">
                                            <div className="service-section-boxloop-header">
                                                <div className="float-left">
                                                    <h3 className="service-section-page-title"> <span className="service-section-page-title-icon bg-service-yellow text-white mr-3">
                                                        <i className="ico icon-service-notes"></i> </span> <Translate text={'Service Notes'} /> </h3>
                                                </div>
                                                 <div className="float-right">
                                        <div className="service-add-button">
                                            <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); this.handleNotesOpen(); }}>
                                                <i className="ico icon-add"></i>
                                            </a>
                                        </div>

                                    </div> 
                                            </div>

                                            <div className="service-section-page-content  form-style">
                                                {
                                                    !_.isEmpty(serviceNotes)
                                                        ?
                                                        <>
                                                            {
                                                                _.orderBy(serviceNotes, ['addedDate'], ['desc']).map((noteData, index) => {
                                                                    return <div key={index} className="notes-item text-area-space ">
                                                                        {noteData.note}
                                                                        <div className="notes-item-sub">
                                                                            {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), noteData.addedBy)}
                                                                            <span>{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <div className="spinner-loader">
                                                                <div className="no-cafe-flex justify-content-center align-items-center ">
                                                                    <div className="no-cafe-img">
                                                                        <i className="ico icon-note icon-service-nonote"></i> </div>
                                                                </div>
                                                            </div>
                                                        </>

                                                }


                                            </div>

                                        </div>
                                    </>
                                    :
                                    <></>

                            } */}

                            {/** CHANGElOG TAB */}
                            {/* <div className="service-section-boxloop mt-3">
                                <div className="service-section-boxloop-header">
                                    <div className="float-left">
                                        <h3 className="service-section-page-title">
                                            <span className="service-section-page-title-icon bg-service-slate text-white mr-3">
                                                <i className="ico icon-service-log"></i> </span> <Translate text={'Change Logs'} /> </h3>

                                    </div>

                                </div>

                                <div className="service-section-page-content  form-style">
                                    <ChangeLog
                                        service={this.state.fields}
                                        clientUsers={this.props.clientUsers}
                                        groupUsers={this.props.groupUsers}
                                        dealersettings={this.props.dealersettings}
                                        history={this.props.history}
                                    >
                                    </ChangeLog>
                                </div>
                            </div> */}


                            {/** ACTIVITY TAB */}
                            <div className="mt-3">
                                <Activity
                                    service={this.state.fields}
                                    clientUsers={clientUsers}
                                    groupUsers={groupUsers}
                                    dealersettings={dealersettings}
                                    //isNewEnquiry={isNew}
                                    history={this.props.history}
                                    dialNumber={this.props.dialNumber}
                                >
                                </Activity>
                            </div>


                        </div>


                    </div>
                </div>


                <PopUpModal show={contactModal.show}>
                    <AddContact
                        docID={this.state.contact.documentID}
                        show={contactModal.show}
                        clsActive={contactModal.clsActive}
                        engageNewContact={engageNewContact}
                        handleClose={this.handleContactClose}
                        title={contactModal.title}
                        showSearch={isCheckInJobDone === true ? false : true}
                        mandatoryFields={contactModal.mandatoryFields}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        serviceID={this.state.serviceID}
                    >
                    </AddContact>
                </PopUpModal>

                <PopUpModal show={vehicleModal.show}>
                    <AddVehicle
                        show={vehicleModal.show}
                        clsActive={vehicleModal.clsActive}
                        title={vehicleModal.title}
                        handleClose={this.handleVehicleClose}
                        mandatoryFields={vehicleModal.mandatoryFields}
                        isVehicle={isVehicle}
                        serviceID={this.state.serviceID}
                        contactID={this.state.contact.documentID}
                        isDeivered={canModifyService ? _isCompleted : true}
                        vehicle={vehicleModal.vehicle}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddVehicle>
                </PopUpModal>

                <PopUpModal show={recommendationModal.show}>
                    <AddRecommendation
                        show={recommendationModal.show}
                        docID={recommendationModal.docID}
                        title={recommendationModal.title}
                        recomm={recommendationModal.data}
                        serviceID={this.state.serviceID}
                        handleClose={this.handleRecommendationClose}
                        mandatoryFields={this.mandatoryRecommFields}
                        isVehicle={isVehicle}
                        isDeivered={canModifyService ? _isCompleted : true}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        isOwnerResponsed={isOwnerResponsed}
                        handleStatusReScheduled={this.handleStatusReScheduled}
                        serviceVideos={serviceVideos}
                        handleVideoOpen={this.handleVideoOpen}
                        deleteVideo={this.deleteVideo}
                    >
                    </AddRecommendation>
                </PopUpModal>

                <PopUpModal show={partModal.show}>
                    <AddPart
                        show={partModal.show}
                        docID={partModal.docID}
                        title={partModal.title}
                        part={partModal.part}
                        recommID={partModal.recommID}
                        recommendation={partModal.recommendation}
                        isPackage={partModal.isPackage}
                        serviceID={this.state.serviceID}
                        service={this.state.fields}
                        handleClose={this.handlePartClose}
                        mandatoryFields={this.mandatoryPartsFields}
                        isVehicle={isVehicle}
                        isDeivered={canModifyService ? _isCompleted : true}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        isOwnerResponsed={isOwnerResponsed}
                        handleStatusReScheduled={this.handleStatusReScheduled}
                    >
                    </AddPart>
                </PopUpModal>

                <PopUpModal show={fileModal.show}>
                    <AddFile
                        show={fileModal.show}
                        serviceID={this.state.serviceID}
                        contactID={this.state.contact.documentID}
                        handleClose={this.handleFileClose}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddFile>
                </PopUpModal>

                <PopUpModal show={cropShow.show}>
                    <ImageCropHook
                        cropShow={cropShow}
                        handleClose={this.handleCropClose}
                        handleSelect={this.handleCropSelect}
                    ></ImageCropHook>
                </PopUpModal>

                <PopUpModal show={showCanvas}>
                    <ImageCanvas
                        show={showCanvas}
                        clsActive='overlay-modal active'
                        imagePath='serviceJobs'
                        marks={this.state.fields.marks}
                        handleClose={this.handleCanvasClose}
                        dealersettings={dealersettings}
                        showCost={false}
                        isReadOnly={(_isCompleted || !canModifyService) ? true : !inspectionAccess}
                    ></ImageCanvas>
                </PopUpModal>

                <PopUpModal show={showAddOns}>
                    <AddOns
                        show={showAddOns}
                        serviceID={this.state.serviceID}
                        service={this.state.fields}
                        dealersettings={dealersettings}
                        handleClose={this.handleAddOnsClose}
                        serviceAddOns={_servicePackagesList}
                        selectedAddOns={servicePackages}
                        isOwnerResponsed={isOwnerResponsed}
                        handleStatusReScheduled={this.handleStatusReScheduled}
                    />
                </PopUpModal>
                <PopUpModal show={showAddOnsInfo.show}>
                    <AddOnsInfo
                        show={showAddOnsInfo.show}
                        dealersettings={dealersettings}
                        handleClose={this.handleAddOnsInfoClose}
                        selectedInfos={showAddOnsInfo.data}
                    />
                </PopUpModal>

                <ChatSidePanel
                    sidepanelClose={this.handleSharePanelClose}
                    clsActive={showSharePanel.chatClsActive}
                    clsName={showSharePanel.chatClsName}
                    title='selectUser'
                    sharePanel={true}
                    sendAs='service'
                    service={this.state.fields}
                />

                <PopUpModal show={showNotes}>
                    <AddNotes
                        show={showNotes}
                        serviceID={this.state.serviceID}
                        handleClose={this.handleNotesClose}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    />
                </PopUpModal>

                <PopUpModal show={showCheckLists}>
                    <AddCheckList
                        show={showCheckLists}
                        serviceID={this.state.serviceID}
                        handleClose={this.handleCheckListClose}
                        recommendations={this.state.recommendations}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        hasAccess={canModifyService ? checklistAccess : false}
                        isOwnerResponsed={isOwnerResponsed}
                        mandatoryRecommFields={this.mandatoryRecommFields}
                        handleStatusReScheduled={this.handleStatusReScheduled}
                        handleEditRecommendation={this.handleEditRecommendation}
                        serviceVideos={serviceVideos}
                        handleVideoOpen={this.handleVideoOpen}
                        deleteVideo={this.deleteVideo}
                    />
                </PopUpModal>

                <PopUpModal show={showBBTCheckLists}>
                    <AddBBTCheckList
                        show={showBBTCheckLists}
                        serviceID={this.state.serviceID}
                        handleClose={this.handleBBTCheckListClose}
                        recommendations={this.state.recommendations}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        hasAccess={canModifyService ? checklistBBTAccess : false}
                        isOwnerResponsed={isOwnerResponsed}
                        mandatoryRecommFields={this.mandatoryRecommFields}
                        handleStatusReScheduled={this.handleStatusReScheduled}
                        handleEditRecommendation={this.handleEditRecommendation}
                        serviceVideos={serviceVideos}
                        handleVideoOpen={this.handleVideoOpen}
                        deleteVideo={this.deleteVideo}
                    />
                </PopUpModal>

                <PopUpModal show={showCustomerApproval}>
                    <CustomerApproval
                        show={showCustomerApproval}
                        handleClose={this.sendToCustomerApproval}
                        contact={this.state.contact}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    />
                </PopUpModal>

                <PopUpModal show={showConfrimQuotation}>
                    <ConfrimQuotation
                        show={showConfrimQuotation}
                        handleClose={this.quotationApproved}
                        contact={this.state.contact}
                        service={this.state.fields}
                        spokeTo={spokeTo}
                        recommendations={_recommRequiredList}
                        servicePackages={servicePackages}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    />
                </PopUpModal>

                <PopUpModal show={showServiceDetails}>
                    <ServiceDetails
                        show={showServiceDetails}
                        handleClose={this.handleServiceDetailClose}
                        serviceJob={this.state.fields}
                        contact={this.state.contact}
                        serviceID={this.state.serviceID}
                        dealersettings={dealersettings}
                        allAdvisorUsers={allAdvisorUsers}
                        allTechnicianUsers={allTechnicianUsers}
                        allWorkshopUsers={allWorkshopUsers}
                        allPartsUsers={allPartsUsers}
                        appointmentTypes={appointmentTypes}
                        service_Types={service_Types}
                        allTeamUsers={allTeamUsers}
                        errors={this.state.errors}
                    />
                </PopUpModal>

                <PopoverPanel
                    showpopover={pipelinePopOver.showpopover}
                    targetpopover={pipelinePopOver.targetpopover}
                    title='Pipeline'
                    position='bottom'
                    id='pipeline-switch'
                    className='popover-pipeline-switch'
                    closepopover={() => {
                        this.setState({
                            pipelinePopOver: { showpopover: false, targetpopover: '' }
                        });
                    }}>
                    <PipelineSwitch
                        pipeline={pipeline}
                        stage={stage}
                        clientID={this.state.fields.clientID}
                        dealersettings={dealersettings}
                        handleClose={this.handlePipelineClose}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        owner={owner}
                        status={status}
                    />
                </PopoverPanel>

                <PopoverPanel
                    showpopover={costPopOver.showpopover}
                    targetpopover={costPopOver.targetpopover}
                    title='Total Cost'
                    position='bottom'
                    id='total-service-cost'
                    className='popover-pipeline-switch'
                    closepopover={() => {
                        this.setState({
                            costPopOver: { showpopover: false, targetpopover: '' }
                        });
                    }}>
                    <ShowCosts
                        fullServiceCost={fullServiceCost}
                        currencySymbol={currencySymbol}
                        isTaxIncluded={_isTaxIncluded}
                        dispalyTax={_dispalyTax}
                        taxType={taxType}
                    />
                </PopoverPanel>

                <PopUpModal show={showDiscountService.show}>
                    <AddDiscount
                        show={showDiscountService.show}
                        isReadOnly={showDiscountService.isview}
                        handleClose={this.handleCloseDiscount}
                        service={this.state.fields}
                        recommendations={_recommRequiredList}
                        servicePackages={servicePackages}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    />
                </PopUpModal>

                <PopUpModal show={showDiscountRecomm.show}>
                    <AddDiscountRecomm
                        show={showDiscountRecomm.show}
                        isReadOnly={showDiscountRecomm.isview}
                        handleClose={this.handleCloseRecommDiscount}
                        service={this.state.fields}
                        recommendation={showDiscountRecomm.data}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    />
                </PopUpModal>

                <PopUpModal show={showDiscountPack.show}>
                    <AddDiscountPack
                        show={showDiscountPack.show}
                        isReadOnly={showDiscountPack.isview}
                        handleClose={this.handleClosePackDiscount}
                        service={this.state.fields}
                        servicePackage={showDiscountPack.data}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    />
                </PopUpModal>

                <PopUpModal show={videoModal.show}>
                    <AddVideo
                        show={videoModal.show}
                        recordID={videoModal.recordID}
                        serviceID={this.state.serviceID}
                        handleClose={this.handleVideoClose}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddVideo>
                </PopUpModal>

                <PopUpModal show={showStatusRequestModal}>
                    <ReRequestStatus
                        show={showStatusRequestModal}
                        currentStatus={_jobStatus}
                        serviceID={this.state.serviceID}
                        handleClose={this.handleRequestStatusClose}
                        handleMoveStageForStatusChange={this.handleMoveStageForStatusChange}
                        dealersettings={dealersettings}
                    >
                    </ReRequestStatus>
                </PopUpModal>

                <PopUpModal show={showLoanAppointmentModal}>
                    <AddLoanAppointment
                        show={showLoanAppointmentModal}
                        serviceLoanStocks={serviceLoanStocks}
                        serviceID={this.state.serviceID}
                        appointmentDate={appointmentDate}
                        timeOfDay={timeOfDay}
                        appID={loanAppointmentID}
                        appStock={_loanAppointmentStock}
                        appData={_loanAppointmentData}
                        handleClose={this.handleCloseLoanAppointment}
                        dealersettings={dealersettings}
                    >
                    </AddLoanAppointment>
                </PopUpModal>

                <PopUpModal show={showStocks}>
                    <StockLoanList
                        show={showStocks}
                        serviceLoanStocks={serviceLoanStocks}
                        handleClose={this.handleCloseLoanStocks}
                        dealersettings={dealersettings}
                        isAvailStock={true}
                    >
                    </StockLoanList>
                </PopUpModal>

                <PopUpModal show={fleetModal.show}>
                    <AddFleet
                        docID={fleetModal.ID}
                        serviceID={fleetModal.serviceID}
                        show={fleetModal.show}
                        clsActive='overlay-modal active'
                        stock={fleetModal.stock}
                        contact={fleetModal.contact}
                        loanAppointmentData={_loanAppointmentData}
                        handleClose={this.handleCloseFleetAndTDModal}
                        title={'fleet'}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddFleet>
                </PopUpModal>

                <PopUpModal show={testDriveModal.show}>
                    <AddTestDrive
                        docID={testDriveModal.ID}
                        show={testDriveModal.show}
                        clsActive='overlay-modal active'
                        handleClose={this.handleCloseFleetAndTDModal}
                        title={'testDrive'}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        isDeivered={false}
                        contact={null}
                    >
                    </AddTestDrive>
                </PopUpModal>

                <PopUpModal show={tradeInModal.show}>
                    <AddTradeIn
                        docID={tradeInModal.tradeinID}
                        show={tradeInModal.show}
                        clsActive={tradeInModal.clsActive}
                        handleClose={this.handleTradeInClose}
                        title={tradeInModal.title}
                        dealersettings={dealersettings}
                        serviceID={this.state.serviceID}
                        service={this.state.fields}
                        contact={this.state.contact}
                        isDeivered={!isTradeIn ? false : canModifyService ? _isCompleted : true}
                        tradeinPlusEnabled={tradeinPlusEnabled}
                    ></AddTradeIn>
                </PopUpModal>
                <PopUpModal show={autoGrabModal.show}>
                    <AutoGrab
                        selectedVehicle={autoGrabModal.selectedVehicle}
                        history={this.props.history}
                        docID={autoGrabModal.tradeinID}
                        show={autoGrabModal.show}
                        clsActive={autoGrabModal.clsActive}
                        handleClose={this.handleAutograbClose}
                        title={autoGrabModal.title}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        dealersettings={dealersettings}
                    ></AutoGrab>
                </PopUpModal>

                <PopUpModal show={showEvalScan}>
                    <EvalScan
                        show={showEvalScan}
                        handleClose={this.handleTradeInClose}
                        dealersettings={dealersettings}
                        serviceID={this.state.serviceID}
                        service={this.state.fields}
                        contact={this.state.contact}
                    />
                </PopUpModal>
                <PopUpModal show={sendLinkModal.show}>
                    <SendTradeInLink
                        show={sendLinkModal.show}
                        tradeinID={sendLinkModal.tradeinID}
                        handleClose={this.handleCloseSendLink}
                        contact={this.state.contact}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    />
                </PopUpModal>
                <PopUpModal show={linkAppraisalModal}>
                    <TradeinLinkAppraisal
                        show={linkAppraisalModal}
                        clientID={this.state.fields.clientID}
                        serviceID={this.state.serviceID}
                        handleClose={this.handleCloseLinkAppraisal}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    />
                </PopUpModal>

                <PopUpModal show={showReasons.show}>
                    <Reasons
                        show={showReasons.show}
                        docID={showReasons.id}
                        docType={showReasons.type}
                        reasons={reasons}
                        handleClose={this.handleReasonsClose}
                        isFromPage={true}
                    />
                </PopUpModal>

            </>
        ) : this.state.pagenotFound ? (
            <PageNotFound />
        ) : (
            <LoaderOverlay text='Fetching service details...' active={true} />
        );
    }
}

export default ServiceInfo;
