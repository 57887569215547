/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import _ from "lodash";
/** COMPONENTS */
import Translate from "../../../../constants/translate";
import { default as _images } from "../../../../images";
import { InputText } from "../../../../components";
/** VIEW-MODELS */
import { firestoreDB } from "../../../../services/helper";

export default class UpdateGenericApiModal extends Component {
  constructor(props) {
    super(props);
    this.isLoading = false;
    this.state = {
      fields: Object.assign({
        integrationKey: "",
      }),
      mandatoryFields: [],
      errors: {},
    };
  }

  componentDidMount() {
    const { dealersettings } = this.props;
    //console.log(dealersettings?.client, 'dealersettings')
    let _mand = [];
    if (_.isEmpty(dealersettings?.client?.integrationKey))
      _mand.push("integrationKey");

    this.setState({ mandatoryFields: _mand });
  }

  //#region ON CHANGE EVENT
  errorChange = (key) => {
    let errors = Object.assign({}, this.state.errors);
    const { mandatoryFields, fields } = this.state;
    let errorClass = "input_error";

    if (errors !== {}) {
      if (!fields[key] && mandatoryFields.indexOf(key) >= 0)
        errors[key] = errorClass;
      else errors[key] = "";
      this.setState({ errors: errors });
    }
  };

  onValueChange = (name, e) => {
    let fields = Object.assign({}, this.state.fields);
    fields[name] = e.value;
    this.setState({ fields: fields }, () => {
      this.errorChange(name);
    });
  };

  handleOnCapChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let fields = Object.assign({}, this.state.fields);
    if (value) fields[name] = _.trim(value);
    else fields[name] = null;
    this.setState({ fields: fields }, () => {
      this.errorChange(name);
    });
  };

  //#region SUBMIT

  handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};
    let formIsValid = true;

    let errorClass = "input_error";
    const { mandatoryFields, fields } = this.state;

    mandatoryFields &&
      mandatoryFields.forEach((key) => {
        if (!fields[key]) {
          formIsValid = false;
          errors[key] = errorClass;
        }
      });
    console.log("errors", errors, fields, mandatoryFields);
    this.setState({ errors: errors });

    if (formIsValid === true) {
      this.handleSave();
    } else return formIsValid;
  };

  handleSave = () => {
    this.isLoading = true;
    const { dealersettings, selectedLevel } = this.props;

    const objData = Object.assign({}, this.state.fields);

    objData.modifiedBy = dealersettings ? dealersettings.id : "";
    objData.modifiedDate = window.firebase.firestore.Timestamp.now();
    objData.modifiedFrom = "web";

    for (var propName in objData) {
      if (
        objData[propName] === null ||
        objData[propName] === undefined ||
        objData[propName] === "" ||
        objData[propName] === [] ||
        (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))
      ) {
        delete objData[propName];
      }
    }

    let integrationRef;
    if (selectedLevel.level === "oem") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID);
    } else if (selectedLevel.level === "region") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID)
        .collection("regions")
        .doc(selectedLevel.levelID);
    } else if (selectedLevel.level === "group") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID)
        .collection("groups")
        .doc(selectedLevel.levelID);
    } else if (selectedLevel.level === "individual") {
      integrationRef = window.firebase
        .firestore()
        .doc(
          `clients/${selectedLevel.levelID}/currentSettings/${selectedLevel.levelID}`
        );
      window.firebase
        .firestore()
        .collection("clients")
        .doc(selectedLevel.levelID)
        .set(objData, { merge: true });
    }
    //   console.log('handleSave', enquiryID, objData, newStatus)

    integrationRef
      .set(objData, { merge: true })
      .then((docRef) => {
        this.isLoading = false;
        this.props.handleClose(objData);
      })
      .catch((error) => {
        this.isLoading = false;
        console.error("Error updating enquiries: ", error);
      });
  };

  //#endregion

  render() {
    const { fields, errors, mandatoryFields } = this.state;
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.handleClose}
          backdrop="static"
          backdropClassName={this.props.show ? "overlay-modal active" : ""}
          dialogClassName={`modal-dialog-centered modal-add-regno`}
        >
          <Modal.Header className="border-bottom" closeButton>
            <Modal.Title>
              <Translate text={"Generic API's"} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="search-pop form-style">
                  <div className="form-row ">
                    <div className="form-group col-md-12">
                      <label>
                        {" "}
                        <Translate text={"Integration Key"} />
                      </label>
                      <InputText
                        name="integrationKey"
                        value={fields.integrationKey ? fields.integrationKey : ""}
                        onChange={this.handleOnCapChange}
                        autoComplete="off"
                        placeholder={"Client Integration Key"}
                        className={`form-control ${errors["integrationKey"]}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button
              type="button"
              className={`btn btn-primary float-right ${this.isLoading ? "form-disable" : ""
                }`}
              onClick={(e) => this.handleSubmit(e)}
            >
              {this.isLoading ? (
                <span
                  className="spinner-border spinner-button mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <></>
              )}
              <Translate text={"done"} />
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
