import React, { useState, useEffect } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import _ from 'lodash'
import Swal from 'sweetalert2'
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { ReactSelect, PopUpModal, ContentOverlay, ReactTimePicker, AntDatePicker } from '../../components';
import ContactInfo from '../contacts/contactInfo'
import CapInfo from '../contacts/list/porsche'
import CommonHelper from '../../services/common';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import { objEnquiry, objNewEnquiryVM, objActivitylog, activityLogOptions } from '../pipeline/viewModel'
import { objContact, objOwnedVehicle } from '../contacts/viewModel'
import { objRequirement } from '../requirement/viewModel';
import { contactResponseCode, enquiryStatus, enqUnActionStatus, stockStatus, testDriveStatus } from '../../services/enum';
import OEMLeadAllocation from './oemLeadAllocation'
//import OEMLeadTransfer from './oemLeadTransfer'
import MentionEditor from '../../components/mentionEditor/mentionEditor';
import AddNotes from '../service/details/addNotes';
import { _returnMandatoryFields } from '../fleet/viewModel';
import { companyBasicVM } from '../company/viewModel'

import MergeList from '../contacts/enquiryList'
import { firestoreDB } from '../../services/helper';
import enquiryHelper from '../../services/enquiryHelper';
import { testDriveMapVM } from '../eventsPro/viewModel';
import AddContact from '../contacts/add';
import Requirements from '../requirement/list';

const InBoundLeadConvert = (props) => {

    const [fields, setFields] = useState({ clientID: '', pipeline: '', stage: '', owner: localStorage.uid, comments: '', activityDate: null, activityType: '' });
    const [groups, setGroups] = useState([]);
    const [enquiry, setEnquiry] = useState({});
    const [contact, setContact] = useState({});
    const [defaulStages, setDefaulStages] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [activeStageIndex, setActiveStageIndex] = useState(0);
    const [errors, setErrors] = useState({});
    const [allUsers, setAllusers] = useState([]);
    const [clientSettings, setClientSettings] = useState({});

    const [showContactModal, setshowContactModal] = useState(false)
    const [showContactInfo, setshowContactInfo] = useState(false)
    const [showOEMLeadConvert, setshowOEMLeadConvert] = useState(false)
    const [showOEMLeadConvertPrev, setshowOEMLeadConvertPrev] = useState(false)

    const [existingContactInfo, setexistingContactInfo] = useState({})

    const [isLoading, setLoading] = useState(false);
    const [isContactMerged, setContactMerged] = useState(false);

    const [titles, setTitles] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    //const [makes, setMakes] = useState([])
    const [logNotes, setNotes] = useState([]);
    const [deleteLoader, setDeleteloader] = useState(false);
    const [teamMentions, setTeamMentions] = useState(null);
    const [mentions, setMentions] = useState([]);

    const [notesHistory, setNotesHistory] = useState([])
    const [showNotes, setShowNotes] = useState(false);
    const [notesHistoryEnquiry, setNotesHistoryEnquiry] = useState([])
    const [notesLoader, setNotesLoader] = useState(true);

    const [activityTypes, setActivityTypes] = useState(activityLogOptions);

    const [showMergeEnqList, setShowMergeEnqList] = useState({ show: false, enquiry: null });

    const [rosterMsg, setRosterMsg] = useState(null)

    const [showCapInfo, setShowCapInfo] = useState({ show: false, contactID: '', contact: null })

    const enableLeadTransfer = ((!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.group) && props.dealersettings.group.enableLeadTransfer) ? true : false);
    const history = useHistory();
    const enableSharedStock = ((props.dealersettings?.group?.enableSharedStock) ? true : false);

    const _dmsSettings = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "cap")[0]);
    const _dmsEnabled = _dmsSettings && _dmsSettings.active && _dmsSettings.enabled && _dmsSettings.settings && _dmsSettings.settings.searchContact ? true : false;

    useEffect(() => {
        if (_.isEmpty(props.docID))
            return;
        const { docID } = props;
        if (docID) {
            loadEnquiry();
        }

        return () => {
            window.unsubscribeInboundEnq && window.unsubscribeInboundEnq();
        };
    }, [props.docID]);

    useEffect(() => {
        if (_.isEmpty(props.docID))
            return;


        const notesHistoryInboundCollection = firestoreDB(props.dealersettings).firestore().collection(`inboundLeads/${props.docID}/notesHistory`)
            .onSnapshot(onCollectionHistoryUpdate);

        return () => {
            notesHistoryInboundCollection && notesHistoryInboundCollection();
        }
    }, [props.docID]);

    const deleteInboundLead = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.deleteInboundLead) ? true : false);

    const mentionsData = props.clientUsers.map(clientUser => {
        return {
            name: `@${clientUser.name}`,
            id: clientUser.id,
            avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
        };
    });
    props.dealersettings.client.teams.forEach(team => {
        mentionsData.push({
            name: `@${team.name}`,
            id: team.user_ids.join(','),
            avatar: _images.group
        })
    })

    const enableCustShowNoShow = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

    const onCollectionHistoryUpdate = querySnapshot => {
        let _logData = [];
        querySnapshot.forEach(_log => {
            let objData = Object.assign({}, _log.data());
            objData.documentID = _log.id;
            _logData.push(objData);
        });

        if (!_.isEmpty(_logData)) {
            setNotesHistory(_.orderBy(_logData, ["modifiedDate"], ["desc"]))
        }
        // else if (activityLog && activityLog.notes && activityLog.notes.trim()) {
        // 	saveLogNote();
        // }

    };

    //#region CHANGE EVENT Notes  
    const handleNotesOpen = () => {
        setShowNotes(true);
    }

    const handleNotesClose = (noteTxt, _mentions) => {

        if (noteTxt) {
            const { docID } = props;

            let convertedIDs = []
            if (!_.isEmpty(_mentions)) {
                let rawIDs = _mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })
            }

            let _objNote =
            {
                documentID: firestoreDB(props.dealersettings).firestore().collection('inboundLeads').doc().id,
                notes: noteTxt,
                mentions: Object.assign([], convertedIDs),
                addedBy: localStorage.uid,
                addedByName: props.dealersettings.name,
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedFrom: 'web',
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
                projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
            }

            const updateRef = firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${docID}/notesHistory/${_objNote.documentID}`);
            updateRef.set(_objNote, { merge: true }).then((docRef) => {

                toast.notify('notes added successfully', {
                    duration: 2000
                })

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }

        setShowNotes(false);
    }
    //#endregion 

    // useEffect(() => {
    //     const { dealersettings } = props;

    //     if (dealersettings && dealersettings.client && dealersettings.client.settings) {

    //         const pipelines = [];
    //         let stages = null;
    //         const setttings = props.dealersettings.client.settings;
    //         let defaultstages = dealersettings.allPipelines.filter(item => item.value !== 'LeadsBucket')[0].stages;
    //         stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

    //         dealersettings.allPipelines.filter(item => item.value !== 'LeadsBucket').forEach((doc) => {
    //             pipelines.push({
    //                 value: doc.value,
    //                 active: _.isBoolean(doc.active) ? doc.active : true,
    //                 label: doc.name
    //             });
    //         });

    //         let _titles = [];
    //         setttings.titles && setttings.titles.forEach((doc) => {
    //             _titles.push({
    //                 value: doc.value,
    //                 label: doc.name
    //             });
    //         });

    //         setTitles(_titles);
    //         setFields({
    //             ...fields,
    //             'pipeline': pipelines.length > 0 ? pipelines[0].value : '',
    //             'stage': stages[0]
    //         });
    //         setDefaulStages(defaultstages)
    //         setPipelines(pipelines)
    //         setActiveStageIndex(stages.indexOf(stages[0]))
    //         setErrors({})
    //     }
    // }, [props])

    // useEffect(() => {
    //     let options = [];
    //     props.clientUsers.map(rec => {
    //         return options.push({
    //             value: rec.id,
    //             active: _.isBoolean(rec.active) ? rec.active : true,
    //             searchlabel: rec.name ? rec.name : '--',
    //             label: <div className="drop-image">
    //                 <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
    //                 {/* <img src={rec.profileImage ? rec.profileImage : _images.nouser} alt="" width="50" className="img-object-fit" /> */}
    //                 {rec.name}
    //             </div>
    //         })
    //     })
    //     setAllusers(options)
    // }, [])

    //#region  EVENT CHANGES
    const handleDealerChange = async (e, data) => {
        const { dealersettings, groupUsers, clientUsers, pipeline } = props;
        const _clientSettings = await window.firebase.firestore().doc(`clients/${e.value}/currentSettings/${e.value}`).get().then(doc => { if (doc.exists) return doc.data(); else return null; });

        setClientSettings(Object.assign({}, _clientSettings));
        let objFilds = {
            ...fields,
            'clientID': e.value
        }

        if (!_.isEmpty(_clientSettings) && !_.isEmpty(_clientSettings.settings)) {

            const setttings = _clientSettings ? _clientSettings.settings : {};
            const pipelines = [];
            let _stages = null;
            let _currentClientID = dealersettings.client.id;
            let _shoWLeadBucket = ((pipeline && pipeline !== 'LeadsBucket' && e.value === _currentClientID) || e.value === _currentClientID) ? false : true;
            let _allpipelines = enquiryHelper.allOpenPipelines((!_.isEmpty(setttings.allPipelines) ? setttings.allPipelines : setttings.pipelines), dealersettings)
            let _pipeline = Object.assign({}, _allpipelines[0]);
            let defaultstages = _pipeline.stages && _pipeline.stages.filter(item => item.value !== 'InboundLead' && item.value !== 'Converted');
            _stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            _allpipelines.forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
            objFilds.pipeline = _pipeline ? _pipeline.value : '';
            objFilds.stage = _stages[0];
            objFilds.pipeline_status = !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null;

            if (fields.owner === localStorage.uid) {
                const _userRoles = (dealersettings && dealersettings.roleID &&
                    dealersettings.client && dealersettings.client.roles &&
                    dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
                const noPipelineAccess = _userRoles && objFilds.pipeline && _userRoles.pipeline && _userRoles.pipeline.some(u => u === objFilds.pipeline) ? false : true;
                if (noPipelineAccess)
                    objFilds.owner = '';
            }

            setDefaulStages(defaultstages)
            setPipelines(pipelines)
            setActiveStageIndex(_stages.indexOf(_stages[0]))

            // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            if (!_.isEmpty(dealersettings.client.roles)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === e.value), (v) => _.includes(roleIds, v.roleID));

                let options = [];
                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                setAllusers(options)
                let _currentUser = fields.owner && options.filter(v => v.value === fields.owner)[0];
                if (!_currentUser) {
                    objFilds.owner = '';
                }
            }
            else {
                setAllusers([])
                objFilds.owner = '';
            }
        }
        else {
            objFilds.pipeline = '';
            objFilds.stage = '';
            objFilds.owner = '';

            setDefaulStages([])
            setPipelines([])
            setActiveStageIndex(0)
            setAllusers([])
        }

        setFields(objFilds);
        checkUserRoster(objFilds.owner, objFilds.activityDate);
    }

    const handlePipelineChange = (e, data) => {
        const { dealersettings, groupUsers, clientUsers } = props;

        let objFilds = {
            ...fields,
            'pipeline': e.value
        }

        if (!_.isEmpty(clientSettings) && !_.isEmpty(clientSettings.settings)) {

            const setttings = Object.assign({}, clientSettings.settings);
            let _stages = null;

            let _pipeline = Object.assign({}, (setttings.allPipelines.filter(v => v.value === e.value)[0]));
            let defaultstages = _pipeline?.stages;
            _stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            objFilds.stage = _stages[0];
            objFilds.pipeline_status = !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null;

            setDefaulStages(defaultstages)
            setActiveStageIndex(_stages.indexOf(_stages[0]))

            // // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            if (!_.isEmpty(dealersettings.client.roles) && !_.isEmpty(clientSettings.users)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = clientSettings.users;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers, (v) => _.includes(roleIds, v.roleID));

                let options = [];
                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

                setAllusers(options)
                let _currentUser = fields.owner ? options.filter(v => v.value === fields.owner)[0] : {};
                if (!_currentUser) {
                    objFilds.owner = '';
                }
            }
            else {
                let options = [];
                clientUsers && clientUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                setAllusers(options)
                let _currentUser = options.filter(v => v.value === fields.owner)[0];
                if (!_currentUser) {
                    objFilds.owner = '';
                }
            }
            setFields(objFilds);
            checkUserRoster(objFilds.owner, objFilds.activityDate);
        }
        else {
            handleDefaultPipelineChange(e, data);
        }
    }

    const handleDefaultPipelineChange = (e, data) => {
        const { dealersettings, groupUsers, clientUsers } = props;
        //const setttings = props.dealersettings;


        let _pipeline = Object.assign({}, (dealersettings.allPipelines.filter(v => v.value === e.value)[0]));
        let defaultstages = _pipeline.stages && _pipeline.stages.filter(item => item.value !== 'InboundLead' && item.value !== 'Converted');
        let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

        let objFilds = {
            ...fields,
            'pipeline': e.value,
            'stage': stages[0],
            'pipeline_status': !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null
        }

        if (fields.owner === localStorage.uid) {
            const { dealersettings } = props;
            const _userRoles = (dealersettings && dealersettings.roleID &&
                dealersettings.client && dealersettings.client.roles &&
                dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
            const noPipelineAccess = _userRoles && e.value && _userRoles.pipeline && _userRoles.pipeline.some(u => u === e.value) ? false : true;
            if (noPipelineAccess)
                objFilds.owner = '';
        }
        //console.log(groupUsers, clientUsers)
        // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
        if (!_.isEmpty(dealersettings.client.roles) && objFilds.clientID) {
            let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                return obj.documentID;
            })];
            let _selectedUsers = [];
            let allDealerUsers = (localStorage.defaultModule !== 'oem' && !_.isEmpty(groupUsers)) ? groupUsers : clientUsers;
            if (!_.isEmpty(roleIds))
                _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === objFilds.clientID), (v) => _.includes(roleIds, v.roleID));

            let options = [];
            _selectedUsers && _selectedUsers.map(rec => {
                return options.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                })
            })
            setAllusers(options)
            let _currentUser = options.filter(v => v.value === fields.owner)[0];
            if (!_currentUser) {
                objFilds.owner = '';
            }
        }
        else {
            let options = [];
            clientUsers && clientUsers.map(rec => {
                return options.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                })
            })
            setAllusers(options)
            let _currentUser = options.filter(v => v.value === fields.owner)[0];
            if (!_currentUser) {
                objFilds.owner = '';
            }
        }

        setFields(objFilds);
        setDefaulStages(defaultstages)
        setActiveStageIndex(stages.indexOf(stages[0]));
        checkUserRoster(objFilds.owner, objFilds.activityDate);

    }

    const handleStageChange = (e) => {
        e.preventDefault();
        const { id, name } = e.target;
        const { dealersettings } = props;
        let statusSettings = fields.pipeline && dealersettings.allPipelines.filter(e => e.value === fields.pipeline)[0]?.statusSettings;
        let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.pipeline === fields.pipeline && e.stage === name)[0];

        if (_statusMndry && _statusMndry.status !== enquiryStatus.OPEN) {
            let __stage = defaulStages.find(x => x.value === name);
            const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
            let _status = CommonHelper.getNameByValue(enquiryAllStatus, _statusMndry.status)
            Swal.fire(`${CommonHelper.showLocale(props, 'Enquiry status will be')} ${_status.toUpperCase()} ${CommonHelper.showLocale(props, 'for selecting stage')}: ${__stage ? __stage.name : ''}`, '', 'info')
            return;
        }

        setFields({ ...fields, ['stage']: name });
        setActiveStageIndex(id)
        setErrors('stage');
    }

    const handleCancel = (e) => {
        e.preventDefault();
        const { dealersettings } = props;
        const userID = dealersettings ? dealersettings.id : '';

        const updateRef = firestoreDB(props.dealersettings).firestore().collection('inboundLeads').doc(fields.documentID);
        updateRef.update({
            status: 2,
            modifiedBy: userID,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedFrom: 'web'
        }).then((docRef) => {


        }).catch((error) => {
            console.error("Error updating enquiries: ", error);
        });

        props.handleModalClose();
    }

    const handleReactSelectChange = (e, data) => {
        if (e) {
            setFields({
                ...fields,
                [data.name]: e.value
            });

            if (data.name === 'owner')
                checkUserRoster(e.value, fields.activityDate);
        }
    }

    // const handleNotesChanges = (e) => {
    //     e.preventDefault();

    //     let { name, value } = e.target;
    //     if (value)
    //         value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

    //     if (value.length > 0) {
    //         setErrors({ ...errors, 'comments': '' });
    //     }
    //     setFields({
    //         ...fields,
    //         [name]: value
    //     });
    // }

    const checkUserRoster = (userID, _date) => {
        if (userID) {
            const { dealersettings, groupUsers, clientUsers } = props;
            CommonHelper.checkUserRoster(dealersettings,
                {
                    owner: userID,
                    startDate: _date ? _date : window.firebase.firestore.Timestamp.now()
                }, (!_.isEmpty(groupUsers) ? groupUsers : clientUsers),
                (_date ? true : false))
                .then(msg => {
                    setRosterMsg(msg);
                })
        }
    }
    //#endregion

    //#region SUBMIT CHANGES
    const getRequirementnotes = (_requirement) => {
        let notes = '';
        if (!_.isEmpty(_requirement)) {
            for (let [key, value] of Object.entries(_requirement)) {
                if (!_.isEmpty(_requirement[key]) && _.isString(value))
                    notes = notes.concat([key] + ': ' + value + '\n')
            }
        }

        return notes;
    }
    const createIMotorLead = async (viewmodel) => {
        const _objEnquiry = Object.assign({}, objNewEnquiryVM);

        try {
            const _objContact = Object.assign({}, objContact)
            const _objRequirement = Object.assign({}, objRequirement)

            if (!_.isEmpty(viewmodel.contact)) {
                _objEnquiry.contact = viewmodel.contact;
                _objEnquiry.isContact = true;
            }
            let _requiremntData = viewmodel.requirement ? viewmodel.requirement : viewmodel.systemType === 'facebook' ? null : viewmodel.request;
            if (!_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.meta_data)) {

                if (viewmodel.request.meta_data.stock_number) {
                    let stock = {};
                    let checkStock = window.firebase.firestore().collection('stock')
                        .where('stockNo', '==', viewmodel.request.meta_data.stock_number)
                        .where('isDeleted', '==', false)

                    if (enableSharedStock && props.dealersettings?.client?.group)
                        checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                    else
                        checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                    if (checkStock.size === 1) {
                        checkStock.forEach(doc => {
                            stock = doc.data();
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.stock = stock;
                        _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

                if (viewmodel.request.meta_data.make && viewmodel.request.meta_data.model && !_objEnquiry.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value && m.value.toLowerCase() === viewmodel.request.meta_data.make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = viewmodel.request.meta_data.model ? viewmodel.request.meta_data.model : '';
                        _objRequirement.saleType = viewmodel.request.meta_data.veh_type && viewmodel.request.meta_data.veh_type.toLowerCase() == "used" ? "Preowned" : "New";
                        _objRequirement.price = viewmodel.request.meta_data.price ? parseInt(viewmodel.request.meta_data.price) : null;
                        _objRequirement.year = viewmodel.request.meta_data.year ? viewmodel.request.meta_data.year : '';
                        _objRequirement.stock = null;
                        if (viewmodel.request.meta_data.stock_number)
                            _objRequirement.stockNo = viewmodel.request.meta_data.stock_number;
                        _objEnquiry.isVehicle = true;

                        let _model = null;
                        //if (_objRequirement.make && (_objRequirement.make.toLowerCase() === 'audi' || _objRequirement.make.toLowerCase() === 'mclaren'))
                        //    _model = _objRequirement.model && _make.models && _make.models.filter(m => m.value && m.value.toLowerCase().includes(_objRequirement.model.toLowerCase()))[0];
                        //else
                        _model = _objRequirement.model && _make.models && _make.models.filter(m => m.value && m.value.toLowerCase() === _objRequirement.model.toLowerCase())[0];

                        if (!_.isEmpty(_model)) {
                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';
                            _objRequirement.group = _model.group ? _model.group : '';
                        }

                        _objEnquiry.requirement = _objRequirement;
                    }
                }
            }
            else if (!_.isEmpty(_requiremntData)) {

                if (_requiremntData.stockNo) {
                    let stock = {};
                    let checkStock = window.firebase.firestore().collection('stock')
                        .where('stockNo', '==', _requiremntData.stockNo)
                        .where('isDeleted', '==', false)

                    if (enableSharedStock && props.dealersettings?.client?.group)
                        checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                    else
                        checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                    if (checkStock.size === 1) {
                        checkStock.forEach(doc => {
                            stock = doc.data();
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.stock = stock;
                        _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

                if (_requiremntData.make && _requiremntData.model && !_objEnquiry.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value && m.value.toLowerCase() === _requiremntData.make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = _requiremntData.model ? _requiremntData.model : '';
                        _objRequirement.type = _requiremntData.type ? _requiremntData.type : '';
                        _objRequirement.group = _requiremntData.group ? _requiremntData.group : '';
                        _objRequirement.saleType = _requiremntData.saleType ? _requiremntData.saleType : '';
                        _objRequirement.price = _requiremntData.price ? parseInt(_requiremntData.price) : null;
                        _objRequirement.extColor = _requiremntData.extColor ? _requiremntData.extColor : null;
                        _objRequirement.year = _requiremntData.year ? _requiremntData.year : null;
                        _objRequirement.intColor = _requiremntData.intColor ? _requiremntData.intColor : null;
                        _objRequirement.stock = null;

                        _objRequirement.stockNo = _requiremntData.stockNo ? _requiremntData.stockNo : '';
                        _objRequirement.regNo = _requiremntData.regNo ? _requiremntData.regNo : '';
                        _objRequirement.vinNo = _requiremntData.vinNo ? _requiremntData.vinNo : '';
                        _objEnquiry.isVehicle = true;

                        let _model = null;
                        //if (_objRequirement.make && (_objRequirement.make.toLowerCase() === 'audi' || _objRequirement.make.toLowerCase() === 'mclaren'))
                        //    _model = _objRequirement.model && _make.models && _make.models.filter(m => m.value && m.value.toLowerCase().includes(_objRequirement.model.toLowerCase()))[0];
                        //else
                        _model = _objRequirement.model && _make.models && _make.models.filter(m => m.value && m.value.toLowerCase() === _objRequirement.model.toLowerCase())[0];

                        if (!_.isEmpty(_model)) {
                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';
                            _objRequirement.group = _model.group ? _model.group : '';
                        }

                        _objEnquiry.requirement = _objRequirement;
                    }
                }
            }


            let notes = [];
            if (!_.isEmpty(viewmodel.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                // newlogVM.enquiryID = this.state.enquiryID;
                // newlogVM.contactID = this.state.contact ? this.state.contact.documentID : null;
                //newlogVM.clientID = fields.clientID ? fields.clientID : (props.dealersettings ? props.dealersettings.client.id : null);
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = viewmodel.notes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            if ((!_objEnquiry.isVehicle || (_objEnquiry.isVehicle && !_.isEmpty(_objEnquiry.requirement) && !_.isEmpty(_objEnquiry.requirement.stockNo)))
                && ((!_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.meta_data)) || !_.isEmpty(_requiremntData))) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                // newlogVM.enquiryID = this.state.enquiryID;
                // newlogVM.contactID = this.state.contact ? this.state.contact.documentID : null;
                //newlogVM.clientID = fields.clientID ? fields.clientID : (props.dealersettings ? props.dealersettings.client.id : null);
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = getRequirementnotes(!_.isEmpty(viewmodel.request.meta_data) ? viewmodel.request.meta_data : _requiremntData);
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            setNotes(notes)
            _objEnquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            return _objEnquiry;
        } catch (error) {

            errorLog(error, 'createIMotorLead');
            return _objEnquiry;
        }

    }

    const createGenericLead = async (viewmodel) => {
        const _objEnquiry = Object.assign({}, objNewEnquiryVM);
        try {
            const _objRequirement = Object.assign({}, objRequirement);

            if (!_.isEmpty(viewmodel.contact)) {
                _objEnquiry.contact = viewmodel.contact;
                _objEnquiry.isContact = true;
            }

            let _requiremntData = viewmodel.requirement ? viewmodel.requirement : (['facebook', 'eventsPro', 'tss'].includes(viewmodel.systemType)) ? null : viewmodel.request;
            if (!_.isEmpty(_requiremntData)) {

                if (_requiremntData.stockNo) {
                    let stock = {};
                    let checkStock = window.firebase.firestore().collection('stock')
                        .where('stockNo', '==', _requiremntData.stockNo)
                        .where('isDeleted', '==', false)

                    if (enableSharedStock && props.dealersettings?.client?.group)
                        checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                    else
                        checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                    if (checkStock.size === 1) {
                        checkStock.forEach(doc => {
                            stock = doc.data();
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.stock = stock;
                        _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

                if (_requiremntData.make && _requiremntData.model && !_objEnquiry.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value && m.value.toLowerCase() === _requiremntData.make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = _requiremntData.model ? _requiremntData.model : '';
                        _objRequirement.type = _requiremntData.type ? _requiremntData.type : '';
                        _objRequirement.group = _requiremntData.group ? _requiremntData.group : '';
                        _objRequirement.saleType = _requiremntData.saleType ? _requiremntData.saleType : '';
                        _objRequirement.price = _requiremntData.price ? parseInt(_requiremntData.price) : null;
                        _objRequirement.intColor = _requiremntData.intColor ? _requiremntData.intColor : null;
                        _objRequirement.extColor = _requiremntData.extColor ? _requiremntData.extColor : null;
                        _objRequirement.year = _requiremntData.year ? _requiremntData.year : null;
                        _objRequirement.stock = null;

                        _objRequirement.stockNo = _requiremntData.stockNo ? _requiremntData.stockNo : '';
                        _objRequirement.regNo = _requiremntData.regNo ? _requiremntData.regNo : '';
                        _objRequirement.vinNo = _requiremntData.vinNo ? _requiremntData.vinNo : '';
                        _objEnquiry.isVehicle = true;

                        let _model = null;
                        // if (_objRequirement.make && (_objRequirement.make.toLowerCase() === 'audi' || _objRequirement.make.toLowerCase() === 'mclaren'))
                        //     _model = _objRequirement.model && _make.models && _make.models.filter(m => m.value && m.value.toLowerCase().includes(_objRequirement.model.toLowerCase()))[0];
                        // else
                        _model = _objRequirement.model && _make.models && _make.models.filter(m => m.value && m.value.toLowerCase() === _objRequirement.model.toLowerCase())[0];

                        if (!_.isEmpty(_model)) {
                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';
                            _objRequirement.group = _model.group ? _model.group : '';
                        }
                        _objEnquiry.requirement = _objRequirement;
                    }
                }
            }

            let notes = [];
            if (!_.isEmpty(viewmodel.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                // newlogVM.enquiryID = this.state.enquiryID;
                // newlogVM.contactID = this.state.contact ? this.state.contact.documentID : null;
                //newlogVM.clientID = props.dealersettings ? props.dealersettings.client.id : null;
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = viewmodel.notes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
                notes.push(newlogVM)
            }

            if ((!_objEnquiry.isVehicle || (_objEnquiry.isVehicle && !_.isEmpty(_objEnquiry.requirement) && !_.isEmpty(_objEnquiry.requirement.stockNo))) && !_.isEmpty(_requiremntData)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                // newlogVM.enquiryID = this.state.enquiryID;
                // newlogVM.contactID = this.state.contact ? this.state.contact.documentID : null;
                //newlogVM.clientID = props.dealersettings ? props.dealersettings.client.id : null;
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = getRequirementnotes(_requiremntData);
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
                notes.push(newlogVM)
            }
            //add test drive notes when lead received is from fusion events
            if (viewmodel.request && _.isArray(viewmodel.request.TestDrives) && viewmodel.request.TestDrives.length > 0) {
                let _tdNotes = 'Test Drives : ' + '\n';
                viewmodel.request.TestDrives.forEach(rec => {
                    if (rec.StartTime) _tdNotes += 'Start Time : ' + moment(rec.StartTime).format('DD/MM/YYYY hh:mm A') + '\n';
                    if (rec.EndTime) _tdNotes += 'End Time : ' + moment(rec.EndTime).format('DD/MM/YYYY hh:mm A') + '\n';
                    if (rec.VehicleModel) _tdNotes += 'Vehicle Model : ' + `${rec.VehicleMake} ${rec.VehicleModel} ${rec.VehicleYear > 0 ? rec.VehicleYear : ''}` + '\n';
                    _tdNotes += '-------' + '\n';
                })

                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.AddedDate ? window.firebase.firestore.Timestamp.fromDate(moment(viewmodel.AddedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.AddedDate ? window.firebase.firestore.Timestamp.fromDate(moment(viewmodel.AddedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.AddedDate ? window.firebase.firestore.Timestamp.fromDate(moment(viewmodel.AddedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = _tdNotes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
                notes.push(newlogVM)
            }

            if (viewmodel.request && _.isArray(viewmodel.request.Notes) && viewmodel.request.Notes.length > 0) {
                viewmodel.request.Notes.forEach(rec => {
                    if (rec.Notes) {
                        let newlogVM = Object.assign({}, objActivitylog);
                        newlogVM.type = 'log';
                        newlogVM.subType = 'note';
                        newlogVM.isDone = true;
                        newlogVM.addedByName = CommonHelper.getUserNamebyId(!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers, rec.addedBy, 'System');
                        newlogVM.addedBy = rec.addedBy ? rec.addedBy : 'System';
                        newlogVM.modifiedBy = rec.modifiedBy ? rec.modifiedBy : 'System';
                        newlogVM.addedDate = rec.addedDate ? rec.addedDate : rec.ModifiedDate ? window.firebase.firestore.Timestamp.fromDate(moment(rec.ModifiedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                        newlogVM.modifiedDate = newlogVM.addedDate;
                        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                        newlogVM.startDate = newlogVM.addedDate;
                        newlogVM.notes = rec.Notes;
                        newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                        //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
                        notes.push(newlogVM)
                    }
                })
            }

            setNotes(notes)
            _objEnquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            return _objEnquiry;
        } catch (error) {
            errorLog(error, 'createGenericLead');
            return _objEnquiry;
        }

    }

    const createCarSalesLead = async (viewmodel) => {
        const _objEnquiry = Object.assign({}, objNewEnquiryVM)
        try {

            const _objContact = Object.assign({}, objContact)
            const _objRequirement = Object.assign({}, objRequirement)

            if (!_.isEmpty(viewmodel.contact)) {
                _objEnquiry.isContact = true;
                _objContact.firstName = viewmodel.contact.firstName ? viewmodel.contact.firstName : '';
                _objContact.middleName = viewmodel.contact.middleName ? viewmodel.contact.middleName : '';
                _objContact.lastName = viewmodel.contact.lastName ? viewmodel.contact.lastName : '';
                _objContact.phoneCode = viewmodel.contact.phoneCode ? viewmodel.contact.phoneCode : '';
                _objContact.phone = viewmodel.contact.phone ? viewmodel.contact.phone : '';
                _objContact.email = viewmodel.contact.email ? viewmodel.contact.email : '';
                _objEnquiry.contact = _objContact;
            }

            if (!_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.Prospect)) {
                _objEnquiry.isContact = true;
                // _objContact.firstName = viewmodel.request.Prospect.FirstName;
                // _objContact.lastName = viewmodel.request.Prospect.LastName ? viewmodel.request.Prospect.LastName : '';
                // _objContact.phone = viewmodel.request.Prospect.MobilePhone ? viewmodel.request.Prospect.MobilePhone : '';
                // _objContact.email = viewmodel.request.Prospect.Email ? viewmodel.request.Prospect.Email : '';
                _objContact.address = viewmodel.request.Prospect.Address ? viewmodel.request.Prospect.Address : '';
                _objContact.state = viewmodel.request.Prospect.State ? viewmodel.request.Prospect.State : '';
                _objContact.areaCode = viewmodel.request.Prospect.Postcode ? viewmodel.request.Prospect.Postcode : '';
                //_objContact.firstName = viewmodel.request.Prospect.CompanyName;
                if (_objContact.state)
                    _objContact.country = CommonHelper.getNameByValue(states, _objContact.state, '');

                if (!_objContact.phone) {
                    _objContact.phone = viewmodel.request.Prospect.WorkPhone ? viewmodel.request.Prospect.WorkPhone : (viewmodel.request.Prospect.HomePhone ? viewmodel.request.Prospect.HomePhone : '')
                }
                _objEnquiry.contact = _objContact;
            }

            if (!_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.Item)) {

                if (viewmodel.request.Item.StockNumber) {
                    let stock = {};
                    let checkStock = window.firebase.firestore().collection('stock')
                        .where('stockNo', '==', viewmodel.request.Item.StockNumber)
                        .where('isDeleted', '==', false)

                    if (enableSharedStock && props.dealersettings?.client?.group)
                        checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                    else
                        checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                    if (checkStock.size === 1) {
                        checkStock.forEach(async doc => {
                            stock = doc.data();
                            let updateDoc = {};
                            if (parseFloat(viewmodel?.request?.Item?.Price)) {
                                updateDoc = {
                                    ...updateDoc,
                                    price: parseFloat(viewmodel.request.Item.Price)
                                }
                            }
                            if (parseInt(viewmodel?.request?.Item?.Odometer)) {
                                updateDoc = {
                                    ...updateDoc,
                                    miles: parseInt(viewmodel.request.Item.Odometer)
                                }
                            }
                            if (updateDoc) {
                                await doc.ref.update(updateDoc)
                            }
                            stock = {
                                ...stock,
                                ...updateDoc
                            }
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.stock = stock;
                        _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

                if (viewmodel.request.Item.Make && viewmodel.request.Item.Model && !_objEnquiry.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value && m.value.toLowerCase() === viewmodel.request.Item.Make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = viewmodel.request.Item.Model ? viewmodel.request.Item.Model : '';
                        _objRequirement.saleType = !_.isEmpty(viewmodel.request.LeadType) && viewmodel.request.LeadType.toLowerCase() == "used" ? "Preowned" : "New";
                        _objRequirement.price = viewmodel.request.Item.Price ? parseInt(viewmodel.request.Item.Price) : null;
                        _objRequirement.stock = null;
                        if (viewmodel.request.Item.StockNumber)
                            _objRequirement.stockNo = viewmodel.request.Item.StockNumber;
                        _objEnquiry.isVehicle = true;

                        let _model = null;
                        // if (_objRequirement.make && (_objRequirement.make.toLowerCase() === 'audi' || _objRequirement.make.toLowerCase() === 'mclaren'))
                        //     _model = _objRequirement.model && _make.models && _make.models.filter(m => m.value && m.value.toLowerCase().includes(_objRequirement.model.toLowerCase()))[0];
                        // else
                        _model = _objRequirement.model && _make.models && _make.models.filter(m => m.value && m.value.toLowerCase() === _objRequirement.model.toLowerCase())[0];

                        if (!_.isEmpty(_model)) {

                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';
                            _objRequirement.group = _model.group ? _model.group : '';

                        }
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

            }
            let notes = [];
            if (!_.isEmpty(viewmodel.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                // newlogVM.enquiryID = this.state.enquiryID;
                // newlogVM.contactID = this.state.contact ? this.state.contact.documentID : null;
                //newlogVM.clientID = props.dealersettings ? props.dealersettings.client.id : null;
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = viewmodel.notes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
                notes.push(newlogVM)
            }

            if ((!_objEnquiry.isVehicle || (_objEnquiry.isVehicle && !_.isEmpty(_objEnquiry.requirement) && !_.isEmpty(_objEnquiry.requirement.stockNo))) && viewmodel.request && viewmodel.request.Item) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                // newlogVM.enquiryID = this.state.enquiryID;
                // newlogVM.contactID = this.state.contact ? this.state.contact.documentID : null;
                //newlogVM.clientID = props.dealersettings ? props.dealersettings.client.id : null;
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = getRequirementnotes(viewmodel.request.Item);
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
                notes.push(newlogVM)
            }

            setNotes(notes)
            _objEnquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            return _objEnquiry;
        } catch (error) {
            errorLog(error, 'createCarSalesLead');
            return _objEnquiry;
        }

    }

    const getEnquiryLead = async (viewmodel) => {

        let _objEnquiry = Object.assign({}, objNewEnquiryVM)
        try {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('enquiries').doc(viewmodel.enquiryID).get();
            if (refData.exists) {
                _objEnquiry = Object.assign({}, refData.data());

                const _enquiry = Object.assign({}, objEnquiry);
                for (let [key, value] of Object.entries(_objEnquiry)) {
                    _enquiry[key] = value;
                }
                _enquiry.documentID = refData.id;

                _enquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();


                return _enquiry;
            }




        } catch (error) {
            errorLog(error, 'getEnquiryLead');
            return _objEnquiry;
        }

    }

    const loadEnquiry = async () => {
        try {
            const { docID, dealersettings, stage, groupUsers, clientUsers } = props;
            if (docID && dealersettings) {
                if (dealersettings.client.settings.pipelines.length === 0) {
                    history.push('/pipeline');
                    return
                }

                const refData = await firestoreDB(props.dealersettings).firestore().collection('inboundLeads').doc(docID).get();

                if (refData.exists) {
                    let _objLead = Object.assign({}, refData.data());
                    _objLead.documentID = refData.id;

                    let response;
                    if (!_.isEmpty(_objLead.enquiryID) && _objLead.status === 0) {
                        response = await getEnquiryLead(_objLead);

                        const _logData = [];

                        const notes1 = await firestoreDB(props.dealersettings).firestore().collection('activities')
                            .where('enquiryID', '==', _objLead.enquiryID)
                            .where('type', '==', 'log')
                            .where('subType', '==', 'note')
                            .where('isDeleted', '==', false)
                            .get();

                        const notes2 = await firestoreDB(props.dealersettings).firestore().collection('oemNotes')
                            .where('enquiryID', '==', _objLead.enquiryID)
                            .where('isDeleted', '==', false)
                            .get();

                        if (notes1.docs.length > 0) {
                            notes1.docs.forEach((_log) => {
                                let objData = Object.assign({}, _log.data());
                                objData.documentID = _log.id;
                                _logData.push(objData);
                            });
                        }

                        if (notes2.docs.length > 0) {
                            notes2.docs.forEach((_log) => {
                                let objData = Object.assign({}, _log.data());
                                objData.documentID = _log.id;
                                _logData.push(objData);
                            });
                        }

                        if (!_.isEmpty(_logData)) {
                            setNotesHistoryEnquiry(_.orderBy(_logData, ["modifiedDate"], ["desc"]))
                        }


                        setNotesLoader(false)

                    }
                    else {
                        setNotesLoader(false)
                        if (_objLead.systemType === 'autogate')
                            response = await createCarSalesLead(_objLead);
                        else if (_objLead.systemType === 'imotor')
                            response = await createIMotorLead(_objLead);
                        else
                            response = await createGenericLead(_objLead);

                    }

                    if (response) {
                        response['documentID'] = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
                        setEnquiry(response);

                        if (response.isContact && !_.isEmpty(response.contact)) {
                            //SaMeeR OEM
                            //if (_.isEmpty(response.contact['documentID']))
                            let _contact = response.contact
                            _contact['documentID'] = firestoreDB(props.dealersettings).firestore().collection('contactDetails').doc().id;
                            _contact['isDeleted'] = false;

                            //save license image
                            if (_objLead.request?.LicenseImageURL && _objLead.request.LicenseImageURL.includes('http'))
                                _contact.licenseFrontURL = _objLead.request.LicenseImageURL;

                            if (_objLead.request?.LicenseImageBackURL && _objLead.request.LicenseImageBackURL.includes('http'))
                                _contact.licenseBackURL = _objLead.request.LicenseImageBackURL;

                            if (_objLead.request?.ImageURL && _objLead.request.ImageURL.includes('http'))
                                _contact.userImageURL = _objLead.request.ImageURL;

                            if (_objLead.request?.LicenseExpiry)
                                _contact.licenseExpiry = moment(_objLead.request.LicenseExpiry).format('YYYY-MM-DD');

                            if (_objLead.request?.LicenseNo)
                                _contact.licenseNo = _objLead.request.LicenseNo;

                            //end
                            response.contact = _contact;
                            //console.log('_contact', _contact)
                            setContact(response.contact);
                        }
                    }

                    // console.log('load-Enquiry', _objLead, response, logNotes)

                    _objLead.tags = [CommonHelper.getNameByValue(props.dealersettings.integrations, _objLead.systemType), _objLead.subType];

                    const pipelines = [];
                    let stages = null;
                    let _pipeline = null;

                    let pipeline = '';
                    if (dealersettings && dealersettings.client && dealersettings.client.settings) {

                        let _groups = [];
                        if (dealersettings.group && dealersettings.group.clients)
                            _groups = Object.keys(dealersettings.group.clients).map(cid => { return { value: cid, label: props.dealersettings.group.clients[cid].name } })

                        const brands = dealersettings.client.brands;

                        const setttings = props.dealersettings.client.settings;
                        let _allpipelines = enquiryHelper.allOpenPipelines(dealersettings.allPipelines, dealersettings)
                        pipeline = _allpipelines.length > 0
                            ? ((stage && stage === 'Converted') ? _allpipelines.filter(m => m.value !== 'LeadsBucket')[0].value : (!_.isEmpty(_allpipelines.filter(m => m.value === 'LeadsBucket')[0]) ? _allpipelines.filter(m => m.value === 'LeadsBucket')[0].value : _allpipelines[0].value))
                            : ''

                        _pipeline = Object.assign({}, (_allpipelines.filter(v => v.value === pipeline)[0]));
                        let defaultstages = _pipeline.stages && _pipeline.stages.filter(item => item.value !== 'InboundLead' && item.value !== 'Converted');

                        stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

                        _allpipelines.filter(m => localStorage.defaultModule === 'oem' ? m.value === 'LeadsBucket' : (stage === 'Converted' ? m.value !== 'LeadsBucket' : true)).forEach((doc) => {
                            pipelines.push({
                                value: doc.value,
                                active: _.isBoolean(doc.active) ? doc.active : true,
                                label: doc.name
                            });
                        });

                        const _makes = [];
                        brands.forEach((doc) => {
                            _makes.push({
                                value: doc.value,
                                label: doc.name,
                                models: doc.models
                            });
                        });

                        let _titles = [];
                        setttings.titles && setttings.titles.forEach((doc) => {
                            _titles.push({
                                value: doc.value,
                                label: doc.name
                            });
                        });

                        const _countries = [];
                        props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
                            _countries.push({
                                value: doc.code,
                                active: _.isBoolean(doc.active) ? doc.active : true,
                                label: doc.name
                            });
                        });

                        const _states = [];
                        props.dealersettings.states && props.dealersettings.states.forEach((doc) => {
                            _states.push({
                                value: doc.code,
                                active: _.isBoolean(doc.active) ? doc.active : true,
                                label: doc.name,
                                name: doc.country_code
                            });
                        });

                        const _activityTypes = [];
                        setttings.activityTypes && setttings.activityTypes.filter(a => a.active === true).forEach(doc => {
                            _activityTypes.push(
                                {
                                    label: (<><i className={`ico icon-${doc.icon} mr-1`}></i>{doc.name}</>),
                                    name: doc.name,
                                    value: doc.value,
                                    active: true,
                                });
                        });

                        if (!_.isEmpty(_activityTypes))
                            setActivityTypes(_activityTypes);

                        setGroups(_groups)
                        //setMakes(_makes)
                        setTitles(_titles);
                        setCountries(_countries);
                        setStates(_states);
                        setDefaulStages(defaultstages)
                        setPipelines(pipelines)
                        setActiveStageIndex(stages.indexOf(stage === 'Converted' ? stages[0] : (stage ? stage : stages[0])))
                        setErrors({})
                    }

                    var _owner = null;
                    if (!_.isEmpty(_objLead) && _objLead.userEmail) {
                        _owner = props.clientUsers && props.clientUsers.filter(m => m.email === _objLead.userEmail)[0] ? props.clientUsers.filter(m => m.email === _objLead.userEmail)[0].id : null;
                    }

                    _objLead = {
                        ..._objLead,
                        'oldStage': _objLead.stage,
                        //'clientID': dealersettings.client.id,
                        'pipeline': pipelines.length > 0 ? (pipeline ? pipeline : pipelines[0].value) : '',
                        'stage': stage === 'Converted' ? stages[0] : (stage ? stage : stages[0]),
                        'pipeline_status': !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null,
                        'owner': _owner ? _owner : localStorage.uid,
                        'activityType': ''
                        //'activityDate': window.firebase.firestore.Timestamp.now()
                    }

                    if (localStorage.defaultModule !== 'oem') {
                        _objLead.clientID = dealersettings.client.id;
                        _objLead.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                    }

                    if (_objLead.owner === localStorage.uid) {
                        const _userRoles = (dealersettings && dealersettings.roleID &&
                            dealersettings.client && dealersettings.client.roles &&
                            dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
                        const noPipelineAccess = _userRoles && _objLead.pipeline && _userRoles.pipeline && _userRoles.pipeline.some(u => u === _objLead.pipeline) ? false : true;
                        if (noPipelineAccess)
                            _objLead.owner = '';
                    }


                    // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
                    let options = [];
                    if (!_.isEmpty(dealersettings.client.roles) && _objLead.clientID) {
                        let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === _objLead.pipeline)), function (obj) {
                            return obj.documentID;
                        })];
                        let _selectedUsers = [];
                        let allDealerUsers = (localStorage.defaultModule !== 'oem' && !_.isEmpty(groupUsers)) ? groupUsers : clientUsers;
                        if (!_.isEmpty(roleIds))
                            _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === _objLead.clientID), (v) => _.includes(roleIds, v.roleID));


                        _selectedUsers && _selectedUsers.map(rec => {
                            return options.push({
                                value: rec.id,
                                active: _.isBoolean(rec.active) ? rec.active : true,
                                searchlabel: rec.name,
                                label: <div className="drop-image">
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            })
                        })
                        setAllusers(options)
                    }
                    else {
                        props.clientUsers.map(rec => {
                            return options.push({
                                value: rec.id,
                                active: _.isBoolean(rec.active) ? rec.active : true,
                                searchlabel: rec.name,
                                label: <div className="drop-image">
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            })
                        })
                        setAllusers(options)
                    }

                    let _currentUser = _objLead.owner ? options.filter(v => v.value === _objLead.owner)[0] : null;
                    if (_.isEmpty(_currentUser)) {
                        _objLead.owner = '';
                    }

                    setFields(_objLead);
                    //   console.log('_objLead', _objLead)

                    // window.unsubscribeInboundEnq = window.firebase.firestore().collection('inboundLeads')
                    //     .doc(docID)
                    //     .onSnapshot(onCollectionUpdate);
                }
            }
        } catch (error) {
            errorLog(error, 'loadEnquiry');
        }
    }

    // const onCollectionUpdate = (docSnapshot) => {

    //     if (!docSnapshot.exists) {
    //         return;
    //     }
    //     const objData = Object.assign({}, docSnapshot.data());
    //     setFields({
    //         ...fields,
    //         ['status']: objData.status
    //     });

    // }

    const handleDateChange = (val, name) => {
        if (val) {
            val = moment(moment(val).format('YYYY-MM-DD') + ' ' + (fields.activityDate ? moment.unix(fields.activityDate.seconds).format('HH:mm') : moment().format('HH:mm')))._d;

            setFields({
                ...fields,
                [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
            });

            checkUserRoster(fields.owner, window.firebase.firestore.Timestamp.fromDate(moment(val)._d));
        }
    };
    const handleStartTimeChange = (val, name) => {
        //console.log(val)
        setFields({
            ...fields,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });

        checkUserRoster(fields.owner, window.firebase.firestore.Timestamp.fromDate(moment(val)._d));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { docID } = props;
        let errors = {};
        let formIsValid = true;
        let objfields = Object.assign({}, fields);

        let errorClass = 'input_error';

        for (let [key] of Object.entries(objfields)) {
            if ((!fields[key] && key === 'owner') || (key === 'activityType' && !fields[key] && fields['activityDate'])) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }
        //console.log('handleSubmit', errors, objfields)
        setErrors(errors);

        if (formIsValid === true) {
            const inboundResult = await firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${docID}`).get()
            const inboundData = inboundResult.data();
            //    console.log('handleSubmit-check already exist', inboundData)
            if (!_.isEmpty(inboundData) && !_.isEmpty(inboundData.enquiryID) && inboundData.status !== 0) {
                formIsValid = false;
                Swal.fire({
                    title: CommonHelper.showLocale(props, `Lead already allocated`),
                    showCancelButton: true,
                    confirmButtonClass: 'viewButton',
                    confirmButtonColor: '#459E20',
                    cancelButtonColor: '#17232D',
                    confirmButtonText: CommonHelper.showLocale(props, 'View Enquiry'),
                    cancelButtonText: CommonHelper.showLocale(props, 'Okay'),
                    reverseButtons: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then((result) => {
                    if (result.value) {
                        props.handleModalClose();
                        if (props.setNotifyClose)
                            props.setNotifyClose(true);

                        history.push("/enquiry/details/" + inboundData.enquiryID);
                    }
                });
                return
            }
        }

        if (formIsValid === true) {
            if (!_.isEmpty(enquiry.contact)) {
                handleValidContact();
            }
            else {
                handleSaveEnquiry();
            }

        }
        else {
            setLoading(false);
            return formIsValid;
        }


    };

    const handleSaveEnquiry = async (__contact) => {

        try {
            setLoading(true);
            const { dealersettings, stage } = props;
            if (dealersettings &&
                dealersettings.client &&
                dealersettings.client.settings) {

                const setttings = dealersettings.client.settings;
                let state = Object.assign({}, enquiry);
                let _contact = Object.assign({}, (!_.isEmpty(__contact) ? __contact : contact));
                //if (_.isEmpty(state.documentID) && _.isEmpty(state.addedBy)) {
                state.addedBy = dealersettings ? dealersettings.id : '';
                if (fields.enquiryID)
                    state.addedDate = window.firebase.firestore.Timestamp.now()
                else
                    state.addedDate = fields.leadCreated ? fields.leadCreated : (fields.addedDate ? fields.addedDate : window.firebase.firestore.Timestamp.now());

                state.addedFrom = 'web';
                //}
                state.modifiedBy = dealersettings ? dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();
                state.createdDate = window.firebase.firestore.Timestamp.now();
                state.modifiedFrom = 'web';
                state.isNewEnquiry = true;
                state.isDeleted = false;

                const inboundAllocation = (localStorage.defaultModule === 'oem') ? true : false;

                state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings, null, fields.clientID, inboundAllocation);

                if (_.isEmpty(state.clientID) && localStorage.defaultModule !== 'oem') {
                    state.clientID = fields.clientID ? fields.clientID : (dealersettings ? dealersettings.client.id : '');
                    state.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                }

                // state.clientID = fields.clientID ? fields.clientID : (dealersettings ? dealersettings.client.id : '');
                // state.groupID = (dealersettings && dealersettings.client.group ? dealersettings.client.group : null);
                // state.regionID = (dealersettings && dealersettings.client.region ? dealersettings.client.region : null);
                // state.oemID = (dealersettings ? dealersettings.client.settingsID : null);

                if (state.isContact && !_.isEmpty(_contact)) {
                    let objContactData = Object.assign({}, _contact);
                    //Remove empty value from object
                    for (var propName in objContactData) {
                        if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                            delete objContactData[propName];
                        }
                    }
                    objContactData = bindCompany(objContactData);
                    state.contact = objContactData;
                    state.contactID = objContactData.documentID;
                    state.isNewEnquiry = false;
                }

                if (fields.pipeline) state.pipeline = fields.pipeline;

                if (fields.stage) state.stage = fields.stage;

                if (__contact?.owner && __contact?.capOwner) state.owner = __contact.owner;
                else if (fields.owner) state.owner = fields.owner;

                if (fields.campaign) state.campaign = fields.campaign;
                if (fields.origin) state.origin = fields.origin;
                if (fields.enquiryType) state.enquiryType = fields.enquiryType;
                if (fields.leadSource) state.leadSource = fields.leadSource;
                if (fields.oemStatus) state.oemStatus = fields.oemStatus;
                if (fields.oemConvertedBy) state.oemConvertedBy = fields.oemConvertedBy;
                if (fields.oemConvertedDate) state.oemConvertedDate = fields.oemConvertedDate;
                if (fields.eventsProAttendeeID) state.eventsProAttendeeID = fields.eventsProAttendeeID;
                if (fields.purchaseIntention) state.purchaseIntention = fields.purchaseIntention;
                if (fields.depositAmount) state.depositAmount = fields.depositAmount;
                if (fields.depositReciptNumber) state.depositReciptNumber = fields.depositReciptNumber;
                //add in inbound list and dashbord enquiry list
                if (!_.isEmpty(fields.dynamicFields)) state.dynamicFields = fields.dynamicFields;

                if (state.oemStatus === 'converted') {
                    if (fields.oemID) state.oemID = fields.oemID;
                    if (!state.oemConvertedBy) state.oemConvertedBy = fields.modifiedBy;
                    if (!state.oemConvertedDate) state.oemConvertedDate = fields.modifiedDate;
                }

                if (fields.pipeline !== 'LeadsBucket')
                    state.status = fields.pipeline_status ? fields.pipeline_status : 'open';
                else {
                    state.status = null;
                    state.ownerInbound = state.owner;
                }
                state.inboundLeadID = fields?.documentID || null;
                if (fields.leadStatus) state.status = fields.leadStatus;
                if (!_.isEmpty(fields?.request?.TestDrives)) state.isTestDrive = true;
                if (!_.isEmpty(fields?.request?.testDrives)) state.isTestDrive = true;

                if (localStorage.defaultModule === 'oem') {
                    state.oemStatus = 'pending'
                    state.status = 'pending';
                }
                if (state.status)
                    state.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: state.status, user: localStorage.uid }]);

                state.stageDate = window.firebase.firestore.Timestamp.now();
                state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
                state.isDeleted = false;

                if (!fields.enquiryID) {
                    state.tags = window.firebase.firestore.FieldValue.arrayUnion(
                        {
                            type: (fields.systemType ? fields.systemType : ''),
                            refID: (fields.refID ? fields.refID : ''),
                            subType: (fields.subType ? fields.subType : '')
                        }
                    )
                    // state.tags = [
                    //     {
                    //         type: (fields.systemType ? fields.systemType : ''),
                    //         refID: (fields.refID ? fields.refID : ''),
                    //         subType: (fields.subType ? fields.subType : '')
                    //     }
                    // ]
                }

                if ((stage && stage === 'Converted') || (state.pipeline !== 'LeadsBucket')) {
                    state.isConverted = true;
                    state.unactionedNotify = enqUnActionStatus.PENDING;
                    state.convertedBy = dealersettings ? dealersettings.id : localStorage.uid;
                    state.convertedDate = window.firebase.firestore.Timestamp.now();

                    if (enableCustShowNoShow && fields.activityType === 'appointment' && !_.isEmpty(fields.activityDate)) {
                        state.bdcStatus = 'allocated';
                        state.appointmentDate = fields.activityDate;
                    }
                }

                const objData = Object.assign({}, state);
                //Remove empty value from object
                for (var propName in objData) {
                    if (objData[propName] === null || objData[propName] === undefined || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                        delete objData[propName];
                    }
                }
                //    console.log('handleSaveEnquiry', objData)

                //salestype access
                if (_.isEmpty(objData.requirement)) {
                    const ownerRoleID = props.clientUsers ? props.clientUsers.find(rec => rec.id === objData.owner) : null;
                    if (ownerRoleID) {
                        const ownerRole = props.dealersettings.client.roles ? props.dealersettings.client.roles.find(rec => rec.documentID === ownerRoleID.roleID) : null;
                        if (ownerRole && _.isArray(ownerRole.salesType) && ownerRole.salesType.length > 0) {
                            objData.requirement = {
                                saleType: ownerRole.salesType[0]
                            }
                        }
                    }
                }
                //end

                if (!objData.documentID)
                    objData.documentID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;

                const updateRef = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc(objData.documentID);
                updateRef.set(objData, { merge: true }).then(async (docRef) => {
                    let documentID = objData.documentID;

                    toast.notify('Allocated sucessfully', { duration: 2000 });
                    //(_.isEmpty(state.documentID) && commented for dms changes, 
                    if (objData.isVehicle && !_.isEmpty(documentID) && !_.isEmpty(objData.requirement))
                        saveReqInfo(documentID, objData.requirement, objData.clientID)

                    if (!_.isEmpty(documentID) && !_.isEmpty(fields.documentID))
                        await updateInBound(documentID, fields.documentID, objData);

                    if (!_.isEmpty(objData) && !_.isEmpty(objData.contact) && objData.contact.isCompany &&
                        !_.isEmpty(objData.contact.company) && _.isObject(objData.contact.company))
                        updateCompany(objData.contact.company, objData.contactID);

                    if (objData.isConverted) {
                        CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, 'Lead converted', 'enquiry', 'updated');
                    }

                    if (!_.isEmpty(fields.comments)) {
                        let newlogVM = Object.assign({}, objActivitylog);
                        newlogVM.type = 'log';
                        newlogVM.subType = 'note';
                        newlogVM.isDone = true;
                        newlogVM.addedBy = localStorage.uid;
                        newlogVM.modifiedBy = localStorage.uid;
                        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
                        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                        newlogVM.enquiryID = documentID ? documentID : null;
                        newlogVM.contactID = (_contact && _contact.documentID) ? _contact.documentID : null;
                        newlogVM['enquiry'] = CommonHelper.getMinifiedData(objData, 'enquiry');
                        newlogVM['contact'] = CommonHelper.getMinifiedData(objData?.contact, 'contact');
                        //newlogVM.clientID = fields.clientID ? fields.clientID : (props.dealersettings ? props.dealersettings.client.id : null);
                        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                        newlogVM.startDate = window.firebase.firestore.Timestamp.now();
                        newlogVM.notes = fields.comments;
                        Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                        newlogVM.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;


                        if (!_.isEmpty(mentions)) {
                            if (mentions.length > 0) {
                                let convertedIDs = []
                                let rawIDs = mentions.map(mentionObject => mentionObject.id)
                                rawIDs.forEach(id => {
                                    if (id.includes(',')) {
                                        let allIDs = id.split(',')
                                        allIDs.forEach(a => {
                                            convertedIDs.push(a)
                                        })
                                    } else {
                                        convertedIDs.push(id)
                                    }
                                })

                                if (newlogVM.mentions && newlogVM.mentions.length > 0) {
                                    newlogVM.mentions = _.uniq([...newlogVM.mentions, ...convertedIDs])
                                } else {
                                    newlogVM.mentions = _.uniq(convertedIDs)
                                }
                            }
                        }

                        newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, objData.clientID);
                        //newlogVM.tags = [viewmodel.systemType];
                        firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
                            .set(newlogVM, { merge: true })
                            .catch(error => {
                                console.error(error);
                            });
                    }

                    if (objData.contactID) {
                        const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
                        updateRepeatCustomer({ 'contactID': objData.contactID, 'projectId': objData?.projectId ? objData.projectId : null })
                    }

                    if (!_.isEmpty(fields.activityDate))
                        createAppoitment({ ...fields, enquiryID: documentID, contactID: (_contact?.documentID || null) }, objData)

                    if (!_.isEmpty(fields.appointmentOn))
                        createAppoitment({ ...fields, activityType: null, enquiryID: documentID, contactID: (_contact?.documentID || null) }, objData, fields.appointmentOn)

                    //props.handleModalClose();
                    if (props.setNotifyClose)
                        props.setNotifyClose(true);

                    if (fields.systemType === 'fusionevents' && fields.request) {
                        createFusionEvents(objData)
                    }

                    if (!_.isEmpty(fields?.request?.TestDrives))
                        createTestdrives(objData, fields.request.TestDrives);

                    //add test drive when lead received is from fusion events pro
                    if (!_.isEmpty(fields?.request?.testDrives))
                        enquiryHelper.createTestdrivesFromEventsPro(objData, fields.request.testDrives, props.dealersettings);

                    if (!_.isEmpty(fields?.request?.currentlyOwned))
                        createOwnedVehicles(objData.contactID, fields.request.currentlyOwned, true);

                    if (!_.isEmpty(fields?.request?.previouslyOwned))
                        createOwnedVehicles(objData.contactID, fields.request.previouslyOwned, false);

                    if (!_.isEmpty(_contact?.Vehicles))
                        createOwnedVehicles(objData.contactID, _contact?.Vehicles, true);

                    // UPDATE EVENT ATTENDEE - ALLOCATION STATUS
                    enquiryHelper.updateEventsAttendee(fields.eventsProAttendeeID, documentID, dealersettings);

                    //TSS create call activity
                    if (fields?.activity) {
                        let newlogVM = Object.assign({}, fields.activity);
                        newlogVM.enquiryID = documentID ? documentID : null;
                        newlogVM.callDuration = fields?.activity?.callDuration || 0;
                        newlogVM.contactID = (_contact && _contact.documentID) ? _contact.documentID : null;
                        newlogVM['enquiry'] = CommonHelper.getMinifiedData(objData, 'enquiry');
                        newlogVM['contact'] = CommonHelper.getMinifiedData(objData?.contact, 'contact');
                        newlogVM.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
                        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;

                        firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
                            .set(newlogVM, { merge: true })
                            .catch(error => {
                                console.error(error);
                            });
                    }

                    updateConvertedData(documentID, objData);
                    setLoading(false);
                    handleOpenMerge(objData);
                }).catch((error) => {
                    setLoading(false);
                    console.error("Error updating contact_details: ", error);
                });
            }
        } catch (error) {
            errorLog(error, 'handleSaveEnquiry');
        }
    }
    //#region CONTACT OWNED VEHICLE
    const createOwnedVehicles = (_contactID, _vehicles, _currentlyOwned) => {
        if (_.isEmpty(_contactID)) return;
        _vehicles && _vehicles.forEach(async (_tdData) => {
            let objData = Object.assign({});
            if (_tdData.vin) objData.vinNo = _tdData.vin;
            for (let [key, value] of Object.entries(_tdData)) {
                if (objOwnedVehicle.hasOwnProperty(key) && value !== undefined) objData[key] = value;
            }
            objData.addedBy = localStorage.uid;
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
            objData.modifiedBy = localStorage.uid;
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';
            objData.isDeleted = false;
            objData.currentlyOwned = _currentlyOwned;
            objData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
            objData.documentID = window.firebase.firestore().collection('contactDetails').doc().id;
            Object.keys(objData).forEach(key => objData[key] === undefined ? delete objData[key] : {});
            if (_tdData.capID) objData.documentID = _tdData.capID;
            if (_tdData.hasOwnProperty('owned')) {
                objData.owned = (_tdData.owned || false);
                objData.currentlyOwned = objData.owned;
            }
            firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${_contactID}/ownedVehicles/${objData.documentID}`).set(objData, { merge: true })
        });
    }
    //#endregion
    //#region TEST DRIVES  
    // const createTestdrivesFromEventsPro = async (_enquiry, _testDrives) => {
    //     let _stockList = [];
    //     const { dealersettings } = props;

    //     _testDrives && _testDrives.forEach(async (_tdData) => {
    //         if ((_tdData.make && _tdData.model) || _tdData.stock) {
    //             let stock = {};
    //             let checkStock = window.firebase.firestore().collection('stock')
    //                 .where('make', '==', _tdData?.stock?.make || _tdData.make)
    //                 .where('model', '==', _tdData?.stock?.model || _tdData.model)
    //                 .where('status', '==', stockStatus.UNAVAILABLE)
    //                 .where('isDeleted', '==', false)

    //             if (enableSharedStock && props.dealersettings?.client?.group)
    //                 checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
    //             else
    //                 checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

    //             if (checkStock.size === 1) {
    //                 checkStock.forEach(doc => {
    //                     stock = doc.data();
    //                 });
    //             }

    //             let objData = Object.assign({});

    //             for (let [key, value] of Object.entries(_tdData)) {
    //                 if (testDriveMapVM.hasOwnProperty(key) && value !== undefined) objData[key] = value;
    //             }
    //             objData.addedBy = _tdData.addedBy ? _tdData.addedBy : 'System';
    //             objData.addedDate = objData.startDate ? objData.startDate : window.firebase.firestore.Timestamp.now();
    //             objData.addedFrom = 'web';
    //             objData.modifiedBy = _tdData.addedBy ? _tdData.addedBy : 'System';
    //             objData.modifiedDate = objData.startDate ? objData.startDate : window.firebase.firestore.Timestamp.now();
    //             objData.modifiedFrom = 'web';
    //             objData.isDeleted = false;
    //             objData.status = testDriveStatus.COMPLETE;
    //             objData.documentID = window.firebase.firestore().collection('testdrives').doc().id;
    //             objData.clientID = _enquiry?.clientID ? _enquiry.clientID : (dealersettings ? dealersettings.client.id : '');
    //             objData.projectId = _enquiry?.projectId ? _enquiry.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
    //             if (!_.isEmpty(_enquiry)) {
    //                 objData.enquiry = _enquiry;
    //                 objData.enquiryID = _enquiry.documentID;
    //                 objData.contact = _enquiry?.contact;
    //                 objData.contactID = _enquiry?.contactID;
    //             }

    //             if (!_.isEmpty(stock)) {
    //                 objData.stock = stock;
    //                 objData.stockID = stock?.documentID;
    //                 objData.tradePlate = (stock && stock.tradePlate) ? stock.tradePlate : '';
    //                 objData.startOdometer = (stock && Number(stock.miles)) ? Number(stock.miles) : 0;
    //             }
    //             else {
    //                 let _selectedStock = _stockList && _stockList.filter(m =>
    //                     m.make.toLowerCase() === _tdData.make.toLowerCase() &&
    //                     m.model.toLowerCase() === _tdData.model.toLowerCase()
    //                 )[0];

    //                 if (!_.isEmpty(_selectedStock)) {
    //                     objData.stock = _selectedStock;
    //                     objData.stockID = _selectedStock?.documentID;
    //                 }
    //                 else {
    //                     let _objStock = Object.assign({});
    //                     _objStock.addedBy = localStorage.uid;
    //                     _objStock.addedDate = window.firebase.firestore.Timestamp.now();
    //                     _objStock.addedFrom = 'web';
    //                     _objStock.modifiedBy = localStorage.uid;
    //                     _objStock.modifiedDate = window.firebase.firestore.Timestamp.now();
    //                     _objStock.modifiedFrom = 'web';
    //                     _objStock.isDeleted = false;
    //                     _objStock.status = stockStatus.UNAVAILABLE;
    //                     _objStock.documentID = window.firebase.firestore().collection('stock').doc().id;
    //                     _objStock.clientID = objData.clientID;
    //                     _objStock.groupID = dealersettings.client.group ? dealersettings.client.group : '';
    //                     _objStock.make = _tdData?.stock?.make || _tdData.make || null;
    //                     _objStock.model = _tdData?.stock?.model || _tdData.model || null;
    //                     _objStock.extColor = _tdData?.stock?.extColor || _tdData.extColor || null;
    //                     _objStock.regNo = _tdData?.stock?.regNo || _tdData.regNo || null;
    //                     _objStock.vinNo = _tdData?.stock?.vinNo || _tdData.vinNo || null;
    //                     _objStock.year = _tdData?.stock?.year || _tdData.year || null;
    //                     _objStock.type = _tdData.VehicleModelType ? _tdData.VehicleModelType : '';
    //                     _objStock.isTestDrive = true;
    //                     let _ID = _objStock.documentID;
    //                     _objStock.stockNo = `STK-${_ID.substring(_ID.length - 5, _ID.length)}${moment().format('-hmmssSS')}`;

    //                     objData.stock = _objStock;
    //                     objData.stockID = _objStock?.documentID;
    //                     _stockList.push(_objStock);
    //                     window.firebase.firestore().doc(`stock/${_objStock.documentID}`).set(_objStock, { merge: true })
    //                 }
    //             }
    //             firestoreDB(props.dealersettings).firestore().doc(`testdrives/${objData.documentID}`).set(objData, { merge: true })
    //         }
    //     });
    // }
    const createTestdrives = async (_enquiry, _testDrives) => {
        let _stockList = [];
        const { dealersettings } = props;

        _testDrives && _testDrives.forEach(async (_tdData) => {
            if (_tdData.VehicleMake && _tdData.VehicleModel) {
                let stock = {};
                let checkStock = window.firebase.firestore().collection('stock')
                    .where('make', '==', _tdData.VehicleMake)
                    .where('model', '==', _tdData.VehicleModel)
                    .where('status', '==', stockStatus.UNAVAILABLE)
                    .where('isDeleted', '==', false)

                if (enableSharedStock && props.dealersettings?.client?.group)
                    checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                else
                    checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                if (checkStock.size === 1) {
                    checkStock.forEach(doc => {
                        stock = doc.data();
                    });
                }

                let objData = Object.assign({});
                objData.addedBy = localStorage.uid;
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
                objData.modifiedBy = localStorage.uid;
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = 'web';
                objData.isDeleted = false;
                objData.status = testDriveStatus.COMPLETE;
                objData.documentID = window.firebase.firestore().collection('testdrives').doc().id;
                objData.clientID = _enquiry?.clientID ? _enquiry.clientID : (dealersettings ? dealersettings.client.id : '');
                objData.projectId = _enquiry?.projectId ? _enquiry.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
                if (!_.isEmpty(_enquiry)) {
                    objData.enquiry = _enquiry;
                    objData.enquiryID = _enquiry.documentID;
                    objData.contact = _enquiry?.contact;
                    objData.contactID = _enquiry?.contactID;
                }

                if (fields?.request?.EventDate) {
                    objData.startDate = window.firebase.firestore.Timestamp.fromDate(moment(fields.request.EventDate)._d);
                    objData.endDate = objData.startDate;
                }

                if (_tdData.StartTime)
                    objData.startDate = window.firebase.firestore.Timestamp.fromDate(moment(_tdData.StartTime)._d);

                if (_tdData.EndTime)
                    objData.endDate = window.firebase.firestore.Timestamp.fromDate(moment(_tdData.EndTime)._d);

                if (_tdData.IsTerms) {
                    objData.terms = {
                        verbalConsent: _tdData.SignatureURL ? false : true,
                        signature: _tdData.SignatureURL ? _tdData.SignatureURL : '',
                        termsAndConditions: _tdData.Terms ? _tdData.Terms : ''
                    }
                }
                if (_tdData.SignatureURL)
                    objData.afterSignatureURL = _tdData.SignatureURL;

                if (!_.isEmpty(stock)) {
                    objData.stock = stock;
                    objData.stockID = stock?.documentID;
                    objData.tradePlate = (stock && stock.tradePlate) ? stock.tradePlate : '';
                    objData.startOdometer = (stock && Number(stock.miles)) ? Number(stock.miles) : 0;
                }
                else {
                    let _selectedStock = _stockList && _stockList.filter(m =>
                        m.make.toLowerCase() === _tdData.VehicleMake.toLowerCase() &&
                        m.model.toLowerCase() === _tdData.VehicleModel.toLowerCase()
                    )[0];

                    if (!_.isEmpty(_selectedStock)) {
                        objData.stock = _selectedStock;
                        objData.stockID = _selectedStock?.documentID;
                    }
                    else {
                        let _objStock = Object.assign({});
                        _objStock.addedBy = localStorage.uid;
                        _objStock.addedDate = window.firebase.firestore.Timestamp.now();
                        _objStock.addedFrom = 'web';
                        _objStock.modifiedBy = localStorage.uid;
                        _objStock.modifiedDate = window.firebase.firestore.Timestamp.now();
                        _objStock.modifiedFrom = 'web';
                        _objStock.isDeleted = false;
                        _objStock.status = stockStatus.UNAVAILABLE;
                        _objStock.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objStock.clientID = objData.clientID;
                        objData.groupID = dealersettings.client.group ? dealersettings.client.group : '';
                        _objStock.make = _tdData.VehicleMake;
                        _objStock.model = _tdData.VehicleModel;
                        _objStock.type = _tdData.VehicleModelType ? _tdData.VehicleModelType : '';
                        _objStock.year = _tdData.VehicleYear ? _tdData.VehicleYear.toString() : '';
                        _objStock.isTestDrive = true;
                        _objStock.stockNo = `STK-${_tdData?.CustomerId ? _tdData?.CustomerId + (_tdData?.Id ? '-' + _tdData?.Id : moment().format('-hmmssSS')) : moment().format('YY-MM-DD-hmmssSS')}`;

                        objData.stock = _objStock;
                        objData.stockID = _objStock?.documentID;
                        _stockList.push(_objStock);
                        window.firebase.firestore().doc(`stock/${_objStock.documentID}`).set(_objStock, { merge: true })
                    }
                }
                firestoreDB(props.dealersettings).firestore().doc(`testdrives/${objData.documentID}`).set(objData, { merge: true })
            }
        });
    }
    //#endregion

    //#region MERGE ENQUIRY
    const handleOpenMerge = (_enquiry) => {
        if (_enquiry.contactID)
            setShowMergeEnqList({ show: true, enquiry: _enquiry });
        else
            props.handleModalClose(_enquiry, 'accept', fields.documentID);
    }

    const handleCloseMerge = (_enquiryID) => {
        if (_enquiryID && _enquiryID !== 'new') {
            setShowMergeEnqList({ show: false, enquiry: null });
            history.push("/enquiry/details/" + _enquiryID);
            props.handleModalClose();
        }
        else {
            props.handleModalClose(showMergeEnqList.enquiry, 'accept', fields.documentID);
            setShowMergeEnqList({ show: false, enquiry: null });
        }

    }
    //#endregion

    const updateConvertedData = async (enquiryID, __enquiryData, _tags) => {
        try {

            const batch = firestoreDB(props.dealersettings).firestore().batch();

            if (!_.isEmpty(_tags)) {
                batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${enquiryID}`), { tags: _tags }, { merge: true });
            }

            logNotes && logNotes.forEach(newlogVM => {
                newlogVM.enquiryID = enquiryID;
                newlogVM['enquiry'] = CommonHelper.getMinifiedData(__enquiryData, 'enquiry');
                newlogVM['contact'] = CommonHelper.getMinifiedData(__enquiryData?.contact, 'contact');
                newlogVM.contactID = contact ? contact.documentID : null;
                newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(__enquiryData)
                    ? __enquiryData.clientID
                    : null));
                Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                newlogVM.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

                batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            })

            notesHistory && notesHistory.forEach(newlogVM => {
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.isDeleted = false;
                newlogVM.startDate = newlogVM.addedDate ? newlogVM.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(__enquiryData)
                    ? __enquiryData.clientID
                    : null));
                newlogVM.enquiryID = enquiryID;
                newlogVM['enquiry'] = CommonHelper.getMinifiedData(__enquiryData, 'enquiry');
                newlogVM['contact'] = CommonHelper.getMinifiedData(__enquiryData?.contact, 'contact');

                newlogVM.contactID = contact ? contact.documentID : null;
                Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                newlogVM.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
                batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            })

            try {
                if (localStorage.defaultModule !== 'oem' && fields.enquiryID && !_.isEmpty(__enquiryData) && __enquiryData.clientID) {

                    const refwalkins = await firestoreDB(props.dealersettings).firestore().collection(`enquiries/${fields.enquiryID}/walkins`).get();
                    refwalkins.docs && refwalkins.docs.forEach((newlogVM) => {

                        if (__enquiryData.clientID) newlogVM.clientID = __enquiryData.clientID;
                        if (__enquiryData.projectId) newlogVM.projectId = __enquiryData.projectId;
                        newlogVM.isDeleted = false;
                        if (newlogVM.documentID)
                            batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${fields.enquiryID}/walkins/${newlogVM.documentID}`), newlogVM, { merge: true });

                    });

                    // UPDATE WITH NEW CLIENT ID
                    let _objDAta =
                    {
                        clientID: __enquiryData.clientID,
                        contact: __enquiryData && __enquiryData.contact ? Object.assign({}, __enquiryData.contact) : null,
                        contactID: contact ? contact.documentID : null
                    }
                    await firestoreDB(props.dealersettings).firestore().collection('testdrives')
                        .where('enquiryID', '==', fields.enquiryID)
                        .where('isDeleted', '==', false)
                        .get().then(response => {
                            response.docs.forEach((doc) => { batch.update(doc.ref, _objDAta); });
                        });

                    await firestoreDB(props.dealersettings).firestore().collection('tradeins')
                        .where('enquiryID', '==', fields.enquiryID)
                        .where('isDeleted', '==', false)
                        .get().then(response => {
                            response.docs.forEach((doc) => { batch.update(doc.ref, _objDAta); });
                        });

                    await firestoreDB(props.dealersettings).firestore().collection('activities')
                        .where('enquiryID', '==', fields.enquiryID)
                        .where('isDeleted', '==', false)
                        .get().then(response => {
                            response.docs.forEach((doc) => { batch.update(doc.ref, _objDAta); });
                        });

                }

            } catch (error) {
                errorLog(error, 'updateConvertedData');
            }


            batch.commit().catch((error) => {
                console.error("Error updating document: ", error);
            });
        } catch (error) {
            errorLog(error, 'updateConvertedData');
        }

    }

    const createAppoitment = (objFields, __enquiryData, appointmentOn) => {
        try {
            const { dealersettings, clientUsers, stage } = props;
            let newlogVM = Object.assign({}, objActivitylog);
            newlogVM.type = 'activity';
            newlogVM.subType = objFields.activityType ? objFields.activityType : 'appointment';
            newlogVM.title = objFields.activityType ? CommonHelper.getNameByValue(activityTypes, objFields.activityType) : 'Appointment';
            newlogVM.owner = (__enquiryData?.owner || objFields.owner);
            newlogVM.addedBy = localStorage.uid;
            newlogVM.modifiedBy = localStorage.uid;
            newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
            newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newlogVM.modifiedFrom = 'web';
            newlogVM.enquiryID = objFields.enquiryID;
            newlogVM.contactID = objFields.contactID;
            newlogVM.enquiry = Object.assign({}, __enquiryData);
            newlogVM.contact = __enquiryData && __enquiryData.contact ? Object.assign({}, __enquiryData.contact) : null;
            //newlogVM.clientID = objFields.clientID ? objFields.clientID : dealersettings.client.id;
            newlogVM.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
            newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;

            if (appointmentOn) {
                newlogVM.startDate = _.isString(appointmentOn) ? window.firebase.firestore.Timestamp.fromDate(new Date(appointmentOn)) : appointmentOn;
            }
            else
                newlogVM.startDate = objFields.activityDate;

            newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(newlogVM.startDate.seconds).add('hour', 1).toDate());
            newlogVM.addedByName = CommonHelper.getUserNamebyId(clientUsers, localStorage.uid);
            newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(__enquiryData)
                ? __enquiryData.clientID
                : null));
            if (enableCustShowNoShow)//(stage && stage === 'Converted') || objFields.pipeline !== 'LeadsBucket')
                newlogVM = CommonHelper.setCustShowNoShow(newlogVM, props.dealersettings, enableCustShowNoShow, activityTypes);

            Object.keys(newlogVM).forEach(key => newlogVM[key] === undefined ? delete newlogVM[key] : {});
            firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`).set(newlogVM, { merge: true })
                .catch(error => {
                    console.error(error);
                });
        } catch (error) {
            errorLog(error, 'updateInBound');
        }
    }

    const updateInBound = async (enquiryID, documentID, __enquiryData) => {
        try {

            const { dealersettings } = props;
            const userID = dealersettings ? dealersettings.id : '';

            const batch = firestoreDB(props.dealersettings).firestore().batch();
            let _objReq = {
                status: 1,
                enquiryID: enquiryID,
                oemStatus: null,
                modifiedBy: userID,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }
            batch.set(firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${documentID}`), _objReq, { merge: true });

            // await window.firebase.firestore().collection(`inboundLeads/${documentID}/notesHistory`)
            //     .get().then(response => {
            //         response.docs.forEach((newlogVM) => {

            //             newlogVM.type = 'log';
            //             newlogVM.subType = 'note';
            //             newlogVM.isDone = true;
            //             newlogVM.startDate = newlogVM.addedDate ? newlogVM.addedDate : window.firebase.firestore.Timestamp.now();
            //             newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(__enquiryData)
            //                 ? __enquiryData.clientID
            //                 : null));
            //             newlogVM.enquiryID = enquiryID;
            //             newlogVM.enquiry = Object.assign({}, __enquiryData);
            //             newlogVM.contact = __enquiryData && __enquiryData.contact ? Object.assign({}, __enquiryData.contact) : null;
            //             newlogVM.contactID = __enquiryData && __enquiryData.contact && __enquiryData.contact.documentID ? __enquiryData.contact.documentID : null;

            //             batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            //         });
            //     });
            batch.commit().catch((error) => {
                console.error("Error updating document: ", error);
            });

        } catch (error) {
            errorLog(error, 'updateInBound');
        }
    }

    const bindCompany = (objData) => {

        if (!_.isEmpty(objData) && !_.isEmpty(objData.company)) {
            let _company = Object.assign({});
            if (_.isString(objData.company)) {
                _company.name = objData.company;
                _company.documentID = firestoreDB(props.dealersettings).firestore().collection('companies').doc().id;
            }
            else if (_.isObject(objData.company) && _.isEmpty(objData.company.documentID)) {
                _company = Object.assign({}, companyBasicVM);
                for (let [key, value] of Object.entries(objData.company)) {
                    if (_company.hasOwnProperty(key) && value)
                        _company[key] = value;
                }
                _company.documentID = firestoreDB(props.dealersettings).firestore().collection('companies').doc().id;
            }

            if (!_.isEmpty(_company)) {
                objData.company = _company;
                objData.isCompany = true;
            }
            else
                objData.company = null;

        }

        return objData;

    }
    const updateCompany = (_company, _contactID) => {
        try {
            if (_.isObject(_company)) {
                const batch = firestoreDB(props.dealersettings).firestore().batch();

                const { dealersettings } = props;

                let objData = Object.assign({}, companyBasicVM);
                for (let [key, value] of Object.entries(_company)) {
                    if (objData.hasOwnProperty(key) && value)
                        objData[key] = value;
                }
                if (!objData.documentID)
                    objData.documentID = firestoreDB(dealersettings).firestore().collection('companies').doc().id;


                objData.addedBy = dealersettings ? dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
                objData.modifiedBy = dealersettings ? dealersettings.id : '';
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = 'web';
                objData.isDeleted = false;
                objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(objData, dealersettings, null, true);

                batch.set(firestoreDB(dealersettings).firestore().doc(`companies/${objData.documentID}`), objData, { merge: true });

                if (_contactID) {
                    batch.set(firestoreDB(dealersettings).firestore().doc(`contactDetails/${_contactID}`),
                        {
                            'company': {
                                name: objData.name ? objData.name : '',
                                documentID: objData.documentID
                            }
                        }, { merge: true });
                }


                batch.commit().catch((error) => {
                    console.error("Error updating document: ", error);
                });
            }

        } catch (error) {
            errorLog(error, 'updateCompany');
        }
    }

    const saveReqInfo = (enquiryID, _objReq, clientID) => {
        try {

            const { dealersettings } = props;
            const userID = dealersettings ? dealersettings.id : '';
            let objData = Object.assign({}, _objReq);
            const inboundAllocation = (localStorage.defaultModule === 'oem') ? true : false;
            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings, null, clientID, inboundAllocation);

            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = userID
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = userID
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            if (!objData.enquiryID)
                objData.enquiryID = enquiryID;

            let requirementID = objData['documentID'];

            const refReqData = firestoreDB(props.dealersettings).firestore().collection(`enquiries/${enquiryID}/requirements`).doc(requirementID);
            refReqData.set(objData, { merge: true }).then((docRef) => {

                let _makeModel = !_.isEmpty(objData.stock) ?
                    (objData.stock.make ? objData.stock.make + ' ' : '') + (objData.stock.model ? objData.stock.model + ' ' : '') + (objData.stock.year ? objData.stock.year : '') :
                    (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '');

                let _logNote = (!_.isEmpty(objData.stock) ? 'Requirement stock added' : 'Requirement added') + (_makeModel ? ' (' + _makeModel + ')' : '');

                CommonHelper.saveChangeLog(dealersettings.client.id, objData.groupID, objData.enquiryID, _logNote, 'enquiry', 'updated');

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        } catch (error) {
            errorLog(error, 'saveReqInfo');
        }

    };

    const deleteInbound = () => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'You want to delete?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(result => {
            if (result.value) {
                setDeleteloader(true);
                let objFilds = {
                    'modifiedDate': window.firebase.firestore.Timestamp.now(),
                    'modifiedBy': localStorage.uid,
                    'isDeleted': true,
                    'status': 2
                }
                firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${props.docID}`)
                    .set(objFilds, { merge: true })
                    .then(snapshot => {
                        setDeleteloader(false);

                        props.handleModalClose(fields, 'delete');
                        if (props.setNotifyClose)
                            props.setNotifyClose(true);

                        toast.notify('Lead deleted successfully', {
                            duration: 2000,
                        });
                    })
                    .catch(error => {
                        errorLog(error, 'deleteInbound');
                        setDeleteloader(false);
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000,
                        });
                    });
            }
        });
    };

    const createFusionEvents = (_enquiry) => {
        let newEventVM = {};
        newEventVM.isCheckIn = fields.request.isCheckIn;
        newEventVM.checkinDate = fields.request.CheckedInTime;
        newEventVM.clientID = _enquiry.clientID;
        newEventVM.contactID = _enquiry.contactID;
        newEventVM.documentID = `${_enquiry.contactID}-${fields.request.EventId}`;
        newEventVM.eventCustomerID = fields.request.Id;
        newEventVM.eventDate = fields.request.EventStartDate;
        newEventVM.eventID = fields.request.EventId;
        newEventVM.location = fields.request.EventLocation;
        newEventVM.title = fields.request.EventTitle;
        Object.keys(newEventVM).forEach(key => newEventVM[key] === undefined ? delete newEventVM[key] : {});
        window.firebase.firestore().doc(`fusionevents/${newEventVM.documentID}`).set(newEventVM, { merge: true })
            .catch(error => {
                console.error(error);
            });;
    }

    //#endregion

    //#region  CONTACT CHANGES

    const handleContactClose = (ID, _contact) => {
        if (!_.isEmpty(_contact)) {
            Object.keys(_contact).forEach(key => ([undefined, null, ''].includes(_contact[key]) || (_.isObject(_contact[key]) && _.isEmpty(_contact[key]))) ? delete _contact[key] : {});
            if (!_.isEmpty(_contact)) setContact(_contact)
        }

        setshowContactModal(false)
    }

    const handleValidContact = () => {
        //console.log(contact)
        if (_dmsEnabled && (contact.email || contact.phone) && _.isEmpty(_.find(contact.tags, { type: 'cap' }))) {
            searchDMSContact();
        }
        else {
            handleSaveContact();
        }

    }

    const searchDMSContact = () => {
        setLoading(true);
        const { dealersettings, groupUsers, clientUsers } = props;
        let clientID = contact.clientID ? contact.clientID : (dealersettings.client ? dealersettings.client.id : '');

        try {
            const dmsContacts = window.firebase.functions().httpsCallable('contact-searchDMS');
            dmsContacts({
                clientID: clientID,
                dms: _dmsSettings.type,
                keywords: contact.email ? contact.email : contact.phone
            }).then((response) => {
                if (response.data.success && !_.isEmpty(response?.data?.data)) {
                    let _contact = response.data.data[0];

                    if (!_.isEmpty(_contact?.Vehicles)) _contact.contactType = 'Current Customer';
                    if (_contact?.capOwner && !_.isEmpty(CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _contact.capOwner))) _contact.owner = _contact.capOwner;

                    setShowCapInfo({ show: true, contact: { ..._contact, type: null } })
                }
                else {
                    handleSaveContact();
                }
            });
        }
        catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire('Something went wrong on CAP searching.', '', 'error')
        }
    }

    const handleCloseCAPInfo = async (_contact, isEnquiryExist, _enquiry) => {
        //console.log('handleCloseCAPInfo', _contact, isEnquiryExist, _enquiry)

        if (isEnquiryExist && _enquiry?.documentID) {
            await updateInBound(_enquiry.documentID, fields.documentID, _enquiry);

            let _tags = Object.assign([], _enquiry?.tags);
            _tags.push({
                type: (fields.systemType ? fields.systemType : ''),
                refID: (fields.refID ? fields.refID : ''),
                subType: (fields.subType ? fields.subType : '')
            })
            //SAVE NOTES , TAGS ON LINKED ENQUIRY
            updateConvertedData(_enquiry.documentID, _enquiry, _tags);

            if (props.setNotifyClose) props.setNotifyClose(true);
            props.handleModalClose();
            history.push("/enquiry/details/" + _enquiry?.documentID);
        }
        else if (!_.isEmpty(_contact)) {
            handleSaveContact(_contact)
        }
        else {
            setLoading(false);
        }

        setShowCapInfo({ show: false, contact: null })
    }

    const handleSaveContact = (_contact) => {
        try {

            setLoading(true);
            let objData = Object.assign({}, (!_.isEmpty(_contact) ? _contact : contact));
            let _param = {};
            let dealersettings = props.dealersettings;
            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = props.dealersettings ? props.dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = props.dealersettings ? props.dealersettings.id : '';
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.isDeleted = false;
            objData.modifiedFrom = 'web';

            if (objData?.owner && objData?.capOwner) objData.owner = objData.capOwner;
            else if (fields.owner) objData.owner = fields.owner;

            if (fields.campaign && !objData.campaign) objData.campaign = fields.campaign;
            if (fields.origin && !objData.origin) objData.origin = fields.origin;
            let defaultCountry = props.dealersettings.client.countryCode ? props.dealersettings.client.countryCode.toUpperCase() : 'AU';
            //Remove empty value from object
            for (var propName in objData) {

                if (defaultCountry === 'AU' && (propName === 'phone' || propName === 'secondaryPhone')) {
                    objData[propName] = CommonHelper.validPhoneZeroPrefix(objData[propName], objData[`${propName}Code`])
                }

                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            if (_.isEmpty(objData.country)) objData.country = defaultCountry;

            // //save license image
            // if (fields.request && fields.request.LicenseImageURL && fields.request.LicenseImageURL.includes('http')) {
            //     objData.licenseFrontURL = fields.request.LicenseImageURL;
            // }
            // if (fields.request && fields.request.LicenseImageBackURL && fields.request.LicenseImageBackURL.includes('http')) {
            //     objData.licenseBackURL = fields.request.LicenseImageBackURL;
            // }
            // if (fields.request && fields.request.ImageURL && fields.request.ImageURL.includes('http')) {
            //     objData.userImageURL = fields.request.ImageURL;
            // }
            // if (fields.request && fields.request.LicenseExpiry) {
            //     objData.licenseExpiry = moment(fields.request.LicenseExpiry).format('YYYY-MM-DD');
            // }
            // if (fields.request && fields.request.LicenseNo) {
            //     objData.licenseNo = fields.request.LicenseNo;
            // }
            // //end

            if (!_.isEmpty(enquiry.convertedLeadRefID) &&
                _.isEmpty(contact.mergeID) &&
                !_.isEmpty(objData.clientID) &&
                objData.clientID !== dealersettings.client.id) {
                objData = copyExistingContact(objData);
            }

            if (!objData.documentID)
                objData.documentID = firestoreDB(props.dealersettings).firestore().collection('contactDetails').doc().id;

            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(objData, props.dealersettings, objData.clientID, true);



            // if (!objData.clientID)
            //     objData.clientID = fields.clientID ? fields.clientID : (props.dealersettings ? props.dealersettings.client.id : '');

            // if (!objData.groupID)
            //     objData.groupID = (props.dealersettings && props.dealersettings.client.group ? props.dealersettings.client.group : null);

            // if (!objData.regionID)
            //     objData.regionID = (props.dealersettings && props.dealersettings.client.region ? props.dealersettings.client.region : null);

            // if (!objData.oemID)
            //     objData.oemID = (props.dealersettings ? props.dealersettings.client.settingsID : null);

            if (objData.documentID)
                _param['contactID'] = objData.documentID;

            // if (objData.groupID) {
            //     _param['groupID'] = objData.groupID;
            // }

            const _systemType = Object.assign({}, !_.isEmpty(dealersettings?.client?.integrations) ?
                dealersettings.client.integrations.filter(e => e.type === fields.systemType)[0] : {});

            if (!_.isEmpty(_systemType) && _systemType?.settings?.customerConsent)
                objData.terms = {
                    addedBy: localStorage.uid,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    verbalConsent: false,
                    signature: '',
                    termsAndConditions: !_.isEmpty(dealersettings?.client?.digitalConsent) ? dealersettings.client.digitalConsent : (!_.isEmpty(dealersettings?.client?.customerConsent) ? dealersettings.client.customerConsent : ''),
                    consentType: 'Digital'
                };


            const _pmds = Object.assign({}, !_.isEmpty(dealersettings) &&
                dealersettings.client &&
                dealersettings.client.integrations &&
                dealersettings.client.integrations.filter(e => e.type === "pmds")[0]);
            if (!_.isEmpty(_pmds) && _pmds.active && _pmds.enabled)
                _param['pmds'] = _pmds;

            if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'pmds')))
                _param['pmds'] = null;

            const _keyloop = Object.assign({}, !_.isEmpty(dealersettings) &&
                dealersettings.client &&
                dealersettings.client.integrations &&
                dealersettings.client.integrations.filter(e => e.type === "keyloop")[0]);
            _param['keyloop'] = !_.isEmpty(_keyloop) && _keyloop.active && _keyloop.enabled ? true : false;

            if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'keyloop')))
                _param['keyloop'] = null;

            //_param['testpmds'] = true;

            if (objData.mergeID) {
                _param['mergeID'] = objData.mergeID;
                //_param['pmds'] = null;
                delete objData['mergeID'];
            }

            _param = CommonHelper.setClientOrGroupOrRegionOrOemID(_param, props.dealersettings);
            _param['isShared'] = ((!_.isEmpty(props.dealersettings) &&
                !_.isEmpty(props.dealersettings.group) &&
                props.dealersettings.group.enableSharedContact) ? true : false);
            setContact(objData);
            //console.log('contact-saveContact', objData, _param)
            const addContact = window.firebase.functions().httpsCallable('contact-saveContact');
            addContact({
                "model": objData,
                "param": _param
            }).then((res) => {

                let response = res.data;
                if (response.success === false) {
                    //console.log(response);
                    if (response.code === contactResponseCode.PhoneAlreadyExists ||
                        response.code === contactResponseCode.EmailAlreadyExists ||
                        response.code === contactResponseCode.LicenseAlreadyExists ||
                        response.code === contactResponseCode.PMDSAlreadyExists ||
                        response.code === contactResponseCode.KeyloopAlreadyExists) {
                        setLoading(false);
                        handleExistingContact(response);
                    }
                    else {
                        toast.notify(response.message, {
                            duration: 2000
                        })
                    }
                    return false;
                }
                else {

                    handleSaveEnquiry(objData);



                    return true;
                }
            });
        } catch (error) {
            errorLog(error, 'handleSaveContact');
        }
    }

    const handleExistingContact = (response) => {

        let _entity;
        let _msg;
        let _title;
        let _fullName = response.data ? CommonHelper.displayContactName([], response.data) : '';

        if (response.code === contactResponseCode.PhoneAlreadyExists) {
            _entity = "mobile no.";
            _title = "Mobile No. already exist";
        } else if (response.code === contactResponseCode.EmailAlreadyExists) {
            _entity = "email address";
            _title = "Email already exist";
        } else if (response.code === contactResponseCode.LicenseAlreadyExists) {
            _entity = "license no.";
            _title = "License No. already exist";
        }

        _msg = _entity ? `A contact ${_fullName} already has this ${_entity}.` : '';
        if (response.code === contactResponseCode.PMDSAlreadyExists || response.code === contactResponseCode.KeyloopAlreadyExists)
            _title = response.message;


        Swal.fire({
            title: CommonHelper.showLocale(props, (_title ? _title : '')),
            html: _msg ? _msg : '',
            showCancelButton: true,
            confirmButtonClass: 'viewButton',
            confirmButtonColor: '#459E20',
            cancelButtonColor: '#17232D',
            confirmButtonText: CommonHelper.showLocale(props, 'View Contact'),
            cancelButtonText: CommonHelper.showLocale(props, 'Okay'),
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                handleXContactShow(response.data)
            }
        });
    }

    const handleXContactShow = (data) => {
        try {

            if (!_.isEmpty(data)) {
                data = convertContactVM(data);
                setshowContactInfo(true);
                setexistingContactInfo(data);
            }
        } catch (error) {
            errorLog(error, 'handleXContactShow');
        }
    }

    const handleXContactClose = (type, objData) => {
        try {

            if (type && !_.isEmpty(objData)) {
                if (type === 'fillMissing') {
                    fillMissingContact(objData);
                }
                else if (type === 'replaceAll' || type === 'merge') {
                    setContactMerged(true);
                    replaceContact(objData)
                }
            }
            setshowContactInfo(false);
            setexistingContactInfo({})
        } catch (error) {
            errorLog(error, 'handleXContactClose');
        }
    }

    const convertContactVM = (doc) => {
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objcontactData)) {
            if (contact.hasOwnProperty(key))
                contact[key] = value;
        }
        return contact
    }
    const fillMissingContact = (objData) => {
        const _fields = Object.assign({}, contact);

        for (let [key, value] of Object.entries(objData)) {
            if (_fields[key] === null || _fields[key] === undefined || _fields[key] === false || _fields[key] === '' || (_.isObject(_fields[key]) && _.isEmpty(_fields[key]))) {
                _fields[key] = value;
            }
        }
        _fields.documentID = objData.documentID ? objData.documentID : '';
        _fields.owner = objData.owner ? objData.owner : '';
        _fields.addedBy = objData.addedBy ? objData.addedBy : '';
        _fields.addedDate = objData.addedDate ? objData.addedDate : null;
        if (_.isEmpty(_fields.owner)) _fields.owner = localStorage.uid;
        if (_.isEmpty(_fields.country) && !_.isEmpty(_fields.state)) _fields.country = 'AU';
        _fields.isDeleted = false;
        setContact(_fields);
    }
    const replaceContact = (objData) => {
        const _fields = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objData)) {
            _fields[key] = value;
        }
        _fields.isDeleted = false;
        if (_.isEmpty(_fields.owner)) _fields.owner = localStorage.uid;
        if (_.isEmpty(_fields.country) && !_.isEmpty(_fields.state)) _fields.country = 'AU';
        setContact(_fields);
    }

    const copyExistingContact = (objData) => {
        const _fields = Object.assign({}, objData);

        _fields.documentID = firestoreDB(props.dealersettings).firestore().collection('contactDetails').doc().id;
        _fields.displayID = null;
        _fields.refContactID = objData.documentID;
        _fields.addedBy = props.dealersettings ? props.dealersettings.id : '';
        _fields.addedDate = window.firebase.firestore.Timestamp.now();
        _fields.owner = props.dealersettings ? props.dealersettings.id : '';
        _fields.isDeleted = false;

        // if (objData.clientID !== props.dealersettings.id) {

        // }
        return _fields
    }

    const errorLog = (error, origin) => {
        const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
        const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
        ref.set({
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            clientID: props.dealersettings.client.id,
            oemID: props.dealersettings.client.settingsID,
            error: error.message ? error.message : '',
            description: (origin ? origin + ':- ' : '') + (error.message ? error.message : ''),
            currentPath: window.location.pathname,
        }).then(() => {
            console.log('Error has been uploaded')
        }).catch((error) => {
            console.error("Error adding log: ", error);
        });
    }

    const handlOEMLeadConvertClose = (_enqFields) => {
        if (!_.isEmpty(_enqFields)) {

            props.handleModalClose();

            if (props.setNotifyClose)
                props.setNotifyClose(true);

            setshowOEMLeadConvert(false);
            setshowOEMLeadConvertPrev(false);

        }
        else {
            setshowOEMLeadConvert(false);
            setshowOEMLeadConvertPrev(false);
        }
    }

    //#endregion

    const { dealersettings } = props;

    const _permissions = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : (props.readOnlyView ? true : false));
    const _settings = dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings;

    //console.log('C O N T A C T', fields.isDeleted);

    const onMentionChange = notesText => {
        if (notesText)
            notesText = notesText.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (notesText.length > 0) {
            setErrors({ ...errors, 'notes': '' });
        }
        setFields({
            ...fields,
            comments: notesText
        });

        // handleChangelog(e, true);
    };

    const onMentionSelect = (option, ownerID) => {
        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        if (id.includes(',')) {
            if (idToPass.includes(localStorage.uid)) {
                idToPass = idToPass.replace(`${localStorage.uid},`, '')
                idToPass = idToPass.replace(`${localStorage.uid}`, '')
            }
            if (ownerID && idToPass.includes(ownerID)) {
                idToPass = idToPass.replace(`${ownerID},`, '')
                idToPass = idToPass.replace(`${ownerID}`, '')
            }
            setTeamMentions(idToPass)
        }

        // Check to avoid adding duplicate entries in mention
        const check = mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            setMentions([...mentions, { name, id: idToPass }]);
        }
    };

    const clearTeamMentions = () => {
        setTeamMentions(null)
    }


    const _transferHistoryData = fields && !_.isEmpty(fields.transferHistory) ? CommonHelper.getLatestTransferHistory(fields.transferHistory, dealersettings, true, 'InboundLead') : null;

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleModalClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-integration-lead"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={'Lead Details'} />

                        <div className="lead-source-badge">
                            {!_.isEmpty(_transferHistoryData) ?
                                <div className="oem-tag-id w-100 mr-2">
                                    <i className="ico icon-location"></i>{_transferHistoryData.name}
                                    <span className="user-role-span ml-1">{_transferHistoryData.value}</span>
                                </div> : <></>}
                            {
                                fields.tags && fields.tags.length > 0 ? (<>

                                    {
                                        fields.tags.filter(m => !_.isEmpty(m)).map((r, i) => {
                                            return <div key={i} className="badge badge-pill badge-white ml-1 text-capitalize">{r}</div>
                                        })
                                    }

                                </>) : (<></>)
                            }
                            {fields && fields.isDeleted ?
                                <><div className={`badge badge-pill badge-lost ml-2`}><Translate text={'deleted'} upperCase={true} /></div></>
                                :
                                <></>}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {
                        !_.isEmpty(fields)
                            ?
                            <>
                                <div className={`container-fluid ${(readOnlyEnquiryView || fields.isDeleted) ? 'form-disable' : ''}`}>
                                    <div className="row">
                                        <div className="integration-lead-body">
                                            <div className="integration-lead-contact-details">
                                                <h3> <Translate text={'contactDetails'} /></h3>
                                                <div className="integration-lead-box">
                                                    <div className="integration-lead-contact-head">
                                                        {(() => {
                                                            return <>
                                                                {
                                                                    (localStorage.defaultModule !== 'oem' && fields.status !== 1 && !readOnlyEnquiryView && !fields.isDeleted) && (<div className="loaninfo-edit-button">
                                                                        <a href="#" className="mini-button" title={'Edit Contact Info'} onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setshowContactModal(true);
                                                                        }}>
                                                                            <i className="ico icon-edit"></i>
                                                                        </a>
                                                                    </div>)
                                                                }
                                                            </>
                                                        })()}

                                                        <div className="integration-lead-contact-title"> {CommonHelper.displayFullContact(titles, contact, '--')}</div>
                                                        {contact && contact.preferredName ? <span className="integration-lead-contact-preferred">({contact.preferredName})</span> : <></>}
                                                        <div className="integration-lead-contact-sub">
                                                            <ul className="integration-lead-ul">
                                                                <li> <i className="ico icon-call"></i> {contact && contact.phone ? (CommonHelper.phoneFormat(contact.phoneCode, contact.phone, props.dealersettings)) : '--'}</li>
                                                                <li> <i className="ico icon-email"></i> {contact && contact.email ? (contact.email) : '--'}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="integration-lead-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'licenseNo'} /></span> {contact && contact.licenseNo ? (contact.licenseNo) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'licenseExpiry'} /> </span> {contact && contact.licenseExpiry ? (contact.licenseExpiry) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'gender'} /></span> {contact && contact.gender ? (contact.gender) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'companyName'} /></span> {contact && contact.company ? (_.isObject(contact.company) ? contact.company.name : contact.company) : '--'}</td>
                                                                </tr>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'nationality'} /></span> {contact && contact.nationality ? (contact.nationality) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'dob'} /></span>{contact && contact.dob ? (contact.dob) : '--'}</td>
                                                                    <td colSpan="2"><span className="table-label"><Translate text={'address'} /></span> {contact && contact.address ? (contact.address) : '--'} </td>
                                                                </tr>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'areaCode'} /></span> {contact && contact.areaCode ? (contact.areaCode) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'state'} /> </span> {contact && contact.state ? CommonHelper.getLabelByValue(states, contact.state) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'country'} /></span> {contact && contact.country ? CommonHelper.getLabelByValue(countries, contact.country) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'optinPhone'} /></span> {_.isBoolean(contact?.optinPhone) ? (contact.optinPhone ? 'Yes' : 'No') : '--'}</td>
                                                                </tr>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'optinEmail'} /></span>{_.isBoolean(contact?.optinEmail) ? (contact.optinEmail ? 'Yes' : 'No') : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'optinPost'} /> </span> {_.isBoolean(contact?.optinPost) ? (contact.optinPost ? 'Yes' : 'No') : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'optinSMS'} /></span> {_.isBoolean(contact?.optinSMS) ? (contact.optinSMS ? 'Yes' : 'No') : '--'}</td>
                                                                    <td width="25%">&nbsp;</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <h3> <Translate text={'vehicleDetails'} /></h3>
                                                <Requirements
                                                    requirement={fields.requirement || {}}
                                                    //enquiryID={activity.enquiryID}
                                                    handleEdit={() => { }}
                                                    setPrimary={() => { }}
                                                    decrementQuantity={() => { }}
                                                    incrementQuantity={() => { }}
                                                    isPrimary={false}
                                                    isDeivered={true}
                                                    hideMore={true}
                                                    dealersettings={props.dealersettings}
                                                    clientUsers={props.clientUsers}
                                                    groupUsers={props.groupUsers}
                                                >
                                                </Requirements>
                                                {/*<div className="integration-lead-box">
                                                    
                                                     <div className="integration-lead-vehicle-head">
                                                        <div className="flex-vehicle">
                                                            <div className="vehicle-item-image"> <a href="#"> <img src={CommonHelper.showBrandLogo(dealersettings, fields?.requirement?.make)} className="img-object-fit-contain" alt="" width="70" height="70" /> </a> </div>
                                                            <div className="vehicle-data">
                                                                <div className="vehicle-item-title">
                                                                    {fields.requirement && fields.requirement.make ? (fields.requirement.make) : ''} &nbsp;
                                                                    {fields.requirement && fields.requirement.model ? (fields.requirement.model) : ''}
                                                                </div>
                                                                <div className="vehicle-item-info">
                                                                    {fields.requirement && fields.requirement.extColor ? `${fields.requirement.extColor}, ` : ''}
                                                                    {fields.requirement && fields.requirement.year ? (fields.requirement.year) : ''}
                                                                </div>
                                                                {fields?.requirement?.stockNo ? <><div className="vehicle-item-info">
                                                                    <Translate text={'stock'} toUpperCase={true} />#: &nbsp; {fields.requirement.stockNo}
                                                                </div></> : <></>}
                                                                {fields?.requirement?.regNo ? <><div className="vehicle-item-info">
                                                                    <Translate text={'reg'} toUpperCase={true} />#: &nbsp; {fields.requirement.regNo}
                                                                </div></> : <></>}
                                                                {
                                                                    fields.requirement && fields.requirement.saleType ?
                                                                        <div className="vehicle-item-status">

                                                                            <div className="badge badge-pill badge-white ml-1">
                                                                                {CommonHelper.getNameByValue(_settings && _settings.salesType,
                                                                                    fields.requirement.saleType, '')}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div> 

                                                <div className="integration-lead-contact-table">
                                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                        <tbody>
                                                            <tr className="tr-brk">
                                                                <td width="25%"><span className="table-label">Stock No.</span> ST-986532</td>
                                                                <td width="25%"><span className="table-label">VIN No.</span> VIN986532147852</td>
                                                                <td width="25%"><span className="table-label">Reg. No.</span> XDF9865</td>
                                                                <td width="25%"><span className="table-label">Price</span> $125,000</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div> */}


                                                <h3> <Translate text={'Other Information'} /></h3>

                                                <div className="integration-lead-box">
                                                    <div className="integration-lead-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic, 'origin')} /> </span>{fields.origin ? CommonHelper.getNameByValue(_settings && _settings.origins, fields.origin, '--') : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic, 'campaign')} /></span> {fields.campaign ? CommonHelper.getNameByValue(_settings && _settings.campaigns, fields.campaign, '--') : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic, 'leadSource')} /> </span>{fields.leadSource ? CommonHelper.getNameByValue(_settings && _settings.leadSource, fields.leadSource, '--') : '--'}</td>
                                                                    <td width="25%">
                                                                        <span className="table-label"><Translate text={'Received Date & Time'} /></span>
                                                                        {fields.addedDate ? moment.unix(fields.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}
                                                                    </td>
                                                                </tr>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'Appointment Date & Time'} /></span> {fields.appointmentOn ? (_.isString(fields.appointmentOn) ? moment(new Date(fields.appointmentOn)).format('DD/MM/YYYY h:mm A') : moment.unix(fields.appointmentOn.seconds).format('DD/MM/YYYY h:mm A')) : '--'}</td>
                                                                    <td colSpan="3" className="text-area-space"><span className="table-label"><Translate text={'Notes'} /> </span>
                                                                        {fields.notes ? (
                                                                            <div dangerouslySetInnerHTML={{
                                                                                __html: CommonHelper.stringtoURL(fields.notes),
                                                                            }}
                                                                            ></div>
                                                                        ) : '--'}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>


                                                {
                                                    fields.status !== 1 && (localStorage.defaultModule !== 'oem' || (localStorage.defaultModule === 'oem' && !_.isEmpty(fields) && fields.status === 0 && _.isEmpty(fields.clientID)))
                                                        ?
                                                        <>
                                                            <div className="lead-stage-layout form-style">
                                                                {
                                                                    enableLeadTransfer && localStorage.defaultModule !== 'oem'
                                                                        ?
                                                                        <div className="form-row ">
                                                                            <div className="form-group col-md-12 mt-2">
                                                                                <label > <Translate text={'dealerships'} /></label>
                                                                                <ReactSelect
                                                                                    options={groups}
                                                                                    name={"clientID"}
                                                                                    placeholder={'select dealer'}
                                                                                    onChange={handleDealerChange}
                                                                                    value={fields.clientID}
                                                                                    classNamePrefix={`${errors["clientID"]} basic-select`}
                                                                                    removeClearable={true}
                                                                                >
                                                                                </ReactSelect>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>

                                                                }


                                                                <div className="form-row ">
                                                                    <div className="form-group col-md-12 mt-2">
                                                                        <label > <Translate text={'pipeline'} /></label>
                                                                        <ReactSelect
                                                                            options={pipelines}
                                                                            name={"pipeline"}
                                                                            placeholder={'select pipeline'}
                                                                            onChange={handlePipelineChange}
                                                                            value={fields.pipeline}
                                                                            classNamePrefix={`${errors["pipeline"]} basic-select`}
                                                                            removeClearable={true}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <label ><Translate text={'Pipeline Stage'} /> </label>
                                                                        <div className={`${errors["stage"] ? 'custom_error' : ''}`}>
                                                                            <ul className={`pipeline-step `}>
                                                                                {
                                                                                    defaulStages.length > 0 && defaulStages.map((_stage, index) => {
                                                                                        return (
                                                                                            <OverlayTrigger
                                                                                                key={index}
                                                                                                placement='bottom'
                                                                                                overlay={
                                                                                                    <Tooltip>{_stage.name}</Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <li key={index} className={activeStageIndex >= index ? 'active' : ''}>
                                                                                                    <a href="#" id={index} name={_stage.value} onClick={(e) => handleStageChange(e)}>
                                                                                                        {/* {_stage.name} */}
                                                                                                        {<>&nbsp;</>}
                                                                                                    </a>
                                                                                                </li>
                                                                                            </OverlayTrigger>

                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label ><Translate text={'owner'} /></label>
                                                                    <ReactSelect
                                                                        options={_.orderBy(allUsers, ["searchlabel"], ["asc"])}
                                                                        name={"owner"}
                                                                        onChange={handleReactSelectChange}
                                                                        value={fields.owner}
                                                                        classNamePrefix={`${errors["owner"]} basic-select`}
                                                                        removeClearable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                                <div className="scheduled-activities-title"> <Translate text={'activity'} /></div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-3">
                                                                        <label > <Translate text={'activityType'} /></label>
                                                                        <ReactSelect
                                                                            options={activityTypes}
                                                                            name={"activityType"}
                                                                            placeholder={'select activity type'}
                                                                            onChange={handleReactSelectChange}
                                                                            value={fields.activityType}
                                                                            classNamePrefix={`${errors["activityType"]} basic-select`}
                                                                            removeClearable={true}
                                                                            isSearchable={false}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label ><Translate text={'activityDate'} /></label>
                                                                        <AntDatePicker
                                                                            value={fields.activityDate ? moment
                                                                                .unix(fields.activityDate.seconds)
                                                                                .format('YYYY-MM-DD') : null}
                                                                            name={'activityDate'}
                                                                            onChange={e => {
                                                                                handleDateChange(e, 'activityDate');
                                                                            }}
                                                                            format='DD/MM/YYYY'
                                                                            placeholder='DD/MM/YYYY'
                                                                            className={`form-control`}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label ><Translate text={'Time'} /></label>
                                                                        <div className='inner-addon right-addon'>
                                                                            <i className='bg-icons ico icon-time'></i>
                                                                            <ReactTimePicker
                                                                                value={fields.activityDate ? moment.unix(fields.activityDate.seconds)._d : null}
                                                                                name={'activityDate'}
                                                                                onChange={handleStartTimeChange}
                                                                                timeIntervals={15}
                                                                                placeholder='h:mm aa'
                                                                                className={`form-control`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h3> <Translate text={'Notes'} /></h3>
                                                            {/* <textarea className={`${errors["comments"]} form-control notes`}
                                                                name="comments" onChange={(e) => handleNotesChanges(e)}
                                                                value={fields.comments ? fields.comments : ''}
                                                                placeholder="notes" cols="80" rows="3"
                                                            ></textarea> */}
                                                            <MentionEditor
                                                                mentionsData={mentionsData}
                                                                onMentionChange={onMentionChange}
                                                                onMentionSelect={onMentionSelect}
                                                                notes={fields.comments ? fields.comments : ''}
                                                                focusOnMount={false}
                                                                isNoteEmpty={false}
                                                                teamMentions={teamMentions}
                                                                clearTeamMentions={clearTeamMentions}
                                                                clientUsers={props.clientUsers}
                                                                ownerID={fields.owner ? fields.owner : ''}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                (!_.isEmpty(fields) && fields.modifiedBy && fields.modifiedDate) ? (
                                                                    <div className="lead-stage-layout form-style vehicle-status-alert alert-user mb-1 mt-2">
                                                                        <b className="alert-link"><Translate text={'allocated'} /> :</b>
                                                                        {moment.unix(fields.modifiedDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), fields.modifiedBy)}

                                                                    </div>
                                                                ) : (<></>)
                                                            }

                                                        </>
                                                }

                                                {
                                                    (localStorage.defaultModule === 'oem' && dealersettings.level === 'oem')
                                                        ?
                                                        <>

                                                            <div className="linked-activities-wrap mt-3">
                                                                <div className="linked-activities-title p-0 pb-2"> <Translate text={'Notes History'} />
                                                                    <div className=" float-right linked-activities-add">
                                                                        <button type="button"
                                                                            className="btn btn-primary float-right"
                                                                            onClick={(e) => { e.preventDefault(); handleNotesOpen(); }}
                                                                        >
                                                                            <i className="ico icon-add"></i> <Translate text={'addNote'} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    notesLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<div className="enquiry-flow mt-0 mb-3">
                                                                        {
                                                                            !_.isEmpty(notesHistory) || !_.isEmpty(notesHistoryEnquiry)
                                                                                ?
                                                                                <div className="enquiry-flow-wrap mt-0 mb-4 border-bottom pb-3">
                                                                                    <div className="flow-timelinebar"></div>

                                                                                    {
                                                                                        notesHistory.map((obj, index) => {
                                                                                            return (
                                                                                                <div key={index} className="enquiry-flow-item note">
                                                                                                    <div className="flow-item  note  ">
                                                                                                        <div className="flow-item-content">
                                                                                                            <div className="flow-text-wrap">
                                                                                                                <div className="flow-text text-area-space note">{obj.notes}
                                                                                                                    <div className="flow-notes-sub mt-1">
                                                                                                                        <span>{obj.addedDate ? moment.unix(obj.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</span>
                                                                                                                        {
                                                                                                                            obj.addedByName
                                                                                                                                ?
                                                                                                                                <>{' - '}{obj.addedByName}</>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        obj.addedBy
                                                                                                                                            ?
                                                                                                                                            <>{' - '}{CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), obj.addedBy)}</>
                                                                                                                                            :
                                                                                                                                            <></>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                        }

                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <span className="flow-icon"> <i className="ico icon-note"></i> </span>
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                    }

                                                                                    {
                                                                                        notesHistoryEnquiry.map((obj, index) => {
                                                                                            return (
                                                                                                <div key={index} className="enquiry-flow-item note">
                                                                                                    <div className="flow-item  note  ">
                                                                                                        <div className="flow-item-content">
                                                                                                            <div className="flow-text-wrap">
                                                                                                                <div className="flow-text text-area-space note">{obj.notes}
                                                                                                                    <div className="flow-notes-sub mt-1">
                                                                                                                        <span>{obj.addedDate ? moment.unix(obj.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</span>
                                                                                                                        {
                                                                                                                            obj.addedByName
                                                                                                                                ?
                                                                                                                                <>{' - '}{obj.addedByName}</>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        obj.addedBy
                                                                                                                                            ?
                                                                                                                                            <>{' - '}{CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), obj.addedBy)}</>
                                                                                                                                            :
                                                                                                                                            <></>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                        }

                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <span className="flow-icon"> <i className="ico icon-note"></i> </span>
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                    }

                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div className="text-center p-5">
                                                                                        <p><Translate text={'no notes created'} /> </p>
                                                                                    </div>
                                                                                </>
                                                                        }

                                                                    </div>)
                                                                }
                                                            </div>

                                                        </>
                                                        :
                                                        <>

                                                        </>
                                                }

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="mt-5"><ContentOverlay active={true} /></div>
                            </>
                    }

                </Modal.Body>

                <>
                    {
                        fields.status !== 1 && !readOnlyEnquiryView && !fields.isDeleted
                            ?
                            <>
                                <Modal.Footer className="modal-footer">

                                    {
                                        !_.isEmpty(rosterMsg) ? (
                                            <div className="activity-pop-info alert-danger mb-3">
                                                <i className="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>{rosterMsg}
                                            </div>
                                        ) : (<></>)
                                    }

                                    {
                                        (localStorage.defaultModule !== 'oem' || (localStorage.defaultModule === 'oem' && !_.isEmpty(fields) && fields.status === 0 && _.isEmpty(fields.clientID)))
                                            ?
                                            <button
                                                type="button"
                                                className={`btn btn-primary float-right ml-2 ${isLoading || _.isEmpty(fields.pipeline) ? 'form-disable' : ''}`}
                                                onClick={(e) => handleSubmit(e)}
                                            >
                                                {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'accept'} />
                                            </button>
                                            :
                                            <></>
                                    }


                                    {
                                        localStorage.defaultModule === 'oem' && !_.isEmpty(fields) && fields.status === 0 && _.isEmpty(fields.clientID)
                                            ?
                                            <button
                                                type="button"
                                                className={`btn btn-green float-right ml-2 ${showOEMLeadConvert || showOEMLeadConvertPrev || _.isEmpty(fields.pipeline) ? 'form-disable' : ''}`}
                                                onClick={(e) => { e.preventDefault(); setshowOEMLeadConvert(true); }}
                                            >
                                                {showOEMLeadConvert || showOEMLeadConvertPrev ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'allocate'} />
                                            </button>
                                            :
                                            <></>
                                    }


                                    {/* <button
                                    type="button"
                                    className="btn btn-red float-right"
                                    onClick={(e) => handleCancel(e)}
                                ><Translate text={'cancel'} />
                                </button> */}

                                    {
                                        (deleteInboundLead || props.dealersettings.superAdmin === true)
                                            ? (
                                                <button
                                                    type='button'
                                                    className='btn btn-red float-left ml-2'
                                                    onClick={() => {
                                                        deleteInbound();
                                                    }}
                                                >
                                                    {deleteLoader ? (
                                                        <span
                                                            className='spinner-border spinner-button mr-1'
                                                            role='status'
                                                            aria-hidden='true'
                                                        ></span>
                                                    ) : (
                                                        <>
                                                            <i className='ico icon-delete marker-delete'></i>
                                                        </>
                                                    )}
                                                    <Translate text={'delete'} />
                                                </button>
                                            ) : (
                                                <></>
                                            )}
                                </Modal.Footer>
                            </>
                            :
                            <>
                                {
                                    fields && fields.enquiryID && !fields.isDeleted
                                        ?
                                        <>
                                            <Modal.Footer className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary float-right ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (props.setNotifyClose)
                                                            props.setNotifyClose(true);

                                                        props.handleModalClose();
                                                        history.push("/enquiry/details/" + fields.enquiryID);
                                                    }}
                                                >
                                                    {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                    <Translate text={'viewEnquiry'} />
                                                </button>
                                            </Modal.Footer>
                                        </>
                                        :
                                        <></>
                                }

                            </>
                    }
                </>


            </Modal>

            <PopUpModal show={showContactModal}>
                <AddContact
                    docID={null}
                    show={showContactModal}
                    handleClose={handleContactClose}
                    showSearch={false}
                    engageNewContact={contact ? contact : {}}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isNewEnquiry={true}
                    skipMandatory={true}
                    skipSave={true}
                >
                </AddContact>
            </PopUpModal>
            <PopUpModal show={showContactInfo}>
                <ContactInfo
                    show={showContactInfo}
                    existingContact={existingContactInfo}
                    newContact={contact ? contact : {}}
                    isNewContact={isContactMerged ? false : true}
                    handleClose={handleXContactClose}
                    title={'contactDetails'}
                    dealersettings={props.dealersettings}
                    clientUsers={!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers}
                >
                </ContactInfo>
            </PopUpModal>

            <PopUpModal show={showOEMLeadConvert}>
                <OEMLeadAllocation
                    show={showOEMLeadConvert}
                    handleModalClose={handlOEMLeadConvertClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    enquiryID={fields.enquiryID}
                    enquiry={fields}
                    returnEnq={true}
                />
            </PopUpModal>

            {/* <PopUpModal show={showOEMLeadConvertPrev}>
                <OEMLeadTransfer
                    show={showOEMLeadConvertPrev}
                    handleModalClose={handlOEMLeadConvertClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    enquiryID={fields.enquiryID}
                    enquiry={fields}
                />
            </PopUpModal> */}

            <PopUpModal show={showNotes}>
                <AddNotes
                    show={showNotes}
                    handleClose={handleNotesClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                />
            </PopUpModal>

            <PopUpModal show={showMergeEnqList.show}>
                <MergeList
                    show={showMergeEnqList.show}
                    title={'Existing enquiries for the selected contact'}
                    handleClose={handleCloseMerge}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    contactID={showMergeEnqList.enquiry && showMergeEnqList.enquiry.contactID}
                    enquiryID={showMergeEnqList.enquiry && showMergeEnqList.enquiry.documentID}
                    primaryEnquiry={showMergeEnqList.enquiry}
                    isMerge={true}
                />
            </PopUpModal>

            <PopUpModal show={showCapInfo.show}>
                <CapInfo
                    show={showCapInfo.show}
                    contact={showCapInfo.contact}
                    handleClose={handleCloseCAPInfo}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    enquiryID={enquiry?.documentID}
                    //enquiry={{}}
                    inbound={true}
                />
            </PopUpModal>
        </>
    )
}

export default InBoundLeadConvert;