import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { default as _images } from '../../../../images';
import { PopUpModal } from '../../../../components';
import Signature from '../../../../components/signature'
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import moment from 'moment';
import toast from 'toasted-notes'

import '../../../../styles/sms-sign.scss';
import { storageBucket } from '../../../../services/helper';

const SignRequest = props => {
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let _contactID = params.get('cid');
	const [loader, setLoader] = useState(true)
	const [client, setClient] = useState({})
	const [clientSettings, setClientSettings] = useState({})
	const [contact, setContact] = useState({})
	const [signRequest, setSignRequest] = useState({})
	const [showSignature, setShowSignature] = useState(false)
	const [signatureLoader, setSignatureLoader] = useState(false)
	const [error, setError] = useState(false)
	const [submitLoader, setSubmitLoader] = useState('')
	const [firestoreDB, setFirestoreDB] = useState();

	useEffect(() => {
		window.firebase.firestore().doc(`contactDetails/${_contactID}`).get()
			.then(doc => {
				if (doc.exists) {
					setFirestoreDB(window.firebase)
				}
				else {
					setFirestoreDB(window.firebase2)
				}
			})
	}, [])

	useEffect(() => {
		if (_.isEmpty(firestoreDB))
			return
		const getData = async () => {
			let signRequestData = {}
			let contactData = {}
			let clientData = {}
			let clientSettingsData = {}

			if (props.signRequestID) {
				console.log('starteds')
				const ref = firestoreDB.firestore().collection(`contactDetails`).doc(_contactID).collection(`signRequest`).doc(props.signRequestID)
				// .where('documentID', '==', props.signRequestID)
				const snapshots = await ref.get()
				console.log('snapshots.size', snapshots.size)

				if (snapshots.exists) {
					// snapshots.docs.forEach((doc) => {
					// 		signRequests.push(doc.data())
					// });
					signRequestData = snapshots.data()
					console.log('signRequest', signRequest)
					// signRequestData = signRequests[0]

					const contactRef = firestoreDB.firestore().collection(`contactDetails`).doc(signRequestData.contactId)
					const contactSnap = await contactRef.get()
					contactData = contactSnap.data()

					const clientSnap = await window.firebase.firestore().doc(`clients/${signRequestData.clientId}`).get()
					clientData = clientSnap.data();

					const clientSettingsSnap = await window.firebase.firestore().doc(`clients/${signRequestData.clientId}/currentSettings/${signRequestData.clientId}`).get()
					clientSettingsData = clientSettingsSnap.data();

					setClient(clientData)
					setClientSettings(clientSettingsData)
					setContact(contactData)
					setSignRequest(signRequestData)
					setLoader(false)
				}

			} else {
				setLoader(false)
			}
		}

		getData()
	}, [firestoreDB])

	useEffect(() => {
		if (_.isEmpty(firestoreDB))
			return
		if (_.isEmpty(firestoreDB.auth().currentUser)) {
			firestoreDB.auth().signInWithEmailAndPassword('secondarydb@fusionamspro.com', 'secondaryDB@321')
				.catch((error) => {
					console.log('error on secondary firestore auth user', error)
				});
		}
	}, [firestoreDB])

	const handleSignatureClose = (imgURL) => {
		console.log('imageURl', imgURL)
		if (imgURL) {
			setSignRequest({
				...signRequest,
				terms: {
					...signRequest.terms,
					signature: imgURL,
				}
			})
			setShowSignature(false)
			saveImageToStorage(imgURL, 'signature', 'png')
			// @CHECK for what to return a base64 image or uploaded image url
			// if (this.props.shouldReturnBase64 === true) {
			// 		this.setState({
			// 				fields: {
			// 						signature: imgURL,
			// 						termsAndConditions: this.props.termsandCondition
			// 								? this.props.termsandCondition
			// 								: _termsandCondition,
			// 				},
			// 		});
			// } else {
			// 		this.;
			// }
		}
		else {
			setShowSignature(false)
		}
	}

	const saveImageToStorage = (imageBase64, id, ext) => {
		console.log('imageBase64, id, ext', imageBase64, id, ext)
		let uid = moment()._d.getTime().toString() + '.' + ext;
		let clientID = client.documentID;
		setSignatureLoader(true)
		console.log('uid', uid);
		var storageRef = storageBucket(props.dealersettings).ref(`${clientID}/signRequest/${uid}`).putString(imageBase64, 'data_url');
		storageRef.on('state_changed', (snapshot) => {
			console.log(snapshot);
		},
			(error) => {
				console.error(error);
				setSignatureLoader(false)
			},
			() => {
				storageBucket(props.dealersettings).ref(`${clientID}/signRequest`).child(uid).getDownloadURL()
					.then(dataURL => {
						console.log('dataURL', dataURL);
						setSignRequest({
							...signRequest,
							terms: {
								...signRequest.terms,
								signature: dataURL,
							}
						})
						setSignatureLoader(false)
						setError(false)
					})
			})
	};

	const onSubmit = async (e) => {
		console.log('onSubmit')
		e.preventDefault()
		if (!_.isEmpty(signRequest) && !_.isEmpty(signRequest.terms) && !_.isEmpty(signRequest.terms.signature)) {
			try {
				setSubmitLoader(true)
				let objData = { ...signRequest }
				objData.status = 'signed'
				const signReqRef = firestoreDB.firestore().collection(`contactDetails`).doc(contact.documentID).collection(`signRequest`).doc(props.signRequestID)
				await signReqRef.set(objData, { merge: true })
				setSubmitLoader(false)
				setSignRequest(objData)
				toast.notify(`Submitted successfully`, {
					duration: 2000
				})
			} catch (error) {
				console.log(error)
				setSubmitLoader(false)
			}
		} else {
			setError(true)
		}
	}

	return loader ? (
		<>
			<div className='loader-center-screen'>
				<div className="spinner-loader h-100 "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
			</div>
		</>) : (
		<main role='main' className='container sms-sign-wrap-bg sms-sign-main'>
			<div className='middle-sms-sign-wrap'>
				<div className='customer-rec-header'>
					<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
						<tbody>
							<tr>
								<td width='100%'>
									<div className='customer-rec-header-left'>
										<div className='customer-rec-logo'>
											<img src={client.logoURL ? client.logoURL : ''} alt='dealer logo' />
										</div>
										<div className='customer-rec-dealer'>{client.name ? client.name : 'dealer Name'}</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='customer-rec-title'>{signRequest.title ? signRequest.title : 'Sign Request'}</div>
				<div className='detailhead'>
					<table
						className='detailtbl01'
						width='100%'
						cellSpacing='0'
						cellPadding='0'
						border='0'
					>
						<tbody>
							<tr>
								<td className='grid-two mob-grid'>
									<div>
										<span><Translate text={'Contact Name'} upperCase={true} /></span>
										<div>{contact.firstName || contact.lastName ? CommonHelper.displayContactName([], contact) : '--'}</div>
									</div>
								</td>
								<td className='grid-two'>
									<div>
										<span><Translate text={'phone'} upperCase={true} /></span>
										<div className='cap-ltr d-flex signreq-contact-name'>{contact.phone ? CommonHelper.phoneFormat(contact.phoneCode, contact.phone, props.dealersettings) : '--'}</div>
									</div>
								</td>
								<td className='grid-two'>
									<div>
										<span><Translate text={'email'} upperCase={true} /></span>
										<div className='cap-ltr emailbreak'>
											{contact.email ? contact.email : '--'}
										</div>
									</div>
								</td>
								<td className='grid-two no-brdright'>
									<div>
										<span><Translate text={'owner'} upperCase={true} /></span>
										<div className='cap-ltr'>{contact.owner ? CommonHelper.getUserNamebyId(clientSettings.users, contact.owner) : '--'}</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='clear'></div>
				</div>

				<div className='clear'></div>

				<div className='sub-inner-wraper brd-none'>
					<div className='sub-inner-head'><Translate text={'Terms and Conditions'} /></div>
					<div className='disclaimer-list'>
						{!_.isEmpty(signRequest) && !_.isEmpty(signRequest.terms) && !_.isEmpty(signRequest.terms.termsAndConditions) ? signRequest.terms.termsAndConditions : '--'}
					</div>
				</div>
				<div className='sub-inner-wraper brd-none'>
					<div className='sub-inner-head'><Translate text={'signature'} /></div>
					<div className={`sign-list ${error ? 'input_error' : ''}`}>
						{(SignRequest.status && SignRequest.status === 'signed') ? (
							<img alt='signature' src={SignRequest.terms.signature} width='120' style={{ maxHeight: '90px' }} />
						) : signatureLoader ? (
							<div className="img-loader" style={{}}>
								<div className="d-flex h-100 justify-content-center align-items-center text-secondary">
									<div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
								</div>
							</div>
						) : (
							<img alt='signature' src={!_.isEmpty(signRequest) && !_.isEmpty(signRequest.terms) && !_.isEmpty(signRequest.terms.signature) ? signRequest.terms.signature : _images.nosign} style={{ maxHeight: '90px' }} width='120' onClick={(e) => {
								e.preventDefault();
								setShowSignature(true)
							}} />
						)}
					</div>
				</div>

				<div className='clear'></div>

				{signRequest.status === 'requested' && (
					<div className='accordion-bottom approve-btn'>
						<a
							href='#'
							className='btn btn-common button-green float-right mt-3'
							onClick={onSubmit}
						>
							<Translate text={'submit'} />
						</a>
						<div className='clear'></div>
					</div>
				)}

				{/* <!--    
  <footer>
     
      <div className="footer-cpy mt-5">
        <div className="footer-bottom">
         <span style="padding-left: 0;">306-308 Church Street, Granville
            PO Box 220, Parramatta NSW 2124</span><span style="padding-left: 0;"> |   Tel: <span style="padding-left: 0;">02 9897 1777 </span> |   Email: <span style="padding-left: 0;">info@fusionsd.com.au</span> </span>
          <div className="cpy-wrap"> Powered by <a href="http://fusionsd.com.au/" target="_blank">Fusion SD </a></div>
          <div className="clear"></div>
        </div>
      </div>
    </footer>  --> */}
			</div>
			<PopUpModal show={showSignature}>
				<Signature
					show={showSignature}
					clsActive='overlay-modal active'
					handleClose={handleSignatureClose}
				></Signature>
			</PopUpModal>
		</main>
	);
};

export default SignRequest;
