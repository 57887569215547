import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash'
import moment from 'moment'
import CommonHelper from '../../services/common';

import Translate from '../../constants/translate';

const RecipientReply = ({ title, show, handleClose, data, dealersettings }) => {
    // console.log('RecipientReply', data)

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop='static'
            backdropClassName={show ? 'overlay-modal active' : ''}
            enforceFocus={false}
            dialogClassName='modal-dialog modal-dialog-centered modal-view-sms-reply'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 className='modal-title'>
                        <Translate text={'Display Messages'} />
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="sms-reply-view-wrapper">
                            <div className="sms-reply-view-popup">
                                <ul>
                                    <li> <span>Name : </span> {CommonHelper.displayContact(null, data, '')}</li>
                                    <li> <span>Phone : </span>{CommonHelper.phoneFormat(data.phoneCode, data.phone, dealersettings)} </li>
                                </ul>

                                <div className="sms-reply-message-view text-area-space"> <span>Message Content:</span>
                                    {
                                        data.content
                                            ?
                                            <>{data.content}
                                            </>
                                            :
                                            <></>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="sms-reply-view-content">
                            <h2> {!_.isEmpty(data.replies) ? data.replies.length : '0'} Reply</h2>
                            <div className="sms-reply-view-list">
                                {
                                    !_.isEmpty(data.replies)
                                        ?
                                        <>
                                            {
                                                data.replies.map((rec, index) => {
                                                    return <div key={index} className="sms-reply-view-loop text-area-space"> {rec.text}
                                                        {rec.date ? <div className="flow-activity-date">
                                                            {moment.unix(rec.date.seconds).format('DD/MM/YYYY h:mm A')}
                                                        </div> : <></>}
                                                    </div>
                                                })
                                            }
                                        </>

                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
};

export default RecipientReply;
