/** LIBRARIES */
import React from 'react'
import moment from 'moment';
import { Draggable } from "react-beautiful-dnd";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** PROVIDERS */
import { StageConsumer } from './provider'
/** COMPONENTS */
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { tipStatus } from '../../services/enum'

const EnquiryCell = React.memo(({ index, task, sidepanelOpen, clientUsers, groupUsers, handleEnquiryFav, dealersettings }) => {

    function getStyle(style, snapshot) {
        if (!snapshot.isDragging) return {};
        if (!snapshot.isDropAnimating) {
            return style;
        }

        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`
        };
    }

    const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
    const _enquiryFields = !_.isEmpty(task.enqFields) ? task.enqFields : task;
    let _tags = _.uniqBy(_.union((_enquiryFields?.tags || []), (_enquiryFields?.tradeIn?.tags || [])), 'type')

    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const _jobStatus = _enquiryFields.status;

    const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
    let _objCurrentStatus = _enquiryFields.status && tradeinProAllStatus.filter(e => e.value === _enquiryFields.status)[0]

    let _badgeColor = _jobStatus === tipStatus.PENDING ? 'blue' : _jobStatus === tipStatus.LOST ? 'orange' : 'green'

    const _client = task.clientID && !_.isEmpty(dealersettings?.group?.clients) && dealersettings?.group?.clients[task.clientID]?.tradeinPro
        ? dealersettings.group.clients[task.clientID] : dealersettings.client;

    let clientIDs = CommonHelper.getTradeinProDealers(dealersettings, true);

    const _permissions = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);
    const lockFinalPrice = ((!_.isEmpty(_permissions) && _permissions.lockFinalPrice) ? true : false);

    let _allClients = Object.assign([], !_.isEmpty(dealersettings.allClients) ? dealersettings.allClients : dealersettings.clients)
    let clientObj = _.find(_allClients, { id: task.clientID });

    let managerWorkflow = clientObj?.clientSettings?.managerWorkflow ? true : false;
    const _isTradeinCompleted = [tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(_jobStatus) ? true : false;
    const _isTradeinWonOrLost = _jobStatus === tipStatus.WON || _jobStatus === tipStatus.LOST ? true : false;
    return (
        // <StageConsumer>
        //     {({ handleEnquiryFav, handleShowInboundLead, dealersettings }) => (
        <Draggable
            draggableId={task.id}
            index={index}
            key={task.id}
        >
            {(provided1, snapshot1) => (
                <>
                    <div
                        ref={provided1.innerRef}
                        {...provided1.draggableProps}
                        {...provided1.dragHandleProps}
                        style={getStyle(provided1.draggableProps.style, snapshot1)}
                        className={`pipeline-item ${task.isNewTradein ? 'newlead' : (task.status ? task.status.toLowerCase() : '')}-cell ${snapshot1.isDraggingOver ? "pipeline-item-dragging-Over" : ""}`}
                        id={`tradeinpro-${task.id}`}
                        onClick={(e) => {
                            e.preventDefault();
                            sidepanelOpen(task.id)

                        }}
                    >
                        <div className="pipeline-control">
                            <div className="pipeline-favourite"><a href="#" onClick={(e) => {
                                e.stopPropagation();
                                handleEnquiryFav(task.id, !task.isFav, task.stage, task.stage, task, 'isFav')
                            }} ><i className={`ico icon-star${task.isFav ? '-active' : ''}`}></i></a></div>

                        </div>

                        {task.displayID ? <div className="tradein-valuation-num text-uppercase">Valuation No:&nbsp; {task.displayID.substring(0, 8)}</div> : <></>}

                        <div className="pipeline-item-head tradein-tile-contact">
                            {/* <span className="badge badge-pill badge-lost">{index + 1}</span>  */}
                            {CommonHelper.displayContactIcon(task?.enqFields?.contact, 'mr-1')}
                            {
                                (!_.isEmpty(task.enqFields) && !_.isEmpty(task.enqFields.contact))
                                    ?
                                    <>
                                        {CommonHelper.displayContact(null, task.enqFields.contact, '')}
                                    </>
                                    :
                                    <>
                                        {
                                            (!_.isEmpty(task.enqFields) && !_.isEmpty(task.enqFields.engageNewContact))
                                                ?
                                                <>
                                                    {CommonHelper.displayContact(null, task.enqFields.engageNewContact, '')}
                                                </>
                                                :
                                                <>
                                                    {task.name}
                                                </>
                                        }
                                    </>
                            }
                        </div>
                        {
                            (!_.isEmpty(task.enqFields) && !_.isEmpty(task.enqFields.contact) && task.enqFields.contact.businessContact && !_.isEmpty(task.enqFields.contact.company))
                                ?
                                <div className="testdrive-contact-license">
                                    <i className={`ico icon-Contacts mr-1`}></i>
                                    {CommonHelper.displayContactName([], task.enqFields.contact, '')}
                                </div>
                                :
                                <></>
                        }
                        {
                            (task.phone || task.email) ?
                                <div className="pipeline-item-phone">
                                    {task.phone ?
                                        CommonHelper.phoneFormat(task.phoneCode, task.phone, dealersettings)
                                        : task.email}
                                </div>
                                : <>
                                    {
                                        (!_.isEmpty(task.enqFields) &&
                                            !_.isEmpty(task.enqFields.contact) &&
                                            task.enqFields.contact.businessContact &&
                                            !_.isEmpty(task.enqFields.contact.company) &&
                                            (task.enqFields.contact.company.phone || task.enqFields.contact.company.email))
                                            ?
                                            <div className="pipeline-item-phone">
                                                {task.enqFields.contact.company.phone ?
                                                    CommonHelper.phoneFormat(task.enqFields.contact.company.phoneCode, task.enqFields.contact.company.phone, dealersettings)
                                                    : task.enqFields.contact.company.email}
                                            </div>
                                            :
                                            <div className="no-data-red"><Translate text={'contactDetailsMissing'} /></div>
                                    }
                                </>
                        }
                        {task.clientName && clientIDs && clientIDs.filter(e => ![dealersettings.client.id].includes(e)).length > 0 ? <div className="dealer-group-title blue-color">{task.clientName}</div> : <></>}
                        {
                            task.vehiclemodel ? <>
                                <div className="pipeline-item-vehicle tradein-tile-vehicle"><strong>{task.vehiclemodel}</strong></div>
                                <div className="pipeline-item-source">
                                    {task.regNo && (<>REG#: &nbsp;<strong>{task.regNo}</strong> &nbsp;</>)}
                                    {task.mileage && (<>Mileage: &nbsp;<strong><NumberFormat style={{ fontSize: 12 }} value={task?.mileage} displayType={'text'} thousandSeparator={true} suffix={' km'} /></strong></>)}

                                </div>
                            </> : (<div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>)
                        }

                        <div className="pipeline-item-div w-100">
                            {
                                task.owner && (<OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>{'Owner'}<br />{task.owner}</Tooltip>
                                    }
                                >
                                    <div className="float-right">
                                        <div className="pipeline-users pl-0">
                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                <img src={CommonHelper.getUserImagebyId(allUsers, _enquiryFields.owner)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                            </a>
                                        </div>
                                    </div>
                                </OverlayTrigger>)
                            }
                            {
                                task.ownerSalesName ? (<OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>{'Sales Owner'}<br />{task.ownerSalesName}</Tooltip>
                                    }
                                >
                                    <div className="float-right mr-1">
                                        <div className="pipeline-users pl-0">
                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                <img src={CommonHelper.getUserImagebyId(allUsers, task.ownerSales)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                            </a>
                                        </div>
                                    </div>
                                </OverlayTrigger>) : (<></>)
                            }
                            <div className="float-left">

                                {
                                    _enquiryFields && _enquiryFields.addedDate
                                        ?
                                        <div className="pipeline-item-div w-100">
                                            <i className="ico icon-timer"></i>
                                            {moment.unix(_enquiryFields.addedDate.seconds).format('DD MMM YYYY h:mm A')}
                                        </div>
                                        :
                                        <></>
                                }

                            </div>


                        </div>

                        {!_.isEmpty(_enquiryFields?.stageHistory) && (<div className="pipeline-item-lead-age">
                            <img src={_images.leadAge} height="16px" className="mr-1" /> Age
                            <div className="lead-age-inner" title='Pipeline Age'>P: <span>{CommonHelper.showPSAge(_enquiryFields.stageHistory, _enquiryFields.pipeline, _enquiryFields.stage, 'pipeline')}</span></div>
                            <div className="lead-age-inner" title='Stage Age'>S: <span>{CommonHelper.showPSAge(_enquiryFields.stageHistory, _enquiryFields.pipeline, _enquiryFields.stage)}</span></div>
                        </div>)}
                        <div className="pipeline-item-source">
                            <div className="float-left">
                                {CommonHelper.getNameByValue(_client?.tradeinPro?.origins, task.origin, '')}
                            </div>
                            {
                                _tags && _tags.length > 0
                                    ?
                                    <>
                                        {
                                            _tags.map((r, i) => {
                                                return r.subType ? (<div key={i} className="float-left"><div className="badge badge-pill badge-white ml-1 text-uppercase">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)} - {r.subType}</div>
                                                    {/* <div className="badge badge-pill badge-white ml-1 text-uppercase">{r.subType}</div>*/}
                                                </div>
                                                ) :
                                                    (<div key={i} className="badge badge-pill badge-white ml-1 text-uppercase">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
                                            })
                                        }
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                        <div className="pipeline-item-div badge-slot-container">
                            <div className="float-left">
                                {/* {
                                    (_jobStatus)
                                        ?
                                        <>
                                            <div className='pipeline-button'>
                                                <span className={`status-${_jobStatus.toLowerCase()} border-bottom-0`}>
                                                    {_jobStatus.split(/(?=[A-Z])/).join(' ').toUpperCase()}
                                                </span>
                                            </div>

                                        </>
                                        :
                                        <></>

                                } */}

                                {
                                    _objCurrentStatus
                                        ?
                                        <>
                                            <div className='pipeline-button'>
                                                <span
                                                    className={`status-${_jobStatus === tipStatus.NEW ? 'open' : 'empty'}`} style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                    <Translate
                                                        text={_objCurrentStatus.name}
                                                        upperCase={true}
                                                    />

                                                </span>
                                            </div>
                                        </>
                                        :
                                        <> </>
                                }
                            </div>
                            {
                                _enquiryFields && !_.isEmpty(_enquiryFields.offer) && _enquiryFields.offer.dealerPrice && !lockFinalPrice && _isTradeinCompleted
                                    ?
                                    <>
                                        {
                                            managerWorkflow
                                                ?
                                                <div className="float-right">

                                                    {
                                                        _enquiryFields.finalPrice
                                                            ?
                                                            <div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                                                <i className="ico icon-sent"></i>
                                                                <NumberFormat value={_enquiryFields.finalPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                                            </div>
                                                            :
                                                            <>
                                                                {_enquiryFields.valuerPrice ? (<div className={`badge-slot-tradein badge-slot-tradein-blue`}>
                                                                    <i className="ico icon-sent"></i>
                                                                    <NumberFormat value={_enquiryFields.valuerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                                                </div>)
                                                                    :
                                                                    <>
                                                                        {
                                                                            (<div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                                                                <i className="ico icon-sent"></i>
                                                                                <NumberFormat value={_enquiryFields.offer.dealerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                                                            </div>)
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                    }


                                                </div>
                                                :
                                                <>
                                                    <div className="float-right">

                                                        <div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                                            <i className="ico icon-sent"></i>
                                                            <NumberFormat value={_enquiryFields.offer.dealerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                                        </div>

                                                    </div>
                                                </>
                                        }

                                    </>
                                    :
                                    <></>
                            }

                        </div>




                    </div>
                </>
            )
            }
        </Draggable >
        //     )}
        // </StageConsumer>
    )
})

export default EnquiryCell