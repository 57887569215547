const images = {
    nouser: require('./none-user.png'),
    logo: require('./logo.png'),
    logoPro: require('./ams-pro.png'),
    loginGraphics: require('./login-graphic.png'),
    nolicense: require('./no-license.jpg'),
    novehicle: require('./no-vehicle.jpg'),
    novehicleMotorcycle: require('./no-vehicle-motorcycle.jpg'),
    arrowclose: require('./arrow-close.svgz'),
    plus: require('./plus.svgz'),
    nonotify: require('./notification.svgz'),
    loginbg: require('./login-bg.jpg'),
    logonpattern: require('./login-pattern.png'),
    addvehicle: require('./add-image.png'),
    inspection: require('./inspection.jpg'),
    inspectionMotorcycle: require('./inspection-motorcycle.jpg'),
    inspectionMcLaren: require('./inspection-mclaren.jpg'),
    mapcar: require('./map-car.png'),
    mapcarMotorcycle: require('./map-motorcycle.png'),
    nodata: require('./no-data.png'),
    addcafe: require('./add-cafe.png'),
    mailsent: require('./sent.svgz'),
    nolicensebg: require('./license-upload-bg.jpg'),
    nosign: require('./sign-ico.png'),
    darklogo: require('./logo-dark.png'),
    nocompany: require('./nocompany.jpg'),
    //chat: require('./chat.svgz'),
    nopipeline: require('./no-pipeline.png'),
    closecircular: require('./close-circular.svgz'),
    nochart: require('./no-graph.svgz'),
    amsfinance: require('./ams-finance.png'),
    amsvideo: require('./ams-video.png'),
    tradeinplus: require('./tradein-plus.png'),
    licensescan: require('./license-scan.png'),
    regoscan: require('./rego-scan.png'),
    amsfrontdesk: require('./ams-frontdesk.png'),
    amsoem: require('./ams-oem.png'),
    nomap: require('./no-map.jpg'),
    tickcircular: require('./tick-circular.svgz'),
    sign: require('./sign.jpg'),
    error: require('./error.png'),
    nofinance: require('./finance-no-image.jpg'),
    mailsync: require('./mail-sync.png'),
    tickgreen: require('./tick-green.svgz'),
    deployment: require('./deployment.svgz'),
    queueicon: require('./q-ico.png'),
    autogate: require('./autogate.png'),
    titandms: require('./titan-dms.png'),
    imotor: require('./imotor.png'),
    messagemedia: require('./messagemedia.png'),
    axon: require('./axon.png'),
    autotrader: require('./autotrader.png'),
    genericApi: require('./generic-api.png'),
    dealersolutions: require('./dealersolutions.png'),
    drivechat: require('./drivechat.png'),
    erapower: require('./erapower.png'),
    saleslogs: require('./saleslogs.png'),
    print: require('./print-ico.svgz'),
    download: require('./download-ico.svgz'),
    tickCompleted: require('./tick.png'),
    group: require('./group.jpg'),
    mailchimp: require('./mailchimp.png'),
    nylas: require('./nylas.png'),
    adtorqueedge: require('./adtorqueedge.png'),
    appraisal_solutions: require('./appraisal_solutions.png'),
    autoit: require('./auto_it.png'),
    bentley_cab: require('./bentley_cab.png'),
    carsales: require('./carsales.png'),
    conversica: require('./conversica.png'),
    creativo: require('./creativo.png'),
    dubizzle: require('./dubizzle.png'),
    easycars: require('./easycars.png'),
    facebook: require('./facebook.png'),
    autograb: require('./autograb.png'),
    fca_digital: require('./fca_digital.png'),
    ferrari_modis: require('./ferrari_modis.png'),
    gumtree: require('./gumtree.png'),
    heroleads: require('./heroleads.png'),
    mattaki: require('./mattaki.png'),
    motorweb: require('./motorweb.png'),
    porsche_cap: require('./porsche_cap.png'),
    red_book: require('./red_book.png'),
    roi: require('./roi.png'),
    salesforce: require('./salesforce.png'),
    shiftdigital: require('./shiftdigital.png'),
    sms_broadcast: require('./sms_broadcast.png'),
    wildjar: require('./wildjar.png'),
    appstore: require('./appstore.png'),
    appstoreIcon: require('./appstore-icon.png'),
    playstore: require('./playstore.png'),
    playstoreIcon: require('./playstore-icon.png'),
    appDownload: require('./app-download.jpg'),
    editIcon: require('./edit-ico.svgz'),
    plusIcon: require('./plus-ico.svgz'),
    printWhite: require('./print-white.png'),
    downloadBlack: require('./download-black.png'),
    saveWhite: require('./save-white.png'),
    unsubscribe: require('./unsubscribe.svgz'),
    unsubscribed: require('./unsubscribed.svgz'),
    dubicars: require('./dubicars.png'),
    integrations: require('./integrations-thumb.png'),
    lock: require('./license-lock.jpg'),
    odometer: require('./odo-icon.svgz'),
    mfa: require('./mfa.svgz'),
    carsguide: require('./carsguide.png'),
    digitallicence_nsw: require('./service-nsw.png'),
    gubagoo: require('./gubagoo.png'),
    autoforce: require('./autoforce.png'),
    pmds: require('./pmds.png'),
    amsService: require('./ams-service.png'),
    todoempty: require('./oktodo-blank.svgz'),
    todoyes: require('./oktodo-green.svgz'),
    todono: require('./oktodo-red.svgz'),
    closecircular: require('./close-circular.svgz'),
    warningcircular: require('./warning-circular.svgz'),
    amsSales: require('./amsSales.png'),
    radioTick: require('./radio-tick.svgz'),
    radioUnTick: require('./radio-untick.svgz'),
    checkboxTick: require('./checkbox-tick.svgz'),
    checkboxUnTick: require('./checkbox-untick.svgz'),
    addVideo: require('./add-video.png'),
    previewVideo: require('./video-preview.png'),
    shareFacebook: require('./facebook-share.svgz'),
    shareMail: require('./mail-share.svgz'),
    shareTwitter: require('./twitter-share.svgz'),
    smshub: require('./smshub.png'),
    podium: require('./podium.png'),
    campaignMobile: require('./campaign-device-mobile.svgz'),
    campaignMonitor: require('./campaign-device-monitor.svgz'),
    campaignTablet: require('./campaign-device-tablet.svgz'),
    campaignUnknown: require('./campaign-device-unknown.svgz'),
    campaignClick: require('./campaign-status-clicked.svgz'),
    campaignComplain: require('./campaign-status-complained.svgz'),
    campaignOpen: require('./campaign-status-opened.svgz'),
    campaignProfile: require('./campaign-status-profile.svgz'),
    campaignUnOpen: require('./campaign-status-unopened.svgz'),
    campaignRecipients: require('./campaign-status-recipients.svgz'),
    campaignUnSubscribed: require('./campaign-status-unsubscribed.svgz'),
    campaignDelivered: require('./campaign-status-delivered.svgz'),
    campaignFailed: require('./campaign-status-failed.svgz'),
    vision6: require('./vision6.png'),
    nomapImage: require('./no-map-image.jpg'),
    evalexpert: require('./evalexpert.png'),
    evalexpertLogo: require('./evalexpert-logo.png'),
    fusionevents: require('./fusion-events.png'),
    minilogo: require('./mini-logo.jpg'),
    wifioff: require('./offline-ico.png'),
    online: require('./online-ico.svgz'),
    calendarCheckBooked: require('./calendar-check-booked.svgz'),
    afterSales: require('./ams-aftersales.png'),
    porscheLogo: require('./porsche-logo.png'),
    surveyTick: require('./survey-tick.svgz'),
    surveyImg: require('./survey-img.svgz'),
    pagenotFound: require('./not-found.png'),
    driva: require('./driva.svgz'),
    amsme: require('./ams-me.png'),
    surveyImgBlue: require('./survey-img-blue.svgz'),
    safeTag: require('./safe-tag.png'),
    meInvite: require('./me-invite.svgz'),
    meJoined: require('./me-joined.svgz'),
    meSent: require('./me-sent.svgz'),
    meIcon: require('./ams-me.svgz'),
    dollarIcon: require('./dollar-ico.svgz'),
    subscriptionAlert: require('./subscription-alert.svgz'),
    amsProLogoDark: require('./ams-pro-logo-dark.svgz'),
    upgrade: require('./upgrade.svgz'),
    cafe: require('./ams-cafe.png'),
    carExpertLogo: require('./carexpert-logo.svgz'),
    frontView: require('./front-view.png'),
    rearView: require('./rear-view.png'),
    leftView: require('./left-view.png'),
    rightView: require('./right-view.png'),
    leftFrontView: require('./left-front-corner-view.png'),
    leftRearView: require('./left-rear-corner-view.png'),
    rightFrontView: require('./right-front-corner-view.png'),
    rightRearView: require('./right-rear-corner-view.png'),
    odometerView: require('./odometer-view.png'),
    bookletView: require('./service-booklet-view.png'),    
    buildDateView: require('./build-date.png'),
    regExpiryView: require('./reg-expiry.png'),
    frontViewMotorcycle: require('./motorcycle-front-view.png'),
    rearViewMotorcycle: require('./motorcycle-rear-view.png'),
    leftViewMotorcycle: require('./motorcycle-left-view.png'),
    rightViewMotorcycle: require('./motorcycle-right-view.png'),
    leftFrontViewMotorcycle: require('./motorcycle-left-front-corner-view.png'),
    leftRearViewMotorcycle: require('./motorcycle-left-rear-corner-view.png'),
    rightFrontViewMotorcycle: require('./motorcycle-right-front-corner-view.png'),
    rightRearViewMotorcycle: require('./motorcycle-right-rear-corner-view.png'),
    odometerViewMotorcycle: require('./motorcycle-odometer-view.png'),
    bookletViewMotorcycle: require('./service-booklet-view.png'),
    buildDateViewMotorcycle: require('./build-date.png'),
    regExpiryViewMotorcycle: require('./reg-expiry.png'),
    c2native: require('./c2native.png'),
    keyloop: require('./keyloop.png'),
    tradeinPro: require('./ams-tradeinPro.png'),
    gforces: require('./gforces.png'),
    formatImage: require('./format-image.svgz'),
    formatLine: require('./format-line.svgz'),
    formatText: require('./format-textbox.svgz'),
    selectDrag: require('./select-drag.svgz'),
    tvModule: require('./tv-device.png'),
    performanceReview: require('./performance-review.png'),
    roster: require('./roster.png'),
    timerOut: require('./timer-outline-circle.svgz'),
    gdpr: require('./gdpr.png'),
    data_deletion: require('./data-deletion.svgz'),
    cmdotcom: require('./cm.png'),
    humm: require('./humm.png'),
    afterpay: require('./afterpay.png'),
    openpay_logo: require('./openpay-logo.png'),
    bpay_logo: require('./bpay-logo.svgz'),
    edit_black: require('./edit-black.png'),
    invoice: require('./invoice.png'),
    mailup: require('./mailup.png'),
    mailjet: require('./mailjet.png'),
    aircall: require('./aircall.png'),
    aircallIcon: require('./aircall.svgz'),
    ringcentral: require('./ringcentral.png'),
    ringcentralIcon: require('./ringcentral.svgz'),
    ppsr: require('./ppsr.png'),
    ppsr_logo: require('./ppsr-logo-icon.svgz'),
    eventsPro: require('./ams-events-pro.png'),
    template: require('./template.svgz'),
    templatesIcon: require('./templates-image.jpg'),
    noEvent: require('./no-event-image.jpg'),
    alertInfo: require('./info-circular.svgz'),
    d365: require('./d365.png'),
    autograb_logo: require('./autograb-logo.svgz'),
    rego_search: require('./rego-search.png'),
    mclaren_logo: require('./mclaren-logo.png'),
    ti_bodytype: require('./ui-body-type.svgz'),
    ti_bodytypeMotorcycle: require('./motorcycle-ui-body-type.svgz'),
    ti_engine: require('./ui-engine.svgz'),
    ti_odometer: require('./ui-odometer.svgz'),
    ti_tickGreen: require('./ui-tick-green.svgz'),
    ti_tickWhite: require('./ui-tick-white.svgz'),
    ti_tickBlue: require('./ui-tick-blue.svgz'),
    ti_transmission: require('./ui-transmission.svgz'),
    link_share: require('./link-share.svgz'),
    singlelink_landing_bg: require('./singlelink-landing-bg.jpg'),
    fleetPro: require('./ams-fleet-pro.png'),
    eventClosed: require('./eventClosed.svgz'),
    emailCampaign: require('./ams-email-campaign.png'),
    novehiclePortables: require('./no-vehicle-portables.jpg'),
    justcall: require('./justcall.png'),
    justcallIcon: require('./justcall-icon.svgz'),
    ti_dealer: require('./ui-dealers.svgz'),
    arrow_above_red: require('./above-arrow-red.svgz'),
    arrow_below_green: require('./below-arrow-green.svgz'),
    tune: require('./tune.png'),
    arrow_down_red: require('./arrow-down-red.svgz'),
    arrow_up_green: require('./arrow-up-green.svgz'),
    leadAge: require('./lead-age.svgz'),
    emailIntg: require('./emailIntg.png'),
    impel: require('./impel.png'),
    a_i: require('./ai.svgz'),
    qrcodescanner: require('./qr-code-scanner.svgz'),
    googleauthenticatorappstore: require('./google-authenticator-appstore.png'),
    googleauthenticatorplaystore: require('./google-authenticator-playstore.png'),
    microsoftauthenticatorappstore: require('./microsoft-authenticator-appstore.png'),
    microsoftauthenticatorplaystore: require('./microsoft-authenticator-playstore.png'),
    microsoftauthenticator: require('./microsoft-authenticator.svgz'),
    googleauthenticator: require('./Google-Authenticator.svgz'),
    mutifactorshield: require('./mutifactor-shield.svgz'),
    googlesheet: require('./googlesheet.png'),
    serviceFleetPro: require('./servicefleetPro.png'),
    tss: require('./tss.png'),
};

export default images;
