import React, { useState, useMemo, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Papa from 'papaparse'
import _ from 'lodash';
import { Progress } from 'antd';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'

import { ReactSelect, ReactMultiSelect } from '../../../../components';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import downloadFile from './import_sms_contact.csv'
import { useDropzone } from 'react-dropzone';
import { levelOptions } from './viewModel'

const ImportCSV = props => {
	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({
		maxFiles: 1,
	});

	const [errorFields, setErrorFields] = useState({});
	const [file, setFile] = useState(null);
	const [recipientsToBeSet, setRecipientsToBeSet] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isFileUploaded, setIsFileUploaded] = useState(false);
	const [progress, setProgress] = useState(0);
	const [data, setData] = useState({
		name: '',
		size: '',
	});
	const [lookup, setLookup] = useState({})

	useEffect(() => {
		if (_.isEmpty(acceptedFiles))
			return

		const validateFiles = files => {
			let filteredFiles = [];
			acceptedFiles.forEach(file => {
				var _size = file.size;
				var filesize = _size / 1024 / 1024;
				if (filesize > 10) {
					Swal.fire(CommonHelper.showLocale(props, 'Maximum file size 10MB.'), '', 'info')
				} else {
					filteredFiles.push(file)
				}
			})

			if (filteredFiles.length > 0) {
				onSelectFile(filteredFiles)
			}
		}
		validateFiles(acceptedFiles)
	}, [acceptedFiles])

	const handleFileRemove = () => {
		setFile(null)
		setRecipientsToBeSet([])
		setIsLoading(false)
		setIsFileUploaded(false)
		setProgress(0)
		setData({
			name: '',
			size: '',
		})
	};

	const onSelectFile = (files) => {
		if (!files) {
			return
		}
		if (files && files.length > 0) {
			if (!files[0].name.match(/.(csv)$/i)) {
				Swal.fire(CommonHelper.showLocale(props, 'File format not supported. Please select CSV file.'), '', 'info')
				return;
			}
		}
		setIsLoading(true)
		let addedFile = files[0];

		setFile(addedFile)
		setData({ ...data, name: addedFile.name, size: addedFile.size })
		setIsFileUploaded(true)
		Papa.parse(addedFile, {
			complete: updateRecipientsWithCSVData,
			header: true
		});
	}

	const updateRecipientsWithCSVData = (result) => {
		if (!result) {
			return
		}
		let data = result.data;
		console.log('some', data);

		let selectedContactsArr = data
			.map(contact => {
				return {
					partName: contact.PartName ? contact.PartName : contact['Part Name'] ? contact['Part Name'] : '',
					partCost: contact.PartCost ? contact.PartCost : contact['Part Cost'] ? contact['Part Cost'] : '',
					partNumber: contact.PartNumber ? contact.PartNumber : contact['Part Number'] ? contact['Part Number'] : '',
					documentID: `${props.dealersettings.oemID}-${contact.PartNumber ? contact.PartNumber : contact['Part Number'] ? contact['Part Number'] : ''}`,
					oemID: props.dealersettings.oemID,
					addedBy: localStorage.uid,
					isDeleted: false,
					active: true,
					make: contact.Make ? contact.Make : contact['Make'] ? contact['Make'] : '',
					model: contact.Model ? contact.Model : contact['Model'] ? contact['Model'] : '',
				};
			});

		selectedContactsArr = selectedContactsArr.filter(a => a.partName && a.partCost && a.partNumber)

		setRecipientsToBeSet(selectedContactsArr)

		setTimeout(() => {
			if (progress !== 100) {
				setProgress(progress + 50)
				setTimeout(() => {
					setProgress(100)
					setIsLoading(false)
					// toast.notify('File added successfully', {
					//     duration: 2000
					// })
				}, 500)
			}
		}, 500)
	}

	const importContacts = () => {
		if (recipientsToBeSet.length > 0 && lookup.level && !_.isEmpty(lookup.levelID)) {
			props.handleClose(recipientsToBeSet, lookup.level, lookup.levelID)
		} else {
			if (!recipientsToBeSet.length > 0) {
				toast.notify('No parts file added yet', {
					duration: 2000
				})
			}

			if (!lookup.level) {
				setErrorFields({
					...errorFields,
					level: 'input_error'
				});
			}

			if (_.isEmpty(lookup.levelID)) {
				setErrorFields({
					...errorFields,
					levelID: 'input_error'
				});
			}
		}
	}

	const handleSelectChange = (e, data) => {
		console.log('handleSelectChange', e, data)
		if (data.name === 'level') {
			setLookup({
				...lookup,
				[data.name]: e.value,
				['levelName']: (data.name === 'level' && e.value === 'oem') ? props.dealersettings.settingName : data.name === 'levelID' ? e.data.name : '',
				['levelID']: (data.name === 'level' && e.value === 'oem') ? [props.dealersettings.oemID] : '',
				['clientIDs']: ''
			});
		}
		else {
			setLookup({
				...lookup,
				[data.name]: e.value,
				['levelName']: (data.name === 'level' && e.value === 'oem') ? props.dealersettings.settingName : data.name === 'levelID' ? e.data.name : '',
				['clientIDs']: e.clientIDs
			});
		}
		setErrorFields({
			...errorFields,
			[data.name]: ''
		});
	}

	const handleReactMultiSelectChange = (selectedOptions, name) => {
		console.log('handleReactMultiSelectChange', selectedOptions, name)
		// return 
		const value = [];
		!_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
			value.push(data.value)
		})

		setLookup({
			...lookup,
			[name]: value
		});

		setErrorFields({
			...errorFields,
			[name]: ''
		});
	}

	const baseStyle = {
		width: '100%',
		background: '#f2f7ff',
		border: '2px dashed #a7cbff',
		minHeight: '180px',
		float: 'left',
		textDecoration: 'none',
		color: '#4466f2',
	};

	const activeStyle = {
		border: '2px dashed #2bb673',
	};

	const acceptStyle = {
		borderColor: '#2bb673',
	};

	const rejectStyle = {
		borderColor: '#ff1744'
	};

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject,
		isDragAccept
	]);

	return (
		<Modal
			show={props.show}
			onHide={props.handleClose}
			backdrop='static'
			backdropClassName={props.show ? 'overlay-modal active' : ''}
			dialogClassName='modal-dialog-centered modal-file-manager'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title'>
						{' '}
						<Translate text={'Import CSV'} />
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='upload-file-box mt-2'>
							<div {...getRootProps({ style, className: 'upload-iconwrap upload-box-dragndrop' })}>
								<input {...getInputProps()} />
								<i className="ico icon-files-upload"></i>
								<p><Translate text={"Drag 'n' drop csv file here, or click to upload file"}/></p>
							</div>

							{isFileUploaded ? (
								<div className='uploaded-list-video'>
									<div className='flex-upload'>
										<div className='upload-clear'>
											<a
												href='#'
												onClick={e => handleFileRemove(e)}
												className={`${isLoading ? 'form-disable' : ''}`}
											>
												<i className='fa fa-times' aria-hidden='true'></i>
											</a>
										</div>
										<div className='file-upload-icon'>
											<i className="ico icon-files-excel"></i>
										</div>
										<div className='upload-data'>
											{data.name}
											{' (' + CommonHelper.showFileSize(data.size) + ')'}
										</div>
									</div>
									{isLoading ? <Progress percent={progress} /> : <></>}
								</div>
							) : (
								<></>
							)}
						</div>
						<div className="form-style w-100 mt-2">
							<div className="form-row">
								<div className="form-group col-md-12">
									<label > <Translate text={'Level'} /></label>
									<ReactSelect
										options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
											levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
										name={"level"}
										placeholder={'select level'}
										onChange={handleSelectChange}
										value={lookup.level}
										classNamePrefix={`${''} ${errorFields["level"]} basic-select`}
										removeClearable={true}
									// isDisabled={!newLookup}
									>
									</ReactSelect>
								</div>


								{
									(!_.isEmpty(lookup.level) && lookup.level !== 'oem') ? (<div className="form-group col-md-12">
										<label ><Translate text={lookup.level === 'group' ? 'Group' : lookup.level === 'region' ? 'Region' : 'client'} /></label>
										<ReactMultiSelect
											options={lookup.level === 'group' ? props.groupOptions : props.clientOptions}
											name={'levelID'}
											placeholder={`select ${lookup.level === 'individual' ? 'client' : lookup.level}`}
											onChange={(rec) => { handleReactMultiSelectChange(rec, 'levelID') }}
											value={lookup.levelID ? _.isArray(lookup.levelID) ? lookup.levelID : [lookup.levelID] : []}
											classNamePrefix={`${''}  ${errorFields["levelID"]} basic-select`}
											removeClearable={true}
											// isDisabled={!newLookup}
											isMulti={true}
											isSearchable={true}
										>
										</ReactMultiSelect>
									</div>) : (<></>)
								}
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<button
					type='button'
					className={`btn btn-primary float-right`}
					onClick={importContacts}
				>
					<Translate text={'Import'} />
				</button>
				<a
					href={downloadFile}
					download='import_part_file.csv'
					className='btn btn-default float-left'
				>
					<Translate text={'Download Sample'} />
				</a>
			</Modal.Footer>
		</Modal>
	);
};

export default ImportCSV;
