import React from "react";

export const reportVM = {
    documentID: null,
    clientID: null,
    userIDs: [],
    name: '',
    pipeline: '',
    type: '',
    subType: '',
    calculationType: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
};


export const reportOptions = [
    { active: true, value: 'walkintestdrive', label: 'Walk In to Test Drive' },
    { active: true, value: 'enquiryOptionsDF', label: 'KPIs' },
    { active: true, value: 'salesConversion', label: 'Sales Conversion' },
]

export const salesConversions = [
    { active: true, value: 'appointment', label: 'Appointment' },
    { active: true, value: 'call', label: 'Call' },
    { active: true, value: 'email', label: 'Email' },
    { active: true, value: 'walkin', label: 'Walk Ins' },
]

export const calculateOptions = [
    { active: true, value: 'count', label: 'Count' },
    { active: true, value: 'duration', label: 'Duration' },
    { active: true, value: 'percentage', label: 'Percentage' }
]

export const datefilterType = [
    { active: true, value: 'daily', label: 'Daily' },
    { active: true, value: 'weekly', label: 'Weekly' },
    { active: true, value: 'monthly', label: 'Monthly' }
]

export const modelGroupTypes = [
    { active: true, value: 'make', label: 'By Make' },
    { active: true, value: 'model', label: 'By Model' },
    { active: true, value: 'type', label: 'By Type' },
    { active: true, value: 'group', label: 'By Group' }
]

export const mandatoryFields = [
    'name',
    'type',
    'subType',
    'calculationType',
    'userIDs'
];

export const leadTypes = [
    { value: 'new', label: 'New Leads Only' },
    { value: 'existing', label: 'Existing Leads Only' }
]

export const reportColumns = [
    {
        name: 'Walk Ins',
        value: 'walkin',
        type: 'walkintestdrive',

    },
    {
        name: 'Test Drives',
        value: 'testdrive',
        type: 'walkintestdrive'
    },
    {
        name: 'Walk In Test Drive Ratio',
        value: 'walkintestdrive',
        type: 'walkintestdrive',
        percentage: true
    },
    { type: 'salesConversion', value: 'appointment', name: 'Appointment', dynamic: true },
    { type: 'salesConversion', value: 'call', name: 'Call', dynamic: true },
    { type: 'salesConversion', value: 'email', name: 'Email', dynamic: true },
    { type: 'salesConversion', value: 'walkin', name: 'Walk Ins', dynamic: true },
    { type: 'salesConversion', value: 'totalLeads', name: 'Total', dynamic: false, cls: 'table-middle-total' },
    { type: 'salesConversion', value: 'won', name: 'Won', dynamic: false },
    { type: 'salesConversion', value: 'wonConv', name: 'Won Conv.', dynamic: false, percentage: true },
]


export const reportGroupBy = [
    { label: 'Client', value: 'client', displayName: 'Client', displayValue: 'Client', tableHeader: 'Client' },
    { label: 'User', value: 'user', displayName: 'User', displayValue: 'User', tableHeader: 'User' },
    { label: 'Finance Business Manager', value: 'financeBM', displayName: 'Finance Business Manager', displayValue: 'Finance Business Manager', tableHeader: 'Finance Business Manager' },
    { label: 'After Market Manager', value: 'afterMarketBM', displayName: 'After Market Manager', displayValue: 'After Market Manager', tableHeader: 'After Market Manager' },
    { label: 'Inbound User', value: 'inboundUser', displayName: 'Inbound User', displayValue: 'Inbound User', tableHeader: 'Inbound User' },
    { label: 'Lead Created By', value: 'enqAddedBy', displayName: 'Lead Created By', displayValue: 'Lead Created By', tableHeader: 'Lead Created By' },
    { label: 'Activity Created By', value: 'actAddedBy', displayName: 'Activity Created By', displayValue: 'Activity Created By', tableHeader: 'Activity Created By' },
    { label: 'Enquiry Status', value: 'enquiryStatus', displayName: 'Enquiry Status', displayValue: 'enquiry status', tableHeader: 'Enquiry Status' },
    { label: 'Enquiry Type', value: 'enquiryTypes', displayName: 'Enquiry Type', displayValue: 'enquiry type', tableHeader: 'Enquiry Type' },
    { label: 'Campaign', value: 'campaigns', displayName: 'Campaign', displayValue: 'campaign', tableHeader: 'Campaign' },
    { label: 'Origin', value: 'origins', displayName: 'Origin', displayValue: 'origin', tableHeader: 'Origin' },
    { label: 'Lead Source', value: 'leadSource', displayName: 'Lead Source', displayValue: 'lead source', tableHeader: 'Lead Source' },
    { label: 'Pipelines', value: 'pipelines', displayName: 'Pipelines', displayValue: 'pipelines', tableHeader: 'Pipelines' },
    { label: 'Lost Reason', value: 'lostReasons', displayName: 'Lost Reason', displayValue: 'lost reason', tableHeader: 'Lost Reason' },
    { label: 'Model', value: 'model', displayName: 'Make', displayValue: 'make', tableHeader: 'Model' },
    { label: 'Sales Type', value: 'salesType', displayName: 'Sales Type', displayValue: 'sales type', tableHeader: 'Sales Type', type: 'stock' },
    { label: 'Date Filter', value: 'dateFilter', displayName: 'Date Filter', displayValue: 'date filter', tableHeader: 'Date' },
    { label: 'Data Quality', value: 'dataQuality', displayName: 'Data Quality', displayValue: 'data quality', tableHeader: 'Data Quality' },
    { label: 'Inbound Source', value: 'inboundSource', displayName: 'Inbound Source', displayValue: 'inbound source', tableHeader: 'Name' },
]

export const reportFilters = [
    { label: 'User', value: 'user', displayValue: 'user' },
    { label: 'Inbound User', value: 'inboundUser', displayValue: 'inbound user' },
    { label: 'Lead Created By', value: 'enqAddedBy', displayValue: 'Lead Created By' },
    { label: 'Finance Business Manager', value: 'financeBM', displayValue: 'Finance Business Manager' },
    { label: 'After Market Manager', value: 'afterMarketBM', displayValue: 'After Market Manager' },
    { label: 'Pipeline', value: 'pipelines', displayValue: 'pipeline' },
    { label: 'Enquiry Type', value: 'enquiryTypes', displayValue: 'enquiry type' },
    { label: 'Campaign', value: 'campaigns', displayValue: 'campaign' },
    { label: 'Origin', value: 'origins', displayValue: 'origin' },
    { label: 'Status', value: 'status', displayValue: 'status' },
    { label: 'Finance Status', value: 'financeStatus', displayValue: 'finance status' },
    { label: 'After Market Status', value: 'afterMarketStatus', displayValue: 'After Market status' },
    { label: 'Label', value: 'label', displayValue: 'label' },
    { label: 'Inbound Source', value: 'inboundSource', displayValue: 'inbound source' },
    { label: 'Lost Reason', value: 'lostReasons', displayValue: 'lost reasons' },
    { label: 'Make and Models', value: 'makeModels', displayValue: 'make' },
    { label: 'Sales Type', value: 'salesType', displayValue: 'sales type' },
    { label: 'Lead Type', value: 'leadType', displayValue: 'lead type' },
    { label: 'Triggers', value: 'triggers', displayValue: 'trigger' },
    { label: 'Client', value: 'client', displayValue: 'client' },
    { label: 'Service Packages', value: 'servicePackages', displayValue: 'service package' },
    { label: 'Lead Source', value: 'leadSource', displayValue: 'lead source' },
]

export const filterOptions = [
    { label: 'Date', value: 'date', displayValue: 'date', type: 'stock' },
    { label: 'User', value: 'user', displayValue: 'user' },
    { label: 'Inbound User', value: 'inboundUser', displayValue: 'inbound user' },
    { label: 'Lead Created By', value: 'enqAddedBy', displayValue: 'Lead Created By' },
    { label: 'Finance Business Manager', value: 'financeBM', displayValue: 'Finance Business Manager' },
    { label: 'After Market Manager', value: 'afterMarketBM', displayValue: 'After Market Manager' },
    { label: 'Enquiry Type', value: 'enquiryTypes', displayValue: 'enquiry type' },
    { label: 'Campaign', value: 'campaigns', displayValue: 'campaign' },
    { label: 'Origin', value: 'origins', displayValue: 'origin' },
    { label: 'Status', value: 'status', displayValue: 'status' },
    { label: 'Finance Status', value: 'financeStatus', displayValue: 'finance status' },
    { label: 'After Market Status', value: 'afterMarketStatus', displayValue: 'After Market status' },
    { label: 'Label', value: 'label', displayValue: 'label' },
    { label: 'Inbound Source', value: 'inboundSource', displayValue: 'inbound source' },
    { label: 'Lost Reason', value: 'lostReasons', displayValue: 'lost reasons' },
    { label: 'Make', value: 'makeModels', displayValue: 'make', type: 'stock' },
    { label: 'Sales Type', value: 'salesType', displayValue: 'sales type', type: 'stock' },
    { label: 'Lead Type', value: 'leadType', displayValue: 'lead type' },
    { label: 'Triggers', value: 'triggers', displayValue: 'trigger' },
    { label: 'Service Packages', value: 'servicePackages', displayValue: 'service package' },
    { label: 'Lead Source', value: 'leadSource', displayValue: 'lead source' },
    { label: 'Call Status', value: 'callStatus', displayValue: 'call status' },
    { label: 'Call Type', value: 'callType', displayValue: 'call type' },
    { label: 'Contact Area Code', value: 'areaCode', displayValue: 'area code' },
    { label: 'Pipeline', value: 'pipelines', displayValue: 'pipelines' },
    { label: 'Include Stage History', value: 'includeStageHistory', displayValue: 'include stage history' },
    { label: 'Files', value: 'files', displayValue: 'files' },
]

export const labels = [
    { active: true, value: 'hot', label: <div className={`badge badge-pill badge-hot`}>HOT</div> },
    { active: true, value: 'warm', label: <div className={`badge badge-pill badge-warm`}>WARM</div> },
    { active: true, value: 'cold', label: <div className={`badge badge-pill badge-cold`}>COLD</div> }
]
export const enquiryStatus = [
    { active: true, value: 'open', label: <div className={`badge badge-pill badge-status-open`}>OPEN</div> },
    { active: true, value: 'won', label: <div className={`badge badge-pill badge-status-won`}>WON</div> },
    { active: true, value: 'delivered', label: <div className={`badge badge-pill badge-status-delivered`}>DELIVERED</div> },
    { active: true, value: 'lost', label: <div className={`badge badge-pill badge-status-lost`}>LOST</div> }
]

export const visibilities = [
    { active: true, value: 'private', label: <><i className="ico icon-reserve mr-1"></i> Private</> },
    { active: true, value: 'shared', label: <><i className="ico icon-edit-circle mr-1"></i> Shared - anyone can edit</> },
    { active: true, value: 'sharedView', label: <><i className="ico icon-activity-meeting mr-1"></i> Shared - only owner can edit</> },
]

export const testdriveOptions = [
    { active: true, value: 'enqOwner', label: 'Enquiry Owner' },
    { active: true, value: 'tdOwner', label: 'Test Drive Owner' },
]

export const columnsAction = [
    { active: true, value: 'add', label: 'Add' },
    { active: true, value: 'percentage', label: 'Percentage' },
]


export const reportDynColumns = [
    //end
    { label: 'New Enquiries', value: 'newLeads', name: 'New Enquiries', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#4967CC', columnType: 'sales', reportType: ['salesProgress', 'leadKpi', 'inboundleadProgress', 'salesPerformance'], checkoutDefault: true },
    { label: 'Existing Enquiries', value: 'exisitngLeads', name: 'Existing Enquiries', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#9294F8', columnType: 'sales', reportType: ['salesProgress', 'leadKpi', 'inboundleadProgress', 'salesPerformance'] },
    { label: 'Total Enquiries', value: 'total', name: 'Total Enquiries', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#1036A0', columnType: 'sales', reportType: ['salesProgress', 'leadKpi', 'inboundleadProgress', 'salesPerformance'] },
    { label: 'Open', value: 'open', name: 'Open', status: true, view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#F7931E', reportType: ['salesProgress', 'leadKpi', 'inboundleadProgress', 'salesPerformance'] },
    { label: 'Quotes Generated', value: 'quotes', name: 'Quotes Generated', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#39B54A', columnType: 'service', reportType: ['salesPerformance'] },
    { label: 'Quotes Amount', value: 'quoteAmount', name: 'Quotes Amount', view: ['table', 'column', 'pie', 'target'], calculationType: ['amount'], color: '#39B54A', columnType: 'service', currency: true, reportType: ['salesPerformance'] },
    { label: 'Won', value: 'won', name: 'Won', status: true, view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#39B54A', reportType: ['salesProgress', 'leadKpi', 'inboundleadProgress', 'salesPerformance'], checkoutDefault: true },
    { label: 'Won Amount', value: 'wonAmount', name: 'Won Amount', view: ['table', 'column', 'pie', 'target'], calculationType: ['amount'], color: '#39B54A', columnType: 'service', currency: true, reportType: ['salesPerformance'] },
    { label: 'Delivered', value: 'delivered', status: true, name: 'Delivered', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#049B8C', reportType: ['salesProgress', 'leadKpi', 'salesPerformance'], checkoutDefault: true },
    { label: 'Lost', value: 'lost', name: 'Lost', status: true, view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#EF4136', reportType: ['salesProgress', 'leadKpi', 'inboundleadProgress', 'salesPerformance'], checkoutDefault: true, },
    { label: 'New Showroom Walk Ins', value: 'newwalkin', name: 'New Showroom Walk Ins', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#DB4F9B', columnType: 'sales', reportType: ['salesProgress', 'salesPerformance'] },
    { label: 'Repeat Showroom Walk Ins', value: 'repeatwalkin', name: 'Repeat Showroom Walk Ins', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#FFAE64', columnType: 'sales', reportType: ['salesProgress', 'salesPerformance'] },
    { label: 'Total Showroom Walk Ins', value: 'walkin', name: 'Total Showroom Walk Ins', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#003F5C', columnType: 'sales', reportType: ['salesProgress', 'leadKpi', 'salesPerformance'] },
    { label: 'Test Drive', value: 'testdrive', name: 'Test Drive', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'testdrive', color: '#29ABE2', reportType: ['salesProgress', 'leadKpi', 'inboundleadProgress', 'salesPerformance'], checkoutDefault: true },
    { label: 'Carry Over Leads', value: 'carryover', name: 'Carry Over Leads', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#f18e8a', reportType: ['salesProgress', 'leadKpi', 'inboundleadProgress'] },
    { label: 'Status Modified', value: 'statusModified', name: 'Status Modified', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#F7931E' },
    { label: 'Test Drive to Won', value: 'wonTD', name: 'Test Drive to Won', status: true, view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#39B54A' },

    //lead bucket
    { label: 'Total Inbound', value: 'totalInbound', name: 'Total Inbound', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#1036A0', columnType: 'inbound', reportType: ['inboundleadProgress', 'leadKpi'] },
    { label: 'Overall Pending', value: 'overallPending', name: 'Overall Pending', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#9294F8', columnType: 'inbound', reportType: ['inboundleadProgress', 'leadKpi'] },
    { label: 'Pending Prospects', value: 'pendingLeads', name: 'Pending Prospects', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#f18e8a', reportType: ['inboundleadProgress', 'leadKpi'] },
    { label: 'Converted Prospects', value: 'convertedLeads', name: 'Converted Prospects', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#4967cc', columnType: 'inbound', reportType: ['inboundleadProgress'] },
    { label: 'Prospect Lost', value: 'prospectLost', name: 'Prospect Lost', status: true, view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#EF4136', columnType: 'inbound' },
    { label: 'Lead Lost', value: 'leadLost', name: 'Lead Lost', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#EF4136', columnType: 'inbound' },
    { label: 'Total Lost', value: 'totalLost', name: 'Total Lost', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#EF4136', columnType: 'inbound' },
    { label: 'Avg. Lead Response Time', value: 'responseTime', name: 'Avg. Lead Response Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#DB4F9B', average: true, columnType: 'inbound' },
    { label: 'Max. Lead Response Time', value: 'maxresponseTime', name: 'Max. Lead Response Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#FFAE64', average: true, columnType: 'inbound' },
    { label: 'Min. Lead Response Time', value: 'minresponseTime', name: 'Min. Lead Response Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#5D9B40', average: true, columnType: 'inbound' },

    { label: 'Avg. Lead Allocated Time', value: 'allocatedTime', name: 'Avg. Lead Allocated Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#DB4F9B', average: true, columnType: 'inbound' },
    { label: 'Max. Lead Allocated Time', value: 'maxallocatedTime', name: 'Max. Lead Allocated Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#FFAE64', average: true, columnType: 'inbound' },
    { label: 'Min. Lead Allocated Time', value: 'minallocatedTime', name: 'Min. Lead Allocated Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#5D9B40', average: true, columnType: 'inbound' },

    { label: 'Avg. Contacted Time', value: 'contactedTime', name: 'Avg. Lead Contacted Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#DB4F9B', average: true, columnType: 'inbound' },
    { label: 'Max. Contacted Time', value: 'maxcontactedTime', name: 'Max. Lead Contacted Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#FFAE64', average: true, columnType: 'inbound' },
    { label: 'Min. Contacted Time', value: 'mincontactedTime', name: 'Min. Lead Contacted Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#5D9B40', average: true, columnType: 'inbound' },

    //trade in
    { label: 'Trade In', value: 'tradein', name: 'Trade In', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'tradein', color: '#DB4F9B', columnType: 'sales', reportType: ['salesPerformance'] },
    { label: 'Trade In InProgress', value: 'tradeininprogress', name: 'Trade In InProgress', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'tradein', color: '#FFAE64', columnType: 'sales', reportType: ['salesPerformance'] },
    { label: 'Trade In Completed', value: 'tradeincompleted', name: 'Trade In Completed', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'tradein', color: '#5D9B40', columnType: 'sales', reportType: ['salesPerformance'] },
    { label: 'Trade In Won', value: 'tradeinwon', name: 'Trade In Won', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'tradein', color: '#14D175', columnType: 'sales', reportType: ['salesPerformance'] },
    { label: 'Trade In Lost', value: 'tradeinlost', name: 'Trade In Lost', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'tradein', color: '#FF6361', columnType: 'sales', reportType: ['salesPerformance'] },
    { label: 'Inbound Call Log', value: 'inboundCall', name: 'Inbound Call Log', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'log', color: '#8E8EBC', reportType: ['salesPerformance'] },
    { label: 'Outbound Call Log', value: 'outboundCall', name: 'Outbound Call Log', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'log', color: '#619AAF', reportType: ['salesPerformance'] },
    { label: 'Total Call Log', value: 'totalCall', name: 'Total Call Log', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'log', color: '#7EA880', reportType: ['salesPerformance'] },
    { label: 'SMS Log', value: 'messageLog', name: 'SMS Log', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'log', color: '#7989AA', reportType: ['salesPerformance'] },
    { label: 'Email Log', value: 'emailLog', name: 'Email Log', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'log', color: '#5DA098', reportType: ['salesPerformance'] },
    { label: 'Notes', value: 'noteLog', name: 'Notes', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'log', color: '#7FD3D3', reportType: ['salesPerformance'] },

    { label: 'Appointment Scheduled', value: 'appointmentScheduled', name: 'Appointment Scheduled', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'scheduleActivity', color: '#8E8EBC', reportType: ['salesPerformance'] },
    { label: 'Test Drive Scheduled', value: 'testdriveScheduled', name: 'Test Drive Scheduled', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'scheduleActivity', color: '#619AAF', reportType: ['salesPerformance'] },
    { label: 'Call Scheduled', value: 'callScheduled', name: 'Call Scheduled', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'scheduleActivity', color: '#7EA880', reportType: ['salesPerformance'] },
    { label: 'Mail Scheduled', value: 'mailScheduled', name: 'Mail Scheduled', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'scheduleActivity', color: '#7989AA', reportType: ['salesPerformance'] },
    { label: 'Delivery Scheduled', value: 'deliveryScheduled', name: 'Delivery Scheduled', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'scheduleActivity', color: '#5DA098', reportType: ['salesPerformance'] },

    { label: 'Appointment Done', value: 'appointment', name: 'Appointment Done', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#8E8EBC', reportType: ['salesPerformance'] },
    { label: 'Test Drive Activity Done', value: 'testdriveActivity', name: 'Test Drive Activity Done', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#619AAF', reportType: ['salesPerformance'] },
    { label: 'Call Activity Done', value: 'callActivity', name: 'Call Activity Done', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#7EA880', reportType: ['salesPerformance'] },
    { label: 'Mail Activity Done', value: 'mailActivity', name: 'Mail Activity Done', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#7989AA', reportType: ['salesPerformance'] },
    { label: 'Delivery Activity Done', value: 'deliveryActivity', name: 'Delivery Activity Done', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#5DA098', reportType: ['salesPerformance'] },
    { label: 'Appointment to Test Drive Done', value: 'tdappt', name: 'Appointment to Test Drive Done', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#7FD3D3', columnType: 'sales', reportType: ['salesPerformance'] },
    { label: 'Test Drive Activity to Test Drive Done', value: 'tdtestdrive', name: 'Test Drive Activity to Test Drive Done', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#7AD3CB', columnType: 'sales', reportType: ['salesPerformance'] },

    { label: 'Leads Without Activity', value: 'noActivity', name: 'Leads Without Activity', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#8E8EBC' },
    { label: 'Leads Without Notes', value: 'noNotes', name: 'Leads Without Notes', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#8E8EBC' },
    { label: 'Leads Without Call Logs', value: 'noCalls', name: 'Leads Without Call Logs', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#8E8EBC' },
    { label: 'Leads Without SMS Logs', value: 'noMessage', name: 'Leads Without SMS Logs', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#8E8EBC' },
    { label: 'Unactioned Leads', value: 'unactionedLeads', name: 'Unactioned Leads', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'sales', color: '#8E8EBC' },

    { label: 'Completed Activity', value: 'doneActivity', name: 'Completed Activity', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#8E8EBC', reportType: ['salesPerformance'] },
    { label: 'Overdue Activity', value: 'overdueActivity', name: 'Overdue Activity', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], type: 'activity', color: '#7FD3D3', reportType: ['salesPerformance'] },

    { label: 'Show', value: 'show', name: 'Show', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#34c68d' },
    { label: 'No Show', value: 'noshow', name: 'No Show', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#f97863' },
    { label: 'Show Conv. Ratio', value: 'showConv', name: 'Show Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, color: '#B4C10A' },
    { label: 'No Show Conv. Ratio', value: 'noshowConv', name: 'No Show Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, color: '#00D2E6' },

    { label: 'Walk In Test Drive Conv. Ratio', value: 'walkintestdrive', name: 'Walk In Test Drive Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, color: '#5BCDF4', columnType: 'sales', reportType: ['salesProgress', 'leadKpi', 'salesPerformance'] },
    { label: 'Walk In Won Conv. Ratio', value: 'walkinsold', name: 'Walk In Won Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, color: '#B4C10A', columnType: 'sales', reportType: ['salesProgress', 'leadKpi', 'salesPerformance'] },
    { label: 'Appointment Test Drive Conv. Ratio', value: 'appointmenttestdrive', name: 'Appointment Test Drive Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, type: 'activity', color: '#00D2E6', columnType: 'sales', reportType: ['salesPerformance'] },
    { label: 'Appointment Won Conv. Ratio', value: 'appointmentsold', name: 'Appointment Won Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, type: 'activity', color: '#CDB100', columnType: 'sales', reportType: ['salesPerformance'] },
    { label: 'Test Drive Activity to Test Drive Conv. Ratio', value: 'testdrivetotestdrive', name: 'Test Drive Activity to Test Drive Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, type: 'activity', color: '#00D2E6', columnType: 'sales', reportType: ['salesPerformance'] },
    { label: 'Test Drive Conv. Ratio', value: 'testdriveConv', name: 'Test Drive Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, color: '#00BFC3', reportType: ['salesProgress', 'leadKpi', 'inboundleadProgress', 'salesPerformance'] },
    { label: 'Trade In Won Conv. Ratio', value: 'tradeinwonConv', name: 'Trade In Won Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, color: '#9DBF0C', columnType: 'sales', reportType: ['salesPerformance'] },
    { label: 'Won Conv. Ratio', value: 'wonConv', name: 'Won Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, color: '#5FB637', reportType: ['salesProgress', 'leadKpi', 'inboundleadProgress', 'salesPerformance'] },
    { label: 'Scheduled Conv. Ratio', value: 'scheduledConv', name: 'Scheduled Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, columnType: 'inbound', color: '#5BCDF4' },
    { label: 'Converted Conv. Ratio', value: 'convertedConv', name: 'Converted Conv. Ratio', view: ['table', 'column', 'target'], calculationType: ['percentage'], percentage: true, columnType: 'inbound', color: '#CDB100' },
    //data quality new lead convertion
    { label: 'New Enquiries', value: 'newLeadsConv', name: 'New Enquiries', view: ['column', 'target'], calculationType: ['dqPercentage'], percentage: true, color: '#5BCDF4', dqConv: true, fieldValue: 'newLeads' },
    { label: 'Existing Enquiries', value: 'exisitngLeadsConv', name: 'Existing Enquiries', view: ['column', 'target'], calculationType: ['dqPercentage'], percentage: true, color: '#B4C10A', dqConv: true, fieldValue: 'exisitngLeads' },
    { label: 'Total Enquiries', value: 'totalConv', name: 'Total Enquiries', view: ['column', 'target'], calculationType: ['dqPercentage'], percentage: true, color: '#00D2E6', dqConv: true, fieldValue: 'total' },

    //stock
    { label: 'Total Stock', value: 'totalStock', name: 'Total Stock', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#5BCDF4', fieldValue: 'totalStock', type: 'stock' },
    { label: 'Stock with Enquiry', value: 'stockwithEnq', name: 'Stock with Enquiry', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#5BCDF4', fieldValue: 'stockwithEnq', type: 'stock' },
    { label: 'Stock without Enquiry', value: 'stockwithoutEnq', name: 'Stock without Enquiry', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#5BCDF4', fieldValue: 'stockwithoutEnq', type: 'stock' },

    //credit score
    { label: 'Total Credit Score Request', value: 'creditScore', name: 'Total Credit Score Request', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#5BCDF4', fieldValue: 'creditScore', type: 'creditScore' },
    { label: 'Pre-approved', value: 'preApproved', name: 'Pre-approved', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#5BCDF4', fieldValue: 'preApproved', type: 'creditScore' },
    { label: 'Not Pre-approved', value: 'notpreApproved', name: 'Not Pre-approved', view: ['table', 'column', 'pie', 'target'], calculationType: ['count'], color: '#5BCDF4', fieldValue: 'notpreApproved', type: 'creditScore' },

]


export const frequencyOptions = [
    { active: true, value: 'daily', label: 'Daily' },
    { active: true, value: 'weekly', label: 'Weekly' },
    { active: true, value: 'monthly', label: 'Monthly' }
]

export const dataOptions = [
    { active: true, value: 'today', label: `Today's Data` },
    { active: true, value: 'lwd', label: 'Last 7 Days Data' },
    { active: true, value: 'wtd', label: 'Current Week Data' },
    { active: true, value: 'pwd', label: 'Last Week Data' },
    { active: true, value: 'mtd', label: 'Current Month Data' },
    { active: true, value: 'lmd', label: 'Last Month Data' },
    { active: true, value: 'upcoming', label: 'Upcoming Activities' }
]

export const columnTypes = [
    { label: 'Activities', value: 'activities' },
    { label: 'Trigger Activities', value: 'triggers' },
    { label: 'Customer Logs', value: 'logs' },
    { label: 'Duration', value: 'duration' },
    { label: 'Performance', value: 'performance' },
    { label: 'Persistency', value: 'persistency' },
    { label: 'Stock', value: 'stock' }
]

export const dataQualityOptions = [
    { label: 'With Phone', value: 'phone' },
    { label: 'Without Phone', value: 'nophone' },
    { label: 'With Email', value: 'email' },
    { label: 'Without Email', value: 'noemail' },
    { label: 'With Nationality', value: 'nationality' },
    { label: 'Without Nationality', value: 'nonationality' },
    { label: 'With Vehicle Model', value: 'make' },
    { label: 'Without Vehicle Model', value: 'nomake' },
    { label: 'With Origin', value: 'origin' },
    { label: 'Without Origin', value: 'noorigin' },
    { label: 'With Enquiry Type', value: 'enquiryType' },
    { label: 'Without Enquiry Type', value: 'noenquiryType' },
    { label: 'With Campaign', value: 'campaign' },
    { label: 'Without Campaign', value: 'nocampaign' },
]
export const defaultFilters = {
    user: [],
    dmstype: [],
    make: '',
    model: [],
    pipelines: [],
    enquiryTypes: [],
    campaigns: [],
    origins: [],
    status: [],
    label: [],
    lostReasons: [],
    lostsubreason: [],
    salesType: [],
    vehicleSearchOn: '',
    enqAddedBy: []
}

export const dealertargetDynCols = [
    { name: 'Target', value: 'target', color: '#f8cbad', borderColor: '#f19454', dummyData: { width: 20, height: 20 } },
    { name: 'Actual', value: 'actual', color: '#e7e6e6', borderColor: '#2f2f2f', dummyData: { width: 20, height: 20, } },
    { name: 'Target-Cummulative', value: 'cummulativeTarget', color: '#ed7d31', dummyData: { width: 25, height: 7, top: 8, right: 20 } },
    { name: 'Actual-Cummulative', value: 'cummulativeActual', color: '#000000', dummyData: { width: 25, height: 7, top: 8, right: 20 } }
]

export const reportDynFields = [
    {
        name: 'Name',
        value: 'name',
        flex: 2,
        default: true,
        fixed: true
    },
    {
        name: 'Total Enquiries',
        value: 'total',
        flex: 1
    },
    {
        name: 'New Enquiries',
        value: 'newLeads',
        flex: 1
    },
    {
        name: 'Existing Enquiries',
        value: 'exisitngLeads',
        flex: 1
    },
    {
        name: 'Open',
        value: 'open',
        status: true,
        flex: 1
    },
    {
        name: 'Won',
        value: 'won',
        status: true,
        flex: 1
    },
    {
        name: 'Test Drive to Won',
        value: 'wonTD',
        status: true,
        flex: 1
    },
    {
        name: 'Lost',
        value: 'lost',
        status: true,
        flex: 1
    },
    {
        name: 'Prospect Lost',
        value: 'prospectLost',
        status: true,
        flex: 1
    },
    {
        name: 'Lead Lost',
        value: 'leadLost',
        flex: 1
    },
    {
        name: 'Total Lost',
        value: 'totalLost',
        flex: 1
    },
    {
        name: 'Delivered',
        value: 'delivered',
        status: true,
        flex: 1
    },
    {
        name: 'New Showroom Walk Ins',
        value: 'newwalkin',
        flex: 1
    },
    {
        name: 'Repeat Showroom Walk Ins',
        value: 'repeatwalkin',
        flex: 1
    },
    {
        name: 'Total Showroom Walk Ins',
        value: 'walkin',
        flex: 1
    },
    {
        name: 'Test Drive',
        value: 'testdrive',
        flex: 1
    },
    {
        value: 'tradein',
        name: 'Trade In',
        flex: 1
    },
    {
        value: 'tradeininprogress',
        name: 'Trade In InProgress',
        flex: 1
    },
    {
        value: 'tradeincompleted',
        name: 'Trade In Completed',
        flex: 1
    },
    {
        value: 'tradeinwon',
        name: 'Trade In Won',
        flex: 1
    },
    {
        value: 'tradeinlost',
        name: 'Trade In Lost',
        flex: 1
    },
    {
        name: 'Inbound Call Log',
        value: 'inboundCall',
        flex: 1
    },
    {
        name: 'Outbound Call Log',
        value: 'outboundCall',
        flex: 1
    },
    {
        name: 'Total Call Log',
        value: 'totalCall',
        flex: 1
    },
    {
        name: 'SMS Log',
        value: 'messageLog',
        flex: 1
    },
    {
        name: 'Email Log',
        value: 'emailLog',
        flex: 1
    },
    {
        name: 'Notes',
        value: 'noteLog',
        flex: 1
    },
    {
        name: 'Appointment Scheduled',
        value: 'appointmentScheduled',
        flex: 1
    },
    {
        name: 'Test Drive Scheduled',
        value: 'testdriveScheduled',
        flex: 1
    },
    {
        name: 'Call Scheduled',
        value: 'callScheduled',
        flex: 1
    },
    {
        name: 'Mail Scheduled',
        value: 'mailScheduled',
        flex: 1
    },
    {
        name: 'Delivery Scheduled',
        value: 'deliveryScheduled',
        flex: 1
    },
    {
        name: 'Appointment Done',
        value: 'appointment',
        flex: 1
    },
    {
        name: 'Test Drive Activity Done',
        value: 'testdriveActivity',
        flex: 1
    },
    {
        name: 'Call Activity Done',
        value: 'callActivity',
        flex: 1
    },
    {
        name: 'Mail Activity Done',
        value: 'mailActivity',
        flex: 1
    },
    {
        name: 'Delivery Activity Done',
        value: 'deliveryActivity',
        flex: 1
    },
    {
        name: 'Walk In Test Drive Conv. Ratio',
        value: 'walkintestdrive',
        flex: 1
    },
    {
        name: 'Walk In Won Conv. Ratio',
        value: 'walkinsold',
        flex: 1
    },
    {
        name: 'Appointment to Test Drive Done',
        value: 'tdappt',
        flex: 1
    },
    {
        name: 'Appointment Test Drive Conv. Ratio',
        value: 'appointmenttestdrive',
        flex: 1
    },
    {
        name: 'Test Drive Activity to Test Drive Done',
        value: 'tdtestdrive',
        flex: 1
    },
    {
        name: 'Test Drive Activity to Test Drive Conv. Ratio',
        value: 'testdrivetotestdrive',
        flex: 1
    },
    {
        name: 'Appointment Won Conv. Ratio',
        value: 'appointmentsold',
        flex: 1
    },
    {
        name: 'Trade In Won Conv. Ratio',
        value: 'tradeinwonConv',
        flex: 1
    },
    {
        name: 'Test Drive Conv. Ratio',
        value: 'testdriveConv',
        flex: 1
    },
    {
        name: 'Won Conv. Ratio',
        value: 'wonConv',
        flex: 1
    },
    {
        name: 'Pending Prospects',
        value: 'pendingLeads',
        flex: 1
    },
    {
        name: 'Overall Pending',
        value: 'overallPending',
        flex: 1
    },
    {
        name: 'Converted Prospects',
        value: 'convertedLeads',
        flex: 1
    },
    {
        name: 'Show',
        value: 'show',
        flex: 1
    },
    {
        name: 'No Show',
        value: 'noshow',
        flex: 1
    },
    {
        name: 'Total Inbound',
        value: 'totalInbound',
        flex: 1
    },
    {
        name: 'Avg. Lead Response Time',
        value: 'responseTime',
        flex: 1
    },
    {
        name: 'Max. Lead Response Time',
        value: 'maxresponseTime',
        flex: 1
    },
    {
        name: 'Min. Lead Response Time',
        value: 'minresponseTime',
        flex: 1
    },
    {
        name: 'Avg. Lead Allocated Time',
        value: 'allocatedTime',
        flex: 1
    },
    {
        name: 'Max. Lead Allocated Time',
        value: 'maxallocatedTime',
        flex: 1
    },
    {
        name: 'Min. Lead Allocated Time',
        value: 'minallocatedTime',
        flex: 1
    },
    {
        name: 'Avg. Contacted Time',
        value: 'contactedTime',
        flex: 1
    },
    {
        name: 'Max. Contacted Time',
        value: 'maxcontactedTime',
        flex: 1
    },
    {
        name: 'Min. Contacted Time',
        value: 'mincontactedTime',
        flex: 1
    },
    {
        value: 'quotes',
        name: 'Quotes Generated',
        flex: 1
    },
    {
        value: 'quoteAmount',
        name: 'Quotes Amount',
        flex: 1
    },
    {
        value: 'wonAmount',
        name: 'Won Amount',
        flex: 1
    },
    {
        value: 'scheduledConv',
        name: 'Scheduled Conv. Ratio',
        flex: 1
    },
    {
        value: 'showConv',
        name: 'Show Conv. Ratio',
        flex: 1
    },
    {
        value: 'noshowConv',
        name: 'No Show Conv. Ratio',
        flex: 1
    },
    {
        value: 'convertedConv',
        name: 'Converted Conv. Ratio',
        flex: 1
    },
    {
        name: 'Carry Over Leads',
        value: 'carryover',
        flex: 1
    },
    {
        name: 'Completed Activity',
        value: 'doneActivity',
        flex: 1
    },
    {
        name: 'Overdue Activity',
        value: 'overdueActivity',
        flex: 1
    },
    {
        name: 'Status Modified',
        value: 'statusModified',
        flex: 1
    },
    {
        value: 'noActivity',
        name: 'Leads Without Activity',
        flex: 1
    },
    {
        value: 'noNotes',
        name: 'Leads Without Notes',
        flex: 1
    },
    {
        value: 'noCalls',
        name: 'Leads Without Call Logs',
        flex: 1
    },
    {
        value: 'noMessage',
        name: 'Leads Without SMS Logs',
        flex: 1
    },
    {
        value: 'unactionedLeads',
        name: 'Unactioned Leads',
        flex: 1
    },
    {
        value: 'totalStock',
        name: 'Total Stock',
        flex: 1
    },
    {
        value: 'stockwithEnq',
        name: 'Stock with Enquiry',
        flex: 1
    },
    {
        value: 'stockwithoutEnq',
        name: 'Stock without Enquiry',
        flex: 1
    },
    { value: 'creditScore', name: 'Total Credit Score Request', flex: 1 },
    { value: 'preApproved', name: 'Pre-approved', flex: 1 },
    { value: 'notpreApproved', name: 'Not Pre-approved', flex: 1 },

]

export const levelOptions = [
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Client', index: 4 }
]