import React from 'react'
import Translate from "../../constants/translate"

export const pipelineCountVM = {
    monthActivity: [],
    monthDelivered: [],
    monthLost: [],
    monthOpen: [],
    monthNew: [],
    monthTestdrive: [],
    monthTradein: [],
    monthWon: [],
    monthWalkin: [],

    overdueActivity: [],
    todayActivity: [],
    todayDelivered: [],
    todayLost: [],
    todayOpen: [],
    todayTestdrive: [],
    todayTradein: [],
    todayWon: [],
    todayNew: [],
    todayWalkin: [],

    weekActivity: [],
    weekDelivered: [],
    weekLost: [],
    weekOpen: [],
    weekNew: [],
    weekTestdrive: [],
    weekTradein: [],
    weekWon: [],
    weekWalkin: [],
}

export const pipelineCountVMNEW = {
    monthActivity: 0,
    monthDelivered: 0,
    monthLost: 0,
    monthOpen: 0,
    monthNew: 0,
    monthTestdrive: 0,
    monthTradein: 0,
    monthWon: 0,
    monthWalkin: 0,

    overdueActivity: 0,
    todayActivity: 0,
    todayDelivered: 0,
    todayLost: 0,
    todayOpen: 0,
    todayTestdrive: 0,
    todayTradein: 0,
    todayWon: 0,
    todayNew: 0,
    todayWalkin: 0,

    weekActivity: 0,
    weekDelivered: 0,
    weekLost: 0,
    weekOpen: 0,
    weekNew: 0,
    weekTestdrive: 0,
    weekTradein: 0,
    weekWon: 0,
    weekWalkin: 0,
}

export const leadBucketCountVM = {
    pendingInboundleads: [],
    todayConverted: [],
    monthConverted: [],
    lostApprovalList: []
}

export const pipelinestatus = [
    {
        name: "Open",
        value: "open",
        color: "#67b7dc"
    },
    {
        name: "Won",
        value: "won",
        color: "#2bb673"
    },
    {
        name: "Lost",
        value: "lost",
        color: "#ef4136"
    },
    {
        name: "Delivered",
        value: "delivered",
        color: "#049b8c"
    }
]


export const pipelineLabels = [
    {
        name: "Hot",
        value: "hot",
        color: "#ed1c24"
    },
    {
        name: "Warm",
        value: "warm",
        color: "#f7931e"
    },
    {
        name: "Cold",
        value: "cold",
        color: "#29abe2"
    },
    {
        name: "Other/Blank",
        value: "others",
        color: "#67b7dc"
    }
]

export const initialLayoutArr = [
    { x: 0, y: 0, w: 4, h: 4, i: 'LeadsBucket', minW: 4, maxW: 12, minH: 3, maxH: 3, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 4, w: 4, h: 7, i: 'LeadsByLabels', minW: 4, maxW: 12, minH: 7, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 0, w: 4, h: 11, i: 'Activities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 0, w: 4, h: 11, i: 'LeadsByOrigin', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 12, w: 4, h: 11, i: 'LeadsByEnquiryType', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 11, w: 4, h: 11, i: 'SalesFunnel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 11, w: 4, h: 11, i: 'LeadsByCampaign', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 24, w: 4, h: 11, i: 'LeadsByStatus', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 22, w: 4, h: 11, i: 'SalesTarget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 22, w: 4, h: 11, i: 'DealerTarget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 0, w: 4, h: 4, i: 'ActivitiesWidget', minW: 4, maxW: 4, minH: 3, maxH: 3, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 0, y: 34, w: 4, h: 11, i: 'FleetCounts', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LoanBookingsWidget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 0, w: 4, h: 3, i: 'InboundActivitiesWidget', minW: 4, maxW: 4, minH: 3, maxH: 3, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 0, y: 34, w: 4, h: 11, i: 'PerformanceReviewWidget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'InboundActivities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'FinanceActivities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'FinanceActivitiesWidget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'AfterMarketActivities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'AfterMarketActivitiesWidget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'DynamicFieldsWidget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 0, w: 4, h: 3, i: 'TestDriveInbound', minW: 4, maxW: 4, minH: 3, maxH: 3, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 4, y: 0, w: 4, h: 11, i: 'TestDriveBooking', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },

]

export const mclarenLayoutArr = [
    { x: 0, y: 0, w: 4, h: 4, i: "ActivitiesWidget", },
    { x: 0, y: 4, w: 4, h: 4, i: "LeadsBucket" },
    { x: 0, y: 8, w: 4, h: 4, i: "InboundActivitiesWidget", },
    { x: 4, y: 0, w: 8, h: 23, i: "DealerTarget", },
    { x: 0, y: 11, w: 4, h: 11, i: "Activities" },


    { x: -1, i: "LeadsByEnquiryType" },
    { x: -1, i: "LeadsByOrigin" },
    { x: -1, i: "LeadsByStatus" },
    { x: -1, i: "LeadsByLabels" },
    { x: -1, i: "LeadsByCampaign" },
    { x: -1, i: "InboundActivities" },
    { x: -1, i: "SalesTarget" },
    { x: -1, i: "FleetCounts" },
    { x: -1, i: "PerformanceReviewWidget" },
    { x: -1, i: "LoanBookingsWidget" },
    { x: -1, i: "FinanceActivities" },
    { x: -1, i: "FinanceActivitiesWidget" },
    { x: -1, i: "AfterMarketActivities" },
    { x: -1, i: "AfterMarketActivitiesWidget" },
    { x: -1, i: "DynamicFieldsWidget" },
    { x: -1, i: "TestDriveInbound" },
    { x: -1, i: "TestDriveBooking" },
    { x: -1, i: "FinanceCreditScore" },
]


export const widgetSettingsNames = [
    { name: <Translate text={'Leads Bucket'} />, value: 'LeadsBucket' },
    { name: <Translate text={'Leads By Labels'} />, value: 'LeadsByLabels' },
    { name: <Translate text={'activities'} />, value: 'Activities' },
    { name: <Translate text={'Inbound Activities'} />, value: 'InboundActivities' },
    { name: <Translate text={'Leads By Origin'} />, value: 'LeadsByOrigin' },
    { name: <Translate text={'Leads By EnquiryType'} />, value: 'LeadsByEnquiryType' },
    { name: <Translate text={'Sales Funnel'} />, value: 'SalesFunnel' },
    { name: <Translate text={'Leads By Campaign'} />, value: 'LeadsByCampaign' },
    { name: <Translate text={'Leads By Status'} />, value: 'LeadsByStatus' },
    { name: <Translate text={'Sales Target'} />, value: 'SalesTarget' },
    { name: <Translate text={'dealerTarget'} />, value: 'DealerTarget' },
    { name: <Translate text={'Activities Counts'} />, value: 'ActivitiesWidget' },
    { name: <Translate text={'LoanTestDriveVehicle'} />, value: 'FleetCounts' },
    { name: <Translate text={'loanVehicleBookings'} />, value: 'LoanBookingsWidget' },
    { name: <Translate text={'Inbound Activities Counts'} />, value: 'InboundActivitiesWidget' },
    { name: <Translate text={'Daily Performance Review'} />, value: 'PerformanceReviewWidget' },
    { name: <Translate text={'Finance Activities'} />, value: 'FinanceActivities' },
    { name: <Translate text={'Finance Activities Counts'} />, value: 'FinanceActivitiesWidget' },
    { name: <Translate text={'After Market Activities'} />, value: 'AfterMarketActivities' },
    { name: <Translate text={'After Market Activities Counts'} />, value: 'AfterMarketActivitiesWidget' },
    { name: <Translate text={'Dynamic Fields'} />, value: 'DynamicFieldsWidget' },
    { name: <Translate text={'Test Drive Inbound'} />, value: 'TestDriveInbound' },
    { name: <Translate text={'Test Drive Booking'} />, value: 'TestDriveBooking' },
    { name: <Translate text={'Finance Credit Score'} />, value: 'FinanceCreditScore' },
    { name: <Translate text={'Campaign Performance'} />, value: 'CampaignPerformance' },
    { name: <Translate text={'Origin Performance'} />, value: 'OriginPerformance' },
    { name: <Translate text={'Lost Leads Reasons'} />, value: 'LostLeadsReasons' },
    { name: <Translate text={'Lost Enquiry Reasons'} />, value: 'LostEnquiryReasons' },
    { name: <Translate text={'Leads Conversion'} />, value: 'LeadsCreated' },
    { name: <Translate text={'Test Drives Completed'} />, value: 'TestDriveCompleted' },
    { name: <Translate text={'Trade Ins'} />, value: 'TradeInCompleted' },
    { name: <Translate text={'Lost Enquiry Reignition'} />, value: 'LostEnquiryReignition' },
    { name: <Translate text={'Enquiry Qualification Development'} />, value: 'EnquiryQualificationDevelopment' },
    { name: <Translate text={'Lead Qualification Development'} />, value: 'LeadQualificationDevelopment' },
    { name: <Translate text={'Showroom Visits'} />, value: 'ShowroomVisits' },
    { name: <Translate text={'Leads By Model'} />, value: 'LeadsByModel' },
    { name: <Translate text={'New Enquiries By Model'} />, value: 'EnquiriesByModel' },
    { name: <Translate text={'New Enquiries By Campaign'} />, value: 'EnquiriesByCampaign' },
    { name: <Translate text={'Qualified Enquiries By Model'} />, value: 'QualifiedEnquiriesByModel' },
    { name: <Translate text={'Qualified Enquiries By Campaign'} />, value: 'QualifiedEnquiriesByCampaign' },
    { name: <Translate text={'Leads By Source'} />, value: 'LeadsBySource' },
    { name: <Translate text={'New Enquiries By Source'} />, value: 'EnquiriesBySource' },

]

export const defaultWidgetSettings = [
    'LeadsBucket',
    'TestDriveInbound',
    'TestDriveBooking',
    'LeadsByLabels',
    'Activities',
    'InboundActivities',
    'LeadsByOrigin',
    'LeadsByEnquiryType',
    'SalesFunnel',
    'LeadsByCampaign',
    'LeadsByStatus',
    'SalesTarget',
    'DealerTarget',
    'ActivitiesWidget',
    'FleetCounts',
    'LoanBookingsWidget',
    'InboundActivitiesWidget',
    'PerformanceReviewWidget'
]

export const liteVersionWidgetSettings = [
    'SalesFunnel',
    'Activities',
    'FleetCounts',
]

export const defaultStatsIcons = [
    { name: 'New', icon: 'icon-new-leads', value: 'new' },
    { name: 'Open', icon: 'icon-open-leads', value: 'open', status: true },
    { name: 'Won', icon: 'icon-won-leads', value: 'won', status: true, icoVehicle: true },
    { name: 'Lost', icon: 'icon-lost-leads', value: 'lost', status: true, icoVehicle: true },
    { name: 'Delivered', icon: 'icon-delivered', value: 'delivered', status: true, icoVehicle: true },
    { name: 'Test Drive', icon: 'icon-oem-testdrive', value: 'testdrive', icoVehicle: true },
    { name: 'Trade-In', icon: 'icon-oem-tradein', value: 'tradein', icoVehicle: true },
    { name: 'Unattended', icon: 'icon-overdue-activities', value: 'unattended' },
    { name: 'Pending Deliveries', icon: 'icon-oem-tradein', value: 'delivery', color: '#02abc8', icoVehicle: true },
    { name: 'Overall Pending Deliveries', icon: 'icon-oem-tradein', value: 'pendingdelivery', color: '#f7931e', icoVehicle: true },
    { name: 'Scheduled Appointments', icon: 'icon-activity-calendar', value: 'scheduledAppointments', color: '#57b000' },
    { name: 'Show Customers', icon: 'ico icon-show-leads', value: 'show' },
    { name: 'No Show Customers', icon: 'ico icon-noshow-leads', value: 'noshow' }
]