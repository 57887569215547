import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import {
    oemDataVM,
    mandatoryFields,
    defaultroleVM,
    defaultpipelineVM,
    managerroleVM,
    salesroleVM,
    defaultLostReasons,
    defaultReqInfo,
    defaultTitles,
    defaultTestdrivefeedback,
    defaultOrigins,
    defaultFinanceStatus,
    defaultEnquiryOptions,
    defaultEnquiryTypes,
    defaultCallStatus,
    defaultGenders,
    defaultMaritalStatus,
    defaultContactMethod,
    defaultInterests,
    defaultLicenseType,
    defaultCompanyType,
    defaultSalesTypes,
    servicepipelineVM,
    defaultReasons,
    defaultPartStatus,
    defaultserviceTypes,
    defaultSlots,
    defaultAppointmentTypes,
    pipelineModules,
    defaultEnquiryStatus,
    defaultActivityTypes,
    brandThemes,
    defaultCafecategory,
    tradeinpropipelineVM,
    eventspropipelineVM,
    defaultEventCategories
} from './viewModel'
import { ClientContext } from '../../../layout/clientContext'
import { InputText, ReactMultiSelect, ReactSelect, InputCheckBox } from '../../../../components'
import { validateEmail } from "../../../../services/helper";
import { reportScheduler } from '../clients/viewModel'


const AddOEM = (props) => {

    const [selectedOEM, setSelectedOEM] = useState(props.selectedOEM);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false);
    const newOEM = !_.isEmpty(props.selectedOEM) ? false : true
    const { regionLookups, oemBrands } = useContext(ClientContext);
    const [staticReports, setStaticReports] = useState([]);
    const [deletedReports, setDeletedReports] = useState([]);
    const [projects, setProjects] = useState([])

    useEffect(() => {
        const _projects = [];
        if (window?.firebase?.app()?.options?.projectId) {
            _projects.push({
                label: window.firebase.app().name,
                value: window?.firebase?.app()?.options?.projectId
            })
        }
        if (window?.firebase2?.options?.projectId) {
            _projects.push({
                label: window.firebase2.name,
                value: window.firebase2.options.projectId
            })
        }
        setProjects(_projects);
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.selectedOEM)) {
            var departments = [];
            departments.push({ name: 'New Car Sales', value: 'New' })
            departments.push({ name: 'Used Car Sales', value: 'Used' })
            departments.push({ name: 'Management', value: 'Management' })

            const newoemVM = Object.assign({}, oemDataVM);
            newoemVM.documentID = window.firebase.firestore().collection('clients').doc().id;
            newoemVM.addedBy = localStorage.uid;
            newoemVM.modifiedBy = localStorage.uid;
            newoemVM.addedDate = window.firebase.firestore.Timestamp.now();
            newoemVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newoemVM.departments = departments;
            newoemVM.pipelines = defaultpipelineVM;
            newoemVM.mandatoryFields = {
                contact: 'firstName,lastName,phoneORemail',
                company: 'name',
                stock: 'stockNo,make,model,year,saleType',
                testdrive: 'startOdometer,terms,expectedReturn,endDate,startDate',
                tradein: 'make,model,year',
                requirement: 'make,model,saleType',
                pipeline: [{
                    name: 'LeadsBucket-Contacted',
                    enquiry: 'isVehicle,isContact'
                }, {
                    name: 'LeadsBucket-Converted',
                    enquiry: 'isVehicle,isContact'
                }],
                status: [{
                    name: 'open',
                    enquiry: 'isVehicle,isContact'
                }, {
                    name: 'won',
                    enquiry: 'isVehicle,isContact'
                }, {
                    name: 'delivered',
                    enquiry: 'isVehicle,isContact'
                }, {
                    name: 'lost',
                    enquiry: 'isVehicle,isContact'
                }]
            };
            newoemVM.callStatus = defaultCallStatus;
            newoemVM.lostReasons = defaultLostReasons;
            newoemVM.enquiryTypes = defaultEnquiryTypes;
            newoemVM.enquiryOptionsDF = defaultEnquiryOptions;
            newoemVM.financeStatus = defaultFinanceStatus;
            newoemVM.origins = defaultOrigins;
            newoemVM.testdriveFeedback = defaultTestdrivefeedback;
            newoemVM.titles = defaultTitles;
            newoemVM.reqOtherInfo = defaultReqInfo;
            newoemVM.genders = defaultGenders;
            newoemVM.maritalStatus = defaultMaritalStatus;
            newoemVM.contactMethod = defaultContactMethod;
            newoemVM.interests = defaultInterests;
            newoemVM.licenseType = defaultLicenseType;
            newoemVM.companyType = defaultCompanyType;
            newoemVM.salesType = defaultSalesTypes;
            newoemVM.enquiryStatus = defaultEnquiryStatus;
            newoemVM.activityTypes = defaultActivityTypes;
            newoemVM.pipelineModules = pipelineModules.map(rec => { return rec.value });
            newoemVM.cafecategory = defaultCafecategory;

            setSelectedOEM(newoemVM)
        }
        else {
            setSelectedOEM({
                ...selectedOEM,
                pipelineModules: _.isEmpty(selectedOEM.pipelineModules) ? pipelineModules.map(rec => { return rec.value }) : selectedOEM.pipelineModules
            })
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(props.selectedOEM)) {
            window.firebase.firestore().collection(`schedular`)
                .where('oemID', '==', props.selectedOEM.documentID)
                .where('static', '==', true)
                .get()
                .then(snap => {
                    let _report = [];
                    snap.docs.forEach(rec => {
                        _report.push(rec.data());
                    })
                    setStaticReports(_report);
                })
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email') {
            setSelectedOEM({
                ...selectedOEM,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setSelectedOEM({
                    ...selectedOEM,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setSelectedOEM({
                    ...selectedOEM,
                    [name]: str
                });
            }
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        if (name === 'reportIDs') {
            let difference = staticReports.filter(x => !(!_.isEmpty(selectedOptions) ? selectedOptions.map(r => { return r.value }) : []).includes(x.value));
            setDeletedReports([
                ...deletedReports,
                ...difference
            ])
            let _report = [];
            selectedOptions && selectedOptions.forEach(rec => {
                if (staticReports.some(x => x.value === rec.value)) {
                    _report.push(_.find(staticReports, { value: rec.value }))
                }
                else {
                    _report.push(rec)
                }
            })
            setStaticReports(_report)
        }
        else {
            setSelectedOEM({
                ...selectedOEM,
                [name]: value
            });
            setErrorFields({
                ...errorFields,
                [name]: ''
            });
        }

    }

    const handleCheckedChange = (e) => {
        setSelectedOEM({
            ...selectedOEM,
            [e.target.name]: e.target.checked
        })
    }

    const saveOEM = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        for (let [key] of Object.entries(selectedOEM)) {
            if (mandatoryFields.indexOf(key) >= 0) {
                if (_.isEmpty(selectedOEM[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
                if (key === 'email') {
                    if (typeof selectedOEM[key] !== "undefined") {
                        if (!validateEmail(selectedOEM[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)


        var adminRole = Object.assign({}, defaultroleVM);
        adminRole.documentID = window.firebase.firestore().collection(`clientSettings/${selectedOEM.settingsID}/roles`).doc().id;
        adminRole.addedBy = localStorage.uid;
        adminRole.modifiedBy = localStorage.uid;
        adminRole.addedDate = window.firebase.firestore.Timestamp.now();
        adminRole.modifiedDate = window.firebase.firestore.Timestamp.now();


        var managerRole = Object.assign({}, managerroleVM);
        managerRole.name = 'Management';
        managerRole.documentID = window.firebase.firestore().collection(`clientSettings/${selectedOEM.settingsID}/roles`).doc().id;
        managerRole.addedBy = localStorage.uid;
        managerRole.modifiedBy = localStorage.uid;
        managerRole.addedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        managerRole.modifiedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);

        var salesRole = Object.assign({}, salesroleVM);
        salesRole.name = 'New Car Sales';
        salesRole.documentID = window.firebase.firestore().collection(`clientSettings/${selectedOEM.settingsID}/roles`).doc().id;
        salesRole.addedBy = localStorage.uid;
        salesRole.modifiedBy = localStorage.uid;
        salesRole.addedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        salesRole.modifiedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        salesRole.permissions = {
            ...salesRole.permissions,
            modifyStatusDate: true,
            modifyOtherActivity: true,
            movePipeline: true,
            modifyEnquiryOrigin: true,
            modifyCampaign: true,
            accessStaticReport: true,
            returnVehicle: true,
            modifyPurhaseInt: true,
            exportData: true,
            accessToShowTradeInPrice: true,
            modifyRequirements: true,
            modifyEnquiryType: true,
            modifyLostReason: true,
            ownerDashboard: true,
            modifyLabel: true,
            reopenEnquiry: true,
            modifyStageAfterDeliveredLost: true,
            accessToFinanceManager: true,
            modifyVehicleInfo: true,
            testdriveHandover: true,
            modifyOtherContact: true,
            mergeContact: true,
            viewOtherContact: true,
            modifyOtherEnquiry: true,
            accessToAcquisitionReport: true
        }

        var usedsalesRole = Object.assign({}, salesroleVM);
        usedsalesRole.name = 'Used Car Sales';
        usedsalesRole.documentID = window.firebase.firestore().collection(`clientSettings/${selectedOEM.settingsID}/roles`).doc().id;
        usedsalesRole.addedBy = localStorage.uid;
        usedsalesRole.modifiedBy = localStorage.uid;
        usedsalesRole.addedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        usedsalesRole.modifiedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        usedsalesRole.permissions = {
            ...usedsalesRole.permissions,
            modifyStatusDate: true,
            modifyOtherActivity: true,
            movePipeline: true,
            modifyEnquiryOrigin: true,
            modifyCampaign: true,
            accessStaticReport: true,
            returnVehicle: true,
            modifyPurhaseInt: true,
            exportData: true,
            accessToShowTradeInPrice: true,
            modifyRequirements: true,
            modifyEnquiryType: true,
            modifyLostReason: true,
            ownerDashboard: true,
            modifyLabel: true,
            reopenEnquiry: true,
            modifyStageAfterDeliveredLost: true,
            accessToFinanceManager: true,
            modifyVehicleInfo: true,
            testdriveHandover: true,
            modifyOtherContact: true,
            mergeContact: true,
            viewOtherContact: true,
            modifyOtherEnquiry: true,
            accessToAcquisitionReport: true
        }


        var receptionRole = Object.assign({}, salesroleVM);
        receptionRole.documentID = window.firebase.firestore().collection(`clientSettings/${selectedOEM.settingsID}/roles`).doc().id;
        receptionRole.name = 'Reception';
        receptionRole.addedBy = localStorage.uid;
        receptionRole.modifiedBy = localStorage.uid;
        receptionRole.addedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        receptionRole.modifiedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        receptionRole.modules = {
            ...receptionRole.modules,
            enquiry: true,
            frontdesk: true,
            kitchen: true,
            stock: true
        }
        receptionRole.permissions = {
            ...receptionRole.permissions,
            modifyOtherActivity: true,
            deleteEnquiry: true,
            exportData: true,
            transferContact: true,
            mergeContact: true,
            modifyEnquiryOwner: true,
            modifyOtherEnquiry: true,
            modifyCampaign: true,
            modifyEnquiryOrigin: true,
            modifyEnquiryType: true,
            modifyLabel: true,
            modifyLostReason: true,
            modifyPurhaseInt: true,
            modifyRequirements: true,
            modifyStatusDate: true,
            modifyVehicleInfo: true,
            movePipeline: true,
            queueReorder: true,
            returnVehicle: true,
            testdriveHandover: true,
            transferEnquiry: true,
            viewOtherEnquiry: true,
            unlinkEnquiry: true,
            accessDealertarget: true,
            viewOtherActivity: true,
            modifyStageAfterDeliveredLost: true,
            bdcDashboard: true,
            accessStaticReport: true,
            separateInbdUserAct: true,
            frontdeskQueueActivation: true,
            showFormFeedback: true
        }

        var businessmanagerrole = Object.assign({}, salesroleVM);
        businessmanagerrole.documentID = window.firebase.firestore().collection(`clientSettings/${selectedOEM.settingsID}/roles`).doc().id;
        businessmanagerrole.name = 'Business Managers';
        businessmanagerrole.addedBy = localStorage.uid;
        businessmanagerrole.modifiedBy = localStorage.uid;
        businessmanagerrole.addedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        businessmanagerrole.modifiedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        businessmanagerrole.modules = {
            ...businessmanagerrole.modules,
            stock: true,
            loanVehicle: true,
            oem: true,
            kitchen: false,
            frontdesk: false,
            enquiry: true,
            service: true
        }
        businessmanagerrole.permissions = {
            ...businessmanagerrole.permissions,
            modifyContactOwner: true,
            modifyCampaign: true,
            unlinkEnquiry: true,
            accessToFinanceActivity: true,
            verifyEnquiry: true,
            movePipeline: true,
            modifyLabel: true,
            modifyNotes: true,
            viewOtherEnquiry: true,
            transferEnquiry: true,
            returnVehicle: true,
            accessToShowTradeInPrice: true,
            modifyActivityOwner: true,
            testdriveHandover: true,
            viewOtherActivity: true,
            modifyEnquiryOrigin: true,
            modifyOtherEnquiry: true,
            viewOtherContact: true,
            accessToBusinessManager: true,
            modifyPurhaseInt: true,
            modifyStatusDate: true,
            reopenEnquiry: true,
            modifyStageAfterDeliveredLost: true,
            modifyVehicleInfo: true,
            mergeContact: true,
            exportData: true,
            queueReorder: true,
            dealerFileUpload: true,
            accessDealertarget: true,
            modifyOtherActivity: true,
            accessToFinanceManager: true,
            modifyOtherContact: true,
            accessStaticReport: true,
            modifyRequirements: true,
            modifyLostReason: true,
            modifyEnquiryType: true,
            dealerLeadTransfer: false
        }

        var servicerole = Object.assign({}, salesroleVM);
        servicerole.documentID = window.firebase.firestore().collection(`clientSettings/${selectedOEM.settingsID}/roles`).doc().id;
        servicerole.name = 'Service';
        servicerole.addedBy = localStorage.uid;
        servicerole.modifiedBy = localStorage.uid;
        servicerole.addedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        servicerole.modifiedDate = window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'sec')._d);
        servicerole.modules = {
            ...servicerole.modules,
            stock: false,
            loanVehicle: true,
            oem: false,
            kitchen: false,
            frontdesk: false,
            enquiry: false,
            service: false
        }
        servicerole.permissions = {
            ...servicerole.permissions,
            returnVehicle: true,
            ownerDashboard: true,
            testdriveHandover: true,
            accessToShowTradeInPrice: true,
            mergeContact: true,
            modifyOtherActivity: true,
        }

        selectedOEM.modifiedDate = window.firebase.firestore.Timestamp.now();
        selectedOEM.modifiedBy = localStorage.uid;
        selectedOEM.pipelines = newOEM ? selectedOEM.pipelines.map(rec => {
            return {
                ...rec,
                modules: {
                    ...setModules(rec.modules)
                }

            }
        }) : selectedOEM.pipelines;
        const batch = window.firebase.firestore().batch();
        batch.set(window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}`),
            selectedOEM, { merge: true });
        if (newOEM) {
            batch.set(window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/roles/${adminRole.documentID}`),
                adminRole, { merge: true });
            batch.set(window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/roles/${managerRole.documentID}`),
                managerRole, { merge: true });
            batch.set(window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/roles/${salesRole.documentID}`),
                salesRole, { merge: true });
            batch.set(window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/roles/${usedsalesRole.documentID}`),
                usedsalesRole, { merge: true });
            batch.set(window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/roles/${receptionRole.documentID}`),
                receptionRole, { merge: true });
            batch.set(window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/roles/${businessmanagerrole.documentID}`),
                businessmanagerrole, { merge: true });
            batch.set(window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/roles/${servicerole.documentID}`),
                servicerole, { merge: true });
            regionLookups.forEach(rec => {
                let regiondID = `${selectedOEM.documentID}${rec.code}`
                batch.set(window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/regions/${regiondID}`), {
                    name: rec.name,
                    documentID: regiondID
                }, { merge: true });
            });

            staticReports.forEach(report => {
                let reportID = window.firebase.firestore().collection(`schedular`).doc().id;
                window.firebase.firestore().doc(`schedular/${reportID}`).set({
                    ...report,
                    documentID: reportID,
                    oemID: selectedOEM.documentID,
                    url: report.fileType === 'pdf' ? `${report.url}/${selectedOEM.documentID}` : report.url,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    addedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    static: true,
                    oemName: selectedOEM.name
                }, { merge: true })
            })
        }
        else {
            staticReports.filter(a => _.isEmpty(a.documentID)).forEach(report => {
                let reportID = window.firebase.firestore().collection(`schedular`).doc().id;
                window.firebase.firestore().doc(`schedular/${reportID}`).set({
                    ...report,
                    documentID: reportID,
                    oemID: selectedOEM.documentID,
                    url: report.fileType === 'pdf' ? `${report.url}/${selectedOEM.documentID}` : report.url,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    addedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    static: true,
                    oemName: selectedOEM.name
                }, { merge: true })
            })

            _.uniq(_.map(deletedReports.filter(a => !_.isEmpty(a.documentID)), function (e) {
                return e.documentID
            })).forEach(reportID => {
                window.firebase.firestore().doc(`schedular/${reportID}`).delete();
            })
        }
        batch.commit()
            .then(snapshot => {
                setLoader(false)
                toast.notify('OEM ' + (newOEM ? 'added' : 'updated') + ' successfully', {
                    duration: 2000
                })
                if (newOEM) {
                    window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/services/${selectedOEM.documentID}`)
                        .set({
                            pipelines: servicepipelineVM,
                            mandatoryFields: {
                                contact: 'firstName,lastName,phoneORemail',
                                company: 'name',
                                vehicle: 'make,model'
                            },
                            reasons: defaultReasons,
                            partStatus: defaultPartStatus,
                            serviceTypes: defaultserviceTypes,
                            slots: defaultSlots,
                            appointmentTypes: defaultAppointmentTypes
                        }, { merge: true })

                    window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/tradeinPro/${selectedOEM.documentID}`)
                        .set({
                            pipelines: tradeinpropipelineVM,
                            mandatoryFields: {
                                contact: 'firstName,lastName,phoneORemail',
                                company: 'name',
                                tradein: 'make,model',
                                status: [{
                                    tradeinPro: 'owner',
                                    name: 'InProgress'
                                },
                                {
                                    tradeinPro: 'owner',
                                    name: 'Pending'
                                },
                                {
                                    tradeinPro: 'owner',
                                    name: 'Won'
                                },
                                {
                                    tradeinPro: 'owner',
                                    name: 'Lost'
                                },
                                {
                                    tradeinPro: 'owner',
                                    name: 'New'
                                }]
                            }
                        }, { merge: true })
                    window.firebase.firestore().doc(`clientSettings/${selectedOEM.documentID}/eventsPro/${selectedOEM.documentID}`)
                        .set({
                            pipelines: eventspropipelineVM,
                            mandatoryFields: {
                                attendees: 'firstName',
                                events: 'title,startDate,endDate',
                            },
                            categories: defaultEventCategories
                        }, { merge: true })
                }
                props.handleClose()
            })
            .catch((error) => {
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const setModules = (module) => {
        let _module = module;
        Object.keys(module).forEach(rec => {
            _module = {
                ..._module,
                [rec]: selectedOEM.pipelineModules.includes(rec)
            }
        })
        return _module;
    }


    return _.isEmpty(selectedOEM) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-oem"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                <div className="settings-tab-wrap tabs">
                                    <div className="tabs-wraper">
                                        <div className="settings-tab-fields-wrap">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label >Name</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'name'}
                                                        className={`form-control ${errorFields["name"]}`}
                                                        name="name"
                                                        onChange={handleOnChange}
                                                        value={selectedOEM.name}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label >Brands</label>
                                                    <ReactMultiSelect
                                                        options={!_.isEmpty(oemBrands) ? _.map(oemBrands, function (e) {
                                                            return {
                                                                searchlabel: e.name,
                                                                label: e.category !== 'Automotive' ? <>{e.name}<span className="user-role-span">{e.category}</span></> : e.name,
                                                                value: e.documentID
                                                            }
                                                        }) : []}
                                                        name={"brandIDs"}
                                                        placeholder={'select brands'}
                                                        onChange={(option) => handleReactMultiSelectChange(option, 'brandIDs')}
                                                        value={selectedOEM.brandIDs}
                                                        classNamePrefix={`${errorFields["brandIDs"]} basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label >Reports</label>
                                                    <ReactMultiSelect
                                                        options={reportScheduler.filter(a => a.reportType === 'oem')}
                                                        name={"reportIDs"}
                                                        placeholder={'select reports'}
                                                        onChange={(option) => handleReactMultiSelectChange(option, 'reportIDs')}
                                                        value={!_.isEmpty(staticReports) ? staticReports.map(r => { return r.value }) : []}
                                                        classNamePrefix={`${errorFields["reportIDs"]} basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label >Pipeline Modules</label>
                                                    <ReactMultiSelect
                                                        options={pipelineModules}
                                                        name={"pipelineModules"}
                                                        placeholder={'select modules'}
                                                        onChange={(option) => handleReactMultiSelectChange(option, 'pipelineModules')}
                                                        value={selectedOEM.pipelineModules ? selectedOEM.pipelineModules : []}
                                                        classNamePrefix={`${errorFields["pipelineModules"]} basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    //isDisabled={!newOEM}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label >Theme</label>
                                                    <ReactSelect
                                                        options={brandThemes}
                                                        name={"theme"}
                                                        placeholder={'select theme'}
                                                        onChange={(e) => {
                                                            setSelectedOEM({
                                                                ...selectedOEM,
                                                                ['theme']: e ? e.value : null
                                                            })
                                                        }}
                                                        value={selectedOEM.theme}
                                                        classNamePrefix={` basic-select`}
                                                        isSearchable={true}
                                                    //isDisabled={!newOEM}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label >Data Region</label>
                                                    <ReactSelect
                                                        options={projects}
                                                        name={"projectId"}
                                                        placeholder={'select region'}
                                                        onChange={(e) => {
                                                            setSelectedOEM({
                                                                ...selectedOEM,
                                                                ['projectId']: e ? e.value : null
                                                            })
                                                        }}
                                                        value={selectedOEM.projectId}
                                                        classNamePrefix={`${errorFields["projectId"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className='form-group col-md-12 mt-1'> <span className="switch switch-sm mr-2">
                                                    <InputCheckBox
                                                        id="mvwReport-settings"
                                                        className="switch"
                                                        name={'mvwReport'}
                                                        checked={Boolean(selectedOEM.mvwReport)}
                                                        onChange={(e) => {
                                                            setSelectedOEM({
                                                                ...selectedOEM,
                                                                ['mvwReport']: e.target.checked
                                                            })
                                                        }} />
                                                    <label htmlFor="mvwReport-settings">Materialized view Reporting</label>
                                                </span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <span className={`switch switch-sm float-left mt-1`}>
                        <InputCheckBox
                            className={`switch`}
                            id={`oem-liteVersion`}
                            name={'liteVersion'}
                            checked={selectedOEM.liteVersion ? selectedOEM.liteVersion : false}
                            onChange={(e) => { handleCheckedChange(e) }} />
                        <label htmlFor={`oem-liteVersion`}>Lite Version</label>
                    </span>
                    <span className={`switch switch-sm float-left mt-1`}>
                        <InputCheckBox
                            className={`switch`}
                            id={`oem-isMclaren`}
                            name={'isMclaren'}
                            checked={selectedOEM.isMclaren ? selectedOEM.isMclaren : false}
                            onChange={(e) => { handleCheckedChange(e) }} />
                        <label htmlFor={`oem-isMclaren`}>Mclaren Version</label>
                    </span>
                    <span className={`switch switch-sm float-left mt-1`}>
                        <InputCheckBox
                            className={`switch`}
                            id={`oem-isPorsche`}
                            name={'isPorsche'}
                            checked={selectedOEM.isPorsche ? selectedOEM.isPorsche : false}
                            onChange={(e) => { handleCheckedChange(e) }} />
                        <label htmlFor={`oem-isPorsche`}>Porsche Version</label>
                    </span>
                    <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveOEM(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        Save
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddOEM;