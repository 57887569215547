import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { ReactMultiSelect } from "../../components";
import { pipelineCountVMNEW, defaultStatsIcons, widgetSettingsNames } from './viewModel'
// import _images from '../../images'
import CommonHelper from '../../services/common';
import { PopUpModal } from '../../components'
import WidgetSettings from '../dashboard/widgetSettingsModal'
import Translate from '../../constants/translate';
import { Dropdown } from 'react-bootstrap'
import { CustomToggle, CustomFilter } from '../../components/customdropdown';
import DynamicFilters from '../reports/dynamicFiltersNew';

const PipelineCounts = (props) => {

    const [counts, setCounts] = useState(pipelineCountVMNEW)
    const [pipelineOptions, setPipelineOption] = useState([])
    const [todayStart, setTodayStart] = useState(window.firebase.firestore.Timestamp.fromDate(moment().startOf('day').toDate()))
    const [todayEnd, setTodayEnd] = useState(window.firebase.firestore.Timestamp.fromDate(moment().endOf('day').toDate()))
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [loader, setLoader] = useState(false);
    const node = useRef();
    const [filterShow, setShow] = useState(false)
    const [objFilter, setObjFilter] = useState({});
    const [showCount, setShowCount] = useState(false)
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const [dealersCount, setDealersCount] = useState([])

    // const statisFilters = [
    //     'daterange',
    //     'customPipeline',
    //     'makes',
    //     'salesType',
    //     'user'
    // ];

    const statisFilters = [
        { label: 'Date', value: 'daterange', displayValue: 'daterange' },
        { label: 'Sales Consultant', value: 'user', displayValue: 'user' },
        //{ label: 'Make and Models', value: 'makeModels', displayValue: 'make' },
        { label: 'Modal Interest', value: 'models', displayValue: 'model' },
        { label: 'Campaign ID', value: 'campaigns', displayValue: 'campaign' },
        { label: 'Origin', value: 'origins', displayValue: 'origin' },
        { label: 'Enquiry Type', value: 'enquiryTypes', displayValue: 'enquiryType' },
        { label: 'Lead Source', value: 'leadSource', displayValue: 'leadSource' },
        { label: 'Pipelines', value: 'pipelines', displayValue: 'pipeline' },
        //{ label: 'custom Pipeline', value: 'customPipeline', displayValue: 'pipeline' },

    ]

    useEffect(() => {
        if (!_.isEmpty(props.dashboardFilter)) {
            setObjFilter(props.dashboardFilter);
            setShowCount(true);
        }
    }, [])

    const ownerDashboard = props?.dealersettings?.rolePermissions?.permissions?.ownerDashboard ? true : false

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);

    const enquiryStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.settings) &&
        props.dealersettings.client.settings.enquiryStatus) ? props.dealersettings.client.settings.enquiryStatus : []);

    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? true : false);

    // useEffect(() => {
    //     if (!_.isEmpty(props.dealersettings.client.timezone)) {
    //         setTodayStart(window.firebase.firestore.Timestamp.fromDate(moment().tz(props.dealersettings.client.timezone).startOf('day')._d))
    //         setTodayEnd(window.firebase.firestore.Timestamp.fromDate(moment().tz(props.dealersettings.client.timezone).endOf('day')._d))
    //     }
    // }, [])
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);

    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-pipelineStats`);
    const enquiryOptionsStatic = props.dealersettings.client.settings.enquiryOptionsStatic;

    //sameer - 03-09-24 for static filter
    // useEffect(() => {
    //     document.addEventListener("mousedown", handleClick);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClick);
    //         window.unsubpipelinestatsSnap && window.unsubpipelinestatsSnap();
    //     }
    // }, []);

    // const handleClick = e => {
    //     if ((node && node.current && node.current.contains(e.target))
    //         || e.target.classList.contains('ant-picker-cell-inner')
    //         || (e?.target?.classList?.value && e.target.classList.value.includes('ant-picker'))
    //         || e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')) {
    //         return;
    //     }
    //     setShow(false);
    // };

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchData();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return

        setLoader(true)
        window.unsubpipelinestatsSnap = docPathRef
            .onSnapshot(snap => {
                if (snap.exists && snap.data().counts) {
                    setLoader(false);
                    setInitialLoad(false)
                    const _counts = _.isArray(snap.data().counts) ? snap.data().counts : [snap.data().counts];
                    setDealersCount(_counts);
                    setCounts(getTotalCounts(_counts));
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    useEffect(() => {
        if (initialLoad)
            return;
        if (props.fromOEM) {
            setCounts(getTotalCounts(dealersCount.filter(a => props?.dashboardFilter?.clients?.length > 0 ? props.dashboardFilter.clients.includes(a.clientID) : true)))
        } else {
            fetchData()
        }

    }, [props.dashboardFilter])

    const fetchData = async (hideLoader) => {
        try {
            if (!hideLoader)
                setLoader(true);
            let dataToSet = []
            let objToPass = {
                "timezone": !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
                "clientIDs": CommonHelper.getAllDealers(props.dealersettings),
                "filters": {}
            }

            if (props?.dashboardFilter?.pipelines?.length > 0) {
                objToPass.filters.pipeline = props.dashboardFilter.pipelines.join(',')
            } else if (!_.isEmpty(props.pipeline) && !props.fromOEM) {
                if (inclInboundLeadsinRpt)
                    objToPass.filters.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
                else
                    objToPass.filters.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
            }
            if (props?.dashboardFilter?.user?.length > 0) {
                objToPass.filters.owner = props.dashboardFilter.user.join(',')
            }
            if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
                objToPass.filters.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
                objToPass.filters.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
            }

            if (props.ownerDashboard) {
                objToPass.filters.owner = localStorage.uid
            }

            if (props?.dashboardFilter?.salesType?.length > 0) {
                objToPass.filters.saleType = props.dashboardFilter.salesType.join(',')
            } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
                objToPass.filters.saleType = props.dealersettings.accessSalesType.join(',');
            }

            let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
            if (!_.isEmpty(excludeOrigins))
                objToPass.filters.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

            if (props?.dashboardFilter?.makes?.length > 0) {
                objToPass.filters.make = props.dashboardFilter.makes.join(',')
            }

            if (calcCurrentStatus) {
                objToPass.filters.calcCurrentStatus = 'true'
            }

            if (props.fromOEM) {
                objToPass.filters.inclInboundBuckeinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundBuckeinRpt;
                objToPass.filters.inclInboundLeadsinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundLeadsinRpt;
                objToPass.array = true;
            }

            // New Filters Added for Porsche - SaMeeR - 05-09-24
            if (props?.dashboardFilter?.models?.length > 0) objToPass.filters.model = props.dashboardFilter.models.join(',')
            if (props?.dashboardFilter?.campaigns?.length > 0) objToPass.filters.campaign = props.dashboardFilter.campaigns.join(',')
            if (props?.dashboardFilter?.origins?.length > 0) objToPass.filters.origin = props.dashboardFilter.origins.join(',')
            if (props?.dashboardFilter?.enquiryTypes?.length > 0) objToPass.filters.enquiryType = props.dashboardFilter.enquiryTypes.join(',')
            if (props?.dashboardFilter?.leadSource?.length > 0) objToPass.filters.leadSource = props.dashboardFilter.leadSource.join(',')


            objToPass.filters.walkins = true;
            objToPass.filters.isPorsche = true;
            objToPass.filters.outdated = true;
            if (props?.dealersettings?.mvwReport) {
                objToPass.v2 = true;
            }
            const getDashboardCounts = window.firebase.functions().httpsCallable('generic-getOEMDashboardCounts');
            const resp = await getDashboardCounts(objToPass)

            if (resp.data.success) {
                //console.log('data returned', resp.data.data)
                const data = resp.data.data
                dataToSet = _.isArray(data) ? data : [data];
            } else {
                // console.log('data not returned')
            }
            setDealersCount(dataToSet)
            const _counts = getTotalCounts(dataToSet);
            setCounts(_counts)
            setLoader(false)
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({ counts: props.fromOEM ? dataToSet : dataToSet[0], logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            else {
                setRefreshTime(moment().unix());
            }
        } catch (error) {
            console.error(error)
            setLoader(false)
        }
    }

    const getTotalCounts = (countsByClients) => {
        let _dynStatus = {};
        if (enquiryStatus.length > 0) {
            enquiryStatus.filter(a => !Boolean(a.default)).forEach(rec => {
                _dynStatus = {
                    ..._dynStatus,
                    [`month${rec.value}`]: _.sumBy(countsByClients, `month${rec.value}`) ? _.sumBy(countsByClients, `month${rec.value}`) : 0,
                    [`today${rec.value}`]: _.sumBy(countsByClients, `today${rec.value}`) ? _.sumBy(countsByClients, `today${rec.value}`) : 0,
                }
            })
        }
        let _counts = {
            todaynew: _.sumBy(countsByClients, 'todayNew') ? _.sumBy(countsByClients, 'todayNew') : 0,
            monthnew: _.sumBy(countsByClients, 'monthNew') ? _.sumBy(countsByClients, 'monthNew') : 0,
            todayopen: _.sumBy(countsByClients, 'todayOpen') ? _.sumBy(countsByClients, 'todayOpen') : 0,
            monthopen: _.sumBy(countsByClients, 'monthOpen') ? _.sumBy(countsByClients, 'monthOpen') : 0,
            todaywon: _.sumBy(countsByClients, 'todayWon') ? _.sumBy(countsByClients, 'todayWon') : 0,
            monthwon: _.sumBy(countsByClients, 'monthWon') ? _.sumBy(countsByClients, 'monthWon') : 0,
            todaylost: _.sumBy(countsByClients, 'todayLost') ? _.sumBy(countsByClients, 'todayLost') : 0,
            monthlost: _.sumBy(countsByClients, 'monthLost') ? _.sumBy(countsByClients, 'monthLost') : 0,
            todaydelivered: _.sumBy(countsByClients, 'todayDelivered') ? _.sumBy(countsByClients, 'todayDelivered') : 0,
            monthdelivered: _.sumBy(countsByClients, 'monthDelivered') ? _.sumBy(countsByClients, 'monthDelivered') : 0,
            todaytestdrive: _.sumBy(countsByClients, 'todayTestdrive') ? _.sumBy(countsByClients, 'todayTestdrive') : 0,
            monthtestdrive: _.sumBy(countsByClients, 'monthTestdrive') ? _.sumBy(countsByClients, 'monthTestdrive') : 0,
            todaytradein: _.sumBy(countsByClients, 'todayTradein') ? _.sumBy(countsByClients, 'todayTradein') : 0,
            monthtradein: _.sumBy(countsByClients, 'monthTradein') ? _.sumBy(countsByClients, 'monthTradein') : 0,
            todaydelivery: _.sumBy(countsByClients, 'todayDelivery') ? _.sumBy(countsByClients, 'todayDelivery') : 0,
            monthdelivery: _.sumBy(countsByClients, 'monthDelivery') ? _.sumBy(countsByClients, 'monthDelivery') : 0,
            pendingdelivery: _.sumBy(countsByClients, 'overallDelivery') ? _.sumBy(countsByClients, 'overallDelivery') : 0,
            todaypendingdelivery: _.sumBy(countsByClients, 'overallDelivery') ? _.sumBy(countsByClients, 'overallDelivery') : 0,
            monthpendingdelivery: _.sumBy(countsByClients, 'overallDelivery') ? _.sumBy(countsByClients, 'overallDelivery') : 0,
            todayActivity: _.sumBy(countsByClients, 'todayActivity') ? _.sumBy(countsByClients, 'todayActivity') : 0,
            monthActivity: _.sumBy(countsByClients, 'monthActivity') ? _.sumBy(countsByClients, 'monthActivity') : 0,
            overdueActivity: _.sumBy(countsByClients, 'overdueActivity') ? _.sumBy(countsByClients, 'overdueActivity') : 0,
            todayscheduledAppointments: _.sumBy(countsByClients, 'todayScheduled') ? _.sumBy(countsByClients, 'todayScheduled') : 0,
            monthscheduledAppointments: _.sumBy(countsByClients, 'monthScheduled') ? _.sumBy(countsByClients, 'monthScheduled') : 0,
            outdated: _.sumBy(countsByClients, 'outdated') ? _.sumBy(countsByClients, 'outdated') : 0,
            todayoutdated: _.sumBy(countsByClients, 'outdated') ? _.sumBy(countsByClients, 'outdated') : 0,
            monthoutdated: _.sumBy(countsByClients, 'outdated') ? _.sumBy(countsByClients, 'outdated') : 0,
            outdatedLeads: _.sumBy(countsByClients, 'outdatedLeads') ? _.sumBy(countsByClients, 'outdatedLeads') : 0,
            todayoutdatedLeads: _.sumBy(countsByClients, 'outdatedLeads') ? _.sumBy(countsByClients, 'outdatedLeads') : 0,
            monthoutdatedLeads: _.sumBy(countsByClients, 'outdatedLeads') ? _.sumBy(countsByClients, 'outdatedLeads') : 0,
            todaywalkin: _.sumBy(countsByClients, 'todayWalkin') ? _.sumBy(countsByClients, 'todayWalkin') : 0,
            monthwalkin: _.sumBy(countsByClients, 'monthWalkin') ? _.sumBy(countsByClients, 'monthWalkin') : 0,
            todaynewCAP: _.sumBy(countsByClients, 'todayNewCAP') ? _.sumBy(countsByClients, 'todayNewCAP') : 0,
            monthnewCAP: _.sumBy(countsByClients, 'monthNewCAP') ? _.sumBy(countsByClients, 'monthNewCAP') : 0,
            todayallocated: _.sumBy(countsByClients, 'todayAllocated') ? _.sumBy(countsByClients, 'todayAllocated') : 0,
            monthallocated: _.sumBy(countsByClients, 'monthAllocated') ? _.sumBy(countsByClients, 'monthAllocated') : 0,
            ..._dynStatus
        }
        _counts.todaypendingCAP = _counts.todaynew - _counts.todaynewCAP;
        _counts.monthpendingCAP = _counts.monthnew - _counts.monthnewCAP;
        _counts.todayleadConversion = (_counts.todaywon + _counts.todaylost) > 0 ? `${Math.round((100 / (_counts.todaywon + _counts.todaylost)) * _counts.todaywon)}%` : '0%';
        _counts.monthleadConversion = (_counts.monthwon + _counts.monthlost) > 0 ? `${Math.round((100 / (_counts.monthwon + _counts.monthlost)) * _counts.monthwon)}%` : '0%';
        return _counts;
    }

    useEffect(() => {
        if (_.isEmpty(props.pipeline))
            return
        let _pipeline = [];
        const ownDashboard = (!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false;
        props.pipeline.filter(item => (inclInboundLeadsinRpt ? true : item.value !== 'LeadsBucket') && !Boolean(item.disableReport)).forEach(rec => {
            _pipeline.push({
                label: rec.name,
                active: _.isBoolean(rec.active) ? rec.active : true,
                value: rec.value,
                data: rec
            })
        })
        setPipelineOption(_pipeline)
        if (_pipeline.length === 1 && !ownDashboard && _.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0)
            props.setPipeline && props.setPipeline(_pipeline[0])
    }, [props.pipeline])

    const filterCounts = () => {

        var FilterCount = props.dashboardFilter ? Object.keys(_.pickBy(props.dashboardFilter, function (value, key) {
            return !_.isEmpty(value);
        })) : [];

        return (FilterCount.length > 0 && showCount) ? (<span className="badge badge-pill badge-new-filterdash">{FilterCount.length}</span>) : (<></>)
    }

    // const setMultibjFilter = (selectedOptions, name) => {
    //     let value = [];
    //     !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
    //         value.push(data.value)
    //     })
    //     let _objFilter = objFilter;
    //     if (name === 'region') {
    //         _objFilter = {
    //             ..._objFilter,
    //             [name]: value,
    //             ['subregion']: [],
    //             ['country']: [],
    //             ['dealer']: [],
    //         };
    //     } else if (name === 'subregion') {
    //         _objFilter = {
    //             ..._objFilter,
    //             [name]: value,
    //             ['country']: [],
    //             ['dealer']: [],
    //         };
    //     } else if (name === 'country') {
    //         _objFilter = {
    //             ..._objFilter,
    //             [name]: value,
    //             ['dealer']: [],
    //         };
    //     } else {
    //         _objFilter = {
    //             ..._objFilter,
    //             [name]: value,
    //         };
    //     }
    //     _objFilter.clients = props?.dealersettings?.clients ? props.dealersettings.clients.filter(client =>
    //         (_objFilter?.region?.length > 0 ? _objFilter.region.includes(client.region) : true) &&
    //         (_objFilter?.subregion?.length > 0 ? _objFilter.subregion.includes(client.subregion) : true) &&
    //         (_objFilter?.country?.length > 0 ? _objFilter.country.includes(client.country) : true) &&
    //         (_objFilter?.dealer?.length > 0 ? _objFilter.dealer.includes(client.documentID) : true)
    //     ).map(r => { return r.id }) : []
    //     setObjFilter(_objFilter);
    //     props.setDashboardFilter(_objFilter);
    // }


    // const getRegions = () => {
    //     let regionsToReturn;
    //     let filteredRegions;

    //     filteredRegions = props.dealersettings.regions.filter(region => {
    //         if (props.dealersettings.clients.some(client => client.region === region.code)) {
    //             return true
    //         }
    //         return false;
    //     })

    //     regionsToReturn = filteredRegions.map(e => {
    //         return { label: e.name, value: e.code };
    //     })

    //     return regionsToReturn
    // }

    // const getSubregions = () => {
    //     let regionsToReturn;
    //     let filteredSubregions;

    //     filteredSubregions = props.dealersettings.subregions.filter(subregion => {
    //         if (props.dealersettings.clients.some(client => (client.subregion === subregion.code) &&
    //             (!_.isEmpty(objFilter['region']) ? objFilter['region'].includes(client.region) : true))) {
    //             return true
    //         }
    //         return false;
    //     })

    //     regionsToReturn = filteredSubregions.map(e => {
    //         return { label: e.name, value: e.code };
    //     })

    //     return regionsToReturn
    // }

    // const getCountries = () => {
    //     let countriesToReturn;
    //     let filteredCountries;

    //     filteredCountries = props.dealersettings.countries.filter(country => {
    //         if (props.dealersettings.clients.filter(client => (
    //             (!_.isEmpty(objFilter['region']) ? objFilter['region'].includes(client.region) : true) &&
    //             (!_.isEmpty(objFilter['subregion']) ? objFilter['subregion'].includes(client.subregion) : true)
    //         )).some(client =>
    //             (client.country === country.code)
    //         )) {
    //             return true
    //         }
    //         return false;
    //     })

    //     countriesToReturn = filteredCountries.map(e => {
    //         return { label: e.name, value: e.code };
    //     })

    //     return countriesToReturn
    // }

    // const getDealers = () => {
    //     let clientsToReturn = props.dealersettings.clients;

    //     if (!_.isEmpty(objFilter['region'])) {
    //         clientsToReturn = clientsToReturn.filter(client =>
    //             objFilter['region'].includes(client.region)
    //         );
    //     }

    //     if (!_.isEmpty(objFilter['subregion'])) {
    //         clientsToReturn = clientsToReturn.filter(client =>
    //             objFilter['subregion'].includes(client.subregion)
    //         );
    //     }

    //     if (!_.isEmpty(objFilter['country'])) {
    //         clientsToReturn = clientsToReturn.filter(client =>
    //             objFilter['country'].includes(client.country)
    //         );
    //     }

    //     clientsToReturn = clientsToReturn.map(client => {
    //         return {
    //             value: client.documentID,
    //             doc: client,
    //             label: client.name,
    //             active: _.isBoolean(client.active) ? client.active : true,
    //         };
    //     })

    //     return clientsToReturn
    // }


    return (<>
        <div className="dashboard-panel-top">
            {loader && <div className='custom-counts-loader' />}

            <div className="dashboard-panel-head-custom dahsboardHeadwithAdvancedFilter">
                <div className="float-left filTitle">
                    <div className="d-flex">
                        {
                            !_.isEmpty(props.selectedPipeline) ? (<h3>{props.selectedPipeline.label} Stats</h3>) : (<h3><Translate text={'allPipelineStats'} /></h3>)
                        }
                        {loader && <div className="align-items-center d-flex justify-content-center text-primary spinner-loader-h3"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div>}
                    </div>
                    <div className="dashboard-panel-subhead-main">
                        <div className="float-left">
                            <div className="dash-head-currentdate">
                                {
                                    props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate ?
                                        `${moment(props.dashboardFilter.daterange.startDate.toDate()).format('DD MMM YYYY')} to ${moment(props.dashboardFilter.daterange.endDate.toDate()).format('DD MMM YYYY')}` :
                                        moment().format('MMMM YYYY')
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightFilterOptions">
                    <div className="float-right advancedFilter">
                        <div className="dropdown">
                            <div className="advanced-filter-btn float-right">
                                <a href="#" className="dropdown dropdown-toggle" onClick={(e) => {
                                    e.preventDefault();
                                    setShow(!filterShow)
                                }}>
                                    <div className="position-relative">
                                        {filterCounts()}
                                        <i className="ico icon-filter"></i><Translate text={'Filters'} />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    {
                        refreshTime > 0 ? (
                            <div className="dash-head-refreshdate">
                                {moment.unix(refreshTime).format('DD-MM-YYYY h:mm A')}
                            </div>
                        ) : (<></>)
                    }

                    <div className="float-right settingsMain">
                        <div className="dashboard-panel-subhead-main">
                            <div className="float-left">  </div>
                        </div>
                        <div className="dash-widget-settings-wrap">
                            <a href="#" className="dash-mini-button" onClick={(e) => {
                                e.preventDefault();
                                fetchData();
                                props.setRefreshData();
                            }}> <i className="ico icon-refresh"></i></a>
                        </div>
                        {
                            liteVersion || props.fromOEM === true || props.hideSettings ? <></> : (<div className="dash-widget-settings-wrap">
                                <a href="#" className="dropdown-toggle" onClick={props.handleOpenWidgetSettings}>
                                    <i className="ico icon-settings"></i>
                                </a>
                            </div>)
                        }

                    </div>
                </div>
            </div>

            {
                filterShow && (<div className="dashboard-panel-body-filter form-style">
                    <div className="form-row">
                        {
                            statisFilters.filter(a => !ownerDashboard ? true : a.value !== 'user').map((rec, i) => {
                                return <div className={`form-group col-md-2 ${rec.value === 'daterange' ? 'daterange-fix' : ''}`}><DynamicFilters
                                    key={i}
                                    {...props}
                                    filter={rec.value}
                                    filterLabel={CommonHelper.getStaticFieldName(enquiryOptionsStatic, rec.displayValue, rec.label, props)}
                                    objFilter={objFilter}
                                    defaultMake={true}
                                    columns={[]}
                                    pipelineOptions={pipelineOptions}
                                    setObjFilter={(e, name) => {
                                        setObjFilter({
                                            ...objFilter,
                                            [name]: e ? e.value : ''
                                        })
                                    }}
                                    setMultibjFilter={(selectedOptions, name) => {
                                        let value = [];
                                        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                            value.push(data.value)
                                        })
                                        setObjFilter({
                                            ...objFilter,
                                            [name]: value
                                        });
                                    }}
                                    handleDateRangeChange={(val, name) => {
                                        if (val) {
                                            setObjFilter({
                                                ...objFilter,
                                                [name]: {
                                                    startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                                                    endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
                                                }
                                            });
                                        }
                                        else {
                                            setObjFilter({
                                                ...objFilter,
                                                [name]: null
                                            });
                                        }
                                    }}
                                />
                                </div>
                            })
                        }

                        <div className="form-group col-md-2">
                            <div className="dashboard-panel-filter-button float-left">
                                <button type="button" className="btn btn-default float-right ml-3" onClick={() => {
                                    //setShow(false)
                                    setShowCount(false)
                                    setObjFilter({})
                                    props.setDashboardFilter({});
                                    setInitialLoad(true)
                                }}><Translate text={'clear'} /></button>
                                <button type="button" className="btn btn-primary float-right" onClick={() => {
                                    // setRefreshData(true)
                                    //setShow(false)
                                    //console.log('objFilter', objFilter)
                                    setShowCount(true)
                                    props.setDashboardFilter(objFilter);
                                    window.unsubpipelinestatsSnap && window.unsubpipelinestatsSnap();
                                }}><Translate text={'apply'} /></button>
                            </div>
                        </div>



                    </div>

                </div>)}

            {/* <div className="dashboard-panel-head-custom">
                <div className="float-left d-flex">
                    {
                        !_.isEmpty(props.selectedPipeline) ? (<h3>{props.selectedPipeline.label} Stats</h3>) : (<h3><Translate text={'allPipelineStats'} /></h3>)
                    }
                    {loader && <div className="align-items-center d-flex justify-content-center text-primary spinner-loader-h3"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div>}
                </div>
                <div className="dash-widget-settings-wrap">
                    <a href="#" className="dash-mini-button" onClick={(e) => {
                        e.preventDefault();
                        fetchData();
                        props.setRefreshData();
                    }}> <i className="ico icon-refresh"></i></a>
                </div>
                {
                    liteVersion || props.fromOEM === true || props.hideSettings ? <></> : (<div className="dash-widget-settings-wrap">
                        <a href="#" className="dropdown-toggle" onClick={props.handleOpenWidgetSettings}>
                            <i className="ico icon-settings"></i>
                        </a>
                    </div>)
                }
                <div className="float-lg-right form-dashboard">
                    {
                        props.fromOEM === true ? (<div className="form-row justify-content-end">
                            <div className="form-group col-md-3">
                                <ReactMultiSelect
                                    options={getRegions()}
                                    name={'region'}
                                    placeholder={`select region`}
                                    onChange={(e) => {
                                        setMultibjFilter(e, 'region')
                                    }}
                                    value={objFilter['region']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                            <div className="form-group col-md-3">
                                <ReactMultiSelect
                                    options={getSubregions()}
                                    name={'subregion'}
                                    placeholder={`select subregion`}
                                    onChange={(e) => {
                                        setMultibjFilter(e, 'subregion')
                                    }}
                                    value={objFilter['subregion']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                            <div className="form-group col-md-3">

                                <ReactMultiSelect
                                    options={getCountries()}
                                    name={'country'}
                                    placeholder={`select country`}
                                    onChange={(e) => {
                                        setMultibjFilter(e, 'country')
                                    }}
                                    value={objFilter['country']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                            <div className="form-group col-md-3">

                                <ReactMultiSelect
                                    options={getDealers()}
                                    name={'dealer'}
                                    placeholder={`select dealer`}
                                    onChange={(e) => {
                                        setMultibjFilter(e, 'dealer')
                                    }}
                                    value={objFilter['dealer']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                        </div>) : (
                            <div className="float-right">
                                <Dropdown ref={node} alignRight show={filterShow}>
                                    <div className="advanced-filter-btn mr-2 float-right">
                                        <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                            setShow(!filterShow)
                                        }}>
                                            {filterCounts()}
                                            <i className="ico icon-filter"></i>Filters
                                        </Dropdown.Toggle>
                                    </div>
                                    <Dropdown.Menu as={CustomFilter}
                                        id="table-reports-cols-settings-pipeline"
                                        className="dropdown-menu dropdown-menu-left"
                                        ChildClass="form-style quick-filter-wrap"
                                        xplacement="bottom-end"
                                    >
                                        <div className="quick-filter-body mt-1 h-100 overflow-inherit">
                                            <div className="report-filter-wraper form-style">
                                                {
                                                    statisFilters.filter(a => !ownerDashboard ? true : a !== 'user').map((rec, i) => {
                                                        return <DynamicFilters
                                                            key={i}
                                                            {...props}
                                                            filter={rec}
                                                            objFilter={objFilter}
                                                            columns={[]}
                                                            pipelineOptions={pipelineOptions}
                                                            setObjFilter={(e, name) => {
                                                                setObjFilter({
                                                                    ...objFilter,
                                                                    [name]: e ? e.value : ''
                                                                })
                                                            }}
                                                            setMultibjFilter={(selectedOptions, name) => {
                                                                let value = [];
                                                                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                                    value.push(data.value)
                                                                })
                                                                setObjFilter({
                                                                    ...objFilter,
                                                                    [name]: value
                                                                });
                                                            }}
                                                            handleDateRangeChange={(val, name) => {
                                                                if (val) {
                                                                    setObjFilter({
                                                                        ...objFilter,
                                                                        [name]: {
                                                                            startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                                                                            endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
                                                                        }
                                                                    });
                                                                }
                                                                else {
                                                                    setObjFilter({
                                                                        ...objFilter,
                                                                        [name]: null
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="quick-filter-footer">
                                            <button type="button" className="btn btn-default float-left" onClick={() => {
                                                setShow(false)
                                                setShowCount(false)
                                                setObjFilter({})
                                                props.setDashboardFilter({});
                                                setInitialLoad(true)
                                            }}>Clear</button>
                                            <button type="button" className="btn btn-primary float-right" onClick={() => {
                                                // setRefreshData(true)
                                                setShow(false)
                                                setShowCount(true)
                                                props.setDashboardFilter(objFilter);
                                                window.unsubpipelinestatsSnap && window.unsubpipelinestatsSnap();
                                            }}>Apply</button>

                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )
                    }
                </div>
                <div className="dashboard-panel-subhead-main">
                    <div className="float-left">
                        <div className="dash-head-currentdate">
                            {
                                props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate ?
                                    `${moment(props.dashboardFilter.daterange.startDate.toDate()).format('DD MMM YYYY')} to ${moment(props.dashboardFilter.daterange.endDate.toDate()).format('DD MMM YYYY')}` :
                                    moment().format('MMMM YYYY')
                            }
                        </div>
                    </div>
                    {
                        refreshTime > 0 ? (
                            <div className="float-right">
                                <div className="dash-head-refreshdate">
                                    {moment.unix(refreshTime).format('DD/MM/YYYY h:mm A')}
                                </div>
                            </div>
                        ) : (<></>)
                    }
                </div>
            </div> */}


            <div className="dashboard-panel-body">
                <div className="dashboard-custom-tileswrapper">
                    {
                        _.chunk(defaultStatsIcons, 9).map((stats, i) => {
                            return <ul key={i} className={`${i > 0 ? 'mt-2' : ''}`}>
                                {
                                    stats.map((rec, index) => {
                                        let _stsLength = i === 0 && stats.length <= 9 ? stats.length : 9;
                                        return <li key={index} style={{ flex: `0 0 ${100 / _stsLength}%`, maxWidth: `${100 / _stsLength}%`, float: `left` }}>
                                            <div className="dashicon-fix">
                                                <div className="dashboard-module-loop dashboard-module-flex">
                                                    <div className="dashboard-module-icon">
                                                        <i className={`ico ${rec.icon}`} style={rec.color ? { color: `${rec.color}` } : {}}></i>
                                                    </div>
                                                    <div className="dashboard-module-label">{CommonHelper.showLocale(props, rec.name)}</div>
                                                    <div className="dashboard-module-count-wraper">
                                                        {
                                                            rec?.subList?.length ? (
                                                                <div className="row">
                                                                    {
                                                                        rec.subList.map((r, i) => {
                                                                            return <div key={i} className="col-lg-6" onClick={() => {
                                                                                props.handleShowEnquiryList({
                                                                                    emptyCap: !Boolean(r.cap),
                                                                                    startDate: monthStart,
                                                                                    endDate: monthEnd,
                                                                                    status: 'outdated',
                                                                                }, CommonHelper.showLocale(props, r.name), counts[r.value])
                                                                            }}>
                                                                                <div className="dashboard-count-title"> <Translate text={r.name} /></div>
                                                                                <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts[`${r.value}`]}</a></div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className=" row">
                                                                    {
                                                                        props.applydateRange === false ? (
                                                                            <div className="col-lg-6" onClick={() => {
                                                                                if (counts[`today${rec.value}`] && rec.value !== 'leadConversion') {
                                                                                    if (rec.value === 'testdrive') {
                                                                                        props.handleShowTestDriveList({
                                                                                            startDate: todayStart,
                                                                                            endDate: todayEnd,
                                                                                            dateType: 'startDateValue'
                                                                                        }, (props.applydateRange === false ? CommonHelper.showLocale(props, 'Test Drive Leads') : CommonHelper.showLocale(props, 'Test Drive Leads')), counts.monthtestdrive)
                                                                                    }
                                                                                    else if (rec.value === 'tradein') {
                                                                                        props.handleShowTradeinList({
                                                                                            startDate: todayStart,
                                                                                            endDate: todayEnd,
                                                                                            dateType: 'addedDateValue'
                                                                                        }, (props.applydateRange === false ? CommonHelper.showLocale(props, 'Trade-In Leads') : CommonHelper.showLocale(props, 'Trade-In Leads')), counts.monthtradein)
                                                                                    }
                                                                                    else if (rec.value === 'delivery') {
                                                                                        props.handleShowEnquiryList({
                                                                                            startDate: todayStart,
                                                                                            endDate: todayEnd,
                                                                                            dateType: 'deliveryDate'
                                                                                        }, (props.applydateRange === false ? CommonHelper.showLocale(props, 'Pending Deliveries') : CommonHelper.showLocale(props, 'Pending Deliveries')), counts.monthdelivery)
                                                                                    }
                                                                                    else if (rec.value === 'scheduledAppointments') {
                                                                                        props.handleShowActivityList({
                                                                                            startDate: todayStart,
                                                                                            endDate: todayEnd,
                                                                                            dateType: 'startDate',
                                                                                            subType: 'appointment',
                                                                                            isDone: 'false'
                                                                                        }, (props.applydateRange === false ? CommonHelper.showLocale(props, 'Today Scheduled Appointments') : CommonHelper.showLocale(props, 'scheduledAppointments')), counts.monthscheduledAppointments)
                                                                                    }
                                                                                    else if (rec.value === 'walkin') {
                                                                                        props.handleShowEnquiryList({
                                                                                            startDate: todayStart,
                                                                                            endDate: todayEnd,
                                                                                            type: 'walkin',
                                                                                        }, (props.applydateRange === false ? CommonHelper.showLocale(props, 'Today Showroom Visits') : CommonHelper.showLocale(props, 'Today Showroom Visits')), counts.monthWalkin)
                                                                                    }
                                                                                    else {
                                                                                        props.handleShowEnquiryList({
                                                                                            cap: rec.cap,
                                                                                            status: rec.value === 'new' || rec.value === 'newCAP' ? 'all' : rec.value,
                                                                                            startDate: todayStart,
                                                                                            endDate: todayEnd,
                                                                                            dateType: rec.value === 'newCAP' ? 'dmsDate' : (rec.value === 'new' || rec.value === 'allocated' || rec.value === 'pendingCAP' || rec.value === 'open') ? 'addedDate' : (rec.value === 'won' || rec.value === 'lost' || rec.value === 'delivered') ? `${rec.value}Date` : rec.value,
                                                                                            pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                                                        }, rec.name, counts[`month${rec.value}`])
                                                                                    }
                                                                                }
                                                                            }}>
                                                                                <div className="dashboard-count-title"> <Translate text={'today'} /></div>
                                                                                <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts[`today${rec.value}`]}</a></div>
                                                                            </div>
                                                                        ) : (<></>)
                                                                    }
                                                                    <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                                        if (counts[`month${rec.value}`] && rec.value !== 'leadConversion') {
                                                                            if (rec.value === 'outdated' || rec.value === 'outdatedLeads') {
                                                                                props.handleShowEnquiryList({
                                                                                    emptyCap: !Boolean(rec.cap),
                                                                                    startDate: monthStart,
                                                                                    endDate: monthEnd,
                                                                                    status: 'outdated',
                                                                                }, CommonHelper.showLocale(props, rec.name), counts[rec.value])
                                                                            }
                                                                            else if (rec.value === 'pendingdelivery') {
                                                                                props.handleShowEnquiryList({
                                                                                    searchType: 'overallPending',
                                                                                    dateType: 'deliveryDate'
                                                                                }, CommonHelper.showLocale(props, 'Overall Pending Deliveries'), counts.pendingdelivery)
                                                                            }
                                                                            else if (rec.value === 'testdrive') {
                                                                                props.handleShowTestDriveList({
                                                                                    startDate: monthStart,
                                                                                    endDate: monthEnd,
                                                                                    dateType: 'startDateValue'
                                                                                }, (props.applydateRange === false ? `MTD ${CommonHelper.showLocale(props, 'Test Drive Leads')}` : CommonHelper.showLocale(props, 'Test Drive Leads')), counts.monthtestdrive)
                                                                            }
                                                                            else if (rec.value === 'tradein') {
                                                                                props.handleShowTradeinList({
                                                                                    startDate: monthStart,
                                                                                    endDate: monthEnd,
                                                                                    dateType: 'addedDateValue'
                                                                                }, (props.applydateRange === false ? `MTD ${CommonHelper.showLocale(props, 'Trade-In Leads')}` : CommonHelper.showLocale(props, 'Trade-In Leads')), counts.monthtradein)
                                                                            }
                                                                            else if (rec.value === 'delivery') {
                                                                                props.handleShowEnquiryList({
                                                                                    startDate: monthStart,
                                                                                    endDate: monthEnd,
                                                                                    dateType: 'deliveryDate'
                                                                                }, (props.applydateRange === false ? `MTD ${CommonHelper.showLocale(props, 'Pending Deliveries')}` : CommonHelper.showLocale(props, 'Pending Deliveries')), counts.monthdelivery)
                                                                            }
                                                                            else if (rec.value === 'scheduledAppointments') {
                                                                                props.handleShowActivityList({
                                                                                    startDate: monthStart,
                                                                                    endDate: monthEnd,
                                                                                    dateType: 'startDate',
                                                                                    subType: 'appointment',
                                                                                    isDone: 'false'
                                                                                }, (props.applydateRange === false ? `MTD ${CommonHelper.showLocale(props, 'scheduledAppointments')}` : CommonHelper.showLocale(props, 'scheduledAppointments')), counts.monthscheduledAppointments)
                                                                            }
                                                                            else if (rec.value === 'walkin') {
                                                                                props.handleShowEnquiryList({
                                                                                    startDate: monthStart,
                                                                                    endDate: monthEnd,
                                                                                    type: 'walkin',
                                                                                }, (props.applydateRange === false ? CommonHelper.showLocale(props, 'MTD Showroom Visits') : CommonHelper.showLocale(props, 'MTD Showroom Visits')), counts.monthWalkin)
                                                                            }
                                                                            else {
                                                                                props.handleShowEnquiryList({
                                                                                    cap: rec.cap,
                                                                                    status: rec.value === 'new' || rec.value === 'newCAP' ? 'all' : rec.value,
                                                                                    startDate: monthStart,
                                                                                    endDate: monthEnd,
                                                                                    dateType: rec.value === 'newCAP' ? 'dmsDate' : (rec.value === 'new' || rec.value === 'allocated' || rec.value === 'pendingCAP' || rec.value === 'open') ? 'addedDate' : (rec.value === 'won' || rec.value === 'lost' || rec.value === 'delivered') ? `${rec.value}Date` : rec.value,
                                                                                    pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                                                }, (props.applydateRange === false ? `MTD ${CommonHelper.showLocale(props, rec.name)}` : `${rec.name}`), counts[`month${rec.value}`])
                                                                            }

                                                                        }
                                                                    }}>
                                                                        {
                                                                            props.applydateRange === false ?
                                                                                (
                                                                                    <div className="dashboard-count-title">MTD</div>
                                                                                ) : (<></>)
                                                                        }
                                                                        <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts[`month${rec.value}`]}</a></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                        })
                    }

                </div>
            </div>
        </div >
        <PopUpModal show={props.showWidgetSettings}>
            <WidgetSettings
                {...props}
                show={props.showWidgetSettings}
                handleClose={props.handleCloseWidgetSettings}
                title={'Sales Dashboard Settings'}
                sortedWidgets={props.sortedWidgets}
                hiddenWidgets={props.hiddenWidgets}
                removeUnAllowedWidgets={props.removeUnAllowedWidgets}
                onLayoutChange={props.onLayoutChange}
                layout={props.layout}
                setLayout={props.setLayout}
                sortedStats={props.sortedStats}
                hiddenStats={props.hiddenStats}
                enquiryStatus={enquiryStatus}
                hideStats={true}
                widgetSettingsNames={widgetSettingsNames}
            ></WidgetSettings>
        </PopUpModal>
    </>
    );
}

export default PipelineCounts;