import React, { useState, useEffect, Fragment } from 'react'
import _ from 'lodash'
import moment from 'moment';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';

import CommonHelper from '../../../../services/common';
import { ContentOverlay } from '../../../../components';
import Translate from '../../../../constants/translate';
import { pipelineCountVM } from '../../../dashboard/viewModel'

import "../../../../styles/dailyActivity.scss";

const GulsonDailyActivityReport = (props) => {
    const [countsLoader, setCountsLoader] = useState(true)
    const [allCounts, setAllCounts] = useState([])
    const [data, setData] = useState(pipelineCountVM)
    const [pipelineEnquiries, setEnquiries] = useState([]);
    const [withoutReqCounts, setWithoutReqCounts] = useState(pipelineCountVM)
    const [mtdTestdrives, setTestdrives] = useState([]);
    const [client, setClient] = useState({});
    const [clientSettings, setClientSettings] = useState();
    const [inclInboundLeadsinRpt, setinclInboundLeadsinRpt] = useState(false)
    const [followupEnquiries, setFollowupEnquiries] = useState([]);
    const [allMakes, setAllMakes] = useState([]);
    const [tempDataLoader, settempDataLoader] = useState(true)

    const [dateRange, setDateRange] = useState({})

    const [tempData, setTempData] = useState({})


    const saleTypes = ['New', 'Used', 'Demo']

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`).get()
            .then(doc => {
                if (doc.exists && doc.data()?.projectId === window.secondaryProjectId) {
                    setFirestoreDB(window.firebase2)
                }
                else {
                    setFirestoreDB(window.firebase)
                }
            })
    }, [])
    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        if (props.clientid) {

            window.firebase.firestore().collection('clients').doc(props.clientid).get().then(clientSnap => {
                if (!clientSnap.exists) {
                    return
                }

                const clientData = clientSnap.data()
                const _inclInboundLeadsinRpt = ((
                    !_.isEmpty(clientData) &&
                    !_.isEmpty(clientData.clientSettings) && clientData.clientSettings.inclInboundLeadsinRpt) ? true : false);

                let search = window.location.search;
                let params = new URLSearchParams(search);
                let _date = params.get('date');
                _date = _date ? _date : moment.tz(clientData.timezone).format('YYYY-MM-DD');

                let _dateRange = {
                    date: _date,
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).startOf('month').toDate()),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).endOf('month').toDate()),
                    todayStart: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).startOf('day').toDate()),
                    todayEnd: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).endOf('day').toDate()),
                    weekStart: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).startOf('week').toDate()),
                    weekEnd: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).endOf('week').toDate()),
                }
                if (!_.isEmpty(clientData.weekStart)) {
                    moment.updateLocale('en', {
                        week: {
                            dow: moment().day(clientData.weekStart).day(),
                        },
                    })
                    _dateRange = {
                        ..._dateRange,
                        weekStart: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).startOf('week').toDate()),
                        weekEnd: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).endOf('week').toDate()),
                    }
                }
                _dateRange = {
                    ..._dateRange,
                    monthStart: _dateRange.startDate,
                    monthEnd: _dateRange.endDate
                }

                setDateRange(_dateRange)



                //LOST PENDING APPROVAL
                let lostPendingApproval = firestoreDB.firestore().collection('enquiries')
                    .where('clientID', '==', props.clientid)
                    .where('status', '==', 'pendingLost')
                    .where('isDeleted', '==', false)


                //MTD OPEN ENQUIRIES / CAMPAIGN /LEAD ORIGIN / ENQUIRY TYPE / LABEL  
                let refOpenEnquiryData = firestoreDB.firestore().collection('enquiries')
                    .where('clientID', '==', props.clientid)
                    .where('addedDate', '>=', _dateRange.startDate)
                    .where('addedDate', '<=', _dateRange.endDate)
                    .where('isDeleted', '==', false)

                //MTD WON ENQUIRIES    
                let refWonEnquiryData = firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'won')
                    .where('clientID', '==', props.clientid)
                    .where('wonDate', '>=', _dateRange.startDate)
                    .where('wonDate', '<=', _dateRange.endDate)
                    .where('isDeleted', '==', false)

                //MTD LOST ENQUIRIES    
                let refLostEnquiryData = firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'lost')
                    .where('clientID', '==', props.clientid)
                    .where('lostDate', '>=', _dateRange.startDate)
                    .where('lostDate', '<=', _dateRange.endDate)
                    .where('isDeleted', '==', false)

                //MTD DELIVERED ENQUIRIES    
                let refDeliveredEnquiryData = firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'delivered')
                    .where('clientID', '==', props.clientid)
                    .where('deliveredDate', '>=', _dateRange.startDate)
                    .where('deliveredDate', '<=', _dateRange.endDate)
                    .where('isDeleted', '==', false)

                //MTD TEST DRIVES    
                let refTestDriveData = firestoreDB.firestore().collection('testdrives')
                    .where('clientID', '==', props.clientid)
                    .where('isDeleted', '==', false)
                    .where('startDate', '>=', _dateRange.startDate)
                    .where('startDate', '<=', _dateRange.endDate)
                    // @alert: comment below line before pushing  
                    .orderBy('startDate', 'desc')

                //MTD TRADE IN COUNT    
                let refTradeinData = firestoreDB.firestore().collection('tradeins')
                    .where('clientID', '==', props.clientid)
                    .where('isDeleted', '==', false)
                    .where('addedDate', '>=', _dateRange.startDate)
                    .where('addedDate', '<=', _dateRange.endDate)


                //MTD OVERDUE ACTIVITIES 
                let refScheduleActivityData = firestoreDB.firestore().collection('activities')
                    .where('clientID', '==', props.clientid)
                    .where('isDeleted', '==', false)
                    .where('isDone', '==', false)
                    .where('type', '==', 'activity')
                    .where('startDate', '>=', _dateRange.todayStart)
                    .where('startDate', '<=', _dateRange.todayEnd)
                    .orderBy('startDate', 'desc')

                //MTD WALKIN LEADS
                let refWalkinEnquiryData = firestoreDB.firestore().collectionGroup('walkins')
                    .where('clientID', '==', props.clientid)
                    .where('walkinDate', '>=', _dateRange.startDate)
                    .where('walkinDate', '<=', _dateRange.endDate)

                let refClientData = window.firebase.firestore().collection('clients').where('documentID', '==', props.clientid)
                let refClientSettings = window.firebase.firestore().collection('clients').doc(props.clientid).collection('currentSettings')

                let refLeadsBucketEnquiryData = firestoreDB.firestore().collection('enquiries')
                    .where('pipeline', '==', 'LeadsBucket')
                    .where('isDeleted', '==', false)
                    .where('clientID', '==', props.clientid)

                let refLeadsBucketEnquiryDataByDate = firestoreDB.firestore().collection('enquiries')
                    .where('clientID', '==', props.clientid)
                    .where('isDeleted', '==', false)
                    .where('convertedDate', '>=', _dateRange.startDate)
                    .where('convertedDate', '<=', _dateRange.endDate)
                    .orderBy('convertedDate', 'desc')

                let refInboundLeads = firestoreDB.firestore().collection('inboundLeads')
                    .where('clientID', '==', props.clientid)
                    .where('status', '==', 0)
                    .where('isDeleted', '==', false)

                var unionPipelines = combineLatest(
                    of([]),
                    of([]),
                    lostPendingApproval ? collection(lostPendingApproval) : of([]),
                    refOpenEnquiryData ? collection(refOpenEnquiryData) : of([]),
                    refWonEnquiryData ? collection(refWonEnquiryData) : of([]),
                    refLostEnquiryData ? collection(refLostEnquiryData) : of([]),
                    refDeliveredEnquiryData ? collection(refDeliveredEnquiryData) : of([]),
                    collection(refLeadsBucketEnquiryData),
                    collection(refLeadsBucketEnquiryDataByDate),
                    of([]),
                    collection(refTestDriveData),
                    collection(refTradeinData),
                    of([]),
                    collection(refScheduleActivityData),
                    collection(refWalkinEnquiryData),
                    collection(refClientData),
                    collection(refClientSettings),
                ).pipe(
                    map(([pendinBucket, convertedLeads, lostPendingApproval,
                        openLeads, wonLeads, lostLeads, deliveredLeads, leadsBucketEnquiryData, leadsBucketEnquiryDataByDate, inboundLeads,
                        testdriveLeads, tradeinLeads,
                        doneActivity, scheduleActivity, walkins, clients, clientsSetting]) => {
                        return [
                            _.map(pendinBucket, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(convertedLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(lostPendingApproval, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(openLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(wonLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(lostLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(deliveredLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(leadsBucketEnquiryData, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(leadsBucketEnquiryDataByDate, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(inboundLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(testdriveLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(tradeinLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(doneActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(scheduleActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(walkins, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(clients, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(clientsSetting, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        ]
                    }))
                    .subscribe(allDocs => {
                        var _enquiries = [];
                        for (let i = 0; i < 10; i++) {
                            allDocs[i].forEach(el => {
                                if (!_enquiries.some(e => e.documentID === el.documentID)) {
                                    _enquiries.push({
                                        ...convertEnquiryVM({
                                            ...el,
                                            testdrives: allDocs[10].filter(a => a.enquiryID === el.documentID),
                                            tradeins: allDocs[11].filter(a => a.enquiryID === el.documentID),
                                            walkins: allDocs[14].filter(a => a.enquiryID === el.documentID),
                                            firstWalkinDate: allDocs[14].filter(a => a.enquiryID === el.documentID).length > 0 ? _.orderBy(allDocs[14].filter(a => a.enquiryID === el.documentID), ['walkinDate'], ['asc'])[0].walkinDate : null,
                                        }, allDocs[15] ? Object.assign({}, allDocs[15][0]) : {})
                                    })
                                }
                            });
                        }

                        allDocs[10].forEach(el => {//testdrive array
                            if (!_enquiries.some(e => e.documentID === el.enquiryID) && !_.isEmpty(el.enquiry) && !_.isEmpty(el.enquiry.addedDate)) {
                                _enquiries.push({
                                    ...convertEnquiryVM({
                                        ...el.enquiry,
                                        'documentID': el.enquiryID,
                                        testdrives: [el],
                                        walkins: allDocs[14].filter(a => a.enquiryID === el.documentID),
                                        firstWalkinDate: allDocs[14].filter(a => a.enquiryID === el.documentID).length > 0 ? _.orderBy(allDocs[14].filter(a => a.enquiryID === el.documentID), ['walkinDate'], ['asc'])[0].walkinDate : null,
                                    }, allDocs[15] ? Object.assign({}, allDocs[15][0]) : {})
                                })
                            }
                        });
                        var _activities = []
                        allDocs[13].forEach(el => {
                            if (el.enquiryID) {
                                _activities.push(el)
                            }
                        });

                        setTestdrives(allDocs[10])
                        setClient(allDocs[15] ? Object.assign({}, allDocs[15][0]) : {})
                        setClientSettings(allDocs[16] ? Object.assign({}, allDocs[16][0]) : {})
                        setTempData({
                            enquiries: _enquiries.filter(a => !_.isEmpty(a.contact)),
                            walkins: allDocs[14],
                            activities: _activities,
                            tradeins: allDocs[11]
                        })
                        setinclInboundLeadsinRpt(_inclInboundLeadsinRpt)
                    });
                return () => {
                    unionPipelines && unionPipelines.unsubscribe()
                }
            })
        }

    }, [firestoreDB])

    useEffect(() => {
        if (_.isEmpty(tempData))
            return;
        async function getWalkinEnq(walkins, enquiries, activities, tradeins, client) {
            const promises = []
            const followupPromises = []
            const followupEnquiries = []

            walkins.forEach(el => {
                if (!enquiries.some(e => e.documentID === el.enquiryID)) {
                    promises.push(firestoreDB.firestore().doc(`enquiries/${el.enquiryID}`).get())
                }
            });
            _.uniq(_.map(activities, function (e) { return e.enquiryID })).forEach(el => {
                followupPromises.push(firestoreDB.firestore().doc(`enquiries/${el}`).get())
            });

            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                const dataDoc = { ...snap.data(), 'documentID': snap.id };
                if (dataDoc.isDeleted === false && (inclInboundLeadsinRpt ? true : dataDoc.pipeline !== 'LeadsBucket')) {
                    enquiries.push({
                        ...dataDoc,
                        walkins: walkins.filter(a => a.enquiryID === snap.id),
                        firstWalkinDate: walkins.filter(a => a.enquiryID === snap.id).length > 0 ? _.orderBy(walkins.filter(a => a.enquiryID === snap.id), ['walkinDate'], ['asc'])[0].walkinDate : null,
                        tradeins: tradeins.filter(a => a.enquiryID === snap.id),
                    })
                }
            })

            const followupsnapshots = await Promise.all(followupPromises)
            followupsnapshots.forEach(snap => {
                const dataDoc = { ...snap.data(), 'documentID': snap.id };
                if (dataDoc.isDeleted === false && (inclInboundLeadsinRpt ? true : dataDoc.pipeline !== 'LeadsBucket')) {
                    followupEnquiries.push({
                        ...convertEnquiryVM({
                            ...dataDoc,
                            walkins: walkins.filter(a => a.enquiryID === snap.id),
                        }, client)
                    })
                }
            })

            let uniqEnquiries = _.uniqBy(enquiries, 'documentID')
            setEnquiries(uniqEnquiries)
            setFollowupEnquiries(
                followupEnquiries
                    .filter(a =>
                        (!_.isEmpty(a.requirement) && a.requirement.make && a.requirement.model && a.requirement.saleType)
                        || (!_.isEmpty(a.requirement) && !_.isEmpty(a.requirement.stock) && a.requirement.stock.make && a.requirement.stock.model && a.requirement.stock.saleType))
            )
            settempDataLoader(false);
        }
        getWalkinEnq(tempData.walkins, tempData.enquiries, tempData.activities, tempData.tradeins, tempData.client);
    }, [tempData])

    useEffect(() => {
        if (tempDataLoader)
            return

        if (!clientSettings || _.isEmpty(clientSettings))
            return

        let enqWithoutLeadsBucket = pipelineEnquiries.filter(enq => (inclInboundLeadsinRpt ? true : enq.pipeline !== 'LeadsBucket'))
        let enqWithReq = enqWithoutLeadsBucket.filter(enq => !_.isEmpty(enq.requirement))

        // taking all makes from enquiries 

        let _allMakes = []
        enqWithReq.forEach(enq => {
            let make = ''
            if (!_.isEmpty(enq.requirement.stock) && !_.isEmpty(enq.requirement.stock.make)) {
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                make = enq.requirement.make
            }
            if (make) {
                _allMakes = _.uniq([..._allMakes, make.trim().toLowerCase()])
            }
        })
        //console.log('_allMakes', _allMakes)


        let makeSaleType = [];
        let makes = '';
        if (!_.isEmpty(_allMakes)) {
            _allMakes.forEach((mk, index) => {
                if (index === _allMakes.length - 1) {
                    saleTypes.forEach((st, i) => {
                        if (i === saleTypes.length - 1) {
                            makes += `${mk}:${st}`;
                        } else {
                            makes += `${mk}:${st};`;
                        }
                    })
                } else {
                    saleTypes.forEach(st => {
                        makes += `${mk}:${st};`;
                    })
                }
            })
        }

        if (!makes) {
            if (!_.isEmpty(clientSettings.brands)) {
                clientSettings.brands.forEach((mk, index) => {
                    if (index === clientSettings.brands.length - 1) {
                        saleTypes.forEach((st, i) => {
                            if (i === saleTypes.length - 1) {
                                makes += `${mk.value}:${st}`;
                            } else {
                                makes += `${mk.value}:${st};`;
                            }
                        })
                    } else {
                        saleTypes.forEach(st => {
                            makes += `${mk.value}:${st};`;
                        })
                    }
                })
            }
        }
        makes.split(';').forEach(rec => {
            let makeSplit = rec.split(':');
            //console.log('makeSplit', makeSplit)
            makeSaleType.push({
                title: (`${makeSplit[0].toUpperCase() === 'ALL' ? '' : makeSplit[0].toUpperCase() === 'NOMAKE' ? 'LEADS WITHOUT VEHICLE MODEL REQUIREMENT' : makeSplit[0].toUpperCase() === 'OTHERS' ? 'Others Used' : makeSplit[0].toUpperCase()} ${makeSplit[0].toUpperCase() === 'NOMAKE' || makeSplit[0].toUpperCase() === 'OTHERS' ? '' : makeSplit[1].toUpperCase()} ${makeSplit[0].toUpperCase() !== 'NOMAKE' ? 'ENQUIRY' : ''}`).trim(),
                make: makeSplit[0].toLowerCase(),
                saleType: makeSplit[1].toLowerCase()
            })
        });
        //console.log('makeSaleType', makeSaleType)

        let allCountsTest = {
            todayAllEnq: enqWithoutLeadsBucket,
            weekAllEnq: enqWithoutLeadsBucket,
            monthAllEnq: enqWithoutLeadsBucket,
            eligibleTestdrives: mtdTestdrives,
        }

        allCountsTest = {
            ...allCountsTest,
            todayNew: allCountsTest.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekNew: allCountsTest.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthNew: allCountsTest.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayOpen: allCountsTest.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekOpen: allCountsTest.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthOpen: allCountsTest.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayExisting: allCountsTest.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.todayStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.todayStart.seconds
                    && a.wonDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.todayStart.seconds
                        && a.lostDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.todayStart.seconds
                        && item.startDate.seconds <= dateRange.todayEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                        && item.walkinDate.seconds <= dateRange.todayEnd.seconds)))),
            weekExisting: allCountsTest.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.weekStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.weekStart.seconds
                    && a.wonDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.weekStart.seconds
                        && a.lostDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.weekStart.seconds
                        && item.startDate.seconds <= dateRange.weekEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                        && item.walkinDate.seconds <= dateRange.weekEnd.seconds)))),
            monthExisting: allCountsTest.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.monthStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.monthStart.seconds
                    && a.wonDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.monthStart.seconds
                        && a.lostDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.monthStart.seconds
                        && item.startDate.seconds <= dateRange.monthEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                        && item.walkinDate.seconds <= dateRange.monthEnd.seconds)))),
            todayWon: allCountsTest.todayAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.todayStart.seconds
                && a.wonDate.seconds <= dateRange.todayEnd.seconds),
            weekWon: allCountsTest.weekAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.weekStart.seconds
                && a.wonDate.seconds <= dateRange.weekEnd.seconds),
            monthWon: allCountsTest.monthAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.monthStart.seconds
                && a.wonDate.seconds <= dateRange.monthEnd.seconds),
            todayLost: allCountsTest.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.todayStart.seconds
                && a.lostDate.seconds <= dateRange.todayEnd.seconds),
            weekLost: allCountsTest.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.weekStart.seconds
                && a.lostDate.seconds <= dateRange.weekEnd.seconds),
            monthLost: allCountsTest.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.monthStart.seconds
                && a.lostDate.seconds <= dateRange.monthEnd.seconds),
            todayDelivered: allCountsTest.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.todayStart.seconds
                && a.deliveredDate.seconds <= dateRange.todayEnd.seconds),
            weekDelivered: allCountsTest.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.weekStart.seconds
                && a.deliveredDate.seconds <= dateRange.weekEnd.seconds),
            monthDelivered: allCountsTest.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.monthStart.seconds
                && a.deliveredDate.seconds <= dateRange.monthEnd.seconds),
            todayTestdrive: allCountsTest.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.todayStart.seconds
                && a.startDate.seconds <= dateRange.todayEnd.seconds),
            weekTestdrive: allCountsTest.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.weekStart.seconds
                && a.startDate.seconds <= dateRange.weekEnd.seconds),
            monthTestdrive: allCountsTest.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.monthStart.seconds
                && a.startDate.seconds <= dateRange.monthEnd.seconds),
            todayTradein: allCountsTest.todayAllEnq.filter(a => a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.todayStart.seconds
                    && item.addedDate.seconds <= dateRange.todayEnd.seconds
                )),
            weekTradein: allCountsTest.weekAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.weekStart.seconds
                    && item.addedDate.seconds <= dateRange.weekEnd.seconds
                )),
            monthTradein: allCountsTest.monthAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.monthStart.seconds
                    && item.addedDate.seconds <= dateRange.monthEnd.seconds
                )),
            todayWalkin: allCountsTest.todayAllEnq.filter(a =>
            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                && item.walkinDate.seconds <= dateRange.todayEnd.seconds))),
            weekWalkin: allCountsTest.weekAllEnq.filter(a =>
            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                && item.walkinDate.seconds <= dateRange.weekEnd.seconds))),
            monthWalkin: allCountsTest.monthAllEnq.filter(a =>
            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                && item.walkinDate.seconds <= dateRange.monthEnd.seconds))),
        }

        //console.log('allCountsTest', allCountsTest)

        let finalData = []
        makeSaleType.forEach((makeInfoObj => {
            let makeEnq = null
            let makeCounts = null
            if (makeInfoObj.saleType === 'new') {
                makeEnq = enqWithReq.filter(enq => {
                    let returnVal = false;
                    let salesType = ''
                    let make = ''
                    if (!_.isEmpty(enq.requirement.stock) && !_.isEmpty(enq.requirement.stock.make)) {
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            enq.requirement.stock.saleType, '');
                        make = enq.requirement.stock.make
                    } else if (enq.requirement.make && enq.requirement.model) {
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            enq.requirement.saleType, '');
                        make = enq.requirement.make
                    }
                    if (salesType.toLowerCase().includes('preowned') || salesType.toLowerCase().includes('demo')) {
                        returnVal = false
                    } else if (make.toLowerCase().includes(makeInfoObj.make.toLowerCase()) && !salesType.toLowerCase().includes('preowned') && !salesType.toLowerCase().includes('demo')) {
                        returnVal = true
                    }
                    return returnVal
                })

                makeCounts = {
                    todayAllEnq: makeEnq,
                    weekAllEnq: makeEnq,
                    monthAllEnq: makeEnq,
                    eligibleTestdrives: mtdTestdrives.filter(a => {
                        let returnVal = false;
                        let make = '';
                        let salesType = '';
                        if (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement)) {
                            if (!_.isEmpty(a.enquiry.requirement.stock) && !_.isEmpty(a.enquiry.requirement.stock.make)) {
                                make = a.enquiry.requirement.stock.make.toLowerCase()
                                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                                    a.enquiry.requirement.stock.saleType, '');
                            } else if (a.enquiry.requirement.make && a.enquiry.requirement.model) {
                                make = a.enquiry.requirement.make.toLowerCase()
                                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                                    a.enquiry.requirement.saleType, '');
                            } else {
                                returnVal = false
                            }
                            if (salesType.toLowerCase().includes('preowned') || salesType.toLowerCase().includes('demo')) {
                                returnVal = false
                            } else if (make.toLowerCase().includes(makeInfoObj.make.toLowerCase())) {
                                returnVal = true
                            }
                        } else {
                            returnVal = false
                        }

                        return returnVal
                    }),
                }
            } else if (makeInfoObj.saleType === 'used') {
                makeEnq = enqWithReq.filter(enq => {
                    let returnVal = false;
                    let salesType = ''
                    let make = ''
                    if (!_.isEmpty(enq.requirement.stock) && !_.isEmpty(enq.requirement.stock.make)) {
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            enq.requirement.stock.saleType, '');
                        make = enq.requirement.stock.make
                    } else if (enq.requirement.make && enq.requirement.model) {
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            enq.requirement.saleType, '');
                        make = enq.requirement.make
                    }
                    if (salesType.toLowerCase().includes('preowned') && make.toLowerCase().includes(makeInfoObj.make.toLowerCase())) {
                        returnVal = true
                    }
                    return returnVal
                })

                makeCounts = {
                    todayAllEnq: makeEnq,
                    weekAllEnq: makeEnq,
                    monthAllEnq: makeEnq,
                    eligibleTestdrives: mtdTestdrives.filter(a => {
                        let returnVal = false;
                        let make = '';
                        let salesType = '';
                        if (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement)) {
                            if (!_.isEmpty(a.enquiry.requirement.stock) && !_.isEmpty(a.enquiry.requirement.stock.make)) {
                                make = a.enquiry.requirement.stock.make.toLowerCase()
                                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                                    a.enquiry.requirement.stock.saleType, '');
                            } else if (a.enquiry.requirement.make && a.enquiry.requirement.model) {
                                make = a.enquiry.requirement.make.toLowerCase()
                                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                                    a.enquiry.requirement.saleType, '');
                            } else {
                                returnVal = false
                            }
                            if (salesType.toLowerCase().includes('preowned') && make.toLowerCase().includes(makeInfoObj.make.toLowerCase())) {
                                returnVal = true
                            }
                        } else {
                            returnVal = false
                        }

                        return returnVal
                    }),
                }
            } else if (makeInfoObj.saleType === 'demo') {
                makeEnq = enqWithReq.filter(enq => {
                    let returnVal = false;
                    let salesType = ''
                    let make = ''
                    if (!_.isEmpty(enq.requirement.stock) && !_.isEmpty(enq.requirement.stock.make)) {
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            enq.requirement.stock.saleType, '');
                        make = enq.requirement.stock.make
                    } else if (enq.requirement.make && enq.requirement.model) {
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            enq.requirement.saleType, '');
                        make = enq.requirement.make
                    }
                    if (salesType.toLowerCase().includes('demo') && make.toLowerCase().includes(makeInfoObj.make.toLowerCase())) {
                        returnVal = true
                    }
                    return returnVal
                })

                makeCounts = {
                    todayAllEnq: makeEnq,
                    weekAllEnq: makeEnq,
                    monthAllEnq: makeEnq,
                    eligibleTestdrives: mtdTestdrives.filter(a => {
                        let returnVal = false;
                        let make = '';
                        let salesType = '';
                        if (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement)) {
                            if (!_.isEmpty(a.enquiry.requirement.stock) && !_.isEmpty(a.enquiry.requirement.stock.make)) {
                                make = a.enquiry.requirement.stock.make.toLowerCase()
                                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                                    a.enquiry.requirement.stock.saleType, '');
                            } else if (a.enquiry.requirement.make && a.enquiry.requirement.model) {
                                make = a.enquiry.requirement.make.toLowerCase()
                                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                                    a.enquiry.requirement.saleType, '');
                            } else {
                                returnVal = false
                            }
                            if (salesType.toLowerCase().includes('demo') && make.toLowerCase().includes(makeInfoObj.make.toLowerCase())) {
                                returnVal = true
                            }
                        } else {
                            returnVal = false
                        }

                        return returnVal
                    }),
                }
            }
            makeCounts = {
                ...makeCounts,
                todayNew: makeCounts.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                    && a.addedDate.seconds <= dateRange.todayEnd.seconds),
                weekNew: makeCounts.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.weekStart.seconds
                    && a.addedDate.seconds <= dateRange.weekEnd.seconds),
                monthNew: makeCounts.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.monthStart.seconds
                    && a.addedDate.seconds <= dateRange.monthEnd.seconds),
                todayOpen: makeCounts.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                    && a.addedDate.seconds <= dateRange.todayEnd.seconds),
                weekOpen: makeCounts.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.weekStart.seconds
                    && a.addedDate.seconds <= dateRange.weekEnd.seconds),
                monthOpen: makeCounts.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.monthStart.seconds
                    && a.addedDate.seconds <= dateRange.monthEnd.seconds),
                todayExisting: makeCounts.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds < dateRange.todayStart.seconds
                    && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.todayStart.seconds
                        && a.wonDate.seconds <= dateRange.todayEnd.seconds) ||
                        (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.todayStart.seconds
                            && a.lostDate.seconds <= dateRange.todayEnd.seconds) ||
                        (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.todayStart.seconds
                            && item.startDate.seconds <= dateRange.todayEnd.seconds)) ||
                        (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                            && item.walkinDate.seconds <= dateRange.todayEnd.seconds)))),
                weekExisting: makeCounts.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds < dateRange.weekStart.seconds
                    && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.weekStart.seconds
                        && a.wonDate.seconds <= dateRange.weekEnd.seconds) ||
                        (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.weekStart.seconds
                            && a.lostDate.seconds <= dateRange.weekEnd.seconds) ||
                        (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.weekStart.seconds
                            && item.startDate.seconds <= dateRange.weekEnd.seconds)) ||
                        (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                            && item.walkinDate.seconds <= dateRange.weekEnd.seconds)))),
                monthExisting: makeCounts.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds < dateRange.monthStart.seconds
                    && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.monthStart.seconds
                        && a.wonDate.seconds <= dateRange.monthEnd.seconds) ||
                        (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.monthStart.seconds
                            && a.lostDate.seconds <= dateRange.monthEnd.seconds) ||
                        (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.monthStart.seconds
                            && item.startDate.seconds <= dateRange.monthEnd.seconds)) ||
                        (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                            && item.walkinDate.seconds <= dateRange.monthEnd.seconds)))),
                todayWon: makeCounts.todayAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                    && !_.isEmpty(a.wonDate)
                    && a.wonDate.seconds >= dateRange.todayStart.seconds
                    && a.wonDate.seconds <= dateRange.todayEnd.seconds),
                weekWon: makeCounts.weekAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                    && !_.isEmpty(a.wonDate)
                    && a.wonDate.seconds >= dateRange.weekStart.seconds
                    && a.wonDate.seconds <= dateRange.weekEnd.seconds),
                monthWon: makeCounts.monthAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                    && !_.isEmpty(a.wonDate)
                    && a.wonDate.seconds >= dateRange.monthStart.seconds
                    && a.wonDate.seconds <= dateRange.monthEnd.seconds),
                todayLost: makeCounts.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                    && !_.isEmpty(a.lostDate)
                    && a.lostDate.seconds >= dateRange.todayStart.seconds
                    && a.lostDate.seconds <= dateRange.todayEnd.seconds),
                weekLost: makeCounts.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                    && !_.isEmpty(a.lostDate)
                    && a.lostDate.seconds >= dateRange.weekStart.seconds
                    && a.lostDate.seconds <= dateRange.weekEnd.seconds),
                monthLost: makeCounts.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                    && !_.isEmpty(a.lostDate)
                    && a.lostDate.seconds >= dateRange.monthStart.seconds
                    && a.lostDate.seconds <= dateRange.monthEnd.seconds),
                todayDelivered: makeCounts.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                    && !_.isEmpty(a.deliveredDate)
                    && a.deliveredDate.seconds >= dateRange.todayStart.seconds
                    && a.deliveredDate.seconds <= dateRange.todayEnd.seconds),
                weekDelivered: makeCounts.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                    && !_.isEmpty(a.deliveredDate)
                    && a.deliveredDate.seconds >= dateRange.weekStart.seconds
                    && a.deliveredDate.seconds <= dateRange.weekEnd.seconds),
                monthDelivered: makeCounts.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                    && !_.isEmpty(a.deliveredDate)
                    && a.deliveredDate.seconds >= dateRange.monthStart.seconds
                    && a.deliveredDate.seconds <= dateRange.monthEnd.seconds),
                todayTestdrive: makeCounts.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                    && a.startDate.seconds >= dateRange.todayStart.seconds
                    && a.startDate.seconds <= dateRange.todayEnd.seconds),
                weekTestdrive: makeCounts.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                    && a.startDate.seconds >= dateRange.weekStart.seconds
                    && a.startDate.seconds <= dateRange.weekEnd.seconds),
                monthTestdrive: makeCounts.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                    && a.startDate.seconds >= dateRange.monthStart.seconds
                    && a.startDate.seconds <= dateRange.monthEnd.seconds),
                todayTradein: makeCounts.todayAllEnq.filter(a => a.isTradeIn === true
                    && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.todayStart.seconds
                        && item.addedDate.seconds <= dateRange.todayEnd.seconds
                    )),
                weekTradein: makeCounts.weekAllEnq.filter(a =>
                    a.isTradeIn === true
                    && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.weekStart.seconds
                        && item.addedDate.seconds <= dateRange.weekEnd.seconds
                    )),
                monthTradein: makeCounts.monthAllEnq.filter(a =>
                    a.isTradeIn === true
                    && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.monthStart.seconds
                        && item.addedDate.seconds <= dateRange.monthEnd.seconds
                    )),
                todayWalkin: makeCounts.todayAllEnq.filter(a =>
                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                    && item.walkinDate.seconds <= dateRange.todayEnd.seconds))),
                weekWalkin: makeCounts.weekAllEnq.filter(a =>
                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                    && item.walkinDate.seconds <= dateRange.weekEnd.seconds))),
                monthWalkin: makeCounts.monthAllEnq.filter(a =>
                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                    && item.walkinDate.seconds <= dateRange.monthEnd.seconds))),
            }

            finalData.push({
                ...makeInfoObj,
                counts: makeCounts
            })

        }))

        let totalCounts = {
            todayNew: [],
            todayExisting: [],
            todayTestdrive: [],
            todayTradein: [],
            todayWon: [],
            todayLost: [],
            todayWalkin: [],

            weekNew: [],
            weekExisting: [],
            weekTestdrive: [],
            weekTradein: [],
            weekWon: [],
            weekLost: [],
            weekWalkin: [],

            monthNew: [],
            monthExisting: [],
            monthTestdrive: [],
            monthTradein: [],
            monthWon: [],
            monthLost: [],
            monthWalkin: [],
        }

        finalData.forEach((item) => {
            totalCounts.todayNew = [...totalCounts.todayNew, ...item.counts.todayNew]
            totalCounts.todayExisting = [...totalCounts.todayExisting, ...item.counts.todayExisting]
            totalCounts.todayTestdrive = [...totalCounts.todayTestdrive, ...item.counts.todayTestdrive]
            totalCounts.todayTradein = [...totalCounts.todayTradein, ...item.counts.todayTradein]
            totalCounts.todayWon = [...totalCounts.todayWon, ...item.counts.todayWon]
            totalCounts.todayLost = [...totalCounts.todayLost, ...item.counts.todayLost]
            totalCounts.todayWalkin = [...totalCounts.todayWalkin, ...item.counts.todayWalkin]

            totalCounts.weekNew = [...totalCounts.weekNew, ...item.counts.weekNew]
            totalCounts.weekExisting = [...totalCounts.weekExisting, ...item.counts.weekExisting]
            totalCounts.weekTestdrive = [...totalCounts.weekTestdrive, ...item.counts.weekTestdrive]
            totalCounts.weekTradein = [...totalCounts.weekTradein, ...item.counts.weekTradein]
            totalCounts.weekWon = [...totalCounts.weekWon, ...item.counts.weekWon]
            totalCounts.weekLost = [...totalCounts.weekLost, ...item.counts.weekLost]
            totalCounts.weekWalkin = [...totalCounts.weekWalkin, ...item.counts.weekWalkin]

            totalCounts.monthNew = [...totalCounts.monthNew, ...item.counts.monthNew]
            totalCounts.monthExisting = [...totalCounts.monthExisting, ...item.counts.monthExisting]
            totalCounts.monthTestdrive = [...totalCounts.monthTestdrive, ...item.counts.monthTestdrive]
            totalCounts.monthTradein = [...totalCounts.monthTradein, ...item.counts.monthTradein]
            totalCounts.monthWon = [...totalCounts.monthWon, ...item.counts.monthWon]
            totalCounts.monthLost = [...totalCounts.monthLost, ...item.counts.monthLost]
            totalCounts.monthWalkin = [...totalCounts.monthWalkin, ...item.counts.monthWalkin]
        })

        // @secion: withour requirement

        let withoutReqEnq = enqWithoutLeadsBucket.filter(a => {
            let returnVal = false;
            if (
                (!_.isEmpty(a.requirement) && a.requirement.make && a.requirement.model && a.requirement.saleType)
                || (!_.isEmpty(a.requirement) && !_.isEmpty(a.requirement.stock) && a.requirement.stock.make && a.requirement.stock.model && a.requirement.stock.saleType)
            ) {
                returnVal = false
            } else {
                returnVal = true
            }
            return returnVal
        })

        let withoutReqCountsAll = {
            todayAllEnq: withoutReqEnq,
        }

        withoutReqCountsAll = {
            ...withoutReqCountsAll,
            todayNew: withoutReqCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds)
        }

        //console.log('totalCounts', totalCounts)
        setAllCounts(allCountsTest)
        setWithoutReqCounts(withoutReqCountsAll)
        setData(finalData)
        setCountsLoader(false)
        setAllMakes(_allMakes)

    }, [tempDataLoader])

    const convertEnquiryVM = (doc, _client) => {
        let listVM = { ...doc };

        if (!_.isEmpty(doc.contact)) {
            listVM.contactName = CommonHelper.displayFullContact([], doc.contact);
            listVM.contactNumber = doc.contact.displayID;
            listVM.phone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, { client: _client });
            listVM.email = doc.contact.email;
        }

        if (!_.isEmpty(doc.requirement)) {
            listVM.vehicleModelDescription = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.make + ' ' + doc.requirement.stock.model : doc.requirement.make + ' ' + doc.requirement.model
            listVM.vehicleMake = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.make : doc.requirement.make
            listVM.vehicleModel = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.model : doc.requirement.model
            listVM.vehicleYear = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.year : doc.requirement.year;
            listVM.vehicleSaletype = doc.requirement.saleType;
            listVM.vehicleBodytype = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
        }
        if (!_.isEmpty(doc.testdrives)) {
            listVM.testdrive = _.orderBy(Object.assign([], doc.testdrives), ['addedDate'], ['asc'])[0];
            listVM.testdriveModelDescription = !_.isEmpty(listVM.testdrive.stock) ? listVM.testdrive.stock.make + ' ' + listVM.testdrive.stock.model : ''
            listVM.testdriveSaletype = !_.isEmpty(listVM.testdrive.stock) ? listVM.testdrive.stock.saleType : ''
        }

        if (!_.isEmpty(doc.tradeins)) {
            listVM.tradein = _.orderBy(Object.assign([], doc.tradeins), ['addedDate'], ['asc'])[0];
            listVM.tradeinModelDescription = !_.isEmpty(listVM.tradein) ? listVM.tradein.make + ' ' + listVM.tradein.model : ''
            listVM.tradeinYear = !_.isEmpty(listVM.tradein) ? listVM.tradein.year : '';
            listVM.tradeinSaletype = !_.isEmpty(listVM.stock) ? listVM.tradein.saleType : '';
        }

        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOn = doc.convertedDate ? moment.unix(doc.convertedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.enquiryStatus = doc.status ? (<span className={`mrg-fixstatus badge-${doc.status === 'prospectLost' ? 'lost' : doc.status.toLowerCase()}`}>{doc.status === 'prospectLost' ? 'PROSPECT LOST' : doc.status}</span>) : '';
        return listVM
    }

    return (
        <>
            {
                !countsLoader && !_.isEmpty(client) && !_.isEmpty(clientSettings)
                    ?
                    <>
                        <div className="A4 landscape report-data-loaded report-data-load">
                            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="60%"><div className="header-left">
                                                    {
                                                        client && client.logoURL
                                                            ?
                                                            <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }
                                                </div></td>

                                                <td width="40%"><div className="header-right"> {`DAILY ACTIVITY REPORT ${client ? ' - ' + client.name.toUpperCase() : ''}`} <br></br>
                                                    <span> {moment(dateRange.date).format('DD MMMM YYYY')}</span> </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-wraper">
                                    {/* <div className="table-head">{a.title}</div> */}
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="10%">&nbsp;</th>
                                                <th width="10%">New Leads</th>
                                                <th width="10%">Existing Leads</th>
                                                <th width="10%">Test Drive  </th>
                                                <th width="10%">Trade In </th>
                                                <th width="10%">Sold</th>
                                                <th width="10%">Lost</th>
                                                <th width="10%">Walkin </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">TODAY</td>
                                                <td className="count-large">{allCounts.todayNew.length}</td>
                                                <td className="count-large">{allCounts.todayExisting.length}</td>
                                                <td className="count-large">{allCounts.todayTestdrive.length}</td>
                                                <td className="count-large">{allCounts.todayTradein.length}</td>
                                                <td className="count-large">{allCounts.todayWon.length}</td>
                                                <td className="count-large">{allCounts.todayLost.length}</td>
                                                <td className="count-large">{allCounts.todayWalkin.length}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS WEEK</td>
                                                <td className="count-large">{allCounts.weekNew.length}</td>
                                                <td className="count-large">{allCounts.weekExisting.length}</td>
                                                <td className="count-large">{allCounts.weekTestdrive.length}</td>
                                                <td className="count-large">{allCounts.weekTradein.length}</td>
                                                <td className="count-large">{allCounts.weekWon.length}</td>
                                                <td className="count-large">{allCounts.weekLost.length}</td>
                                                <td className="count-large">{allCounts.weekWalkin.length}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS MONTH</td>
                                                <td className="count-large">{allCounts.monthNew.length}</td>
                                                <td className="count-large">{allCounts.monthExisting.length}</td>
                                                <td className="count-large">{allCounts.monthTestdrive.length}</td>
                                                <td className="count-large">{allCounts.monthTradein.length}</td>
                                                <td className="count-large">{allCounts.monthWon.length}</td>
                                                <td className="count-large">{allCounts.monthLost.length}</td>
                                                <td className="count-large">{allCounts.monthWalkin.length}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* <div className="table-head">LEADS CREATED WITHOUT VEHICLE MODEL REQUIREMENT</div>
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="10%">&nbsp;</th>
                                                <th width="10%">New Leads</th>
                                                <th width="10%">Existing Leads</th>
                                                <th width="10%">Test Drive  </th>
                                                <th width="10%"> Trade In </th>
                                                <th width="10%">Sold</th>
                                                <th width="10%">Lost</th>
                                                <th width="10%"> Walkin </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">TODAY</td>
                                                <td className="count-large">{withoutReqCounts.todayNew.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayExisting.length}</td> 
                                                <td className="count-large">{withoutReqCounts.todayTestdrive.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayTradein.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayWon.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayLost.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayWalkin.length}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS WEEK</td>
                                                <td className="count-large">{withoutReqCounts.weekNew.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekExisting.length}</td> 
                                                <td className="count-large">{withoutReqCounts.weekTestdrive.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekTradein.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekWon.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekLost.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekWalkin.length}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS MONTH</td>
                                                <td className="count-large">{withoutReqCounts.monthNew.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthExisting.length}</td> 
                                                <td className="count-large">{withoutReqCounts.monthTestdrive.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthTradein.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthWon.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthLost.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthWalkin.length}</td>
                                            </tr>
                                        </tbody>
                                    </table> */}

                                    <div className="table-head" style={{ fontSize: '16px' }}>TODAY'S ENQUIRIES</div>

                                    {allMakes && allMakes.map(make =>
                                        (data.filter(a => a.saleType === 'new' && make.toLowerCase() === a.make.toLowerCase()).length &&
                                            data.filter(a => a.saleType === 'new' && make.toLowerCase() === a.make.toLowerCase())[0].counts.todayNew.length > 0) ||
                                            (data.filter(a => a.saleType === 'used' && make.toLowerCase() === a.make.toLowerCase()).length &&
                                                data.filter(a => a.saleType === 'used' && make.toLowerCase() === a.make.toLowerCase())[0].counts.todayNew.length > 0) ||
                                            (data.filter(a => a.saleType === 'demo' && make.toLowerCase() === a.make.toLowerCase()).length &&
                                                data.filter(a => a.saleType === 'demo' && make.toLowerCase() === a.make.toLowerCase())[0].counts.todayNew.length > 0)
                                            ? (
                                                <Fragment key={make}>
                                                    <table className="table-loop table-sub-fix gulson-table-border">
                                                        <thead>
                                                            <tr>
                                                                <th width="20%">CONTACT DETAILS</th>
                                                                <th width="15%">REQUIREMENTS</th>
                                                                <th width="15%">TEST DRIVE</th>
                                                                <th width="15%">TRADE-IN </th>
                                                                <th width="12%">STARTED ON</th>
                                                                <th width="12%">UPDATED ON </th>
                                                                <th width="10%" className="align-center"> STATUS</th>
                                                            </tr>
                                                        </thead>
                                                        <div className="table-head gulson-make-table-head">{make ? make.toUpperCase() : make.toUpperCase()}</div>
                                                        {data.filter(a => a.saleType === 'new' && make.toLowerCase() === a.make.toLowerCase()).length &&
                                                            data.filter(a => a.saleType === 'new' && make.toLowerCase() === a.make.toLowerCase())[0].counts.todayNew.length > 0 &&
                                                            data.filter(a => a.saleType === 'new' && make.toLowerCase() === a.make.toLowerCase()).map(a => (
                                                                <Fragment key={a.title}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan='7'><strong style={{ fontSize: '12px', marginLeft: '1rem' }}>NEW</strong></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            a.counts.todayNew && a.counts.todayNew.filter(a => !_.isEmpty(a.addedDate)
                                                                                && a.addedDate.seconds >= dateRange.todayStart.seconds
                                                                                && a.addedDate.seconds <= dateRange.todayEnd.seconds).map((data, index) => {
                                                                                    return (<tr key={index}>
                                                                                        <td valign="top" align="left">
                                                                                            <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                                                            {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                                            {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                                            {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                                                        </td>

                                                                                        <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                                                            {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                                                            {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                                            {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.createdOn ? data.createdOn : '--'}
                                                                                            {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                                            {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                                                        </td>

                                                                                        <td className="align-center">
                                                                                            {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                                                            {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                                                        </td>
                                                                                    </tr>)
                                                                                })
                                                                        }



                                                                    </tbody>
                                                                </Fragment>
                                                            ))}

                                                        {data.filter(a => a.saleType === 'used' && make.toLowerCase() === a.make.toLowerCase()).length &&
                                                            data.filter(a => a.saleType === 'used' && make.toLowerCase() === a.make.toLowerCase())[0].counts.todayNew.length > 0 &&
                                                            data.filter(a => a.saleType === 'used' && make.toLowerCase() === a.make.toLowerCase()).map(a => (
                                                                <Fragment key={a.title}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan='7'><strong style={{ fontSize: '12px', marginLeft: '1rem' }}>preowned</strong></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            a.counts.todayNew && a.counts.todayNew.filter(a => !_.isEmpty(a.addedDate)
                                                                                && a.addedDate.seconds >= dateRange.todayStart.seconds
                                                                                && a.addedDate.seconds <= dateRange.todayEnd.seconds).map((data, index) => {
                                                                                    return (<tr key={index}>
                                                                                        <td valign="top" align="left">
                                                                                            <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                                                            {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                                            {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                                            {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                                                        </td>

                                                                                        <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                                                            {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                                                            {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                                            {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.createdOn ? data.createdOn : '--'}
                                                                                            {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                                            {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                                                        </td>

                                                                                        <td className="align-center">
                                                                                            {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                                                            {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                                                        </td>
                                                                                    </tr>)
                                                                                })
                                                                        }



                                                                    </tbody>
                                                                </Fragment>
                                                            ))}

                                                        {data.filter(a => a.saleType === 'demo' && make.toLowerCase() === a.make.toLowerCase()).length &&
                                                            data.filter(a => a.saleType === 'demo' && make.toLowerCase() === a.make.toLowerCase())[0].counts.todayNew.length > 0 &&
                                                            data.filter(a => a.saleType === 'demo' && make.toLowerCase() === a.make.toLowerCase()).map(a => (
                                                                <Fragment key={a.title}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan='7'><strong style={{ fontSize: '12px', marginLeft: '1rem' }}>demo</strong></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            a.counts.todayNew && a.counts.todayNew.filter(a => !_.isEmpty(a.addedDate)
                                                                                && a.addedDate.seconds >= dateRange.todayStart.seconds
                                                                                && a.addedDate.seconds <= dateRange.todayEnd.seconds).map((data, index) => {
                                                                                    return (<tr key={index}>
                                                                                        <td valign="top" align="left">
                                                                                            <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                                                            {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                                            {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                                            {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                                                        </td>

                                                                                        <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                                                            {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                                                            {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                                            {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.createdOn ? data.createdOn : '--'}
                                                                                            {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                                                        </td>

                                                                                        <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                                            {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                                                        </td>

                                                                                        <td className="align-center">
                                                                                            {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                                                            {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                                                        </td>
                                                                                    </tr>)
                                                                                })
                                                                        }



                                                                    </tbody>
                                                                </Fragment>
                                                            ))}
                                                    </table>
                                                </Fragment>
                                            ) : (<></>))}

                                    <table className="table-loop table-sub-fix gulson-table-border">
                                        <thead>
                                            <tr>
                                                <th width="20%">CONTACT DETAILS</th>
                                                <th width="15%">REQUIREMENTS</th>
                                                <th width="15%">TEST DRIVE</th>
                                                <th width="15%">TRADE-IN </th>
                                                <th width="12%">STARTED ON</th>
                                                <th width="12%">UPDATED ON </th>
                                                <th width="10%" className="align-center"> STATUS</th>
                                            </tr>
                                        </thead>
                                        {/* <div className="table-head gulson-make-table-head width-imp"></div> */}

                                        <tbody>
                                            {
                                                withoutReqCounts.todayNew && withoutReqCounts.todayNew.filter(a => !_.isEmpty(a.addedDate)
                                                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                                                    && a.addedDate.seconds <= dateRange.todayEnd.seconds).map((data, index) => {
                                                        return (<tr key={index}>
                                                            <td valign="top" align="left">
                                                                <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                                {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                            </td>

                                                            <td>{'--'}
                                                            </td>

                                                            <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                                {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                            </td>

                                                            <td>{data.createdOn ? data.createdOn : '--'}
                                                                {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                            </td>

                                                            <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                            </td>

                                                            <td className="align-center">
                                                                {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                                {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                            </td>
                                                        </tr>)
                                                    })
                                            }
                                        </tbody>
                                    </table>

                                    <div className="table-head" style={{ fontSize: '16px' }}>OUTSTANDING FOLLOW UPS</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="20%">CONTACT DETAILS</th>
                                                <th width="15%">REQUIREMENTS</th>
                                                <th width="15%">TEST DRIVE</th>
                                                <th width="15%">TRADE-IN </th>
                                                <th width="12%">STARTED ON</th>
                                                <th width="12%">UPDATED ON </th>
                                                <th width="10%" className="align-center"> STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                followupEnquiries && followupEnquiries.map((data, index) => {
                                                    return (<tr key={index}>
                                                        <td valign="top" align="left">
                                                            {/* {console.log(data)} */}
                                                            <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                            {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                            {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                            {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                        </td>

                                                        <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                            {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                        </td>

                                                        <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                            {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                        </td>

                                                        <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                            {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                        </td>

                                                        <td>{data.createdOn ? data.createdOn : '--'}
                                                            {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                        </td>

                                                        <td>{data.updatedOn ? data.updatedOn : '--'}
                                                            {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                        </td>

                                                        <td className="align-center">
                                                            {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                            {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                        </td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="footer">
                                    <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="footer-cpy">
                                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                                        <div className="footer-right"></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </section>

                        </div>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>
            }
        </>
    )
}
export default GulsonDailyActivityReport;