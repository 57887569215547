import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { InputCheckBox, ReactSelect, PopUpModal, ContentOverlay } from '../../../../components'
import { rolePermissions, levelOptions } from './viewModel'
import { ClientContext } from '../../../layout/clientContext'
import { default as _images } from "../../../../images";
import CommonHelper from '../../../../services/common';
import AddRole from './addRole'

/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../../../components/customdropdown';
import Translate from '../../../../constants/translate';

const UserRoles = (props) => {
    const [clientOptions, setClientOptions] = useState([]);
    const [oemOptions, setOEMOptions] = useState([]);
    const [selectedSettingsID, setSelectedSettingsID] = useState('')
    const [selectedClient, setSelectedClient] = useState('')
    const [selectedOEM, setSelectedOEM] = useState('')
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [clientUsers, setClientUsers] = useState([])
    const [userLoader, setUserloader] = useState(false)
    const [roleLoader, setRoleloader] = useState(false)
    const [permissionLoader, setPermissionloader] = useState(false)
    const [showRoleModel, setShowRoleModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [editRole, setEditRole] = useState(null)
    const [oldSelecedRole, setOldSelecedRole] = useState(null)
    const [searchPermission, setSearchPermission] = useState('')
    const { clients } = useContext(ClientContext);

    useEffect(() => {
        if (props.activeKey === 'users') {
            // function handleResize() {
            //     document.documentElement.style.setProperty('--rolenavbarHeight', (window.innerHeight - 260) + 'px');
            //     document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 209) + 'px');
            //     document.documentElement.style.setProperty('--rolesuseritemHeight', (window.innerHeight - 350) + 'px');
            // }
            // window.addEventListener('resize', handleResize);
            // document.documentElement.style.setProperty('--rolenavbarHeight', (window.innerHeight - 260) + 'px');
            // document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 209) + 'px');
            // document.documentElement.style.setProperty('--rolesuseritemHeight', (window.innerHeight - 350) + 'px');
            // return () => {
            //     window.removeEventListener('resize', handleResize);
            // }
        }
    }, [props.activeKey])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            clients.forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client })
            });
            setClientOptions(_clients)
        }
    }, [clients])

    useEffect(() => {
        const OEMsettingSnapshot = window.firebase.firestore().collection(`clientSettings`)
            .onSnapshot(querySnapshot => {
                let oemSettings = [];
                querySnapshot.forEach((rec) => {
                    oemSettings.push({ label: rec.data().name, value: rec.id, data: { ...rec.data(), 'id': rec.id } })
                })
                setOEMOptions(_.orderBy(oemSettings, ['label'], ['asc']));
            })
        return () => {
            OEMsettingSnapshot && OEMsettingSnapshot();
            window.OEMClientSnapshot && window.OEMClientSnapshot();
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(selectedOEM)) {
            window.OEMClientSnapshot = window.firebase.firestore().collection(`clientSettings/${selectedOEM.id}/clients`)
                .onSnapshot(querySnapshot => {
                    let clients = [];
                    querySnapshot.forEach((rec) => {
                        clients.push({ label: rec.data().name, value: rec.id, data: { ...rec.data(), 'id': rec.id } })
                    })
                    setClientOptions(clients)
                })
        }
    }, [selectedOEM])

    useEffect(() => {
        if (_.isEmpty(selectedClient))
            return

        setUserloader(true)
        const clientUserSnapshot = window.firebase.firestore().collection('users')
            .where('arrayClientID', 'array-contains', selectedClient.id)
            .onSnapshot(querysnapshot => {
                let users = []
                querysnapshot.forEach((doc) => {
                    const _user = doc.data()
                    users.push({
                        id: doc.id,
                        name: _user.name,
                        email: _user.email,
                        profileImage: _user.profileImage,
                        roleID: _user.roleID,
                        arrayClientID: _user.arrayClientID
                    })
                })
                setClientUsers(users)
                setUserloader(false)
            });

        return () => {
            clientUserSnapshot && clientUserSnapshot()
        }
    }, [selectedClient])

    // async function getClientUsers() {
    //     setUserloader(true)
    //     let users = []
    //     const clientUserSnapshot = await window.firebase.firestore().collection('users')
    //         .where('arrayClientID', 'array-contains', selectedClient.id)
    //         .get();
    //     clientUserSnapshot.forEach((doc) => {
    //         const _user = doc.data()
    //         users.push({
    //             id: doc.id,
    //             name: _user.name,
    //             email: _user.email,
    //             profileImage: _user.profileImage
    //         })
    //     });
    //     users.forEach(_user => {
    //         window.firebase.firestore().doc(`users/${_user.id}/userClients/${selectedClient.id}`)
    //             .onSnapshot((docSnapshot) => {
    //                 setClientUsers([..._.forEach(users, function (obj) {
    //                     if (obj.id === _user.id)
    //                         _.set(obj, 'roleID', (docSnapshot.exists ? docSnapshot.data().roleID : ''));
    //                 })]);
    //             });
    //     })
    //     setUserloader(false)
    // }

    const handleOEMChange = async (e) => {
        if ((!_.isEmpty(selectedRole) && JSON.stringify(selectedRole)) !== (!_.isEmpty(oldSelecedRole) && JSON.stringify(oldSelecedRole))) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to discard permission changes?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    getClientRole(e)
                }
            })
        }
        else {
            getClientRole(e)
        }
    }

    const getClientRole = async (e) => {
        setSelectedOEM(e.data)
        setSelectedSettingsID(e.value)
        setRoleloader(true)
        const refClientRoles = await window.firebase.firestore().collection(`clientSettings/${e.value}/roles`).orderBy('addedDate', 'asc').get()
        setRoleloader(false)
        if (refClientRoles.docs.length > 0) {
            var _roles = [];
            var i = 0;
            refClientRoles.docs.forEach(doc => {
                _roles.push({
                    ...doc.data(),
                    'documentID': doc.id,
                    'active': i === 0 ? true : false
                });
                i++;
            })
            setRoles(_roles);
            setSelectedRole(_roles.find(item => item.active === true))
            setOldSelecedRole(_roles.find(item => item.active === true))
        }
        else {
            setRoles([]);
            setSelectedRole(null)
            setOldSelecedRole(null)
        }
    }

    const handleRoleChange = (role, delRole) => {
        if (JSON.stringify(selectedRole) !== JSON.stringify(oldSelecedRole)) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to discard permission changes?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    setRoles([..._.map(roles.filter(item => !_.isEmpty(delRole) ? item.documentID !== delRole.documentID : true), function (obj) {
                        return obj.documentID === oldSelecedRole.documentID ? { ...oldSelecedRole, 'active': false } : {
                            ...obj, 'active': (obj.documentID === role.documentID ? true : false)
                        };
                    })])
                    setSelectedRole(role)
                    setOldSelecedRole(role)
                }
            })
        }
        else {
            if (!_.isEmpty(role)) {
                setRoles([..._.forEach(roles.filter(item => !_.isEmpty(delRole) ? item.documentID !== delRole.documentID : true), function (obj) {
                    if (obj.documentID === role.documentID)
                        _.set(obj, 'active', true);
                    else
                        _.set(obj, 'active', false);
                })]);
                setSelectedRole(role)
                setOldSelecedRole(role)
            }
        }
    }

    const handlePermissionChange = (e, name) => {
        let _pipeline = [];
        if (name === 'pipeline') {
            if (e.target.checked)
                _pipeline = selectedRole[name] ? [...selectedRole[name].filter(item => item !== e.target.name), e.target.name] : [e.target.name];
            else
                _pipeline = selectedRole[name] ? [...selectedRole[name].filter(item => item !== e.target.name)] : [];
        }
        let _permissionCheck = { [e.target.name]: e.target.checked };
        if (e.target.name === 'ownerDashboard' || e.target.name === 'viewOtherEnquiry') {
            _permissionCheck = {
                [e.target.name]: e.target.checked,
                [e.target.name === 'ownerDashboard' ? 'viewOtherEnquiry' : 'ownerDashboard']: !e.target.checked
            }
        }
        var updateRole = {
            ...selectedRole,
            [name]: name === 'pipeline' ? _pipeline : {
                ...selectedRole[name],
                ..._permissionCheck
            }
        };
        setSelectedRole(updateRole)
        setRoles([..._.map(roles, function (obj) {
            return obj.documentID === selectedRole.documentID ? { ...updateRole } : obj;
        })])
    }

    const handleClientUserChange = (e) => {
        setClientUsers([{ ...e.doc, 'roleID': selectedRole.documentID }, ...clientUsers.filter(item => item.id !== e.value)]);
        window.firebase.firestore().doc(`users/${e.value}`).set({ 'roleID': selectedRole.documentID, 'role': selectedRole.name, 'level': selectedRole.level }, { merge: true })
        //window.firebase.firestore().doc(`users/${e.value}/userClients/${selectedClient.id}`).set({ 'roleID': selectedRole.documentID }, { merge: true })
        e.doc.arrayClientID.forEach(clientid => {
            window.firebase.firestore().doc(`users/${e.value}/userClients/${clientid}`).set({ 'roleID': selectedRole.documentID }, { merge: true })
        })
    }

    const handleswitchRole = (role, user) => {
        setClientUsers([{ ...user, 'roleID': role.documentID }, ...clientUsers.filter(item => item.id !== user.id)]);
        window.firebase.firestore().doc(`users/${user.id}`).set({ 'roleID': role.documentID, 'role': role.name, 'level': role.level }, { merge: true })
        //window.firebase.firestore().doc(`users/${user.id}/userClients/${selectedClient.id}`).set({ 'roleID': role.documentID }, { merge: true })
        user.arrayClientID.forEach(clientid => {
            window.firebase.firestore().doc(`users/${user.id}/userClients/${clientid}`).set({ 'roleID': role.documentID }, { merge: true })
        })
    }

    const handledeleteUserRole = (user) => {
        setClientUsers([{ ...user, 'roleID': '' }, ...clientUsers.filter(item => item.id !== user.id)]);
        window.firebase.firestore().doc(`users/${user.id}`).set({ 'roleID': '', 'role': '' }, { merge: true })
        //window.firebase.firestore().doc(`users/${user.id}/userClients/${selectedClient.id}`).set({ 'roleID': '' }, { merge: true })
        user.arrayClientID.forEach(clientid => {
            window.firebase.firestore().doc(`users/${user.id}/userClients/${clientid}`).set({ 'roleID': '' }, { merge: true })
        })
    }

    const handleModelClose = (role) => {
        setShowRoleModel(false)
        if (!_.isEmpty(role)) {
            setEditRole(null)
            if (!_.isEmpty(oldSelecedRole) && oldSelecedRole.documentID === role.documentID) {
                setOldSelecedRole({ ...oldSelecedRole, 'name': role.name })
            }
            if (roles.some(item => item.documentID === role.documentID)) {
                setRoles([..._.forEach(roles, function (obj) {
                    if (obj.documentID === role.documentID)
                        _.set(obj, 'name', role.name);
                })]);
            }
            else {
                setRoles([...roles.filter(item => item.documentID !== role.documentID), role]);
            }
        }
    }

    const handleeditRole = (role) => {
        setPopHeader('Edit Role')
        setEditRole(role)
        setShowRoleModel(true)
    }

    const handlePermissionSave = () => {
        setPermissionloader(true)
        delete selectedRole.active
        window.firebase.firestore().doc(`clientSettings/${selectedSettingsID}/roles/${selectedRole.documentID}`).set(selectedRole, { merge: true })
            .then(snapshot => {
                setPermissionloader(false)
                setOldSelecedRole(selectedRole)
                toast.notify('Permissions updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                setPermissionloader(false)
                toast.notify('Something went wrong', {
                    duration: 2000,
                    position: 'top-right'
                })
            });
    }

    const handledeleteRole = async (role) => {

        var checkroleUsers = await window.firebase.firestore().collection('users')
            .where('settingsID', '==', selectedSettingsID)
            .where('roleID', '==', role.documentID)
            .limit(1)
            .get();
        if (checkroleUsers.docs.length > 0) {
            Swal.fire('Role cannot be removed as it has been assigned to other users.', '', 'error')
            return;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`clientSettings/${selectedSettingsID}/roles/${role.documentID}`).delete()
                    .then(snapshot => {
                        clientUsers.filter(item => item.roleID === role.documentID).forEach(_user => {
                            window.firebase.firestore().doc(`users/${_user.id}`).set({ 'roleID': '' }, { merge: true })
                            window.firebase.firestore().doc(`users/${_user.id}/userClients/${selectedClient.id}`).set({ 'roleID': '' }, { merge: true })
                        })
                        if (role.active === true) {
                            handleRoleChange(roles.filter(item => item.documentID !== role.documentID)[0], role)
                        }
                        else {
                            setRoles([...roles.filter(item => item.documentID !== role.documentID)]);
                        }
                        setClientUsers([..._.forEach(clientUsers, function (obj) {
                            if (obj.roleID === role.documentID)
                                _.set(obj, 'roleID', '');
                        })]);
                        toast.notify('Role deleted successfully', {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.error(error);
                        //setSectionLoading(false)
                        toast.notify('Something went wrong', {
                            duration: 2000,
                            position: 'top-right'
                        })
                    });
            }
        })
    }
    const PermissionList = ({ rec }) => {
        return (<>
            <div className="settings-field-subheader"><Translate text={rec.type} /></div>
            {
                rec.permissions.map((r, i) => {
                    return <li key={i} className={selectedRole.level === 'individual' && r.value === 'oemDashboard' ? 'btn-disable' : ''}>
                        <span className={`switch switch-sm ml-2`}>
                            <InputCheckBox
                                className={`switch`}
                                id={`${rec.type}_${r.value}`}
                                name={r.value}
                                checked={!_.isEmpty(selectedRole[rec.type]) ? rec.type === 'pipeline' ? selectedRole[rec.type].some(item => item === r.value) : Boolean(selectedRole[rec.type][r.value]) : false}
                                onChange={(e) => { handlePermissionChange(e, rec.type) }} />
                            <label htmlFor={`${rec.type}_${r.value}`}>{r.name}</label>
                        </span></li>
                })
            }
        </>)
    }
    return (
        <>
            <div className="settings-head form-style">
                <div className="form-row">
                    <div className="form-group col-md-3">
                        <ReactSelect
                            options={oemOptions}
                            name={"oemID"}
                            placeholder={'select oem'}
                            onChange={handleOEMChange}
                            value={!_.isEmpty(selectedOEM) ? selectedOEM.id : ''}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                </div>
            </div>

            <div className="settings-column-wraper">
                <div className="settings-columns">
                    <div className="settings-column-left">
                        <div className="settings-column-title">
                            {
                                !_.isEmpty(selectedSettingsID) ? (
                                    <div className="column-add-button"><a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setEditRole(null); setShowRoleModel(true); setPopHeader('Add Role') }}> <i className="ico icon-add"></i></a></div>
                                ) : (<></>)
                            }
                            <h3><Translate text={'roles'} /></h3>
                        </div>
                        <div className="settings-column-nav">
                            {
                                roleLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : roles.length > 0 ? (<ul>
                                    {
                                        roles.map((rec, index) => {
                                            return <li key={index} className={`${rec.active === true ? 'active' : ''}`}>
                                                <Dropdown className="role-list-items-more" alignRight>
                                                    <Dropdown.Toggle as={CustomToggle} className="dropdown role-list-items-more-fix">
                                                        <i className="ico icon-more"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                        <Dropdown.Item eventKey="delete" onClick={(e) => { handleeditRole(rec) }}><i className="ico icon-edit"></i> <Translate text={'edit'} /> </Dropdown.Item>
                                                        <Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteRole(rec) }}><i className="ico icon-delete"></i> <Translate text={'delete'} /> </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <a href="#" className="column-list-left"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleRoleChange(rec);
                                                    }}
                                                >{rec.name}
                                                    <br />
                                                    <span className="user-role-span ml-0">{_.find(levelOptions, { value: rec.level }) ? _.find(levelOptions, { value: rec.level }).label : rec.level}</span>
                                                </a>
                                            </li>
                                        })
                                    }
                                </ul>) : (<></>)
                            }
                        </div>
                    </div>
                    <div className="settings-column-middle">
                        <div className="settings-column-title">
                            <h3><Translate text={'users'} /></h3>
                        </div>
                        {
                            !_.isEmpty(selectedRole) ? (<>
                                <div className="user-list">
                                    <p><Translate text={'Add users to'} /> '{selectedRole.name}'</p>
                                    <div className="row">
                                        <div className="col-md-6 pl-0">
                                            <ReactSelect
                                                options={clientOptions}
                                                name={"clientID"}
                                                placeholder={'select client'}
                                                onChange={(e, data) => {
                                                    if (e) {
                                                        setSelectedClient(e.data)
                                                    }
                                                    else {
                                                        setSelectedClient(null)
                                                    }
                                                }}
                                                value={!_.isEmpty(selectedClient) ? selectedClient.id : ''}
                                                classNamePrefix={`cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="col-md-6 pl-0">
                                            <ReactSelect
                                                options={_.map(clientUsers.filter(item => _.isEmpty(item.roleID)), function (c) {
                                                    return {
                                                        value: c.id,
                                                        doc: c,
                                                        searchlabel: c.name,
                                                        label: <div className="drop-image">
                                                            <img src={CommonHelper.showUserAvatar(c.profileImage, c.name)} alt="" className="img-object-fit" />
                                                            {c.name}
                                                        </div>
                                                    };
                                                })}
                                                name={"clientUser"}
                                                onChange={handleClientUserChange}
                                                value={''}
                                                classNamePrefix={`basic-select`}
                                                removeClearable={true}
                                                placeholder={'select user'}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                </div>
                                {
                                    userLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :

                                        !_.isEmpty(selectedClient) ? clientUsers.length === 0 ? (<><div className="common-table"><div className="text-center p-5"><p><Translate text={'pleaseSelectUser'} /></p></div></div></>) : (<div className="user-list-items">
                                            <ul>
                                                {
                                                    clientUsers.filter(item => item.roleID === selectedRole.documentID).map((rec, index) => {
                                                        return <li key={index} className="user-list-box">
                                                            <Dropdown className="user-list-items-more" drop={'left'}>
                                                                <Dropdown.Toggle as={CustomToggle} className="dropdown">
                                                                    <i className="ico icon-more"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                                    {
                                                                        roles.filter(item => item.active === false).map((_role, index) => {
                                                                            return <Dropdown.Item key={index} eventKey={index} onClick={(e) => { handleswitchRole(_role, rec) }}> {_role.name} </Dropdown.Item>
                                                                        })
                                                                    }
                                                                    <Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteUserRole(rec) }}><i className="ico icon-delete"></i> <Translate text={'Remove'} /> </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            <div className="user-list-items-image">
                                                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" className="rounded-circle img-object-fit" />
                                                            </div>
                                                            <div className="user-list-items-info">
                                                                <h4>{rec.name}</h4>
                                                                <h5>{rec.email}</h5>
                                                            </div>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>) : (<><Translate text={'select client'} /></>)
                                }
                            </>
                            ) : (<></>)
                        }

                    </div>
                    <div className="settings-column-right">
                        <div className="settings-column-title">
                            <h3><Translate text={'permissions'} /></h3>
                            {
                                !_.isEmpty(selectedRole) ? (
                                    <div className="column-common-button">
                                        <button type="button" className="btn btn-primary float-right" onClick={() => handlePermissionSave()}>
                                            {
                                                permissionLoader ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            <Translate text={'save'} /></button>
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                        <div className={`permission-list roles-nav-left`}>
                            {
                                !_.isEmpty(selectedRole) ? (<>
                                    <div className="settings-inner-padding">
                                        <div className="filter-search w-100">
                                            <input placeholder="search permissions" aria-label="Search" className="w-100" value={searchPermission} onChange={(e) => { setSearchPermission(e.target.value) }} />
                                        </div>
                                    </div>
                                    <ul className={``}>
                                        {
                                            _.orderBy(_.chain(rolePermissions.filter(({ name, value }) =>
                                            (!_.isEmpty(searchPermission) ? (
                                                (!_.isEmpty(name) ? name.toLowerCase().includes(searchPermission.toLowerCase()) : false) ||
                                                (!_.isEmpty(value) ? value.toLowerCase().includes(searchPermission.toLowerCase()) : false)
                                            ) : true)
                                            ))
                                                .groupBy("type")
                                                .map((value, key) => ({
                                                    type: key, permissions: value
                                                }))
                                                .value(), ['type'], ['asc']).map((rec, index) => {
                                                    return <PermissionList key={index} rec={rec} />
                                                })
                                        }
                                    </ul></>) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <PopUpModal show={showRoleModel}>
                <AddRole
                    show={showRoleModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    role={editRole}
                    settingsID={selectedSettingsID}
                    allRoles={roles}
                />
            </PopUpModal>
        </>
    );
}

export default UserRoles;