/** LIBRARIES */
import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import Translate from '../../../constants/translate';

/** COMPONENTS */
import CommonHelper from '../../../services/common';
import { ContentOverlay, PopUpModal, TableView } from '../../../components';
import { firestoreDB } from '../../../services/helper';
import FilterPanel from './filter'

//import { default as _images } from '../../images';
import AddStock from '../../stock/add'
import AddFleet from '../../fleet/add'
import AddTestDrive from '../../testdrive/add'

import StockListReducer from './stockListReducer'
import { stockStatus } from '../../../services/enum';

import { allFleetStockFieldsVM, objFleetStockVM } from '../../fleet/viewModel'
import { objStock } from '../../stock/viewModel'

const StockList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [stocks, dispatch] = useReducer(StockListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [sortName, setSortName] = useState('modifiedDate')
    const [sortOrder, setSortOrder] = useState('desc')
    const [hasMore, setHasMoreData] = useState([])
    const [fleetFields, setStockFields] = useState(props.dealersettings.fleetFields ? props.dealersettings.fleetFields : [])
    const [settingsLoader, setSettingsLoader] = useState(false)

    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [stockLoader, setStockLoader] = useState(true)
    const pageLimit = 50

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0)
    const [selectedIds, setSelectedIds] = useState({})
    const [csvHeader, setHeader] = useState([])
    const [searchText, setSearchText] = useState(localStorage.oemStockSearchText ? localStorage.oemStockSearchText : null)
    const [stockCount, setStockCount] = useState(0)


    const [modelFleetShow, setModelFleetShow] = useState(false)
    const [modelTestDriveShow, setModelTestDriveShow] = useState(false)
    const [modelStock, setModelStock] = useState({})

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
    const exportData = ((!_.isEmpty(_permissions) && _permissions.exportData) ? true : false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if ((searchText && searchText.length > 2) || (searchText === ''))
                handleSearchText(searchText);

        }, 2000);
        return () => clearTimeout(timeoutId);
    }, [searchText]);


    useEffect(() => {
        let headerFields = fleetFields;
        let allHeaderFields = allFleetStockFieldsVM;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        setHeader(_headers);

    }, [fleetFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubOEMStocks && window.unSubOEMStocks();
        }
    }, [])

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'stocks')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.pipelineFilter', isFilterApplied, localStorage.pipelineFilter)
        if (localStorage.oemStockFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.oemStockFilter])

    useEffect(() => {

        if (!checkDataLoad) {
            return;
        }

        const { dealersettings } = props;
        // let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
        let _stockFilter = localStorage.oemStockFilter ? JSON.parse(localStorage.oemStockFilter) : {};
        let _filter = Object.assign({}, _stockFilter?.value);

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
            delete _filter['date'];
        }

        if (!_.isEmpty(_filter.make))
            _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

        if (_.isEmpty(_filter.clientID) && !_.isEmpty(dealersettings.group.clients))
            _filter.clientID = Object.keys(dealersettings.group.clients).map(cid => cid).join(',')

        _filter.status = _filter.status ? _filter.status : 'onLoan,onTestdrive'
        let _filterStatus = _filter.status;

        //_filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, props.dealersettings);
        _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess();
        let _searchObject = {
            "type": "searchStock",
            "filters": JSON.stringify(_filter),
            "sortOrder": "modifiedDate desc",
            "pageNum": pageNum,
            "pageLimit": pageLimit
        }
        //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings);
        //console.log('_filter', _filter, searchText)
        _searchObject.clientID = _filter.clientID;
        const searchStock = window.firebase.functions().httpsCallable('generic-searchData');
        searchStock(_searchObject).then((response) => {
            if (response.data.success) {
                onCollectionStocks(response.data);
            }
            else {
                setDataload(false)
                setStockLoader(false)
                setPaging(false)
            }
        });

        let _searchObj = {
            type: "getStockCount",
            params: JSON.stringify({ ..._filter, status: _filterStatus }),
        }
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        getData(_searchObj).then((resp) => {
            if (!_.isEmpty(resp) && !_.isEmpty(resp.data) && resp.data.success) {
                const data = resp.data.data[0]
                setStockCount(data);
                //console.log(`getStockCount`, data, '_searchObj', _searchObj)
            }

        });


    }, [checkDataLoad])


    const onCollectionStocks = (querySnapshot) => {

        const _stocks = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            var dataVM = dataMappingVM(doc);
            _stocks.push(dataVM)
        });

        dispatch({
            type: isPaging ? "_APPEND" : "_SUCCESS",
            data: _stocks,
            sortName: 'modifiedDate',
            sortOrder: 'desc'
        });

        setDataload(false)
        setStockLoader(false)
        setPaging(false)
    }

    // const onQueryCollectionStocks = (querySnapshot, _filter) => {

    //     let _stocks = [];

    //     if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
    //         setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
    //         let _pageNum = pageNum + 1;
    //         setPageNum(_pageNum)
    //     }
    //     else {
    //         setHasMoreData([]);
    //     }
    //     querySnapshot.docs.forEach(function (doc) {
    //         var dataVM = dataMappingVM({ ...doc.data(), 'documentID': doc.id });
    //         _stocks.push(dataVM)
    //     });

    //     _stocks = _stocks.filter(item => (
    //         ((_filter.dateType && _filter.startDate && _filter.endDate && item[`${_filter.dateType}Value`])
    //             ? (item[`${_filter.dateType}Value`].seconds >= moment(_filter.startDate).startOf('day').unix() && item[`${_filter.dateType}Value`].seconds <= moment(_filter.endDate).endOf('day').unix()) : true)
    //         && (_filter.keywords ? _.indexOf(item.keywords, _filter.keywords.toLowerCase()) >= 0 : true)
    //         && (_filter.owner ? item.addedByValue === _filter.owner : true)
    //         && (_filter.status === 'available' ? (item.statusValue === _filter.status && item.isAllocated === false) : true)
    //         && (_filter.status === 'allocated' ? item.isAllocated === true : true)
    //         && (_filter.saleType ? item.saleTypeValue === _filter.saleType : true)
    //         && (_filter.location ? item.locationValue === _filter.location : true)
    //         && (_filter.make ? item.make.toLowerCase().includes(_filter.make.toLowerCase()) : true)
    //         && (_filter.model ? item.model.toLowerCase().includes(_filter.model.toLowerCase()) : true)
    //         && (_filter.year ? item.year === _filter.year : true)
    //         && (_filter.isSale ? item.isSale === _filter.isSale : true)
    //         && (_filter.isTestDrive ? item.isTestDrive === _filter.isTestDrive : true)
    //         && (_filter.isLoan ? item.isLoan === _filter.isLoan : true)
    //     ))
    //     dispatch({
    //         type: isPaging ? "_APPEND" : "_SUCCESS",
    //         data: _stocks,
    //         sortName: '',
    //         sortOrder: ''
    //     });

    //     setStockLoader(false)
    //     setDataload(false)
    //     setPaging(false)
    // }

    const handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }


    const dataMappingVM = (doc) => {

        doc.regDate = doc.regDate && doc.regDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regDate._seconds)._d) : doc.regDate;
        doc.regExp = doc.regExp && doc.regExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regExp._seconds)._d) : doc.regExp;
        doc.warrantyStartDate = doc.warrantyStartDate && doc.warrantyStartDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyStartDate._seconds)._d) : doc.warrantyStartDate;
        doc.warrantyExp = doc.warrantyExp && doc.warrantyExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyExp._seconds)._d) : doc.warrantyExp;
        doc.stockInDate = doc.stockInDate && doc.stockInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stockInDate._seconds)._d) : doc.stockInDate;
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        doc.etaDate = doc.etaDate && doc.etaDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.etaDate._seconds)._d) : doc.etaDate;
        doc.fokDate = doc.fokDate && doc.fokDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.fokDate._seconds)._d) : doc.fokDate;
        doc.soldDate = doc.soldDate && doc.soldDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.soldDate._seconds)._d) : doc.soldDate;
        doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;
        doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;

        const objlogData = Object.assign({}, doc);
        const stockVM = Object.assign({}, objFleetStockVM);
        for (let [key, value] of Object.entries(objlogData)) {
            stockVM[key] = value;
        }

        if (stockVM.clientID && (stockVM.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            stockVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, stockVM.clientID);
        }
        else
            stockVM.dealerName = '';

        stockVM.keywords = doc.keywords
        stockVM.createdOn = stockVM.addedDate ? moment.unix(stockVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        stockVM.updatedOn = stockVM.modifiedDate ? moment.unix(stockVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        stockVM.addedBy = stockVM.addedBy ? CommonHelper.getUserNamebyId(allUsers, stockVM.addedBy) : '--';
        stockVM.modifiedBy = stockVM.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, stockVM.modifiedBy) : '--';
        stockVM.statusUpdatedBy = stockVM.statusModifiedBy ? CommonHelper.getUserNamebyId(allUsers, stockVM.statusModifiedBy) : '';

        if (!_.isEmpty(stockVM.regDate))
            stockVM.regDate = moment.unix(stockVM.regDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stockVM.warrantyExp))
            stockVM.warrantyExp = moment.unix(stockVM.warrantyExp.seconds).format('DD/MM/YYYY');

        let dealersettings = props.dealersettings;
        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            stockVM.location = CommonHelper.getNameByValue(dealersettings.client.settings.stockLocation, stockVM.location, '');
            stockVM.saleType = CommonHelper.getNameByValue(dealersettings.client.settings.salesType, stockVM.saleType, '');
            stockVM.statusName = CommonHelper.getNameByValue(dealersettings?.client?.settings?.stockStatus, stockVM.status, '');
            stockVM.stockStatus = CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, stockVM.status, '');
        }

        if (stockVM.status === stockStatus.ONLOAN && !_.isEmpty(stockVM.fleetDetail)) {
            if (!_.isEmpty(stockVM.fleetDetail.contact)) {
                let _contact = Object.assign({}, stockVM.fleetDetail.contact);

                stockVM.contactFullName = CommonHelper.displayFullContact(dealersettings?.client?.settings?.titles, _contact, '', true);
                stockVM.contactName = <div onClick={(e) => {
                    e.preventDefault();
                    const url = `/contacts/details/${_contact.documentID}`;
                    window.open(url, '_blank');
                }}>
                    {stockVM.contactFullName}</div>;

                stockVM.contactPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, dealersettings);
                stockVM.phone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
                stockVM.contactEmail = _contact.email;
                stockVM.licenseNo = _contact.licenseNo;
                stockVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
                stockVM.contactType = _contact.contactType;
                stockVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
                stockVM.gender = _contact.gender;
                stockVM.maritalStatus = _contact.maritalStatus;
                stockVM.contactMethod = _contact.contactMethod;
                stockVM.licenseType = _contact.licenseType;
                stockVM.isDLScan = _contact.isDLScan ? 'Yes' : 'No';
                stockVM.language = CommonHelper.getNameByCode(dealersettings.languages, _contact.language, '');
                stockVM.nationality = CommonHelper.getNameByCode(dealersettings.languages, _contact.nationality, '');

                if (dealersettings && dealersettings.client && dealersettings.client.settings) {
                    stockVM.campaign = CommonHelper.getNameByValue(dealersettings.client.settings.campaigns, _contact.campaign, '');
                    stockVM.origin = CommonHelper.getNameByValue(dealersettings.client.settings.origins, _contact.origin, '');
                    stockVM.gender = CommonHelper.getNameByValue(dealersettings.client.settings.genders, _contact.gender, '');
                    stockVM.maritalStatus = CommonHelper.getNameByValue(dealersettings.client.settings.maritalStatus, _contact.maritalStatus, '');
                    stockVM.contactMethod = CommonHelper.getNameByValue(dealersettings.client.settings.contactMethod, _contact.contactMethod, '');
                    stockVM.interests = CommonHelper.getLabelByMultipleValues(dealersettings.client.settings.interests, _contact.interests, '', true);
                    stockVM.licenseType = CommonHelper.getNameByValue(dealersettings.client.settings.licenseType, _contact.licenseType, '');
                }

                stockVM.address = _contact.address;
                stockVM.companyName = _contact.companyName;
                stockVM.designation = _contact.designation;

                stockVM.licenseState = _contact.licenseState;
                stockVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
                stockVM.secondaryContactPhone = CommonHelper.phoneFormat(_contact.secondaryPhoneCode, _contact.secondaryPhone, props.dealersettings);
                stockVM.secondaryPhone = _contact.secondaryPhone ? ((_contact.secondaryPhoneCode ? _contact.secondaryPhoneCode : '') + _contact.secondaryPhone) : '';
                stockVM.secondaryContactEmail = _contact.secondaryEmail;
                stockVM.contactCreatedBy = _contact.addedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.addedBy) : '';
                stockVM.contactModifiedby = _contact.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.modifiedBy) : '';
                stockVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
                stockVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            }
            else if (!_.isEmpty(stockVM.fleetDetail.staff)) {
                stockVM.contactFullName = stockVM.fleetDetail.staff.name ? stockVM.fleetDetail.staff.name : '';
                stockVM.contactName = stockVM.contactFullName;
            }

            if (stockVM.fleetDetail.fleetID)
                stockVM.fleetID = stockVM.fleetDetail.fleetID;

            let _startDate = stockVM?.fleetDetail?.startDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(stockVM.fleetDetail.startDate._seconds)._d) : stockVM.fleetDetail.startDate;
            let _expectedReturn = stockVM?.fleetDetail?.expectedReturn?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(stockVM.fleetDetail.expectedReturn._seconds)._d) : stockVM.fleetDetail.expectedReturn;
            let _addedDate = stockVM?.fleetDetail?.addedDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(stockVM.fleetDetail.addedDate._seconds)._d) : stockVM.fleetDetail.addedDate;
            stockVM.startDate = _startDate ? moment.unix(_startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            stockVM.expectedReturn = _expectedReturn ? moment.unix(_expectedReturn.seconds).format('DD/MM/YYYY hh:mm A') : '';
            stockVM.fleetAddedBy = CommonHelper.getUserNamebyId(allUsers, stockVM.fleetDetail.addedBy);
            stockVM.fleetOwner = stockVM.fleetDetail.addedBy;
            stockVM.fleetAddedDate = _addedDate ? moment.unix(_addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            stockVM.roNumber = stockVM.fleetDetail.roNumber ? stockVM.fleetDetail.roNumber : '';
            stockVM.loanType = stockVM.fleetDetail.loanType ? stockVM.fleetDetail.loanType : '';

            if (stockVM.fleetDetail.clientID) {
                stockVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, stockVM.fleetDetail.clientID);
            }

        }

        if (stockVM.status === stockStatus.ONTESTDRIVE && !_.isEmpty(stockVM.testDriveDetail)) {
            if (!_.isEmpty(stockVM.testDriveDetail.contact)) {
                let _contact = Object.assign({}, stockVM.testDriveDetail.contact);
                stockVM.contactFullName = CommonHelper.displayFullContact(dealersettings?.client?.settings?.titles, _contact, '', true);
                stockVM.contactName = <div onClick={(e) => {
                    e.preventDefault();
                    const url = `/contacts/details/${_contact.documentID}`;
                    window.open(url, '_blank');
                }}>{stockVM.contactFullName}</div>;

                stockVM.contactPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
                stockVM.phone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
                stockVM.contactEmail = _contact.email;
                stockVM.licenseNo = _contact.licenseNo;
                stockVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
                stockVM.contactType = _contact.contactType;
                stockVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
                stockVM.gender = _contact.gender;
                stockVM.maritalStatus = _contact.maritalStatus;
                stockVM.contactMethod = _contact.contactMethod;
                stockVM.licenseType = _contact.licenseType;
                stockVM.isDLScan = _contact.isDLScan ? 'Yes' : 'No';
                stockVM.language = CommonHelper.getNameByCode(dealersettings.languages, _contact.language, '');
                stockVM.nationality = CommonHelper.getNameByCode(dealersettings.languages, _contact.nationality, '');


                if (dealersettings && dealersettings.client && dealersettings.client.settings) {
                    stockVM.campaign = CommonHelper.getNameByValue(dealersettings.client.settings.campaigns, _contact.campaign, '');
                    stockVM.origin = CommonHelper.getNameByValue(dealersettings.client.settings.origins, _contact.origin, '');
                    stockVM.gender = CommonHelper.getNameByValue(dealersettings.client.settings.genders, _contact.gender, '');
                    stockVM.maritalStatus = CommonHelper.getNameByValue(dealersettings.client.settings.maritalStatus, _contact.maritalStatus, '');
                    stockVM.contactMethod = CommonHelper.getNameByValue(dealersettings.client.settings.contactMethod, _contact.contactMethod, '');
                    stockVM.interests = CommonHelper.getLabelByMultipleValues(dealersettings.client.settings.interests, _contact.interests, '', true);
                    stockVM.licenseType = CommonHelper.getNameByValue(dealersettings.client.settings.licenseType, _contact.licenseType, '');
                }

                stockVM.address = _contact.address;
                stockVM.companyName = _contact.companyName;
                stockVM.designation = _contact.designation;

                stockVM.licenseState = _contact.licenseState;
                stockVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
                stockVM.secondaryContactPhone = CommonHelper.phoneFormat(_contact.secondaryPhoneCode, _contact.secondaryPhone, dealersettings);
                stockVM.secondaryPhone = _contact.secondaryPhone ? ((_contact.secondaryPhoneCode ? _contact.secondaryPhoneCode : '') + _contact.secondaryPhone) : '';
                stockVM.secondaryContactEmail = _contact.secondaryEmail;
                stockVM.contactCreatedBy = CommonHelper.getUserNamebyId(allUsers, _contact.addedBy);
                stockVM.contactModifiedby = CommonHelper.getUserNamebyId(allUsers, _contact.modifiedBy);
                stockVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
                stockVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            }

            if (stockVM.testDriveDetail.testdriveID)
                stockVM.testDriveID = stockVM.testDriveDetail.testdriveID;

            let _startDate = stockVM?.testDriveDetail?.startDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(stockVM.testDriveDetail.startDate._seconds)._d) : stockVM.testDriveDetail.startDate;
            let _expectedReturn = stockVM?.testDriveDetail?.expectedReturn?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(stockVM.testDriveDetail.expectedReturn._seconds)._d) : stockVM.testDriveDetail.expectedReturn;
            let _addedDate = stockVM?.testDriveDetail?.addedDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(stockVM.testDriveDetail.addedDate._seconds)._d) : stockVM.testDriveDetail.addedDate;
            stockVM.startDate = _startDate ? moment.unix(_startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            stockVM.expectedReturn = _expectedReturn ? moment.unix(_expectedReturn.seconds).format('DD/MM/YYYY hh:mm A') : '';
            stockVM.fleetAddedBy = CommonHelper.getUserNamebyId(allUsers, stockVM.testDriveDetail.addedBy);
            stockVM.fleetOwner = stockVM.testDriveDetail.addedBy;
            stockVM.fleetAddedDate = _addedDate ? moment.unix(_addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

            if (stockVM.testDriveDetail.clientID) {
                stockVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, stockVM.testDriveDetail.clientID);
            }
        }

        stockVM.stockInDays = ((!_.isEmpty(stockVM.stockInDate))
            ?
            handleDiffDays(stockVM.stockInDate)
            :
            '');

        stockVM.availability = (<div className="Vehicle-availability">
            {stockVM.isTestDrive ? <div><span>{'Test Drive'}</span></div> : <></>}
            {stockVM.isLoan ? <div><span>{'Loan'}</span></div> : <></>}
            {stockVM.isServiceLoan ? <div><span>{'Service Loan'}</span></div> : <></>}
            {stockVM.isSale ? <div><span>{'Sale'}</span></div> : <></>}
        </div>);

        stockVM.availabileFor = ((stockVM.isTestDrive ? 'Test Drive, ' : '') +
            (stockVM.isLoan ? 'Loan, ' : '') +
            (stockVM.isServiceLoan ? 'Service Loan, ' : '') +
            (stockVM.isSale ? 'Sale' : ''));

        return stockVM
    }


    // const dataMappingVM = (doc) => {
    //     let dealersettings = props.dealersettings;

    //     doc.regDate = doc.regDate && doc.regDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regDate._seconds)._d) : doc.regDate;
    //     doc.warrantyExp = doc.warrantyExp && doc.warrantyExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyExp._seconds)._d) : doc.warrantyExp;
    //     doc.warrantyStartDate = doc.warrantyStartDate && doc.warrantyStartDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyStartDate._seconds)._d) : doc.warrantyStartDate;
    //     doc.stockInDate = doc.stockInDate && doc.stockInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stockInDate._seconds)._d) : doc.stockInDate;
    //     doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
    //     doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

    //     if (doc.clientID && dealersettings && dealersettings.group &&
    //         dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings) {
    //         doc.location = CommonHelper.getNameByValue(dealersettings.group.clients[doc.clientID].settings.docLocation, doc.location, '');
    //         doc.saleType = CommonHelper.getNameByValue(dealersettings.group.clients[doc.clientID].settings.salesType, doc.saleType, '');
    //     }
    //     else if (dealersettings && dealersettings.client && dealersettings.client.settings) {
    //         doc.location = CommonHelper.getNameByValue(dealersettings.client.settings.docLocation, doc.location, '');
    //         doc.saleType = CommonHelper.getNameByValue(dealersettings.client.settings.salesType, doc.saleType, '');
    //     }

    //     const objStockData = Object.assign({}, doc);
    //     const stock = Object.assign({}, objStock);
    //     for (let [key, value] of Object.entries(objStockData)) {
    //         stock[key] = value;
    //     }

    //     stock.regDateValue = doc.regDate
    //     stock.warrantyExpValue = doc.warrantyExp
    //     stock.warrantyStartDateValue = doc.warrantyStartDate
    //     stock.stockInDateValue = doc.stockInDate
    //     stock.addedDateValue = doc.addedDate
    //     stock.modifiedDateValue = doc.modifiedDate
    //     stock.addedByValue = doc.addedBy
    //     stock.statusValue = doc.status
    //     stock.saleTypeValue = doc.saleType
    //     stock.locationValue = doc.location
    //     stock.createdOn = moment.unix(stock.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
    //     stock.updatedOn = moment.unix(stock.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');

    //     if (!_.isEmpty(stock.regDate))
    //         stock.regDate = moment.unix(stock.regDate.seconds).format('DD/MM/YYYY');

    //     if (!_.isEmpty(stock.warrantyExp))
    //         stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('DD/MM/YYYY');

    //     if (!_.isEmpty(stock.warrantyStartDate))
    //         stock.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('DD/MM/YYYY');

    //     if (!_.isEmpty(stock.buildDate))
    //         stock.buildDate = moment(stock.buildDate, 'YYYY-MM-DD').format('MMM YYYY');

    //     stock.stockInDate = ((!_.isEmpty(stock.stockInDate))
    //         ?
    //         handleDiffDays(stock.stockInDate)
    //         :
    //         '');
    //     stock.statusName = stock.status ? stock.status : '';
    //     stock.status = (stock.status
    //         ?
    //         <div className={`badge badge-pill badge-${stock.status.trim().toLowerCase()}-fill `}>
    //             {stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
    //         </div>
    //         :
    //         <></>);
    //     stock.availability = (<div className="Vehicle-availability">
    //         {stock.isTestDrive ? <div><span>{'Test Drive'}</span></div> : <></>}
    //         {stock.isLoan ? <div><span>{'Loan'}</span></div> : <></>}
    //         {stock.isSale ? <div><span>{'Sale'}</span></div> : <></>}
    //     </div>);


    //     stock.availabileFor = ((stock.isTestDrive ? 'Test Drive, ' : '') +
    //         (stock.isLoan ? 'Loan, ' : '') +
    //         (stock.isSale ? 'Sale' : ''));

    //     if (stock.clientID) {
    //         //stock.status = (<div className="badge badge-pill badge-allocated-fill ">ALLOCATED</div>)
    //         let _client = props.dealersettings.clients.filter(client => client.id === stock.clientID)[0];
    //         // stock.clientName = _client ? _client.name : '';
    //         stock.clientName = <span className="tbl-sub-dealer">{_client ? _client.name : ''}</span>
    //     }
    //     stock.addedBy = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), stock.addedBy);
    //     stock.modifiedBy = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), stock.modifiedBy);
    //     return stock
    // }

    const handleSearchText = (val) => {
        if (!_.isEmpty(_.trim(val))) {
            if (localStorage.oemStockFilter) {
                let _pipelineFilter = JSON.parse(localStorage.oemStockFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);
                _filter['keywords'] = _.trim(val);
                _pipelineFilter.value = _filter;
                handleApplyFilter(_pipelineFilter);
            }
            else {
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': val }, type: 'filter' })
                handleApplyFilter(_pipelineFilter);
            }
        }
        else {
            handleClearFilter();
        }

    }

    const handleApplyFilter = (_filter) => {
        //   console.log('this is the filter applied', _filter)
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('oemStockFilter', JSON.stringify(_filter));
            setFilterApplied(true);
            setStockLoader(true)
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setDataload(true);

        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.oemStockFilter)) {
            localStorage.removeItem('oemStockFilter');
            localStorage.oemStockSearchText = '';
            setSearchText('');
            setFilterApplied(false);
            setStockLoader(true)
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setDataload(true);
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ fleetFields: fields }, { merge: true })
            .then(() => {
                setStockFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('fleetFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, obj) => {


        if (obj.status === stockStatus.AVAILABLE) {
            return (
                <>
                    {
                        readOnlyEnquiryView
                            ?
                            <><div className="mini-button float-right mr-3"><i className="ico icon-lock"></i></div></>
                            :
                            <>
                                <div className="fleet-btn-loan width-fix">
                                    <button
                                        style={{ cursor: 'pointer' }}
                                        className="btn btn-sm btn-primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (!readOnlyEnquiryView)
                                                handleEditFleet(id, obj);
                                        }}
                                    >
                                        <Translate text={'loanVehicle'} />
                                    </button>
                                </div>
                            </>
                    }
                </>
            );
        }
        else if (obj.status === stockStatus.ONTESTDRIVE) {
            return (
                <>
                    {
                        readOnlyEnquiryView
                            ?
                            <><div className="mini-button float-right mr-3"><i className="ico icon-lock"></i></div></>
                            :
                            <>
                                <div className="fleet-btn-loan width-fix">
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (!readOnlyEnquiryView)
                                                handleEditFleet(id, obj);
                                        }}
                                    ><Translate text={'returnVehicle'} />
                                    </button>
                                </div>
                            </>
                    }
                </>
            );
        }
        else if (obj.status === stockStatus.ONLOAN) {
            return (
                <>
                    {
                        readOnlyEnquiryView
                            ?
                            <><div className="mini-button float-right mr-3"><i className="ico icon-lock"></i></div></>
                            :
                            <>
                                <div className="fleet-btn-loan width-fix">
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (!readOnlyEnquiryView)
                                                handleEditFleet(id, obj);
                                        }}
                                    >
                                        <Translate text={'returnVehicle'} />
                                    </button>
                                </div>
                            </>
                    }
                </>
            );
        }

    }

    const handleEditFleet = async (id, obj) => {
        // if (liteVersion)
        //     return;
        //console.log('handleEditFleet', id, obj)
        let _stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(obj)) {
            if (_stock.hasOwnProperty(key))
                _stock[key] = value;
        }

        if (_stock.status === stockStatus.ONTESTDRIVE && obj.testDriveID) {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('testdrives').doc(obj.testDriveID).get();
            if (refData.exists) {
                setEditDocument(obj.testDriveID);
                setModelTestDriveShow(true);
                setModelStock(_stock);
            }
            else {
                updateStockStatus(id);
            }
        }
        else if (_stock.status === stockStatus.ONLOAN && obj.fleetID) {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('fleet').doc(obj.fleetID).get();
            if (refData.exists) {
                setEditDocument(obj.fleetID);
                setModelFleetShow(true);
                setModelStock(_stock);
            }
            else {
                updateStockStatus(id);
            }

        }
    }

    const updateStockStatus = (id) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, "Are you sure?"),
            text: CommonHelper.showLocale(props, "Do you want to return this vehicle?"),
            icon: "info",
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, "Yes"),
            cancelButtonText: CommonHelper.showLocale(props, "No"),
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`stock/${id}`)
                    .set({
                        status: stockStatus.AVAILABLE,
                        enquiryID: null,
                        testDriveDetail: null,
                        fleetDetail: null,
                        statusModifiedBy: localStorage.uid,
                        statusModifiedFrom: 'web',
                        modifiedBy: localStorage.uid,
                        modifiedFrom: 'web',
                        modifiedDate: window.firebase.firestore.Timestamp.now()
                    }, { merge: true })
                    .then(() => {
                        toast.notify('Return successfully.', {
                            duration: 2000
                        })
                    })
            }
        });

    }


    const handleDetailsClick = (e, id) => {
        e.preventDefault();
        //props.history.push("/contacts/details/" + id);
        //setEditDocument(id);
        //setModelShow(true);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const handleClose = (id) => {

        setModelFleetShow(false);
        setModelTestDriveShow(false);
        setModelStock({});
        setEditDocument(null);

        if (!_.isEmpty(id)) {
            const oldLog = stocks.find(item => item.fleetID === id || item.testDriveID === id);
            if (!_.isEmpty(oldLog))
                dispatch({
                    type: "_REMOVE",
                    data: oldLog,
                    sortName: 'modifiedDate',
                    sortOrder: 'desc'
                });

        }
    }

    const updateStock = (objStock) => {
        if (!_.isEmpty(objStock)) {
            let _data = dataMappingVM(objStock);

            if (!_.isEmpty(_data)) {
                dispatch({
                    type: "_UPDATE",
                    data: _data,
                    sortName: 'modifiedDate',
                    sortOrder: 'desc'
                });
            }
        }

    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(stocks) && stocks.map(file => {
                let record = Object.assign({}, stocks.filter(e => e.documentID === file.documentID)[0]);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, stocks.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }


    return (
        <>
            <div className='middle-wrapper'>
                <FilterPanel
                    sortOrder={sortOrder}
                    customFilters={customFilters}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    handleApplyFilter={handleApplyFilter}
                    handleClearFilter={handleClearFilter}
                    sortName={sortName}
                    handlesortOrder={(val) => {
                        setSortOrder(val)
                        setHasMoreData([]);
                        setDataload(true)
                    }}
                    handleSortNamechange={(val) => {
                        setSortName(val)
                        setHasMoreData([]);
                        setDataload(true)
                    }}
                    handleAddStock={() => {
                        //setModelShow(true);
                        //setEditDocument(null);
                    }}
                    csvHeader={csvHeader}
                    datalist={stocks}
                    searchText={searchText}
                    handlesearchText={(val) => {
                        setSearchText(val);
                    }}
                    selectedIds={selectedIds}
                    onFleetMode={true}
                    history={props.history}
                />
                {
                    stockLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        (
                            <div className="contact-panel" style={{ width: windowSize.windowWidth + 'px' }}>
                                <TableView
                                    isReorderable={true}
                                    datalist={stocks}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={allFleetStockFieldsVM}
                                    handleSavesettings={handleSavesettings}
                                    dynamicFields={(fleetFields && fleetFields.length > 0) ? fleetFields : allFleetStockFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                    settingsLoader={settingsLoader}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleDetailsClick}
                                    isSettings={true}
                                    hasMore={hasMore}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    selectedIds={selectedIds}
                                    handleAllChecked={handleAllChecked}
                                    handleCheckChange={handleCheckChange}
                                />
                            </div>
                        )
                }

                <PopUpModal show={modelFleetShow}>
                    <AddFleet
                        docID={editDocumentID}
                        show={modelFleetShow}
                        clsActive='overlay-modal active'
                        stock={modelStock}
                        handleClose={handleClose}
                        title={'fleet'}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                    >
                    </AddFleet>
                </PopUpModal>

                {/* <PopUpModal show={modelStockShow}>
                    <AddStock
                        docID={editDocumentID}
                        show={modelStockShow}
                        clsActive='overlay-modal active'
                        handleClose={handleClose}
                        title={'stock'}
                        enableSale={false}
                        enableTestDrive={false}
                        enableLoan={true}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                    >
                    </AddStock>
                </PopUpModal> */}

                <PopUpModal show={modelTestDriveShow}>
                    <AddTestDrive
                        docID={editDocumentID}
                        show={modelTestDriveShow}
                        clsActive='overlay-modal active'
                        handleClose={handleClose}
                        title={'testDrive'}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                        isDeivered={false}
                        //enquiryID={this.state.enquiryID}
                        contact={null}
                    >
                    </AddTestDrive>
                </PopUpModal>


            </div>
        </>
    )
}

export default StockList