import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { ContentOverlay, TableView } from '../../../components';

const CampaignDetails = ({
	setActiveTab,
	campaign,
	windowSize,
	allrecipientFields,
	handleActionClick,
	handleRowSingleClick,
	contacts,
	contactsLoader,
	setpageNum,
	pageNum,
	setDataload,
	tableLoader,
	pageLimit,
	timezone
}) => {
	const [activeSubTab, setActiveSubTab] = useState('campaignpreview');

	return (
		<>
			<div className='campaign-view-wrapper-head'>
				<div className='float-left'>
					<div className='campaign-add-title'>{campaign ? campaign.title : ''} </div>
					<div className='campaign-add-sub-title'>
						<div className={`badge badge-pill float-left badge-${_.toLower(campaign.status) === 'sent'
							? 'available'
							: (_.toLower(campaign.status) === 'pending' || _.toLower(campaign.status) === 'sending')
								? 'pending'
								: (_.toLower(campaign.status) === 'failed' || _.toLower(campaign.status) === 'cancelled')
									? 'draft'
									: 'draft'
							}-fill `}
						>
							{campaign.status ? campaign.status : ''}
						</div>
						{
							campaign.DeliveredAt ? (
								<span className='float-left campaign-add-date'>
									{' '}
									Delivered on{' '}
									{moment(campaign.DeliveredAt).tz(timezone).format('DD/MM/YYYY hh:mm A')}{' '}
								</span>
							) : (
								<span className='float-left campaign-add-date'>
									{' '}
									Created on{' '}
									{moment(campaign.CreatedAt).tz(timezone).format('DD/MM/YYYY hh:mm A')}{' '}
								</span>
							)
						}
					</div>
				</div>
				<div className='float-right campaign-tab-align'>
					<div className='campaign-tab-switch center-fix'>
						<ul className='campaign-tabs-list'>
							<li
								className='campaign-tab-item campaign-tab-active'
								onClick={() => setActiveTab('detail')}
							>
								Campaign Details
							</li>
							<li
								className='campaign-tab-item '
								onClick={() => setActiveTab('report')}
							>
								Report
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='campaign-view-wrapper' style={{ minHeight: '75vh' }}>
				<div className='tabs'>
					<div className='tabs-wraper'>
						<nav>
							<Tabs
								transition={false}
								className='nav-fill'
								defaultActiveKey={activeSubTab}
								onSelect={k => setActiveSubTab(k)}
							>
								<Tab eventKey='campaignpreview' title='Campaign Preview'>
									<div>
										<div className='text-center'>
											<div
												dangerouslySetInnerHTML={{
													__html: campaign?.overview?.bodyHTML,
												}}
											></div>
										</div>
									</div>
								</Tab>
								<Tab eventKey='details' title='Details'>
									<div className='common-table'>
										<table className='table table-bordered'>
											<tbody>
												<tr>
													<th className='head-light'>
														Delivery date &amp; time
													</th>
													<td>
														{campaign.DeliveredAt ? moment(campaign.DeliveredAt).tz(timezone).format('DD/MM/YYYY hh:mm A') : ''}
													</td>
												</tr>

												<tr>
													<th className='head-light'>From name</th>
													<td>{campaign.SenderName}</td>
												</tr>
												<tr>
													<th className='head-light'>From email</th>
													<td>{campaign.SenderEmail}</td>
												</tr>
												<tr>
													<th className='head-light'>Subject line</th>
													<td>{campaign.Subject}</td>
												</tr>
												<tr>
													<th className='head-light'>Recipients</th>
													<td>
														{campaign?.overview?.Total || contacts?.length}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Tab>
								<Tab eventKey='recipients' title='Recipients'>
									{contactsLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<>
										{
											tableLoader
												?
												<>
													<div className="dashboard-widget-loader h-100">
														<div className="d-flex h-100 justify-content-center align-items-center text-primary">
															<div className="spinner-border" role="status">
																<span className="sr-only">Loading...</span>
															</div>
														</div>

													</div>
												</>
												:
												<>
												</>
										}
										<div className='common-table'>
											<TableView
												isReorderable={false}
												datalist={contacts}
												height={windowSize.windowHeight}
												width={windowSize.windowWidth - 70}
												columns={allrecipientFields}
												dynamicFields={allrecipientFields
													.filter(e => e.default === true)
													.map(v => _.pick(v, ['value']).value)}
												settingsLoader={false}
												handleActionClick={handleActionClick}
												handleRowSingleClick={handleRowSingleClick}
												isSettings={false}
												hasMore={[{ a: 1 }]}
												handlePagination={() => {
													let _pageNum = (pageNum + 1) * pageLimit;
													setpageNum(_pageNum)
													setDataload(true)
												}}
												isPaging={true}
											/>
										</div>
									</>)}
								</Tab>
							</Tabs>
						</nav>
					</div>
				</div>
			</div>
		</>
	);
};

export default CampaignDetails;
