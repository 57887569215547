import _ from 'lodash';
import React from 'react';

const LineChartCSS = (props) => {
    return _.isEmpty(props.data) ? (<></>) : (
        // https://webdesign.tutsplus.com/how-to-make-line-charts-in-pure-css--cms-108657t
        <div className="line-chart-wrapper">
            <ul className="chart-x">
                {
                    props.data.map((rec, index) => {
                        const _start = `${rec[`${props.field}Ptg`]}%`;
                        const _end = `${props.data[index + 1]?.[`${props.field}Ptg`]?.toString() ? props.data[index + 1]?.[`${props.field}Ptg`] : rec[`${props.field}Ptg`]}%`
                        return <li  className='linebar__bar' key={index} style={{ '--start': `${_start}`, '--end': `${_end}` }}>
                            <span className="linebar__tooltip">{rec.month}<br />{rec[`${props.field}`]}</span>
                            <span>&nbsp;</span>
                        </li>
                    })
                }
            </ul>
        </div>

    )
}

export default LineChartCSS;