import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { tradeinProTabs, NoDataDiv, LoadingDiv } from '../constants';
import CommonHelper from '../../../../services/common';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { tipStatus } from '../../../../services/enum'
import { firestoreDB } from '../../../../services/helper';
import Translate from '../../../../constants/translate';

const TradeinProSearch = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	handleCompanyView,
	handleOpenEditActivity,
	history,
	pipeline,
	getDealerName,
	modules,
	enableSharedContact,
	handleInBoundOpen
}) => {
	const [activeTab, setActivetab] = useState('all');

	const [appraisals, setAppraisals] = useState([]);
	const [contactDetails, setContactDetails] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [activities, setActivities] = useState([]);
	const [all, setAll] = useState([]);

	const [loader, setLoader] = useState([]);

	const inboundAccess = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.rolePermissions) &&
		!_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions) &&
		dealersettings.rolePermissions.tradeinProPermissions.inboundAccess) ? true : false);
	const tradeinAllStatus = (dealersettings && dealersettings.client && dealersettings.client.tradeinPro ? dealersettings.client.tradeinPro.tradeinProStatus : [])

	useEffect(() => {
		if (searchText.length > 0) {
			setLoader(true);

			let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
			let companiesInContacts = []

			let _chunkVal = 10;
			let _allUserClients = CommonHelper.getTradeinProDealers(dealersettings, true, true);

			let collectionPromise = [];
			for (let k = 0; k < Math.ceil(_allUserClients.length / _chunkVal); k++) {
				let refEnquiryData = firestoreDB(dealersettings).firestore().collection('tradeinPro')
					.where('clientID', 'in', CommonHelper.arrPaginate(_allUserClients, _chunkVal, k + 1))
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.orderBy('addedDate', 'desc')
					.limit(7);
				collectionPromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));
			}

			for (let k = 0; k < Math.ceil(_allUserClients.length / _chunkVal); k++) {
				let refContactDetails = firestoreDB(dealersettings).firestore().collection('contactDetails')
					.where('clientID', 'in', CommonHelper.arrPaginate(_allUserClients, _chunkVal, k + 1))
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.limit(7);
				collectionPromise.push(refContactDetails ? collection(refContactDetails) : of([]));
			}

			for (let k = 0; k < Math.ceil(_allUserClients.length / _chunkVal); k++) {
				let refCompanies = firestoreDB(dealersettings).firestore().collection('companies')
					.where('clientID', 'in', CommonHelper.arrPaginate(_allUserClients, _chunkVal, k + 1))
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.limit(10);
				collectionPromise.push(refCompanies ? collection(refCompanies) : of([]));
			}

			for (let k = 0; k < Math.ceil(_allUserClients.length / _chunkVal); k++) {
				let refActivities = firestoreDB(dealersettings).firestore().collection('activities')
					.where('type', '==', 'tradeinProactivity')
					.where('clientID', 'in', CommonHelper.arrPaginate(_allUserClients, _chunkVal, k + 1))
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.orderBy('startDate', 'desc')
					.limit(5)
				collectionPromise.push(refActivities ? collection(refActivities) : of([]));
			}

			for (let k = 0; k < Math.ceil(_allUserClients.length / _chunkVal); k++) {
				let refInboundLeads = '';
				if (inboundAccess) {
					refInboundLeads = firestoreDB(dealersettings).firestore().collection('tradeinProInbound')
						.where('clientID', 'in', CommonHelper.arrPaginate(_allUserClients, _chunkVal, k + 1))
						.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
						.where('status', '==', 0)
						.where('isDeleted', '==', false)
						.limit(10);
				}
				collectionPromise.push(refInboundLeads ? collection(refInboundLeads) : of([]));
			}

			var dashboardPipelines = combineLatest(collectionPromise).pipe(
				map((allLogs) => {
					let _i = 0;
					let logs = []
					Object.assign([], ['tradeinPro', 'contactDetail', 'company', 'activity', 'inboundLeads']).forEach((_renderType) => {
						let _combine = []
						for (let k = 0; k < Math.ceil(_allUserClients.length / _chunkVal); k++) {
							let _mapData = [];
							if (_renderType === 'tradeinPro')
								_mapData = _.map(allLogs[_i], function (obj) {
									return {
										...obj.data(),
										documentID: obj.id,
										renderType: _renderType,
										sortDate: obj.data().stageDate,
										isInbound: false,
									};
								});
							if (_renderType === 'contactDetail')
								_mapData = _.map(allLogs[_i], function (obj) {
									let contactObj = obj.data()
									if (!_.isEmpty(contactObj.company) && contactObj.company.documentID) {
										companiesInContacts.push({ ...contactObj.company, renderType: 'company', })
									}
									return {
										...contactObj,
										documentID: obj.id,
										renderType: _renderType,
										sortDate: contactObj.addedDate,
									};
								});
							if (_renderType === 'company')
								_mapData = _.map(allLogs[_i], function (obj) {
									return {
										...obj.data(),
										documentID: obj.id,
										renderType: _renderType,
										sortDate: obj.data().addedDate,
									};
								});
							if (_renderType === 'activity')
								_mapData = _.map(allLogs[_i], function (obj) {
									return {
										...obj.data(),
										documentID: obj.id,
										renderType: _renderType,
										sortDate: obj.data().startDate,
									};
								});
							if (_renderType === 'inboundLeads')
								_mapData = _.map(allLogs[_i], function (obj) {
									return {
										...obj.data(),
										documentID: obj.id,
										renderType: 'tradeinPro',
										sortDate: obj.data().addedDate,
										isInbound: true,
									};
								});
							_combine = _.union(_combine, _mapData)
							_i++;
						}
						logs.push(_combine)
					})
					return logs;
				}))
				.subscribe((allDocs) => {
					console.log(allDocs)
					let filterActivities = allDocs[3];
					let allAppraisals = [...allDocs[0], ...allDocs[4]]
					let allCompanies = _.uniqBy([...allDocs[2], ...companiesInContacts], 'documentID')
					setAppraisals(allAppraisals);
					setContactDetails(allDocs[1]);
					setCompanies(allCompanies);
					setActivities(filterActivities);
					setAll([
						...allAppraisals,
						...allDocs[1],
						...allCompanies,
						...filterActivities,
					]);
					setLoader(false);
				});
		}
		return () => {
			dashboardPipelines && dashboardPipelines.unsubscribe();
		};
	}, [searchText, dealersettings.client.documentID]);

	// useEffect(() => {
	// 	if (searchText.length > 0) {
	// 		setLoader(true);

	// 		let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
	// 		let companiesInContacts = []

	// 		let refAppraisals = firestoreDB(dealersettings)
	// 			.firestore()
	// 			.collection('tradeinPro')
	// 			.where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
	// 			.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
	// 			.where('isDeleted', '==', false)
	// 		//if (!viewOtherAppraisal)
	// 		//	refAppraisals = refAppraisals.where('owner', '==', dealersettings.id)

	// 		refAppraisals = refAppraisals.orderBy('addedDate', 'desc')
	// 			.limit(7);

	// 		let refContactDetails = firestoreDB(dealersettings)
	// 			.firestore()
	// 			.collection('contactDetails')
	// 			.where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
	// 			.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
	// 			.where('isDeleted', '==', false)
	// 			.limit(7);

	// 		let refCompanies = firestoreDB(dealersettings)
	// 			.firestore()
	// 			.collection('companies')
	// 			.where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
	// 			.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
	// 			.where('isDeleted', '==', false)
	// 			.limit(10);


	// 		let refActivities = firestoreDB(dealersettings).firestore().collection('activities')
	// 			.where('type', '==', 'tradeinProactivity')
	// 			.where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
	// 			.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
	// 			.where('isDeleted', '==', false)
	// 			.orderBy('startDate', 'desc')
	// 			.limit(5);

	// 		let refInboundLeads = '';
	// 		if (inboundAccess) {
	// 			refInboundLeads = firestoreDB(dealersettings)
	// 				.firestore()
	// 				.collection('tradeinProInbound')
	// 				.where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
	// 				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
	// 				.where('status', '==', 0)
	// 				.where('isDeleted', '==', false)
	// 				.limit(10);
	// 		}

	// 		var dashboardPipelines = combineLatest(
	// 			collection(refAppraisals),
	// 			collection(refContactDetails),
	// 			collection(refCompanies),
	// 			collection(refActivities),
	// 			refInboundLeads ? collection(refInboundLeads) : of([])
	// 		)
	// 			.pipe(
	// 				map(([appraisals, contactDetails, companies, activities, inboundLeads]) => {
	// 					return [
	// 						_.map(appraisals, function (obj) {
	// 							return {
	// 								...obj.data(),
	// 								documentID: obj.id,
	// 								renderType: 'tradeinPro',
	// 								sortDate: obj.data().stageDate,
	// 								isInbound: false,
	// 							};
	// 						}),
	// 						_.map(contactDetails, function (obj) {
	// 							let contactObj = obj.data()
	// 							if (!_.isEmpty(contactObj.company) && contactObj.company.documentID) {
	// 								companiesInContacts.push({ ...contactObj.company, renderType: 'company', })
	// 							}
	// 							return {
	// 								...contactObj,
	// 								documentID: obj.id,
	// 								renderType: 'contactDetail',
	// 								sortDate: contactObj.addedDate,
	// 							};
	// 						}),
	// 						_.map(companies, function (obj) {
	// 							return {
	// 								...obj.data(),
	// 								documentID: obj.id,
	// 								renderType: 'company',
	// 								sortDate: obj.data().addedDate,
	// 							};
	// 						}),
	// 						_.map(activities, function (obj) {
	// 							return {
	// 								...obj.data(),
	// 								documentID: obj.id,
	// 								renderType: 'activity',
	// 								sortDate: obj.data().startDate,
	// 							};
	// 						}),
	// 						_.map(inboundLeads, function (obj) {
	// 							return {
	// 								...obj.data(),
	// 								documentID: obj.id,
	// 								renderType: 'tradeinPro',
	// 								sortDate: obj.data().addedDate,
	// 								isInbound: true,
	// 							};
	// 						}),
	// 					];
	// 				}),
	// 			)
	// 			.subscribe(allDocs => {
	// 				let filterActivities = allDocs[3];
	// 				let allAppraisals = [...allDocs[0], ...allDocs[4]]
	// 				let allCompanies = _.uniqBy([...allDocs[2], ...companiesInContacts], 'documentID')
	// 				setAppraisals(allAppraisals);
	// 				setContactDetails(allDocs[1]);
	// 				setCompanies(allCompanies);
	// 				setActivities(filterActivities);
	// 				setAll([
	// 					...allAppraisals,
	// 					...allDocs[1],
	// 					...allCompanies,
	// 					...filterActivities,
	// 				]);
	// 				setLoader(false);
	// 				dashboardPipelines && dashboardPipelines.unsubscribe();
	// 			});
	// 	}
	// 	return () => {
	// 		dashboardPipelines && dashboardPipelines.unsubscribe();
	// 	};
	// }, [searchText, dealersettings.client.documentID]);

	return (
		<div
			className='dropdown-menu dropdown-menu-left'
			aria-labelledby='dropdownMenuButton'
			style={{ display: showSearchPanel ? 'block' : '', left: '120px' }}
		>
			<div className='form-style globalsearch-wrap'>
				<div className='globalsearch-nav-wraper'>
					<div className='globalsearch-nav pl-0'>
						<Tab.Container defaultActiveKey={activeTab}>
							<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
								{tradeinProTabs.map((rec, index) => {
									return (
										<OverlayTrigger
											key={index}
											placement='bottom'
											overlay={
												<Tooltip><span>{rec.title}</span></Tooltip>
											}
										>
											<Nav.Item
												as='li'
												bsPrefix='li-nav'
												key={index}
											>
												<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
													<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, rec.ico, true, rec.icoVehicle)}></i>
													{rec.name}
												</Nav.Link>
											</Nav.Item>
										</OverlayTrigger>
									);
								})}
							</Nav>
						</Tab.Container>
					</div>
				</div>
				<div className='globalsearch-body'>
					{loader ? (
						<LoadingDiv />
					) : (
						<div className='global-item-wraper'>
							{activeTab === 'all' ? (
								<>
									{all.length > 0 ? (
										<>
											{all
												// _.orderBy(all, ['sortDate.seconds'], ['desc'])
												.map(obj =>
													obj.renderType === 'tradeinPro' ? (
														<>
															{obj.isInbound ? (
																<a
																	className='global-item d-flex'
																	onClick={(e) => {
																		e.preventDefault();
																		handleInBoundOpen(obj)
																		handleClose();
																	}}
																	key={obj.documentID}
																>
																	<div className='global-space'>
																		<div className='global-icon-circle'>
																			<i className='ico icon-enquiry'></i>
																		</div>
																	</div>
																	<div>
																		{(!_.isEmpty(obj.requirement) && (obj.requirement.make || obj.requirement.stock)) ? (
																			<>
																				{!_.isEmpty(obj.requirement.stock) && obj.requirement.stock.make ? (
																					<span className='global-header'>
																						{' '}
																						{`${obj.requirement
																							? obj.requirement.stock.make
																							: ''
																							} ${obj.requirement
																								? obj.requirement.stock.model
																								: ''
																							}`}{' '}
																					</span>
																				) : (<>
																					<span className='global-header'>
																						{' '}
																						{`${obj.requirement
																							? obj.requirement.make
																							: ''
																							} ${obj.requirement
																								? obj.requirement.model
																								: ''
																							}`}{' '}
																					</span>
																					{obj?.requirement?.regNo && (<span className='global-info'>
																						<Translate text={'regNo'} />: &nbsp;{`${obj.requirement.regNo} `}
																					</span>)}
																				</>
																				)}
																			</>
																		) : (
																			<></>
																		)}
																		<span className='global-info'>
																			{' '}
																			{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																			{obj.displayID ? ` - ${obj.displayID}` : ''}
																		</span>
																		<div className='global-status'>
																			<span
																				className={`status-pending`}
																			>
																				{' '}
																				Pending{' '}
																			</span>
																		</div>
																		{
																			obj.highlightTags && obj.highlightTags.map((rec, index) => {
																				return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																			})
																		}
																	</div>
																</a>
															) : (
																<a
																	className='global-item d-flex'
																	onClick={(e) => {
																		e.preventDefault();
																		history.push(`/`);
																		history.push(`tradeinpro/details/${obj.documentID}`);
																		handleClose();
																	}}
																	key={obj.documentID}
																>
																	<div className='global-space'>
																		<div className='global-icon-circle'>
																			<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
																		</div>
																	</div>
																	<div>
																		{(!_.isEmpty(obj.tradein) && obj.tradein.make) ? (
																			<span className='global-header'>
																				{' '}
																				{`${obj.tradein
																					? obj.tradein.make
																					: ''
																					} ${obj.tradein
																						? obj.tradein.model
																						: ''
																					}`}{' '}
																			</span>
																		) : (
																			<></>
																		)}
																		{(!_.isEmpty(obj.tradein) && obj.tradein.regNo) ? (
																			<span className='global-info'>
																				<Translate text={'regNo'} />:&nbsp;{`${obj.tradein ? obj.tradein.regNo : ''} `}
																			</span>
																		) : (
																			<></>
																		)}
																		<span className='global-info'>
																			{' '}
																			{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																			{obj.displayID ? ` - ${obj.displayID}` : ''}
																		</span>
																		{!_.isEmpty(obj) && obj.status && (
																			<div className='global-status'>
																				{(() => {
																					let _objCurrentStatus = obj.status && tradeinAllStatus.filter(e => e.value === obj.status)[0]

																					return <>
																						{
																							_objCurrentStatus
																								?
																								<>
																									<span
																										className={`status-${obj.status === 'New' ? 'open' : 'empty'} mr-2`}
																										style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																									>
																										{_objCurrentStatus.name}
																									</span>
																								</>
																								:
																								<></>
																						}
																					</>
																				})()}
																				{obj.status.toLowerCase() === 'pendinglost' ? (
																					<OverlayTrigger
																						placement='bottom'
																						overlay={
																							<Tooltip>Lost Pending Approval</Tooltip>
																						}
																					>
																						<span className="pipeline-pendinglost ml-1" style={{ float: 'none', paddingLeft: '0px' }}> <span className="status-pendinglost-circle">PA</span> </span>
																					</OverlayTrigger>) : (<></>)
																				}
																			</div>
																		)}
																	</div>
																</a>
															)}
														</>

													) : obj.renderType === 'contactDetail' ? (
														<a
															className='global-item d-flex'
															onClick={e => {
																e.preventDefault();
																history.push(`/`);
																history.push(
																	`contacts/details/${obj.documentID}`,
																);
																handleClose();
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-Contacts'></i>
																</div>
															</div>
															<div>
																{(obj.firstName || obj.lastName) && (<span className='global-header'>{`${CommonHelper.displayContactName([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
																)}
																{obj.phone ? (
																	<span className='global-info'>{CommonHelper.phoneFormat(obj.phoneCode, obj.phone, dealersettings)}</span>
																) : obj.email ? (
																	<span className='global-info'>{`${obj.email}`}</span>
																) : (
																	<></>
																)}
																{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
																	(<>
																		<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																	</>) : <></>}
															</div>
														</a>
													) : obj.renderType === 'company' ? (
														<a
															className='global-item d-flex'
															href='#'
															onClick={() => {
																history.push(`/`)
																history.push(`/company/details/${obj.documentID}`)
																handleClose();
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-company'></i>
																</div>
															</div>
															<div>
																{obj.name && (
																	<span className='global-header'>{obj.name}</span>
																)}
																{obj.address && (
																	<span className='global-info'>
																		{`${obj.address} ${obj.areaCode}`}
																	</span>
																)}
																{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
																	(<>
																		<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																	</>) : <></>}
															</div>
														</a>
													) : obj.renderType === 'activity' ? (
														<>
															{obj.type === 'tradeinProactivity' ? (
																<a
																	className='global-item d-flex'
																	href='#'
																	onClick={e => {
																		e.preventDefault();
																		handleOpenEditActivity(obj);
																		handleClose();
																	}}
																	key={obj.documentID}
																>
																	<div className='global-space'>
																		<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																			<i className='ico icon-date'></i>
																		</div>
																	</div>
																	<div>
																		{obj.title && (
																			<span className='global-header'>
																				{obj.title}
																			</span>
																		)}
																		{obj.contact && (CommonHelper.displayContactName([], obj.contact, '--'))}
																		{!isEmpty(obj.addedDate) && (
																			<div className='global-date'>
																				{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj.startDate ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																			</div>
																		)}
																	</div>
																</a>
															) : (
																<Link
																	className='global-item d-flex'
																	to={
																		obj.tradeinProID
																			? `tradeinpro/details/${obj.tradeinProID}?comments=${obj.documentID}`
																			: `contacts/details/${obj.contactID}?comments=${obj.documentID}`
																	}
																	key={obj.documentID}
																	onClick={handleClose}
																>
																	<div className='global-space'>
																		<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																			<i className='ico icon-date'></i>
																		</div>
																	</div>
																	<div>
																		{obj.title && (
																			<span className='global-header'>
																				{obj.title}
																			</span>
																		)}
																		{obj.contact && (CommonHelper.displayContactName([], obj.contact, '--'))}
																		{!isEmpty(obj.addedDate) && (
																			<div className='global-date'>
																				{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj.startDate ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																			</div>
																		)}
																	</div>
																</Link>
															)}
														</>
													) : (
														<></>
													),
												)}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'appraisal' ? (
								<>
									{appraisals.length > 0 ? (
										<>
											{appraisals.map(obj => (
												<a
													className='global-item d-flex'
													onClick={(e) => {
														e.preventDefault();
														history.push(`/`);
														history.push(`tradeinpro/details/${obj.documentID}`);
														handleClose();
													}}
													key={obj.documentID}
												>
													<div className='global-space'>
														<div className='global-icon-circle'>
															<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
														</div>
													</div>
													<div>
														{(!_.isEmpty(obj.tradein) && obj.tradein.make) ? (
															<span className='global-header'>
																{' '}
																{`${obj.tradein
																	? obj.tradein.make
																	: ''
																	} ${obj.tradein
																		? obj.tradein.model
																		: ''
																	}`}{' '}
															</span>
														) : (
															<></>
														)}
														{(!_.isEmpty(obj.tradein) && obj.tradein.regNo) ? (
															<span className='global-info'>
																<Translate text={'regNo'} />:&nbsp;{`${obj.tradein ? obj.tradein.regNo : ''} `}
															</span>
														) : (
															<></>
														)}
														<span className='global-info'>
															{' '}
															{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
															{obj.displayID ? ` - ${obj.displayID}` : ''}
														</span>
														{!_.isEmpty(obj) && obj.status && (
															<div className='global-status'>
																<>
																	{(() => {
																		let _objCurrentStatus = obj.status && tradeinAllStatus.filter(e => e.value === obj.status)[0]

																		return <>
																			{
																				_objCurrentStatus
																					?
																					<>
																						<span
																							className={`status-${obj.status === 'New' ? 'open' : 'empty'} mr-2`}
																							style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																						>
																							{_objCurrentStatus.name}
																						</span>
																					</>
																					:
																					<></>
																			}
																		</>
																	})()}
																</>
																{obj.status.toLowerCase() === 'pendinglost' ? (
																	<OverlayTrigger
																		placement='bottom'
																		overlay={
																			<Tooltip>Lost Pending Approval</Tooltip>
																		}
																	>
																		<span className="pipeline-pendinglost ml-1" style={{ float: 'none', paddingLeft: '0px' }}> <span className="status-pendinglost-circle">PA</span> </span>
																	</OverlayTrigger>) : (<></>)
																}
															</div>
														)}
													</div>
												</a>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'contacts' ? (
								<>
									{contactDetails.length > 0 ? (
										<>
											{contactDetails.map(obj => (
												<a
													className='global-item d-flex'
													onClick={(e) => {
														e.preventDefault();
														history.push(`/`);
														history.push(`contacts/details/${obj.documentID}`);
														handleClose();
													}}
													key={obj.documentID}
												>
													<div className='global-space'>
														<div className='global-icon-circle'>
															<i className='ico icon-Contacts'></i>
														</div>
													</div>
													<div>
														{(obj.firstName || obj.lastName) && (
															<span className='global-header'>{`${CommonHelper.displayContactName([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
														)}
														{obj.phone ? (
															<span className='global-info'>{CommonHelper.phoneFormat(obj.phoneCode, obj.phone, dealersettings)}</span>
														) : obj.email ? (
															<span className='global-info'>{obj.email}</span>
														) : (
															<></>
														)}
														{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
															(<>
																<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
															</>) : <></>}
													</div>
												</a>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'company' ? (
								<>
									{companies.length > 0 ? (
										<>
											{companies.map(obj => (
												<a
													className='global-item d-flex'
													href='#'
													onClick={() => {
														history.push(`/`)
														history.push(`/company/details/${obj.documentID}`)
														// handleCompanyView(obj.documentID);
														handleClose();
													}}
													key={obj.documentID}
												>
													<div className='global-space'>
														<div className='global-icon-circle'>
															<i className='ico icon-company'></i>
														</div>
													</div>
													<div>
														{obj.name && (
															<span className='global-header'>{obj.name}</span>
														)}
														{obj.address && (
															<span className='global-info'>
																{`${obj.address} ${obj.areaCode}`}
															</span>
														)}
														{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
															(<>
																<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
															</>) : <></>}
													</div>
												</a>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'activity' ? (
								<>
									{activities.length > 0 ? (
										<>
											{_.orderBy(
												activities,
												['sortDate.seconds'],
												['desc'],
											).map(obj => (
												<>
													{obj.type === 'tradeinProactivity' ? (
														<a
															className='global-item d-flex'
															href='#'
															onClick={e => {
																e.preventDefault();
																handleOpenEditActivity(obj);
																handleClose();
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																	<i className='ico icon-date'></i>
																</div>
															</div>
															<div>
																{obj.title && (
																	<span className='global-header'>{obj.title}</span>
																)}
																{!_.isEmpty(obj.contact) && (<span className='global-info'>{`${CommonHelper.displayContactName([], obj.contact, '--')}`}</span>
																)}
																{!isEmpty(obj.addedDate) && (
																	<div className='global-date'>
																		{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj.startDate ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																	</div>
																)}
															</div>
														</a>
													) : (
														<Link
															className='global-item d-flex'
															to={
																obj.tradeinProID
																	? `tradeinpro/details/${obj.tradeinProID}?comments=${obj.documentID}`
																	: `contacts/details/${obj.contactID}?comments=${obj.documentID}`
															}
															onClick={handleClose}
														>
															<div className='global-space'>
																<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																	<i className='ico icon-date'></i>
																</div>
															</div>
															<div>
																{obj.title && (
																	<span className='global-header'>{obj.title}</span>
																)}
																{!_.isEmpty(obj.contact) && (<span className='global-info'>{`${CommonHelper.displayContactName([], obj.contact, '--')}`}</span>)}
																{!isEmpty(obj.addedDate) && (
																	<div className='global-date'>
																		{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj.startDate ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																	</div>
																)}
															</div>
														</Link>
													)}
												</>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : (
								<></>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TradeinProSearch;
