import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import MentionEditor from '../../../../components/mentionEditor/mentionEditor';
import { PopUpModal } from '../../../../components'
import _ from 'lodash';
import { mentions } from './viewmodel';
import TemplateEditor from './templateEditor';
import AddTemplateHTML from './addTemplateHTML';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import Swal from 'sweetalert2'


const Templates = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 371) })
    const [showEdiorModel, setShowEdiorModel] = useState({ show: false })
    const [addHtml, setAddHtml] = useState({ show: false })
    const [state, setState] = useState(props.data)

    const convertTemplateJSON = (templateJSON) => {
        // const _templateJSON = JSON.stringify(obj.rawData)
        // const textMessage = obj.value.replace(/<[^>]*>?/gm, '')
        let data = JSON.parse(templateJSON);
        let text = '';
        if (!_.isEmpty(data.blocks)) {
            data.blocks.forEach(ele => {
                text += ele.text + ' ';
            });
        }
        if (text) {
            mentions.filter(a => a.dynamic === true).forEach(rec => {
                if (text.includes(`${rec.name}`)) {
                    text = text.replace(new RegExp(`${rec.name}`, 'g'), rec.id);
                }
            })
        }
        return text
    }


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 371)
            })

        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const onMentionChange = notesText => {
        setState({
            ...state,
            templateJSON: '',
            smsTemplate: notesText ? notesText : '',
        });
    };

    const onMentionSelect = (option, ownerID) => {
        console.log('select', option);
    };


    return (<>
        <div className="settings-tab-wrap tabs">
            <div className="tabs-wraper">
                <nav>
                    <Tabs defaultActiveKey={"email"} transition={false} className="nav-fill">
                        <Tab eventKey={"email"} title={CommonHelper.showLocale(props, "Email Template")}>
                            {
                                props.data?.emailTemplate ? (<>
                                    <div className="gdpr-template-edit mt-2">
                                        <div className="float-left">
                                            <h5 className="settings-subhead"><Translate text={'Subject'} /> : {props.data.subject}</h5>
                                        </div>
                                        <div className="float-right">
                                            <button type="button" className="btn btn-red float-right ml-2" onClick={(e) => {
                                                e.preventDefault();
                                                Swal.fire({
                                                    title: CommonHelper.showLocale(props, 'Are you sure?'),
                                                    text: CommonHelper.showLocale(props, 'Do you want to delete'),
                                                    icon: 'info',
                                                    showCancelButton: true,
                                                    confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                                                    cancelButtonText: CommonHelper.showLocale(props, 'No'),
                                                }).then(async (result) => {
                                                    if (result.value) {
                                                        props.saveTemplate({
                                                            emailTemplate: null,
                                                            templateJSON: null
                                                        })
                                                    }
                                                })
                                            }}><i className="ico icon-delete mr-1" style={{ fontSize: '11px' }}></i> <Translate text={'delete'} /></button>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                if (props.data.templateJSON)
                                                    setShowEdiorModel({ show: true, title: 'Edit Template', template: props.data })
                                                else
                                                    setAddHtml({ show: true, title: 'Edit Template', template: props.data })

                                            }}
                                                type="button" className="btn btn-primary float-right ml-2"><i className="ico icon-edit mr-1" style={{ fontSize: '11px' }}></i> <Translate text={'edit'} /></button>
                                        </div>
                                    </div>
                                    <div className="gdpr-btn-box gdpr-flex-preview" style={{ height: `${windowSize.windowHeight}px` }}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: props.data.emailTemplate }}>
                                        </div>
                                    </div>

                                </>
                                ) : (
                                    <div className="gdpr-btn-box gdpr-flex" style={{ height: `${windowSize.windowHeight}px` }}>
                                        <div className="gdpr-btn-info"><Translate text={'Design the content for your email.'} /></div>
                                        <div className="gdpr-btn-wrapper">
                                            <button type="button" className="btn btn-primary float-left" onClick={(e) => {
                                                e.preventDefault();
                                                setAddHtml({
                                                    show: true,
                                                    title: 'Add Template',
                                                    template: null
                                                })
                                            }}> <i className="ico icon-add mr-2"></i> <Translate text={'Add Email HTML Template'} /> </button>
                                            <span><Translate text={'or'} upperCase={true} /></span>
                                            <button type="button" className="btn btn-secondary float-left ml-2" onClick={(e) => {
                                                e.preventDefault();
                                                setShowEdiorModel({
                                                    show: true,
                                                    title: 'Add Template',
                                                    template: null
                                                })
                                            }}> <i className="ico icon-add mr-2"></i> <Translate text={'Custom Template Builder'} /> </button>
                                        </div>
                                    </div>
                                )
                            }
                        </Tab>
                        <Tab eventKey={"sms"} title={CommonHelper.showLocale(props, "SMS Template")}>
                            <div className='w-100 d-inline-block mb-2'>
                                <div className="float-right">
                                    <button type="button" className="btn btn-primary float-right" onClick={() => props.saveTemplate(state)}>
                                        {
                                            props.loader ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        <Translate text={'save'} />
                                    </button>
                                </div>
                            </div>
                            <div className="form-group">
                                <MentionEditor
                                    mentionsData={mentions.map(mention => {
                                        return {
                                            name: `${mention.value}`,
                                            id: mention.value,
                                            title: `${mention.name}`,
                                        };
                                    })}
                                    onMentionChange={onMentionChange}
                                    onMentionSelect={onMentionSelect}
                                    notes={state.smsTemplate && _.isEmpty(state.templateJSON) ? state.smsTemplate : !_.isEmpty(state.templateJSON) ? convertTemplateJSON(state.templateJSON) : ''}
                                    focusOnMount={false}
                                    isNoteEmpty={false}
                                    teamMentions={null}
                                    clearTeamMentions={() => { }}
                                    clientUsers={[]}
                                    ownerID={''}
                                    showCustomSuggestions={true}
                                    height={`${windowSize.windowHeight}px`}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </nav>
            </div>
        </div >
        <PopUpModal show={showEdiorModel.show}>
            <TemplateEditor
                {...props}
                {...showEdiorModel}
                handleClose={(obj) => {
                    if (obj) {
                        props.saveTemplate(obj)
                    }
                    setShowEdiorModel({ show: false, title: '', template: null })
                }}
                formMentions={mentions}
            />
        </PopUpModal>
        <PopUpModal show={addHtml.show}>
            <AddTemplateHTML
                {...props}
                {...addHtml}
                handleClose={(email, subject) => {
                    if (email) {
                        props.saveTemplate({
                            emailTemplate: email ? email : null,
                            subject: subject ? subject : null,
                        })
                    }
                    setAddHtml({ show: false, title: '', template: null })
                }}
                formMentions={mentions}
            />
        </PopUpModal>
    </>
    );
}

export default Templates;