import React from 'react';

export const objEnquiry = {
    documentID: null,
    displayID: null,
    clientID: null,
    projectId: null,
    settingsID: null,
    //  keywords: [],
    pipeline: '',
    stage: '',
    status: '',
    oemStatus: '',
    enquiryType: '',
    serviceType: '',
    leadSource: '',
    origin: '',
    campaign: '',
    label: '',
    nextActivity: {},
    purchaseIntention: '',
    depositAmount: 0,
    depositReciptNumber: '',
    grossAmount: 0,
    contractNumber: '',
    contact: {},
    contactID: null,
    requirement: {},
    isFav: false,
    isContact: false,
    isVehicle: false,
    isTestDrive: false,
    isTradeIn: false,
    isCafe: false,
    isFiles: false,
    isNotes: false,
    isFinance: false,
    isQuotation: false,
    isVideo: false,
    isProduct: false,
    stageDate: null,
    stageHistory: [],
    statusHistory: [],
    lostReason: '',
    lostSubReason: '',
    lostNotes: '',
    responseTime: null,
    responseTimeDisplay: null,
    isFinanceRequestSent: false,
    isFinanceRequired: false,
    isConverted: false,
    convertedBy: '',
    convertedDate: null,
    financeStatus: '',
    afterMarketStatus: '',
    chassisNo: '',
    regNo: '',
    stockNo: '',
    warrantyStartDate: null,
    warrantyExpiry: null,
    deliveryDate: null,
    wonDate: null,
    lostDate: null,
    deliveredDate: null,
    options: [],
    walkins: [],
    owner: '',
    ownerInbound: '',
    financeBM: '',
    financeProvider: '',
    financeStartDate: null,
    financeTerms: 0,
    financeAmount: 0,
    financeRate: 0,
    afterMarketBM: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    counts: {},
    isDeleted: false,
    isNewEnquiry: false,
    isAfterSales: false,
    engageContactName: null,
    confirmedBy: '',
    confirmedDate: null,
    lastStatus: '',
    verifiedBy: '',
    verifiedDate: null,
    receivedDate: null,
    dynamicFields: {},
    tags: [],
    // isConvertedGroup: false,
    // convertedByGroup: '',
    // convertedDateGroup: null,
    // convertedGroupID: null,
    // isConvertedOEM: false,
    // convertedByOEM: '',
    // convertedDateOEM: null,
    // convertedOEMID: null,
    // isConvertedRegion: false,
    // convertedByRegion: '',
    // convertedDateRegion: null,
    // convertedRegionID: null,
    oemConvertedBy: null,
    oemConvertedDate: null,
    lostRefID: null,
    linkID: null,
    linkEnquiryID: null,
    transferHistory: [],
    bdcStatus: null,
    requirementCount: 0,
    soldCount: 0,
    quotation: {},
    td: {},
    unactionedNotify: ''
};

export const objWalkIn = {
    documentID: null,
    clientID: null,
    projectId: null,
    enquiryID: null,
    walkinDate: null,
    addedBy: "",
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null
}

// export const mandatoryFields = [
//     'pipeline',
//     'stage',
//     'status',
//     'enquiryType',
//     'origin'
// ];

// export const objRequirement = {
//     documentID: null,
//     make: '',
//     model: '',
//     saleType: '',
//     type: '',
//     year: '',
//     stock: {},
//     extColor: '',
//     intColor: '',
//     price: null,
//     quantity: 1,
//     isSold: false,
//     reqOtherInfo: [],
//     addedBy: '',
//     addedDate: null,
//     modifiedBy: '',
//     modifiedDate: null,
//     isDeleted: false
// };
export const objModules = {
    amsVideo: false,
    cafe: false,
    contact: false,
    files: true,
    finance: false,
    requirement: false,
    testDrive: false,
    tradeIn: false
};

export const objStageEnquiry = {
    addedby: '',
    email: '',
    id: '',
    isFav: false,
    modifiedby: '',
    name: '',
    origin: '',
    phone: '',
    pipeline: '',
    saletype: '',
    stage: '',
    status: '',
    oemStatus: '',
    vehiclemodel: '',
    addedDate: null,
    modifiedFrom: 'web',
    modifiedDate: null,
    label: ''
};

export const objActivitylog = {
    documentID: null,
    addedBy: "",
    addedDate: null,
    clientID: null,
    projectId: null,
    contactID: null,
    enquiryID: null,
    stockID: null,
    startDate: null,
    endDate: null,
    isDone: false,
    isDeleted: false,
    modifiedBy: "",
    modifiedFrom: "web",
    modifiedDate: null,
    notes: "",
    subType: "",
    type: "",
    title: '',
    teammates: [],
    teams: [],
    searchUsers: [],
    callDuration: 0,
    callStatus: '',
    owner: '',
    addedByName: '',
    callType: '',
    inbound: null,
    activityOutcome: '',
    outlookEventID: '',
    googleEventID: '',
    reminder: null,
    tradeinProID: null,
}

export const objChangeLog = {
    documentID: null,
    clientID: null,
    projectId: null,
    // contactID: null,
    // enquiryID: null,
    recordId: null,
    notes: "",
    type: "",
    subType: "",
    logBy: "",
    logDate: null
}

export const customerlogTypes = [
    {
        name: "Notes",
        value: "note",
        ico: "ico icon-note",
        activeTab: true,
        emptymessage: "No notes yet! let's create one"
    },
    {
        name: "Messages",
        value: "message",
        ico: "ico icon-message",
        activeTab: false,
        emptymessage: "No message yet! let's create one"
    },
    {
        name: "Calls",
        value: "call",
        ico: "ico icon-call-log",
        activeTab: false,
        emptymessage: "No calls yet! let's create one"
    },
    {
        name: "Emails",
        value: "email",
        ico: "ico icon-email-log",
        activeTab: false,
        emptymessage: "No email found! let's compose one"
    }
]


export const activitylogTypes = [
    {
        ico: "ico icon-activity-appointment",
        name: "Appointment",
        type: "appointment",
        value: "appointment"
    },
    {
        ico: "ico icon-activity-testdrive",
        icoVehicle: true,
        name: "Test Drive",
        type: "testdrive",
        value: "testdrive"
    },
    {
        ico: "ico icon-call",
        name: "Call",
        type: "call",
        value: "call"
    },
    {
        ico: "ico icon-Mail",
        name: "Mail",
        type: "email",
        value: "email"
    },
    {
        ico: "ico icon-activity-delivery",
        icoVehicle: true,
        name: "Delivery",
        type: "delivery",
        value: "delivery"
    },
    {
        ico: "ico icon-activity",
        name: "Task",
        type: "task",
        value: "task"
    }
]

export const activityLogOptions = [
    {
        label: (<><i className="ico icon-activity-appointment mr-1"></i>Appointment</>),
        icon: 'activity-appointment',
        name: 'Appointment',
        value: "appointment",
        active: true,
    },
    {
        label: (<><i className="ico icon-activity-testdrive mr-1"></i>Test Drive</>),
        icon: 'activity-testdrive',
        icoVehicle: true,
        name: "Test Drive",
        value: "testdrive",
        active: true,
    },
    {
        label: (<><i className="ico icon-call mr-1"></i>Call</>),
        icon: "call",
        name: "Call",
        value: "call",
        active: true,
    },
    {
        label: (<><i className="ico icon-Mail mr-1"></i>Mail</>),
        icon: "Mail",
        name: "Mail",
        value: "email",
        active: true,
    },
    {
        label: (<><i className="ico icon-activity-delivery mr-1"></i>Delivery</>),
        icon: "activity-delivery",
        icoVehicle: true,
        name: "Delivery",
        value: "delivery",
        active: true,
    },
    {
        label: (<><i className="ico icon-activity mr-1"></i>Task</>),
        icon: "activity",
        name: "Task",
        value: "task",
        active: true,
    }
]


export const enquiryActivities = [
    {
        name: "Add Notes",
        value: "note",
        ico: "ico icon-note",
        emptymessage: "Click here to add notes..."
    },
    {
        name: "Schedule Activity",
        value: "activity",
        ico: "ico icon-date",
        emptymessage: "Click here to add activity..."
    },
    {
        name: "Log Message",
        value: "message",
        ico: "ico icon-message",
        emptymessage: "Click here to add message..."
    },
    {
        name: "Log Call",
        value: "call",
        ico: "ico icon-call-log",
        emptymessage: "Click here to add call log..."
    },
    {
        name: "Send Email",
        value: "email",
        ico: "ico icon-email-log",
        emptymessage: "Click here to add email log..."
    }
]

export const enquirylogscompleted = [
    {
        name: "All",
        value: "all",
        type: "all",
        subType: "all",
    },
    {
        name: "Notes",
        value: "allnotes",
        type: "allnotes",
        subType: "allnotes",
    },
    {
        name: "Activities",
        type: "activity",
        value: "activity",
        subType: "",
    },
    {
        name: "Notes",
        type: "log",
        subType: "note",
        value: "note"
    },
    {
        name: "Messages",
        type: "log",
        subType: "message",
        value: "message"
    },
    {
        name: "Calls",
        type: "log",
        subType: "call",
        value: "call"
    },
    {
        name: "Emails",
        type: "log",
        subType: "email",
        value: "email"
    },
    {
        name: "EDMs",
        type: "log",
        subType: "edms",
        value: "edms"
    },
    {
        name: "GDPR",
        type: "log",
        subType: "gdpr",
        value: "gdpr"
    },
    {
        name: "Change Logs",
        type: "log",
        subType: "log",
        value: "log"
    }
]


export const fieldsCustTestDrive = [
    'licenseExpiry',
    'licenseNo',
    'PhoneOrEmailReq',
    'licenseBackURL',
    'licenseFrontURL'
];


export const enquiryBasicDetailsVM = {
    documentID: null,
    displayID: null,
    pipeline: '',
    stage: '',
    status: '',
    enquiryType: '',
    serviceType: '',
    leadSource: '',
    origin: '',
    campaign: '',
    label: '',
    purchaseIntention: '',
    requirement: {},
    contact: {},
    contactID: null,
    stageDate: null,
    wonDate: null,
    deliveryDate: null,
    deliveredDate: null,
    addedDate: null,
    modifiedDate: null,
    owner: '',
    ownerInbound: '',
    financeBM: '',
    afterMarketBM: '',
    isAfterSales: false,
    regNo: null
};

export const objEnquiryListVM = {
    documentID: null,
    displayID: null,
    clientID: null,
    projectId: null,
    settingsID: null,
    refID: null,
    enquiryID: null,
    pipeline: '',
    stage: '',
    status: '',
    oemStatus: '',
    enquiryType: '',
    serviceType: '',
    leadSource: '',
    origin: '',
    campaign: '',
    label: '',
    isAfterSales: false,
    purchaseIntention: '',
    depositAmount: 0,
    depositReciptNumber: '',
    grossAmount: 0,
    contractNumber: '',
    stageDate: null,
    owner: '',
    ownerInbound: '',
    financeBM: '',
    financeProvider: '',
    financeStartDate: null,
    financeTerms: 0,
    financeAmount: 0,
    financeRate: 0,
    afterMarketBM: '',
    createdOn: '',
    updatedOn: '',
    addedby: '',
    modifiedby: '',
    modifiedFrom: 'web',
    contactName: '',
    dob: null,
    gender: '',
    maritalStatus: '',
    language: '',
    nationality: '',
    address: '',
    phone: '',
    email: '',
    companyName: '',
    designation: '',
    contactMethod: '',
    licenseNo: '',
    licenseExpiry: null,
    licenseType: '',
    licenseState: '',
    lostNotes: '',
    interests: [],
    campaign: '',
    optinPhone: true,
    optinEmail: true,
    optinPost: true,
    optinSMS: true,
    marketingOptinEmail: true,
    marketingOptinSMS: true,
    dependents: '',
    driverName: '',
    secondaryName: '',
    secondaryPhone: '',
    secondaryEmail: '',
    vehicleModel: '',
    vehicleSaletype: '',
    vehicleYear: '',
    vehicleBodytype: '',
    overdueActivity: 0,
    todayActivity: 0,
    upcomingActivity: 0,
    doneActivity: 0,
    totalActivity: 0,
    enquiryAge: 0,
    rottenDays: 0,
    isFav: false,
    requirementCount: 0,
    soldCount: 0,
};


export const allenquiryFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'Favourite',
        value: 'documentID',
        subText: 'isFav',
        width: 80,
        default: true,
        type: 'favorite',
        flex: 0,
        align: 'center'
    },
    {
        name: 'Enquiry Number',
        value: 'displayID',
        flex: 1,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
        sortValue: 'displayID'
    },
    {
        name: 'Name',
        value: 'contactName',
        flex: 2,
        default: true,
        subText: 'contactNumber',
        subTextTitle: 'Contact ID',
        sortValue: 'contact."firstName"'
        // nestedText: 'dealerName',
        // nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
        sortValue: 'contact."email"'
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true,
        elementName: 'csvPhone',
        sortValue: 'contact."phone"'
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        subText: 'vehicleType',
        subTextTitle: 'Sale Type',
        elementsubName: 'vehicleSaletype',
        sortValue: 'vehicleModel'

    },
    {
        name: 'Vehicle Body Type',
        value: 'vehicleBodytype',
        flex: 1,
    },
    {
        name: 'Vehicle Year',
        value: 'vehicleYear',
        flex: 1,
    },
    {
        name: 'Origin',
        value: 'origin',
        flex: 1,
        default: true,
        sortValue: 'origin.name'
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        default: true,
        elementName: 'statusName',
        sortValue: 'status.name',
        subText: 'statusDate',
        subTextTitle: 'Current Status Date',
    },
    {
        name: 'Owner',
        value: 'owner',
        flex: 1,
        default: true,
        sortValue: 'owner.name'
    },
    {
        name: 'Inbound Owner',
        value: 'ownerInbound',
        flex: 1,
        sortValue: 'owner.name'
    },
    {
        name: 'Stage Date',
        value: 'stageDate',
        flex: 1,
        default: true,
        sortValue: 'stageDate'
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        subText: 'addedBy',
        subTextTitle: 'Created By',
        sortValue: 'addedDate'
    },
    {
        name: 'Last Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By',
        sortValue: 'modifiedDate'
    },
    {
        name: 'Finance Business Manager',
        value: 'financeBM',
        flex: 1,
        sortValue: 'financeBM.name'
    },
    {
        name: 'After Market Manager',
        value: 'afterMarketBM',
        flex: 1,
        sortValue: 'afterMarketBM.name'
    },
    {
        name: 'Unattended Leads',
        value: 'rottenDays',
        flex: 1,
    },
    {
        name: 'Requirement Count',
        value: 'requirementCount',
        flex: 1,
        sortValue: 'requirementCount'
    },
    {
        name: 'Won Count',
        value: 'soldCount',
        flex: 1,
        sortValue: 'soldCount'
    },
    {
        name: 'Enquiry Age',
        value: 'enquiryAge',
        flex: 1,
    },
    {
        name: 'Won Date',
        value: 'wonOn',
        flex: 1,
        sortValue: 'wonDate'
    },
    {
        name: 'Lost Date',
        value: 'lostOn',
        flex: 1,
        sortValue: 'lostDate'
    },
    {
        name: 'Delivered Date',
        value: 'deliveredOn',
        flex: 1,
        sortValue: 'deliveredDate'
    },
    {
        name: 'Delivery Date',
        value: 'deliveryOn',
        flex: 1,
        sortValue: 'deliveryDate'
    },
    // {
    //     name: 'Total Activities',
    //     value: 'totalActivity',
    //     flex: 1,
    // },
    // {
    //     name: 'Done Activities',
    //     value: 'doneActivity',
    //     flex: 1,
    // },
    // {
    //     name: 'upcoming Activities',
    //     value: 'upcomingActivity',
    //     flex: 1,
    // },
    // {
    //     name: 'Today Activities',
    //     value: 'todayActivity',
    //     flex: 1,
    // },
    // {
    //     name: 'Overdue Activities',
    //     value: 'overdueActivity',
    //     flex: 1,
    // },
    {
        name: 'Enquiry Type',
        value: 'enquiryType',
        flex: 1,
        sortValue: 'enquiryType.name'
    },
    {
        name: 'Campaign',
        value: 'campaign',
        flex: 1,
        sortValue: 'campaign.name'
    },
    {
        name: 'Label',
        value: 'label',
        flex: 1,
        elementName: 'labelName',
        sortValue: 'label.name'
    },
    {
        name: 'Pipeline',
        value: 'pipeline',
        flex: 1,
        sortValue: 'pipeline.name'
    },
    {
        name: 'Stage',
        value: 'stage',
        flex: 1,
        sortValue: 'stage.name'
    },
    {
        name: 'Lost Reason',
        value: 'lostReason',
        flex: 1,
        sortValue: 'lostReason.name'
    },
    {
        name: 'Lost Notes',
        value: 'lostNotes',
        flex: 1,
    },
    {
        name: 'Lost Sub Reason',
        value: 'lostSubReason',
        flex: 1,
        sortValue: 'lostSubReason.name'
    },
    {
        name: 'Purchase Intention',
        value: 'purchaseIntention',
        flex: 1
    },
    {
        name: 'Gross Amount',
        value: 'grossAmount',
        flex: 1,
    },
    {
        name: 'Deposit Amount',
        value: 'depositAmount',
        flex: 1,
    },
    {
        name: 'Deposit Recipt Number',
        value: 'depositReciptNumber',
        flex: 1,
    },
    {
        name: 'Lead Source',
        value: 'leadSource',
        flex: 1,
        sortValue: 'leadSource.name'
    },
    {
        name: 'Contract Number',
        value: 'contractNumber',
        flex: 1,
    },
    {
        name: 'Verified',
        value: 'verifiedDate',
        flex: 1,
        subText: 'verifiedBy',
        subTextTitle: 'Verified By'
    },
    {
        name: 'Received Date',
        value: 'receivedDate',
        flex: 1
    },
    {
        name: 'Quote Amount',
        value: 'quoteAmount',
        flex: 1,
        elementName: 'csvQuoteAmount'
    },
    {
        name: 'Confirmed',
        value: 'confirmedDate',
        flex: 1,
        subText: 'confirmedBy',
        subTextTitle: 'Confirmed By'
    },
    {
        name: 'VIN #',
        value: 'chassisNo',
        flex: 1,
    },
    {
        name: 'Reg #',
        value: 'regNo',
        flex: 1,
    },
    {
        name: 'Stock #',
        value: 'stockNo',
        flex: 1,
    },
    {
        name: 'Warranty Start Date',
        value: 'warrantyStartDate',
        flex: 1
    },
    {
        name: 'Warranty Expiry',
        value: 'warrantyExpiry',
        flex: 1
    },
    {
        name: 'Converted Date',
        value: 'convertedOn',
        flex: 1,
        subText: 'convertedOwner',
        subTextTitle: 'Converted By',
        sortValue: 'convertedDate'
    },
    {
        name: 'OEM Converted Date',
        value: 'convertedOnOEM',
        flex: 1,
        subText: 'convertedOwnerOEM',
        subTextTitle: 'OEM Converted By'
    },
    {
        name: 'Region Converted Date',
        value: 'convertedOnRegion',
        flex: 1,
        subText: 'convertedOwnerRegion',
        subTextTitle: 'Region Converted By'
    },
    {
        name: 'Group Converted Date',
        value: 'convertedOnGroup',
        flex: 1,
        subText: 'convertedOwnerGroup',
        subTextTitle: 'Group Converted By'
    },
    {
        name: 'BDC Status',
        value: 'bdcStatus',
        flex: 1,
        elementName: 'bdcStatusName'
    },
    {
        name: 'Appointment Date',
        value: 'appointmentDateOn',
        flex: 1
    },
    {
        name: 'Test Drive Model',
        value: 'testdriveModel',
        flex: 1,
        subText: 'testdriveSaletype',
        subTextTitle: 'Test Drive Sale Type'
    },
    {
        name: 'Lead Response (hh:mm:ss)',
        value: 'strResponseTime',
        flex: 2,
        sortValue: 'responseTime'
    },
    {
        name: 'Allocated Time(hh:mm:ss)',
        value: 'strAllocatedTime',
        flex: 2,
        sortValue: 'allocatedTime'
    },
    {
        name: 'Contacted Time(hh:mm:ss)',
        value: 'strContactedTime',
        flex: 2,
        sortValue: 'contactedTime'
    },
    {
        name: 'Inbound Lead Source',
        value: 'domleadSource',
        flex: 1,
        elementName: 'strleadSource'
    },
    {
        name: 'Finance Status',
        value: 'financeStatus',
        flex: 1,
        sortValue: 'financeStatus.name'
    },
    {
        name: 'After Market Status',
        value: 'afterMarketStatus',
        flex: 1,
        sortValue: 'afterMarketStatus.name'
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        flex: 1,
        sortValue: 'contact."licenseExpiry"'
    },
    {
        name: 'License State',
        value: 'licenseState',
        flex: 1
    },
    {
        name: 'License Type',
        value: 'licenseType',
        flex: 1
    },
    {
        name: 'Address',
        value: 'address',
        flex: 2,
        sortValue: 'contact."address"'
    },
    {
        name: 'State',
        value: 'state',
        flex: 1,
        sortValue: 'contact."state"'
    },
    {
        name: 'Country',
        value: 'country',
        flex: 1,
        sortValue: 'contact."country"'
    },
    {
        name: 'Area Code',
        value: 'areaCode',
        flex: 1,
        sortValue: 'contact."areaCode"'
    },
    {
        name: 'Company',
        value: 'companyName',
        flex: 1
    },
    {
        name: 'Designation',
        value: 'designation',
        flex: 1
    },
    {
        name: 'Birthday',
        value: 'dob',
        flex: 1,
        sortValue: 'contact."dob"'
    },
    {
        name: 'Gender',
        value: 'gender',
        flex: 1,
        sortValue: 'contact."gender"'
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        flex: 1
    },
    {
        name: 'Nationality',
        value: 'nationality',
        flex: 1,
        sortValue: 'contact."nationality"'
    },
    {
        name: 'Language',
        value: 'language',
        flex: 1
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        flex: 1,
        sortValue: 'contact."contactType"'
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        flex: 1
    },
    {
        name: 'Hobbies',
        value: 'interests',
        flex: 1
    },
    {
        name: 'Dependents',
        value: 'dependents',
        flex: 1
    },
    {
        name: 'Driver Name',
        value: 'driverName',
        flex: 1
    },
    {
        name: 'Contact Campaign',
        value: 'contactcampaign',
        flex: 1
    },
    {
        name: 'Contact Origin',
        value: 'contactorigin',
        flex: 1
    },
    {
        name: 'Phone Opt In',
        value: 'optinPhone',
        flex: 1,
        sortValue: 'contact."optinPhone"'
    },
    {
        name: 'SMS Opt In',
        value: 'optinSMS',
        flex: 1,
        sortValue: 'contact."optinSMS"'
    },
    {
        name: 'Email Opt In',
        value: 'optinEmail',
        flex: 1,
        sortValue: 'contact."optinEmail"'
    },
    {
        name: 'Post Opt In',
        value: 'optinPost',
        flex: 1,
        sortValue: 'contact."optinPost"'
    },
    {
        name: 'Marketing Email Opt In',
        value: 'marketingOptinEmail',
        flex: 1,
        sortValue: 'contact."marketingOptinEmail"'
    },
    {
        name: 'Marketing SMS Opt In',
        value: 'marketingOptinSMS',
        flex: 1,
        sortValue: 'contact."marketingOptinSMS"'
    },
    {
        name: 'Secondary Name',
        value: 'secondaryName',
        flex: 1
    },
    {
        name: 'Secondary Phone',
        value: 'secondaryPhone',
        flex: 1
    },
    {
        name: 'Secondary Email',
        value: 'secondaryEmail',
        flex: 1
    },
    {
        name: 'Last Activity/Log Notes',
        value: 'lastNotes',
        flex: 1,
        limit: 190
    },
    {
        name: 'Last Activity type',
        value: 'lastActivityType',
        flex: 1,
    },
    {
        name: 'Last Activity Actioned On',
        value: 'actActionOn',
        flex: 1,
        subText: 'actActionBy',
        subTextTitle: 'Last Activity Actioned By'
    },
    {
        name: 'Next Activity',
        value: 'nextActivityOn',
        flex: 1,
        subText: 'nextActivityBy',
        subTextTitle: 'Next Activity Owner',
        elementName: 'nextActivityStr'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]


export const allEnquiryStockHistoryFields = [
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        group: 'Contact',
        default: true,
        subText: 'displayID',
        subTextTitle: 'Contact ID',
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Contact Email',
        value: 'contactEmail',
        group: 'Contact',
        flex: 1,
        default: true
    },
    {
        name: 'Contact Phone',
        value: 'contactPhone',
        group: 'Contact',
        flex: 1,
        default: true,
        elementName: 'csvPhone'
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        group: 'Enquiry',
        default: true,
        elementName: 'statusName'
    },
    {
        name: 'Created on',
        value: 'createdOn',
        flex: 1,
        group: 'Enquiry',
        subText: 'addedBy',
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        group: 'Enquiry',
        subText: 'modifiedBy',
        default: true
    },
    {
        name: 'Pipeline',
        value: 'pipeline',
        flex: 1,
        group: 'Enquiry',
        default: true

    },
    {
        name: 'Stage',
        value: 'stage',
        flex: 1,
        group: 'Enquiry',
        default: true

    },
    {
        name: 'Enquiry Type',
        value: 'enquiryType',
        flex: 1,
        group: 'Enquiry',
        default: true

    },
    {
        name: 'Lead Source',
        value: 'leadSource',
        flex: 1,
        group: 'Enquiry',
        default: true

    },
    {
        name: 'Label',
        value: 'label',
        flex: 1,
        group: 'Enquiry',
        default: true,
        elementName: 'labelName'
    },
    {
        name: 'Digital Driving License',
        value: 'isDLScan',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License No',
        value: 'licenseNo',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License Type',
        value: 'licenseType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License State',
        value: 'licenseState',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Birthday',
        value: 'dob',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Gender',
        value: 'gender',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Language',
        value: 'language',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Nationality',
        value: 'nationality',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Address',
        value: 'address',
        group: 'Contact',
        flex: 2,
        default: false
    },
    {
        name: 'Company Name',
        value: 'companyName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Designation',
        value: 'designation',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Name',
        value: 'secondaryContactName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Phone',
        value: 'secondaryContactPhone',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Email',
        value: 'secondaryContactEmail',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Created On',
        value: 'contactCreatedOn',
        subText: 'contactCreatedBy',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Updated On',
        value: 'contactModifiedOn',
        subText: 'contactModifiedby',
        group: 'Contact',
        flex: 1,
        default: false
    },

    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]


export const allBookingStockHistoryFields = [
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 2,
        group: 'Contact',
        default: true,
        subText: 'displayID',
        subTextTitle: 'Contact ID',
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Booking Date',
        value: 'dueDate',
        subText: 'dueTime',
        flex: 1,
        default: true
    },
    {
        name: 'Notes',
        value: 'notes',
        flex: 1,
        group: 'Booking',
        default: true,
        limit: 190

    },
    {
        name: 'Owner',
        value: 'activityOwner',
        flex: 1,
        group: 'Booking',
        default: true

    },
    {
        name: 'Created on',
        value: 'createdOn',
        flex: 1,
        group: 'Booking',
        subText: 'createdBy',
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        group: 'Booking',
        subText: 'updatedBy',
        default: true
    },
    {
        name: 'Completed On',
        value: 'activityCompletedDate',
        subText: 'activityCompletedBy',
        flex: 1,
        group: 'Booking',
        default: false,
        subTextTitle: 'Completed By'
    },
    {
        name: 'Contact Email',
        value: 'contactEmail',
        group: 'Contact',
        flex: 1,
        default: true
    },
    {
        name: 'Contact Phone',
        value: 'contactPhone',
        group: 'Contact',
        flex: 1,
        default: true,
        elementName: 'csvPhone'
    },
    {
        name: 'Digital Driving License',
        value: 'isDLScan',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License No',
        value: 'licenseNo',
        flex: 1,
        default: false
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        flex: 1,
        default: false
    },
    {
        name: 'License Type',
        value: 'licenseType',
        flex: 1,
        default: false
    },
    {
        name: 'License State',
        value: 'licenseState',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        flex: 1,
        default: false
    },
    {
        name: 'Birthday',
        value: 'dob',
        flex: 1,
        default: false
    },
    {
        name: 'Gender',
        value: 'gender',
        flex: 1,
        default: false
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        flex: 1,
        default: false
    },
    {
        name: 'Language',
        value: 'language',
        flex: 1,
        default: false
    },
    {
        name: 'Nationality',
        value: 'nationality',
        flex: 1,
        default: false
    },
    {
        name: 'Address',
        value: 'address',
        flex: 2,
        default: false
    },
    {
        name: 'Company Name',
        value: 'companyName',
        flex: 1,
        default: false
    },
    {
        name: 'Designation',
        value: 'designation',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Name',
        value: 'secondaryContactName',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Phone',
        value: 'secondaryContactPhone',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Email',
        value: 'secondaryContactEmail',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Created On',
        value: 'contactCreatedOn',
        subText: 'contactCreatedBy',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Updated On',
        value: 'contactModifiedOn',
        subText: 'contactModifiedby',
        flex: 1,
        default: false
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const enquiryBasicVM = {
    documentID: null,
    displayID: null,
    // keywords: [],
    pipeline: '',
    stage: '',
    status: '',
    oemStatus: '',
    enquiryType: '',
    serviceType: '',
    leadSource: '',
    origin: '',
    campaign: '',
    label: '',
    purchaseIntention: '',
    contractNumber: '',
    clientID: '',
    requirement: {},
    stageDate: null,
    owner: '',
    ownerInbound: '',
    financeBM: '',
    afterMarketBM: '',
    isAfterSales: false,
};

export const enquiryBasicTDVM = {
    documentID: null,
    displayID: null,
    clientID: null,
    projectId: null,
    pipeline: '',
    stage: '',
    status: '',
    oemStatus: '',
    enquiryType: '',
    serviceType: '',
    leadSource: '',
    origin: '',
    campaign: '',
    label: '',
    isAfterSales: false,
    purchaseIntention: '',
    depositAmount: 0,
    depositReciptNumber: '',
    grossAmount: 0,
    contractNumber: '',
    contact: {},
    contactID: null,
    requirement: {},
    isFav: false,
    stageDate: null,
    stageHistory: [],
    statusHistory: [],
    lostReason: '',
    lostSubReason: '',
    lostNotes: '',
    isFinanceRequestSent: false,
    isFinanceRequired: false,
    isConverted: false,
    convertedBy: '',
    convertedDate: null,
    financeStatus: '',
    afterMarketStatus: '',
    chassisNo: '',
    regNo: '',
    stockNo: '',
    warrantyStartDate: null,
    warrantyExpiry: null,
    deliveryDate: null,
    wonDate: null,
    lostDate: null,
    deliveredDate: null,
    options: [],
    walkins: [],
    owner: '',
    ownerInbound: '',
    financeBM: '',
    financeProvider: '',
    financeStartDate: null,
    financeTerms: 0,
    financeAmount: 0,
    financeRate: 0,
    afterMarketBM: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    confirmedBy: '',
    confirmedDate: null,
    verifiedBy: '',
    verifiedDate: null,
    receivedDate: null,
    paymentMethod: '',
    dynamicFields: {},
    tags: [],
    linkID: null,
    linkEnquiryID: null,
};

export const objEnquiryFilter = [
    "owner", "label", "status", "pipeline", "enquiryType", "origin", "campaign", "leadSource"
]

export const objOEMInboundVM = {
    documentID: null,
    refID: null,
    enquiryID: null,
    pipeline: 'LeadsBucket',
    stage: 'InboundLead',
    status: 0,
    enquiryType: '',
    leadSource: '',
    origin: '',
    campaign: '',
    contact: {},
    requirement: {},
    isFav: false,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false,
    notes: null,
    leadCreated: null,
    leadModified: null
};

export const objNewEnquiryVM = {
    documentID: null,
    displayID: null,
    clientID: null,
    projectId: null,
    pipeline: '',
    stage: '',
    status: '',
    contact: {},
    contactID: null,
    requirement: {},
    isFav: false,
    isContact: false,
    isVehicle: false,
    isTestDrive: false,
    isTradeIn: false,
    isCafe: false,
    isFiles: false,
    isNotes: false,
    isFinance: false,
    isQuotation: false,
    isVideo: false,
    isProduct: false,
    owner: '',
    origin: '',
    ownerInbound: '',
    financeBM: '',
    financeProvider: '',
    financeStartDate: null,
    financeTerms: 0,
    financeAmount: 0,
    financeRate: 0,
    afterMarketBM: '',
    addedBy: '',
    addedFrom: 'web',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    counts: {},
    isDeleted: false,
    isNewEnquiry: false,
    isAfterSales: false,
};

export const objNewSubEnquiryVM = {
    documentID: null,
    displayID: null,
    clientID: null,
    projectId: null,
    settingsID: null,
    pipeline: '',
    stage: '',
    status: '',
    contact: {},
    contactID: null,
    requirement: {},
    isFav: false,
    isContact: false,
    isVehicle: false,
    stageDate: null,
    stageHistory: [],
    statusHistory: [],
    enquiryType: '',
    serviceType: '',
    leadSource: '',
    origin: '',
    campaign: '',
    label: '',
    purchaseIntention: '',
    depositAmount: 0,
    depositReciptNumber: '',
    grossAmount: 0,
    responseTime: null,
    responseTimeDisplay: null,
    deliveryDate: null,
    wonDate: null,
    owner: '',
    ownerInbound: '',
    financeBM: '',
    financeProvider: '',
    financeStartDate: null,
    financeTerms: 0,
    financeAmount: 0,
    financeRate: 0,
    afterMarketBM: '',
    addedBy: '',
    addedFrom: 'web',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    counts: {},
    dynamicFields: {},
    tags: [],
    linkID: null,
    linkEnquiryID: null,
    isDeleted: false,
    isNewEnquiry: false,
    isAfterSales: false,
};


export const contactBasicDetailsVM = {
    documentID: null,
    displayID: null,
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    preferredName: '',
    userImageURL: '',
    phoneCode: '',
    phone: '',
    email: '',
    address: '',
    state: '',
    areaCode: '',
    country: '',
    licenseNo: '',
    licenseExpiry: null,
    licenseFrontURL: '',
    licenseBackURL: '',
    licenseType: '',
    licenseState: '',
    owner: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
};