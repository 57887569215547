import _ from 'lodash';
import React from 'react';

const BarChartCSS = (props) => {
    return _.isEmpty(props.data) ? (<></>) : (
        <div className="minibar minibar--mini">
            {
                props.data.map((rec, index) => {
                    return <div key={index} className="minibar__bar" onClick={(e) => {
                        if (props.handleShowList) {
                            props.handleShowList(e, rec, props.field)
                        }
                    }}>
                        <span className="minibar__tooltip">{rec.month}<br />{rec[props.field]}</span>
                        <div className="minibar__fill cursor-pointer" style={{ height: `${rec[`${props.field}Ptg`]}%` }}></div>
                    </div>
                })
            }
        </div>
    )
}

export default BarChartCSS;