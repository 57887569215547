import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import { InputText, ReactSelect, InputMultipleText, InputCheckBox, PopUpModal, ReactMultiSelect, ReactTimePicker } from '../../../../components'
import { saleTypes, mailtotypes, triggerVM, tiggerFrequency, frequencyOptions, priodEveryTypes, eventTypes, replyTypes, contactFieldNames, recurringOptions, triggerDateOptions, modelYearOptions } from './viewModel'
import TemplatePreview from './previewTemplate'
import Translate from '../../../../constants/translate';
import _images from '../../../../images'
import CommonHelper from '../../../../services/common';
import { activityDates, enquiryDates, levelOptions } from '../fieldSettings/viewModel'
import { TemplateContext } from '../templateContext'
import { activityLogOptions } from '../../../pipeline/viewModel'
import { callTypes } from '../../../../services/enum'
import moment from 'moment'

let origins = [];
let enquiryTypes = [];
let campaigns = [];
let lead_source = [];

const TriggerType = (props) => {
    //console.log(props)
    const [trigger, setTrigger] = useState(props.trigger);
    const [errorFields, setErrorFields] = useState({});
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [years, setYears] = useState([])
    const [bodyTypes, setBodyTypes] = useState([])
    const [modelGroups, setmodelGroups] = useState([])
    const [emailTemplates, setEmailTemplates] = useState([])
    const [smsTemplates, setSmsTemplates] = useState([])
    const [lostReasons, SetLostReasons] = useState([])


    const [showPreviewModel, setShowPreviewModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [template, setTemplate] = useState(null)
    const [loader, setLoader] = useState(false)
    const [ownerOptions, setOwnerOptions] = useState([]);
    const [allTeamUsers, setAllTeamUsers] = useState([])
    const [activityTypes, setActivityTypes] = useState(activityLogOptions);
    const [alleventTypes, setEventTypes] = useState(eventTypes);

    const { triggerSmsTemplates, edmTemplates } = useContext(TemplateContext);
    const [arrcallstatus, setCallStatus] = useState([]);
    const [cron, setCron] = useState();
    const [dateOptions, setDateOptions] = useState()

    const enquiryStatus = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.enquiryStatus ? props.dealersettings.client.settings.enquiryStatus : [];


    const enquiryOptionsDF = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.enquiryOptionsDF ? props.dealersettings.client.settings.enquiryOptionsDF : [];

    const defaultactivityTypes = ['activity', 'financeactivity', 'afterMarketactivity']

    useEffect(() => {
        setTrigger(props.trigger)
    }, [props.trigger])

    useEffect(() => {
        if (_.isEmpty(enquiryStatus))
            return;

        setEventTypes(eventTypes.map(rec => {
            return !_.isEmpty(rec.statusValue) ? {
                ...rec,
                name: `Enquiry ${_.find(enquiryStatus, { value: rec.statusValue.toLowerCase() }) ? `${_.find(enquiryStatus, { value: rec.statusValue.toLowerCase() }).name}` : rec.statusValue}`,
                label: `Enquiry ${_.find(enquiryStatus, { value: rec.statusValue.toLowerCase() }) ? `${_.find(enquiryStatus, { value: rec.statusValue.toLowerCase() }).name}` : rec.statusValue} Date`,
                desc: `Schedule an Activity, Email or SMS once an enquiry is marked as ${_.find(enquiryStatus, { value: rec.statusValue.toLowerCase() }) ? `${_.find(enquiryStatus, { value: rec.statusValue.toLowerCase() }).name}` : rec.statusValue}.`
            } : {
                ...rec
            }
        }))
    }, [])

    useEffect(() => {
        let _date = []
        for (let i = 1; i < 31; i++) {
            _date.push({ label: `${i}`, value: `${i}` })
        }
        _date.push({ label: 'Last day of month', value: 'L' })
        setDateOptions(_date)

        const years = [];
        for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
            years.push({
                value: i,
                label: i.toString(),
                active: true
            });
        }
        setYears(years)
    }, [])

    useEffect(() => {
        if (_.isEmpty(trigger))
            return

        let _cron = ['*', '*', '*', '*', '*']
        if (trigger.schedulartime) {
            _cron[0] = moment.unix(trigger.schedulartime.seconds).format('m');
            _cron[1] = moment.unix(trigger.schedulartime.seconds).format('H');
        }
        if (trigger.day) {
            _cron[4] = trigger.day;
        }
        if (trigger.date) {
            _cron[2] = trigger.date;
        }
        if (trigger.month) {
            _cron[3] = trigger.month;
        }
        setCron(_cron.join(' '));
    }, [trigger])

    useEffect(() => {


        let users = [];
        users.push({
            label: <div className="drop-image">
                <img src={_images.nouser} alt="" width="50" />
                Owner
            </div>,
            value: 'owner',
            active: true,
        })
        users.push({
            label: <div className="drop-image">
                <img src={_images.nouser} alt="" width="50" />
                Business Manager
            </div>,
            value: 'financeBM',
            active: true,
        })
        users.push({
            label: <div className="drop-image">
                <img src={_images.nouser} alt="" width="50" />
                After Market Manager
            </div>,
            value: 'afterMarketBM',
            active: true,
        })
        if (!_.isEmpty(props.clientUsers)) {
            props.clientUsers.forEach(rec => {
                users.push({
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>,
                    value: rec.id,
                    searchlabel: rec.name,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                })
            })
        }
        setOwnerOptions(users)
    }, [props.clientUsers])

    useEffect(() => {
        if (_.isEmpty(edmTemplates)) {
            return;
        }
        let listVM = [];
        edmTemplates.forEach(rec => {
            if (!listVM.some(e => e.documentID === rec.documentID))
                listVM.push({
                    label: rec.title,
                    value: rec.documentID,
                    doc: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                })
        })
        setEmailTemplates(listVM)
    }, [edmTemplates])

    useEffect(() => {
        if (_.isEmpty(triggerSmsTemplates)) {
            return;
        }
        let listVM = [];
        triggerSmsTemplates.forEach(rec => {
            if (!listVM.some(e => e.documentID === rec.documentID))
                listVM.push({
                    label: rec.title,
                    value: rec.documentID,
                    doc: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                })
        })
        setSmsTemplates(listVM)
    }, [triggerSmsTemplates])

    useEffect(() => {
        setTrigger({
            ...trigger,
            'category': props.trigger.category,
            'status': alleventTypes.find(item => item.value === props.trigger.category && item.status === props.trigger.status) ?
                alleventTypes.find(item => item.value === props.trigger.category && item.status === props.trigger.status).status
                : alleventTypes.filter(item => item.value === props.trigger.category)[0].status,
            'triggerType': props.newTrigger === true && props.trigger.category === 'activity' ? 'email' : trigger.triggerType
        })
    }, [props.trigger.category])
    useEffect(() => {
        if (_.isEmpty(trigger))
            return
        let brands = props.dealersettings.client.brands;
        let makes = [];
        let types = [];
        let groups = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (trigger.make) {
            let _make = trigger.make.split(',')[0]
            let _models = !_.isEmpty(makes.filter(m => m.value === _make)[0]) ? makes.filter(m => m.value === _make)[0].models : [];
            _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        label: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }

        const _teams = [];
        trigger.clientID === props.dealersettings.client.id && props.dealersettings.client.teams && props.dealersettings.client.teams.forEach(doc => {
            _teams.push({
                value: doc.id,
                type: 'team',
                searchlabel: doc.name,
                user_ids: doc.user_ids ? doc.user_ids : [],
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: (
                    <div className='drop-image'>
                        <img src={CommonHelper.showUserAvatar('', doc.name)} alt="" width="50" className="img-object-fit" />
                        {doc.name}
                    </div>
                )
            });
        });

        let _users = [];
        if (!_.isEmpty(trigger)) {
            if (trigger.clientID === props.dealersettings.client.id) {
                props.clientUsers.filter(e => e.id !== trigger.owner).map(rec => {
                    return _users.push({
                        value: rec.id,
                        type: 'user',
                        searchlabel: rec.name,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        label: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        ),
                    });
                });
            }
            else if (!_.isEmpty(props.groupUsers)) {
                props.groupUsers.filter(e => e.clientID === trigger.clientID && e.id !== trigger.owner).map(rec => {
                    return _users.push({
                        value: rec.id,
                        type: 'user',
                        searchlabel: rec.name,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        label: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        ),
                    });
                });
            }
        }

        const _lostReasons = [];
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings) {
            const setttings = props.dealersettings.client.settings;
            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            lead_source = [];
            setttings.leadSource && setttings.leadSource.forEach(doc => {
                lead_source.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                enquiryTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            const _activityTypes = [];
            setttings.activityTypes && setttings.activityTypes.filter(a => a.active === true).forEach(doc => {
                _activityTypes.push(
                    {
                        label: (<><i className={`ico icon-${doc.icon} mr-1`}></i>{doc.name}</>),
                        name: doc.name,
                        value: doc.value,
                        active: true,
                    });
            });

            const _callstatus = [];
            setttings.callStatus && setttings.callStatus.forEach(doc => {
                _callstatus.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });
            setCallStatus(_callstatus);
            if (!_.isEmpty(_activityTypes))
                setActivityTypes(_activityTypes);
        }
        SetLostReasons(_lostReasons)
        setMakes(makes)
        setModels(models)
        setmodelGroups(groups)
        setBodyTypes(types)
        setAllTeamUsers([
            {
                label: 'Teams',
                options: _teams
            },
            {
                label: 'Users',
                options: _users
            }
        ])
    }, [props.dealersettings, trigger, trigger.make])

    const handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        setTrigger({
            ...trigger,
            [name]: value ? value.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() }) : ''
        })

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        var str = value;

        if (str && str.length >= 1) {
            var firstChar = str.charAt(0);
            var remainingStr = str.slice(1);
            str = firstChar.toUpperCase() + remainingStr;
        }
        setTrigger({
            ...trigger,
            [name]: str
        });
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleTextChange = e => {
        const { name, value } = e.target;

        setTrigger({
            ...trigger,
            [name]: (value || ''),
        });
        setErrorFields({
            ...errorFields,
            [name]: '',
        });
    };

    const handleOnChangeMultipleText = (val, name) => {

        setTrigger({
            ...trigger,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'make') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['model']: '',
                ['type']: ''
            })
        }
        else if (data.name === 'ccType') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['mailCc']: e && e.value === 'owner' ? e.value : ''
            })
        }
        else if (data.name === 'bccType') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['mailBcc']: e && e.value === 'owner' ? e.value : ''
            })
        }
        else if (data.name === 'frequency') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['day']: null,
                ['date']: null,
                ['month']: null,
            })
        }
        else if (data.name === 'fieldName') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['fieldValue']: e && (e?.value === 'financeBM' || e?.value === 'afterMarketBM') ? e.value : '',
                ['triggerFor']: e && (e?.value === 'financeBM' || e?.value === 'afterMarketBM') ? e.value : '',
            })
        }
        else if (data.name === 'period') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['customTime']: null,
            })
        }
        else {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : ''
            })
        }

        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleHoursChange = (val, name) => {
        if (name === 'customTime') {
            setTrigger({
                ...trigger,
                [name]: moment(val).format('hh:mm A')
            });
        } else {
            setTrigger({
                ...trigger,
                [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
            });
        }

    }

    const handleCheckedChange = (e) => {
        setTrigger({
            ...trigger,
            [e.target.name]: e.target.checked
        })
    }

    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setTrigger({
                ...trigger,
                [e.target.name]: number !== '' ? Number(number) : number
            });
        }

        setErrorFields({
            ...errorFields,
            [e.target.name]: ''
        });
    };

    const removeEmail = (index, name) => {
        let arr = trigger[name] ? trigger[name].split(',') : []
        arr.splice(index, 1)
        setTrigger({
            ...trigger,
            [name]: arr.join(',')
        })
    }

    const saveTrigger = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(trigger['title'])) {
            formIsValid = false;
            errors['title'] = errorClass;
        }
        if (_.isEmpty(trigger['period'])) {
            formIsValid = false;
            errors['period'] = errorClass;
        }
        if (trigger.triggerType === 'activity') {
            if (_.isEmpty(trigger['todoType'])) {
                formIsValid = false;
                errors['todoType'] = errorClass;
            }
        }
        else {
            if (_.isEmpty(trigger['templateID'])) {
                formIsValid = false;
                errors['templateID'] = errorClass;
            }
            if (trigger.recipientType === 'custom' && _.isEmpty(trigger['recipientTo'])) {
                formIsValid = false;
                errors['recipientTo'] = errorClass;
            }
        }
        if (trigger.period !== 'instant' && trigger.period !== 'customTime' && !Boolean(trigger.recurrence)) {
            if (!trigger['periodValue'] || trigger['periodValue'] === 0 || _.isEmpty(trigger['periodValue'].toString())) {
                formIsValid = false;
                errors['periodValue'] = errorClass;
            }
        }
        if (trigger.period === 'customTime') {
            if (!trigger['customTime'] || trigger['customTime'] === 0 || _.isEmpty(trigger['customTime'].toString())) {
                formIsValid = false;
                errors['customTime'] = errorClass;
            }
        }
        if (trigger.period === 'iteration') {
            if (!trigger['periodEvery'] || trigger['periodEvery'] === 0 || _.isEmpty(trigger['periodEvery'].toString())) {
                formIsValid = false;
                errors['periodEvery'] = errorClass;
            }
            if (_.isEmpty(trigger['periodEveryType'])) {
                formIsValid = false;
                errors['periodEveryType'] = errorClass;
            }
        }
        if (trigger.status === 'contactfieldUpdate' || trigger.status === 'enquiryContactFieldUpdate') {
            if (_.isEmpty(trigger['fieldName'])) {
                formIsValid = false;
                errors['fieldName'] = errorClass;
            }
            if (_.isEmpty(trigger['fieldValue'])) {
                formIsValid = false;
                errors['fieldValue'] = errorClass;
            }
        }
        if (trigger.recurrence === true) {
            if (_.isEmpty(trigger['pipelines'])) {
                formIsValid = false;
                errors['pipelines'] = errorClass;
            }
            if (_.isEmpty(trigger['stages'])) {
                formIsValid = false;
                errors['stages'] = errorClass;
            }
            if (_.isEmpty(trigger['frequency'])) {
                formIsValid = false;
                errors['frequency'] = errorClass;
            }
            if (_.isEmpty(trigger['fieldName'])) {
                formIsValid = false;
                errors['fieldName'] = errorClass;
            }
            if (!trigger['fieldValue'] || trigger['fieldValue'] === 0 || _.isEmpty(trigger['fieldValue'].toString())) {
                formIsValid = false;
                errors['fieldValue'] = errorClass;
            }
            if (trigger['frequency'] === 'weekly' && _.isEmpty(trigger['day'])) {
                formIsValid = false;
                errors['day'] = errorClass;
            }
            if (trigger['frequency'] === 'monthly' && _.isEmpty(trigger['date'])) {
                formIsValid = false;
                errors['date'] = errorClass;
            }
            if (trigger['frequency'] === 'yearly' && _.isEmpty(trigger['month'])) {
                formIsValid = false;
                errors['month'] = errorClass;
            }
            if (trigger['frequency'] === 'yearly' && _.isEmpty(trigger['date'])) {
                formIsValid = false;
                errors['date'] = errorClass;
            }
            if (_.isEmpty(trigger['schedulartime'])) {
                formIsValid = false;
                errors['schedulartime'] = errorClass;
            }
        }
        if (trigger.status === 'financeStatus' || trigger.status === 'afterMarketStatus') {
            if (_.isEmpty(trigger[trigger.status])) {
                formIsValid = false;
                errors[trigger.status] = errorClass;
            }
        }
        // if (!_.isEmpty(trigger['pipeline']) && _.isEmpty(trigger['stage'])) {
        //     formIsValid = false;
        //     errors['stage'] = errorClass;
        // }
        if (!trigger.level) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (!_.isEmpty(trigger.level) && !trigger.subLevelIDs && trigger.level !== 'oem' && trigger.level !== 'individual') {
            formIsValid = false;
            errors['subLevelIDs'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        if (trigger.triggerFor === 'financeBM' && defaultactivityTypes.includes(trigger.triggerType)) {
            trigger.triggerType = 'financeactivity'
        }
        if (trigger.triggerFor === 'afterMarketBM' && defaultactivityTypes.includes(trigger.triggerType)) {
            trigger.triggerType = 'afterMarketactivity'
        }
        setLoader(true)
        if (trigger.level) {
            const selectedOptions = trigger.subLevelIDs;
            trigger.level = trigger.level ? trigger.level : ''
            trigger.clientID = (trigger.level === 'individual' && selectedOptions.length === 1) ? selectedOptions[0] : null;
            trigger.oemID = null;
            trigger.regionIDs = [];
            trigger.groupIDs = [];
            trigger.clientIDs = [];
            if (trigger.level === 'oem') {
                trigger.oemID = props.dealersettings.client.settingsID
            } else if (trigger.level === 'region') {
                trigger.regionIDs = selectedOptions
            } else if (trigger.level === 'group') {
                trigger.groupIDs = selectedOptions
            } else if (trigger.level === 'individual') {
                trigger.clientIDs = selectedOptions
            }
        }
        trigger.modifiedDate = window.firebase.firestore.Timestamp.now();
        trigger.modifiedBy = localStorage.uid;
        trigger.module = 'sales';
        if (Boolean(trigger.recurrence)) {
            trigger.cron = cron;
        }
        else {
            trigger.cron = null;
            trigger.frequency = null;
            trigger.schedulartime = null
        }
        if (trigger.status !== 'enquiryUpdate' && !trigger.status.includes('Scheduled')) {
            trigger.startFromField = null
        }
        if (trigger.status === 'enquiryCreated' && (!_.isEmpty(trigger.make) || !_.isEmpty(trigger.model) || !_.isEmpty(trigger.bodyType) || !_.isEmpty(trigger.saleType)))
            trigger.status = 'enquiryCreatedREQ'
        if (_.isEmpty(trigger.recipientType) && (trigger.triggerType === 'email' || trigger.triggerType === 'sms'))
            trigger.recipientType = 'contact'
        window.firebase.firestore().doc(`triggers/${trigger.documentID}`)
            .set({
                ..._.pick({
                    ...trigger,
                    periodValue: (trigger.period !== 'iteration' && trigger.period !== 'instant' && trigger.period !== 'birthday' && props.frequencyValue === 'before') ? -trigger.periodValue : trigger.periodValue
                }, _.keys(triggerVM))
            }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((props.newTrigger === true ? 'Trigger added successfully' : 'Trigger updated successfully'), {
                    duration: 2000
                })
                props.handleClose(trigger);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const previewTemplate = (type) => {
        if (_.isEmpty(trigger.templateID))
            return
        if (type === 'email' && emailTemplates.find(item => item.doc.documentID === trigger.templateID)) {
            setTemplate(emailTemplates.find(item => item.doc.documentID === trigger.templateID).doc)
            setPopHeader(emailTemplates.find(item => item.doc.documentID === trigger.templateID).label)
            setShowPreviewModel(true)
        }
        else if (smsTemplates.find(item => item.doc.documentID === trigger.templateID)) {
            setTemplate(smsTemplates.find(item => item.doc.documentID === trigger.templateID).doc)
            setPopHeader(smsTemplates.find(item => item.doc.documentID === trigger.templateID).label)
            setShowPreviewModel(true)
        }

    }

    const handlePreviewModelClose = () => {
        setShowPreviewModel(false)
        setTemplate(null)
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        if (name === 'pipelines') {
            setTrigger({
                ...trigger,
                stages: [],
                [name]: value
            });
        }
        else if (name === 'make') {
            setTrigger({
                ...trigger,
                ['model']: '',
                ['type']: '',
                [name]: value.join(',')
            });
        }
        else if (name === 'model') {
            setTrigger({
                ...trigger,
                ['type']: '',
                [name]: value.join(',')
            });
        }
        else if (name === 'recipientTo') {
            setTrigger({
                ...trigger,
                [name]: !_.isEmpty(value) ? value.join(',') : ''
            })
        }
        else {
            setTrigger({
                ...trigger,
                [name]: value
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handlesetLevel = (e, data) => {
        if (data.name === 'level') {
            if (e.value === 'oem') {
                setTrigger({
                    ...trigger,
                    [data.name]: e.value,
                    ['levelID']: e.value === 'oem' ? props.dealersettings.client.settingsID : '',
                    ['subLevelIDs']: ''
                });
            } else {
                setTrigger({
                    ...trigger,
                    [data.name]: e.value,
                    ['levelID']: '',
                    ['subLevelIDs']: ''
                });
            }
            setErrorFields({
                ...errorFields,
                [data.name]: ''
            });
        }
    }

    const handleTemmateChange = selectedOptions => {
        let _usersList = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data, index) => {
            if (data.type === 'team') {
                _usersList = _.union(_usersList, Object.assign([], data.user_ids));
            }
            else
                _usersList = _.union(_usersList, [data.value]);
        });

        setTrigger({
            ...trigger,
            teammates: _usersList
        });
    };

    const pipelineOptions = _.map(props.dealersettings.client.settings.pipelines, function (rec) {
        return {
            label: rec.name,
            value: rec.value
        }
    })

    return (
        <>
            <div className="add-trigger-columns h-100" >
                <div className="add-trigger-column-left">
                    <div className="add-trigger-column-title">
                        <h3> <Translate text={'Event Type'} /></h3>
                    </div>
                    <div className="add-trigger-column-nav">
                        <ul>
                            {
                                alleventTypes.filter(item => item.value === trigger.category).map((rec, index) => {
                                    return <li key={index} className={`${rec.status === ((trigger.status === 'enquiryCreated' || trigger.status === 'enquiryCreatedREQ') ? 'enquiryCreated' : trigger.status) ? 'active' : ''}`}>
                                        <a href="#" className="column-list-left" onClick={(e) => {
                                            e.preventDefault();
                                            if (rec.status === 'contactBirthday') {
                                                setTrigger({
                                                    ...trigger,
                                                    'status': rec.status,
                                                    'period': 'birthday',
                                                    'stageTo': null,
                                                    'stageList': null,
                                                })
                                            }
                                            else if (Boolean(rec.recurrence)) {
                                                setTrigger({
                                                    ...trigger,
                                                    'status': rec.status,
                                                    'recurrence': Boolean(rec.recurrence),
                                                    'frequency': _.isEmpty(trigger.frequency) ? 'monthly' : trigger.frequency,
                                                    'stageTo': null,
                                                    'stageList': null,
                                                })
                                            }
                                            else {
                                                setTrigger({
                                                    ...trigger,
                                                    'status': rec.status,
                                                    'recurrence': Boolean(rec.recurrence),
                                                    'stageTo': null,
                                                    'stageList': null,
                                                })
                                            }
                                        }}>
                                            {
                                                rec.status === ((trigger.status === 'enquiryCreated' || trigger.status === 'enquiryCreatedREQ') ? 'enquiryCreated' : trigger.status) ?
                                                    (<div className="trigger-active-check"><i className="ico icon-check"> </i></div>)
                                                    : (<></>)
                                            }

                                            <Translate text={rec.name} /> <span>{CommonHelper.showLocale(props, rec.desc)}</span> </a>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="add-trigger-column-middle">
                    <div className="add-trigger-column-title">
                        <h3> <Translate text={'Trigger Type'} /></h3>
                    </div>
                    <div className="add-trigger-submenu">
                        <ul>
                            {
                                props.value !== 'activity' && props.value !== 'inboundLead' && trigger.status !== 'enquiryRecurrence' ?
                                    (<li><a href="#" className={`${defaultactivityTypes.includes(trigger.triggerType) ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setTrigger({
                                                ...trigger,
                                                'triggerType': 'activity',
                                                'templateID': null,
                                                'stageTo': null,
                                                'stageList': null,

                                            })
                                        }}><i className="ico icon-trigger-activity"></i>  <Translate text={'activity'} /></a></li>)
                                    : (<></>)
                            }

                            <li><a href="#" className={`${trigger.triggerType === 'email' ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setTrigger({
                                        ...trigger,
                                        'triggerType': 'email',
                                        'templateID': null,
                                        'stageTo': null,
                                        'stageList': null,
                                    })
                                }}><i className="ico icon-trigger-email"></i>  <Translate text={'email'} /></a></li>
                            <li><a href="#" className={`${trigger.triggerType === 'sms' ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setTrigger({
                                        ...trigger,
                                        'triggerType': 'sms',
                                        'templateID': null,
                                        'stageTo': null,
                                        'stageList': null,
                                    })
                                }}><i className="ico icon-trigger-sms"></i>  <Translate text={'SMS'} /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="settings-column-right">
                    <div className="add-trigger-column-title">
                        <h3> <Translate text={'Trigger Settings'} /></h3>
                    </div>
                    <div className="trigger-form-wraper form-style">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label > <Translate text={'title'} /> </label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={`${'Title'}`}
                                    className={`form-control ${errorFields["title"]}`}
                                    name="title"
                                    onChange={handleOnChange}
                                    value={trigger.title}
                                />
                            </div>
                        </div>
                        {
                            trigger.triggerType === 'email' ? (
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label > <Translate text={'Subject'} /> </label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={`${'subject'}`}
                                            className={`form-control ${errorFields["subject"]}`}
                                            name="subject"
                                            onChange={handleTextChange}
                                            value={trigger.subject}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label > <Translate text={'Sender Name'} /> </label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={`${'senderName'}`}
                                            className={`form-control ${errorFields["senderName"]}`}
                                            name="senderName"
                                            onChange={handleTextChange}
                                            value={trigger.senderName}
                                        />
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                        {
                            trigger.status === 'contactfieldUpdate' || trigger.status === 'enquiryContactFieldUpdate' ? (
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label> <Translate text={'Field Name'} /></label>
                                        <ReactSelect
                                            options={contactFieldNames.filter(a => a.status.includes(trigger.status))}
                                            name={"fieldName"}
                                            placeholder={'select contact field'}
                                            onChange={handleSelectChange}
                                            value={trigger.fieldName}
                                            classNamePrefix={`${errorFields["fieldName"]} basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                    {
                                        trigger.fieldName === 'financeBM' || trigger.fieldName === 'afterMarketBM' ? (<></>) :
                                            (<>
                                                {
                                                    trigger.fieldName === 'creditScore' ? (<>
                                                        <div className="form-group col-md-6">
                                                            <label> <Translate text={'Operator'} /></label>
                                                            <ReactSelect
                                                                options={modelYearOptions}
                                                                name={"fieldOP"}
                                                                placeholder={'select'}
                                                                onChange={handleSelectChange}
                                                                value={trigger.fieldOP}
                                                                classNamePrefix={` basic-select`}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'Field Value'} /> </label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={`${'fieldValue'}`}
                                                                className={`form-control ${errorFields["fieldValue"]}`}
                                                                name="fieldValue"
                                                                onChange={handleTextChange}
                                                                value={trigger.fieldValue}
                                                            />
                                                        </div>
                                                    </>) : (<div className={`form-group col-md-${trigger.fieldName === 'creditScore' ? '6' : '12'}`}>
                                                        <label> <Translate text={'Field Values'} /></label>
                                                        <InputMultipleText
                                                            placeholder={`values`}
                                                            className={`form-control react-multi-tag  ${errorFields["fieldValue"]}`}
                                                            name="fieldValue"
                                                            onChange={(emails) => { handleOnChangeMultipleText(emails, 'fieldValue') }}
                                                            value={!_.isEmpty(trigger.fieldValue) ? trigger.fieldValue.split(',') : []}
                                                            validateEmail={false}
                                                            getLabel={(email, index) => {
                                                                return <div data-tag key={index}>
                                                                    {email}
                                                                    <span data-tag-handle onClick={() => removeEmail(index, 'fieldValue')}>
                                                                        ×
                                                                    </span>
                                                                </div>
                                                            }}
                                                        />
                                                    </div>)
                                                }

                                            </>)
                                    }
                                </div>) : (<></>)
                        }
                        {
                            trigger.triggerType === 'activity' ? (
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label> <Translate text={'Activity Type'} /></label>
                                        <ReactSelect
                                            options={activityTypes}
                                            name={"todoType"}
                                            placeholder={'select activity type'}
                                            onChange={handleSelectChange}
                                            value={trigger.todoType}
                                            classNamePrefix={`${errorFields["todoType"]} basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>) : (<></>)
                        }
                        {
                            trigger.status === 'financeStatus' || trigger.status === 'afterMarketStatus' ? (
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label> <Translate text={trigger.status} /></label>
                                        <ReactSelect
                                            options={props?.dealersettings?.client?.settings?.[trigger.status]?.map(r => {
                                                return {
                                                    label: <div className={`badge badge-pill ${r.value === 'open' ? 'badge-status-open' : 'badge-status-empty'}`} style={{ background: r.color }}>{r.name}</div>,
                                                    value: r.value,
                                                    searchlabel: r.name
                                                }
                                            }) || []}
                                            name={trigger.status}
                                            placeholder={'select status'}
                                            onChange={handleSelectChange}
                                            value={trigger[trigger.status]}
                                            classNamePrefix={`${errorFields[trigger.status]} basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                        {
                            (trigger.category === 'enquiry' || trigger.category === 'testdrive' || trigger.category === 'tradein') ? (
                                <div className="form-row">
                                    <div className={`form-group col-md-12`}>
                                        <label> <Translate text={'Pipelines'} /></label>
                                        {
                                            trigger.status === 'enquiryRecurrence' ? (
                                                <ReactSelect
                                                    options={_.map(props.dealersettings.client.settings.pipelines.filter(e => localStorage.defaultModule === 'oem' ? e.value === 'LeadsBucket' : true), function (rec) {
                                                        return {
                                                            label: rec.name,
                                                            value: rec.value,
                                                            doc: rec,
                                                        }
                                                    })}
                                                    name={"pipelines"}
                                                    placeholder={'select pipelines'}
                                                    onChange={(e) => {
                                                        setTrigger({
                                                            ...trigger,
                                                            ['pipelines']: e ? [e.value] : []
                                                        })
                                                    }}
                                                    value={trigger.pipelines[0]}
                                                    classNamePrefix={`${errorFields["pipelines"]}  basic-select`}
                                                >
                                                </ReactSelect>
                                            ) : (
                                                <ReactMultiSelect
                                                    options={_.map(props.dealersettings.client.settings.pipelines.filter(e => localStorage.defaultModule === 'oem' ? e.value === 'LeadsBucket' : true), function (rec) {
                                                        return {
                                                            label: rec.name,
                                                            value: rec.value,
                                                            doc: rec,
                                                        }
                                                    })}
                                                    name={"pipelines"}
                                                    placeholder={'select pipeline'}
                                                    onChange={e => handleReactMultiSelectChange(e, 'pipelines')}
                                                    value={trigger.pipelines}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            )
                                        }
                                    </div>
                                    {!_.isEmpty(trigger.pipelines) && trigger.pipelines.length === 1 && trigger.status !== 'enquiryCreated' ? (
                                        <div className={`form-group col-md-12`}>
                                            <label> <Translate text={'Stages'} /></label>
                                            {
                                                trigger.status === 'enquiryRecurrence' ? (
                                                    <ReactSelect
                                                        options={_.map(props.dealersettings.client.settings.pipelines.filter(item => item.value === trigger.pipelines[0])?.[0]?.stages?.filter(item => trigger.pipelines[0] === 'LeadsBucket' ? (item.value !== 'InboundLead' && item.value !== 'Converted') : true), function (s) {
                                                            return {
                                                                value: s.value,
                                                                label: s.name,
                                                                active: _.isBoolean(s.active) ? s.active : true,
                                                            }
                                                        })}
                                                        name={"stages"}
                                                        placeholder={'select stage'}
                                                        onChange={(e) => {
                                                            setTrigger({
                                                                ...trigger,
                                                                ['stages']: e ? [e.value] : []
                                                            })
                                                        }}
                                                        value={trigger.stages[0]}
                                                        classNamePrefix={`${errorFields["stages"]}  basic-select`}
                                                    >
                                                    </ReactSelect>
                                                ) : (
                                                    <ReactMultiSelect
                                                        options={_.map(props.dealersettings.client.settings.pipelines.filter(item => item.value === trigger.pipelines[0])?.[0]?.stages?.filter(item => trigger.pipelines[0] === 'LeadsBucket' ? (item.value !== 'InboundLead' && item.value !== 'Converted') : true), function (s) {
                                                            return {
                                                                value: s.value,
                                                                label: s.name,
                                                                active: _.isBoolean(s.active) ? s.active : true,
                                                            }
                                                        })}
                                                        name={"stages"}
                                                        placeholder={'select stages'}
                                                        onChange={e => handleReactMultiSelectChange(e, 'stages')}
                                                        value={trigger.stages}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                )
                                            }

                                        </div>
                                    ) : <></>}
                                    {
                                        !_.isEmpty(trigger.pipelines)
                                            && trigger.pipelines.length === 1
                                            && trigger?.stages?.length > 0
                                            && trigger.category === 'enquiry'
                                            && trigger.status === 'enquiryUpdate'
                                            && (trigger.triggerType === 'sms' || trigger.triggerType === 'email') ? (
                                            <div className={`form-group col-md-12`}>
                                                <label> <Translate text={'Move Stage on SMS response / Email Sent'} /></label>
                                                <ReactSelect
                                                    options={_.map(props.dealersettings.client.settings.pipelines.filter(item => item.value === trigger.pipelines[0])?.[0]?.stages.filter(item => trigger.pipelines[0] === 'LeadsBucket' ? (item.value !== 'InboundLead' && item.value !== 'Converted') : !trigger.stages.includes(item.value)), function (s) {
                                                        return {
                                                            value: s.value,
                                                            label: s.name,
                                                            active: _.isBoolean(s.active) ? s.active : true,
                                                        }
                                                    })}
                                                    name={"stageTo"}
                                                    placeholder={'select stage'}
                                                    onChange={(e) => {
                                                        setTrigger({
                                                            ...trigger,
                                                            ['stageTo']: e ? e.value : null,
                                                            ['stageList']: e ? _.map(props.dealersettings.client.settings.pipelines.filter(item => item.value === trigger.pipelines[0])?.[0]?.stages, function (s) {
                                                                return s.value
                                                            }) : null
                                                        })
                                                    }}
                                                    value={trigger.stageTo}
                                                    classNamePrefix={`${errorFields["stageTo"]}  basic-select`}
                                                >
                                                </ReactSelect>

                                            </div>
                                        ) : (<></>)
                                    }
                                </div>
                            ) : (<></>)
                        }
                        {
                            (trigger.category === 'contact' || trigger.category === 'company' || trigger.category === 'loanvehicle' || trigger.category === 'tradein' || trigger.category === 'activity' || trigger.category === 'log') ? (<></>) : (<>
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label> <Translate text={'make'} /></label>
                                        <ReactMultiSelect
                                            options={makes}
                                            name={"make"}
                                            placeholder={'select make'}
                                            onChange={e => handleReactMultiSelectChange(e, 'make')}
                                            value={!_.isEmpty(trigger.make) ? trigger.make.split(',') : []}
                                            classNamePrefix={` basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label> <Translate text={'model'} /></label>
                                        <ReactMultiSelect
                                            options={models}
                                            name={"model"}
                                            placeholder={'select model'}
                                            onChange={e => handleReactMultiSelectChange(e, 'model')}
                                            value={!_.isEmpty(trigger.model) ? trigger.model.split(',') : []}
                                            classNamePrefix={` basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>  <Translate text={'group'} /></label>
                                        <ReactSelect
                                            options={modelGroups}
                                            name={"modelGroup"}
                                            placeholder={'select group'}
                                            onChange={handleSelectChange}
                                            value={trigger.modelGroup}
                                            classNamePrefix={` basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>  <Translate text={'type'} /></label>
                                        <ReactSelect
                                            options={bodyTypes}
                                            name={"bodyType"}
                                            placeholder={'select body type'}
                                            onChange={handleSelectChange}
                                            value={trigger.bodyType}
                                            classNamePrefix={` basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label> <Translate text={'saleType'} /></label>
                                        <ReactSelect
                                            options={saleTypes}
                                            name={"saleType"}
                                            placeholder={'select sale type'}
                                            onChange={handleSelectChange}
                                            value={trigger.saleType}
                                            classNamePrefix={` basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label> <Translate text={'Year'} /></label>
                                        <ReactSelect
                                            options={modelYearOptions}
                                            name={"modelYearOP"}
                                            placeholder={'select'}
                                            onChange={handleSelectChange}
                                            value={trigger.modelYearOP}
                                            classNamePrefix={` basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>&nbsp;</label>
                                        <ReactSelect
                                            options={trigger.modelYearOP ? years : []}
                                            name={"modelYear"}
                                            placeholder={'select year'}
                                            onChange={handleSelectChange}
                                            value={trigger.modelYear}
                                            classNamePrefix={` basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            </>
                            )
                        }
                        {
                            (trigger.category === 'enquiry' || trigger.category === 'inboundLead') ? (
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label>  <Translate text={'enquiryType'} /></label>
                                        <ReactMultiSelect
                                            options={enquiryTypes}
                                            name={'enquiryType'}
                                            placeholder={'select enquiry type'}
                                            onChange={e => handleReactMultiSelectChange(e, 'enquiryType')}
                                            value={trigger.enquiryType ? _.isArray(trigger.enquiryType) ? trigger.enquiryType : trigger.enquiryType.split(',') : []}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>

                                    <div className="form-group col-md-3">
                                        <label>  <Translate text={'origin'} /></label>
                                        <ReactMultiSelect
                                            options={origins}
                                            name={'origin'}
                                            placeholder={'select origin'}
                                            onChange={e => handleReactMultiSelectChange(e, 'origin')}
                                            value={trigger.origin ? _.isArray(trigger.origin) ? trigger.origin : trigger.origin.split(',') : []}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>

                                    <div className="form-group col-md-3">
                                        <label>  <Translate text={'campaign'} /></label>
                                        <ReactMultiSelect
                                            options={campaigns}
                                            name={'campaign'}
                                            placeholder={'select campaign'}
                                            onChange={e => handleReactMultiSelectChange(e, 'campaign')}
                                            value={trigger.campaign ? _.isArray(trigger.campaign) ? trigger.campaign : trigger.campaign.split(',') : []}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>

                                    <div className="form-group col-md-3">
                                        <label>  <Translate text={'Lead Source'} /></label>
                                        <ReactMultiSelect
                                            options={lead_source}
                                            name={'leadSource'}
                                            placeholder={'select lead source'}
                                            onChange={e => handleReactMultiSelectChange(e, 'leadSource')}
                                            value={trigger.leadSource ? _.isArray(trigger.leadSource) ? trigger.leadSource : trigger.leadSource.split(',') : []}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        {
                            trigger.status === 'enquiryUpdate' ? (
                                <div className="form-row">
                                    <div className="form-group col-md-4 mt-2">
                                        <label>&nbsp;</label>
                                        <span className="switch switch-sm float-left">
                                            <InputCheckBox
                                                className={`switch`}
                                                id={`trigger-tradeinDone`}
                                                name={'tradeinDone'}
                                                checked={trigger.tradeinDone}
                                                onChange={(e) => { handleCheckedChange(e) }} />
                                            <label htmlFor={`trigger-tradeinDone`}><Translate text={'Trade-In Done'} /></label>
                                        </span>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                        {
                            trigger.status === 'callLogCompleted' ? (
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>  <Translate text={'callType'} /></label>
                                        <ReactSelect
                                            options={callTypes}
                                            name={"callType"}
                                            placeholder={'select call type'}
                                            onChange={handleSelectChange}
                                            value={trigger.callType}
                                            classNamePrefix={`basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>  <Translate text={'callStatus'} /></label>
                                        <ReactMultiSelect
                                            options={arrcallstatus}
                                            name={"callStatus"}
                                            placeholder={'select call status'}
                                            onChange={e => handleReactMultiSelectChange(e, 'callStatus')}
                                            value={trigger.callStatus ? _.isArray(trigger.callStatus) ? trigger.callStatus : trigger.callStatus.split(',') : []}
                                            classNamePrefix={` basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        {
                            (trigger.category === 'enquiry' && trigger.status === 'enquiryLost') ? (
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>  <Translate text={'lostReasons'} /></label>
                                        <ReactMultiSelect
                                            options={lostReasons}
                                            name={'lostReasons'}
                                            placeholder={`select lost reasons`}
                                            onChange={e => handleReactMultiSelectChange(e, 'lostReasons')}
                                            value={trigger.lostReasons ? _.isArray(trigger.lostReasons) ? trigger.lostReasons : trigger.lostReasons.split(',') : []}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        {
                            (trigger.triggerType === 'email' || trigger.triggerType === 'sms') ? (
                                <>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            {
                                                !_.isEmpty(trigger.templateID) ? (
                                                    <a href="#" className="inputlink-addmore" onClick={(e) => { e.preventDefault(); previewTemplate(trigger.triggerType); }}> <Translate text={'preview'} /></a>
                                                ) : (<></>)
                                            }
                                            <label> <Translate text={'Template'} /></label>
                                            <ReactSelect
                                                options={trigger.triggerType === 'email' ? emailTemplates : smsTemplates}
                                                name={"templateID"}
                                                placeholder={'select template'}
                                                onChange={handleSelectChange}
                                                value={trigger.templateID}
                                                classNamePrefix={`${errorFields["templateID"]} basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label> <Translate text={'to'} /></label>
                                            <ReactSelect
                                                options={mailtotypes.filter(a => trigger.category === 'enquiry' ? true : a.value !== 'financeBM' && a.value !== 'afterMarketBM')}
                                                name={"recipientType"}
                                                onChange={handleSelectChange}
                                                value={!_.isEmpty(trigger.recipientType) ? trigger.recipientType : 'contact'}
                                                classNamePrefix={`basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                    {
                                        trigger.recipientType === 'custom' ? (<div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={trigger.triggerType === 'email' ? 'To Email' : 'phone'} /></label>
                                                {
                                                    trigger.triggerType === 'email' ? (
                                                        <InputMultipleText
                                                            placeholder={`to emails`}
                                                            className={`form-control react-multi-tag ${errorFields["recipientTo"]}`}
                                                            name="recipientTo"
                                                            onChange={(emails) => { handleOnChangeMultipleText(emails, 'recipientTo') }}
                                                            value={!_.isEmpty(trigger.recipientTo) ? trigger.recipientTo.split(',') : []}
                                                            validateEmail={true}
                                                            getLabel={(email, index) => {
                                                                return <div data-tag key={index}>
                                                                    {email}
                                                                    <span data-tag-handle onClick={() => removeEmail(index, 'recipientTo')}>
                                                                        ×
                                                                    </span>
                                                                </div>
                                                            }}
                                                        />
                                                    ) : (
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={`phone`}
                                                            className={`form-control ${errorFields["recipientTo"]}`}
                                                            name="recipientTo"
                                                            onChange={handleOnChange}
                                                            value={trigger.recipientTo}
                                                        />
                                                    )
                                                }

                                            </div>
                                        </div>) : trigger.recipientType === 'user' ? (<div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={trigger.triggerType === 'email' ? 'To Email' : 'phone'} /></label>
                                                <ReactMultiSelect
                                                    options={_.map(props.clientUsers.filter(item => trigger.triggerType === 'sms' ? !_.isEmpty(item.phone) : true), function (rec) {
                                                        return {
                                                            label: <div className="drop-image">
                                                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                                {rec.name}
                                                            </div>,
                                                            value: trigger.triggerType === 'email' ? rec.email : rec.phone,
                                                            searchlabel: rec.name,
                                                            active: _.isBoolean(rec.active) ? rec.active : true,
                                                        };
                                                    })}
                                                    name={"recipientTo"}
                                                    placeholder={'select user'}
                                                    onChange={e => handleReactMultiSelectChange(e, 'recipientTo')}
                                                    value={!_.isEmpty(trigger.recipientTo) ? trigger.recipientTo.split(',') : []}
                                                    classNamePrefix={`${errorFields["recipientTo"]} basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>) : (<></>)
                                    }
                                    {
                                        trigger.triggerType === 'email' ? (
                                            <>
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label> <Translate text={'Cc Emails To'} /></label>
                                                        <ReactSelect
                                                            options={replyTypes}
                                                            name={"ccType"}
                                                            onChange={handleSelectChange}
                                                            value={!_.isEmpty(trigger.ccType) ? trigger.ccType : ''}
                                                            classNamePrefix={`${errorFields["ccType"]} basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                                {trigger.ccType && trigger.ccType !== 'owner' && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label> <Translate text={'Cc Emails'} /></label>
                                                            <InputMultipleText
                                                                placeholder={`cc emails`}
                                                                className={`form-control react-multi-tag`}
                                                                name="mailCc"
                                                                onChange={(emails) => { handleOnChangeMultipleText(emails, 'mailCc') }}
                                                                value={!_.isEmpty(trigger.mailCc) ? trigger.mailCc.split(',') : []}
                                                                validateEmail={true}
                                                                getLabel={(email, index) => {
                                                                    return <div data-tag key={index}>
                                                                        {email}
                                                                        <span data-tag-handle onClick={() => removeEmail(index, 'mailCc')}>
                                                                            ×
                                                                        </span>
                                                                    </div>
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label> <Translate text={'Bcc Emails To'} /></label>
                                                        <ReactSelect
                                                            options={replyTypes}
                                                            name={"bccType"}
                                                            onChange={handleSelectChange}
                                                            value={!_.isEmpty(trigger.bccType) ? trigger.bccType : ''}
                                                            classNamePrefix={`${errorFields["bccType"]} basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                                {trigger.bccType && trigger.bccType !== 'owner' && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label> <Translate text={'Bcc Emails'} /></label>
                                                            <InputMultipleText
                                                                placeholder={`bcc emails`}
                                                                className={`form-control react-multi-tag`}
                                                                name="mailBcc"
                                                                onChange={(emails) => { handleOnChangeMultipleText(emails, 'mailBcc') }}
                                                                value={!_.isEmpty(trigger.mailBcc) ? trigger.mailBcc.split(',') : []}
                                                                validateEmail={true}
                                                                getLabel={(email, index) => {
                                                                    return <div data-tag key={index}>
                                                                        {email}
                                                                        <span data-tag-handle onClick={() => removeEmail(index, 'mailBcc')}>
                                                                            ×
                                                                        </span>
                                                                    </div>
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label> <Translate text={'Reply Type'} /></label>
                                                        <ReactSelect
                                                            options={replyTypes}
                                                            name={"replyType"}
                                                            onChange={handleSelectChange}
                                                            value={!_.isEmpty(trigger.replyType) ? trigger.replyType : ''}
                                                            classNamePrefix={`${errorFields["replyType"]} basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                                {trigger.replyType && trigger.replyType !== 'owner' && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label> <Translate text={'Reply To Email'} /></label>
                                                            <InputMultipleText
                                                                placeholder={`reply to emails`}
                                                                className={`form-control react-multi-tag`}
                                                                name="replyTo"
                                                                onChange={(emails) => { handleOnChangeMultipleText(emails, 'replyTo') }}
                                                                value={!_.isEmpty(trigger.replyTo) ? trigger.replyTo.split(',') : []}
                                                                validateEmail={true}
                                                                getLabel={(email, index) => {
                                                                    return <div data-tag key={index}>
                                                                        {email}
                                                                        <span data-tag-handle onClick={() => removeEmail(index, 'replyTo')}>
                                                                            ×
                                                                        </span>
                                                                    </div>
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </>

                                        ) : (<></>)
                                    }
                                </>
                            ) : (<>
                                {
                                    trigger.triggerType === 'activity' ? (
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'teammate'} /></label>
                                                <ReactMultiSelect
                                                    options={allTeamUsers}
                                                    name={'teammates'}
                                                    placeholder={'select teammate'}
                                                    onChange={handleTemmateChange}
                                                    value={trigger.teammates ? trigger.teammates : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    menuPlacement='bottom'
                                                    isGroup={true}
                                                ></ReactMultiSelect>
                                            </div>
                                        </div>) : (<></>)
                                }
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label> <Translate text={'user'} /></label>
                                        <ReactSelect
                                            options={ownerOptions.filter(a => trigger.category === 'enquiry' ? true : a.value !== 'financeBM' && a.value !== 'afterMarketBM')}
                                            name={"triggerFor"}
                                            onChange={handleSelectChange}
                                            value={!_.isEmpty(trigger.triggerFor) ? trigger.triggerFor : 'owner'}
                                            classNamePrefix={`basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            </>)
                        }
                        {
                            trigger.recurrence === true ? (
                                <>
                                    <div className="form-row">
                                        <div className={`form-group ${trigger.frequency === 'yearly' ? 'col-md-3' : 'col-md-4'}`}>
                                            <label>  <Translate text={'Frequency'} /></label>
                                            <ReactSelect
                                                options={recurringOptions}
                                                name={"frequency"}
                                                onChange={handleSelectChange}
                                                value={trigger.frequency}
                                                classNamePrefix={`${errorFields["frequency"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        {
                                            trigger.frequency === 'weekly' ? (
                                                <>
                                                    <div className="form-group col-md-4">
                                                        <label ><Translate text={'Every'} /></label>
                                                        <ReactMultiSelect
                                                            options={moment.weekdays().map(rec => {
                                                                return {
                                                                    label: rec,
                                                                    value: moment.weekdays().indexOf(rec).toString()
                                                                }
                                                            })}
                                                            name={"day"}
                                                            placeholder={'select day'}
                                                            onChange={(option) => handleReactMultiSelectChange(option, 'day')}
                                                            value={trigger.day ? trigger.day : []}
                                                            classNamePrefix={`${errorFields["day"]} cursor-pointer basic-select`}
                                                            removeClearable={false}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                    <div className={`form-group col-md-4`}>
                                                        <label ><Translate text={'Time'} /></label>
                                                        <div className="inner-addon right-addon">
                                                            <i className="bg-icons ico icon-time"></i>
                                                            <ReactTimePicker
                                                                value={trigger.schedulartime ? moment.unix(trigger.schedulartime.seconds)._d : null}
                                                                name={'schedulartime'}
                                                                onChange={handleHoursChange}
                                                                timeIntervals={30}
                                                                placeholder='h:mm aa'
                                                                className={`form-control ${errorFields["schedulartime"]}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : trigger.frequency === 'monthly' ? (
                                                <>
                                                    <div className="form-group col-md-4">
                                                        <label ><Translate text={'selectDate'} /></label>
                                                        <ReactSelect
                                                            options={dateOptions}
                                                            name={"date"}
                                                            placeholder={'select date'}
                                                            onChange={handleSelectChange}
                                                            value={trigger.date ? trigger.date : []}
                                                            classNamePrefix={`${errorFields["date"]} cursor-pointer basic-select`}
                                                            removeClearable={false}
                                                            isSearchable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className={`form-group col-md-4`}>
                                                        <label ><Translate text={'Time'} /></label>
                                                        <div className="inner-addon right-addon">
                                                            <i className="bg-icons ico icon-time"></i>
                                                            <ReactTimePicker
                                                                value={trigger.schedulartime ? moment.unix(trigger.schedulartime.seconds)._d : null}
                                                                name={'schedulartime'}
                                                                onChange={handleHoursChange}
                                                                timeIntervals={30}
                                                                placeholder='h:mm aa'
                                                                className={`form-control ${errorFields["schedulartime"]}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : trigger.frequency === 'yearly' ? (<>
                                                <>
                                                    <div className="form-group col-md-3">
                                                        <label ><Translate text={'month'} /></label>
                                                        <ReactSelect
                                                            options={Array.apply(0, Array(12))
                                                                .map(function (_, i) { return moment().month(i).format('MMMM') })
                                                                .map(r => {
                                                                    return {
                                                                        label: r,
                                                                        value: `${moment(`${r} ${moment().year()}`).month() + 1}`
                                                                    }
                                                                })}
                                                            name={"month"}
                                                            placeholder={'select month'}
                                                            onChange={handleSelectChange}
                                                            value={trigger.month}
                                                            classNamePrefix={`${errorFields["month"]} cursor-pointer basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className="form-group col-md-3">

                                                        <label ><Translate text={'selectDate'} /></label>
                                                        <ReactSelect
                                                            options={trigger.month ? Array.from(Array(moment(`${moment().year()}-${trigger.month}`).daysInMonth()).keys())
                                                                .map(r => {
                                                                    return {
                                                                        label: `${r + 1}`,
                                                                        value: `${r + 1}`
                                                                    }
                                                                }) : []}
                                                            name={"date"}
                                                            placeholder={'select date'}
                                                            onChange={handleSelectChange}
                                                            value={trigger.date ? trigger.date : []}
                                                            classNamePrefix={`${errorFields["date"]} cursor-pointer basic-select`}
                                                            removeClearable={false}
                                                            isSearchable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className={`form-group col-md-3`}>
                                                        <label ><Translate text={'Time'} /></label>
                                                        <div className="inner-addon right-addon">
                                                            <i className="bg-icons ico icon-time"></i>
                                                            <ReactTimePicker
                                                                value={trigger.schedulartime ? moment.unix(trigger.schedulartime.seconds)._d : null}
                                                                name={'schedulartime'}
                                                                onChange={handleHoursChange}
                                                                timeIntervals={30}
                                                                placeholder='h:mm aa'
                                                                className={`form-control ${errorFields["schedulartime"]}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            </>) : (<></>)
                                        }
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>  <Translate text={'Date Field'} /></label>
                                            <ReactSelect
                                                options={triggerDateOptions.filter(a => a.category === trigger.category)}
                                                name={"fieldName"}
                                                onChange={handleSelectChange}
                                                value={trigger.fieldName}
                                                classNamePrefix={`${errorFields["fieldName"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label><Translate text={'No of Days'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={`no. of days`}
                                                className={`form-control ${errorFields["fieldValue"]}`}
                                                name="fieldValue"
                                                onChange={handleNumberChange}
                                                value={trigger.fieldValue}
                                                removeClearable={true}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                                : (<>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label>  <Translate text={'Frequency'} /></label>
                                            <ReactSelect
                                                options={trigger.status === 'contactBirthday' ? tiggerFrequency.filter(e => e.value === 'birthday') : tiggerFrequency.filter(e => e.value !== 'birthday' && (trigger.status === 'enquiryUpdate' ? true : e.value !== 'customTime'))}
                                                name={"period"}
                                                onChange={handleSelectChange}
                                                value={trigger.period}
                                                classNamePrefix={`basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        {
                                            (trigger.period === 'iteration' || trigger.period === 'birthday') ? (
                                                <div className="form-group col-md-12">
                                                    <label> <Translate text={'Frequency Value'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'number of occurrences'}
                                                        className={`form-control ${errorFields["periodValue"]}`}
                                                        name="periodValue"
                                                        onChange={handleNumberChange}
                                                        value={trigger.periodValue}
                                                        removeClearable={true}
                                                    />
                                                </div>
                                            ) : trigger.period === 'customTime' ? (
                                                <div className="form-group col-md-12">
                                                    <label> <Translate text={'Custom Time'} /></label>
                                                    <div className="inner-addon right-addon">
                                                        <i className="bg-icons ico icon-time"></i>
                                                        <ReactTimePicker
                                                            value={trigger.customTime ? moment(`${moment().format('YYYY-MM-DD')} ${trigger.customTime}`)._d : null}
                                                            name={'customTime'}
                                                            onChange={handleHoursChange}
                                                            timeIntervals={30}
                                                            placeholder='h:mm aa'
                                                            className={`form-control ${errorFields["customTime"]}`}
                                                        />
                                                    </div>
                                                </div>
                                            ) : trigger.period !== 'instant' ? (<>
                                                <div className="form-group col-md-6">
                                                    <label> <Translate text={'Frequency Value'} /> </label>
                                                    <ReactSelect
                                                        options={frequencyOptions}
                                                        name={"frequencyValue"}
                                                        onChange={(e, date) => {
                                                            props.setFrequencyValue(e.value)
                                                        }}
                                                        value={props.frequencyValue}
                                                        classNamePrefix={`basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className={`form-group col-md-${trigger.period === 'afterDays' ? '3' : '6'}`}>
                                                    <label>&nbsp;</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={`no. of ${trigger.period === 'afterMins' ? 'mins' : trigger.period === 'afterHours' ? 'hours' : 'days'}`}
                                                        className={`form-control ${errorFields["periodValue"]}`}
                                                        name="periodValue"
                                                        onChange={handleNumberChange}
                                                        value={trigger.periodValue}
                                                        removeClearable={true}
                                                    />
                                                </div>
                                                {
                                                    trigger.period === 'afterDays' ? (
                                                        <div className="form-group col-md-3">
                                                            <label> <Translate text={'Custom Time'} /></label>
                                                            <div className="inner-addon right-addon">
                                                                <i className="bg-icons ico icon-time"></i>
                                                                <ReactTimePicker
                                                                    value={trigger.customTime ? moment(`${moment().format('YYYY-MM-DD')} ${trigger.customTime}`)._d : null}
                                                                    name={'customTime'}
                                                                    onChange={handleHoursChange}
                                                                    timeIntervals={30}
                                                                    placeholder='h:mm aa'
                                                                    className={`form-control ${errorFields["customTime"]}`}
                                                                />
                                                            </div>
                                                        </div>) : (<></>)
                                                }
                                            </>) : (<></>)
                                        }

                                    </div>
                                    {
                                        trigger.period === 'iteration' ? (
                                            <>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label> <Translate text={'Repeat Every'} /></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={`repeat every`}
                                                            className={`form-control ${errorFields["periodEvery"]}`}
                                                            name="periodEvery"
                                                            onChange={handleNumberChange}
                                                            value={trigger.periodEvery}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>&nbsp;</label>
                                                        <ReactSelect
                                                            options={priodEveryTypes}
                                                            placeholder={`select day/month/year`}
                                                            name={"periodEveryType"}
                                                            onChange={handleSelectChange}
                                                            value={trigger.periodEveryType}
                                                            classNamePrefix={`${errorFields["periodEveryType"]} basic-select`}
                                                            removeClearable={false}
                                                        >
                                                        </ReactSelect>
                                                    </div>

                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label> <Translate text={`Start After`} /> {_.find(alleventTypes, { status: trigger.status }) && !_.isEmpty(_.find(alleventTypes, { status: trigger.status }).label) ? _.find(alleventTypes, { status: trigger.status }).label : ''}</label>
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={`start after ${_.find(alleventTypes, { status: trigger.status }) && !_.isEmpty(_.find(alleventTypes, { status: trigger.status }).label) ? _.find(alleventTypes, { status: trigger.status }).label.toLocaleLowerCase() : ''}`}
                                                            className={`form-control`}
                                                            name="startAfter"
                                                            onChange={handleNumberChange}
                                                            value={trigger.startAfter}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>&nbsp;</label>
                                                        <ReactSelect
                                                            options={priodEveryTypes}
                                                            placeholder={`select day/month/year`}
                                                            name={"startAfterType"}
                                                            onChange={handleSelectChange}
                                                            value={trigger.startAfterType}
                                                            classNamePrefix={`basic-select`}
                                                            removeClearable={false}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (<></>)
                                    }
                                </>)
                        }
                        {
                            (trigger.category === 'enquiry'
                                && trigger.status === 'enquiryUpdate') ? (
                                <div className="form-row">
                                    <div className={`form-group col-md-12`}>
                                        <label> <Translate text={'Date From'} /></label>
                                        <ReactSelect
                                            options={[
                                                ...enquiryDates,
                                                ...enquiryOptionsDF.filter(a => a.active && a.type === 'date').map(r => { return { active: true, label: r.name, value: `dynamicFields.${r.value}` } })
                                            ]}
                                            name={"startFromField"}
                                            placeholder={'select date'}
                                            onChange={handleSelectChange}
                                            value={trigger.startFromField}
                                            classNamePrefix={`${errorFields["startFromField"]} cursor-pointer basic-select `}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                        {
                            (trigger.category === 'activity'
                                && trigger.status.includes('Scheduled')) ? (
                                <div className="form-row">
                                    <div className={`form-group col-md-12`}>
                                        <label> <Translate text={'Date From'} /></label>
                                        <ReactSelect
                                            options={activityDates}
                                            name={"startFromField"}
                                            placeholder={'select date'}
                                            onChange={handleSelectChange}
                                            value={trigger.startFromField}
                                            classNamePrefix={`${errorFields["startFromField"]} cursor-pointer basic-select `}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                        <div className="form-row">
                            <div className={`form-group col-md-12`}>
                                <label> <Translate text={'Level'} /></label>
                                <ReactSelect
                                    options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                    name={"level"}
                                    placeholder={'select level'}
                                    onChange={handlesetLevel}
                                    value={trigger.level}
                                    classNamePrefix={`${errorFields["level"]} cursor-pointer basic-select `}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            {
                                (!_.isEmpty(trigger.level) && trigger.level !== 'oem') ?
                                    (<div className={`form-group col-md-12`}>
                                        <label> <Translate text={trigger.level === 'group' ? 'Group' : trigger.level === 'region' ? 'Region' : 'Client'} /></label>
                                        <ReactMultiSelect
                                            options={trigger.level === 'group' ? props.groupOptions : trigger.level === 'region' ? props.regionOptions : props.clientOptions}
                                            name={'subLevelIDs'}
                                            placeholder={`select ${trigger.level === 'individual' ? 'client' : trigger.level}`}
                                            onChange={e => handleReactMultiSelectChange(e, 'subLevelIDs')}
                                            value={trigger.subLevelIDs ? _.isArray(trigger.subLevelIDs) ? trigger.subLevelIDs : [trigger.subLevelIDs] : []}
                                            classNamePrefix={`${errorFields["subLevelIDs"]} basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>) : (<></>)
                            }
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label> <Translate text={'notes'} /></label>
                                <textarea className="form-control notes" name='notes' value={trigger.notes} rows="3" placeholder="activity notes..." onChange={handleOnAreaChange}></textarea>
                            </div>
                        </div>
                        <div className="trigger-form-footer">
                            <span className="switch switch-sm float-left">
                                <InputCheckBox
                                    className={`switch`}
                                    id={`trigger-active`}
                                    name={'isActive'}
                                    checked={trigger.isActive}
                                    onChange={(e) => { handleCheckedChange(e) }} />
                                <label htmlFor={`trigger-active`}><Translate text={'active'} /></label>
                            </span>
                            {
                                trigger.status === 'enquiryUpdate' ? (
                                    <span className="switch switch-sm float-left">
                                        <InputCheckBox
                                            className={`switch`}
                                            id={`trigger-multiple`}
                                            name={'multiple'}
                                            checked={trigger.multiple}
                                            onChange={(e) => { handleCheckedChange(e) }} />
                                        <label htmlFor={`trigger-multiple`}><Translate text={'Trigger on Every Update'} /></label>
                                    </span>
                                ) : (<></>)
                            }
                            {(trigger.triggerType === 'sms' || trigger.triggerType === 'email') && (
                                <span className="switch switch-sm float-left">
                                    <InputCheckBox
                                        className={`switch`}
                                        id={`trigger-feedback`}
                                        name={'triggerFeedback'}
                                        checked={trigger.triggerFeedback}
                                        onChange={(e) => { handleCheckedChange(e) }} />
                                    <label htmlFor={`trigger-feedback`}><Translate text={'Trigger Feedback'} /></label>
                                </span>
                            )}
                            <button type="button" className="btn btn-primary float-right ml-2" onClick={() => { saveTrigger() }}>
                                {loader ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'save'} />
                            </button>
                            <button type="button" className="btn btn-default float-right" onClick={() => { props.handleClose() }} > <Translate text={'cancel'} /></button>
                        </div>
                    </div>
                </div>
            </div >
            <PopUpModal show={showPreviewModel}>
                <TemplatePreview
                    {...props}
                    show={showPreviewModel}
                    title={popHeader}
                    handleClose={handlePreviewModelClose}
                    template={template}
                />
            </PopUpModal>
        </>
    );
}

export default TriggerType;