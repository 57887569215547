import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import MentionEditor from '../../../../components/mentionEditor/mentionEditor';
import { InputCheckBox, InputText, ReactMultiSelect } from '../../../../components'
import ReactSummernote from 'react-summernote';
import SummerEditor from '../../../mail/summereditor/summereditor';
import _ from 'lodash';
import { mentions } from './viewmodel';
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import LeadAlloction from './leadAllocation';


const AlertTabs = (props) => {
    const [email, setEmail] = useState(props.email)

    useEffect(() => {
        if (props.activeTab !== 'unactionedLeadsTIP') {
            var node = document.createElement('div');
            node.innerHTML = `${props.email}`;
            ReactSummernote.insertNode(node);
            setEmail(`${props.email}`)
        }
    }, [])

    const convertTemplateJSON = (templateJSON) => {
        // const _templateJSON = JSON.stringify(obj.rawData)
        // const textMessage = obj.value.replace(/<[^>]*>?/gm, '')
        let data = JSON.parse(templateJSON);
        let text = '';
        if (!_.isEmpty(data.blocks)) {
            data.blocks.forEach(ele => {
                text += ele.text + ' ';
            });
        }
        if (text) {
            mentions.filter(a => a.dynamic === true).forEach(rec => {
                if (text.includes(`${rec.name}`)) {
                    text = text.replace(new RegExp(`${rec.name}`, 'g'), rec.id);
                }
            })
        }
        return text
    }

    return (
        <>
            {
                props.activeTab === 'unactionedLeadsTIP' ? (
                    <LeadAlloction {...props} />
                ) : (<>
                    <div className="settings-tab-wrap tabs">
                        <div className="tabs-wraper">
                            <nav>
                                <Tabs defaultActiveKey={"email"} transition={false} className="nav-fill">
                                    <Tab eventKey={"email"} title={CommonHelper.showLocale(props, "Email Template")}>
                                        <div className='form-row'>
                                            <div className='form-group col-md-1'>
                                                <label className='mt-1'><b> <Translate text={'Subject'} /> :</b> </label>
                                            </div>
                                            <div className='form-group  col-md-11'>
                                                <InputText
                                                    autoComplete='off'
                                                    placeholder={'subject'}
                                                    className={`form-control`}
                                                    name='subject'
                                                    onChange={props.handleOnChange}
                                                    value={props.data.subject}
                                                    maxLength={250}
                                                />
                                            </div>
                                            <div className="form-group col-md-12 mt-2">
                                                <SummerEditor
                                                    name={props.key}
                                                    value={email}
                                                    height={'350px'}
                                                    handleChange={props.handleChange}
                                                    emailType={'compose'}
                                                    templateMentionsEnabled={true}
                                                    otherMentions={mentions}
                                                    customFonts={props?.dealersettings?.customFonts || []}
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey={"sms"} title={CommonHelper.showLocale(props, "SMS Template")}>
                                        <div className="form-group">
                                            <MentionEditor
                                                mentionsData={mentions.map(mention => {
                                                    return {
                                                        name: `${mention.value}`,
                                                        id: mention.value,
                                                        title: `${mention.name}`,
                                                    };
                                                })}
                                                onMentionChange={props.onMentionChange}
                                                onMentionSelect={props.onMentionSelect}
                                                notes={props.data.smsTemplate && _.isEmpty(props.data.templateJSON) ? props.data.smsTemplate : !_.isEmpty(props.data.templateJSON) ? convertTemplateJSON(props.data.templateJSON) : ''}
                                                focusOnMount={false}
                                                isNoteEmpty={false}
                                                teamMentions={null}
                                                clearTeamMentions={() => { }}
                                                clientUsers={[]}
                                                ownerID={''}
                                                showCustomSuggestions={true}
                                                height={'400px'}
                                            />
                                        </div>
                                    </Tab>
                                </Tabs>
                            </nav>
                        </div>
                    </div>
                </>)
            }
        </>
    );
}

export default AlertTabs;