import React, { useEffect, useState } from 'react';
// import { uniqBy } from 'lodash';
// import Swal from 'sweetalert2';
import CommonHelper from '../../../../services/common';
import _ from 'lodash';
import { auditlogsfieldsVM, typeOptions } from '../viewModel';
import { TableView, ContentOverlay, ReactSelect } from '../../../../components';
import moment from 'moment'

const AuditLogs = props => {
	const [auditLogs, setAuditLogs] = useState(null);
	const [searchText, setSearchText] = useState('');
	const [selectedClient, setSelectedClient] = useState([])
	const [selectedUser, setSelectedUser] = useState([])
	const [type, setType] = useState([])
	const [clientUsers, setUsersData] = useState([])
	const [loader, setLoader] = useState(true)
	const [clients, setClients] = useState([])
	const [userOptions, setUserOptions] = useState([])
	const [hasMore, setHasMoreData] = useState([])
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 485,
		windowHeight: window.innerHeight - 313,
	});
	const auditLogsRef = window.firebase
		.firestore()
		.collection(`auditLogs`)

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 485,
				windowHeight: window.innerHeight - 313,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

	useEffect(() => {
		// if (loader ===  false) {
		// 	return
		// }

		let ref
		if (!_.isEmpty(selectedClient) && _.isEmpty(selectedUser)) {
			// console.log('client selected, no user')
			ref = auditLogsRef.where('clientID', '==', selectedClient.documentID)
		} else if (!_.isEmpty(selectedClient) && !_.isEmpty(selectedUser)) {
			// console.log('client selected, user selected')
			ref = auditLogsRef
				.where('clientID', '==', selectedClient.documentID)
				.where('logBy', '==', selectedUser.value)
		} else if (_.isEmpty(selectedClient) && _.isEmpty(selectedUser)) {
			// console.log('no client, no user')
			ref = auditLogsRef.where('clientID', '==', props.dealersettings.client.id)
		} else if (_.isEmpty(selectedClient) && !_.isEmpty(selectedUser)) {
			// console.log('no client, user selected')
			ref = auditLogsRef
				.where('clientID', '==', props.dealersettings.client.id)
				.where('logBy', '==', selectedUser.value)
		}

		if (!_.isEmpty(type)) {
			ref = ref
				.where('type', '==', type.value)
		}

		ref = ref.orderBy('logDate', 'desc').limit(100)

		window.auditLogsSnapshot = ref.onSnapshot(async auditLogSnap => {
			// console.log('auditLogSnap', auditLogSnap);
			let data = [];
			let users = []
			let usersData = []
			if (auditLogSnap.empty) {
				setAuditLogs([]);
				setHasMoreData([])
				setLoader(false)
				return;
			}
			auditLogSnap.forEach(doc => {
				let docData = {
					...doc.data(),
					documentID: doc.id,
				};

				if (!docData.logBy || !docData.clientID || !docData.logDate) {
					return;
				}

				if (docData.logBy) {
					users.push(docData.logBy)
				}

				if (docData.logDate) {
					data.push(docData);
				}
			});
			users = _.uniq(users)
			// console.log('users', users);

			const promises = []
			users.forEach(el => {
				promises.push(window.firebase.firestore().doc(`users/${el}`).get())
			});
			const snapshots = await Promise.all(promises)
			snapshots.forEach(snap => {
				if (snap.exists) {
					usersData.push(snap.data())
				}
			})
			// console.log('usersData', usersData)
			data = data.map(_data => convertVM(_data, usersData))
			data = data.filter(_data => (_data.userName && !_data.userName.toLowerCase() === 'admin') || !Boolean(_data.superAdmin))

			setAuditLogs(data);
			setUsersData(usersData)
			setHasMoreData([auditLogSnap.docs[auditLogSnap.docs.length - 1]])
			setLoader(false)
		});

		return () => {
			window.auditLogsSnapshot && window.auditLogsSnapshot();
		};
	}, [loader]);

	useEffect(() => {
		if (_.isEmpty(props.dealersettings) || localStorage.defaultModule === 'oem')
			return

		// const clientSnapshot = window.firebase.firestore().collection(`clients`)
		//     .where('documentID', 'in', props.dealersettings.clients.map(v => _.pick(v, ['id']).id))
		//     .onSnapshot(querySnapshot => {
		let _clients = [];
		props.dealersettings.clients.forEach(rec => {
			_clients.push({
				label: rec.name,
				value: rec.id,
				data: rec,
				active: true,
			})
		})
		setSelectedClient(_clients.filter(a => a.value === props.dealersettings.client.id)[0].data)
		setClients(_clients)
		// setLoader(false)
		//     })
		// return () => {
		//     clientSnapshot && clientSnapshot()
		// }

	}, [props])

	useEffect(() => {
		if (_.isEmpty(props.clientUsers))
			return
		let _users = [];
		props.clientUsers.filter(user => !Boolean(user.superAdmin)).forEach(rec => {
			_users.push({
				value: rec.id,
				active: _.isBoolean(rec.active) ? rec.active : true,
				searchlabel: rec.name,
				label: <div className="drop-image">
					<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
					{rec.name}
				</div>,
				data: rec
			})
		})
		setUserOptions(_users)
	}, [props.clientUsers])

	const convertVM = (doc, usersData) => {
		const objLog = { ...doc }
		const currentUser = usersData.filter(a => a.documentID === objLog.logBy)[0]

		objLog.email = currentUser && currentUser.email ? currentUser.email : '';
		objLog.modifiedDate = objLog.modifiedDate ? objLog.modifiedDate.toDate() : '';
		objLog.addedDate = objLog.addedDate ? objLog.addedDate.toDate() : '';
		objLog.logDate = objLog.logDate ? objLog.logDate.toDate() : '';
		objLog.userName = currentUser && currentUser.name ? currentUser.name : CommonHelper.getUserNamebyId(
			props.clientUsers,
			objLog.logBy,
		);
		objLog.platform = objLog.platform ? objLog.platform : objLog.plateform ? objLog.plateform : ''
		objLog.subtype = objLog.subtype ? objLog.subtype : objLog.subType ? objLog.subType : ''
		objLog.platform =
			objLog.platform === 'ios' ? 'iOS' :
				objLog.platform === 'android' ? 'Android' :
					objLog.platform === 'web' ? 'Web' : ''

		if (objLog.forceLogoutBy && objLog.notes === 'Forced Logout by ') {
			let forceLogoutUser = usersData.filter(a => a.documentID === objLog.forceLogoutBy)[0]
			forceLogoutUser = forceLogoutUser && forceLogoutUser.name ? forceLogoutUser.name : CommonHelper.getUserNamebyId(
				props.clientUsers,
				objLog.forceLogoutBy,
			)
			objLog.notes = `${objLog.notes}${forceLogoutUser}`
		}

		objLog.notes =
			objLog.notes ? objLog.notes :
				objLog.type === 'authentication' && objLog.subtype === 'login' ? 'User Logged In' :
					objLog.type === 'authentication' && objLog.subtype === 'logout' ? 'User Logged Out' :
						objLog.type === 'screenshot' ? 'User took screenshot' :
							''
		objLog.strevent = `${objLog.notes}`;
		objLog.strlogDate = `${moment(objLog.logDate).format('DD/MM/YYYY hh:mm A')}`;

		objLog._user = (
			<div className='security-userthumb'>
				<div className='security-userthumbimage'>
					{' '}
					<img
						src={currentUser && currentUser.profileImage ? currentUser.profileImage : CommonHelper.getUserImagebyId(
							props.clientUsers,
							objLog.logBy,
						)}
						alt=''
						className='rounded-circle img-object-fit'
					/>{' '}
				</div>
				<div className='security-userthumb-info'>
					{' '}
					{objLog.userName}
				</div>
			</div>
		)

		objLog._event = (
			<div className="securitylist-items-info ">
				<h4>{objLog.notes} </h4>
				<h5>{objLog.platform}</h5>
			</div>
		)

		objLog._type = objLog.type === 'authentication' ? 'Authentication' :
			objLog.type === 'excelExport' ? 'Excel Export' :
				objLog.type === 'files' ? 'Files' :
					objLog.type === 'licenseImage' ? 'License Image' :
						objLog.type === 'triggers' ? 'Triggers' :
							objLog.type === 'dynamicFields' ? 'Dynamic Fields' :
								objLog.type === 'activities' ? 'Activities' :
									objLog.type === 'companies' ? 'Companies' :
										objLog.type === 'quotations' ? 'Quotations' :
											objLog.type === 'enquiries' ? 'Enquiries' :
												objLog.type === 'stock' ? 'Stock' :
													objLog.type === 'videos' ? 'Videos' :
														objLog.type === 'inboundLeads' ? 'InboundLeads' :
															objLog.type === 'screenshot' ? 'Screenshot' : ''

		objLog._logDate = (
			<div className='securitylist-items-info '>
				<h4>
					{`${moment(objLog.logDate).format('DD/MM/YYYY hh:mm A')}`}{' '}
				</h4>
			</div>
		)

		objLog._action = objLog.imageURL && (objLog.type === 'licenseImage' || objLog.type === 'screenshot') ? (
			<a className='btn-common float-left font-600' data-fancybox={`LicenseImageBackURL${Math.floor(Math.random() * 10000)}`} href={objLog.imageURL} onClick={(e) => { e.preventDefault(); }}>
				<i className="ico icon-zoom mr-2"></i> View
			</a>
		) : (<></>)

		return objLog
	}

	const filterBySearchText = device => {
		let returnVal = false;

		if (!searchText.length) {
			return true;
		}

		if (device.userName && device.userName.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		if (device.notes && device.notes.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		if (device.platform && device.platform.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		if (device._type && device._type.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		return returnVal;
	};

	const handleRowSingleClick = (e, id, obj) => {
		// console.log(`e, id, obj`, e, id, obj)
	}

	const fetchMoreData = async () => {
		if (_.isEmpty(hasMore)) {
			return
		}
		let ref
		if (!_.isEmpty(selectedClient) && _.isEmpty(selectedUser)) {
			ref = auditLogsRef.where('clientID', '==', selectedClient.documentID)
		} else if (!_.isEmpty(selectedClient) && !_.isEmpty(selectedUser)) {
			ref = auditLogsRef
				.where('clientID', '==', selectedClient.documentID)
				.where('logBy', '==', selectedUser.value)
		} else if (_.isEmpty(selectedClient) && _.isEmpty(selectedUser)) {
			ref = auditLogsRef.where('clientID', '==', props.dealersettings.client.id)
		} else if (_.isEmpty(selectedClient) && !_.isEmpty(selectedUser)) {
			ref = auditLogsRef
				.where('clientID', '==', props.dealersettings.client.id)
				.where('logBy', '==', selectedUser.value)
		}

		ref = ref.orderBy('logDate', 'desc').startAfter(hasMore[0]).limit(60)


		ref.get().then(async auditLogSnap => {
			let data = [];
			if (auditLogSnap.empty) {
				return;
			}
			// console.log('auditLogSnap', auditLogSnap);
			auditLogSnap.forEach(doc => {
				let docData = {
					...doc.data(),
					documentID: doc.id,
				};

				if (!docData.logBy || !docData.clientID || !docData.logDate) {
					return;
				}

				if (docData.logDate) {
					data.push(docData);
				}
			});

			// console.log('data', data);
			data = data.map(_data => convertVM(_data, clientUsers))
			data = data.filter(_data => !Boolean(_data.superAdmin))
			setAuditLogs([...auditLogs, ...data]);
			setHasMoreData([auditLogSnap.docs[auditLogSnap.docs.length - 1]])
		});
		// let recipientsToAdd = []
		// let ref = window.firebase.firestore().collection('smsCampaigns').doc(campaignID).collection('smsRecipients')
		// const snapshots = await ref.get()
		// if (!snapshots.empty) {
		// 	snapshots.forEach((doc) => {
		// 		recipientsToAdd.push(doc.data())
		// 	});
		// 	setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
		// 	// recipientsToAdd = recipientsToAdd.filter(a => data.recipientIDs.some(id => id === a.documentID))
		// 	setRecipients(_.uniqBy([...recipients, ...recipientsToAdd], 'phone'))
		// }	
	}

	return (
		<>
			{/* <p className='mb-3'>
				Below are all the devices that are logged into this dealer. Take a quick
				look below and if you do not recognise any of the devices, we recommend
				logging the device out and changing the password to something more
				secure.
			</p> */}
			<div className='settings-head'>
				<div className='float-left'>
					<div className='filter-search search-icon'>
						<div className='input-group template-search'>
							<input type='text' className='form-control' placeholder='Search' value={searchText} onChange={e => setSearchText(e.target.value)} style={{ width: '100%' }} />
							<div className='input-group-append input-search-clear'>
								{searchText.length > 0 && (
									<a href='#' className='input-search-clear-icon' style={{ top: '9px' }} onClick={() => setSearchText('')}>
										<i className='ico icon-remove'></i>
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="float-right" style={{ width: '180px' }}>
					<ReactSelect
						options={clients}
						name={"client"}
						placeholder={'select client'}
						onChange={(e) => {
							setLoader(true)
							setHasMoreData([])
							setSelectedClient(e.data)
						}}
						value={!_.isEmpty(selectedClient) ? selectedClient.documentID : ''}
						classNamePrefix={`basic-select`}
						removeClearable={true}
					>
					</ReactSelect>
				</div>
				<div className="float-right mr-2" style={{ width: '180px' }}>
					<ReactSelect
						options={userOptions}
						name={"user"}
						placeholder={'select user'}
						onChange={(e, data) => {
							setLoader(true)
							setHasMoreData([])
							setSelectedUser(e)
						}}
						value={!_.isEmpty(selectedUser) ? selectedUser.value : ''}
						classNamePrefix={`basic-select`}
						removeClearable={false}
					>
					</ReactSelect>
				</div>
				<div className="float-right mr-2" style={{ width: '180px' }}>
					<ReactSelect
						options={_.orderBy(typeOptions, ["value"], ["asc"])}
						name={"user"}
						placeholder={'select type'}
						onChange={(e, data) => {
							setLoader(true)
							setHasMoreData([])
							setType(e)
						}}
						value={!_.isEmpty(type) ? type.value : ''}
						classNamePrefix={`basic-select`}
						removeClearable={false}
					>
					</ReactSelect>
				</div>
				{/* <div className="float-right">
        <div className="enquiry-view-more float-right mt-0 dropdown"><a href="#" className="common-button dropdown-toggle"><i className="ico icon-more"></i></a></div>
        <div className="advanced-filter-btn float-right " id="dropdown03" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <a href="#"> <span className="badge badge-pill badge-advanced-filter">2</span> <i className="ico icon-filter"></i> Filter </a> <a href="#" className="clear-btn"> <i className="ico icon-remove"></i> </a> </div>
      </div> */}
			</div>
			<div className='security-inner-section'>
				{loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
					<div className='common-table'>
						<TableView
							isReorderable={false}
							datalist={auditLogs ? auditLogs.filter(device => filterBySearchText(device)) : []}
							height={windowSize.windowHeight}
							width={windowSize.windowWidth}
							columns={auditlogsfieldsVM}
							dynamicFields={auditlogsfieldsVM
								.filter(e => e.default === true)
								.map(v => _.pick(v, ['value']).value)}
							settingsLoader={false}
							// handleActionClick={handleActionClick}
							handleRowSingleClick={handleRowSingleClick}
							isSettings={false}
							hasMore={hasMore}
							isPaging={true}
							handlePagination={fetchMoreData}
							isSorting={true}
							isTranslate={true}
							dealersettings={props.dealersettings}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default AuditLogs;
