import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { ReactMultiSelect, ReactSelect, ReactTimePicker } from '../../components';
import { default as _images } from "../../images";
import { frequencyOptions, dataOptions } from "./viewModel";
import moment from 'moment'
import Swal from 'sweetalert2'
import CommonHelper from '../../services/common';

const ScheduleMultiple = (props) => {
    const [report, setReport] = useState(props.report);
    const [errorFields, setErrorFields] = useState({});
    const [dateOptions, setDateOptions] = useState([{ label: 'First day of month', value: '1' }, { label: 'Last day of month', value: 'L' }])

    useEffect(() => {
        if (_.isEmpty(report))
            return

        let cron = ['*', '*', '*', '*', '*']
        if (report.schedulartime) {
            cron[0] = moment.unix(report.schedulartime.seconds).format('m');
            cron[1] = moment.unix(report.schedulartime.seconds).format('H');
        }
        if (report.day) {
            cron[4] = report.day;
            cron[2] = '*';
        }
        if (report.date) {
            cron[2] = report.date;
            cron[4] = '*';
        }
        props.updateReport({
            ...report,
            cron: cron.join(' ')
        });
    }, [report])

    useEffect(() => {
        setErrorFields(props.errorFields)
    }, [props.errorFields])

    const handleSelectChange = (e, data) => {
        if (data.name === 'frequency')
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['day']: '',
                ['date']: ''
            })
        else
            setReport({
                ...report,
                [data.name]: e ? e.value : ''
            })


        setErrorFields({
            ...errorFields,
            [`${report.documentID}-${data.name}`]: ''
        });
    }

    const handleHoursChange = (val, name) => {
        setReport({
            ...report,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });
    }


    return _.isEmpty(report) ? (<></>) : (<>

        <fieldset className="reportscheduler-multi-border">
            <div className="reportscheduler-multi-close"><a href="#" onClick={(e) => {
                e.preventDefault();
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'Do you want to delete this?',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        props.deleteScheduler(report.documentID)
                    }
                })
            }}><i className="ico icon-remove"></i> </a></div>
            <legend className="reportscheduler-multi-border">Schedule {CommonHelper.autoCaps(report.frequency)}</legend>
            <div className="form-row">
                {
                    (Boolean(report.static) && report?.fileType !== 'sms') ? (<></>) : (
                        <div className={`form-group col-md-12`}>
                            <label >Report Data</label>
                            <ReactSelect
                                options={dataOptions.filter(a => props?.report?.columns?.some(a => a.includes('Scheduled')) ? true : a.value !== 'upcoming')}
                                name={"dataParam"}
                                placeholder={'select data option'}
                                onChange={handleSelectChange}
                                value={_.isEmpty(report.dataParam) ? 'MTD' : report.dataParam}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={true}
                            >
                            </ReactSelect>
                        </div>
                    )
                }
                {
                    report.dataParam === 'upcoming' ? (

                        <div className={`form-group col-md-12`}>
                            <label >Select Days</label>
                            <ReactMultiSelect
                                options={moment.weekdays().map(rec => {
                                    return {
                                        label: rec,
                                        value: moment.weekdays().indexOf(rec).toString()
                                    }
                                })}
                                name={'upcomingDays'}
                                placeholder={'select days'}
                                onChange={(selectedOptions) => {
                                    const value = [];
                                    !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                        value.push(data.value)
                                    })
                                    setReport({
                                        ...report,
                                        ['upcomingDays']: value
                                    })
                                }}
                                value={report?.upcomingDays ? report?.upcomingDays : []}
                                classNamePrefix={`${errorFields[`${report.documentID}-upcomingDays`]} basic-select`}
                                isMulti={true}
                                isSearchable={true}
                            >
                            </ReactMultiSelect>
                        </div>
                    ) : (<></>)
                }

                <div className={`form-group ${report.frequency === 'daily' || _.isEmpty(report.frequency) ? 'col-md-6' : 'col-md-4'}`}>
                    <label >Frequency</label>
                    <ReactSelect
                        options={frequencyOptions}
                        name={"frequency"}
                        placeholder={'select frequency'}
                        onChange={handleSelectChange}
                        value={report.frequency}
                        classNamePrefix={`${errorFields[`${report.documentID}-frequency`]} cursor-pointer basic-select`}
                        removeClearable={true}
                    >
                    </ReactSelect>
                </div>
                {
                    report.frequency === 'weekly' ? (
                        <div className="form-group col-md-4">
                            <label >Select Day</label>
                            <ReactSelect
                                options={moment.weekdays().map(rec => {
                                    return {
                                        label: rec,
                                        value: moment.weekdays().indexOf(rec).toString()
                                    }
                                })}
                                name={"day"}
                                placeholder={'select day'}
                                onChange={handleSelectChange}
                                value={report.day}
                                classNamePrefix={`${errorFields[`${report.documentID}-day`]} cursor-pointer basic-select`}
                                removeClearable={true}
                            >
                            </ReactSelect>
                        </div>
                    ) : report.frequency === 'monthly' ? (
                        <div className="form-group col-md-4">
                            <label >Select Date</label>
                            <ReactSelect
                                options={dateOptions}
                                name={"date"}
                                placeholder={'select date'}
                                onChange={handleSelectChange}
                                value={report.date}
                                classNamePrefix={`${errorFields[`${report.documentID}-date`]} cursor-pointer basic-select`}
                                removeClearable={true}
                            >
                            </ReactSelect>
                        </div>
                    ) : (<></>)
                }
                <div className={`form-group ${report.frequency === 'daily' || _.isEmpty(report.frequency) ? 'col-md-6' : 'col-md-4'}`}>
                    <label >Time</label>
                    <div className="inner-addon right-addon">
                        <i className="bg-icons ico icon-time"></i>
                        <ReactTimePicker
                            value={report.schedulartime ? moment.unix(report.schedulartime.seconds)._d : null}
                            name={'schedulartime'}
                            onChange={handleHoursChange}
                            timeIntervals={30}
                            placeholder='h:mm aa'
                            className={`form-control ${errorFields[`${report.documentID}-schedulartime`]}`}
                        />
                    </div>
                </div>
            </div>
        </fieldset>
    </>)
}

export default ScheduleMultiple;