/** LIBRARIES */
import * as React from 'react'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import moment from 'moment';
import _ from 'lodash';
//import NumberFormat from 'react-number-format';
//import _ from 'lodash'
import { collection } from 'rxfire/firestore';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common'

import { objCafe } from '../viewModel'
import { cafeStatus } from '../../../services/enum';
import { firestoreDB } from '../../../services/helper';
const StageContext = React.createContext()

export const StageConsumer = StageContext.Consumer
const _objColumns = [
    { name: <Translate text={'Pending'} />, value: 'Pending' },
    // { name: <Translate text={'Preparing'} />, value: 'Preparing' },
    // { name: <Translate text={'Ready For Delivery'} />, value: 'Ready' },
    { name: <Translate text={'delivered'} />, value: 'Delivered' },
    { name: <Translate text={'cancelled'} />, value: 'Cancelled' },
]
export class StageProvider extends React.Component {
    constructor(props) {
        super(props);
        this.unSubCompanyList = null;
        this.state = {
            filter: {
                iSearch: ''
            },
            searchText: '',
            cafeOrders: {},
            isLoading: true,
            columns: ["Pending", "Delivered", "Cancelled"],
            clientID: '',
            pageLimit: 200,
            stageLoading: '',
            showNotifier: false,
            notifierMsg: '',
            notifierInfo: '',
            sectionloading: false
        };

        this.listenScrollEvent = this.listenScrollEvent.bind(this);
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        this.LoadOrders()
        if (this.props.path !== 'contacts')
            document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.remove('fixed-top')
    }
    // componentDidUpdate(prevProps, prevState) {
    //     if (this.state.isLoading) {
    //         this.LoadOrders()
    //     }
    // }
    componentWillUnmount() {
        this.unSubscribeList = null;
        window.refCollectionKitchenData && window.refCollectionKitchenData.unsubscribe()
        if (this.props.path !== 'contacts')
            document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
    }

    LoadOrders = async () => {
        try {
            const { dealersettings } = this.props;
            const { columns, searchText } = this.state;
            let cafeOrders = Object.assign({}, this.state.cafeOrders);
            if (_.isEmpty(this.state.cafeOrders)
                || this.state.stageLoading === '') {
                cafeOrders = {}
            }

            let clientPromise = []
            dealersettings.clients.forEach(client => {

                let snapDataRef = firestoreDB(dealersettings).firestore().collection('cafeOrders')
                    .where('clientID', '==', client.documentID)
                    .where("status", "in", [cafeStatus.PENDING, cafeStatus.DELIVERED, cafeStatus.CANCELLED])
                    .where('isDeleted', '==', false)
                    .where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                    .where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
                    .orderBy('addedDate', 'desc')

                if (searchText && searchText.trim()) {
                    snapDataRef = snapDataRef
                        .where('keywords', 'array-contains', searchText.trim().toLowerCase())
                }

                clientPromise.push(collection(snapDataRef));
            });

            window.refCollectionKitchenData = combineLatest(clientPromise).pipe(
                map((allClients) => {
                    let _allClients = [];

                    allClients && allClients.forEach(clients => {
                        _.map(clients, function (obj) { _allClients.push({ ...obj.data(), documentID: obj.id, id: obj.id }) })
                    });

                    return _allClients;
                }))
                .subscribe((allDocs) => {
                    //console.log('allDocs,', allDocs)
                    this.onCollectionUpdate(allDocs)

                });

            if (Object.keys(cafeOrders).length === 0) {
                cafeOrders['tasks'] = {};
                let cols = {}
                _objColumns.forEach(col => {
                    var leadsids = [];
                    cafeOrders.tasks && Object.keys(cafeOrders.tasks).map(function (el) {
                        if (cafeOrders.tasks[el].status === col.value.toLowerCase()) {
                            leadsids.push(cafeOrders.tasks[el].id)
                        }
                    })
                    cols[col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids
                    }
                })
                cafeOrders['columns'] = cols
            }
            this.setState({
                isLoading: false,
                cafeOrders: cafeOrders,
                stageLoading: ''
            })
        }
        catch (error) {
            this.setState({
                isLoading: false,
                stageLoading: ''
            })
            console.error(error)
        }
    }

    onCollectionUpdate = (querySnapshot) => {

        const { columns } = this.state;
        let cafeOrders = Object.assign({})
        const { clientUsers, dealersettings, groupUsers } = this.props;
        let result = {}
        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
        let isMultiDealer = dealersettings && dealersettings.clients && dealersettings.clients.length > 1 ? true : false;
        _.orderBy(querySnapshot, ['addedDate'], ['asc']).forEach((doc) => {
            const _cafe = Object.assign({}, doc);
            //const _cafe = Object.assign({}, doc.data());
            const cafe = Object.assign({}, objCafe);
            for (let [key, value] of Object.entries(_cafe)) {
                if (cafe.hasOwnProperty(key))
                    cafe[key] = value;
            }

            cafe.documentID = doc.id;
            cafe.id = doc.id;
            cafe.name = _cafe.contact ? CommonHelper.getFullName(null, _cafe.contact) : '';
            cafe.phone = _cafe.contact ? CommonHelper.phoneFormat(_cafe.contact.phoneCode, _cafe.contact.phone, dealersettings) : '';
            cafe.email = _cafe.contact ? (_cafe.contact.email) : '';
            cafe.orderImage = this.orderImage(_cafe.orders);
            cafe.orderQuantity = this.orderQuantity(_cafe.orders);
            cafe.createdby = _cafe.addedBy;
            cafe.addedby = CommonHelper.getUserNamebyId(allUsers, _cafe.addedBy);
            cafe.modifiedby = CommonHelper.getUserNamebyId(allUsers, _cafe.modifiedBy);
            cafe.modifieddate = _cafe.modifiedDate;

            if (isMultiDealer)
                cafe.dealerName = CommonHelper.getOtherDealerName(dealersettings, _cafe.clientID);

            result[doc.id] = Object.assign({}, cafe);

            if (cafeOrders.tasks) {
                cafeOrders['tasks'][doc.id] = { ...result[doc.id] }

            } else {
                cafeOrders['tasks'] = result
            }

            let cols = {}
            _objColumns.forEach(col => {
                var leadsids = [];
                cafeOrders.tasks && Object.keys(cafeOrders.tasks).map(function (el) {
                    if (cafeOrders.tasks[el].status === col.value.toLowerCase()) {
                        leadsids.push(cafeOrders.tasks[el].id)
                    }
                })
                cols[col.value] = {
                    id: col.value,
                    title: col.name,
                    taskIds: leadsids
                }
            })

            cafeOrders['columns'] = cols
        });

        if (_.isEmpty(cafeOrders)) {
            cafeOrders['tasks'] = {};
            let cols = {}
            _objColumns.forEach(col => {
                var leadsids = [];
                cafeOrders.tasks && Object.keys(cafeOrders.tasks).map(function (el) {
                    if (cafeOrders.tasks[el].status === col.value.toLowerCase()) {
                        leadsids.push(cafeOrders.tasks[el].id)
                    }
                })
                cols[col.value] = {
                    id: col.value,
                    title: col.name,
                    taskIds: leadsids
                }
            })
            cafeOrders['columns'] = cols
        }

        this.setState({
            isLoading: false,
            cafeOrders: cafeOrders,
            stageLoading: ''
        })

    }

    orderQuantity = (orders) => {

        if (!_.isEmpty(orders)) {
            var total = 0;
            for (var i = 0; i < orders.length; i++) {
                total = total + (orders[i].quantity ? orders[i].quantity : 0);
            }
            return total;
        }
        else {
            return 0;
        }
    }

    orderImage = (orders) => {

        if (!_.isEmpty(orders)) {
            return orders[0].image;
        }
        else {
            return null;
        }
    }

    listenScrollEvent(e) {

        if (e.target.scrollHeight - e.target.scrollTop === e.target.offsetHeight) {

        }
        return;
    }

    handlesearchText = (value) => {
        this.setState({ searchText: value }, () => { this.LoadOrders(); });

    }

    handleAutoCaps = (value) => {

        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            return newarray1.join(' ');

        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            return str;
        }

    }
    updateEnquiry = (objCafe) => {

    }

    handleOrderStatus = (id, status, oldStatus) => {
        //console.log('handleOrderStatus', id, status, oldStatus)
        const { cafeOrders } = this.state;
        //let _cafeStatus = this.handleAutoCaps(objCafe.status);

        if (status !== oldStatus) {
            cafeOrders.tasks[id].status = status.toLowerCase();
            cafeOrders.tasks[id].modifieddate = window.firebase.firestore.Timestamp.now();
        }

        var inqUpdate = {
            status: status.toLowerCase()
        };
        if (status !== oldStatus) {
            inqUpdate.modifiedBy = localStorage.uid;
            inqUpdate.modifiedDate = window.firebase.firestore.Timestamp.now();
            inqUpdate.modifiedFrom = 'web';
        }

        if (inqUpdate.status === cafeStatus.READY || inqUpdate.status === cafeStatus.DELIVERED || inqUpdate.status === cafeStatus.CANCELLED) {
            inqUpdate.deliveredBy = localStorage.uid;
            inqUpdate.deliveredDate = window.firebase.firestore.Timestamp.now();
            inqUpdate.deliveredFrom = 'web';
        }
        //this.setState({ cafeOrders })
        //this.forceUpdate()

        firestoreDB(this.props.dealersettings).firestore().doc(`cafeOrders/${id}`).set(inqUpdate, { merge: true })
            .then(snapshot => {

                toast.notify('Cafe Order updated successfully.', {
                    duration: 2000
                })
                // setTimeout(function () {

                //     document.getElementById('orders-' + id).scrollIntoView(
                //         {
                //             behavior: "smooth",
                //             block: "center"
                //         });
                // }, 1000)
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    render() {
        const { children } = this.props
        const { columns, cafeOrders, isLoading, searchText, stageLoading, sectionloading } = this.state
        //console.log('KITCHEN', cafeOrders)
        return (
            <StageContext.Provider
                value={{
                    cafeOrders,
                    columns,
                    searchText,
                    isLoading,
                    listenScrollEvent: this.listenScrollEvent,
                    handleOrderStatus: this.handleOrderStatus,
                    handlesearchText: this.handlesearchText,
                    stageLoading,
                    // showNotifier,
                    // notifierMsg,
                    // notifierInfo,
                    updateEnquiry: this.updateEnquiry,
                    sectionloading
                }}
            >
                {children}
            </StageContext.Provider>
        )
    }

}