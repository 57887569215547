/** LIBRARIES */
import React, { useState, useEffect, useReducer, Suspense } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import Swal from 'sweetalert2';
/** PROVIDERS */
/** VIEW-MODELS */
//import { allcontactFields, objContact } from '../viewModel';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopoverListLoader, PopUpModal, ReactSelect } from '../../../components';
import AddFleet from '../../fleet/add'
import AddTestDrive from '../../testdrive/add'
import CommonHelper from '../../../services/common';

import { default as _images } from '../../../images';
import { stockStatus, stockAvail } from '../../../services/enum'
import { objStock } from '../viewModel'


import StockListReducer from '../stockListReducer'
let sale_type = [];
const StockLists = (props) => {
    //const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [stocks, dispatch] = useReducer(StockListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [hasMore, setHasMoreData] = useState([])
    const [isAvailable, setIsAvailable] = useState(false)
    const [saleType, setSaleType] = useState('')
    const [isPaging, setPaging] = useState(false)
    const [loader, setLoader] = useState(true)
    const pageLimit = 30

    const [modelFleetShow, setModelFleetShow] = useState(false)
    const [modelTestDriveShow, setModelTestDriveShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [modelStock, setModelStock] = useState({})

    const [selectedIds, setSelectedIds] = useState({})
    const [isLoading, setIsLoading] = useState(false);

    const enableSharedStock = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedStock) ? true : false);

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);
    const accessToPipelineStock = ((!_.isEmpty(_permissions) && _permissions.accessToPipelineStock) ? true : false);
    const stockLocations = props?.dealersettings?.client?.clientSettings?.stockLocations
    const incWonStockinTD = props?.dealersettings?.client?.clientSettings?.incWonStockinTD
    useEffect(() => {
        // function handleResize() {
        //     // setWindowSize({
        //     //     windowWidth: (window.innerWidth - 30),
        //     //     windowHeight: (window.innerHeight - 123)
        //     // })
        // }
        //window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            //window.removeEventListener('resize', handleResize);
            window.unSubStockList && window.unSubStockList();
            //console.log('pageUNLoad', isAvailable, props.dealersettings.client.id, searchText, hasMore)
        }
    }, [])

    useEffect(() => {
        const { dealersettings } = props;

        if (!dealersettings)
            return;

        const setttings = dealersettings?.client?.settings;
        sale_type = [];
        setttings?.salesType && setttings.salesType.forEach(doc => {
            sale_type.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name,
            });
        });

    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }

        //console.log('pageLoad', isAvailable, props.dealersettings.client.id, searchText, hasMore, isPaging)
        const { dealersettings, availableType } = props;
        if (dealersettings) {

            let cliendId = dealersettings.client ? dealersettings.client.id : '';
            let isGroupOrRegionOrOemUser = CommonHelper.isGroupOrRegionOrOemUser(dealersettings);

            let stkLstDataRef = window.firebase.firestore().collection('stock');

            if (!isGroupOrRegionOrOemUser && _.isEmpty(stockLocations)) {

                //let _statusFilter = [stockStatus.AVAILABLE, stockStatus.MCRETAILED, stockStatus.ONTESTDRIVE, stockStatus.ONLOAN, stockStatus.RESERVED]
                let _statusFilter = _.map(dealersettings?.client?.settings?.stockStatus.filter(m => ![stockStatus.INPRODUCTION, stockStatus.DELIVERED, stockStatus.SOLD, stockStatus.UNAVAILABLE].includes(m.value)), function (e) { return e.value })
                if (accessToPipelineStock)
                    _statusFilter.push(stockStatus.INPRODUCTION)

                if (availableType === stockAvail.TESTDRIVE) {
                    _statusFilter.push(stockStatus.DELIVERED)

                    if (incWonStockinTD) _statusFilter.push(stockStatus.SOLD)
                }

                stkLstDataRef = stkLstDataRef
                    .where("status", "in", _statusFilter);
            }

            //stkLstDataRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(stkLstDataRef, dealersettings);
            if (localStorage.defaultModule === 'oem') {
                stkLstDataRef = stkLstDataRef.where('settingsID', '==', dealersettings.client.settingsID)
            }
            else if (!_.isEmpty(stockLocations) && availableType) {
                stkLstDataRef = stkLstDataRef.where('clientID', 'in', CommonHelper.arrLimit(_.uniq(stockLocations), 30))
            }
            else if (enableSharedStock && dealersettings.client.group) {
                stkLstDataRef = stkLstDataRef.where('groupID', '==', dealersettings.client.group)
            }
            else {
                stkLstDataRef = stkLstDataRef.where('clientID', '==', dealersettings.client.id)
            }

            if (searchText) {
                stkLstDataRef = stkLstDataRef
                    .where('keywords', 'array-contains', searchText.trim().toLowerCase())
            }

            if (availableType === stockAvail.SALE) {
                stkLstDataRef = stkLstDataRef
                    .where('isSale', '==', true)
            }
            else if (availableType === stockAvail.TESTDRIVE) {
                stkLstDataRef = stkLstDataRef
                    .where('isTestDrive', '==', true)

            }
            else if (availableType === stockAvail.LOAN) {
                stkLstDataRef = stkLstDataRef
                    .where('isLoan', '==', true)
            }

            if (isAvailable === true && !isGroupOrRegionOrOemUser) {
                stkLstDataRef = stkLstDataRef
                    .where('status', '==', stockStatus.AVAILABLE)
            }

            if (saleType && !isGroupOrRegionOrOemUser)
                stkLstDataRef = stkLstDataRef.where('saleType', '==', saleType)

            stkLstDataRef = stkLstDataRef
                .where('isDeleted', '==', false)
            //.orderBy('modifiedDate', 'desc')

            if (hasMore.length > 0) {
                stkLstDataRef = stkLstDataRef
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                stkLstDataRef = stkLstDataRef
                    .limit(pageLimit)
            }

            window.unSubStockList = stkLstDataRef
                .onSnapshot(onCollectionUpdate);

            //  return () => {
            //      window.unSubStockList();
            //  }

        }


    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertContactVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "_ADD";
                }
                else if (change.type === 'modified') {
                    actionType = "_UPDATE"
                }
                else if (change.type === 'removed') {
                    actionType = "_REMOVE"
                }
            }
        })
        let stocks = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                stocks.push(convertContactVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });

            const { dealersettings, availableType } = props;
            if (!_.isEmpty(stockLocations) && availableType) {
                let _statusFilter = _.map(dealersettings?.client?.settings?.stockStatus.filter(m => ![stockStatus.INPRODUCTION, stockStatus.DELIVERED, stockStatus.SOLD, stockStatus.UNAVAILABLE].includes(m.value)), function (e) { return e.value })
                if (accessToPipelineStock)
                    _statusFilter.push(stockStatus.INPRODUCTION)

                if (availableType === stockAvail.TESTDRIVE) {
                    _statusFilter.push(stockStatus.DELIVERED)

                    if (incWonStockinTD) _statusFilter.push(stockStatus.SOLD)
                }

                stocks = stocks.filter(m => _statusFilter.includes(m.status));
            }
        }

        if (isPaging) {
            dispatch({
                type: "_APPEND",
                data: stocks,
                sortName: 'modifiedDate',
                sortOrder: 'desc'
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "_SUCCESS",
                data: actionType ? snapshotDoc : stocks,
                sortName: 'modifiedDate',
                sortOrder: 'desc'
            });
        }

        //console.log('hasMore', hasMore)
        setDataload(false)
        setLoader(false)
        setPaging(false)
    }

    const convertContactVM = (doc) => {
        const objStockData = Object.assign({}, doc);
        const stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(objStockData)) {
            stock[key] = value;
        }

        return stock
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const listenScrollEvent = (e) => {
        if (hasMore.length === 0) return;
        //console.log('listenScrollEvent', e.target.scrollHeight - e.target.scrollTop, e.target.offsetHeight)
        if (e.target.scrollHeight - e.target.scrollTop === e.target.offsetHeight) {
            //console.log('listenScrollEvent')
            //handlePagination();
        }
        return;
    }

    const handleStockSelect = (stock) => {
        //console.log(stock);
        if (props.fromActivity === true && stock.status !== stockStatus.SOLD && stock.status !== stockStatus.DELIVERED && stock.status !== stockStatus.RESERVED) {
            handleSelectClose(stock.documentID, stock)
        }
        else if (stock.status === stockStatus.AVAILABLE || stock.status === stockStatus.MCRETAILED ||
            (props.availableType === stockAvail.TESTDRIVE && stock.status === stockStatus.DELIVERED && stock.saleType === 'Demo') ||
            (props.availableType === stockAvail.TESTDRIVE && stock.status === stockStatus.SOLD && incWonStockinTD) ||
            (props.fromRequirement === true && localStorage.defaultModule !== 'oem' &&
                ((stock.status === stockStatus.ONTESTDRIVE && !_.isEmpty(stock.testDriveDetail)) ||
                    (stock.status === stockStatus.ONLOAN && !_.isEmpty(stock.fleetDetail)) ||
                    stock.status === stockStatus.INPRODUCTION)
            )) {
            handleSelectClose(stock.documentID, stock)
        }
        else if (localStorage.defaultModule !== 'oem' && stock.status === stockStatus.ONTESTDRIVE && !_.isEmpty(stock.testDriveDetail)) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Selected vehicle is on Test Drive'),
                text: CommonHelper.showLocale(props, 'In order to select You have to return it'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Return'),
                cancelButtonText: CommonHelper.showLocale(props, 'Cancel'),
            }).then((result) => {
                if (result.value) {
                    setEditDocument(stock.testDriveDetail.testdriveID);
                    setModelTestDriveShow(true);
                    setModelStock(stock);
                }
            })

        }
        else if (localStorage.defaultModule !== 'oem' && stock.status === stockStatus.ONLOAN && !_.isEmpty(stock.fleetDetail)) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Selected vehicle is on Loan'),
                text: CommonHelper.showLocale(props, 'In order to select You have to return it'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Return'),
                cancelButtonText: CommonHelper.showLocale(props, 'Cancel'),
            }).then((result) => {
                if (result.value) {
                    setEditDocument(stock.fleetDetail.fleetID);
                    setModelFleetShow(true);
                    setModelStock(stock);
                }
            })

        }


    }

    const handleSelectClose = (ID, objData) => {

        if ((props.availableType === stockAvail.TESTDRIVE || props.availableType === stockAvail.SALE) && objData.isServiceLoan) {
            Swal.fire(CommonHelper.showLocale(props, 'This vehicle has been reserved for service loan only'), '', 'info')
            return;
        }

        const { alrtMsg } = props;
        if (alrtMsg) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, alrtMsg),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    props.handleClose(ID, objData)
                }
            })
        }
        else { props.handleClose(ID, objData) }

    }

    const handleClose = () => {
        setModelFleetShow(false);
        setModelTestDriveShow(false);
        setModelStock({});
        setEditDocument(null);
    }

    const handleCheckChange = (e, id, stock) => {
        const { name, checked } = e.target;
        //console.log(e, id, stock)

        if (stock.isServiceLoan) {
            Swal.fire(CommonHelper.showLocale(props, 'This vehicle has been reserved for service loan only'), '', 'info')
            return;
        }

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked && !_.isEmpty(stock)) {
            _selectedIds[id] = stock;
        }
        else {
            delete _selectedIds[id];
        }
        setSelectedIds(_selectedIds);
    }

    const handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }

    const divStyle = {
        height: '500px',
        overflow: 'auto',
        position: "relative"
    };

    const modelPrice = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.modelPrice) ? true : false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const addStock = (props.dealersettings?.rolePermissions?.permissions?.addStock) ? true : false;
    return (
        <>
            <Modal
                id="stockList"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-select-stock">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={'stock'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className={`vehicle-popover-head ${props.showAdd && addStock ? '' : 'pr-0'}`}>
                            {
                                props.showAdd && addStock
                                    ?
                                    <div className="vehicle-popover-add">
                                        <a href="#" className="mini-button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.handleAdd();
                                            }}> <i className="ico icon-add"></i>
                                        </a>
                                    </div>
                                    :
                                    <></>

                            }

                            <input
                                type="text"
                                aria-label="Search"
                                placeholder="search..."
                                value={searchText ? searchText : ''}
                                autoComplete="off"
                                onChange={e => {
                                    setSearchText(e.target.value);
                                    setHasMoreData([]);
                                    setDataload(true)
                                    setLoader(true)
                                }}
                            />

                        </div>

                        {
                            localStorage.defaultModule !== 'oem'
                                ?
                                <>
                                    <div className="popover-sub-head">
                                        {
                                            (!_.isEmpty(selectedIds)) ?
                                                (<>
                                                    <div className="list-count-btn float-left">
                                                        <span><Translate text={Object.keys(selectedIds).length > 1 ? 'Selected Stocks' : 'Selected Stock'} /></span>
                                                        <span className="list-count"> {Object.keys(selectedIds).length} </span>
                                                    </div>
                                                </>)
                                                : (<></>)
                                        }

                                        <div className="float-right stock-saletype-select-width">

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={sale_type}
                                                        name={"saleType"}
                                                        placeholder={'filter sale type'}
                                                        onChange={(e) => {
                                                            setSaleType(e?.value);
                                                            setPaging(false)
                                                            setHasMoreData([]);
                                                            setDataload(true)
                                                            setLoader(true)
                                                        }}
                                                        value={saleType}
                                                        classNamePrefix={`basic-select`}
                                                        removeClearable={false}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="float-right mr-3">

                                            <div className="checkbox icheck-success">
                                                <input
                                                    type="checkbox"
                                                    id="isAvailable"
                                                    name="isAvailable"
                                                    className="uncheck-activity"
                                                    checked={isAvailable}
                                                    onChange={(e) => {
                                                        setIsAvailable(e.target.checked);
                                                        setPaging(false)
                                                        setHasMoreData([]);
                                                        setDataload(true)
                                                        setLoader(true)
                                                    }} />
                                                <label htmlFor="isAvailable"><Translate text={'available'} /> </label>
                                            </div>
                                        </div>
                                    </div>


                                </>
                                :
                                <></>
                        }

                        <Suspense>
                            <div style={divStyle} onScroll={listenScrollEvent}>
                                {
                                    loader ? (<PopoverListLoader />) :
                                        (!_.isEmpty(stocks) && stocks.length) > 0
                                            ?
                                            <div className='mr-2'>
                                                {stocks.map(_stock =>
                                                    <div key={_stock.documentID} id={`vehicle-stock-${_stock.documentID}`} className="vehicle-popover-loop" >

                                                        {
                                                            props.isMultiStockSelect
                                                                ?
                                                                <>
                                                                    {
                                                                        !_.isEmpty(!_.isEmpty(props.requirements) && props.requirements.filter(e => e.stock?.documentID === _stock.documentID)[0])
                                                                            ?
                                                                            <>
                                                                                <div className="file-manager-check-wrap ml-2">
                                                                                    <div className="checkbox icheck-success">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id={`checkbox_${_stock.documentID}`}
                                                                                            name={`checkbox_${_stock.documentID}`}
                                                                                            className="uncheck-activity"
                                                                                            checked={true}
                                                                                            disabled={true}
                                                                                            onChange={(e) => {
                                                                                                //handleCheckChange(e, _stock.documentID, stock)
                                                                                            }} />
                                                                                        <label htmlFor={`checkbox_${_stock.documentID}`}></label>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="file-manager-check-wrap ml-2">
                                                                                    <div className="checkbox icheck-success">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id={`checkbox_${_stock.documentID}`}
                                                                                            name={`checkbox_${_stock.documentID}`}
                                                                                            className="uncheck-activity"
                                                                                            checked={(_.isObject(selectedIds) && !_.isEmpty(selectedIds) && selectedIds.hasOwnProperty(_stock.documentID)) ? true : false}
                                                                                            disabled={(_stock.status === stockStatus.AVAILABLE || _stock.status === stockStatus.MCRETAILED ||
                                                                                                (_stock.status === stockStatus.ONTESTDRIVE && !_.isEmpty(_stock.testDriveDetail)) ||
                                                                                                (_stock.status === stockStatus.ONLOAN && !_.isEmpty(_stock.fleetDetail)) ||
                                                                                                _stock.status === stockStatus.INPRODUCTION) ? false : true}
                                                                                            onChange={(e) => {
                                                                                                handleCheckChange(e, _stock.documentID, _stock)
                                                                                            }} />
                                                                                        <label htmlFor={`checkbox_${_stock.documentID}`}></label>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                    }

                                                                </>
                                                                :
                                                                <></>
                                                        }


                                                        <div className={`divTable ${props.isMultiStockSelect ? 'w-95' : ''}`} onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!props.isMultiStockSelect) handleStockSelect(_stock);

                                                        }}>
                                                            <div className="divTableBody">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell width70">
                                                                        <div className="vehicle-stock-content">
                                                                            <div className="vehicle-stock-image"> <a href="#" onClick={(e) => { e.preventDefault(); }}> <img src={(!_.isEmpty(_stock.images)) ? _stock.images[0] : CommonHelper.showBrandLogo(props.dealersettings, _stock.make)} alt="" width="70" height="70" className="img-object-fit-contain" /> </a> </div>
                                                                            <div className="vehicle-stock-title">{_stock.make + " " + _stock.model}</div>
                                                                            {/* <div className="vehicle-stock-titlesub">
                                                                                <strong><Translate text={'regNo'} />:</strong> {_stock.regNo ? `${_stock.regNo}, ` : '--'}
                                                                                <strong className='ml-1'><Translate text={'stockNo'} />:</strong> {_stock.stockNo ? _stock.stockNo : '--'}
                                                                            </div>
                                                                            {_stock.extColor ? <div className="vehicle-stock-titlesub">
                                                                                <strong><Translate text={'extColor'} />:</strong> {_stock.extColor}
                                                                            </div> : <></>} */}
                                                                            {
                                                                                _stock.clientID && (!_.isEmpty(stockLocations) || _stock.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem') ? (
                                                                                    <div className="vehicle-stock-titlesub">
                                                                                        {
                                                                                            localStorage.defaultModule === 'oem' && _stock.clientID && _stock.oemID && _stock.isAllocated
                                                                                                ?
                                                                                                <>
                                                                                                    <strong className="mr-1"><Translate text={'Allocated To'} />:</strong>
                                                                                                </>
                                                                                                :
                                                                                                <> </>
                                                                                        }
                                                                                        <strong className="blue-color">{CommonHelper.getOtherDealerName(props.dealersettings, _stock.clientID)}</strong>

                                                                                    </div>) : (<></>)
                                                                            }

                                                                            <div className="dashboard-testdrive-activity-column">
                                                                                Stock#: <div title={CommonHelper.showLocale(props, 'stockNo')}>{_stock?.stockNo || '--'}</div>
                                                                                {_stock?.extColor && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'exteriorColor')}>{_stock?.extColor}</div></>)}
                                                                                {_stock?.year && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'year')}>{_stock?.year}</div></>)}
                                                                                {_stock?.regNo && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale(props, 'registrationNumber')}>{_stock?.regNo}</div></>)}
                                                                                {_stock?.vinNo && (<><span className="veh-details-seperator">|</span>VIN#:&nbsp;<div title={CommonHelper.showLocale(props, 'vinNo')}>{_stock?.vinNo}</div></>)}
                                                                                {modelPrice && props.displayPrice && _stock.price && (<><span className="veh-details-seperator">|</span><Translate text={'price'} />:&nbsp;<NumberFormat value={_stock.price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /></>)}
                                                                            </div>


                                                                            {/* {
                                                                                modelPrice && props.displayPrice
                                                                                    ?
                                                                                    <>
                                                                                        <div className="vehicle-stock-titlesub">
                                                                                            <strong><Translate text={'price'} />:</strong>
                                                                                            {_stock.price ? <NumberFormat value={_stock.price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : ''}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="vehicle-stock-titlesub">
                                                                                            <strong><Translate text={'vinNo'} />:</strong> {_stock.vinNo ? _stock.vinNo : '--'}
                                                                                        </div>
                                                                                    </>
                                                                            } */}


                                                                        </div>
                                                                    </div>
                                                                    <div className="divTableCell width30">
                                                                        <div className="vehicle-stock-status float-right">


                                                                            {_stock.status
                                                                                ?
                                                                                <>
                                                                                    {CommonHelper.bindStockLabel(props.dealersettings?.client?.settings?.stockStatus, _stock.status, '', 'ml-1 float-right mt-1')}
                                                                                </>
                                                                                :
                                                                                <></>}

                                                                            {_stock.saleType ? <div className="badge badge-pill badge-white float-right mt-1">
                                                                                {CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.salesType, _stock.saleType, '')}
                                                                            </div> : <></>}
                                                                        </div>
                                                                        {
                                                                            !_.isEmpty(_stock.stockInDate)
                                                                                ?
                                                                                <div className="vehicle-stock-status float-right mt-2">
                                                                                    <div className="badge badge-pill status-stock-age">
                                                                                        <Translate text={'stockAge'} />{' : '}
                                                                                        {handleDiffDays(_stock.stockInDate)}
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        {
                                                            _stock.status && _stock.status !== stockStatus.AVAILABLE && _stock.statusModifiedBy && props.clientUsers //&& _stock.enquiryID !== enquiryID
                                                                ?
                                                                <>
                                                                    <div className="vehicle-current-status pl-2 pr-2 mt-0 border-0">
                                                                        <div className="vehicle-status-alert alert-user" >
                                                                            <b className="alert-link">
                                                                                {/* {_stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()} */}
                                                                                {CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.stockStatus, _stock.status)?.toUpperCase()}
                                                                            </b>
                                                                            {' by '}
                                                                            {_stock.statusModifiedBy && props.clientUsers.filter(e => e.id === _stock.statusModifiedBy).length > 0 ? props.clientUsers.filter(m => m.id === _stock.statusModifiedBy)[0].name + '.' : '--'}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className={CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, 'ico icon-Stock')}></i> </div>
                                                        <div className="no-data-txt mt-2"> <Translate text={'noStockMsg'} /></div>
                                                    </div>

                                                </div>
                                            </>
                                }

                            </div>
                        </Suspense>

                    </>
                </Modal.Body>

                {
                    !_.isEmpty(selectedIds)
                        ?
                        <>
                            <Modal.Footer className={isLoading ? 'form-disable' : ''}>
                                <button
                                    type="button"
                                    className="btn btn-default float-left"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedIds({});

                                    }}
                                ><Translate text={'clear'} />
                                </button>
                                {
                                    hasMore && hasMore.length > 0 ? (
                                        <div className="load-more-button pb-2 w-75"> <a href="#" className="mt-1" onClick={(e) => {
                                            e.preventDefault();
                                            handlePagination();
                                        }}><Translate text={'Load More'} />
                                            {
                                                !_.isEmpty(checkDataLoad) ? (
                                                    <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                                ) : (<i className="fa fa-angle-down" aria-hidden="true"></i>)
                                            }

                                        </a></div>
                                    ) : (<></>)
                                }
                                <button
                                    type="button"
                                    className="btn btn-primary float-right"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsLoading(true);
                                        props.handleMultiStockClose(selectedIds);
                                    }}
                                >
                                    {isLoading ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                                    <Translate text={Object.keys(selectedIds).length > 1 ? 'save' : 'select'} />
                                </button>
                            </Modal.Footer>
                        </>
                        :
                        <>
                            {
                                hasMore && hasMore.length > 0 ? (
                                    <Modal.Footer >
                                        <div className="load-more-button pb-2"> <a href="#" className="mt-1" onClick={(e) => {
                                            e.preventDefault();
                                            handlePagination();
                                        }}><Translate text={'Load More'} />
                                            {
                                                !_.isEmpty(checkDataLoad) ? (
                                                    <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                                ) : (<i className="fa fa-angle-down" aria-hidden="true"></i>)
                                            }

                                        </a></div>
                                    </Modal.Footer>

                                ) : (<></>)
                            }
                        </>
                }
            </Modal>

            <PopUpModal show={modelFleetShow}>
                <AddFleet
                    docID={editDocumentID}
                    show={modelFleetShow}
                    clsActive='overlay-modal active'
                    stock={modelStock}
                    handleClose={handleClose}
                    title={'fleet'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </AddFleet>
            </PopUpModal>

            <PopUpModal show={modelTestDriveShow}>
                <AddTestDrive
                    docID={editDocumentID}
                    show={modelTestDriveShow}
                    clsActive='overlay-modal active'
                    handleClose={handleClose}
                    title={'testDrive'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isDeivered={false}
                    contact={null}
                >
                </AddTestDrive>
            </PopUpModal>

        </>
    )
}

export default StockLists