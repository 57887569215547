import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import _images from '../../images'
import moment from 'moment'
import { BarChart } from '../../components'
import toast from 'toasted-notes'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';

const LeadsCreated = (props) => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true);
    const [dealersData, setDealersData] = useState([])

    const [refreshTime, setRefreshTime] = useState(0)
    const [initialLoad, setInitialLoad] = useState(true)
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);
    const inclInboundBuckeinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundBuckeinRpt) ? true : false);


    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-${props.id}`);

    const reportColumns = [
        { name: CommonHelper.showLocale(props, 'Leads Created'), value: 'leads', color: '#364d6e', handleClick: 'handleShowEnquiryList', dummyData: { width: 20, height: 20 } },
        { name: CommonHelper.showLocale(props, 'Lost Enquiries'), value: 'lost', color: '#c30c3e', handleClick: 'handleShowEnquiryList', dummyData: { width: 20, height: 20 } },
        { name: CommonHelper.showLocale(props, 'Revival Enquiries'), value: 'rev', color: '#737278', handleClick: 'handleShowEnquiryList', dummyData: { width: 20, height: 20 } },
        { name: CommonHelper.showLocale(props, 'Leads Won Conversion Rate'), value: 'conversion', color: '#d5001c', handleClick: 'handleShowEnquiryList', dummyData: { width: 25, height: 7, top: 8, right: 20 } },
        { name: CommonHelper.showLocale(props, 'testDrives'), value: 'td', color: '#d5001c', handleClick: 'handleShowTestDriveList', dummyData: { width: 25, height: 7, top: 8, right: 20 } },
        { name: CommonHelper.showLocale(props, 'Trade Ins'), value: 'trd', color: '#d5001c', handleClick: 'handleShowTradeinList', dummyData: { width: 25, height: 7, top: 8, right: 20 } },
        { name: CommonHelper.showLocale(props, 'Showroom Visits'), value: 'visits', color: '#364d6e', handleClick: 'handleShowEnquiryList', dummyData: { width: 20, height: 20 } },
        { name: CommonHelper.showLocale(props, 'Qualified Enquiries'), value: 'qualified', color: '#364d6e', handleClick: 'handleShowEnquiryList', dummyData: { width: 20, height: 20 } },
        { name: CommonHelper.showLocale(props, 'New Enquiries'), value: 'enquiries', color: '#d5001c', handleClick: 'handleShowEnquiryList', dummyData: { width: 25, height: 7, top: 8, right: 20 } },
        //{ name: CommonHelper.showLocale(props, 'Qualified Enquiries'), value: 'qualifiedy', color: '#d5001c', handleClick: 'handleShowEnquiryList', dummyData: { width: 25, height: 7, top: 8, right: 20 } },

    ]

    useEffect(() => {
        return () => {
            window[`unsub${props.id}Snap`] && window[`unsub${props.id}Snap`]();
        }
    }, []);

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchData();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return

        setLoader(true)
        window[`unsub${props.id}Snap`] = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setLoader(false);
                    setInitialLoad(false)
                    setDealersData(snap.data().counts);
                    setData(snap.data().counts);
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    // useEffect(() => {
    //     if (!initialLoad)
    //         return;

    //     fetchData();
    // }, [initialLoad])


    const fetchData = async (hideLoader) => {
        let _filter = {
            clientID: CommonHelper.getAllDealers(props.dealersettings),
            //campaignReq: true,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
            hideTradein: props.id === 'TradeInCompleted' ? null : true,
            hideActivities: true,
            hideWalkin: props.id === 'ShowroomVisits' ? null : true,
            dmsDate: true,
            scheduleActivities: false,
            inclInboundBuckeinRpt,
            inclInboundLeadsinRpt,
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        else {
            _filter.startDate = moment().startOf('year').format('YYYY-MM-DD')
            _filter.endDate = moment().endOf('month').format('YYYY-MM-DD')
        }

        let _searchObject = {
            type: "getSummaryReport",
            params: JSON.stringify(_filter),
        }
        if (!hideLoader)
            setLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        //console.log('getSummaryReport', props.id, _searchObject);
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp alternate', props.id, resp.data.data);
            const _data = getBarData(resp.data.data, _filter.startDate, _filter.endDate);
            setData(_data);
            if (_.isEmpty(props.dashboardFilter)) {
                //console.log('initial Data:', props.id, _data)
                setDealersData(_data);
                await docPathRef.set({ counts: _data, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            setLoader(false)
            setFirstTimeLoaded(true)
        } else {
            toast.closeAll();
            setLoader(false)
            setFirstTimeLoaded(true)
        }
    }

    const getBarData = (data, startDate, endDate) => {
        //console.log('getBarData1', props.id, props?.dashboardFilter, data)
        let start = moment(startDate);
        let end = moment(endDate);
        let months = [];
        while (start <= end) {
            months.push(start.format('MMM YYYY'));
            start.add(1, 'month');
        }
        const filterData = data.filter((a) => a.stgv !== 'Archived'
            && (props?.dashboardFilter?.pipelines?.length ? props.dashboardFilter.pipelines.includes(a.plnv) :
                (!_.isEmpty(props.pipeline) ? (props.pipeline.filter(item => !Boolean(item.disableReport) && (inclInboundLeadsinRpt ? true : item.value !== 'LeadsBucket')).some(b => b.value === a.plnv)) : true))
            && (props?.dashboardFilter?.makes?.length ? a.mk && props.dashboardFilter.makes.some(b => b.toLowerCase() === a.mk.toLowerCase()) : true)
            && (props?.dashboardFilter?.salesType?.length ? a.st && props.dashboardFilter.salesType.includes(a.st) :
                (!_.isEmpty(props.dealersettings?.accessSalesType) ? props.dealersettings.accessSalesType.includes(a.st) : true))
            && (props?.dashboardFilter?.user?.length ? a.st && props.dashboardFilter.user.includes(a.uid) : true)
            && (props?.dashboardFilter?.models?.length ? a.ml && props.dashboardFilter.models.some(b => b.toLowerCase() === a.ml.toLowerCase()) : true)
            && (props?.dashboardFilter?.campaigns?.length ? a.cpgv && props.dashboardFilter.campaigns.includes(a.cpgv) : true)
            && (props?.dashboardFilter?.origins?.length ? a.orgv && props.dashboardFilter.origins.includes(a.orgv) : true)
            && (props?.dashboardFilter?.enquiryTypes?.length ? a.etv && props.dashboardFilter.enquiryTypes.includes(a.etv) : true)
            && (props?.dashboardFilter?.leadSource?.length ? a.lsv && props.dashboardFilter.leadSource.includes(a.lsv) : true)
        );
        //console.log('filterData', props.id, props?.dashboardFilter, filterData)
        let _data = []
        months.forEach(month => {
            // Create a moment object from the short month name and year
            let startDate = moment(month, 'MMM YYYY');
            // Get the first day of the month
            let startOfMonth = startDate.startOf('month').startOf('day').unix()
            // Get the last day of the month
            let endOfMonth = startDate.endOf('month').endOf('day').unix()
            let _datFormat = {
                leads: checkDataFilter(filterData.filter((a) => a.col === 'enq' && a.tags && JSON.parse(a.tags).some(b => b.type === 'cap')), 'eid', startOfMonth, endOfMonth, null, 'dmsdt'),
                wonLeads: checkDataFilter(filterData.filter((a) => a.col === 'enq' && a.tags && JSON.parse(a.tags).some(b => b.type === 'cap')), 'eid', startOfMonth, endOfMonth, 'sold', 'wd'),
                lostLeads: checkDataFilter(filterData.filter((a) => a.col === 'enq' && a.tags && JSON.parse(a.tags).some(b => b.type === 'cap')), 'eid', startOfMonth, endOfMonth, 'lost', 'ld'),
                lost: checkDataFilter(filterData.filter((a) => a.col === 'enq'), 'eid', startOfMonth, endOfMonth, 'lost', 'ld'),
                //lost: mergeStatusFilter(filterData.filter(a => a.col === 'enq'), 'eid', startOfMonth, endOfMonth, 'lost', 'ld'),
                rev: checkDataFilter(filterData.filter(a => a.col === 'enq' && ['won', 'delivered'].includes(a.sts) && a.ds && !_.isEmpty(JSON.parse(a.ds)?.['lost'])), 'eid', startOfMonth, endOfMonth, null, 'wd'),
                td: checkDataFilter(filterData.filter(a => a.col === 'td'), 'did', startOfMonth, endOfMonth),
                trd: checkDataFilter(filterData.filter(a => a.col === 'trd'), 'did', startOfMonth, endOfMonth),
                visits: checkDataFilter(filterData.filter(a => a.col === 'wk'), 'eid', startOfMonth, endOfMonth),
                qualified: checkDataFilter(filterData.filter((a) => a.col === 'enq' && a.conv === 'true'), 'eid', startOfMonth, endOfMonth),
                enquiries: checkDataFilter(filterData.filter((a) => a.col === 'enq'), 'eid', startOfMonth, endOfMonth),
            }
            let _obj = { month: startDate.format((startDate.year() === moment().year()) ? 'MMM' : 'MMM YY') }
            for (let [key, value] of Object.entries(_datFormat)) {
                _obj[key] = value.actual;
                _obj[`${key}IDs`] = value.arractual;
            }
            _obj.conversion = (_obj.wonLeads + _obj.lostLeads) > 0 ? Math.round((100 / (_obj.wonLeads + _obj.lostLeads)) * _obj.wonLeads) : 0;
            _data.push(_obj)
        })
        //console.log('getBarData', props.id, _data)
        return _data;

    }

    const mergeStatusFilter = (_data, fieldName, startDt, endDt, status, dateType, newlead, saleType) => {
        let statusData = checkDataFilter(_data.filter(a => a.col === 'enq'), fieldName, startDt, endDt, status, dateType, null, null, true);
        let statusRevivalData = checkDataFilter(_data.filter(a => a.col === 'enq'
            && a.sts !== status
            && a.ds
            && !_.isEmpty(JSON.parse(a.ds)?.[status])
            && (a.ds && JSON.parse(a.ds)?.[status]?._seconds >= startDt && JSON.parse(a.ds)?.[status]?._seconds <= endDt)), fieldName, startDt, endDt, null, null, null, null, true)
        let filterData = _.union(statusData?.data, statusRevivalData?.data)
        return {
            arractual: _.uniqBy(filterData, fieldName).map(r => { return r[fieldName] }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, fieldName), 'wc') : _.uniqBy(filterData, fieldName).length
        }
    }

    const checkDataFilter = (_data, fieldName, startDt, endDt, status, dateType, newlead, saleType, dataReturn) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won' || a.sts.toLowerCase() === 'delivered')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));

        return {
            arractual: _.uniqBy(filterData, fieldName).map(r => { return r[fieldName] }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, fieldName), 'wc') : _.uniqBy(filterData, fieldName).length,
            data: dataReturn === true ? filterData : [],
        }
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props.dashboardFilter)) {
            window[`unsub${props.id}Snap`] && window[`unsub${props.id}Snap`]();
            setFirstTimeLoaded(false);
            fetchData();
        }
        else {
            loadBarData();
        }

    }, [props.dashboardFilter])

    // useEffect(() => {
    //     if (initialLoad)
    //         return;
    //     loadBarData();
    // }, [selectedKpi])

    const setColums = () => {
        return props.id === 'LostEnquiryReignition' ? ['lost', 'rev'] :
            props.id === 'ShowroomVisits' ? ['visits'] :
                props.id === 'EnquiryQualificationDevelopment' ? ['qualified'] :
                    ['leads']
    }

    const setLineColums = () => {
        return props.id === 'LeadsCreated' ? ['conversion'] :
            props.id === 'TestDriveCompleted' ? ['td'] :
                props.id === 'TradeInCompleted' ? ['trd'] :
                    props.id === 'EnquiryQualificationDevelopment' ? ['enquiries'] :
                        props.id === 'LeadQualificationDevelopment' ? ['qualified'] :
                            []
    }

    const setReportColumns = () => {
        return props.id === 'LeadQualificationDevelopment' ? reportColumns.map(rec => {
            if (rec.value === 'qualified')
                return {
                    ...rec,
                    color: '#d5001c',
                    borderColor: '#d5001c',
                    dummyData: { width: 25, height: 7, top: 8, right: 20 }
                }
            else return rec;
        }) : reportColumns
    }

    const loadBarData = () => {
        let _filter = {
            startDate: moment().startOf('year').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD')
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        //console.log('loadBarData', props.id, dealersData)
        //setData(getBarData(dealersData, _filter.startDate, _filter.endDate));
        setData(dealersData)
    }



    const handleShowList = (value, data) => {
        //console.log(value, data)
        let handleClick = reportColumns.filter(e => e.value === value)[0]?.handleClick;
        if (!_.isEmpty(data?.[`${value}IDs`]) && data?.[`${value}IDs`].length > 0 && props[handleClick]) {
            props[handleClick]({ documentIDs: data?.[`${value}IDs`] || [] })
        }
    }

    const sameYAxis = () => {
        return ['TestDriveCompleted', 'TradeInCompleted', 'LeadQualificationDevelopment', 'EnquiryQualificationDevelopment'].includes(props.id) ? true : false;
    }

    return (
        <>
            <div className="dashboard-box">
                {
                    loader && !firstTimeLoaded ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setFirstTimeLoaded(false); fetchData(); }}> <i className="ico icon-refresh"></i></a>

                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }
                    </div>
                    <h3>{props.title || <Translate text={'Leads Conversion'} />}</h3>
                </div>

                <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                    <div className="text-center">
                        <BarChart
                            id={`${props.id}-bar-chart`}
                            height={`400px`}
                            data={data}
                            columns={setColums()}
                            lineColumns={setLineColums()}
                            dynamicColumns={setReportColumns()}
                            xCategory={'month'}
                            calculationType={'count'}
                            calculationLineType={props.id === 'LeadsCreated' ? 'percentage' : null}
                            handleShowEnquiryList={(value, name) => {
                                handleShowList(value, name);
                            }}
                            hideBullet={props.id === 'LostEnquiryReignition' ? false : true}
                            lblWhitecolor={true}
                            dynColor={true}
                            removeGridLines={true}
                            columnsStacked={props.columnsStacked}
                            sameYAxis={sameYAxis()}
                        />
                    </div>
                </div>

            </div >
        </>
    );
}
export default LeadsCreated;