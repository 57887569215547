/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { ReactSelect } from '../../components';
import CommonHelper from '../../services/common';
export default class Dealers extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            fields: {
                dealer: '',
                pipeline: '',
                owner: localStorage.uid
            },
            errors: {},
            dealers: [],
            tradeinProPipeline: [],
            allUsers: [],
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount = () => {
        const { dealersettings, clientUsers, showOwner, origin } = this.props;
        if (!_.isEmpty(dealersettings)) {

            let _dealers = [];
            if (localStorage.defaultModule === 'tradeinPro' && !_.isEmpty(dealersettings?.tradeinProClients)) {
                _dealers = CommonHelper.tradeinProClients(dealersettings);
            }

            if (origin === 'oemStock' && !_.isEmpty(dealersettings.group.clients)) {
                _dealers = Object.keys(dealersettings.group.clients).map(cid => { return { value: cid, label: dealersettings.group.clients[cid].name } })
            }

            let tradeinProPipeline = [];
            if (origin === 'tradeinPro' && !_.isEmpty(dealersettings?.client?.tradeinPro?.pipelines)) {
                dealersettings.client.tradeinPro.pipelines.map(rec => {
                    return tradeinProPipeline.push({
                        label: rec.name,
                        value: rec.value,
                        data: rec,
                        active: true,
                    })
                });
            }

            // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            let options = [];
            let objFilds = {
                dealer: '',
                owner: localStorage.uid
            }
            if (showOwner) {
                clientUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })


                let _currentUser = options.filter(v => v.value === localStorage.uid)[0];
                if (!_currentUser) {
                    objFilds.owner = '';
                }
            }


            this.setState({ dealers: _dealers, allUsers: options, fields: objFilds, tradeinProPipeline: tradeinProPipeline })
        }


    }

    handleReactSelectChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';


        let options = [];
        const { clientUsers, groupUsers } = this.props;
        let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
        _.filter(allDealerUsers.filter(v => v.clientID === e.value)).map(rec => {
            return options.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>
            })
        })

        this.setState({ fields: fields, allUsers: options }, () => { this.errorChange(data.name) });

    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (!fields[key] && key !== 'pipeline') {
                formIsValid = false;
                errors[key] = errorClass;

            }
        }
        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.isLoading = true;
            const { showOwner, origin } = this.props;
            if (showOwner)
                this.props.handleClose(fields, origin);
            else
                this.props.handleClose(fields.dealer, origin, fields.pipeline);
            return true;
        }
        else
            return formIsValid;
    };


    render() {
        const { dealer, owner, pipeline } = this.state.fields;
        const { show, isGenericLayout, title, showOwner, origin } = this.props;
        const { dealers, allUsers, tradeinProPipeline } = this.state;
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-regno">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={title} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">
                        <div className={`container-fluid ${isGenericLayout ? 'pl-0 pr-0' : ''}`}>
                            <div className={`row ${isGenericLayout ? 'w-100 d-block border-0' : ''}`}>
                                <div className="search-pop form-style">
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'dealerships'} /></label>
                                            <ReactSelect
                                                options={_.orderBy(dealers, ['label'], ['asc'])}
                                                name={"dealer"}
                                                placeholder={'select dealer'}
                                                onChange={this.handleReactSelectChange}
                                                value={dealer}
                                                classNamePrefix={`${this.state.errors["dealer"]} basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>
                                        {
                                            origin === 'tradeinPro' && !_.isEmpty(tradeinProPipeline) && tradeinProPipeline.length > 1
                                                ?
                                                <div className="form-group col-md-12">
                                                    <label > <Translate text={'pipeline'} /></label>
                                                    <ReactSelect
                                                        options={tradeinProPipeline}
                                                        name={"pipeline"}
                                                        placeholder={'select pipeline'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={pipeline}
                                                        classNamePrefix={`${this.state.errors["pipeline"]} basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            showOwner
                                                ?
                                                <div className="form-group col-md-12">
                                                    <label ><Translate text={'owner'} /></label>
                                                    <ReactSelect
                                                        options={_.orderBy(allUsers, ["searchlabel"], ["asc"])}
                                                        name={"owner"}
                                                        onChange={this.handleReactSelectChange}
                                                        value={owner}
                                                        classNamePrefix={`${this.state.errors["owner"]} basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                        <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}>
                            {this.isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'submit'} />
                        </button>
                    </Modal.Footer>
                </Modal>

            </>


        );
    }
}

