import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import moment from 'moment'
// import { combineLatest, of } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { collection } from 'rxfire/firestore';
import { ContentOverlay, AntDateRangePicker, ReactSelect, PopUpModal, ReactMultiSelect, PopoverPanel, BarChartCSS, LineChartCSS, InputAutoNumber } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import EnquiryList from '../../dashboard/enquiryList'
import TestDriveList from '../../dashboard/testdriveList'
import { CustomToggle, CustomFilter, CustomMenu } from '../../../components/customdropdown';
import ReactExport from 'react-export-excel-xlsx-fix';
import ActivityList from '../../dashboard/activitiesList'
import { reportVM, reportDynColumns, defaultFilters, reportGroupBy } from '../viewModel'
import Swal from 'sweetalert2'
import DynamicFilters from "../dynFilters";
import AddReport from '../addDynReport'
import ScheduleReport from '../scheduleReport';
import cronstrue from 'cronstrue';
import NumberFormat from 'react-number-format';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const leadTypes = [
    { value: 'all', label: 'All Leads' },
    { value: 'new', label: 'New Leads' },
    { value: 'existing', label: 'Existing Leads' },
    { value: 'existingCustomer', label: 'Existing Customers Leads' },
    { value: 'newExistingSold', label: 'New Leads with Existing Sold / Test Drives' }
]

const statisFilters = [
    'user',
    'pipelines',
    'makeModels',
    'salesType',
    'campaigns',
    'origins',
    'enquiryTypes',
    'leadSource',
];

const kpis = [
    //{ label: 'Enquiry Types', name: 'Enquiry Types', value: 'enquiryTypes', fieldValue: 'etv', fieldName: 'enquiryType', marketing: true },
    { label: 'Campaigns', name: 'Campaigns', value: 'campaigns', fieldValue: 'cpgv', fieldName: 'campaign', marketing: true, nested: 'leadSource', nestedName: 'Lead Source' },
    //{ label: 'Lead Origins', name: 'Lead Origins', value: 'origins', fieldValue: 'orgv', fieldName: 'origin', marketing: true },
    { label: 'Sales Person', name: 'Sales Person', value: 'user', fieldValue: 'uid', fieldName: 'owner' },
    { label: 'Model', name: 'Model', value: 'model', fieldValue: 'ml', fieldName: 'model', nested: 'models', nestedName: 'Models' }
];

const PerformanceWithTrend = (props) => {
    const [loader, setLoader] = useState(true)
    const [allEnquiries, setEnquiries] = useState([]);
    const [allTestdrives, setTestdrives] = useState([]);
    const [userOptions, setUserOptions] = useState([])
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const todayStart = window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)
    const todayEnd = window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)
    const [progressReport, setProgressReport] = useState([])
    const [dates, setDates] = useState([]);
    const [modelShow, setModelShow] = useState({
        enquiry: false,
        testdrive: false,
        activity: false
    })
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)
    const [selectedUser, setUser] = useState();
    const [refreshData, setRefreshData] = useState(true)
    const [refreshClientData, setRefreshClientData] = useState(false)
    const [sortConfig, setSortConfig] = useState();
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [salesTypes, setSalesTypes] = useState([])
    const [selectedMake, setSelectedMake] = useState()
    const [selectedModel, setSelectedModel] = useState()
    const [selectedDMSType, setSelectedDMSType] = useState()
    const [selectedEnqType, setSelectedEnqType] = useState()
    const [selectedOrigin, setSelectedOrigin] = useState()
    const [selectedLeadSource, setSelectedLeadSource] = useState()
    const [selectedCampaign, setSelectedCampaign] = useState()
    const [selectedSaleType, setSelectedSaleType] = useState()
    const [tempData, setTempData] = useState({
        enquiries: [],
        walkins: [],
        requirements: []
    })
    const [filterShow, setShow] = useState(false)
    const [showCount, setShowCount] = useState(false)
    const node = useRef();
    const [selectedKpi, setSelectedKpi] = useState({})
    const [csvData, setCSVData] = useState([])
    const [selectedLeadType, setLeadType] = useState()
    const [doneActivities, setDoneActivities] = useState([]);
    const [isInbound, setInbound] = useState();
    const [reportJSON, setReportJSON] = useState({})
    const [showModel, setShowmodel] = useState(false)
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [counts, setCounts] = useState([])
    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false
    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);
    const [objFilter, setObjFilter] = useState(defaultFilters)
    const enquiryStatus = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.enquiryStatus ? props.dealersettings.client.settings.enquiryStatus : [];

    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? 'true' : 'false');

    const multiTDperLead = props?.dealersettings?.client?.clientSettings?.multiTDperLead === true;
    const [schedueReport, setSchedeReport] = useState({});
    const [multiSchedule, setMultiSchedule] = useState([]);
    const [popover, setPopover] = useState({ show: false, target: null },)
    const [marketingCost, setMarketingCost] = useState({})
    const [textEditor, setTexteditor] = useState({})
    const [editFields, setEditFields] = useState({})
    const [ytdData, setYtdData] = useState([])
    const [nestedTbl, setNestedTbl] = useState({})

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const inclInboundBuckeinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundBuckeinRpt) ? true : false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        // console.log('lead by stage')
        let _filter = {
            clientID: props.dealersettings.client.id,
            timezone: props?.dealersettings?.client?.timezone || moment.tz.guess(),
            hideTradein: true,
            hideTestdrive: true,
            hideActivities: true,
            hideWalkin: true,
            scheduleActivities: false,
            dmsDate: true,
            inclInboundBuckeinRpt,
            inclInboundLeadsinRpt,
            startDate: moment().startOf('year').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD')
        }

        let _searchObject = {
            type: "getSummaryReport",
            params: JSON.stringify(_filter),
        }
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            setYtdData(resp.data.data);
        }
    }

    useEffect(() => {
        if (_.isEmpty(selectedKpi))
            return
        const refScheduleReport = window.firebase.firestore().doc(`schedular/kpi-${ownerDashboard ? localStorage.uid : props.dealersettings.client.id}-${selectedKpi.value}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setSchedeReport(doc.data())
                    if (doc.data().multiSchedule?.length > 0) {
                        let refPromise = [];
                        doc.data().multiSchedule.forEach(rpt => {
                            refPromise.push(window.firebase.firestore().doc(`schedular/${rpt}`).get())
                        })
                        Promise.all(refPromise)
                            .then(docs => {
                                let rpts = [];
                                docs.forEach(doc => {
                                    if (doc.exists) {
                                        let arrCron = [];
                                        if (doc.data().cron && doc.data().cron !== '* * * * *') {
                                            arrCron = doc.data().cron.split(' ');
                                        }
                                        rpts.push({
                                            ...doc.data(),
                                            schedulartime: arrCron.length > 0 ?
                                                window.firebase.firestore.Timestamp.fromDate(new Date(`${moment().format('YYYY-MM-DD')} ${arrCron[1]}:${arrCron[0]}`)) : null
                                        })
                                    }
                                })
                                setMultiSchedule([
                                    doc.data(),
                                    ...rpts
                                ]);
                            })
                    }
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [selectedKpi])

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) || e.target.classList.contains('ant-picker-cell-inner') || e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        if (_.isEmpty(progressReport))
            return

        let _headers = [];
        let csvStyle = CommonHelper.getCSVStyle();
        _headers.push(
            {
                title: CommonHelper.getCustomValue(reportGroupBy, reportJSON.groupBy, 'tableHeader', 'value'),
                dynValue: 'displayName',
                dynWidth: 10,
                ...csvStyle.header
            })

        reportJSON.columns.forEach((val) => {
            var cols = reportColumns.find(item => item.value === val)
            if (cols) {
                _headers.push({ title: cols.name, dynValue: val, dynWidth: cols.name.length, ...csvStyle.header })
            }
            else {
                _headers.push({ title: val, dynValue: val, dynWidth: val.toString().length, ...csvStyle.header })
            }
        })

        var exportData = []
        var totals = {
            inboundCall: _.sumBy(progressReport, "inboundCall"),
            outboundCall: _.sumBy(progressReport, "outboundCall"),
            totalCall: _.sumBy(progressReport, "totalCall"),
            messageLog: _.sumBy(progressReport, "messageLog"),
            emailLog: _.sumBy(progressReport, "emailLog"),
            noteLog: _.sumBy(progressReport, "noteLog"),
            appointmentScheduled: _.sumBy(progressReport, "appointmentScheduled"),
            testdriveScheduled: _.sumBy(progressReport, "testdriveScheduled"),
            callScheduled: _.sumBy(progressReport, "callScheduled"),
            mailScheduled: _.sumBy(progressReport, "mailScheduled"),
            deliveryScheduled: _.sumBy(progressReport, "deliveryScheduled"),
            appointment: _.sumBy(progressReport, "appointment"),
            testdriveActivity: _.sumBy(progressReport, "testdriveActivity"),
            callActivity: _.sumBy(progressReport, "callActivity"),
            mailActivity: _.sumBy(progressReport, "mailActivity"),
            deliveryActivity: _.sumBy(progressReport, "deliveryActivity"),
            appointmentsold: `${(_.sumBy(progressReport, "apptsold") > 0 ? Math.round((100 / _.sumBy(progressReport, "apptsold")) * _.sumBy(progressReport, "won")) : 0)}%`,
            appointmenttestdrive: `${(_.sumBy(progressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "tdappt")) : 0)}%`,
            testdrivetotestdrive: `${(_.sumBy(progressReport, "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(progressReport, "testdriveActivity")) * _.sumBy(progressReport, "tdtestdrive")) : 0)}%`,
            apptsold: _.sumBy(progressReport, "apptsold"),
            delivered: _.sumBy(progressReport, "delivered"),
            displayName: "Totals",
            exisitngLeads: _.sumBy(progressReport, "exisitngLeads"),
            id: "totals",
            lost: _.sumBy(progressReport, "lost"),
            name: "Totals",
            totalInbound: _.sumBy(progressReport, "totalInbound"),
            overallPending: _.sumBy(progressReport, "overallPending"),
            pendingLeads: _.sumBy(progressReport, "pendingLeads"),
            convertedLeads: _.sumBy(progressReport, "convertedLeads"),
            show: _.sumBy(progressReport, "show"),
            noshow: _.sumBy(progressReport, "noshow"),
            responseTime: (_.sumBy(progressReport, `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalresponseTime), props.dealersettings.client) : '00:00:00'),
            maxresponseTime: (_.sumBy(progressReport, `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxresponseTime), props.dealersettings.client) : '00:00:00'),
            minresponseTime: (_.sumBy(progressReport, `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminresponseTime), props.dealersettings.client) : '00:00:00'),
            newLeads: _.sumBy(progressReport, "newLeads"),
            open: _.sumBy(progressReport, "open"),
            tdappt: _.sumBy(progressReport, "tdappt"),
            tdtestdrive: _.sumBy(progressReport, "tdtestdrive"),
            testdrive: _.sumBy(progressReport, "testdrive"),
            testdriveConv: `${(_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "testdrive")) : 0)}%`,
            total: _.sumBy(progressReport, "total"),
            tradein: _.sumBy(progressReport, "tradein"),
            tradeincompleted: _.sumBy(progressReport, "tradeincompleted"),
            tradeininprogress: _.sumBy(progressReport, "tradeininprogress"),
            tradeinlost: _.sumBy(progressReport, "tradeinlost"),
            tradeinwon: _.sumBy(progressReport, "tradeinwon"),
            tradeinwonConv: `${(_.sumBy(progressReport, "tradein") > 0 ? Math.round((100 / _.sumBy(progressReport, "tradein")) * _.sumBy(progressReport, "tradeinwon")) : 0)}%`,
            walkin: _.sumBy(progressReport, "walkin"),
            newwalkin: _.sumBy(progressReport, "newwalkin"),
            repeatwalkin: _.sumBy(progressReport, "repeatwalkin"),
            carryover: _.sumBy(progressReport, "carryover"),
            doneActivity: _.sumBy(progressReport, "doneActivity"),
            overdueActivity: _.sumBy(progressReport, "overdueActivity"),
            walkinsold: `${(_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "won")) : 0)}%`,
            walkintestdrive: `${(_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "testdrive")) : 0)}%`,
            won: _.sumBy(progressReport, "won"),
            allocated: _.sumBy(progressReport, "allocated"),
            newCAP: _.sumBy(progressReport, "newCAP"),
            wonConv: `${(_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "won")) : 0)}%`,
            scheduledConv: `${(_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "appointmentScheduled")) : 0)}%`,
            showConv: `${(_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "show")) : 0)}%`,
            noshowConv: `${(_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "noshow")) : 0)}%`,
            convertedConv: `${(_.sumBy(progressReport, "totalInbound") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalInbound")) * _.sumBy(progressReport, "convertedLeads")) : 0)}%`,
            newLeadsConv: `${(_.sumBy(progressReport, "totalnewLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalnewLeads")) * _.sumBy(progressReport, "newLeads")) : 0)}%`,
            exisitngLeadsConv: `${(_.sumBy(progressReport, "totalexisitngLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalexisitngLeads")) * _.sumBy(progressReport, "exisitngLeads")) : 0)}%`,
            totalConv: `${(_.sumBy(progressReport, "totalLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalLeads")) * _.sumBy(progressReport, "total")) : 0)}%`,
            ...CommonHelper.getLeadsByEnquiryTypeTotal({ ...props, report: reportJSON }, progressReport, isInbound)
        };

        progressReport.forEach(rec => {
            let obj = rec;
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${obj[col.dynValue]}${col.percentage ? '%' : ''}`, ...csvStyle.cell, dynValue: col.dynValue, dynWidth: obj[col.dynValue] ? obj[col.dynValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        })
        if (exportData.length > 0) {
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${totals[col.dynValue]}`, ...csvStyle.footer, dynValue: col.dynValue, dynWidth: totals[col.dynValue] ? totals[col.dynValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        }
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                    ...h,
                    'width': { wch: w.dynWidth + 5 }
                } : {
                    ...h,
                    'width': { wch: h.dynWidth + 5 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData]
        }])
    }, [progressReport])

    useEffect(() => {
        let brands = props.dealersettings.client.brands;
        let makes = [];
        let types = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (selectedMake) {
            let _models = !_.isEmpty(makes.filter(m => m.value === selectedMake.value)[0]) ? makes.filter(m => m.value === selectedMake.value)[0].models : [];
            _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }
        setMakes(makes)
        setModels(models)
    }, [selectedMake])

    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return
        let _users = [];
        props.clientUsers.filter(a => Boolean(a.superAdmin) === false).forEach(rec => {
            _users.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>,
                data: rec,
                name: rec.name
            })
        })
        setUserOptions(_users)
    }, [props.clientUsers])

    useEffect(() => {
        if (_.isEmpty(props.dealersettings) || _.isEmpty(props.dealersettings.client))
            return

        if (!_.isEmpty(props.dealersettings.client.settings['salesType'])) {
            let _saleTypes = [];
            props.dealersettings.client.settings['salesType'].filter(a => a.active === true).map(rec => {
                return _saleTypes.push({
                    value: rec.value,
                    label: rec.name
                });
            })
            setSalesTypes(_saleTypes)
        }
    }, [props.dealersettings])

    useEffect(() => {
        setSelectedKpi(kpis[0])

        setReportColumns([...reportColumns.filter(a => _.isEmpty(a.sourceType)).map(rec => {
            return rec.status === true && _.find(enquiryStatus, { value: rec.value }) ? {
                ...rec,
                name: `${_.find(enquiryStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                label: `${_.find(enquiryStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
            } : rec.status === true && props.dealersettings.isPorsche === true ? {
                ...rec,
                name: `${rec.name} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
                label: `${rec.label} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
            } : { ...rec }
        }), ...CommonHelper.getReportColumns({ ...props, report: reportJSON })]);

        window.firebase.firestore().doc(`reports/kpi-${props.dealersettings.client.id}`)
            .onSnapshot(snap => {
                if (snap.exists) {
                    setReportJSON({
                        ...snap.data(),
                        documentID: snap.id,
                        columns: _.uniq(['allocated', 'newCAP', 'won']),
                        pipeline: _.map(props.pipeline.filter(item => !Boolean(item.disableReport)), function (e) { return e.value; })
                    });
                    setRefreshData(true);
                    setInbound((snap.data().pipeline
                        && snap.data().pipeline.some(b => b === 'LeadsBucket')
                        && snap.data().pipeline.length === 1) ? true : false)
                }
                else {
                    const newReportVM = Object.assign({}, reportVM);
                    newReportVM.documentID = `kpi-${props.dealersettings.client.id}`;
                    newReportVM.name = 'Trend Analysis Report';
                    newReportVM.clientID = props.dealersettings.client.id;
                    newReportVM.reportType = 'table';
                    newReportVM.groupBy = selectedKpi?.value || kpis[0].value;
                    newReportVM.groupSubtype = ['all'];
                    newReportVM.columns = _.uniq(['allocated', 'newCAP', 'won']);
                    newReportVM.pipeline = _.map(props.pipeline.filter(item => !Boolean(item.disableReport)), function (e) { return e.value; });
                    newReportVM.static = true;
                    newReportVM.visibility = 'shared';
                    setReportJSON(newReportVM)
                    setInbound((props.pipeline
                        && props.pipeline.some(b => b === 'LeadsBucket')
                        && props.pipeline.length === 1) ? true : false)
                }
            })


    }, [])

    useEffect(() => {
        if (!refreshData || _.isEmpty(reportJSON))
            return

        setLoader(true)
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: props?.dealersettings?.mvwReport ? "getDynamicReportV2" : "getDynamicReport",
                params: JSON.stringify({
                    clientID: props.dealersettings.client.id,
                    reportJSON: JSON.stringify({
                        ...reportJSON,
                        groupBy: selectedKpi.value,
                        groupSubtype: ['all'],
                        modelGroupBy: 'group'
                    }),
                    startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
                    leadType: selectedLeadType ? selectedLeadType.value : '',
                    calcCurrentStatus,
                    multiTDperLead,
                    ...{
                        ...objFilter,
                        salesType: (_.isEmpty(objFilter.salesType) && _.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) ?
                            props.dealersettings.accessSalesType : objFilter.salesType,
                        user: ownerDashboard ? [localStorage.uid] : objFilter.user,
                        isPorsche: props.dealersettings.isPorsche
                    }
                })
            }).then((response) => {
                setLoader(false)
                setRefreshData(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }
    }, [refreshData, reportJSON])

    useEffect(() => {
        window.firebase.firestore().doc(`reports/marketing-${props.dealersettings.client.id}`)
            .onSnapshot(snap => {
                if (snap.exists) {
                    setMarketingCost(snap.data())
                } else {
                    window.firebase.firestore().doc(`reports/marketing-${props.dealersettings.client.id}`).set({
                        documentID: `marketing-${props.dealersettings.client.id}`,
                        clientID: props.dealersettings.client.id,
                        name: 'Marketing ROI Report',
                        marketing: true
                    }, { merge: true })
                }
            })
    }, [])

    const loadReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'][0][props.dealersettings.client.id] : []
        let reports = [];
        _report.forEach(rec => {
            if (Object.keys(rec).filter(r => _.isArray(rec[r])).some(r => rec[r].length > 0)) {
                reports.push({
                    ...rec,
                    id: selectedKpi.value === 'model' ? rec.name : rec.id,
                    arrcarryover: rec.arrcarryover ? rec.arrcarryover.filter(e => rec.arrexisitngLeads ? !rec.arrexisitngLeads.some(a => a === e) : true) : 0,
                    profileImage: selectedKpi.value === 'user' && _.find(props.clientUsers, { id: rec.id }) ? _.find(props.clientUsers, { id: rec.id }).profileImage : '',
                    newLeads: rec.arrnewLeads ? rec.arrnewLeads.length : 0,
                    exisitngLeads: rec.arrexisitngLeads ? rec.arrexisitngLeads.length : 0,
                    pendingLeads: rec.arrpendingLeads ? rec.arrpendingLeads.length : 0,
                    totalInbound: rec.arrtotalInbound ? rec.arrtotalInbound.length : 0,
                    overallPending: rec.arroverallPending ? rec.arroverallPending.length : 0,
                    convertedLeads: rec.arrconvertedLeads ? rec.arrconvertedLeads.length : 0,
                    show: rec.arrshow ? rec.arrshow.length : 0,
                    showCount: rec.arrshow ? rec.arrshow.length : 0,
                    noshow: rec.arrnoshow ? rec.arrnoshow.length : 0,
                    //avg response time
                    arrresponseLeads: rec.arrresponseTime ? _.uniq(rec.arrresponseTime.map(r => { return r.documentID })) : [],
                    totalresponseTime: _.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0,
                    responseTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0, props.dealersettings.client, (reportJSON.reportType === 'table' ? '' : 'seconds')),
                    //max reposnse time
                    arrmaxresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                    arrmaxresponseLeads: _.maxBy(rec.arrresponseTime, 'responseTime') ? [_.maxBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                    totalmaxresponseTime: _.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                    maxresponseTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0), props.dealersettings.client, (reportJSON.reportType === 'table' ? '' : 'seconds')),
                    //min response time
                    arrminresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                    arrminresponseLeads: _.minBy(rec.arrresponseTime, 'responseTime') ? [_.minBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                    totalminresponseTime: _.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                    minresponseTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0), props.dealersettings.client, (reportJSON.reportType === 'table' ? '' : 'seconds')),
                    open: rec.arropen ? rec.arropen.length : 0,
                    statusModified: rec.arrstatusModified ? rec.arrstatusModified.length : 0,
                    //won: rec.arrwon ? rec.arrwon.length : 0,
                    arrwon: rec.arrwon ? _.uniq(rec.arrwon.map(r => { return r.documentID })) : [],
                    won: rec.arrwon ? _.sumBy(rec.arrwon, "soldCount") : 0,
                    arrdelivered: rec.arrdelivered ? _.uniq(rec.arrdelivered.map(r => { return r.documentID })) : [],
                    delivered: rec.arrdelivered ? _.sumBy(rec.arrdelivered, "soldCount") : 0,
                    lost: rec.arrlost ? rec.arrlost.length : 0,
                    prospectLost: rec.arrprospectLost ? rec.arrprospectLost.length : 0,
                    leadLost: rec.arrleadLost ? rec.arrleadLost.length : 0,
                    totalLost: rec.arrtotalLost ? rec.arrtotalLost.length : 0,
                    //delivered: rec.arrdelivered ? rec.arrdelivered.length : 0,
                    walkin: rec.arrwalkin ? rec.arrwalkin.length : 0,
                    newwalkin: rec.arrnewwalkin ? rec.arrnewwalkin.length : 0,
                    repeatwalkin: rec.arrrepeatwalkin ? rec.arrrepeatwalkin.length : 0,
                    testdrive: rec.arrtestdrive ? rec.arrtestdrive.length : 0,
                    tradein: rec.arrtradein ? rec.arrtradein.length : 0,
                    tradeinwon: rec.arrtradeinwon ? rec.arrtradeinwon.length : 0,
                    tradeininprogress: rec.arrtradeininprogress ? rec.arrtradeininprogress.length : 0,
                    tradeincompleted: rec.arrtradeincompleted ? rec.arrtradeincompleted.length : 0,
                    tradeinlost: rec.arrtradeinlost ? rec.arrtradeinlost.length : 0,
                    inboundCall: rec.arrinboundCall ? rec.arrinboundCall.length : 0,
                    outboundCall: rec.arroutboundCall ? rec.arroutboundCall.length : 0,
                    totalCall: rec.arrtotalCall ? rec.arrtotalCall.length : 0,
                    messageLog: rec.arrmessageLog ? rec.arrmessageLog.length : 0,
                    emailLog: rec.arremailLog ? rec.arremailLog.length : 0,
                    noteLog: rec.arrnoteLog ? rec.arrnoteLog.length : 0,
                    appointmentScheduled: rec.arrappointmentScheduled ? rec.arrappointmentScheduled.length : 0,
                    testdriveScheduled: rec.arrtestdriveScheduled ? rec.arrtestdriveScheduled.length : 0,
                    callScheduled: rec.arrcallScheduled ? rec.arrcallScheduled.length : 0,
                    mailScheduled: rec.arrmailScheduled ? rec.arrmailScheduled.length : 0,
                    deliveryScheduled: rec.arrdeliveryScheduled ? rec.arrdeliveryScheduled.length : 0,
                    appointment: rec.arrappointment ? rec.arrappointment.length : 0,
                    testdriveActivity: rec.arrtestdriveActivity ? rec.arrtestdriveActivity.length : 0,
                    callActivity: rec.arrcallActivity ? rec.arrcallActivity.length : 0,
                    mailActivity: rec.arrmailActivity ? rec.arrmailActivity.length : 0,
                    deliveryActivity: rec.arrdeliveryActivity ? rec.arrdeliveryActivity.length : 0,
                    total: rec.arrtotal ? rec.arrtotal.length : 0,
                    tdappt: rec.arrtdappt ? rec.arrtdappt.length : 0,
                    tdtestdrive: rec.arrtdtestdrive ? rec.arrtdtestdrive.length : 0,
                    apptsold: rec.arrapptsold ? rec.arrapptsold.length : 0,
                    target: reportJSON.target ? reportJSON.target : 0,
                    totalnewLeads: rec.arrdataQualitynewLeads ? rec.arrdataQualitynewLeads.length : 0,
                    totalexisitngLeads: rec.arrdataQualityexisitngLeads ? rec.arrdataQualityexisitngLeads.length : 0,
                    totalLeads: rec.arrdataQualitytotal ? rec.arrdataQualitytotal.length : 0,
                    doneActivity: rec.arrdoneActivity ? rec.arrdoneActivity.length : 0,
                    overdueActivity: rec.arroverdueActivity ? rec.arroverdueActivity.length : 0,
                    allocated: rec.arrallocated ? rec.arrallocated.length : 0,
                    newCAP: rec.arrnewCAP ? rec.arrnewCAP.length : 0,
                    lostEnq: rec.arrlostEnq ? rec.arrlostEnq.length : 0,
                    ...CommonHelper.getDynamicTotalLength({ ...props, report: reportJSON }, rec, isInbound)
                })
            }
        });

        reports = reports.map(rec => {
            return {
                ...rec,
                carryover: rec.arrcarryover.length,
                walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
                walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
                appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
                testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
                appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
                testdriveConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.testdrive) : 0,
                tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
                wonConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.won) : 0,
                scheduledConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.appointmentScheduled) : 0,
                showConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.show) : 0,
                noshowConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.noshow) : 0,
                convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
                newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
                exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
                totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,
                leadsbyEnq: rec.allocated > 0 ? Math.round((100 / rec.allocated) * rec.newCAP) : 0,
                wonbyLeads: rec.newCAP > 0 ? Math.round((100 / rec.newCAP) * rec.won) : 0,
            }
        })

        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkintestdrive > 100 ? 100 : rec.walkintestdrive,
                walkinsold: rec.walkinsold > 100 ? 100 : rec.walkinsold,
                wonConv: rec.wonConv > 100 ? 100 : rec.wonConv,
                testdriveConv: rec.testdriveConv > 100 ? 100 : rec.testdriveConv,
                leadsbyEnq: rec.leadsbyEnq > 100 ? 100 : rec.leadsbyEnq,
                wonbyLeads: rec.wonbyLeads > 100 ? 100 : rec.wonbyLeads
            }
        })
        // reportJSON.columns.forEach(col => {
        //     if (!reportColumns.find(val => val.value === col)) {
        //         reports = reports.map(rec => {
        //             return {
        //                 ...rec,
        //                 [col]: 0
        //             }
        //         });
        //     }
        // })
        setProgressReport(_.orderBy(reports, 'allocated', 'desc'))
    }

    const checkDataFilter = (_data, fieldName, startDt, endDt, status, dateType, newlead, saleType, dataReturn) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won' || a.sts.toLowerCase() === 'delivered')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));
        //return _.uniqBy(filterData, fieldName).map(r => { return r[fieldName] }).length

        return {
            arractual: _.uniqBy(filterData, fieldName).map(r => { return r[fieldName] }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, fieldName), 'wc') : _.uniqBy(filterData, fieldName).length,
            data: dataReturn === true ? filterData : [],
        }
    }

    const getChartData = (data, rec, cost, fieldValue) => {
        //console.log('getBarData1', props.id, props?.dashboardFilter, data)
        let start = moment().add('month', -12).startOf('month');
        let end = moment().endOf('month');
        let months = [];
        while (start <= end) {
            months.push(start.format('MMM YYYY'));
            start.add(1, 'month');
        }
        const filterData = data.filter((a) => a.stgv !== 'Archived');
        //console.log('filterData', props.id, props?.dashboardFilter, filterData)
        let _data = []
        months.forEach(month => {
            // Create a moment object from the short month name and year
            let startDate = moment(month, 'MMM YYYY');
            // Get the first day of the month
            let startOfMonth = startDate.startOf('month').startOf('day').unix()
            // Get the last day of the month
            let endOfMonth = startDate.endOf('month').endOf('day').unix();

            let _datFormat = {
                allocated: checkDataFilter(filterData.filter((a) => a.col === 'enq' && (rec.id === 'others' ? _.isEmpty(a[fieldValue]) : a[fieldValue] === rec.id) && a.conv === 'true'), 'eid', startOfMonth, endOfMonth),
                newCAP: checkDataFilter(filterData.filter((a) => a.col === 'enq' && (rec.id === 'others' ? _.isEmpty(a[fieldValue]) : a[fieldValue] === rec.id) && a.tags && JSON.parse(a.tags).some(b => b.type === 'cap')), 'eid', startOfMonth, endOfMonth, null, 'dmsdt'),
                won: checkDataFilter(filterData.filter((a) => a.col === 'enq' && (rec.id === 'others' ? _.isEmpty(a[fieldValue]) : a[fieldValue] === rec.id) && a.tags && JSON.parse(a.tags).some(b => b.type === 'cap')), 'eid', startOfMonth, endOfMonth, 'sold', 'wd'),

            }
            let _obj = { month: startDate.format('MMM YYYY') }
            for (let [key, value] of Object.entries(_datFormat)) {
                _obj[key] = value.actual;
                _obj[`arr${key}`] = value.arractual;
            }
            _data.push(_obj)
        })
        _data = _data.map(rec => {
            return {
                ...rec,
                costperEnq: cost > 0 && rec.allocated > 0 ? _.round(cost / rec.allocated, 2) : 0,
                costperLead: cost > 0 && rec.newCAP > 0 ? _.round(cost / rec.newCAP, 2) : 0,
                costperWon: cost > 0 && rec.won > 0 ? _.round(cost / rec.won, 2) : 0,
            }
        })
        _data = _data.map(rec => {
            return {
                ...rec,
                allocatedPtg: _.maxBy(_data, 'allocated')?.allocated > 0 ? Math.round((100 / _.maxBy(_data, 'allocated')?.allocated) * rec.allocated) : 0,
                newCAPPtg: _.maxBy(_data, 'newCAP')?.newCAP > 0 ? Math.round((100 / _.maxBy(_data, 'newCAP')?.newCAP) * rec.newCAP) : 0,
                wonPtg: _.maxBy(_data, 'won')?.won > 0 ? Math.round((100 / _.maxBy(_data, 'won')?.won) * rec.won) : 0,
                costperWonPtg: _.maxBy(_data, 'costperWon')?.costperWon > 0 ? Math.round((100 / _.maxBy(_data, 'costperWon')?.costperWon) * rec.costperWon) : 0,
                costperLeadPtg: _.maxBy(_data, 'costperLead')?.costperLead > 0 ? Math.round((100 / _.maxBy(_data, 'costperLead')?.costperLead) * rec.costperLead) : 0,
                costperEnqPtg: _.maxBy(_data, 'costperEnq')?.costperEnq > 0 ? Math.round((100 / _.maxBy(_data, 'costperEnq')?.costperEnq) * rec.costperEnq) : 0,
                leadsbyEnqPtg: rec.allocated > 0 ? Math.round((100 / rec.allocated) * rec.newCAP) : 0,
                wonbyLeadsPtg: rec.newCAP > 0 ? Math.round((100 / rec.newCAP) * rec.won) : 0,
                leadsbyEnq: rec.allocated > 0 ? Math.round((100 / rec.allocated) * rec.newCAP) : 0,
                wonbyLeads: rec.newCAP > 0 ? Math.round((100 / rec.newCAP) * rec.won) : 0,

            }
        })
        //console.log('getBarData', props.id, _data)
        return _data;
    }

    const getNestedData = (yData, data, field, fieldName, fieldValue) => {
        // console.log(yData)
        // console.log(data)
        let _data = [];
        const colKeys = ['allocated', 'newCAP', 'won'];
        colKeys.forEach(col => {
            _.chain(yData.map(r => { return { ...r, [field]: r[field] || 'others' } }))
                .groupBy(field)
                .map((value, key) => ({
                    key,
                    value,
                    name: value[0]?.[fieldName] || 'Others/Blank'
                }))
                .value().forEach(rec => {
                    const colData = rec?.value?.filter(r => data?.[`arr${col}`]?.includes(r.eid)) || []
                    if (_.find(_data, { id: rec.key })) {
                        _.assign(_.find(_data, { id: rec.key }), {
                            [col]: colData.length,
                            [`arr${col}`]: colData.map(r => { return r.eid })
                        });
                    } else {
                        _data.push({
                            id: rec.key,
                            name: rec.name,
                            displayName: rec.name,
                            [col]: colData.length,
                            [`arr${col}`]: colData.map(r => { return r.eid })
                        })
                    }

                })
        })
        //console.log(_data)
        _data = _data.map(rec => {
            const _cost = marketingCost?.[`${fieldValue}Cost`]?.[`${rec.id}`]?.['totalCost'] || 0;
            return {
                ...rec,
                leadsbyEnq: rec.allocated > 0 ? Math.round((100 / rec.allocated) * rec.newCAP) : 0,
                wonbyLeads: rec.newCAP > 0 ? Math.round((100 / rec.newCAP) * rec.won) : 0,
                trendData: getChartData(ytdData.filter(a => data.id === 'others' ? _.isEmpty(a[selectedKpi.fieldValue]) : a[selectedKpi.fieldValue] === data.id), rec, null, field),
                totalCost: _cost,
                costperEnq: _cost > 0 && rec.allocated > 0 ? _.round(_cost / rec.allocated, 2) : 0,
                costperLead: _cost > 0 && rec.newCAP > 0 ? _.round(_cost / rec.newCAP, 2) : 0,
                costperWon: _cost > 0 && rec.won > 0 ? _.round(_cost / rec.won, 2) : 0,
            }
        })
        return _.orderBy(_data.filter(a => a.allocated > 0 || a.newCAP > 0 || a.won > 0), 'allocated', 'desc');
    }

    const sortedItems = useMemo(() => {
        const filterData = ytdData.filter((a) => a.stgv !== 'Archived'
            && (objFilter?.user?.length ? a.st && objFilter.user.includes(a.uid) : true)
            && (objFilter?.model?.length ? a.ml && objFilter.model.some(b => b.toLowerCase() === a.ml.toLowerCase()) : true)
            && (objFilter?.salesType?.length ? a.st && objFilter.salesType.some(b => b.toLowerCase() === a.st.toLowerCase()) : true)
            && (objFilter?.make ? a.mk && objFilter.make.toLowerCase() === a.mk.toLowerCase() : true)
            && ((objFilter?.pipelines?.length) ? a.plnv && objFilter.pipelines.includes(a.plnv) : true)
            && ((objFilter?.campaigns?.length) ? a.cpgv && objFilter.campaigns.includes(a.cpgv) : true)
            && ((objFilter?.origins?.length) ? a.orgv && objFilter.origins.includes(a.orgv) : true)
            && ((objFilter?.enquiryTypes?.length) ? a.etv && objFilter.enquiryTypes.includes(a.etv) : true)
            && ((objFilter?.leadSource?.length) ? a.lsv && objFilter.leadSource.includes(a.lsv) : true)
        );
        let sortableItems = [...progressReport.map(rec => {
            return {
                ...rec,
                origins: getNestedData(filterData, rec, 'orgv', 'org', 'origins'),
                leadSource: getNestedData(filterData, rec, 'lsv', 'ls', 'leadSource'),
                campaigns: getNestedData(filterData, rec, 'cpgv', 'cpg', 'campaigns'),
                models: getNestedData(filterData, rec, 'ml', 'ml')
            }
        })];
        sortableItems = sortableItems.map(rec => {
            const _cost = rec?.leadSource?.length ? _.sumBy(rec?.leadSource, 'totalCost') : marketingCost?.[`${selectedKpi.value}Cost`]?.[`${rec.id}`]?.['totalCost'] || 0;
            return {
                ...rec,
                totalCost: _cost,
                costperEnq: _cost > 0 && rec.allocated > 0 ? _.round(_cost / rec.allocated, 2) : 0,
                costperLead: _cost > 0 && rec.newCAP > 0 ? _.round(_cost / rec.newCAP, 2) : 0,
                costperWon: _cost > 0 && rec.won > 0 ? _.round(_cost / rec.won, 2) : 0,
                trendData: getChartData(filterData, rec, _cost, selectedKpi.fieldValue),
            }
        })
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        console.log(sortableItems)
        return sortableItems;
    }, [progressReport, sortConfig, marketingCost, ytdData]);

    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }
    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    const handleModelClose = async (report) => {
        setUser(null)
        setFilter(null)
        setShowmodel(false)
        setModelShow({
            enquiry: false,
            testdrive: false,
            activity: false
        })
        if (report) {
            await window.firebase.firestore().doc(`schedular/kpi-${ownerDashboard ? localStorage.uid : props.dealersettings.client.id}-campaigns`)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        doc.ref.update({
                            reportJSON: {
                                ...report,
                                columns: _.uniq(['totalInbound', 'overallPending', 'pendingLeads', 'convertedLeads', ...report.columns])
                            }
                        })
                    }
                })
            await window.firebase.firestore().doc(`schedular/kpi-${ownerDashboard ? localStorage.uid : props.dealersettings.client.id}-origins`)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        doc.ref.update({
                            reportJSON: {
                                ...report,
                                columns: _.uniq(['totalInbound', 'overallPending', 'pendingLeads', 'convertedLeads', ...report.columns])
                            }
                        })
                    }
                })
            await window.firebase.firestore().doc(`schedular/kpi-${ownerDashboard ? localStorage.uid : props.dealersettings.client.id}-enquiryTypes`)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        doc.ref.update({
                            reportJSON: {
                                ...report,
                                columns: _.uniq(['totalInbound', 'overallPending', 'pendingLeads', 'convertedLeads', ...report.columns])
                            }
                        })
                    }
                })
        }
    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const totaltestdrives = (testdrives) => {
        var _enq = [];
        testdrives.forEach(e => {
            if (e.arrtestdrive) {
                _enq = _enq.concat(e.arrtestdrive)
            }
        })
        return _.uniq(_enq).length
    }

    const filterCounts = () => {

        var FilterCount = Object.keys(_.pickBy(objFilter, function (value, key) {
            return !_.isEmpty(value);
        }));

        return (FilterCount.length > 0 && showCount) ? (<span className="badge badge-pill badge-advanced-filter">{FilterCount.length}</span>) : (<></>)
    }

    const saveEnquiryField = (name, value) => {
        const _valus = document.getElementById(`totalCost-${textEditor.value}`)?.value?.replace(/\D/g, '') || value;
        window.firebase.firestore().doc(`reports/${marketingCost.documentID}`).update({
            [`${textEditor.groupKey}Cost.${textEditor.value}.${name}`]: Number(_valus)
        }).then(snapshot => {
            setTexteditor({});
            setEditFields(null);
        });
    }

    const cancelEnquiryField = (name, value) => {
        setTexteditor({});
        setEditFields(null);
    }

    const handleNumberChange = (e) => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.floatValue;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            //setEditFields(number);
        }
    };

    const handleShowList = (e, data, key) => {
        e.preventDefault();
        if (data[`arr${key}`]?.length) {
            setCounts(data[`arr${key}`] ? data[`arr${key}`].length : 0)
            setFilter({
                documentIDs: data[`arr${key}`]
            })
            setPopHeader('Enquiry List')
            setModelShow({
                ...modelShow,
                enquiry: true
            })
        }
    }

    const NestedTbl = ({ data, field, fieldName }) => {
        return <tr className={`emp-tr-hover-fix ${nestedTbl[data.id] === true ? '' : 'hide'}`}>
            <td colSpan={selectedKpi.marketing ? 16 : 12} style={{ padding: ` 0.5rem !important` }}>
                <div className="common-table emp-table">
                    <table className="table table-bordered mb-0">
                        <thead>
                            <tr>
                                <th width="18%" colspan="2" rowspan="3" className="emp-header-bg" scope="col">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        //requestSort('name')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'name' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        {fieldName}
                                    </div></th>
                                <th colspan="2" className="emp-header-bg text-center" scope="col">New Enquiries</th>
                                <th colspan="2" className="emp-header-bg text-center" scope="col">Leads Created</th>
                                <th colspan="2" className="emp-header-bg text-center" scope="col">Leads Won</th>
                                <th colspan="4" className="emp-header-bg text-center" scope="col">Conversion Rates</th>
                                {
                                    selectedKpi.marketing ? (
                                        <th colspan="5" className="emp-header-bg text-center" scope="col">Cost</th>

                                    ) : (<></>)
                                }
                            </tr>
                            <tr>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        //requestSort('allocated')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'allocated' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        Total</div>
                                </th>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">Trend</th>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        //requestSort('newCAP')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'newCAP' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        Total</div>
                                </th>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">Trend</th>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        //requestSort('won')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'won' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>Total</div>

                                </th>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">Trend</th>
                                <th width="5%" colspan="2" className="head-light text-center" scope="col">Leads/Enquiries</th>
                                <th width="5%" colspan="2" className="head-light text-center" scope="col">Won/ Created</th>
                                {
                                    selectedKpi.marketing ? (<>
                                        <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                e.preventDefault()
                                                //requestSort('totalCost')
                                            }}>
                                                <div className="table-sort"><a href="#">
                                                    {
                                                        sortConfig && sortConfig.key === 'totalCost' ?
                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                    }
                                                </a>
                                                </div>{fieldName} Investment</div>
                                        </th>
                                        <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                e.preventDefault()
                                                //requestSort('costperEnq')
                                            }}>
                                                <div className="table-sort"><a href="#">
                                                    {
                                                        sortConfig && sortConfig.key === 'costperEnq' ?
                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                    }
                                                </a>
                                                </div>Cost per enquiry</div>
                                        </th>
                                        <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                e.preventDefault()
                                                //requestSort('costperLead')
                                            }}>
                                                <div className="table-sort"><a href="#">
                                                    {
                                                        sortConfig && sortConfig.key === 'costperLead' ?
                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                    }
                                                </a>
                                                </div>Cost per lead</div></th>
                                        <th width="5%" rowspan="2" className="head-light text-center" scope="col"> <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            //requestSort('costperWon')
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === 'costperWon' ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>Cost per won</div>
                                        </th>
                                    </>


                                    ) : (<></>)
                                }
                            </tr>
                            <tr>
                                <th scope="col" className="head-light text-center" width="5%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        //requestSort('leadsbyEnq')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'leadsbyEnq' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>Total</div>
                                </th>
                                <th scope="col" className="head-light text-center" width="5%">Trend</th>
                                <th scope="col" className="head-light text-center" width="5%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        //requestSort('wonbyLeads')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'wonbyLeads' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>Total</div>
                                </th>
                                <th scope="col" className="head-light text-center" width="5%">Trend</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.[field]?.length > 0 ?
                                    data[field].map((rec, index) => {
                                        return <tr key={index}>
                                            <td className="text-left head-light" width="2%">{index + 1}</td>
                                            <td className='head-light' width={'15.5%'}>
                                                {
                                                    selectedKpi.value === 'user' ? (<div className="table-user-list">
                                                        <a href="#">
                                                            <div className="table-user-avatar">
                                                                <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                                            {rec.displayName}
                                                        </a>
                                                    </div>) : (
                                                        <div className="dealership-tbl">
                                                            {rec.displayName}
                                                        </div>
                                                    )
                                                }
                                            </td>
                                            <td className="text-center emp-border-none cursor-pointer" onClick={(e) => handleShowList(e, rec, 'allocated')}>{rec.allocated}</td>
                                            <td className="text-center emp-border-none p-0">
                                                <BarChartCSS data={rec.trendData} field={'allocated'} handleShowList={handleShowList} />
                                            </td>
                                            <td className="text-center emp-border-none cursor-pointer" onClick={(e) => handleShowList(e, rec, 'newCAP')}>{rec.newCAP}</td>
                                            <td className="text-center emp-border-none p-0">
                                                <BarChartCSS data={rec.trendData} field={'newCAP'} handleShowList={handleShowList} />
                                            </td>
                                            <td className="text-center emp-border-none cursor-pointer" onClick={(e) => handleShowList(e, rec, 'won')}>{rec.won}</td>
                                            <td className="text-center emp-border-none p-0">
                                                <BarChartCSS data={rec.trendData} field={'won'} handleShowList={handleShowList} />
                                            </td>
                                            <td className="text-center emp-border-none">{rec.leadsbyEnq}%</td>
                                            <td className="text-center emp-border-none p-0">
                                                <LineChartCSS data={rec.trendData} field={'leadsbyEnq'} />
                                            </td>
                                            <td className="text-center emp-border-none">{rec.wonbyLeads}%</td>
                                            <td className="text-center emp-border-none p-0">
                                                <LineChartCSS data={rec.trendData} field={'wonbyLeads'} />
                                            </td>
                                            {
                                                selectedKpi.marketing ? (<>
                                                    <td className="text-center emp-border-none">
                                                        {
                                                            textEditor[`totalCost-${rec.id}`] ? (<div className='form-style'><InputAutoNumber
                                                                id={`totalCost-${rec.id}`}
                                                                name={`totalCost`}
                                                                allowNegative={false}
                                                                thousandSeparator={true}
                                                                placeholder={''}
                                                                prefix={currencySymbol}
                                                                className={`form-control mtdreport-input`}
                                                                onValueChange={(e) => handleNumberChange(e)}
                                                                value={editFields}
                                                                onSaveField={saveEnquiryField}
                                                                onCancelField={cancelEnquiryField}
                                                                returnDefault={true}
                                                            /></div>) : (<> <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                                displayType={'text'}
                                                                value={rec.totalCost || 0}
                                                            /><span className="mtdreport-edit-span" onClick={(e) => {
                                                                e.preventDefault();
                                                                setEditFields(rec.totalCost)
                                                                setTexteditor({
                                                                    groupKey: field,
                                                                    value: rec.id,
                                                                    [`totalCost-${rec.id}`]: true
                                                                })
                                                            }}><i className="ico icon-edit"></i></span></>)
                                                        }
                                                    </td>
                                                    <td className="text-center emp-border-none ">
                                                        <NumberFormat
                                                            thousandSeparator={true}
                                                            prefix={currencySymbol}
                                                            displayType={'text'}
                                                            value={rec.costperEnq || 0}
                                                        />
                                                    </td>
                                                    <td className="text-center emp-border-none ">
                                                        <NumberFormat
                                                            thousandSeparator={true}
                                                            prefix={currencySymbol}
                                                            displayType={'text'}
                                                            value={rec.costperLead || 0}
                                                        />
                                                    </td>
                                                    <td className="text-center emp-border-none ">
                                                        <NumberFormat
                                                            thousandSeparator={true}
                                                            prefix={currencySymbol}
                                                            displayType={'text'}
                                                            value={rec.costperWon || 0}
                                                        />
                                                    </td>
                                                    {/* <td className="text-center emp-border-none p-0">
                                                            <LineChartCSS data={rec.trendData} field={'costperWon'} />
                                                        </td> */}
                                                </>


                                                ) : (<></>)
                                            }
                                        </tr>
                                    }) : (
                                        <tr>
                                            <td colSpan={12}>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                        <div className="no-data-txt mt-2"> No data</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>
            </td>
        </tr>
    }

    return (
        <>
            <div className="report-page-head">
                <h2>Trend Analysis Report</h2>
                {/* <Dropdown className='enquiry-view-more float-right mt-0'>
                    <Dropdown.Toggle as={CustomToggle} className='common-button'>
                        <i className='ico icon-more'></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        as={CustomMenu}
                        ChildclassName="more-dropdown dropsub-fix"
                        xplacement="bottom-end"
                    >
                        {
                            !ownerDashboard || props.dealersettings.roleName === 'Administrator' ? (
                                <Dropdown.Item eventKey="1" onClick={(e) => { setShowmodel(true) }}><i className="ico icon-edit"></i> Edit </Dropdown.Item>
                            ) : (<></>)
                        }
                        {
                            <Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-excel'}>
                                <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i> Export Excel</a>} filename={`lead-progress-kpi`}>
                                    <ExcelSheet dataSet={csvData} name="Sheet1" />
                                </ExcelFile>
                            </Dropdown.Item>
                        }
                        <Dropdown.Item eventKey="4" onClick={(e) => {
                            setModelShow({
                                ...modelShow,
                                sheduleReport: true
                            })
                        }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                {
                    !_.isEmpty(schedueReport) ? (
                        <div className="reportsuccess-item-btn mr-2 float-right cursor-pointer" onClick={(e) => {
                            if (schedueReport?.multiSchedule?.length > 0) {
                                setPopover({ show: true, target: e.target })
                            }
                        }}>
                            <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                            <div className="reportsuccess-inner-text">
                                <div className="reportsuccess-inner-text-top"> Scheduled </div>
                                {
                                    schedueReport?.multiSchedule?.length > 0 ? (
                                        <div className="reportsuccess-inner-text-sub">{`${schedueReport.multiSchedule.length + 1} Scheduler is Active`}</div>
                                    ) : (
                                        <div className="reportsuccess-inner-text-sub">{`${schedueReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(schedueReport.cron)}`}</div>
                                    )
                                }
                            </div>
                            <div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
                        </div>
                    ) : (<></>)
                }
            </div>
            <div className="divider-line"></div>
            <div className="report-content-head">

                <div className="row">
                    <div className="col-sm-12 col-md-9">
                        <div className="form-style d-block">
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <ReactSelect
                                        options={kpis}
                                        name={"kpis"}
                                        placeholder={'select kpi'}
                                        onChange={(e, data) => {
                                            setSelectedKpi(e)
                                            setRefreshData(true)
                                        }}
                                        value={!_.isEmpty(selectedKpi) ? selectedKpi.value : ''}
                                        classNamePrefix={`cursor-pointer basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                {/* <div className="form-group col-md-3">
                                    <ReactSelect
                                        options={leadTypes}
                                        name={"leadType"}
                                        placeholder={'select lead type'}
                                        onChange={(e, data) => {
                                            setLeadType(e)
                                            setRefreshData(true)
                                        }}
                                        value={!_.isEmpty(selectedLeadType) ? selectedLeadType.value : 'all'}
                                        classNamePrefix={`cursor-pointer basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div> */}
                                <div className="form-group col-md-3 daterange-fix">
                                    <AntDateRangePicker
                                        value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                        id={"dashboard-date-range"}
                                        name={'leadkpi-date'}
                                        format='DD/MM/YYYY'
                                        onChange={(e) => { handleDateRangeChange(e, 'leadkpi-date') }}
                                        placeholder='DD/MM/YYYY'
                                        onCalendarChange={(value) => {
                                            if (value) {
                                                const [start, end] = value;
                                                setDates([start, end]);
                                            }
                                            else {
                                                setDates([])
                                            }
                                        }}
                                    //disabledDate={disabledDate}
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <div>
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            setRefreshData(true)
                                        }}> <i className="ico icon-search"></i> Search</button>
                                        <button type="button" className="btn btn-default ml-3" onClick={() => {
                                            setDateRange({
                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                            })
                                            setUser(null)
                                            setSelectedMake(null)
                                            setSelectedModel(null)
                                            setSelectedEnqType(null)
                                            setSelectedOrigin(null)
                                            setSelectedLeadSource(null)
                                            setSelectedCampaign(null)
                                            setLeadType(null)
                                            setRefreshData(true)
                                            setShowCount(false)
                                            setObjFilter(defaultFilters)
                                        }}>Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <div className="float-right">
                            <Dropdown ref={node} alignRight show={filterShow}>
                                <div className="advanced-filter-btn mr-2 float-right">
                                    <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                        setShow(!filterShow)
                                    }}>
                                        {filterCounts()}
                                        <i className="ico icon-filter"></i>Filters
                                    </Dropdown.Toggle>
                                </div>
                                <Dropdown.Menu as={CustomFilter}
                                    id="table-reports-cols-settings-pipeline"
                                    className="dropdown-menu dropdown-menu-left"
                                    ChildClass="form-style quick-filter-wrap"
                                    xplacement="bottom-end"
                                >
                                    <div className="quick-filter-body mt-1 h-100 overflow-inherit">
                                        <div className="report-filter-wraper form-style report-filter-height-fix">
                                            {
                                                statisFilters.filter(a => ownerDashboard ? a !== 'user' : true).map((rec, i) => {
                                                    return <DynamicFilters
                                                        key={i}
                                                        {...props}
                                                        filter={rec}
                                                        objFilter={objFilter}
                                                        setObjFilter={(e, name) => {
                                                            setObjFilter({
                                                                ...objFilter,
                                                                [name]: e ? e.value : ''
                                                            })
                                                        }}
                                                        setMultibjFilter={(selectedOptions, name) => {
                                                            let value = [];
                                                            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                                value.push(data.value)
                                                            })
                                                            setObjFilter({
                                                                ...objFilter,
                                                                [name]: value
                                                            });
                                                        }}
                                                    />
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="quick-filter-footer">
                                        <button type="button" className="btn btn-default float-left" onClick={() => {
                                            setDateRange({
                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                            })
                                            setUser(null)
                                            setSelectedMake(null)
                                            setSelectedModel(null)
                                            setSelectedEnqType(null)
                                            setSelectedOrigin(null)
                                            setSelectedLeadSource(null)
                                            setSelectedCampaign(null)
                                            setSelectedSaleType(null)
                                            setLeadType(null)
                                            setRefreshData(true)
                                            setShow(false)
                                            setShowCount(false)
                                            setObjFilter(defaultFilters)
                                        }}>Clear</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => {
                                            setRefreshData(true)
                                            setShow(false)
                                            setShowCount(true)
                                        }}>Apply</button>

                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340}px` }}><ContentOverlay active={true} /></div>) : progressReport && progressReport.length === 0 ? (
                    <div className="common-table">
                        <div className="text-center p-5">
                            <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                            <div className="no-data-txt mt-2"> No records found</div>
                        </div>
                    </div>
                ) : (<div className="common-table emp-table">

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th width="18%" colspan="2" rowspan="3" className="emp-header-bg" scope="col">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('name')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'name' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        {selectedKpi.name}
                                    </div></th>
                                <th colspan="2" className="emp-header-bg text-center" scope="col">New Enquiries</th>
                                <th colspan="2" className="emp-header-bg text-center" scope="col">Leads Created</th>
                                <th colspan="2" className="emp-header-bg text-center" scope="col">Leads Won</th>
                                <th colspan="4" className="emp-header-bg text-center" scope="col">Conversion Rates</th>
                                {
                                    selectedKpi.marketing ? (
                                        <th colspan="5" className="emp-header-bg text-center" scope="col">Cost</th>

                                    ) : (<></>)
                                }
                            </tr>
                            <tr>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('allocated')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'allocated' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        Total</div>
                                </th>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">Trend</th>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('newCAP')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'newCAP' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        Total</div>
                                </th>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">Trend</th>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('won')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'won' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>Total</div>

                                </th>
                                <th width="5%" rowspan="2" className="head-light text-center" scope="col">Trend</th>
                                <th width="5%" colspan="2" className="head-light text-center" scope="col">Leads/Enquiries</th>
                                <th width="5%" colspan="2" className="head-light text-center" scope="col">Won/ Created</th>
                                {
                                    selectedKpi.marketing ? (<>
                                        <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                e.preventDefault()
                                                requestSort('totalCost')
                                            }}>
                                                <div className="table-sort"><a href="#">
                                                    {
                                                        sortConfig && sortConfig.key === 'totalCost' ?
                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                    }
                                                </a>
                                                </div>{selectedKpi.name} Investment</div>
                                        </th>
                                        <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                e.preventDefault()
                                                requestSort('costperEnq')
                                            }}>
                                                <div className="table-sort"><a href="#">
                                                    {
                                                        sortConfig && sortConfig.key === 'costperEnq' ?
                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                    }
                                                </a>
                                                </div>Cost per enquiry</div>
                                        </th>
                                        <th width="5%" rowspan="2" className="head-light text-center" scope="col">
                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                e.preventDefault()
                                                requestSort('costperLead')
                                            }}>
                                                <div className="table-sort"><a href="#">
                                                    {
                                                        sortConfig && sortConfig.key === 'costperLead' ?
                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                    }
                                                </a>
                                                </div>Cost per lead</div></th>
                                        <th width="5%" rowspan="2" className="head-light text-center" scope="col"> <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort('costperWon')
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === 'costperWon' ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>Cost per won</div>
                                        </th>
                                    </>


                                    ) : (<></>)
                                }
                            </tr>
                            <tr>
                                <th scope="col" className="head-light text-center" width="5%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('leadsbyEnq')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'leadsbyEnq' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>Total</div>
                                </th>
                                <th scope="col" className="head-light text-center" width="5%">Trend</th>
                                <th scope="col" className="head-light text-center" width="5%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('wonbyLeads')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'wonbyLeads' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>Total</div>
                                </th>
                                <th scope="col" className="head-light text-center" width="5%">Trend</th>


                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedItems.length > 0 ?
                                    sortedItems.map((rec, index) => {
                                        return <Fragment key={index}>
                                            <tr key={index}>
                                                <td className="text-left head-light" width="2%">{index + 1}</td>
                                                <td className="head-light">
                                                    <div className="dealership-tbl cursor-pointer position-relative" onClick={() => {
                                                        setNestedTbl({
                                                            ...nestedTbl,
                                                            [rec.id]: Boolean(!nestedTbl[rec.id])
                                                        })
                                                    }}>
                                                        {
                                                            selectedKpi.value === 'user' ? (<div className="table-user-list">
                                                                <a href="#">
                                                                    <div className="table-user-avatar">
                                                                        <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                                                    {rec.displayName}
                                                                </a>
                                                            </div>) : (
                                                                <div className="dealership-tbl">
                                                                    {rec.displayName}
                                                                </div>
                                                            )
                                                        }
                                                        <div className="table-sort"><a href="#"><i className={`ico icon-sort-${nestedTbl[rec.id] === true ? 'asc' : 'desc'}`}></i></a></div>
                                                    </div>
                                                </td>
                                                <td className="text-center emp-border-none cursor-pointer" onClick={(e) => handleShowList(e, rec, 'allocated')}>{rec.allocated}</td>
                                                <td className="text-center emp-border-none p-0">
                                                    <BarChartCSS data={rec.trendData} field={'allocated'} handleShowList={handleShowList} />
                                                </td>
                                                <td className="text-center emp-border-none cursor-pointer" onClick={(e) => handleShowList(e, rec, 'newCAP')}>{rec.newCAP}</td>
                                                <td className="text-center emp-border-none p-0">
                                                    <BarChartCSS data={rec.trendData} field={'newCAP'} handleShowList={handleShowList} />
                                                </td>
                                                <td className="text-center emp-border-none cursor-pointer" onClick={(e) => handleShowList(e, rec, 'won')}>{rec.won}</td>
                                                <td className="text-center emp-border-none p-0">
                                                    <BarChartCSS data={rec.trendData} field={'won'} handleShowList={handleShowList} />
                                                </td>
                                                <td className="text-center emp-border-none">{rec.leadsbyEnq}%</td>
                                                <td className="text-center emp-border-none p-0">
                                                    <LineChartCSS data={rec.trendData} field={'leadsbyEnq'} />
                                                </td>
                                                <td className="text-center emp-border-none">{rec.wonbyLeads}%</td>
                                                <td className="text-center emp-border-none p-0">
                                                    <LineChartCSS data={rec.trendData} field={'wonbyLeads'} />
                                                </td>
                                                {
                                                    selectedKpi.marketing ? (<>
                                                        <td className="text-center emp-border-none">
                                                            {
                                                                textEditor[`totalCost-${rec.id}`] ? (<div className='form-style'><InputAutoNumber
                                                                    id={`totalCost-${rec.id}`}
                                                                    name={`totalCost`}
                                                                    allowNegative={false}
                                                                    thousandSeparator={true}
                                                                    placeholder={''}
                                                                    prefix={currencySymbol}
                                                                    className={`form-control mtdreport-input`}
                                                                    onValueChange={(e) => handleNumberChange(e)}
                                                                    value={editFields}
                                                                    onSaveField={saveEnquiryField}
                                                                    onCancelField={cancelEnquiryField}
                                                                    returnDefault={true}
                                                                /></div>) : (<> <NumberFormat
                                                                    thousandSeparator={true}
                                                                    prefix={currencySymbol}
                                                                    displayType={'text'}
                                                                    value={rec.totalCost || 0}
                                                                />
                                                                    {/* <span className="mtdreport-edit-span" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setEditFields(rec.totalCost)
                                                                    setTexteditor({
                                                                        groupKey: selectedKpi.value,
                                                                        value: rec.id,
                                                                        [`totalCost-${rec.id}`]: true
                                                                    })
                                                                }}><i className="ico icon-edit"></i></span> */}
                                                                </>)
                                                            }
                                                        </td>
                                                        <td className="text-center emp-border-none ">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                                displayType={'text'}
                                                                value={rec.costperEnq || 0}
                                                            />
                                                        </td>
                                                        <td className="text-center emp-border-none ">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                                displayType={'text'}
                                                                value={rec.costperLead || 0}
                                                            />
                                                        </td>
                                                        <td className="text-center emp-border-none ">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                                displayType={'text'}
                                                                value={rec.costperWon || 0}
                                                            />
                                                        </td>
                                                        {/* <td className="text-center emp-border-none p-0">
                                                            <LineChartCSS data={rec.trendData} field={'costperWon'} />
                                                        </td> */}
                                                    </>


                                                    ) : (<></>)
                                                }

                                            </tr>
                                            {
                                                selectedKpi.nested ? (
                                                    <NestedTbl data={rec} field={selectedKpi.nested} fieldName={selectedKpi.nestedName} />
                                                ) : (<></>)
                                            }
                                        </Fragment>
                                    }) : (
                                        <tr>
                                            <td colSpan={selectedKpi.marketing ? 15 : 12}>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                        <div className="no-data-txt mt-2"> No data</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div >
                )
            }
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={popHeader}
                    dataList={allEnquiries}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    isFromDashboard={true}
                ></EnquiryList>
            </PopUpModal>
            <PopUpModal show={modelShow.testdrive}>
                <TestDriveList
                    {...props}
                    show={modelShow.testdrive}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={popHeader}
                    dataList={allTestdrives}
                    filter={filter}
                    isFromDashboard={true}
                ></TestDriveList>
            </PopUpModal>
            <PopUpModal show={modelShow.activity}>
                <ActivityList
                    {...props}
                    show={modelShow.activity}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={popHeader}
                    dataList={doneActivities}
                    filter={filter}
                    isFromDashboard={true}
                ></ActivityList>
            </PopUpModal>
            <PopUpModal show={showModel}>
                <AddReport
                    {...props}
                    show={showModel}
                    title={'Edit Report'}
                    handleClose={handleModelClose}
                    report={reportJSON}
                />
            </PopUpModal>
            <PopUpModal show={modelShow.sheduleReport}>
                <ScheduleReport
                    {...props}
                    show={modelShow.sheduleReport}
                    handleClose={handleModelClose}
                    title={popHeader}
                    reportJSON={{
                        ...reportJSON,
                        groupBy: selectedKpi.value,
                        groupSubtype: ['all']
                    }}
                    report={{
                        ...reportJSON,
                        static: false,
                        documentID: `kpi-${ownerDashboard ? localStorage.uid : props.dealersettings.client.id}-${selectedKpi.value}`,
                        groupBy: selectedKpi.value,
                        groupSubtype: ['all']
                    }}
                ></ScheduleReport>
            </PopUpModal >
            <PopoverPanel
                showpopover={popover.show}
                targetpopover={popover.target}
                title='Scheduler'
                position='left'
                id='popover-schdule-report'
                className='popover-schdule-report'
                closepopover={() => {
                    setPopover({ show: false })
                }}>
                {
                    multiSchedule.map((rec, index) => {
                        return <div key={index} className="reportsuccess-multi-btn mb-2">
                            <div className="reportsuccess-multi-logo">
                                <i className="ico icon-files-document"></i>
                            </div>
                            <div className="reportsuccess-multi-inner-text">
                                <div className="reportsuccess-multi-inner-text-top"> Scheduled </div>
                                <div className="reportsuccess-multi-inner-text-sub"> {`${rec.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(rec.cron)}`}</div>
                            </div>
                        </div>
                    })
                }
            </PopoverPanel>
        </>
    )
}

export default PerformanceWithTrend;