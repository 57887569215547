/** LIBRARIES */
import React, { useState, useEffect, Fragment } from 'react'
import "../../../styles/tradein_internal.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { ContentOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { objTradeIn, tradeinSortOptions } from '../../tradeIn/viewModel'
import { objOfferNotesVM, objTradeIP } from '../viewModel'
import { tipStatus } from '../../../services/enum';

const TradeInDetails = (props) => {
    const [tradeInPro, setTradeInPro] = useState(objTradeIP)
    const [tradeIn, setTradeIn] = useState(objTradeIn)
    const [tradeinProID, setTradeInID] = useState(null)
    const [isPDF, setPDF] = useState(true)
    const [accessToShowTradeInPrice, setShowPrice] = useState(true)

    const [tipOfferLogs, settipOfferLogs] = useState([])
    const [tipOfferNotes, settipOfferNotes] = useState([])

    const [enquiryOptionsDF, setenquiryOptionsDF] = useState([])
    const [tradeinOptionsDF, settradeinOptionsDF] = useState([])
    const [dealersettings, setdealerSettings] = useState({})

    const {
        status, addedBy, offer, owner, addedDate, lostDate, origin,
        client, users, lostReason, lostSubReason, valuerPrice,
        finalPrice, finalizedBy, finalizedDate,
    } = tradeInPro;


    const {
        make, model, year, modelDescription, specifications, tags,
        notes, transmissionType, driveType, fuelType,
        mileage, exteriorColor, chassisNo, regNo,
        images, marks, inspectionSnapshot, sidePhotos
    } = tradeIn;
    let _tags = _.uniqBy((tradeInPro?.tags || []), _.union((tags || [])), 'type')
    const currencySymbol = ((!_.isEmpty(tradeInPro) &&
        !_.isEmpty(tradeInPro.client) &&
        !_.isEmpty(tradeInPro.client.currency)) ? tradeInPro.client.currency.symbol + ' ' : '$ ');

    const _settings = !_.isEmpty(tradeInPro?.currentSettings?.tradeinPro) ? tradeInPro.currentSettings.tradeinPro : null;
    let objCurrentStatus = status && _settings && _settings.tradeinProStatus && _settings.tradeinProStatus.filter(e => e.value === status)[0]
    const _isTradeinCompleted = [tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(status) ? true : false;

    const toDataURL = async (url) => await fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))


    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`tradeinPro/${props.tradeinid}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        //console.log('TradeInDetails', props.tradeinid);
        if (props.tradeinid) {
            loadTradeIn();
        }
        return () => {
            window.refCollectionData && window.refCollectionData.unsubscribe()
        }
    }, [firestoreDB])

    const loadTradeIn = async () => {


        window.refCollectionData = docData(firestoreDB.firestore().doc(`tradeinPro/${props.tradeinid}`)//.doc(props.tradeinid).get()
            // .where('documentID', '==', props.tradeinid)
            // .where('isDeleted', '==', false)
        )
            .pipe(
                switchMap(tradeIns => {
                    //console.log(tradeIns);
                    const tradeIds = [props.tradeinid];
                    const enqIds = tradeIns?.tradeinID ? [tradeIns.tradeinID] :
                        (tradeIns?.tradein?.documentID ? [tradeIns.tradein.documentID] : []);
                    const clientIds = [tradeIns.clientID];
                    return combineLatest(
                        of(tradeIns),
                        combineLatest(
                            enqIds.length > 0 ? enqIds.map(tradeID =>
                                collectionData(firestoreDB.firestore().collection('tradeins').where('documentID', '==', tradeID)).pipe(
                                    map(tradeinSnapshot => tradeinSnapshot[0] ? tradeinSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            tradeIds.length > 0 ? tradeIds.map(tradeinproid =>
                                collectionData(window.firebase.firestore().collection(`tradeinPro/${tradeinproid}/offerLogs`)).pipe(
                                    map(offerLogsSnapshot => offerLogsSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            tradeIds.length > 0 ? tradeIds.map(tradeinproid =>
                                collectionData(window.firebase.firestore().collection(`tradeinPro/${tradeinproid}/offerNotes`)).pipe(
                                    map(offerNotesSnapshot => offerNotesSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
                                    map(clientSettingsSnapshot => clientSettingsSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                                    map(usersSnapshot => usersSnapshot)
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([tradeIns, tradeinSnapshot, offerLogsSnapshot, offerNotesSnapshot, clientSnapshot, clientSettingsSnapshot, usersSnapshot]) => {

                    return {
                        ...tradeIns,
                        tradein: tradeinSnapshot[0] ? tradeinSnapshot[0] : tradeIns.tradein,
                        client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                        currentSettings: clientSettingsSnapshot[0],
                        users: usersSnapshot[0],
                        tipOfferLogs: offerLogsSnapshot[0],
                        tipOfferNotes: offerNotesSnapshot[0],
                    }

                })
            )
            .subscribe(_tradeIns => {
                let search = window.location.search;
                let params = new URLSearchParams(search);
                let backURL = params.get('isView');

                setPDF(backURL ? false : true);

                if (backURL === 'yes')
                    setShowPrice(false)

                if (!_.isEmpty(_tradeIns)) {
                    //console.log('_tradeIns', _tradeIns)
                    //setTradeInPro(_tradeIns)
                    //setTradeIn(_tradeIns.tradein);

                    let _dealerSettings = {};
                    let _client = Object.assign({}, _tradeIns.client);
                    let _clientSettings = Object.assign({}, _tradeIns.currentSettings);
                    _dealerSettings.client = Object.assign({
                        ..._client,
                        id: _tradeIns.clientID,
                        name: _clientSettings.name,
                        timezone: _clientSettings.timezone,
                        phone: _clientSettings.phone,
                        logoURL: _clientSettings.logoURL,
                        address: _clientSettings.address,
                        moduleSettings: _clientSettings.moduleSettings,
                        currency: _clientSettings.settings && _clientSettings.settings.currency,
                        integrations: _clientSettings.integrations,
                        settings: _clientSettings?.settings,
                        tradeinPro: _clientSettings?.tradeinPro
                    });

                    let _tradeinOptionsDF = [];
                    if (_dealerSettings?.client?.settings?.tradeinOptionsDF)
                        _tradeinOptionsDF = _dealerSettings?.client?.settings?.tradeinOptionsDF;
                    settradeinOptionsDF(_tradeinOptionsDF);

                    let _enquiryOptionsDF = [];
                    if (_dealerSettings?.client?.tradeinPro?.enquiryOptionsDF)
                        _enquiryOptionsDF = _dealerSettings?.client?.tradeinPro?.enquiryOptionsDF;
                    setenquiryOptionsDF(_enquiryOptionsDF);
                    setdealerSettings(_dealerSettings);

                    saveTradeinPro(_tradeIns, _enquiryOptionsDF)
                    saveTradein(_tradeIns.tradein, _tradeinOptionsDF);

                    setTradeInID(props.tradeinid)

                    if (_tradeIns?.tradein?.sent && _tradeIns?.tradein?.evalexpertID) {
                        getDetailsFromEvalExpert(_tradeIns?.tradein?.documentID, _tradeIns.tradein);
                    }

                    const offerNotes = [];
                    _tradeIns.tipOfferNotes && _tradeIns.tipOfferNotes.forEach((doc) => {
                        const _notes = Object.assign({}, objOfferNotesVM);
                        for (let [key, value] of Object.entries(doc)) {
                            _notes[key] = value;
                        }
                        if (!_notes.isDeleted && ((_notes.isInternalNotes === true && _notes.wholeSalerNotes === false) || // isDealerInternalNotes 
                            (_notes.isInternalNotes === false && _notes.wholeSalerNotes === false) || // isDealerPublicNotes 
                            (_notes.isInternalNotes === false && _notes.wholeSalerNotes === true) // isWholesalerPublicNotes 
                        )) offerNotes.push(_notes);
                    });
                    settipOfferNotes(offerNotes);
                    settipOfferLogs(_tradeIns.tipOfferLogs?.filter(e => !e.isDeleted))

                }

                //window.refCollectionData && window.refCollectionData.unsubscribe()
            });

    }

    const saveTradein = (doc, _tradeinOptionsDF) => {
        let _fields = { ...doc };
        let __tradeinOptionsDF = _tradeinOptionsDF ? _tradeinOptionsDF : tradeinOptionsDF
        if (!_.isEmpty(doc.dynamicFields) && __tradeinOptionsDF) {

            __tradeinOptionsDF.filter(e => e.active === true).forEach(rec => {
                if (rec.type === 'toggle') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                // else if (rec.type === 'price') {
                //     _fields[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(dealersettings?.client?.currency) ? dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
                // }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    // _fields[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                    //     {
                    //         doc.dynamicFields[rec.value].map((rec, index) => {
                    //             return <div key={index}><span>{rec}</span></div>
                    //         })
                    //     }
                    // </div>) : (<></>)
                }
                else {
                    _fields[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        setTradeIn(_fields);
    }

    const saveTradeinPro = (doc, _tradeinOptionsDF) => {
        let _fields = { ...doc };
        let __tradeinOptionsDF = _tradeinOptionsDF ? _tradeinOptionsDF : enquiryOptionsDF
        if (!_.isEmpty(doc.dynamicFields) && __tradeinOptionsDF) {

            __tradeinOptionsDF.filter(e => e.active === true).forEach(rec => {
                if (rec.type === 'toggle') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                // else if (rec.type === 'price') {
                //     _fields[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(dealersettings?.client?.currency) ? dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
                // }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    // _fields[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                    //     {
                    //         doc.dynamicFields[rec.value].map((rec, index) => {
                    //             return <div key={index}><span>{rec}</span></div>
                    //         })
                    //     }
                    // </div>) : (<></>)
                }
                else {
                    _fields[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        setTradeInPro(_fields);
    }

    const getDetailsFromEvalExpert = async (docID, _fields) => {
        try {

            const evalexpertGet = window.firebase.functions().httpsCallable('evalexpert-get');
            evalexpertGet(_fields).then((_response) => {

                if (_response && _response.data && _response.data.success && _response.data.data) {
                    //console.log('evalexpert-get--', _response.data.data[0]);
                    var tradein = CommonHelper.convertEvalMappingVM(_response.data.data[0], _fields);
                    saveTradein(tradein);
                }

            }).catch((error) => {
                //Swal.fire('Something went wrong. Please contact support.', '', 'error')
            });
        } catch (error) {
            console.log(error);
        }
    }




    const getInspection = (id, _marks) => {
        const canvas = document.createElement("canvas");
        canvas.width = 395;
        canvas.height = 600;
        // var canvas = document.getElementById("canvas");
        var context = canvas.getContext('2d');

        var img = new Image();
        if (client?.category === 'Motorcycle')
            img.src = require("../../../images/inspection-motorcycle.jpg");
        else
            img.src = require("../../../images/inspection.jpg");
        //img.src = require(`../../../images/${CommonHelper.getVehicleIcons(client?.category, 'inspection')}.jpg`);
        img.onload = async function (e) {
            context.drawImage(img, 0, 0, 395, 600);

            if (!_.isEmpty(_marks)) {
                await asyncForEach(_marks, async (mark) => {
                    let imgURL = require(`../../../images/${mark.markType}.png`);
                    await loadImage(imgURL).then(img2 => {
                        context.drawImage(img2, mark.dx, mark.dy, 25, 25);
                    });
                });
            }

            var dataURL = canvas.toDataURL();
            document.getElementById(id).src = dataURL;
            //console.log('imageToBase64:', dataURL)
            return dataURL;
        }
    }

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // It's better to use async image loading.
    const loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };

    const bindTradein = (rec, index) => {
        if (tradeIn && (!_.isEmpty(tradeIn[rec]) || (_.isNumber(tradeIn[rec]) && tradeIn[rec] > 0) || _.isBoolean(tradeIn[rec]))) {
            if (rec === 'regDate' || rec === 'complianceDate' || rec === 'buildDate' || rec === 'serviceContFrom' || rec === 'serviceContTo' || rec === 'lastService' || rec === 'warrantyExpiry') {
                return <li key={index} width="25%" className="page-break">
                    <Translate text={rec} /><span>{tradeIn[rec] ? moment(tradeIn[rec], 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</span>
                </li>
            }
            else if (rec === 'massageVentilated' || rec === 'contrastStiching' || rec === 'financed' || rec === 'outstandingPayments' || rec === 'sunroof' || rec === 'camera' || rec === 'woodSteering' || rec === 'warranty' || rec === 'reconditioning') {
                return <li key={index} width="25%" className="page-break">
                    <Translate text={rec} /><span>{tradeIn[rec] ? 'YES' : 'NO'}</span>
                </li>
            }
            else if (rec === 'reconditioningCost') {
                return <li key={index} width="25%" className="page-break">
                    <Translate text={rec} />{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : <span>{'--'}</span>}
                </li>
            }
            else if (rec === 'mileage') {
                return <li key={index} width="25%" className="page-break">
                    <Translate text={rec} />{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : <span>{'--'}</span>}
                </li>
            }
            else if (objTradeIn.hasOwnProperty(rec)) {
                return <li key={index} width="25%" className="page-break"><Translate text={rec} /><span>{tradeIn[rec] ? tradeIn[rec] : '--'}</span></li>
            }
            else if (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0])) {
                let info = tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                if (info.type === 'price')
                    return <li key={index} width="25%" className="page-break"><Translate text={info.name} />{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : <span>{'--'}</span>}</li>
                else
                    return <li key={index} width="25%" className="page-break"><Translate text={info.name} /><span>{tradeIn[rec] ? tradeIn[rec] : '--'}</span></li>
            }
        }
    }

    let enquirySortOptions = [
        "origin", "lostReason", "lostSubReason"
    ];
    let dynamicDetailProList = (!_.isEmpty(dealersettings?.client?.tradeinPro?.sortEnquiryOptions))
        ? dealersettings.client.tradeinPro.sortEnquiryOptions :
        Object.assign([], _.uniq(_.union(enquirySortOptions, Object.assign([], (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(enquiryOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));

    let dynamicDetailList = (!_.isEmpty(dealersettings?.client?.settings?.sortTradeinOptions))
        ? dealersettings.client.settings.sortTradeinOptions :
        Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));

    let dynamicFilteredList = dynamicDetailList.filter(e => !['notes', 'specifications', 'status', 'modelDescription'].includes(e));

    let _apperance = [
        "exteriorColor",
        "interiorColor",
        "trim"]
    let _characteristics = [
        "badge",
        "series",
        "driveType",
        "fuelType",
        "transmission",
        "transmissionType",
        "mileage",
        "cylinders",
        "gears",
        "doors",
        "seats",
        "powerKw",
        "range",
        "torqueNm",
        "Wheelbase"]
    const _conditionAfterMArket = [
        "vehicleCondition",
        "spareKey",
        "logBooks",
        "serviceHistory",
        "cargoBlind",
        "headRests",
        "sdCard",
        "frontRightWheel",
        "frontLeftWheel",
        "rearRightWheel",
        "rearLeftWheel",
        "spareTyre",
        "owners",
        "serviceContFrom",
        "serviceContTo",
        "lastService",
        "warrantyExpiry",
        "financeCo",
        "tyreAge",
        "reconditioningCost",
        "massageVentilated",
        "contrastStiching",
        "sunroof",
        "camera",
        "woodSteering",
        "warranty",
        "reconditioning",
        "financed",
        "outstandingPayments"
    ];
    let _excludes = [..._apperance, ..._characteristics, ..._conditionAfterMArket]

    let managerWorkflow = client?.clientSettings?.managerWorkflow ? true : false;
    return (
        <>

            {
                !_.isEmpty(tradeinProID)
                    ?
                    <>
                        <div className="A4">
                            <div className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="print-ico">
                                    <a href="#" onClick={() => window.print()}>  <img src={_images.print} width="19" height="18" /> <span>Print</span></a>
                                </div>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="50%">

                                                    <div className="header-left-align">
                                                        {
                                                            client && client.logoURL
                                                                ?
                                                                <div className="logo-head"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="50" /></div>
                                                                :
                                                                <>
                                                                    <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                                </>
                                                        }

                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div className="header-right-align align-right-text">
                                                        <div className="header-company"> {client.name} <br />
                                                            <span>{client.address ? client.address : ''}

                                                                {client.phone ? (
                                                                    <>
                                                                        <br />
                                                                        {`Phone: ${client.phone}`}
                                                                    </>
                                                                ) : <></>}
                                                                {client.email || client.website ? (
                                                                    <>
                                                                        <br />
                                                                        {client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
                                                                    </>
                                                                ) : <></>} </span> </div>
                                                    </div>






                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div className="middle-section-wraper">

                                    <div className="report-ui-mainhead">
                                        {'Internal Evaluation'}

                                    </div>

                                    <div className="report-ui-subcontainer">

                                        <div className="report-ui-flex-main">

                                            {
                                                !_.isEmpty(images) && (
                                                    <div className="report-ui-flex-image">
                                                        <a data-fancybox={`tradein_img`} href={images[0]} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                            <img src={images[0]} width="80" height="80" alt="" className="img-object-fit" />
                                                        </a>
                                                    </div>
                                                )
                                            }


                                            <div className="report-ui-flex-content">
                                                <h2>
                                                    {modelDescription ?
                                                        <>{modelDescription}</> :
                                                        <>{year ? year : ''}&nbsp;{make ? make : ''}&nbsp;{model ? model : ''}</>
                                                    }
                                                </h2>
                                                <h3>{exteriorColor ? exteriorColor + ',' : ''} &nbsp;{driveType ? driveType : ''} &nbsp;{transmissionType ? transmissionType : ''}&nbsp;{fuelType ? fuelType : ''}</h3>

                                                <div className="report-ui-veh-info">
                                                    <div className="block">
                                                        <span>CHASSIS NO. </span>
                                                        <p>{chassisNo ? chassisNo : '--'}</p>
                                                    </div>
                                                    <div className="block">
                                                        <span>REG. NUMBER    </span>
                                                        <p>{regNo ? regNo : '--'}</p>
                                                    </div>
                                                    <div className="block">
                                                        <span>Mileage </span>
                                                        <p><span>{mileage ? <NumberFormat style={{ fontSize: 12 }} value={mileage} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : '--'}</span></p>
                                                    </div>
                                                </div>
                                                {/*  */}
                                                {/* {
                                                    offer && offer.evaluatedBy && (<div className="report-ui-bottomline">
                                                        <p> <span>Evaluated By:</span>{(!_.isEmpty(users)) ? CommonHelper.getUserNamebyId(users, offer.evaluatedBy) : '--'}</p>
                                                        <p> <span>Date:</span>{offer.evaluationDate ?
                                                            (!_.isEmpty(client) && client.timezone ?
                                                                moment(moment.unix(offer.evaluationDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                moment.unix(offer.evaluationDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                            : '--'}</p>
                                                    </div>)
                                                } */}

                                                <div>

                                                </div>

                                            </div>



                                        </div>



                                    </div>
                                    <div className="report-ui-subhead">

                                        <div className="report-ui-cst"><Translate text={'Appraisal Information'} />
                                            {
                                                status && objCurrentStatus
                                                    ?
                                                    <>
                                                        <div
                                                            className={`badge-inline-status ml-2 mt-0`} style={{ background: (objCurrentStatus && objCurrentStatus.color ? objCurrentStatus.color : '#333'), color: (status === tipStatus.NEW ? '#333' : '#FFF') }}>
                                                            <Translate
                                                                text={objCurrentStatus ? objCurrentStatus.name : status.toLowerCase()}
                                                                upperCase={true}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                            {
                                                tradeIn.isDeletedFromTradeIn
                                                    ?
                                                    <>
                                                        <div className={`mrg-fixstatus badge-lost ml-2 mt-1`}>
                                                            {'Deleted From TradeIn Plus'}
                                                        </div>
                                                    </>
                                                    :
                                                    <>

                                                    </>
                                            }

                                            {
                                                !_.isEmpty(_tags) && _tags.map((rec, index) => {
                                                    return <div key={index}
                                                        className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">
                                                        {CommonHelper.getNameByValue(!_.isEmpty(tradeInPro.client) && tradeInPro.client.integrations, rec.type)}
                                                    </div>
                                                })
                                            }
                                        </div>

                                        {tradeIn.EvaluationNumber ?
                                            <div className="report-ui-enq"> Valuation No.: <span > {tradeIn.EvaluationNumber} </span> </div> :
                                            <>
                                                {
                                                    tradeIn.evalexpertID ?
                                                        <div className="report-ui-enq"> Eval Expert No.: <span > {tradeIn.evalexpertID} </span> </div> :
                                                        <>
                                                            {
                                                                tradeInPro.displayID ?
                                                                    <div className="report-ui-enq"> Valuation No.: <span > {tradeInPro.displayID} </span> </div> :
                                                                    <></>
                                                            }
                                                        </>
                                                }
                                            </>
                                        }


                                    </div>

                                    <div className="report-ui-subcontainer">


                                        <ul>
                                            <li><Translate text={'owner'} /> <span>{(!_.isEmpty(users) && owner) ? CommonHelper.getUserNamebyId(users, owner) : '--'}</span></li>
                                            {origin && (<li>origin <span>{origin ? CommonHelper.getNameByValue(_settings && _settings.origins, origin, '--') : '--'}</span></li>)}
                                            {
                                                (status === tipStatus.LOST)
                                                    ?
                                                    <>
                                                        {(() => {

                                                            let _lostReasons = _settings && lostReason && _settings.lostReasons.filter(e => e.value === lostReason)[0];
                                                            return <>

                                                                {lostReason && _lostReasons && (<li>
                                                                    <Translate text={'lostReason'} />
                                                                    <span>{lostReason && _lostReasons ? _lostReasons.name : '--'}</span>
                                                                </li>)}
                                                                {lostSubReason && (<li>
                                                                    <Translate text={'lostSubReason'} />
                                                                    <span>{lostSubReason ? CommonHelper.getNameByValue(_lostReasons && _lostReasons.subList, lostSubReason, '--') : '--'}</span>
                                                                </li>)}
                                                                {lostDate && (<li>
                                                                    <Translate text={CommonHelper.getNameByValue(_settings?.tradeinProStatus, 'lost', 'Lost') + ' Date'} />
                                                                    <span>{(!_.isEmpty(client) && client.timezone ?
                                                                        moment(moment.unix(lostDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                        moment.unix(lostDate.seconds).format('DD/MM/YYYY hh:mm A'))}</span>
                                                                </li>)}

                                                            </>
                                                        })()}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            {
                                                _.chunk(dynamicDetailProList.filter(e => !["origin", "lostReason", "lostSubReason", "lostDate"].includes(e)), 4).map((stats, i) => {
                                                    return <Fragment key={i}>
                                                        {
                                                            stats.map((rec, index) => {
                                                                if (!_.isEmpty(tradeInPro[rec]) || (_.isNumber(tradeInPro[rec]) && tradeInPro[rec] > 0) || _.isBoolean(tradeInPro[rec])) {
                                                                    return <li key={index}>

                                                                        {(() => {
                                                                            if (objTradeIP.hasOwnProperty(rec)) {
                                                                                return <><Translate text={rec} /><span>{tradeInPro[rec] ? tradeInPro[rec] : '--'}</span></>
                                                                            }
                                                                            else if (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active === true && m.value === rec)[0])) {
                                                                                let info = enquiryOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                                                                                if (info.type === 'price')
                                                                                    return <><Translate text={info.name} /><span>{tradeInPro[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeInPro[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : '--'}</span></>
                                                                                else
                                                                                    return <><Translate text={info.name} /><span>{tradeInPro[rec] ? tradeInPro[rec] : '--'}</span></>
                                                                            }
                                                                        })()}
                                                                    </li>
                                                                }
                                                            })
                                                        }
                                                    </Fragment>
                                                })
                                            }

                                            <li><Translate text={'Created By'} /> <span>{(!_.isEmpty(users) && addedBy) ? CommonHelper.getUserNamebyId(users, addedBy) : '--'}</span></li>
                                            <li><Translate text={'Created Date'} /> <span>{(addedDate) ? moment.unix(addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span></li>
                                        </ul>
                                    </div>

                                    <div className="report-ui-subhead">
                                        <div className="report-ui-cst"><Translate text={'Customer Details'} />
                                            {
                                                (tradeInPro?.contact && tradeInPro?.contact?.displayID)
                                                    ?
                                                    <span className="badge-custom">{tradeInPro.contact.displayID}</span>
                                                    :
                                                    <>
                                                        {
                                                            !_.isEmpty(tradeIn?.contact) && tradeIn?.contact?.displayID
                                                                ?
                                                                <span className="badge-custom">{tradeIn.contact.displayID}</span>
                                                                :
                                                                <></>
                                                        }
                                                    </>
                                            }

                                        </div>
                                    </div>

                                    <div className="report-ui-subcontainer">
                                        {
                                            !_.isEmpty(tradeInPro?.contact)
                                                ?
                                                <ul>
                                                    <li>
                                                        Customer<span>{CommonHelper.displayFullContact(null, tradeInPro?.contact, '--')}</span>
                                                    </li>
                                                    {tradeInPro?.contact?.phone && (<li>
                                                        <Translate text={'phone'} /><span>{tradeInPro.contact.phone ? CommonHelper.phoneFormat(tradeInPro?.contact?.phoneCode, tradeInPro.contact.phone, { client: client }) : '--'} </span>
                                                    </li>)}
                                                    {tradeInPro?.contact?.email && (<li>
                                                        <Translate text={'email'} /><span> {tradeInPro?.contact?.email ? tradeInPro.contact.email : '--'}</span>
                                                    </li>)}
                                                    {tradeInPro?.contact?.address && (<li>
                                                        <Translate text={'Address'} /><span>{tradeInPro?.contact?.address ? tradeInPro.contact.address : '--'}</span>
                                                    </li>)}
                                                </ul>
                                                :
                                                <ul>
                                                    <li>
                                                        Customer<span>{CommonHelper.displayFullContact(null, tradeIn?.contact, '--')}</span>
                                                    </li>
                                                    {tradeIn?.contact?.phone && (<li>
                                                        <Translate text={'phone'} /><span>{tradeIn.contact.phone ? CommonHelper.phoneFormat(tradeIn?.contact?.phoneCode, tradeIn.contact.phone, { client: client }) : '--'} </span>
                                                    </li>)}
                                                    {tradeIn?.contact?.email && (<li>
                                                        <Translate text={'email'} /><span> {tradeIn?.contact?.email ? tradeIn.contact.email : '--'}</span>
                                                    </li>)}
                                                    {tradeIn?.contact?.address && (<li>
                                                        <Translate text={'Address'} /><span>{tradeIn?.contact?.address ? tradeIn.contact.address : '--'}</span>
                                                    </li>)}
                                                </ul>
                                        }
                                    </div>

                                    <div className="">

                                        <div className="page-break" >
                                            <div className="report-ui-subhead page-break" >
                                                Vehicle Details

                                            </div>
                                            {
                                                dynamicFilteredList.filter(e => !_excludes.includes(e) && !_.isEmpty(tradeIn) &&
                                                    (!_.isEmpty(tradeIn[e]) || (_.isNumber(tradeIn[e]) && tradeIn[e] > 0) || _.isBoolean(tradeIn[e])))[0] &&
                                                (<div className="report-ui-subcontainer page-break">

                                                    <div className="report-ui-subcontainer-head">Manufacture Details</div>
                                                    <div>
                                                        <ul>
                                                            {
                                                                dynamicFilteredList.filter(e => !_excludes.includes(e)).map((rec, index) => {
                                                                    return <Fragment key={index}>{bindTradein(rec, index)}</Fragment>
                                                                })
                                                            }
                                                        </ul>

                                                    </div>

                                                </div>)
                                            }

                                        </div>

                                        {
                                            dynamicFilteredList.filter(e => _apperance.includes(e) && !_.isEmpty(tradeIn) &&
                                                (!_.isEmpty(tradeIn[e]) || (_.isNumber(tradeIn[e]) && tradeIn[e] > 0) || _.isBoolean(tradeIn[e])))[0] &&
                                            (<div className="report-ui-subcontainer page-break">

                                                <div className="report-ui-subcontainer-head">Appearance</div>
                                                <div>
                                                    <ul>
                                                        {
                                                            dynamicFilteredList.filter(e => _apperance.includes(e)).map((rec, index) => {
                                                                return <Fragment key={index}>{bindTradein(rec, index)}</Fragment>
                                                            })
                                                        }
                                                    </ul>

                                                </div>

                                            </div>)
                                        }
                                        {
                                            dynamicFilteredList.filter(e => _characteristics.includes(e) && !_.isEmpty(tradeIn) &&
                                                (!_.isEmpty(tradeIn[e]) || (_.isNumber(tradeIn[e]) && tradeIn[e] > 0) || _.isBoolean(tradeIn[e])))[0] &&
                                            (<div className="report-ui-subcontainer page-break">

                                                <div className="report-ui-subcontainer-head">Characteristics</div>
                                                <div>
                                                    <ul>
                                                        {
                                                            dynamicFilteredList.filter(e => _characteristics.includes(e)).map((rec, index) => {
                                                                return <Fragment key={index}>{bindTradein(rec, index)}</Fragment>
                                                            })
                                                        }
                                                    </ul>

                                                </div>

                                            </div>)
                                        }
                                        {
                                            dynamicFilteredList.filter(e => _conditionAfterMArket.includes(e) && !_.isEmpty(tradeIn) &&
                                                (!_.isEmpty(tradeIn[e]) || (_.isNumber(tradeIn[e]) && tradeIn[e] > 0) || _.isBoolean(tradeIn[e])))[0] &&
                                            (<div className="report-ui-subcontainer page-break">

                                                <div className="report-ui-subcontainer-head">Condition &amp; Aftermarket</div>
                                                <div>
                                                    <ul>
                                                        {
                                                            dynamicFilteredList.filter(e => _conditionAfterMArket.includes(e)).map((rec, index) => {
                                                                return <Fragment key={index}>{bindTradein(rec, index)}</Fragment>
                                                            })
                                                        }
                                                    </ul>

                                                </div>

                                            </div>)
                                        }

                                    </div>

                                    {
                                        !_.isEmpty(specifications) && !_.isEmpty(dynamicDetailList.filter(e => ['specifications'].includes(e))[0])
                                            ?
                                            <div className="page-break">
                                                <div className="report-ui-subhead"> SPECIFICATIONS </div>
                                                <div className="report-ui-subcontainer text-area-space"
                                                    dangerouslySetInnerHTML={{ __html: (specifications ? '&middot; ' + specifications.replace(/\n/g, "<br/> &middot; ") : '--') }} />
                                            </div>
                                            :
                                            <>
                                            </>
                                    }

                                    {
                                        !_.isEmpty(marks) && (<div className="page-break"><div className="report-ui-subhead">
                                            <div className="report-ui-cst"> Reconditioning</div>
                                            <div className="report-ui-enq">{CommonHelper.showReconditioningCost(marks, currencySymbol)}</div>

                                        </div>

                                            <div className="report-ui-subcontainer page-break">
                                                <ul>
                                                    {
                                                        !_.isEmpty(marks) && marks.map((mark, index) => {
                                                            return <li key={index} >
                                                                {CommonHelper.bindMArkType(mark.markType, mark.price, mark.notes, currencySymbol)}
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div></div>)
                                    }



                                    {/* <div className="page-break">
                                        <div className="report-ui-subhead">Price Logs</div>
                                        <div className="report-ui-subcontainer page-break">
                                            <div className="report-ui-changelogwrap">
                                                {!_.isEmpty(tipOfferLogs)
                                                    ?
                                                    <>
                                                        {
                                                            _.orderBy(tipOfferLogs, ['addedDate'], ['desc']).map((noteData, index) => {
                                                                return <div key={index} className="report-ui-changelog-loop text-area-space ">
                                                                    {noteData.message}
                                                                    <div className="span-log">
                                                                        <span className="pl-0">{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                                                        &nbsp;{' - '}&nbsp;{CommonHelper.getUserNamebyId(users, noteData.addedBy)}


                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <div className="report-ui-nodata"> No Log</div>
                                                    </>}
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="page-break">

                                        <div className="report-ui-subhead">
                                            VEHICLE IMAGES

                                        </div>
                                        <div className="report-ui-subcontainer page-break">

                                            <div className="report-ui-thumb-wraper">

                                                <>
                                                    {
                                                        (!_.isEmpty(images) || !_.isEmpty(sidePhotos) || (!_.isEmpty(marks) && marks.find(item => !_.isEmpty(item.images))))
                                                            ?
                                                            <>
                                                                {
                                                                    isPDF
                                                                        ?
                                                                        <>
                                                                            <div className="report-vehicle-link-wrap">
                                                                                {/* <p>Please find the requested pictures in the link below.</p> */}
                                                                                <div className="button-view"><a target="_blank" href={`${window.location.origin}/tradein/images/${props.tradeinid}`} className="gallery-link btn-bg ">Click here to view images</a></div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                !_.isEmpty(images) && images.map((data, index) => (
                                                                                    <div className="report-ui-thumb-item-image" key={index}>
                                                                                        <a data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                                            <img id={`tradein-before-img-${index}`} src={data} width="80" height="80" alt="" className="img-object-fit" />
                                                                                        </a>
                                                                                    </div>
                                                                                ))
                                                                            }

                                                                            {!_.isEmpty(sidePhotos) && Object.keys(sidePhotos).map((key, index) => {
                                                                                if (sidePhotos[key]) {
                                                                                    return (<div className="report-ui-thumb-item-image" key={index}>
                                                                                        <a data-fancybox={`tradein_img`} href={sidePhotos[key]} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                                            <img id={`tradein-side-img-${index}`} src={sidePhotos[key]} width="80" height="80" alt="" className="img-object-fit" />
                                                                                        </a>
                                                                                    </div>)
                                                                                }

                                                                            })}

                                                                            {
                                                                                !_.isEmpty(marks) && marks.map((mark, index) => {
                                                                                    return <div key={index}>
                                                                                        {!_.isEmpty(mark.images) && mark.images.map((data, index) => (
                                                                                            <div className="report-ui-thumb-item-image" key={index}>
                                                                                                <a data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                                                    <img id={`tradein-mark-img-${index}`} src={data} width="80" height="80" alt="" className="img-object-fit" />
                                                                                                </a>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <div className="report-ui-thumb-item-image">
                                                                    <a href="#" onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                        <img src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} width="80" height="80" alt="" className="img-object-fit" />
                                                                    </a>
                                                                </div>
                                                            </>
                                                    }

                                                </>



                                            </div>

                                        </div>
                                    </div>
                                    <div className="page-break">
                                        <div className="report-ui-subhead">
                                            VEHICLE INSPECTION

                                        </div>

                                        <div className="report-ui-subcontainer page-break">
                                            <div className="report-ui-inspection-wrap">
                                                <div className="inspection-image">
                                                    <a data-fancybox={`tradein_inspection_img`} href={inspectionSnapshot ? inspectionSnapshot : _images[CommonHelper.getNoVehicleIcons(client?.category, 'inspection')]} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                        <img id={`tradein_inspection_img`} src={getInspection(`tradein_inspection_img`, marks)}
                                                            width="750"
                                                            height="1118"
                                                            alt="" />
                                                    </a>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    {
                                        offer && offer.dealerPrice && _isTradeinCompleted
                                            ?
                                            <>
                                                {
                                                    managerWorkflow
                                                        ?
                                                        <>
                                                            {
                                                                (valuerPrice || ([tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(status) && offer.dealerPrice))
                                                                    ?
                                                                    <>
                                                                        <div className="page-break">
                                                                            <div className="report-ui-subhead">
                                                                                VALUER EVALUATED PRICE
                                                                            </div>

                                                                            <div className="report-ui-subcontainer page-break">
                                                                                <div className="report-ui-valuerprice">
                                                                                    <img src={_images.ti_tickBlue} height="25" />
                                                                                    {
                                                                                        valuerPrice
                                                                                            ?
                                                                                            <NumberFormat value={valuerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                                            :
                                                                                            <NumberFormat value={offer.dealerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    offer.approvedBy && offer.approvedDate && (
                                                                                        <div className="report-ui-approved-wrap approved-black">
                                                                                            <p> <span>Approved By:</span>{(!_.isEmpty(users) && offer.approvedBy) ? CommonHelper.getUserNamebyId(users, offer.approvedBy) : '--'}</p>
                                                                                            <p> <span>ON</span>{(offer.approvedDate) ? moment.unix(offer.approvedDate.seconds).format('DD MMM YYYY') : '--'}</p>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                            {
                                                                (finalPrice || ([tipStatus.WON, tipStatus.LOST].includes(status) && offer.dealerPrice))
                                                                    ?
                                                                    <>
                                                                        <div className="page-break">
                                                                            <div className="report-ui-subhead">
                                                                                FINAL EVALUATED PRICE
                                                                            </div>
                                                                            <div className="report-ui-subcontainer page-break">
                                                                                <div className="report-ui-finalprice">
                                                                                    <img src={_images.ti_tickGreen} height="25" />
                                                                                    {
                                                                                        finalPrice
                                                                                            ?
                                                                                            <NumberFormat value={finalPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                                            :
                                                                                            <NumberFormat value={offer.dealerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    finalizedBy && finalizedDate
                                                                                        ?
                                                                                        <>
                                                                                            <div className="report-ui-approved-wrap approved-black">
                                                                                                <p> <span>Finalized By:</span>{!_.isEmpty(users) ? CommonHelper.getUserNamebyId(users, finalizedBy) : ''}</p>
                                                                                                <p> <span>ON</span>{moment.unix(finalizedDate.seconds).format('DD MMM YYYY')}</p>
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                offer.approvedBy && offer.approvedDate && (
                                                                                                    <div className="report-ui-approved-wrap approved-black">
                                                                                                        <p> <span>Approved By:</span>{(!_.isEmpty(users) && offer.approvedBy) ? CommonHelper.getUserNamebyId(users, offer.approvedBy) : '--'}</p>
                                                                                                        <p> <span>ON</span>{(offer.approvedDate) ? moment.unix(offer.approvedDate.seconds).format('DD MMM YYYY') : '--'}</p>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                            <div className="page-break">
                                                                <div className="report-ui-subhead">
                                                                    FINAL EVALUATED PRICE
                                                                </div>

                                                                <div className="report-ui-subcontainer page-break">
                                                                    <div className="report-ui-finalprice">
                                                                        <img src={_images.ti_tickGreen} height="25" />
                                                                        <NumberFormat value={offer.dealerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                    </div>
                                                                    {
                                                                        offer.approvedBy && offer.approvedDate && (
                                                                            <div className="report-ui-approved-wrap approved-black">
                                                                                <p> <span>Approved By:</span>{(!_.isEmpty(users) && offer.approvedBy) ? CommonHelper.getUserNamebyId(users, offer.approvedBy) : '--'}</p>
                                                                                <p> <span>ON</span>{(offer.approvedDate) ? moment.unix(offer.approvedDate.seconds).format('DD MMM YYYY') : '--'}</p>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                }

                                            </>
                                            : <></>
                                    }
                                    {
                                        (notes && !_.isEmpty(dynamicDetailList.filter(e => ['notes'].includes(e))[0])) || (offer && offer?.documentID && !_.isEmpty(tipOfferNotes.filter(o => o.offerID === offer.documentID)))
                                            ?
                                            <>
                                                <div className="page-break">
                                                    <div className="report-ui-subhead">
                                                        Notes

                                                    </div>
                                                    <div className="report-ui-subcontainer report-ui-notes">

                                                        {
                                                            notes && !_.isEmpty(dynamicDetailList.filter(e => ['notes'].includes(e))[0])
                                                                ?
                                                                <>

                                                                    <div className="report-ui-notes-loop text-area-space">
                                                                        {notes}
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }

                                                        {!_.isEmpty(tipOfferNotes) && offer && offer?.documentID
                                                            ?
                                                            <>
                                                                {
                                                                    _.orderBy(tipOfferNotes.filter(o => o.offerID === offer.documentID), ['addedDate'], ['desc']).map((noteData, index) => {
                                                                        return <div key={index} className="report-ui-notes-loop text-area-space ">
                                                                            {noteData.notes}
                                                                            <div className="report-ui-notes-sub">

                                                                                <span className="pl-0">{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                                                                &nbsp;{' - '}&nbsp;{CommonHelper.getUserNamebyId(users, noteData.addedBy)}


                                                                                {
                                                                                    (noteData.wholeSalerNotes) ? (
                                                                                        <div className="badge-status-white ml-1 text-uppercase">{'wholeSaler'}</div>
                                                                                    ) : (<></>)
                                                                                }

                                                                                {
                                                                                    (noteData.isInternalNotes) ? (
                                                                                        <div className="badge-status-white ml-1 text-uppercase">{'Internal'}</div>
                                                                                    ) : (<></>)
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }

                                                            </>
                                                            :
                                                            <>

                                                            </>}


                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }



                                    <div className="report-ui-disclaimer">The assessments are effective for one week. They are dependent on successful transfer of a clear title and the passing of a satisfactory REVS/WOVS check. The quoted
                                        evaluation hinges on existing vehicle data and/or a visual inspection if one has been performed. A more detailed technical examination may be required later, and it
                                        could lead to a revised price. Vehicle information was obtained electronically through vehicle identifiers. Despite our efforts to maintain precision, this data should not be
                                        fully relied upon. Any inaccuracies or omissions are excepted.</div>



                                    <div className="footer">
                                        <div className="footer-bottom">
                                            <p>
                                                <span style={{ paddingLeft: 0 }} >
                                                    {client?.address ? <>{client.address}</> : <></>}
                                                </span>
                                                <span style={{ paddingLeft: 0 }}>
                                                    {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                    {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                                    {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                                    {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="footer-cpy">
                                            <div className="footer-left">Powered by <a target="_blank" href="http://fusionsd.com.au/">Fusion SD</a></div>
                                            <div className="footer-right">Created On - {!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment().format('DD/MM/YYYY hh:mm A')}</div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>

            }
        </>



    )
};

export default TradeInDetails;
