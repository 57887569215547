/** LIBRARIES */
import React, { Component, createRef } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import toast from 'toasted-notes'
import _ from 'lodash';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopUpModal, ReactSelect, InputTextArea, InputText, ReactMultiSelect } from '../../../components';
import { CustomToggle, CustomSearchMenu } from '../../../components/customdropdown';
import MentionEditor from '../../../components/mentionEditor/mentionEditor';
import Users from '../../users/list/index';
import Contacts from '../../contacts/list/index';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import SearchContainer from '../../contacts/searchContainer';
import PopoverEnquiryList from '../../pipeline/popoverlist';
import { objRequirement } from '../../requirement/viewModel';
/** VIEW-MODELS */
import { callLogStatus, callTypes, logTypes, contactResponseCode, enquiryStatus } from '../../../services/enum'
import { objCallLogs, mandatoryFields } from '../viewModel'
import { objContact } from "../../contacts/viewModel";
import { objEnquiry, objActivitylog } from "../../pipeline/viewModel";
import ContactInfo from '../../contacts/contactInfo'
import { firestoreDB } from "../../../services/helper";

let titles = [];
let arrcallstatus = [];
let phoneCodes = [];
let sale_type = [];
let enquiryTypes = [];
let origins = [];
let campaigns = [];

export default class AddCallLog extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.isLoadingEngage = false;
        this.isLoadingInbound = false;
        this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('callLogs');
        this.inputRef = createRef()
        this.state = {
            fields: Object.assign({}, objCallLogs),
            selectedContact: Object.assign({}, objContact),
            selectedEnquiry: Object.assign({}),
            allClientUsers: Object.assign([]),
            oldFields: Object.assign({}),
            clientSettings: Object.assign({}),
            searchText: '',
            showContacts: false,
            showEnquiries: false,
            showContactsMobile: false,
            showContactsEmail: false,
            errors: {},
            mentions: [],
            makes: [],
            models: [],
            groups: [],
            types: [],
            years: [],
            allDealers: [],
            showContactInfo: {
                show: false,
                data: null
            },
            rosterMsg: null,
            //origins: [],
            teamMentions: null
        }
        this.mentionsData = props.clientUsers.map(clientUser => {
            return {
                name: `@${clientUser.name}`,
                id: clientUser.id,
                avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
            };
        });
        props.dealersettings.client.teams.forEach(team => {
            this.mentionsData.push({
                name: `@${team.name}`,
                id: team.user_ids.join(','),
                avatar: _images.group
            })
        })
    }

    //#region PAGE LOAD EVENTS

    loadCallLog = async (props) => {
        const { docID, show, dealersettings, clientUsers, groupUsers } = props;

        const fields = Object.assign({}, objCallLogs);
        const selectedContact = Object.assign({}, objContact);
        if (show === true && docID) {
            const refData = firestoreDB(dealersettings).firestore().collection('callLogs').doc(docID);
            await refData.get().then(async (doc) => {
                if (doc.exists) {
                    const objData = Object.assign({}, doc.data());
                    objData.documentID = doc.id;

                    for (let [key, value] of Object.entries(objData)) {
                        fields[key] = value;
                    }

                    if (fields.enquiryID && _.isEmpty(fields.enquiry)) {
                        const refStockData = firestoreDB(dealersettings).firestore().collection('enquiries').doc(fields.enquiryID);
                        await refStockData.get().then((_testStock) => {
                            if (_testStock.exists) {
                                const enquiry = Object.assign({}, objEnquiry);
                                const objTDStockData = Object.assign({}, _testStock.data());
                                for (let [key, value] of Object.entries(objTDStockData)) {
                                    if (enquiry.hasOwnProperty(key))
                                        enquiry[key] = value;
                                }
                                enquiry.documentID = fields.enquiryID;
                                fields.enquiry = enquiry;
                                this.setState({ selectedEnquiry: enquiry });
                            }
                            else {
                                console.error("No such document!");
                            }
                        });
                    }

                    this.setState({ fields: fields, selectedContact: fields.contact, oldFields: fields });

                } else {
                    console.error("No such logs document!");
                }
            });
        }
        else if (show === true) {

            if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries) && !_.isEmpty(dealersettings.client)) {
                let countryCode = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
                let phone_code = dealersettings.countries.filter(m => m.code === countryCode)[0];
                if (!_.isEmpty(phone_code)) {
                    selectedContact.phoneCode = phone_code.phone_code ? phone_code.phone_code : '';
                }
            }
            fields.documentID = this.ref.doc().id;
            fields.user = localStorage.uid;
            fields.clientID = dealersettings ? dealersettings.client.id : '';
            this.setState({ fields: fields, selectedContact: selectedContact });

            this.checkUserRoster(localStorage.uid);

        }

        if (show === true) {

            phoneCodes = [];
            dealersettings.countries && dealersettings.countries.forEach((doc) => {
                if (doc.phone_code) {
                    phoneCodes.push({
                        country_code: doc.code,
                        value: doc.phone_code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                    });
                }
            });

            this.loadSettings(dealersettings, fields);
            let _dealers = [];
            dealersettings.clients && dealersettings.clients.forEach(rec => {
                _dealers.push({
                    label: rec.name,
                    value: rec.id,
                    settingsID: rec.settingsID,
                    data: rec,
                    active: true,
                })
            })
            this.setState({
                allDealers: _dealers
            });


        }

    }

    loadSettings = (clientSettings, fields, isChange) => {

        const { clientUsers, dealersettings } = this.props;
        const accessToSelectAllKPIs = dealersettings?.rolePermissions?.permissions?.accessToSelectAllKPIs;

        if (clientSettings && clientSettings.client && clientSettings.client.settings) {
            const setttings = clientSettings.client.settings;

            titles = [];
            setttings.titles && setttings.titles.forEach(doc => {
                titles.push({
                    value: doc.value,
                    label: doc.name,
                });
            });

            arrcallstatus = [];
            setttings.callStatus && setttings.callStatus.forEach((doc) => {
                arrcallstatus.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });



            sale_type = [];
            setttings.salesType && setttings.salesType.forEach(doc => {
                sale_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            origins = [];
            let excludeOrigins = Object.assign([], dealersettings?.rolePermissions?.excludeOrigins)
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? false : _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? true : (accessToSelectAllKPIs ? false : Boolean(doc.autoSelect))
                });
            });

            enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                enquiryTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    name: doc.name,
                    isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            const years = [];
            const makes = [];
            const brands = clientSettings.client.brands;
            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true
                });
            }

            _.orderBy(brands, ['name'], ['asc']).forEach((doc) => {
                makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models,
                    active: _.isBoolean(doc.active) ? doc.active : true
                });
            });

            if (fields?.make) {
                const types = [];
                const models = [];
                const groups = [];
                let _models = !_.isEmpty(makes.filter(m => m.value === fields.make)[0]) ? makes.filter(m => m.value === fields.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }
                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
                this.setState({
                    types: _.orderBy(types, ['label'], ['asc']),
                    groups: _.orderBy(groups, ['label'], ['asc']),
                    models: models
                });

            }

            this.setState({
                makes: makes,
                years: years
            });

        }
        const _clientUsers = [];
        if (isChange) {
            !_.isEmpty(clientSettings.client.users) && clientSettings.client.users.filter(e => !e.isDeleted).forEach((rec) => {
                _clientUsers.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                });
            });

        }
        else if (clientUsers) {
            clientUsers.filter(e => !e.isDeleted).forEach((rec) => {
                _clientUsers.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                });
            });

        }

        this.setState({
            allClientUsers: _clientUsers
        });
    }

    checkUserRoster = (userID) => {
        if (userID) {
            const { dealersettings, groupUsers, clientUsers } = this.props;
            CommonHelper.checkUserRoster(dealersettings,
                {
                    owner: userID,
                    startDate: window.firebase.firestore.Timestamp.now()
                }, (!_.isEmpty(groupUsers) ? groupUsers : clientUsers))
                .then(msg => {
                    this.setState({ rosterMsg: msg });
                })
        }
    }


    componentDidMount() {
        this.loadCallLog(this.props);
    }
    //#endregion


    //#region CONTACT CHANGES
    handleContactClose = (ID, objData) => {
        if (ID && !_.isEmpty(objData)) {

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            const fields = Object.assign({}, this.state.fields);
            fields.contact = Object.assign({}, objData);
            fields.contactID = ID;
            fields.user = fields.contact.owner;
            // fields.notes = (CommonHelper.displayContact([], objData, '--')) + ' is an existing contact. please engage.';
            // fields.notes = `${fields.notes} ${this.state.fields.notes}`
            this.setState({ fields: fields, selectedContact: fields.contact });
            this.checkUserRoster(fields.user);

        }
        else if (ID) {
            const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {

                    const objData = Object.assign({}, doc.data());
                    //Remove empty value from object
                    for (var propName in objData) {
                        if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                            delete objData[propName];
                        }
                    }
                    const customer = Object.assign({}, objContact);
                    for (let [key, value] of Object.entries(objData)) {
                        customer[key] = value;
                    }

                    customer.documentID = doc.id;

                    const fields = Object.assign({}, this.state.fields);
                    fields.contact = Object.assign({}, customer);
                    fields.contactID = doc.id;
                    fields.user = fields.contact.owner;
                    // fields.notes = (CommonHelper.displayContact([], customer, '--')) + ' is an existing contact. please engage.';
                    this.setState({ fields: fields, selectedContact: fields.contact });
                    this.checkUserRoster(fields.user);

                } else {
                    console.error("No such document!");
                }
            });
        }

        this.setState({
            showContacts: false,
            searchText: '',
            showContactsMobile: false,
            showContactsEmail: false,
            showContactInfo:
            {
                show: false,
                data: null
            }
        }, () => { this.errorChange('contact') });

    }

    handleRemoveCustomer = () => {
        const fields = Object.assign({}, this.state.fields);
        fields.contact = {};
        fields.contactID = null;
        // fields.notes = '';

        const selectedContact = Object.assign({}, objContact);
        const { dealersettings } = this.props;
        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries) && !_.isEmpty(dealersettings.client)) {
            let countryCode = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
            let phone_code = dealersettings.countries.filter(m => m.code === countryCode)[0];
            if (!_.isEmpty(phone_code)) {
                selectedContact.phoneCode = phone_code.phone_code ? phone_code.phone_code : '';
            }
        }
        this.setState({ fields: fields, selectedContact: selectedContact });
    }
    //#endregion


    //#region USER CHANGES
    handleStaffClose = (ID, objData) => {
        if (ID) {

            const fields = Object.assign({}, this.state.fields);
            fields.user = ID;
            this.setState({ fields: fields });
            this.checkUserRoster(fields.user);
        }
        this.setState({
            showUsers: false
        }, () => { this.errorChange('user') });
    }

    handleRemoveStaff = () => {
        const fields = Object.assign({}, this.state.fields);
        fields.user = null;
        this.setState({ fields: fields, rosterMsg: null });
    }
    //#endregion

    //#region ENQUIRY CHANGES
    handleEnquiryClose = (ID, objData) => {
        if (ID && !_.isEmpty(objData)) {

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            const fields = Object.assign({}, this.state.fields);
            fields.enquiry = Object.assign({}, objData);
            fields.enquiryID = ID;
            fields.user = fields.enquiry.owner;
            // fields.notes = '';
            this.setState({ fields: fields, selectedEnquiry: fields.enquiry });
            this.checkUserRoster(fields.user);

        }
        this.setState({
            showEnquiries: false
        }, () => { this.errorChange('enquiry') });
    }

    handleRemoveEnquiry = () => {
        const fields = Object.assign({}, this.state.fields);
        fields.enquiry = {};
        fields.enquiryID = null;
        if (!fields.notes)
            // fields.notes = (CommonHelper.displayContact([], fields.contact, '--')) + ' is an existing contact. please engage.';

            this.setState({ fields: fields, selectedEnquiry: {} });
    }
    //#endregion

    //#region CHANGE EVENTS
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (!_.isEmpty(errors)) {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                errors[key] = errorClass;
            }
            else {
                errors[key] = '';
            }
        }

        this.setState({ errors: errors });
    }

    errorContactChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.selectedContact);
        let errorClass = 'input_error';

        if (!_.isEmpty(errors)) {
            if (key === 'firstName' || key === 'lastName' || key === 'middleName' || key === 'email' || key === 'phone') {
                if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                    errors[key] = errorClass;
                }
                else {
                    if (key === 'email' && fields[key].length > 1) {
                        if (typeof fields[key] !== "undefined") {
                            let lastAtPos = fields[key].lastIndexOf('@');
                            let lastDotPos = fields[key].lastIndexOf('.');

                            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                                errors[key] = errorClass;
                            }
                            else
                                errors[key] = '';
                        }
                    }
                    else
                        errors[key] = '';
                }
            }

        }

        this.setState({ errors: errors });
    }

    handleOnAreaChange = (e, notes) => {
        e.preventDefault && e.preventDefault();

        // if (!notes) {
        // 	if (e.target.name === 'title' && e.target.value.length === 0) {
        // 		this.inputRef.current.classList.add('input_error');
        // 	} else {
        // 		this.inputRef.current.classList.remove('input_error');
        // 	}
        // }

        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleSearchText = (e) => {
        this.setState({ searchText: e.target.value }, () => {
            let val = this.state.searchText.trim();
            if (val.length > 2 && !this.state.showContacts) {
                this.setState({ showContacts: true })
            } else if (val.length < 3) {
                this.setState({ showContacts: false })
            }
        });
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.selectedContact);
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);


        if (name === 'email') {
            state[name] = _.trim(value);
            this.setState({ selectedContact: state }, () => { this.errorContactChange(name); });

            if (state.email.length > 2 && !this.state.showContactsEmail) {
                this.setState({ showContactsEmail: true })
            } else if (state.email.length < 3) {
                this.setState({ showContactsEmail: false })
            }

        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                state[name] = newarray1.join(' ');

                if (name === 'firstName' || name === 'middleName' || name === 'lastName') {
                    // fields.notes = (state.firstName ? ((state.firstName ? state.firstName + ' ' : '') + (state.lastName ? state.lastName : '')) + ' is an new contact. please engage.' : '');
                    this.setState({ fields: fields }, () => { this.errorChange('notes'); });
                }

                this.setState({ selectedContact: state }, () => { this.errorContactChange(name); });

            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                state[name] = str;

                if (name === 'firstName' || name === 'middleName' || name === 'lastName') {
                    // fields.notes = (state.firstName ? ((state.firstName ? state.firstName + ' ' : '') + (state.lastName ? state.lastName : '')) + ' is an new contact. please engage.' : '');
                    this.setState({ fields: fields }, () => { this.errorChange('notes'); });
                }

                this.setState({ selectedContact: state }, () => { this.errorContactChange(name); });
            }
        }




    }

    handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        this.setState({ fields: state }, () => { this.errorChange(data.name); });

        if (data.name === 'user')
            this.checkUserRoster(state[data.name]);

    }

    handleDealerChange = async (e, data) => {
        let state = Object.assign({}, this.state.fields);
        if (e) {
            state.clientName = e.label;
            state[data.name] = e.value;

            if (state.contactID) {
                state.contact = {};
                state.enquiry = {};
                state.contactID = null;
                state.enquiryID = null;
                this.setState({ selectedContact: Object.assign({}, objContact), selectedEnquiry: Object.assign({}) });
            }

            state.activityID = null;

            state.callType = null;
            state.callStatus = null;
            state.callDuration = 0;
            state.campaign = null;
            state.origin = null;
            state.enquiryType = null;

            state.make = null;
            state.model = null;
            state.type = null;
            state.group = null;

            const _clientSettings = await window.firebase.firestore().doc(`clients/${state.clientID}/currentSettings/${state.clientID}`).get().then(doc => { if (doc.exists) return doc.data(); else return null; });
            //console.log('_clientSettings', _clientSettings)

            this.loadSettings({ 'client': _clientSettings }, state, true);

            this.setState({ fields: state, clientSettings: _clientSettings }, () => { this.errorChange(data.name) });
        }


    }

    handleReactMultiSelectChange = (selectedOptions) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let state = Object.assign({}, this.state.fields);
        state['teammates'] = value;
        this.setState({ fields: state });
    }

    handleCalldurationChange = val => {
        let state = Object.assign({}, this.state.fields);
        var secs = Number(val.format('mm')) * 60 + Number(val.format('ss'));
        state['callDuration'] = secs;
        this.setState({ fields: state }, () => { this.errorChange('callDuration'); });
    };

    onHandleTelephoneChange = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.selectedContact);
        var regexp = /^[0-9 \b]+$/;
        let telephone = _.trim(value);

        // if value is not blank, then test the regex
        if (telephone === '' || regexp.test(telephone)) {
            state[name] = _.trim(value);
            this.setState({ selectedContact: state }, () => { this.errorContactChange(name) });

            if (_.trim(value) && value.length > 2 && !this.state.showContactsMobile) {
                this.setState({ showContactsMobile: true })
            } else if (value.length < 3) {
                this.setState({ showContactsMobile: false })
            }
        }
        else
            this.setState({ showContactsMobile: false })
    };

    handleAddPhoneCode = (name, value) => {
        let state = Object.assign({}, this.state.selectedContact);
        state[name] = value ? value : '';
        this.setState({ selectedContact: state });
    };

    handleTypeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
            }
        }

        state['model'] = '';
        this.setState({ fields: state, models: models }, () => { this.errorChange(data.name); });

    }

    handleGroupChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.group ? m.group === state.group : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.group ? m.group === state.group : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })
        }

        state['model'] = '';
        this.setState({ fields: state, models: models, types: _.orderBy(types, ['label'], ['asc']) }, () => { this.errorChange(data.name); });

    }

    handleMakeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const types = [];
        const groups = [];
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (e) {
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true
                });
            });
        }

        state['model'] = '';
        state['type'] = '';
        state['group'] = '';
        this.setState({
            fields: state,
            types: _.orderBy(types, ['label'], ['asc']),
            groups: _.orderBy(groups, ['label'], ['asc']),
            models: models
        }, () => { this.errorChange(data.name); });

    }

    //#endregion


    //#region SAVE & SUBMIT 

    handleSubmit = async (e, type) => {
        //console.log('handleSubmit', e, type)
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let fieldsContact = Object.assign({}, this.state.selectedContact);
        let errorClass = 'input_error';
        const evaluateMandatoryFields = () => {
            if (!fields.logType) {
                return ['user', 'logType', 'notes']
            }
            if (fields.logType && fields.logType === 'call') {
                return ['user', 'logType', 'callType', 'notes']
            }
            if (fields.logType && (fields.logType === 'walkin' || fields.logType === 'internet')) {
                return ['user', 'logType', 'notes']
            }
        }
        let mandatoryField = evaluateMandatoryFields(fields)

        if (_.isEmpty(fields.enquiry))
            mandatoryField = _.union(mandatoryField, ['enquiryType', 'origin']);

        if (fieldsContact && fieldsContact['firstName'])
            fields.contact = fieldsContact;

        for (let [key] of Object.entries(fields)) {
            if (mandatoryField.indexOf(key) >= 0) {
                // console.log(key, mandatoryField)
                if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }

            }
        }

        if ((_.isObject(fields.contact) && _.isEmpty(fields.contact)) && !fieldsContact['firstName']) {
            formIsValid = false;
            errors.firstName = errorClass;
        }

        console.log('errors', errors)
        this.setState({ errors: errors });

        if (formIsValid === true) {


            await this.saveContact(fields).then((response) => {
                //console.log('response', response)

                if (response.success === false) {
                    if (response.code === contactResponseCode.PhoneAlreadyExists ||
                        response.code === contactResponseCode.EmailAlreadyExists) {
                        this.handleExistingContact(response, type);
                    }
                    else {
                        toast.notify(response.message, {
                            duration: 2000
                        })
                    }

                    return false;
                }
                else {
                    this.saveLog(type);
                    return true;
                }
            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
            return formIsValid;
        }


    };



    saveLog = async (_type) => {
        const { dealersettings, docID, clientUsers, groupUsers } = this.props;

        if (_type === 'sendEngage')
            this.isLoadingEngage = true;
        else if (_type === 'sendInbound')
            this.isLoadingInbound = true;
        else
            this.isLoading = true;

        const objData = Object.assign({}, this.state.fields);
        const objDataContact = Object.assign({}, this.state.selectedContact);

        for (var propName in objDataContact) {
            if (objDataContact[propName] === null || objDataContact[propName] === undefined || objDataContact[propName] === '' || (_.isObject(objDataContact[propName]) && _.isEmpty(objDataContact[propName]))) {
                delete objDataContact[propName];
            }
        }

        objData.contact = objDataContact;
        let _date = moment()._d;
        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.fromDate(_date);
        }
        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.fromDate(_date);

        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!objData.clientID)
            objData.clientID = dealersettings ? dealersettings.client.id : '';

        if (_type === 'sendInbound') {
            objData.status = callLogStatus.ACCEPTED;
            objData.user = localStorage.uid;
            objData.enquiryID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;

            const { allDealers, clientSettings } = this.state;
            let _settingID = dealersettings.client.settingsID;
            if (objData.clientID && allDealers.filter(e => e.value === objData.clientID)[0]?.settingsID)
                _settingID = allDealers.filter(e => e.value === objData.clientID)[0].settingsID;

            const roleSnapshot = await window.firebase.firestore().collection(`clientSettings/${_settingID}/roles`)
                .where('pipeline', 'array-contains', 'LeadsBucket')
                .get();

            let roleIds = [];
            let _notificationIds = [];
            let userIds = [];
            if (roleSnapshot.docs.length > 0) {
                roleSnapshot.docs.forEach((role) => {
                    roleIds.push(role.id);
                })
            }
            let allDealerUsers = !_.isEmpty(clientSettings?.users) ? clientSettings.users : (!_.isEmpty(groupUsers) ? groupUsers.filter(v => v.clientID === objData.clientID) : clientUsers);
            if (!_.isEmpty(roleIds))
                _notificationIds = _.filter(allDealerUsers, (v) => _.includes(roleIds, v.roleID));

            if (!_.isEmpty(_notificationIds))
                userIds = [..._.map(_notificationIds.filter(e => e.id !== localStorage.uid), function (obj) {
                    return obj.id;
                })];

            if (!_.isEmpty(userIds))
                objData.notifyUserIDs = userIds;

        }
        else {
            objData.status = (_type === 'sendEngage' ? callLogStatus.PENDING : callLogStatus.LOGGED);
            // let userIds = [];

            // if (!_.isEmpty(objData.user))
            //     userIds.push(objData.user);

            // if (!_.isEmpty(objData.teammates))
            //     userIds = _.union(objData.teammates, userIds);

            // // if (mentions.length > 0) {
            // //     if (activityLog.mentions && activityLog.mentions.length > 0) {
            // //         activityLog.mentions = _.union(activityLog.mentions, mentions.map(mention => mention.id));
            // //     } else {
            // //         activityLog.mentions = mentions.map(mention => mention.id);
            // //     }
            // // }

            // console.log('notifyUserIDs', userIds)
        }




        if (!objData.projectId)
            objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        if (!objData.documentID)
            objData.documentID = this.ref.doc().id;

        if (objData['enquiry'])
            delete objData['enquiry'];

        if (!objData.activityID && (objData.status === callLogStatus.LOGGED || objData.status === callLogStatus.ACCEPTED) && objData.enquiryID)
            objData.activityID = firestoreDB(this.props.dealersettings).firestore().collection('activities').doc().id;

        if (this.state.mentions.length > 0) {
            let convertedIDs = []
            let rawIDs = this.state.mentions.map(mentionObject => mentionObject.id)
            rawIDs.forEach(id => {
                if (id.includes(',')) {
                    let allIDs = id.split(',')
                    allIDs.forEach(a => {
                        convertedIDs.push(a)
                    })
                } else {
                    convertedIDs.push(id)
                }
            })

            if (objData.mentions && objData.mentions.length > 0) {
                objData.mentions = _.uniq([...objData.mentions, ...convertedIDs])
            } else {
                objData.mentions = _.uniq(convertedIDs)
            }
        }
        let isExisting;

        // if (!this.props.docID) {
        //     if (this.state.selectedContact.documentID) {
        //         isExisting = true
        //     } else if (this.state.selectedContact.email) {
        //         isExisting = false
        //     }
        //     console.log('at time of save', this.state.selectedContact)
        //     objData.notes = `${CommonHelper.displayContact([], this.state.selectedContact, '--')} ${isExisting ? 'is an existing' : 'is a new'} contact. please engage. ${objData.notes ? objData.notes : ''}`
        // }





        //console.log('Engage request send', objData);
        const updateRef = firestoreDB(dealersettings).firestore().collection('callLogs').doc(objData.documentID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
            this.isLoading = false;
            this.isLoadingEngage = false;
            this.isLoadingInbound = false;
            if (_type === 'sendEngage')
                toast.notify('Engage request send successfully', { duration: 2000 });
            else
                toast.notify('Log saved successfully', { duration: 2000 });

            if (objData.status === callLogStatus.LOGGED && objData.enquiryID) {
                this.addActivitylog(objData);
                this.handleEnquiryUpdate(objData.enquiryID);

                // if (!_.isEmpty(objData.make)) {
                //     const _requirement = Object.assign({}, objRequirement);
                //     for (let [key, value] of Object.entries(objData)) {
                //         if (value && (key === 'make' || key === 'model' || key === 'type' || key === 'year' || key === 'saleType'))
                //             _requirement[key] = value;
                //     }
                //     _requirement.documentID = window.firebase.firestore().collection('enquiries').doc().id;
                //     this.saveReqInfo(objData.enquiryID, _requirement);
                // }
            }

            if (_type === 'sendInbound') {
                this.loadEnquiry(objData);
            }

            if (((objData.logType && objData.logType === 'walkin') || objData.enquiryType && objData.enquiryType.toLowerCase().includes('walk')) && objData.enquiryID) {
                let walkinID = this.ref.doc().id
                firestoreDB(dealersettings).firestore().collection('enquiries').doc(objData.enquiryID).collection('walkins').doc(walkinID).set({
                    addedBy: localStorage.uid,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    documentID: walkinID,
                    clientID: objData.clientID ? objData.clientID : dealersettings ? dealersettings.client.id : '',
                    projectId: dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null,
                    enquiryID: objData.enquiryID,
                    modifiedBy: dealersettings ? dealersettings.id : '',
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    walkinDate: window.firebase.firestore.Timestamp.now(),
                }, { merge: true })
            }

            this.props.handleClose()
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };

    saveReqInfo = (enquiryID, _objReq, clientID) => {
        try {

            const { dealersettings } = this.props;
            const userID = dealersettings ? dealersettings.id : '';
            const objData = Object.assign({}, _objReq);

            if (clientID) objData.clientID = clientID;
            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = userID
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = userID
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';

            if (!objData.projectId)
                objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            if (!objData.enquiryID)
                objData.enquiryID = enquiryID;

            let requirementID = objData['documentID'];

            const refReqData = firestoreDB(dealersettings).firestore().collection(`enquiries/${enquiryID}/requirements`).doc(requirementID);
            refReqData.set(objData, { merge: true }).then((docRef) => {

                let _makeModel = !_.isEmpty(objData.stock) ?
                    (objData.stock.make ? objData.stock.make + ' ' : '') + (objData.stock.model ? objData.stock.model + ' ' : '') + (objData.stock.year ? objData.stock.year : '') :
                    (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '');

                let _logNote = (!_.isEmpty(objData.stock) ? 'Requirement stock added' : 'Requirement added') + (_makeModel ? ' (' + _makeModel + ')' : '');

                CommonHelper.saveChangeLog((clientID ? clientID : dealersettings.client.id), objData.groupID, objData.enquiryID, _logNote, 'enquiry', 'updated');

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        } catch (error) {
            //errorLog(error, 'saveReqInfo');
            console.error("Error adding enquiries: ", error);
        }

    };

    addActivitylog = (objData, _enquiry) => {
        const { dealersettings, clientUsers } = this.props;
        const newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = 'log';
        if (objData.logType && objData.logType === 'walkin') {
            newlogVM.subType = 'note';
        } else if (objData.logType && objData.logType === 'call') {
            newlogVM.subType = 'call';
        } else {
            newlogVM.subType = 'note';
        }


        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.modifiedFrom = 'web';

        // if (!_.isEmpty(objData.teammates))
        //     newlogVM.teammates = objData.teammates;

        if (!_.isEmpty(objData.mentions))
            newlogVM.mentions = objData.mentions;

        newlogVM.addedByName = dealersettings ? dealersettings.name : '';
        newlogVM.modifiedByName = dealersettings ? dealersettings.name : '';
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.startDate = window.firebase.firestore.Timestamp.now();
        newlogVM.enquiryID = objData.enquiryID ? objData.enquiryID : null;
        newlogVM.contactID = objData.contactID ? objData.contactID : null;
        newlogVM['enquiry'] = CommonHelper.getMinifiedData(_enquiry, 'enquiry');
        newlogVM['contact'] = CommonHelper.getMinifiedData(_enquiry?.contact, 'contact');
        newlogVM.clientID = objData.clientID ? objData.clientID : this.props.dealersettings.client.id;
        newlogVM.documentID = objData.activityID ? objData.activityID : firestoreDB(this.props.dealersettings).firestore().collection('activities').doc().id;
        newlogVM.callDuration = objData.callDuration ? objData.callDuration : 0;
        newlogVM.callType = objData.callType ? objData.callType : '';
        newlogVM.callStatus = objData.callStatus ? objData.callStatus : '';
        newlogVM.notes = objData.notes ? objData.notes : '';
        newlogVM.isDone = true;


        // if (this.state.mentions.length > 0) {
        //     if (objData.mentions && objData.mentions.length > 0) {
        //         newlogVM.mentions = _.union(objData.mentions, this.state.mentions.map(mention => mention.id));
        //     } else {
        //         newlogVM.mentions = this.state.mentions.map(mention => mention.id);
        //     }
        // }
        let isExisting;

        // if (!this.props.docID) {
        //     if (this.state.selectedContact.documentID) {
        //         isExisting = true
        //     } else if (this.state.selectedContact.email) {
        //         isExisting = false
        //     }
        //     console.log('at time of save',this.state.selectedContact)
        // newlogVM.notes = `${CommonHelper.displayContact([], this.state.selectedContact, '--')} ${isExisting ? 'is an existing' : 'is a new'} contact. please engage. ${newlogVM.notes}`
        // }
        Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
        newlogVM.projectId = this.props.dealersettings?.client?.projectId ? this.props.dealersettings?.client?.projectId : null;
        firestoreDB(this.props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
            .set(newlogVM, { merge: true })
            .catch(error => {
                console.error(error);
            });
    }

    handleEnquiryUpdate = async (enquiryID) => {
        if (enquiryID) {
            await firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(enquiryID).update({
                modifiedBy: this.props.dealersettings ? this.props.dealersettings.id : '',
                modifiedFrom: 'web',
                modifiedDate: window.firebase.firestore.Timestamp.now()
            }).catch((error) => {
                console.error("Error updating enquiries_details: ", error);
            });
        }
    }

    async saveContact(fields) {
        if (!_.isEmpty(fields.contact) && _.isEmpty(fields.contact.documentID)) {

            const { dealersettings } = this.props;
            let data = Object.assign({}, this.state.selectedContact);
            let objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts({}, dealersettings, fields.clientID, true);

            const contactID = '';
            const mergeID = null;
            const clientID = fields.clientID ? fields.clientID : dealersettings ? dealersettings.client.id : '';
            const oemID = objData?.oemID ? objData?.oemID : null;
            const groupID = objData?.groupID ? objData?.groupID : null;
            const regionID = objData?.regionID ? objData?.regionID : null;
            const isShared = objData?.isShared ? objData?.isShared : false;
            let exists = false;
            let existingContact;
            const phone8digit = data.phone ? data.phone.replace(/\D/g, '').slice(-8) : '';
            const phone = data.phone ? data.phone.replace(/\D/g, '') : '';
            const email = data.email ? data.email.toLowerCase() : '';

            const contactDetailsRef = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
            let query = clientID ? contactDetailsRef.where('clientID', '==', clientID) : null;

            if (isShared === true) {
                if (oemID) query = contactDetailsRef.where('oemID', '==', oemID);
                else if (regionID) query = contactDetailsRef.where('regionID', '==', regionID);
                else if (groupID) query = contactDetailsRef.where('groupID', '==', groupID);
            } else {
                if (oemID) query = contactDetailsRef.where('oemID', '==', oemID).where('isOEM', '==', true);
                else if (regionID) query = contactDetailsRef.where('regionID', '==', regionID).where('isRegion', '==', true);
                else if (groupID) query = contactDetailsRef.where('groupID', '==', groupID).where('isGroup', '==', true);
            }

            if (query) {
                const checkPhone8digit = query.where('keywords', 'array-contains', phone8digit)
                    .where('isDeleted', '==', false).limit(2).get()
                    .then(snapshot => {
                        if (contactID !== '') {
                            snapshot.forEach(doc => {
                                const contct = doc.data();
                                if (contactID !== doc.id && doc.id !== mergeID && contct.phone && contct.phone.replace(/\D/g, '').slice(-8) === phone8digit) {
                                    exists = true;
                                    existingContact = contct;
                                }
                            });
                            return exists;
                        }
                        return exists;
                    });
                if (phone8digit !== '') {
                    const [checkQuery] = await Promise.all([checkPhone8digit]);
                    if (checkQuery) {
                        return { success: false, message: `Contact already exists with phone: ${data.model.phone}`, data: existingContact, code: 'PhoneAlreadyExists' }
                    }
                }

                const checkPhone = query.where('keywords', 'array-contains', phone)
                    .where('isDeleted', '==', false).limit(2).get()
                    .then(snapshot => {
                        if (contactID !== '') {
                            snapshot.forEach(doc => {
                                const contct = doc.data();
                                if (contactID !== doc.id && doc.id !== mergeID && contct.phone && contct.phone.replace(/\D/g, '') === phone) {
                                    exists = true;
                                    existingContact = contct;
                                }
                            });
                            return exists;
                        }
                        return exists;
                    });
                if (phone !== '') {
                    const [checkQuery] = await Promise.all([checkPhone]);
                    if (checkQuery) {
                        return { success: false, message: `Contact already exists with phone: ${data.model.phone}`, data: existingContact, code: 'PhoneAlreadyExists' }
                    }
                }

                const checkEmail = query.where('keywords', 'array-contains', email).where('isDeleted', '==', false).limit(2).get()
                    .then(snapshot => {
                        if (contactID !== '') {
                            snapshot.forEach(doc => {
                                if (contactID !== doc.id && doc.id !== mergeID) {
                                    existingContact = doc.data();
                                    if (existingContact.email && existingContact.email.toLowerCase() === email) exists = true;

                                }
                            });
                            return exists;
                        }
                        return exists;
                    });
                if (email !== '') {
                    const [checkQuery] = await Promise.all([checkEmail]);
                    if (checkQuery) {
                        return { success: false, message: `Contact already exists with email: ${data.model.email}`, data: existingContact, code: 'EmailAlreadyExists' }
                    }
                }

            }
        }
        return { success: true }
    }

    handleExistingContact = (response, type) => {

        let _entity;
        let _msg;
        let _title;
        let _fullName = CommonHelper.displayContactName([], response.data);

        if (response.code === contactResponseCode.PhoneAlreadyExists) {
            _entity = "mobile no.";
            _title = "Mobile No.";
        } else if (response.code === contactResponseCode.EmailAlreadyExists) {
            _entity = "email address";
            _title = "Email";
        } else if (response.code === contactResponseCode.LicenseAlreadyExists) {
            _entity = "license no.";
            _title = "License No.";
        }

        _msg = `A contact ${_fullName} already has this ${_entity}.`;

        Swal.fire({
            title: CommonHelper.showLocale(this.props, `${_title} already exist`),
            html: `${_msg}`,
            showCancelButton: true,
            confirmButtonClass: 'viewButton',
            confirmButtonColor: '#459E20',
            cancelButtonColor: '#17232D',
            confirmButtonText: CommonHelper.showLocale(this.props, 'View Contact'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Okay'),
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                this.handleXContactShow(response.data)
            }
        });
    }

    handleXContactShow = (data) => {
        if (!_.isEmpty(data)) {

            const contact = Object.assign({}, objContact);
            for (let [key, value] of Object.entries(Object.assign({}, data))) {
                if (contact.hasOwnProperty(key))
                    contact[key] = value;
            }

            this.setState({
                showContactInfo:
                {
                    show: true,
                    data: contact
                }
            });
        }

    }

    async loadEnquiry(objLogRequest) {
        //console.log('loadEnquiry', objLogRequest)
        const { dealersettings } = this.props;
        if (dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings) {


            let state = Object.assign({}, objEnquiry);

            if (_.isEmpty(state.addedBy)) {
                state.addedBy = dealersettings ? dealersettings.id : '';
                state.addedDate = window.firebase.firestore.Timestamp.now();
                state.owner = objLogRequest.addedBy ? objLogRequest.addedBy : (dealersettings ? dealersettings.id : '');
            }
            state.modifiedBy = dealersettings ? dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
            state.clientID = objLogRequest.clientID ? objLogRequest.clientID : (dealersettings ? dealersettings.client.id : '');
            state.projectId = objLogRequest.projectId ? objLogRequest.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);

            if (state.clientID === dealersettings.client.id) {
                const setttings = dealersettings.client.settings;
                let _pipeline = Object.assign({}, setttings.pipelines.filter(e => e.value === 'LeadsBucket')[0])
                let _defaultstages = _pipeline.stages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead')
                let _stages = Array.prototype.map.call(_defaultstages, function (item) { return item.value; }).join(",").split(',');

                state.pipeline = _pipeline.value;
                state.stage = _stages[0];
            }
            else if (state.clientID) {

                const _clientSettings = await window.firebase.firestore().doc(`clients/${state.clientID}/currentSettings/${state.clientID}`).get().then(doc => { if (doc.exists) return doc.data(); else return null; });
                const setttings = _clientSettings ? _clientSettings.settings : {};
                let _pipeline = Object.assign({}, (!_.isEmpty(setttings.allPipelines) ? setttings.allPipelines.filter(e => e.value === 'LeadsBucket')[0] : setttings.pipelines.filter(e => e.value === 'LeadsBucket')[0]));
                let _defaultstages = _pipeline.stages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead')
                let _stages = Array.prototype.map.call(_defaultstages, function (item) { return item.value; }).join(",").split(',');

                state.pipeline = _pipeline.value;
                state.stage = _stages[0];
            }

            state.status = null;
            state.ownerInbound = objLogRequest.addedBy ? objLogRequest.addedBy : localStorage.uid;

            if (_.isEmpty(state.tags) && objLogRequest.documentID)
                state.tags = [{ type: 'frontdesk', refID: objLogRequest.documentID, subType: '' }];

            state.stageDate = window.firebase.firestore.Timestamp.now();
            state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
            state.isDeleted = false;
            state.isNewEnquiry = true;

            if (!_.isEmpty(objLogRequest.contact) && objLogRequest.contactID) {
                const refContactData = await this.refContact.doc(objLogRequest.contactID).get();
                if (refContactData.exists) {
                    const contact = Object.assign({}, objContact);
                    const objcontactData = Object.assign({}, refContactData.data());
                    for (let [key, value] of Object.entries(objcontactData)) {
                        contact[key] = value;
                    }
                    contact.documentID = refContactData.id;
                    state.contact = contact;
                    state.isContact = true;
                    state.contactID = refContactData.id;
                    state.isNewEnquiry = false;
                }
            }
            else if (!_.isEmpty(objLogRequest.contact) && !objLogRequest.contactID) {
                var _contact = objLogRequest.contact;
                var ObjCont = {
                    firstName: _contact.firstName ? _contact.firstName : '',
                    middleName: _contact.middleName ? _contact.middleName : '',
                    lastName: _contact.lastName ? _contact.lastName : '',
                    phoneCode: _contact.phoneCode ? _contact.phoneCode : '',
                    phone: _contact.phone ? _contact.phone : '',
                    email: _contact.email ? _contact.email : '',
                }
                state.engageNewContact = ObjCont;
            }

            if (!_.isEmpty(objLogRequest.make)) {
                const _requirement = Object.assign({}, objRequirement);
                for (let [key, value] of Object.entries(objLogRequest)) {
                    if (value && (key === 'make' || key === 'model' || key === 'type' || key === 'group' || key === 'year' || key === 'saleType'))
                        _requirement[key] = value;
                }
                _requirement.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;
                state.requirement = _requirement;
                //state.isVehicle = true;
            }
            // if (_.isEmpty(state.requirement) && _.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
            //     state.requirement = {
            //         saleType: dealersettings.accessSalesType[0]
            //     }
            // }

            if (!_.isEmpty(objLogRequest.logType) && _.isEmpty(objLogRequest.enquiryType)) {
                let _enquiryTypes = enquiryTypes && enquiryTypes.filter(i => (i.name.toLowerCase().includes(objLogRequest.logType) || i.value.toLowerCase().includes(objLogRequest.logType)))[0];
                state.enquiryType = !_.isEmpty(_enquiryTypes) ? _enquiryTypes.value : null;
            }

            if (objLogRequest.enquiryType) state.enquiryType = objLogRequest.enquiryType
            if (objLogRequest.origin) state.origin = objLogRequest.origin
            if (objLogRequest.campaign) state.campaign = objLogRequest.campaign

            const objData = Object.assign({}, state);
            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    if (!['origin'].includes(propName)) delete objData[propName];
                }
            }
            if (objLogRequest.enquiryID)
                objData.documentID = objLogRequest.enquiryID;

            if (!objData.documentID)
                objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;

            const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(objData.documentID);
            updateRef.set(objData, { merge: true }).then((docRef) => {
                let documentID = objData.documentID;
                objLogRequest.enquiryID = documentID;

                if (!_.isEmpty(documentID) && !_.isEmpty(objData.requirement))
                    this.saveReqInfo(documentID, objData.requirement, objData.clientID)

                this.addActivitylog(objLogRequest, objData);

                if (objData.contactID) {
                    const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
                    updateRepeatCustomer({ 'contactID': objData.contactID, 'projectId': objData?.projectId ? objData.projectId : null })
                }

                //this.props.history.push("/enquiry/details/" + documentID + '?type=enquiry');
            }).catch((error) => {
                console.error("Error adding enquiries: ", error);
            });
        }
    }

    onMentionChange = notesText => {
        // if (notesText)
        // notesText = notesText.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        const e = {
            target: {
                name: 'notes',
                value: notesText,
            },
        };

        // this.handleOnAreaChange(e, true);
        const fields = Object.assign({}, this.state.fields);
        fields.notes = notesText
        this.setState({ fields: fields });

    };

    onMentionSelect = (option, ownerID) => {

        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        if (id.includes(',')) {
            let idToPass = id
            if (idToPass.includes(localStorage.uid)) {
                idToPass = idToPass.replace(`${localStorage.uid},`, '')
                idToPass = idToPass.replace(`${localStorage.uid}`, '')
            }
            if (ownerID && idToPass.includes(ownerID)) {
                idToPass = idToPass.replace(`${ownerID},`, '')
                idToPass = idToPass.replace(`${ownerID}`, '')
            }
            this.setState({
                teamMentions: idToPass
            })
        }

        // Check to avoid adding duplicate entries in mention
        const check = this.state.mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            this.setState({
                mentions: [...this.state.mentions, { name, id: idToPass }]
            })
        }
    };

    clearTeamMentions = () => {
        this.setState({
            teamMentions: null
        })
    }



    //#endregion

    render() {
        const { documentID, user, contact, contactID, enquiry, enquiryID, activityID,
            notes, callDuration, callType, callStatus, status, logType,
            campaign, origin, enquiryType, clientID,
            make, model, saleType, type, year, group } = this.state.fields;
        const { firstName, middleName, lastName, phoneCode, phone, email } = this.state.selectedContact;
        const { show, dealersettings, clientUsers, docID, title, groupUsers } = this.props;
        const { searchText, showContacts, selectedEnquiry, showEnquiries, fields, allClientUsers, oldFields, errors,
            showContactsMobile, showContactsEmail, showContactInfo,
            makes, types, models, years, groups, rosterMsg, allDealers } = this.state;

        const isReadOnly = (
            (status === callLogStatus.LOGGED && !_.isEmpty(oldFields) && _.isEmpty(oldFields.enquiryID)) ||
            (status === callLogStatus.LOGGED && _.isEmpty(enquiryID) && _.isEmpty(activityID)) ||
            _.isEmpty(docID)
        ) ? false : true;

        const liteVersion = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.clientSettings) &&
            dealersettings.client.clientSettings.liteVersion) ? true : false);
        return (
            <>
                {
                    !_.isEmpty(fields) && fields.documentID
                        ?
                        <>

                            <Modal
                                id="add-calllogs"
                                show={show}
                                onHide={this.props.handleClose}
                                backdrop="static"
                                backdropClassName={show ? 'overlay-modal active' : ''}
                                dialogClassName={`modal-dialog ${searchText ? '' : 'modal-dialog-scrollable'} modal-dialog-centered modal-calllogs`}>

                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <h5 className="modal-title" >
                                            <Translate text={title ? title : 'callLog'} />
                                            {status
                                                ?
                                                <div className="float-right kitchen-modal-status">
                                                    <div className={`badge badge-pill badge-${status.trim().toLowerCase()}-fill `}>
                                                        {status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                                                    </div>
                                                </div>
                                                :
                                                <></>}
                                        </h5>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body >
                                    <div className={`container-fluid ${isReadOnly ? 'form-disable' : ''}`}>
                                        <div className="row">

                                            <div className="pop-layout form-style">

                                                {localStorage.defaultModule === 'frontdesk' && allDealers.length > 1 && (<div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label > <Translate text={'dealerships'} /></label>
                                                        <ReactSelect
                                                            options={allDealers}
                                                            name={"clientID"}
                                                            placeholder={'select dealer'}
                                                            onChange={this.handleDealerChange}
                                                            value={clientID}
                                                            classNamePrefix={`${this.state.errors["clientID"]} basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>)}

                                                <div className="pop-subhead"><h3><Translate text={'contactInfo'} /></h3></div>


                                                {/* <!-- new customer--> */}

                                                {

                                                    (!_.isEmpty(contact))
                                                        ?
                                                        <>
                                                            <div className="form-group">
                                                                <div className="form-readonly-wrap">
                                                                    <div className="input-readonly">
                                                                        {
                                                                            !docID
                                                                                ?
                                                                                <div className="input-readonly-close">
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleRemoveCustomer(); }}><i className="ico icon-remove"></i> </a>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }

                                                                        <div className="input-readonly-inner">
                                                                            <div className="calllogs-head">
                                                                                {CommonHelper.displayContactIcon(contact, 'mr-1')}
                                                                                {CommonHelper.displayContact(titles, contact)}
                                                                                {/* {contact.displayID ? <span className='calllogs-contact-id'>({contact.displayID})</span> : <></>} */}
                                                                            </div>
                                                                            {
                                                                                (contact.businessContact && !_.isEmpty(contact.company))
                                                                                    ?
                                                                                    <div className="calllogs-head">
                                                                                        <i className={`ico icon-Contacts mr-1`}></i>
                                                                                        {CommonHelper.getFullName([], contact, '')}
                                                                                    </div>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            {dealersettings.group && contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ?
                                                                                <div className="customer-avatar-titlesub blue-color"><strong>{CommonHelper.getOtherDealerName(dealersettings, contact.clientID)}</strong></div> :
                                                                                <></>}

                                                                            {contact.regionID && dealersettings.regionID && contact.regionID !== dealersettings.regionID ?
                                                                                <div className="customer-avatar-titlesub blue-color"><strong>{CommonHelper.getNameByCode(dealersettings.regions, contact.regionID, '')}</strong></div>
                                                                                : <></>}
                                                                            {(contact.phone || contact.email)
                                                                                ?
                                                                                <div className="calllogs-contact-sub">
                                                                                    <ul className="calllogs-contact-details">
                                                                                        {contact.phone ? <li> <i className="ico icon-call"></i>{CommonHelper.phoneFormat(contact.phoneCode, contact.phone, dealersettings)}</li> : <></>}
                                                                                        {contact.email ? <li> <i className="ico icon-email"></i>{contact.email}</li> : <></>}
                                                                                    </ul>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="form-group position-relative">
                                                                <div className="calllogs-search ">
                                                                    <InputText
                                                                        value={searchText}
                                                                        onChange={this.handleSearchText}
                                                                        autoComplete="off"
                                                                        placeholder={'search contact'}
                                                                        className={`mb-2`}
                                                                    />

                                                                    <SearchContainer
                                                                        history={this.props.history}
                                                                        showSearchPanel={showContacts}
                                                                        dealersettings={dealersettings}
                                                                        searchText={searchText ? searchText : ''}
                                                                        clientID={clientID}
                                                                        handleClose={this.handleContactClose}//{() => this.setState({ showContacts: false, searchText: '' })}
                                                                        handleSearchClose={() => this.setState({ showContacts: false })}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="form-row">
                                                                <div className="form-group col-md-6">
                                                                    <label ><Translate text={'firstName'} /></label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        placeholder={'first name'}
                                                                        className={`form-control ${this.state.errors["firstName"]}`}
                                                                        name="firstName"
                                                                        onChange={this.handleOnChange}
                                                                        value={firstName}
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-3">
                                                                    <label ><Translate text={'middleName'} /></label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        placeholder={'middle name'}
                                                                        className={`form-control ${this.state.errors["middleName"]}`}
                                                                        name="middleName"
                                                                        onChange={this.handleOnChange}
                                                                        value={middleName}
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-3">
                                                                    <label ><Translate text={'lastName'} /></label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        placeholder={'last name'}
                                                                        className={`form-control ${this.state.errors["lastName"]}`}
                                                                        name="lastName"
                                                                        onChange={this.handleOnChange}
                                                                        value={lastName}
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label ><Translate text={'phone'} /></label>
                                                                    <div className={`input-group country-code mb-3 ${this.state.errors["phone"] ? 'phone_error' : ''}`}>
                                                                        <div className="input-group-prepend">
                                                                            <Dropdown className={`btn btn-outline-secondary`} >
                                                                                <Dropdown.Toggle as={CustomToggle} >
                                                                                    <span id="spn-code-name" data-name={phoneCode}>
                                                                                        {phoneCode && phoneCodes.find(item => item.value === phoneCode)
                                                                                            ? phoneCodes.find(item => item.value === phoneCode).value + ' ' :
                                                                                            'Code'
                                                                                        }</span>
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu as={CustomSearchMenu}
                                                                                    className="rm-pointers dropdown-menu-lg dropdown-menu-right"
                                                                                    ChildClass="pipeline-dropdown"
                                                                                    xplacement="bottom-end"
                                                                                >
                                                                                    {
                                                                                        phoneCodes && phoneCodes.length > 0 ?
                                                                                            phoneCodes.map((rec, index) => {
                                                                                                return (<Dropdown.Item key={index} className={`current-pipeline ${(phoneCode && rec.value === phoneCode) ? 'active' : ''}`} onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.handleAddPhoneCode('phoneCode', rec.value)
                                                                                                }}>
                                                                                                    {rec.label}
                                                                                                </Dropdown.Item>)
                                                                                            })
                                                                                            : (<></>)
                                                                                    }
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>

                                                                        <div style={{ flex: 'auto' }}>
                                                                            <input
                                                                                type="text"
                                                                                autoComplete="off"
                                                                                placeholder={'phone'}
                                                                                pattern="[0-9]*"
                                                                                className={`form-control `}
                                                                                name="phone"
                                                                                onChange={this.onHandleTelephoneChange}
                                                                                value={phone}
                                                                            />
                                                                            <SearchContainer
                                                                                history={this.props.history}
                                                                                showSearchPanel={showContactsMobile}
                                                                                dealersettings={dealersettings}
                                                                                searchText={phone ? phone : ''}
                                                                                clientID={clientID}
                                                                                handleClose={this.handleContactClose}
                                                                                className={'globalsearch-wrap-sm'}
                                                                                handleSearchClose={() => this.setState({ showContactsMobile: false })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-6 position-relative">
                                                                    <label ><Translate text={'email'} /></label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        placeholder={'email'}
                                                                        className={`form-control ${this.state.errors["email"]}`}
                                                                        name="email"
                                                                        onChange={this.handleOnChange}
                                                                        value={email}
                                                                    />
                                                                    <SearchContainer
                                                                        history={this.props.history}
                                                                        showSearchPanel={showContactsEmail}
                                                                        dealersettings={dealersettings}
                                                                        searchText={email ? email : ''}
                                                                        clientID={clientID}
                                                                        handleClose={this.handleContactClose}
                                                                        className={'globalsearch-wrap-sm'}
                                                                        handleSearchClose={() => this.setState({ showContactsEmail: false })}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </>
                                                }


                                                {
                                                    (!_.isEmpty(enquiry))
                                                        ?
                                                        <>
                                                            <div className="pop-subhead"><h3><Translate text={'Link Enquiry'} /></h3></div>
                                                            <div className="calllogs-vehicle-box">
                                                                {
                                                                    !isReadOnly
                                                                        ?
                                                                        <>
                                                                            <div className="input-readonly-close">
                                                                                <a href="#"
                                                                                    onClick={(e) => { e.preventDefault(); this.handleRemoveEnquiry(); }}>
                                                                                    <i className="ico icon-remove"></i>
                                                                                </a>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }
                                                                <div className="flex-vehicle">
                                                                    <div className="vehicle-item-image"> <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                        <img src={CommonHelper.showBrandLogo(dealersettings, (!_.isEmpty(enquiry?.requirement?.stock) ? enquiry.requirement.stock?.make : enquiry.requirement?.make))} className="img-object-fit-contain" alt="" width="70" height="70" /> </a>
                                                                    </div>
                                                                    <div className="vehicle-data">
                                                                        {
                                                                            enquiry.displayID ?
                                                                                (<div className="vehicle-item-info"><i className={`ico icon-enquiry mr-1`}></i>{enquiry.displayID}</div>) :
                                                                                (<></>)
                                                                        }
                                                                        {
                                                                            !_.isEmpty(enquiry.requirement) ?
                                                                                (<><div className="vehicle-item-title">{!_.isEmpty(enquiry.requirement.stock) ? (enquiry.requirement.stock.make + ' ' + enquiry.requirement.stock.model) : (enquiry.requirement.make + ' ' + enquiry.requirement.model)}
                                                                                    {
                                                                                        enquiry.requirement.saleType
                                                                                            ?
                                                                                            <>
                                                                                                <div className="badge badge-pill badge-white ml-1">
                                                                                                    {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                        enquiry.requirement.saleType, '')}
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                    }
                                                                                </div></>) :
                                                                                <>
                                                                                    {
                                                                                        liteVersion && !_.isEmpty(enquiry.td) && !_.isEmpty(enquiry.td.make)
                                                                                            ?
                                                                                            <>
                                                                                                <div className="vehicle-item-title">
                                                                                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-activity-testdrive')}></i>
                                                                                                    {enquiry.td.make + ' ' + enquiry.td.model}
                                                                                                    {
                                                                                                        enquiry.td.saletype
                                                                                                            ?
                                                                                                            <>
                                                                                                                <div className="badge badge-pill badge-white ml-1">
                                                                                                                    {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                                        enquiry.td.saletype, '')}
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                            </>
                                                                                                    }
                                                                                                </div>

                                                                                            </>
                                                                                            :
                                                                                            <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>
                                                                                    }

                                                                                </>
                                                                        }

                                                                        <div className="vehicle-item-status">
                                                                            <div className="calllogs-vehicle-owner">
                                                                                <div className="owner-view">
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                        <img src={CommonHelper.getUserImagebyId(clientUsers, enquiry.owner)} className="img-object-fit" />
                                                                                    </a>
                                                                                </div>
                                                                                {CommonHelper.getUserNamebyId(clientUsers, enquiry.owner)}
                                                                            </div>
                                                                            {
                                                                                enquiry.status ? <>
                                                                                    {(() => {
                                                                                        let __status = (enquiry.status && enquiry.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : enquiry.status;
                                                                                        const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
                                                                                        let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

                                                                                        return <>
                                                                                            {
                                                                                                _objCurrentStatus
                                                                                                    ?
                                                                                                    <>
                                                                                                        <span
                                                                                                            className={`status-${enquiry.status === 'open' ? 'open' : 'empty'} float-right`}
                                                                                                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                                                                        >
                                                                                                            <Translate
                                                                                                                text={_objCurrentStatus.name}
                                                                                                                upperCase={true}
                                                                                                            />
                                                                                                        </span>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>


                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                    })()}

                                                                                </>
                                                                                    : (<></>)
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                !isReadOnly
                                                                    ?
                                                                    <>
                                                                        <div className="pop-subhead"><h3><Translate text={'Link Enquiry'} /></h3></div>
                                                                        <div className='form-group'>
                                                                            <div className="calllogs-search ">
                                                                                <input
                                                                                    type='text'
                                                                                    className='mb-2'
                                                                                    placeholder='select enquiry'
                                                                                    readOnly
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        this.setState({ showEnquiries: true })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>


                                                                    </>
                                                                    :
                                                                    <>
                                                                    </>
                                                            }
                                                            <div className="pop-subhead mt-3"> <h3><Translate text={'Vehicle Info'} /></h3></div>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-6">
                                                                    <label> <Translate text={'make'} /></label>
                                                                    <ReactSelect
                                                                        options={makes}
                                                                        name={"make"}
                                                                        placeholder={'select vehicle make'}
                                                                        onChange={this.handleMakeChange}
                                                                        value={make}
                                                                        classNamePrefix={`cursor-pointer ${errors["make"]} basic-select`}
                                                                        AddUnAvailable={true}
                                                                        menuPlacement='bottom'
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label><Translate text={'group'} /></label>
                                                                    <ReactSelect
                                                                        options={groups}
                                                                        name={"group"}
                                                                        placeholder={'select vehicle group'}
                                                                        onChange={this.handleGroupChange}
                                                                        value={group}
                                                                        classNamePrefix={`cursor-pointer ${errors["group"]} basic-select`}
                                                                        AddUnAvailable={true}
                                                                        menuPlacement='bottom'
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label><Translate text={'type'} /></label>
                                                                    <ReactSelect
                                                                        options={types}
                                                                        name={"type"}
                                                                        placeholder={'select vehicle type'}
                                                                        onChange={this.handleTypeChange}
                                                                        value={type}
                                                                        classNamePrefix={`cursor-pointer ${errors["type"]} basic-select`}
                                                                        AddUnAvailable={true}
                                                                        menuPlacement='bottom'
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label> <Translate text={'model'} /></label>
                                                                    <ReactSelect
                                                                        options={models}
                                                                        name={"model"}
                                                                        placeholder={'select vehicle model'}
                                                                        onChange={this.handleReactSelectChange}
                                                                        value={model}
                                                                        classNamePrefix={`cursor-pointer ${errors["model"]} basic-select`}
                                                                        AddUnAvailable={true}
                                                                        menuPlacement='bottom'
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label><Translate text={'year'} /></label>
                                                                    <ReactSelect
                                                                        options={years}
                                                                        name={"year"}
                                                                        placeholder={'select vehicle year'}
                                                                        onChange={this.handleReactSelectChange}
                                                                        value={year}
                                                                        classNamePrefix={`cursor-pointer ${errors["year"]} basic-select`}
                                                                        menuPlacement='bottom'
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label ><Translate text={'saleType'} /></label>
                                                                    <ReactSelect
                                                                        options={sale_type}
                                                                        name={"saleType"}
                                                                        placeholder={'select vehicle sale type'}
                                                                        onChange={this.handleReactSelectChange}
                                                                        value={saleType}
                                                                        classNamePrefix={`cursor-pointer ${errors["saleType"]} basic-select`}
                                                                        menuPlacement='bottom'
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            </div>
                                                            <div className="pop-subhead mt-3"> <h3><Translate text={'Enquiry Info'} /></h3></div>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-6">
                                                                    <label ><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'enquiryType')} /></label>
                                                                    <ReactSelect
                                                                        options={enquiryTypes}
                                                                        name={"enquiryType"}
                                                                        placeholder={'select enquiry type'}
                                                                        onChange={this.handleReactSelectChange}
                                                                        value={enquiryType}
                                                                        classNamePrefix={`cursor-pointer ${errors["enquiryType"]} basic-select`}
                                                                        removeClearable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>

                                                                <div className="form-group col-md-6">
                                                                    <label ><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'origin')} /></label>
                                                                    <ReactSelect
                                                                        options={origins}
                                                                        name={"origin"}
                                                                        placeholder={'select origin'}
                                                                        onChange={this.handleReactSelectChange}
                                                                        value={origin}
                                                                        classNamePrefix={`cursor-pointer ${errors["origin"]} basic-select`}
                                                                        removeClearable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>

                                                                <div className="form-group col-md-6">
                                                                    <label ><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'campaign')} /></label>
                                                                    <ReactSelect
                                                                        options={campaigns}
                                                                        name={"campaign"}
                                                                        placeholder={'select campaign'}
                                                                        onChange={this.handleReactSelectChange}
                                                                        value={campaign}
                                                                        classNamePrefix={`cursor-pointer ${errors["campaign"]} basic-select`}
                                                                        removeClearable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            </div>
                                                        </>
                                                }


                                                <div className="pop-subhead mt-3"> <h3><Translate text={'Log Info'} /></h3></div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'Log Type'} /></label>
                                                        <div className='inner-addon right-addon'>
                                                            <ReactSelect
                                                                options={logTypes}
                                                                name={'logType'}
                                                                onChange={this.handleReactSelectChange}
                                                                value={logType}
                                                                classNamePrefix={`cursor-pointer ${this.state.errors["logType"]} basic-select`}
                                                            />
                                                        </div>
                                                    </div>
                                                    {logType === 'call' ? (
                                                        <>
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'Call Type'} /></label>
                                                                <div className='inner-addon right-addon'>
                                                                    <ReactSelect
                                                                        options={callTypes}
                                                                        name={'callType'}
                                                                        onChange={this.handleReactSelectChange}
                                                                        value={callType}
                                                                        classNamePrefix={`cursor-pointer ${this.state.errors["callType"]} basic-select`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                (callType && callType === 'outbound')
                                                                    ?
                                                                    <div className="form-group col-md-6">
                                                                        <label ><Translate text={'Call Status'} /></label>
                                                                        <div className='inner-addon right-addon'>
                                                                            <ReactSelect
                                                                                options={arrcallstatus}
                                                                                name={'callStatus'}
                                                                                onChange={this.handleReactSelectChange}
                                                                                value={callStatus}
                                                                                classNamePrefix={`cursor-pointer ${this.state.errors["callStatus"]} basic-select`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                            }
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'Call Duration'} /></label>
                                                                <div className='inner-addon right-addon'>
                                                                    <TimePicker
                                                                        value={CommonHelper.convertminstoDate(callDuration)}
                                                                        showHour={false}
                                                                        onChange={this.handleCalldurationChange}
                                                                        name={'callDuration'}
                                                                        allowEmpty={false}
                                                                        className={`input-time-picker w-100 ${this.state.errors["callDuration"]}`}
                                                                        popupClassName='pop-time-picker'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}


                                                    {/* <div className="form-group col-md-12">
                                                        <label ><Translate text={'teammate'} /></label>
                                                        <ReactMultiSelect
                                                            options={allClientUsers ? user ? allClientUsers.filter(e => e.value !== user) : allClientUsers : []}
                                                            name={'teammates'}
                                                            placeholder={'select teammate'}
                                                            onChange={this.handleReactMultiSelectChange}
                                                            value={teammates}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                            menuPlacement='top'
                                                        ></ReactMultiSelect>
                                                    </div> */}

                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'user'} /></label>
                                                        <ReactSelect
                                                            options={allClientUsers}
                                                            name={"user"}
                                                            placeholder={'select user'}
                                                            onChange={this.handleReactSelectChange}
                                                            value={user}
                                                            classNamePrefix={`${this.state.errors["user"]} basic-select`}
                                                            removeClearable={true}
                                                            isSearchable={true}
                                                            //isDisabled={enquiryID ? true : false}
                                                            menuPlacement='top'
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                                <div className="form-group" id='notes'>
                                                    <label ><Translate text={this.state.fields.logType === 'call' ? 'Call Summary' : 'Notes'} /></label>
                                                    {/* <InputTextArea
                                                        className={`form-control notes ${this.state.errors["notes"]}`}
                                                        name="notes"
                                                        onChange={this.handleOnAreaChange}
                                                        value={notes}
                                                        maxLength="280"
                                                        rows="3">
                                                    </InputTextArea> */}
                                                    <MentionEditor
                                                        mentionsData={this.mentionsData}
                                                        onMentionChange={this.onMentionChange}
                                                        onMentionSelect={this.onMentionSelect}
                                                        notes={this.state.fields.notes ? this.state.fields.notes : ''}
                                                        focusOnMount={false}
                                                        isNoteEmpty={this.state.errors.notes === 'input_error' && this.state.fields.notes === '' ? true : false}
                                                        teamMentions={this.state.teamMentions}
                                                        clearTeamMentions={this.clearTeamMentions}
                                                        clientUsers={this.props.clientUsers}
                                                    // ownerID={this.state.fields.user}
                                                    />
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </Modal.Body>

                                {
                                    (!isReadOnly)
                                        ?
                                        <>
                                            <Modal.Footer className={`modal-footer ${(this.isLoading || this.isLoadingInbound || this.isLoadingEngage) ? 'form-disable' : ''}`}>
                                                {
                                                    enquiryID
                                                        ?
                                                        <></>
                                                        :
                                                        <>
                                                            {
                                                                !_.isEmpty(rosterMsg) ? (
                                                                    <div className="activity-pop-info alert-danger mb-3">
                                                                        <i className="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>{rosterMsg}
                                                                    </div>
                                                                ) : (<></>)
                                                            }

                                                            <button type="button"
                                                                className={`btn btn-info float-right ml-2`}
                                                                onClick={(e) => this.handleSubmit(e, 'sendInbound')}>
                                                                {this.isLoadingInbound ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                <Translate text={'sendToInBound'} />
                                                            </button>

                                                            <button type="button"
                                                                className={`btn btn-green float-right ml-2`}
                                                                onClick={(e) => this.handleSubmit(e, 'sendEngage')}>
                                                                {this.isLoadingEngage ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                <Translate text={'sendEngage'} />
                                                            </button>
                                                        </>
                                                }

                                                <button type="button"
                                                    className={`btn btn-primary float-right ml-2`}
                                                    onClick={(e) => this.handleSubmit(e, 'save')}>
                                                    {this.isLoading ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                    <Translate text={'save'} />
                                                </button>
                                                <button type="button"
                                                    className="btn btn-default float-left"
                                                    onClick={(e) => { e.preventDefault(); this.props.handleClose() }}>
                                                    <Translate text={'cancel'} />
                                                </button>

                                            </Modal.Footer>
                                        </>
                                        :
                                        <>
                                        </>
                                }

                            </Modal>

                            <PopUpModal show={showEnquiries}>
                                <PopoverEnquiryList
                                    show={showEnquiries}
                                    dealersettings={dealersettings}
                                    clientUsers={clientUsers}
                                    groupUsers={groupUsers}
                                    handleEnquiryClose={this.handleEnquiryClose}
                                    contactID={contactID}
                                />
                            </PopUpModal>

                            <PopUpModal show={showContactInfo.show}>
                                <ContactInfo
                                    show={showContactInfo.show}
                                    existingContact={showContactInfo.data}
                                    handleClose={this.handleContactClose}
                                    isViewOnly={true}
                                    title={'contactDetails'}
                                    dealersettings={dealersettings}
                                    clientUsers={clientUsers}
                                    groupUsers={groupUsers}
                                >
                                </ContactInfo>
                            </PopUpModal>

                        </>
                        :
                        <></>


                }
            </>
        );
    }
}
