import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import { InputText, ReactSelect, ReactMultiSelect, InputCheckBox, PopUpModal } from '../../../../components'
import Translate from '../../../../constants/translate';
import { firestoreDB } from '../../../../services/helper';
import { pipelineVM, levelOptions } from './viewModel'
import MovePipeline from './movePipeline';
import CommonHelper from '../../../../services/common';

const AddPipeline = (props) => {
    const [pipeline, setPipeline] = useState(props.pipeline);
    const [errorFields, setErrorFields] = useState({});
    const newPipeline = !_.isEmpty(props.pipeline) ? false : true
    const [loader, setLoader] = useState(false)
    const [deletePipeline, setDeletePipeline] = useState(null)
    const [popHeader, setPopHeader] = useState('')
    const [editPipeline, setEditPipeline] = useState(null)
    const [showMovePipeline, setShowMovePipeline] = useState(false)
    const [roles, setRoles] = useState([])
    const pipelineModules = (props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.pipelineModules) ? props.dealersettings.client.settings.pipelineModules : [];

    useEffect(() => {
        if (_.isEmpty(props.pipeline)) {
            const newpiplineVM = Object.assign({}, pipelineVM);
            setPipeline({
                ...newpiplineVM,
                stages: [{
                    name: 'New',
                    value: uuid(),
                    rottenDays: 0,
                }],
                roles: []
            })
        }
        else {
            setPipeline({
                ...pipeline,
                levelID: _.isArray(pipeline.levelID) ? pipeline.levelID : [pipeline.levelID]
            })
        }
    }, [])

    useEffect(() => {
        let _roles = [];
        if (props?.dealersettings?.client?.roles) {
            _roles.push({ label: 'All', value: 'all' })
            _.orderBy(props?.dealersettings?.client?.roles, 'name', 'asc').filter(a =>
                (_.isEmpty(a.clientID) || a.clientID === props?.dealersettings?.client?.id) &&
                a?.modules?.enquiry === true
            ).map(rec => {
                return _roles.push({
                    label: rec.name,
                    value: rec.id
                })
            });
        }
        setRoles(_roles)
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setPipeline({
                ...pipeline,
                [name]: newarray1.join(' '),
                ['value']: newPipeline ? uuid() : pipeline.value
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setPipeline({
                ...pipeline,
                [name]: str,
                ['value']: newPipeline ? uuid() : pipeline.value
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'level' && e.value === 'oem') {
            setPipeline({
                ...pipeline,
                [data.name]: e.value,
                ['levelID']: props.dealersettings.client.settingsID
            });
        }
        else {
            setPipeline({
                ...pipeline,
                [data.name]: e.value
            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        if (selectedOptions?.length > 0 && selectedOptions.some(item => item.value === 'all')) {
            value = roles.filter(e => e.value !== 'all').map(rec => { return rec.value })
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value.push(data.value);
            })
        }

        setPipeline({
            ...pipeline,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }


    const savePipeline = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(pipeline['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (_.isEmpty(pipeline['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (!_.isEmpty(pipeline.level) && pipeline.level !== 'oem' && _.isEmpty(pipeline['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        setErrorFields(errors)
        if (newPipeline && props.allPipelines.some(item => item.value.toLowerCase() === pipeline.value.toLowerCase())) {
            Swal.fire(CommonHelper.showLocale(props, 'Pipeline with same name already exists'), '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        if (pipeline.active === false) {

            const pipelineEnq = await firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('clientID', '==', props.selectedClient.documentID)
                .where('isDeleted', '==', false)
                .where('pipeline', '==', pipeline.value)
                .limit(1)
                .get();
            if (pipelineEnq.docs.length > 0) {
                setPopHeader(`Inactive ${pipeline.name} Pipeline`)
                setEditPipeline(pipeline)
                setDeletePipeline(pipeline)
                setShowMovePipeline(true)
                setLoader(false)
                return
            }
        }
        let clinetpromise = []
        const batch = window.firebase.firestore().batch();
        if (pipeline.level === 'oem') {
            const pipelineObj = {
                'pipelines': newPipeline ? [..._.map(props.allPipelines.filter(item => item.level === pipeline.level), function (obj) { return { ..._.pick(obj, _.keys(pipelineVM)) }; }), _.pick(pipeline, _.keys(pipelineVM))] :
                    [..._.map(props.allPipelines.filter(item => item.level === pipeline.level), function (obj) {
                        return obj.value === pipeline.value ? { ..._.pick(pipeline, _.keys(pipelineVM)) } : { ..._.pick(obj, _.keys(pipelineVM)) };
                    })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
                pipelineObj, { merge: true });
            clinetpromise.push(window.firebase.firestore().collection(`clients`).where('settingsID', '==', props.dealersettings.client.settingsID).get());
        }
        else if (_.isArray(pipeline.levelID)) {
            pipeline.levelID.forEach(rec => {
                const pipelineObj = {
                    'pipelines': newPipeline ? [..._.map(props.allPipelines.filter(item => item.level === pipeline.level && rec === item.levelID), function (obj) { return { ..._.pick(obj, _.keys(pipelineVM)) }; }), _.pick(pipeline, _.keys(pipelineVM))] :
                        [..._.map(props.allPipelines.filter(item => item.level === pipeline.level && rec === item.levelID), function (obj) {
                            return obj.value === pipeline.value ? { ..._.pick(pipeline, _.keys(pipelineVM)) } : { ..._.pick(obj, _.keys(pipelineVM)) };
                        })]
                }
                console.log(pipelineObj)
                batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${pipeline.level === 'group' ? 'groups' : pipeline.level === 'region' ? 'regions' : 'clients'}/${rec}`),
                    pipelineObj, { merge: true });
                clinetpromise.push(window.firebase.firestore().collection(`clients`).where(`${pipeline.level === 'individual' ? 'documentID' : pipeline.level}`, '==', rec).get());

            })
        }
        else {
            const pipelineObj = {
                'pipelines': newPipeline ? [..._.map(props.allPipelines.filter(item => item.level === pipeline.level && pipeline.levelID === item.levelID), function (obj) { return { ..._.pick(obj, _.keys(pipelineVM)) }; }), _.pick(pipeline, _.keys(pipelineVM))] :
                    [..._.map(props.allPipelines.filter(item => item.level === pipeline.level && pipeline.levelID === item.levelID), function (obj) {
                        return obj.value === pipeline.value ? { ..._.pick(pipeline, _.keys(pipelineVM)) } : { ..._.pick(obj, _.keys(pipelineVM)) };
                    })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${pipeline.level === 'group' ? 'groups' : pipeline.level === 'region' ? 'regions' : 'clients'}/${pipeline.levelID}`),
                pipelineObj, { merge: true });
            clinetpromise.push(window.firebase.firestore().collection(`clients`).where(`${pipeline.level === 'individual' ? 'documentID' : pipeline.level}`, '==', pipeline.levelID).get());
        }
        if (newPipeline) {
            let pipelinesortpromise = []
            const clinetSnapsnot = await Promise.all(clinetpromise);
            clinetSnapsnot.forEach((clients) => {
                clients.docs.forEach(client => {
                    if (client.exists)
                        pipelinesortpromise.push(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${client.id}/enquiryOptions/${client.id}`).get());
                })
            })
            const pipelinesortSnapsnot = await Promise.all(pipelinesortpromise);
            pipelinesortSnapsnot.forEach((sortOption) => {
                if (sortOption.exists && !_.isEmpty(sortOption.data().pipelines)) {
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${sortOption.id}/enquiryOptions/${sortOption.id}`),
                        {
                            pipelines: [...sortOption.data().pipelines.filter(item => item !== pipeline.value), pipeline.value]
                        }, { merge: true });
                }
            })
        }
        batch.commit()
            .then(snapshot => {
                setLoader(false)
                //update settings date and priority
                if (!_.isEmpty(props.selectedClient)) {
                    window.firebase.firestore().doc(`clients/${props.selectedClient.documentID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Pipeline Settings has been updated',
                        }, { merge: true })
                }
                if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Pipeline Settings has been updated',
                        }, { merge: true })
                }
                //end
                if (newPipeline) {
                    //when the new pipeline is added then it default enable to administrator role
                    window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/roles`)
                        .where('name', '==', 'Administrator')
                        .get()
                        .then(snapshot => {
                            snapshot.docs.forEach(doc => {
                                if (doc.exists) {
                                    var _pl = !_.isEmpty(doc.data().pipeline) ? doc.data().pipeline : [];
                                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/roles/${doc.id}`)
                                        .set({ 'pipeline': [..._pl.filter(item => item !== pipeline.value), pipeline.value] }, { merge: true })
                                        .catch(error => console.log('error'))
                                }
                            })
                        })
                    if (props.dealersettings.roleName !== 'Administrator') {
                        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/roles/${props.dealersettings.roleID}`)
                            .get()
                            .then(doc => {
                                if (doc.exists) {
                                    var _pl = !_.isEmpty(doc.data().pipeline) ? doc.data().pipeline : [];
                                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/roles/${doc.id}`)
                                        .set({ 'pipeline': [..._pl.filter(item => item !== pipeline.value), pipeline.value] }, { merge: true })
                                        .catch(error => console.log('error'))
                                }
                            })
                    }
                    if (pipeline.roles) {
                        pipeline.roles.forEach(rec => {
                            window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/roles/${rec}`)
                                .set({
                                    'pipeline': window.firebase.firestore.FieldValue.arrayUnion(pipeline.value)
                                }, { merge: true })
                        })
                    }
                }
                toast.notify('Pipeline ' + (newPipeline ? 'added' : 'updated') + ' successfully', {
                    duration: 2000
                })
                props.handleClose(pipeline)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });

    }

    const handleEnqModules = (e) => {
        setPipeline({
            ...pipeline,
            'modules': {
                ...pipeline.modules,
                [e.target.name]: e.target.checked
            }
        })
    }

    const handleCheckedChange = (e) => {
        setPipeline({
            ...pipeline,
            [e.target.name]: e.target.checked
        })
    }

    const handleModelClose = (pipeline) => {
        setShowMovePipeline(false)
        setDeletePipeline(null)
        setEditPipeline(null)
        if (!_.isEmpty(pipeline)) {
            savePipeline()
        }
    }

    return _.isEmpty(pipeline) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-small-popup"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100 pipeline-form-style">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label >  <Translate text={'pipeline'} /> </label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'name'}
                                            className={`form-control ${errorFields["name"]}`}
                                            name="name"
                                            onChange={handleOnChange}
                                            value={pipeline.name}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label > <Translate text={'Level'} /></label>
                                        <ReactSelect
                                            options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                                levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                            name={"level"}
                                            placeholder={'select level'}
                                            onChange={handleSelectChange}
                                            value={pipeline.level}
                                            classNamePrefix={`${!newPipeline ? 'select-readonly' : ''} ${errorFields["level"]} basic-select`}
                                            removeClearable={true}
                                            isDisabled={!newPipeline}
                                        >
                                        </ReactSelect>
                                    </div>
                                    {
                                        (!_.isEmpty(pipeline.level) && pipeline.level !== 'oem') ? (<div className="form-group col-md-12">
                                            <label >{pipeline.level === 'group' ? 'Group' : pipeline.level === 'region' ? 'Region' : 'Client'}</label>
                                            <ReactMultiSelect
                                                options={pipeline.level === 'group' ? props.groupOptions : pipeline.level === 'region' ? props.regionOptions : props.clientOptions}
                                                name={'levelID'}
                                                placeholder={`select ${pipeline.level === 'individual' ? 'client' : pipeline.level}`}
                                                onChange={(rec) => { handleReactMultiSelectChange(rec, 'levelID') }}
                                                value={pipeline.levelID ? _.isArray(pipeline.levelID) ? pipeline.levelID : [pipeline.levelID] : []}
                                                classNamePrefix={`${!newPipeline ? 'select-readonly' : ''}  ${errorFields["levelID"]} basic-select`}
                                                isDisabled={!newPipeline}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>) : (<></>)
                                    }
                                    {
                                        pipeline.value !== 'LeadsBucket' ? (
                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'status'} /></label>
                                                <ReactMultiSelect
                                                    options={props.enquiryStatus ? props.enquiryStatus.filter(a => !Boolean(a.isModule)) : []}
                                                    name={'status'}
                                                    placeholder={`select status`}
                                                    onChange={(rec) => { handleReactMultiSelectChange(rec, 'status') }}
                                                    value={pipeline.status ? pipeline.status : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        ) : (<></>)
                                    }
                                    {
                                        pipeline.value !== 'LeadsBucket' ? (
                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'make'} /></label>
                                                <ReactMultiSelect
                                                    options={props.dealersettings?.client?.brands ? props.dealersettings?.client?.brands
                                                        .map(rec => {
                                                            return {
                                                                label: rec.name,
                                                                value: rec.value
                                                            }
                                                        }) : []}
                                                    name={'makes'}
                                                    placeholder={`select make`}
                                                    onChange={(rec) => { handleReactMultiSelectChange(rec, 'makes') }}
                                                    value={pipeline.makes ? pipeline.makes : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        ) : (<></>)
                                    }
                                    <div className={`form-group col-md-12`}>
                                        <label >  <Translate text={'modules'} /> </label>
                                        <div className="module-list">
                                            <div className="check-list">
                                                <div className="checkbox icheck-success">
                                                    <input type="checkbox" id="module_contact" name={'contact'} checked={pipeline.modules.contact} readOnly={true} />
                                                    <label htmlFor="module_contact"> <Translate text={'contact'} /> </label>
                                                </div>
                                            </div>
                                            <div className="check-list">
                                                <div className="checkbox icheck-success">
                                                    <input type="checkbox" id="module_vehicle" name={'requirement'} checked={pipeline.modules.requirement} onChange={(e) => { handleEnqModules(e) }} />
                                                    <label htmlFor="module_vehicle"> <Translate text={'requirement'} /> </label>
                                                </div>
                                            </div>
                                            {
                                                _.isEmpty(pipelineModules) || pipelineModules.includes('testDrive') ? (
                                                    <div className="check-list">
                                                        <div className="checkbox icheck-success">
                                                            <input type="checkbox" id="module_testdrive" name={'testDrive'} checked={pipeline.modules.testDrive} onChange={(e) => { handleEnqModules(e) }} />
                                                            <label htmlFor="module_testdrive"> <Translate text={'testDrive'} /> </label>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                _.isEmpty(pipelineModules) || pipelineModules.includes('tradeIn') ? (
                                                    <div className="check-list">
                                                        <div className="checkbox icheck-success">
                                                            <input type="checkbox" id="module_tradein" name={'tradeIn'} checked={pipeline.modules.tradeIn} onChange={(e) => { handleEnqModules(e) }} />
                                                            <label htmlFor="module_tradein"> <Translate text={'tradeIn'} /> </label>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                _.isEmpty(pipelineModules) || pipelineModules.includes('cafe') ? (
                                                    <div className="check-list">
                                                        <div className="checkbox icheck-success">
                                                            <input type="checkbox" id="module_cafe" name={'cafe'} checked={pipeline.modules.cafe} onChange={(e) => { handleEnqModules(e) }} />
                                                            <label htmlFor="module_cafe"> <Translate text={'cafe'} /> </label>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                !_.isEmpty(props.dealersettings.client.moduleSettings) &&
                                                    !_.isEmpty(props.dealersettings.client.moduleSettings.finance) &&
                                                    props.dealersettings.client.moduleSettings.finance.enabled === true ?
                                                    (
                                                        <div className="check-list">
                                                            <div className="checkbox icheck-success">
                                                                <input type="checkbox" id="module_finance" name={'finance'} checked={pipeline.modules.finance} onChange={(e) => { handleEnqModules(e) }} />
                                                                <label htmlFor="module_finance"> <Translate text={'finance'} /> </label>
                                                            </div>
                                                        </div>) : (<></>)
                                            }
                                            <div className="check-list">
                                                <div className="checkbox icheck-success">
                                                    <input type="checkbox" id="module_files" name={'files'} checked={pipeline.modules.files} onChange={(e) => { handleEnqModules(e) }} />
                                                    <label htmlFor="module_files"> <Translate text={'Files'} /></label>
                                                </div>
                                            </div>
                                            {
                                                !_.isEmpty(props.dealersettings.client.moduleSettings) &&
                                                    !_.isEmpty(props.dealersettings.client.moduleSettings.amsVideo) &&
                                                    props.dealersettings.client.moduleSettings.amsVideo.enabled === true ?
                                                    (
                                                        <div className="check-list">
                                                            <div className="checkbox icheck-success">
                                                                <input type="checkbox" id="module_amsVideo" name={'amsVideo'} checked={pipeline.modules.amsVideo} onChange={(e) => { handleEnqModules(e) }} />
                                                                <label htmlFor="module_amsVideo"> <Translate text={'AMS Video'} /> </label>
                                                            </div>
                                                        </div>) : (<></>)
                                            }
                                        </div>
                                    </div>
                                    {
                                        newPipeline ? (
                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'Roles'} /></label>
                                                <ReactMultiSelect
                                                    options={roles}
                                                    name={'roles'}
                                                    placeholder={`select roles`}
                                                    onChange={(rec) => { handleReactMultiSelectChange(rec, 'roles') }}
                                                    value={pipeline.roles ? pipeline.roles : []}
                                                    classNamePrefix={`basic-select`}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        ) : (<></>)
                                    }
                                    {
                                        props.dealersettings.superAdmin === true ? (
                                            <div className="form-group col-md-12">
                                                <label > <Translate text={'Key'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'key'}
                                                    className={`form-control ${errorFields["key"]}`}
                                                    name="key"
                                                    onChange={(e) => {
                                                        setPipeline({
                                                            ...pipeline,
                                                            [e.target.name]: e.target.value
                                                        })
                                                    }}
                                                    value={pipeline.key}
                                                    maxLength={150}
                                                />
                                            </div>
                                        ) : (<></>)
                                    }
                                </div>
                                <div className="alert alert-primary" role="alert">
                                    <Translate text={'hideOrUnhideMsg'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <span className={`switch switch-sm float-left mt-1`}>
                        <InputCheckBox
                            className={`switch`}
                            id={`pipeline-active`}
                            name={'active'}
                            checked={pipeline.active ? pipeline.active : false}
                            onChange={(e) => { handleCheckedChange(e) }} />
                        <label htmlFor={`pipeline-active`}><Translate text={'active'} /></label>
                    </span>
                    <span className={`switch switch-sm float-left mt-1`}>
                        <InputCheckBox
                            className={`switch`}
                            id={`pipeline-disableReport`}
                            name={'disableReport'}
                            checked={pipeline.disableReport ? pipeline.disableReport : false}
                            onChange={(e) => { handleCheckedChange(e) }} />
                        <label htmlFor={`pipeline-disableReport`}><Translate text={'Exclude in Report'} /></label>
                    </span>
                    <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => savePipeline(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'save'} />
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >  <Translate text={'cancel'} /></button>
                </Modal.Footer>
            </Modal >
            <PopUpModal show={showMovePipeline}>
                <MovePipeline
                    {...props}
                    show={showMovePipeline}
                    title={popHeader}
                    handleClose={handleModelClose}
                    allPipelines={props.allPipelines}
                    pipeline={editPipeline}
                    deletePipeline={deletePipeline}
                    selectedClient={props.selectedClient}
                    inactive={true}
                />
            </PopUpModal>
        </>
    );
}

export default AddPipeline;