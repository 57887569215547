import React, { useState, useEffect, useReducer, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';
import moment from 'moment'
import _, { filter } from 'lodash'

import { collectionData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

import CommonHelper from '../../../services/common';
import toast from 'toasted-notes'
import _images from '../../../images'
import { firestoreDB } from '../../../services/helper';
import Translate from '../../../constants/translate';
import FleetHistoryReducer from '../history/historyReducer'
import AddFleet from '../../fleet/add'
import { objFleetHistoryVM, allFleetHistoryFieldsVM } from '../viewModel'
import { objStock } from '../../stock/viewModel'
import { fleetStatus } from '../../../services/enum';
import { ContentOverlay, PopUpModal, TableView, AntDateRangePicker, ReactSelect } from '../../../components'

const LoanList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [fleetHistory, dispatch] = useReducer(FleetHistoryReducer, { Loan: [], TestDrive: [] })
    const [checkDataLoad, setDataload] = useState({ "Loan": false, "TestDrive": false })
    const [hasMore, setHasMoreData] = useState([])
    const [loanHistoryFields, setLoanHistoryFields] = useState(props.dealersettings.loanHistoryFields)

    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelFleetShow, setModelFleetShow] = useState(false)

    const [editDocumentID, setEditDocument] = useState()
    const [modelStock, setModelStock] = useState({})
    const [isPaging, setPaging] = useState(false)
    const [fleetHistoryLoader, setLoader] = useState({ "Loan": true, "TestDrive": true })
    const pageLimit = 20
    const [activeTab, setActiveTab] = useState('Loan')
    const [searchText, setSearchText] = useState('')

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    const [pageNum, setPageNum] = useState(0)

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
    const stockLocations = props.dealersettings?.client?.clientSettings?.stockLocations

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            // setDataload({
            //     ...checkDataLoad,
            //     [activeTab]: false
            // })
            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            setCampaigns(_campaigns);
            setOrigins(_origins);
            setTitles(_titles);
            setLanguages(_languages);
            setNationalities(_nationalities)
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
        } else {
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
        }
    }, [])
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.unSubLoanCountList && window.unSubLoanCountList();
        }
    }, [])


    useEffect(() => {

        if (!checkDataLoad[activeTab]) {
            return;
        }

        const { dealersettings, activeFleetTag } = props;
        let ownerDashboard = (!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

        if (activeTab === 'Loan') {
            let refSubLoanCountData = firestoreDB(dealersettings).firestore().collection('fleet')
                .where('clientID', '==', dealersettings.client.id)
                .where('status', '==', 'pending')
            //.where('isDeleted', '==', false)

            if (ownerDashboard)
                refSubLoanCountData = refSubLoanCountData.where('addedBy', '==', localStorage.uid)
            else if (props.selectedUser)
                refSubLoanCountData = refSubLoanCountData.where('addedBy', '==', props.selectedUser.value)

            if (searchText && searchText.trim()) {
                refSubLoanCountData = refSubLoanCountData
                    .where('keywords', 'array-contains', searchText.trim().toLowerCase())
            }

            if (activeFleetTag === 'Overdue') { // @info: Overdue
                refSubLoanCountData = refSubLoanCountData
                    .where('expectedReturn', '<=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                    .orderBy('expectedReturn', 'asc')
            }
            else if (activeFleetTag === 'Today') {// @info: Today Expected Return
                refSubLoanCountData = refSubLoanCountData
                    .where('expectedReturn', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                    .where('expectedReturn', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
                    .orderBy('expectedReturn', 'asc')
            }
            else {// @info: On Loan
                refSubLoanCountData = refSubLoanCountData
                    .orderBy('expectedReturn', 'asc')
            }

            let lastRecord = hasMore.filter(e => e.type === activeTab);
            if (lastRecord.length > 0) {
                refSubLoanCountData = refSubLoanCountData
                    .startAfter(lastRecord[0].lastDoc)
                    .limit(pageLimit)
            }
            else {
                refSubLoanCountData = refSubLoanCountData
                    .limit(pageLimit)
            }

            refSubLoanCountData.get().then(querySnapshot => {
                let __fleetHistory = [];

                if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                    setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
                    {
                        type: activeTab,
                        lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]

                    }])
                }
                else {
                    setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                }
                querySnapshot.forEach((doc) => {
                    __fleetHistory.push(dataMappingVM(doc.data()));
                });


                //console.log('__fleetHistory', __fleetHistory)
                if (isPaging) {
                    dispatch({
                        type: "APPEND_LIST",
                        data: __fleetHistory,
                        activetab: activeTab
                    });
                }
                else {
                    dispatch({
                        type: "SUCCESS_LIST",
                        data: __fleetHistory,
                        activetab: activeTab
                    });
                }

                setDataload({
                    ...checkDataLoad,
                    [activeTab]: false
                })
                setLoader({
                    ...fleetHistoryLoader,
                    [activeTab]: false
                })
                setPaging(false)

            });
            //window.unSubLoanCountList = refSubLoanCountData.onSnapshot(onCollectionUpdate);
        }

    }, [checkDataLoad, activeTab])




    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM(change.doc.data());
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })

        let __fleetHistory = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
                {
                    type: activeTab,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1].data()

                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            }
            querySnapshot.forEach((doc) => {
                __fleetHistory.push(dataMappingVM(doc.data()));
            });

        }
        //console.log('__fleetHistory', __fleetHistory)
        if (isPaging) {
            dispatch({
                type: "APPEND_LIST",
                data: __fleetHistory,
                activetab: activeTab
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS_LIST",
                data: actionType ? snapshotDoc : __fleetHistory,
                activetab: activeTab
            });
        }

        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoader({
            ...fleetHistoryLoader,
            [activeTab]: false
        })
        setPaging(false)
    }
    const dataMappingVM = (doc) => {
        return loanMappingVM(doc);
    }

    const loanMappingVM = (doc) => {
        //console.log('loanMappingVM', doc)
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.startDate = doc.startDate && doc.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate._seconds)._d) : doc.startDate;
        doc.endDate = doc.endDate && doc.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate._seconds)._d) : doc.endDate;
        doc.expectedReturn = doc.expectedReturn && doc.expectedReturn._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.expectedReturn._seconds)._d) : doc.expectedReturn;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objlogData = Object.assign({}, doc);
        const fleetVM = Object.assign({}, objFleetHistoryVM);
        for (let [key, value] of Object.entries(objlogData)) {
            fleetVM[key] = value;
        }

        fleetVM.createdOn = fleetVM.addedDate ? moment.unix(fleetVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        fleetVM.updatedOn = fleetVM.modifiedDate ? moment.unix(fleetVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        fleetVM.addedBy = fleetVM.addedBy ? CommonHelper.getUserNamebyId(allUsers, fleetVM.addedBy) : '--';
        fleetVM.modifiedBy = fleetVM.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, fleetVM.modifiedBy) : '--';

        fleetVM.endOdometer = fleetVM.endOdomter ? fleetVM.endOdomter : fleetVM.endOdometer;
        let dealersettings = props.dealersettings;
        if (!_.isEmpty(fleetVM.contact)) {
            let _contact = Object.assign({}, fleetVM.contact);
            // fleetVM.contactName = <div onClick={(e) => {
            //     e.preventDefault();
            //     const url = `/contacts/details/${_contact.documentID}`;
            //     window.open(url, '_blank');
            // }}>{CommonHelper.displayFullContact(titles, _contact)}</div>;
            fleetVM.contactName = CommonHelper.displayFullContact(titles, _contact);
            fleetVM.firstName = (_contact.firstName || '');
            fleetVM.middleName = (_contact.middleName || '');
            fleetVM.lastName = (_contact.lastName || '');
            fleetVM.contactPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, dealersettings);
            fleetVM.phone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
            fleetVM.contactEmail = _contact.email;
            fleetVM.displayID = _contact.displayID;
            fleetVM.licenseNo = _contact.licenseNo;
            fleetVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
            fleetVM.contactType = _contact.contactType;
            fleetVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
            fleetVM.gender = _contact.gender;
            fleetVM.maritalStatus = _contact.maritalStatus;
            fleetVM.contactMethod = _contact.contactMethod;
            fleetVM.licenseType = _contact.licenseType;
            fleetVM.language = CommonHelper.getLabelByValue(languages, _contact.language, '');
            //fleetVM.campaign = CommonHelper.getLabelByValue(campaigns, _contact.campaign, '');
            //fleetVM.origin = CommonHelper.getLabelByValue(origins, _contact.origin, '');
            fleetVM.nationality = CommonHelper.getLabelByValue(nationalities, _contact.nationality, '');

            let _setttings = _contact.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[_contact.clientID] && dealersettings.group.clients[_contact.clientID].settings;
            if (_.isEmpty(_setttings))
                _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

            if (_setttings) {
                fleetVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, _contact.campaign, '');
                fleetVM.origin = CommonHelper.getNameByValue(_setttings.origins, _contact.origin, '');
                fleetVM.gender = CommonHelper.getNameByValue(_setttings.genders, _contact.gender, '');
                fleetVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, _contact.maritalStatus, '');
                fleetVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, _contact.contactMethod, '');
                fleetVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, _contact.interests, '', true);
                fleetVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, _contact.licenseType, '');
            }

            if (_contact.clientID && (_contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                fleetVM.contactdealerName = CommonHelper.getOtherDealerName(dealersettings, _contact.clientID);
            }
            else
                fleetVM.contactdealerName = '';


            fleetVM.address = _contact.address;
            fleetVM.companyName = _contact.companyName;
            fleetVM.designation = _contact.designation;

            fleetVM.licenseState = _contact.licenseState;
            fleetVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
            fleetVM.secondaryContactPhone = CommonHelper.phoneFormat(_contact.secondaryPhoneCode, _contact.secondaryPhone, dealersettings);
            fleetVM.secondaryPhone = _contact.secondaryPhone ? ((_contact.secondaryPhoneCode ? _contact.secondaryPhoneCode : '') + _contact.secondaryPhone) : '';
            fleetVM.secondaryContactEmail = _contact.secondaryEmail;
            fleetVM.contactCreatedBy = _contact.addedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.addedBy) : '';
            fleetVM.contactModifiedby = _contact.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.modifiedBy) : '';
            fleetVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            fleetVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        }
        else if (!_.isEmpty(fleetVM.staff)) {
            let _staff = Object.assign({}, fleetVM.staff);
            fleetVM.contactName = _staff.name ? _staff.name : '';
            fleetVM.displayID = 'staff';
            fleetVM.phone = _staff.phone ? _staff.phone : '';
            fleetVM.contactEmail = _staff.email;
            fleetVM.dob = _staff.dob ? moment(_staff.dob).format('DD/MM/YYYY') : '';
            fleetVM.licenseNo = _staff.licenseNo ? _staff.licenseNo : '';
            fleetVM.licenseExpiry = _staff.licenseExpiry ? moment(_staff.licenseExpiry).format('DD/MM/YYYY') : '';
            fleetVM.address = _staff.address ? _staff.address : '';
        }

        if (!_.isEmpty(doc.stock)) {
            fleetVM.stockNo = doc.stock.stockNo;
            fleetVM.make = doc.stock.make;
            fleetVM.model = doc.stock.model;
            fleetVM.modelDescription = doc.stock.modelDescription;
            fleetVM.saleType = CommonHelper.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
                doc.stock.saleType, '');
            fleetVM.type = doc.stock.type;
            fleetVM.year = doc.stock.year;
            fleetVM.regNo = doc.stock.regNo;
            fleetVM.vinNo = doc.stock.vinNo;
            fleetVM.extColor = doc.stock.extColor;
            fleetVM.intColor = doc.stock.intColor;
            fleetVM.price = doc.stock.price;
            fleetVM.purchasePrice = doc.stock.purchasePrice;
            fleetVM.location = doc.stock.location;
            fleetVM.stockStatus = doc.stock.status;

            fleetVM.statusUpdatedBy = doc.stock.statusModifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.stock.statusModifiedBy) : '';

            if (doc.stock.clientID && (!_.isEmpty(stockLocations) || doc.stock.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                fleetVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, doc.stock.clientID);
            }
            else
                fleetVM.dealerName = '';
        }

        fleetVM.startDate = fleetVM.startDate ? moment.unix(fleetVM.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        fleetVM.expectedReturn = fleetVM.expectedReturn ? moment.unix(fleetVM.expectedReturn.seconds).format('DD/MM/YYYY hh:mm A') : '';
        fleetVM.endDate = fleetVM.endDate ? moment.unix(fleetVM.endDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        fleetVM.lvstatusName = fleetVM.status ? fleetVM.status : '';
        fleetVM.status = (fleetVM.status
            ?
            <div className={`badge badge-pill badge-${fleetVM.status === fleetStatus.PENDING ? 'pending' : 'active-fill'}`}>
                {fleetVM.status}
            </div>
            :
            <></>);
        fleetVM.stockStatusName = CommonHelper.getNameByValue(dealersettings?.client?.settings?.stockStatus, fleetVM.stockStatus, '');
        fleetVM.stockStatus = CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, fleetVM.stockStatus, '');


        return fleetVM
    }


    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        if (activeTab === 'Loan') {
            window.firebase.firestore().doc(`/users/${localStorage.uid}`)
                .set({ loanHistoryFields: fields }, { merge: true })
                .then(() => {
                    setLoanHistoryFields(fields)
                    setSettingsLoader(false)
                    props.updateDealerSettings('loanHistoryFields', fields)
                    if (showToast)
                        toast.notify('Settings updated successfully', {
                            duration: 2000
                        })

                }).catch(error => {
                    setSettingsLoader(false)
                    console.error(error)
                });
        }

    }
    const handleEditFleet = (id, obj) => {

        let _stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(obj.stock)) {
            if (_stock.hasOwnProperty(key))
                _stock[key] = value;
        }

        if (!readOnlyEnquiryView) {
            if (activeTab === 'Loan') {
                setEditDocument(id);
                setModelFleetShow(true);
                setModelStock(_stock);
            }
        }

    }

    const handleClose = (id) => {
        //console.log('handleClose', id)
        setModelFleetShow(false);

        setModelStock({});
        setEditDocument(null);

        if (!_.isEmpty(id)) {
            getFleetbyID(id);

        }
    }

    const getFleetbyID = (id) => {
        window.clistSubscription = collectionData(firestoreDB(props.dealersettings).firestore().collection('fleet')
            .where('documentID', '==', id))
            .pipe(
                switchMap(dataSnapshot => {
                    const stockIds = _.uniq(dataSnapshot.filter(item => !_.isEmpty(item.stockID)).map(item => item.stockID))
                    return combineLatest(
                        of(dataSnapshot),
                        combineLatest(
                            stockIds.length > 0 ? stockIds.map(stockID =>
                                collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
                                    map(stockSnapshot => stockSnapshot[0] ? stockSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([dataSnapshot, stockSnapshot]) => {
                    return dataSnapshot.map(snapDoc => {
                        return {
                            ...snapDoc,
                            stock: stockSnapshot.find(a => a.documentID === snapDoc.stockID),
                        }
                    })
                })
            )
            .subscribe(datas => {
                onDocUpdate(dataMappingVM(datas[0]));
                window.clistSubscription && window.clistSubscription.unsubscribe()
            });
    }

    const onDocUpdate = (_data) => {
        //console.log('onDocUpdate', data)
        if (!_.isEmpty(_data)) {
            const oldLog = fleetHistory[activeTab].find(item => item.documentID === _data.documentID);
            if (!_.isEmpty(oldLog)) {
                dispatch({
                    type: "UPDATE_LIST",
                    data: _data,
                    activetab: activeTab
                });
            }

        }
    }

    const handleActionClick = (id, obj) => {

        return (
            <div
                style={{ cursor: 'pointer' }}
                className="mini-button"
                onDoubleClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    console.log(id, obj)
                }}
                onClick={(e) => {
                    e.preventDefault();
                    console.log(id, obj)
                    if (!readOnlyEnquiryView)
                        handleEditFleet(id, obj);
                }}>

                {readOnlyEnquiryView ? <><i className="ico icon-lock mr-1"></i></> : <><i className="ico icon-more"></i></>}
            </div>
        )


    }

    const handleDetailsClick = (e, id, obj) => {
        e.preventDefault();
        if (!readOnlyEnquiryView)
            handleEditFleet(id, obj);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.activeFleetTag ? props.activeFleetTag === 'Today' ? `todaysExpectedReturn` : props.activeFleetTag === 'OnLoan' ? 'onLoan' : 'Overdue' : 'LoanVehicle'} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">

                            <div className="settings-head">
                                <div className="float-left">
                                    <div className={`filter-search`}>
                                        <div className="input-group ">
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="isearch"
                                                placeholder="search..."
                                                value={searchText ? searchText : ''}
                                                onChange={(e) => {
                                                    setSearchText(e.target.value);

                                                    if (!e.target.value) {
                                                        setSearchText('');
                                                        setDataload({
                                                            ...checkDataLoad,
                                                            [activeTab]: true
                                                        })
                                                        setLoader({
                                                            ...fleetHistoryLoader,
                                                            [activeTab]: true
                                                        })
                                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                                        setPageNum(0);
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setSearchText(searchText)
                                                        setDataload({
                                                            ...checkDataLoad,
                                                            [activeTab]: true
                                                        })
                                                        setLoader({
                                                            ...fleetHistoryLoader,
                                                            [activeTab]: true
                                                        })
                                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                                        setPageNum(0);
                                                    }
                                                }}
                                                autoComplete="off"
                                            />

                                            {
                                                searchText && searchText.length > 0
                                                    ?
                                                    <div className="input-group-append input-search-clear">
                                                        <a href="#"
                                                            className="input-search-clear-icon"
                                                            title="Clear"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setSearchText('');
                                                                setDataload({
                                                                    ...checkDataLoad,
                                                                    [activeTab]: true
                                                                })
                                                                setLoader({
                                                                    ...fleetHistoryLoader,
                                                                    [activeTab]: true
                                                                })
                                                                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                                                setPageNum(0);
                                                            }}
                                                        >
                                                            <i className="ico icon-remove"></i>
                                                        </a>
                                                        <button
                                                            className="btn btn-default"
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setSearchText(searchText)
                                                                setDataload({
                                                                    ...checkDataLoad,
                                                                    [activeTab]: true
                                                                })
                                                                setLoader({
                                                                    ...fleetHistoryLoader,
                                                                    [activeTab]: true
                                                                })
                                                                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                                                setPageNum(0);
                                                            }}>
                                                            <i className="ico icon-search-fill"></i>
                                                        </button>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }



                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="calendar-panel">
                                {
                                    fleetHistoryLoader[activeTab] ? <>
                                        // <div className="mt-5"><ContentOverlay active={true} /></div>
                                        <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                            <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                                        </div>
                                    </> :
                                        <div className="common-table">
                                            <TableView
                                                isReorderable={true}
                                                datalist={fleetHistory[activeTab] ? fleetHistory[activeTab] : []}
                                                height={windowSize.windowHeight}
                                                width={windowSize.windowWidth}
                                                columns={allFleetHistoryFieldsVM}
                                                handleSavesettings={handleSavesettings}
                                                dynamicFields={(loanHistoryFields && loanHistoryFields.length > 0) ? loanHistoryFields : allFleetHistoryFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                                settingsLoader={settingsLoader}
                                                handleActionClick={handleActionClick}
                                                handleRowSingleClick={handleDetailsClick}
                                                isSettings={true}
                                                hasMore={hasMore.find(e => e.type === activeTab)}
                                                handlePagination={handlePagination}
                                                isPaging={true}
                                                activityTab={activeTab}
                                            />
                                        </div>
                                }


                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={modelFleetShow}>
                <AddFleet
                    docID={editDocumentID}
                    show={modelFleetShow}
                    clsActive='overlay-modal active'
                    stock={modelStock}
                    handleClose={handleClose}
                    title={'fleet'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </AddFleet>
            </PopUpModal>


        </>
    );
}

export default LoanList;