import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { eventsProTabs, NoDataDiv, LoadingDiv } from '../constants';
import CommonHelper from '../../../../services/common';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { tipStatus, eventsProStatus, userLevel } from '../../../../services/enum'
import Translate from '../../../../constants/translate';
import { firestoreDB } from '../../../../services/helper';
import { levelOptions } from '../../../eventsProTemplates/viewModel';

const EventsProSearch = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	handleAttendeeOpen,
	handleOpenEditActivity,
	history,
	pipeline,
	getDealerName,
	modules,
	enableSharedContact,
	handleInBoundOpen,
}) => {
	const [activeTab, setActivetab] = useState('all');
	const [eventsPro, setEventsPro] = useState([]);
	const [eventsProAttendees, setEventsProAttendees] = useState([]);
	const [all, setAll] = useState([]);
	const [checkDataLoad, setDataload] = useState(false)

	const [loader, setLoader] = useState([]);


	const _servicePermissions = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.rolePermissions) &&
		!_.isEmpty(dealersettings.rolePermissions.servicePermissions)) ? dealersettings.rolePermissions.servicePermissions : null);

	const eventsProStatus = dealersettings?.client?.eventsPro?.eventsProStatus;


	useEffect(() => {
		setLoader(true)
		const timeoutId = setTimeout(() => {
			if (searchText && searchText.length > 2) {
				setDataload(true)
			}

			if (searchText === '')
				setDataload(true)
		}, 1000);
		return () => clearTimeout(timeoutId);
	}, [searchText]);

	useEffect(() => {
		if (!checkDataLoad) {
			return;
		}
		if (searchText.length > 0) {
			setLoader(true);


			let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)


			let refEventsPro = firestoreDB(dealersettings)
				.firestore()
				.collection('eventsPro')
				//.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false);

			refEventsPro = refEventsPro
				.orderBy('addedDate', 'desc')
				.limit(100)
				.get();

			// let refEventsProAttendees = firestoreDB(dealersettings)
			// 	.firestore()
			// 	.collection('eventsProAttendees')
			// 	.where('clientID', '==', dealersettings.client.documentID)
			// 	.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
			// 	.where('isDeleted', '==', false);

			//     refEventsProAttendees = refEventsProAttendees
			// 	.orderBy('addedDate', 'desc')
			// 	.limit(7)
			// 	.get();


			Promise.all([
				refEventsPro,
				// refEventsProAttendees

			])

				.then((allDocs) => {

					const allEventsPro = _.map(allDocs[0].docs, function (obj) {
						return {
							...obj.data(),
							documentID: obj.id,
							renderType: 'eventsPro',
							sortDate: obj.data().addedDate,
							isInbound: false,
						};

					});
					// const allEventsProAttendees = _.map(allDocs[1].docs, function (obj) {
					// 	return {
					// 		...obj.data(),
					// 		documentID: obj.id,
					// 		renderType: 'eventsProAttendees',
					// 		sortDate: obj.data().addedDate,
					// 		isInbound: false,
					// 	};

					// });
					let _events = []
					let _userLevel = levelOptions.filter(e => e.value !== userLevel.OEM && e.index >= _.find(levelOptions, { value: dealersettings.level })?.index)
					_userLevel.forEach(level => {
						let _levelIDs = CommonHelper.getAllEventsProByLevel(dealersettings, level.value, true, true);
						if (level.value === userLevel.INDIVIDUAL) {
							_events.push(...allEventsPro.filter(a => _levelIDs.some(b => a?.clientIDs?.includes(b))))
						}
						else {
							_events.push(...allEventsPro.filter(a => _levelIDs.some(b => a?.levelID?.includes(b))))
						}
					});
					setEventsPro(_.uniqBy(_events, 'documentID'));
					//setEventsProAttendees(allEventsProAttendees);

					if (_servicePermissions) {
						setAll([
							..._events,
							//    ...allEventsProAttendees
						]);
					} else {
						setAll([
							..._events,
							//    ...allEventsProAttendees
						]);
					}
					setLoader(false);
					setDataload(false)
				})
		}
		else {
			setDataload(false)
		}
	}, [checkDataLoad]);

	return (
		<div
			className='dropdown-menu dropdown-menu-left'
			aria-labelledby='dropdownMenuButton'
			style={{ display: showSearchPanel ? 'block' : '', left: '120px' }}
		>
			<div className='form-style globalsearch-wrap'>
				<div className='globalsearch-nav-wraper'>
					<div className='globalsearch-nav pl-0'>
						<Tab.Container defaultActiveKey={activeTab}>
							<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
								{eventsProTabs.map((rec, index) => {
									return (
										<OverlayTrigger
											key={index}
											placement='bottom'
											overlay={
												<Tooltip><span>{rec.title}</span></Tooltip>
											}
										>
											<Nav.Item
												as='li'
												bsPrefix='li-nav'
												key={index}
											>
												<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
													<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, rec.ico, true, rec.icoVehicle)}></i>
													{rec.name}
												</Nav.Link>
											</Nav.Item>
										</OverlayTrigger>
									);
								})}
							</Nav>
						</Tab.Container>
					</div>
				</div>
				<div className='globalsearch-body'>
					{loader ? (
						<LoadingDiv />
					) : (
						<div className='global-item-wraper'>
							{activeTab === 'all' ? (
								<>
									{all.length > 0 ? (
										<>
											{all
												// _.orderBy(all, ['sortDate.seconds'], ['desc'])
												.map(obj =>
													obj.renderType === 'eventsPro' ? (
														<a
															className='global-item d-flex'
															onClick={e => {
																e.preventDefault();
																history.push(`/`);
																history.push(
																	`eventsPro/details/${obj.documentID}`,
																);
																handleClose();
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-events-event'></i>
																</div>
															</div>
															<div>
																<span className='global-header'>{`${obj.title}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
																<span className='global-info'>{`${obj.location}`}</span>
																<span className='global-info'>{CommonHelper.showCombineDate(obj.startDate, obj.endDate)}</span>
																<div className='global-status'>
																	{
																		_.find(eventsProStatus, { value: obj.status }) ? (
																			<div className={`badge badge-pill badge-status-${_.find(eventsProStatus, { value: obj.status }).value === 'New' ? 'open' : 'empty'}`} style={{ background: (_.find(eventsProStatus, { value: obj.status })?.color ? _.find(eventsProStatus, { value: obj.status }).color : '#6b717f') }}>{_.find(eventsProStatus, { value: obj.status }).name} </div>
																		) : (
																			<div className={`badge badge-pill badge-status-${obj.status.toLowerCase()}`} >{obj.status} </div>
																		)
																	}
																</div>
															</div>
														</a>
														// ) : obj.renderType === 'eventsProAttendees' ? (
														// 	<a
														// 		className='global-item d-flex'
														// 		onClick={e => {
														// 			e.preventDefault();
														// 			handleAttendeeOpen(obj.documentID)
														// 			handleClose();
														// 		}}
														// 		key={obj.documentID}
														// 	>
														// 		<div className='global-space'>
														// 			<div className='global-icon-circle'>
														//             <i className='ico icon-events-attendees'></i>
														// 			</div>
														// 		</div>
														// 		<div>
														//             {(obj.firstName || obj.lastName) ? (
														//                 <span className='global-header'>{`${obj.firstName} ${obj.lastName}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
														//             ) : (
														//                 <span className='global-header'>{`${CommonHelper.displayContact([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
														//             )}
														//             {obj.phone ? (
														//                 <span className='global-info'>{CommonHelper.phoneFormat(obj.phoneCode, obj.phone)}</span>
														//             ) : obj.email ? (
														//                 <span className='global-info'>{obj.email}</span>
														//             ) : (
														//                 <></>
														//             )}
														//             {enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
														//                 (<>
														//                     <span className="tbl-sub-dealer">{getDealerName(obj)}</span>
														//                 </>) : <></>}
														//         </div>
														// 	</a>
													) : (
														<></>
													),
												)}
										</>
									) : (
										<NoDataDiv />
									)}
								</>

							) : activeTab === 'eventsPro' ? (
								<>
									{eventsPro.length > 0 ? (
										<>
											{eventsPro.map(obj => (
												<a
													className='global-item d-flex'
													onClick={(e) => {
														e.preventDefault();
														history.push(`/`);
														history.push(`eventspro/details/${obj.documentID}`);
														handleClose();
													}}
													key={obj.documentID}
												>
													<div className='global-space'>
														<div className='global-icon-circle'>
															<i className='ico icon-events-event'></i>
														</div>
													</div>
													<div>
														<span className='global-header'>{`${obj.title}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
														<span className='global-info'>{`${obj.location}`}</span>
														<span className='global-info'>{CommonHelper.showCombineDate(obj.startDate, obj.endDate)}</span>
														<div className='global-status'>
															{
																_.find(eventsProStatus, { value: obj.status }) ? (
																	<div className={`badge badge-pill badge-status-${_.find(eventsProStatus, { value: obj.status }).value === 'New' ? 'open' : 'empty'}`} style={{ background: (_.find(eventsProStatus, { value: obj.status })?.color ? _.find(eventsProStatus, { value: obj.status }).color : '#6b717f') }}>{_.find(eventsProStatus, { value: obj.status }).name} </div>
																) : (
																	<div className={`badge badge-pill badge-status-${obj.status.toLowerCase()}`} >{obj.status} </div>
																)
															}
														</div>
													</div>
												</a>
											))}
										</>

									) : (
										<NoDataDiv />
									)}
								</>
								// ) : activeTab === 'attendees' ? (
								// 	<>
								// 		{eventsProAttendees.length > 0 ? (
								// 			<>
								// 				{eventsProAttendees.map(obj => (
								// 					<a
								// 						className='global-item d-flex'
								// 						onClick={(e) => {
								// 							e.preventDefault();
								// 							handleAttendeeOpen(obj.documentID)
								// 							handleClose();
								// 						}}
								// 						key={obj.documentID}
								// 					>
								// 						<div className='global-space'>
								// 							<div className='global-icon-circle'>
								// 								<i className='ico icon-events-attendees'></i>
								// 							</div>
								// 						</div>
								// 						<div>
								// 							{(obj.firstName || obj.lastName) ? (
								// 								<span className='global-header'>{`${obj.firstName} ${obj.lastName}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
								// 							) : (
								// 								<span className='global-header'>{`${CommonHelper.displayContact([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
								// 							)}
								// 							{obj.phone ? (
								// 								<span className='global-info'>{CommonHelper.phoneFormat(obj.phoneCode, obj.phone)}</span>
								// 							) : obj.email ? (
								// 								<span className='global-info'>{obj.email}</span>
								// 							) : (
								// 								<></>
								// 							)}
								// 							{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
								// 								(<>
								// 									<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
								// 								</>) : <></>}
								// 						</div>
								// 					</a>
								// 				))}
								// 			</>
								// 		) : (
								// 			<NoDataDiv />
								// 		)}
								// 	</>    

							) : (
								<></>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default EventsProSearch;
