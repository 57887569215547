import React from 'react';
import { InputText, ReactSelect, ReactTimePicker, AutoComplete } from '../../../../components'
import { Dropdown } from "react-bootstrap";
import CommonHelper from '../../../../services/common'
import _ from 'lodash'
import _images from '../../../../images'
import Translate from '../../../../constants/translate';
import momenttz from 'moment-timezone'
import moment from 'moment'
import { CustomToggle, CustomSearchMenu } from '../../../../components/customdropdown';
export const weekDays = [
    { active: true, value: 'sunday', label: 'Sunday' },
    { active: true, value: 'monday', label: 'Monday' },
    { active: true, value: 'tuesday', label: 'Tuesday' },
    { active: true, value: 'wednesday', label: 'Wednesday' },
    { active: true, value: 'thursday', label: 'Thursday' },
    { active: true, value: 'friday', label: 'Friday' },
    { active: true, value: 'saturday', label: 'Saturday' },
]

const Dealerinfo = ({ countries, states, dealer, handleReactSelectChange, handleOnChange, handleHoursChange, onSelectFile, handleNumberChange, bindAddress, errorFields, deleteLogo, imageLoader, handleDynSelectChange }) => {
    const phoneCodes = !_.isEmpty(countries) ? countries.map((doc) => {
        return {
            country_code: doc.code,
            value: doc.phone_code,
            active: _.isBoolean(doc.active) ? doc.active : true,
            label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
        };
    }) : [];
    return (<>
        <div className="form-style w-100">
            <div className="form-row settings-company pt-2">
                <div className="settings-companyimage">
                    {
                        (imageLoader)
                            ?
                            <div className="img-loader">
                                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                    <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (dealer.logoURL)
                            ?
                            <a data-fancybox={`logoURL`} href={dealer.logoURL} onClick={(e) => { e.preventDefault(); }}>
                                <img className="img-object-fit-contain" src={dealer.logoURL} alt="" height="65" />
                            </a>
                            :
                            <img src={_images.nocompany} alt="" height="65" />
                    }
                </div>
                <div className="settings-profile-info">
                    <div className="settings-profile-upload">
                        <label htmlFor="dealer-logoURL" className={`btn-common float-left mr-2 font-600`}>
                            <Translate text={'Choose Picture'} />
                            <input className="fileInput"
                                type="file"
                                name="testee"
                                id="dealer-logoURL"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={(e) => onSelectFile(e, 'profile', 'logoURL', 'Client Logo')} />
                        </label>
                        <div className={`settings-delete-button ${_.isEmpty(dealer.logoURL) ? 'btn-disable' : ''}`}> <a href="#" className="mini-button"
                            onClick={(e) => {
                                e.preventDefault();
                                deleteLogo();
                            }}> <i className="ico icon-delete"></i></a></div>
                    </div>
                    <div className=" settings-limit"><Translate text={'maxSizeMB'} /></div>
                </div>
            </div>
            <div className="settings-tab-fields-wrap">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'name'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'name'}
                            className={`form-control ${errorFields["name"]}`}
                            name="name"
                            onChange={handleOnChange}
                            value={dealer.name}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'email'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'email'}
                            className={`form-control ${errorFields["email"]}`}
                            name="email"
                            onChange={handleOnChange}
                            value={dealer.email}
                        />
                    </div>
                </div>
                <div className="form-row">

                    <div className="form-group col-md-6">
                        <label ><Translate text={'phone'} /></label>
                        <div className={`input-group country-code ${errorFields["phone"] ? 'phone_error' : ''}`}>

                            <div className="input-group-prepend">
                                <Dropdown className={`btn btn-outline-secondary`} >
                                    <Dropdown.Toggle as={CustomToggle} >
                                        <span id="spn-code-name" data-name={dealer.phoneCode}>
                                            {dealer.phoneCode && phoneCodes.find(item => item.value === dealer.phoneCode)
                                                ? phoneCodes.find(item => item.value === dealer.phoneCode).value + ' ' :
                                                'Code'
                                            }</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomSearchMenu}
                                        className="rm-pointers dropdown-menu-lg dropdown-menu-right"
                                        ChildClass="pipeline-dropdown"
                                        xplacement="bottom-end"
                                    >
                                        {
                                            !_.isEmpty(phoneCodes) && phoneCodes.length > 0 ?
                                                phoneCodes.map((rec, index) => {
                                                    return (<Dropdown.Item key={index} className={`current-pipeline ${(dealer.phoneCode && rec.value === dealer.phoneCode) ? 'active' : ''}`} onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDynSelectChange('phoneCode', rec.value)
                                                    }}>
                                                        {rec.label}
                                                    </Dropdown.Item>)
                                                })
                                                : (<></>)
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <InputText
                                autoComplete="off"
                                placeholder={'phone'}
                                className={`form-control`}
                                name="phone"
                                onChange={handleOnChange}
                                value={dealer.phone}
                            />
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'Fax'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'fax'}
                            className={`form-control ${errorFields["fax"]}`}
                            name="fax"
                            onChange={handleNumberChange}
                            value={dealer.fax}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'companyAbnAcn'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'acn'}
                            className={`form-control ${errorFields["acn"]}`}
                            name="acn"
                            onChange={handleOnChange}
                            value={dealer.acn}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'companyACN'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'abn'}
                            className={`form-control ${errorFields["abn"]}`}
                            name="abn"
                            onChange={handleOnChange}
                            value={dealer.abn}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'website'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'website'}
                            className={`form-control ${errorFields["website"]}`}
                            name="website"
                            onChange={handleOnChange}
                            value={dealer.website}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'Timezone'} /></label>
                        <ReactSelect
                            options={momenttz.tz.names().map(rec => {
                                return {
                                    label: rec, value: rec, active: true,
                                }
                            })}
                            name={"timezone"}
                            placeholder={'select timezone'}
                            onChange={handleReactSelectChange}
                            value={dealer.timezone}
                            classNamePrefix={`${errorFields["timezone"]} cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'address'} /></label>
                        <AutoComplete
                            className={`form-control ${errorFields["address"]}`}
                            bindAddress={bindAddress}
                            types={['address']}
                            value={dealer.address}
                            placeholder={'search address'}
                            onChange={handleOnChange}
                            name="address"
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label ><Translate text={'Latitude'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'latitude'}
                            className={`form-control`}
                            name="latitude"
                            onChange={handleOnChange}
                            value={dealer.latitude}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label ><Translate text={'Longitude'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'longitude'}
                            className={`form-control`}
                            name="longitude"
                            onChange={handleOnChange}
                            value={dealer.longitude}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'country'} /></label>
                        <ReactSelect
                            options={!_.isEmpty(countries) ? _.map(countries, function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } }) : []}
                            name={"country"}
                            placeholder={'select country'}
                            onChange={handleReactSelectChange}
                            value={dealer.country}
                            classNamePrefix={`${errorFields["country"]} cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'state'} /></label>
                        <ReactSelect
                            options={
                                !_.isEmpty(dealer.country) && !_.isEmpty(states) ?
                                    _.map(states.filter(item => item.country_code === dealer.country), function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } })
                                    : []
                            }
                            name={"state"}
                            placeholder={'select state'}
                            onChange={handleReactSelectChange}
                            value={dealer.state}
                            classNamePrefix={`${errorFields["state"]} cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>

                </div>
                <div className="form-row">
                    <div className="form-group col-md-3">
                        <label ><Translate text={'Facebook URL'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'facebook url'}
                            className={`form-control ${errorFields["facebookURL"]}`}
                            name="facebookURL"
                            onChange={handleOnChange}
                            value={dealer.facebookURL}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label ><Translate text={'X URL'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'x url'}
                            className={`form-control ${errorFields["xURL"]}`}
                            name="xURL"
                            onChange={handleOnChange}
                            value={dealer.xURL}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label ><Translate text={'Instagram URL'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'instagram url'}
                            className={`form-control ${errorFields["instagramURL"]}`}
                            name="instagramURL"
                            onChange={handleOnChange}
                            value={dealer.instagramURL}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label ><Translate text={'YouTube URL'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'youtube url'}
                            className={`form-control ${errorFields["youtubeURL"]}`}
                            name="youtubeURL"
                            onChange={handleOnChange}
                            value={dealer.youtubeURL}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-3">
                        <label ><Translate text={'Fiscal Year Start Month'} /></label>
                        <ReactSelect
                            options={moment.monthsShort().map(month => {
                                return {
                                    label: moment().month(month).format('MMMM'),
                                    value: moment().month(month).month()
                                }
                            })}
                            name={"fiscalStart"}
                            placeholder={'select start month'}
                            onChange={handleReactSelectChange}
                            value={dealer.fiscalStart}
                            classNamePrefix={`${errorFields["fiscalStart"]} cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                    <div className="form-group col-md-3">
                        <label ><Translate text={'Week Start'} /></label>
                        <ReactSelect
                            options={weekDays}
                            name={"weekStart"}
                            placeholder={'select week start'}
                            onChange={handleReactSelectChange}
                            value={dealer.weekStart}
                            classNamePrefix={`${errorFields["weekStart"]} cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                    <div className="form-group col-md-3">
                        <label > <Translate text={'Business Hours From'} /></label>
                        <div className="inner-addon right-addon">
                            <i className="bg-icons ico icon-time"></i>
                            <ReactTimePicker
                                value={dealer.startworkingHours ? moment.unix(dealer.startworkingHours.seconds)._d : null}
                                name={'startworkingHours'}
                                onChange={handleHoursChange}
                                timeIntervals={30}
                                placeholder='h:mm aa'
                                className={`form-control ${errorFields["startworkingHours"]}`}
                            />
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label > <Translate text={'to'} /></label>
                        <div className="inner-addon right-addon">
                            <i className="bg-icons ico icon-time"></i>
                            <ReactTimePicker
                                value={dealer.endworkingHours ? moment.unix(dealer.endworkingHours.seconds)._d : null}
                                name={'endworkingHours'}
                                onChange={handleHoursChange}
                                timeIntervals={30}
                                placeholder='h:mm aa'
                                className={`form-control ${errorFields["endworkingHours"]}`}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>);
}

export default Dealerinfo;