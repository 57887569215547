/** LIBRARIES */
import React from 'react'
import moment from 'moment';
import { Draggable } from "react-beautiful-dnd";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** PROVIDERS */
import { StageConsumer } from './provider'
/** COMPONENTS */
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { serviceStatus } from '../../services/enum'

const EnquiryCell = React.memo(({ index, task, sidepanelOpen, clientUsers, groupUsers, handleEnquiryFav, dealersettings }) => {

    function getStyle(style, snapshot) {
        if (!snapshot.isDragging) return {};
        if (!snapshot.isDropAnimating) {
            return style;
        }

        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`
        };
    }

    const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
    const _enquiryFields = !_.isEmpty(task.enqFields) ? task.enqFields : task;

    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const _jobStatus = _enquiryFields && _enquiryFields.status && _enquiryFields.status === serviceStatus.OWNERRESPONDED ? _enquiryFields.ownerResponse : _enquiryFields.status;
    const serviceAllStatus = Object.assign([], dealersettings?.client?.services?.serviceStatus)
    const technicianAllStatus = Object.assign([], dealersettings?.client?.services?.technicianStatus)
    let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0]

    const isStatusComplete = CommonHelper.isServiceStatusComplete(serviceAllStatus, _jobStatus)
    const isStatusOnProgress = CommonHelper.isServiceStatusOnProgress(serviceAllStatus, _jobStatus);
    return (
        // <StageConsumer>
        //     {({ handleEnquiryFav, handleShowInboundLead, dealersettings }) => (
        <Draggable
            draggableId={task.id}
            index={index}
            key={task.id}
        >
            {(provided1, snapshot1) => (
                <>
                    <div
                        ref={provided1.innerRef}
                        {...provided1.draggableProps}
                        {...provided1.dragHandleProps}
                        style={getStyle(provided1.draggableProps.style, snapshot1)}
                        className={`pipeline-item ${task.isNewEnquiry ? 'newlead' : (task.status ? task.status.toLowerCase() : '')}-cell ${snapshot1.isDraggingOver ? "pipeline-item-dragging-Over" : ""}`}
                        id={`service-${task.id}`}
                        onClick={(e) => {
                            e.preventDefault();
                            sidepanelOpen(task.id)

                        }}
                    >
                        <div className="pipeline-control">
                            <div className="pipeline-favourite"><a href="#" onClick={(e) => {
                                e.stopPropagation();
                                handleEnquiryFav(task.id, !task.isFav, task.stage, task.stage, task, 'isFav')
                            }} ><i className={`ico icon-star${task.isFav ? '-active' : ''}`}></i></a></div>

                        </div>

                        <div className="pipeline-item-head">
                            {/* <span className="badge badge-pill badge-lost">{index + 1}</span>  */}
                            {CommonHelper.displayContactIcon(task, 'mr-1')}
                            {
                                (!_.isEmpty(task.enqFields) && !_.isEmpty(task.enqFields.contact))
                                    ?
                                    <>
                                        {CommonHelper.displayContact(null, task.enqFields.contact, '')}
                                    </>
                                    :
                                    <>
                                        {
                                            (!_.isEmpty(task.enqFields) && !_.isEmpty(task.enqFields.engageNewContact))
                                                ?
                                                <>
                                                    {CommonHelper.displayContact(null, task.enqFields.engageNewContact, '')}
                                                </>
                                                :
                                                <>
                                                    {task.name}
                                                </>
                                        }
                                    </>
                            }
                        </div>
                        {
                            (!_.isEmpty(task.enqFields) && !_.isEmpty(task.enqFields.contact) && task.enqFields.contact.businessContact && !_.isEmpty(task.enqFields.contact.company))
                                ?
                                <div className="testdrive-contact-license">
                                    <i className={`ico icon-Contacts mr-1`}></i>
                                    {CommonHelper.displayContactName([], task.enqFields.contact, '')}
                                </div>
                                :
                                <></>
                        }
                        {
                            (task.phone || task.email) ?
                                <div className="pipeline-item-phone">
                                    {task.phone ?
                                        CommonHelper.phoneFormat(task.phoneCode, task.phone, dealersettings)
                                        : task.email}
                                </div>
                                : <>
                                    {
                                        (!_.isEmpty(task.enqFields) &&
                                            !_.isEmpty(task.enqFields.contact) &&
                                            task.enqFields.contact.businessContact &&
                                            !_.isEmpty(task.enqFields.contact.company) &&
                                            (task.enqFields.contact.company.phone || task.enqFields.contact.company.email))
                                            ?
                                            <div className="pipeline-item-phone">
                                                {task.enqFields.contact.company.phone ?
                                                    CommonHelper.phoneFormat(task.enqFields.contact.company.phoneCode, task.enqFields.contact.company.phone, dealersettings)
                                                    : task.enqFields.contact.company.email}
                                            </div>
                                            :
                                            <div className="no-data-red"><Translate text={'contactDetailsMissing'} /></div>
                                    }
                                </>
                        }

                        {
                            task.vehiclemodel ? <>
                                <div className="pipeline-item-vehicle"><strong>{task.vehiclemodel}</strong></div>
                                {
                                    task.regNo
                                        ?
                                        <><div className="pipeline-item-source"><strong><Translate text={'regNo'} /></strong>: {task.regNo}</div></>
                                        :
                                        <></>
                                }
                            </> : (<div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>)
                        }

                        {task.displayID ? <div className="pipeline-item-source"><i className="ico icon-service-settings"></i> {task.displayID}</div> : <></>}

                        {
                            _enquiryFields && _enquiryFields.appointmentDate
                                ?
                                <div className="pipeline-item-div w-100">
                                    <i className="ico icon-service-calendar"></i>
                                    {moment(_enquiryFields.appointmentDate).format('DD MMM YYYY h:mm A')}
                                </div>
                                :
                                <></>
                        }

                        {!_.isEmpty(_enquiryFields?.stageHistory) && (<div className="pipeline-item-lead-age">
                            <img src={_images.leadAge} height="16px" className="mr-1" /> Age
                            <div className="lead-age-inner" title='Pipeline Age'>P: <span>{CommonHelper.showPSAge(_enquiryFields.stageHistory, _enquiryFields.pipeline, _enquiryFields.stage, 'pipeline')}</span></div>
                            <div className="lead-age-inner" title='Stage Age'>S: <span>{CommonHelper.showPSAge(_enquiryFields.stageHistory, _enquiryFields.pipeline, _enquiryFields.stage)}</span></div>
                        </div>)}

                        <div className="pipeline-item-div w-100 mt-2">
                            {
                                _enquiryFields && _enquiryFields.owner ? (<OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>{CommonHelper.getUserNamebyId(allUsers, _enquiryFields.owner)}</Tooltip>
                                    }
                                >
                                    <div className="float-right">
                                        <div className="pipeline-users pl-0">
                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                <img src={CommonHelper.getUserImagebyId(allUsers, _enquiryFields.owner)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                            </a>
                                        </div>
                                    </div>
                                </OverlayTrigger>) : (<></>)
                            }
                            <div className="float-left">


                                {
                                    (_jobStatus)
                                        ?
                                        <>
                                            <div className='pipeline-button'>
                                                {
                                                    !_.isEmpty(_objCurrentStatus)
                                                        ?
                                                        <>
                                                            <span
                                                                className={`status-empty border-bottom-0`} style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                                <Translate
                                                                    text={_objCurrentStatus.name}
                                                                    upperCase={true}
                                                                />

                                                            </span>
                                                        </>
                                                        :
                                                        <>
                                                            <span className={`status-${_jobStatus.toLowerCase()} border-bottom-0`}>
                                                                {_jobStatus.split(/(?=[A-Z])/).join(' ').toUpperCase()}
                                                            </span>
                                                        </>
                                                }

                                            </div>

                                            {
                                                _jobStatus === serviceStatus.OWNERAPPROVED
                                                    ?
                                                    <div className="pipeline-button ml-1">
                                                        <span className="service-status-confirm border-bottom-0 pl-4">
                                                            <img src={_enquiryFields && _enquiryFields.jobConfirmed ? _images.radioTick : _images.radioUnTick} alt="" width="14" className="confirm-ico-status" /> Confirmed
                                                        </span>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                            {
                                                _enquiryFields?.technicianStatus
                                                    ?
                                                    <>
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            overlay={<Tooltip><Translate text={'technicianStatus'} /> <br /> {CommonHelper.getNameByValue(technicianAllStatus, _enquiryFields?.technicianStatus, _enquiryFields?.technicianStatus)}</Tooltip>}
                                                        >
                                                            <div className="pipeline-button ml-1">
                                                                <i className={`ico icon-timer`} style={{ color: CommonHelper.getFieldByValue(technicianAllStatus, _enquiryFields?.technicianStatus, 'color', '#b0babe') }}></i>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                            {
                                                (_enquiryFields?.isTradeIn) ? (
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip>
                                                                <Translate text={'Trade-In'} />
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className="tradein-card-icon"> <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, "ico icon-tradein")}></i> </div>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </>
                                        :
                                        <></>

                                }
                            </div>


                        </div>

                        {
                            _enquiryFields && (_enquiryFields.checkInBy || _enquiryFields.serviceAdvisor || _enquiryFields.technician)
                                ?
                                <div className="service-card-status card-top-border">
                                    <ul>
                                        {_enquiryFields.checkInBy ? <li> <strong> <Translate text={'checkIn'} /> </strong>: {CommonHelper.getUserNamebyId(allUsers, _enquiryFields.checkInBy)} </li> : <></>}
                                        {_enquiryFields.serviceAdvisor ? <li> <strong> <Translate text={'serviceAdvisor'} /></strong>: {CommonHelper.getUserNamebyId(allUsers, _enquiryFields.serviceAdvisor)} </li> : <></>}
                                        {_enquiryFields.technician ? <li> <strong> <Translate text={'technician'} /> </strong>: {CommonHelper.getUserNamebyId(allUsers, _enquiryFields.technician)} </li> : <></>}
                                    </ul>
                                </div>
                                :
                                <></>
                        }

                        {
                            (isStatusComplete || isStatusOnProgress || _jobStatus === serviceStatus.OWNERAPPROVED || _jobStatus === serviceStatus.WORKINPROGRESS || _jobStatus === serviceStatus.COMPLETED)
                                ?
                                <>
                                    < div className="service-card-statusinfo card-top-border">
                                        <div className="home-radio-block">

                                            <div className="statusinfo-checkbox-loop">
                                                <div className="right chkbxin">
                                                    <img src={_enquiryFields && _enquiryFields.partsConfirmed ? _images.checkboxTick : _images.checkboxUnTick} alt="" width="14" />
                                                    <Translate text={'Parts'} /> </div>
                                            </div>

                                            <div className="statusinfo-checkbox-loop">
                                                <div className="right chkbxin">
                                                    <img src={_enquiryFields && _enquiryFields.workshopConfirmed ? _images.checkboxTick : _images.checkboxUnTick} alt="" width="14" />
                                                    <Translate text={'Workshop'} />
                                                </div>
                                            </div>

                                            <div className="statusinfo-checkbox-loop">
                                                <div className="right chkbxin">
                                                    <img src={_enquiryFields && _enquiryFields.jobAllocated ? _images.checkboxTick : _images.checkboxUnTick} alt="" width="14" />
                                                    <Translate text={'allocated'} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>
                                :
                                <></>
                        }

                        {
                            !_.isEmpty(_enquiryFields) && (!_.isEmpty(_enquiryFields.slot) || _enquiryFields.totalServiceCost)
                                ?
                                <>
                                    <div className="pipeline-item-div w-100 card-top-border">
                                        <div className="float-left">
                                            {
                                                !_.isEmpty(_enquiryFields.slot)
                                                    ?
                                                    <>
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            overlay={<Tooltip>{_enquiryFields.slot.from}{'-'}{_enquiryFields.slot.to}</Tooltip>}
                                                        >
                                                            <div className="badge-slot"
                                                                style={{
                                                                    color: `${_enquiryFields.slot.color ? _enquiryFields.slot.color : '#f47815 '}`,
                                                                    backgroundColor: `${_enquiryFields.slot.color ? _enquiryFields.slot.color + '1f' : '#f478151f'}`,
                                                                    boxShadow: `inset 0 0 0 1px ${_enquiryFields.slot.color ? _enquiryFields.slot.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
                                                                }}>
                                                                <i className="ico icon-service-timer"></i> {_enquiryFields.slot.name}
                                                                {
                                                                    _enquiryFields.slot.isExpress ? <span className="express-inline-tag"><div className="badge red-ribbon ml-2 express-fix"><Translate text={'express'} upperCase={true} /></div></span> : <></>
                                                                }

                                                            </div>
                                                        </OverlayTrigger>
                                                    </>

                                                    :
                                                    <></>
                                            }
                                        </div>
                                        {
                                            _enquiryFields.totalServiceCost
                                                ?
                                                <div className="float-right">
                                                    <div className="badge-slot badge-cost-green">
                                                        <i className="ico icon-service-wallet"></i>
                                                        <NumberFormat value={_enquiryFields.totalServiceCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }


                                    </div>

                                </>
                                :
                                <></>
                        }



                    </div>
                </>
            )
            }
        </Draggable >
        //     )}
        // </StageConsumer>
    )
})

export default EnquiryCell