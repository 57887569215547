/** LIBRARIES */
import React from 'react';
import Translate from '../../../constants/translate';

const LegalSettings = () => {

    return (
        <div className='settings-container ml-4'>
            <h2><Translate text={'Legal'} /></h2>
            <div className='divider-line'></div>
            <div className="settings-legal-wrap mt-3">

                <div className="settings-terms" style={{ width: '100%' }}>

                    <div className="settings-legal-loop">

                        <div className="settings-legal-link"> <a href="https://www.fusionamspro.com/fusionsd-subscription-terms.html"
                            target="_blank"> <i className="ico icon-files-document"></i> <Translate text={'Subscription Terms'} /></a></div>



                    </div>


                    <div className="settings-legal-loop">

                        <div className="settings-legal-link"> <a
                            href="https://www.fusionamspro.com/fusionsd-order-data-processing-agreement.html" target="_blank">
                            <i className="ico icon-files-document"></i> <Translate text={'Data Processing Agreement'} /></a></div>



                    </div>


                    <div className="settings-legal-loop">

                        <div className="settings-legal-link"> <a href="https://www.fusionamspro.com/fusionsd-privacy-policy.html"
                            target="_blank"> <i className="ico icon-files-document"></i><Translate text={'Privacy Policy'} />  </a></div>



                    </div>


                    <div className="settings-legal-loop">

                        <div className="settings-legal-link"> <a
                            href="https://www.fusionamspro.com/fusionsd-service-level-agreement.html" target="_blank"> <i
                                className="ico icon-files-document"></i> <Translate text={'Service Level Agreement'} /> </a></div>



                    </div>


                </div>

                <div className="settings-legal-archive"><Translate text={'You can view previous versions of the above terms and policies in the'} /> <a
                    href="https://www.fusionamspro.com/legal-archive.html" target="_blank"><strong><Translate text={'Archive section'} /></strong></a>.
                </div>


            </div>
        </div>
    )
}

export default LegalSettings;