/** LIBRARIES */
import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import moment from 'moment';
/** COMPONENTS */
import Translate from '../constants/translate';
import ReactTimePicker from './reactTimePicker';

const WorkingHours = props => {
    const [state, setState] = useState({
        workingHours: props?.state ? props?.state : {},
    })
    const [errorFields, setErrorFields] = useState({});
    const currentDate = moment().format('YYYY-MM-DD')

    const handleSubmit = (e) => {
        e.preventDefault();
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        _.range(0, 7).map((day) => {
            if (props.allMandatory === true) {
                if (!state?.workingHours?.[day]?.to) {
                    formIsValid = false;
                    errors[`${day}-to`] = errorClass;
                }
                if (!state?.workingHours?.[day]?.from) {
                    formIsValid = false;
                    errors[`${day}-from`] = errorClass;
                }
            }
            else {
                if (state?.workingHours?.[day]?.from && !state?.workingHours?.[day]?.to) {
                    formIsValid = false;
                    errors[`${day}-to`] = errorClass;
                }
                if (state?.workingHours?.[day]?.to && !state?.workingHours?.[day]?.from) {
                    formIsValid = false;
                    errors[`${day}-from`] = errorClass;
                }
            }

        });
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        props.handleClose(state);
    };

    const handleHoursChange = (val, name, day) => {
        setState({
            ...state,
            ['workingHours']: {
                ...state['workingHours'] ? state['workingHours'] : {},
                [day]: {
                    ...state['workingHours'][day] ? state['workingHours'][day] : {},
                    [name]: moment(val).format('HH:mm')
                }
            }
        });
    }

    return (
        <>
            <Modal show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-cafe-hours"
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">

                            <div className="form-style w-100">
                                {
                                    _.range(0, 7).map((day, index) => {
                                        return <div key={index} className="form-row form-row-line">
                                            <div className="form-group col-md-3">
                                                <label style={{ paddingTop: '8px' }}><Translate text={moment().day(day).format('dddd')}/></label>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <div className="inner-addon right-addon">
                                                    <i className="bg-icons ico icon-time"></i>
                                                    <ReactTimePicker
                                                        value={state['workingHours'][day]?.from ? moment(`${currentDate} ${state['workingHours'][day]?.from}`)._d : null}
                                                        name={`${day}-startworkingHours`}
                                                        onChange={(val) => handleHoursChange(val, 'from', day)}
                                                        timeIntervals={30}
                                                        placeholder='h:mm aa'
                                                        className={`form-control ${errorFields[`${day}-from`]}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <div className="inner-addon right-addon">
                                                    <i className="bg-icons ico icon-time"></i>
                                                    <ReactTimePicker
                                                        value={state['workingHours'][day]?.to ? moment(`${currentDate} ${state['workingHours'][day]?.to}`)._d : null}
                                                        name={`${day}-endworkingHours`}
                                                        onChange={(val) => handleHoursChange(val, 'to', day)}
                                                        timeIntervals={30}
                                                        placeholder='h:mm aa'
                                                        className={`form-control ${errorFields[`${day}-to`]}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-1">
                                                <a href="#" className="mini-button mt-1" onClick={(e) => {
                                                    e.preventDefault();
                                                    setState({
                                                        ...state,
                                                        ['workingHours']: {
                                                            ...state['workingHours'] ? state['workingHours'] : {},
                                                            [day]: {}
                                                        }
                                                    })
                                                }}>
                                                    <i className="ico icon-remove"></i>
                                                </a>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-default float-left"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleClose();
                        }}
                    ><Translate text={'cancel'} />
                    </button>

                    <button
                        type="button"
                        className={`btn btn-primary float-right ${props.btnLoader ? 'form-disable' : ''}`}
                        onClick={(e) => handleSubmit(e)}
                    >
                        {props.btnLoader ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                        <Translate text={'done'} />
                    </button>
                </Modal.Footer>
            </Modal>
        </>


    )
}

export default WorkingHours