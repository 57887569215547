import React, { useRef, useState, useEffect } from "react";
import { Dropdown, Modal, Popover } from "react-bootstrap";
import moment from "moment";
import _ from 'lodash'
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { reminderOptions, defaultActivityTypes } from '../../../services/enum';
import toast from 'toasted-notes';
//import { firestoreDB } from "../../../services/helper";

const ContactLink = (props) => {
    //const [activity, setActivity] = useState({})    
    //const [checkDataLoad, setDataload] = useState(true)
    //const node = useRef();
    const { selectedCustomer, activityLog, activityOldLog } = props;
    const history = useHistory();
    const expandLink = () => {
        if (!Boolean(props.isNewEnquiry)) {
            const url = `/contacts/details/${selectedCustomer.documentID}`;
            if (JSON.stringify(activityOldLog) === JSON.stringify(activityLog)) {
                history.push(url);
            }
            else {
                Swal.fire({
                    title: CommonHelper.showLocale(props, 'Are you sure?'),
                    text: CommonHelper.showLocale(props, 'You want to  view contact details'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                    cancelButtonText: CommonHelper.showLocale(props, 'No'),
                }).then(result => {
                    if (result.value) {
                        history.push(url);
                        props.handleClose(null, null, 'close');
                    }
                });
            }
            // window.open(url, '_blank');
        }
    }
    return (<>
        <div className='form-readonly-wrap'>
            <div className='input-readonly'>
                {activityLog.owner === localStorage.uid ||
                    (activityLog.teammates &&
                        activityLog.teammates.some(
                            item => item === localStorage.uid,
                        )) ? (
                    <div className='input-readonly-close'>
                        <a
                            href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                props.removeLink('contact');
                            }}
                        >
                            <i className='ico icon-remove'></i>{' '}
                        </a>
                    </div>
                ) : (
                    <></>
                )}
                <div className='input-readonly-inner'>
                    <div className='inner-left'>
                        {CommonHelper.displayContactIcon(selectedCustomer, '')}
                    </div>
                    <div className='inner-right'>

                        <div
                            className='input-readonly-head cursor-pointer'
                            onClick={e => {
                                e.preventDefault();
                                expandLink();
                            }}
                        >
                            {CommonHelper.displayContact([], selectedCustomer, '')}
                            <span className='activity-details-preferred'>{CommonHelper.displayPreferredName(selectedCustomer, '')}</span>
                        </div>


                        {selectedCustomer.displayID
                            ?
                            <div className='input-readonly-head'>{selectedCustomer.displayID}</div>
                            :
                            <></>}

                        {selectedCustomer.clientID && props.dealersettings && props.dealersettings.client.id !== selectedCustomer.clientID && props.dealersettings.group && props.dealersettings.group.clients && props.dealersettings.group.clients[selectedCustomer.clientID]
                            ?
                            <div className='input-readonly-head blue-color'>{props.dealersettings.group.clients[selectedCustomer.clientID].name}</div>
                            :
                            <></>
                        }
                        {selectedCustomer.phone ||
                            selectedCustomer.email ? (
                            <div className='input-readonly-sub'>
                                {selectedCustomer.phone
                                    ? CommonHelper.phoneFormat(selectedCustomer.phoneCode, selectedCustomer.phone, props.dealersettings)
                                    : selectedCustomer.email}
                            </div>
                        ) :
                            <>
                                {
                                    (!_.isEmpty(selectedCustomer) &&
                                        selectedCustomer.businessContact &&
                                        !_.isEmpty(selectedCustomer.company) &&
                                        (selectedCustomer.company.phone || selectedCustomer.company.email))
                                        ?
                                        <div className="input-readonly-sub">
                                            {selectedCustomer.company.phone ?
                                                CommonHelper.phoneFormat(selectedCustomer.company.phoneCode, selectedCustomer.company.phone, props.dealersettings)
                                                : selectedCustomer.company.email}
                                        </div>
                                        :
                                        <div className="no-data-red"><Translate text={'contactDetailsMissing'} /></div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default ContactLink;