
/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import _ from 'lodash';
import moment from 'moment';
//import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../constants/translate';
//import { LayoutProvider } from '../layout/provider';
import { InputTextArea } from '../../components';
import CommonHelper from '../../services/common';
// import Users from '../users/list/index';
// import Contacts from '../contacts/list/index';
// import ContactActivityView from './activity'
import { default as _images } from '../../images';
import { objRequirement } from '../requirement/viewModel';
/** VIEW-MODELS */
import { callLogStatus, callTypes, logTypes, enquiryStatus } from '../../services/enum'
import { objCallLogs } from './viewModel'
import { objEnquiry, objActivitylog, objWalkIn } from '../pipeline/viewModel';
import { objContact } from '../contacts/viewModel';
import { firestoreDB } from "../../services/helper";
let enquiryTypes = [];
export default class EngageRequest extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.isLoadingReject = false;
        // this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        // this.ref = firestoreDB(this.props.dealersettings).firestore().collection('callLogs');
        this.unsubscribeCallReq = null;
        this.state = {
            fields: Object.assign({}, objCallLogs),
            showReject: false,
            errors: {},
            arrcallstatus: [],
            titles: [],
        }
    }

    //#region PAGE LOAD EVENTS

    async loadEngageRequest(props) {
        const { show, docID, dealersettings } = props;

        const fields = Object.assign({}, objCallLogs);
        if (show === true && docID) {
            this.newDocID = docID;
            const refData = await firestoreDB(dealersettings).firestore().collection('callLogs').doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                for (let [key, value] of Object.entries(objData)) {
                    fields[key] = value;
                }

                fields.documentID = refData.id;

                if (fields.enquiryID) {
                    const refStockData = firestoreDB(dealersettings).firestore().collection('enquiries').doc(fields.enquiryID);
                    await refStockData.get().then((_testStock) => {
                        if (_testStock.exists) {
                            const enquiry = Object.assign({}, objEnquiry);
                            const objTDStockData = Object.assign({}, _testStock.data());
                            for (let [key, value] of Object.entries(objTDStockData)) {
                                if (enquiry.hasOwnProperty(key))
                                    enquiry[key] = value;
                            }
                            enquiry.documentID = fields.enquiryID;
                            fields.enquiry = enquiry;

                        }
                        else {
                            console.error("No such document!");
                        }
                    });
                }

                this.setState({ fields: fields });

                this.unsubscribeCallReq = firestoreDB(dealersettings).firestore().collection('callLogs')
                    .doc(docID)
                    .onSnapshot(this.onCollectionUpdate);

            } else {
                console.error("No such document2!");
            }

            if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
                const setttings = dealersettings.client.settings;

                const titles = [];
                setttings.titles && setttings.titles.forEach(doc => {
                    titles.push({
                        value: doc.value,
                        label: doc.name,
                    });
                });

                enquiryTypes = [];
                setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                    enquiryTypes.push({ value: doc.value, name: doc.name });
                });

                const arrcallstatus = [];
                setttings.callStatus && setttings.callStatus.forEach((doc) => {
                    arrcallstatus.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });

                this.setState({
                    arrcallstatus: arrcallstatus,
                    titles: titles
                });
            }

        }
    }

    onCollectionUpdate = (docSnapshot) => {

        if (!docSnapshot.exists) {
            return;
        }

        const fields = Object.assign({}, this.state.fields);
        const objData = Object.assign({}, docSnapshot.data());
        delete objData['enquiry'];
        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }
        fields.documentID = docSnapshot.id;
        this.setState({ fields: fields });
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadEngageRequest(this.props);
    }

    componentWillUnmount() {
        this.unsubscribeCallReq && this.unsubscribeCallReq();
    }
    //#endregion

    //#region  CHANGE EVENTS
    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields });
        //this.errorChange(name);
    }

    //#endregion

    //#region SAVE & SUBMIT 
    handleRejectSubmit = (e) => {
        e.preventDefault();
        //let errors = {};
        let formIsValid = true;
        //let fields = Object.assign({}, this.state.fields);
        //let errorClass = 'input_error';
        //this.setState({ errors: errors });
        if (formIsValid === true) {
            this.updateStatus(callLogStatus.REJECTED);
        }
        else
            return formIsValid;

    };

    handleAcceptSubmit = (e) => {
        e.preventDefault();
        //let errors = {};
        let formIsValid = true;
        //let fields = Object.assign({}, this.state.fields);
        //let errorClass = 'input_error';
        //this.setState({ errors: errors });
        if (formIsValid === true) {
            this.updateStatus(callLogStatus.ACCEPTED);
        }
        else
            return formIsValid;

    };



    updateStatus = (status) => {
        const { dealersettings } = this.props;

        const objData = Object.assign({}, this.state.fields);
        let _date = moment()._d;

        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.fromDate(_date);
        }
        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedFrom = 'web';
        objData.modifiedDate = window.firebase.firestore.Timestamp.fromDate(_date);
        objData.status = status;
        objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        if (objData.status === callLogStatus.ACCEPTED)
            delete objData['rejectReason'];

        if (!objData.clientID)
            objData.clientID = dealersettings ? dealersettings.client.id : '';

        if (!objData.documentID)
            objData.documentID = firestoreDB(this.props.dealersettings).firestore().collection('callLogs').doc().id;

        if (objData.status === callLogStatus.ACCEPTED) {
            this.loadEnquiry(objData);
        }
        else {
            this.isLoadingReject = true;
            this.updateRequest(objData)
        }
    };
    //#endregion

    //#region ENQUIRY
    async loadEnquiry(objLogRequest) {
        this.isLoading = true;
        const { dealersettings, clientModule } = this.props;
        if (dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings) {

            const setttings = dealersettings.client.settings;


            let state = Object.assign({}, objEnquiry);

            if (_.isEmpty(state.addedBy)) {
                state.addedBy = dealersettings ? dealersettings.id : '';
                state.addedDate = window.firebase.firestore.Timestamp.now();
                state.owner = dealersettings ? dealersettings.id : '';
            }
            state.modifiedBy = dealersettings ? dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
            state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings, null, objLogRequest.clientID)
            // state.clientID = objLogRequest.clientID ? objLogRequest.clientID : (dealersettings ? dealersettings.client.id : '');
            // state.groupID = (dealersettings && dealersettings.client.group ? dealersettings.client.group : null);
            // state.regionID = (dealersettings && dealersettings.client.region ? dealersettings.client.region : null);
            // state.oemID = (dealersettings ? dealersettings.client.settingsID : null);

            if (setttings.pipelines.length > 0 && setttings.pipelines.some(item => item.value !== 'LeadsBucket')) {

                let _pipeline = Object.assign({}, setttings.pipelines.filter(e => e.value !== 'LeadsBucket')[0])
                if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultPipeline)) {
                    _pipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultPipeline);
                }
                let defaultstages = _pipeline?.stages;
                let defaultstatus = _pipeline?.status;
                let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

                state.pipeline = _pipeline.value;
                state.stage = stages[0];
                state.status = !_.isEmpty(defaultstatus) ? defaultstatus[0] : 'open';
                state.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: state.status, user: localStorage.uid }]);

            }
            else {

                let _pipeline = Object.assign({}, setttings.pipelines.filter(e => e.value === 'LeadsBucket')[0])
                let _defaultstages = _pipeline.stages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead')
                let _stages = Array.prototype.map.call(_defaultstages, function (item) { return item.value; }).join(",").split(',');

                state.pipeline = _pipeline.value;
                state.stage = _stages[0];
                state.status = null;
                state.ownerInbound = localStorage.uid;
            }

            if (_.isEmpty(state.tags) && objLogRequest.documentID)
                state.tags = [{ type: 'frontdesk', refID: objLogRequest.documentID, subType: '' }];

            state.stageDate = window.firebase.firestore.Timestamp.now();
            state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
            state.isDeleted = false;
            state.isNewEnquiry = true;

            if (!_.isEmpty(objLogRequest.contact) && objLogRequest.contactID) {
                const refContactData = await firestoreDB(dealersettings).firestore().collection('contactDetails').doc(objLogRequest.contactID).get();
                if (refContactData.exists) {
                    const contact = Object.assign({}, objContact);
                    const objcontactData = Object.assign({}, refContactData.data());
                    for (let [key, value] of Object.entries(objcontactData)) {
                        contact[key] = value;
                    }
                    contact.documentID = refContactData.id;
                    state.contact = contact;
                    state.isContact = true;
                    state.contactID = refContactData.id;
                    state.isNewEnquiry = false;
                }
            }
            else if (!_.isEmpty(objLogRequest.contact) && !objLogRequest.contactID) {
                var _contact = objLogRequest.contact;
                var ObjCont = {
                    firstName: _contact.firstName ? _contact.firstName : '',
                    middleName: _contact.middleName ? _contact.middleName : '',
                    lastName: _contact.lastName ? _contact.lastName : '',
                    phoneCode: _contact.phoneCode ? _contact.phoneCode : '',
                    phone: _contact.phone ? _contact.phone : '',
                    email: _contact.email ? _contact.email : '',
                }
                state.engageNewContact = ObjCont;
            }

            if (!_.isEmpty(objLogRequest.make)) {
                const _requirement = Object.assign({}, objRequirement);
                for (let [key, value] of Object.entries(objLogRequest)) {
                    if (value && (key === 'make' || key === 'model' || key === 'type' || key === 'group' || key === 'year' || key === 'saleType'))
                        _requirement[key] = value;
                }
                _requirement.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;
                state.requirement = _requirement;
                //state.isVehicle = true;
            }
            if (_.isEmpty(state.requirement) && _.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
                state.requirement = {
                    saleType: dealersettings.accessSalesType[0]
                }
            }

            if (!_.isEmpty(objLogRequest.logType)) {
                let _enquiryTypes = enquiryTypes && enquiryTypes.filter(i => (i.name.toLowerCase().includes(objLogRequest.logType) || i.value.toLowerCase().includes(objLogRequest.logType)))[0];
                state.enquiryType = !_.isEmpty(_enquiryTypes) ? _enquiryTypes.value : null;
            }

            if (objLogRequest.enquiryType) state.enquiryType = objLogRequest.enquiryType
            if (objLogRequest.origin) state.origin = objLogRequest.origin
            if (objLogRequest.campaign) state.campaign = objLogRequest.campaign

            const objData = Object.assign({}, state);
            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    if (!['origin'].includes(propName)) delete objData[propName];
                }
            }

            objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;
            //console.log('loadEnquiry', objData);
            const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(objData.documentID);
            updateRef.set(objData, { merge: true }).then((docRef) => {
                let documentID = objData.documentID;
                objLogRequest.enquiryID = documentID;

                if (!_.isEmpty(documentID) && !_.isEmpty(objData.requirement))
                    this.saveReqInfo(documentID, objData.requirement, objData.clientID)

                this.saveCustomerlog(objLogRequest, objData);
                this.updateRequest(objLogRequest);
                this.logWalkin(objLogRequest)
                //this.reArrangeQueue(objData.clientID);

                if (objData.contactID) {
                    const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
                    updateRepeatCustomer({ 'contactID': objData.contactID, 'projectId': objData?.projectId ? objData.projectId : null })
                }

                this.props.history.push("/enquiry/details/" + documentID + '?type=enquiry');
            }).catch((error) => {
                console.error("Error adding enquiries: ", error);
            });
        }
    }

    saveReqInfo = (enquiryID, _objReq, clientID) => {
        try {

            const { dealersettings } = this.props;
            const userID = dealersettings ? dealersettings.id : '';
            let objData = Object.assign({}, _objReq);
            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings, null, clientID)

            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = userID
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = userID
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            if (!objData.enquiryID)
                objData.enquiryID = enquiryID;

            let requirementID = objData['documentID'];

            const refReqData = firestoreDB(dealersettings).firestore().collection(`enquiries/${enquiryID}/requirements`).doc(requirementID);
            refReqData.set(objData, { merge: true }).then((docRef) => {

                let _makeModel = !_.isEmpty(objData.stock) ?
                    (objData.stock.make ? objData.stock.make + ' ' : '') + (objData.stock.model ? objData.stock.model + ' ' : '') + (objData.stock.year ? objData.stock.year : '') :
                    (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '');

                let _logNote = (!_.isEmpty(objData.stock) ? 'Requirement stock added' : 'Requirement added') + (_makeModel ? ' (' + _makeModel + ')' : '');

                CommonHelper.saveChangeLog(dealersettings.client.id, objData.groupID, objData.enquiryID, _logNote, 'enquiry', 'updated');

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        } catch (error) {
            //errorLog(error, 'saveReqInfo');
            console.error("Error adding enquiries: ", error);
        }

    };

    updateRequest = (objData) => {
        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('callLogs').doc(objData.documentID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.isLoading = false;
            this.isLoadingReject = false;
            toast.notify('Engage request updated successfully', {
                duration: 2000
            });
            this.props.setNotifyClose(true)
            this.props.handleClose();
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });
    }

    saveCustomerlog = (objFields, _enquiry) => {
        const { dealersettings, clientUsers } = this.props;
        const { logType } = this.state.fields;
        const newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = 'log';
        newlogVM.subType = logType === 'walkin' ? 'note' : 'call';
        newlogVM.isDone = true;
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.modifiedFrom = 'web';

        if (!_.isEmpty(objFields.teammates))
            newlogVM.teammates = objFields.teammates;

        if (!_.isEmpty(objFields.mentions))
            newlogVM.mentions = objFields.mentions;

        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.enquiryID = !_.isEmpty(objFields.enquiryID) ? objFields.enquiryID : null;
        newlogVM.contactID = !_.isEmpty(objFields.contactID) ? objFields.contactID : null;
        newlogVM['enquiry'] = CommonHelper.getMinifiedData(_enquiry, 'enquiry');
        newlogVM['contact'] = CommonHelper.getMinifiedData(_enquiry?.contact, 'contact');
        newlogVM.clientID = dealersettings.client.id;
        newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
        newlogVM.startDate = objFields.addedDate ? objFields.addedDate : window.firebase.firestore.Timestamp.now();
        newlogVM.addedByName = dealersettings ? dealersettings.name : '';
        newlogVM.modifiedByName = dealersettings ? dealersettings.name : '';

        newlogVM.callDuration = objFields.callDuration ? objFields.callDuration : 0;
        newlogVM.callType = objFields.callType ? objFields.callType : '';
        newlogVM.callStatus = objFields.callStatus ? objFields.callStatus : '';
        newlogVM.notes = objFields.notes ? objFields.notes : '';
        Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
        newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`).set(newlogVM, { merge: true })
            .catch(error => {
                console.error(error);
            });;
    }

    logWalkin = (objFields) => {
        const { dealersettings, clientUsers } = this.props;
        const { logType, enquiryType } = this.state.fields;

        if ((logType === 'walkin' || enquiryType === 'walkin') && !_.isEmpty(objFields.enquiryID)) {
            const objData = Object.assign({}, objWalkIn);
            objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;
            objData.addedBy = localStorage.uid;
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
            objData.modifiedBy = localStorage.uid;
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.clientID = dealersettings.client.id;
            objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            objData.enquiryID = !_.isEmpty(objFields.enquiryID) ? objFields.enquiryID : null;
            objData.contactID = !_.isEmpty(objFields.contactID) ? objFields.contactID : null;
            objData.walkinDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';

            const refOnjData = firestoreDB(dealersettings).firestore().collection(`enquiries/${objData.enquiryID}/walkins`).doc(objData.documentID);
            refOnjData.set(objData).then((docRef) => {
                CommonHelper.logFirstWalkin(objData.enquiryID, objData.walkinDate, dealersettings);
            }).catch((error) => {
                console.error("Error updating walkin_details: ", error);
            });
        }

    }

    async reArrangeQueue(clientID) {
        const { dealersettings } = this.props;

        const queueRef = window.firebase.firestore().doc(`clients/${clientID}/queues/${dealersettings.department}`);
        await queueRef.get().then(async queueDoc => {
            if (queueDoc.exists) {
                const queue = queueDoc.data();
                if (!_.isEmpty(queue) && !_.isEmpty(queue.users) && queue.qTimer && queue.users.indexOf(localStorage.uid) >= 0) {
                    queue.users = queue.users.filter(e => e !== localStorage.uid).concat(localStorage.uid)
                    await queueRef.update({
                        users: queue.users
                    }).catch(err => console.error(err));
                }
            }
        });
    }
    //#endregion

    render() {
        const { documentID, user, contact, contactID, enquiry, enquiryID,
            notes, callDuration, callType, logType, callStatus, status,
            campaign, origin, enquiryType,
            rejectReason, activityID } = this.state.fields;
        const { show, clientUsers, dealersettings } = this.props;
        const { showReject, titles, arrcallstatus } = this.state

        const isLoggedEnquiry = ((status === callLogStatus.LOGGED || status === callLogStatus.ACCEPTED) && enquiryID) ? true : false;

        const isLoggedBucket = (status === callLogStatus.ACCEPTED && enquiryID) ? true : false;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

        const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
        const setttings = dealersettings && dealersettings.client ? dealersettings.client.settings : {};

        const liteVersion = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.clientSettings) &&
            dealersettings.client.clientSettings.liteVersion) ? true : false);
        return (
            documentID
                ?
                <>
                    <Modal
                        id="engage-request-LOGS"
                        show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-calllogs">

                        <Modal.Header>
                            <Modal.Title>
                                <h5 className="modal-title" ><Translate text={isLoggedEnquiry ? (isLoggedBucket ? 'newInboundLeadReceived' : 'logActivity') : 'engageRequest'} />
                                    {
                                        (status === callLogStatus.ACCEPTED || status === callLogStatus.REJECTED)
                                            ?
                                            <div className="float-right kitchen-modal-status">
                                                <div className={`badge badge-pill badge-${status.trim().toLowerCase()}-fill `}>
                                                    {status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                </h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <div className={`container-fluid`}>
                                <div className="row">

                                    <div className="pop-layout form-style">
                                        <div className="pop-subhead"><h3><Translate text={'contactInfo'} /></h3></div>

                                        {/* <!-- new customer--> */}

                                        {
                                            (!_.isEmpty(contact))
                                                ?
                                                <>
                                                    <div className="form-group">
                                                        <div className="form-readonly-wrap">
                                                            <div className="input-readonly">
                                                                <div className="input-readonly-inner">
                                                                    <div className="calllogs-head">
                                                                        {CommonHelper.displayContactIcon(contact, 'mr-1')}
                                                                        {CommonHelper.displayContact(titles, contact)}
                                                                        {/* {contact.displayID ? <span className='calllogs-contact-id'>({contact.displayID})</span> : <></>} */}
                                                                    </div>
                                                                    {
                                                                        (contact.businessContact && !_.isEmpty(contact.company))
                                                                            ?
                                                                            <div className="calllogs-head">
                                                                                <i className={`ico icon-Contacts mr-1`}></i>
                                                                                {CommonHelper.getFullName([], contact, '')}
                                                                            </div>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        contact.displayID ?
                                                                            (<div className="vehicle-item-info"><i className={`ico icon-enquiry mr-1`}></i>{contact.displayID}</div>) :
                                                                            (<></>)
                                                                    }
                                                                    {(contact.phone || contact.email)
                                                                        ?
                                                                        <div className="calllogs-contact-sub">
                                                                            <ul className="calllogs-contact-details">
                                                                                {contact.phone ? <li> <i className="ico icon-call"></i>{CommonHelper.phoneFormat(contact.phoneCode, contact.phone, dealersettings)}</li> : <></>}
                                                                                {contact.email ? <li> <i className="ico icon-email"></i>{contact.email}</li> : <></>}
                                                                            </ul>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }


                                        {
                                            (!_.isEmpty(enquiry))
                                                ?
                                                <>
                                                    <div className="pop-subhead"><h3> <Translate text={'enquiryInfo'} /></h3></div>
                                                    <div className="calllogs-vehicle-box">

                                                        <div className="flex-vehicle">
                                                            <div className="vehicle-item-image"> <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                <img src={CommonHelper.showBrandLogo(dealersettings, (!_.isEmpty(enquiry?.requirement?.stock) ? enquiry.requirement.stock?.make : enquiry.requirement?.make))} className="img-object-fit-contain" alt="" width="70" height="70" /> </a>
                                                            </div>
                                                            <div className="vehicle-data">
                                                                {
                                                                    enquiry.displayID ?
                                                                        (<div className="vehicle-item-info"><i className={`ico icon-enquiry mr-1`}></i>{enquiry.displayID}</div>) :
                                                                        (<></>)
                                                                }
                                                                {
                                                                    !_.isEmpty(enquiry.requirement) ?
                                                                        (<><div className="vehicle-item-title">{!_.isEmpty(enquiry.requirement.stock) ? (enquiry.requirement.stock.make + ' ' + enquiry.requirement.stock.model) : (enquiry.requirement.make + ' ' + enquiry.requirement.model)}
                                                                            {
                                                                                enquiry.requirement.saleType
                                                                                    ?
                                                                                    <>
                                                                                        <div className="badge badge-pill badge-white ml-1">
                                                                                            {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                enquiry.requirement.saleType, '')}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                    </>
                                                                            }
                                                                        </div></>) :
                                                                        <>
                                                                            {
                                                                                liteVersion && !_.isEmpty(enquiry.td) && !_.isEmpty(enquiry.td.make)
                                                                                    ?
                                                                                    <>
                                                                                        <div className="vehicle-item-title">
                                                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-activity-testdrive')}></i>
                                                                                            {enquiry.td.make + ' ' + enquiry.td.model}
                                                                                            {
                                                                                                enquiry.td.saletype
                                                                                                    ?
                                                                                                    <>
                                                                                                        <div className="badge badge-pill badge-white ml-1">
                                                                                                            {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                                enquiry.td.saletype, '')}
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                    </>
                                                                                            }
                                                                                        </div>

                                                                                    </>
                                                                                    :
                                                                                    <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>
                                                                            }

                                                                        </>
                                                                }

                                                                <div className="vehicle-item-status">
                                                                    <div className="calllogs-vehicle-owner">
                                                                        <div className="owner-view">
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                <img src={CommonHelper.getUserImagebyId(clientUsers, enquiry.owner)} className="img-object-fit" />
                                                                            </a>
                                                                        </div>
                                                                        {CommonHelper.getUserNamebyId(clientUsers, enquiry.owner)}
                                                                    </div>
                                                                    {
                                                                        enquiry.status ? <>
                                                                            {(() => {
                                                                                let __status = (enquiry.status && enquiry.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : enquiry.status;
                                                                                const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
                                                                                let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

                                                                                return <>
                                                                                    {
                                                                                        _objCurrentStatus
                                                                                            ?
                                                                                            <>
                                                                                                <span
                                                                                                    className={`status-${enquiry.status === 'open' ? 'open' : 'empty'} float-right`}
                                                                                                    style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                                                                >
                                                                                                    <Translate
                                                                                                        text={_objCurrentStatus.name}
                                                                                                        upperCase={true}
                                                                                                    />
                                                                                                </span>
                                                                                            </>
                                                                                            :
                                                                                            <>


                                                                                            </>
                                                                                    }
                                                                                </>
                                                                            })()}

                                                                        </>
                                                                            : (<></>)
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }


                                    </div>


                                    <div className="pop-layout form-style pt-4">
                                        <div className="engage-contact-details">

                                            {
                                                (!_.isEmpty(enquiry)) ? <></> : <h3> <Translate text={'Enquiry Info'} /></h3>
                                            }

                                            <table className="table-fill">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={CommonHelper.getStaticFieldName(setttings?.enquiryOptionsStatic, 'enquiryType')} /></td>
                                                        <td className="text-left">{CommonHelper.getNameByValue(setttings.enquiryTypes, enquiryType, '--')}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={CommonHelper.getStaticFieldName(setttings?.enquiryOptionsStatic, 'origin')} /></td>
                                                        <td className="text-left">{CommonHelper.getNameByValue(setttings.origins, origin, '--')}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={CommonHelper.getStaticFieldName(setttings?.enquiryOptionsStatic, 'campaign')} /></td>
                                                        <td className="text-left">{CommonHelper.getNameByValue(setttings.campaigns, campaign, '--')}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>


                                    <div className="pop-layout form-style pt-4">
                                        <div className="engage-contact-details">
                                            <h3> <Translate text={'Log Info'} /></h3>
                                            <table className="table-fill">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={'Log Type'} /></td>
                                                        <td className="text-left">{CommonHelper.getLabelByValue(logTypes, logType, '--')}</td>
                                                    </tr>
                                                    {
                                                        logType === 'call' ? (<>
                                                            <tr>
                                                                <td className="text-left label-text"><Translate text={'Call Type'} /></td>
                                                                <td className="text-left">{CommonHelper.getLabelByValue(callTypes, callType, '--')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left label-text"><Translate text={'callStatus'} /></td>
                                                                <td className="text-left">{CommonHelper.getLabelByValue(arrcallstatus, callStatus, '--')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left label-text"><Translate text={'Call Duration'} /></td>
                                                                <td className="text-left">{CommonHelper.convertsecstostr(callDuration)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left label-text"><Translate text={'Call Summary'} /></td>
                                                                <td className="text-left">{notes ? notes : '--'}</td>
                                                            </tr>
                                                        </>) : (<>
                                                            <tr>
                                                                <td className="text-left label-text"><Translate text={'Notes'} /></td>
                                                                <td className="text-left">{notes ? notes : '--'}</td>
                                                            </tr>
                                                        </>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            showReject
                                                ?
                                                <>
                                                    <div className="engage-request-notes">
                                                        <h3> <Translate text={'rejectReason'} /></h3>
                                                        <InputTextArea
                                                            className={`engage-request-notewrap ${this.state.errors["rejectReason"]}`}
                                                            name="rejectReason"
                                                            onChange={this.handleOnAreaChange}
                                                            value={rejectReason}
                                                            maxLength="500"
                                                            rows="3">
                                                        </InputTextArea>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        {
                            status === callLogStatus.PENDING && !readOnlyEnquiryView
                                ?
                                <>
                                    {
                                        showReject
                                            ?
                                            <>
                                                <Modal.Footer className={`modal-footer ${this.isLoadingReject ? 'form-disable' : ''}`}>

                                                    <button type="button"
                                                        className="btn btn-default float-left"
                                                        onClick={(e) => { e.preventDefault(); this.setState({ showReject: false }) }}>
                                                        <Translate text={'cancel'} />
                                                    </button>
                                                    <button type="button"
                                                        className={`btn btn-primary float-right ${this.isLoadingReject ? 'form-disable' : ''}`}
                                                        onClick={(e) => this.handleRejectSubmit(e)}>
                                                        {this.isLoadingReject ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                        <Translate text={'send'} />
                                                    </button>

                                                </Modal.Footer>
                                            </>
                                            :
                                            <>
                                                <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>

                                                    <button type="button"
                                                        className="btn btn-red float-left"
                                                        onClick={(e) => { e.preventDefault(); this.setState({ showReject: true }) }}
                                                    >
                                                        <Translate text={'reject'} />
                                                    </button>
                                                    <button type="button"
                                                        className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                                                        onClick={(e) => this.handleAcceptSubmit(e)}
                                                    >
                                                        {this.isLoading ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                        <Translate text={'accept'} />
                                                    </button>

                                                </Modal.Footer>
                                            </>
                                    }

                                </>
                                :
                                <>
                                    <Modal.Footer className="modal-footer">

                                        <button type="button"
                                            className={`btn btn-default ${isLoggedEnquiry ? 'float-left' : 'float-right'}`}
                                            onClick={(e) => { e.preventDefault(); this.props.handleClose(); }}>
                                            <Translate text={'close'} />
                                        </button>

                                        {
                                            isLoggedEnquiry
                                                ?
                                                <>
                                                    <button type="button"
                                                        className={`btn btn-info float-right`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.setNotifyClose(true)
                                                            this.props.handleClose();
                                                            this.props.history.push("/enquiry/details/" + enquiryID + (activityID ? '?comments=' + activityID : ''));
                                                        }}>
                                                        <Translate text={'viewEnquiry'} />
                                                    </button>
                                                </>
                                                :
                                                <></>
                                        }

                                    </Modal.Footer>
                                </>
                        }



                    </Modal>
                </>
                :
                <>
                </>
        );
    }
}

