/** LIBRARIES */
import React, { useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import AllActiveDevices from '../subsections/allactivedevices';
import Translate from '../../../../constants/translate';
import DevicesHistory from '../subsections/deviceshistory';
import AuditLogs from '../subsections/auditLogs';
import { UpgradePlan } from '../../../../components'
import CommonHelper from '../../../../services/common';
import _ from 'lodash'


const DeviceLog = props => {
	useEffect(() => {
		function handleResize() {
			document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 100) + 'px');
		}
		window.addEventListener('resize', handleResize);
		document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 100) + 'px');
		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, [])

	return (
		<>
			<h2> {CommonHelper.showLocale(props, 'Security Device Log')}</h2>
			<div className={`settings-tab-wrap tabs`}>
				<div className='tabs-wraper'>
					<nav>
						<Tabs
							defaultActiveKey='changepassword'
							transition={false}
							className='nav-fill'
						>
							<Tab eventKey='changepassword' title={CommonHelper.showLocale(props, 'Active Devices')}>
								<div>
									<AllActiveDevices {...props} />
								</div>
							</Tab>
							<Tab eventKey='authentication' title={CommonHelper.showLocale(props, 'Devices History')}>
								<div>
									{' '}
									<DevicesHistory {...props} />
								</div>
							</Tab>
							<Tab eventKey='auditlog' title={CommonHelper.showLocale(props, 'Audit Log')}>
								<div>
									{' '}
									<AuditLogs {...props} />
								</div>
							</Tab>
						</Tabs>
					</nav>
				</div>
			</div>
		</>
	)
}

export default DeviceLog;
