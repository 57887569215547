import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { collectionData, docData } from 'rxfire/firestore';
import ContactFields from './contactFields'
import BusinessContactFields from './businessContactFields'
import CompanyFields from './companyFields'
import EnquiryFields from './enquiryFields'
import FleetFields from './fleetFields'
import StockFields from './stockFields'
import RequirementFields from './requirementFields'
import TestDriveFields from './testdriveFields'
import TradeInFields from './tradeinFields'
import FinanceFields from './financeFields'
import FrontDeskFields from './frontdeskFields'
import { TemplateContext } from '../templateContext'
import { allModules, levelOptions } from './viewModel'
import AddFieldSettings from './addFieldSettings'
import { PopUpModal, PopoverPanel, UpgradePlan } from '../../../../components'
import RequiredFields from './requiredFields'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import CallLogFields from './calllogFields';

const FieldSettings = (props) => {

    const [loader, setLoader] = useState(true);
    const [fields, setFields] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [selectedClient, setSelectedClient] = useState('')
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [showModel, setShowmodel] = useState(false)
    const [selectedField, setSelectedField] = useState(null)
    const [activeModule, setActiveModule] = useState(null)
    const [selectedLevel, setSelectedLevel] = useState(null)
    const [requiredPopOver, setRequiredPopOver] = useState({ show: false, target: null, field: null })
    const { clients, groups, regions } = useContext(TemplateContext);
    const [enquiryOptionsDF, setEnquiryOptionsDF] = useState();
    const [tradeinOptionsDF, setTradeinOptionsDF] = useState();
    const [enquiryStatus, setEnquiryStatus] = useState([]);
    const [financeStatus, setFinanceStatus] = useState([]);
    const [afterMarketStatus, setafterMarketStatus] = useState([]);
    const [filesCategory, setfilesCategory] = useState([]);
    const allDynamicFieldNames = ['enquiryOptionsDF', 'tradeinOptionsDF', 'stockOptionsDF']
    const pipelineModules = (props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.pipelineModules) ? props.dealersettings.client.settings.pipelineModules : [];
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) &&
        !Boolean(props.dealersettings.superAdmin) ? true : false);
    useEffect(() => {
        setSelectedLevel({
            'level': props.dealersettings.level,
            'levelID': props.dealersettings.level === 'oem' ? props.dealersettings.oemID :
                props.dealersettings.level === 'region' ? props.dealersettings.regionID :
                    props.dealersettings.level === 'group' ? props.dealersettings.groupID :
                        props.dealersettings.client.id,
            'region': (props.dealersettings.level === 'group' || props.dealersettings.level === 'individual') ? props.dealersettings.client.region : '',
            'group': props.dealersettings.level === 'individual' ? props.dealersettings.client.group : '',
            'levelName': props.dealersettings.level === 'region' ? (_.find(regions, { code: props.dealersettings.client.region }) ? _.find(regions, { code: props.dealersettings.client.region }).name : '') :
                props.dealersettings.level === 'group' ? (_.find(groups, { documentID: props.dealersettings.client.group }) ? _.find(groups, { documentID: props.dealersettings.client.group }).name : '') :
                    props.dealersettings.level === 'individual' ? (_.find(clients, { documentID: props.dealersettings.client.id }) ? _.find(clients, { documentID: props.dealersettings.client.id }).name : '') : ''
        })
    }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true })
            });
            setClientOptions(_clients)
            if (_.isEmpty(selectedClient)) {
                setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }))
            }
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return


        setRegionOptions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))
        setGroupOptions(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    regionID: _.find(clients, { "group": rec.documentID }) ? _.find(clients, { "group": rec.documentID }).region : ''
                }
            }))

    }, [clientOptions, groups, regions])

    useEffect(() => {
        if (_.isEmpty(selectedLevel) || _.isEmpty(selectedLevel.levelID)) {
            return;
        }
        var allfieldsettings = combineLatest(docData(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`)),
            (selectedLevel.level !== 'oem' ?
                docData(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${selectedLevel.level === 'region' ? selectedLevel.levelID : (!_.isEmpty(selectedLevel.region) ? selectedLevel.region : 'region')}`))
                : of({})),
            (selectedLevel.level !== 'oem' && selectedLevel.level !== 'region' ?
                docData(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${selectedLevel.level === 'group' ? selectedLevel.levelID : (!_.isEmpty(selectedLevel.group) ? selectedLevel.group : 'group')}`))
                : of({})),
            (selectedLevel.level !== 'oem' && selectedLevel.level !== 'region' && selectedLevel.level !== 'group' ?
                docData(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${selectedLevel.level === 'individual' ? selectedLevel.levelID : 'client'}`))
                : of({})))
            .pipe(
                map((fields) => {
                    // console.log('fields', fields)
                    // Destructure the values to combine a single array.
                    var [oemFields, regionFields, groupFields, clientFields] = fields;
                    return [
                        //spread the arrays out to combine as one array
                        { ...oemFields, id: oemFields.documentID, 'level': 'oem' },
                        { ...regionFields, id: regionFields.documentID, 'level': 'region' },
                        { ...groupFields, id: groupFields.documentID, 'level': 'group' },
                        { ...clientFields, id: clientFields.documentID, 'level': 'individual' }
                    ];

                }))
            .subscribe(allFields => {
                var _fields = [];
                allFields.forEach(rec => { _.isArray(rec) ? rec.forEach(e => _fields.push(e)) : _fields.push(rec) })
                let fieldsDoc = [];
                let pipelineDoc = [];
                let enqStatus = [];
                let finStatus = [];
                let amStatus = [];
                let _filesCategory = props?.dealersettings?.client?.settings?.filesCategory || [];
                _fields.forEach(rec => {
                    var f = { 'levelID': rec.documentID, 'levelName': rec.name, 'level': rec.level }
                    allModules.forEach(module => {
                        if (rec['mandatoryFields'] && rec['mandatoryFields'][module]) {
                            f = {
                                ...f,
                                [module]: rec['mandatoryFields'][module]
                            }
                        }
                    })
                    fieldsDoc.push(f)
                })
                _fields.forEach(rec => {
                    if (rec['pipelines']) {
                        rec['pipelines'].forEach((_lookups) => {
                            pipelineDoc.push({
                                ..._lookups,
                                'levelID': rec.documentID,
                                'levelName': rec.name,
                                'level': rec.level
                            })
                        })
                    }
                    if (rec['enquiryStatus']) {
                        rec['enquiryStatus'].forEach((_lookups) => {
                            enqStatus.push({
                                ..._lookups
                            })
                        })
                    }
                    if (rec['financeStatus']) {
                        rec['financeStatus'].forEach((_lookups) => {
                            finStatus.push({
                                ..._lookups
                            })
                        })
                    }
                    if (rec['afterMarketStatus']) {
                        rec['afterMarketStatus'].forEach((_lookups) => {
                            amStatus.push({
                                ..._lookups
                            })
                        })
                    }
                    if (rec['filesCategory']) {
                        _filesCategory = [];
                        rec['filesCategory'].forEach((_lookups) => {
                            _filesCategory.push({
                                ..._lookups
                            })
                        })
                    }
                })
                let settingsDoc = {};
                _fields.forEach(rec => {
                    allDynamicFieldNames.forEach(field => {
                        if (rec[field]) {
                            rec[field].forEach((_lookups) => {
                                if (settingsDoc[field]) {
                                    settingsDoc[field] = [
                                        ...settingsDoc[field],
                                        {
                                            ..._lookups,
                                            'levelID': rec.id,
                                            'levelName': rec.name,
                                            'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                            'levelIndex': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).index : 1,
                                            'documentID': _lookups.value,
                                            'disableEdit': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                                (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
                                        }
                                    ]
                                }
                                else {
                                    settingsDoc[field] = [{
                                        ..._lookups,
                                        'levelID': rec.id,
                                        'levelName': rec.name,
                                        'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                        'levelIndex': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).index : 1,
                                        'documentID': _lookups.value,
                                        'disableEdit': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                            (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
                                    }]
                                }
                            })
                        }
                        else if (_.isEmpty(settingsDoc[field])) {
                            settingsDoc[field] = [];
                        }
                    })
                })
                setEnquiryOptionsDF(settingsDoc ? settingsDoc.enquiryOptionsDF : []);
                setTradeinOptionsDF(settingsDoc ? settingsDoc.tradeinOptionsDF : []);
                setFields(fieldsDoc)
                setPipelines(pipelineDoc)
                if (enqStatus.length > 0) {
                    setEnquiryStatus(enqStatus);
                }
                if (finStatus.length > 0) {
                    setFinanceStatus(finStatus);
                }
                if (amStatus.length > 0) {
                    setafterMarketStatus(amStatus);
                }
                if (_filesCategory.length > 0) {
                    setfilesCategory(_filesCategory);
                }
                setLoader(false)
            });
        return () => {
            allfieldsettings && allfieldsettings.unsubscribe()
        }
    }, [selectedLevel])

    const handleModelClose = () => {
        setShowmodel(false)
    }

    const handlesetloading = (val) => {
        setLoader(val)
    }

    const handlesetfield = (name, module) => {
        setActiveModule(module)
        setSelectedField(name);
        setShowmodel(true)
    }

    const handlesetLevel = (e, data) => {
        if (data.name === 'level') {
            if (e.value === 'oem')
                setLoader(true)
            setSelectedLevel({
                ...selectedLevel,
                [data.name]: e.value,
                ['levelID']: e.value === 'oem' ? props.dealersettings.client.settingsID : ''
            });
        }
        else {
            setSelectedLevel({
                ...selectedLevel,
                [data.name]: e.value,
                'region': !_.isEmpty(e.regionID) ? e.regionID : !_.isEmpty(e.data.region) ? e.data.region : '',
                'group': !_.isEmpty(e.data.group) ? e.data.group : '',
                ['levelName']: e.label,
            });
            if (data.name === 'levelID') {
                setLoader(true)
            }
        }
    }

    const handlesetRequired = (e, field, module) => {
        setActiveModule(module)
        setRequiredPopOver({
            show: true,
            target: e.target,
            field: field
        });
        e.preventDefault()
    }

    return (<>
        {
            liteVersion && !loader ? (<UpgradePlan />) : (<></>)
        }
        <h2> <Translate text={'Field Settings'} /> </h2>
        <div className={`settings-tab-wrap tabs ${liteVersion ? 'form-lite' : ''}`}>
            <div className='tabs-wraper'>
                <nav>
                    <Tabs
                        defaultActiveKey='contacts'
                        className='nav-fill'
                    >
                        <Tab eventKey='contacts' title={CommonHelper.showLocale(props, 'contacts')}>
                            <ContactFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                pipelines={pipelines}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                            />
                        </Tab>
                        <Tab eventKey='businessContact' title={CommonHelper.showLocale(props, 'businessContact')}>
                            <BusinessContactFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                pipelines={pipelines}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                            />
                        </Tab>
                        <Tab eventKey='company' title={CommonHelper.showLocale(props, 'Company')}>
                            <CompanyFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                pipelines={pipelines}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                            />
                        </Tab>
                        <Tab eventKey='enquiry' title={CommonHelper.showLocale(props, 'Enquiry')}>
                            <EnquiryFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                pipelines={pipelines}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                                enquiryOptionsDF={enquiryOptionsDF}
                                filesCategory={filesCategory}
                            />
                        </Tab>
                        <Tab eventKey='fleet' title={CommonHelper.showLocale(props, 'Loan Vehicle')}>
                            <FleetFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                pipelines={pipelines}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                            />
                        </Tab>
                        <Tab eventKey='requirement' title={CommonHelper.showLocale(props, 'requirement')}>
                            <RequirementFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                pipelines={pipelines}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                            />
                        </Tab>
                        <Tab eventKey='stock' title={CommonHelper.showLocale(props, 'stock')}>
                            <StockFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                pipelines={pipelines}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                            />
                        </Tab>
                        {
                            _.isEmpty(pipelineModules) || pipelineModules.includes('testDrive') ? (<Tab eventKey='testdrive' title={CommonHelper.showLocale(props, 'Test Drive')}>
                                <TestDriveFields
                                    {...props}
                                    loader={loader}
                                    fields={fields}
                                    pipelines={pipelines}
                                    selectedClient={selectedClient}
                                    groupOptions={groupOptions}
                                    clientOptions={clientOptions}
                                    regionOptions={regionOptions}
                                    setloading={handlesetloading}
                                    setfield={handlesetfield}
                                    selectedLevel={selectedLevel}
                                    setLevel={handlesetLevel}
                                    setRequired={handlesetRequired}
                                />
                            </Tab>) : (<></>)
                        }
                        {
                            _.isEmpty(pipelineModules) || pipelineModules.includes('tradeIn') ? (<Tab eventKey='tradein' title={CommonHelper.showLocale(props, 'tradeIn')}>
                                <TradeInFields
                                    {...props}
                                    loader={loader}
                                    fields={fields}
                                    pipelines={pipelines}
                                    selectedClient={selectedClient}
                                    groupOptions={groupOptions}
                                    clientOptions={clientOptions}
                                    regionOptions={regionOptions}
                                    setloading={handlesetloading}
                                    setfield={handlesetfield}
                                    selectedLevel={selectedLevel}
                                    setLevel={handlesetLevel}
                                    setRequired={handlesetRequired}
                                    tradeinOptionsDF={tradeinOptionsDF}
                                />
                            </Tab>) : (<></>)
                        }
                        {
                            !_.isEmpty(props.dealersettings.client.moduleSettings) &&
                                !_.isEmpty(props.dealersettings.client.moduleSettings.finance) &&
                                props.dealersettings.client.moduleSettings.finance.enabled === true ?
                                (
                                    <Tab eventKey='finance' title={CommonHelper.showLocale(props, 'finance')}>
                                        <FinanceFields
                                            {...props}
                                            loader={loader}
                                            fields={fields}
                                            pipelines={pipelines}
                                            selectedClient={selectedClient}
                                            groupOptions={groupOptions}
                                            clientOptions={clientOptions}
                                            regionOptions={regionOptions}
                                            setloading={handlesetloading}
                                            setfield={handlesetfield}
                                            selectedLevel={selectedLevel}
                                            setLevel={handlesetLevel}
                                            setRequired={handlesetRequired}
                                        />
                                    </Tab>
                                )
                                : (<></>)
                        }
                        {
                            !_.isEmpty(props.dealersettings.client.moduleSettings) &&
                                !_.isEmpty(props.dealersettings.client.moduleSettings.frontdesk) &&
                                props.dealersettings.client.moduleSettings.frontdesk.enabled === true ?
                                (
                                    <Tab eventKey='frontdesk' title={CommonHelper.showLocale(props, 'Front Desk')}>
                                        <FrontDeskFields
                                            {...props}
                                            loader={loader}
                                            fields={fields}
                                            pipelines={pipelines}
                                            selectedClient={selectedClient}
                                            groupOptions={groupOptions}
                                            clientOptions={clientOptions}
                                            regionOptions={regionOptions}
                                            setloading={handlesetloading}
                                            setfield={handlesetfield}
                                            selectedLevel={selectedLevel}
                                            setLevel={handlesetLevel}
                                            setRequired={handlesetRequired}
                                        />
                                    </Tab>
                                )
                                : (<></>)
                        }
                        <Tab eventKey='callLog' title={CommonHelper.showLocale(props, 'callLog')}>
                            <CallLogFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                pipelines={pipelines}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                            />
                        </Tab>
                    </Tabs>
                </nav>
            </div>
        </div>
        <PopUpModal show={showModel}>
            <AddFieldSettings
                {...props}
                show={showModel}
                title={'Edit Field'}
                handleClose={handleModelClose}
                groupOptions={groupOptions}
                clientOptions={clientOptions}
                regionOptions={regionOptions}
                fields={fields}
                selectedField={selectedField}
                pipelines={pipelines}
                activeModule={activeModule}
                selectedClient={selectedClient}
                enquiryStatus={enquiryStatus}
                financeStatus={financeStatus}
                afterMarketStatus={afterMarketStatus}
            />
        </PopUpModal>
        <PopoverPanel
            showpopover={requiredPopOver.show}
            targetpopover={requiredPopOver.target}
            title={'Required Fields'}
            position="left"
            className="popover-required-fields"
            closepopover={() => {
                setRequiredPopOver({ show: false, target: '', field: '' });
            }}>
            <RequiredFields
                {...props}
                fields={fields}
                fieldName={requiredPopOver.field}
                activeModule={activeModule}
                pipelines={pipelines}
                enquiryStatus={enquiryStatus}
                financeStatus={financeStatus}
                afterMarketStatus={afterMarketStatus}
            />
        </PopoverPanel>
    </>);
}

export default FieldSettings;