import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment'
import _ from 'lodash'
import Translate from '../../../constants/translate';
import { objEnquiry, objActivitylog } from '../../pipeline/viewModel';
import { ContentOverlay, PopUpModal } from '../../../components'
import AddActivity from '../../activity'
import AcitivityReducer from '../../calendar/activityReducer'
import { activityListVM } from '../../calendar/viewModel'
import CommonHelper from '../../../services/common';
import EnquiryInfo from '../../pipeline/enquiryInfo'
import { firestoreDB } from '../../../services/helper';

const ContactActivityView = (props) => {
    const [activeScheduledTab, setActiveScheduledTab] = useState('Activities')
    const [activitylogs, dispatch] = useReducer(AcitivityReducer, { Activities: [], Enquiries: [] })
    const [checkDataLoad, setDataload] = useState({ "Activities": true, "Enquiries": true })
    const [activityLoader, setLoader] = useState({ "Activities": true, "Enquiries": true })
    const [showActivityModel, setShowActivityModel] = useState(false)
    const [newActivitylog, setNewActivitylog] = useState(null);
    const [enquiryObj, setEnquiryObj] = useState(null);
    const [contactObj, setContactObj] = useState(null);
    const [stockObj, setStockObj] = useState(null);
    const [isNewActivity, setNewActivity] = useState(false);
    const [activityHeader, setActivityHeader] = useState('Add New Activity')
    const [titles, setTitles] = useState([]);
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])

    useEffect(() => {
        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            setDataload({
                ...checkDataLoad,
                [activeScheduledTab]: false
            })
            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            setCampaigns(_campaigns);
            setOrigins(_origins);
            setTitles(_titles);
            setLanguages(_languages);
            setNationalities(_nationalities)
            setDataload({
                ...checkDataLoad,
                [activeScheduledTab]: true
            })
        }
    }, [props.dealersettings])
    useEffect(() => {

        return () => {
            //console.log('page un mount')
            window.unSubscriveActivities && window.unSubscriveActivities();
            window.unsubscribeEnquiry && window.unsubscribeEnquiry();
        }
    }, [])

    useEffect(() => {
        setDataload({
            ...checkDataLoad,
            [activeScheduledTab]: true
        })
        //console.log('userID', props.userID)
    }, [props.userID])

    useEffect(() => {

        if (!props.contactID && activeScheduledTab === 'Enquiries') {
            //console.log('contactID', props.contactID)
            setActiveScheduledTab('Activities');
        }

        setDataload({
            ...checkDataLoad,
            [activeScheduledTab]: true
        })
        //console.log('contactID', props.contactID)
    }, [props.contactID])

    useEffect(() => {

        if (!checkDataLoad[activeScheduledTab]) {
            return;
        }
        if (!props.userID) {
            //console.log('props.userID', props.userID)
            window.unSubscriveActivities && window.unSubscriveActivities();
            return;
        }

        if (!props.contactID && activeScheduledTab === 'Enquiries') {
            //console.log('props.contactID', props.contactID)
            window.unSubscriveActivities && window.unSubscriveActivities();
            return;
        }

        //console.log('page mount Load', props.contactID, props.userID)

        if (activeScheduledTab === 'Activities') {
            let refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('activities')
                .where('searchUsers', 'array-contains', props.userID)
                .where('clientID', '==', props.dealersettings.client.id)
                .where('type', '==', 'activity')
                .where('isDeleted', '==', false)
                .where('isDone', '==', false)
                .where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
                .orderBy('startDate', 'desc')
            //.orderBy('startDate', 'asc')
            //.orderBy('documentID', 'asc')

            window.unSubscriveActivities = refActivitiesData
                .onSnapshot(onCollectionUpdate);

        }
        else {
            window.unsubscribeEnquiry = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('contact.documentID', '==', props.contactID)
                //.orderBy('modifiedDate', 'desc')
                .where('isDeleted', '==', false)
                .onSnapshot(onEnquiryCollectionUpdate);
        }

        setDataload({
            ...checkDataLoad,
            [activeScheduledTab]: false
        })
        setLoader({
            ...activityLoader,
            [activeScheduledTab]: false
        })

    }, [checkDataLoad, activeScheduledTab, props])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertLogVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })
        let _activities = [];
        if (!actionType) {
            querySnapshot.forEach(function (doc) {
                var logVM = convertLogVM({
                    ...doc.data(),
                    documentID: doc.id
                });
                _activities.push(logVM)
            });
        }
        dispatch({
            type: actionType ? actionType : "SUCCESS_LIST",
            data: actionType ? snapshotDoc : _.orderBy(_activities, ["startDate"], ["asc"]),
            activetab: activeScheduledTab
        });
        setDataload({
            ...checkDataLoad,
            [activeScheduledTab]: false
        })
        setLoader({
            ...activityLoader,
            [activeScheduledTab]: false
        })

    }

    const onEnquiryCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = Object.assign({}, objEnquiry);
                const objEnquiryData = Object.assign({}, change.doc.data());
                for (let [key, value] of Object.entries(objEnquiryData)) {
                    snapshotDoc[key] = value;
                }
                snapshotDoc.documentID = snapshotDoc.id;

                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })

        let enquiries = [];
        if (!actionType) {
            querySnapshot.forEach((_enquiry) => {
                const enquiry = Object.assign({}, objEnquiry);
                const objEnquiryData = Object.assign({}, _enquiry.data());
                for (let [key, value] of Object.entries(objEnquiryData)) {
                    enquiry[key] = value;
                }
                enquiry.documentID = _enquiry.id;
                enquiries.push(enquiry)
            });
        }

        dispatch({
            type: actionType ? actionType : "SUCCESS_LIST",
            data: actionType ? snapshotDoc : _.orderBy(enquiries, ["modifiedDate"], ["desc"]),
            activetab: activeScheduledTab
        });
        setDataload({
            ...checkDataLoad,
            [activeScheduledTab]: false
        })
        setLoader({
            ...activityLoader,
            [activeScheduledTab]: false
        })
    }

    const convertLogVM = (doc) => {
        let dealersettings = props.dealersettings;
        const objlogData = Object.assign({}, doc);
        const logVM = Object.assign({}, activityListVM);
        for (let [key, value] of Object.entries(objlogData)) {
            if (logVM.hasOwnProperty(key))
                logVM[key] = value;
        }
        if (!doc.startDate) {
            doc.startDate = doc.addedDate
        }

        if (!doc.endDate) {
            doc.endDate = doc.startDate
        }
        logVM.dueDate = moment.unix(doc.startDate.seconds).format('DD/MM/YYYY');
        logVM.dueTime = moment.unix(doc.startDate.seconds).format('hh:mm A') + ' - ' + moment.unix(doc.endDate.seconds).format('hh:mm A');

        logVM.activityCompletedDate = doc.completedDate ? moment.unix(doc.completedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        logVM.activityCompletedBy = CommonHelper.getUserNamebyId(props.clientUsers, doc.completedBy);

        if (!_.isEmpty(doc.enquiry)) {
            logVM.enquiryStatus = doc.enquiry.status;
        }
        if (!_.isEmpty(doc.stock)) {
            logVM.testdriveModel = doc.stock.make + ' ' + doc.stock.model;
        }
        if (!_.isEmpty(doc.contact)) {
            logVM.contactName = CommonHelper.displayFullContact(titles, doc.contact);
            logVM.contactPhone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings);
            logVM.phone = doc.contact.phone ? (doc.contact.phoneCode ? doc.contact.phoneCode : '') + doc.contact.phone : '';
            logVM.contactEmail = doc.contact.email;
            logVM.licenseNo = doc.contact.licenseNo;
            logVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
            logVM.contactType = doc.contact.contactType;
            logVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
            logVM.gender = doc.contact.gender;
            logVM.maritalStatus = doc.contact.maritalStatus;
            logVM.contactMethod = doc.contact.contactMethod;
            logVM.licenseType = doc.contact.licenseType;
            logVM.language = CommonHelper.getLabelByValue(languages, doc.contact.language, '');
            logVM.campaign = CommonHelper.getLabelByValue(campaigns, doc.contact.campaign, '');
            logVM.origin = CommonHelper.getLabelByValue(origins, doc.contact.origin, '');
            logVM.nationality = CommonHelper.getLabelByValue(nationalities, doc.contact.nationality, '');

            
            if (dealersettings && dealersettings.client && dealersettings.client.settings) {
                logVM.gender = CommonHelper.getNameByValue(dealersettings.client.settings.genders, doc.contact.gender, '');
                logVM.maritalStatus = CommonHelper.getNameByValue(dealersettings.client.settings.maritalStatus, doc.contact.maritalStatus, '');
                logVM.contactMethod = CommonHelper.getNameByValue(dealersettings.client.settings.contactMethod, doc.contact.contactMethod, '');
                logVM.interests = CommonHelper.getLabelByMultipleValues(dealersettings.client.settings.interests, doc.contact.interests, '', true);
                logVM.licenseType = CommonHelper.getNameByValue(dealersettings.client.settings.licenseType, doc.contact.licenseType, '');
            }

            logVM.address = doc.contact.address;
            logVM.companyName = doc.contact.companyName;
            logVM.designation = doc.contact.designation;

            logVM.licenseState = doc.contact.licenseState;
            logVM.secondaryContactName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
            logVM.secondaryContactPhone = CommonHelper.phoneFormat(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone, props.dealersettings);
            logVM.secondaryPhone = doc.contact.secondaryPhone ? ((doc.contact.secondaryPhoneCode ? doc.contact.secondaryPhoneCode : '') + doc.contact.secondaryPhone) : '';
            logVM.secondaryContactEmail = doc.contact.secondaryEmail;
            logVM.contactCreatedBy = CommonHelper.getUserNamebyId(props.clientUsers, doc.contact.addedBy);
            logVM.contactModifiedby = CommonHelper.getUserNamebyId(props.clientUsers, doc.contact.modifiedBy);
            logVM.contactCreatedOn = doc.contact.addedDate ? moment.unix(doc.contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            logVM.contactModifiedOn = doc.contact.modifiedDate ? moment.unix(doc.contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            logVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
            logVM.optinPhone = doc.contact.optinPhone === null ? '' : (doc.contact.optinPhone ? 'Yes' : 'No');
            logVM.optinEmail = doc.contact.optinEmail === null ? '' : (doc.contact.optinEmail ? 'Yes' : 'No');
            logVM.optinPost = doc.contact.optinPost === null ? '' : (doc.contact.optinPost ? 'Yes' : 'No');
            logVM.optinSMS = doc.contact.optinSMS === null ? '' : (doc.contact.optinSMS ? 'Yes' : 'No');
            logVM.marketingOptinEmail = doc.contact.marketingOptinEmail === null ? '' : (doc.contact.marketingOptinEmail ? 'Yes' : 'No');
            logVM.marketingOptinSMS = doc.contact.marketingOptinSMS === null ? '' : (doc.contact.marketingOptinSMS ? 'Yes' : 'No');
        }
        return logVM
    }

    const handleEditActivity = (id) => {
        const editLog = activitylogs[activeScheduledTab].find(item => item.documentID === id);
        const logVM = Object.assign({}, objActivitylog);
        for (let [key, value] of Object.entries(editLog)) {
            if (logVM[key] !== undefined)
                logVM[key] = value;
        }
        setNewActivitylog(logVM)
        setEnquiryObj(editLog.enquiry)
        setContactObj(editLog.contact)
        setStockObj(editLog.stock)
        setNewActivity(false)
        setActivityHeader('Edit Activity')
        setShowActivityModel(true)
    }

    const handleActivityModelDelete = (objLog) => {
        setEnquiryObj(null)
        setContactObj(null)
        setNewActivitylog(null);
        setShowActivityModel(false)
        if (!_.isEmpty(objLog)) {
            dispatch({
                type: "REMOVE_LIST",
                data: objLog,
                activetab: activeScheduledTab
            });
        }
    }

    const handleActivityModelClose = (objLog) => {
        setEnquiryObj(null)
        setContactObj(null)
        setStockObj(null)
        setNewActivitylog(null);
        setShowActivityModel(false)
    }

    const handleActionClick = (id) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            handleEditActivity(id)
        }}>
            <i className="ico icon-edit"></i>
        </div>)
    }

    const handleContactdetailsClick = (e, id) => {
        e.preventDefault();
        handleEditActivity(id)
    }

    const sidepanelOpen = () => {

    }

    return (
        <>
            <div className="engage-contact-middle">

                <div className="tabber-nav engage-contact-tabber" style={props.contactID ? {} : { display: 'none' }}>
                    <ul>
                        <li><a href="#" className={`${activeScheduledTab === 'Activities' ? 'active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveScheduledTab('Activities');
                            }}><Translate text={'todaysActivities'} /> </a></li>
                        <li><a href="#" className={`${activeScheduledTab === 'Enquiries' ? 'active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveScheduledTab('Enquiries')
                            }}
                        > <Translate text={'enquiries'} /> </a></li>
                    </ul>
                </div>

                {
                    (!props.contactID)
                        ?
                        <div className="engage-today-title"><Translate text={'todaysActivities'} /> </div>
                        :
                        <></>
                }



                {
                    activityLoader[activeScheduledTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        activitylogs[activeScheduledTab] && activitylogs[activeScheduledTab].length > 0 ? (
                            <>
                                {
                                    (activeScheduledTab === 'Activities')
                                        ?
                                        <>
                                            <div className="engage-activity-item">
                                                {
                                                    activitylogs[activeScheduledTab].map((log, index) => {
                                                        return (
                                                            <div key={index} className="engage-activity-loop">
                                                                {/* <div className="engage-activity-more">
                                                                <a href="#"><i className="ico icon-more"></i></a>
                                                            </div> */}

                                                                <div className="engage-activity-list">
                                                                    <div className="engage-activity-title">
                                                                        <div className="quickview-activity-icon">
                                                                            <i className={`ico ${CommonHelper.activityIconByValue(props.dealersettings, log.subType)}`}></i>
                                                                        </div>

                                                                        <a href="#" onClick={(e) => { e.preventDefault(); handleEditActivity(log.documentID) }}>{log.title}</a>
                                                                    </div>

                                                                    <div className="engage-activity-date">{moment.unix(log.startDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId(props.clientUsers, log.owner)}</div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                        :
                                        <>
                                            {
                                                activitylogs[activeScheduledTab].map((_enquiry, index) => {
                                                    return (
                                                        <EnquiryInfo
                                                            key={index}
                                                            enquiry={_enquiry}
                                                            dealersettings={props.dealersettings}
                                                            clientUsers={props.clientUsers}
                                                            groupUsers={props.groupUsers}
                                                            sidepanelOpen={sidepanelOpen}
                                                            history={props.history}
                                                            showMore={false}
                                                        >
                                                        </EnquiryInfo>
                                                    )
                                                })
                                            }
                                        </>
                                }
                            </>
                        ) : (
                            <div className="engage-activity-item">
                                <div className="common-table">
                                    <div className="text-center p-5">
                                        <p><Translate text={`No ${activeScheduledTab.toLocaleLowerCase()} found.`} /></p>

                                    </div>
                                </div>
                            </div>
                        )
                }


            </div>

            <PopUpModal show={showActivityModel}>
                <AddActivity
                    show={showActivityModel}
                    handleClose={handleActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={activityHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={newActivitylog}
                    enquiry={enquiryObj}
                    contact={contactObj}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={isNewActivity}
                >
                </AddActivity>
            </PopUpModal>
        </>
    )
}

export default ContactActivityView;