import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../constants/translate';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { allrecipientFields } from './viewModel';
import Report from './campaignReport';
import CampaignDetails from './campaignDetails'

const CampaignPreview = props => {

	const [contacts, setContacts] = useState([]);
	const [contactsLoader, setContactsLoader] = useState(true);
	const [recipientLoader, setRecipientLoader] = useState(true);

	const [campaign, setCampaign] = useState(props.campaign);
	const [activeTab, setActiveTab] = useState('detail');
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 380,
		windowHeight: window.innerHeight - 273,
	});
	const [checkDataLoad, setDataload] = useState(true)
	const [pageNum, setpageNum] = useState(0)
	const [tableLoader, settableLoader] = useState(false)
	const pageLimit = 20;
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 380,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			let overview = {};
			const payload = {
				id: props.campaign['ID'],
				listID: props?.campaign?.ContactsListID,
				settingsID: props.dealersettings.oemID
			}
			if (props.dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && props.dealersettings.oemID) {
				payload.oemID = props.dealersettings.oemID;
			}
			else if (props.dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && props.dealersettings.regionID) {
				payload.regionID = props.dealersettings.regionID;
			}
			else if (props.dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && props.dealersettings.groupID) {
				payload.groupID = props.dealersettings.groupID;
			}
			else {
				payload.clientID = props.dealersettings.client.id
			}
			const recipientsCount = await axios.post(
				`${props.apiUrl}mailjet-api/api/mailjet/overview-campaign`,
				payload,
			);
			if (recipientsCount?.data?.success) {
				const overview = recipientsCount.data.data;
				let perCalc = {};
				perCalc.delivered = `${overview?.Total > 0 && overview?.MessageSentCount > 0 ? Math.round((100 / overview.Total) * overview.MessageSentCount) : 0}%`
				perCalc.blocked = `${overview?.Total > 0 && overview?.MessageBlockedCount > 0 ? Math.round((100 / overview.Total) * overview.MessageBlockedCount) : 0}%`
				perCalc.softbounced = `${overview?.Total > 0 && overview?.MessageSoftBouncedCount > 0 ? Math.round((100 / overview.Total) * overview.MessageSoftBouncedCount) : 0}%`
				perCalc.hardbounced = `${overview?.Total > 0 && overview?.MessageHardBouncedCount > 0 ? Math.round((100 / overview.Total) * overview.MessageHardBouncedCount) : 0}%`
				perCalc.retrying = `${overview?.Total > 0 && overview?.MessageQueuedCount > 0 ? Math.round((100 / overview.Total) * overview.MessageQueuedCount) : 0}%`
				setCampaign({
					...campaign,
					overview,
					perCalc
				})
			}
			setRecipientLoader(false)
		}
		fetchData()
	}, []);

	useEffect(() => {
		if (!checkDataLoad) {
			return;
		}
		const fetchRecipients = async () => {
			const payload = {
				limit: pageLimit,
				offset: pageNum,
				listID: props?.campaign?.ContactsListID,
				settingsID: props.dealersettings.oemID
			}
			if (props.dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && props.dealersettings.oemID) {
				payload.oemID = props.dealersettings.oemID;
			}
			else if (props.dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && props.dealersettings.regionID) {
				payload.regionID = props.dealersettings.regionID;
			}
			else if (props.dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && props.dealersettings.groupID) {
				payload.groupID = props.dealersettings.groupID;
			}
			else {
				payload.clientID = props.dealersettings.client.id
			}
			const recipientsCount = await axios.post(
				`${props.apiUrl}mailjet-api/api/mailjet/list-contact`,
				payload
			);
			if (recipientsCount?.data?.success) {
				setContacts([
					...contacts,
					...recipientsCount?.data?.data?.map(rec => {
						return {
							name: rec.Name,
							email: rec.Email,
							sendTime: campaign.DeliveredAt ? moment(campaign.DeliveredAt).tz(props.timezone).format('DD/MM/YYYY hh:mm A') : ''
						}
					})
				])
			}
			setContactsLoader(false)
			setDataload(false)
			settableLoader(false)
		}
		fetchRecipients()
	}, [checkDataLoad])

	const handleActionClick = () => {

	}
	const handleRowSingleClick = () => {

	}

	return (
		<Modal
			show={props.show}
			backdrop='static'
			backdropClassName={'overlay-modal active'}
			dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
		>
			<div className='modal-content modal-content-fullscreen'>
				<Modal.Header className='modal-header-fullscreen'>
					<div className='modal-title'>
						{' '}
						<Translate text={props.title} />
					</div>
					<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
						<button
							type='button'
							className='close close-fullscreen float-right'
							onClick={() => {
								props.handleClose();
							}}
						>
							×
						</button>
					</div>
				</Modal.Header>
				<Modal.Body className='modal-body-fullscreen'>
					<div className='popup-wrap-fullscreen h-100'>
						{campaign && (

							<div className='container-fluid pl-0 '>
								{recipientLoader ? (
									<div className='loader-center-screen'>
										<div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
									</div>
								) : activeTab === 'detail' ? (
									<CampaignDetails
										setActiveTab={setActiveTab}
										campaign={campaign}
										recipients={[]}
										windowSize={windowSize}
										allrecipientFields={allrecipientFields}
										handleActionClick={handleActionClick}
										handleRowSingleClick={handleRowSingleClick}
										activeTab={activeTab}
										tableLoader={tableLoader}
										contactsLoader={contactsLoader}
										contacts={contacts}
										settableLoader={(val) => settableLoader(val)}
										setpageNum={(val) => setpageNum(val)}
										pageLimit={pageLimit}
										setDataload={val => setDataload(val)}
										timezone={props.timezone}
										pageNum={pageNum}
									/>
								) : (
									<Report
										setActiveTab={setActiveTab}
										campaign={campaign}
										activeTab={activeTab}
										timezone={props.timezone}

									/>
								)}
							</div>
						)}
					</div>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default CampaignPreview;
