/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import "../../../styles/tradein_new.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { ContentOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { objTradeIn, tradeinSortOptions } from '../viewModel'
import { tipStatus } from '../../../services/enum';

const TradeInDetails = (props) => {
    const [tradeIn, setTradeIn] = useState(objTradeIn)
    const [isPDF, setPDF] = useState(false)
    const [accessToShowTradeInPrice, setShowPrice] = useState(true)
    const [tradeinOptionsDF, settradeinOptionsDF] = useState([])
    const [dealersettings, setdealerSettings] = useState({})


    // const {
    //     specifications, tags,
    //     notes, images, marks, status, addedBy, inspectionSnapshot, sidePhotos,
    //     enquiry, client, users, offerPrice, tradeinProID
    // } = tradeIn;
    const {
        make, model, year, modelDescription, specifications, tags,
        notes, transmissionType, driveType, fuelType, transmission,
        mileage, exteriorColor,
        type, engineType,
        images, marks, status, addedBy, inspectionSnapshot, sidePhotos,
        enquiry, client, users, offerPrice, tradeinPro,
        tradeinProID, service
    } = tradeIn;

    const currencySymbol = ((!_.isEmpty(tradeIn) &&
        !_.isEmpty(tradeIn.client) &&
        !_.isEmpty(tradeIn.client.currency)) ? tradeIn.client.currency.symbol + ' ' : '$ ');

    const _settings = !_.isEmpty(tradeIn?.currentSettings?.tradeinPro) ? tradeIn.currentSettings.tradeinPro : null;
    let objCurrentStatus = tradeinProID && status && _settings && _settings.tradeinProStatus && _settings.tradeinProStatus.filter(e => e.value === status)[0]
    const _isTradeinCompleted = [tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(status) ? true : false;
    const toDataURL = async (url) => await fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))


    const fetchLookups = async () => {
        let _tradeinplusURL
        const remoteConfig = window.firebase.remoteConfig();
        try {
            await remoteConfig.fetchAndActivate()
                .then(() => {
                    _tradeinplusURL = remoteConfig.getString('tradeinplusURL');
                    //console.log('_tradeinplusURL', _tradeinplusURL);
                })
                .catch((err) => {
                    console.error(err);
                });

        } catch (error) {
            console.log('lookups', error);
        }
        return _tradeinplusURL;
    }

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`tradeins/${props.tradeinid}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        //console.log('TradeInDetails', props.tradeinid);
        if (props.tradeinid) {
            loadTradeIn();
        }
        return () => {
            window.refCollectionData && window.refCollectionData.unsubscribe()
        }
    }, [firestoreDB])

    const loadTradeIn = async () => {


        window.refCollectionData = docData(firestoreDB.firestore().doc(`tradeins/${props.tradeinid}`)//.doc(props.tradeinid).get()
            // .where('documentID', '==', props.tradeinid)
            // .where('isDeleted', '==', false)
        )
            .pipe(
                switchMap(tradeIns => {
                    //console.log(tradeIns);                    
                    const enqIds = tradeIns.enquiryID ? [tradeIns.enquiryID] : [];
                    const serviceIds = tradeIns.serviceID ? [tradeIns.serviceID] : [];
                    const tradeIds = tradeIns.tradeinProID ? [tradeIns.tradeinProID] : [];
                    const clientIds = [tradeIns.clientID];
                    return combineLatest(
                        of(tradeIns),
                        combineLatest(
                            enqIds.length > 0 ? enqIds.map(enquiryID =>
                                collectionData(firestoreDB.firestore().collection('enquiries').where('documentID', '==', enquiryID)).pipe(
                                    map(enquirySnapshot => enquirySnapshot[0] ? enquirySnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            serviceIds.length > 0 ? serviceIds.map(serviceID =>
                                collectionData(firestoreDB.firestore().collection('serviceJobs').where('documentID', '==', serviceID)).pipe(
                                    map(serviceSnapshot => serviceSnapshot[0] ? serviceSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            tradeIds.length > 0 ? tradeIds.map(tradeinProID =>
                                collectionData(firestoreDB.firestore().collection('tradeinPro').where('documentID', '==', tradeinProID)).pipe(
                                    map(tipSnapshot => tipSnapshot[0] ? tipSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
                                    map(clientSettingsSnapshot => clientSettingsSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                                    map(usersSnapshot => usersSnapshot)
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([tradeIns, enquirySnapshot, serviceSnapshot, tipSnapshot, clientSnapshot, clientSettingsSnapshot, usersSnapshot]) => {

                    return {
                        ...tradeIns,
                        enquiry: tradeIns.enquiryID ? enquirySnapshot.find(a => a.documentID === tradeIns.enquiryID) : {},
                        service: tradeIns.serviceID ? serviceSnapshot.find(a => a.documentID === tradeIns.serviceID) : null,
                        tradeinPro: tradeIns.tradeinProID ? tipSnapshot.find(a => a.documentID === tradeIns.tradeinProID) : {},
                        client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                        currentSettings: clientSettingsSnapshot[0],
                        users: usersSnapshot[0],
                    }

                })
            )
            .subscribe(_tradeIns => {
                let search = window.location.search;
                let params = new URLSearchParams(search);
                let backURL = params.get('isView');

                setPDF(backURL ? false : true);

                if (backURL === 'yes')
                    setShowPrice(false)



                if (!_.isEmpty(_tradeIns)) {

                    let _dealerSettings = {};
                    let _client = Object.assign({}, _tradeIns.client);
                    let _clientSettings = Object.assign({}, _tradeIns.currentSettings);
                    _dealerSettings.client = Object.assign({
                        ..._client,
                        id: _tradeIns.clientID,
                        name: _clientSettings.name,
                        timezone: _clientSettings.timezone,
                        phone: _clientSettings.phone,
                        logoURL: _clientSettings.logoURL,
                        address: _clientSettings.address,
                        moduleSettings: _clientSettings.moduleSettings,
                        currency: _clientSettings.settings && _clientSettings.settings.currency,
                        integrations: _clientSettings.integrations,
                        settings: _clientSettings.settings
                    });

                    let _tradeinOptionsDF = [];
                    if (_dealerSettings?.client?.settings?.tradeinOptionsDF)
                        _tradeinOptionsDF = _dealerSettings?.client?.settings?.tradeinOptionsDF;
                    settradeinOptionsDF(_tradeinOptionsDF);
                    setdealerSettings(_dealerSettings);

                    saveTradein(_tradeIns, _tradeinOptionsDF);


                    const tradeinPlusEnabled = ((!_.isEmpty(_tradeIns.client) &&
                        !_.isEmpty(_tradeIns.client.moduleSettings) &&
                        !_.isEmpty(_tradeIns.client.moduleSettings.tradeinPlus) &&
                        _tradeIns.client.moduleSettings.tradeinPlus.enabled) ? true : false);

                    //console.log('TradeInDetails', _tradeIns, '==', _tradeIns.sent, '--', tradeinPlusEnabled);

                    if (_tradeIns.sent && _tradeIns.evalexpertID) {
                        getDetailsFromEvalExpert(props.tradeinid, _tradeIns);
                    }
                    else if (_tradeIns.sent && tradeinPlusEnabled) {
                        getDetailsFromTradeInPlus(props.tradeinid, _tradeIns);
                    }


                }


            });

    }

    const getDetailsFromTradeInPlus = async (docID, _fields) => {
        try {

            let tradeinplusURL = await fetchLookups();
            //console.log('tradeinplusURL', tradeinplusURL);
            if (tradeinplusURL) {
                const ObjectToSend = {
                    Name: docID
                };

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(ObjectToSend),
                };

                const response = await fetch(
                    tradeinplusURL + 'api/TradeInAMS/Action/GetTradeInByAMSProId',
                    requestOptions,
                );

                const respjson = await response.json();

                if (respjson.success) {
                    //console.log(respjson);
                    //convertMappingVM(respjson.data, _fields);
                    var tradein = CommonHelper.convertTradeInMappingVM(respjson.data, _fields);
                    saveTradein(tradein);
                } else {
                    //console.log('getDetailsFromTradeInPlus', respjson);
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    const getDetailsFromEvalExpert = async (docID, _fields) => {
        try {

            const evalexpertGet = window.firebase.functions().httpsCallable('evalexpert-get');
            evalexpertGet(_fields).then((_response) => {

                if (_response && _response.data && _response.data.success && _response.data.data) {
                    //console.log('evalexpert-get--', _response.data.data[0]);
                    var tradein = CommonHelper.convertEvalMappingVM(_response.data.data[0], _fields);
                    saveTradein(tradein);
                }

            }).catch(() => {
                //Swal.fire('Something went wrong. Please contact support.', '', 'error')
            });
        } catch (error) {
            console.log(error);
        }
    }


    const saveTradein = (doc, _tradeinOptionsDF) => {
        let _fields = { ...doc };
        let __tradeinOptionsDF = _tradeinOptionsDF ? _tradeinOptionsDF : tradeinOptionsDF
        if (!_.isEmpty(doc.dynamicFields) && __tradeinOptionsDF) {

            __tradeinOptionsDF.filter(e => e.active === true).forEach(rec => {
                if (rec.type === 'toggle') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                // else if (rec.type === 'price') {
                //     _fields[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(dealersettings?.client?.currency) ? dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
                // }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    // _fields[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                    //     {
                    //         doc.dynamicFields[rec.value].map((rec, index) => {
                    //             return <div key={index}><span>{rec}</span></div>
                    //         })
                    //     }
                    // </div>) : (<></>)
                }
                else {
                    _fields[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        setTradeIn(_fields);
    }

    const getInspection = (id, _marks) => {
        const canvas = document.createElement("canvas");
        canvas.width = 395;
        canvas.height = 600;
        // var canvas = document.getElementById("canvas");
        var context = canvas.getContext('2d');

        var img = new Image();
        if (client?.category === 'Motorcycle')
            img.src = require("../../../images/inspection-motorcycle.jpg");
        else
            img.src = require("../../../images/inspection.jpg");
        //img.src = require(`../../../images/${CommonHelper.getVehicleIcons(client?.category, 'inspection')}.jpg`);
        img.onload = async function (e) {
            context.drawImage(img, 0, 0, 395, 600);

            if (!_.isEmpty(_marks)) {
                await asyncForEach(_marks, async (mark) => {
                    let imgURL = require(`../../../images/${mark.markType}.png`);
                    await loadImage(imgURL).then(img2 => {
                        context.drawImage(img2, mark.dx, mark.dy, 25, 25);
                    });
                });
            }

            var dataURL = canvas.toDataURL();
            document.getElementById(id).src = dataURL;
            //console.log('imageToBase64:', dataURL)
            return dataURL;
        }
    }

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // It's better to use async image loading.
    const loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };




    let dynamicDetailList = (!_.isEmpty(dealersettings?.client?.settings?.sortTradeinOptions))
        ? dealersettings.client.settings.sortTradeinOptions :
        Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));
    let dynamicFilteredList = dynamicDetailList.filter(e => !['notes', 'specifications', 'status', 'modelDescription'].includes(e));

    const tradeinIdentifiers = [
        "chassisNo",
        "regNo",
        "regDate",
        "regState",
        "regStatus",
        "region",
        "buildDate",
        "engineNo",
        "engineSize",
        "engineType",
        "complianceDate"
    ];
    //console.log('dynamicFilteredList', dynamicFilteredList)
    return (
        <>

            {
                !_.isEmpty(tradeIn) && (!_.isEmpty(enquiry) || !_.isEmpty(service) || !_.isEmpty(tradeinPro))
                    ?
                    <>
                        <div className="A4">
                            <div className="sheet padding-10mm report-data-load" style={{ margin: '0px auto 0px auto' }}>
                                <div className="print-ico">
                                    <a href="#" onClick={() => window.print()}>  <img src={_images.print} width="19" height="18" /> <span>Print</span></a>
                                </div>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="50%">

                                                    <div className="header-left-align">

                                                        {
                                                            client && client.logoURL
                                                                ?
                                                                <div className="logo-head"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="50" /></div>
                                                                :
                                                                <>
                                                                    <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                                </>
                                                        }

                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div className="header-right-align align-right-text">
                                                        <div className="header-company"> {client.name} <br />
                                                            <span>{client.address ? client.address : ''}

                                                                {client.phone ? (
                                                                    <>
                                                                        <br />
                                                                        {`Phone: ${client.phone}`}
                                                                    </>
                                                                ) : <></>}
                                                                {client.email || client.website ? (
                                                                    <>
                                                                        <br />
                                                                        {client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
                                                                    </>
                                                                ) : <></>} </span>
                                                        </div>
                                                    </div>






                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div className="middle-section-wraper">

                                    <div className="report-ui-mainhead">
                                        <Translate text={'Vehicle Evaluation Request'} upperCase={true} />

                                    </div>

                                    <div className="report-ui-subcontainer-main">

                                        <h2>
                                            {modelDescription ?
                                                <>{modelDescription}</> :
                                                <>{year ? year : ''}&nbsp;{make ? make : ''}&nbsp;{model ? model : ''}</>
                                            }
                                        </h2>
                                        <h3>{exteriorColor ? exteriorColor + ',' : ''} &nbsp;{driveType ? driveType : ''} &nbsp;{transmissionType ? transmissionType : ''}&nbsp;{fuelType ? fuelType : ''}</h3>
                                        <div className="report-veh-info">
                                            <div className="block">
                                                <img src={_images.ti_odometer} />
                                                <p>
                                                    {' Odometer'}
                                                    <span>{mileage ? <NumberFormat style={{ fontSize: 12 }} value={mileage} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : '--'}</span>
                                                </p>
                                            </div>
                                            <div className="block">
                                                <img src={_images.ti_bodytype} />
                                                <p>
                                                    {' Body Type'}
                                                    <span>{type ? type : '--'}</span>
                                                </p>
                                            </div>
                                            <div className="block">
                                                <img src={_images.ti_transmission} />
                                                <p>
                                                    {' Transmission'}
                                                    <span>{transmission ? transmission : '--'}</span>
                                                </p>
                                            </div>
                                            <div className="block">
                                                <img src={_images.ti_engine} />
                                                <p>
                                                    {' Engine'}
                                                    <span>{engineType ? engineType : '--'}</span>
                                                </p>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="report-ui-subhead">

                                        <div className="report-ui-cst">Customer Details
                                            {
                                                (enquiry?.contact && enquiry?.contact?.displayID)
                                                    ?
                                                    <span className="badge-custom">{enquiry.contact.displayID}</span>
                                                    :
                                                    <>
                                                        {
                                                            (service?.contact && service?.contact?.displayID)
                                                                ?
                                                                <span className="badge-custom">{service.contact.displayID}</span>
                                                                :
                                                                <>
                                                                    {
                                                                        !_.isEmpty(tradeinPro?.contact) && tradeinPro?.contact?.displayID
                                                                            ?
                                                                            <span className="badge-custom">{tradeinPro.contact.displayID}</span>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                            }

                                            {
                                                status && objCurrentStatus
                                                    ?
                                                    <>
                                                        <div
                                                            className={`badge-inline-status ml-2 mt-1`} style={{ background: (status === tipStatus.NEW ? '#333' : (objCurrentStatus && objCurrentStatus.color ? objCurrentStatus.color : '#333')) }}>
                                                            <Translate
                                                                text={objCurrentStatus ? objCurrentStatus.name : status.toLowerCase()}
                                                                upperCase={true}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                            {
                                                tradeIn.isDeletedFromTradeIn
                                                    ?
                                                    <>
                                                        <div className={`mrg-fixstatus badge-lost ml-2 mt-1`}>
                                                            {'Deleted From TradeIn Plus'}
                                                        </div>
                                                    </>
                                                    :
                                                    <>

                                                    </>
                                            }

                                            {
                                                !_.isEmpty(tags) && tags.map((rec, index) => {
                                                    return <div key={index}
                                                        className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">
                                                        {CommonHelper.getNameByValue(!_.isEmpty(tradeIn.client) && tradeIn.client.integrations, rec.type)}
                                                    </div>
                                                })
                                            }
                                        </div>

                                        {tradeIn.EvaluationNumber ?
                                            <div className="report-ui-enq"> Valuation No.: <span> {tradeIn.EvaluationNumber} </span> </div> :
                                            <>
                                                {
                                                    tradeIn.evalexpertID ?
                                                        <div className="report-ui-enq"> Eval Expert No.: <span> {tradeIn.evalexpertID} </span> </div> :
                                                        <>
                                                            {
                                                                enquiry?.displayID ?
                                                                    <div className="report-ui-enq"> Enquiry No.: <span> {enquiry.displayID} </span> </div> :
                                                                    <>
                                                                        {
                                                                            service?.displayID ?
                                                                                <div className="report-ui-enq"> Service No.: <span> {service.displayID} </span> </div> :
                                                                                <>
                                                                                    {
                                                                                        tradeinPro?.displayID ?
                                                                                            <div className="report-ui-enq"> Appraisal No.: <span> {tradeinPro.displayID} </span> </div> :
                                                                                            <>
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </>
                                        }

                                    </div>

                                    <div className="report-ui-subcontainer padding-15">
                                        <div className="table-details">
                                            <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                <tbody>
                                                    {
                                                        !_.isEmpty(tradeinPro)
                                                            ?
                                                            <tr>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span>Customer</span>{CommonHelper.displayFullContact(null, tradeinPro?.contact, '--')}</div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span><Translate text={'phone'} /></span>{tradeinPro.contact.phone ?
                                                                        CommonHelper.phoneFormat(tradeinPro?.contact?.phoneCode, tradeinPro.contact.phone, { client: client })
                                                                        : '--'} </div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span><Translate text={'email'} /></span> {tradeinPro?.contact?.email ? tradeinPro.contact.email : '--'}</div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="grid-one"> <span><Translate text={'Address'} /></span>{tradeinPro?.contact?.address ? tradeinPro.contact.address : '--'}</div>
                                                                </td>

                                                            </tr>
                                                            :
                                                            <>
                                                                {
                                                                    !_.isEmpty(service)
                                                                        ?
                                                                        <tr>
                                                                            <td width="25%">
                                                                                <div className="grid-one"> <span>Customer</span>{CommonHelper.displayFullContact(null, service?.contact, '--')}</div>
                                                                            </td>
                                                                            <td width="25%">
                                                                                <div className="grid-one"> <span><Translate text={'phone'} /></span>{service.contact.phone ?
                                                                                    CommonHelper.phoneFormat(service?.contact?.phoneCode, service.contact.phone, { client: client })
                                                                                    : '--'} </div>
                                                                            </td>
                                                                            <td width="25%">
                                                                                <div className="grid-one"> <span><Translate text={'email'} /></span> {service?.contact?.email ? service.contact.email : '--'}</div>
                                                                            </td>
                                                                            <td width="25%">
                                                                                <div className="grid-one"> <span><Translate text={'Address'} /></span>{service?.contact?.address ? service.contact.address : '--'}</div>
                                                                            </td>

                                                                        </tr>
                                                                        :
                                                                        <tr>
                                                                            <td width="25%">
                                                                                <div className="grid-one"> <span>Customer</span>{CommonHelper.displayFullContact(null, enquiry?.contact, '--')}</div>
                                                                            </td>
                                                                            <td width="25%">
                                                                                <div className="grid-one"> <span><Translate text={'phone'} /></span>{enquiry.contact.phone ?
                                                                                    CommonHelper.phoneFormat(enquiry?.contact?.phoneCode, enquiry.contact.phone, { client: client })
                                                                                    : '--'} </div>
                                                                            </td>
                                                                            <td width="25%">
                                                                                <div className="grid-one"> <span><Translate text={'email'} /></span> {enquiry?.contact?.email ? enquiry.contact.email : '--'}</div>
                                                                            </td>
                                                                            <td width="25%">
                                                                                <div className="grid-one"> <span><Translate text={'Address'} /></span>{enquiry?.contact?.address ? enquiry.contact.address : '--'}</div>
                                                                            </td>

                                                                        </tr>
                                                                }
                                                            </>
                                                    }

                                                    <tr>
                                                        <td width="25%">
                                                            <div className="grid-one"> <span>Dealer Name</span> {!_.isEmpty(client) ? client.name : '--'}</div>
                                                        </td>
                                                        <td width="25%">
                                                            <div className="grid-one"> <span>Created By</span> {(!_.isEmpty(users) && addedBy) ? CommonHelper.getUserNamebyDocumentId(users, addedBy) : '--'} </div>
                                                        </td>
                                                        <td width="25%"></td>
                                                        <td width="25%"></td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>



                                    <div className="report-ui-subhead">
                                        Vehicle Details

                                    </div>
                                    <div className="report-ui-subcontainer padding-15">
                                        <div className="table-details">
                                            <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                <tbody>
                                                    {
                                                        _.chunk(dynamicFilteredList.filter(e => !tradeinIdentifiers.includes(e)), 4).map((stats, i) => {
                                                            return <tr key={i}>
                                                                {
                                                                    stats.map((rec, index) => {

                                                                        return <td key={index} width="25%"><div className="grid-one">

                                                                            {(() => {
                                                                                if (rec === 'regDate' || rec === 'complianceDate' || rec === 'buildDate' || rec === 'serviceContFrom' || rec === 'serviceContTo' || rec === 'lastService' || rec === 'warrantyExpiry') {
                                                                                    return <>
                                                                                        <span><Translate text={rec} /></span>{tradeIn[rec] ? moment(tradeIn[rec], 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}
                                                                                    </>
                                                                                }
                                                                                else if (rec === 'massageVentilated' || rec === 'contrastStiching' || rec === 'financed' || rec === 'outstandingPayments' || rec === 'sunroof' || rec === 'camera' || rec === 'woodSteering' || rec === 'warranty' || rec === 'reconditioning') {
                                                                                    return <>
                                                                                        <span><Translate text={rec} /></span>{tradeIn[rec] ? 'YES' : 'NO'}
                                                                                    </>
                                                                                }
                                                                                else if (rec === 'reconditioningCost') {
                                                                                    return <>
                                                                                        <span><Translate text={rec} /></span>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}
                                                                                    </>
                                                                                }
                                                                                else if (rec === 'mileage') {
                                                                                    return <>
                                                                                        <span><Translate text={rec} /></span>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : '--'}
                                                                                    </>
                                                                                }
                                                                                else if (objTradeIn.hasOwnProperty(rec)) {
                                                                                    return <><span><Translate text={rec} /></span>{tradeIn[rec] ? tradeIn[rec] : '--'}</>
                                                                                }
                                                                                else if (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0])) {
                                                                                    let info = tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                                                                                    if (info.type === 'price')
                                                                                        return <><span><Translate text={info.name} /></span>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : '--'}</>
                                                                                    else
                                                                                        return <><span><Translate text={info.name} /></span>{tradeIn[rec] ? tradeIn[rec] : '--'}</>
                                                                                }
                                                                            })()}
                                                                        </div></td>
                                                                    })
                                                                }
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>

                                            </table>
                                        </div>

                                    </div>



                                    <div className="report-ui-subhead">
                                        Vehicle Identifiers

                                    </div>
                                    <div className="report-ui-subcontainer padding-15">
                                        <div className="table-details">
                                            <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                <tbody>
                                                    {
                                                        _.chunk(dynamicFilteredList.filter(e => tradeinIdentifiers.includes(e)), 4).map((stats, i) => {
                                                            return <tr key={i}>
                                                                {
                                                                    stats.map((rec, index) => {

                                                                        return <td key={index} width="25%"><div className="grid-one">

                                                                            {(() => {
                                                                                if (rec === 'regDate' || rec === 'complianceDate' || rec === 'buildDate' || rec === 'serviceContFrom' || rec === 'serviceContTo' || rec === 'lastService' || rec === 'warrantyExpiry') {
                                                                                    return <>
                                                                                        <span><Translate text={rec} /></span>{tradeIn[rec] ? moment(tradeIn[rec], 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}
                                                                                    </>
                                                                                }
                                                                                else if (rec === 'massageVentilated' || rec === 'contrastStiching' || rec === 'financed' || rec === 'outstandingPayments' || rec === 'sunroof' || rec === 'camera' || rec === 'woodSteering' || rec === 'warranty' || rec === 'reconditioning') {
                                                                                    return <>
                                                                                        <span><Translate text={rec} /></span>{tradeIn[rec] ? 'YES' : 'NO'}
                                                                                    </>
                                                                                }
                                                                                else if (rec === 'reconditioningCost') {
                                                                                    return <>
                                                                                        <span><Translate text={rec} /></span>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}
                                                                                    </>
                                                                                }
                                                                                else if (objTradeIn.hasOwnProperty(rec)) {
                                                                                    return <><span><Translate text={rec} /></span>{tradeIn[rec] ? tradeIn[rec] : '--'}</>
                                                                                }
                                                                                else if (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0])) {
                                                                                    let info = tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                                                                                    if (info.type === 'price')
                                                                                        return <><span><Translate text={info.name} /></span>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : '--'}</>
                                                                                    else
                                                                                        return <><span><Translate text={info.name} /></span>{tradeIn[rec] ? tradeIn[rec] : '--'}</>
                                                                                }
                                                                            })()}
                                                                        </div></td>
                                                                    })
                                                                }
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>

                                            </table>
                                        </div>

                                    </div>


                                    {
                                        !_.isEmpty(specifications) && !_.isEmpty(dynamicDetailList.filter(e => ['specifications'].includes(e))[0])
                                            ?
                                            <>
                                                <div className="report-ui-subhead"> SPECIFICATIONS </div>
                                                <div className="report-ui-subcontainer text-area-space"
                                                    dangerouslySetInnerHTML={{ __html: (specifications ? '&middot; ' + specifications.replace(/\n/g, "<br/> &middot; ") : '--') }} />
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    {
                                        !_.isEmpty(marks) && (
                                            <>
                                                <div className="report-ui-subhead">

                                                    <div className="report-ui-cst"> Reconditioning</div>
                                                    <div className="report-ui-enq">{CommonHelper.showReconditioningCost(marks, currencySymbol)}</div>

                                                </div>

                                                {/* <div className="report-ui-subcontainer padding-15">
                                                    <div className="table-details">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                {
                                                                    _.chunk(marks, 4).map((stats, i) => {
                                                                        return <tr key={i}>
                                                                            {
                                                                                stats.map((mark, index) => {

                                                                                    return <td key={index} width="25%"><div className="grid-one">

                                                                                        {bindMArkType(mark.markType, mark.price, true)}
                                                                                    </div></td>
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>

                                                        </table>
                                                    </div>

                                                </div> */}
                                                <div className="report-ui-subcontainer">
                                                    <ul>
                                                        {
                                                            marks.map((mark, index) => {
                                                                return <li key={index} >
                                                                    {CommonHelper.bindMArkType(mark.markType, mark.price, mark.notes, currencySymbol)}
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </>
                                        )
                                    }



                                    {/* <div className="report-ui-subhead">
                                        Aftermarket & Servicing

        </div>

                                    <div className="report-ui-subcontainer padding-15">
                                        <div className="table-details">
                                            <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td width="25%">
                                                            <div className="grid-one"> <span>AFTERMARKET</span>No</div>
                                                        </td>
                                                        <td width="25%">
                                                            <div className="grid-one"> <span>SERVICE HISTORY</span>Yes</div>
                                                        </td>
                                                        <td width="25%"></td>
                                                        <td width="25%"></td>

                                                    </tr>







                                                </tbody>

                                            </table>
                                        </div>

                                    </div> */}









                                    <div style={{ pageBreakAfter: 'always' }}></div>
                                    <div className="report-ui-subhead"> VEHICLE IMAGES</div>
                                    <div className="report-ui-subcontainer">

                                        <div className="report-ui-thumb-wraper">

                                            <>
                                                {
                                                    (!_.isEmpty(images) || !_.isEmpty(sidePhotos) || (!_.isEmpty(marks) && marks.find(item => !_.isEmpty(item.images))))
                                                        ?
                                                        <>
                                                            {
                                                                isPDF
                                                                    ?
                                                                    <>
                                                                        <div className="report-vehicle-link-wrap">
                                                                            {/* <p>Please find the requested pictures in the link below.</p> */}
                                                                            <div className="button-view"><a target="_blank" href={`${window.location.origin}/tradein/images/${props.tradeinid}`} className="gallery-link btn-bg ">Click here to view images</a></div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            !_.isEmpty(images) && images.map((data, index) => (
                                                                                <div className="report-ui-thumb-item-image" key={index}>
                                                                                    <a data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                                        <img id={`tradein-before-img-${index}`} src={data} width="80" height="80" alt="" className="img-object-fit" />
                                                                                    </a>
                                                                                </div>
                                                                            ))
                                                                        }

                                                                        {!_.isEmpty(sidePhotos) && Object.keys(sidePhotos).map((key, index) => {
                                                                            if (sidePhotos[key]) {
                                                                                return (<div className="report-ui-thumb-item-image" key={index}>
                                                                                    <a data-fancybox={`tradein_img`} href={sidePhotos[key]} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                                        <img id={`tradein-side-img-${index}`} src={sidePhotos[key]} width="80" height="80" alt="" className="img-object-fit" />
                                                                                    </a>
                                                                                </div>)
                                                                            }

                                                                        })}

                                                                        {
                                                                            !_.isEmpty(marks) && marks.map((mark, index) => {
                                                                                return <div key={index}>
                                                                                    {!_.isEmpty(mark.images) && mark.images.map((data, index) => (
                                                                                        <div className="report-ui-thumb-item-image" key={index}>
                                                                                            <a data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                                                <img id={`tradein-mark-img-${index}`} src={data} width="80" height="80" alt="" className="img-object-fit" />
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <div className="report-ui-thumb-item-image">
                                                                <a href="#" onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                    <img src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} width="80" height="80" alt="" className="img-object-fit" />
                                                                </a>
                                                            </div>
                                                        </>
                                                }

                                            </>


                                        </div>

                                    </div>


                                    <div className="report-ui-subhead"> VEHICLE INSPECTION </div>

                                    <div className="report-ui-subcontainer">
                                        <div className="report-ui-inspection-wrap">
                                            <div className="inspection-image">
                                                <a data-fancybox={`tradein_inspection_img`} href={inspectionSnapshot ? inspectionSnapshot : _images[CommonHelper.getNoVehicleIcons(client?.category, 'inspection')]} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                    <img id={`tradein_inspection_img`} src={getInspection(`tradein_inspection_img`, marks)}
                                                        width="750"
                                                        height="1118"
                                                        alt="" />
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                    {
                                        offerPrice && accessToShowTradeInPrice && (!tradeinProID || (tradeinProID && _isTradeinCompleted))
                                            ?
                                            <>
                                                <div className="report-ui-subhead">OFFER PRICE </div>
                                                <div className="report-ui-subcontainer green-offer">
                                                    <div className="report-ui-offerprice">
                                                        <img src={_images.ti_tickWhite} width="50" height="25" />
                                                        <NumberFormat value={offerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                    </div>
                                                </div>
                                            </>
                                            : <></>
                                    }

                                    {
                                        notes && !_.isEmpty(dynamicDetailList.filter(e => ['notes'].includes(e))[0])
                                            ?
                                            <>
                                                <div className="report-ui-subhead"> NOTES </div>
                                                <div className="report-ui-subcontainer text-area-space">
                                                    {notes}
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }

                                    <div className="report-ui-disclaimer">This appraisal remains valid for a week. The evaluation is contingent on the
                                        successful transfer of clear title and approval from PPSR checks. The given price estimate
                                        relies on known vehicle data and/or a visual inspection (if conducted). A more detailed technical inspection
                                        may be required in the future, and the proposed price could
                                        be adjusted based on its findings. Vehicle information was collected electronically using specific vehicle
                                        identifiers. Despite our best efforts to guarantee accuracy, we
                                        advise not to rely solely on this data. Any mistakes or oversights are excepted.</div>


                                    <div className="footer">
                                        {/* <div className="footer-icons">                                            
                                            <div className="icons">
                                                <div className="badge-ins major-info"> i</div>
                                                <Translate text={'info'} upperCase={true} /></div>

                                            <div className="icons">
                                                <div className="badge-ins major-scratch"> S</div>
                                                <Translate text={'Major Scratch'} upperCase={true} /></div>
                                            <div className="icons">
                                                <div className="badge-ins minor-scratch"> S</div>
                                                <Translate text={'Minor Scratch'} upperCase={true} /></div>

                                            <div className="icons">
                                                <div className="badge-ins major-rust"> R</div>
                                                <Translate text={'Major Rust'} upperCase={true} /></div>
                                            <div className="icons">
                                                <div className="badge-ins minor-rust"> R</div>
                                                <Translate text={'Minor Rust'} upperCase={true} /></div>

                                            <div className="icons">
                                                <div className="badge-ins major-dent"> D</div>
                                                <Translate text={'Major Dent'} upperCase={true} /></div>
                                            <div className="icons">
                                                <div className="badge-ins minor-dent"> D</div>
                                                <Translate text={'Minor Dent'} upperCase={true} /></div>

                                            <div className="icons">
                                                <div className="badge-ins major-crack"> C</div>
                                                <Translate text={'Major Crack'} upperCase={true} /></div>
                                            <div className="icons">
                                                <div className="badge-ins minor-crack"> C</div>
                                                <Translate text={'Minor Crack'} upperCase={true} /></div>

                                            <div className="clear"></div>
                                        </div> */}
                                        <div className="footer-bottom">
                                            <p>
                                                <span style={{ paddingLeft: 0 }} >
                                                    {client?.address ? <>{client.address}</> : <></>}
                                                </span>
                                                <span style={{ paddingLeft: 0 }}>
                                                    {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                    {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                                    {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                                    {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="footer-cpy">
                                            <div className="footer-left">Powered by <a target="_blank" href="http://fusionsd.com.au/">Fusion SD</a></div>
                                            <div className="footer-right">Created On - {!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment().format('DD/MM/YYYY hh:mm A')}</div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>

            }
        </>



    )
};

export default TradeInDetails;
