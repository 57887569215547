/** LIBRARIES */
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Accordion, Card } from 'react-bootstrap';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import moment from 'moment';
import _ from 'lodash'
import { Tooltip } from 'antd';
//import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2'
/** PROVIDERS */
import { LayoutConsumer } from '../../layout/provider';
import { StageProvider } from '../../pipeline/provider';
/** COMPONENTS */
import {
	PopUpModal,
	ReactSelect,
	LoaderOverlay,
	SidePanel,
	ContentOverlay
} from '../../../components';
import ReAuthenticate from '../../common/reAuthenticate';
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import QuickView from '../../pipeline/quickview';
import AddContact from '../../contacts/add';
import Activity from '../../pipeline/details/activity';
import EnquiryInfo from '../../pipeline/enquiryInfo';
import OwnedVehicleInfo from '../owned/ownedVehicleInfo';
import OwnedEnquiryInfo from '../owned/ownedEnquiryInfo';
import AddOwnedVehicle from '../owned/addOwnedVehicle';
import AddOwnedEnquiryVehicle from '../owned/addOwnedEnquiryVehicle';
import Files from '../../files/contacts';
import AddFile from '../../files/add';
import SendMail from '../../pipeline/details/sendMail';
import QuickViewService from '../../service/quickview';
import QuickViewTradeinPro from '../../tradeinplus/quickview'
import ServiceInfo from '../../service/serviceInfo';
import TIPInfo from '../../tradeinplus/info'
import PageNotFound from '../../../components/pagenotFound';
import InviteAmsMe from '../inviteME'
import FleetInfo from '../../fleet/fleetInfo';
import AddFleet from '../../fleet/add'
/** VIEW-MODELS */
import {
	objContact,
	objOwnedVehicle,
	objOwnedEnquiryVehicle,
	contactBasicDetailsVM,
} from '../../contacts/viewModel';
import { objEnquiry, objActivitylog } from '../../pipeline/viewModel';
import { Dropdown } from 'react-bootstrap';
import ChatSidePanel from '../../chat/chatsidepanel';
import EventInfo from '../../pipeline/eventInfo';
import EventModal from '../../pipeline/eventModal';
import UserRatingInfo from '../../pipeline/userRatingInfo'
import FormFeedbackInfo from '../../pipeline/formFeedback'
import { enquiryStatus, serviceStatus } from '../../../services/enum';
import EventsProInfo from '../../eventsProAttendees/eventCardInfo'

import { objFleet } from '../../fleet/viewModel'
import { objStock } from '../../stock/viewModel'
import { firestoreDB } from '../../../services/helper';

let titles = [];
let languages = [];
let license_state = [];
export default class ContactInfo extends Component {
	// _isMounted = false;
	constructor(props) {
		super(props);
		this.refEnquiry = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
		this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
		this.refActivity = firestoreDB(this.props.dealersettings).firestore().collection('activities');
		this.refServiceJobs = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs');
		this.refTIP = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro');
		this.refFleet = firestoreDB(this.props.dealersettings).firestore().collection('fleet');
		this.refEvents = window.firebase.firestore().collection('fusionevents');
		this.refUsersRating = firestoreDB(this.props.dealersettings).firestore().collection('usersRating');
		this.refUsersFeedback = firestoreDB(this.props.dealersettings).firestore().collection('formFeedback');
		this.newID = this.refContact.doc().id;
		this.unsubscribeContact = null;
		this.unsubscribeEnquiry = null;
		this.unsubscribeService = null;
		this.unsubscribeTradeinPro = null;
		this.unsubscribeActivity = null;
		this.unsubscribeFleet = null;
		this.unsubscribeEnquiryOwned = null;
		this.unsubscribeOwned = null;
		this.unsubscribeAmsMe = null;
		this.state = {
			loading: false,
			activeLeftKey: '0',
			activeRightKey: '00',
			contact: Object.assign({}, objContact),
			enquiry: {},
			amsmeUser: {},
			enquiries: Object.assign([]),
			loanVehicles: Object.assign([]),
			services: Object.assign([]),
			tradeinPro: Object.assign([]),
			events: Object.assign([]),
			usersRating: Object.assign([]),
			activities: Object.assign([]),
			ownedVehicles: Object.assign([]),
			ownedEnquiryVehicles: Object.assign([]),
			formFeedback: Object.assign([]),
			enqCampaigns: Object.assign([]),
			contactID: null,
			clientUsers: [],
			campaigns: [],
			contactModal: {
				showContact: false,
				showContactSearch: false,
				contactTitle: '',
				clsContactActive: '',
			},
			ownedModal: {
				show: false,
				ID: '',
				title: '',
				clsActive: '',
			},
			ownedEnquiryModal:
			{
				show: false,
				ID: '',
				data: null,
				title: '',
				clsActive: ''
			},
			eventModal:
			{
				show: false,
				ID: '',
				data: null,
				title: '',
				clsActive: ''
			},
			fleetModal:
			{
				show: false,
				ID: '',
				title: '',
				clsActive: ''
			},
			showpanel: {
				clsActive: '',
				clsName: 'sidebar-quickview',
				enquiryid: 0,
			},
			showServicepanel: {
				clsActive: '',
				clsName: 'sidebar-service-quickview',
				serviceid: 0,
			},
			showTradeinPropanel: {
				clsActive: '',
				clsName: 'sidebar-tradeinpro-quickview',
				tradeinproid: 0,
			},
			showSharePanel: {
				chatClsActive: '',
				chatClsName: 'sidebar',
			},
			showReAuthentication: false,
			pagenotFound: false,
			fileModal: {
				show: false
			},
			enquiryhasMore: [],
			enquiryPageLimit: 20,
			enquiriesCount: 0
		};
	}

	handleSharePanelOpen = (e) => {
		e.preventDefault();
		//console.log('handleShare Clicked');
		localStorage.removeItem('showChat');
		this.setState({
			showSharePanel: {
				chatClsActive: 'active',
				chatClsName: 'sidebar',
			},
		});
	};

	handleSharePanelClose = () => {
		//	console.log('handleShare Clicked Closed');
		this.setState({
			showSharePanel: {
				chatClsActive: '',
				chatClsName: 'sidebar',
			},
		});
	};

	//#region  QUICK VIEW ENQUIRY
	sidepanelOpen = id => {
		document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
		//if (localStorage.defaultModule !== 'service') {
		this.setState({
			showpanel: {
				clsActive: 'active',
				clsName: 'sidebar-quickview',
				enquiryid: id,
			},
		});
		//}
	};

	sidepanelClose = () => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		this.setState({
			showpanel: {
				clsActive: '',
				clsName: 'sidebar-quickview',
				enquiryid: 0,
			},
		});
	};
	//#endregion

	//#region  QUICK VIEW SERVICE
	sidepanelServiceOpen = id => {
		document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
		//if (localStorage.defaultModule === 'service') {
		this.setState({
			showServicepanel: {
				clsActive: 'active',
				clsName: 'sidebar-service-quickview',
				serviceid: id,
			},
		});
		//}

	};

	sidepanelServiceClose = () => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		this.setState({
			showServicepanel: {
				clsActive: '',
				clsName: 'sidebar-service-quickview',
				serviceid: 0,
			},
		});
	};
	//#endregion

	//#region  QUICK VIEW Tradein Pro
	sidepanelTIPOpen = id => {
		document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
		//this.props.history.push('/tradeinpro/details/' + id);
		//if (localStorage.defaultModule === 'tradeinPro') {
		this.setState({
			showTradeinPropanel: {
				clsActive: 'active',
				clsName: 'sidebar-tradeinpro-quickview',
				tradeinproid: id,
			},
		});
		//}

	};

	sidepanelTIPClose = () => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		this.setState({
			showTradeinPropanel: {
				clsActive: '',
				clsName: '',
				tradeinproid: 0,
			},
		});
	};
	//#endregion

	//#region CONTACT CHANGES

	handleContactClose = (ID, objData) => {
		if (ID) {
			const refData = firestoreDB(this.props.dealersettings)
				.firestore()
				.collection('contactDetails')
				.doc(ID);
			refData.get().then(doc => {
				if (doc.exists) {
					const contact = this.convertContactVM(doc.data());
					contact.documentID = doc.id;
					this.setState({ contact: contact });

					const { enquiries } = this.state;
					enquiries && enquiries.forEach(enq => {
						this.props.updatenavbar({
							id: enq.documentID,
							name: CommonHelper.displayContact(null, objData, '')
						});
					});
				} else {
					console.error('No such document!');
				}
			});
		}

		this.setState({
			contactModal: {
				showContact: false,
				showContactSearch: false,
				contactTitle: '',
				clsContactActive: '',
			},
		});
	};

	handleDeleteContact = e => {
		e.preventDefault();
		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, 'Do you want to delete contact.'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
		}).then(result => {
			if (result.value) {
				let state = Object.assign({}, this.state.contact);
				state.isDeleted = true;

				state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
				state.modifiedDate = window.firebase.firestore.Timestamp.now();
				this.setState({ contact: state });
				const updateRef = this.refContact.doc(this.state.contactID);
				updateRef
					.update({
						isDeleted: state.isDeleted,
						modifiedBy: state.modifiedBy,
						modifiedDate: state.modifiedDate,
						modifiedFrom: 'web'
					})
					.then(docRef => {
						toast.notify('Contact deleted successfully', {
							duration: 2000,
						});

						let logNotes = 'Contact deleted';
						if (!_.isEmpty(state) && state.displayID)
							logNotes = 'Contact: ' + state.displayID + ' deleted';

						let _objLogData = {
							notes: logNotes,
							type: 'contactDetails',
							subType: 'delete',
							recordId: this.state.contactID,
						}
						_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, this.props.dealersettings);
						CommonHelper.saveAuditLog(_objLogData)


						let search = window.location.search;
						let params = new URLSearchParams(search);
						let backURL = params.get('back-url');

						if (backURL) this.props.history.push(backURL);
						else this.props.history.push('/contacts');
					})
					.catch(error => {
						console.error('Error updating enquiries: ', error);
					});

			}
		});
	}

	convertContactVM = (doc) => {

		doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
		doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

		const objcontactData = Object.assign({}, doc);
		const contact = Object.assign({}, objContact);
		for (let [key, value] of Object.entries(objcontactData)) {
			contact[key] = value;
		}
		if (_.isNil(objcontactData?.optinSMS)) contact.optinSMS = null;
		if (_.isNil(objcontactData?.marketingOptinEmail)) contact.marketingOptinEmail = null;
		if (_.isNil(objcontactData?.marketingOptinSMS)) contact.marketingOptinSMS = null;

		let dealersettings = this.props.dealersettings;
		let _setttings = contact.clientID && dealersettings && dealersettings.group &&
			dealersettings.group.clients && dealersettings.group.clients[contact.clientID] && dealersettings.group.clients[contact.clientID].settings;
		if (_.isEmpty(_setttings))
			_setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

		if (_setttings) {
			contact.language = CommonHelper.getNameByCode(dealersettings.languages, contact.language, '');
			contact.nationality = CommonHelper.getNameByValue(dealersettings.nationalities, contact.nationality, contact.nationality);

			contact.fullName = CommonHelper.getFullName(_setttings.titles, contact, '', true);
			contact.campaign = CommonHelper.getNameByValue(_setttings.campaigns, contact.campaign, '');
			contact.origin = CommonHelper.getNameByValue(_setttings.origins, contact.origin, '');
			contact.gender = CommonHelper.getNameByValue(_setttings.genders, contact.gender, '');
			contact.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, contact.maritalStatus, '');
			contact.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, contact.contactMethod, '');
			contact.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, contact.interests, '', true);
			contact.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, contact.licenseType, '');
		}

		if (dealersettings && contact.clientID) {
			contact.dealerName = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);
		}
		return contact
	}
	//#endregion

	//#region LOAD PAGE

	onContactCollectionUpdate = docSnapshot => {
		document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		if (!docSnapshot.exists) {
			return;
		}
		const contact = this.convertContactVM(docSnapshot.data());
		contact.documentID = docSnapshot.id;

		if (
			!contact.counts ||
			contact.counts.todayDate !== moment().format('YYYY-MM-DD')
		) {
			let objcounts = contact.counts ? contact.counts : {};
			objcounts.todayDate = moment().format('YYYY-MM-DD');

			this.refContact
				.doc(docSnapshot.id)
				.set({ counts: objcounts }, { merge: true });
		}

		let _contactBasicVM = Object.assign({}, contactBasicDetailsVM);
		_contactBasicVM.documentID = docSnapshot.id;
		for (let [key, value] of Object.entries(docSnapshot.data())) {
			if (_contactBasicVM.hasOwnProperty(key)) _contactBasicVM[key] = value;
		}

		this.setState({
			contactID: docSnapshot.id,
			contact: contact,
			contactBasicVM: _contactBasicVM,
		});
	};
	onEnquiryCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const enquiries = [];
		const enqCampaigns = [];
		let _fields = Object.assign({}, objEnquiry);
		if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === this.state.enquiryPageLimit) {
			this.setState({
				enquiryhasMore: [{
					lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
				}]
			})
		}
		else {
			this.setState({
				enquiryhasMore: []
			})
		}
		querySnapshot.forEach(_enquiry => {
			const enquiry = Object.assign({}, objEnquiry);
			const objEnquiryData = Object.assign({}, _enquiry.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				enquiry[key] = value;
			}
			enquiry.documentID = _enquiry.id;

			let dealersettings = this.props.dealersettings;
			if (dealersettings && enquiry.clientID && (enquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
				enquiry.dealerName = CommonHelper.getOtherDealerName(dealersettings, enquiry.clientID);
			}

			if (!_.isEmpty(enquiry.pipeline)) {
				_fields = enquiry;
				enquiries.push(enquiry);

				if (enquiry.campaign && !enqCampaigns.find(e => e === enquiry.campaign))
					enqCampaigns.push(enquiry.campaign)
			}

		});

		this.setState({
			enquiries: _.uniqBy(_.orderBy([
				...this.state.enquiries,
				...enquiries
			], ["modifiedDate"], ["desc"]), 'documentID'),
			enquiry: _fields,
			enqCampaigns: enqCampaigns,
			enqPagingLoader: false
		});
	};

	onFleetCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		var loans = [];
		querySnapshot.forEach(_fleet => {
			const fleet = Object.assign({}, objFleet);
			const objTestDriveData = Object.assign({}, _fleet.data());
			for (let [key, value] of Object.entries(objTestDriveData)) {
				fleet[key] = value;
			}
			fleet.documentID = _fleet.id;

			let dealersettings = this.props.dealersettings;
			if (dealersettings && fleet.clientID && (fleet.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
				fleet.dealerName = CommonHelper.getOtherDealerName(dealersettings, fleet.clientID);
			}

			loans.push(fleet)
		})

		this.setState({ loanVehicles: _.orderBy(loans, ["modifiedDate"], ["desc"]) })
		// Promise.all(loans).then(_loans => {

		// })
	}

	onServiceJobCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const services = [];
		let _fields = Object.assign({});
		querySnapshot.forEach(_enquiry => {
			const enquiry = Object.assign({});
			const objEnquiryData = Object.assign({}, _enquiry.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				enquiry[key] = value;
			}
			enquiry.documentID = _enquiry.id;

			let dealersettings = this.props.dealersettings;
			if (dealersettings && enquiry.clientID && (enquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
				enquiry.dealerName = CommonHelper.getOtherDealerName(dealersettings, enquiry.clientID);
			}

			if (!_.isEmpty(enquiry.pipeline)) {
				_fields = enquiry;
				services.push(enquiry);
			}

		});

		this.setState({
			services: _.orderBy(services, ["modifiedDate"], ["desc"])
		});
	};

	onTIPCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const _tradeinPro = [];
		let _fields = Object.assign({});
		querySnapshot.forEach(_enquiry => {
			const enquiry = Object.assign({});
			const objEnquiryData = Object.assign({}, _enquiry.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				enquiry[key] = value;
			}
			enquiry.documentID = _enquiry.id;

			let dealersettings = this.props.dealersettings;
			if (dealersettings && enquiry.clientID && (enquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
				enquiry.dealerName = CommonHelper.getOtherDealerName(dealersettings, enquiry.clientID);
			}

			if (!_.isEmpty(enquiry.pipeline)) {
				_fields = enquiry;
				_tradeinPro.push(enquiry);
			}

		});

		this.setState({
			tradeinPro: _.orderBy(_tradeinPro, ["modifiedDate"], ["desc"])
		});
	};

	onEventCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const events = [];
		let _fields = Object.assign({});
		querySnapshot.forEach(_event => {
			const event = Object.assign({});
			const objEnquiryData = Object.assign({}, _event.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				event[key] = value;
			}
			// event.documentID = _event.id;

			let dealersettings = this.props.dealersettings;
			if (dealersettings && event.clientID && (event.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
				event.dealerName = CommonHelper.getOtherDealerName(dealersettings, event.clientID);
			}
			events.push(event);
		});

		this.setState({
			events: _.orderBy(events, ["modifiedDate"], ["desc"])
		});
	};

	onUsersRatingCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const usersRating = [];
		let _fields = Object.assign({});
		querySnapshot.forEach(_userRating => {
			const event = Object.assign({});
			const objEnquiryData = Object.assign({}, _userRating.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				event[key] = value;
			}
			// event.documentID = _userRating.id;
			usersRating.push(event);
		});

		this.setState({
			usersRating: _.orderBy(usersRating, ["addedDate"], ["desc"])
		});
	};

	onFormFeedbackCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const formFeebacks = [];
		let _fields = Object.assign({});
		querySnapshot.forEach(_feedback => {
			const event = Object.assign({});
			const objEnquiryData = Object.assign({}, _feedback.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				event[key] = value;
			}
			//if (!_.isEmpty(event.response))
			formFeebacks.push({
				...event,
				documentID: _feedback.id,
				score: event.score > 100 ? 100 : event.score < 0 ? 0 : event.score
			});
		});

		this.setState({
			formFeedback: _.orderBy(formFeebacks, ["sentDate"], ["desc"])
		});
	};

	onEnquiryOwnedCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const ownedEnquiries = [];

		const { dealersettings } = this.props;
		let _clientSettings = dealersettings && dealersettings.group &&
			dealersettings.group.clients;// && dealersettings.group.clients[contact.clientID] && dealersettings.group.clients[contact.clientID].settings;


		querySnapshot.forEach(_enquiry => {
			const enquiry = Object.assign({}, objEnquiry);
			const ownedEnquiry = Object.assign({}, objOwnedEnquiryVehicle);
			const objEnquiryData = Object.assign({}, _enquiry.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				enquiry[key] = value;
			}
			enquiry.documentID = _enquiry.id;

			if (enquiry.isVehicle && !_.isEmpty(enquiry.requirement)) {
				ownedEnquiry.enquiryID = enquiry.documentID;
				ownedEnquiry.clientID = enquiry.clientID;
				ownedEnquiry.displayID = enquiry.displayID ? enquiry.displayID : '';
				ownedEnquiry.status = enquiry.status;
				ownedEnquiry.regNo = enquiry.regNo ? enquiry.regNo : '';
				ownedEnquiry.owner = enquiry.owner ? enquiry.owner : '';
				ownedEnquiry.chassisNo = enquiry.chassisNo ? enquiry.chassisNo : '';
				ownedEnquiry.currentlyNotOwned = enquiry.currentlyNotOwned ? true : false;
				ownedEnquiry.modifiedDate = enquiry.modifiedDate;

				if (dealersettings && enquiry.clientID && (enquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
					ownedEnquiry.dealerName = CommonHelper.getOtherDealerName(dealersettings, enquiry.clientID);
				}

				if (!_.isEmpty(enquiry.requirement.stock)) {
					ownedEnquiry.make = enquiry.requirement.stock.make
						? enquiry.requirement.stock.make
						: '';
					ownedEnquiry.model = enquiry.requirement.stock.model
						? enquiry.requirement.stock.model
						: '';
					ownedEnquiry.type = enquiry.requirement.stock.type
						? enquiry.requirement.stock.type
						: '';
					ownedEnquiry.year = enquiry.requirement.stock.year
						? enquiry.requirement.stock.year
						: '';
					ownedEnquiry.regNo = enquiry.requirement.stock.regNo ? enquiry.requirement.stock.regNo : '';
					ownedEnquiry.vinNo = enquiry.requirement.stock.vinNo ? enquiry.requirement.stock.vinNo : '';
					ownedEnquiry.extColor = enquiry.requirement.stock.extColor
						? enquiry.requirement.stock.extColor
						: '';
					ownedEnquiry.saleType = enquiry.requirement.saleType
						? CommonHelper.getNameByValue(_clientSettings && _clientSettings[enquiry.clientID] && _clientSettings[enquiry.clientID].settings && _clientSettings[enquiry.clientID].salesType,
							enquiry.requirement.saleType, '')
						: '';
				} else {
					ownedEnquiry.make = enquiry.requirement.make
						? enquiry.requirement.make
						: '';
					ownedEnquiry.model = enquiry.requirement.model
						? enquiry.requirement.model
						: '';
					ownedEnquiry.type = enquiry.requirement.type
						? enquiry.requirement.type
						: '';
					ownedEnquiry.year = enquiry.requirement.year
						? enquiry.requirement.year
						: '';
					ownedEnquiry.regNo = enquiry.requirement.regNo ? enquiry.requirement.regNo : '';
					ownedEnquiry.vinNo = enquiry.requirement.vinNo ? enquiry.requirement.vinNo : '';
					ownedEnquiry.extColor = enquiry.requirement.extColor
						? enquiry.requirement.extColor
						: '';
					ownedEnquiry.saleType = enquiry.requirement.saleType
						? CommonHelper.getNameByValue(_clientSettings && _clientSettings[enquiry.clientID] && _clientSettings[enquiry.clientID].settings && _clientSettings[enquiry.clientID].salesType,
							enquiry.requirement.saleType, '')
						: '';
				}

				ownedEnquiries.push(ownedEnquiry);
			}
		});

		this.setState({
			ownedEnquiryVehicles: _.orderBy(ownedEnquiries, ["modifiedDate"], ["desc"]),
		});
	};

	onActivityCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const activities = [];
		querySnapshot.forEach(_activity => {
			const activity = Object.assign({}, objActivitylog);
			const objActivityData = Object.assign({}, _activity.data());
			for (let [key, value] of Object.entries(objActivityData)) {
				activity[key] = value;
			}
			activity.documentID = _activity.id;
			activities.push(activity);
		});

		this.setState({
			activities: _.orderBy(activities, ["addedDate"], ["desc"]),
		});
	};

	onAmsMeCollectionUpdate = docSnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		if (!docSnapshot.exists) {
			return;
		}

		let amsmeUser = Object.assign({}, docSnapshot.data());
		amsmeUser.documentID = docSnapshot.id;

		this.setState({ amsmeUser: amsmeUser });
	};

	onOwnedCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		let ownedVehicles = [];
		querySnapshot.forEach(_owned => {
			const owned = Object.assign({}, objOwnedVehicle);
			const objOwnedData = Object.assign({}, _owned.data());
			for (let [key, value] of Object.entries(objOwnedData)) {
				owned[key] = value;
			}
			owned.documentID = _owned.id;
			ownedVehicles.push(owned);

			//console.log('onOwnedCollectionUpdate', objOwnedData)
		});

		this.setState({
			ownedVehicles: _.orderBy(ownedVehicles, ["modifiedDate"], ["desc"]),
		});
	};

	async loadEnquiry() {
		const { contactid, dealersettings, clientUsers, groupUsers } = this.props;
		document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		if (contactid && dealersettings) {
			const refData = await this.refContact.doc(contactid).get();

			if (refData.exists) {
				//console.log('E              X             I                S                  T             S', contactid)
				let _clientUsers = [];
				const contact = this.convertContactVM(refData.data());
				contact.documentID = refData.id;
				this.unsubscribeContact = this.refContact
					.doc(contactid)
					.onSnapshot(this.onContactCollectionUpdate);

				// this.unsubscribeActivity = this.refActivity
				// 	.where('contactID', '==', refData.id)
				// 	.where('isDeleted', '==', false)
				// 	//.orderBy('addedDate', 'desc')
				// 	.onSnapshot(this.onActivityCollectionUpdate);

				// this.unsubscribeFleet = firestoreDB(this.props.dealersettings).firestore().collection('fleet')
				// 	.where('contact.documentID', '==', refData.id)
				// 	.where('isDeleted', '==', false)
				// 	//.orderBy('addedDate', 'desc')
				// 	.onSnapshot(this.onFleetCollectionUpdate);

				// this.unsubscribeEnquiryOwned = this.refEnquiry
				// 	.where('contact.documentID', '==', refData.id)
				// 	.where('status', '==', enquiryStatus.DELIEVERD)
				// 	//.orderBy('modifiedDate', 'desc')
				// 	.where('isDeleted', '==', false)
				// 	.onSnapshot(this.onEnquiryOwnedCollectionUpdate);

				if (!_.isEmpty(contact.amsMe) && !_.isEmpty(contact.amsMe.meID)) {
					this.unsubscribeAmsMe = window.firebase.firestore()
						.collection(`amsmeUsers`)
						.doc(contact.amsMe.meID)
						.onSnapshot(this.onAmsMeCollectionUpdate);
				}

				this.unsubscribeOwned = firestoreDB(this.props.dealersettings)
					.firestore()
					.collection(`contactDetails/${refData.id}/ownedVehicles`)
					//.orderBy('modifiedDate', 'desc')
					//.orderBy('make', 'asc')
					.onSnapshot(this.onOwnedCollectionUpdate);

				this.unsubscribeService = this.refServiceJobs
					.where('contact.documentID', '==', refData.id)
					.where('isDeleted', '==', false)
					.onSnapshot(this.onServiceJobCollectionUpdate);

				// this.unsubscribeTradeinPro = this.refTIP
				// 	.where('contact.documentID', '==', refData.id)
				// 	.where('isDeleted', '==', false)
				// 	.onSnapshot(this.onTIPCollectionUpdate);

				// this.unsubscribeService = this.refEvents
				// 	.where('contactID', '==', refData.id)
				// 	.onSnapshot(this.onEventCollectionUpdate);

				// this.unsubscribeUsersRating = this.refUsersRating
				// 	.where('contactID', '==', refData.id)
				// 	.onSnapshot(this.onUsersRatingCollectionUpdate);

				// this.unsubscribeFormFeedback = this.refUsersFeedback
				// 	.where('clientID', '==', dealersettings.client.id)
				// 	.where('contactID', '==', refData.id)
				// 	.onSnapshot(this.onFormFeedbackCollectionUpdate);

				// // const refActivityData = await this.refActivity
				// // 	.where('contactID', '==', refData.id)
				// // 	.where('isDeleted', '==', false)
				// // 	//.orderBy('addedDate', 'desc')
				// // 	.get();
				let campaigns = [];
				let defaultCountry = 'AU';
				let _setttings = contact.clientID && dealersettings && dealersettings.group &&
					dealersettings.group.clients && dealersettings.group.clients[contact.clientID] && dealersettings.group.clients[contact.clientID].settings;
				if (_.isEmpty(_setttings))
					_setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

				if (_setttings) {
					defaultCountry = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
					//defaultCountry = dealersettings.group.clients[contact.clientID] && dealersettings.group.clients[contact.clientID].countryCode ? dealersettings.group.clients[contact.clientID].countryCode.toUpperCase() : 'AU';

					titles = [];
					_setttings.titles && _setttings.titles.forEach((doc) => {
						titles.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name
						});
					});

					_setttings.campaigns && _setttings.campaigns.forEach(doc => {
						campaigns.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name,
						});
					});

				}

				if (!_.isEmpty(dealersettings)) {

					languages = [];
					dealersettings.languages && dealersettings.languages.forEach((doc) => {
						languages.push({
							value: doc.code,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name
						});
					});

					license_state = [];
					defaultCountry && dealersettings.states &&
						dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
							license_state.push({
								value: doc.code,
								active: _.isBoolean(doc.active) ? doc.active : true,
								label: doc.name
							});

						});
				}

				let _allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
				if (contact.clientID && !_.isEmpty(_allUsers)) {
					_allUsers.filter(e => e.clientID === contact.clientID).forEach((rec) => {
						_clientUsers.push({
							value: rec.id,
							active: _.isBoolean(rec.active) ? rec.active : true,
							searchlabel: rec.name,
							label: <div className="drop-image">
								<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
								{rec.name}
							</div>
						});
					});
				}
				else if (!_.isEmpty(_allUsers)) {
					_.uniq(_allUsers.map(v => _.pick(v, ['id']).id)).map(e => { return { ..._.find(_allUsers, { id: e }) } }).forEach((rec) => {
						_clientUsers.push({
							value: rec.id,
							active: _.isBoolean(rec.active) ? rec.active : true,
							searchlabel: rec.name,
							label: <div className="drop-image">
								<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
								{rec.name}
							</div>
						});
					});
				}

				//console.log('_clientUsers', _clientUsers)
				//console.log('groupUsers', groupUsers)
				//console.log('clientUsers', clientUsers, contact.clientID)
				//console.log('dealersettings', dealersettings)

				this.setState({
					contactID: refData.id,
					contact: contact,
					clientUsers: _clientUsers,
					campaigns,
				});

				this.loadPagingEnquiry();
				// try {
				// 	let _searchObj = {
				// 		type: "getEnquiriesCount",
				// 		params: JSON.stringify({
				// 			contactID: contactid
				// 		}),
				// 	}
				// 	const getData = window.firebase.functions().httpsCallable('generic-getData');
				// 	getData(_searchObj).then((resp) => {
				// 		if (!_.isEmpty(resp) && !_.isEmpty(resp.data) && resp.data.success) {
				// 			const data = resp.data.data[0]
				// 			this.setState({
				// 				enquiriesCount: data.enquiries
				// 			});
				// 		}
				// 	});
				// } catch (err) { }
			}
			else {
				this.setState({
					pagenotFound: true,
					isLoading: false,
					contactID: null
				})
			}
		}
	}

	loadPagingEnquiry() {
		let lastRecord = this.state.enquiryhasMore;

		const enquiryAllStatus = Object.assign([], this.props.dealersettings?.client?.settings?.enquiryStatus)
		let _statuses = _.uniq(_.map(enquiryAllStatus.filter(e => e.dynamic === true && !e.afterSold), function (e) { return e.value }));

		var enquiryQuery = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
			.where('contact.documentID', '==', this.props.contactid)
			//.where('status', 'in', _statuses)
			.where('isDeleted', '==', false);

		// if (lastRecord.length > 0) {
		// 	enquiryQuery = enquiryQuery
		// 		.startAfter(lastRecord[0].lastDoc)
		// 		.limit(this.state.enquiryPageLimit)
		// }
		// else {
		// 	enquiryQuery = enquiryQuery
		// 		.limit(this.state.enquiryPageLimit)
		// }
		this.unsubscribeEnquiry = enquiryQuery
			.onSnapshot(this.onEnquiryCollectionUpdate);
	}

	componentDidMount() {
		localStorage.removeItem("objSales");
		this._isMounted = true;
		if (this._isMounted) {
			this.loadEnquiry();
			setTimeout(() => this.scrollIntoComments(), 4000);
		}
	}

	componentDidUpdate() {
		if (this.state.loading) {
			this.clearAllSubscribe();
			this.loadEnquiry();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.clearAllSubscribe();
		document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
	}

	clearAllSubscribe = () => {
		this.unsubscribeContact && this.unsubscribeContact();
		this.unsubscribeActivity && this.unsubscribeActivity();
		this.unsubscribeFleet && this.unsubscribeFleet();
		this.unsubscribeEnquiry && this.unsubscribeEnquiry();
		this.unsubscribeService && this.unsubscribeService();
		this.unsubscribeTradeinPro && this.unsubscribeTradeinPro();
		this.unsubscribeEnquiryOwned && this.unsubscribeEnquiryOwned();
		this.unsubscribeOwned && this.unsubscribeOwned();
		this.unsubscribeAmsMe && this.unsubscribeAmsMe();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.contactid !== state.contactID && state.contactID) {
			return {
				loading: true,
				contactID: null,
			};
		} else {
			return {
				loading: false,
			};
		}
		return null;
	}

	scrollIntoComments = () => {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let __comments = params.get('comments');

		if (__comments && document.getElementById('enquiry-item-' + __comments)) {
			document.getElementById('enquiry-item-' + __comments).scrollIntoView({
				behavior: "smooth",
				block: "center"
			})
		}

	}
	//#endregion

	//#region CHANGE EVENT ENQUIRY

	handleSelectSave = (e, data) => {
		this.saveContactField(data.name, e.value);
	};

	saveContactField = (name, val) => {
		let state = Object.assign({}, this.state.contact);
		state[name] = val;

		state.modifiedBy = this.props.dealersettings
			? this.props.dealersettings.id
			: '';
		state.modifiedDate = window.firebase.firestore.Timestamp.now();
		this.setState({ contact: state });
		const updateRef = this.refContact.doc(this.state.contactID);
		updateRef
			.update({
				[name]: val,
				modifiedBy: state.modifiedBy,
				modifiedDate: state.modifiedDate,
				modifiedFrom: 'web'
			})
			.then(docRef => {
				toast.notify('Contact updated successfully', {
					duration: 2000,
				});

			})
			.catch(error => {
				console.error('Error updating enquiries: ', error);
			});
	};

	handleLeftActiveKey = index => {
		this.setState({ activeLeftKey: index });
	};
	handleRightActiveKey = index => {
		this.setState({ activeRightKey: index });
	};

	//#endregion

	//#region OWNED VEHICLE
	ownedCount = () => {
		const { ownedEnquiryVehicles, ownedVehicles } = this.state;

		//return parseInt(ownedEnquiryVehicles.length + ownedVehicles.length);
		return parseInt(ownedVehicles.length);
	};

	handleOwnedClose = ID => {
		this.setState({
			ownedModal: {
				show: false,
				ID: '',
				title: '',
				clsActive: '',
			},
		});
	};

	handleEditOwned = ID => {
		this.setState({
			ownedModal: {
				show: true,
				ID: ID,
				title: 'editVehicle',
				clsActive: 'overlay-modal active',
			},
		});
	};

	handleOwnedEnquiryClose = (data) => {
		this.setState({
			ownedEnquiryModal:
			{
				show: false,
				data: null,
				title: '',
				clsActive: ''
			}
		});
	}

	handleEditOwnedEnquiry = (data) => {
		this.setState({
			ownedEnquiryModal:
			{
				show: true,
				data: data,
				title: 'editVehicle',
				clsActive: 'overlay-modal active'
			}
		});
	}
	//#endregion

	//#region RE-AUTHENTICATION
	handleReAuthOpen = () => {
		this.setState({ showReAuthentication: true });
	}

	handleReAuthOpenClose = (data) => {
		if (!_.isEmpty(data)) {
			this.forceUpdate();
		}
		this.setState({ showReAuthentication: false });
	}
	//#endregion

	handleSaveAuditLog = (imagePath) => {
		const { documentID } = this.state.contact;
		const { dealersettings } = this.props;
		let _name = CommonHelper.getFullNameWithID(null, this.state.contact, '');
		let _objLogData = {
			notes: 'License image viewed for ' + _name,
			type: 'licenseImage',
			subType: 'contactDetails',
			recordId: documentID ? documentID : null,
			contactID: documentID ? documentID : null,
			imageURL: imagePath,
		}

		_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
		CommonHelper.saveAuditLog(_objLogData);
	}

	handleEventOpen = (data) => {
		this.setState({
			eventModal:
			{
				show: true,
				data: data,
				title: 'Event',
				clsActive: 'overlay-modal active'
			}
		});
	}

	handleEventClose = (data) => {
		this.setState({
			eventModal:
			{
				show: false,
				data: null,
				title: '',
				clsActive: ''
			}
		});
	}

	handleEditFleet = (testDriveID, status) => {
		this.setState({
			fleetModal:
			{
				show: true,
				ID: testDriveID,
				title: 'loanVehicle',
				clsActive: 'overlay-modal active'
			}
		});
	};

	handleCloseFleet = () => {
		this.setState({
			fleetModal:
			{
				show: false,
				ID: '',
				title: '',
				clsActive: ''
			}
		});
	}

	//#region CREATE NEW

	isValidNewEnquiry = () => {
		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, 'You want to create a new enquiry'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then(result => {
			if (result.value) {
				const { dealersettings, history } = this.props;
				const { contact } = this.state;
				CommonHelper.loadNewEnquiry(dealersettings, contact, history);
			}
		});
	}

	isValidNewService = () => {
		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, 'You want to create a new service'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then(result => {
			if (result.value) {
				const { dealersettings, history } = this.props;
				const { contact } = this.state;

				const checkInAccess = ((!_.isEmpty(dealersettings?.rolePermissions?.servicePermissions) && dealersettings.rolePermissions.servicePermissions?.checkInAccess) ? true : false);
				CommonHelper.loadNewService(dealersettings, contact, history, (checkInAccess ? serviceStatus.CHECKIN : serviceStatus.NEWSERVICE));
			}
		});
	}

	isValidNewAppraisal = () => {
		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, 'You want to create a new appraisal'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then(result => {
			if (result.value) {
				const { dealersettings, history } = this.props;
				const { contact } = this.state;
				CommonHelper.loadNewTradeInPro(dealersettings, contact, history);
			}
		});
	}

	//#endregion

	//#region FILE CHANGES

	handleFileClose = () => {
		this.setState({
			fileModal: {
				show: false,
			},
		});
	}

	handleFileOpen = () => {
		this.setState({
			fileModal: {
				show: true,
			},
		});
	}
	//#endregion

	bindSaleType = (enquiries) => {
		let enquiry = _.orderBy(enquiries.filter(e => !_.isEmpty(e.requirement) && e.isVehicle), ["modifiedDate"], ["desc"])[0];

		let _salesType = enquiry?.requirement?.stock?.saleType ? enquiry.requirement.stock.saleType : enquiry?.requirement?.saleType
		let salesType = _salesType ? CommonHelper.getNameByValue(this.props.dealersettings?.client?.settings?.salesType, _salesType, '') : '';

		return salesType ? (salesType.toLowerCase() === 'preowned' ? 'CPO' : salesType.toUpperCase()) : null
	}


	render() {
		//console.log('R E - R E N D E R - C O N T A C T - E D I T ');

		const {
			displayID,
			contactType,
			// title,
			// firstName,
			// lastName,
			clientID,
			preferredName,
			dob,
			isVIP,
			banLoanVehicle,
			prevPurchased,
			//businessContact,
			gender,
			maritalStatus,
			language,
			nationality,
			address,
			owner,
			company,
			contactMethod,
			licenseNo,
			licenseExpiry,
			licenseFrontURL,
			licenseBackURL,
			phone,
			phoneCode,
			email,
			licenseState,
			secondaryLicenseFrontUrl,
			secondaryLicenseBackUrl,
			campaign,
			origin,
			licenseType,
			interests,
			optinPhone,
			optinPost,
			optinEmail,
			optinSMS, marketingOptinEmail, marketingOptinSMS,
			dependents,
			driverName,
			addedBy,
			designation,
			phones, emails,
			secondaryTitle, secondaryPreferredName, secondaryPhoneCode,
			secondaryFirstName, secondaryLastName, secondaryPhones, secondaryEmails, secondaryPhone, secondaryEmail,
			dealerName, visitingCardURL,
			isDeleted, amsMe, gdprDeleted, creditScoreTerms,
			userImageURL, isDLScan, tags, terms,
			Vehicles, capTags, capOwner
		} = this.state.contact;

		const {
			activeLeftKey,
			activeRightKey,
			enquiries,
			activities,
			showpanel,
			contactModal,
			ownedModal,
			ownedEnquiryModal,
			eventModal,
			showServicepanel, showTradeinPropanel,
			pagenotFound, amsmeUser,
			contactID,
			enqCampaigns,
			campaigns,
			loanVehicles,
			fleetModal,
			fileModal, ownedVehicles
		} = this.state;

		//let _vehicles = Object.assign([], Vehicles);
		const _activeVehicles = ownedVehicles.filter(e => e.owned === true);
		const _ownedVehicles = ownedVehicles.filter(e => e.owned !== true);

		const lastActivity = Object.assign(
			{},
			activities.filter(e => e.type === 'activity')[0],
		);
		const { dealersettings, groupUsers, clientUsers } = this.props;
		const _allUser = !_.isEmpty(groupUsers) ? groupUsers : clientUsers

		const isGroupDealer = localStorage.defaultModule !== 'oem' && clientID && !_.isEmpty(dealersettings) && clientID !== dealersettings.client.id ? true : false;
		const canModifyContact = isDeleted || gdprDeleted ? false : true;

		const modifyContactOwner = (canModifyContact && (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.permissions) &&
			dealersettings.rolePermissions.permissions.modifyContactOwner) ? (isGroupDealer ? false : true) : false);

		const isPrivacyEnabled = CommonHelper.isPrivacyEnabled(dealersettings);

		const _moduleSettings = (!_.isEmpty(dealersettings?.client?.moduleSettings) ? dealersettings.client.moduleSettings : null);

		const enquiryEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.sales) &&
			_moduleSettings.sales.enabled) ? true : false);

		const serviceEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.service) &&
			_moduleSettings.service.enabled) ? true : false);

		const tradeinProEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.tradeinPro) &&
			_moduleSettings.tradeinPro.enabled) ? true : false);

		const fusioneventsEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.fusionevents) &&
			_moduleSettings.fusionevents.enabled) ? true : false);

		const eventsProEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.eventsPro) &&
			_moduleSettings.eventsPro.enabled) ? true : false);

		const enquiryAccess = (enquiryEnabled && (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.modules) &&
			dealersettings.rolePermissions.modules.enquiry) ? true : false);

		const serviceAccess = (serviceEnabled && (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.modules) &&
			dealersettings.rolePermissions.modules.service) ? true : false);

		const tradeinProAccess = (tradeinProEnabled && (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.modules) &&
			dealersettings.rolePermissions.modules.tradeinPro) ? true : false);

		const loanVehicleAccess = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.modules) &&
			dealersettings.rolePermissions.modules.loanVehicle) ? true : false);

		const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
		) ? true : false);

		const clientSettings = (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.clientSettings)) ? dealersettings.client.clientSettings : null;

		const liteVersion = (!_.isEmpty(clientSettings) && clientSettings.liteVersion) ? true : false;

		const _permissions = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

		const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
		const disableToAddEnquiry = ((!_.isEmpty(_permissions) && _permissions.disableToAddEnquiry) ? true : false);
		const accessToCreateLeadByContactOwner = ((!_.isEmpty(_permissions) && _permissions.accessToCreateLeadByContactOwner) ? true : false);
		const createLead = accessToCreateLeadByContactOwner && owner !== localStorage.uid ? false : true;

		const _userRoles = (dealersettings && dealersettings.roleID &&
			dealersettings.client && dealersettings.client.roles &&
			dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);

		const isInboundAccess = _userRoles && _userRoles.pipeline && _userRoles.pipeline.some(e => e === 'LeadsBucket') ? true : false;
		const isSalesAccess = _userRoles && _userRoles.pipeline && _userRoles.pipeline.some(e => e !== 'LeadsBucket') ? true : false;

		const canCreateEnquiry = canModifyContact && localStorage.defaultModule === 'enquiry' && (dealersettings &&
			dealersettings.client &&
			dealersettings.client.settings &&
			dealersettings.client.settings.pipelines.length > 0 &&
			(isInboundAccess || isSalesAccess) && !liteVersion && !readOnlyEnquiryView && !disableToAddEnquiry && createLead) ? true : false;

		const _servicePermissions = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.servicePermissions)) ? dealersettings.rolePermissions.servicePermissions : null);
		const scheduleAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.scheduleAccess) ? true : false);
		const checkInAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.checkInAccess) ? true : false);

		const canCreateService = canModifyContact && localStorage.defaultModule === 'service' && (dealersettings &&
			dealersettings.client &&
			dealersettings.client.services &&
			dealersettings.client.services.pipelines.length > 0 &&
			(scheduleAccess || checkInAccess) && !liteVersion) ? true : false;

		const canCreateAppraisal = canModifyContact && localStorage.defaultModule === 'tradeinPro' && (dealersettings &&
			dealersettings.client &&
			dealersettings.client.tradeinPro &&
			dealersettings.client.tradeinPro.pipelines.length > 0 && !liteVersion) ? true : false;


		const isAmsMeContact = amsMeEnabled && clientID && contactID && !_.isEmpty(amsmeUser) && !_.isEmpty(amsmeUser.amsProLink) &&
			amsmeUser.amsProLink.filter(e => e.clientID === clientID && e.contactID === contactID)[0] ? true : false;

		const amsMeID = !_.isEmpty(amsMe) && !_.isEmpty(amsMe.meID) ? amsMe.meID : null;

		const enquiryAllStatus = Object.assign([], dealersettings?.client?.settings?.enquiryStatus)
		//let _statuses = _.uniq(_.map(enquiryAllStatus.filter(e => e.dynamic === true && !e.afterSold), function (e) { return e.value }));
		const _openEnquiries = CommonHelper.getEnquiriesByStatus(enquiryAllStatus, enquiryStatus.OPEN, enquiries);
		const _wonEnquiries = CommonHelper.getEnquiriesByStatus(enquiryAllStatus, enquiryStatus.WON, enquiries);

		const aircallEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "aircall")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "aircall")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "aircall")[0].enabled === true
		) ? true : false);

		const ringcentralEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0].enabled === true
		) ? true : false);

		let _resPerson = capOwner && !_.isEmpty(CommonHelper.getUserNamebyId(_allUser, capOwner)) ? capOwner : owner;
		let _contactType = !_.isEmpty(ownedVehicles) ? 'Current Customer' : 'Potential Customer'

		return contactID ? (
			<>
				<div className='middle-wrapper'>
					{/* <div className="filter-panel"><div className="float-left fleet-head"><h3><Translate text={'contactDetails'} /></h3></div></div> */}

					<div className="enquiry-detailswrap">

						<div className="enquiry-detail-head">
							<div className="float-left">
								<div className="contactdetails-userthumb-img ">
									<img src={CommonHelper.showUserAvatar(userImageURL, CommonHelper.displayContactName(null, this.state.contact, ''))} alt=""
										className="rounded-circle img-object-fit" />
								</div>
							</div>

							<div className="float-left">
								<div className='enquiry-details-contact'>
									{CommonHelper.displayContactName(titles, this.state.contact, '--')}
								</div>

								{preferredName ? (
									<span className='enquiry-details-preferred'> ({preferredName})</span>
								) : (
									<></>
								)}

								<div className="enquiry-details-optin">
									{
										optinPhone === false
											?
											<span><Tooltip placement="bottom" title={<Translate text={'optoutPhone'} />}>
												<i className={`ico icon-no-call`}></i>
											</Tooltip></span>
											:
											<></>
									}
									{
										optinSMS === false
											?
											<span><Tooltip placement="bottom" title={<Translate text={'optoutSMS'} />}>
												<i className={`ico icon-no-sms`}></i>
											</Tooltip></span>
											:
											<></>
									}
									{
										optinEmail === false
											?
											<span><Tooltip placement="bottom" title={<Translate text={'optoutEmail'} />}>
												<i className={`ico icon-no-email`}></i>
											</Tooltip></span>
											:
											<></>
									}
									{
										optinPost === false
											?
											<span><Tooltip placement="bottom" title={<Translate text={'optoutPost'} />}>
												<i className={`ico icon-no-post`}></i>
											</Tooltip></span>
											:
											<></>
									}
									{
										marketingOptinEmail === false
											?
											<span><Tooltip placement="bottom" title={<Translate text={'marketingoptoutEmail'} />}>
												<i className={`ico icon-no-email`}></i>
											</Tooltip></span>
											:
											<></>
									}
									{
										marketingOptinSMS === false
											?
											<span><Tooltip placement="bottom" title={<Translate text={'marketingoptoutSMS'} />}>
												<i className={`ico icon-no-sms`}></i>
											</Tooltip></span>
											:
											<></>
									}
									{
										banLoanVehicle ? <span><Tooltip placement="bottom" title={<Translate text={'banLoanVehicle'} />}>
											<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, `ico icon-ban-loan`)}></i>
										</Tooltip></span> : <></>
									}
								</div>
								{/* <div className="enquiry-title">
									{CommonHelper.displayContactName(titles, this.state.contact, '--')}
																
								</div> */}

								<div className="enquiry-subtitle">
									{displayID ? displayID : (`CNT-` + this.state.contactID)}

									{
										tags && tags.length > 0 ? (<>
											{
												tags.map((r, i) => {
													return r.subType ? (
														<span key={i} className="lead-badge-border">
															<div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>
															<div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{r.subType}</div>
														</span>
													) : (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
												})
											}
										</>) : (<></>)
									}


									{_contactType ? (
										<div className='badge badge-pill badge-white ml-2'>
											{_contactType}
										</div>
									) : (
										<></>
									)}


									{isDLScan ? (
										<div className="badge badge-pill badge-white ml-2">
											<img src={_images.meSent} alt="" width="14" className="mr-1" />
											{`Digital Driving Licence`}
										</div>
									) : (
										<></>
									)}

									{!_.isEmpty(terms) ? <div className="badge badge-pill badge-white ml-2">
										<img src={_images.meSent} alt="" width="14" className="mr-1" />
										{`Customer Consent ${terms.verbalConsent ? '(Verbal)' : (terms.consentType ? `(${terms.consentType})` : '')}`}
									</div> : <></>}

									{!_.isEmpty(creditScoreTerms) ? <div className="badge badge-pill badge-white ml-2">
										<img src={_images.meSent} alt="" width="14" className="mr-1" />
										{`Finance Credit Consent ${creditScoreTerms.verbalConsent ? '(Verbal)' : (creditScoreTerms.consentType ? `(${creditScoreTerms.consentType})` : '')}`}
									</div> : <></>}

								</div>
							</div>


							<div className="float-right">
								<div className='filter-item-btn ml-2 mt-2'>
									<a
										href='#'
										onClick={e => {
											e.preventDefault();
											let search = window.location.search;
											let params = new URLSearchParams(search);
											let backURL = params.get('back-url');

											if (backURL) this.props.history.push(backURL);
											else this.props.history.push('/contacts');
										}}
									>
										<i className='fa fa-chevron-left' aria-hidden='true'></i>{' '}
										<Translate text={'back'} />
									</a>
								</div>

								<Dropdown className='enquiry-view-more'>
									<Dropdown.Toggle as={CustomToggle} className='common-button'>
										<i className='ico icon-more'></i>
									</Dropdown.Toggle>
									<Dropdown.Menu
										as={CustomMenu}
										ChildClass="more-dropdown dropsub-fix"
										xplacement="bottom-end"
									>
										<Dropdown.Item eventKey="1" onClick={(e) => { this.handleSharePanelOpen(e) }}><i className="ico icon-share"></i> <Translate text={'Share'} /> </Dropdown.Item>
										{
											(!_.isEmpty(dealersettings.rolePermissions) &&
												!_.isEmpty(dealersettings.rolePermissions.permissions) &&
												dealersettings.rolePermissions.permissions.deleteContact)
												?
												<Dropdown.Item eventKey="2" onClick={(e) => { this.handleDeleteContact(e); }}><i className="ico icon-delete"></i> <Translate text={'delete'} /> </Dropdown.Item>
												:
												<></>
										}
										{
											Boolean(this.props.dealersettings.superAdmin) ? (
												<Dropdown.Item eventKey="3" onClick={(e) => {
													const sendGDPREmail = window.firebase.functions().httpsCallable('contact-sendGDPREmail');
													sendGDPREmail(this.state.contact).then((resp) => {
														if (!_.isEmpty(resp) && !_.isEmpty(resp.data) && resp.data.success) {
															toast.notify('Email sent successfully', {
																duration: 2000,
															});
														}
														else {
															Swal.fire(resp.data.message, '', 'error')
														}
													});
												}}><i className="ico icon-email"></i> <Translate text={'Test GDPR Email'} /> </Dropdown.Item>
											) : (<></>)
										}
									</Dropdown.Menu>
								</Dropdown>

							</div>

						</div>


						<div className="enquiry-detail-bottom">
							<div className="float-left mt-2">
								<ul className="porsche-enquiry-details">
									{language ? (
										<li title='language'> <i className="ico icon-activity-world"></i> {language}</li>
									) : (
										<></>
									)}
									{
										aircallEnabled && phone ? (
											<li> <i className="ico icon-call"></i> <a onClick={(e) => {
												e.preventDefault();
												this.props.dialNumber(CommonHelper.validPhone(phone, phoneCode), null, this.state.contactID)
											}} href='#' className='hyperlink-blue'>{phone ? CommonHelper.phoneFormat(phoneCode, phone, dealersettings) : '--'}</a></li>
										) : ringcentralEnabled && phone ? (
											<li> <i className="ico icon-call"></i> <a onClick={(e) => {
												e.preventDefault();
												this.props.dialRingcentral(CommonHelper.validPhone(phone, phoneCode), null, this.state.contactID)
											}} href='#' className='hyperlink-blue'>{phone ? CommonHelper.phoneFormat(phoneCode, phone, dealersettings) : '--'}</a></li>
										) : (
											<li> <i className="ico icon-call"></i> {phone ? CommonHelper.phoneFormat(phoneCode, phone, dealersettings) : '--'}</li>
										)
									}

									{email && (<li>
										<i className='ico icon-email'></i>
										<SendMail
											email={email}
											contact={this.state.contact}
											contactBasicVM={this.state.contactBasicVM}
											dealersettings={this.props.dealersettings}
										>
										</SendMail>
									</li>)}

									{!_.isEmpty(company) ? (
										<li title='Company'>
											{' '}
											<i className='ico icon-company'></i> <Link to={`/company/details/${company.documentID}?back-url=/contacts/details/${this.state.contactID}`}>{company.name}</Link>
										</li>
									) : (
										<></>
									)}

									{isVIP ? (
										<li> <i className="ico icon-star-active"></i> VIP</li>
									) : (
										<></>
									)}
									{
										!_.isEmpty(capTags) && capTags.map((r, i) => {
											return <li key={i}><i className="ico icon-Stock"></i>  {r}</li>
										})
									}
									{/* {
										!_.isEmpty(this.bindSaleType(enquiries)) && (<li title='Sale Type'><i className="ico icon-Stock"></i>  {this.bindSaleType(enquiries)}</li>)
									} */}

									{
										tags && tags.length > 0 && (<li><i className="ico icon-Contacts"></i>  Active BP</li>)
									}

								</ul>
							</div>

							<div className="float-right">
								<InviteAmsMe
									dealersettings={dealersettings}
									clientUsers={this.props.clientUsers}
									amsMeEnabled={amsMeEnabled}
									isContact={true}
									isAmsMeContact={isAmsMeContact}
									contact={this.state.contact}
								>
								</InviteAmsMe>
							</div>
						</div>

						<div className="porsche-detail-bottom">
							<div className="float-left">
								<ul className="porsche-list-details">
									{dealerName ? (
										<li> <i className="ico icon-dealers"></i><strong>{dealerName}</strong></li>
									) : (
										<></>
									)}
								</ul>
							</div>

							<div className="float-right">
								<div className="porsche-item-btn ml-3">
									<div className="porsche-designation">Sales Consultant</div>
									<div className='filter-item-btn filter-owner-btn ml-2'>
										<ReactSelect
											options={this.state.clientUsers}
											name={'owner'}
											placeholder={'select ownership'}
											onChange={this.handleSelectSave}
											value={owner ? owner : addedBy}
											classNamePrefix={`cursor-pointer basic-select`}
											removeClearable={true}
											isSearchable={true}
											isDisabled={modifyContactOwner ? false : true}
										></ReactSelect>
									</div>
								</div>
							</div>

						</div>

					</div>

					{/**=================================================================================================================================================== */}

					{
						!canModifyContact || isDeleted || gdprDeleted
							?
							<>
								<div className="enquiry-stagewrap">
									<div className="enquiry-stagewrap-head">
										<div className="float-left">
											{
												!canModifyContact ? (
													<div className="pipeline-enquiry-status ml-2">
														<div className="badge badge-pill badge-readonly">
															<i className="ico icon-lock mr-1"> </i> <Translate text={'readonly'} upperCase={true} />
														</div>
													</div>
												) : (
													<></>
												)
											}

											{
												isDeleted ? (
													<div className="pipeline-enquiry-status ml-2">
														<div className="badge badge-pill badge-readonly">
															<i className="ico icon-delete mr-1"> </i><Translate text={'deleted'} upperCase={true} />
														</div>
													</div>
												) : (
													<></>
												)
											}

											{
												gdprDeleted ? (
													<div className="pipeline-enquiry-status ml-2">
														<div className="badge badge-pill badge-merged">
															<i className="ico icon-delete mr-1"> </i><Translate text={'gdprDeleted'} upperCase={true} />
														</div>
													</div>
												) : (
													<></>
												)
											}

										</div>
									</div>
								</div>
							</>
							:
							<>
							</>
					}


					<div className='clearfix'></div>

					<div className='enquirydetail-grid flex-column mt-4'>
						{/** LEFT TAB */}
						<aside className='small-column mr-3'>
							<div className='enquiry-sidebar'>
								<div className="contact-info-porsche-section-head"> <Translate text={'vehicleDetails'} /></div>
								<Accordion defaultActiveKey='01'>

									{
										enquiryAccess
											?
											<>


												{/** OPEN ENQUIRY INFO */}
												<Card>
													<Accordion.Toggle
														as={Card.Header}
														eventKey='01'
														onClick={e => {
															e.preventDefault();

															if (activeRightKey === '01')
																this.handleRightActiveKey(null);
															else this.handleRightActiveKey('01');
														}}
													>
														<div className='mb-0'>
															{
																canCreateEnquiry
																	?
																	<>
																		<div className='add-button'>
																			<a
																				href='#'
																				onClick={e => {
																					e.preventDefault();
																					e.stopPropagation();
																					this.isValidNewEnquiry();
																				}}
																				className='mini-button'
																			>
																				{' '}
																				<i className='ico icon-add'></i>
																			</a>
																		</div>
																	</>
																	:
																	<>
																	</>
															}

															<div className='cardlink'>
																<a
																	onClick={e => {
																		e.preventDefault();
																	}}
																	className={`btn btn-link ${activeRightKey === '01' ? '' : 'collapsed'
																		}`}
																>
																	<i className="ico icon-enquiry" title="Enquiry"></i>  Open Leads
																	{
																		_openEnquiries && _openEnquiries.length > 0 ? (
																			<span className='badge badge-pill badge-cafe ml-1'>
																				{_openEnquiries.length}
																			</span>
																		) : (
																			<></>
																		)
																	}
																	<i className='arrow-collapse'></i>
																</a>
															</div>
														</div>
													</Accordion.Toggle>

													<Accordion.Collapse eventKey='01'>
														<Card.Body>
															{_openEnquiries &&
																_openEnquiries.length > 0 ? (
																<>
																	{_openEnquiries.map((_enquiry, index) => (
																		<EnquiryInfo
																			key={index}
																			enquiry={_enquiry}
																			clientUsers={this.props.clientUsers}
																			groupUsers={this.props.groupUsers}
																			sidepanelOpen={this.sidepanelOpen}
																			history={this.props.history}
																			showMore={false}
																			dealersettings={dealersettings}
																			campaigns={campaigns}
																		></EnquiryInfo>
																	))}
																</>
															) : (
																<>
																	<div className='text-center p-3'>
																		<p>
																			<Translate text={'No Lead Available'} />
																		</p>
																		{
																			canCreateEnquiry
																				?
																				<button
																					className='btn btn-sm btn-default'
																					onClick={e => {
																						e.preventDefault();
																						this.isValidNewEnquiry();
																					}}
																				>
																					<i className='ico icon-add mr-1'></i>{' '}
																					<Translate text={'add'} />
																				</button>
																				:
																				<>
																				</>
																		}
																	</div>
																</>
															)}
														</Card.Body>
													</Accordion.Collapse>
												</Card>

												{/** LEAD HISTORY INFO */}
												<Card>
													<Accordion.Toggle
														as={Card.Header}
														eventKey='002'
														onClick={e => {
															e.preventDefault();

															if (activeRightKey === '002')
																this.handleRightActiveKey(null);
															else this.handleRightActiveKey('002');
														}}
													>
														<div className='mb-0'>


															<div className='cardlink'>
																<a
																	onClick={e => {
																		e.preventDefault();
																	}}
																	className={`btn btn-link ${activeRightKey === '002' ? '' : 'collapsed'
																		}`}
																>
																	<i className="ico icon-history" title="Enquiry"></i>   Lead History
																	{
																		_wonEnquiries && _wonEnquiries.length > 0 ? (
																			<span className='badge badge-pill badge-cafe ml-1'>
																				{_wonEnquiries.length}
																			</span>
																		) : (
																			<></>
																		)
																	}
																	<i className='arrow-collapse'></i>
																</a>
															</div>
														</div>
													</Accordion.Toggle>

													<Accordion.Collapse eventKey='002'>
														<Card.Body>
															{_wonEnquiries &&
																_wonEnquiries.length > 0 ? (
																<>
																	{_wonEnquiries.map((_enquiry, index) => (
																		<EnquiryInfo
																			key={index}
																			enquiry={_enquiry}
																			clientUsers={this.props.clientUsers}
																			groupUsers={this.props.groupUsers}
																			sidepanelOpen={this.sidepanelOpen}
																			history={this.props.history}
																			showMore={false}
																			dealersettings={dealersettings}
																			campaigns={campaigns}
																		></EnquiryInfo>
																	))}

																</>
															) : (
																<>
																	<div className='text-center p-3'>
																		<p>
																			<Translate text={'No Lead Available'} />
																		</p>

																	</div>
																</>
															)}
														</Card.Body>
													</Accordion.Collapse>
												</Card>

												{/** ACTIVE VEHICLES INFO */}
												<Card>
													<Accordion.Toggle
														as={Card.Header}
														eventKey='03'
														onClick={e => {
															e.preventDefault();

															if (activeRightKey === '03')
																this.handleRightActiveKey(null);
															else this.handleRightActiveKey('03');
														}}
													>
														<div className='mb-0'>

															<div className='cardlink'>
																<a
																	onClick={e => {
																		e.preventDefault();
																	}}
																	className={`btn btn-link ${activeRightKey === '03' ? '' : 'collapsed'
																		}`}
																>
																	<i className="ico icon-Stock" title="Enquiry"></i>   Active Vehicles
																	{!_.isEmpty(_activeVehicles) && _activeVehicles?.length > 0 ? (
																		<span className='badge badge-pill badge-cafe ml-1'>
																			{_activeVehicles.length}
																		</span>
																	) : (
																		<></>
																	)}
																	<i className='arrow-collapse'></i>
																</a>
															</div>
														</div>
													</Accordion.Toggle>

													{
														canModifyContact && (isGroupDealer === false)
															?
															<div className='add-button'>
																<a
																	href='#'
																	onClick={e => {
																		e.preventDefault();
																		this.setState({
																			ownedModal: {
																				show: true,
																				ID: '',
																				title: 'addVehicle',
																				clsActive: 'overlay-modal active',
																			},
																		});
																	}}
																	className='mini-button'
																>
																	<i className='ico icon-add'></i>
																</a>
															</div>
															:
															<>
															</>
													}

													<Accordion.Collapse eventKey='03'>
														<Card.Body>
															{!_.isEmpty(_activeVehicles) &&
																_activeVehicles.length > 0 ? (
																<>
																	{
																		_activeVehicles.map((_owned, index) => (
																			<OwnedVehicleInfo
																				key={index}
																				requirement={_owned}
																				handleEdit={this.handleEditOwned}
																				isDeivered={canModifyContact ? false : true}
																				dealersettings={dealersettings}
																			></OwnedVehicleInfo>
																		))
																	}
																</>
															) : (
																<>
																	<div className='text-center p-3'>
																		<p>
																			<Translate text={'No Vehicle Available'} />
																		</p>
																		{
																			canModifyContact && (isGroupDealer === false)
																				?
																				<button
																					className='btn btn-sm btn-default'
																					onClick={e => {
																						e.preventDefault();
																						this.setState({
																							ownedModal: {
																								show: true,
																								ID: '',
																								title: 'addVehicle',
																								clsActive: 'overlay-modal active',
																							},
																						});
																					}}
																				>
																					<i className='ico icon-add mr-1'></i>{' '}
																					<Translate text={'add'} />
																				</button>
																				:
																				<>
																				</>
																		}

																	</div>
																</>
															)}
														</Card.Body>
													</Accordion.Collapse>
												</Card>

												{/** OWNED VEHICLES INFO */}
												<Card>
													<Accordion.Toggle
														as={Card.Header}
														eventKey='04'
														onClick={e => {
															e.preventDefault();

															if (activeRightKey === '04')
																this.handleRightActiveKey(null);
															else this.handleRightActiveKey('04');
														}}
													>
														<div className='mb-0'>

															<div className='cardlink'>
																<a
																	onClick={e => {
																		e.preventDefault();
																	}}
																	className={`btn btn-link ${activeRightKey === '04' ? '' : 'collapsed'
																		}`}
																>
																	<i className="ico icon-tradein" title="Enquiry"></i>   Ever Owned Vehicles
																	{!_.isEmpty(_ownedVehicles) && _ownedVehicles?.length > 0 ? (
																		<span className='badge badge-pill badge-cafe ml-1'>
																			{_ownedVehicles.length}
																		</span>
																	) : (
																		<></>
																	)}
																	<i className='arrow-collapse'></i>
																</a>
															</div>
														</div>
													</Accordion.Toggle>

													<Accordion.Collapse eventKey='04'>
														<Card.Body>
															{!_.isEmpty(_ownedVehicles) &&
																_ownedVehicles.length > 0 ? (
																<>
																	{
																		_ownedVehicles.map((_owned, index) => (
																			<OwnedVehicleInfo
																				key={index}
																				requirement={_owned}
																				handleEdit={this.handleEditOwned}
																				isDeivered={canModifyContact ? false : true}
																				dealersettings={dealersettings}
																			></OwnedVehicleInfo>
																		))
																	}
																</>
															) : (
																<>
																	<div className='text-center p-3'>
																		<p>
																			<Translate text={'No Vehicle Available'} />
																		</p>

																	</div>
																</>
															)}
														</Card.Body>
													</Accordion.Collapse>
												</Card>

											</>
											:
											<></>
									}


									{/** SERVICE INFO */}
									{
										serviceAccess
											?
											<>
												<Card>
													<Accordion.Toggle
														as={Card.Header}
														eventKey='04'
														onClick={e => {
															e.preventDefault();

															if (activeRightKey === '04')
																this.handleRightActiveKey(null);
															else this.handleRightActiveKey('04');
														}}
													>
														<div className='mb-0'>
															{
																canCreateService
																	?
																	<>
																		<div className='add-button'>
																			<a
																				href='#'
																				onClick={e => {
																					e.preventDefault();
																					e.stopPropagation();
																					this.isValidNewService();
																				}}
																				className='mini-button'
																			>
																				{' '}
																				<i className='ico icon-add'></i>
																			</a>
																		</div>
																	</>
																	:
																	<>
																	</>
															}
															<div className='cardlink'>
																<a
																	onClick={e => {
																		e.preventDefault();
																	}}
																	className={`btn btn-link ${activeRightKey === '04' ? '' : 'collapsed'
																		}`}
																>
																	<i className="ico icon-service-settings"></i> Active Service Vehicles
																	{this.state.services.length > 0 ? (
																		<span className='badge badge-pill badge-cafe ml-1'>
																			{this.state.services.length}
																		</span>
																	) : (
																		<></>
																	)}
																	<i className='arrow-collapse'></i>
																</a>
															</div>
														</div>
													</Accordion.Toggle>

													<Accordion.Collapse eventKey='04'>
														<Card.Body>
															{this.state.services &&
																this.state.services.length > 0 ? (
																<>
																	{this.state.services.map((_enquiry, index) => (
																		<ServiceInfo
																			key={index}
																			service={_enquiry}
																			clientUsers={this.props.clientUsers}
																			groupUsers={this.props.groupUsers}
																			sidepanelOpen={this.sidepanelServiceOpen}
																			history={this.props.history}
																			showMore={false}
																			dealersettings={dealersettings}
																		></ServiceInfo>
																	))}
																</>
															) : (
																<>
																	<div className='text-center p-3'>
																		<p>
																			<Translate text={'No Service Available'} />
																		</p>
																		{
																			canCreateService
																				?
																				<button
																					className='btn btn-sm btn-default'
																					onClick={e => {
																						e.preventDefault();
																						this.isValidNewService();
																					}}
																				>
																					<i className='ico icon-add mr-1'></i>{' '}
																					<Translate text={'add'} />
																				</button>
																				:
																				<>
																				</>
																		}
																	</div>
																</>
															)}
														</Card.Body>
													</Accordion.Collapse>
												</Card>
											</>
											:
											<></>
									}

									{/** FILES INFO */}
									{/* <Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='03'
											onClick={e => {
												e.preventDefault();

												if (activeLeftKey === '03')
													this.handleLeftActiveKey(null);
												else this.handleLeftActiveKey('03');
											}}
										>
											<div className='mb-0'>
												<div className='cardlink'>
													<a
														onClick={e => {
															e.preventDefault();
														}}
														className={`btn btn-link ${activeLeftKey === '03' ? '' : 'collapsed'
															}`}
													>
														<i className='ico icon-files'></i> <Translate text={'Files'} />{' '}
														<i className='arrow-collapse'></i>
													</a>
												</div>
											</div>
										</Accordion.Toggle>
										{
											canModifyContact
												?
												<div className='add-button'>
													<a
														href='#'
														onClick={e => {
															e.preventDefault();
															this.handleFileOpen();
														}}
														className='mini-button'
													>
														<i className='ico icon-add'></i>
													</a>
												</div>
												:
												<></>
										}

										<Accordion.Collapse eventKey='03'>
											<Card.Body>
												<Files
													contactID={this.state.contactID}
													contact={this.state.contact}
													dealersettings={this.props.dealersettings}
													clientUsers={this.props.clientUsers}
													groupUsers={this.props.groupUsers}
													isDeivered={canModifyContact ? false : true}
													filePath={`contactDetails/${this.state.contactID}/files`}
												></Files>
											</Card.Body>
										</Accordion.Collapse>
									</Card> */}

									{/** CONSENT FILES INFO */}
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='00'
											onClick={e => {
												e.preventDefault();

												if (activeLeftKey === '00')
													this.handleLeftActiveKey(null);
												else this.handleLeftActiveKey('00');
											}}
										>
											<div className='mb-0'>
												<div className='cardlink'>
													<a
														onClick={e => {
															e.preventDefault();
														}}
														className={`btn btn-link ${activeLeftKey === '00' ? '' : 'collapsed'
															}`}
													>
														<i className='ico icon-contract-fill'></i> <Translate text={'Consent'} />{' '}
														<i className='arrow-collapse'></i>
													</a>
												</div>
											</div>
										</Accordion.Toggle>


										<Accordion.Collapse eventKey='00'>
											<Card.Body>
												<Files
													contactID={this.state.contactID}
													contact={this.state.contact}
													dealersettings={this.props.dealersettings}
													clientUsers={this.props.clientUsers}
													groupUsers={this.props.groupUsers}
													isDeivered={canModifyContact ? false : true}
													filePath={`contactDetails/${this.state.contactID}/files`}
													customTab={'others'}
												></Files>
											</Card.Body>
										</Accordion.Collapse>
									</Card>


								</Accordion>



								<div className="info-table">
									<div className="contact-info-porsche-section-head"> <Translate text={'Relationships'} /></div>


									<div className="relationships-wrap">
										<div className="flex-walkin">
											<div className="relationships-icon">
												<img src={CommonHelper.getUserImagebyId(_allUser, (_resPerson ? _resPerson : addedBy))} alt="" width="50" height="50" className="rounded-circle img-object-fit" />
											</div>
											<div className="walkin-data">
												<div className="walkin-text">
													<div className="relationships-title">{'Responsible Sales Person'} </div>
													<div className="relationships-data">
														{CommonHelper.getUserNamebyId(_allUser, (_resPerson ? _resPerson : addedBy), '--')}
													</div>
												</div>
											</div>
										</div>
										<div>
										</div>
									</div>

									<div className="relationships-wrap">
										<div className="flex-walkin">
											<div className="relationships-icon">
												<i className="ico icon-dealers"></i>
											</div>
											<div className="walkin-data">
												<div className="walkin-text">
													<div className="relationships-title">{'Responsible Sales Dealer'} </div>
													<div className="relationships-data">
														{dealerName ? dealerName : '--'}
													</div>
												</div>
											</div>
										</div>
										<div>
										</div>
									</div>

								</div>

							</div>
						</aside>

						{/** ACTIVITY TAB */}
						<Activity
							contact={this.state.contact}
							clientUsers={this.props.clientUsers}
							groupUsers={this.props.groupUsers}
							dealersettings={this.props.dealersettings}
							contactBasicVM={this.state.contactBasicVM}
							history={this.props.history}
							dialNumber={this.props.dialNumber}
							enquiries={this.state.enquiries}
						></Activity>

						{/** RIGHT TAB */}
						<aside className='small-column ml-3 '>
							<div className='enquiry-sidebar'>
								<Accordion defaultActiveKey='0'>
									{/** CONTACT INFO */}
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='0'
											onClick={e => {
												e.preventDefault();

												if (activeLeftKey === '0')
													this.handleLeftActiveKey(null);
												else this.handleLeftActiveKey('0');
											}}
										>
											<div className='mb-0'>
												<div className='cardlink'>
													<a
														onClick={e => {
															e.preventDefault();
														}}
														className={`btn btn-link ${activeLeftKey === '0' ? '' : 'collapsed'
															}`}
													>
														<i className='ico icon-Contacts'></i>{' '}
														<Translate text={'contactInfo'} />{' '}
														<i className='arrow-collapse'></i>
													</a>
												</div>
											</div>
										</Accordion.Toggle>
										{
											canModifyContact
												?
												<>
													<div className='edit-button'>
														<a
															href='#'
															className='mini-button'
															onClick={e => {
																e.preventDefault();
																this.setState({
																	contactModal: {
																		showContact: true,
																		showContactSearch: false,
																		contactTitle: 'editContact',
																		clsContactActive: 'overlay-modal active',
																	},
																});
															}}
														>
															<i className='ico icon-edit'></i>
														</a>
													</div>
												</>
												:
												<>
												</>
										}


										<Accordion.Collapse eventKey='0'>
											<Card.Body>
												<div className='info-table'>
													<div className="contact-info-section-head"> <Translate text={'contactInformation'} /></div>
													<table className='table-fill'>
														<tbody>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'Last source (header)'} />
																</td>
																<td className='text-left'>
																	{'--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'Last source (sub-header)'} />
																</td>
																<td className='text-left'>
																	{'--'}
																</td>
															</tr>

															<tr>
																<td
																	className='text-left label-text'
																	width='35%'
																>
																	<Translate text={'gender'} />
																</td>
																<td className='text-left' width='65%'>
																	{gender ? gender : '--'}
																</td>
															</tr>

															<tr>
																<td
																	className='text-left label-text'
																	width='35%'
																>
																	<Translate text={'Mobile'} />
																</td>
																<td className='text-left' width='65%'>
																	{phone ? CommonHelper.phoneFormat(phoneCode, phone, dealersettings) : '--'}
																</td>
															</tr>
															<tr>
																<td
																	className='text-left label-text'
																	width='35%'
																>
																	<Translate text={'email'} />
																</td>
																<td className='text-left' width='65%'>
																	{email ? email : '--'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'dateOfBirth'} />
																</td>
																<td className='text-left'>
																	{dob ? moment(dob).format('DD MMM YYYY') : '--'}
																</td>
															</tr>

															<tr>
																<td
																	className='text-left label-text'
																	width='35%'
																>
																	<Translate text={'Occupation'} />
																</td>
																<td className='text-left' width='65%'>
																	{designation ? designation : '--'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'Hobbies'} />
																</td>
																<td className='text-left'>
																	{interests ? interests : '--'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'Religion'} />
																</td>
																<td className='text-left'>
																	{'--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'maritalStatus'} />
																</td>
																<td className='text-left'>
																	{maritalStatus ? maritalStatus : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'Address line 1'} />
																</td>
																<td className='text-left'>
																	{address ? address : '--'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'Address line 2'} />
																</td>
																<td className='text-left'>
																	{'--'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'Address line 3'} />
																</td>
																<td className='text-left'>
																	{'--'}
																</td>
															</tr>

														</tbody>
													</table>
													<div className="contact-info-section-head"> <Translate text={'otherInformation'} /></div>
													<table className='table-fill'>
														<tbody>
															<tr>
																<td
																	className='text-left label-text'
																	width='35%'
																>
																	<Translate text={'primaryID'} />
																</td>
																<td className='text-left' width='65%'>
																	<div className='contact-detail-license'>
																		<div className='license-box'>
																			{licenseFrontURL ? (
																				<>
																					{
																						(isPrivacyEnabled)
																							?
																							<a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
																							:
																							<a
																								data-fancybox={`license-box`}
																								href={licenseFrontURL}
																								onClick={e => {
																									e.preventDefault();
																									this.handleSaveAuditLog(licenseFrontURL);
																								}}
																							>
																								<img src={licenseFrontURL} alt='' className="img-object-fit" />{' '}
																							</a>
																					}
																				</>

																			) : (
																				<a
																					href='#'
																					onClick={e => {
																						e.preventDefault();
																					}}
																				>
																					<img src={_images.nolicense} alt='' />{' '}
																				</a>
																			)}
																		</div>
																		<div className='license-box'>
																			{licenseBackURL ? (
																				<>
																					{
																						(isPrivacyEnabled)
																							?
																							<a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
																							:
																							<a
																								data-fancybox={`license-box`}
																								href={licenseBackURL}
																								onClick={e => {
																									e.preventDefault();
																									this.handleSaveAuditLog(licenseBackURL);
																								}}
																							>
																								<img src={licenseBackURL} alt='' className="img-object-fit" />{' '}
																							</a>
																					}
																				</>

																			) : (
																				<a
																					href='#'
																					onClick={e => {
																						e.preventDefault();
																					}}
																				>
																					<img src={_images.nolicense} alt='' />{' '}
																				</a>
																			)}
																		</div>
																	</div>
																</td>
															</tr>

															{secondaryLicenseBackUrl ||
																secondaryLicenseFrontUrl ? (
																<>
																	<tr>
																		<td
																			className='text-left label-text'
																			width='35%'
																		>
																			<Translate text={'seconadryID'} />
																		</td>
																		<td className='text-left' width='65%'>
																			<div className='contact-detail-license'>
																				<div className='license-box'>
																					{secondaryLicenseFrontUrl ? (
																						<>
																							{
																								(isPrivacyEnabled)
																									?
																									<a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
																									:
																									<a
																										data-fancybox={`license-box`}
																										href={secondaryLicenseFrontUrl}
																										onClick={e => {
																											e.preventDefault();
																											this.handleSaveAuditLog(secondaryLicenseFrontUrl);
																										}}
																									>
																										<img
																											src={secondaryLicenseFrontUrl}
																											alt=''
																											className="img-object-fit"
																										/>{' '}
																									</a>
																							}
																						</>

																					) : (
																						<a
																							href='#'
																							onClick={e => {
																								e.preventDefault();
																							}}
																						>
																							<img
																								src={_images.nolicense}
																								alt=''
																								className="img-object-fit"
																							/>{' '}
																						</a>
																					)}
																				</div>
																				<div className='license-box'>
																					{secondaryLicenseBackUrl ? (
																						<>
																							{
																								(isPrivacyEnabled)
																									?
																									<a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
																									:
																									<a
																										data-fancybox={`license-box`}
																										href={secondaryLicenseBackUrl}
																										onClick={e => {
																											e.preventDefault();
																											this.handleSaveAuditLog(secondaryLicenseBackUrl);
																										}}
																									>
																										<img
																											src={secondaryLicenseBackUrl}
																											alt=''
																											className="img-object-fit"
																										/>{' '}
																									</a>
																							}
																						</>

																					) : (
																						<a
																							href='#'
																							onClick={e => {
																								e.preventDefault();
																							}}
																						>
																							<img
																								src={_images.nolicense}
																								alt=''
																								className="img-object-fit"
																							/>{' '}
																						</a>
																					)}
																				</div>
																			</div>
																		</td>
																	</tr>
																</>
															) : (
																<></>
															)}

															{visitingCardURL ? (
																<>
																	<tr>
																		<td
																			className='text-left label-text'
																			width='35%'
																		>
																			<Translate text={'visitingCardURL'} />
																		</td>
																		<td className='text-left' width='65%'>
																			<div className='contact-detail-license'>
																				<div className='license-box'>
																					{visitingCardURL ? (
																						<>
																							{
																								(isPrivacyEnabled)
																									?
																									<a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
																									:
																									<a
																										data-fancybox={`visiting-box`}
																										href={visitingCardURL}
																										onClick={e => {
																											e.preventDefault();
																											this.handleSaveAuditLog(visitingCardURL);
																										}}
																									>
																										<img
																											src={visitingCardURL}
																											alt=''
																											className="img-object-fit"
																										/>{' '}
																									</a>
																							}
																						</>

																					) : (
																						<a
																							href='#'
																							onClick={e => {
																								e.preventDefault();
																							}}
																						>
																							<img
																								src={_images.nolicense}
																								alt=''
																								className="img-object-fit"
																							/>{' '}
																						</a>
																					)}
																				</div>

																			</div>
																		</td>
																	</tr>
																</>
															) : (
																<></>
															)}
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'licenseNo'} />
																</td>
																<td className='text-left'>
																	{licenseNo ? licenseNo : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'licenseExpiry'} />
																</td>
																<td className='text-left'>
																	{licenseExpiry ? moment(licenseExpiry).format('DD MMM YYYY') : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'licenseState'} />
																</td>
																<td className='text-left'>
																	{licenseState ? licenseState : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'licenseType'} />
																</td>
																<td className='text-left'>
																	{licenseType ? licenseType : '--'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'phones'} />
																</td>
																<td className='text-left'>
																	{(phones && !_.isEmpty(phones)) ? <>
																		{
																			phones.map((data, idx) => (
																				data.value
																					?
																					<div key={idx}>
																						{data.value}{' [' + data.name + ']'}<br />
																					</div>
																					:
																					<></>
																			))
																		}
																	</> : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'emails'} />
																</td>
																<td className='text-left'>
																	{(emails && !_.isEmpty(emails)) ? <>
																		{
																			emails.map((data, idx) => (
																				data.value
																					?
																					<div key={idx}>
																						{data.value}{' [' + data.name + ']'}<br />
																					</div>
																					:
																					<></>
																			))
																		}
																	</> : '--'}
																</td>
															</tr>





															<tr>
																<td className='text-left label-text'>
																	<Translate text={'nationality'} />
																</td>
																<td className='text-left'>
																	{nationality ? nationality : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'preferredLanguage'} />
																</td>
																<td className='text-left'>
																	{language ? language : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'preferredContactMethod'} />
																</td>
																<td className='text-left'>
																	{contactMethod ? contactMethod : '--'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'driverName'} />
																</td>
																<td className='text-left'>
																	{driverName ? driverName : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'dependents'} />
																</td>
																<td className='text-left'>
																	{dependents ? dependents : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'campaign'} />
																</td>
																<td className='text-left'>
																	{campaign ? campaign : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'origin'} />
																</td>
																<td className='text-left'>
																	{origin ? origin : '--'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'banLoanVehicle'} />
																</td>
																<td className='text-left'>
																	{banLoanVehicle ? 'Yes' : 'No'}
																</td>
															</tr>
															{
																prevPurchased
																	?
																	<tr>
																		<td className='text-left label-text'>
																			<Translate text={'prevPurchased'} />
																		</td>
																		<td className='text-left'>
																			{prevPurchased}
																		</td>
																	</tr>
																	:
																	<></>
															}

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'optinPhone'} />
																</td>
																<td className='text-left'>
																	{optinPhone ? 'Yes' : 'No'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'optinSMS'} />
																</td>
																<td className='text-left'>
																	{optinSMS === null ? '--' : (optinSMS ? 'Yes' : 'No')}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'optinEmail'} />
																</td>
																<td className='text-left'>
																	{optinEmail ? 'Yes' : 'No'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'optinPost'} />
																</td>
																<td className='text-left'>
																	{optinPost ? 'Yes' : 'No'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'marketingOptinEmail'} />
																</td>
																<td className='text-left'>
																	{marketingOptinEmail === null ? '--' : (marketingOptinEmail ? 'Yes' : 'No')}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'marketingOptinSMS'} />
																</td>
																<td className='text-left'>
																	{marketingOptinSMS === null ? '--' : (marketingOptinSMS ? 'Yes' : 'No')}
																</td>
															</tr>

														</tbody>
													</table>
													{/* <div className="contact-info-section-head"> <Translate text={'Secondary Contact'} /></div>
													<table className='table-fill'>
														<tbody>


															<tr>
																<td className='text-left label-text'>
																	<Translate text={'title'} />
																</td>
																<td className='text-left'>
																	{CommonHelper.getLabelByValue(titles, secondaryTitle, '--')}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'firstName'} />
																</td>
																<td className='text-left'>
																	{secondaryFirstName ? secondaryFirstName : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'lastName'} />
																</td>
																<td className='text-left'>
																	{secondaryLastName ? secondaryLastName : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'preferredName'} />
																</td>
																<td className='text-left'>
																	{secondaryPreferredName ? secondaryPreferredName : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'phone'} />
																</td>
																<td className='text-left'>
																	{secondaryPhone ? CommonHelper.phoneFormat(secondaryPhoneCode, secondaryPhone, dealersettings) : '--'}
																</td>
															</tr>

															<tr>
																<td className='text-left label-text'>
																	<Translate text={'phones'} />
																</td>
																<td className='text-left'>
																	{(secondaryPhones && !_.isEmpty(secondaryPhones)) ? <>
																		{
																			secondaryPhones.map((data, idx) => (
																				data.value
																					?
																					<div key={idx}>
																						{data.value}{' [' + data.name + ']'}<br />
																					</div>
																					:
																					<></>
																			))
																		}
																	</> : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'email'} />
																</td>
																<td className='text-left'>
																	{secondaryEmail ? secondaryEmail : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'emails'} />
																</td>
																<td className='text-left'>
																	{(secondaryEmails && !_.isEmpty(secondaryEmails)) ? <>
																		{
																			secondaryEmails.map((data, idx) => (
																				data.value
																					?
																					<div key={idx}>
																						{data.value}{' [' + data.name + ']'}<br />
																					</div>
																					:
																					<></>
																			))
																		}
																	</> : '--'}
																</td>
															</tr>
														</tbody>
													</table> */}
													{!_.isEmpty(company) ? (
														<>
															<div className="contact-info-section-head"> <Translate text={'Organization'} /></div>
															<table className='table-fill'>
																<tbody>
																	<tr>
																		<td className='text-left label-text'>
																			<Translate text={'Company name'} />
																		</td>
																		<td className='text-left'>
																			{company.name ? company.name : '--'}
																		</td>
																	</tr>
																	<tr>
																		<td className='text-left label-text'>
																			<Translate text={'phone'} />
																		</td>
																		<td className='text-left'>
																			{company.phone ? company.phone : '--'}
																		</td>
																	</tr>
																	<tr>
																		<td className='text-left label-text'>
																			<Translate text={'email'} />
																		</td>
																		<td className='text-left'>
																			{company.email ? company.email : '--'}
																		</td>
																	</tr>
																</tbody>
															</table>
														</>
													) : (
														<></>
													)}

												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						</aside>
					</div>
				</div>

				<LayoutConsumer>
					{({ dealersettings, clientUsers, groupUsers }) => (
						<StageProvider
							dealersettings={dealersettings}
							clientUsers={clientUsers}
							groupUsers={groupUsers}
							history={this.props.history}
							path={'contacts'}
						>
							<>
								<PopUpModal show={contactModal.showContact}>
									<AddContact
										docID={this.state.contact.documentID}
										show={contactModal.showContact}
										clsActive={contactModal.clsContactActive}
										handleClose={this.handleContactClose}
										title={contactModal.contactTitle}
										showSearch={contactModal.showContactSearch}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
									></AddContact>
								</PopUpModal>

								<PopUpModal show={ownedModal.show}>
									<AddOwnedVehicle
										docID={ownedModal.ID}
										contactID={this.state.contact.documentID}
										show={ownedModal.show}
										clsActive={ownedModal.clsActive}
										handleClose={this.handleOwnedClose}
										title={ownedModal.title}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										isDeivered={isGroupDealer}
									></AddOwnedVehicle>
								</PopUpModal>

								<PopUpModal show={ownedEnquiryModal.show}>
									<AddOwnedEnquiryVehicle
										vehicle={ownedEnquiryModal.data}
										show={ownedEnquiryModal.show}
										clsActive={ownedEnquiryModal.clsActive}
										handleClose={this.handleOwnedEnquiryClose}
										title={ownedEnquiryModal.title}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										isDeivered={isGroupDealer}
									>
									</AddOwnedEnquiryVehicle>
								</PopUpModal>

								<PopUpModal show={this.state.showReAuthentication}>
									<ReAuthenticate
										show={this.state.showReAuthentication}
										handleClose={this.handleReAuthOpenClose}
										dealersettings={dealersettings}
									/>
								</PopUpModal>

								<PopUpModal show={eventModal.show}>
									<EventModal
										show={eventModal.show}
										event={eventModal.data}
										title={eventModal.title}
										handleClose={this.handleEventClose}
										dealersettings={dealersettings}
									/>
								</PopUpModal>

								<SidePanel
									clsActive={showpanel.clsActive}
									clsName={showpanel.clsName}
									sidepanelClose={this.sidepanelClose}
									title=''
								>
									<QuickView
										enquiryid={showpanel.enquiryid}
										history={this.props.history}
										sidepanelClose={this.sidepanelClose}
										isReadOnlyView={localStorage.defaultModule !== 'service' ? false : true}
										handleModuleChange={this.props.handleModuleChange}
									/>
								</SidePanel>

								<SidePanel
									clsActive={showServicepanel.clsActive}
									clsName={showServicepanel.clsName}
									sidepanelClose={this.sidepanelServiceClose}
									title=''
								>
									<QuickViewService
										serviceid={showServicepanel.serviceid}
										history={this.props.history}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										sidepanelClose={this.sidepanelServiceClose}
										isReadOnlyView={localStorage.defaultModule === 'service' ? false : true}
										handleModuleChange={this.props.handleModuleChange}
									/>
								</SidePanel>

								<SidePanel
									clsActive={showTradeinPropanel.clsActive}
									clsName={showTradeinPropanel.clsName}
									sidepanelClose={this.sidepanelTIPClose}
									title=''
								>
									<QuickViewTradeinPro
										tradeinproid={showTradeinPropanel.tradeinproid}
										history={this.props.history}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										sidepanelClose={this.sidepanelTIPClose}
										isReadOnlyView={localStorage.defaultModule === 'tradeinPro' ? false : true}
										handleModuleChange={this.props.handleModuleChange}
									/>
								</SidePanel>

								<ChatSidePanel
									sidepanelClose={this.handleSharePanelClose}
									clsActive={this.state.showSharePanel.chatClsActive}
									clsName={this.state.showSharePanel.chatClsName}
									title='selectUser'
									sharePanel={true}
									sendAs='contact'
									contact={this.state.contact}
								/>

								<PopUpModal show={fleetModal.show}>
									<AddFleet
										docID={fleetModal.ID}
										show={fleetModal.show}
										clsActive='overlay-modal active'
										stock={fleetModal.stock}
										contact={fleetModal.contact}
										handleClose={this.handleCloseFleet}
										title={'fleet'}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
									>
									</AddFleet>
								</PopUpModal>

								<PopUpModal show={fileModal.show}>
									<AddFile
										show={fileModal.show}
										contactID={this.state.contactID}
										handleClose={this.handleFileClose}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										filePath={`contactDetails/${this.state.contactID}/files`}
									>
									</AddFile>
								</PopUpModal>
							</>
						</StageProvider>
					)}
				</LayoutConsumer>
			</>
		) : pagenotFound ? (
			<PageNotFound />
		) : (
			<LoaderOverlay text='Fetching contact details...' active={true} />
		);
	}
}
