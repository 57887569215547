import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { Modal } from "react-bootstrap";
import NumberFormat from 'react-number-format';

import { StageProvider, StageConsumer } from './provider'
import { LayoutConsumer } from '../layout/provider';
import Translate from '../../constants/translate';
import { ContentOverlay, SidePanel, TableView, PopUpModal } from '../../components'
//import QuickView from "./quickview";
import FilterPanel from './filterPanel'
import TransferOwner from '../common/transfer'
import CommonHelper from '../../services/common';

import { objServiceListVM, allserviceFields } from './viewModel'
import images from '../../images'
import LeadTransfer from '../pipeline/leadTransfer'
import ReAuthenticate from '../common/reAuthenticate';
import { serviceStatus } from '../../services/enum';
import QuickView from "./quickview";
import { firestoreDB } from '../../services/helper';

const EnquiryListReducer = (state, action) => {
    function updateEnquiry() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_ENQUIRY_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_ENQUIRY_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_ENQUIRY_LIST": {
            return updateEnquiry()
        }
        default:
            return state;
    }
};



const PipelineListView = (props) => {
    const [enquiries, dispatch] = useReducer(EnquiryListReducer, [])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [serviceFields, setServiceFields] = useState(props.dealersettings.serviceFields)
    const pageLimit = 50
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.serviceSearchText ? localStorage.serviceSearchText : '')
    const [enquiryLoader, setEnquiryLoader] = useState(true)
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-service-quickview' })
    const [customFilters, setCustomFilters] = useState([])

    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})
    const [modalTransferShow, setModalTransferShow] = useState(false)
    const [modalMoveShow, setModalMoveShow] = useState(false)
    const [pipelineCount, setPipelineCount] = useState(0)

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [enquiryTypes, setEnquiryTypes] = useState([])
    const [enquiryOptions, setEnquiryOptions] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])
    const [financeStatuses, setFinanceStatuses] = useState([])
    const [lostReasons, setLostReasons] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const _servicePipeline = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.services) &&
        !_.isEmpty(props.dealersettings.client.services.pipelines)) ? props.dealersettings.client.services.pipelines : [];


    const [defaultPipeline, setDefaultPipeline] = useState(_servicePipeline.find(item => item.default === true) ? _servicePipeline.find(item => item.default === true).value : '')

    // const _moduleSettings = ((!_.isEmpty(props.dealersettings) &&
    //     !_.isEmpty(props.dealersettings.client) &&
    //     !_.isEmpty(props.dealersettings.client.moduleSettings)) ? props.dealersettings.client.moduleSettings : null);

    // const financeEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.finance) &&
    //     _moduleSettings.finance.enabled) ? true : false);

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    useEffect(() => {
        let headerFields = serviceFields;
        let allHeaderFields = allserviceFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        setHeader(CommonHelper.bindContactName(_headers, 'contactName'));
        //setHeader(_headers);

    }, [serviceFields])



    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });


            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                _origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });



            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                _campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _pipelines = [];
            setttings.pipelines && setttings.pipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _countries = [];
            props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
                _countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _states = [];
            props.dealersettings.states && props.dealersettings.states.forEach((doc) => {
                _states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });



            setTitles(_titles);
            setCampaigns(_campaigns);
            setOrigins(_origins);
            setLanguages(_languages);
            setNationalities(_nationalities)
            setCountries(_countries);
            setStates(_states);
            setDataload(true);
        }
    }, [])

    // useEffect(() => {
    //     if (isFilterApplied || _servicePipeline.length === 0)
    //         return
    //     const viewOtherEnquiry = ((!_.isEmpty(props.dealersettings) &&
    //         !_.isEmpty(props.dealersettings.rolePermissions) &&
    //         !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    //         props.dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);
    //     const dpl = _servicePipeline.find(item => item.default === true).value
    //     var querySet = `clients/${props.dealersettings.client.id}`
    //     if (!viewOtherEnquiry) {
    //         querySet = `users/${localStorage.uid}/userClients/${props.dealersettings.client.id}`
    //     }
    //     window.unSubServicePipelineLstCount = window.firebase.firestore().doc(querySet)
    //         .onSnapshot(docSnapshot => {
    //             if (docSnapshot.exists && docSnapshot.data().counts && docSnapshot.data().counts.pipeline && docSnapshot.data().counts.pipeline[dpl] && docSnapshot.data().counts.pipeline[dpl].stages) {
    //                 var stageCount = {};
    //                 _servicePipeline.find(item => item.default === true).stages.forEach(stage => {
    //                     stageCount = {
    //                         ...stageCount,
    //                         [stage.value]: {
    //                             ...docSnapshot.data().counts.pipeline[dpl].stages[stage.value],
    //                             ['total']: sumObj(docSnapshot.data().counts.pipeline[dpl].stages[stage.value])
    //                         }
    //                     }
    //                 })
    //                 setPipelineCount(stageCount)
    //             }

    //         })
    // }, [_servicePipeline, isFilterApplied])

    const sumObj = (obj) => {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum;
    }

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'service')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            serviceid: 0
        })
    }

    const sidepanelOpen = (documentID) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            serviceid: documentID
        })
        //props.history.push("/service/details/" + documentID + '?back-url=/service/list');
    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubservicelist && window.unsubservicelist();
            window.unSubServicePipelineLstCount && window.unSubServicePipelineLstCount();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.servicePipelineFilter', isFilterApplied, localStorage.servicePipelineFilter)
        if (localStorage.servicePipelineFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.servicePipelineFilter])



    useEffect(() => {
        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        if (_servicePipeline.length === 0) {
            return
        }
        if (!checkDataLoad && defaultPipeline === _servicePipeline.find(item => item.default === true).value) {
            return;
        }
        if (defaultPipeline !== _servicePipeline.find(item => item.default === true).value) {
            setEnquiryLoader(true)
        }

        if (isFilterApplied && pageNum === 0)
            setEnquiryLoader(true)

        const viewOtherEnquiry = true;
        // const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
        //     !_.isEmpty(dealersettings.rolePermissions) &&
        //     !_.isEmpty(dealersettings.rolePermissions.permissions) &&
        //     dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        if ((isFilterApplied || localStorage.servicePipelineFilter) && isValidFilter()) {
            let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
            let _pipelineFilter = JSON.parse(localStorage.servicePipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            if (_.isEmpty(_filter.pipeline))
                _filter.pipeline = _servicePipeline.find(item => item.default === true).value;

            if (!viewOtherEnquiry)
                _filter.owner = dealersettings.id;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
                delete _filter['date'];
            }

            if (_filter.rottenDays &&
                !_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.services) &&
                !_.isEmpty(dealersettings.client.services.pipelines)) {
                _filter.pipelineSettings = JSON.stringify(dealersettings.client.services.pipelines)
            }

            _filter.clientID = clientID;
            _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();
            let _searchObject = {
                "type": "searchServiceJobs",
                "filters": JSON.stringify(_filter),
                "sortOrder": "stageDate desc",
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }

            _searchObject.clientID = clientID;

            //console.log('filter-searchServiceJobs', _searchObject, clientID, pageNum, _filter);
            const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
            searchEnquiries(_searchObject).then((response) => {
                //console.log('generic-searchData', _rec, response);
                if (response.data.success && localStorage.servicePipelineFilter && isValidFilter()) {
                    onCollectionServiceJob(response.data);
                    setPipelineCount({
                        advance: {
                            total: response.data.total
                        }
                    })
                }
                else {
                    setEnquiryLoader(false)
                    setDataload(false)
                    setPaging(false)
                }
            });

            window.unsubservicelist && window.unsubservicelist();
            window.unSubServicePipelineLstCount && window.unSubServicePipelineLstCount();
        }
        else {

            let searchKeywords = searchText;
            let searchOwners = [];

            if (localStorage.servicePipelineFilter) {
                let _pipelineFilter = JSON.parse(localStorage.servicePipelineFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);

                searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

                setSearchText(searchKeywords);
                localStorage.serviceSearchText = searchKeywords;
                //console.log('search---Keywords', searchKeywords, searchOwners, searchText)
            }

            let refEnquiryData = firestoreDB(dealersettings).firestore().collection('serviceJobs')
                .where('clientID', '==', props.dealersettings.client.id)
                .where('isDeleted', '==', false)

            if (searchKeywords) {
                let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                if (formattedSearchText.trim())
                    refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())

                refEnquiryData = refEnquiryData
                    .orderBy('addedDate', 'desc');
            }
            else {
                if (!viewOtherEnquiry)
                    refEnquiryData = refEnquiryData.where('owner', 'in', [localStorage.uid])
                else if (!_.isEmpty(searchOwners))
                    refEnquiryData = refEnquiryData.where('owner', 'in', searchOwners)

                refEnquiryData = refEnquiryData
                    .where('pipeline', '==', _servicePipeline.find(item => item.default === true).value)
                    .orderBy('stageDate', 'desc')
            }


            if (hasMore.length > 0 && defaultPipeline === _servicePipeline.find(item => item.default === true).value) {
                refEnquiryData = refEnquiryData
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                refEnquiryData = refEnquiryData
                    .limit(pageLimit)
            }

            window.unsubservicelist = refEnquiryData
                .onSnapshot(onCollectionUpdate);
        }


    }, [checkDataLoad, _servicePipeline, props.dealersettings])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertServiceJobsVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADD_ENQUIRY_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_ENQUIRY_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_ENQUIRY_LIST"
                }
            }
        })
        const _enquiries = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach(function (doc) {
                var logVM = convertServiceJobsVM({
                    ...doc.data(),
                    documentID: doc.id
                });
                _enquiries.push(logVM)
            });
        }
        dispatch({
            type: actionType ? actionType : isPaging ? "APPEND_LIST" : "SUCCESS",
            data: actionType ? snapshotDoc : _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        setDefaultPipeline(_servicePipeline.find(item => item.default === true).value)
    }

    const onCollectionServiceJob = (querySnapshot) => {

        const _enquiries = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            var logVM = convertServiceJobsVM(doc);
            _enquiries.push(logVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS",
            data: _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        setDefaultPipeline(_servicePipeline.find(item => item.default === true).value)
    }

    const convertServiceJobsVM = (doc) => {

        doc.stageDate = doc.stageDate && doc.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stageDate._seconds)._d) : doc.stageDate;

        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
        doc.checkInDate = doc.checkInDate && doc.checkInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.checkInDate._seconds)._d) : doc.checkInDate;
        doc.completionDate = doc.completionDate && doc.completionDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.completionDate._seconds)._d) : doc.completionDate;
        doc.nextService = doc.nextService && doc.nextService._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.nextService._seconds)._d) : doc.nextService;
        doc.clientContactDate = doc.clientContactDate && doc.clientContactDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.clientContactDate._seconds)._d) : doc.clientContactDate;

        doc.stageHistory = [..._.map(doc.stageHistory, function (obj) {
            return obj.date && obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]


        const objenquiryData = Object.assign({}, doc);
        const listVM = Object.assign({}, objServiceListVM);
        for (let [key, value] of Object.entries(objenquiryData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }
        listVM.objDoc = Object.assign({}, objenquiryData);
        listVM.displayID = listVM.displayID ? listVM.displayID : '--';
        listVM.originValue = doc.origin;
        listVM.enquiryTypeValue = doc.enquiryType;

        listVM.serviceTypeValue = doc.serviceType;
        listVM.appointmentTypeValue = doc.appointmentType;
        listVM.pipelineValue = doc.pipeline;
        listVM.stageValue = doc.stage;
        listVM.ownerValue = doc.owner;
        listVM.slotValue = doc.slot ? doc.slot.value : '';
        listVM.slotName = doc.slot ? doc.slot.name : '';

        listVM.jobAllocated = doc.jobAllocated ? 'Yes' : '';
        listVM.jobConfirmed = doc.jobConfirmed ? 'Yes' : '';
        listVM.partsConfirmed = doc.partsConfirmed ? 'Yes' : '';
        listVM.workshopConfirmed = doc.workshopConfirmed ? 'Yes' : '';


        let dealersettings = props.dealersettings;
        listVM.pipeline = CommonHelper.getNameByValue(props.dealersettings.client.services.pipelines, doc.pipeline, '');
        if (_.find(props.dealersettings.client.services.pipelines, { value: doc.pipeline })) {
            listVM.stage = CommonHelper.getNameByValue(_.find(props.dealersettings.client.services.pipelines, { value: doc.pipeline }).stages, doc.stage, '');
        }

        let _setttings = doc.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].services;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.services;

        if (_setttings) {
            //listVM.serviceType = CommonHelper.getNameByValue(_setttings.serviceTypes, doc.serviceType, '');
            listVM.appointmentType = CommonHelper.getNameByValue(_setttings.appointmentTypes, doc.appointmentType, '');
        }

        listVM.appointmentDate = doc.appointmentDate ? moment(doc.appointmentDate).format('DD/MM/YYYY hh:mm A') : '';
        listVM.checkInDate = doc.checkInDate ? moment.unix(doc.checkInDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.stageDate = doc.stageDate ? moment.unix(doc.stageDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.clientContactDate = doc.clientContactDate ? moment.unix(doc.clientContactDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

        listVM.owner = doc.owner ? CommonHelper.getUserNamebyId(allUsers, doc.owner) : '';
        listVM.addedBy = CommonHelper.getUserNamebyId(allUsers, doc.addedBy);
        listVM.modifiedBy = doc.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.modifiedBy) : '';
        listVM.checkInBy = doc.checkInBy ? CommonHelper.getUserNamebyId(allUsers, doc.checkInBy) : '';
        listVM.serviceAdvisor = doc.serviceAdvisor ? CommonHelper.getUserNamebyId(allUsers, doc.serviceAdvisor) : '';
        listVM.technician = doc.technician ? CommonHelper.getUserNamebyId(allUsers, doc.technician) : '';
        listVM.partsInterpretor = doc.partsInterpretor ? CommonHelper.getUserNamebyId(allUsers, doc.partsInterpretor) : '';
        listVM.workshopManager = doc.workshopManager ? CommonHelper.getUserNamebyId(allUsers, doc.workshopManager) : '';

        const _jobStatus = doc.status === serviceStatus.OWNERRESPONDED ? doc.ownerResponse : doc.status;
        const serviceAllStatus = Object.assign([], _setttings?.serviceStatus)
        let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0];
        listVM.status = _objCurrentStatus ? (<span key={doc.status}
            className={`status-empty`}
            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
        >
            {_objCurrentStatus.name}
        </span>) : '';

        listVM.statusValue = _jobStatus;
        listVM.statusName = CommonHelper.getNameByValue(serviceAllStatus, _jobStatus, '');

        const technicianAllStatus = Object.assign([], _setttings?.technicianStatus)
        let _objCurrentTechStatus = doc.technicianStatus && technicianAllStatus.filter(e => e.value === doc.technicianStatus)[0];
        listVM.technicianStatus = _objCurrentTechStatus ? (<span key={doc.technicianStatus}
            className={`status-empty`}
            style={{ background: (_objCurrentTechStatus.color ? _objCurrentTechStatus.color : '#333') }}
        >
            {_objCurrentTechStatus.name}
        </span>) : '';

        listVM.technicianStatusValue = doc.technicianStatus;
        listVM.technicianStatusName = CommonHelper.getNameByValue(technicianAllStatus, doc.technicianStatus, '');

        listVM.slot = !_.isEmpty(doc.slot) ? <div className="badge-slot"
            style={{
                color: `${doc.slot.color ? doc.slot.color : '#f47815 '}`,
                backgroundColor: `${doc.slot.color ? doc.slot.color + '1f' : '#f478151f'}`,
                boxShadow: `inset 0 0 0 1px ${doc.slot.color ? doc.slot.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
            }}>
            <i className="ico icon-service-timer"></i> {doc.slot.name}
        </div> : '';


        listVM.totalServiceCost = doc.totalServiceCost ? currencySymbol + doc.totalServiceCost : '';
        listVM.serviceAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '');
        _servicePipeline.filter(e => e.default)[0].stages.forEach(col => {
            if (listVM.stage === col.value) {
                if (col.rottenDays) {
                    var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                    listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
                }
            }
        })

        if (!_.isEmpty(doc.vehicle)) {
            listVM.vehicleModel = doc.vehicle.make + ' ' + doc.vehicle.model
            listVM.vehicleYear = doc.vehicle.year ? doc.vehicle.year : '';
            listVM.vehicleVinNo = doc.vehicle.vinNo ? doc.vehicle.vinNo : '';
            listVM.vehicleRegNo = doc.vehicle.rego ? doc.vehicle.rego : (doc.vehicle.regNo ? doc.vehicle.regNo : '');

        }
        if (!_.isEmpty(doc.contact)) {
            listVM.contactName = CommonHelper.displayFullContact(titles, doc.contact);
            listVM.contactNumber = doc.contact.displayID;
            listVM.phone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings);
            listVM.displayPhone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings, true);
            listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''
            listVM.email = doc.contact.email;
            listVM.licenseNo = doc.contact.licenseNo;
            listVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
            listVM.contactType = doc.contact.contactType;
            listVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
            listVM.gender = doc.contact.gender;
            listVM.maritalStatus = doc.contact.maritalStatus;
            listVM.contactMethod = doc.contact.contactMethod;
            listVM.licenseType = doc.contact.licenseType;
            listVM.language = CommonHelper.getLabelByValue(languages, doc.contact.language, '');
            listVM.nationality = CommonHelper.getLabelByValue(nationalities, doc.contact.nationality, '');
            listVM.campaign = CommonHelper.getLabelByValue(campaigns, doc.contact.campaign, '');
            listVM.origin = CommonHelper.getLabelByValue(origins, doc.contact.origin, '');

            let _setttings = doc.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings;
            if (_.isEmpty(_setttings))
                _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

            if (_setttings) {
                listVM.gender = CommonHelper.getNameByValue(_setttings.genders, doc.contact.gender, '');
                listVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
                listVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
                listVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
                listVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');
            }

            if (dealersettings.group && doc.contact.clientID && (doc.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
                listVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, doc.contact.clientID);
            }
            else
                listVM.dealerName = '';

            listVM.address = doc.contact.address;
            listVM.companyName = doc.contact.companyName;
            listVM.designation = doc.contact.designation;

            listVM.licenseState = doc.contact.licenseState;
            listVM.secondaryName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
            listVM.secondaryPhone = CommonHelper.phoneFormat(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone, props.dealersettings);
            listVM.secondaryEmail = doc.contact.secondaryEmail;
            listVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
            listVM.optinPhone = doc.contact.optinPhone === null ? '' : (doc.contact.optinPhone ? 'Yes' : 'No');
            listVM.optinEmail = doc.contact.optinEmail === null ? '' : (doc.contact.optinEmail ? 'Yes' : 'No');
            listVM.optinPost = doc.contact.optinPost === null ? '' : (doc.contact.optinPost ? 'Yes' : 'No');
            listVM.optinSMS = doc.contact.optinSMS === null ? '' : (doc.contact.optinSMS ? 'Yes' : 'No');
            listVM.marketingOptinEmail = doc.contact.marketingOptinEmail === null ? '' : (doc.contact.marketingOptinEmail ? 'Yes' : 'No');
            listVM.marketingOptinSMS = doc.contact.marketingOptinSMS === null ? '' : (doc.contact.marketingOptinSMS ? 'Yes' : 'No');
        }
        else if (!_.isEmpty(doc.engageNewContact)) {
            let _contact = Object.assign({}, doc.engageNewContact);
            listVM.contactName = CommonHelper.displayFullContact([], _contact, '', true);
            listVM.phone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
            listVM.displayPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings, true);
            listVM.phoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
            listVM.email = _contact.email ? _contact.email : '';
            listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''
        }


        return listVM
    }

    const isValidFilter = () => {
        if (localStorage.servicePipelineFilter) {
            let _pipelineFilter = JSON.parse(localStorage.servicePipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ serviceFields: fields }, { merge: true })
            .then(() => {
                setServiceFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('serviceFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            sidepanelOpen(id)
        }}>
            <i className="ico icon-more"></i>
        </div>)
    }

    const enquiryDetailclick = (e, id) => {
        sidepanelOpen(id)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const handleFavorite = (id, val) => {
        const enq = enquiries.find(item => item.documentID === id);
        if (!_.isEmpty(enq)) {
            enq.isFav = val
            dispatch({
                type: "UPDATE_ENQUIRY_LIST",
                data: enq
            });
            firestoreDB(props.dealersettings).firestore().doc(`serviceJobs/${id}`)
                .set({
                    isFav: val
                }, { merge: true })
                .then(snapshot => {
                    // toast.notify('service updated successfully', {
                    //     duration: 2000
                    // })

                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
    }

    const updateListEnquiry = (objEnquiry) => {
        if (!_.isEmpty(objEnquiry)) {
            let enq = convertServiceJobsVM(objEnquiry);

            if (!_.isEmpty(enq)) {
                dispatch({
                    type: "UPDATE_ENQUIRY_LIST",
                    data: enq
                });
            }
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('servicePipelineFilter', JSON.stringify(_filter));
            localStorage.serviceSearchText = '';
            setFilterApplied(true);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.servicePipelineFilter)) {
            localStorage.removeItem('servicePipelineFilter');
            localStorage.serviceSearchText = '';
            setFilterApplied(false);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
        }
    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries) && enquiries.map(file => {
                let record = Object.assign({}, enquiries.filter(e => e.documentID === file.documentID)[0].objDoc);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleShowTransferEnquiries = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select service to transfer.'));
            return;
        }
        else {
            setModalTransferShow(true)
        }
    }


    const handleCloseTransferEnquiries = async (owner) => {
        if (owner) {
            let ObjData = {
                owner: owner,
                modifiedBy: props.dealersettings ? props.dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
            }

            const batch = firestoreDB(props.dealersettings).firestore().batch();

            let _selectedIds = Object.keys(Object.assign({}, selectedIds)).slice(0, 50).reduce((result, key) => {
                result[key] = selectedIds[key];
                return result;
            }, {});
            for (let [key, value] of Object.entries(_selectedIds)) {
                let _selectedEnq = Object.assign({}, value);
                await firestoreDB(props.dealersettings).firestore().collection('enquiries')
                    .doc(key)
                    .update(ObjData).then((docRef) => {
                        for (let [key, value] of Object.entries(ObjData)) {
                            _selectedEnq[key] = value;
                        }
                        updateListEnquiry(_selectedEnq);
                    }).catch((error) => {
                        console.error("Error updating enquiries: ", error);
                    });

                await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', key)
                    .where('isDeleted', '==', false)
                    .where('isDone', '==', false)
                    .get().then(response => {
                        response.docs.forEach((doc) => {
                            batch.update(doc.ref, ObjData);
                        });
                    });

                await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', key)
                    .where('isHidden', '==', true)
                    .where('isDeleted', '==', true)
                    .get().then(response => {
                        response.docs.forEach((doc) => {
                            batch.update(doc.ref, ObjData);
                        });
                    });
            }

            batch.commit()
                .then(() => {
                    setSelectedIds({});
                    setModalTransferShow(false)

                    let _leadsSize = Object.keys(_selectedIds).length;
                    toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} Service(s) transferred successfully`, {
                        duration: 2000
                    })
                })
                .catch((error) => {
                    console.log(error);
                    setModalTransferShow(false)
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else {
            setModalTransferShow(false);
        }

    }

    const handleShowMoveEnquiries = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select service to move.'));
            return;
        }
        else {
            setModalMoveShow(true)
        }
    }

    const handleCloseMoveEnquiries = async (fields) => {
        //    console.log('handleCloseMoveEnquiries',handleCloseMoveEnquiries)
        if (!_.isEmpty(fields)) {

            let ObjData = {
                modifiedBy: props.dealersettings ? props.dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                stageDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
            }

            if (fields.clientID)
                ObjData.clientID = fields.clientID;

            if (fields.pipeline)
                ObjData.pipeline = fields.pipeline;

            if (fields.stage)
                ObjData.stage = fields.stage;

            if (fields.owner)
                ObjData.owner = fields.owner;

            const batch = firestoreDB(props.dealersettings).firestore().batch();

            let _selectedIds = Object.keys(Object.assign({}, selectedIds)).slice(0, 50).reduce((result, key) => {
                result[key] = selectedIds[key];
                return result;
            }, {});
            for (let [key, value] of Object.entries(_selectedIds)) {
                let _selectedEnq = Object.assign({}, value);
                let _objEnq = Object.assign({}, ObjData);

                let newstagehistory = {
                    pipeline: fields.pipeline,
                    stage: fields.stage,
                    date: window.firebase.firestore.Timestamp.now()
                };

                if (fields.pipeline === 'LeadsBucket') {
                    //_objEnq.status = null;
                    _objEnq.isConverted = false;
                    _objEnq.convertedBy = null;
                    _objEnq.convertedDate = null;
                }

                let arrstagehistory = _selectedEnq.stageHistory ? _selectedEnq.stageHistory : []
                if (arrstagehistory.some(e => e.stage === fields.stage && e.pipeline === fields.pipeline)) {
                    newstagehistory = _.filter(arrstagehistory, { stage: fields.stage, pipeline: fields.pipeline })[0]
                    newstagehistory.date = window.firebase.firestore.Timestamp.now()
                }
                arrstagehistory = [...arrstagehistory.filter(e => e.stage !== fields.stage), newstagehistory]
                _objEnq.stageHistory = arrstagehistory;

                //console.log('_objEnq', _objEnq)
                await firestoreDB(props.dealersettings).firestore().collection('enquiries')
                    .doc(key)
                    .update(_objEnq).then((docRef) => {
                        for (let [key, value] of Object.entries(_objEnq)) {
                            _selectedEnq[key] = value;
                        }
                        updateListEnquiry(_selectedEnq);

                    }).catch((error) => {
                        console.error("Error updating enquiries: ", error);
                    });

                if (_selectedEnq.owner !== ObjData.owner || (ObjData.clientID && ObjData.clientID !== props.dealersettings.client.id)) {
                    await firestoreDB(props.dealersettings).firestore().collection('activities')
                        .where('enquiryID', '==', key)
                        .where('isDeleted', '==', false)
                        .where('isDone', '==', false)
                        .get().then(response => {
                            response.docs.forEach((doc) => {
                                batch.update(doc.ref, {
                                    clientID: ObjData.clientID,
                                    owner: ObjData.owner ? ObjData.owner : localStorage.uid
                                });
                            });
                        });
                    await firestoreDB(props.dealersettings).firestore().collection('activities')
                        .where('enquiryID', '==', key)
                        .where('isHidden', '==', true)
                        .where('isDeleted', '==', true)
                        .get().then(response => {
                            response.docs.forEach((doc) => {
                                batch.update(doc.ref, {
                                    clientID: ObjData.clientID,
                                    owner: ObjData.owner ? ObjData.owner : localStorage.uid
                                });
                            });
                        });
                }

                if ((ObjData.clientID && ObjData.clientID !== props.dealersettings.client.id)) {
                    await firestoreDB(props.dealersettings).firestore().collection('tradeins')
                        .where('enquiryID', '==', key)
                        .where('isDeleted', '==', false)
                        .get().then(response => {
                            response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                        });

                    await firestoreDB(props.dealersettings).firestore().collection('testdrives')
                        .where('enquiryID', '==', key)
                        .where('isDeleted', '==', false)
                        .get().then(response => {
                            response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                        });

                    await firestoreDB(props.dealersettings).firestore().collection('cafeOrders').firestore().collection('cafeOrders')
                        .where('enquiryID', '==', key)
                        .where('isDeleted', '==', false)
                        .get().then(response => {
                            response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                        });

                    await firestoreDB(props.dealersettings).firestore().collection(`enquiries/${key}/walkins`)
                        .get().then(response => {
                            response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                        });
                }
            }

            batch.commit()
                .then(() => {
                    setSelectedIds({});
                    setModalMoveShow(false)

                    let _leadsSize = Object.keys(_selectedIds).length;
                    toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} Service(s) allocated successfully`, {
                        duration: 2000
                    })
                })
                .catch((error) => {
                    console.log(error);
                    setModalMoveShow(false)
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else {
            setModalMoveShow(false);
        }
    }

    const excelDownload = async (total) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
        let _pipelineFilter = localStorage.servicePipelineFilter ? JSON.parse(localStorage.servicePipelineFilter) : {};
        let _filter = Object.assign({}, _pipelineFilter.value);
        _filter.pipeline = _servicePipeline.find(item => item.default === true).value;
        const viewOtherEnquiry = ((!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        if (!viewOtherEnquiry)
            _filter.owner = props.dealersettings.id;

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        if (_filter.rottenDays &&
            !_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.client) &&
            !_.isEmpty(props.dealersettings.client.services) &&
            !_.isEmpty(props.dealersettings.client.services.pipelines)) {
            _filter.pipelineSettings = JSON.stringify(props.dealersettings.client.services.pipelines)
        }
        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> downloading...</div>),
            {
                position: 'top',
                duration: null
            },
        )

        _filter.clientID = clientID;
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();

        let _searchObject = {
            "type": "searchServiceJobs",
            "filters": JSON.stringify(_filter),
            "sortOrder": "stageDate desc",
            "pageNum": 0,
            "pageLimit": total > 999 ? 5000 : (total ? total : 5000)
        }

        _searchObject.clientID = clientID;



        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = convertServiceJobsVM(doc);
                _enquiries.push(logVM)
            });
            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from pipeline',
                type: 'excelExport',
                subType: 'serviceJobs',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = [];
                for (var i in pipelineCount) {
                    if (i !== 'Converted')
                        dataArray.push(pipelineCount[i]);
                }
                let count = _.sumBy(dataArray, "total")
                excelDownload(count);
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    return (
        <LayoutConsumer>
            {({ dealersettings, clientUsers, groupUsers, updateDealerSettings }) => dealersettings ? (


                <div className={`middle-wrapper ${_servicePipeline.length === 0 ? 'h-100' : ''}`}>

                    {
                        _servicePipeline.length === 0 ? (<></>) : (<FilterPanel
                            pipeline={_servicePipeline}
                            customFilters={customFilters}
                            handlePipelineChange={(val) => {
                                updateDealerSettings('servicePipeline', val);
                                setPageNum(0);
                                if (!_.isEmpty(localStorage.servicePipelineFilter))
                                    localStorage.removeItem('servicePipelineFilter');
                            }}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            handleApplyFilter={handleApplyFilter}
                            handleClearFilter={handleClearFilter}
                            handleTransfer={handleShowTransferEnquiries}
                            handleMove={handleShowMoveEnquiries}
                            showMore={true}
                            stageCounts={pipelineCount}
                            csvHeader={csvHeader}
                            csvData={csvData}
                            datalist={enquiries}
                            view="list"
                            isFilterApplied={isFilterApplied}
                            searchText={searchText}
                            handlesearchText={(val) => {
                                setSearchText(val);
                                setFilterApplied(false);
                                setDataload(true);
                                setPaging(false);
                                setHasMoreData([])
                                setPageNum(0);
                                if (!_.isEmpty(localStorage.servicePipelineFilter))
                                    localStorage.removeItem('servicePipelineFilter');

                            }}
                            selectedIds={selectedIds}
                            excelDownload={excelDownload}
                            csvBtn={csvBtn}
                        />)
                    }
                    <div className={`${_servicePipeline.length === 0 ? 'h-100' : 'mt-2'}`}>
                        {
                            _servicePipeline.length === 0 ? (
                                <div className="spinner-loader h-100">
                                    <div className="no-data-flex-wrap h-100 justify-content-center align-items-center text-primary">
                                        <div className="no-data-img-wrap"><img src={images.nopipeline} width="350" alt="" /></div>
                                        <div className="no-data-txt-wrap">
                                            <div className="no-data-content-head"><Translate text={'Access denied.'} /></div>
                                            <div className="no-data-content"><Translate text={'You dont have any pipeline access. Please contact administrator.'} /></div>
                                        </div>
                                    </div>
                                </div>
                            ) : enquiryLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (<div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={enquiries}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allserviceFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(serviceFields && serviceFields.length > 0) ? serviceFields : allserviceFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={enquiryDetailclick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        handleFavorite={handleFavorite}
                                        selectedIds={selectedIds}
                                        handleAllChecked={handleAllChecked}
                                        handleCheckChange={handleCheckChange}
                                        displayByValue={true}
                                    />
                                </div>
                                )
                        }


                    </div>

                    <PopUpModal show={modalTransferShow}>
                        <TransferOwner
                            show={modalTransferShow}
                            handleClose={handleCloseTransferEnquiries}
                            title={'Transfer Enquiries'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        >
                        </TransferOwner>
                    </PopUpModal>

                    <PopUpModal show={modalMoveShow}>
                        <LeadTransfer
                            show={modalMoveShow}
                            handleModalClose={handleCloseMoveEnquiries}
                            title={'Move Enquiries'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                            isList={true}
                        >
                        </LeadTransfer>
                    </PopUpModal>

                    <PopUpModal show={showReAuthentication}>
                        <ReAuthenticate
                            show={showReAuthentication}
                            handleClose={handleReAuthOpenClose}
                            dealersettings={props.dealersettings}
                            privacy='file'
                        />
                    </PopUpModal>

                    <SidePanel
                        clsActive={showpanel.clsActive}
                        clsName={showpanel.clsName}
                        sidepanelClose={sidepanelClose}
                        title=''
                    >
                        <QuickView {...props}
                            serviceid={showpanel.serviceid}
                            isFilterApplied={isFilterApplied}
                            updateListEnquiry={updateListEnquiry}
                            sidepanelClose={sidepanelClose}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        />
                    </SidePanel>

                </div>

            ) : (<></>)}
        </LayoutConsumer>
    )
}

export default PipelineListView;