import React, { useState, useEffect } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash'
import { ReactSelect } from '../../components';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import MentionEditor from '../../components/mentionEditor/mentionEditor';

const LeadTransfer = (props) => {

    const [fields, setFields] = useState({ clientID: '', pipeline: '', stage: '', owner: (props.owner ? props.owner : ''), notes: '', enquiryID: props.enquiryID });
    const [defaulStages, setDefaulStages] = useState([]);
    const [groups, setGroups] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [activeStageIndex, setActiveStageIndex] = useState(0);
    const [errors, setErrors] = useState({});
    const [allUsers, setAllusers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [teamMentions, setTeamMentions] = useState(null);
    const [mentions, setMentions] = useState([]);
    const [clientSettings, setClientSettings] = useState({});

    const mentionsData = props.clientUsers.map(clientUser => {
        return {
            name: `@${clientUser.name}`,
            id: clientUser.id,
            avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
        };
    });
    props.dealersettings.client.teams.forEach(team => {
        mentionsData.push({
            name: `@${team.name}`,
            id: team.user_ids.join(','),
            avatar: _images.group
        })
    })

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const dealerLeadTransfer = ((!_.isEmpty(_permissions) && _permissions.dealerLeadTransfer) ? true : false);

    useEffect(() => {
        const { dealersettings, groupUsers, clientUsers, pipeline, stage, owner, } = props;
        if (dealersettings && dealersettings.client && dealersettings.client.settings) {

            let _groups = [];
            // if (dealersettings.group && dealersettings.group.clients)
            //     _groups = Object.keys(dealersettings.group.clients).map(cid => { return { value: cid, label: props.dealersettings.group.clients[cid].name } })

            if (dealersettings.clients)
                _groups = dealersettings.clients.filter(c =>
                    _.isEmpty(c.moduleSettings?.sales) ||
                    (c.moduleSettings?.sales?.enabled === true && c?.moduleSettings?.sales?.active === true)
                ).map(rec => { return { value: rec.id, label: rec.name, active: true } })

            const pipelines = [];
            let stages = null;

            let _selectedPipeline = null;

            if (pipeline)
                _selectedPipeline = dealersettings.allPipelines.filter(item => item.value === pipeline)[0];
            else
                _selectedPipeline = Object.assign({}, dealersettings.allPipelines[0]);

            let defaultstages = _selectedPipeline.stages && _selectedPipeline.stages.filter(item => item.value !== 'InboundLead' && item.value !== 'Converted');
            stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            dealersettings.allPipelines.forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let objFilds = {
                ...fields,
                'clientID': dealersettings.client.id,
                'pipeline': pipeline ? pipeline : (_selectedPipeline ? _selectedPipeline.value : ''),
                'stage': stage ? stage : stages[0],
                'status': !_.isEmpty(_selectedPipeline?.status) ? _selectedPipeline.status[0] : null,
            }

            if (fields.owner === localStorage.uid) {
                const _userRoles = (dealersettings && dealersettings.roleID &&
                    dealersettings.client && dealersettings.client.roles &&
                    dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
                const noPipelineAccess = _userRoles && objFilds.pipeline && _userRoles.pipeline && _userRoles.pipeline.some(u => u === objFilds.pipeline) ? false : true;
                if (noPipelineAccess)
                    objFilds.owner = '';
            }

            // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            let options = [];
            if (!_.isEmpty(dealersettings.client.roles)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === objFilds.clientID), (v) => _.includes(roleIds, v.roleID));

                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                setAllusers(options)
            }
            else {
                props.clientUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                setAllusers(options)
            }

            let _currentUser = fields.owner && options.filter(v => v.value === fields.owner)[0];
            if (!_currentUser) {
                objFilds.owner = '';
            }

            setFields(objFilds);
            setGroups(_groups)
            setDefaulStages(defaultstages)
            setPipelines(pipelines)
            setActiveStageIndex(stage ? stages.indexOf(stage) : 0)
            setErrors({})
        }
    }, [])

    // useEffect(() => {
    //     let options = [];
    //     props.clientUsers.map(rec => {
    //         return options.push({
    //             value: rec.id,
    //             active: _.isBoolean(rec.active) ? rec.active : true,
    //             searchlabel: rec.name,
    //             label: <div className="drop-image">
    //                 <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
    //                 {rec.name}
    //             </div>
    //         })
    //     })
    //     setAllusers(options)
    // }, [])

    const handleDealerChange = async (e, data) => {
        const { dealersettings, groupUsers, clientUsers, pipeline } = props;
        const _clientSettings = await window.firebase.firestore().doc(`clients/${e.value}/currentSettings/${e.value}`).get().then(doc => { if (doc.exists) return doc.data(); else return null; });

        setClientSettings(Object.assign({}, _clientSettings));
        let objFilds = {
            ...fields,
            'clientID': e.value
        }

        if (!_.isEmpty(_clientSettings) && !_.isEmpty(_clientSettings.settings)) {

            const setttings = _clientSettings ? _clientSettings.settings : {};
            const pipelines = [];
            let _stages = [];
            let _currentClientID = dealersettings.client.id;
            let _shoWLeadBucket = ((pipeline && pipeline !== 'LeadsBucket' && e.value === _currentClientID) || e.value === _currentClientID) ? false : true;

            let _pipeline = Object.assign({}, (setttings.allPipelines ? setttings.allPipelines.filter(e => localStorage.defaultModule === 'oem' ? e.level === 'oem' : true)[0] : setttings.pipelines[0]));
            let defaultstages = _pipeline && _pipeline.stages && _pipeline.stages.filter(item => item.value !== 'InboundLead' && item.value !== 'Converted');
            if (defaultstages)
                _stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            setttings.allPipelines && setttings.allPipelines.filter(e => localStorage.defaultModule === 'oem' ? e.level === 'oem' : true).forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
            objFilds.pipeline = _pipeline ? _pipeline.value : '';
            objFilds.stage = _stages ? _stages[0] : null;
            objFilds.status = !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null;

            if (fields.owner === localStorage.uid) {
                const _userRoles = (dealersettings && dealersettings.roleID &&
                    dealersettings.client && dealersettings.client.roles &&
                    dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
                const noPipelineAccess = _userRoles && objFilds.pipeline && _userRoles.pipeline && _userRoles.pipeline.some(u => u === objFilds.pipeline) ? false : true;
                if (noPipelineAccess)
                    objFilds.owner = '';
            }

            setDefaulStages(defaultstages)
            setPipelines(pipelines)
            setActiveStageIndex(_stages.indexOf(_stages[0]))

            // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            if (!_.isEmpty(dealersettings.client.roles)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === e.value), (v) => _.includes(roleIds, v.roleID));

                let options = [];
                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                setAllusers(options)
                let _currentUser = fields.owner && options.filter(v => v.value === fields.owner)[0];
                if (!_currentUser) {
                    objFilds.owner = '';
                }
            }
            else {
                setAllusers([])
                objFilds.owner = '';
            }
        }
        else {
            objFilds.pipeline = '';
            objFilds.stage = '';
            objFilds.owner = '';

            setDefaulStages([])
            setPipelines([])
            setActiveStageIndex(0)
            setAllusers([])
        }

        setFields(objFilds);
    }

    const handlePipelineChange = (e, data) => {
        const { dealersettings, groupUsers, clientUsers } = props;

        let objFilds = {
            ...fields,
            'pipeline': e.value
        }

        if (!_.isEmpty(clientSettings) && !_.isEmpty(clientSettings.settings)) {

            const setttings = Object.assign({}, clientSettings.settings);
            let _stages = null;

            let _pipeline = Object.assign({}, (setttings.allPipelines.filter(v => v.value === e.value)[0]));
            let defaultstages = _pipeline && _pipeline.stages && _pipeline.stages.filter(item => item.value !== 'InboundLead' && item.value !== 'Converted');
            _stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            objFilds.stage = _stages[0];
            objFilds.status = !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null;

            setDefaulStages(defaultstages)
            setActiveStageIndex(_stages.indexOf(_stages[0]))

            // // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            if (!_.isEmpty(dealersettings.client.roles) && !_.isEmpty(clientSettings.users)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = clientSettings.users;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers, (v) => _.includes(roleIds, v.roleID));

                let options = [];
                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                //console.log('handlePipelineChange-allDealerUsers', allDealerUsers, roleIds, options)
                setAllusers(options)
                let _currentUser = fields.owner ? options.filter(v => v.value === fields.owner)[0] : {};
                if (!_currentUser) {
                    objFilds.owner = '';
                }
            }
            else {
                setAllusers([])
                objFilds.owner = '';
            }
            setFields(objFilds);
        }
        else {
            handleDefaultPipelineChange(e, data);
        }


    }

    const handleDefaultPipelineChange = (e, data) => {
        const { dealersettings, groupUsers, clientUsers } = props;
        //const setttings = props.dealersettings;

        let _pipeline = Object.assign({}, (dealersettings.allPipelines.filter(v => v.value === e.value)[0]));
        let defaultstages = _pipeline && _pipeline.stages && _pipeline.stages.filter(item => item.value !== 'InboundLead' && item.value !== 'Converted');
        let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

        let objFilds = {
            ...fields,
            'pipeline': e.value,
            'stage': stages[0],
            'status': !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null
        }

        if (fields.owner === localStorage.uid) {
            const _userRoles = (dealersettings && dealersettings.roleID &&
                dealersettings.client && dealersettings.client.roles &&
                dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
            const noPipelineAccess = _userRoles && e.value && _userRoles.pipeline && _userRoles.pipeline.some(u => u === e.value) ? false : true;
            if (noPipelineAccess)
                objFilds.owner = '';
        }

        // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
        if (!_.isEmpty(dealersettings.client.roles)) {
            let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                return obj.documentID;
            })];
            let _selectedUsers = [];
            let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
            if (!_.isEmpty(roleIds))
                _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === objFilds.clientID), (v) => _.includes(roleIds, v.roleID));

            let options = [];
            _selectedUsers && _selectedUsers.map(rec => {
                return options.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                })
            })
            setAllusers(options)
            let _currentUser = options.filter(v => v.value === fields.owner)[0];
            if (!_currentUser) {
                objFilds.owner = '';
            }
        }
        setFields(objFilds);
        setDefaulStages(defaultstages)
        setActiveStageIndex(stages.indexOf(stages[0]))

    }

    const handleStageChange = (e) => {
        e.preventDefault();
        const { id, name } = e.target;

        setFields({ ...fields, ['stage']: name });
        setActiveStageIndex(id)
        setErrors('stage');
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let objfields = Object.assign({}, fields);

        let notManfatory = ["notes", "enquiryID", "status"];
        if (props.isList)
            notManfatory.push('owner')

        let errorClass = 'input_error';
        for (let [key] of Object.entries(objfields)) {
            if (!fields[key] && notManfatory.indexOf(key) < 0) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        setErrors(errors);
        let newstagehistory = {
            pipeline: fields.pipeline,
            stage: fields.stage,
            date: window.firebase.firestore.Timestamp.now()
        };
        let arrstagehistory = props.enquiry && props.enquiry.stageHistory ? props.enquiry.stageHistory : []
        if (arrstagehistory.some(e => e.stage === fields.stage && e.pipeline === fields.pipeline)) {
            newstagehistory = _.filter(arrstagehistory, { stage: fields.stage, pipeline: fields.pipeline })[0]
            newstagehistory.date = window.firebase.firestore.Timestamp.now()
        }
        arrstagehistory = [...arrstagehistory.filter(e => e.stage !== fields.stage), newstagehistory]
        //    console.log('handleSubmit-errors', errors)
        if (formIsValid === true) {
            setLoading(true);
            props.handleModalClose({
                ...fields,
                stageHistory: arrstagehistory,
                mentions,
            });
        }
        else
            return formIsValid;

    };

    const handleCancel = (e) => {
        e.preventDefault();
        props.handleModalClose({});
    }

    const handleReactSelectChange = (e, data) => {
        if (e) {
            setFields({
                ...fields,
                ['owner']: e.value
            });
        }
    }

    const handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (value.length > 0) {
            setErrors({ ...errors, 'notes': '' });
        }
        setFields({
            ...fields,
            [name]: value
        });
    }

    const onMentionChange = notesText => {
        if (notesText)
            notesText = notesText.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (notesText.length > 0) {
            setErrors({ ...errors, 'notes': '' });
        }
        setFields({
            ...fields,
            notes: notesText
        });

        // handleChangelog(e, true);
    };

    const onMentionSelect = (option, ownerID) => {
        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        if (id.includes(',')) {
            if (idToPass.includes(localStorage.uid)) {
                idToPass = idToPass.replace(`${localStorage.uid},`, '')
                idToPass = idToPass.replace(`${localStorage.uid}`, '')
            }
            if (ownerID && idToPass.includes(ownerID)) {
                idToPass = idToPass.replace(`${ownerID},`, '')
                idToPass = idToPass.replace(`${ownerID}`, '')
            }
            setTeamMentions(idToPass)
        }

        // Check to avoid adding duplicate entries in mention
        const check = mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            setMentions([...mentions, { name, id: idToPass }]);
        }
    };

    const clearTeamMentions = () => {
        setTeamMentions(null)
    }

    return (

        <Modal
            show={props.show}
            onHide={props.handleModalClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-company"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title ? props.title : 'Dealer Transfer'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">

                <div className={`container-fluid ${isLoading ? 'form-disable' : ''}`}>
                    <div className="row">
                        <div className=" pop-layout form-style">

                            {
                                dealerLeadTransfer || localStorage.defaultModule === 'oem'
                                    ?
                                    <div className="form-row ">
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'dealerships'} /></label>
                                            <ReactSelect
                                                options={groups}
                                                name={"clientID"}
                                                placeholder={'select dealer'}
                                                onChange={handleDealerChange}
                                                value={fields.clientID}
                                                classNamePrefix={`${errors["clientID"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                    :
                                    <></>

                            }
                            <div className="form-row ">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'pipeline'} /></label>
                                    <ReactSelect
                                        options={pipelines}
                                        name={"pipeline"}
                                        placeholder={'select pipeline'}
                                        onChange={handlePipelineChange}
                                        value={fields.pipeline}
                                        classNamePrefix={`${errors["pipeline"]} basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'Pipeline Stage'} /> </label>
                                    <div className={`${errors["stage"] ? 'custom_error' : ''}`}>

                                        {
                                            !_.isEmpty(defaulStages)
                                                ?
                                                <>
                                                    <ul className={`pipeline-step `}>
                                                        {
                                                            defaulStages.map((_stage, index) => {
                                                                return (
                                                                    <OverlayTrigger
                                                                        key={index}
                                                                        placement='bottom'
                                                                        overlay={
                                                                            <Tooltip>{_stage.name}</Tooltip>
                                                                        }
                                                                    >
                                                                        <li key={index} className={activeStageIndex >= index ? 'active' : ''}>
                                                                            <a href="#" id={index} name={_stage.value} onClick={(e) => handleStageChange(e)}>
                                                                                {/* {_stage.name} */}
                                                                                {<>&nbsp;</>}
                                                                            </a>
                                                                        </li>
                                                                    </OverlayTrigger>

                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </>
                                                :
                                                <></>

                                        }

                                    </div>

                                </div>
                            </div>
                            <div className="form-group">
                                <label ><Translate text={'owner'} /></label>
                                <ReactSelect
                                    options={_.orderBy(allUsers, ["searchlabel"], ["asc"])}
                                    name={"owner"}
                                    onChange={handleReactSelectChange}
                                    value={fields.owner}
                                    classNamePrefix={`${errors["owner"]} basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            {
                                props.isList
                                    ?
                                    <></>
                                    :
                                    <div className="form-group">
                                        <label ><Translate text={'notes'} /></label>
                                        {/* <textarea className={`${errors["notes"]} form-control notes`} name="notes" onChange={(e) => handleNotesChanges(e)} value={fields.notes ? fields.notes : ''} placeholder="notes" cols="80" rows="3"></textarea> */}
                                        <MentionEditor
                                            mentionsData={mentionsData}
                                            onMentionChange={onMentionChange}
                                            onMentionSelect={onMentionSelect}
                                            notes={fields.notes ? fields.notes : ''}
                                            focusOnMount={false}
                                            isNoteEmpty={false}
                                            teamMentions={teamMentions}
                                            clearTeamMentions={clearTeamMentions}
                                            clientUsers={props.clientUsers}
                                            ownerID={fields.owner ? fields.owner : ''}
                                        />
                                    </div>
                            }

                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>

                <button
                    type="button"
                    className="btn btn-primary float-right ml-2"
                    onClick={(e) => handleSubmit(e)}

                >
                    {/* {
                        props.loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    } */}
                    {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                    <Translate text={'done'} />
                </button>
                <button
                    type="button"
                    className="btn btn-default float-right"
                    onClick={(e) => handleCancel(e)}
                ><Translate text={'cancel'} />
                </button>


            </Modal.Footer>
        </Modal>
    )
}

export default LeadTransfer;