/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import "../../../styles/video.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { ContentOverlay } from '../../../components'
import Translate from '../../../constants/translate';

const VideoDetails = (props) => {
    const [video, setVideo] = useState({})
    const { enquiry, client, users } = video;

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().collectionGroup(`videos`).where('documentID', '==', props.videoid)
            .where('isDeleted', '==', false)
            .limit(1).get()
            .then(snapshot => {
                if (snapshot.size > 0) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            });
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
        return
        let refCollectionData;
        if (props.videoid) {

            refCollectionData = collectionData(firestoreDB.firestore().collectionGroup(`videos`)
                .where('documentID', '==', props.videoid)
                .where('isDeleted', '==', false))
                .pipe(
                    switchMap(videos => {
                        const enqIds = _.uniq(videos.filter(item => !_.isEmpty(item.enquiryID)).map(item => item.enquiryID))
                        const clientIds = _.uniq(videos.filter(item => !_.isEmpty(item.clientID)).map(item => item.clientID))
                        return combineLatest(
                            of(videos),
                            combineLatest(
                                enqIds.length > 0 ? enqIds.map(enquiryID =>
                                    collectionData(firestoreDB.firestore().collection('enquiries').where('documentID', '==', enquiryID)).pipe(
                                        map(enquirySnapshot => enquirySnapshot[0] ? enquirySnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                        map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                                        map(usersSnapshot => usersSnapshot)
                                    )
                                ) : of([])
                            ),
                        )
                    }),
                    map(([videos, enquirySnapshot, clientSnapshot, usersSnapshot]) => {
                        return videos.map(snapDoc => {
                            return {
                                ...snapDoc,
                                enquiry: enquirySnapshot.find(a => a.documentID === snapDoc.enquiryID),
                                client: clientSnapshot.find(a => a.documentID === snapDoc.clientID),
                                users: usersSnapshot[0],
                            }
                        })
                    })
                )
                .subscribe(_videos => {
                    setVideo(_videos[0])
                });
            return () => {
                refCollectionData && refCollectionData.unsubscribe()
            }
        }
    }, [firestoreDB])
    //console.log('video', video)
    return (
        <>
            {
                !_.isEmpty(video) && !_.isEmpty(enquiry)
                    ?
                    <>
                        <nav className="navbar navbar-light video-nav-bg-white static-top report-data-load">
                            <div className="container pl-0 pr-0">
                                {!_.isEmpty(client) && client.logoURL ?
                                    <a className="navbar-logo" href="#" onClick={e => { e.preventDefault(); }}>
                                        <img src={client.logoURL} height="50" alt="" />
                                    </a>
                                    : client.name}


                            </div>
                        </nav>
                        <section className="video-section-middle-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="video-section-main-wrap">
                                        <div className="video-section-inner-wrap">
                                            <div className="video-box">
                                                <video
                                                    style={{ width: `100%`, background: `black` }}
                                                    controls
                                                    preload="auto"
                                                    src={video.url}
                                                >
                                                    <source type="video/quicktime" />
                                                </video>
                                            </div>
                                            <div className="video-details">
                                                <div className="video-info-head">{!_.isEmpty(enquiry.contact) ? CommonHelper.displayContact([], enquiry.contact, '') : ''}, here’s your <span>video presentation</span> from &nbsp;
                                                {!_.isEmpty(client) ? client.name : '--'}.</div>
                                                <span className="line"></span>
                                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="25%" ><strong><Translate text={'vehicle'} />:</strong></td>
                                                            <td width="75%" id="Model">{
                                                                (!_.isEmpty(enquiry.requirement) && (!_.isEmpty(enquiry.requirement.make) || !_.isEmpty(enquiry.requirement.stock)))
                                                                    ?
                                                                    <>
                                                                        {
                                                                            !_.isEmpty(enquiry.requirement.stock)
                                                                                ?
                                                                                enquiry.requirement.stock.make + ' ' + enquiry.requirement.stock.model
                                                                                :
                                                                                enquiry.requirement.make + ' ' + enquiry.requirement.model
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>{'--'}</>
                                                            }</td>
                                                        </tr>
                                                        <tr>
                                                            <td ><strong><Translate text={'Presenter'} />:</strong></td>
                                                            <td id="Presenter">{!_.isEmpty(users) ? CommonHelper.getUserNamebyDocumentId(users, video.modifiedBy) : '--'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td ><strong><Translate text={'phone'} />: </strong></td>
                                                            <td id="Phone">{enquiry.contact.phone ?
                                                                CommonHelper.phoneFormat(enquiry.contact.phoneCode, enquiry.contact.phone, props.dealersettings)
                                                                : '--'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td ><strong><Translate text={'email'} />: </strong></td>
                                                            <td id="Email"><div className="email-break">{enquiry.contact.email ? enquiry.contact.email : '--'} </div></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="video-section-footer-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="video-footer-left"> <span className="video-footer-heading">{!_.isEmpty(client) ? client.name : '--'}</span> <span className="line"></span>
                                        <ul className="address">
                                            <li>{client.address ? <>{client.address}</> : <></>}</li>
                                            <li><span>T</span>{client.phone ? <>{client.phone}</> : <></>}</li>
                                            <li><span>E</span>
                                                {client.email ? <a href={`mailto:${client.email}`} className="email-break">{client.email}</a> : <></>}
                                            </li>
                                            {client.website ? <li><span>W</span>
                                                <a href={`https://${client.website}`} className="email-break">{client.website}</a>
                                            </li> : <></>}


                                        </ul>
                                    </div>
                                    {/* <div className="video-footer-right"> <span className="video-footer-heading">Share your presentation</span> <span className="line"></span>
                                        <ul className="footer-social">
                                            <li><a href="#" onClick={e => { e.preventDefault(); }} target="_blank"><span><img src={_images.shareFacebook} alt="" width="28px" /></span></a></li>
                                            <li><a href="#" onClick={e => { e.preventDefault(); }} target="_blank"><span><img src={_images.shareTwitter} width="28px" /></span></a></li>
                                            <li><a href="#" onClick={e => { e.preventDefault(); }} target="_blank"><span><img src={_images.shareMail} width="28px" /></span></a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                        <section className="video-section-footer-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="video-footer-link">
                                        {/* <ul>
                                            <li> <a href="#"> Privacy Policy </a></li>
                                            <li> <a href="#">www.lexusofchatswood.com.au</a></li>
                                        </ul> */}
                                        <a href="https://fusionsd.com.au/" target="_blank">© Copyright FusionSD {moment()._d.getFullYear()}. All rights reserved.</a>
                                    </div>
                                </div>
                            </div>
                        </section>


                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>

            }
        </>



    )
};

export default VideoDetails;
