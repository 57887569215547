/** LIBRARIES */
import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
/** PROVIDERS */
//import { LayoutConsumer } from '../layout/provider';
/** VIEW-MODELS */
import { allcontactFields, objContact } from './viewModel';
/** COMPONENTS */
import CommonHelper from '../../services/common';
import { ContentOverlay, PopUpModal, TableView } from '../../components';
import FilterPanel from './filter'
import MergeContact from './merge'
import ScheduleReport from './scheduleReport';
import TransferOwner from '../common/transfer'
import ReAuthenticate from '../common/reAuthenticate';
//import { default as _images } from '../../images';
import AddContact from './add'
import { firestoreDB } from '../../services/helper';

import ContactListReducer from './contactlistReducer'

const ContactList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [contacts, dispatch] = useReducer(ContactListReducer, [])
    const [checkDataLoad, setDataload] = useState(false)
    const [sortName, setSortName] = useState('firstName')
    const [sortOrder, setSortOrder] = useState('asc')
    const [hasMore, setHasMoreData] = useState([])
    const [contactFields, setContactFields] = useState(props.dealersettings.contactFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [contactLoader, setContactLoader] = useState(true)
    const pageLimit = 50

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})

    const [modalMergeShow, setModalMergeShow] = useState({ show: false, mergeContacts: [] })
    const [modalTransferShow, setModalTransferShow] = useState(false)

    //const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    //const [campaigns, setCampaigns] = useState([])
    //const [origins, setOrigins] = useState([])

    const [showSchedulerModel, setShowSchedulerModel] = useState(false)
    const [selectedReport, setReport] = useState(null)

    const [csvHeader, setHeader] = useState([])

    const [searchText, setSearchText] = useState(localStorage.contactSearchText ? localStorage.contactSearchText : null);
    const [filterClient, setFilterClient] = useState()
    const [contactCount, setContactCount] = useState(0);

    const [myContactsOnly, setMyContactsOnly] = useState(localStorage.myContactsOnly ? JSON.parse(localStorage.myContactsOnly) : false);

    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();
    const [sortConfig, setSortConfig] = useState();
    const [tableLoader, setTableLoader] = useState(false)

    const enableSharedContact = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedContact) ? true : false);

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;

    const [showReAuthentication, setReAuthentication] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchText && searchText.length > 2) {
                setDataload(true)
            }

            if (searchText === '')
                setDataload(true)
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [searchText]);

    useEffect(() => {
        let headerFields = contactFields;
        let allHeaderFields = allcontactFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(CommonHelper.bindContactName(_headers, 'fullName'));

    }, [contactFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.unSubContacts && window.unSubContacts();
        }
    }, [])

    useEffect(() => {
        if (isFilterApplied) {
            return;
        }


        let _searchObject = {};
        const { dealersettings } = props;
        if (localStorage.defaultModule === 'tradeinPro')
            _searchObject.clientID = filterClient ? filterClient : dealersettings.client.id
        else
            _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings);

        // if (enableSharedContact && props.dealersettings.client.group) {
        //     _searchObject.groupID = props.dealersettings.client.group
        // }
        // else { _searchObject.clientID = props.dealersettings.client.id }

        try {
            const addContact = window.firebase.functions().httpsCallable('contact-getContactCount');
            addContact(_searchObject).then((_data) => {
                if (!_.isEmpty(_data) && !_.isEmpty(_data.data) && _data.data.length > 0) {
                    //console.log('contact-getContactCount', _data.data[0])
                    setContactCount(_data.data[0].contact_count);
                }

            });
        }
        catch (error) {
            console.error(error)
        }

    }, [isFilterApplied, filterClient])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

            //const setttings = props.dealersettings.client.settings;

            // let _titles = [];
            // setttings.titles && setttings.titles.forEach((doc) => {
            //     _titles.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name
            //     });
            // });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _countries = [];
            props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
                _countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _states = [];
            props.dealersettings.states && props.dealersettings.states.forEach((doc) => {
                _states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    country_code: doc.country_code
                });
            });

            // let _campaigns = [];
            // setttings.campaigns && setttings.campaigns.forEach(doc => {
            //     campaigns.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // let _origins = [];
            // setttings.origins && setttings.origins.forEach(doc => {
            //     origins.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // setCampaigns(_campaigns);
            // setOrigins(_origins);

            // setTitles(_titles);
            setLanguages(_languages);
            setNationalities(_nationalities)
            setCountries(_countries)
            setStates(_states)
            setDataload(true);
        }
    }, [])

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'contacts')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.contactFilter', isFilterApplied, localStorage.contactFilter)
        if (localStorage.contactFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.contactFilter])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }
        const { dealersettings } = props;
        let clientID = dealersettings.client ? dealersettings.client.id : '';


        if ((localStorage.contactFilter && isValidFilter()) || isValidSortConfig()) {

            let _contactFilter = localStorage.contactFilter ? JSON.parse(localStorage.contactFilter) : {};
            let _filter = Object.assign({}, _contactFilter.value);
            let _sortOrder = sortName + ' ' + sortOrder;

            if (_filter['owner'] && isOtherContactOwners(_filter['owner'])) {
                setMyContactsOnly(false);
                localStorage.setItem('myContactsOnly', false)
            }
            else {
                _filter.owner = myContactsOnly ? localStorage.uid : _filter.owner;
                if (!_filter.owner)
                    delete _filter['owner'];
            }
            // if (!viewOtherContact)
            //     _filter.owner = dealersettings.id;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                delete _filter['date'];
            }

            /* MAKE & MODEL FILTER */
            if (!_.isEmpty(_filter.make))
                _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

            // if (enableSharedContact && props.dealersettings.client.group) {
            //     _filter.groupID = props.dealersettings.client.group
            // }

            if (localStorage.defaultModule === 'tradeinPro')
                _filter.clientID = filterClient ? filterClient : clientID
            else
                _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings);

            _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
            let _searchObject = {
                "type": "searchContacts",
                "filters": JSON.stringify(_filter),
                "sortOrder": _sortOrder,
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }

            // if (!enableSharedContact || _filter.clientID)
            //     _searchObject.clientID = _filter.clientID ? _filter.clientID : clientID;
            if (localStorage.defaultModule === 'tradeinPro')
                _searchObject.clientID = _filter.clientID
            else
                _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings);

            //console.log('generic-searchData', _searchObject, isFilterApplied, pageNum, _searchObject, localStorage.contactFilter)
            const searchContacts = window.firebase.functions().httpsCallable('generic-searchData');
            searchContacts(_searchObject).then((response) => {
                //console.log('generic-searchData-response', response);
                if (response.data.success && ((localStorage.contactFilter && isValidFilter()) || isValidSortConfig())) {
                    onCollectionContacts(response.data);
                    setContactCount(response.data.total);
                }
                else {
                    setDataload(false)
                    setContactLoader(false)
                    setTableLoader(false)
                    setPaging(false)
                }

            });

            //window.unSubContacts && window.unSubContacts();
        }
        else {

            let searchKeywords = searchText;
            let selectClient = filterClient;
            let searchOwners = myContactsOnly ? [localStorage.uid] : [];

            if (localStorage.contactFilter) {
                let _contactFilter = JSON.parse(localStorage.contactFilter);
                let _filter = Object.assign({}, _contactFilter.value);

                searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                searchOwners = _filter['owner'] ? _filter['owner'].split(",") : searchOwners;

                setSearchText(searchKeywords);
                setFilterClient(selectClient);

                localStorage.contactSearchText = searchKeywords;
                localStorage.contactFilterClient = selectClient;

                if (_filter['owner'] && isOtherContactOwners(_filter['owner'])) {
                    setMyContactsOnly(false);
                    localStorage.setItem('myContactsOnly', false)
                }

                // console.log('search---Keywords', searchKeywords, searchOwners, searchText)
            }

            let contactlstDataRef = firestoreDB(dealersettings).firestore().collection('contactDetails')

            if (localStorage.defaultModule === 'tradeinPro')
                contactlstDataRef = contactlstDataRef.where('clientID', '==', (selectClient ? selectClient : clientID))
            else
                contactlstDataRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(contactlstDataRef, dealersettings);

            // if (enableSharedContact && dealersettings.client.group) {
            //     contactlstDataRef = contactlstDataRef.where('groupID', '==', dealersettings.client.group)
            //     //contactlstDataRef = contactlstDataRef.where('clientID', '==', dealersettings.client.id)
            // }
            // else {
            //     contactlstDataRef = contactlstDataRef.where('clientID', '==', dealersettings.client.id)
            // }

            contactlstDataRef = contactlstDataRef.where('isDeleted', '==', false)

            if (searchKeywords) {
                let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                if (formattedSearchText.trim())
                    contactlstDataRef = contactlstDataRef.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
            }

            if (!_.isEmpty(searchOwners)) {
                contactlstDataRef = contactlstDataRef.where('owner', 'in', searchOwners)
            }


            // if (dealersettings.level === 'individual')
            //     contactlstDataRef = contactlstDataRef.orderBy('addedDate', 'desc')
            // else
            contactlstDataRef = contactlstDataRef.orderBy('firstName', 'asc')
            // if (!viewOtherContact)
            //     contactlstDataRef = contactlstDataRef.where('owner', '==', dealersettings.id)

            // if (enableSharedContact && dealersettings.client.group) {
            //commented on 30-03//
            // }
            // else {
            //     contactlstDataRef = contactlstDataRef
            //         .orderBy(sortName, sortOrder)
            // }


            if (hasMore.length > 0) {
                contactlstDataRef = contactlstDataRef
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                contactlstDataRef = contactlstDataRef
                    .limit(pageLimit)
            }

            // window.unSubContacts = contactlstDataRef
            //     .onSnapshot(onCollectionUpdate);

            contactlstDataRef.get().then(onCollectionUpdate);
        }

    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         snapshotDoc = convertContactVM(change.doc.data());
        //         if (change.type === 'added') {
        //             actionType = "ADDCONTACT";
        //         }
        //         else if (change.type === 'modified') {
        //             actionType = "UPDATECONTACT"
        //         }
        //         else if (change.type === 'removed') {
        //             actionType = "REMOVECONTACT"
        //         }
        //     }
        // })
        const contacts = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                contacts.push(convertContactVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }
        if (isPaging) {
            dispatch({
                type: "APPENDCONTACT",
                data: contacts,
                sortName: sortName,
                sortOrder: sortOrder
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS",
                data: actionType ? snapshotDoc : contacts,
                sortName: sortName,
                sortOrder: sortOrder
            });
        }


        setDataload(false)
        setContactLoader(false)
        setTableLoader(false)
        setPaging(false)
    }

    const onCollectionContacts = (querySnapshot) => {

        const _contacts = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            var logVM = convertContactVM(doc);
            _contacts.push(logVM)
        });

        dispatch({
            type: isPaging ? "APPENDCONTACT" : "SUCCESS",
            data: _contacts,
            sortName: sortName,
            sortOrder: sortOrder
        });

        setDataload(false)
        setPaging(false)
        setContactLoader(false)
        setTableLoader(false)

    }

    const convertContactVM = (doc) => {

        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objcontactData)) {
            contact[key] = value;
        }
        contact.objDoc = Object.assign({}, contact);
        contact.firstName = _.trim(doc.firstName);
        contact.phone = CommonHelper.phoneFormat(doc.phoneCode, doc.phone, props.dealersettings);
        contact.displayPhone = CommonHelper.phoneFormat(doc.phoneCode, doc.phone, props.dealersettings, true);
        contact.displayID = contact.displayID ? contact.displayID : '--';
        contact.licenseExpiry = contact.licenseExpiry ? moment(contact.licenseExpiry).format('DD/MM/YYYY') : '';
        contact.createdOn = moment.unix(contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        contact.updatedOn = moment.unix(contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');
        contact.company = !_.isEmpty(contact.company) ? contact.company.name : '';
        contact.dob = contact.dob ? moment(contact.dob).format('DD/MM/YYYY') : '';
        contact.secondaryName = contact.secondaryFirstName ? (contact.secondaryFirstName + ' ' + contact.secondaryLastName) : '';
        contact.secondaryPhone = CommonHelper.phoneFormat(contact.secondaryPhoneCode, contact.secondaryPhone, props.dealersettings);
        contact.secondaryDob = contact.secondaryDob ? moment(contact.secondaryDob).format('DD/MM/YYYY') : '';
        contact.addedBy = CommonHelper.getUserNamebyId(allUsers, contact.addedBy);
        contact.modifiedBy = CommonHelper.getUserNamebyId(allUsers, contact.modifiedBy);
        contact.owner = contact.owner ? CommonHelper.getUserNamebyId(allUsers, contact.owner) : '';
        contact.language = CommonHelper.getLabelByValue(languages, contact.language, '');
        contact.nationality = CommonHelper.getLabelByValue(nationalities, contact.nationality, '');
        contact.country = CommonHelper.getLabelByValue(countries, contact.country, '');
        contact.state = CommonHelper.getLabelByValue(states.filter(a => doc?.country ? a.country_code === doc.country : true), contact.state, '');
        contact.csvPhone = contact.displayPhone ? `${contact.displayPhone}` : ''

        // if (this.selectedClientID && !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.group) && !_.isEmpty(dealersettings.group.clients)
        //     && !_.isEmpty(dealersettings.group.clients[this.selectedClientID]) && !_.isEmpty(dealersettings.group.clients[this.selectedClientID].settings)) {

        //     const client = Object.assign({}, dealersettings.group.clients[this.selectedClientID]);
        //     const setttings = client.settings;

        let dealersettings = props.dealersettings;
        let _setttings = contact.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[contact.clientID] && dealersettings.group.clients[contact.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
        if (_setttings) {
            contact.fullName = CommonHelper.getFullName(_setttings.titles, contact, '', true);
            contact.campaign = CommonHelper.getNameByValue(_setttings.campaigns, contact.campaign, '');
            contact.origin = CommonHelper.getNameByValue(_setttings.origins, contact.origin, '');
            contact.gender = CommonHelper.getNameByValue(_setttings.genders, contact.gender, '');
            contact.secondaryGender = CommonHelper.getNameByValue(_setttings.genders, contact.secondaryGender, '');
            contact.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, contact.maritalStatus, '');
            contact.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, contact.contactMethod, '');
            contact.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, contact.interests, '', true);
            contact.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, contact.licenseType, '');
        }

        if (contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            contact.dealerName = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);
        }
        else
            contact.dealerName = '';

        contact.isVIP = doc.isVIP ? 'Yes' : 'No';
        contact.isGDPR = doc.gdprDeleted ? 'Yes' : 'No';
        contact.banLoanVehicle = doc.banLoanVehicle ? 'Yes' : 'No';
        contact.isDLScan = doc.isDLScan ? 'Yes' : 'No';
        contact.optinPhone = contact.optinPhone === null ? '' : (contact.optinPhone ? 'Yes' : 'No');
        contact.optinEmail = contact.optinEmail === null ? '' : (contact.optinEmail ? 'Yes' : 'No');
        contact.optinPost = contact.optinPost === null ? '' : (contact.optinPost ? 'Yes' : 'No');
        contact.optinSMS = contact.optinSMS === null ? '' : (contact.optinSMS ? 'Yes' : 'No');
        contact.marketingOptinEmail = contact.marketingOptinEmail === null ? '' : (contact.marketingOptinEmail ? 'Yes' : 'No');
        contact.marketingOptinSMS = contact.marketingOptinSMS === null ? '' : (contact.marketingOptinSMS ? 'Yes' : 'No');
        return contact
    }

    const isValidFilter = () => {
        if (localStorage.contactFilter) {
            let _contactFilter = JSON.parse(localStorage.contactFilter);
            let _filter = Object.assign({}, _contactFilter.value);

            if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
                return false;

            if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    const isValidSortConfig = () => {
        if (searchText && sortConfig)
            return false;
        else if (localStorage.contactFilter && sortConfig) {
            let _contactFilter = JSON.parse(localStorage.contactFilter);
            let _filter = Object.assign({}, _contactFilter.value);

            if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
                return false;

            if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
                return false;

            return true;
        }
        else if (sortConfig) return true; else return false;
    }

    const isOtherContactOwners = (owner) => {
        let _owners = owner ? owner.split(',') : [];
        if (_owners.length > 1 || (_owners.length === 1 && _owners.indexOf(localStorage.uid) < 0)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('contactFilter', JSON.stringify(_filter));
            localStorage.contactSearchText = '';
            setFilterApplied(true);
            setContactLoader(true)
            setDataload(true);
            setPaging(false);
            setHasMoreData([]);
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.contactFilter)) {
            localStorage.removeItem('contactFilter');
            localStorage.contactSearchText = '';
            setFilterApplied(false);
            setContactLoader(true)
            setDataload(true);
            setPaging(false);
            setHasMoreData([]);
            setPageNum(0);
            setSearchText('');
            setSortConfig(null)
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ contactFields: fields }, { merge: true })
            .then(() => {
                setContactFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('contactFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, _data) => {
        return _data?.gdprDeleted ?
            (<div className="mini-button" onClick={(e) => { e.preventDefault(); }}><i className="ico icon-lock"></i></div>)
            : (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                setEditDocument(id);
                setModelShow(true);
            }}>
                <i className="ico icon-edit"></i>
            </div>)
    }

    const handleContactdetailsClick = (e, id) => {
        e.preventDefault();
        props.history.push("/contacts/details/" + id);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }
    const handleClose = (id) => {
        setModelShow(false);
        setEditDocument(null);
    }

    const updateContact = (objContact) => {
        if (!_.isEmpty(objContact)) {
            let _data = convertContactVM(objContact);

            if (!_.isEmpty(_data)) {
                dispatch({
                    type: "UPDATECONTACT",
                    data: _data,
                    sortName: sortName,
                    sortOrder: sortOrder
                });
            }
        }

    }

    const handleAddContact = () => {
        setEditDocument(null);
        setModelShow(true);
    }

    const RemoveContact = (docID) => {
        let objContact = Object.assign({}, contacts.filter(e => e.documentID === docID)[0].objDoc);
        if (!_.isEmpty(objContact)) {
            let _data = convertContactVM(objContact);

            if (!_.isEmpty(_data)) {
                dispatch({
                    type: "REMOVECONTACT",
                    data: _data,
                    sortName: sortName,
                    sortOrder: sortOrder
                });
            }
        }

    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(contacts) && contacts.map(file => {
                let record = Object.assign({}, contacts.filter(e => e.documentID === file.documentID)[0].objDoc);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, contacts.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleShowMergeContacts = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds) || (!_.isEmpty(_selectedIds) && Object.keys(selectedIds).length !== 2)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select two contacts to merge.'));
            return;
        }
        else {
            const _mergeContacts = [];
            for (let [key, value] of Object.entries(_selectedIds)) {
                if (value?.gdprDeleted) {
                    Swal.fire(CommonHelper.showLocale(props, "Can't merge GDPR contacts"));
                    return;
                }
                _mergeContacts.push(value);
            }

            if (_mergeContacts.length === 2)
                setModalMergeShow({ show: true, mergeContacts: _mergeContacts })
        }
    }

    const handleShowTransferContacts = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select contact to transfer.'));
            return;
        }
        else {
            setModalTransferShow(true)
        }
    }

    const handleCloseMergeContacts = (fields) => {

        if (!_.isEmpty(fields)) {
            let objData = Object.assign({}, fields);
            let _param = {};

            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = props.dealersettings ? props.dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = props.dealersettings ? props.dealersettings.id : '';
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';
            let defaultCountry = props.dealersettings.client.countryCode ? props.dealersettings.client.countryCode.toUpperCase() : 'AU';
            //Remove empty value from object
            for (var propName in objData) {

                if (defaultCountry === 'AU' && (propName === 'phone' || propName === 'secondaryPhone')) {
                    objData[propName] = CommonHelper.validPhoneZeroPrefix(objData[propName], objData[`${propName}Code`])
                }

                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            if (objData.documentID)
                _param['contactID'] = objData.documentID;

            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(objData, props.dealersettings, objData.clientID, true);

            // if (!objData.clientID)
            //     objData.clientID = props.dealersettings ? props.dealersettings.client.id : '';

            // if (!objData.groupID)
            //     objData.groupID = (props.dealersettings && props.dealersettings.client.group ? props.dealersettings.client.group : null);

            // if (!objData.regionID)
            //     objData.regionID = (props.dealersettings && props.dealersettings.client.region ? props.dealersettings.client.region : null);

            // if (!objData.oemID)
            //     objData.oemID = (props.dealersettings ? props.dealersettings.client.settingsID : null);

            if (objData.mergeID) {
                _param['mergeID'] = objData.mergeID;
                delete objData['mergeID'];
            }

            if (objData.groupID) {
                _param['groupID'] = objData.groupID;
            }
            _param = CommonHelper.setClientOrGroupOrRegionOrOemID(_param, props.dealersettings);
            _param['isShared'] = ((!_.isEmpty(props.dealersettings) &&
                !_.isEmpty(props.dealersettings.group) &&
                props.dealersettings.group.enableSharedContact) ? true : false);
            //console.log('contact-saveContact', _param, objData);
            const addContact = window.firebase.functions().httpsCallable('contact-saveContact');
            addContact({
                "model": objData,
                "param": _param
            }).then((res) => {
                //console.log('Contact_docRef', data);
                let response = res.data;
                //console.log(response);

                if (_param.mergeID) {
                    updateContact(objData);
                    RemoveContact(_param.mergeID);
                }
                setModalMergeShow({ show: false, mergeContacts: [] })
                setSelectedIds({});
                toast.notify(response.message, {
                    duration: 2000
                });
                return true;
            });

        }
        else {
            setModalMergeShow({ show: false, mergeContacts: [] })
        }

    }

    const handleCloseTransferContacts = async (owner) => {
        if (owner) {

            let ObjData = {
                owner: owner,
                modifiedBy: props.dealersettings ? props.dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
            }

            const batch = window.firebase.firestore().batch();
            let _selectedIds = Object.assign({}, selectedIds);
            for (let [key, value] of Object.entries(_selectedIds)) {
                let _selectedContact = Object.assign({}, value);
                await firestoreDB(props.dealersettings).firestore().collection('contactDetails')
                    .doc(key)
                    .update(ObjData).then((docRef) => {
                        for (let [key, value] of Object.entries(ObjData)) {
                            _selectedContact[key] = value;
                        }
                        updateContact(_selectedContact);
                    }).catch((error) => {
                        console.error("Error updating contactDetails: ", error);
                    });
            }

            batch.commit()
                .then(() => {
                    setSelectedIds({});
                    setModalTransferShow(false)
                    toast.notify('Contact transferred successfully', {
                        duration: 2000
                    })
                })
                .catch((error) => {
                    console.log(error);
                    setModalTransferShow(false)
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else {
            setModalTransferShow(false);
        }
    }

    const excelDownload = async (total) => {
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        const { dealersettings } = props;
        let clientID = dealersettings.client ? dealersettings.client.id : '';
        let _contactFilter = localStorage.contactFilter ? JSON.parse(localStorage.contactFilter) : {};
        let _filter = Object.assign({}, _contactFilter.value);
        let _sortOrder = sortName + ' ' + sortOrder;

        _filter.owner = myContactsOnly ? localStorage.uid : _filter.owner;
        if (!_filter.owner)
            delete _filter['owner'];

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
            delete _filter['date'];
        }

        /* MAKE & MODEL FILTER */
        if (!_.isEmpty(_filter.make))
            _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);


        if (localStorage.defaultModule === 'tradeinPro')
            _filter.clientID = filterClient ? filterClient : clientID
        else
            _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings);

        if (!_.isEmpty(searchText)) {
            _filter = {
                ..._filter,
                keywords: searchText
            }
        }
        _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
        let _searchObject = {
            "type": "searchContacts",
            "filters": JSON.stringify(_filter),
            "sortOrder": _sortOrder,
            "pageNum": 0,
            "pageLimit": total > 999 ? 10000 : (total ? total : 10000)
        }

        if (localStorage.defaultModule === 'tradeinPro')
            _searchObject.clientID = _filter.clientID
        else
            _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings);

        //console.log('generic-searchData-export', _searchObject)

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        const searchContacts = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchContacts(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = convertContactVM(doc);
                _enquiries.push(logVM)
            });
            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from contacts',
                type: 'excelExport',
                subType: 'contactDetails',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
        }

    }


    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                excelDownload(contactCount);
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    const hadleEditReport = (data) => {
        //setPopHeader('Edit Scheduler')
        setReport({
            'documentID': props.dealersettings ? props.dealersettings.client.id + '-contact' : null
        })
        setShowSchedulerModel(true)
    }

    const handleModelClose = () => {
        setShowSchedulerModel(false)
        //setShowDetailsModel(false)
    }

    const handleDynamicSort = (sortConfig) => {
        setSortName(sortConfig && sortConfig.key ? sortConfig.key : 'firstName');
        setSortOrder(sortConfig && sortConfig.sort ? sortConfig.sort : 'asc');
        setSortConfig(sortConfig);
        setTableLoader(true)
        setDataload(true);
        setPaging(false);
        setHasMoreData([]);
        setPageNum(0);
    }
    return (
        <>
            <FilterPanel
                sortOrder={sortOrder}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                handleMergeContacts={handleShowMergeContacts}
                sortName={sortName}
                handlesortOrder={(val) => {
                    setSortOrder(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
                handleSortNamechange={(val) => {
                    setSortName(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
                handleTransfer={handleShowTransferContacts}
                csvHeader={csvHeader}
                datalist={contacts}
                searchText={searchText}
                handlesearchText={(val) => {
                    setSearchText(val)
                    setFilterApplied(false);
                    setContactLoader(true);
                    //setDataload(true);
                    setPaging(false);
                    setHasMoreData([]);
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.contactFilter))
                        localStorage.removeItem('contactFilter');

                }}
                handleAddContact={handleAddContact}
                contactCount={contactCount}
                myContactsOnly={myContactsOnly}
                handleContactsOnly={(val) => {
                    setMyContactsOnly(val);
                    setFilterApplied(false);
                    setContactLoader(true)
                    setDataload(true);
                    setPaging(false);
                    setHasMoreData([]);
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.contactFilter))
                        localStorage.removeItem('contactFilter');

                }}
                csvData={csvData}
                excelDownload={excelDownload}
                csvBtn={csvBtn}
                hadleEditReport={hadleEditReport}
                filterClient={filterClient}
                handleFilterClient={(val) => {
                    setFilterClient(val)
                    setFilterApplied(false);
                    setContactLoader(true);
                    setDataload(true);
                    setPaging(false);
                    setHasMoreData([]);
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.contactFilter))
                        localStorage.removeItem('contactFilter');

                }}
            />
            {
                contactLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    (
                        <div className="contact-panel" style={{ width: windowSize.windowWidth + 'px' }}>
                            {
                                tableLoader
                                    ?
                                    <>
                                        <div className="dashboard-widget-loader h-100">
                                            <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            }
                            <TableView
                                isReorderable={true}
                                datalist={contacts}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={allcontactFields.filter(e => localStorage.defaultModule === 'oem' ? e.value !== 'checkbox' : true)}
                                handleSavesettings={handleSavesettings}
                                dynamicFields={(contactFields && contactFields.length > 0) ? contactFields.filter(e => localStorage.defaultModule === 'oem' ? e !== 'checkbox' : true) : allcontactFields.filter(e => e.default === true && (localStorage.defaultModule === 'oem' ? e.value !== 'checkbox' : true)).map(v => _.pick(v, ['value']).value)}
                                settingsLoader={settingsLoader}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleContactdetailsClick}
                                isSettings={true}
                                hasMore={hasMore}
                                handlePagination={handlePagination}
                                isPaging={true}
                                selectedIds={selectedIds}
                                handleAllChecked={handleAllChecked}
                                handleCheckChange={handleCheckChange}
                                isSorting={true}
                                dynamicSort={true}
                                handleDynamicSort={handleDynamicSort}
                                sortConfig={sortConfig}
                                displayByValue={true}
                            />
                        </div>
                    )
            }
            <PopUpModal show={modelShow}>
                <AddContact
                    show={modelShow}
                    handleClose={handleClose}
                    docID={editDocumentID}
                    title={editDocumentID ? 'editContact' : 'addContact'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isFilterApplied={true}
                    updateContact={updateContact}
                >
                </AddContact>
            </PopUpModal>

            <PopUpModal show={modalMergeShow.show}>
                <MergeContact
                    show={modalMergeShow.show}
                    contactA={modalMergeShow.mergeContacts[0]}
                    contactB={modalMergeShow.mergeContacts[1]}
                    isNewContact={false}
                    handleClose={handleCloseMergeContacts}
                    title={'Merge Contacts'}
                    dealersettings={props.dealersettings}
                >
                </MergeContact>
            </PopUpModal>

            <PopUpModal show={modalTransferShow}>
                <TransferOwner
                    show={modalTransferShow}
                    handleClose={handleCloseTransferContacts}
                    title={'Transfer Contacts'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </TransferOwner>
            </PopUpModal>

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>

            <PopUpModal show={showSchedulerModel}>
                <ScheduleReport
                    {...props}
                    show={showSchedulerModel}
                    handleClose={handleModelClose}
                    title={'Schedule Report.'}
                    report={selectedReport}
                ></ScheduleReport>
            </PopUpModal>
        </>
    )
}

export default ContactList