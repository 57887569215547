/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import Translate from '../../../../constants/translate';
import { ContentOverlay, PopUpModal, ReactSelect, TableView } from '../../../../components';
import { frequencyOptions, tableFields } from './viewModel';
import AddLookup from './addItem';
import uuid from 'react-uuid'
import _ from 'lodash'

const RosterList = (props) => {
	const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 259) })
	const [searchText, setSearchText] = useState('');
	const [showModel, setShowModel] = useState({ show: false })
	const [selectedClient, setSelectedClient] = useState()
	const [selectedLookup, setSelectedLookup] = useState()

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
				windowHeight: (window.innerHeight - 259)
			})
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, [])



	const handleAddItem = () => {
		setShowModel({
			show: true,
			title: `Add ${props.title}`,
			newLookup: {
				level: 'individual',
				levelID: [props.dealersettings.client.id],
				value: props.activeTab === 'rosterCategories' ? '' : uuid(),
				active: true,
				color: '#CCCCCC'
			},
		})
	}

	const handleActionClick = (id, data) => {
		return (<div className="action-fix">
			<div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
				e.preventDefault();
				setShowModel({
					show: true,
					title: `Edit ${props.title}`,
					lookup: data
				})
			}}>
				<i className="ico icon-edit"></i>
			</div>
			<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
				e.preventDefault();
				props.handleDeleteItem(data)
			}}>
				<i className="ico icon-delete"></i>
			</div>
		</div>)
	}

	const handleClientdetailsClick = (e, id, data) => {
		e.preventDefault();
		setShowModel({
			show: true,
			title: `Edit ${props.title}`,
			lookup: data
		})
	}

	return (<>
		<div className="settings-head form-style">
			<div className="row">
				<div className="col-md-9">
					<div className="row">
						<div className="col-md-3 pr-0">
							<ReactSelect
								options={props.clientOptions}
								name={"client"}
								placeholder={'select client'}
								onChange={(e) => {
									setSelectedClient(e ? e.data : null)
								}}
								value={!_.isEmpty(selectedClient) ? selectedClient.documentID : ''}
								classNamePrefix={`basic-select`}
								removeClearable={false}
							>
							</ReactSelect>
						</div>
						<div className="filter-search search-icon col-md-3">
							<input placeholder="search..." aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
						</div>
					</div>
				</div>

				<div className="col-md-3">
					<div className="float-right">
						<button type="button" className="btn btn-primary float-right" onClick={() => { handleAddItem(); }}><i className="ico icon-add mr-2"></i> <Translate text={'add'} /></button>
					</div>
				</div>
			</div>
		</div>
		{
			props.loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
				props.lookupList && props.lookupList.length > 0 ? (
					<div className="common-table">
						<TableView
							datalist={props.lookupList.filter(({ name, levelID }) =>
								(!_.isEmpty(searchText) ? (
									(!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false)
								) : true) &&
								(selectedClient ? levelID.includes(selectedClient.documentID) : true)
							)}
							height={windowSize.windowHeight}
							width={windowSize.windowWidth}
							columns={tableFields}
							dynamicFields={tableFields.filter(e => (props.activeTab === 'rosterCategories' ? e.value !== 'strCron' : true) && e.default === true).map(v => _.pick(v, ['value']).value)}
							handleActionClick={handleActionClick}
							handleRowSingleClick={handleClientdetailsClick}
							isSettings={false}
							handleSwitchChange={props.handleSwitchChange}
							isTranslate={true}
							dealersettings={props.dealersettings}
						/>
					</div>
				) : (
					<div className="common-table">
						<div className="text-center p-5">
							<p><Translate text={'No items found.'} /></p>
							<button className="btn btn-sm btn-default" onClick={() => { handleAddItem() }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
								<Translate text={'add'} />
							</button>
						</div>
					</div>
				)
		}
		<PopUpModal show={showModel.show}>
			<AddLookup
				{...props}
				{...showModel}
				activeLookup={props.activeTab}
				allLookups={props.allLookups}
				selectedOEM={{}}
				groupOptions={[]}
				clientOptions={props.clientOptions}
				regionOptions={[]}
				handleClose={() => {
					setShowModel({ show: false })
				}}
				frequencyOptions={frequencyOptions}
			/>
		</PopUpModal>
	</>);
};

export default RosterList;
