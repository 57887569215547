import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
import { collectionData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'
import Swal from 'sweetalert2';

import { ContentOverlay, PopUpModal, TableView } from '../../components'
import Translate from '../../constants/translate';

import FleetHistoryReducer from './historyReducer'
import AddTestDrive from './add'
import CommonHelper from '../../services/common';
import ReAuthenticate from '../common/reAuthenticate';

import { testDriveStatus } from '../../services/enum'
import FilterPanel from './filter'
import { objTestDriveHistoryVM, allTestDriveHistoryFieldsVM } from './viewModel'
import { firestoreDB } from '../../services/helper';

const TestDriveHistoryListView = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 170) })
    const [fleetHistory, dispatch] = useReducer(FleetHistoryReducer, { TestDrive: [] })
    const [checkDataLoad, setDataload] = useState(true)
    const [hasMore, setHasMoreData] = useState([])
    const [testDriveHistoryFields, setTestDriveHistoryFields] = useState(props.dealersettings.testDriveHistoryFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelTestDriveShow, setModelTestDriveShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [fleetHistoryLoader, setLoader] = useState(true)

    const [activeTab, setActiveTab] = useState('TestDrive')
    const [searchText, setSearchText] = useState(localStorage.tdHistorySearchText ? localStorage.tdHistorySearchText : '')

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [testDriveCount, setTestDriveCount] = useState(0)
    const [pageNum, setPageNum] = useState(0)
    const pageLimit = 50

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [dates, setDates] = useState([]);

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
    const exportData = ((!_.isEmpty(_permissions) && _permissions.exportData) ? true : false);
    const isOEMModule = localStorage.defaultModule === 'oem' ? true : false
    const stockLocations = props.dealersettings?.client?.clientSettings?.stockLocations
    useEffect(() => {
        //console.log('localStorage.testDriveFilter', isFilterApplied, localStorage.testDriveFilter)
        if (localStorage.testDriveFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.testDriveFilter])

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'testDrives')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        let headerFields = testDriveHistoryFields;;
        let allHeaderFields = allTestDriveHistoryFieldsVM;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: rec.subText, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: rec.subText, key: rec.subText })
            }
        })
        if (isOEMModule ||!_.isEmpty(stockLocations))
            _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
                var index = _.findIndex(_headers, (e) => {
                    return e.key == rec.value;
                }, 0)
                if (index >= 0) {
                    _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
                }
                else {
                    _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
                }
            })
        //setHeader(_headers);
        setHeader(CommonHelper.bindContactName(_headers, 'contactName'));

    }, [testDriveHistoryFields])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            setDataload(false)
            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            setCampaigns(_campaigns);
            setOrigins(_origins);
            setTitles(_titles);
            setLanguages(_languages);
            setNationalities(_nationalities)
            setDataload(true)
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 170)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubTestDriveList && window.unSubTestDriveList();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }
        const { dealersettings } = props;
        if (isFilterApplied || localStorage.testDriveFilter || isOEMModule) {
            let _testDriveFilter = localStorage.testDriveFilter ? JSON.parse(localStorage.testDriveFilter) : {};
            let _filter = Object.assign({}, _testDriveFilter.value);
            let _isWhere = false;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
                delete _filter['date'];
            }
            if (isOEMModule && !_.isEmpty(dealersettings.group.clients)) {
                if (_.isEmpty(_filter.clientID)) _filter.clientID = Object.keys(dealersettings.group.clients).map(cid => cid).join(',')
            }
            else {
                _filter.clientID = props.dealersettings.client.id;
            }

            if (searchText && searchText.trim()) _filter.keywords = searchText.trim().toLowerCase();

            _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();
            //_filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, props.dealersettings);
            let _searchObject = {
                "type": "searchTestdrives",
                "clientID": _filter.clientID,
                "filters": JSON.stringify(_filter),
                "sortOrder": `${_filter.dateType ? _filter.dateType : 'modifiedDate'} desc`,
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }
            //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings);
            // console.log('generic-searchData', isFilterApplied, pageNum, _searchObject, localStorage.testDriveFilter)
            const searchTestdrives = window.firebase.functions().httpsCallable('generic-searchData');
            searchTestdrives(_searchObject).then((response) => {
                //   console.log('generic-searchTestdrives', response);
                if (response.data.success && (localStorage.testDriveFilter || isOEMModule)) {
                    onCollectionStocks(response.data);
                    setTestDriveCount(response.data.total)
                }
                else {
                    setDataload(false)
                    setLoader(false)
                    setPaging(false)
                }
            });

            window.unSubTestDriveList && window.unSubTestDriveList();
        }
        else {
            let refTestDriveData = firestoreDB(props.dealersettings).firestore().collection('testdrives')
                .where('clientID', '==', props.dealersettings.client.id)
                .where('isDeleted', '==', false)
                //.where('startDate', '>=', dateRange.startDate)
                //.where('startDate', '<=', dateRange.endDate)
                .orderBy('startDate', 'desc')

            if (searchText && searchText.trim()) {
                refTestDriveData = refTestDriveData
                    .where('keywords', 'array-contains', searchText.trim().toLowerCase())
            }

            if (hasMore.length > 0) {
                refTestDriveData = refTestDriveData
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                refTestDriveData = refTestDriveData
                    .limit(pageLimit)
            }

            window.unSubTestDriveList = refTestDriveData
                .onSnapshot(onCollectionUpdate);
        }


    }, [checkDataLoad])

    const onCollectionStocks = (querySnapshot) => {

        const _stocks = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            var dataVM = dataMappingVM(doc);
            _stocks.push(dataVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS_LIST",
            data: _stocks,
            activetab: activeTab
        });

        setLoader(false)
        setDataload(false)
        setPaging(false)
    }

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM(change.doc.data());
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })
        const _tdHistorys = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                _tdHistorys.push(dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }
        if (isPaging) {
            dispatch({
                type: "APPEND_LIST",
                data: _tdHistorys,
                activetab: activeTab
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS_LIST",
                data: actionType ? snapshotDoc : _tdHistorys,
                activetab: activeTab
            });
        }


        setDataload(false)
        setLoader(false)
        setPaging(false)
    }


    const dataMappingVM = (doc) => {
        return testdriveMappingVM(doc);
    }

    const testdriveMappingVM = (doc) => {

        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        doc.startDate = doc.startDate && doc.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate._seconds)._d) : doc.startDate;
        doc.endDate = doc.endDate && doc.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate._seconds)._d) : doc.endDate;
        doc.expectedReturn = doc.expectedReturn && doc.expectedReturn._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.expectedReturn._seconds)._d) : doc.expectedReturn;

        const objlogData = Object.assign({}, doc);
        const testDriveVM = Object.assign({}, objTestDriveHistoryVM);
        for (let [key, value] of Object.entries(objlogData)) {
            testDriveVM[key] = value;
        }

        testDriveVM.createdOn = testDriveVM.addedDate ? moment.unix(testDriveVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        testDriveVM.updatedOn = testDriveVM.modifiedDate ? moment.unix(testDriveVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        testDriveVM.startDate = testDriveVM.startDate ? moment.unix(testDriveVM.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        testDriveVM.expectedReturn = testDriveVM.expectedReturn ? moment.unix(testDriveVM.expectedReturn.seconds).format('DD/MM/YYYY hh:mm A') : '';
        testDriveVM.endDate = testDriveVM.endDate ? moment.unix(testDriveVM.endDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        testDriveVM.addedBy = testDriveVM.addedBy ? CommonHelper.getUserNamebyId(allUsers, testDriveVM.addedBy) : '--';
        testDriveVM.modifiedBy = testDriveVM.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, testDriveVM.modifiedBy) : '--';
        testDriveVM.pipelineValue = doc.pipeline;
        let dealersettings = props.dealersettings;
        if (!_.isEmpty(testDriveVM.contact)) {
            let _contact = Object.assign({}, testDriveVM.contact);
            _contact.addedDate = _contact.addedDate && _contact.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_contact.addedDate._seconds)._d) : _contact.addedDate;
            _contact.modifiedDate = _contact.modifiedDate && _contact.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_contact.modifiedDate._seconds)._d) : _contact.modifiedDate;

            // testDriveVM.contactName = <div onClick={(e) => {
            //     e.preventDefault();
            //     const url = `/contacts/details/${_contact.documentID}`;
            //     window.open(url, '_blank');
            // }}>{CommonHelper.displayFullContact(titles, _contact)}</div>;
            testDriveVM.contactName = CommonHelper.displayFullContact(titles, _contact);
            testDriveVM.firstName = (_contact.firstName || '');
            testDriveVM.middleName = (_contact.middleName || '');
            testDriveVM.lastName = (_contact.lastName || '');
            testDriveVM.contactPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
            testDriveVM.phone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
            testDriveVM.contactEmail = _contact.email;
            testDriveVM.displayID = _contact.displayID;
            testDriveVM.licenseNo = _contact.licenseNo;
            testDriveVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
            testDriveVM.contactType = _contact.contactType;
            testDriveVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
            testDriveVM.gender = _contact.gender;
            testDriveVM.maritalStatus = _contact.maritalStatus;
            testDriveVM.contactMethod = _contact.contactMethod;
            testDriveVM.licenseType = _contact.licenseType;
            testDriveVM.language = CommonHelper.getLabelByValue(languages, _contact.language, '');
            //testDriveVM.campaign = CommonHelper.getLabelByValue(campaigns, _contact.campaign, '');
            //testDriveVM.origin = CommonHelper.getLabelByValue(origins, _contact.origin, '');
            testDriveVM.nationality = CommonHelper.getLabelByValue(nationalities, _contact.nationality, '');


            let _setttings = _contact.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[_contact.clientID] && dealersettings.group.clients[_contact.clientID].settings;
            if (_.isEmpty(_setttings))
                _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
            if (_setttings) {
                testDriveVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, _contact.campaign, '');
                testDriveVM.origin = CommonHelper.getNameByValue(_setttings.origins, _contact.origin, '');
                testDriveVM.gender = CommonHelper.getNameByValue(_setttings.genders, _contact.gender, '');
                testDriveVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, _contact.maritalStatus, '');
                testDriveVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, _contact.contactMethod, '');
                testDriveVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, _contact.interests, '', true);
                testDriveVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, _contact.licenseType, '');
            }

            if (_contact.clientID && (_contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                testDriveVM.contactdealerName = CommonHelper.getOtherDealerName(dealersettings, _contact.clientID);
            }
            else
                testDriveVM.contactdealerName = '';

            testDriveVM.address = _contact.address;
            testDriveVM.companyName = _contact.companyName;
            testDriveVM.designation = _contact.designation;
            testDriveVM.isDLScan = _contact.isDLScan ? 'Yes' : 'No';
            testDriveVM.licenseState = _contact.licenseState;
            testDriveVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
            testDriveVM.secondaryContactPhone = CommonHelper.phoneFormat(_contact.secondaryPhoneCode, _contact.secondaryPhone, props.dealersettings);
            testDriveVM.secondaryPhone = _contact.secondaryPhone ? ((_contact.secondaryPhoneCode ? _contact.secondaryPhoneCode : '') + _contact.secondaryPhone) : '';
            testDriveVM.secondaryContactEmail = _contact.secondaryEmail;
            testDriveVM.contactCreatedBy = _contact.addedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.addedBy) : '';
            testDriveVM.contactModifiedby = _contact.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.modifiedBy) : '';
            testDriveVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            testDriveVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        }

        if (!_.isEmpty(doc.stock)) {
            testDriveVM.stockNo = doc.stock.stockNo;
            testDriveVM.make = doc.stock.make;
            testDriveVM.model = doc.stock.model;
            testDriveVM.modelDescription = doc.stock.modelDescription;
            testDriveVM.saleType = CommonHelper.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
                doc.stock.saleType, '');
            testDriveVM.type = doc.stock.type;
            testDriveVM.year = doc.stock.year;
            testDriveVM.regNo = doc.stock.regNo;
            testDriveVM.vinNo = doc.stock.vinNo;
            testDriveVM.extColor = doc.stock.extColor;
            testDriveVM.intColor = doc.stock.intColor;
            testDriveVM.price = doc.stock.price;
            testDriveVM.purchasePrice = doc.stock.purchasePrice;
            testDriveVM.location = doc.stock.location;
            testDriveVM.stockStatus = doc.stock.status;
            testDriveVM.statusUpdatedBy = doc.stock.statusModifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.stock.statusModifiedBy) : '';
            if (doc.stock.clientID && (doc.stock.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                testDriveVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, doc.stock.clientID);
            }
            else
                testDriveVM.dealerName = '';
        }

        if (!_.isEmpty(testDriveVM.enquiry)) {
            const enquiryAllStatus = (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.enquiryStatus : []);
            let _enquiry = Object.assign({}, testDriveVM.enquiry);

            let _objCurrentStatus = _enquiry.status && enquiryAllStatus.filter(e => e.value === _enquiry.status)[0];
            testDriveVM.contractNumber = !_.isEmpty(_enquiry.contractNumber) ? _enquiry.contractNumber : '';
            testDriveVM.depositReciptNumber = !_.isEmpty(_enquiry.depositReciptNumber) ? _enquiry.depositReciptNumber : '';
            testDriveVM.leadSource = CommonHelper.getNameByValue(props.dealersettings.client.settings.leadSource, _enquiry.leadSource, '');
            testDriveVM.purchaseIntention = !_.isEmpty(_enquiry.purchaseIntention) ? _enquiry.purchaseIntention : '';
            testDriveVM.enqStatus = !_.isEmpty(_enquiry.status) ? (
                _objCurrentStatus
                    ?
                    <>
                        <span key={_enquiry.status}
                            className={`status-${_enquiry.status === 'open' ? 'open' : 'empty'}`}
                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                        >
                            {_objCurrentStatus.name}
                        </span>
                    </>
                    :
                    <></>
            ) : '';
            testDriveVM.enqOwner = CommonHelper.getUserNamebyId(allUsers, _enquiry.owner);
            testDriveVM.origin = CommonHelper.getNameByValue(props.dealersettings.client.settings.origins, _enquiry.origin, '');
            testDriveVM.enquiryType = CommonHelper.getNameByValue(props.dealersettings.client.settings.enquiryTypes, _enquiry.enquiryType, '');
            testDriveVM.campaign = CommonHelper.getNameByValue(props.dealersettings.client.settings.campaigns, _enquiry.campaign, '');
            testDriveVM.label = CommonHelper.bindEnquiryLabel(props.dealersettings.client.settings.enquiryLabels, _enquiry.label);
            testDriveVM.pipeline = CommonHelper.getNameByValue(props.dealersettings.allPipelines, _enquiry.pipeline, '');

            //BIND LOST REASONS
            if (_enquiry.lostReason) {
                let lostReasons = props.dealersettings.client.settings.lostReasons;
                let _lostReason = _enquiry.lostReason;
                testDriveVM.lostReason = CommonHelper.getNameByValue(lostReasons, _enquiry.lostReason, '');
                if (_enquiry.lostSubReason && _enquiry.lostReason && !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0])) {
                    let lostSubReasons = !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0].subList) ?
                        lostReasons.filter(e => e.value === _lostReason)[0].subList : [];
                    testDriveVM.lostSubReason = CommonHelper.getNameByValue(lostSubReasons, _enquiry.lostSubReason, '');
                }
            }

            if (_.find(props.dealersettings.allPipelines, { value: testDriveVM.pipelineValue })) {
                testDriveVM.stage = CommonHelper.getNameByValue(_.find(props.dealersettings.allPipelines, { value: testDriveVM.pipelineValue }).stages, _enquiry.stage, '');
            }
            testDriveVM.grossAmount = _enquiry.grossAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${_enquiry.grossAmount}` : '';
            testDriveVM.depositAmount = _enquiry.depositAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${_enquiry.depositAmount}` : '';
            testDriveVM.csvQuoteAmount = !_.isEmpty(_enquiry.quotation) && _enquiry.quotation.amount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${_enquiry.quotation.amount}` : '';

            testDriveVM.quoteAmount = !_.isEmpty(_enquiry.quotation) && _enquiry.quotation.amount ? <NumberFormat
                thousandSeparator={true}
                prefix={`${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol + ' ' : '$ '}`}
                displayType={'text'}
                decimalScale={2}
                value={_enquiry.quotation.amount}
            /> : '';

            if (!_.isEmpty(_enquiry.requirement) && (!_.isEmpty(_enquiry.requirement.make) || !_.isEmpty(_enquiry.requirement.stock))) {
                testDriveVM.reqModel = !_.isEmpty(_enquiry.requirement.stock) ? _enquiry.requirement.stock.make + ' ' + _enquiry.requirement.stock.model : _enquiry.requirement.make + ' ' + _enquiry.requirement.model

                testDriveVM.reqSaletype = CommonHelper.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
                    _enquiry.requirement.saleType, '');
            }
        }


        testDriveVM.tdstatusName = testDriveVM.status ? testDriveVM.status : '';
        testDriveVM.status = (testDriveVM.status
            ?
            <div className={`badge badge-pill badge-${testDriveVM.status === testDriveStatus.PENDING ? 'pending' : 'active-fill'}`}>
                {testDriveVM.status}
            </div>
            :
            <></>);

        testDriveVM.stockStatusName = CommonHelper.getNameByValue(dealersettings?.client?.settings?.stockStatus, testDriveVM.stockStatus, '');
        testDriveVM.stockStatus = CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, testDriveVM.stockStatus, '');
        // testDriveVM.stockStatus = (testDriveVM.stockStatus
        //     ?
        //     <div className={`badge badge-pill badge-${testDriveVM.stockStatus.trim().toLowerCase()}-fill `}>
        //         {testDriveVM.stockStatus.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
        //     </div>
        //     :
        //     <></>);


        return testDriveVM
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)

        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ testDriveHistoryFields: fields }, { merge: true })
            .then(() => {
                setTestDriveHistoryFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('testDriveHistoryFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }
    const handleEditFleet = (id, obj) => {
        if (!readOnlyEnquiryView) {
            setEditDocument(id);
            setModelTestDriveShow(true);
        }

    }

    const handleClose = (id) => {
        setModelTestDriveShow(false);
        setEditDocument(null);

        if (!_.isEmpty(id)) {
            getFleetbyID(id);

        }
    }

    const getFleetbyID = (id) => {
        window.clistSubscription = collectionData(firestoreDB(props.dealersettings).firestore().collection('testdrives')
            .where('documentID', '==', id))
            .pipe(
                switchMap(dataSnapshot => {
                    const stockIds = _.uniq(dataSnapshot.filter(item => !_.isEmpty(item.stockID)).map(item => item.stockID))
                    return combineLatest(
                        of(dataSnapshot),
                        combineLatest(
                            stockIds.length > 0 ? stockIds.map(stockID =>
                                collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
                                    map(stockSnapshot => stockSnapshot[0] ? stockSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([dataSnapshot, stockSnapshot]) => {
                    return dataSnapshot.map(snapDoc => {
                        return {
                            ...snapDoc,
                            stock: stockSnapshot.find(a => a.documentID === snapDoc.stockID),
                        }
                    })
                })
            )
            .subscribe(datas => {
                onDocUpdate(dataMappingVM(datas[0]));
                window.clistSubscription && window.clistSubscription.unsubscribe()
            });
    }

    const onDocUpdate = (_data) => {
        //console.log('onDocUpdate', data)
        if (!_.isEmpty(_data)) {
            const oldLog = fleetHistory[activeTab].find(item => item.documentID === _data.documentID);
            if (!_.isEmpty(oldLog)) {
                dispatch({
                    type: "UPDATE_LIST",
                    data: _data,
                    activetab: activeTab
                });
            }
        }
    }

    const handleActionClick = (id, obj) => {

        return (
            <div
                style={{ cursor: 'pointer' }}
                className="mini-button"
                onClick={(e) => {
                    e.preventDefault();

                    if (!readOnlyEnquiryView)
                        handleEditFleet(id, obj);
                }}>

                {readOnlyEnquiryView ? <><i className="ico icon-lock mr-1"></i></> : <><i className="ico icon-more"></i></>}
            </div>
        )


    }

    const handleDetailsClick = (e, id, obj) => {
        e.preventDefault();
        if (!readOnlyEnquiryView)
            handleEditFleet(id, obj);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }
    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
        setDataload(true)
        setLoader(true)
        setHasMoreData([]);
    }

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    const excelDownload = async () => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        const { dealersettings } = props;
        let _testDriveFilter = localStorage.testDriveFilter ? JSON.parse(localStorage.testDriveFilter) : {};
        let _filter = Object.assign({}, _testDriveFilter.value);

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }
        if (isOEMModule && !_.isEmpty(dealersettings.group.clients)) {
            if (_.isEmpty(_filter.clientID)) _filter.clientID = Object.keys(dealersettings.group.clients).map(cid => cid).join(',')
        }
        else {
            _filter.clientID = props.dealersettings.client.id;
        }
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();
        if (searchText && searchText.trim()) _filter.keywords = searchText.trim().toLowerCase();
        let _searchObject = {
            "type": "searchTestdrives",
            "clientID": _filter.clientID,
            "filters": JSON.stringify(_filter),
            "sortOrder": `${_filter.dateType ? _filter.dateType : 'modifiedDate'} desc`,
            "pageNum": 0,
            "pageLimit": 10000
        }

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )

        //console.log('generic-searchData', isFilterApplied, pageNum, _searchObject, localStorage.testDriveFilter)
        const searchTestdrives = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchTestdrives(_searchObject);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = dataMappingVM(doc);
                _enquiries.push(logVM)
            });
            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from test drive history',
                type: 'excelExport',
                subType: 'testdrives',
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }



        // let refTestDriveDataExport = window.firebase.firestore().collection('testdrives')
        //     .where('clientID', '==', props.dealersettings.client.id)
        //     .where('isDeleted', '==', false)
        //     .where('startDate', '>=', dateRange.startDate)
        //     .where('startDate', '<=', dateRange.endDate)
        //     .orderBy('startDate', 'desc')

        // if (searchText && searchText.trim()) {
        //     refTestDriveDataExport = refTestDriveDataExport
        //         .where('keywords', 'array-contains', searchText.trim().toLowerCase())
        // }

        // refTestDriveDataExport = refTestDriveDataExport.limit(1000)

        // window.unSubTestDriveListExport = collectionData(refTestDriveDataExport)
        //     .pipe(
        //         switchMap(testdriveSnapshot => {
        //             const stockIds = _.uniq(testdriveSnapshot.filter(item => !_.isEmpty(item.stockID)).map(item => item.stockID))
        //             return combineLatest(
        //                 of(testdriveSnapshot),
        //                 combineLatest(
        //                     stockIds.length > 0 ? stockIds.map(stockID =>
        //                         collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
        //                             map(stockSnapshot => stockSnapshot[0] ? stockSnapshot[0] : {})
        //                         )
        //                     ) : of([])
        //                 ),
        //             )
        //         }),
        //         map(([testdriveSnapshot, stockSnapshot]) => {
        //             return testdriveSnapshot.map(snapDoc => {
        //                 return {
        //                     ...snapDoc,
        //                     //stock: stockSnapshot.find(a => a.documentID === snapDoc.stockID),
        //                 }
        //             })
        //         })
        //     )
        //     .subscribe(testDrives => {
        //         let _enquiries = [];
        //         testDrives.forEach(function (doc) {
        //             var dataVM = dataMappingVM(doc);
        //             _enquiries.push(dataVM)
        //         });
        //         console.log('refStockDataExport', _enquiries)
        //         setCSVData(_enquiries)
        //         csvBtn.current && csvBtn.current.link.click();
        //         toast.closeAll();
        //         Swal.fire({
        //             icon: 'success',
        //             title: 'Data downloaded successfully.',
        //             showConfirmButton: false,
        //             timer: 1500
        //         });


        //     });

    }


    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                excelDownload();
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('testDriveFilter', JSON.stringify(_filter));
            localStorage.tdHistorySearchText = '';
            setFilterApplied(true);
            setLoader(true)
            setDataload(true)
            setPaging(false);
            setHasMoreData([]);
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.testDriveFilter)) {
            localStorage.removeItem('testDriveFilter');
            localStorage.tdHistorySearchText = '';
            setFilterApplied(false);
            setLoader(true)
            setDataload(true)
            setPaging(false);
            setHasMoreData([]);
            setPageNum(0);
            setSearchText('');
        }
    }

    return (
        <div className="middle-wrapper">

            <FilterPanel
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                csvHeader={csvHeader}
                datalist={fleetHistory[activeTab]}
                searchText={searchText}
                handlesearchText={(val) => {
                    setSearchText(val)
                    setDataload(true)
                    setHasMoreData([]);
                    setFilterApplied(false);
                    setLoader(true);
                    setPaging(false);
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.testDriveFilter))
                        localStorage.removeItem('testDriveFilter');

                }}
                //testDriveCount={testDriveCount}
                csvData={csvData}
                excelDownload={excelDownload}
                csvBtn={csvBtn}
                dateRange={dateRange}
                handleDateRangeChange={handleDateRangeChange}
                handleSetDates={setDates}
            />
            <div className="calendar-panel">
                {
                    fleetHistoryLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        fleetHistory[activeTab] && fleetHistory[activeTab].length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    isReorderable={true}
                                    datalist={fleetHistory[activeTab] ? fleetHistory[activeTab] : []}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={allTestDriveHistoryFieldsVM}
                                    handleSavesettings={handleSavesettings}
                                    dynamicFields={
                                        (testDriveHistoryFields && testDriveHistoryFields.length > 0) ? testDriveHistoryFields : allTestDriveHistoryFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                    }
                                    settingsLoader={settingsLoader}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleDetailsClick}
                                    isSettings={true}
                                    hasMore={hasMore}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    activityTab={activeTab}
                                    displayByValue={true}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No record found'} /></p>

                                </div>
                            </div>
                        )
                }


            </div>


            <PopUpModal show={modelTestDriveShow}>
                <AddTestDrive
                    docID={editDocumentID}
                    show={modelTestDriveShow}
                    clsActive='overlay-modal active'
                    handleClose={handleClose}
                    title={'testDrive'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isDeivered={false}
                    //enquiryID={this.state.enquiryID}
                    contact={null}
                >
                </AddTestDrive>
            </PopUpModal>

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>

        </div>
    )
}

export default TestDriveHistoryListView;